<div class="whiteboard-container">
<div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="space-around space-around">

<div class="big-text">
<button style="font-size: 1.3em;" (click)="select()">Pick</button>
<button style="font-size: 1.3em;" (click)="paint()">Paint</button>
<button style="font-size: 1.3em;" (click)="line()">Line</button>
<button style="font-size: 1.3em;" (click)="box()">Box</button>
<button style="font-size: 1.3em;" (click)="circle()">Circle</button>
<button style="font-size: 1.3em;" (click)="text()">Text</button>
<button style="font-size: 1.3em;" (click)="shape()">Shape</button>
<button style="font-size: 1.3em;" (click)="image()">Image</button>
<button style="font-size: 1.3em;" (click)="grid()">Grid</button>
<button style="font-size: 1.3em;" (click)="erase()">Eraser</button>
<button style="font-size: 1.3em;" (click)="eraseAllMine()">Erase All Mine</button>
<button style="font-size: 1.3em;" (click)="eraseEverything()">Erase Everything</button>
<button style="font-size: 1.3em;" (click)="end()">End</button>
</div>

</div>

<div class="demo">
<!--
    <ng-whiteboard (dataChange)="newData($event)" (deleteElement)="delElement($event)" (selectElement)="selElement($event)"
    [(data)]="myDrawing"  
    [(strokeColor)]="options.strokeColor"
    [fontSize]="16"
    [zoom]="zoom"
    [enableGrid]="gridLock" [snapToGrid]="gridLock" [gridSize]="20"
    [selectedTool]="toolMode" [options]="options"
    ></ng-whiteboard>
-->
</div>
</div>