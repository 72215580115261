import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";

import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, Navigate, Reviews, Review } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
//import { ImagekitioAngularModule } from 'imagekitio-angular';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { MediaService } from "../media//media-functions/media-functions"
import { Quote } from '../content-interface';


@Component({
  selector: 'app-media-view',
  templateUrl: './media-view.component.html',
  styleUrls: ['./media-view.component.css', '../../common.scss'],
})
export class MediaViewComponent implements OnInit {
  @Input() media: Media[] = [];
  @Input() description: string = "Please add a description";  // Later can decide not to display...
  @Input() noStyle: boolean;
  @Input() justMedia: boolean = false;
  @Input() maxHeight;

  ngOnInit(): void {
    console.log("Media: ", this.media);
  }

  constructor(public global: Globals) {


  }

  getStyle() {
    if (this.maxHeight) return 'height:'+this.maxHeight;
    else return 'height: 100%'

  }

  getBackgroundColor(media: Media) {
    /*
        if (media.backgroundColor)
          return node.backgroundColor
        else return "#000000";
    */
  }


  getHeight(media: Media) {

    //    if (node.height)
    //  return node.height.toString()+'px'
    //  else 
    return "max-content";
  }
  getWidth(media: Media) {
    if (this.global.getScreenWidth > 1200) return 1200;
    else return this.global.getScreenWidth;


    if (media.width)
      return media.width.toString() + 'px'
    else return "100%";
  }

  getMediaWidth(m: Media) {
    if (this.global.getScreenWidth > 900) return 900;
    else if (this.global.getScreenWidth > 600) return 600;
    else return this.global.getScreenWidth;

    return "800px";
  }

  getImageStyle(media: Media) {

    return "scale-down";

    /*

    if (!node.size) node.size = "cover";

    var style = "width: 100%;  object-fit: " + node.size + ';'
    if (node.posX) style += "object-position: " + node.posX + "% " + node.posY + "%;"
    if (node.height) style += "height: " + node.height + "px;";
    else style += " height: 400px;"
    //    console.log("backstyle ", style);
    return style;

    */
  }

  setFullScreen() { }

  changeAudio(id, i) {

    var audio = <HTMLVideoElement>document.getElementById(id + i);
    audio.muted = !audio.muted;
  }

  playEnded (i) {
    console.log ("Play ended: ", i);

  }
  showPlay (i) {
    return false;
  }

  paused: boolean = false;

  isPaused(id, i) {

    return this.paused;

    var video = <HTMLVideoElement>document.getElementById(id + i);

    if (video && !video?.paused && !video?.onended) {

      video.onended = (event) => {

        console.log(
          "Video stopped either because it has finished playing or no further data is available.",
        );
        return true;
        }
      }
 

    return false;


  }

  playVideo(id, i) {
    var video = <HTMLVideoElement>document.getElementById(id + i);
    video.play();
    this.paused = false;

  }

  pauseVideo (id, i) {
    var video = <HTMLVideoElement>document.getElementById(id + i);
    video.pause();
    this.paused = true;


  }

  audioLabel(id) {
    var audio = <HTMLVideoElement>document.getElementById(id);
    if (audio && audio.muted) return "Play Audio"
    else return "Mute Audio"

  }



}
