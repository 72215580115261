<!--  Add Features - move to own component -->
<div *ngIf="global.myCompany" class="">
    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">


    <p-accordionTab>
        <ng-template pTemplate="header">
            <div class="pretty-demo">Add new features with a single click</div>
        </ng-template>


        <ng-template pTemplate="content">


            <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">





                <p-accordionTab *ngIf="!global.myCompany.enabled.reviews">
                    <ng-template pTemplate="header">
                        <div class="pretty-demo">Reviews</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        Add review management: just $10/mo.
                        <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.reviews" optionLabel="name"
                        optionValue="code"></p-selectButton>
                    </ng-template>
                </p-accordionTab>


                <p-accordionTab header="Chat with Us">
                    Customer chats simplified<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>
                 <p-accordionTab header="Inspirational Quotes">
                    Words of Wisdom<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>
                 <!--
                 <p-accordionTab header="Reviews">
                    Your online reviews gathered automatically<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>
                 -->
                 <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty-demo">Media Manager</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        Add Media Manager: $10/mo..<br>
                        <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                            [(ngModel)]="global.myCompany.enabled.media" optionLabel="name"
                            optionValue="code"></p-selectButton>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab >
                    <ng-template pTemplate="header">
                        <div class="pretty-demo">Reservations</div>
                    </ng-template>


                    <ng-template pTemplate="content">
                        Add Reservations: just $10/mo.<br>

                        <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                            [(ngModel)]="global.myCompany.enabled.reservations" optionLabel="name"
                            optionValue="code"></p-selectButton>
                    </ng-template>
                </p-accordionTab>
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty-demo">Products & Services</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        Add Product Manager: it's free.<br>
                        <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                            [(ngModel)]="global.myCompany.enabled.products" optionLabel="name"
                            optionValue="code"></p-selectButton>
                    </ng-template>
                </p-accordionTab>  
                <p-accordionTab header="E-Commerce">
                    Start selling online!<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>    
                 <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty-demo">App Builder</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        Add app builder: it's Free!.<br>
                        <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                            [(ngModel)]="global.myCompany.enabled.appBuilder" optionLabel="name"
                            optionValue="code"></p-selectButton>
                    </ng-template>
                </p-accordionTab>  
                <p-accordionTab header="Point of Sale (POS)">
                    Start selling in-person<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.pos" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>                 
                 <p-accordionTab header="Review Management">
                    Collect new reviews easily <br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Loyalty Program">
                    Reward loyal customers easily<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Digital Marketing">
                    Spread the word<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Party Games">
                    Engaging games for your patrons<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Learning Platform">
                    Create & distribute learning modules easily. <br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Ticketing & Events">
                    Free & Paid Events<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  

                 <p-accordionTab header="Social Media">
                    Engage with your market visually<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab> 
                 <p-accordionTab header="Weather">
                    Provide weather data to customers<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Virtual Whiteboard">
                    Innovation space for your any team<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Video Meetings">
                    Engage with your team<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Team Space">
                    Team messaging and collaboration<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>  
                 <p-accordionTab header="Search Engine">
                    Advanced search without ads<br>
                    <p-selectButton (onChange)="updateEnabled()" [options]="enabled"
                        [(ngModel)]="global.myCompany.enabled.chatService" optionLabel="name"
                        optionValue="code"></p-selectButton>
                 </p-accordionTab>   

<!--


[Video Search]
[Image Search]



-->





            </p-accordion>
        </ng-template>

    </p-accordionTab>
    </p-accordion>

</div>