<div *ngIf="global.myCompany.stripeAccountID">

    <div class="max-w-full flex flex flex-row justify-content-center">
        <!--        <div *ngIf="errorMessage">{{errorMessage}}</div> -->

        <div
            class="surface-50 shadow-3 border-x-3 border-50 md:w-screen sm:w-screen lg:w-9 xl:w-6 line-height-4 pb-3 flex flex-column">
            <div *ngIf="!myToken || (myToken && myToken.length==0)"
                class="w-full p-0 bg-blue-100 text-3xl text-white text-center" (click)="returnToPos()">Return to POS
            </div>
            <div *ngIf="paymentSuccess" fxFlex fxLayout="column" fxLayoutAlign="center center">

                <div *ngIf="cashPaymentAmount" style="font-size: 1.2em">
                    {{((cashPaymentAmount-theTotal)|currency)+" change due"}}
                </div>
                <!-- <div *ngIf="1" fxFlex fxLayout="column" fxLayoutAlign="center center">-->

                <span style="font-size: 28px; font-weight: 700; color:blue">Thank you for your payment!</span>

                <span
                    style="line-height: 95%; font-size: 28px; font-weight: 700; color:rgb(118, 165, 0); text-align: center;">
                    Scan below to leave us feedback.<br>
                    Your server will earn an extra $5 on us.<br><br></span>

                <img src={{getReviewUrl()}} (click)="returnToPos()">

                <br>
                <hr>
                <!--
                <div style="color:rgb(49, 95, 95);">powered by</div>
                <img src="./assets/NapkinReviewLogo.png" class="napkin-logo">
                -->

                <div *ngIf="!global.customer" fxLayout="column" fxAlignLayout="center center">
                    <hr>
                    <span *ngIf="!customerId.length" class="perks">This order earns {{myRewardPoints}} points for CHV
                        Perks<br>
                        <div style="font-size:75%">{{redeemTotal+" "}}Perks available</div>

                    </span>

                    <div *ngIf="!customerId.length" fxLayout="row" fxAlignLayout="center center">

                        <input type="email" style="width: 375px; font-size:1.2rem;padding:12px;margin:12px;"
                            placeholder="Email" [(ngModel)]="email">

                        <button pButton pRipple type="button" (click)="registerUser()" style="font-size:1.4em;"
                            class="small-button">Get CHV Perks</button>
                    </div>
                    <div *ngIf="customerId.length" class="thanks" fxLayout="row" fxAlignLayout="center center">
                        Thanks. Check email for Perks & more.
                    </div>

                    <hr>

                </div>



                <!--  Register Perks 
                <div style="font-size:1.2rem;" *ngIf="global.myCompany.registerSettings && global.myCompany.registerSettings.activePerks  
                        ">
                    <br>

                    A CHV Perk is available with this order. Perk coupon is emailed.
                    <strong>{{global.myCompany.registerSettings.activePerks[0]}}</strong>
                    <br><br>
                </div>
-->



                <!-- REWARDS PROGRAM 
                    Must have a User: Validated??: Auto-register from email, name
                    Toast to Announce new points!
                New Points, Total Points
                Show Redeemable Items within 100 points of total
                Allow Redemption
                
                <h1>Total Rewards Points: {{myRewardPoints}}</h1>


                <div class="card">
                    <p-dataView #dv [value]="red" [paginator]="true" [rows]="9" filterBy="name" [sortField]="sortField"
                        [sortOrder]="sortOrder" layout="grid">
                        <ng-template pTemplate="header">
                            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price"
                                    (onChange)="onSortChange($event)" styleClass="p-mb-2 p-mb-md-0"></p-dropdown>
                                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                                    <i class="pi pi-search"></i>
                                    <input type="search" pInputText placeholder="Search by Name"
                                        (input)="dv.filter($event.target.value)">
                                </span>
                                <p-dataViewLayoutOptions></p-dataViewLayoutOptions>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="listItem">
                            <div class="p-col-12">
                                <div class="product-list-item">

                                    <img *ngIf="item.image1" src={{item.image1}} alt="Photo of">


                                    <div class="product-list-detail">
                                        <div class="product-name">{{item.title}}</div>
                                        <div class="product-description">{{item.fullDescription}}</div>
                                        <i class="pi pi-tag product-category-icon"></i><span
                                            class="product-category">{{item.category}}</span>
                                    </div>
                                    <div class="product-list-action">
                                        <span class="product-price">${{item.retail}}</span>
                                        <p-button icon="pi pi-shopping-cart" label="Add 2 Cart"
                                            [disabled]="red.trackInventory === 'OUTOFSTOCK'"></p-button>
                                        <span [class]="'product-badge status-'">{{item.dept}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="gridItem">
                            <div class="p-col-12 p-md-4">
                                <div class="product-grid-item card">
                                    <div class="product-grid-item-top">
                                        <div class="product-grid-item">

                                            <img *ngIf="item.image1" src={{item.image1}} alt="Photo of">

                                        </div>
                                        <div>
                                            <i class="pi pi-tag product-category-icon"></i>
                                            <span class="product-category">{{item.category}}</span>
                                        </div>
                                        <span [class]="'product-badge status-'">{{item.dept}}</span>
                                    </div>
                                    <div class="product-grid-item-content">
                                        <div class="product-name">{{item.title}}</div>
                                        <div class="product-description">{{item.fullDescription}}</div>

                                    </div>
                                    <div class="product-grid-item-bottom">
                                        <span class="product-price">${{item.retail}}</span>
                                        <p-button icon="pi pi-shopping-cart"
                                            [disabled]="red.dept === 'OUTOFSTOCK'"></p-button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>


                </div>
            -->


            </div>
            <div *ngIf="!paymentSuccess" style="font-size:1.75rem;margin-top:12px;">
                <button *ngIf="!cashPayment && (!myToken || myToken.length==0)" type="button" class="tender-cash"
                (click)="tenderCash()"
                style="margin: auto;padding:12px; text-align:center;">
                Tender Cash Payment
            </button>
            <div *ngIf="cashPayment" fxLayout="row">
                <p-inputNumber styleClass="input-styling" [(ngModel)]="cashPaymentAmount"
                inputId="currency-us" mode="currency" size="8" maxFractionDigits="2" currency="USD"
                locale="en-US"> </p-inputNumber>
                <div style="margin-left:12px">{{getBalanceorChange()}}</div>
                <button *ngIf="cashPaymentAmount<theTotal" type="button" class="tender-cash"
                (click)="getCreditCard()"
                style="margin: auto;padding:12px; text-align:center;">
                Continue to Credit Card for balance
            </button>

            <button *ngIf="cashPaymentAmount>=theTotal" type="button" class="tender-cash"
            (click)="closeSale()"
            style="margin: auto;padding:12px; text-align:center;">
            Complete the Sale
        </button>


            </div>


                <div *ngIf="!cashPayment" [style.color]="myColor()">
                    <form autocomplete="off" novalidate (ngSubmit)="createToken()" [formGroup]="stripeTest">
                        <div *ngIf="!myToken || (myToken && myToken.length==0)" fxLayout="row wrap" fxLayoutAlign="center center"
                            style="margin-bottom: 10px;">
                            <input (input)="lookupUser($event)" type="text" style="font-size:1.2rem;padding:12px;"
                                formControlName="name" spellcheck="false" autocomplete="off" placeholder="Jane Doe">

                            <input type="email" style="font-size:1.2rem;padding:12px;" autocomplete="off" placeholder="Email"
                                formControlName="email">

                        </div>
                        <div *ngIf="(!myToken  || myToken.length==0) && foundUsers">
                            <div *ngFor="let user of foundUsers; let i = index" (click)="loadUser(user)">
                                {{user.firstName+" "+user.lastName+" "+user.email}}
                            </div>
                        </div>

                        <div id="card-element" class="field" [style.display]="myColor()"></div>
                        <div fxLayoutAlign="center center">
                            <button *ngIf="!myToken || myToken.length==0" type="submit" class="pay-review"
                                style="margin: auto;padding:12px; text-align:center;">
                                Complete Purchase - Review & Pay
                            </button>
                        </div>
                    </form>
                </div>

                <hr>
                <div *ngIf="viewDetail">

                    <div fxLayout="row" fxLayoutAlign="flex end center" class="order-detail"
                        *ngFor="let item of sharedDataService.payOrder; let i = index" [value]="shoe">
                        <div
                            class="flex align-items-center justify-content-end white-space-nowrap flex-grow-1 text-overflow-clip w-10 t">
                            {{item.item}} </div>
                        <div class="flex align-items-center justify-content-end w-2 t"> {{item.extPrice | currency}}
                        </div>
                    </div>
                </div>


                <div *ngIf="0" class="flex align-items-center justify-content-center t mr-3">
                    <div (click)="CloseOrderPaid()" class="h-1 bg-blue-50 shadow-3 p-1
                    transition-all transition-duration-500 hover:bg-yellow-500 hover:text-gray-900">CLOSE-PAID
                    </div>

                    <div (click)="toggleViewDetail()" class="h-1 bg-blue-50 shadow-3 p-1
                transition-all transition-duration-500 hover:bg-yellow-500 hover:text-gray-900">{{detailText}}
                    </div>
                </div>

                <div *ngIf="discounts()" fxLayout="row" fxLayoutAlign="flex end center" class="order-detail">

                    <div
                        class="flex align-items-center justify-content-end white-space-nowrap flex-grow-1 text-overflow-clip w-10 t">
                        Order discounts:
                    </div>
                    <div class="flex align-items-center justify-content-end w-2 t">
                        {{getDiscounts()}}
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="flex end center" class="order-detail">


                    <div
                        class="flex align-items-center justify-content-end white-space-nowrap flex-grow-1 text-overflow-clip w-10 t">
                        Order subtotal:
                    </div>
                    <div class="flex align-items-center justify-content-end w-2 t">
                        {{getSubTotal()}}
                    </div>
                </div>

                <div fxLayout="row" fxLayoutAlign="flex end center" class="order-detail">
                    <div
                        class="flex align-items-center justify-content-end white-space-nowrap flex-grow-1 text-overflow-clip w-10 t">
                        Sales tax (CA 7.75%):
                    </div>
                    <div class="flex align-items-center justify-content-end w-2 t">
                        {{(salesTax | currency)}}
                    </div>
                </div>


                <div style="margin-top:24px; width: 100%;" fxLayout="row" fxLayoutAlign="center center"
                    class="order-detail">


                    <br>
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <span style="margin-bottom: 16px;">Tipping Options</span>
                        <p-selectButton [options]="tipOptions" (onChange)="updateTip(index)" [(ngModel)]="tipValue"
                            optionLabel="label" optionValue="index">
                        </p-selectButton>
                        <br>
                    </div>
                </div>
                <div class="flex justify-content-end flex-wrap mt-3 mb-3 font-bold">
                    <div *ngIf="tipValue==8; else val">
                        <p-inputNumber styleClass="input-styling" [(ngModel)]="customTip" (onInput)="getTotal()"
                            inputId="currency-us" mode="currency" size="5" maxFractionDigits="0" currency="USD"
                            locale="en-US"> </p-inputNumber>
                    </div>
                    <ng-template #val>
                        {{tipAmount | currency}}
                    </ng-template>

                </div>

                <div class="flex justify-content-end flex-wrap mt-3 mb-3 font-bold">

                    <div class="flex align-items-center justify-content-end t">

                        Total to be charged:
                    </div>
                    <div class="flex align-items-center justify-content-end t ml-3">
                        {{theTotal | currency}}
                    </div>

                </div>

                <button *ngIf="viewPayButton &&  myToken && !paying" class="pay-button" (click)="pay()">Complete
                    Purchase
                    <!--   {{" " + theTotal|currency}} -->
                </button>
                <div *ngIf="myToken && paying" class="pay-button">
                    Stripe Payment Processing...
                </div>

                <div *ngIf="redeemCount()" style="font-size: 1em; line-height: 1.2em;">
                    {{redeemTotal+" "}}Perk<span *ngIf="redeemCount()>1">s</span> available<br>
                Redeem perks after payment.</div>

            </div>
        </div>
        <div *ngIf="!global.myCompany?.stripeAccountID">
            Please Link Stripe Account In Admin > Profile
        </div>

        <button *ngIf="0" class="pay-button" (click)="createCustomer()">Create Customer</button>