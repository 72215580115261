import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Company, ReviewSetup, GoogleBusinessInfo, YelpOverview, Navigate, Coupon, ReviewTotals, Reviews, Review } from './company-interface';
import { Howl, Howler } from 'howler';
import { Logger } from '../functions';
import { MediaService } from './media/media-functions/media-functions';


const sound = new Howl({
  src: ['../../../assets/new-review-0.mp3']
});
const sound2 = new Howl({
  src: ['../../../assets/one-review.mp3']
});

@Injectable({
  providedIn: 'root'
})
export class ReviewsService {

  totals: ReviewTotals;
  newReviews: Review[];

  // pendingReviews: Review[];
  googleInfo: GoogleBusinessInfo;
  yelpOverview: YelpOverview;

  constructor(private global: Globals, private sanitizer: DomSanitizer, private mediaService: MediaService) {

  }

  getCoupons() {
    var self = this;
    var db = firebase.firestore();

    const q0 = query(collection(db, "coupons"), where("companyId", "==", self.global.myCompany.id));
    const unsubscribe = onSnapshot(q0, (querySnapshot) => {
      self.global.coupons = [];

      querySnapshot.forEach((doc) => {
        const c = <Coupon>doc.data();
        self.global.coupons.push(c);
        console.log("Have a coupon: ", c);
      })
    })
  }
  checkFilter(r: Review, tags: string[]) {
    if (!tags || tags.length == 0) return true;

    var found = false;

    if (r.review_text) {
      tags.forEach(function (filter) {
        //  var exists = r.review_text.includes(filter)
        //  if (exists) { found = true; }
        var str = r.review_text.toLowerCase();
        var exists = str.search(filter.toLowerCase())
        if (exists != -1) { found = true; }

      })
    }

    return found;
  }

  getFilteredText(p, tags) {
    var str: string = p.review_text;
    if (!str || str.length == 0) str = ""
    if (!tags || tags.length == 0) return str;

    var strArray = str.split(" ");

    strArray.forEach(function (word, index) {
      tags.forEach(function (filter) {
        var w = word.toLowerCase();
        w = w.replace(',', '');  // REMOVE
        w = w.replace('.', '');  // REMOVE
        w = w.replace('!', '');  // REMOVE


        if (filter.toLowerCase() == w) {
          // ADD BOLD
          strArray[index] = '<strong>' + word + '</strong>'
          //   console.log("Word ", word)
        }

      })

    })

    str = strArray.join(' ');

    return str;

  }

  previousPending = 0;
  started = false;
  reviewListen;

  getReviews(remove?: boolean) {
    var self = this;

    if (remove) {
      if (self.started) {
        self.reviewListen(); // REMOVES IT
        self.started = false;
      }
    }

    if (self.started) return;

    if (!self.global.myCompany.enabled.reviews) return;
    this.getCoupons();

    var db = firebase.firestore();
    if (!self.global.publishedReviews) self.global.publishedReviews = [];
    if (!self.global.pendingReviews) self.global.pendingReviews = [];
    console.log("Start Reviews Listener")
    self.started = true;
    const ref = db.collection('company')
      .doc(self.global.myCompany.id)
      .collection('reviews')
    //      .orderBy('timeStamp', 'desc')
    //      .limit(100)

    self.reviewListen = ref.onSnapshot((querySnapshot) => {
      if (self.global?.authuser?.uid)
        Logger("Reviews snapshot", self.global.authuser.uid, self.global.myCompany.store, querySnapshot.size)

      self.global.publishedReviews = [];
      self.global.pendingReviews = [];
      self.global.reviewMedia = [];

      self.global.reviews = {};
      self.global.reviews.reviews = [];

      querySnapshot.forEach((doc) => {
        const review = <Review>doc.data();

        if (!self.global.reviews || !self.global.reviews.totals) {
          if (!self.global.reviews)
            self.global.reviews = {}
          self.global.reviews.totals = { "total5Star": 0 }
        }

        // FIXERS
        if (!review.timeStamp) {
          var timeStamp = 0
          /*
          if (review.source == 'Google') {
            let currentTime = new Date(review.review_datetime_utc);
            let currentTimeMillis = currentTime.getTime();
            review.timeStamp = currentTimeMillis
            console.log("GOOGLE Date", review.timeStamp)
          }*/
        }

        if (!review.id) review.id = doc.id;
        if (!review.author) review.author = review.author_name;
        if (!review.author_name) review.author_name = review.author;
        if (!review.status) { review.status = "published" }
        if (review.rating) {
          review.review_rating = review.rating;
        }

        if (!review?.media && review?.review_photos) {
          review.media = [];
          review.review_photos.forEach(function (photo) {
            review.media.push({ url: photo })


          })
          //console.log("ADDED REVIEW MEDIA ")

        }

        if (review?.media?.length > 0) {
          self.global.reviewMedia.push(review)
        }
        self.global.reviews.reviews.push(review)
        if (review.status == "published") {
          self.global.publishedReviews.push(review);
        }
        else if (self.validCreds() && review.status == "pending") {
          self.global.pendingReviews.push(review);
          if (self.global.pendingReviews.length > self.previousPending) {
            if (self.global.myCompany?.reviewSetup?.announce) {
              self.previousPending = self.global.pendingReviews.length;
              sound2.play();
            }
          }
        }

      });

      console.log("Got Reviews: ", self.global.publishedReviews, self.global.pendingReviews);
      self.calcTotals();

    })
  }

  validCreds() {
    var self = this;

    // VALIDATE USER CREDS


    if (!self.global.authuser) return false;
    if (self.global.authuser && self.global.authuser.emailVerified == false) return false;
    if (!self.global.authuser.creds) return false;
    if (!self.global.myCompany) return false;
    if (self.global.entryPoint != 'front') {
      console.log(self.global.entryPoint)
      return false;
    }
    // if (self.global.myCompany.store != self.storeNum) return false;

    const xid = this.global.myCompany.id;

    var found = this.global.authuser.creds.find(function (post, index) {
      if (post.id == xid && (post?.enabled?.staff || post.role == 'Creator'))
        return true;
    });
    return found;

  }




  /*

  OLD
  getReviews() {
    var self = this;
    this.getCoupons();

    var db = firebase.firestore();
    if (!self.global.publishedReviews) self.global.publishedReviews = [];
    if (!self.global.pendingReviews) self.global.pendingReviews = [];

    const q = query(collection(db, "reviews"), where("companyID", "==", self.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.global.publishedReviews = [];
      self.global.pendingReviews = [];

      querySnapshot.forEach((doc) => {
        const r = <Review[]>doc.data().reviews;
        const reviews = <Reviews>doc.data();
        self.global.reviews = reviews;
        if (!self.global.reviews || !self.global.reviews.totals) {
          if (!self.global.reviews)
            self.global.reviews = {}
          self.global.reviews.totals = {"total5Star":0}
        }

        r.forEach(function (review) {
          // FIXERS
          if (!review.author) review.author = review.author_name;
          if (!review.author_name) review.author_name = review.author;
          if (!review.status) { review.status = "published" }
          if (review.rating) {
            review.review_rating = review.rating;
          }
          if (review.status == "published") {
            self.global.publishedReviews.push(review);
          }
          else if (review.status == "pending") {
            self.global.pendingReviews.push(review);
          }
        })

        if (self.global.pendingReviews.length > self.previousPending) {
          self.previousPending = self.global.pendingReviews.length;
         // sound2.play();
        }

        console.log("Reviews: ", self.global.publishedReviews, self.global.pendingReviews);
        self.calcTotals();
      });
     

    })
  }

  */

  updateReview(r: Review) {
    var self = this;

    var db = firebase.firestore();

    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection('reviews').doc(r.id).update(r) // Should only update changed fields
      .then(() => {
        console.log("reviews updated....", r);
      })
      .catch((error) => {
        console.error("Error updating review", error);
      });
  }
  /*
    UpdateReviews(r: Review) {
      var self = this;
      console.log("Updating Review ", r, self.global.myCompany.id);
  
      var db = firebase.firestore();
      var ref = db.collection("reviews").where("companyID", "==", self.global.myCompany.id);
  
      var rold: Review[] = [];
  
      ref.get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
  
            var rold = <Review[]>doc.data().reviews;
  
            console.log("Merging ", rold, r, querySnapshot.size);
  
            // Find the review by id??
            var f = rold.findIndex(function (post, index) {
              if (post.review_text == r.review_text && (post.author == r.author || post.author_name == r.author_name))
                return true;
            });
            if (f != -1) {
              rold[f] = r; // PUT THE UPDATED REVIEW HERE
  
              // Now save new reviews
              var ref2 = db.collection("reviews").doc(doc.id);
  
              ref2.update({
                reviews: rold
  
              })
                .then(() => {
                  console.log("reviews updated....", rold);
  
  
  
                })
                .catch((error) => {
                  console.error("Error savin reviews", error);
                });
            }
          })
        })
    }
  */

  calcTotals() {
    var self = this;
    if (!self.global.publishedReviews || self.global.publishedReviews.length == 0) return;

    // console.log ("Totoalss ",  self.global.publishedReviews.length,self.totals.totalReviews )

    if (!self.totals) self.totals = {};

    // if (!self.global.reviews) return;

    // Gather totals
    self.global.reviews.totals.reviewsLastDay = 0;
    self.global.reviews.totals.reviewsLastWeek = 0;
    self.global.reviews.totals.reviewsLastMonth = 0;
    self.global.reviews.totals.totalReviews = self.global.publishedReviews.length;
    self.global.reviews.totals.total5Star = 0;
    self.global.reviews.totals.total4Star = 0;
    self.global.reviews.totals.total3Star = 0;
    self.global.reviews.totals.total2Star = 0;
    self.global.reviews.totals.total1Star = 0;
    self.global.reviews.totals.avgRatingTrailing3 = 0;
    var daysAgo90 = 0;
    self.global.publishedReviews.forEach(function (item) {
      // set up Date string
      var time = new Date().getTime();
      time = +item.date;
      var myDate;
      if (!time) myDate = new Date(item.review_datetime_utc);
      else myDate = new Date(time)

      var daysAgo = (Date.now() - myDate) / (60000 * 60 * 24)
      var hoursAgo = (Date.now() - myDate) / (60000 * 60)
      var minAgo = (Date.now() - myDate) / (60000)

      if (hoursAgo < 25)
        self.global.reviews.totals.reviewsLastDay++;
      if (daysAgo < 8)
        self.global.reviews.totals.reviewsLastWeek++;
      if (daysAgo < 32)
        self.global.reviews.totals.reviewsLastMonth++;

      if (minAgo < 60) {
        item.dateStr = minAgo.toFixed(0) + " minutes ago"
      }
      else if (hoursAgo < 24) {
        item.dateStr = hoursAgo.toFixed(0) + " hours ago"
      }
      else if (daysAgo < 90) {
        item.dateStr = daysAgo.toFixed(0) + " days ago"
      }
      else if (daysAgo < 180)
        item.dateStr = "<6 months ago"
      else if (daysAgo < 360)
        item.dateStr = "this year"
      else
        item.dateStr = "more than 1 year ago"

      if (!item.rating) item.rating = item.review_rating;
      if (!item.review_rating) item.review_rating = item.rating;
      if (+item.rating == 5) self.global.reviews.totals.total5Star++;
      if (+item.rating == 4) self.global.reviews.totals.total4Star++;
      if (+item.rating == 3) self.global.reviews.totals.total3Star++;
      if (+item.rating == 2) self.global.reviews.totals.total2Star++;
      if (+item.rating == 1) self.global.reviews.totals.total1Star++;
      if (daysAgo < 90) {
        self.global.reviews.totals.avgRatingTrailing3 += +item.rating;
        daysAgo90++;
      }
      //  console.log("MY DATE: ", item.dateStr, myDate, Date.now() - myDate);

    })

    self.global.reviews.totals.avgRating = (self.global.reviews.totals.total5Star * 5) + (self.global.reviews.totals.total4Star * 4) + (self.global.reviews.totals.total3Star * 3) + (self.global.reviews.totals.total2Star * 2) + self.global.reviews.totals.total1Star
    self.global.reviews.totals.avgRating /= (self.global.reviews.totals.totalReviews);

    self.global.reviews.totals.avgRating = +self.global.reviews.totals.avgRating.toFixed(1);

    self.global.reviews.totals.avgRatingTrailing3 /= daysAgo90;
    self.global.reviews.totals.avgRatingTrailing3 = +self.global.reviews.totals.avgRatingTrailing3.toFixed(1);

    // Sort to put longer reviews on top
    self.global.publishedReviews.sort(function (a, b) {
      var alen = 0;
      var blen = 0;
      if (b.comment)
        blen = b.comment.length;
      if (a.comment)
        alen = a.comment.length;

      if (b.review_text)
        blen = b.review_text.length;
      if (a.review_text)
        alen = a.review_text.length;

      return blen - alen;

    });

    // Now Sort to put reviews with media on top
    self.global.publishedReviews.sort(function (a, b) {
      var am = 0;
      var bm = 0;
      if (a.review_photos && a.review_photos.length) am = 1;

      if (b.review_photos && b.review_photos.length) bm = 1;

      return bm - am;
    });

    // Sort with recents up front (recent = 6months)
    self.global.publishedReviews.sort(function (a, b) {

      var time = new Date().getTime();
      time = +a.date;
      var myDate;
      if (!time) myDate = new Date(a.review_datetime_utc);
      else myDate = new Date(time)
      var daysAgo = (Date.now() - myDate) / (60000 * 60 * 24)

      var time2 = new Date().getTime();
      time2 = +b.date;
      var myDate2;
      if (!time2) myDate2 = new Date(b.review_datetime_utc)
      else myDate2 = new Date(time2)
      var daysAgo2 = (Date.now() - myDate2) / (60000 * 60 * 24)

      if (daysAgo2 && daysAgo)
        return daysAgo - daysAgo2;
      else return 0;

    });
    //self.global.reviews.totals = self.totals;
    //  console.log("  Reviews", self.global.publishedReviews);

  };

  lastUpdated = Date.now();

  FetchGoogleInfo() {
    var self = this;

    var str = "https://local-business-data.p.rapidapi.com/search?query=";
    str += self.global.myCompany.name;
    str += " " + self.global.myCompany.city;
    str += "," + self.global.myCompany.state;
    str += "&limit=1&language=en&region=us"
    //'https://local-business-data.p.rapidapi.com/search?query=d'carlos%20ramona%2C%20ca&limit=1&language=en&region=us'

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'local-business-data.p.rapidapi.com'
      }
    };



    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        self.googleInfo = <GoogleBusinessInfo>response.data[0];
        console.log("Google: ", self.googleInfo);
        // Update Company record with Google Info
        self.UpdateGoogleInfo();

      })
      .catch(err => console.error(err));

  }
  UpdateGoogleInfo() {
    var self = this;
    var db = firebase.firestore();

    // ADD GET REVIEWS LINK
    //https://search.google.com/local/writereview?placeid=ChIJtbPg2Ycx3YARyi4V2W70xX8

    self.googleInfo.reviewLink = "https://search.google.com/local/writereview?placeid=" +
      self.googleInfo.place_id;




    var ref2 = db.collection("company").doc(this.global.myCompany.id);
    ref2.update({
      googleBusinessInfo: self.googleInfo
    })
      .then(() => {
        console.log("G-info added.");
      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }

  getGoogleReviews() {
    var self = this;

    if (this.global.myCompany.googleBusinessInfo)
      this.googleInfo = this.global.myCompany.googleBusinessInfo;

    var total = this.googleInfo.review_count;

    // TEMP LIMITER
    if (this.googleInfo.review_count > 100) total = 100;

    var maxPages = Math.ceil(total / 100);
    this.newReviews = [];
    this.fetchCount = 0;

    var now = Date.now();

    const MINTIME = 60000 * 60 * 2; // One whole day

    if (self.global.myCompany?.reviewSetup?.lastGoogleUpdate &&
      now - self.global.myCompany.reviewSetup.lastGoogleUpdate < MINTIME) {
      console.log("EARLY UPDATE ")
      return;  // MUST WAIT, SORRY
    }
    if (!self.global.myCompany.reviewSetup) {
      self.global.myCompany.reviewSetup = {
        videoReviews: false,
        announce: true,
        autoPublish: "4 hours",
        lastYelpUpdate: 0,
        lastGoogleUpdate: 0,
        lastTripAdvisorUpdate: 0,
        lastYelpCount: 0,
        lastGoogleCount: 0,
        lastTripAdvisorCount: 0,
      }
    }

    self.global.myCompany.reviewSetup.lastGoogleUpdate = now;
    self.updateSettings(self.global.myCompany.reviewSetup);

    var offset = 0;
    for (var n = 0; n < maxPages; n++) {
      // get up to 100 reviews at a time
      var limit = 100;
      if (total < 100) limit = total;
      console.log("Total, limit offset: ", total, limit, offset);
      total -= limit;
      this.FetchGoogleReviews(offset, limit);
      offset += limit;
      // Slow down to allow API resolves
      sleep(2000);

    }

  }

  updateSettings(reviewSetup: ReviewSetup) {
    var self = this;
    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id)

    docRef.update({ reviewSetup: reviewSetup }).then(() => {
      console.log("review settings updated");

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  FetchGoogleReviews(offset, limit) {
    var self = this;

    if (!self.global.publishedReviews) self.global.publishedReviews = [];
    var str = "https://local-business-data.p.rapidapi.com/business-reviews?business_id=";
    str += self.googleInfo.business_id;
    str += "&limit=" + limit + "&offset=" + offset + "&sort_by=newest&region=us&language=en"

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'local-business-data.p.rapidapi.com'
      }
    };
    self.fetchCount++;
    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        console.log(response)
        const r = <Review[]>response.data;
        console.log("Google ", response, r);
        r.forEach(function (item: Review) {
          //  const exists = self.global.publishedReviews.some(x => x.review_text === item.review_text)
          const exists = self.global.publishedReviews.find((f) => f.review_text == item.review_text)
          if (!exists) {
            item.source = "Google";
            self.newReviews.push(item);  // Add check for dupes
          }
          else
            console.log("Already have the review");

        })
        self.fetchCount--;
        // If Last Call
        if (!self.fetchCount) {
          console.log("GOT NEW Google REVIEWS ", self.newReviews);
          self.addReviews(self.newReviews);
        }



      })
      .catch(err => console.error(err));
  }

  FetchYelpID() {
    var self = this;

const options = {
	method: 'GET',
	headers: {
		'x-rapidapi-key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
		'x-rapidapi-host': 'red-flower-business-data.p.rapidapi.com'
	}
};

/*
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'yelp-reviews.p.rapidapi.com'
      }
    };
*/

    var str = "https://red-flower-business-data.p.rapidapi.com/business-search?query=";
    str += self.global.myCompany.name;
    str += "&location=" + self.global.myCompany.address1 + " " + self.global.myCompany.city;
    str += "," + self.global.myCompany.state;
    str += ",USA";
    str += '&yelp_domain=yelp.com'
    console.log("Yelp ID str: ", str);

    fetch(str, options)
      .then(response => response.json())
      .then(response => {

        self.yelpOverview = <YelpOverview>response.data[0];
        Logger("Yelp Overview", self.global.authuser.uid, self.global.myCompany.store, str, self.yelpOverview.name);

        self.global.myCompany.yelpOverview = <YelpOverview>response.data[0]

        //https://www.yelp.com/writeareview/biz/-K3XUCeCqFIuKfd_U21PJw
        self.global.myCompany.yelpOverview.addReview = "https://www.yelp.com/writeareview/biz/" + self.global.myCompany.yelpOverview.id;
        self.updateYelpOverview();
        console.log(self.yelpOverview);
      })
      .catch(err => console.error(err));

  }
  getYelpReviews() {
    var self = this;

    var now = Date.now();

    const MINTIME = 60000 * 60 * 2; // One whole day

    if (!self.global.myCompany.reviewSetup)
      self.global.myCompany.reviewSetup = {}

    if (self.global.myCompany.reviewSetup?.lastYelpUpdate &&
      now - self.global.myCompany.reviewSetup.lastYelpUpdate < MINTIME) {
      console.log("EARLY UPDATE ")
      return;  // MUST WAIT, SORRY
    }
    self.global.myCompany.reviewSetup.lastYelpUpdate = now;
    self.updateSettings(self.global.myCompany.reviewSetup);

    console.log("Yelp:", self.global.myCompany.yelpOverview.review_count)

    var total = self.global.myCompany.yelpOverview.review_count;

    // LIMITER
    if (total > 100) total = 100;

    var maxPages = Math.ceil(total / 100);



    // TESING 
    //     total = 80;
    //   maxPages = 1;



    this.newReviews = [];
    var page = 1;
    for (var n = 0; n < maxPages; n++) {
      // get up to 100 reviews at a time
      var pages = 10;
      if (total < 100) pages = Math.ceil(total / 10);
      console.log("Total, Page, pages: ", total, page, pages, maxPages);
      total -= pages * 10;
      self.FetchYelp(page, pages);
      page += pages;
      // Slow down to allow API resolves
      sleep(5000);

    }
  }

  fetchCount: number = 0;

  FetchYelp(page, pages) {// pages are 10 reviews per
    var self = this;

    // const url = 'https://red-flower-business-data.p.rapidapi.com/business-reviews?business_id=pearls-deluxe-burgers-san-francisco-3&page=1&page_size=10&num_pages=1&sort=BEST_MATCH&language=en';
    var str = "https://red-flower-business-data.p.rapidapi.com/business-reviews"
    str += "?business_id=" + self.global.myCompany.yelpOverview.id;
    str += "&page=" + page + "&language=en&num_pages=" + pages;
    str += "&sort=NEWEST";
    console.log("Yelp get reviews: ", str);

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'x-rapidapi-host': 'red-flower-business-data.p.rapidapi.com'
      }
    };
    self.fetchCount++;
    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        var r;
        if (response?.data?.reviews) {
          r = <Review[]>response.data.reviews;
        }
        console.log("Yelp ", response, r);
        if (r && r.length) {
          r.forEach(function (item: Review) {
            //    const exists = self.global.publishedReviews.some(x => x.review_text === item.review_text)
            const exists = self.global.publishedReviews.find((f) => f.review_text == item.review_text)

            // Now let's add any media from the review
            /*    if (r.review_photos) {
                  r.review_photos.forEach(function (media) {
                    self.mediaService.addYelpImage(media)
                  })
                }*/


            if (!exists) {
              item.source = "Yelp";
              self.newReviews.push(item);  // Add check for dupes
            }
            else
              console.log("Already have the review");

          })
        }
        self.fetchCount--;
        console.log("Fetch Count:", self.fetchCount)
        // If Last Call
        if (!self.fetchCount) {
          console.log("GOT NEW YELP REVIEWS ", self.newReviews);
          self.addYelpReviews(self.newReviews);
        }

      })
      .catch(err => console.error(err));

    /*
    try {
      const response = await fetch(url, options);
      const result = await response.text();
      console.log(result);
    } catch (error) {
      console.error(error);
    }
*/




    /*
    
        const options = {
          method: 'GET',
          headers: {
            'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
            'X-RapidAPI-Host': 'yelp-reviews.p.rapidapi.com'
          }
        };
    */


  }



  addReviews(reviews: Review[]) {
    var self = this;
    var db = firebase.firestore();
    var newReview: Review;
    //OLD

    reviews.forEach(function (r) {
      newReview = r;

      newReview.author = r.author_name;

      //   self.newReview.dateStr = self.prettyDateTime(sentAt);
      newReview.date = r.review_datetime_utc;
      let currentTime = new Date(r.review_datetime_utc);
      let currentTimeMillis = currentTime.getTime();
      newReview.timeStamp = currentTimeMillis;


      //    this.newReview.html = self.sanitizer.bypassSecurityTrustHtml(<string>self.reviewContent);
      newReview.review_rating = r.rating;
      newReview.review_text = r.review_text;
      newReview.status = 'published';
      newReview.source = 'Google';

      self.addReview(newReview);
      if (r.review_photos)
        r.review_photos.forEach(function (media) {
          self.mediaService.addYelpImage(media, "Google review")
        })

    })


  }




  addYelpReviews(reviews: Review[]) {
    var self = this;
    var db = firebase.firestore();
    var newReview: Review;
    //OLD

    reviews.forEach(function (r) {
      newReview = r;

      newReview.author = r.author_name;
      newReview.dateStr = r.review_datetime_utc;
      var d = Date.parse(r.review_datetime_utc);
      //   newReview.date = d.toString();

      let currentTime = new Date(r.review_datetime_utc);
      let currentTimeMillis = currentTime.getTime();
      newReview.timeStamp = currentTimeMillis

      //    this.newReview.html = self.sanitizer.bypassSecurityTrustHtml(<string>self.reviewContent);
      newReview.review_rating = r.review_rating;
      newReview.review_text = r.review_text;
      newReview.status = 'published';
      newReview.source = 'Yelp';

      self.addReview(newReview);

      // Now let's add any media from the review

      if (r.review_photos)
        r.review_photos.forEach(function (media) {
          self.mediaService.addYelpImage(media, "Yelp review")
        })



    })


  }
  addReview(r: Review) {
    var self = this;
    console.log("Saving New Review ", r, self.global.myCompany.id);

    var db = firebase.firestore();

    var reviewRef = db.collection("company").doc(self.global.myCompany.id).collection("reviews").doc();
    r.id = reviewRef.id;

    reviewRef.set(r)
      .then(() => {
        console.log("review Added!", r);
        //self.reviewAdded = true;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating product: ", error);
      });

  }

  confirmYelp() {
    // USER HAS CLAIMED THIS BUSINESS - check that it isn't claimed more than once, also beef
    // up the general validation

    var self = this;
    console.log("Confirming Yelp Overview ", self.global.myCompany.id);

    self.global.myCompany.yelpConfirmed = true;

    var db = firebase.firestore();

    var dbRef = db.collection("company").doc(self.global.myCompany.id)

    dbRef.update({ yelpConfirmed: self.global.myCompany.yelpConfirmed })
      .then(() => {
        console.log("yelp confirmed!");
        Logger("Yelp Confirmed", self.global.authuser.uid, self.global.myCompany.store, self.global.myCompany.yelpOverview.name);

        //self.reviewAdded = true;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error: ", error);
      });

  }

  updateYelpOverview() {

    var self = this;
    console.log("Updating Yelp Overview ", self.global.myCompany.id);

    var db = firebase.firestore();

    var dbRef = db.collection("company").doc(self.global.myCompany.id)

    dbRef.update({ yelpOverview: self.global.myCompany.yelpOverview })
      .then(() => {
        console.log("ryelp updated!");
        //self.reviewAdded = true;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error: ", error);
      });



  }


}

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}