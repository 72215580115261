/*
 * Media Manager: upload, create galleries, manage, edit images, 
 * Consumer: Media array User database
 * Store: Media stored in Company database
 * Limits:   Consumer: 10Gb
 *           Store: 20Gb
 * Images: Full-res, auto: thumbnail: (100,100) Small: (300,300) Med: (900,900)
 * Functions: Extract, Crop, Enhance (contrast etc.), Rotate
 * Tags: single click tagging
 * Aria/Accessability: All Media used/All Media: Auto-describe - manual edit
 * Reference options: Media[index] or Gallery
 * Create Gallery: auto-mosaic - manual editing
 */



import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { MediaService } from "./media-functions/media-functions"
import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, AppNode, Field, Form, Navigate, Gallery, Reviews, Review, GooglePhotos } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { User, Contact, MediaGroup, PexelVideo, PexelVideoFile, PexelUser, PexelPhoto, Media, SharedMediaFrom } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import lgZoom from 'lightgallery/plugins/zoom';
import { Product } from '../product-int'
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { Logger } from 'src/app/functions';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { FormService } from '../form.service';
import { Photos, Photo, PhotosWithTotalResults, createClient } from 'pexels';

const client = createClient('563492ad6f91700001000001654b43a8e2d04946ad43a2468e61c945');

export interface JOJImage {
  source: { name: string, page: string, title: string },
  thumbnail: { width: number, height: number, url: string },
  image: { width: number, height: number, url: string },
  dominantColor: string;
}

export interface MediaTags {
  clickAdd: boolean;
  tags: string[];

}


@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  styleUrls: ['./media.component.css', '../../common.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MediaComponent implements OnInit {
  @Input() userId: string = "";
  @Input() currentMedia: Media = null;
  @Input() companyId: string = null;
  @Input() editProduct: Product = null;
  @Input() storeNum: string = null; // If present filter results
  @Input() editNode: AppNode;
  @Input() appId: string;
  @Input() mediaSource: string;
  @Output() eventEmitter = new EventEmitter<any>();  // optional

  destination = 'user';

  //private lightGallery!: LightGallery;
  private needRefresh = false;

  imagesFound: JOJImage[];

  mediaList: Media[];
  searchStr; string = "";
  mediaWidth: string = "20vw";
  mediaHeight: string = "20vh";

  selectionMode: string = "clickPick";

  mediaEditing: Media;
  showMediaEdit: boolean = false;
  everyTag: string[];

  //galleries: Gallery[];
  // showNewGallery: boolean = false;
  // galleryName: string = "";
  viewSettings: boolean = false;
  viewManageMedia: boolean = false;
  googlePhotos: GooglePhotos[];
  //searchImages: Media[];

  userMedia: boolean = false;
  editMode: string;
  visible: boolean = false;
  //imagesFound: ImageData[];
  foundVideos: Media[];
  viewVideoSearch: boolean = false;
  imgSearch: string;
  myMedia: Media[];
  email: string;

  showResults: boolean = false;

  tabSelected: boolean[];

  accessModes: string[] = ["public", "private", "shared"]

  boo() { console.log("boo") }

  saveGallery(g: Gallery) {
    this.updateGalleryMedia(g);


  }

  /*
    settings = {
      counter: false,
      plugins: [lgZoom, lgVideo]
    };
    */
  items = [];



  constructor(private route: ActivatedRoute, public global: Globals,
    public nav: NavService, private sanitizer: DomSanitizer,
    public registerService: RegisterService, private formService: FormService,
    private http: HttpClient, private mediaService: MediaService) {


  }


  ngOnInit(): void {

    console.log("Pexel: ", client)



    if (this.mediaSource) {  // USE MODULE FOR OTHER MEDIA SOURCES
      this.setMediaWidth("20vw")
      if (this.mediaSource == 'reviewImages') {
        this.setMediaWidth("20vw")

      }

    }
    else {

      this.getAllfiles();

      this.tabSelected = [];

      console.log("APPID: ", this.appId)


      //  this.galleries = [];

      if (this.userId) {
        console.log("User Media ", this.userId);
        this.getUserMedia();
        this.getUserSharedMedia();
      }
      else {
        this.destination = "store"
        this.mediaService.getMedia();
        this.mediaService.getMediaGroups();
        //  this.getGalleries(this.global.myCompany.id, "");
      }

      console.log(this.editProduct)

      // not needed as array  if (this.editProduct) this.loadMedia();

      //  let timerId = setInterval(() => this.animate(), 1000); // Update every 5 seconds if not already done,

      // this.getMediaOld();  // ONE TIME FIXER

      // this.moveMediaToDocuments();

    }
  }
  outputEventAI(form: Form) {
    console.log ("Got settings ", form)

  }

  imageObjectEvent(field: Field) {
    var self = this;

    console.log ("Image settings object event: ", field)
    if (field.title == 'autoTag') {
      if (!self.currentVideo.autoTags) // only do once
        self.mediaService.autoTag(self.currentVideo.url, self.tagsCallback, self)
    }

  }
  outputEventShare(event) {

  }
  getMyMediaLink() {
    
  }

  tagsCallback (self, results) {
    console.log ("Got Tags ", results)
    self.currentVideo.autoTags = [];
    

    results.result.tags.forEach(function(item){
      self.currentVideo.autoTags.push(item.tag.en)
    })
    self.mediaService.updateMediaTags(self.currentVideo)


  }

  outputEvent(field: Field) {
    var self = this;

    console.log("Media settings update ", field)

    //  self.formService.napkinFieldsToObject(form, self.global.myCompany)


    switch (field.title.toLowerCase()) {
      case "usemedia":
        if (self.editNode)
          self.selectNodeVideo(self.currentVideo, self.editNode)
        //  self.global.myCompany.reviewSetup.announce = field.trueFalse;
        // self.updateSettings (self.global.myCompany.reviewSetup);
        break;
      case "tags":
        self.currentVideo.tags = field.selected;
        self.mediaService.updateMediaTags(self.currentVideo)
        break;
      case "access":
        self.currentVideo.access = field.selected[0];
        self.mediaService.updateAccess(self.currentVideo)
        break;
      case "altdesc":
        self.currentVideo.altDesc = field.binding;
        self.mediaService.updateAltDesc(self.currentVideo)
        break;
    }



  }

  getMyUrl(m: Media) {
    var url = m.url;

    if (m.thumbnailUrl) {
      url = m.thumbnailUrl
      if (url.includes("firebasthumb")) { // Needs fixing
        var fn = m.url.lastIndexOf("review-images");
        if (fn == -1)
          fn = m.url.lastIndexOf("media");

        var thumb = "thumb%2fthumb_" + m.url.slice(fn + 16);
        var thumbStr = m.url.slice(0, fn + 16) + thumb;
        url = thumbStr;
      }
    }
    else if (m.smallUrl)
      url = m.smallUrl;
    else if (m.medUrl)
      url = m.medUrl;
    //  console.log("URL: ", url)
    return url;
  }

  animate() {
    var self = this;
    if (self.mediaEditing) {
      self.xPos += 1;
      // console.log ("ani: ", self.xPos)
      if (self.xPos > 400)
        self.xPos = 0;

      var str: string = this.xPos.toString();
      console.log("X ", this.xPos);

      document.getElementById("bigImage").style.objectPosition = str + "px," + str + "px";
    }
  }

  fullScreenMode: boolean = false;

  fixCount = 0;
  fixed = 0;

  setFullScreen(m: Media) {
    var self = this;
    /*
        if (self.selectionMode == 'clickPick') {
          self.addPhotoToGroups(v);
          return;
        }
    */
    if (self.mediaTags.clickAdd) {
      self.mediaTags.tags.forEach(function (tag) {
        var updated = false;
        if (m.tags && !m.tags.includes(tag)) {
          m.tags.push(tag);
          updated = true;
        }
        if (updated)
          self.mediaService.updateMediaTags(m)
      })
      console.log("TAGS: ", m.tags, self.mediaTags.tags)
      return;
    }

    this.fullScreenMode = !this.fullScreenMode;
    this.currentVideo = m;
    console.log("Full screen ", m)

    /* 
    Code below is a fixer to add width/height to images


    self.fixingMedia = true;
   this.fixCount = 0;
    var skipCount = 0;
    this.fixed = 0;

    this.media.forEach(function (m, index) {

      if ((!m.width) && m.fileType.startsWith("image")) {
        let myImg = <HTMLImageElement>document.querySelector("#image" + index.toString());
        //      console.log ("M: ",myImg, m);
        let realWidth = myImg.naturalWidth;
        let realHeight = myImg.naturalHeight;
        //     console.log("Media Size ", realWidth ,realHeight);
        if (realWidth && realHeight && self.fixCount < 100) {
          m.width = realWidth;
          m.height = realHeight;
          self.updateMediaSize(m)
          self.fixCount++;

        }
      }else skipCount++;

    })
    console.log("Skipped: ", skipCount)
    */

  }


  updateDesc(m: Media, node: AppNode, i) {
    var self = this;
    var db = firebase.firestore();
    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);


    docRef2.update({
      description: m.description
    })
      .then(() => {
        console.log("desc");

      }).catch((error) => { console.error("Error: ", error); });

    // NOW UPDATE THE APP NODE
    var ref2 = db.collection("apps").doc(self.appId)
      .collection('nodes').doc(node.id)
    // Find correct node
    node.media[i].description = m.description;
    ref2.update({

      media: node.media,

    })
      .then(() => {
        console.log("New Media added.",);

      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }

  updateMediaSize(m: Media) {
    var self = this;
    var db = firebase.firestore();

    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);

    // console.log("Remove media: ", docRef2);
    docRef2.update({
      width: m.width,
      height: m.height
    })
      .then(() => {
        console.log("added size ", m.id)
        self.fixed++;
        if (self.fixed == self.fixCount) self.fixingMedia = false; // ALL Done
      }).catch((error) => { console.error("Error: ", error); });

  }


  currentVideo: Media;

  getFullWidth() {
    var style;

    style = "width: " + this.global.getScreenWidth + 'px;'

    if (1) {  // I'm selected yay!
      style += " background-color:#d5ffb5; color: #000000; font-size: 130%;";
    }
    else
      style += " background-color: #023de0; color: #f4f8f4; font-size: 130%;";

    //console.log(style)
    return style;
  }

  getStyle(i) {
    var style;

    // console.log("media style",i, this.tabSelected[i])
    if (this?.global?.getScreenWidth < 600 || this.tabSelected[i])
      style = "width: " + this.global.getScreenWidth + 'px;'


    else if (this.global.getScreenWidth >= 600)
      style = "width: 600px;"

    if (this.tabSelected[i]) {  // I'm selected yay!
      style += " background-color:#d5ffb5; color: #000000; font-size: 130%;";
    }
    else
      style += " background-color: #023de0; color: #f4f8f4; font-size: 130%;";

    //console.log(style)
    return style;
  }

  accessChanged(e, val) {
    console.log(e, val)
  }

  addImageToGame(item, type) {
    var self = this;
    console.log("Adding this to the game ", item)
    self.eventEmitter.emit(item)
  }

  ChooseVideo(media: Media) {
    var self = this;

    if (media.added) return;

    media.added = true;

    self.addMedia(media);


  }
  /*
    addGalleryToNode(g: Gallery) {
      var self = this;
  
  
  
      //   if (!g.createdAt) m.createdAt = Date.now();
  
      console.log("Adding gallery to node: ", this.appId, this.editNode.id, g, this.editNode);
  
  
      var db = firebase.firestore();
  
      var ref2 = db.collection("company").doc(self.global.myCompany.id)
        .collection(this.appId).doc(this.editNode.id)
  
      if (!this.editNode.galleries) this.editNode.galleries = [];
      self.editNode.galleries.push(g.id);
  
      ref2.update({
  
        galleries: self.editNode.galleries     //firebase.firestore.FieldValue.arrayUnion(...this.editNode.galleries) // Spread operator
  
      })
        .then(() => {
          console.log("New gallery added.",);
  
        })
        .catch((error) => {
          console.error("Error in media", error);
        });
  
    }
    */
  addPhotoToGroups(m: Media) {
    var self = this;
    var db = firebase.firestore();

    console.log("GROUPADD", m)

    self.global.mediaGroups.forEach(function (group) {
      if (!m.mediaGroups) m.mediaGroups = [];

      if (!m.mediaGroups.includes(group.id)) {
        m.mediaGroups.push(group.id)
      }
    })
    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);

    docRef2.update({
      mediaGroups: m.mediaGroups
    })
      .then(() => {
        console.log("added groups ", m.id)

      }).catch((error) => { console.error("Error: ", error); });


  }




  selectNodeVideo(m: Media, node: AppNode) {
    var self = this;
    console.log("Adding to node: ", self.appId, m, node);

    if (!self.editNode) return;

    if (!m.createdAt) m.createdAt = Date.now();
    m.userId = self.global.authuser.uid;

    var db = firebase.firestore();

    var ref2 = db.collection("apps").doc(self.appId)
      .collection('nodes').doc(node.id)

    if (!node.media) node.media = [];
    node.media.push(m);
    ref2.update({

      media: firebase.firestore.FieldValue.arrayUnion(...node.media) // Spread operator

    })
      .then(() => {
        console.log("New Media added.",);

      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }


  selectVideo(m: Media) {
    var self = this;
    console.log("Adding to product: ", m, this.editProduct);

    if (!this.editProduct) return;

    if (!m.createdAt) m.createdAt = Date.now();
    m.userId = self.global.authuser.uid;

    var db = firebase.firestore();

    //   var ref2 = db.collection("products").doc(this.editProduct.docID);
    var ref2 = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(this.editProduct.docID);


    ref2.update({
      media: firebase.firestore.FieldValue.arrayUnion(m)
    })
      .then(() => {
        console.log("New Media added.",);

      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }


  addMedia(newMedia: Media) {
    var db = firebase.firestore();
    var self = this;

    // To avoid duplicates add Query for PexelVideo.id before saving - Hack

    if (self.companyId) {
      newMedia.companyId = self.companyId;  // Assign Media to Store
      var mediaRef = db.collection("company").doc(self.global.myCompany.id).collection("media").doc();
      newMedia.id = mediaRef.id;
    }
    else if (self.userId) {
      newMedia.userId = this.userId;
      console.log("Add Media to user?");  //reviews
    }
    newMedia.uploadId = self.global.authuser.uid;
    newMedia.createdAt = Date.now();

    console.log("ref id ", mediaRef, newMedia);

    if (mediaRef)
      mediaRef.set(newMedia)
        .then(() => {
          console.log("Media Added!", newMedia);

          if (!self.myMedia) self.myMedia = [];
          //     if (self.editProduct) self.myMedia.push(newMedia);
          console.log("Excellent Choice! ", newMedia);
          self.visible = false;
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating product: ", error);
        });


    // Save a copy with product.
    if (self.editProduct) { // Only if editing a product
      if (!self.editProduct.media) self.editProduct.media = [];
      self.editProduct.media.push(newMedia);
      //  var mediaProdRef = db.collection("products").doc(self.editProduct.docID);
      var mediaProdRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(this.editProduct.docID);


      newMedia.id = mediaRef.id;
      mediaProdRef.update({ media: self.editProduct.media });

    }


  }

  SetEditMode(mode: string) {
    this.editMode = mode;
    if (this.editMode == "find-video")
      this.viewVideoSearch = true;
    this.visible = true;
    console.log(this.editMode);
  }
  /*
    onInit = (detail): void => {
      this.lightGallery = detail.instance;
    };
  
    changedOptions() {
  
    }
  */

  FindPexelPhoto(search: string) {
    var self = this;


    const query = search;
    if (!self.foundVideos) self.foundVideos = [];


    client.photos.search({ query, per_page: 80 }).then(photos => {
      console.log("DOGS: ", photos)
      const a = <any>photos;

      var r = <Photo[]>a.photos;
      console.log(r, a);

      r.forEach(function (item: Photo) {

        var newMedia: Media = {}

        newMedia.fileType = "image/png"
        newMedia.url = item.src.medium;
        newMedia.pexelPhoto = item;

        if (self.global?.media) {
          const exists = self.global.media.some(function (post) {
            if (post.url == newMedia.url)
              return true;
          });
          if (exists) {
            newMedia.added = true;
            console.log("Found...")
          }
        }

        self.foundVideos.push(newMedia);
      })

    });

  }


  FindVideo(search: string) {
    var self = this;
    self.foundVideos = [];



    const query = search;
    if (!self.foundVideos) self.foundVideos = [];

    client.videos.search({ query, per_page: 80 }).then(videos => {
      console.log("DOGS: ", videos)
      const a = <any>videos;

      var r = <PexelVideo[]>a.videos;
      console.log(r, a);

      r.forEach(function (item: PexelVideo) {

        var smallest: number;
        var smallIndex: number;
        item.video_files.forEach(function (v: PexelVideoFile, i) {
          if (!smallest || (v.height * v.width) < smallest) {
            smallest = v.height * v.width;
            smallIndex = i;
          }
        })

        var newMedia: Media = {}


        newMedia.fileType = item.video_files[smallIndex].file_type;
        newMedia.url = item.video_files[smallIndex].link;
        newMedia.pexelVideo = item;

        const exists = self.global.media.some(function (post) {
          if (post.url == newMedia.url)
            return true;
        });
        if (exists) {
          newMedia.added = true;
          console.log("Found...")
        }



        self.foundVideos.push(newMedia);
      })
      console.log("Videos: ", r, self.foundVideos);
      self.showResults = true;

    })
      .catch(err => console.error(err));



    /*    
    
        const options = {
          method: 'GET',
          headers: {
            Authorization: '563492ad6f91700001000001654b43a8e2d04946ad43a2468e61c945',
            'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
            'X-RapidAPI-Host': 'PexelsdimasV1.p.rapidapi.com'
          }
        };
        var search: string = 'https://pexelsdimasv1.p.rapidapi.com/videos/search?query=' + search + '&per_page=25&page=1';
        console.log("V Search: ", search);
        fetch(search, options)
          .then(response => response.json())
          .then(response => {
            console.log(response);
            var r = <PexelVideo[]>response.videos;
    
            r.forEach(function (item: PexelVideo) {
    
              var smallest: number;
              var smallIndex: number;
              item.video_files.forEach(function (v: PexelVideoFile, i) {
                if (!smallest || (v.height * v.width) < smallest) {
                  smallest = v.height * v.width;
                  smallIndex = i;
                }
              })
    
              var newMedia: Media = {}
    
    
              newMedia.fileType = item.video_files[smallIndex].file_type;
              newMedia.url = item.video_files[smallIndex].link;
              newMedia.pexelVideo = item;
    
              const exists = self.global.media.some(function (post) {
                if (post.url == newMedia.url)
                  return true;
              });
              if (exists) {
                newMedia.added = true;
                console.log("Found...")
              }
    
    
    
              self.foundVideos.push(newMedia);
            })
            console.log("Videos: ", r, self.foundVideos);
            self.showResults = true;
    
          })
          .catch(err => console.error(err));
    
    */

  }

  getImageURL(m: Media) {
    if (m.thumbnailUrl) return m.thumbnailUrl;
  }

  removeNodeMedia($event, m: Media, i: number) {
    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("apps")
      .doc(self.appId)
      .collection('nodes')
      .doc(this.editNode.id);

    self.editNode.media.splice(i, 1);

    docRef.update({ media: self.editNode.media })
      .then(() => { }).catch((error) => { console.error("Error: ", error); });

  }

  removeProductMedia($event, m: Media, i: number) {
    var self = this;

    var db = firebase.firestore();

    //  var docRef = db.collection("products").doc(self.editProduct.docID)
    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(this.editProduct.docID);

    self.editProduct.media.splice(i, 1);

    docRef.update({ media: self.editProduct.media })
      .then(() => { }).catch((error) => { console.error("Error: ", error); });

  }

  removeMedia($event, m: Media) {
    $event.preventDefault();
    $event.stopPropagation();

    console.log("Media ", m);
    //  alert("Are you sure you want to permenantly remove this?")

    var self = this;

    var db = firebase.firestore();
    m.removed = true;
    m.updatedAt = Date.now();
    m.updatedBy = self.global.authuser.uid;

    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);

    console.log("Remove media: ", docRef2);
    docRef2.update(m)
      .then(() => { console.log("Deleted ", m.id) }).catch((error) => { console.error("Error: ", error); });

    this.fullScreenMode = false;
    this.currentVideo = null;

    // return;
    /*
    
        m.status = "deleted";
    
        if (m.source == "Search") {
          const index = this.searchImages.findIndex(x => x.thumbnailUrl === m.thumbnailUrl)
          this.searchImages[index].status = "deleted";
          this.removeMediafromDB(m, m.source);
        }
        if (m.source == "Google") {
          const index = this.googlePhotos.findIndex(x => x.photo_url === m.thumbnailUrl)
          this.googlePhotos[index].status = "deleted";
          //  this.removeMediafromDB(m, m.source);
        }
        else {
          //      this.removeMediafromDB(m);
        }
    */
  }


  removeItem($event, item, g, i) {
    $event.preventDefault();
    $event.stopPropagation();

    console.log("remove gallery item: ", item);
    g.media.splice(i, 1);

    this.updateGalleryMedia(g);

    // this.galleryMediaEdit(g, i);
  }

  addItem() {
    // this.dashboard.push({});
  }

  addImage = (url, thumb) => {
    this.items = [
      ...this.items,
      {
        id: '4',
        size: '1400-933',
        src:
          url,
        thumb:
          thumb,
        subHtml: `<div class="lightGallery-captions">
            <h4>Photo by</h4>
            <p>Published on January 6, 2021</p>
        </div>`
      }
    ];
    this.needRefresh = true;
  };

  addImages = (g: Gallery) => {
    var self = this;

    if (!self.items) self.items = [];
    if (g.media)
      g.media.forEach(function (item) {

        var aspect = item.width / item.height;
        console.log("Aspect: ", aspect);

        var cols = 4;
        var rows = 4;
        if (aspect > 1)
          cols += 2;
        if (aspect < 1)
          rows += 2;
        /*
                g.gridItem = [...self.dashboard, {
                  cols: cols, rows: rows, y: 0, x: 0, thumbnail: item.thumbnailUrl,
                  url: item.thumbnailUrl
        
                }]
                */

        self.items = [
          ...self.items,
          {
            id: item.name,
            size: '1400-933',
            src:
              item.thumbnailUrl,
            thumb:
              item.thumbnailUrl,
            subHtml: `<div class="lightGallery-captions">
              <h4>Photo by</h4>
              <p>Published on January 6, 2021</p>
          </div>`
          }
        ];


      })
    console.log("Images ", this.items)
    self.needRefresh = true;
  };

  whatsPlaying: string[];

  isPlaying(url) {

    if (!this.whatsPlaying) return false;

    return this.whatsPlaying.includes(url)
  }


  playMe(url) {
    var self = this;

    if (!self.whatsPlaying) self.whatsPlaying = [];
    // Let's use this is a toggler
    const index = self.whatsPlaying.indexOf(url)
    if (index != -1) {
      self.whatsPlaying.splice(index, 1)
    }
    else self.whatsPlaying.push(url);


    console.log("REMOVE THE PLAY BUTTON")

  }
  getMediaGroupWidth(g: MediaGroup) {
    if (!g.mediaHeight) {
      return this.mediaWidth;
    }
    return g.mediaWidth;
  }

  getMediaWidth() {

    return this.mediaWidth;
  }
  setMediaWidth(w: string) {
    var self = this;

    const regex = new RegExp('^[0-9]$');

    self.mediaWidth = w;
    const x = w.indexOf('w')


    self.mediaHeight = w.substring(0, x - 1) + 'vh'
  }
  getMediaHeight() {
    var t = "height:" + this.mediaHeight;

    // console.log ("Height: ", t)
    return t;
  }

  mediaTags: MediaTags = { clickAdd: false, tags: [] }

  addTagsOutput(field: Field) {
    console.log(field)

    var self = this;

    console.log("SETTINGS Output ", field)
    switch (field.title) {
      case "Click add":
        self.mediaTags.clickAdd = field.trueFalse;
        break;
      case "Tags":
        self.mediaTags.tags = field.selected;
        break;

      case "Click remove":

        break;

    }



  }

  addTag(t) {
    var found = false;

    if (this.mediaEditing.tags)
      found = this.mediaEditing.tags.includes(t);
    if (!found) {
      if (!this.mediaEditing.tags) this.mediaEditing.tags = [];
      this.mediaEditing.tags.push(t);
    }
  }
  addSearchTag(t) {
    if (!this.searchStr) this.searchStr = "";
    if (this.searchStr.length) this.searchStr += ",";
    this.searchStr += t;
  }
  clearSearch() {
    this.searchStr = "";
  }

  playVideo(i: any) {

    console.log(i);

    let vid = document.getElementById(i);
    this.playMe(vid)
  }


  checkGallery(m: Media, g: Gallery): boolean {
    return false;
    // Check if media is already in gallery

    if (!g.media) return false;


    //  var mediaIndex = g.media.findIndex(item => item.thumbnailUrl === m.thumbnailUrl);
    const exists = g.media.some(x => x.thumbnailUrl === m.thumbnailUrl)
    if (exists) {
      //  console.log("ingal: ",m,g);
      return true;
    }



    return false;

  }

  xPos: number = 5;

  getPos() {
    if (!this.xPos) return "0%,0%";

    var str: string = this.xPos.toString();
    console.log("X ", this.xPos);
    return str + "px, 0px";



  }

  checkGroup(m: Media, groupId: string): boolean {
    if (!m.mediaGroups) return false;

    if (m.mediaGroups.includes(groupId)) return true;

    return false;
  }

  checkSearch(m: Media): boolean {


    // sleep(1);
    if (m?.status == "deleted") return false;
    if (!this.searchStr) return true;

    var found = false;

    if (m.tags) {
      if (this.searchStr) {
        var words = this.searchStr.split(",");
        // console.log("Search tags: ", words);
        words.forEach(function (word: string, index: number) {
          //    console.log("C ", word, m.tags);
          if (m.tags.includes(word)) {
            //   console.log("Found ");
            found = true;
          }

        })
      }
    }
    return found;
  }

  newUpload(event: any) {
    var self = this;
    var newMedia: Media = {};

    var destination = 'Store';
    if (self.userId) destination = 'User';
    self.mediaService.newUpload(newMedia, event, destination)
  }

  getUserSharedMedia() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;

    if (self.userId) {
      db.collection('users')
        .doc(self.userId)
        .collection('sharedMedia')
        .onSnapshot((querySnapshot) => {
          index = 0;
          self.global.authuser.sharedMediaFrom = [];

          querySnapshot.forEach((doc) => {
            const m = <SharedMediaFrom>doc.data();
            self.global.authuser.sharedMediaFrom.push(m);

            // get the media now
            self.getSharedMedia(doc.id, index);
            index++;

          })

        })
      // Here we have all shared froms and can populate that media when called upon
      console.log("Shared ", self.userId, self.global.authuser.sharedMediaFrom)
    }


  }

  getSharedMedia(id, index) {
    var self = this;
    var db = firebase.firestore();

    db.collection('users')
      .doc(self.userId)
      .collection('sharedMedia')
      .doc(id)
      .collection("media").get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc2) => {

          if (!self.global.authuser.sharedMediaFrom[index].media) self.global.authuser.sharedMediaFrom[index].media = [];
          self.global.authuser.sharedMediaFrom[index].media.push(<Media>doc2.data())
          console.log("Happy ", index, doc2.data(), self.global.authuser.sharedMediaFrom[index]);
        })


      })
  }

  changeAudio(id) {

    var audio = <HTMLVideoElement>document.getElementById(id);
    audio.muted = !audio.muted;

  }

  audioLabel(id) {
    var audio = <HTMLVideoElement>document.getElementById(id);
    if (audio && audio.muted) return "Play Audio"
    else return "Mute Audio"

  }

  getUserMedia() {


    //  this.mediaList = this.global.authuser.media;
    console.log("User Media: ", this.mediaList);
    this.userMedia = true;

    var self = this;
    var db = firebase.firestore();

    if (self.userId) {

      db.collection('users')
        .doc(self.userId)
        .collection('media')
        .orderBy('updatedAt', 'desc')
        .onSnapshot((querySnapshot) => {

          //    const q = query(collection(db, "company"), where("companyId", "==", self.companyId));
          //    const unsubscribe = onSnapshot(q, (querySnapshot) => {
          self.global.media = [];

          querySnapshot.forEach((doc) => {
            const m = <Media>doc.data();

            if (!m.removed || m.removed != true) {
              if (!self.storeNum || self.storeNum == m.store) // Store filter???
                if (!m.id) m.id = doc.id; //fixer
              if (!m.access) m.access = "private"; // Auto upgraded by App visibility

              self.global.media.push(m);
            }

          })

          console.log("Current Media: ", self.global.media);

        });

    }
  }


  shareThisMedia(m: Media) {
    var self = this;

    // level 0 scaffolding this.email = "test3@suncraftcellars.com"

    if (!ValidateEmail(this.email)) {
      alert("email required")
      return;
    }
    console.log("Share media ", m)

    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    var foundUser = false;
    if (!this.email || this.email.length < 5) return;

    // self.inputCreds = false;
    db.collection("users").where("email", "==", self.email)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          var tUser = <User>querySnapshot.docs[0].data();
          foundUser = true;
          // Found  user so lets just add Quote to them with today's date.
          var today = Date.now();
          /*
          self.shareQuote.prettyDate= this.getPrettyDate(today);
          self.shareQuote.date = today;
          self.shareQuote.sharedBy = self.global.authuser.firstName+" " +self.global.authuser.lastName;
          */

          // User or Store
          var sourceId = ""
          if (self.userId) {
            sourceId = self.global.authuser.uid;
          }
          else {
            sourceId = self.global.myCompany.id;
          }

          var userRef = querySnapshot.docs[0].ref
          userRef.collection("sharedMedia").where("id", "==", sourceId)
            .get().then((querySnapshot) => {
              if (querySnapshot.empty) { // First time share so set up document
                var newShareSource: SharedMediaFrom = {
                  id: sourceId,
                  type: "store",

                }
                if (self.userId) {
                  newShareSource.type = "user";
                  newShareSource.name = self.global.authuser.firstName + " " + self.global.authuser.lastName;
                }
                else {
                  newShareSource.name = self.global.myCompany.name;

                }
                console.log("Sharing: ", newShareSource);
                const ref2 = userRef.collection("sharedMedia").doc()
                newShareSource.myId = ref2.id;
                ref2.set(newShareSource)
                // Now we can add the Media
                ref2.collection("media").doc().set(m)

              }
              else {// Just add media to this collection
                // Now we can add the Media
                querySnapshot.docs[0].ref
                  .collection("media").doc().set(m)

                console.log("Shared: ", m)
              }
            })


          // check for sharedMedia collection



        }

      })

  }





  fixingMedia: boolean = false;

  fixMedia() {
    var self = this;
    var db = firebase.firestore();

    return;
  }


  moveMediaToDocuments() {
    var self = this;
    var db = firebase.firestore();

    return; // one time fix

    //Move Array to sub-collection

    var docRef = db.collection("media").
      where("companyId", "==", self.global.myCompany.id)
    docRef.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Media>doc.data();

          console.log("Store Media", data);
          // Create sub-collection and copy data here:
          var mediaRef = db.collection("company").doc(self.global.myCompany.id).collection("media").doc();
          mediaRef.set(data)
            .then(() => {
              console.log("Media successfully updated!", data);

            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating media: ", error);
            });


        })




      })


  }


  getMediaOld() {
    var self = this;
    var db = firebase.firestore();
    // var ref2 = db.collection("company").doc();

    //    const unsub = onSnapshot(doc(db, "products", this.global.myCompany.id), (doc) => {

    const q = query(collection(db, "products"), where("companyID", "==", this.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        var newMedia: Media = {}
        var data = <Product>doc.data();
        if (data.image1) {
          newMedia = {
            "url": data.image1,
            "fileType": "image/png"
          }

          //  self.addMedia(newMedia);
          console.log("adding ", newMedia);
        }

      })







      /*    var media = <Media[]>doc.data().media;
          media.forEach(function(item){
    
    
    
            if (item.fileType == 'image/png') {
             newMedia = {
              "url": item.url,
              "fileType": item.fileType,
              }
              if (item.pexelPhoto) newMedia.pexelPhoto = item.pexelPhoto;
            }
            if (item.fileType == 'video/mp4') {
              newMedia = {
              
               "fileType": item.fileType,
    
    
               }
               if (item.height) newMedia.height = item.height;
               if (item.width) newMedia.width = item.width;
    
               if (item.link)  newMedia.url =  item.link;
               if (item.url)  newMedia.url =  item.url;
    
               if (item.pexelVideo) newMedia.pexelVideo = item.pexelVideo;
             }
    
            if (newMedia)
              self.addMedia(newMedia);
            else console.log ("Missed ", item);
    
          })
          */

      //    self.searchImages = <Media[]>doc.data().searchImages;

      /*    if (self.searchImages) {
            self.searchImages.forEach(function (item) {
              const newMedia: Media = {
                "thumbnailUrl": item.thumbnailUrl,
                "url": item.thumbnailUrl,
                "fileType": item.fileType,
                "source": "Search",
    
              }
              if (item.status != "deleted")
                self.mediaList.push(newMedia);
    
            })
    
            //  self.mediaList = this.media.concat(...self.searchImages);
          }
    */

      /*
            self.googlePhotos = <GooglePhotos[]>doc.data().googlePhotos;
            if (self.googlePhotos) {
              self.googlePhotos.forEach(function (item) {
                newMedia = {
                  "thumbnailUrl": item.photo_url,
                  "url": item.photo_url,
                  "fileType": item.type,
                  "source": "Google",
      
                }
                if (item.type == "photo") {
                  newMedia.fileType = "image/png"
                }
               
              if (newMedia)
                self.addMedia(newMedia);
              else console.log ("Missed ", item);
      
              })
            }
      */

      //     media = media.concat(doc.data().googlePhotos);





      // console.log("Current Media: ", self.mediaList);
    });

    return;

    /*
   
    const unsubscribe = onSnapshot(ref2, (querySnapshot) => {
      if (querySnapshot.get docChanges() ) { console.log("Changes: ",querySnapshot.docChanges()) }
      
      querySnapshot.docChanges().forEach((item) => {
        if (item.type=="added") {
          if (!self.galleries) self.galleries = [];
          var r = <Gallery>item.doc.data();
          
          console.log("add: ",r);
          self.galleries.push(r);
    //      self.addImages (r);
        }
        else if (item.type=="modified") {
          var r = <Gallery>item.doc.data();
          console.log("Modified: ",r);
          if (item.newIndex == item.oldIndex) {
            self.galleries[item.newIndex].media = r.media;
          }
   
        }
        else if (item.type=="removed") {
          var r = <Gallery>item.doc.data();
          console.log("removed: ",r);
          if (item.newIndex == item.oldIndex) {
         //   self.galleries[item.newIndex].media = r.media;
          }
        }
      });
      console.log("Galleries: ", self.galleries);
   
    });
    */

  }
  /*
  getGalleries() {
   
    var self = this;
    var db = firebase.firestore();
   
   
    const q = query(collection(db, "company"), where("companyID", "==", this.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
   
    const q = query(collection(db, "galleries"), where("companyID", "==", self.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docChanges()) { console.log("Changes: ", querySnapshot.docChanges()) }
   
      querySnapshot.docChanges().forEach((item) => {
        if (item.type == "added") {
          if (!self.galleries) self.galleries = [];
          var r = <Gallery>item.doc.data();
   
          console.log("add: ", r);
          self.galleries.push(r);
          //  self.addImages (r);
        }
        else if (item.type == "modified") {
          var r = <Gallery>item.doc.data();
          console.log("Modified: ", r);
          if (item.newIndex == item.oldIndex) {
            self.galleries[item.newIndex].media = r.media;
          }
   
        }
        else if (item.type == "removed") {
          var r = <Gallery>item.doc.data();
          console.log("removed: ", r);
          if (item.newIndex == item.oldIndex) {
            //   self.galleries[item.newIndex].media = r.media;
          }
        }
      });
      console.log("Galleries: ", self.galleries);
   
    });
   
  }
  */
/*
  addMediaToGallery(g: Gallery, m: Media) {
    var aspect = m.width / m.height;
    console.log("Add to gallery ", g, m);
    if (!g.media) g.media = [];

    // Add Gridster data
    var cols = 4;
    var rows = 4;
    if (aspect > 1)
      cols += 2;
    if (aspect < 1)
      rows += 2;

    m.gridItem = {
      cols: cols, rows: rows, y: 0, x: 0, thumbnail: m.url,
      url: m.url

    }
    g.media.push(m);

    this.updateGalleryMedia(g);

  }
*/
  getMyURL(m: Media) {

    if (m.fileType == "image")
      return m.url;

    else {

      return "https://suncraftcache.sfo2.cdn.digitaloceanspaces.com/FrontView2.mp4"
    }
  }
  getDataVideo(m: Media) {

    var str =
      '{"source": [{"src":"' + "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/video%2FWinePress1.mp4?alt=media&token=f693472f-812e-4be1-b486-9ec2f41d6c40" + '", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}'

    console.log(str);
    return str;
  }

  removeMediafromDB(m: Media, source?: string) {
    const db = firebase.firestore();
    var self = this;

    m.status = "deleted";

    console.log("Delete Media: ");
    const docRef1 = db.collection('company').doc(this.global.myCompany.id);

    if (!source)
      docRef1.update({
        media: self.global.media
      }).then(() => { }).catch((error) => { console.error("Error: ", error); });

    else if (source == "Google")
      docRef1.update({
        googlePhotos: self.googlePhotos
      }).then(() => { }).catch((error) => { console.error("Error: ", error); });
    /*
        else if (source == "Search")
          docRef1.update({
            searchImages: self.searchImages
          }).then(() => { }).catch((error) => { console.error("Error: ", error); });
    
    */
  }

  updateGalleryMedia(g: Gallery) {
    const db = firebase.firestore();
    var self = this;

    console.log("Update Gallery: ", g);
    const docRef1 = db.collection('company').doc(this.global.myCompany.id).collection("galleries")
      .doc(g.id)

    docRef1.update({ media: g.media })
      .then(() => {
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  editMedia(m) {
    console.log("Editing this video ", m);
    this.mediaEditing = m;
    this.showMediaEdit = true;
  }
  hideEdit() {
    this.mediaEditing = null;
    this.showMediaEdit = false;
  }

  deleteMedia(m: Media) {
    console.log('Delete file: ', m);

    // Actually we move file

    this.http.post<any>(
      'https://us-central1-suncraft-ea7be.cloudfunctions.net/deleteFile',
      { "fileId": m.fileId }).subscribe(data => {
        console.log("askdfj akfj dsf", data);
      })

    this.mediaEditing = null;
    this.showMediaEdit = false;
  }




  saveChanges(m: Media) {

    // this.updateDetails(m);
    console.log('Updating tags: ', m.tags)

    this.http.post<any>(
      'https://us-central1-suncraft-ea7be.cloudfunctions.net/updateDetails',
      { "fileId": m.fileId, "tags": m.tags, }).subscribe(data => {
        console.log("askdfj akfj dsf", data);
      })
    /*
        this.http.patch("https://us-central1-suncraft-ea7be.cloudfunctions.net/updateDetails",
        {
          "tags": m.tags,
          "fileId": m.fileId
        })
        .subscribe(
            (val) => {
                console.log("PATCH call successful value returned in body", 
                            val);
            },
            response => {
                console.log("PATCH call in error", response);
            },
            () => {
                console.log("The PATCH observable is now completed.");
            });
    
    */

    this.mediaEditing = null;
    this.showMediaEdit = false;

  }
  /*

  getMediaList() {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
      }
    };

    fetch('https://us-central1-suncraft-ea7be.cloudfunctions.net/listFiles', options)
      .then(response => response.json())
      .then(response => {
        console.log("File list: ", response);
        self.mediaList = <Media[]>response;
        self.everyTag = [];
        self.mediaList.forEach(function (media) {

          if (media.tags) {
            media.tags.forEach(function (t) {
              const found = self.everyTag.includes(t);

              if (!found) {
                self.everyTag.push(t);
              }
            })
          }

        })


        self.everyTag.sort(function (a, b) {
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          return 0;

        });

        // Let's put behind a button      self.imageSearch ("");

      })
      .catch(err => console.error(err));
  }

  updateDetails(m: Media) {
    var self = this;

    const options = {
      method: 'PUT',
      fileId: m.fileId,
      tags: m.tags,
      headers: {
      }
    };

    console.log("update");
    fetch('https://us-central1-suncraft-ea7be.cloudfunctions.net/updateDetails', options)
      .then(response => response.json())
      .then(response => {
        console.log("File list: ", response);


      })
      .catch(err => console.error(err));
  }

  */


  imageSearch(search: string, cursor: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'joj-image-search.p.rapidapi.com'
      }
    };

    var str = 'https://joj-image-search.p.rapidapi.com/v2/?q=' + search + '&hl=en';

    if (cursor && cursor.length) str += '&cursor=' + cursor;

    if (!cursor) self.mediaList = [];
    console.log("Image search ", str);
    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        console.log(response)
        self.imagesFound = <JOJImage[]>response.response.images;
        console.log(self.imagesFound);
        self.imagesFound.forEach(function (image) {

          var newImage: Media = {
            source: "Internet",

            //      createdAt?: string;
            //      customCoordinates?: string;
            //      fileId?: string;
            //      name?: string;
            url: image.image.url,
            thumbnailUrl: image.image.url,
            height: image.image.height,
            width: image.image.width,
            //         size?: number
            //         filePath?: string;
            //         tags?: string[];
            //        format?: string;
            fileType: "image/png",

          }
          self.mediaList.push(newImage);
        })

        // Call myself if there is a next_Cursor
        console.log("Next: ", response.response.next_cursor);
        if (response.response.next_cursor && self.mediaList.length <= 200) {
          console.log("Fetching: ", response.response.next_cursor)
          self.imageSearch(search, response.response.next_cursor);
        }

      })
      .catch(err => console.error(err));



  }


  getAllfiles() {
    const storage = getStorage();
    // already done
    return;
  }
/*
  newMedia: Media;
  createNewMedia(path) {
    var type = "image/jpeg";

    if (path.includes("png"))
      type = "image/png"
    if (path.toLowerCase().includes("mov"))
      type = "video/mp4"
    if (path.includes("mp4"))
      type = "video/mp4"

    this.newMedia = {
      updatedAt: Date.now(),
      createdAt: Date.now(),
      altDesc: "image desc",
      //     url: photo,
      //     thumbnailUrl: thumbStr,
      //     smallUrl: smallStr,
      //     medUrl: medStr,
      tags: [this.global.myCompany.name],
      fileType: type,
    }

    //  console.log("media ", this.newMedia);


  }
  */
/*
  addImageToCompany(media: Media) {
    var self = this;
    var db = firebase.firestore();
    //   console.log("Store Media add ", media)

    // First check if URL is already there
    var ref1 = db.collection("company").doc(self.global.myCompany.id)
      .collection("media").where("url", "==", media.url)
      .get().then((querySnapshot) => {

        if (querySnapshot.empty) {
          // OK to save
          // add Media to Company as sub-collection
          console.log("Saving image... ")

          var ref2 = db.collection("company").doc(self.global.myCompany.id)
            .collection("media").doc().set(media);


        }
        else {
          console.log("found the image ")
        }
      })

  }
*/
}

function sleep(milliseconds) {
  //bad bad hack
  return;
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}


function getImageDimenstion(m: Media, imgUrl) {

  let img = new Image();

  img.src = imgUrl;
  m.width = img.naturalWidth;
  m.height = img.naturalHeight;
  console.log("Size: ", m.width, m.height);
}

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}