<div *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.chatService">
    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
        <p-accordionTab>
            <ng-template pTemplate="header">
                <div class="pretty-setting">Chat Settings
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="global.myCompany.enabled.chatService">
                    Customer chats are all set! Edit settings below.<br>
                    Update your Days and Hours then add to existing site. <br>
                    Click below to start using chats.
                    <div class="URL" (click)="GotoChat()">Your Chat Domain</div>
                </div>

                <p-selectButton [options]="enabledOptions" [(ngModel)]="whenEnabledSelection" optionLabel="name"
                    optionValue="code"></p-selectButton>
                <div *ngIf="whenEnabledSelection!='always'">
                    Message to display outside business hours:

                    <textarea #textarea2 style="width: 100%;" [(ngModel)]="notOpenChatMsg" [rows]="5" [cols]="30"
                        pInputTextarea autoResize="autoResize" [autofocus]="true"></textarea>

                </div>
                <div class="command" (click)="startTester()">Start Tester</div>

            </ng-template>
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="pretty">Add Chat to your site
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    The following snippet of code can be added to your Wordpress site:

                    {{updateiFrame()}}
                    <textarea #textarea1 style="width: 100%;" (click)="copyToClipboard()" [(ngModel)]="iframeChat"
                        [rows]="5" [cols]="30" pInputTextarea autoResize="autoResize" [autofocus]="true"></textarea>




                </ng-template>
            </p-accordionTab>
        </p-accordionTab>
    </p-accordion>
</div>