<app-form formName="Email Templates" [autoSubmit]="false" (objectEvents)="template($event)"></app-form>

<br><br><br>

<div style="width:100%;height:80px">

<!--
  Enter Start Number
  <p-inputNumber [(ngModel)]="startNumber"></p-inputNumber>
  <br>

  Enter Max Number
  <p-inputNumber [(ngModel)]="maxNumber"></p-inputNumber>
-->

  <button *ngIf="customers" (click)="updateAllCustomers()">Send Message to {{startNumber+" - "+maxNumber}}</button>

  <button (click)="stopBatch()">Stop the Batch</button>

  <br>

  <button (click)="sendTestEMail()">Send Test Message</button>

</div>
<div *ngIf="customerOrders">

  <p style="font-size:1.2em; text-align: center;">Customers with Open Orders</p>
  <div *ngFor="let cust of customerOrders;let i = index;">
    <div class="command">{{cust.firstName+" "+cust.lastName}}</div>
  </div>
</div>


<p-table [value]="customers1" #dt1 [tableStyle]="{ 'min-width': '5rem' }"
  [globalFilterFields]="['firstName', 'lastName']" dataKey="uid">

  <ng-template pTemplate="caption">
    <div fxFlex fxLayout="row" fxLayoutAlign="center center">
      <p>Find a Customer</p>

      <span class="p-input-icon-left ml-auto">
        <i class="pi pi-search"></i>
        <input id="search" [(ngModel)]="search1" pInputText type="text" (input)="searchMe($event.target.value)"
          placeholder="Search keyword" />
      </span>
      <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(dt1)"></button>

    </div>

  </ng-template>

  <ng-template pTemplate="header">
    <tr>

      <th>Name</th>
      <th>Email</th>
      <th>Commands</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-customer let-rowIndex="rowIndex">

    <tr>
      <td>{{rowIndex}}- {{ customer.firstName+" "+customer.lastName}}</td>
      <td>{{customer.email}}</td>
      <td>
        <div fxLayout="row wrap" fxlayoutAlign="space-between center">
          <div class="command2">Reserve</div>
          <div class="command2">POS</div>
          <div class="command2">Message</div>
        </div>

        <button *ngIf="validAdmin()" (click)="removeUser(customer)">
          Remove this User
        </button>

      </td>

    </tr>

  </ng-template>

</p-table>
<br><br>