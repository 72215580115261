import { Component, Input, Output, EventEmitter,SkipSelf, HostListener, ViewChild, OnInit, AfterViewInit, BootstrapOptions } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { interval, NotFoundError } from 'rxjs';
import { User, Media } from '../../user-interface';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/globals';
import { Company, Gallery, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate } from '../../company-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { DragDropModule } from 'primeng/dragdrop';
import { HttpEvent } from '@angular/common/http';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo  from 'lightgallery/plugins/video';
import {
  CompactType,
  DisplayGrid,
  Draggable,
  GridsterComponent,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponent,
  GridType,
  PushDirections,
  Resizable
} from 'angular-gridster2';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  @Input() viewOnly: boolean;
  @Input() gallery: Gallery;
  @Output() selectionEvent = new EventEmitter<string>();

  options: GridsterConfig;
  dashboard: Array<GridsterItem>;
  settings = {
    counter: false,
    plugins: [lgZoom, lgVideo]
  };
  items = [];
  //gallery: Gallery;

  galleries: Gallery[];
  constructor(private sanitizer: DomSanitizer, public global: Globals, private router: Router,
    private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.options = {
    gridType: GridType.Fit,
    compactType: CompactType.CompactUp,
    margin: 0,
    outerMargin: true,
    outerMarginTop: null,
    outerMarginRight: null,
    outerMarginBottom: null,
    outerMarginLeft: null,
    useTransformPositioning: true,
    mobileBreakpoint: 300,
    useBodyForBreakpoint: false,
    minCols: 10,
    maxCols: 100,
    minRows: 10,
    maxRows: 100,
    maxItemCols: 100,
    minItemCols: 1,
    maxItemRows: 100,
    minItemRows: 3,
    maxItemArea: 2500,
    minItemArea: 3,
    defaultItemCols: 4,
    defaultItemRows: 4,
    fixedColWidth: 105,
    fixedRowHeight: 105,
    keepFixedHeightInMobile: false,
    keepFixedWidthInMobile: false,
    scrollSensitivity: 10,
    scrollSpeed: 20,
    enableEmptyCellClick: false,
    enableEmptyCellContextMenu: false,
    enableEmptyCellDrop: false,
    enableEmptyCellDrag: false,
    enableOccupiedCellDrop: false,
    emptyCellDragMaxCols: 50,
    emptyCellDragMaxRows: 50,
    ignoreMarginInRow: false,
    draggable: {
      enabled: false,
    },
    resizable: {
      enabled: false
    },
    swap: false,
    pushItems: false,
    disablePushOnDrag: false,
    disablePushOnResize: false,
    pushDirections: { north: true, east: true, south: true, west: true },
    pushResizeItems: false,
    displayGrid: DisplayGrid.None,
    disableWindowResize: false,
    disableWarnings: false,
    scrollToNewItems: false
  };

/*
    this.options = {
      
     
      gridType: GridType.Fit,
      displayGrid: DisplayGrid.None,
      pushItems: false,
      draggable: {
        enabled: false,
      },
      resizable: {
        enabled: false
      },
      margin: 0,
      outerMargin: true,
      outerMarginTop: 4,
      outerMarginRight: 4,
      outerMarginBottom: 4,
      outerMarginLeft: 4,
      addEmptyRowsCount: 0,

   
      minCols: 10,
      maxCols: 20,
      minRows: 10,
      maxRows: 20,

     


      minRows: 1,
      maxRows: 4,
      maxItemCols: 1,
      minItemCols: 1,
      maxItemRows: 1,
      minItemRows: 1,
      maxItemArea: 2,
      minItemArea: 1,
      defaultItemCols: 4,
      defaultItemRows: 1,
      addEmptyRowsCount: 1



    };
    */

console.log ("Gallery ", this.gallery)
    this.viewOnly = true;
  //  this.getGalleries();

  }

  chooseGallery (g: Gallery) {
    this.selectionEvent.emit(g.id);

  }

  getGalleries() {
    var self = this;
    var db = firebase.firestore();
    const q = query(collection(db, "galleries"), where("companyID", "==", self.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docChanges()) { console.log("Changes: ", querySnapshot.docChanges()) }

      querySnapshot.docChanges().forEach((item) => {
        if (item.type == "added") {
          if (!self.galleries) self.galleries = [];
          var r = <Gallery>item.doc.data();
          if (0){//self.viewOnly == r.id) {
            self.gallery = {...r};
            console.log ("View Gallery ", self.gallery);
          }

          console.log("add: ", r);
          self.galleries.push(r);
     //     self.addImages(r);
        }
        else if (item.type == "modified") {
          var r = <Gallery>item.doc.data();
          console.log("Modified: ", r);
          if (item.newIndex == item.oldIndex) {
            self.galleries[item.newIndex].media = r.media;
          }

        }
        else if (item.type == "removed") {
          var r = <Gallery>item.doc.data();
          console.log("removed: ", r);
          if (item.newIndex == item.oldIndex) {
            //   self.galleries[item.newIndex].media = r.media;
          }
        }
      });
      console.log("Galleries: ", self.galleries);

    });

  }
/*
  addImages = (g: Gallery ) => {
    var self = this;
   
    if (!self.items) self.items = [];
    if (g.media)
    g.media.forEach(function(item){

      var aspect = item.width/item.height;
      console.log ("Aspect: ", aspect);

      var cols = 4;
      var rows = 4;
      if (aspect > 1)
        cols+=2;
      if (aspect < 1)
        rows+=2;

        if (!self.dashboard) self.dashboard = [];
      self.dashboard = [...self.dashboard, { 
        cols: cols, rows: rows, y: 0, x: 0, thumbnail:item.thumbnailUrl, 
        url:item.thumbnailUrl

      }]

      self.items = [
        ...self.items,
        {
          id: item.name,
          size: '1400-933',
          src:
            item.thumbnailUrl,
          thumb:
            item.thumbnailUrl,
          subHtml: `<div class="lightGallery-captions">
              <h4>Photo by</h4>
              <p>Published on January 6, 2021</p>
          </div>`
        }
      ];


    })
    console.log ("Images ",this.items)
  };


*/

}

/*
     function imgSize() {
        let myImg = document.querySelector("#imgId");
        let realWidth = myImg.naturalWidth;
        let realHeight = myImg.naturalHeight;
        alert("Original width=" + realWidth + ", " + "Original height=" + realHeight);
      }
*/
