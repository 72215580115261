import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";

import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { MediaService } from "../media//media-functions/media-functions"
import { Quote } from '../content-interface';
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'

@Component({
  selector: 'app-chatbots',
  templateUrl: './chatbots.component.html',
  styleUrls: ['./chatbots.component.css', '../../common.scss']
})

export class ChatbotsComponent implements OnInit {
  @Input() chatBot: ChatBot;
  @Input() userId: string;

  constructor(private route: ActivatedRoute, public global: Globals,
    public nav: NavService, private sanitizer: DomSanitizer,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {
    if (!this.global.allChatBots) this.global.allChatBots = []



  }

  ngOnInit(): void {
    Logger("Chat Bot Started", this.global.authuser.uid,"")

    if (this.userId) {
      this.watchChatBot()
      this.chatBot = { id: "", uid: this.userId, conversation: [] }
    }
  }

  addChatBot(event) {
    var self = this;
    var newChatBot = {
      uid: this.global.authuser.uid,
      id: "New",
      createdAt: Date.now(),
      createdBy: self.global.authuser.uid,
      editedAt: Date.now(),
      editedBy: self.global.authuser.uid,
      conversation: [],
      query: "",
      response: ""
    }
    this.global.allChatBots.splice(0,0,newChatBot) // Insert at top
    //   this.chatBot.conversation.push({ role: "user", content: query })
    //   this.chatBot.conversation.push({ role: "assistant", content: response.message })

  }

  getBotName(bot: ChatBot) {
    if (bot.name) return bot.name;

    if (bot?.conversation.length)
      return bot.conversation[0].content;
    else return "New Chatbot"
  }
  watchChatBot() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;

    db.collection('chatBots').where("uid", "==", self.userId)
      .onSnapshot((querySnapshot) => {
        if (!self.global.allChatBots) self.global.allChatBots = []

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const m = <ChatBot>change.doc.data();
            if (!m.settings)
              m.settings = {model: "gpt-3.5-turbo"}
      //      self.chatBot = m;
            if (self.global.allChatBots.findIndex(x => x.id === m.id) == -1)
              self.global.allChatBots.push(m)
          }
          if (change.type === "modified") {
            //  const m = <Form>change.doc.data();
            //     const index = self.global.mediaGroups.findIndex(x => x.id === m.id)
            //HAVE NO IDEA WHY NOT NEEDED   if (index != -1) self.global.mediaGroups[index] = {...m}
            console.log("Modified city: ", change.doc.data());
          }

        });
        self.global.allChatBots.sort(function (a, b) {
          if (!a.createdAt || !a.editedAt) {
            a.editedAt = Date.now()
          }
          if (!b.createdAt || !b.editedAt) {
            b.editedAt = Date.now()
          }
          return a.editedAt - b.editedAt;
        });
        console.log("Chatbots: ", self.global.allChatBots);

      });

  }

  activeChat = []

  onChatOpen(e, i) {
    var self = this;
    self.chatBot = self.global.allChatBots[i];

  }

  onChatClose(e, i) {
    var self = this;
  }
}
