import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Order } from '../../order-interface';
import firebase from 'firebase/compat/app';
import { User, Follows, Activity } from '../../user-interface';
import { Company, Rewards } from '../../company-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgAuthService } from "../../../ng-auth.service";
import { Observable } from 'rxjs';
import { Logger } from '../../../functions';

import { PrimeNGConfig } from 'primeng/api';
import { SelectItem } from 'primeng/api/selectitem';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { Product } from '../../product-int';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { onMenuItem } from "../../menu-item-interface";
import { Globals } from 'src/app/globals';
import { DialogService } from 'primeng/dynamicdialog';
import { editProduct } from '../editProduct';


@Component({
  selector: 'myPointsView',
  templateUrl: './my-points.component.html',
  styleUrls: ['./my-points.component.scss'],
  providers: [MessageService, DialogService]

})
export class MyPointsComponent implements OnInit {
  @Input() name: string;

  //orders: Order[] = [];
 // ordersObs: Observable<any[]>;
  user: User;
  companyID: string = "5vjmmQOnSx31XLsMDvZx";  // TEMP HACK
  selected: string;

  rewards: Rewards[];

  // Redemption //
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  //myRewardPoints: number = 0;
  selectedMessage: any;


  // Redemption //


  constructor(private router: Router, public ngAuthService: NgAuthService,
    private sharedDataService: SharedDataService,
    private cartService: CartService,
    private messageService: MessageService,
    private primeNGConfig: PrimeNGConfig,
    public global: Globals,
    public dialogService: DialogService
  ) { }


  ngOnInit(): void {
    var self = this;

    console.log("onInit-my-points");
    this.primeNGConfig.ripple = true;

    this.sortOptions = [
      { label: 'Price High to Low', value: '!retail' },
      { label: 'Price Low to High', value: 'retail' }
    ];

    this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value!
    console.log(this.selectedMessage);



  }

  editMe(item: Product) {

    if (screen.width <900) {
    const ref = this.dialogService.open(editProduct, {
      header: 'Edit: ' + item.title,
      width: '100%',
      data: {
        item
      },
    });
  }
  else {
    const ref = this.dialogService.open(editProduct, {
      header: 'Edit: ' + item.title,
      width: '900px',
      data: {
        item
      },
    });

  }
  }
  GetPoints(item: Rewards) {
    // User Rewards
    console.log("Reward!:", item);

    if (item.validationRequired) {
      //log request as event
      Logger("Rewards Validation", this.user.uid, this.companyID, this.user.email, item.title);
    }
  }

  myProductColor(row: Product) {
    if (row.myColor)
      return "#" + row.myColor + "11";
    else return "FFAABB";
  }

  GetRewards() {
    var db = firebase.firestore();
    var self = this;
    var index = 0; // 

    self.rewards = [];

    db.collection("rewards").where("companyID", "==", "default").get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        const data = <Rewards>doc.data();
        self.rewards.push(data);
      });
      console.log("Rewards: ", self.rewards);
    })
  }

  addRewardItem(item: onMenuItem) {

    item.RewardPaid = item.retail * 100;
    this.cartService.addItemToOrder(item);

  }

  dateTimeSort() {

    this.sharedDataService.payOrder.sort(function (a, b) { return b.dateTime - a.dateTime; });

  }
  // Redemption //
  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  // Redemption //

}