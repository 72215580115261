import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Container, Form, Field, ContainerSettings, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { Product, Options } from '../../components/product-int';
import { ButtonModule } from 'primeng/button';
import { Order } from '../order-interface';
import { CurrencyPipe } from '@angular/common'
import { Logger } from 'src/app/functions';
import { NodeService } from '../node.service';

@Component({
  selector: 'app-api',
  templateUrl: './api.component.html',
  styleUrls: ['./api.component.css', '../../common.scss']
})
export class ApiComponent implements OnInit {
  @Input() apiFunction: string;
  @Input() store: Follows;
  @Input() follows: Follows[];
  @Input() formName: string;
  @Input() form: Form;
  @Input() forms: Form[];
  @Input() fields: boolean;
  @Input() list: string[];

  perkTabs: string[];

  perks: Coupon[];
  usedPerks: Coupon[];
  perkSettings: ContainerSettings;
  followSettings: ContainerSettings;
  formSettings: ContainerSettings;
  formIndex: number;
  field: Field;
  addingField: boolean = false;
  addingForm: boolean = false;

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller,
    private cp: CurrencyPipe,
    private nodeService: NodeService

  ) {

  }

  ngOnInit(): void {
    var self = this;

    console.log("API: ", this.list)

    this.formSettings = {
      width: "60%", backgroundColor: "#449988", color:
        'white'
    }

    if (this.forms) {
      // Find store name in follows and load as store
      const index = self.forms.findIndex(function (item) {
        if (item.name == self.apiFunction)
          return true;
      });
      if (index != -1) {

        self.apiFunction = "Form Edit"
        self.formName = this.forms[index].name;
        console.log("Form: ", this.forms, self.formName);
        self.global.formIndex = index;
      }

    }

    if (this.fields) {
      console.log("FIELDS ", self.apiFunction, self.global.formIndex)

      const index = self.global.allForms[self.global.formIndex].fields.findIndex(function (item) {
        if (item.title == self.apiFunction)
          return true;
      });
      if (index != -1) {
        self.apiFunction = "Field Edit"
        // self.form = this.forms[index].name;
        console.log("field edit: ", this.forms, self.formName);
        // self.global.formIndex = index;
        self.field = self.global.allForms[self.global.formIndex].fields[index];
      }

    }



    if (this.follows) {
      // Find store name in follows and load as store
      const index = self.follows.findIndex(function (item) {
        if (item.storeName == self.apiFunction)
          return true;
      });
      if (index != -1) {
        self.apiFunction = "Places";
        self.store = self.follows[index];

      }
    }

    console.log("API: ", this.apiFunction);




    switch (this.apiFunction) {
      case 'Perks':
        this.getPerks();
        this.perkTabs = ["My Perks", "Available Perks", "Used Perks"]
        this.perkSettings = { width: "180px", backgroundColor: "#bb6622" }
        break;
      case 'All Stores':
        this.nav.GetAllStores();
        break;

    }

  }

  openTab(event) {

  }
  formSelected(event) {
    var self = this;
    var data = <Container>event;

    console.log("FORM selected ", data.node, data.index)
    self.global.formIndex = data.index;
  }
  fieldsSelected(event) {
    var self = this;
    var data = <Container>event;

    console.log("Form ACTION ", data.node, data.index)
    //  self.apiFunction = "Field Edit";
  }

  getPerks() {
    var self = this;

    this.perks = [];
    this.usedPerks = [];

    if (self.global.authuser.perks && self.store) {
      self.global.authuser.perks.forEach(function (item) {
        console.log("Got perks: ", item, self.store.storeNum)

        if (item.storeNum == self.store.storeNum) {
          if (item.used) self.usedPerks.push(item);
          else self.perks.push(item);
        }

      })
      console.log("Got perks: ", self.perks)
    }
  }

  setActiveStore() {
    this.followSettings = { width: "50%", backgroundColor: "#4499bb" }

  }

  hours(event) {
    console.log("Hours ", event);

  }

  addField() {

    this.addingField = !this.addingField;

  }
  addForm() {

    this.addingForm = !this.addingForm;

    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc()

    var newForm: Form = {
      id: docRef.id,
      name: "New form",
      showSubmit: true,
      fields: [],
    }
    docRef.set(newForm).then(() => {
      console.log("form added");

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });


  }

  addFieldToForm(form: Form, field: Field) {
    console.log("Form & field add ", form, field)
    form.fields.push(field);
    this.updateForm(form);


  }
  updateForm(event: Form) {
    var self = this;
    var db = firebase.firestore();


    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(event.id)

    docRef.update(event).then(() => {
      console.log("form updated");

      // Hack find and update global
      const index = self.global.allForms.findIndex(function (post) {
        if (post.id == event.id)
          return true;
      });
      if (index != -1) {
        self.global.allForms[index] = { ...event }
        console.log("Bull: ", self.global.allForms[index])
      }

      // Set up fields
      self.list = [];
      self.global.allForms[self.global.formIndex].fields.forEach(function (item) {
        self.list.push(item.title)
      })


    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  logMeOut() {
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'p': null,
      },
      queryParamsHandling: 'merge'
    })

    this.ngAuthService.SignOut();
    this.global.authuser = null;

    //  window.location.reload();
  }
  password: string = ""
  pwSuccess: boolean = false;
  passwordChange: boolean = false;
  changePassword() {
    this.passwordChange = true;
  }
  updatePassword() {
    var self = this;

    if (self.password.length < 4) {
      alert("Four character minimum")
      return;
    }

    var db = firebase.firestore();
    var ref = db.collection("users").doc(self.global.authuser.uid);
    ref.update({
      pw: self.password,
    })
      .then(() => {
        console.log("pw updated: ");
        Logger("Password change", self.global.authuser.uid, "", this.router.routerState.snapshot.url)

        self.passwordChange = false;
        self.pwSuccess = true;
      })
      .catch((error) => {
        console.error("Error writing perk: ", error);
      });

  }


  RemoveStore(store: Company) {
    // Super-admin

    if (store.id == '5vjmmQOnSx31XLsMDvZx' || store.name == 'Crystal Hill Vineyard') return;


    if (this.global.authuser.uid.includes("vT6eKFxFHb3EKbVeCTusW86CrZ")) {
      console.log("Hello SuperAdmin");
      if (!confirm("Please confirm DATA DELETION! " + store.name)) return;
    }
    else return;

    // FIRST REMOVE ANY SUBCOLLECTIONS



    // Remove company plus all reservations 
    console.log("Removing COMPANY: ", store.name);
    var self = this;

    var db = firebase.firestore();

    db.collection("company").where("id", "==", store.id)
      .get()
      .then((querySnapshot) => {
        // IF HERE THEN WE CAN REMOVE
        if (!querySnapshot.empty) {
          // Set ALL APPS to private




          db.collection("company").doc(store.id).delete().then(() => {
            console.log("Company successfully deleted!");
          }).catch((error) => {
            console.error("Error removing company: ", error);
          });
        }
      });
    console.log("Removing RESERVATIONS for: ", store.name);

    db.collection("reservations").where("companyID", "==", store.id)
      .get().then((querySnapshot) => {
        if (querySnapshot.empty) console.log(store.name + " had no reservations");

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          db.collection("reservations").doc(doc.id).delete().then(() => {
            console.log("Res successfully deleted!");
          }).catch((error) => {
            console.error("Error removing res: ", error);
          });
        })
      });

  }
  copyMasterApps() {
    this.nodeService.copyMasterApp("app-2963", "user", "Contacts")
  }


}
