<div [ngStyle]="{ 'font-size': getFontSize() }">
    <p-accordion expandIcon=" false" collapseIcon="false" [multiple]="false" (onOpen)="onTabOpen($event)">

        <p-accordionTab>
            <ng-template pTemplate="header">
                <div style="text-align: center; width:100%" fxLayout="column" fxLayoutAlign="center none">

                    <div class="pretty-header-company">Order Settings</div>
                </div>
            </ng-template>
            <ng-template pTemplate="content" style="margin: 0px !important; padding: 0px !important;">
                
                <p-toggleButton [(ngModel)]="newOrderNotify" onLabel="New-Order-Notify" offLabel="Notification-off"></p-toggleButton>
                <br>
                <button type="button" (click)="WhatsNew()">Whats New</button>
                <div class="command" (click)="setFontSize(1.1)">Bigger text</div>
                <div class="command" (click)="setFontSize(0.9)">Smaller text</div>


                <div fxLayout="column" fxLayoutAlign="center none">
                    <div  *ngIf="editTagGroup"  fxLayout="row" style="width: 100%">
                        <input style="width:15em;" class="search" type="search"
                            [(ngModel)]="searchString" pInputText placeholder="Search">
                        <div *ngIf="searchString.length>0" (click)="clearTagSearch()" class="command">
                            Clear </div>
                       

                    </div>
                    <div *ngIf="editTagGroup" fxLayout="row" style="width: 100%">

                        <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:30%">
                            <div *ngIf="editTagGroup.tags">
                                Tags assigned:
                            </div>
                            <div *ngIf="editTagGroup.tags" fxFlex fxLayout="row wrap"
                                fxLayoutAlign="start start">
                                <div *ngFor="let d of editTagGroup.tags; let i = index;">
                                    <div class="my-tag" (click)="removeTag(d)">
                                        {{d}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="width:70%" fxLayout="column" fxLayoutAlign="none none">
                            Tags not assigned:
                            <!-- TAGS AVAILABLE -->
                            <div *ngIf="global.allTags" fxFlex fxLayout="row wrap"
                                fxLayoutAlign="none space-between">
                                <div *ngFor="let t of global.allTags; let i = index;">
                                    <div *ngIf="tagFilter(t)">
                                        <div *ngIf="!editTagGroup.tags || (editTagGroup.tags && !editTagGroup.tags.includes(t))"
                                            class="pretty-tag" (click)="addTag(t)">
                                            {{t}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
           


                    <!--
                    <div *ngIf="includedDepartments">
                        Departments included:
                    </div>
                    <div *ngIf="orderService.departments" fxFlex fxLayout="row wrap" fxLayoutAlign="none space-between">

                        <div *ngFor="let d of includedDepartments; let i = index;">
                            <div class="command" (click)="RemoveDepartment(d)">{{d}}</div>
                        </div>
                    </div>


                    Departments not included:
               
                    <div *ngIf="orderService.departments" fxFlex fxLayout="row wrap" fxLayoutAlign="none space-between">
                        <div *ngFor="let t of orderService.departments; let i = index;">
                            <div *ngIf=" (!includedDepartments.includes(t))" class="command" (click)="AddDepartment(t)">
                                {{t}}</div>
                        </div>
                    </div>
                -->
                </div>

            </ng-template>
        </p-accordionTab>
    </p-accordion>

    <div *ngIf="global.openOrders && global.openOrders.length > 0" class="container">

        <p-table [columns]="cols" [value]="global.openOrders"
            [tableStyle]="{ 'min-width': '50rem', 'width': '100%', 'padding': '0px', 'margin': '0px' }">
            <ng-template pTemplate="header" let-columns>
                <tr [ngStyle]="{ 'font-size': getFontSize() }">
                    <th *ngFor="let col of columns">
                        {{ col.header }}
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr *ngIf="applyFilters(order)" [ngStyle]="{ 'font-size': getFontSize() }">

                    <!--        <td *ngFor="let col of columns">
                    {{ rowData[col.field] }}
                </td> dynamic  -->



                    <td class="time-col">{{getLocalDate(order)}}</td>
                    <td class="dept-col">{{getCatDept(order)}}</td>
                    <td class="table-col" [style.background-color]="order.tableColor">{{order.tableName}}</td>
                    <td>
                        <div fxLayout="column" fxLayoutAlign="none none">
                            <div fxLayout="row" fxLayoutAlign="none none">
                                <span *ngIf="order.qty>1"><strong>[{{order.qty}}]</strong>&nbsp;</span>

                                {{" " +order.item }}
                            </div>
                            <div *ngFor="let option of order.allOptions">
                                <div *ngIf="option.chosen" style="color:rgb(46, 160, 4); margin-right: 3px;">
                                    {{option.chosen}}
                                </div>
                            </div>
                            <span style="color:orangered;">{{order.note}}</span>
                            <!--  <div class="status-reset" (click)="nextStatus(order, -1)">undo</div>-->
                        </div>
                    </td>
                    <td style="cursor:pointer" [style.background-color]="myColor(order)" (click)="nextStatus(order, 1)">
                        {{order.status}}</td>
                    <!--
                <td><p-rating [ngModel]="product.rating" [readonly]="true" [cancel]="false"></p-rating></td>
                <td><p-tag [value]="product.inventoryStatus" [severity]="getSeverity(product.inventoryStatus)"></p-tag></td>
                -->
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>