import { Component, SkipSelf, Input, HostListener, ViewChild, OnInit, AfterViewInit, BootstrapOptions } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../../environments/environment';
import { interval, NotFoundError } from 'rxjs';
import { User, Media } from '../../user-interface';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/globals';
import { Company, NapkinApp, Gallery, AppNode, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate } from '../../company-interface';
import { Product, Options, Table } from '../../product-int';
import { Content, Weather } from '../../content-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../../products.service';
import { DragDropModule } from 'primeng/dragdrop';
import { HttpEvent } from '@angular/common/http';
import { NavService } from '../../menu/nav.service';
import { ReviewsComponent } from '../../reviews/reviews.component';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { Order } from '../../order-interface';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent {
  @Input() node: AppNode;


  constructor(public cartService: CartService, public sanitizer: DomSanitizer, public global: Globals, private router: Router,
    private route: ActivatedRoute,
    public navService: NavService,
    public productService: ProductsService) {

      //console.log("AboutUs ",global.myCompany.reserveSettings.reserveDay)

    }


    getDayofWeek(i) {

      var days: string[] = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[i];
  
    }


}
