import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//import { SignInComponent } from './components/sign-in/sign-in.component';
//import { SignUpComponent } from './components/sign-up/sign-up.component';
//import { StoreSignUpComponent } from './components/store-sign-up/store-sign-up.component';

//import { MenuComponent } from './components/menu/menu.component';
import { MyPointsComponent } from './components/menu/my-points/my-points.component';
import { RewardsComponent } from './components/menu/rewards/rewards.component';

import { MyOrdersComponent } from './components/menu/my-orders/my-orders.component';
//import { CheckoutComponent } from './components/menu/checkout/checkout.component';
//import { PaymentRequestButtonComponent } from './components/menu/checkout/payment-request-button.component';
import { CreateTokenComponent } from './components/menu/checkout/create-token.component';
//import { CheckoutComponent } from './components/menu/checkout/checkout.component';


import { ProfileComponent } from './components/profile/old-profile.component';
import { ProductListComponent } from './components/product-list/product-list.component';

import { OrdersComponent } from './components/orders/orders.component';
import { POSComponent } from './components/pos/pos.component';
import { PayComponent } from './components/pos/pay/pay.component';

import { TablesComponent } from './components/tables/tables.component';
import { SeatingComponent } from './components/seating/seating.component';

//import { AuthGuard } from "./auth.guard";

//import { PaymentIntentComponent } from './components/menu/checkout/payment-intent/payment-intent.component';
import { VerifyComponent } from './components/verify/verify.component';
import { UnsubComponent } from './components/unsub/unsub.component';

//import { ReserveComponent } from './components/menu/reserve/reserve.component';
import { ReserveComponentBeta } from './components/menu/reserve/reserve-beta.component';

import { SettingsComponent } from './components/settings/settings.component';
import { FrontComponent } from './components/front/front.component';
import { HomeComponent } from './components/home/home.component';
import { TriviaComponent } from './components/trivia/trivia.component';
import { StatsComponent } from './components/stats/stats.component';
import { DrawComponent } from './components/draw/draw.component';
//import { NavigateComponent } from './components/navigate/navigate.component';
import { ChatComponent } from './components/chat/chat.component';
import { ReviewComponent } from './components/review/review.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { JoinComponent } from './components/join/join.component';
import { AppMakerComponent } from './components/app-maker/app-maker.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';

const routes: Routes = [
  { path: 'unsub', component: UnsubComponent },
  { path: 'unsub/:', component: UnsubComponent },


  { path: '', component: HomeComponent },

  { path: 'stats', component: StatsComponent },
  { path: 'draw/:account/:topic', component: DrawComponent },
  { path: 'draw', component: DrawComponent },

  { path: 'join/:store', component: JoinComponent },
  { path: 'join', component: JoinComponent },

  { path: 'bot/:botId', component: ChatbotComponent },

  { path: 'app/:appId', component: AppMakerComponent },
 // { path: 'app', component: AppMakerComponent },
  

  { path: 'chat/:store', pathMatch: 'full', component: ChatComponent },
  { path: 'chat', component: ChatComponent },

  { path: 'menu/:store', component: AppMakerComponent },
  { path: 'menu', component: AppMakerComponent },
  { path: 'trivia', component: TriviaComponent },
  { path: 'trivia/:game', component: TriviaComponent },
  { path: 'trivia/:game/:id', component: TriviaComponent },

  { path: 'my-points', component: MyPointsComponent },
  { path: 'rewards', component: RewardsComponent },
  { path: 'reserve/:store', component: ReserveComponentBeta },
  { path: 'reserve', component: ReserveComponentBeta },

  { path: 'review/:store', component: ReviewComponent },
  { path: 'review', component: ReviewComponent },

  { path: 'reviews/:store', component: ReviewsComponent },
  { path: 'reviews', component: ReviewsComponent },

  { path: 'reserve-beta/:store', component: ReserveComponentBeta },
  { path: 'reserve-beta', component: ReserveComponentBeta },
  //    { path: 'front/:store', component: FrontComponent},
  { path: 'front', component: FrontComponent },

  { path: 'settings', component: SettingsComponent },
 // { path: 'loyalty-programs', component: MenuComponent },

  { path: 'my-orders', component: MyOrdersComponent },
  
  { path: 'verify', component: VerifyComponent },
  // { path: 'product-list', component: ProductListComponent},
  { path: 'pos', component: POSComponent },
  { path: 'pay', component: PayComponent },
  { path: 'orders', component: OrdersComponent },
  // { path: 'categories', component: CategoriesComponent },  
  { path: ':cid', component: HomeComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }

