<!--
Add tables [drop-in for staff] - (if no tables; Add first table, if tables, show list, at end Add new Table.)
	-Accordion-
	[Tab: Basic Settings]
	[Content}
	SettingsName, min-max, total seats,, MinTime, TimeBlock, Visible(or used only for capacity) - required
	[Tab Description [x]Auto]
	(auto from tags, info)
[Tab]Media 
	-Accordion-
Tabs: Images, video, sound clips(!). 
-Accordion
[Tab] Tags (Clickable group + freestyle :ada, outside, booth, next to window, great views, ocean views…)

	[Tab Party Line: group bookings automated] Entire store - allows groups to book your place. You choose all parameters.

	-Accordion-



-->

<div *ngIf="0 && global.authuser && global.authuser.creds && validCreds()">
    <p-accordion expandIcon=" false" collapseIcon="false" [multiple]="true">
        <p-accordionTab>
            <ng-template pTemplate="header">
                <div class="pretty" >
                    Add new Table
                </div>
            </ng-template>

            <ng-template pTemplate="content">

                <p-accordion expandIcon=" false" collapseIcon="false" [multiple]="true">
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="pretty">
                                Basic Settings
                                <span *ngIf="table && table.name">{{" for table: "+table.name}}</span>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="content">
                          
                                <div class="pretty">
                                    <input type="text" pInputText placeholder="Enter Table name or number"
                                        [(ngModel)]="table.name">

                                    <textarea [rows]="5" [cols]="30" placeholder="Enter a visual description ex;romantic spot by the window" [(ngModel)]="table.description" pInputTextarea
                                        autoResize="autoResize"></textarea>
                                </div>
                                <div class="number-input">
                                    <label for="horizontal">Total seats: </label>
                                    &nbsp;
                                    <div style="width: 8em;">
                                        <p-inputNumber [(ngModel)]="table.seats" [showButtons]="true"
                                            buttonLayout="horizontal" spinnerMode="horizontal"
                                            decrementButtonClass="p-button-secondary"
                                            incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>

                                <div class="number-input">
                                    <label for="horizontal">Min party size: </label>
                                    &nbsp;
                                    <div style="width: 8em;">
                                        <p-inputNumber [(ngModel)]="table.minPartySize" [showButtons]="true"
                                            buttonLayout="horizontal" spinnerMode="horizontal"
                                            decrementButtonClass="p-button-secondary"
                                            incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>
                                <div class="number-input">
                                    <label for="horizontal">Max party size:&nbsp;</label>
                                    <div style="width: 8em;">
                                        <p-inputNumber [(ngModel)]="table.maxPartySize" [showButtons]="true"
                                            buttonLayout="horizontal" spinnerMode="horizontal"
                                            decrementButtonClass="p-button-secondary"
                                            incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>
                                <div class="number-input">
                                    <label for="horizontal">Minutes reserved:&nbsp;</label>
                                    <div style="width: 8em;">
                                        <p-inputNumber [(ngModel)]="table.timeReserve" [showButtons]="true"
                                            buttonLayout="horizontal" spinnerMode="horizontal"
                                            decrementButtonClass="p-button-secondary"
                                            incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>

                                <div class="number-input">
                                    <label for="horizontal">Minimum time block:&nbsp;</label>
                                    <div style="width: 8em;">
                                        <p-inputNumber [(ngModel)]="table.minTimeReserve" [showButtons]="true"
                                            buttonLayout="horizontal" spinnerMode="horizontal"
                                            decrementButtonClass="p-button-secondary"
                                            incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                                            decrementButtonIcon="pi pi-minus" mode="decimal">
                                        </p-inputNumber>
                                    </div>
                                </div>


                                <div class="number-input">
                                    Visible: {{checked}}


                                    <p-inputSwitch [(ngModel)]="checked">Visible</p-inputSwitch>
                                </div>
                                <br>

                                <button class="button-9" role="button" (click)="saveTable()">Add this Table</button>

                                <!--     

                                    <button class="button-9" role="button" (click)="dothis()">tables</button>
                            
                                <button class="button-9" role="button" (click)="dothis()">Enroll table in Party
                                    Line</button>
                                    -->

                          
                        </ng-template>
                    </p-accordionTab>

                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="pretty">
                                Media
                            </div>
                        </ng-template>

                        <ng-template pTemplate="content">
                            <div class="pretty">
                                <input type="text" pInputText placeholder="Enter image URL "
                                    [(ngModel)]="table.image1">
                                    <input type="text" pInputText placeholder="Enter Video URL "
                                    [(ngModel)]="table.video1">
                            </div>

                       <!--    <p-sidebar [(visible)]="selectGalleryVisible" [fullScreen]="true">
                                <app-gallery (selectionEvent)="setGallery($event)"></app-gallery>
                            </p-sidebar>
                            --> 

                        </ng-template>

                    </p-accordionTab>
                    <p-accordionTab>
                        <ng-template pTemplate="header">
                            <div class="pretty">
                                Tags
                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <p-chips placeholder="Enter tags + <return>"[(ngModel)]="table.allTags"></p-chips>
                        </ng-template>

                    </p-accordionTab>
                </p-accordion>

            </ng-template>
        </p-accordionTab>
    </p-accordion>

</div>