import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Phaser from 'phaser'; //import Phaser
import { Globals } from 'src/app/globals';
import { FormService } from '../form.service'
import { Company, SessionField, Container, ContainerSettings, Report, Field, Form, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import { VIPService, VIPObject, Tween } from '../VIP.service';
import { Media } from '../user-interface'
import { StripeAfterpayClearpayMessageComponent } from 'ngx-stripe';

@Component({
  selector: 'app-phaser-edit',
  templateUrl: './phaser-edit.component.html',
  styleUrls: ['./phaser-edit.component.css']
})

export class PhaserEditComponent implements OnInit {
  @Input() sceneId;
  @Input() open: boolean;
  @Input() api: string;
  @Input() VIPIndex: number;
  @Output() eventEmitter = new EventEmitter<any>();

  //declare phaserGame variable, the ! is needed for it to be valid code in Angular, we just have to make sure we initialize it in ngOnInit
  phaserGame!: Phaser.Game;
  config: Phaser.Types.Core.GameConfig;
  tool: string;
  showMedia: boolean = false;


  dataList: string[];
  dataSettings: ContainerSettings;

  editor: Field[];
  tweenEditor: Field[];
  groupEditor: Field[];
  settingsEditor: Field[];

  form: Form;
  groupForm: Form;
  tween: Tween;
  settingsForm: Form;


  public constructor(public global: Globals, private VIPservice: VIPService) {
    const that = this;

    /*
        this.config = {
          type: Phaser.AUTO,
          width: 800,
          height: 100,
          parent: 'editContainer',
          title: "VIP Editor",
          backgroundColor: "#ffbbff",
          scene: {
            preload: function () {
              that.preloadScene(this);
            },
            create: function () {
              that.createScene(this);
            },
            update: function () {
              that.updateScene(this);
            },
          },
          physics: {
            default: 'arcade',
            arcade: {
              gravity: { y: 300 },
              debug: false
            }
          },
        };
    */

  }
  ngOnInit() {
    var self = this;

    if (self.api == 'Tool') {
      self.setupTools();

    }
    if (self.api == 'Objects') {
      console.log("VIP: ", self.global.allVIPs)

      var groupForm: Field[] = [
        { title: 'grp_belong', type: "multi-select", class: 'Neon-Betty', label: "Groups", allowAdds: true, options: [] },
      ]
      if (self.global.allVIPs[self.VIPIndex]?.sceneScene?.groups)
        self.global.allVIPs[self.VIPIndex].sceneScene.groups.forEach(function (group) {
          groupForm[0].options.push(group);

        })
      self.groupEditor = [];
      self.groupEditor.push(...groupForm)
      self.groupForm = { name: "Groups", layout: "row wrap", fields: self.groupEditor, showSubmit: true }


      var tweenForm: Field[] = [
        { title: 'tw_Duration', type: "number", class: 'Neon-Betty', label: "Duration" },
        { title: 'tw_Repeat', type: "number", class: 'Neon-Betty', label: "Repeat" },
        { title: 'tw_Hold', type: "number", class: 'Neon-Betty', label: "Hold" },

        { title: 'tw_yoyo', type: "switch", class: 'Neon-Betty', label: "YoYo" },
        { title: 'tw_repeatDelay', type: "number", class: 'Neon-Betty', label: "Repeat Delay", value: 0 },
        { title: "", newLine: true },
        {
          title: 'tw_xTarget', type: "switch", trueFalse: false,
          optionSelected: [
            { option: 'true', show: ['tw_x', 'tw_flip', 'tw_xEase'] },
            { option: 'false', hide: ['tw_x', 'tw_flip', 'tw_xEase'] }],
          class: 'Neon-Betty', label: "Target X position"
        },

        { title: 'tw_x', display: false, type: "number", class: 'Neon-Betty', label: "Target X" },
        {
          title: 'tw_xEase', display: false, type: "Options", class: 'Neon-Betty', label: "Ease",
          options: ["linear", "circ.in", "circ.out", "circ.inout", "bounce.in", "bounce.out", "bounce.inout"]
        },

        { title: 'tw_flip', display: false, type: "switch", class: 'Neon-Betty', label: "Flip X", trueFalse: false },
        { title: "", newLine: true },

        {
          title: 'tw_yTarget', type: "switch", trueFalse: false, label: "Target Y position",
          optionSelected: [
            { option: 'true', show: ['tw_y', 'tw_yflip', 'tw_yEase'] },
            { option: 'false', hide: ['tw_y', 'tw_yflip', 'tw_yEase'] }],
          class: 'Neon-Betty'
        },

        { title: 'tw_y', display: false, type: "number", class: 'Neon-Betty', label: "Y target" },
        {
          title: 'tw_yEase', display: false, type: "Options", class: 'Neon-Betty', label: "Ease",
          options: ["linear", "circ.in", "circ.out", "circ.inout", "bounce.in", "bounce.out", "bounce.inout"]
        },

        { title: 'tw_yflip', display: false, type: "switch", class: 'Neon-Betty', label: "Flip Y", trueFalse: false },
        { title: "", newLine: true },
        {
          title: 'tw_scaleActive', type: "switch", trueFalse: false, label: "Scale",
          optionSelected: [
            { option: 'true', show: ['tw_scaleYoyo', 'tw_scale'] },
            { option: 'false', hide: ['tw_scaleYoyo', 'tw_scale'] }],
          class: 'Neon-Betty'
        },

        { title: 'tw_scale', display: false, type: "number", decimals: 3, class: 'Neon-Betty', label: "Scale" },
        { title: 'tw_scaleYoyo', display: false, type: "switch", class: 'Neon-Betty', label: "Yoyo", trueFalse: false },


        { title: "", newLine: true },
        {
          title: 'tw_rotateActive', type: "switch", trueFalse: false, label: "Rotate",
          optionSelected: [
            { option: 'true', show: ['tw_AngleYoyo', 'tw_AngleFrom', 'tw_AngleTo'] },
            { option: 'false', hide: ['tw_AngleYoyo', 'tw_AngleFrom', 'tw_AngleTo'] }],
          class: 'Neon-Betty'
        },

        { title: 'tw_AngleFrom', display: false, type: "number", class: 'Neon-Betty', label: "Angle from" },
        { title: 'tw_AngleTo', display: false, type: "number", class: 'Neon-Betty', label: "Angle to" },
        { title: 'tw_AngleYoyo', display: false, type: "switch", class: 'Neon-Betty', label: "Yoyo", trueFalse: false },





      ]
      self.tweenEditor = [];
      self.tweenEditor.push(...tweenForm)
      self.form = { name: "Tween", layout: "row wrap", fields: self.tweenEditor, showSubmit: true }


      /*
              xTarget?: {x:number, ease?: string, flip?: boolean};
              yTarget?: {y:number, ease?: string, flip?: boolean};
              scaleTarget?: {scale: number, ease?: string };
              angleTarget?: {angle: number, ease?: string };
      */


    }
    if (self.api == 'Settings') {
      var settings = self.global.allVIPs[self.VIPIndex].sceneScene.settings
      console.log("set: ", settings)
      if (!settings.gravity) settings.gravity = { x: 0, y: 0 }
      self.settingsEditor = [];
      self.settingsEditor.push({
        title: 'Pause', type: "button", class: 'Neon-Betty', label: "Pause/Resume", binding: ""
      })
      self.settingsEditor.push({
        title: 'Restart', type: "button", class: 'Neon-Betty', label: "Restart", binding: ""
      })
      self.settingsEditor.push({
        title: 'Remove All', type: "button", class: 'Neon-Betty', label: "Remove All", binding: ""
      })
      self.settingsEditor.push({
        title: 'New Scene', type: "button", class: 'Neon-Betty', label: "Create New Scene", binding: ""
      })
      self.settingsEditor.push(
        { title: "", newLine: true },

        { title: 'set_name', type: "text", class: 'Neon-Betty', label: "Name", binding: settings.name },
        { title: 'set_width', type: "Options", class: 'Neon-Betty', label: "Window width", options: ['100%', 'Value'], binding: settings.width },
        { title: 'set_height', type: "Options", class: 'Neon-Betty', label: "Window height", options: ['100%', 'Value'], binding: settings.height },
        { title: 'set_worldWidth', type: "number", class: 'Neon-Betty', label: "World width", value: settings.worldWidth },
        { title: 'set_worldHeight', type: "number", class: 'Neon-Betty', label: "World height", value: settings.worldHeight },

        { title: 'set_gravityX', type: "number", decimals: 2, class: 'Neon-Betty', label: "Gravity X", value: settings.gravity.x },
        { title: 'set_gravityY', type: "number", decimals: 2, class: 'Neon-Betty', label: "Gravity Y", value: settings.gravity.y },

        { title: 'set_backColor', type: "number", class: 'Neon-Betty', label: "Background Color", value: settings.backgroundColor },
        { title: 'set_clear', type: "switch", class: 'Neon-Betty', label: "Clear before render", trueFalse: settings.clear },
        { title: 'set_showNames', type: "switch", class: 'Neon-Betty', label: "Names", trueFalse: settings.showNames },
        { title: 'set_avatars', type: "switch", class: 'Neon-Betty', label: "Avatars", trueFalse: settings.avatars },


      )

      self.settingsForm = { name: "Settings", layout: "row wrap", fields: self.settingsEditor, showSubmit: true }


    }

    console.log("Init Editor: ", this.api, this.editor)
    this.dataList = ["Settings", "Tool", "Objects", "Media"]
    if (this.open) {

      this.dataSettings = { width: "50%", backgroundColor: "#bb345a" }
      //   this.phaserGame = new Phaser.Game(this.config);
      console.log("MY VIP INDEX IS: ", this.VIPIndex)
    }

  }

  settingsOutput(form: Form) {
    var self = this;
    console.log("Settings output ", form)

    self.global.allVIPs[self.VIPIndex].sceneSettings =
      self.global.allVIPs[self.VIPIndex].sceneScene.settings;

    form.fields.forEach(function (field) {
      switch (field.title) {
        case 'grp_belong':
          self.global.allVIPs[self.VIPIndex].sceneScene.groups = field.selected;
          console.log("GROUPS: ", field.selected)
          break;

        case 'set_name':
          self.global.allVIPs[self.VIPIndex].sceneSettings.name = field.binding;
          break;
        case 'set_width':
          if (field.binding == 'Value')
            field.binding = field.value;
          self.global.allVIPs[self.VIPIndex].sceneSettings.width = field.binding;
          break;
        case 'set_height':
          if (field.binding == 'Value')
            field.binding = field.value;
          self.global.allVIPs[self.VIPIndex].sceneSettings.height = field.binding;
          break;
        case 'set_worldWidth':
          self.global.allVIPs[self.VIPIndex].sceneSettings.worldWidth = field.value;
          break;
        case 'set_worldHeight':
          self.global.allVIPs[self.VIPIndex].sceneSettings.worldHeight = field.value;
          break;
        case 'set_clear':
          self.global.allVIPs[self.VIPIndex].sceneSettings.clear = field.trueFalse;
          break;
        case 'set_gravityX':
          self.global.allVIPs[self.VIPIndex].sceneSettings.gravity = { x: field.value };
          self.global.allVIPs[self.VIPIndex].phaserGame.scene.scenes[0].matter.world.setGravity(field.value)
          break;
        //  self.global.allVIPs[self.VIPIndex].phaserGame.scene.scenes[0].game.renderer.game.
        case 'set_gravityY':
          self.global.allVIPs[self.VIPIndex].sceneSettings.gravity = { y: field.value };
          self.global.allVIPs[self.VIPIndex].phaserGame.scene.scenes[0].matter.world.setGravity(self.global.allVIPs[self.VIPIndex].sceneSettings.gravity.x, field.value)

          break;
        case 'set_backColor':
          self.global.allVIPs[self.VIPIndex].sceneSettings.backgroundColor = field.value;
          self.global.allVIPs[self.VIPIndex].phaserGame.scene.scenes[0].cameras.main.setBackgroundColor(field.value);
          break;
        case 'set_showNames':
          if (!field.trueFalse) field.trueFalse = false;
          self.global.allVIPs[self.VIPIndex].sceneSettings.showNames = field.trueFalse;
          break;
        case 'set_avatars':
          if (!field.trueFalse) field.trueFalse = false;
          self.global.allVIPs[self.VIPIndex].sceneSettings.avatars = field.trueFalse;

          break;
      }
      //        self.global.allVIPs[self.VIPIndex].myScene.scene.restart();

    })
    // Let's save the new settings for this scene
    console.log("NEW SETTINGS ", self.global.allVIPs[self.VIPIndex].sceneScene)
    self.VIPservice.setSceneSettings(self.global.allVIPs[self.VIPIndex].sceneId, self.global.allVIPs[self.VIPIndex].sceneScene)


  }

  outputEvent(form: Form) {
    var self = this;

    var tween: Tween = {
      duration: 1000, repeat: 1, yoyo: false,
      hold: 0, repeatDelay: 0
    };
    var xTarget: boolean;
    var yTarget: boolean;
    var scaleActive: boolean;
    var rotateActive: boolean;
    console.log("TWEEN...", form)

    form.fields.forEach(function (field) {
      switch (field.title) {

        case 'tw_Duration':
          tween.duration = field.value;
          break;
        case 'tw_Repeat':
          tween.repeat = field.value;
          break;
        case 'tw_Hold':
          tween.hold = field.value;
          break;
        case 'tw_yoyo':
          if (field.trueFalse)
            tween.yoyo = field.trueFalse;
          break;
        case 'tw_repeatDelay':
          if (field.value)
            tween.repeatDelay = field.value;
          break;
        case 'tw_xTarget':
          xTarget = field.trueFalse;
          if (xTarget)
            tween.xTarget = {}
          break;
        case 'tw_x':
          if (xTarget)
            tween.xTarget.x = field.value;
          break;
        case 'tw_xEase':
          if (xTarget)
            tween.xTarget.ease = field.binding;
          break;
        case 'tw_flip':
          if (xTarget)
            tween.xTarget.flip = field.trueFalse;
          break;
        case 'tw_yTarget':
          yTarget = field.trueFalse
          if (yTarget)
            tween.yTarget = {}
          break;
        case 'tw_y':
          if (yTarget)
            tween.yTarget.y = field.value;
          break;
        case 'tw_yEase':
          if (yTarget)
            tween.yTarget.ease = field.binding;
          break;
        case 'tw_yflip':
          if (yTarget)
            tween.yTarget.flip = field.trueFalse;
          break;
        case 'tw_scaleActive':
          scaleActive = field.trueFalse;
          tween.scaleTarget = {}
          break;
        case 'tw_scale':
          if (scaleActive)
            tween.scaleTarget.scale = field.value;

          break;
        case 'tw_scaleYoyo':
          if (scaleActive)
            tween.scaleTarget.yoyo = field.trueFalse;
          break;
        case 'tw_rotateActive':
          rotateActive = field.trueFalse;
          tween.angleTarget = {}
          break;
        case 'tw_AngleFrom':
          if (rotateActive)
            tween.angleTarget.angleFrom = field.value;
          break;
        case 'tw_AngleTo':
          if (rotateActive)
            tween.angleTarget.angleTo = field.value;
          break;


      }
    })

    console.log("New Tween ", form, tween)
    self.updateTweens(tween, self.sceneId, self.global.allVIPs[self.VIPIndex].allObjects)


  }

  updateTweens(tween: Tween, sceneId, objects: VIPObject[]) {
    var self = this;

    objects.forEach(function (object) {
      if (object.selected) {
        object.tweens = []
        object.tweens.push(tween)
        console.log("Update tween: ", object)
        self.VIPservice.updateVIP(sceneId, object)
      }

    })

  }


  setupTools() {
    var self = this;

    self.editor = [];
    self.editor.push({
      title: 'Tool', type: "Options", class: 'Neon-Betty',
      options: ['Image', 'Text', 'Sprite', 'Object Creator', 'Line', 'Rectangle', 'Circle', 'Star', 'Freehand', 'Selector', 'Drag', 'Re-size'], label: "Tool", binding: ""
    })
    if (self.global.allVIPs[self.VIPIndex].tool == 'Text') {
      var textField = {
        title: 'Text', type: "text", class: 'Neon-Betty',
        label: "Enter text"
      }
      self.editor.push(textField)
    }
    if (self.global.allVIPs[self.VIPIndex].tool == 'Object Creator') {
      var imageKeys = {
        title: 'ImageKey', type: "Options", class: 'Neon-Betty',
        options: ['abc'], label: "Current Media", binding: ""
      }
      imageKeys.options = self.global.allImageKeys;
      self.editor.push(imageKeys)

      var objCreator: Field[] = [
        { title: 'Delay', type: "number", class: 'Neon-Betty', label: "Delay" },
        { title: 'Repeat', type: "number", class: 'Neon-Betty', label: "Repeat" },
        { title: 'ImpulseX', type: "number", class: 'Neon-Betty', label: "Impulse X" },
        { title: 'ImpulseY', type: "number", class: 'Neon-Betty', label: "Impulse Y" },

      ]
      self.editor.push(...objCreator)
    }
    if (self.global.allVIPs[self.VIPIndex].tool == 'Image') {
      var imageKeys = {
        title: 'ImageKey', type: "Options", class: 'Neon-Betty',
        options: ['abc'], label: "Current Media", binding: ""
      }
      imageKeys.options = self.global.allImageKeys;
      self.editor.push(imageKeys)
    }

    if (self.global.allVIPs[self.VIPIndex].tool == 'Sprite') {
      var imageKeys = {
        title: 'ImageKey', type: "Options", class: 'Neon-Betty',
        options: ['abc'], label: "Current Media", binding: ""
      }
      imageKeys.options = self.global.allImageKeys;
      self.editor.push(imageKeys)
    }


  }

  output(event) {
    var self = this;

    console.log("Got output: ", self.VIPIndex, event)
    if (!self.global.allVIPs[self.VIPIndex].vipObject)
      self.global.allVIPs[self.VIPIndex].vipObject = { type: "" };
    switch (event.title) {
      case 'Delay':
        self.global.allVIPs[self.VIPIndex].vipObject.delay = event.value;
        console.log("delay: ", self.global.allVIPs[self.VIPIndex].vipObject.delay)
        break;
      case 'Repeat':
        self.global.allVIPs[self.VIPIndex].vipObject.repeat = event.value;
        break;
      case 'ImpulseX':
        if (!self.global.allVIPs[self.VIPIndex].vipObject.impulse)
          self.global.allVIPs[self.VIPIndex].vipObject.impulse = { xv: 0, yv: 0 }
        self.global.allVIPs[self.VIPIndex].vipObject.impulse.xv = event.value;
        break;
      case 'ImpulseY':
        if (!self.global.allVIPs[self.VIPIndex].vipObject.impulse)
          self.global.allVIPs[self.VIPIndex].vipObject.impulse = { xv: 0, yv: 0 }
        self.global.allVIPs[self.VIPIndex].vipObject.impulse.yv = event.value;
        break;

    }
    if (event.title == 'Tool') {
      self.global.allVIPs[self.VIPIndex].tool = event.binding;
      this.setupTools()
    }
    if (event.title == 'Text') {
      self.global.allVIPs[self.VIPIndex].text = event.binding;
      console.log("New text ", event.binding)
    }
    if (event.title == 'ImageKey') {
      self.global.allVIPs[self.VIPIndex].myScene.registry.set('currentImage', event.binding);
      //  self.global.allVIPs[self.VIPIndex].currentImage = event.binding;
    }
    if (event.title == 'Pause') {
      if (!self.global.allVIPs[self.VIPIndex].paused) {
        self.global.allVIPs[self.VIPIndex].myScene.matter.pause();
        self.global.allVIPs[self.VIPIndex].paused = true;
      }
      else {
        self.global.allVIPs[self.VIPIndex].myScene.matter.resume();
        self.global.allVIPs[self.VIPIndex].paused = false;
      }
    }
    if (event.title == 'Restart') {
      if (!self.global.allVIPs[self.VIPIndex].paused) {
        self.global.allVIPs[self.VIPIndex].myScene.scene.restart();

        self.global.allVIPs[self.VIPIndex].myScene.scene.pause();
        self.global.allVIPs[self.VIPIndex].myScene.matter.pause();
        self.global.allVIPs[self.VIPIndex].paused = true;
      }
      else {
        self.global.allVIPs[self.VIPIndex].myScene.scene.restart();
        self.global.allVIPs[self.VIPIndex].paused = false;

      }
    }
    if (event.title == 'Remove All') {
      self.VIPservice.removeAll(self.sceneId, self.global.allVIPs[self.VIPIndex].myScene);
    }
    if (event.title == 'New Scene') {
      self.VIPservice.newScene();
    }

  }
  tabSelected(event) {
    var self = this;

    console.log("Got tab: ", event)
    if (event == 1) self.api = 'Tool'
  }

  createScene(scene) {
    scene.button1 = scene.add.image(100, 10, 'pause').setInteractive();
    scene.button1.on('pointerdown', () => {
      console.log("Pause", this.global.allVIPs[this.VIPIndex]);
      // this.global.allVIPs[this.VIPIndex].pause()
      /*
      if (!this.global.allVIPs[this.VIPIndex].isPaused)
        this.eventEmitter.emit("Pause")
      else
        this.eventEmitter.emit("Play")
      */
    })

    this.createButton(scene, 200, 10, "Restart")




  }
  selectionChanged(event, id) {
    event.stopPropagation()
    var self = this;
    console.log("EVENT/INDEX ", event, id)

    self.global.allVIPs[self.VIPIndex].selectionChanged(id);
    //   self.global.allVIPs[self.VIPIndex].allObjects[index].selected = !self.global.allVIPs[self.VIPIndex].allObjects[index].selected;

  }

  getObjectWhat(object) {
    return object.type;

  }
  getObjectWho(object: VIPObject) {
    var who: string = "";

    if (object.text)
      who = object.text;
    if (object.name)
      who += object.name;
    if (object.imageKey)
      who += object.imageKey;
    return who;

  }
  getObjectWhere(object: VIPObject) {
    var where: string;

    where = object.x.toFixed(2) + ", " + object.y.toFixed(2);
    if (typeof(object.width) =='string')
      where += " " + object.width + 'W'
    else if (object.width)
      where += " " + object.width.toFixed(2) + 'W'
    if (object.height)
      where += " " + object.height.toFixed(2) + 'H'
    if (object.scale)
      where += " " + object.scale.toFixed(2) + 'S'
    if (object.angle)
      where += " " + object.angle.toFixed(2) + 'A'
    if (object.zOrder)
      where += " " + object.zOrder.toFixed(2) + 'D'
    return where;
  }

  removeSelected() {
    var self = this;

    self.global.allVIPs[this.VIPIndex].allObjects.forEach(function (item) {
      if (item.selected) {
        console.log("Remove ", item)
        if (!item.physics) { // HACK to safeguard data
          self.VIPservice.removeID(self.sceneId, item);
        }
      }

    })




  }

  selectNone() {
    console.log("None ")
    this.global.allVIPs[this.VIPIndex].allObjects.forEach(function (item) {
      item.selected = false;
    })
  }
  selectAll() {
    var self = this;
    this.selectNone();
    self.global.allVIPs[self.VIPIndex].allObjects.forEach(function (item) {
      item.selected = true;
    })
    // console.log("ALL ", self.selectedObjects)
  }

  mediaEvents(media: Media) {
    var self = this;

    console.log("Got media event ", media)
    do {
      const num = Math.floor((Math.random() * 1000))
      if (media.fileType.includes('video')) {
        media.imageKey = "Video " + num.toString();
      }
      else
        media.imageKey = "Image " + num.toString();
    } while (self.global.allImageKeys.includes(media.imageKey))


    self.VIPservice.addMediaToPreload(self.sceneId, media, self.global.allVIPs[self.VIPIndex].myScene);
    // self.global.allVIPs[self.VIPIndex].currentImage = media.imageKey;

    //  self.global.allVIPs[self.VIPIndex].myScene.registry.set('currentImage', media.imageKey);

  }

  preloadScene(scene) {
    console.log('preload method');
  }

  //lastTool;

  updateScene(scene) {

    // if (this.tool != this.lastTool) {
    //   this.lastTool = this.tool;
    //   console.log('update tool ', this.tool);
    // }


  }

  moveForward(object: VIPObject) {
    if (!object.zOrder) object.zOrder = 0;
    object.zOrder += 10000;

    let allSprites = this.global.allVIPs[this.VIPIndex].myScene.children.list.forEach(function (item: any) {
      if (item.name == object.id) {
        item.setDepth(object.zOrder)
      }
    })
    console.log("Forward ")


  }

  moveBack(object: VIPObject) {
    if (!object.zOrder) object.zOrder = 0;

    object.zOrder -= 10000;

    let allSprites = this.global.allVIPs[this.VIPIndex].myScene.children.list.forEach(function (item: any) {
      if (item.name == object.id) {
        item.setDepth(object.zOrder)
      }
    })

  }

  createButton(scene, x, y, label) {
    scene.button1 = scene.add.text(x, y, label, { fontSize: '22px', fontFamily: 'Georgia, "Goudy Bookletter 1911", Times, serif' }).setInteractive();

  }

  enablePhysics() {  // TURN ON PHYSICS FOR SELECTED OBJECTS
    this.global.allVIPs[this.VIPIndex].enablePhysics(true);
  }
  disablePhysics() {  // TURN ON PHYSICS FOR SELECTED OBJECTS
    this.global.allVIPs[this.VIPIndex].enablePhysics(false);
  }

  groupsUpdate(form: Form) {
    var self = this;

    var groups: string[];

    form.fields.forEach(function (field) {
      switch (field.title) {
        case 'grp_belong':
          groups = field.selected;
          console.log("GROUPS: ", field.selected)
          break;
      }
    })
    if (self.groups)
      self.global.allVIPs[this.VIPIndex].updateGroups(groups);

  }


  tweens: boolean = false;
  tweenToggle() {
    this.tweens = !this.tweens;
  }
  groups: boolean = false;
  groupsToggle() {
    this.groups = !this.groups;
  }

  selectObject(id) {
    const index = this.global.allVIPs[this.VIPIndex].allObjects.findIndex(function (post) {
      if (post.id == id)
        return true;
    });
    if (index == -1) return null;
    // SELECT THIS OBJECT
    this.global.allVIPs[this.VIPIndex].allObjects[index].selected = true;

    let allSprites = this.global.allVIPs[this.VIPIndex].myScene.children.list.forEach(function (item: any) {
      if (item.name == id) {
        //  item.setFillStyle(0xf4bbdd, .8)
      }
    })


  }


}



