<div [style]="getMyStyle()">
    <div *ngIf="isOverFlow()">
    <div *ngIf="overFlow==true" (click)="toggleExpand(false)">MINIMIZE</div>
    <div *ngIf="!overFlow" (click)="toggleExpand(true)">EXPAND</div>
    </div>


<div *ngIf="style!='pricing'" fxLayout="column" [style]="getBackColor()">
    <p-accordion *ngIf="editReport" expandIcon="false" collapseIcon="false" [multiple]="false">
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header">
                <div class="settings">Settings</div>
            </ng-template>
            <ng-template pTemplate="content">

                <app-form formName="masterReportSettings" [formInput]="report" [autoSubmit]="false"
                    (submitEvent)="outputEvent($event)"></app-form>
                <button pRipple pButton (click)="exportData()">EXPORT</button>
                <div *ngIf="global.authuser.uid=='kvT6eKFxFHb3EKbVeCTusW86CrZ2'">
                    <button (click)="moveReport(report)" class="scott-function" pButton pRipple type="button">
                        Save this report to Master
                    </button>
                </div>

            </ng-template>
        </p-accordionTab>
    </p-accordion>

    <div *ngIf="rows && rows.length" style="overflow-x:scroll;" [style]="getMaxWidth()">
        <div *ngIf="rows && rows.length" (click)="headerClicked($event)"
            style="border-bottom: 3px solid rgb(176, 160, 160);" 
            fxLayout="row" class="sticky">

            <!-- HEADER -->
            <div *ngFor="let col of cols;let i = index;">
                <div [style.width.px]="getCellWidth(i)">
                    <span style="font-weight: 800; font-size:larger; margin-right: 8px;"> {{col.header}}</span>
                </div>
            </div>
        </div>


        <div *ngIf="headerOpen">

            <app-form formName="masterReportSearch" [formInput]="headerSettings" [autoSubmit]="false"
                (submitEvent)="outputEvent($event)"></app-form>

        </div>



        <div [style]="getMaxWidth()" fxLayout="column" fxLayoutAlign="center none">
            <p-accordion expandIcon="false" collapseIcon="false" [multiple]="true"
                *ngFor="let row of rows;let i = index;" [style]="{'width': '100%','border-bottom':'1px sold gray' }"
                fxLayout="row">

                <p-accordionTab [style]="getMaxWidth()">
                    <ng-template pTemplate="header">
                        <div [style.width.px]="getFullWidth()" [style]="checkGrid()" fxLayout="row"
                            fxLayoutAlign="none center">
                            <div *ngFor="let field of row.fields;let i2 = index;" (click)="editRow(i)">
                                <div [style.width.px]="getCellWidth(i2)" style="height:fit-content; margin-right: 6px;">
                                    <div *ngIf="field">
                                        <div *ngIf="field.media" fxLayoutAlign="center center">
                                            <app-media-view maxHeight="100px" [media]="field.media"
                                                [justMedia]="true"></app-media-view>
                                        </div>
                                        <span *ngIf="!field.media && field.binding">
                                            {{ field.binding }}
                                        </span>
                                        <span *ngIf="field.value">
                                            {{ field.value }}
                                        </span>

                                        <div *ngIf="field.type=='multi-select'" fxLayout="row wrap">
                                            <div *ngFor="let item of field.selected" style="margin-right:8px;">
                                                {{item}}
                                            </div>
                                        </div>

                                    </div>
                                    <div *ngIf="report.allowEdits">
<!--
                                        <button style="margin:auto;" (click)="editRow(i)" pButton pRipple
                                            type="button">
                                            Edit
                                        </button>
                                        -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                    <ng-template pTemplate="content">
                        <div *ngIf="editingRow"
                            style="display: inline-block; width: 100%; max-height: auto; height:auto;">

                            <app-form [form]="editForm" [formInput]="editForm" [showSubmit]="true" [autoSubmit]="false"
                                (submitEvent)="editEvent($event)"></app-form>
                        </div>
                    </ng-template>



                </p-accordionTab>
            </p-accordion>
            <!-- ***** ANALYTICS ***** -->
            <div *ngIf="dataAnalytics && dataAnalytics.length" [style.width.px]="getFullWidth()" [style]="checkGrid()" fxLayout="row">
                <div *ngFor="let col of cols;let i = index;">
                    <div [style.width.px]="getCellWidth(i)">
                        <div *ngFor="let item of dataAnalytics[i];" style="overflow-x:scroll;">
                            <div *ngIf="item.type=='selection'">
                                {{item.item+": "+item.count}}
                            </div>
                            <div *ngIf="item.type=='value'">
                                {{"Count: "+item.count}}
                                {{"Total: "+item.total}}
                                {{"Average: "+item.average}}
                                {{"Min: "+item.min}}
                                {{"Max: "+item.max}}

                            </div>
                        
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</div>

<!--

<div *ngIf="style=='pricing' && rows && rows.length">
    <div class="price_table">
        <div *ngFor="let package of rows;let i = index;" style="overflow-x:scroll;">
            <div class="package package_free" style="overflow-x:scroll;">
                <h2>{{getPackageTitle(package)}}</h2>
                <div class="price">$<div class="big">{{getMonthlyPrice(package)}}</div>/mo</div>

                <ul>
                    <div *ngFor="let item of packageItems;let i2 = index;">
                        <li [style]="getLineStyle(i2)">{{item}}</li>
                    </div>

                </ul>
            </div>
        </div>

    </div>
</div>
-->