import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Container, Functions, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { add, setHours, Duration, set, startOfDay, endOfDay, getMilliseconds } from "date-fns";
import { FormService } from '../form.service';
import { HttpClient } from '@angular/common/http';


export const removeEmptyValuesForLoop = (object) => {

  for (let key in object) {
    if (!object[key]) delete object[key];
  }
  return object;
};


@Component({
  selector: 'app-form-edit',
  templateUrl: './form-edit.component.html',
  styleUrls: ['./form-edit.component.css', '../../common.scss']
})
export class FormEditComponent implements OnInit {
  @Input() form: Form;
  @Input() formName: string;
  @Input() function: string;
  @Input() open: boolean;
  @Input() api: string;
  @Input() formIndex: number;

  formEditList: string[];
  fieldList: string[];
  addingField: boolean = false;
  addingForm: boolean = false;
  list: string[];
  myIndex: number;


  formEditSettings: ContainerSettings;

  currentOption: string;




  

  constructor(public global: Globals, private http: HttpClient, private formService: FormService) {

  }

  ngOnInit(): void {
    var self = this;
    console.log("FORM EDIT: ", this.formIndex, this.formName, this.open, this.api)

    if (this.open) {
      this.myIndex = this.formIndex;
      this.formEditList = ["Form Settings", "Fields", "Logic", "Database", "Analytics"];
      this.formEditSettings = { width: "60%", backgroundColor: "#B1DF11", color: 'black' }

      if (this.formName) {

        const index = self.global.allForms.findIndex(function (item) {
          if (item.name == self.formName)
            return true;
        });
        if (index != -1) {

          self.form = self.global.allForms[index];
          self.formIndex = index;
          self.myIndex = index;
        }
      }
      else {
        if (!self.form && self.formIndex) {
          self.form = self.global.allForms[self.formIndex];
        }
      }
/*
      if (!self.form && !self.formIndex && self.formName) {
         // All we got is a name
        console.log ("Load just this form as observer. ",self.global.allForms)
        self.global.allForms.forEach(function(form, index){
          if (form.name == self.formName) {
            self.form = form;
            self.formIndex = index;
          }
          else console.log (form.name, self.formName)

        })
      }*/
      console.log("FORM-EDIT: ", self.form, self.formName,self.formIndex)

    }




  }

  tabOpened(event) {
    var data = <Container>event;

    this.currentOption = this.formEditList[data.index]

    console.log("FORM EDITOR TAB OPENED ", this.api, data.node, data.index)
  }

  deleteData(form: Form) {
    console.log("Deleting this record ", form)
    var self = this;
    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(form.formId).collection("submissions").doc(form.id)


    form.deleted = true;
    docRef.update(form).then(() => {
      self.clearForm(form)
      console.log("DATA deleted");

    })

  }
  clearForm(form) {
    var self = this;

    form.id = null; // NO DATA HERE

    form.fields.forEach(function (field) {
      field.binding = null;
      field.value = null;
      field.media = null;
      field.selected = [];

    })
  }

  randomData(form: Form) {
    var self = this;
    var db = firebase.firestore();

    var fields: Field[];

    var rows = [];

    if (!form.formId) form.formId = form.id;

    for (var i = 0; i < 10; i++) {
      fields = [];

      var days = Math.random() * 100;
      var hours = Math.floor(Math.random() * 9)
      var minutes = Math.floor(Math.random() * 60)
      var seconds = Math.floor(Math.random() * 60)

      var dur: Duration = {
        days: -days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
      }

      var dayStart = Date.now();
      setHours(dayStart, 9)
      const result = add(dayStart, dur);
      const dateTime = result.getTime()
      form.timeStamp = dateTime;

      form.fields.forEach(function (field) {
        field.selected = [];
        // var newField = { ...field }

        // RANDOMIZE THE DATA WITHIN RANGES
        switch (field.type) {
          case 'number':
          case 'currency':
            const value = Math.floor(Math.random() * 1000) + 1;
            field.value = value;
            //field.binding =  value;
            break;
          case 'select-button':
            const index = Math.floor(Math.random() * field.options.length);
            field.selected = [];
            field.selected.push(field.options[index])
            field.binding = field.options[index];
            break;
          case 'multi-select':
            var count = Math.floor(Math.random() * field.options.length) + 1;
            for (var n = 0; n < count; n++) {
              const index = Math.floor(Math.random() * field.options.length);
              if (!field.selected.includes(field.options[index]))
                field.selected.push(field.options[index]);
            }
            break;
        }
        // fields.push(field)
      })
      rows.push(fields)
      // NOW WE HAVE A ROW OF DATA TO SAVE
      //  form.fields = { ...fields };

      var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
        .doc(form.formId).collection("submissions").doc()

      docRef.set(form).then(() => {
        console.log("DATA ADDED ", form);
      })

    }
    console.log("Random Data: ", rows)

  }

  updateData(form: Form) {
    // DO NOT UPDATE IF NO DATA SAVE
    if (!form.autoSave && !form.showSubmit) return;

    console.log("WE ARE UPDATING THE SUBMISSION ", form)
    var self = this;
    var db = firebase.firestore();
    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(form.formId).collection("submissions").doc(form.id)

    docRef.update(form).then(() => {
      console.log("DATA updated");
    })

  }

  /*
  addField() {
  
    this.addingField = !this.addingField;
  
  }
  
  addFieldToForm(event, form: Form, field: Field) {
    event.stopPropagation();
  
    console.log("Form & field add ", form, field)
    form.fields.push(field);
    this.global.fields.push(field)
    this.updateForm(form);
  
  
  }
  
  
  updateForm(event: Form) {
    var self = this;
    var db = firebase.firestore();
  
  
    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(event.id)
  
    docRef.update(event).then(() => {
      console.log("form updated");
  
      // Hack find and update global
      const index = self.global.allForms.findIndex(function (post) {
        if (post.id == event.id)
          return true;
      });
      if (index != -1) {
        self.global.allForms[index] = { ...event }
        console.log("Bull: ", self.global.allForms[index])
      }
  
      // Set up fields
      self.list = [];
      self.global.allForms[self.global.formIndex].fields.forEach(function (item) {
        self.list.push(item.title)
      })
  
  
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  
  }
  */

  moveForm(formName: string) {
    var self = this;

    var db = firebase.firestore();

    db.collection("company").doc(this.global.myCompany.id).collection("forms")
      .where("name", "==", formName)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Form>doc.data();
          console.log("Form to Master")

          // Now create record in Master
          var ref1 = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("forms").doc()
          data.id = ref1.id;
          ref1.set(data)
        })

      })
      .catch((error) => {
        console.log("no user found: ", error);
      });
  }
  ChatBot() {
    const formData: FormData = new FormData();

    formData.append("prompt", "what is the sum of all fears");

    this.http.post('https://generateanswer-xdnunfyrka-uc.a.run.app',
      { prompt: "What is unique" }).subscribe({
        next: response => console.log('File sent', response),
        error: error => console.error('Error sending file', error)
      });

  }
  createFunction(formIndex) {
    var self = this;
    var newFunc: Functions;
    newFunc = {}

    self.formService.napkinFieldsToObject(self.global.allForms[formIndex], newFunc)

    // FIX - fieldstoObject - do not create empty arrays???

        // GET RID OF EMPTY DATA
    newFunc = removeEmptyValuesForLoop(newFunc)

    if (!newFunc.name || !newFunc.tags) {
      console.log ("No Function created - missing info")
      return;
    }


    console.log("New function: ", newFunc, self.global.allForms[formIndex])

    var db = firebase.firestore();

    // Now create record in Master
    var ref1 = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("functions").doc()
    newFunc.id = ref1.id;
    ref1.set(newFunc)
      .catch((error) => {
        console.log("newfunc error: ", error);
      });
  }


}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
