<div style="max-width:100vw; margin:0px;padding:0px;">

    <button *ngIf="global.authuser.userFeature.addStore && addStore==false" class="function" pButton pRipple type="button" (click)="createStore()">Create
        Store</button>

    <div *ngIf="global.authuser.userFeature.addStore && addStore">
        <app-form formName="masterStartMakerTrial"></app-form>
    </div>

    <div *ngIf="global.authuser.creds && global.authuser.creds.length">

        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
            *ngFor="let cred of global.authuser.creds; let i = index;">
            <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="center center">
                <p-accordionTab iconPos="end" [(selected)]="activeStore[i]">
                    <ng-template pTemplate="header">
                        <div class="user-feature" [style]="getStoreStyle(i)" fxLayout="row wrap"
                            fxLayoutAlign="center center">
                            {{cred.storeName}}
                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <button class="login-button" pButton pRipple type="button"
                                (click)="LoginAdmin(cred.storeNum)">Admin
                                Login</button>

                        </div>
        <!--
                        <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="center center">
                            <div *ngIf="1 || global?.publicApps">

                                <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
                                    (onOpen)="getMyApp($event, i)" *ngFor="let app of global.publicApps; let i = index;"
                                    fxLayout="column" fxLayoutAlign="center center">

                                    <div *ngIf="app?.storeNum == cred.storeNum">
                                        <p-accordionTab iconPos="end" *ngIf="showApp(i)" [(selected)]="openApp[i]">
                                            <ng-template pTemplate="header">
                                                <div fxLayout="column" fxLayoutAlign="center center" class="app-name">
                                                    <div [style]="getAppNameStyle(i)">{{app.name}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <div class="user-feature" [style]="getAppNameStyle(i)">
                                                    <app-appMaker [app]="app" [editing]="true"
                                                        [companyId]="app.companyId"></app-appMaker>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                    </div>
                                </p-accordion>

                            </div>

                        </div>
                        -->

                    </ng-template>
                </p-accordionTab>
            </div>
        </p-accordion>
    </div>
</div>