import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";

import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, Navigate, Reviews, Review } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { MediaService } from "../media//media-functions/media-functions"
import { Quote } from '../content-interface';
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'


@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss', '../../common.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReviewComponent implements OnInit {
  @Input() store: string;

  contact: Contact;
  storeNum: string;
  reviewContent: string;
  starCount = 0;
  starColor = 'white';
  newReview: Review;
  reviewAdded: boolean = false;
  image: string;
  uploadingMedia: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  newMedia: Media;
  quote: Quote;
  reviewId: string;



  transformationOne = [{ width: "300" }];
  transformationTwo = [
    { height: "200", width: "200" },
    {
      rotation: "90"
    }
  ];

  path = "/default-image.jpg";
  notifyUsers: User[];

  private needRefresh = false;
  ngAfterViewChecked(): void {

  }

  constructor(private route: ActivatedRoute, public global: Globals,
    public nav: NavService, private sanitizer: DomSanitizer,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {

    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER
    this.storeNum = this.route.snapshot.paramMap.get('store');

    if (this.storeNum == "9844") this.storeNum = "4390";


    if (this.store) this.storeNum = this.store;

    console.log("Store: ", this.storeNum, this.global);
    firebase.initializeApp(environment.firebase);

    if (!global.authuser) {
      const id = localStorage.getItem('uid');
      if (id) {
        this.nav.getUserOnlywID(id, false, null, this, this.afterUserLoad);
      }
    }

    if (this.global.myCompany && this.global.myCompany.store == this.storeNum) { }
    else
      this.nav.GetStoreOnly(this.storeNum, this.gotStoreCallback, this);


    this.newReview = {}

  }
  uid = "Anon";
  ngOnInit(): void {

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    if (typeof this.global.authuser != 'undefined') this.uid = this.global.authuser.uid;

    if (!this.store) this.store = "????"

    Logger("Review Started", this.uid, this.storeNum)


  }

  afterUserLoad(self: this) {
    console.log("Got user callback ", self.global.authuser);

    // if (self.vcode) { self.validateUser(); 
  }

  gotStoreCallback(self: this) {

    // MAYBE?  self.formService.getForms();

  }

  isEmailFound(event) {

    console.log("Email found", event);

  }


  star(star) {
    console.log("stars: ", star);
    this.starCount = star;
    switch (star) {
      case 1: this.starColor = 'red'; break;
      case 2: this.starColor = 'orange'; break;
      case 3: this.starColor = 'rgb(0, 255, 63)'; break;
      case 4: this.starColor = '#01E7FE'; break; //  'rgb(0, 255, 255)'; break;
      case 5: this.starColor = '#004FE4'; break; //  'rgb(255, 0, 255)'; break;

    }

  }

  getCaptureType() {
    var str = "environment"
    return str;
  }

  getMediaTypes() {
    var self = this;

    var str = "image/*";
    //   if (self.global.myCompany.reviewSetup.videoReviews==true)
    //     str = "image/*;video/*;"
    return str;

  }

  mediaText() {
    var str = "Add/Take Photos"

    if (this.global.myCompany.reviewSetup.videoReviews)
      str = "Add/Take Photos or Create a Video Review"
    return str;
  }

  getBackbroundColor(me) {
    if (this.starCount >= me) return this.starColor;
    else return 'white';


  }
  /*
    upload(event: any) {
      var self = this;
  
      var fn = event.target.value.lastIndexOf("\\");
      console.log(fn);
      var imageName = event.target.value;
      imageName = imageName.slice(fn + 1);
  
      console.log("uploading image ", imageName);
  
      //  var index = self.messages.length;
  
      var rnd: string = (Math.floor(Math.random() * 10000)).toString();
  
      const filePicker = document.querySelector('imageLoad'); //HACK use id
  
  
      console.log("Image: ", event, filePicker);
      // Now get reference to upload
  
      const ref = firebase.storage().ref();
      const file = event.target.files[0];
      //const name = (+new Date()) + '-' + file.name;
      const metadata = {
        contentType: file.type
      };
  
      const name = ('review-images/' + imageName + rnd);
  
      const task = ref.child(name).put(file, metadata);
      task
        .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          console.log(url);
          self.newReview.review_photos = url;
          self.image = url;
          //    self.SendMsg(index);
          //    self.dataMode = "";
          // this.updateMessage(chat, this.currentGroupId);
  
        })
        .catch(console.error);
  
    }
  */



  upload(event: any) {
    var self = this;

    self.uploadingMedia = true;
  //  self.mediaService.newUpload(event, "Both", self.uploadCallback, self)
  }

  uploadCallback(self, url, file, name, newMedia) {
    console.log("Callback after media uploaded ", url, file, name)
    Logger("Media Upload - review", self.global.authuser.uid, self.global.myCompany.store, url, name)

  //  self.newReview.fileType = file.type;
   // self.createMedia(url, file, name);
    if (!self.newReview.media) self.newReview.media = [];
    self.newReview.media.push(newMedia);
  // SAVED WHEN SUBMITTED  
  self.addMediaToReview(self.newReview.media);
    self.uploadingMedia = false;
  }


  setContact(info: Contact) {
    var self = this;

    // Got results back so let's save them
    console.log("Info: ", info);
    this.contact = info;
    if (!self.global.authuser) {
      console.log("contact ", self.contact)

      if (!this.global.authuser) {
        if ((!this.contact.firstName || this.contact.firstName.length == 0)
          || !this.contact.lastName || this.contact.lastName.length == 0
        ) { alert("Please enter full name."); return; }
        //  if (!ValidateEmail(this.Email)) return;
        // if (!validatePhoneNumber(this.Phone)) return;
        var newUserID = this.registerService.GetUID();
        this.registerService.RegisterUser(this.contact.email, this.contact.firstName, this.contact.lastName);

      }

    }

  }

  submitReview() {
    var self = this;

    if (!self.global.authuser) {
      alert("Please add email")
      return;
    }

    if (!self.starCount) {
      alert("Please choose a rating");
      return;
    }
    if (!self.reviewContent || this.reviewContent.length < 10) {
      alert("Please enter feedback");
      return;
    }

    Logger("Submit Clicked - review", self.global.authuser.lastName, self.global.myCompany.store)

    console.log("SUBMIT: ", self.global.authuser)

    self.newReview.author_name = self.global.authuser.firstName + " " + self.global.authuser.lastName;
    var newDate = Date.now();
    const sentAt = Timestamp.now();

    self.newReview.dateStr = self.prettyDateTime(sentAt);
    self.newReview.date = newDate.toString();
    self.newReview.timeStamp = Date.now();

    self.newReview.review_rating = self.starCount.toString();
    self.newReview.review_text = self.reviewContent;
    self.newReview.status = 'pending';
    self.newReview.source = 'Napkin';
    self.newReview.authorId = self.global.authuser.uid;

    self.addReview(self.newReview);

    self.GetInspiration();

  }
/*
  createMedia(photo: string, file: any, name: string) {
    var self = this;

    // Create URL for thumbnail
    var fn = photo.lastIndexOf("review-images");


    var thumb = "thumb%2fthumb_" + photo.slice(fn + 16);
    var thumbStr = photo.slice(0, fn + 16) + thumb;

    // swap the extension if .MOV
    var hasExt = thumbStr.indexOf('.MOV');
    if (hasExt != -1) {
      //off now   thumbStr = thumbStr.replace(".MOV", ".JPG");
    }

    var small = "small%2fsmall_" + photo.slice(fn + 16);
    var smallStr = photo.slice(0, fn + 16) + small;
    // swap the extension if .MOV
    var hasExt = smallStr.indexOf('.MOV');
    if (hasExt != -1) {
      //   smallStr = smallStr.replace(".MOV", ".jpg");
    }


    var med = "med%2fmed_" + photo.slice(fn + 16);
    var medStr = photo.slice(0, fn + 16) + med;

    console.log("Sizes: ", fn, medStr, smallStr);

    self.newMedia = {
      //   fileId?: string;
      name: name,
      store: self.storeNum,
      updatedAt: Date.now(),
      createdAt: Date.now(),
      altDesc: "image desc",
      url: photo,
      thumbnailUrl: thumbStr,
      smallUrl: smallStr,
      medUrl: medStr,
      // height?: number;
      //  width?: number;
      //  size?: number
      tags: [self.global.myCompany.name], // Add Review when published
      //  format?: string;
      fileType: file.type,
    }

    console.log("media ", self.newMedia);


    console.log("Media added. ", self.newMedia.smallUrl);
  }
  */
  /*
    addImageToCompany(media: Media) {
      var self = this;
      var db = firebase.firestore();
      console.log("Store Media add ", media)
  
      // add Media to Company as sub-collection
      var ref2 = db.collection("company").doc(this.global.myCompany.id)
        .collection("media").doc().set(media);
  
      // All done!
    }
  */

  addMediaToReview(media: Media[]) {
    var db = firebase.firestore();
    var self = this;

    if (self.reviewId) {
      var db = firebase.firestore();

      var reviewRef = db.collection("company").doc(self.global.myCompany.id).collection("reviews").doc(self.reviewId);

      reviewRef.update({ media: media })
        .then(() => {
          Logger("Media Added", self.global.authuser.uid, self.global.myCompany.store)
          console.log("Media Added!", media);
          self.reviewId = null;
        })
        .catch((error) => {
          Logger("Review Error", self.global.authuser.uid, error, self.global.authuser.lastName);
          // The document probably doesn't exist.
          console.error("Error adding media: ", error);
        });
    }
  }

  addReview(r: Review) {
    var self = this;
    console.log("Saving New Review ", r, self.global.myCompany.id);

    var db = firebase.firestore();

    var reviewRef = db.collection("company").doc(self.global.myCompany.id).collection("reviews").doc();
    //  reviewRef.id = reviewRef.id;

    reviewRef.set(r)
      .then(() => {
        Logger("Review Added", r.authorId, this.store, r.review_rating, self.reviewContent)

        console.log("review Added!", r);
        self.reviewAdded = true;
        if (self.uploadingMedia && !r.media)  // Prepare to update review with media
          self.reviewId = reviewRef.id;
        self.notifyReviewEmail(self.newReview);

      })
      .catch((error) => {
        Logger("Review Error", r.authorId, error, self.global.authuser.lastName)

        // The document probably doesn't exist.
        console.error("Error adding review: ", error);
      });

  }

  badwords = ['fuck', 'cunt', 'asshole', 'shit', 'jackoff', 'shitty', 'fuk', 'fucking', 'boob']
  checkReview() {  // Profanity filter
    var self = this;


    if (!this.reviewContent) return;
    if (this.reviewContent.length == 0) return;

    this.badwords.forEach(function (item) {
      if (self.reviewContent.includes(item)) {

        while (self.reviewContent.indexOf(item) !== -1) {
          self.reviewContent = self.reviewContent.replace(item, '');

        }
        Logger("badword!", self.uid, self.storeNum)
        console.log("Bad word removed ")


      }

    })



  }


  /*
    UpdateReviews(r: Review[]) {
      var self = this;
      console.log("Saving Reviews ", r, self.global.myCompany.id);
  
      var db = firebase.firestore();
      var ref = db.collection("reviews").where("companyID", "==", self.global.myCompany.id);
  
      ref.get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            // First Review!!!   //create reviews 
            // then save new review
            var ref2 = db.collection("reviews").doc(self.global.myCompany.id);
            ref2.set({
              companyID: ref2.id,
              reviews: r,
  
            })
              .then(() => {
                console.log("reviews created.",);
                self.reviewAdded = true;
              })
              .catch((error) => {
                console.error("Error sin reviews", error);
              });
          }
  
          querySnapshot.forEach((doc) => {
            console.log("Merging ", r, querySnapshot.size);
            // Now save new reviews
            var ref2 = db.collection("reviews").doc(doc.id);
  
            ref2.update({
              reviews: firebase.firestore.FieldValue.arrayUnion(...r) // Spread operator
            })
              .then(() => {
                console.log("reviews updated.", r);
                self.reviewAdded = true;
              })
              .catch((error) => {
                console.error("Error savin reviews", error);
              });
          })
        })
    }
  */
  handleUploadSuccess(res: Media) {
    console.log('File upload success with response: ', res);
    this.newReview.review_photos = [];
    this.newReview.review_photos.push(res.url)
    this.image = res.thumbnailUrl;
    if (res.format)
      this.newReview.format = res.format;
    if (res.fileType)
      this.newReview.fileType = res.fileType;
    this.uploadingMedia = false;


  }

  handleUploadError(err) {
    console.log('There was an error in upload: ', err);
    alert("There was an error " + err);
  }

  handleFileInput(event) {
    console.log('This is the event on file change: ', event);
    this.uploadingMedia = true;
  }


  public notifyReviewEmail(r: Review) {
    var self = this;

    // Look Up all users with creds - should be done on server

    var self = this;
    var db = firebase.firestore();

    this.notifyUsers = [];
    console.log("Review: ", r);

    db.collection("users").where("creds", "!=", "null")
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();

          data.creds.forEach(function (cred) {
            if (cred.id == self.global.myCompany.id
              && cred.notify && cred.notify.enable && cred.notify.reviews) {
              self.notifyUsers.push(data);

              // NOTIFY STAFF
              var templateParams = {
                from_name: "Napkin Reviews",  // Company Name
                to_name: data.firstName,
                to_email: data.email,
                msgDate: r.date,
                fullName: r.author_name,
                content: r.review_text,
                storeName: cred.storeName,
                reviewImage: "",
                canvas: "",
                xcode: "goobers",
                storeAddress: self.global.myCompany.address1 + " " + self.global.myCompany.city + " " + self.global.myCompany.state
              };
              if (r.review_photos) {
                templateParams.reviewImage = r.review_photos[0];
                templateParams.canvas = r.review_photos[0];
              }
              //    headers:{"Content-ID":"<my-image>"}
              // message.attach({path:r.review_photos[0], type:"image/gif", headers:{"Content-ID":"canvas"}});

              //  e.preventDefault();
              emailjs.send("service_jwders9", "template_isj7kxf", templateParams)
                .then((result: EmailJSResponseStatus) => {
                  console.log(result.text);
                }, (error) => {
                  console.log(error.text);
                });

            }
          })
        });
        console.log("Notify users of the review: ", self.notifyUsers);

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });



  }

  prettyDateTime(date: Timestamp): string {

    let d = new Date(date.toDate());
    return dateFormatter(d);
  }

  GetInspiration() {
    var self = this;
    var db = firebase.firestore();

    var r = <number>Math.floor(Math.random() * 1644);

    db.collection("quotes").where("index", "==", r)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Quote>doc.data();
          self.quote = data;
        })
      })


  }





}


function dateFormatter(date: Date) {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true
  };

  return date.toLocaleDateString('en-US', timeformat) + " " + TimeFormat(date.getTime());
}

function TimeFormat(time: number) {

  var d = new Date(time);
  let hours = d.getHours();
  // Determine AM or PM suffix based on the hour
  const suffix = (hours < 12) ? "am" : "pm";

  // Convert hour from military time
  hours = (hours < 12) ? hours : hours - 12;

  // If hour is 0, set it to 12
  hours = hours || 12;

  var min = d.getUTCMinutes();
  var minText = min.toString();
  if (min < 10) minText = "0" + min.toString();
  if (min == 0) minText = "00 ";


  return hours + ":" + minText + suffix;

}


function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You've entered an invalid email address!")
  return (false)
}
