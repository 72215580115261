<div *ngIf="!global.authuser">
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">
    
        <div *ngIf="global.myCompany && global.myCompany.logoImg" class="co-img" style="height:100px; ">
            <img class="logo-image" src={{global.myCompany.logoImg}} alt="Logo for {{global.myCompany.name}}">
        </div>
    
        <div *ngIf="!registered">
            <div class="promo-text">
                Earn free Perks and Special Offers<br>
    
                Participate in member-only events<br>
    
                Track orders, reservations and more<br><br>
    
            </div>
    
            <SignIn startText="Join Perks" (setContactEvent)="setContact($event)" (afterLoginEvent)="afterLogin()"></SignIn>
            <br> <br><br>
        </div>
    
        <div *ngIf="registered" fxFlex fxLayout="column" fxLayoutAlign="center center">
            <div style="font-size: 150%; text-align: center;">
                Thanks! <br>Check your email<br>for validation link.
            </div>
            <div *ngIf="quote">
                <div class="card__content">
                    <article>
                        <div class="card__desc">
                            <blockquote>{{quote.text}}</blockquote>
    
    
                        </div>
                        <p class="author">{{quote.author}}</p>
                    </article>
                </div>
    
            </div>
            <br> <br><br>
            <div  style="font-size: 100%; text-align: center;">
                QR code to let someone else join Perks<br></div>
            <img [src]="getJoinCode()" alt="QR code to join" title="" />
            <br><br>
        </div>
    
    </div>
    </div>
    <div *ngIf="global.authuser">
    <br> <br><br>
    <div  style="font-size: 100%; text-align: center;">
        QR code to let someone else join Perks<br></div>
    <img [src]="getJoinCode()" alt="QR code to join" title="" />
    <br><br>
    </div>
