<div *ngIf="liveChat">




    <div *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.chatService">

        <div class="pretty-chat" fxLayout="column" fxLayoutAlign="none center">
            <div>Chat with {{global.myCompany.name}}</div>
        </div>
    </div>
    <div *ngIf="!global.authuser">


        <SignIn (setContactEvent)="setContact($event)" (afterLoginEvent)="afterLogin()"></SignIn>

    </div>
    <div style="width: 100%">
        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false" (onOpen)="onTabClick()">

            <p-accordionTab *ngIf="allowNewConnection">
                <ng-template pTemplate="header">
                    <div class="pretty" style="width:100%">New Connection</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="p-fluid" fxFlex fxLayout="column" style="width:100%">

                        <!--   <input id="email" type="email" placeholder="Enter recipient Email" class="text-entry" pInputText
                [(ngModel)]="newMsg.to" />-->

                        <tag-input style="width: 100%" [(ngModel)]='groupList' [allowDupes]="false"
                            [separatorKeyCodes]="[32]" [placeholder]="'Add Emails, Space or Enter'"
                            [secondaryPlaceholder]="'Add Emails with Space or Enter'"></tag-input>

                        <div style="width:100%">
                            <!--
            <textarea pInputTextarea placeholder="Message" [rows]="3" [cols]="30" [autoResize]="true"
                    id="fullDescription" type="text" class="text-entry" [(ngModel)]="newMsg.msg">
            </textarea>
-->
                            <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                (click)="createGroup()">Start Group Conversation</div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>



        <p-accordion styleClass="tab" [multiple]="false" expandIcon="false" collapseIcon="false"
            *ngFor="let conversation of groups; let i = index;" (onOpen)="onChatOpen($event, i)">

            <p-accordionTab [selected]="activeChat[i]">
                <ng-template pTemplate="header">
                    <div fxFlex fxLayout="row" fxLayoutAlign="space-between none">


                        <div class="group-name">{{GetGroupName (i)}}
                            <span *ngIf="conversation.newMessages">
                                ({{conversation.newMessages}})
                            </span>
                        </div>

                        <div class="group-timer">{{GetGroupDept(i)}} {{GetGroupTimer(i)}}

                        </div>

                    </div>

                </ng-template>
                <ng-template pTemplate="content">
                    <div class="card">
                        <div *ngIf="dataMode=='address'">
                            <div *ngIf="!newMsg.Lat" fxLayout="row" fxLayoutAlign="space-between none">
                                <div fxFlex="75%">
                                    <input style="width:100%" placeholder="Address, Zipcode or Place name" type="text"
                                        pInputText [(ngModel)]="newMsg.mapAddress" />

                                </div>
                                <div fxFlex="25%" fxLayout="row">
                                    <div class="cancel-button" (click)="cancel()">
                                        X</div>
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="getMap(chat, i)">
                                        GetMap</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="dataMode=='image'" style="margin-bottom: 12px;">

                            <div fxFlex="75%" fxLayout="row">
                                <input id="imageLoad{{i2}}" type="file"
                                    accept="image/x-png,image/jpeg,image/gif,image/heic,image/mp4"
                                    (change)="upload($event, newMsg)" />

                                <input placeholder="enter URL" type="text" pInputText [(ngModel)]="newMsg.media" />

                            </div>
                            <div fxFlex="25%" fxLayout="row">
                                <div class="cancel-button" (click)="cancel()">
                                    X</div>
                                <div class="action-button3"
                                    style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                    (click)="addImage(chat, i)">
                                    Add Image</div>
                            </div>


                        </div>

                        <div *ngIf="dataMode=='search'">
                            <app-search (setInfoEvent)="setInfo($event)" (setQueryEvent)="setQuery($event)"
                                (setResultEvent)="setResult($event)" [cancelIcon]="true"></app-search>
                        </div>
                        <div *ngIf="dataMode=='sticky'">
                            <div class="sticky">
                                <div class="sticky__title">
                                    <input placeholder="Add Title" type="text"
                                        style="text-align: center; font-size: 1.2rem; background-color:yellow"
                                        pInputText [(ngModel)]="newMsg.stickyTitle" />
                                </div>
                                <tag-input inputClass="sticky__text" style="background-color:yellow"
                                    [(ngModel)]='stickyNotes' [allowDupes]="false"
                                    [placeholder]="'Add notes press Enter when done'"
                                    [secondaryPlaceholder]="'Add notes press Enter when done'"></tag-input>
                                <div fxLayout="row">
                                    <div class="action-button3"
                                        style="width: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="sendSticky()">
                                        Send Sticky</div>
                                    <div class="cancel-button" (click)="cancel()">
                                        X</div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="dataMode=='functions'">
                            <p-sidebar [(visible)]="viewFunctions" [modal]="false" position="left" [autoZIndex]="false"
                                [fullScreen]="false" (onHide)="hideFunctions()">
                                Available Functions
                                <div style="width: 100%; cursor:pointer;" fxFlex fxLayout="row wrap"
                                    fxLayoutAlign="space-between space-between">
                                    <div *ngFor="let f of chatFunctions; let i = index;" class="command"
                                        (click)="setFunction($event.target.innerHTML)">
                                        {{f}}

                                    </div>
                                </div>
                            </p-sidebar>

                        </div>


                        <div *ngIf="dataMode=='video'">
                            <p-sidebar [(visible)]="visible" [modal]="false" position="left" [autoZIndex]="false"
                                [fullScreen]="false" (onHide)="hideFindVideo()">

                                <div class="gallery" fxFlex fxLayout="column">
                                    <input type="text" pInputText [(ngModel)]="videoSearch" />
                                    <div class="command" (click)="FindVideo(videoSearch)">Search Videos</div>
                                    <div fxLayout="row wrap" fxLayoutAlign="none none">
                                        <div style="margin: 12px;" *ngFor="let v of videos; let i = index;">
                                            <video style="max-width:100%; max-height:300px;" playsinline
                                                webkit-playsinline autoplay [muted]="'muted'" controls id="myVideo">
                                                <source src="{{v}}" type="video/mp4">
                                            </video>
                                            <div class="action-button3"
                                                style="width: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                                (click)="ChooseVideo(v)">
                                                Choose Video</div>

                                        </div>
                                    </div>
                                </div>
                            </p-sidebar>
                        </div>




                        <!-- Show Chat entry if not other mode -->
                        <div *ngIf="dataMode==''">
                            <div *ngIf="newMsg" fxLayout="row" fxLayoutAlign="space-between none">
                                <div fxFlex="85%" style="position:relative">
                                    <textarea pInputTextarea [placeholder]="getChatPlaceHolder()" [rows]="3"
                                        [autoResize]="false" id="fullDescription" type="text" class="text-entry"
                                        [(ngModel)]="newMsg.msg">
                                        </textarea>
                                    <div *ngIf="!conversation.storeID">
                                        <div *ngIf="newMsg.msg.length==0" class="command-button"
                                            (click)="functionMode()">
                                            Functions
                                        </div>
                                    </div>
                                </div>
                                <div fxFlex="15%">
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="SendMsg(i)">
                                        Send</div>
                                </div>
                            </div>


                        </div>

                        <div *ngIf="newMsg.msg.length==0" style="width: 100%;" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="space-between center">
                            <div class="command" (click)="Clicked(conversation, $event.target.innerHTML)">Finished</div>
                            <div class="command" (click)="Clicked(conversation,$event.target.innerHTML)">Waiting</div>
                            <div class="command" (click)="Clicked(conversation,$event.target.innerHTML)">Active</div>
                            <div class="command" (click)="Clicked(conversation,$event.target.innerHTML)">Route</div>

                        </div>

                        <div *ngIf="activeTagging" fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">

                            <div *ngFor="let t of global.myCompany.activeTags; let i = index;">
                                <div class="command" (click)="setActiveTag(conversation, t)">{{t}}</div>
                            </div>

                            <tag-input style="width: 100%" [(ngModel)]='global.myCompany.activeTags'
                                [allowDupes]="false" [separatorKeyCodes]="[32]"
                                [modelAsStrings]="true"
                                (onAdd)="onTagAdded($event)"
                                [placeholder]="'Add Tags, Space or Enter'"
                                [secondaryPlaceholder]="'Add Tags with Space or Enter'"></tag-input>
                        </div>


                        <div *ngIf="routing" fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">

                            <div *ngFor="let t of global.myCompany.departments; let i = index;">
                                <div class="command" (click)="RouteDepartment(conversation, t)">{{t}}</div>
                            </div>

                            <tag-input style="width: 100%" [(ngModel)]='global.myCompany.departments'
                                [allowDupes]="false" [separatorKeyCodes]="[32]"
                                [placeholder]="'Add Departments, Space or Enter'"
                                [modelAsStrings]="true"
                                [secondaryPlaceholder]="'Add Departments with Space or Enter'"></tag-input>
                        </div>

                        <div fxLayout="column" fxLayoutAlign="none none" *ngFor="let chat of messages; let i2 = index">
                            <div *ngIf="!editChat || editChat!= chat" (click)="msgClicked(chat)" class="card__text">

                                <span *ngIf="chat.msg.length">
                                    <span *ngIf="chat.msg.startsWith('http')">
                                        <div class="urlLink" (click)="goLink(chat.msg)">Go to: {{" "+chat.msg}}
                                        </div>
                                    </span>
                                    <span *ngIf="!chat.msg.startsWith('http')">
                                        <div class="chat-msg" [style.justify-content]="getTextAlign(chat)"
                                            [ngStyle]="{ 'justify-content': getTextAlign(chat)}">
                                            <div class="chat-text" style="width:fit-content"
                                                [style.background-color]="getBackgroundcolor(chat)">
                                                {{chat.msg}}<span style="font-size: .7rem;"></span>
                                            </div>
                                        </div>
                                    </span>
                                </span>


                            </div>


                            <div *ngIf="editChat == chat">
                                <p-accordionTab [selected]="editChat">
                                    <ng-template pTemplate="header">
                                        <div class="card__text" style="width:100%;" [style.color]="getTextColor(chat)"
                                            [ngStyle]="{ 'color': getTextColor(chat)}">
                                            {{chat.msg}}
                                        </div>

                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div style="width: 100%;" fxLayout="column" fxLayoutAlign="space-between none">
                                            <div style="width: 100%;" fxLayout="row" fxLayoutAlign="space-between none">
                                                <div fxFlex="85%">
                                                    <textarea pInputTextarea [rows]="3" [cols]="50" [autoResize]="true"
                                                        id="fullDescription" type="text" class="text-entry"
                                                        [(ngModel)]="newMsg.msg">
                                                </textarea>
                                                </div>
                                                <div fxFlex="15%">
                                                    <div class="action-button3"
                                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                                        (click)="UpdateMsg(chat, i)">
                                                        Update</div>
                                                </div>
                                            </div>
                                            <div fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                                                <div class="command" (click)="Clicked($event.target.innerHTML)">Hide
                                                </div>
                                                <div class="command" (click)="Clicked($event.target.innerHTML)">
                                                    Remove
                                                </div>
                                                <div class="command" (click)="Clicked($event.target.innerHTML)">Copy
                                                </div>

                                            </div>

                                        </div>


                                    </ng-template>
                                </p-accordionTab>





                            </div>
                            <div *ngIf="chat.feature=='video search'" class="pretty-header-data-center">
                                <div *ngIf="chat.media">
                                    <video style="max-width:100%; max-height: 400px;" playsinline webkit-playsinline
                                        autoplay controls [muted]="'muted'" id="myVideo">
                                        <source src="{{chat.media[0]}}" type="video/mp4">
                                    </video>

                                </div>

                            </div>

                            <div *ngIf="chat.feature=='sticky note'" class="pretty-header-data-center">

                                <div *ngIf="chat.stickyTitle" class="sticky">
                                    <div class="sticky__title">{{chat.stickyTitle}}</div>
                                    <div class="sticky__text" style="background-color:yellow"
                                        *ngFor="let n of chat.stickyNotes; let i = index;">
                                        {{n}}

                                    </div>

                                </div>
                            </div>


                            <div *ngIf="chat.feature=='get image'" class="pretty-header-data-center">
                                <div *ngIf="chat.media">
                                    <img style="max-width:100%; max-height: 400px;" src={{chat.media}} alt="">
                                </div>
                            </div>

                            <div *ngIf="chat.feature=='internet search'" class="pretty-header-data-center">
                                <div *ngIf="chat.searchResults">
                                    <p-accordion>
                                        <p-accordionTab [(selected)]="chat.searchTabOpen">
                                            <ng-template pTemplate="header">
                                                <div class="pretty-header-data-center">Search{{"
                                                    "+chat.searchQuery}}</div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                {{chat.searchResults}}
                                                <!--
                                        <app-search [getResults]="chat.searchResults" [getInfo]="chat.searchInfo"
                                            [showQuery]="false" [cancelIcon]="true"></app-search>
-->
                                            </ng-template>
                                        </p-accordionTab>
                                    </p-accordion>



                                </div>
                            </div>



                            <div *ngIf="chat.feature =='map'" class="pretty-header-data-center">
                                <p-accordion>
                                    <p-accordionTab [(selected)]="chat.searchTabOpen">
                                        <ng-template pTemplate="header">
                                            <div *ngIf="chat.mapLat">
                                                {{" "+chat.mapAddress}}

                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">

                                       <!--    <google-map height="400px" width="100%" [options]="chat.mapOptions">
                                            </google-map>--> 
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>

                                <!--

                        <div *ngIf="dataMode=='address' && i2==messages.length" fxLayout="row"
                            fxLayoutAlign="space-between none">
                            <div fxFlex="85%">
                                <input type="text" pInputText [(ngModel)]="newMsg.mapAddress" />

                            </div>
                            <div fxFlex="15%">
                                <div class="action-button3"
                                    style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                    (click)="getMap(chat, i)">
                                    GetMap</div>
                            </div>
                        </div>
                        -->
                            </div>

                            <div *ngIf="chat.feature=='draw'" class="pretty-header-data-center">
                                <p-accordion>
                                    <p-accordionTab>
                                        <ng-template pTemplate="header">
                                            <div class="pretty-header-data-center">Drawing</div>
                                        </ng-template>
                                        <ng-template pTemplate="content">

                                            <div style="height:600px;">
                                                <app-draw initAccount="{{GetAccount(conversation, chat)}}"
                                                    initTopic="{{GetTopic(conversation, chat)}}"></app-draw>
                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>
                            <div *ngIf="chat.feature=='play'">

                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="pretty" style="width: 100%">Game</div>
                                    </ng-template>
                                    <ng-template pTemplate="content">

                                        <div style="width: 100%">
                                            <app-trivia></app-trivia>
                                        </div>
                                    </ng-template>
                                </p-accordionTab>
                            </div>

                        </div>
                        <!--            REMOVE 
                 <div class="delete-button" (click)="cancel()">X</div>
                 -->
                    </div>


                </ng-template>
            </p-accordionTab>

        </p-accordion>
    </div>