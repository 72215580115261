import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Field, Logic, CodeObject, Code } from '../company-interface'
import { Globals } from '../../globals'
import { SpriteService } from '../sprites.service';
import * as math from 'mathjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogicService {
  spriteCount = 1;

  constructor(public global: Globals, public spriteService: SpriteService) { }

  executeLogic(logic: Logic, form?: Form, field?: Field, actionCallback?: any, mySelf?) {
    var self = this;
    var resultsUpdated = false;
    var missingValue = false;

    var result1: number = 0;
    var fields: number[] = [];
    var operators: string[] = [];
    var strValue: string[] = [];

    var result = 0;
    if (!logic || !logic?.code || logic.code.length == 0) return;

    console.log("Execute the logic", logic, field, form);

    result = 0;
    var myParent: string;
    var mode = "";
    var blockActive = false;
    var ifResult: boolean = null;


    var ifBlock = false;

    var forStart = null;
    var forCount = 0;

    logic.code.forEach(function (code, index) {
      if (code.codeObjects) {
        if (code.codeObjects.type == 'End If') {
          ifBlock = false;
        }
        console.log("Type: ", code.codeObjects.type)

        if (!ifBlock || (ifBlock && ifResult))
          switch (code.codeObjects.type) {
            case "Sprite":
              self.Object(code.codeObjects, form, field)
              break;
            case "Function":
              self.executeFunction(code, actionCallback, mySelf)
              break;
           
            case "ForEach":
              forStart = index + 1;
              forCount = 500;
              console.log("Exe-For-Loop")


              break;
            case "End For":

              forCount--;
              var endForPos = index;
              if (forCount) {
                console.log("Loop ", forStart, forCount);
                for (var n = forCount; n > 0; n--) {
                  logic.code.forEach(function (code, loopIndex) {
                    if (loopIndex >= forStart && loopIndex < endForPos) {

                      switch (code.codeObjects.type) {
                        /*
                        case "Sprite":
                          self.Object(code.codeObjects, form, field)

                          code.codeObjects.x = Math.random() * 1600;
                          code.codeObjects.y = Math.random() * 900;

                          code.codeObjects.vx = Math.random() * 400 - 200
                          code.codeObjects.vy = Math.random() * 400 - 200

                          code.codeObjects.dataStr = "";
                          code.codeObjects.spriteType = "Ball";
                          code.codeObjects.bounds = true;


                          break;*/

                      }
                    }
                  })
                }
              }


              break;

            case "End If":
              ifBlock = false;
              break;
            case "If":
              var leftSide: number = self.findValue(0, code.codeObjects.result, code.codeObjects);
              var assign = code.codeObjects.assign;
              var rightSide: number = self.findValue(0, code.codeObjects.fields[0], code.codeObjects);

              var result;
              if (assign == '==' && typeof (leftSide) != null)
                result = leftSide == rightSide;

              ifBlock = true;
              ifResult = result

              //self.setValue(code.codeObjects.result, result)

              console.log("EXE IF->", leftSide, assign, rightSide, result)

              break;

            case "Equation":
              var mathEval = "";
              if (code?.codeObjects?.values?.length)
                code.codeObjects.values.forEach(function (item, index) {
                  // Pattern: 0,1,2,3,2,3,2,3
                  // result/opEq/field-or-value/op

                  var x = index;
                  var y = x % 4;
                  if (x > 3) {
                    if (x % 2 == 0) y = 2;
                    else y = 3;
                  }



                  switch (y) { // silly but it works
                    case 0: // result
                      mathEval += "x "
                      break;
                    case 1:
                      mathEval += item;
                      break;
                    case 2:
                      var value = self.getFieldValue(form, field, index, item, code)
                      console.log("GOT LOGIC VALUE: ", value)
                      if (typeof(value)=='undefined' || value == null || value == "null" || typeof(value)=='string')
                        value = 0;
                      mathEval += value
                      break;
                    case 3:
                      mathEval += item;
                      break;
                  }
                })
              console.log("Evaluate string ", mathEval)
              const bob = math.evaluate(mathEval)
              console.log("Evalulated value: ", bob)
              if (bob?.entries) {
                //   this.field.evaluated = bob.entries;
                console.log("IT'S AN ARRAY ", bob)
                //     this.field.value = this.field.evaluated[this.field.evaluated.length - 1];
              }
              //   else if (typeof(bob)=='string')
              //     self.setFieldValue(form, field, code.codeObjects.values[0], bob)
              else {
                self.setFieldValue(form, field, code.codeObjects.values[0], bob)
              }
              break;

          }
      }
    })

    return;
    /*
          fields = [];
          var resultField = "";
    
          operators = [];
          var func: string[] = [];
    
          code.elements.forEach(function (element) {
            switch (element.type) {
              case 'Result':
    
                break;
              case 'strValue':
                strValue.push(element.stringValue);
                break;
              case 'Field':
                var value: number = self.findValue(element.stringValue, form);
                if (!value) missingValue = true;
                console.log("[Field]: ", value);
                fields.push(value);
                break;
              case 'Equation':
               
                resultField = element.stringValue;
                break;
              case 'If':
                mode = 'If';
                myParent = code.id;
    
                break;
              case 'True':
                mode = 'True block'
                if (ifResult == true)
                  blockActive = true;
                break;
              case 'False':
                mode = 'False block'
                if (ifResult == false)
                  blockActive = true;
                break;
              case 'Operator':
                var operator = element.stringValue;
                operators.push(operator);
                break;
              case 'Function':
                func.push(element.stringValue)
                break;
              case 'Value':
                if (func.length) {
                  if (func[0] == 'Random') {
                    var r = Math.random() * element.numberValue;
                    fields.push(r);
                    func.splice(0, 1);
                  }
    
                }
                else {
                  operators.push("Equals");
                  fields.push(element.numberValue);
                }
    
                break;
    
            }
          })
          if (func.length && !operators.length) {
            
            console.log("Let's execute the FUNCTION ", func[0], fields)
            if (func[0] == 'Hide form') {
              form.hideForm = true;
              if (typeof actionCallback == "function")
                actionCallback(mySelf, "Hide form");
            }
            if (func[0] == 'Goto form') {          
              if (typeof actionCallback == "function")
                actionCallback(mySelf, func[0], strValue[0]);
    
            }
            if (func[0] == 'Payment') {
              var interest = (fields[1] / 100) / 12;
              var payments = fields[2];
              var principal = fields[0];
              var x = Math.pow(1 + interest, payments);
              var monthly = (principal * x * interest) / (x - 1);
              if (!isNaN(monthly) &&
                (monthly != Number.POSITIVE_INFINITY) &&
                (monthly != Number.NEGATIVE_INFINITY)) {
    
                x = self.round(monthly);
    
                console.log("YOUR MONTHLY PAYMENT IS ", x)
                result = x;
              }
            }
          }
    
    
 
          
    
    
          }
          if (resultField) {
            if (mode == 'True block' || mode == 'False block') {
              console.log("blockActive is", blockActive)
              if (blockActive)
                self.setValue(resultField, form, result)
    
            }
            else {
              console.log("Result field ", resultField)
              self.setValue(resultField, form, result)
            }
          }
          // else field.value = result;
    
        })
        //  field.value = result;
        //  if (field.referenced) resultsUpdated = true;
        console.log("Here is your code: and result!", mode, operators, result);
    */

  }

  executeFunction(code: Code, actionCallback, mySelf: this) {
    var self = this;

    console.log("Let's execute some code: ", code);

    //   if (code.codeObjects?.values[2].length) {

    if (typeof actionCallback == "function") {
      actionCallback(code.codeObjects, mySelf);
    }


    //  }

    //   console.log("EXE Function->", leftSide, assign, rightSide, result, minVal, maxVal)
  }


  findObjectValue(code: CodeObject) {
    var self = this;
    // Let's scan objects? for the value
    return self.spriteService.getObjectFieldValue(code.result, code.resultField)


  }


  Object(code: CodeObject, form: Form, field: Field) {
    var self = this;

    if (code.type == "Sprite") {
      if (!code.name) {
        code.name = "sprite-" + self.spriteCount;
        self.spriteCount++
      }
      if (!field.canvasIndex) {
        // Find a canvas
        if (form?.fields.length)
          form.fields.forEach(function (f) {
            if (f.type == 'canvas') {
              field.canvasIndex = f.canvasIndex;
              console.log("Index: ", f.canvasIndex)
            }
          })

      }
      // console.log("New Sprite: ", form, self.spriteService.spriteEngines.length, code, field, field.canvasIndex)

      if (self.spriteService.spriteEngines.length) field.canvasIndex = self.spriteService.spriteEngines.length - 1;

      if (field.canvasIndex >= 0)
        self.spriteService.spriteEngines[field.canvasIndex].addSprite(code);
    }
  }

  getFieldValue(form: Form, field:Field, parameterIndex, me: any, code: CodeObject) {
    var self = this;
    var value = 0;

    console.log("Looking for ", me, code, form)


    if (!me || me.length == 0) return false;

    value = Number(me);
    if (typeof value === "number" && !isNaN(value)) {
      console.log("return value: ", value)
      return value;
    }


    var formIndex = -1;
    var fieldIndex = -1;
    if (typeof (me) == 'object') {
      self.global.allForms.forEach(function (form, index) {
        const foundIndex = form.fields.findIndex(x => x.id === me.id)
        if (foundIndex != -1) {
          fieldIndex = foundIndex;
          formIndex = index;
        }
      })
      if (fieldIndex != -1) {
        value = self.global.allForms[formIndex].fields[fieldIndex].value
        if (typeof (value) == 'number')
          return value;
        else return 0;
      }
      else
        return 0;
    }


 

    var formField: string;
    formField = me;

    console.log("Search formfield: ", formField);

    // Find field

    if (field?.title == me) { // It's me baby.
      if (index != -1) {
        if (typeof (value) == 'number')
         return field.value;
       else {
         return "\"" + field.binding + "\"";
       }
     }
    }

    var index = -1;
    if (form?.fields) {
      index = form.fields.findIndex(function (post) {
        if (post?.title?.toLowerCase() == formField?.toLowerCase()) {
          console.log ("FOUND FIELD ", post)
          return true;
        }
      })
      if (index != -1) {
         if (typeof (value) == 'number')
          return form.fields[index].value;
        else {
          return "\"" + form.fields[index].binding + "\"";
        }
      }
    }
    else { // FIND IN GLOBALS

      var formIndex = -1;
      self.global.allForms.forEach(function (form, fIn) {
        const fid = form.fields.findIndex(function (post) {
          if (post.title.toLowerCase() == formField.toLowerCase()) {
            console.log ("FOUND FIELD ", post)
            return true;
          }

        })
        if (fid != -1) {
          formIndex = fIn;
          fieldIndex = fid;
        }
      })
      console.log(formIndex, fieldIndex)

      var val = self.global.allForms[formIndex].fields[fieldIndex].value;

      if (typeof (val) == 'number')
        return val;
      else {
        return "\"" + self.global.allForms[formIndex].fields[fieldIndex].binding + "\"";
      }

    }

    return null;
  }

  findValue(parameterIndex, me: any, code: CodeObject) {
    var self = this;
    console.log("Looking for ", me, code)

    if (!me || me.length == 0) return false;

    if (me == 'Value' || me == 'value') {
      if (code?.values?.length) {
        console.log("Val: ", code.values[0])
        return code.values[parameterIndex];
      }
      else return 0;
    }

    var formField: string[];


    if (typeof (me) == 'string') {
      formField = me.split(":");  // OLD WAY
    }
    else if (me.title) { // to get data faster use the UID - hack/perf
      formField = [me.subTitle.toString(), me.title.toString()];
    }


    console.log("Search formfield: ", formField);

    // Find form

    const index = self.global?.allForms.findIndex(function (post) {
      if (post.name.toLowerCase() == formField[0].toLowerCase())
        return true;
    });

    if (index != -1) {
      var index2 = self.global.allForms[index].fields.findIndex(function (post) {
        if (post.title.toLowerCase() == formField[1].toLowerCase())
          return true;
      });
      if (index2 == -1) { return false }
      console.log("FOUND FIELD: ", formField, self.global.allForms[index].fields[index2])
      return self.global.allForms[index].fields[index2].value;
    }

    else return null;

  }


  round(x) {
    return Math.round(x * 100) / 100;
  }

  setObjectValue(code: CodeObject, result) {

    this.spriteService.setObjectFieldValue(code.result, code.resultField, result)


  }

  setFieldValue(form, field, resultField, bob) {
    var self = this;
    console.log ("Set field val ",form, field, resultField, bob)


    if (field?.title == resultField) {
        if (typeof (bob) == 'string')
          field.binding = bob;
        else
          field.value = bob;
      return;

    }

    if (form?.fields) {
      // Find form
      const index = form.fields.findIndex(function (post) {
        if (post.title.toLowerCase() == resultField.toLowerCase())
          return true;
      })
      if (index != -1) {
        if (form.fields[index].type=='select-button') {
          form.fields[index].selected = [bob]
          console.log ("Set value of options ")
        }
        else if (typeof (bob) == 'string')
          form.fields[index].binding = bob;
        else
          form.fields[index].value = bob;
      }
      console.log ("Set Form val ", form.fields[index])

    }
    else { // Global field lookup
      if (self.global?.allForms)
      self.global.allForms.forEach(function(thisForm){
        const index = thisForm.fields.findIndex(function (post) {
          if (post?.title?.toLowerCase() == resultField?.toLowerCase())
            return true;
        })
        if (index != -1) {
          if (typeof (bob) == 'string')
            thisForm.fields[index].binding = bob;
          else {
            console.log ("GLOBAL FIELD SET ", bob,thisForm.fields[index])

            if (thisForm.fields[index].type=='select-button') {
              thisForm.fields[index].selected = [bob]
              console.log("Global select button set: ", thisForm.fields[index])
            }
            else
              thisForm.fields[index].value = bob;
          }
        }


      })
      

    }


  }

  setValue(resultField, result) {
    var self = this;

    console.log("Setting field value: ", resultField, result)

    if (!resultField || resultField.length == 0) return null;

    var formField: string[];

    if (typeof (resultField) == 'string')
      formField = resultField.split(":");
    else if (resultField.title) { // to get data faster use the UID - hack/perf
      formField = [resultField.subTitle.toString(), resultField.title.toString()];
    }

    console.log("formfield ", formField)

    if (formField.length == 2) {
      // Find form
      const index = self.global.allForms.findIndex(function (post) {
        if (post.name.toLowerCase() == formField[0].toLowerCase())
          return true;
      });
      console.log("found field: ", index, formField)

      if (index != -1) {
        console.log("Setfield ", index, self.global.allForms)
        var index2 = self.global.allForms[index].fields.findIndex(function (post) {
          if (post.title.toLowerCase() == formField[1].toLowerCase())
            return true;
        });
        if (index2 == -1) { return false }
        self.global.allForms[index].fields[index2].value = result;

        return true;
      }
    }





  }

}
