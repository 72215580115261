<div *ngIf="logic.code" fxFlex fxLayout="column" class="layout" style="background-color: honeydew; border: 2px solid black">
    <div *ngIf="!logic || (logic && !logic.code) || (logic.code && logic.code.length==0)">
        <button style="height:100%; padding:6px; margin:0px; margin-left:10px;" (click)="startNewLine(i)" pRipple>Start
            Logic</button>
    </div>
    <div style="width: 100%; max-width:100vw;" class="logic" *ngFor="let code of logic.code; let i = index;"
        [style.background-color]="getBlockColor(i)">

        <div fxLayout="row" fxLayoutAlign="none center" [style.background-color]="getBlockColor(i)" style="width:100%;">
            {{i+1+": "}}&nbsp;
            <span *ngIf="i==currentLine" style="font-size: 120%; color: white; background-color: rgb(5, 13, 248);">
                <div [innerHTML]="displayRow(code, i)"></div>
            </span>
            <span *ngIf="i!=currentLine">
                <div [innerHTML]="displayRow(code, i)"></div>
            </span>
            <span style="float: right;">
                <button *ngIf="i!=currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;"
                    (click)="editLine(i)" pRipple>Edit</button>
                <button *ngIf="i==currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;"
                    (click)="doneLine(i)" pRipple>Done</button>
                <button *ngIf="i==currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;"
                    (click)="clearLine(i)" pRipple>Clear</button>
                <button *ngIf="i==currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;"
                    (click)="editLine(i)" pRipple>Move</button>
                <button *ngIf="i!=currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;"
                    (click)="startNewLine(i)" pRipple>Add Line</button>
            </span>

        </div>


        <div *ngIf="amIContainer(code)">



        </div>


        <div *ngIf="currentLine==i"
            style="width:100%; max-width:100vw; font-size:larger; background-color: rgb(207, 229, 254); border: 2px solid rgb(12, 76, 237)">
            <div *ngIf="code?.codeObjects?.type" fxFlex fxLayout="row wrap" fxLayoutAlign="start none"
                style="width:100%; max-width:100vw;">
                <div *ngFor="let field of editor[i]; let x = index">

                    <app-field [field]="field" [binding]="" [showSubmit]="false" [editMode]="true"
                        (outputs)="spriteOutput($event)"></app-field>

                </div>
            </div>
            <div *ngIf="!code.codeObjects || (code.codeObjects && !code.codeObjects.type)" style="width:100%" fxFlex
                fxLayout="row wrap" fxLayoutAlign="center none">
                Select a Logic type
                <div *ngFor="let logicType of logicTypes; let i = index;">
                    <button class="show" (click)="logicClicked(logicType)" pRipple>{{logicType}}</button>

                </div>
            </div>
        </div>
    </div>

    <!--------------  DISPLAY CODE

    <div *ngIf="logic.code" fxFlex fxLayout="column" fxLayoutAlign="start center">
        <div style="width: 100%;" class="logic" *ngFor="let code of logic.code; let i = index;">
            <span *ngIf="i==currentLine" style="font-size: 120%; background-color: rgb(246, 214, 214);">
                {{displayRow(code, i)}} 
            </span>
            <span *ngIf="i!=currentLine">
                {{displayRow(code, i)}} 
            </span>
            <span style="float: right;">
                <button *ngIf="i!=currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;" (click)="editLine(i)" pRipple>Edit</button>
                <button *ngIf="i==currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;" (click)="clearLine(i)" pRipple>Clear</button>
                <button *ngIf="i==currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;" (click)="editLine(i)" pRipple>Move</button>
                <button *ngIf="i==currentLine" style="height:100%; padding:6px; margin:0px; margin-left:10px;" (click)="startNewLine(i)" pRipple>Add Line</button>
            </span>

            <div *ngIf="amIContainer(code)">



            </div>



        </div>

    </div>
     ------------------>
</div>



<!--
    Show all Code lines with me as parent

                <app-container [settings]="formSettings" [appId]="appId" [node]="node" [list]="ifElseList"
                    [style]="'List1'" [store]="store" (openTab)="openTab($event)"></app-container>
-->