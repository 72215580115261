<div *ngIf="contentID && thisProduct && !validatingPerk">
    <app-view-item [p]="thisProduct"></app-view-item>
</div>




<div *ngIf="!contentID" style="font-size:150%; background-color: rgb(247, 255, 255);">
    <div *ngIf="validatingPerk">
        <div *ngIf="perkRedeemed">
            This Perk has been redeemed. Have a nice day.

        </div>
        <div *ngIf="!perkRedeemed">
            Checking Validation...
        </div>
        <div *ngIf="!perkRedeemed && perkIsValid" fxFlex fxLayout="column" fxLayoutAlign="center center">

            Yes, it is. Click to Redeem.
            <div *ngIf="thisProduct">
                {{thisProduct.title}}
            </div>
            <button class="redeem-button" (click)="confirmRedeem()">Confirm Redemption of this Perk</button>

        </div>
        <div *ngIf="!perkRedeemed && perkUsed">
            Seems this perk has already been used.
        </div>
    </div>


    <div *ngIf="!validatingPerk && !global.authuser" class="layout">
        <!-- NO USER -->


        <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div fxLayout="column" style="width:100%" fxLayoutAlign="center center">

                        <div *ngIf="global.myCompany" class="pretty">Welcome to {{"
                            "+global.myCompany.name+" "}}
                        </div>
                        <div *ngIf="!global.myCompany" class="pretty">Welcome to Napkin Apps</div>
                        <div style="font-size: .8rem; text-align: center;">Click to Log in or Register</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div fxLayout="column" fxLayoutAlign="center none" style="text-align: center;">
                        <br>Enter email to login or register 
                        <span style="font-size: .9rem">Your data is never sold or shared.</span>

                    </div>


                    <SignIn startText="Get Started" (setContactEvent)="setContact($event)"
                        (afterLoginEvent)="afterLogin()"></SignIn>

                </ng-template>
            </p-accordionTab>

        </p-accordion>

    </div>


    <!-- Authorized USER -->
    <div *ngIf="global.authuser" class="layout">
        <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false" (onOpen)="onTabClick()">
            <div *ngIf="global.authuser">
                <p-accordionTab iconPos="end" [(selected)]="global.userTabOpen">
                    <ng-template pTemplate="header">
                        <div class="user-feature" pRipple>Welcome back {{global.authuser.firstName}}
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <app-container [settings]="userSettings" [list]="userList" [api]="true"
                            [style]="style"></app-container>
                    </ng-template>
                </p-accordionTab>
            </div>
        </p-accordion>

        <!-- add later
        <p-accordionTab>
            <ng-template pTemplate="header">
                <div class="pretty">My Rewards</div>
            </ng-template>
            <ng-template pTemplate="content">
                A list of all the places you have rewards!!!
            </ng-template>
        </p-accordionTab>
        <p-accordionTab>
            <ng-template pTemplate="header">
                <div class="pretty">Find a Table</div>
            </ng-template>
            <ng-template pTemplate="content">
                Let's get you a place!!!
            </ng-template>
        </p-accordionTab>        

-->



    </div>
    <!--
<div *ngIf="!openedTab" class="pretty-help">Napkin uses simple containers to organize all functions and content. Just click box above to reveal.</div>
-->

    <div *ngIf="contentID" class="layout">
        <!-- Removed for new UI
    <div *ngIf="global && global.myCompany" class="link" (click)="gotoMenu()">Go to {{global.myCompany.name}}</div>
-->
        <div *ngIf="content && content.audioURL">
            <audio style="width:100%; margin:auto" id="audio1" controls>
                <source src={{content.audioURL}} type="audio/mpeg">
            </audio>

            <div class="play-audio" style="cursor: pointer;" (click)="playAudio()">Play Audio Clip</div>

            <br>
            <section class="text-content" [innerHTML]="content.html"></section>
            <br>
            <div class="play-audio" style="cursor: pointer;" (click)="playTrivia()">Play Wine Trivia Game</div>
            <br>
            <br>

        </div>

    </div>

    <div>
        <!--
        <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">
        
    <p-accordionTab>
        <ng-template pTemplate="header">
            <div class="pretty">Find a Store</div>
        </ng-template>
        <ng-template pTemplate="content">
            Search bar - in header
        </ng-template>
    </p-accordionTab>
    





        </p-accordion>
        -->

    </div>

    <div *ngIf="0 && !navigating" style="width:100%;" fxLayout="row wrap" fxLayoutAlign="space-around space-around">
        <div *ngIf="global?.recentApps" style="width:100%;" fxLayout="row wrap"
            fxLayoutAlign="space-around space-around">

            <div (click)="gotoApp($event, appLink)" *ngFor="let appLink of global.recentApps; let i = index;"
                fxLayout="column" fxLayoutAlign="center center" class="app-card">

                <div class="store-name">
                    {{appLink.storeName}}
                </div>

                <div fxLayout="column" fxLayoutAlign="center center">
                    <div class="user-feature">{{appLink.name}}</div>
                </div>

            </div>
        </div>
    </div>


    <div *ngIf="home" [ngStyle]="{ 'max-width': getMaxWidth() }">

        <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="gold" style="width: 100%;">NAPKIN APPS</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">

                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%; text-align: center;">Privacy Policy</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div style="width:100%; max-width:900px;margin: auto;">
                                <img src="./assets/PrivacyPolicy.png" class="napkin-logo"
                                    style="width:100%; object-fit: cover; max-height:100vh;">
                                </div>
                            </ng-template>
                        </p-accordionTab>
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%; text-align: center;">Terms of Use</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div style="max-width:900px;margin: auto;">

                                    <div *ngIf="global.getScreenWidth<600">
                                        <img src="./assets/termsmobile.png" class="napkin-logo"
                                            style="object-fit: cover;width:100vw">

                                    </div>
                                    <div *ngIf="global.getScreenWidth>600">
                                        <img src="./assets/terms.png" class="napkin-logo"
                                            style="width:100%; max-width: 900px;">

                                    </div>


                                </div>

                            </ng-template>
                        </p-accordionTab>
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%; text-align: center;">What is Napkin Apps?</div>
                            </ng-template>
                            <ng-template pTemplate="content">

                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>

                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>

</div>