import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Product } from '../product-int'
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Globals } from '../../globals';
import { Media } from '../user-interface';

import firebase from 'firebase/compat/app';
/*
export interface TreeFile<T = any> {
  label?: string;
  data?: T;
  children?: TreeFile<T>[];
  type?: string;
  //  parent?: TreeFile<T>;
  image1?: string;
  parentKey?: string;
  key?: string;
  linkURL?: string;

  productTags?: string[];
  contentTags?: string[];
  featureTags?: string[];
  minHeight?: number;
  imagePosX?: number;
  imagePosY?: number;
  backgroundSize?: string;
}
*/

export interface Images {
  value?: ImageValues[];
}

export interface ImageValues {
  contentUrl: string;
  thumbnailUrl: string;
}

export interface GalleryImage {
  previewImageSrc: string,
  thumbnailImageSrc: string,
  alt: string,
  title: string
}

export interface Videos {
  videos: Video[];

}
export interface Video {
  video_files: VideoFile[];
}

export interface VideoFile {
  link?: string;
  height?: number;
  width?: number;
}



@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.css']
})
export class EditProductComponent {
  @Input() editProduct: Product;
 // @Input() editNav: Navigate;

  showBasic: boolean = true;
  justifyOptions: any[];
  yesNo: any[];
  value: any;
  styles: any[];
  type: any[];
  editMode: string = "";
  visible: boolean = true;
  ImageSearch: boolean = false;
  images: any[];
  videos: any[];
  html: SafeHtml;
  searchString: string = "";
  viewTagTab: boolean = false;
  myMedia: Media [] = [];

  selectGalleryVisible: boolean = true;

  constructor(private sanitizer: DomSanitizer, public global: Globals,) {

    this.yesNo = [
      { option: 'No', value: false },
      { option: 'Yes', value: true },
    ];

    this.styles = [
      { option: 'Tall Image' },
      { option: 'Wide Image' },
      { option: 'Minimal' },
    ];

    this.type = [
      { option: 'Content' },
      { option: 'Product' }

    ];



    this.justifyOptions = [
      { icon: 'pi pi-align-left', justify: 'Left' },
      { icon: 'pi pi-align-right', justify: 'Right' },
      { icon: 'pi pi-align-center', justify: 'Center' },
      { icon: 'pi pi-align-justify', justify: 'Justify' }
    ];
  }

  ngOnInit(): void {
    console.log("Edit: ", this.editProduct);
  //  this.loadMedia();
  }

  SetEditMode(mode: string) {
    this.editMode = mode;
    this.visible = true;
    console.log(this.editMode);
  }

  setGallery(galleryId: string) {
    this.editProduct.galleryId = galleryId;
    this.editMode = "";


  }


  tagFilter(item: string): boolean {
    var self = this;

    if (this.searchString.length > 1) {
      if (item.toLowerCase().includes(this.searchString.toLowerCase())) return true;
      else return false;
    }
    return true;
  }

 
  CloneContent(p: Product) {

    var self = this;
    function replacer(key, value) {
      if (key === "parent") return undefined;
      if (key === "selectable") return undefined;
      if (key === "expanded") return undefined;
      if (value === undefined) return null;
      return value;
    }

    var db = firebase.firestore();

  //  var docRef = db.collection("products").doc();
    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc();

    let newProduct = JSON.parse(JSON.stringify(p, replacer)); //DEEP COPY

    newProduct.title = newProduct.title + " -Copy";
    newProduct.docID = docRef.id;
    newProduct.sid = docRef.id.substring(0,5)+ self.global.myCompany.id.substring(5,7)

    if (!newProduct.dept) newProduct.dept = "All;"

    docRef.set(
      newProduct
    )
      .then(() => {
        console.log("New Product Cloned");
        //            self.GetProducts();  // SHOULD AUTO UPDATE FROM SERVICE
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  addTag(e, t: string) {
    e.stopImmediatePropagation();

    if (!this.editProduct.allTags) this.editProduct.allTags = [];
    this.editProduct.allTags.push(t);
    this.updateTags();
    this.searchString = "";
  }

  addNewTag(e) {
    e.stopImmediatePropagation();
    if (!this.editProduct.allTags) this.editProduct.allTags = [];
    this.editProduct.allTags.push(this.searchString);
    this.global.allTags.push(this.searchString);
    this.updateTags();
    this.searchString = "";
  }

  removeTag(e, t: string) {
    e.stopImmediatePropagation();
    const index = this.editProduct.allTags.indexOf(t);
    if (index != -1) {
      this.editProduct.allTags.splice(index, 1);
      this.updateTags();
      this.viewTagTab = true;
    }
  }

  ChangeStyles() {
    var db = firebase.firestore();
    var self = this;



    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(self.editProduct.docID);

 //   var docRef = db.collection("products").doc(self.editProduct.docID);

    console.log("Product:! ", self.editProduct, docRef);
    if (!self.editProduct.style) self.editProduct.style = "Minimal";
    if (self.editProduct.html) {
      self.editProduct.safeHtml = self.editProduct.html;
      //  self.html = self.sanitizer.bypassSecurityTrustHtml(self.editProduct.html);
      console.log("HTML: ", self.editProduct.safeHtml);
      //  self.editProduct.safeHtml = self.html;
    }


    if (!self.editProduct.docID) {
      self.editProduct.docID = docRef.id;
      //    self.editProduct.categoryTags = [];
      //    self.editProduct.categoryTags.push (self.editNav.key);
      docRef.set(self.editProduct)
        .then(() => {
          console.log("Product successfully added!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating product: ", error);
        });
      
    }
    else {
      docRef.update(self.editProduct)
        .then(() => {
          console.log("Product successfully updated!", self.editProduct);

        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating product: ", error);
        });
    }

  }

  SaveDataStop(node, $event) {

    this.ChangeStyles();
    this.visible = false;
  }

  SaveProduct(p, e) {
    this.ChangeStyles();

  }


  updateTags() {
    var db = firebase.firestore();
    var self = this;

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(self.editProduct.docID);
    docRef.update({ allTags: this.editProduct.allTags })
      .then(() => {
        console.log("Tags successfully updated!");
        self.viewTagTab = true;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating tags: ", error);
      })

  }

  getTagCount (tag: string) {

    var index = this.global.allTags.indexOf(tag);
    if (index != -1) return (this.global.allTagsCount[index])
    return 0;

  }


  FindMedia(node) {
    this.ImageSearch = true;
    this.visible = true;

  }

  ChooseImage(p: Product, img: GalleryImage) {
    p.image1 = img.thumbnailImageSrc;
    console.log("Excellent Choice! ", img);
    // this.SaveData(node);
    this.ImageSearch = false;

  }

  getMyLink(item:Product) {

    // return the direct link
    return "napkinhq.com/home/?c="+item.sid;


  }
/*
  loadMedia () {
    var self = this;
    console.log ("Loading Media")
    var db = firebase.firestore();
    self.myMedia = [];
    if (this.editProduct.media)
    this.editProduct.media.forEach (function(media){
      const mediaRef =  db.collection("media").doc(media).get()
      .then((querySnapshot) => {
        const data = <Media>querySnapshot.data();
        self.myMedia.push(data);
      })
    })   

  }
*/

  ChooseVideo(p: Product, videoURL: string) {
    var self = this;
    var db = firebase.firestore();

    var newMedia: Media = {};

    newMedia.fileType = "video";
    newMedia.url = videoURL;

    self.updateProductMedia(newMedia);

    return;
    /*

    var mediaRef = db.collection("media").doc();

    newMedia.id = mediaRef.id;


    mediaRef.set(newMedia)
      .then(() => {
        console.log("Media Added!", newMedia);

        if (!self.editProduct.media) self.editProduct.media = [];
        self.editProduct.media.push(newMedia.id);
        if (!self.myMedia) self.myMedia = [];
        self.myMedia.push(newMedia);
        console.log("Excellent Choice! ", self.myMedia);
        self.visible = false;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating product: ", error);
      });

*/




  }

  updateProductMedia(media: Media) {
    var db = firebase.firestore();
    var self = this;

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(self.editProduct.docID);

  //  var docRef = db.collection("products").doc(self.editProduct.docID)

      docRef.update({media})
        .then(() => {
          console.log("Media successfully updated!", media);

        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating media: ", error);
        });
   

  }



  updateProduct() {
    var db = firebase.firestore();
    var self = this;

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(self.editProduct.docID);

//    var docRef = db.collection("products").doc(self.editProduct.docID);

      docRef.update(self.editProduct)
        .then(() => {
          console.log("Product successfully updated!", self.editProduct);

        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating product: ", error);
        });
   

  }



  FindImages(search: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'bing-image-search1.p.rapidapi.com'
      }
    };

    var fetchStr = 'https://bing-image-search1.p.rapidapi.com/images/search?q=' + search + '&count=16'

    fetch(fetchStr, options)
      .then(response => response.json())
      .then(response => {
        console.log("Convert to carousel", response);
        var r = <ImageValues[]>response.value;
        self.images = [];
        r.forEach(function (item: ImageValues) {

          var newNode: GalleryImage = {
            previewImageSrc: item.contentUrl,
            thumbnailImageSrc: item.thumbnailUrl,
            alt: "string",
            title: "Title"
          }
          self.images.push(newNode);
        })
        console.log("Gallery: ", self.images);

      })
      .catch(err => console.error(err));

  }

  FindVideo(search: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        Authorization: '563492ad6f91700001000001654b43a8e2d04946ad43a2468e61c945',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'PexelsdimasV1.p.rapidapi.com'
      }
    };
    var search: string = 'https://pexelsdimasv1.p.rapidapi.com/videos/search?query=' + search + '&per_page=15&page=1';
    console.log("V Search: ", search);
    fetch(search, options)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        var r = <Video[]>response.videos;
        self.videos = [];
        r.forEach(function (item: Video) {

          var smallest: number;
          var smallIndex: number;
          item.video_files.forEach(function (v: VideoFile, i) {
            if (!smallest || (v.height * v.width) < smallest) {
              smallest = v.height * v.width;
              smallIndex = i;
            }
          })

          self.videos.push(item.video_files[smallIndex].link);
        })
        console.log("Videos: ", self.videos, r);

      })
      .catch(err => console.error(err));
  }

  Clicked(command: string) {

    console.log("Clicked ", command);
    switch (command) {
      case "+Height":
        if (this.editProduct.minHeight == null) {
          this.editProduct.minHeight = 200;
        }
        else
          this.editProduct.minHeight += 25;
        this.editProduct.autoHeight = null;


        break;

      case "-Height":
        if (this.editProduct.minHeight == null) {
          this.editProduct.minHeight = 200;
        }
        else
          this.editProduct.minHeight -= 25;
        this.editProduct.autoHeight = null;

        break;
      case "Max content":
        if (!this.editProduct.autoHeight) this.editProduct.autoHeight = "max-content";
        else this.editProduct.autoHeight = null;
        break;

      case "Left":
        if (this.editProduct.imagePosX == null) {
          this.editProduct.imagePosY = 0;
          this.editProduct.imagePosX = 0;
        }
        else
          this.editProduct.imagePosX -= 2;
        break;

      case "Right":
        if (this.editProduct.imagePosX == null) {
          this.editProduct.imagePosY = 0;
          this.editProduct.imagePosX = 0;
        }
        else
          this.editProduct.imagePosX += 2;
        console.log("right ", this.editProduct.imagePosX);
        break;

      case "Up":
        if (this.editProduct.imagePosY == null) {
          this.editProduct.imagePosY = 50;
          this.editProduct.imagePosX = 50;
        }
        //   else
        this.editProduct.imagePosY -= 2;
        //  console.log("UP UP ", this.editProduct)
        break;
      case "Down":
        if (this.editProduct.imagePosY == null) {
          this.editProduct.imagePosY = 0;
          //     this.editProduct.imagePosX = 0;
        } else
          this.editProduct.imagePosY += 2;
        console.log("down ", this.editProduct.imagePosY);

        break;
      case "Cover":
        this.editProduct.backgroundSize = 'cover';
        break;
      case "Contain":
        this.editProduct.backgroundSize = "contain";
        break;
      case "Fill":
        this.editProduct.backgroundSize = "fill";
        break;
      case "None":
        this.editProduct.backgroundSize = "none";
        break;
      case "Scale down":
        this.editProduct.backgroundSize = "scale-down";
        break;


    }


  }
}
