import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, CollectionReference } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { User, Contact, Media, MediaGroup } from '../../user-interface';
import { Company, Navigate, Reviews, Review, YelpOverview, GoogleReviewsID, GoogleBusinessInfo, GooglePhotos } from '../../company-interface';
import { Globals } from 'src/app/globals';
import { Logger } from 'src/app/functions';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root' // Just do @Injectable() if you're not on Angular v6+
})

export class MediaService {

  newGooglePhotos: GooglePhotos[];
  searchList: Media[];
  uploadingMedia: boolean = false;
  newMedia: Media;

  constructor(public global: Globals, private http: HttpClient) {


  }

  addImageToUser(newMedia: Media) {
    var self = this;
    var db = firebase.firestore();

    /*
    // Create URL for thumbnail
    var fn = photo.lastIndexOf("media");


    var thumb = "thumb%2fthumb_" + photo.slice(fn + 16);
    var thumbStr = photo.slice(0, fn + 16) + thumb;

    // swap the extension if .MOV
    var hasExt = thumbStr.indexOf('.MOV');
    if (hasExt != -1) {
      //off now   thumbStr = thumbStr.replace(".MOV", ".JPG");
    }

    var small = "small%2fsmall_" + photo.slice(fn + 16);
    var smallStr = photo.slice(0, fn + 16) + small;
    // swap the extension if .MOV
    var hasExt = smallStr.indexOf('.MOV');
    if (hasExt != -1) {
      //   smallStr = smallStr.replace(".MOV", ".jpg");
    }
    var med = "med%2fmed_" + photo.slice(fn + 16);
    var medStr = photo.slice(0, fn + 16) + med;

    var newMedia: Media = {
      //   fileId?: string;
      name: name,
      altDesc: "image desc",
      url: photo,
      thumbnailUrl: photo, // UNTIL I FIX thumbStr,
      smallUrl: photo, // UNTIL I FIX  smallStr,
      medUrl: photo, // UNTIL I FIX  medStr,
      size: file.size,
      // height?: number;
      //  width?: number;
      //  size?: number
      //  format?: string;
      fileType: file.type,

      id: "string",
      userId: userId,
      uploadId: userId, // who uploaded
      createdAt: Date.now(),
      updatedAt: Date.now(),
      updatedBy: userId,

    }
    */


    console.log("media ", newMedia);

    var ref2 = db.collection("users").doc(self.global.authuser.uid).collection("media").doc()
    newMedia.id = ref2.id;

    ref2.set(
      newMedia
    )
      .then(() => {
        console.log("New Media added.", newMedia);

      })
      .catch((error) => {
        console.error("Error in media", error);
      });
  }


  createMedia(folder, uid, type, url: string, file: any, name) {
    var self = this;
    var db = firebase.firestore();

    // Create URL for thumbnail
    var fn = url.lastIndexOf("/");

    let base = url.replace(".jpg", ".png");
    base = base.replace(".jpeg", ".png");

    console.log("REPLACED JPGS ", base)
    var thumb = "thumb_" + base.slice(fn + 1);
    var thumbStr = base.slice(0, fn + 1) + thumb;

    var small = "small_" + base.slice(fn + 1);
    var smallStr = base.slice(0, fn + 1) + small;

    var med = "med_" + base.slice(fn + 1);
    var medStr = base.slice(0, fn + 1) + med;

    var large = "large_" + base.slice(fn + 1);
    var largeStr = base.slice(0, fn + 1) + large;

    var newMedia: Media = {
      //   fileId?: string;
      name: name,
      altDesc: "image desc",
      url: url,
      thumbnailUrl: thumbStr,
      smallUrl: smallStr,
      medUrl: medStr,
      largeUrl: largeStr,
      // height?: number;
      //  width?: number;
      size: file.size,
      tags: [],
      //  format?: string;
      fileType: file.type,

      //   id: "string",
      //   companyId: self.global.myCompany.id,
      //   store: self.global.myCompany.store,
      userId: self.global.authuser.uid,
      //??   fileId?: string;
      uploadId: self.global.authuser.uid, // who uploaded
      createdAt: Date.now(),
      updatedAt: Date.now(),
      updatedBy: self.global.authuser.uid

    }

    console.log("MEDIA CREATION ", newMedia, url, thumbStr, smallStr, medStr)

    return newMedia
  }



  addImageToCompany(newMedia: Media) {
    var self = this;
    var db = firebase.firestore();

    console.log("media ", newMedia);

    var ref2 = db.collection("company").doc(self.global.myCompany.id).collection("media").doc()

    newMedia.id = ref2.id;

    ref2.set(
      newMedia
    )
      .then(() => {
        console.log("New Media added.", newMedia);

      })
      .catch((error) => {
        console.error("Error in media", error);
      });
    return newMedia

  }


  addYelpImage(photo: string, tag) {
    var self = this;
    var db = firebase.firestore();


    var newMedia: Media = {
      //   fileId?: string;

      altDesc: "image desc",
      url: photo,
      // height?: number;
      //  width?: number;
      //  size?: number
      tags: [this.global.myCompany.name],
      //  format?: string;
      fileType: "image/jpeg",

      id: "string",
      companyId: this.global.myCompany.id,
      store: this.global.myCompany.store,
      userId: this.global.authuser.uid,
      //??   fileId?: string;
      uploadId: this.global.authuser.uid, // who uploaded
      createdAt: Date.now(),
      updatedAt: Date.now(),
      updatedBy: this.global.authuser.uid

    }
    newMedia.tags.push(tag)

    console.log("review media added ", newMedia);

    var ref2 = db.collection("company").doc(this.global.myCompany.id).collection("media").doc()

    newMedia.id = ref2.id;

    ref2.set(
      newMedia
    )
      .then(() => {
        console.log("New Media added.", newMedia);

      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }




  FetchGooglePhotos() {
    var self = this;

    self.newGooglePhotos = [];
    if (!self.global.myCompany.googlePhotos) self.global.myCompany.googlePhotos = [];

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'local-business-data.p.rapidapi.com'
      }
    };

    var str = 'https://local-business-data.p.rapidapi.com/business-photos?business_id='
    str += self.global.myCompany.googleBusinessInfo.business_id;
    str += "&limit=100&region=us"

    fetch(str, options)
      .then(response => response.json())
      .then(response => {

        // Get Google images - store only new items
        var media = <GooglePhotos[]>response.data;
        console.log(media)

        media.forEach(function (item) {
          const exists = self.global.myCompany.googlePhotos.some(x => x.photo_id === item.photo_id)
          if (!exists) self.newGooglePhotos.push(item);
          else console.log("Already have it ");
        })
        // Now we have an array of new images
        if (self.newGooglePhotos.length) {
          self.UpdateGooglePhotos(self.newGooglePhotos);
        }

      })
      .catch(err => console.error(err));

  }

  UpdateGooglePhotos(gPhotos: GooglePhotos[]) {
    // Add these photos to Media (check url first)
    var self = this;
    var db = firebase.firestore();

    gPhotos.forEach(function (media, index) {

      //  video_thumbnail_url

      var newMedia: Media = {
        //   fileId?: string;
        name: "Google photo",
        altDesc: "image desc",
        url: media.photo_url_large,
        thumbnailUrl: media.photo_url,

        tags: [self.global.myCompany.name, "Google Photo"],

        id: "string",
        companyId: self.global.myCompany.id,
        store: self.global.myCompany.store,
        userId: self.global.authuser.uid,
        //??   fileId?: string;
        uploadId: self.global.authuser.uid, // who uploaded
        createdAt: media.photo_timestamp,
        updatedAt: Date.now(),
        updatedBy: self.global.authuser.uid
      }

      if (media.type.toLowerCase() == "image") {
        newMedia.fileType = "image/jpeg";
      }
      if (media.type.toLowerCase() == "video") {
        newMedia.fileType = "video/mp4";
      }

      console.log("media ", newMedia);
      // First Check if already in DB
      var ref1 = db.collection("company").doc(self.global.myCompany.id).collection("media")
        .where("url", "==", newMedia.url).get()
        .then((doc) => {

          if (doc.empty) {

            var ref2 = db.collection("company").doc(self.global.myCompany.id).collection("media").doc()

            newMedia.id = ref2.id;

            ref2.set(
              newMedia
            )
              .then(() => {
                console.log("New Media added... ", newMedia);

              })
              .catch((error) => {
                console.error("Error in media", error);
              })

          }
          else console.log("Already have it...", newMedia);
        })
    })

  }

  /*
  UpdateGooglePhotos(gPhotos: GooglePhotos[]) {
    var self = this;
    var db = firebase.firestore();


    var ref2 = db.collection("company").doc(self.global.myCompany.id);
    ref2.update({
      googlePhotos: firebase.firestore.FieldValue.arrayUnion(...gPhotos) // Spread operator

    })
      .then(() => {
        console.log("Photos added.");

      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }

*/
  UpdateJoJImages(images: Media[]) {
    var self = this;
    var db = firebase.firestore();

    var ref2 = db.collection("company").doc(self.global.myCompany.id);
    ref2.update({
      searchImages: firebase.firestore.FieldValue.arrayUnion(...images) // Spread operator

    })
      .then(() => {
        console.log("Photos added.");

      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }

  /*
    imageSearch(cursor?: string) {
      var self = this;
  
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
          'X-RapidAPI-Host': 'joj-image-search.p.rapidapi.com'
        }
      };
  
      var str = 'https://joj-image-search.p.rapidapi.com/v2/?q=';
      str += self.global.myCompany.name + " " + self.global.myCompany.city + " "
        + self.global.myCompany.state + '&hl=en';
  
      //  if (cursor.length) str += '&cursor=' + cursor;
      console.log("Image search ", str);
      fetch(str, options)
        .then(response => response.json())
        .then(response => {
          self.searchList = [];
          self.imagesFound = [];
          console.log(response)
          self.imagesFound = <JoJImageData[]>response.response.images;
          self.imagesFound.forEach(function (image) {
  
            var newImage: Media = {
              //      createdAt?: string;
              //      customCoordinates?: string;
              //      fileId?: string;
              //      name?: string;
              url: image.image.url,
              thumbnailUrl: image.image.url,
              height: image.image.height,
              width: image.image.width,
              source: "Search",
              //         size?: number
              //         filePath?: string;
              //         tags?: string[];
              //        format?: string;
              //fileType: "image",
  
            }
            self.searchList.push(newImage);
          })
  
          // Call myself if there is a next_Cursor
          console.log("List: Next: ", self.searchList, response.response.next_cursor);
          // if (response.response.next_cursor)
          // self.imageSearch(response.response.next_cursor);
  
          self.UpdateJoJImages(self.searchList);
  
        })
        .catch(err => console.error(err));
  
    }
  */
  mediaGroupListener: boolean = false;
  getMediaGroups() {
    var self = this;
    if (self.mediaGroupListener) return;
    if (!self.global.myCompany) return;
    var db = firebase.firestore();
    self.mediaGroupListener = true;
    self.global.mediaGroups = [];

    db.collection('company')
      .doc(self.global.myCompany.id.trim())
      .collection('mediaGroups')
      //      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const m = <MediaGroup>change.doc.data();
            if (!m.removed || m.removed != true) {
              if (!m.access) m.access = "private"; // Auto upgraded by App visibility
              self.global.mediaGroups.push(m);
            }
          }
          if (change.type === "modified") {
            const m = <MediaGroup>change.doc.data();
            const index = self.global.mediaGroups.findIndex(x => x.id === m.id)
            //HAVE NO IDEA WHY NOT NEEDED   if (index != -1) self.global.mediaGroups[index] = {...m}
            console.log("Modified city: ", change.doc.data());
          }
          if (change.type === "removed") {
            const m = <MediaGroup>change.doc.data();

            console.log("Removed city: ", change.doc.data());
          }
        });

        console.log("Current MediaGroups: ", self.global.mediaGroups);

      });


  }

  updateMediaGroups(m: Media) {
    var self = this;
    var db = firebase.firestore();

    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);

    docRef2.update({
      mediaGroups: m.mediaGroups

    })
      .then(() => {
        console.log("added tags ", m.id, m.tags)
      }).catch((error) => { console.error("Error: ", error); });

  }

  updateAccess(m: Media) {
    var self = this;
    var db = firebase.firestore();

    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);

    docRef2.update({
      access: m.access,

    })
      .then(() => {
        console.log("access updated ", m.id, m.tags)
      }).catch((error) => { console.error("Error: ", error); });

  }

  updateAltDesc(m: Media) {
    var self = this;
    var db = firebase.firestore();

    var docRef2 = db.collection("company")
      .doc(self.global.myCompany.id)
      .collection('media')
      .doc(m.id);

    docRef2.update({
      altDesc: m.altDesc,

    })
      .then(() => {
        console.log("AltDesc updated ", m.id, m.tags)
      }).catch((error) => { console.error("Error: ", error); });

  }


  updateMediaTags(m: Media) {
    var self = this;
    var db = firebase.firestore();


    if (self.global?.myCompany?.id)
      var docRef2 = db.collection("company")
        .doc(self.global.myCompany.id)
        .collection('media')
        .doc(m.id);
    else
      var docRef2 = db.collection("users")
        .doc(self.global.authuser.uid)
        .collection('media')
        .doc(m.id);

    var updateInfo: Media = {};
    if (m.tags)
      updateInfo.tags = m.tags;
    if (m.autoTags)
      updateInfo.autoTags = m.autoTags;

    docRef2.update(
      updateInfo
    )
      .then(() => {
        console.log("added tags ", m.id, m.tags)
      }).catch((error) => { console.error("Error: ", error); });

  }

  mediaListener: boolean = false;
  mediaListen;

  getMedia(remove?: boolean) {
    var self = this;
    var db = firebase.firestore();

    if (remove) {
      if (self.mediaListener) {
        self.mediaListen(); // REMOVES IT
        self.mediaListener = false;
      }
    }

    console.log("Init Media Listener ")
    if (self.mediaListener) return;

    self.mediaListener = true;
    self.global.media = [];
    self.global.mediaTags = [];

    //    if (self.companyId) {
    const ref = db.collection('company')
      .doc(self.global.myCompany.id.trim())
      .collection('media')
      .orderBy('createdAt', 'desc')

    this.mediaListen = ref.onSnapshot((querySnapshot) => {

      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const m = <Media>change.doc.data();
          // if (!m.id) {
          m.id = change.doc.id; //fixer
          //   console.log("Fix media Id ", m.id);
          // }
          if (!m.tags) m.tags = [];
          if (!m.removed || m.removed != true) {
            if (!m.access) m.access = "private"; // Auto upgraded by App visibility

            const nodeCount = m.tags.some(function (post) {
              if (post == "wide" || post == "tall" || post == "square")
                return true;
            });

            if (!nodeCount) { // Let's add the aspect ratio for filtering
              const aspect = m.width / m.height;
              if (aspect > 1.3) m.tags.push("wide");
              else if (aspect < .80) m.tags.push("tall");
              else m.tags.push("square");
            }
            self.global.media.push(m);
            // Auto tags...
            if (m.fileType) {
              if (!m.tags || !m.tags.includes(m.fileType))
                m.tags.push(m.fileType)
              /*
  
                    if ((m.fileType == "video/mp4" || m.fileType == "video/mov") && (!m.tags || !m.tags.includes("video")))
                      m.tags.push("video")
                    else if ((m.fileType == "image/jpeg" || m.fileType == "image/png") && (!m.tags || !m.tags.includes("image")))
                     m.tags.push("image")
  
                */
              if (m.tags) {
                m.tags.forEach(function (t) {
                  if (!self.global.mediaTags.includes(t))
                    self.global.mediaTags.push(t);
                })
              }
            }
          }
        }
        if (change.type === "modified") {
          //    const m = <MediaGroup>change.doc.data();
          //    const index = self.global.media.findIndex(x => x.id === m.id)
          //HAVE NO IDEA WHY NOT NEEDED   if (index != -1) self.global.media[index] = {...m}
          console.log("Modified city: ", change.doc.data());

          const m = <Media>change.doc.data();
          if (m.removed) {
            const index = self.global.media.findIndex(x => x.id === m.id)
            if (index != -1) {
              self.global.media.splice(index, 1)//REMOVED
            }
          }
        }
        if (change.type === "removed") {
          const m = <Media>change.doc.data();
          const index = self.global.media.findIndex(x => x.id === m.id)
          if (index != -1) {
            self.global.media.splice(index, 1)//REMOVED
          }
          console.log("Removed media: ", change.doc.data());
        }
      });

      //   self.media.sort(function (a, b) { return b.createdAt - a.createdAt; });

      console.log("Current Media: ", self.global.media, self.global.mediaTags);

    })
  }

  newUpload(media: Media, event: any, destination: string, callback?: any, mySelf?) {
    if (!event?.target?.files[0]) return; // no file selected

    var self = this;
    // var media: Media;
    var fn = event.target.value.lastIndexOf("\\");
    var imageName: string = event.target.value;
    imageName = imageName.slice(fn + 1);
    const file = event.target.files[0];

    // Files captured from camera will have name: image - we need to fix
    if (imageName.startsWith('image')) {//fix to use getuidfunc
      const v = Math.floor(Math.random() * 9999).toString()
      imageName = imageName.replace(".", v + ".")
    }

    var path = "";
    if (destination.toLowerCase() == 'user') {
      path = self.global.authuser.uid + "/images/"
    }
    else {
      path = self.global.myCompany.id + "/images/"
    }
    const name = path + imageName;

    //type, uid, storeID, info1?, info2?, info3?
    // Logger("Media Upload", self.global.authuser.uid, "", file.type, imageName);

    console.log("FILE TYPE: ", file.type);
    if (file.type == "image/heic") {
      return; // not supported
    }
    console.log("Upload Media: ", path, event, file);

    const formData: FormData = new FormData();
    formData.append('file', file);
    if (destination.toLowerCase() == 'user')
      formData.append("bucket", "user-media");
    else
      formData.append("bucket", "store-content");
    formData.append('path', path);
    // use name w/o ext.
    var nameOnly = imageName.split('.')
    formData.append('fileName', nameOnly[0])

    self.http.post('https://uploadfile-xdnunfyrka-uc.a.run.app',
      formData).subscribe({
        next: response => {
          console.log('File sent', response)
          var results: { data: { url: string }, message: string, success: boolean }
          results = <any>response

          if (typeof (callback) == 'function') {
            callback(mySelf, results.data.url, file, imageName, media)
          }

        },
        error: error => console.error('Error sending file', error)
      });
  }

  createThumbnail(bucket, key, outputFile, width, height) {
    console.log("Creating thumbnail ", width, height, bucket, key)

    this.http.post('https://createthumbnail-xdnunfyrka-uc.a.run.app',
      {
        outputFormat: 'png',
        format: 'png',
        width: width,
        height: height,
        bucket: bucket,
        key: key,
        outputImage: outputFile
      }).subscribe({
        next: response => {
          console.log('Thumbnail created', response)
          var results: { data: { url: string }, message: string, success: boolean }
          results = <any>response
          //...
        },
        error: error => console.error('Error sending file', error)
      });



  }



  autoTag(imgUrl, callback, mySelf) {
    console.log("Creating tags ", imgUrl)


    this.http.post('https://imagecaptioning-xdnunfyrka-uc.a.run.app',
      {
        "bucket": "store-content",
        "filename": "5vjmmQOnSx31XLsMDvZx/images/charcuterie.png"
      }).subscribe({
        next: response => {
          console.log('caption created', response)
        //  var results: { data: { url: string }, message: string, success: boolean }
         // results = <any>response
          if (typeof (callback == 'function')) {
          //  callback(mySelf, results)
          }

        },
        error: error => console.error('Error sending file', error)
      });

    /*
    
        this.http.post('https://autotag-xdnunfyrka-uc.a.run.app',
          {
            imageUrl: imgUrl,
            language: 'en',
            limit: 20,
            threshold: 0.7
          }).subscribe({
            next: response => {
              console.log('Tags created', response)
              var results: { data: { url: string }, message: string, success: boolean }
              results = <any>response
              if (typeof (callback == 'function')) {
                callback(mySelf, results)
              }
    
            },
            error: error => console.error('Error sending file', error)
          });
    
    */

  }


  croppings(imgUrl, outputFile) {
    console.log("Croppings ", imgUrl)

    this.http.post('https://croppings-xdnunfyrka-uc.a.run.app',
      {
        imageUrl: imgUrl,
        language: 'EN',
        limit: 20,
        threshold: 0.0
      }).subscribe({
        next: response => {
          console.log('Tags created', response)
          var results: { data: { url: string }, message: string, success: boolean }
          results = <any>response
          //...
        },
        error: error => console.error('Error sending file', error)
      });



  }





  /*
  
  
    createMedia(photo: string, file: any, name: string) {
      var self = this;
  
      // Create URL for thumbnail
      var fn = photo.lastIndexOf("review-images");
  
  
      var thumb = "thumb%2fthumb_" + photo.slice(fn + 16);
      var thumbStr = photo.slice(0, fn + 16) + thumb;
  
      // swap the extension if .MOV
      var hasExt = thumbStr.indexOf('.MOV');
      if (hasExt != -1) {
        //off now   thumbStr = thumbStr.replace(".MOV", ".JPG");
      }
  
      var small = "small%2fsmall_" + photo.slice(fn + 16);
      var smallStr = photo.slice(0, fn + 16) + small;
      // swap the extension if .MOV
      var hasExt = smallStr.indexOf('.MOV');
      if (hasExt != -1) {
        //   smallStr = smallStr.replace(".MOV", ".jpg");
      }
  
  
      var med = "med%2fmed_" + photo.slice(fn + 16);
      var medStr = photo.slice(0, fn + 16) + med;
  
      console.log("Sizes: ", fn, medStr, smallStr);
  
      self.newMedia = {
        //   fileId?: string;
        name: name,
        store: self.global.myCompany.store,
        updatedAt: Date.now(),
        createdAt: Date.now(),
        altDesc: "image desc",
        url: photo,
        thumbnailUrl: thumbStr,
        smallUrl: smallStr,
        medUrl: medStr,
        // height?: number;
        //  width?: number;
        //  size?: number
        tags: [self.global.myCompany.name], // Add Review when published
        //  format?: string;
        fileType: file.type,
      }
  
      console.log("media ", self.newMedia);
  
  
      console.log("Media added. ", self.newMedia.smallUrl);
    }
  
    */
  /*
    addImageToCompany2(media: Media) {
      var self = this;
      var db = firebase.firestore();
      console.log("Store Media add ", media)
  
      // add Media to Company as sub-collection
      var ref2 = db.collection("company").doc(this.global.myCompany.id)
        .collection("media").doc().set(media);
  
      // All done!
    }
  
  
    addImageToUser2(media: Media) {
      var self = this;
      var db = firebase.firestore();
  
      // add Media to user as sub-collection
      var ref2 = db.collection("users").doc(self.global.authuser.uid)
        .collection("media").doc().set(media);
  
      // All done!
  
    }
    */

    addMediaGroup($event) {
      var self = this;
      var db = firebase.firestore();

      var newName = "new group"

      const ref = db.collection('company')
          .doc(self.global.myCompany.id.trim())
          .collection('mediaGroups').doc();

      var newMediaGroup: MediaGroup = { id: ref.id, name: newName }
      ref.set(newMediaGroup)
  }

}