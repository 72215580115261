<div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="addSheet($event)" class="create-form" pButton pRipple type="button">
        Create New Napkin Sheet
    </button>
</div>
<div *ngIf="global.allSheets">
    <p-accordion styleClass="tab" [multiple]="false" expandIcon="false" collapseIcon="false"
        *ngFor="let sheet of global.allSheets; let i = index;" (onOpen)="onSheetOpen($event, i)"
        (onClose)="onSheetClose($event, i)">

        <p-accordionTab>
            <ng-template pTemplate="header">
                <div fxLayout="row" fxLayoutAlign="none none">
                    <div>                
                       {{getSheetName(sheet)}}
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="content">
                <app-sheet [sheet]="sheet"></app-sheet>
            </ng-template>
        </p-accordionTab>
    </p-accordion>

</div>

