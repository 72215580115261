// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  OPEN_AI_KEY:"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",
  CLIENT_EMAIL:"info@napkinhq.com",
  STORAGE_ENDPOINT:"nyc3.digitaloceanspaces.com",
  STORAGE_ACCESS_KEY_ID:"DO00GQUTYBEFBTTLWB7C",
  STORAGE_SECRET_ACCESS_KEY:"gS4qWsjXIX5reWem0ArjPosG08ZGNNggi433v3Vtn08",
  SMTP_HOST:"send.smtp.com",
  SMTP_USER:"swarner100@gmail.com",
  SMTP_PASS:"lhxgb6b6",
  firebase: {
    apiKey: "AIzaSyBTKYfQJFo5y_RMCoMBEenzjXWLZNTRwpY",
    authDomain: "suncraft-ea7be.firebaseapp.com",
    projectId: "suncraft-ea7be",
    databaseURL: "https://suncraft-ea7be-default-rtdb.firebaseio.com/",
    storageBucket: "suncraft-ea7be.appspot.com",
    messagingSenderId: "451205557940",
    appId: "1:451205557940:web:b23d2d2e9e501f009c71e1",
    measurementId: "G-Q2J4GDEDNE"
  },
  stripe: {
    published_key: "pk_test_r92QM1u6KY5SDssmp9bHODCO00fxsBtiB0",
    client_id: "ca_JTOzAZ8dQg86kCKyRrz0qKTEcM26OQrB"
  }


};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



/*

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   firebase: {
    apiKey: "AIzaSyBTKYfQJFo5y_RMCoMBEenzjXWLZNTRwpY",
    authDomain: "suncraft-ea7be.firebaseapp.com",
    projectId: "suncraft-ea7be",
    storageBucket: "suncraft-ea7be.appspot.com",
    messagingSenderId: "451205557940",
    appId: "1:451205557940:web:b23d2d2e9e501f009c71e1",
    measurementId: "G-Q2J4GDEDNE"

}
};

*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
