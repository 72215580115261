

import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Query, When, Report, FieldInfo, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, News, Media } from "../user-interface";
import { set, parseISO, getDate, getMonth, startOfDay, endOfDay, getMilliseconds } from "date-fns";
import { FormService } from '../form.service';
import { HttpClient } from '@angular/common/http';
import { WhereFilterOp } from 'firebase/firestore';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss', '../../common.scss']
})

export class NewsComponent implements OnInit {
  @Input() editing: boolean = false;

  newsQuery;
  newsResults;

  constructor(public global: Globals,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private formService: FormService,
  ) {

  }

  ngOnInit(): void {
    console.log("WIDTH: ", this.global.getScreenWidth)

    if (!this.global.authuser.newsSettings)
      this.global.authuser.newsSettings = {
        includePhotos: true, subArticles: false, excluded: [], newsQuery: []
      }

  }
  fieldEvent(field: Field) {
    console.log("NEWS: ", field, this.newsQuery)

    if (field.title == 'searchNews') {
      this.newsQuery = field.binding;
    }
    if (field.title == 'search' && this.newsQuery && this.newsQuery.length > 2) {
      this.searchNews();
    }
    if (field.title == 'topHeadlines') {
      this.topHeadlines();
    }

  }

  searchNews() {
    var self = this;



    //  const url = 'Elon%20Musk&limit=500&time_published=anytime&country=US&lang=en';
    var str = "https://real-time-news-data.p.rapidapi.com/search?query="
    str += self.newsQuery;
    str += "&limit=500&time_published=anytime&country=US&lang=en";
    console.log("Yelp get reviews: ", str);

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'x-rapidapi-host': 'real-time-news-data.p.rapidapi.com'
      }
    };

    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        var r: News[];
        if (response?.data) {
          r = <News[]>response.data;

          r.forEach(function (news) {
            self.validateSource(news.source_url)
            news.date = parseISO(news.published_datetime_utc)
          })

          console.log("NEWS-> ", response, r);
          self.newsResults = r;
        }

      })
      .catch(err => console.error(err));
  }

  topHeadlines() {
    var self = this;

    // Check headlines DB for data first

    var start = startOfDay(Date.now()).getTime()
    var end = endOfDay(Date.now()).getTime()

    console.log(start, end)

    var db = firebase.firestore();

    db.collection("headlines")
      .where("timeStamp", ">=", start).where("timeStamp", "<", end)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          self.fetchTopHeadlines();
        }
        else {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            console.log("Got the news baby ", data)
            self.newsResults = data.news;
          })
        }
      })
  }

  fetchTopHeadlines() {
    var self = this;

    var str = "https://real-time-news-data.p.rapidapi.com/top-headlines"
    str += "?limit=500&country=US&lang=en";

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'x-rapidapi-host': 'real-time-news-data.p.rapidapi.com'
      }
    };

    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        var r: News[];
        if (response?.data) {
          r = <News[]>response.data;
          console.log("Headlines-> ", response, r);

          self.saveTopHeadlines(r);

          r.forEach(function (news) {
            self.validateSource(news.source_url)
            if (news.published_datetime_utc)
              news.date = parseISO(news.published_datetime_utc)
          })

          console.log("Headlines-> ", response, r);
          console.log("Sources--> ", self.global.authuser.mediaSources)

          self.newsResults = r;
        }

      })
      .catch(err => console.error(err));
  }

  saveTopHeadlines(news: News[]) {
    var self = this;
    var db = firebase.firestore();

    news.forEach(function (item) {
      if (item.published_datetime_utc) {
        item.date = parseISO(item.published_datetime_utc)
        item.timeStamp = item.date.getTime();
      }
    })

    var timeStamp = Date.now();
    var mediaRef = db.collection("headlines").doc();
    mediaRef.set({ timeStamp: timeStamp, news: news })
      .then(() => {
        console.log("Headlines added!", news);
      })
      .catch((error) => {
        console.error("Error updating headlines: ", error);
      });


  }

  validateSource(sourceUrl: string) {
    var self = this;

    if (!sourceUrl) return;
    if (sourceUrl.length < 5) return;

    /*    
        // First extract the source from URL crap
        var strItems = sourceUrl.split("https://www.")
        if (strItems.length == 1)
          strItems = strItems[0].split('https://')
    
        var srcName = strItems[0];
    */

    const getHostnameFromRegex = (url) => {
      const host = new URL(url).host;
      const dots = host.split('.');
      return dots.at(-2).replace(/\d/g, '');
    }

    const srcName = getHostnameFromRegex(sourceUrl);

    console.log("Source: ", srcName)

    if (!self.global.authuser.mediaSources)
      self.global.authuser.mediaSources = []

    if (!self.global.authuser.mediaSources.includes(srcName))
      self.global.authuser.mediaSources.push(srcName);


  }
  getDay(n: News) {
    const result = getDate(n.timeStamp)
    return result
  }

  months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  getMonth(n: News) {
    const result = getMonth(n.timeStamp)
    return this.months[result]
  }


  getDateTime(news: News) {
    var str = " ";
    //  if (news.date && typeof (news.date) == 'object')
    //    str += news.date.toDateString();

    return str;
  }

  getFullWidth() {
    var style;
    var self = this;

    style = "width: " + self.global.getScreenWidth + 'px;'

    return style;
  }


  outputEvent(form: Form) {
    var self = this;

    console.log("FORM: ", form)

    self.formService.napkinFieldsToObject(form, self.global.authuser.newsSettings)
    console.log("Object output ", self.global.authuser.newsSettings)
    self.formService.updateNewsSettings();

  }

}
