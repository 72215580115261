import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../../../environments/environment';
import { interval } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Company, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../../../company-interface';
import { Product, Options } from '../../../product-int'

@Component({
  selector: 'app-res-rewards',
  templateUrl: './res-rewards.component.html',
  styleUrls: ['../reserve.component.css','./res-rewards.component.css']
})
export class ResRewardsComponent implements OnInit {
  @Output() setRewardEvent = new EventEmitter<Product>();


  viewRewardTab: boolean[];
  viewRewards: boolean;
  rewardItems: Product[];
  selectedReward: Product;

  constructor(public global: Globals) { }

  ngOnInit(): void {

    this.GetRewardItems();
  }

  toggleSelect(item: Product) {
    var self = this;

    if (this.selectedReward == item)
      this.selectedReward = null;
    else {
      self.selectedReward = item;
      self.setRewardEvent.emit(self.selectedReward);
      for (var n=0;n<self.viewRewardTab.length;n++) self.viewRewardTab[n] = false;
      self.viewRewards = false;
    }

    console.log("sel reward: ", this.selectedReward,);
  }

  GetRewardItems() {
    var self = this;
    var db = firebase.firestore();
  
    self.viewRewardTab = [];
  
    db.collection("products").where("companyID", "==", self.global.myCompany.id).where("resRewards", "==", true)
      .get()
      .then((querySnapshot) => {
        self.rewardItems = [];
        querySnapshot.forEach((doc) => {
  
          const data = <Product>doc.data();
  
          self.viewRewardTab.push(false);
          self.rewardItems.push(data);
  
        });  
      })
      .catch((error) => {
        console.log("Error getting rewards: ", error);
      });
  
  }
  


}
