<div *ngIf="open && form">
    <app-form (update)="updateData($event)" [form]="form"></app-form>
    
    <div fxLayout="row wrap">
        <div *ngIf="global.authuser.uid=='kvT6eKFxFHb3EKbVeCTusW86CrZ2'">
            <button (click)="moveForm(form.name)" class="scott-function" pButton pRipple type="button">
                Save this form to Master
            </button>
            <button (click)="createFunction(formIndex)" class="scott-function" pButton pRipple type="button">
                Save form as Function
            </button>
            <button *ngIf="form.id" (click)="randomData(form)" class="scott-function" pButton pRipple type="button">
                CREATE 100 Random Records
            </button>
            <button *ngIf="form.id" (click)="deleteData(form)" class="command" pButton pRipple type="button">
                DELETE this data record
            </button>
        </div>

        <button *ngIf="form?.fields.length" (click)="clearForm(form)" class="command" pButton pRipple type="button">
            Clear form data
        </button>
 

    </div>

    <app-container [settings]="formEditSettings" [form]="form" [list]="formEditList" [api]="false"
        [apiFunction]="'EditingForm'" [formIndex]="formIndex" (openTab)="tabOpened($event)"></app-container>

</div>