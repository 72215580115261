import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Globals } from 'src/app/globals';
import { Product } from '../components/product-int';
import { onMenuItem } from './menu-item-interface';
import { Content } from './content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Order } from '../components/order-interface';
import { Company, Theme, Functions, Query, When, Form, Report, Field, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from './company-interface';
//import { RegisterService } from '../components/register.service';
import { Creds, Follows, User, Notify } from './user-interface';
import { set, startOfYear, lastDayOfMonth, subMonths, add, startOfMonth, startOfDay, endOfDay, getMilliseconds, endOfMonth } from "date-fns";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { HttpClient } from '@angular/common/http';


interface Column {
  title: string;
  header: string;
  width?: number;
  //  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class FormService {

  newCompanyID: string;
  storeNum: string;
  busName: string;
  services: Enabled;



  constructor(private global: Globals, private http: HttpClient, private sanitizer: DomSanitizer) {


  }

  helloWorld() {
    console.log("Hello")
  }
  /*
    regStore(form: Form) {
      console.log("Reg store ", form)
   OLDER VERSION - NOW IN REGISTER SERVICE
      var self = this;
      console.log("Form ", form);
  
      //      if (!validatePhoneNumber(this.reservePhone)) return;
      self.newCompanyID = self.registerService.GetCompanyUID();
      var newStore = (+Math.random() * 9000 + 1000); //hack
      var newStoreInt = Math.trunc(newStore);
      self.storeNum = newStoreInt.toString();
  
  
      const zipcode = form.fields[this.findMe("Zipcode", form)].binding;
      const address = form.fields[this.findMe("Address", form)].binding;
      const state = form.fields[this.findMe("State", form)].binding;
      const phone = form.fields[this.findMe("Business phone", form)].binding;
      const city = form.fields[this.findMe("City", form)].binding;
      const domain = form.fields[this.findMe("Domain URL", form)].binding;
      const busName = form.fields[this.findMe("Business Name", form)].binding;
  
      this.busName = busName;
  
      if ((!busName || busName.length == 0)) { alert("Please enter a business name."); return; }
  
  
      self.geoCode(address, city, state, zipcode);
      self.services = {
        appBuilder: true,
        categories: false,
        groupBookings: false,
        pos: false,
        orders: false,
        products: false,
        reservationRewards: false,
        reservations: false,
        rewards: false,
        chatService: false,
        media: true,
        reviews: true,
        reviewsPro: false,  // Implement limits (includes app creator??)
        manageStaff: true,
        reports: true,
        forms: true,
  
      }
      if (self.global.entryPoint == "openbabble") {
        self.services = {
          ...self.services,
          chatService: true,
        }
      }
  
      var chatService;
      var chatServiceEmail;
      var email = self.storeNum + "@napkinhq.com";
  
  
  
  
      var db = firebase.firestore();
  
      // For Chat - add chat store user to connect with
      if (self.services.chatService) {
        var docRef = db.collection("users").doc();
        self.registerService.RegisterChatServiceUser(
          email,
          docRef, "Customer", "Service", null, null);
  
        chatService = docRef.id;
        chatServiceEmail = email;
  
      }
  
      var storeFields = {
        name: busName,
        address1: address,
        city: city,
        state: state,
        postal: zipcode,
        store: this.storeNum,
        primaryPhone: phone,
        storeURL: domain,
      }
      // STRIP NULLS all empty fields
      storeFields = removeUndefined(storeFields)
  
  
      self.registerService.registerStore(self.services, storeFields, self.afterRegisterStore, self, chatService, chatServiceEmail);
  
  
      //    if (!this.newUserID) return;
      //    }
   
  
    }
    */
  afterRegisterStore(self: this) {
    self.updateCreds();

    //  self.sendEmailNewStore(self.services);

    //  self.nav.InitReservations(); // Default settings - hours & days

    // Now re-route to /Front
    //  self.router.navigate(['/front'])


  }

  updateForm(form: Form) {
    var self = this;
    var id = form.id;

    if (!id) id = form.formId; //????


    console.log("Form updating: ", form, id)


    var db = firebase.firestore();

    var fieldRef =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(id)
        .update(form).then((querySnapshot) => {
          //self.global.allForms[self.formIndex] = form;
          //console.log("Form settings UPDATEs ", form)
        })

  }

  getDepartments(callback, mySelf) {
    var self = this;
    if (!self.global?.myCompany?.id) return;

    // Check for form: departments/submissions

    // Let's just find the Departments database
    var db = firebase.firestore()
    console.log("Fetching departments...")
    var ref =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").where("name", '==', 'Departments')
        .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var myDept = doc.id
            console.log("Found departments...", doc.id)
            self.getSomeData(myDept, "department", callback, mySelf, "All")
          })
        })
  }



  deptCallback(self, submissions) {
    console.log("Getting DEPTS ", submissions);
    self.global.myCompany.departments = [];
    submissions.forEach(function (item) {
      if (is_array(item)) item = item[0]
      self.global.myCompany.departments.push(item)

    })
    //  self.field.options = self.global.myCompany.departments;
    console.log("GOT OPTIONS BABY ", submissions, self.global.myCompany.departments)
  }

  updateLogic(form: Form) {
    var self = this;
    var id = form.id;

    if (!id) id = form.formId; //????


    // STRIP REMOVE NULLS all empty fields
    form = removeUndefined(form)

    console.log("Form logic updating: ", form, id)
    return;
    var db = firebase.firestore();

    var fieldRef =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(id)
        .update({ logic: form.logic }).then((querySnapshot) => {
          //self.global.allForms[self.formIndex] = form;
          //console.log("Form settings UPDATEs ", form)
        })

  }


  findMe(me: string, form: Form) {
    // me = me.toLowerCase();
    console.log("FIELD LOOKUP: ", me, form)

    const index = form.fields.findIndex(function (post) {
      if (post.binding == me) return true;
      if (post.title == me) return true;
      if (post?.title.toLowerCase() == me.toLowerCase()) return true;

    });
    if (index == -1) {
      alert("oops here 78 " + me)
      console.log("What happend? ", "oops here 78 ", form, me)
    }
    else
      return index;

  }



  updateNewsSettings() {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("users").doc(this.global.authuser.uid);

    ref.update({
      newsSettings: self.global.authuser.newsSettings
    })
      .then(() => {
        console.log("News settings updated: ", self.global.authuser.newsSettings);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }


  updateCreds() {
    var self = this;


    var db = firebase.firestore();
    var ref = db.collection("users").doc(this.global.authuser.uid);

    var notify: Notify = { enable: true, reservations: true };
    var enable: Enabled = { staff: true }
    if (self.services.reviews == true) {
      notify.reviews = true;
      enable.reviews = true;
    }

    var creds: Creds = {
      id: self.newCompanyID,
      storeNum: self.storeNum,
      role: "Creator",
      startDate: Date.now(),
      storeName: self.busName,
      notify: notify,
      enabled: enable,
    }

    var follow: Follows = {
      id: self.newCompanyID,     // remove later
      storeNum: self.storeNum,    // fix name
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: self.busName
    }



    console.log("Set Creds: ", creds, self.global.authuser.uid, notify)

    ref.update({
      creds: firebase.firestore.FieldValue.arrayUnion(creds),
      following: firebase.firestore.FieldValue.arrayUnion(follow)
    })
      .then(() => {
        console.log("Creds updated: ");
        // Save locally
        //   localStorage.setItem('myCreds', JSON.stringify(creds));
        // It's safe now to reload page - still a hack
        //    window.location.reload();
        //  this.global.authuser.creds.push(creds); // add to user record


      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  submissions;
  requests: { formId: string, startDate: number, endDate: number, fieldTitles: string[], callBack: any, mySelf: any, completed: boolean }[] = []
  listeners: { formId: string, startDate: number, endDate: number, fieldTitles: string[] }[] = []

  getSomeData(formId, fieldTitles, callBack, mySelf, filter?: string, query?: Query) {
    var self = this;
    var db = firebase.firestore();

    if (!self.global.allSubmissions) self.global.allSubmissions = [];
    self.submissions = [];

    // MOVE DATE RANGE CODE TO FUNCTION 
    var dayStart = new Date(startOfDay("2000/01/01")).getTime()
    var dayEnd = new Date(endOfDay(Date.now())).getTime();

    if (!filter && query?.when) filter = query.when;

    if (!filter) filter = "all"; // DEFAULT
    filter = filter.toLowerCase()
    console.log("Filter/Query ", filter, query)

    // FIX THIS CODE - MOVE iT to FUNCTION - use When
    if (filter == 'today') {
      dayStart = startOfDay(Date.now()).getTime()
      dayEnd = endOfDay(Date.now()).getTime();
    }

    if (filter == 'yesterday') {
      var today = startOfDay(Date.now())
      const yesterday = add(today, { days: -1 });
      dayStart = yesterday.getTime()
      dayEnd = endOfDay(yesterday).getTime();
    }

    if (filter == 'this month') {
      dayStart = startOfDay(startOfMonth(Date.now())).getTime()
      dayEnd = new Date(endOfDay(Date.now())).getTime();
    }
    if (filter == 'last month') {
      var lastMonth = startOfDay(subMonths(startOfMonth(Date.now()), 1))
      var endLastMonth = endOfDay(endOfMonth(lastMonth))
      dayStart = lastMonth.getTime()
      dayEnd = endLastMonth.getTime();
    }
    if (filter == 'this year') {
      var startYear = startOfDay(startOfYear(Date.now()))
      dayStart = startYear.getTime()
      dayEnd = new Date(endOfDay(Date.now())).getTime();
    }
    if (filter == 'date') {
      dayStart = startOfDay(query.date1).getTime()
      dayEnd = endOfDay(query.date1).getTime();
    }
    if (filter == 'date range') {
      dayStart = startOfDay(query.date1).getTime()
      dayEnd = endOfDay(query.date2).getTime();
    }
    console.log("LIMITED TO: ", filter, dayStart, dayEnd)

    // Push Only New Requests
    var found = false;
    self.requests.forEach(function (request) {
      if (request.formId == formId &&
        request.startDate == dayStart &&
        request.endDate == dayEnd &&
        request.fieldTitles == fieldTitles)
        found = true;

    })
    if (!found)
      self.requests.push({ formId: formId, startDate: dayStart, endDate: dayEnd, fieldTitles: fieldTitles, callBack: callBack, mySelf: mySelf, completed: false })

    var gotYourData: boolean = false;

    if (self.listeners) {
      //CAN ADD DATES INSIDE DATES!!
      self.listeners.forEach(function (item) {
        if (item.formId == formId &&
          item.startDate == dayStart &&
          item.endDate == dayEnd &&
          item.fieldTitles == fieldTitles) {  // Already have your data      
          gotYourData = true;
          console.log("ALREADY HAVE YOUR DATA")
          self.global.allSubmissions.forEach(function (data) {
            if (data.formId == formId) {
              data.fields.forEach(function (field) {
                if (fieldTitles.includes(field.title))
                  if (field.type == 'select-button' || field.type == 'multi-select' ||
                    field.type == 'tags') {
                    self.submissions.push(field.selected)
                  }
                  else
                    self.submissions.push(field.binding)
                // ADD OTHER TYPES
              })
            }
          })
          if (self.submissions.length > 0) {
            self.submissions.sort(function (a, b) {
              return b.timeStamp - a.timeStamp;
            });
            // GOT NEW DATA SO SEND IT ON

            self.requests.forEach(function (request) {  // CODE BELOW NEEDs BETTER MATCH CODE
              if (request.formId == formId &&
                request.startDate == dayStart &&
                request.endDate == dayEnd &&
                request.fieldTitles == fieldTitles) {
                if (!request.completed && typeof (request.callBack == 'function'))
                  request.callBack(request.mySelf, self.submissions)
                console.log("+");
                //  request.completed = true;
              }
            })
            // IF LISTENER STARTED BUT DATA HAS NOT RETURNED EXIT ANYWAY
            return;
          }
        }
      })
      if (gotYourData) return;
    }

    console.log("STARTING NEW LISTENER ", self.listeners, formId, fieldTitles)
    const exists = self.listeners.some(function (post) {
      if (post.formId == formId)
        return true;
    });
    if (!exists)
      self.listeners.push({ formId: formId, startDate: dayStart, endDate: dayEnd, fieldTitles: fieldTitles })

    var req = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(formId)
      .collection("submissions")
      .where("timeStamp", ">=", dayStart)
      .where("timeStamp", "<=", dayEnd)
    //    if(filters)
    // Let's do this locally      req = req.where(filters.fieldId, filters.operator, filters.pattern)

    req.onSnapshot((querySnapshot) => {
      self.submissions = [];
      querySnapshot.forEach((doc) => {
        const data = <Form>doc.data()
        console.log("GOT NEW DATA?? ", data)
        data.id = doc.id;
        const exists = self.global.allSubmissions.some(function (post) {
          if (post == data)
            return true;
        });
        if (!exists)
          self.global.allSubmissions.push(data)

        data.fields.forEach(function (field) {
          console.log("Check data field: ", field, fieldTitles)
          if (fieldTitles.includes(field.title))
            if (field.type == 'select-button' || field.type == 'multi-select' ||
              field.type == 'tags') {
              self.submissions.push(field.selected)
            }
            else if (field.type == 'calendar') {
              self.submissions.push(field.selected)
            }
            else
              self.submissions.push(field.binding)
          // ADD OTHER TYPES

        })
      })
      self.submissions.sort(function (a, b) {
        if (!a?.timeStamp || b?.timeStamp) {
          console.log("BAD TimeStamp: ", a, b)
          return 0;
        }
        return b.timeStamp - a.timeStamp;
      });
      // GOT NEW DATA SO SEND IT ON TO ALLREQUESTORS

      self.requests.forEach(function (request) {
        if (request.formId == formId &&
          request.startDate >= dayStart &&
          request.endDate <= dayEnd &&
          request.fieldTitles == fieldTitles) {
          if (typeof (request.callBack == 'function'))
            request.callBack(request.mySelf, self.submissions)
          console.log("NEW Submissions; ", self.submissions);
          //  request.completed = true;
        }
      })
      // Clean up the requests
      self.requests.forEach(function (request, index) {
        if (request.completed)
          self.requests.splice(index, 1)
      })

      console.log("ALL Submissions; ", self.global.allSubmissions);
    })

  }


  reportSubmissions: Form[];
  reportListeners: string[] = [];
  reportRequests: {
    formId: string,
    fields: string[],
    filter?: string,
    callBack: any,
    mySelf: any,
    completed: boolean
  }[] = []

  getReportData(formId, fields, callBack, mySelf, report: Report, userReport?, userOnly?) {
    var self = this;
    var db = firebase.firestore();

    console.log ("report data ", fields, report)

    if (!self.global.allSubmissions) self.global.allSubmissions = [];
    self.reportSubmissions = [];

    self.reportRequests.push({ formId: formId, filter: report.filterBy, fields: fields, callBack: callBack, mySelf: mySelf, completed: false })

    var gotYourData: boolean = false;
    /*
        if (self.reportListeners) {
          self.reportListeners.forEach(function (item) {
            if (item == form.id) {  // Already have your data      
              gotYourData = true;
              console.log("ALREADY HAVE YOUR DATA")
              self.global.allSubmissions.forEach(function (data) {
                if (data.formId == form.id) {
                  data.fields.forEach(function (field) {
                    if (fields.includes(field.title))
                      self.reportSubmissions.push(field.binding)
                  })
                }
              })
              if (self.reportSubmissions.length > 0) {
                self.reportSubmissions.sort(function (a, b) {
                  return b.timeStamp - a.timeStamp;
                });
                // GOT NEW DATA SO SEND IT ON
    
                self.reportRequests.forEach(function (request) {
                  if (request.formId == form.id // && request.fieldTitle == fieldTitle
                  ) {
                    if (!request.completed && typeof (request.callBack == 'function'))
                      request.callBack(request.mySelf, self.reportSubmissions)
                    console.log("+");
                    //  request.completed = true;
                  }
                })
                // IF LISTENER STARTED BUT DATA HAS NOT RETURNED EXIT ANYWAY
                return;
    
              }
    
    
            }
    
          })
          if (gotYourData) return;
        }
        */

    console.log("STARTING NEW LISTENER ", self.reportListeners, formId, fields)
    const exists = self.reportListeners.some(function (post) {
      if (post == formId)
        return true;
    });
    if (!exists)
      self.reportListeners.push(formId)

    var dayStart = new Date(startOfDay("2000/01/01")).getTime()
    var dayEnd = new Date(endOfDay(Date.now())).getTime();

    if (report.filterBy == 'Today') {
      dayStart = new Date(startOfDay(Date.now())).getTime()
      dayEnd = new Date(endOfDay(Date.now())).getTime();
    }
    if (report.filterBy == 'Yesterday') {
      var today = startOfDay(Date.now())
      const yesterday = add(today, { days: -1 });
      dayStart = yesterday.getTime()
      dayEnd = endOfDay(yesterday).getTime();
    }
    if (report.filterBy == 'This Month') {
      dayStart = startOfDay(startOfMonth(Date.now())).getTime()
      dayEnd = new Date(endOfDay(Date.now())).getTime();
    }
    if (report.filterBy == 'Last Month') {
      var lastMonth = startOfDay(subMonths(startOfMonth(Date.now()), 1))
      var endLastMonth = endOfDay(endOfMonth(lastMonth))
      dayStart = lastMonth.getTime()
      dayEnd = endLastMonth.getTime();
    }
    if (report.filterBy == 'This Year') {
      var startYear = startOfDay(startOfYear(Date.now()))
      dayStart = startYear.getTime()
      dayEnd = new Date(endOfDay(Date.now())).getTime();
    }
    if (report.filterBy == 'Date') {
      dayStart = startOfDay(report.date1).getTime()
      dayEnd = endOfDay(report.date1).getTime();
    }
    if (report.filterBy == 'Date Range') {
      dayStart = startOfDay(report.date1).getTime()
      dayEnd = endOfDay(report.date2).getTime();
    }

    console.log("LIMITED TO: ", report.filterBy, dayStart, dayEnd)

    if (userReport) {
      var repRef = db.collection("users").doc(self.global.authuser.uid).collection("forms")
        .doc(formId)
        .collection("submissions")
    }
    else {
      var repRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
        .doc(formId)
        .collection("submissions")

    }

    repRef.where("timeStamp", ">=", dayStart)
      .where("timeStamp", "<=", dayEnd)

      .onSnapshot((querySnapshot) => {
        self.reportSubmissions = [];
        querySnapshot.forEach((doc) => {
          const data = <Form>doc.data()
          data.id = doc.id;
          console.log("GOT NEW DATA?? ", data, userOnly)

          if (userOnly) {
            console.log ("USERONLY ",data.userId)
            if (data.userId == self.global.authuser.uid)
              self.reportSubmissions.push(data)
          }
          else
            self.reportSubmissions.push(data)

          /*   const exists = self.global.allSubmissions.some(function (post) {
               if (post == data)
                 return true;
             });
             if (!exists)
               self.global.allSubmissions.push(data)
             
             data.fields.forEach(function (field) {
               if (field.title == fieldTitle)
                 self.reportSubmissions.push(field.binding)
             })*/

        })
        // self.submissions.sort(function (a, b) {
        //   return b.timeStamp - a.timeStamp;
        // });

        // GOT NEW DATA SO SEND IT ON TO ALLREQUESTORS
        self.reportRequests.forEach(function (request) {
          if (request.formId == formId) {
            if (typeof (request.callBack == 'function'))
              request.callBack(request.mySelf, self.reportSubmissions)
            console.log("NEW Submissions; ", self.reportSubmissions);
            //  request.completed = true;
          }
        })
        // Clean up the requests
        self.reportRequests.forEach(function (request, index) {
          if (request.completed)
            self.reportRequests.splice(index, 1)
        })

        console.log("ALL Submissions; ", self.reportSubmissions);
      })

  }

  createForm(name?: string, callBack?, mySelf?) {
    var self = this;

    if (!self.global?.myCompany?.id) return;

    if (!name) name = 'New Form -'

    var newForm: Form = {
      id: "",
      userId: self.global.authuser.uid,
      timeStamp: Date.now(),
      name: name,
      showSubmit: true,
      autoSave: true,
      fields: [],
    }

    // let's ensure unique title
    var digit = 2;
    var unique = true;
    var title = newForm.name;

    //  var newField = {...field};

    do {
      const exists = self.global.allForms.some(function (post) {
        if (post.name == title)
          return true;
      });
      if (exists) {
        title = newForm.name + " " + digit.toString();
        unique = false;
        digit++;
      }
      else unique = true;
    }
    while (!unique)

    newForm.name = title;

    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc()
    newForm.id = docRef.id;

    docRef.set(newForm).then(() => {
      console.log("form added");
      if (typeof (callBack) == 'function') {
        callBack(mySelf, newForm)
      }
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }




  // Create an instance of form from Master to local user/store
  createFromMaster(formName, formId, userForm) {
    var self = this;

    var db = firebase.firestore();

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("forms")
      .where("name", "==", formName)
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          var data = doc.data();
          var q1;
          if (userForm) {
            q1 = db.collection("users").doc(self.global.authuser.uid).collection("forms")
              .doc();
            data.userForm = true;
          }
          else {
            q1 = db.collection("company").doc(self.global.myCompany.id).collection("forms")
              .doc();
          }
          data.id = q1.id;

          q1.set(data)
            .then(() => {
              console.log("FORM ADDED: ");
            })
        })

      })
  }


  // Create an instance of form from Master to local user/store
  createReportFromMaster(reportName, reportId, userForm) {
    var self = this;

    var db = firebase.firestore();

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("reports")
      .where("title", "==", reportName)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var data = doc.data();
          var q1;
          if (userForm) {
            q1 = db.collection("users").doc(self.global.authuser.uid).collection("reports")
              .doc();
            data.userForm = true;
          }
          else {
            q1 = db.collection("company").doc(self.global.myCompany.id).collection("reports")
              .doc();
          }
          data.id = q1.id;

          q1.set(data)
            .then(() => {
              console.log("Report ADDED: ");
            })
        })

      })
  }


  clearForm(form: Form) {
    var self = this;

    // BAD BAD BAD  form.id = null; // NO DATA HERE

    form.fields.forEach(function (field) {
      field.binding = undefined;
      field.value = undefined;
      field.media = undefined;
      field.dateMs = undefined;
      field.htmlFinal = undefined;
      field.safeHtml = undefined;
      field.selected = [];
      field.date = undefined;

    })
  }



  saveForm(form: Form) {
    var self = this;
    var db = firebase.firestore();
    console.log("saveForm ", form)
    if (!self.global.authuser) return; // nobody
    // IF IT's a Master form skip for now

    // If FORM HAS an ID then Update data

    console.log("SAVE FORM DATA ", form)

    if (!form.name.startsWith("master")) {

      if (form.id) {
        console.log("Updating form data ", form)
        var ref2 = db.collection("company").doc(self.global.myCompany.id)
          .collection("forms").doc(form.formId).collection("submissions").doc(form.id);

        //    form.userId = self.global.authuser.uid;
        //    form.userName = self.global.authuser.firstName + " " + self.global.authuser.lastName;
        //    form.timeStamp = Date.now();

        form.lastModifiedBy = self.global.authuser.uid;
        form.lastModified = Date.now();
        form.formId = form.id;  // 

        ref2.update(form);

      }
      else {

        // add Form to user as sub-collection
        var ref2 = db.collection("company").doc(self.global.myCompany.id)
          .collection("forms").doc(form.formId).collection("submissions").doc();

        var newForm = Object.assign(newForm, form)
        newForm.formId = form.id;
        newForm.id = ref2.id;

        newForm.userId = self.global.authuser.uid;
        newForm.userName = self.global.authuser.firstName + " " + self.global.authuser.lastName;
        newForm.timeStamp = Date.now();
        //      if (!form.tags) form.tags = [];

        console.log("NEW DATA ", newForm)
        ref2.set(newForm);
      }

    }
    else if (form.userForm) { // A MASTER/LOCAL/USER FORM
      var ref2 = db.collection("users").doc(self.global.authuser.uid)
        .collection("forms").doc(form.id).collection("submissions").doc();
      var newForm = Object.assign(newForm, form)
      newForm.formId = form.id;
      newForm.id = ref2.id;
      newForm.userId = self.global.authuser.uid;
      newForm.userName = self.global.authuser.firstName + " " + self.global.authuser.lastName;
      newForm.timeStamp = Date.now();
      //      if (!form.tags) form.tags = [];

      console.log("NEW DATA ", newForm)
      ref2.set(newForm);
    }


  }


  geoCode(adr, city, state, zip) {
    var self = this;


    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com'
      }
    };

    fetch('https://forward-reverse-geocoding.p.rapidapi.com/v1/forward?street=' + adr + '&city=' + city + '&state=' + state + '&postalcode=' + zip + '&accept-language=en&polygon_threshold=0.0', options)
      .then(response => response.json())
      .then(response => {
        console.log("GEO: ", response);
        if (response[0] && response[0].lat) {
          var lat: number = Number(response[0].lat);
          var lon: number = Number(response[0].lon);
          self.updateLatLong(lat, lon);
        }
      })
      .catch(err => console.error(err));

  }

  updateLatLong(lat: number, long: number) {
    var self = this;

    if (!this.newCompanyID) this.newCompanyID = this.global.myCompany.id
    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.newCompanyID);

    if (lat) {
      /*
      self.global.mapOptions = {
        center: { lat: lat, lng: long },
        zoom: 14
      }*/
    }

    ref.update({
      lat: <number>lat,
      long: <number>long,
    })
      .then(() => {
        console.log("latlong settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing latlong settings: ", error);
      });
  }

  updateTitle(id, data: any) {
    var self = this;

    return; // YIKES, must be a better way/  Use ID's not titles ...


    // Scan all Forms->logic && Fields->logic for this id
    self.global.allForms.forEach(function (form) {
      var update = false;

      if (form?.logic?.code) {
        //Hack need to add code here for form name changes

      }


      form.fields.forEach(function (field) {
        if (field?.logic?.code) {
          field.logic.code.forEach(function (code) {
            if (code?.codeObjects?.result?.id == id) {
              if (code.codeObjects.result.title != data) {
                update = true;
                code.codeObjects.result.title = data;
                console.log("FOUND uid FIELD logic: ", id, data, code.codeObjects.result);
              }
            }
            if (code?.codeObjects?.fields[0]?.id == id) {
              if (code.codeObjects.fields[0].title != data) {
                update = true;
                code.codeObjects.fields[0].title = data;
                console.log("FOUND uid FIELD logic: ", id, data, code.codeObjects.fields[0]);
              }
            }
          })
        }
      })
      if (update) {
        self.updateForm(form);
        update = false;
      }
    })

  }

  createUID(): string {
    var uid = "";

    // For now just 3 1000 randoms + time;

    var a = Math.floor(Math.random() * 1000).toString();
    var b = Math.floor(Math.random() * 1000).toString();
    var c = Math.floor(Math.random() * 1000).toString();
    var d = Date.now().toString()
    console.log("D ", d)
    uid = a + b + c + d.substring(7, 3)

    return uid;
  }


  // MOVE DATA FROM NAPKIN FIELDS to OBJECT

  objectToNapkinFields(obj: any, fields: Field[]) {
    var values;
    var keys = Object.keys(obj);
    values = Object.values(obj);

    console.log("OBJECT TO FIELDS-> ", obj, fields, keys, values)
    if (keys.length)
      keys.forEach(function (key, dataIndex) {
        var index = fields.findIndex(f => f.title == key);

        //    console.log("Input value ", index, key, self.form)
        if (index != -1) { // NOW SET Field value fields (binding, trueFalse etc.)
          fields[index].binding = values[dataIndex];
          console.log("FIeld: ", fields[index])

          if (fields[index].type == 'number' ||
            fields[index].type == 'currency'
          ) {
            fields[index].value = values[dataIndex];
          }

          if (fields[index].type == 'switch' || fields[index].type == 'checkbox')
            fields[index].trueFalse = values[dataIndex];

          if (fields[index]?.options ||
            fields[index].type == 'tags' ||
            fields[index].type == 'multi-select' ||
            fields[index].type == 'select-button') {

            console.log("FORM INPUT SELECT: ", values[dataIndex])
            if (is_array(obj[key])) {
              fields[index].selected = values[dataIndex];
              fields[index].binding = values[dataIndex];
            }
            else {
              fields[index].selected = [values[dataIndex]];
              fields[index].binding = values[dataIndex]; //???
            }

          }
        }
      })

  }



  napkinFieldsToObject(form: Form, obj: any, create?: boolean) {
    console.log("NAPKIN->OBJ ", form, obj)

    // Default create to True
    if (typeof (create) != "boolean") {
      create = true;

    }

    var keys = Object.keys(obj);
    var values = Object.values(obj);
    //  console.log(keys,values)

    // ARRAY CONVERSION: if fieldGroups then create an array with name, then check each field for array/index

    var arrayStr;

    if (form.fieldGroups) { // BUG bUG HAck HACK olny supports 1 array
      arrayStr = form.fieldGroups[0]

    }
    console.log("Object Keys ", obj, keys)


    form.fields.forEach(function (field) {
      var found = false;
      //      console.log("Converting ", field)
      if (keys.length)
        keys.forEach(function (key, dataIndex) {
          if (field.title && field.title.toLowerCase() == key.toLowerCase()) { // NOW SET Field value fields (binding, trueFalse etc.)
            // First check for special fields
            if (field.type == 'number') {
              obj[key] = field.value;
              console.log("set number field ", field.value)
            } else {
              //   if (field.binding)
              obj[key] = field.binding;
              if (field.media)
                obj[key] = field.media;
              if (field.value)
                obj[key] = field.value;
              if (field.html)
                obj[key] = field.html;
              if (typeof (field.trueFalse) == 'boolean')
                obj[key] = field.trueFalse;
              // select-button
              if (field.selected && field.selected.length > 0) {
                if (field.type.toLowerCase() == 'select-button') {
                  obj[field.title] = field.selected[0];
                }
                else {
                  if (is_array(field.selected)) {
                    console.log("Converted array ", field.title)
                    obj[field.title] = field.selected;
                  }
                  else
                    obj[field.title] = field.selected[0];
                }
              }
            }




            //   if (field.options)
            //     obj[key] = field.options;
            found = true;
            console.log("update ", key, field.binding, field.trueFalse)
          }
        })
      if (!found && create) { // ADD THIS FIELD TO THE OBJECT
        if (field.fieldGroup) {
          if (!obj[arrayStr]) obj[arrayStr] = [{}];
          if (!obj[arrayStr][field.groupIndex]) obj[arrayStr][field.groupIndex] = {}
          if (field.binding && (field.type != 'multi-select' && field.type != 'tags'))
            obj[arrayStr][field.groupIndex][field.title] = field.binding;
          if (typeof (field.trueFalse) == 'boolean')
            obj[arrayStr][field.groupIndex][field.title] = field.trueFalse;
          if (field.type == 'multi-select' || field.type == 'tags')
            obj[arrayStr][field.groupIndex][field.title] = field.selected;
        }
        else {
          if (field.binding)
            obj[field.title] = field.binding;
          if (field.media)
            obj[field.title] = field.media;
          if (field.value)
            obj[field.title] = field.value;
          if (field.html)
            obj[field.title] = field.html;
          if (field.trueFalse) {
            obj[field.title] = field.trueFalse;
            console.log("Creating new switch ", obj[field.title])
          }
          // I NEED A SWITCH ON THIS      obj[field.title] = field.options;
          if (field.selected && field.selected.length > 0) {
            if (field.type.toLowerCase() == 'select-button') {
              obj[field.title] = field.selected[0];
            }
            else {
              if (is_array(field.selected)) {
                console.log("Converted array ", field.title)
                obj[field.title] = field.selected;
              }
              else
                obj[field.title] = field.selected[0];
            }
          }
        }
        // console.log("created field in object: ", obj, field, field.title, obj[field.title])

      }
    })
    // Final step - remove nulls and undefineds
    obj = removeUndefined(obj)


  }

  napkinFieldToObject(field: Field, obj: any) {

    var keys = Object.keys(obj);
    var values = Object.values(obj);
    //  console.log(keys,values)

    var found = false;
    if (keys.length)
      keys.forEach(function (key, dataIndex) {
        if (field.title.toLowerCase() == key.toLowerCase()) { // NOW SET Field value fields (binding, trueFalse etc.)

          if (field.type == 'number') {
            obj[key] = field.value;
            console.log("set number field ", field.value)
          } else {
            //   if (field.binding)
            obj[key] = field.binding;
            if (field.media)
              obj[key] = field.media;
            if (field.value)
              obj[key] = field.value;
            if (field.html)
              obj[key] = field.html;
            if (typeof (field.trueFalse) == 'boolean')
              obj[key] = field.trueFalse;


            if (field.type == 'switch')
              obj[key] = field.trueFalse;
          }

          found = true;
          console.log("update ", key, field.binding)
        }
      })
    if (!found) { // ADD THIS FIELD TO THE OBJECT
      if (field.type == 'number') {
        obj[field.title] = field.value;
      }
      else if (field.binding) {
          obj[field.title] = field.binding;
      }
      else if (field.media)
        obj[field.title].media = field.media;
      else if (field.value)
        obj[field.title].value = field.value;
      else if (typeof (field.trueFalse) == 'boolean')
        obj[field.title] = field.trueFalse;

      console.log("created: ", field.title, obj[field.title])

    }



  }

  // Check Embedded
  checkEmbedded(form: Form) {
    var self = this;


    // Correct HTML in Form
    if (form.submitMsg) {
      // Scan for embedded fields...

      var pos = -1;
      var allValid = true;
      form.htmlFinal = form.submitMsg;
      do {
        var str = form.htmlFinal
        pos = str.search("{{")
        if (pos != -1) { // find match
          var replaceStr = "";
          var pos2 = str.search("}}")
          if (pos2 != -1) {
            // Now extract the variable
            const variableName = str.substring(pos + 2, pos2);
            pos = -1;
            self.global.allForms.forEach(function (form) {
              form.fields.forEach(function (srcField) {
                if (srcField.title == variableName) {
                  if (srcField.type == 'date') {
                    if (srcField.date)
                      replaceStr = self.getPrettyDate(srcField.date.toMillis())
                    else allValid = false;
                  }
                  else if (srcField.type == 'time') {
                    if (srcField?.selected?.length)
                      replaceStr = srcField.selected[0]
                    else allValid = false;
                  }
                  else {
                    var value: number = srcField.value;
                    if (!srcField.value) {
                      value = 0;
                      allValid = false;
                    }

                    replaceStr = self.formatData(srcField)

                  }
                  console.log("Found a Variable ", replaceStr, variableName, srcField, pos, pos2);
                  pos = 1;
                  form.htmlFinal = form.htmlFinal.replace("{{" + variableName + "}}", replaceStr)
                }
              })
            })
          } else pos == -1
        }

      } while (pos != -1)
      //   if (form.showIfValid && !allValid) {
      //     form.display = false;
      //   }
      //else {
      //  form.display = true;
      //??!!     form.safeHtml = self.sanitizer.bypassSecurityTrustHtml(form.htmlFinal);
      //  }
    }











    // CHECK FOR FIELDS THAT INCLUDE EMBEDDED variable (displayText, displayHTML)
    form.fields.forEach(function (field) {
      if (field.type == "displayHTML" && field.html) {

        // Scan for embedded fields...
        var pos = -1;
        var allValid = true;
        field.htmlFinal = field.html;
        do {
          var str = field.htmlFinal
          pos = str.search("{{")
          if (pos != -1) { // find match
            var replaceStr = "";
            var pos2 = str.search("}}")
            if (pos2 != -1) {
              // Now extract the variable
              const variableName = str.substring(pos + 2, pos2);
              pos = -1;
              self.global.allForms.forEach(function (form) {
                form.fields.forEach(function (srcField) {
                  if (srcField.title == variableName) {
                    if (srcField.type == 'date') {
                      if (srcField.date)
                        replaceStr = self.getPrettyDate(srcField.date.toMillis())
                      else allValid = false;
                    }
                    else if (srcField.type == 'time') {
                      if (srcField?.selected?.length)
                        replaceStr = srcField.selected[0]
                      else allValid = false;
                    }
                    else {
                      var value: number = srcField.value;
                      if (!srcField.value) {
                        value = 0;
                        allValid = false;
                      }

                      replaceStr = self.formatData(srcField)

                    }
                    console.log("Found a Variable ", replaceStr, variableName, srcField, pos, pos2);
                    pos = 1;
                    field.htmlFinal = field.htmlFinal.replace("{{" + variableName + "}}", replaceStr)
                  }
                })
              })
            } else pos == -1
          }

        } while (pos != -1)
        if (field.showIfValid && !allValid) {
          field.display = true;
        }
        else {
          field.display = true;
          // field.safeHtml = self.sanitizer.bypassSecurityTrustHtml(field.htmlFinal);
        }
        console.log(field.htmlFinal)
      }
    })
  }


  formatData(field: Field) {
    var str = field.value;

    let USDollar = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    if (field.value && field.decimals > 0) {
      str = field.value.toFixed(field.decimals)
    }
    if (field.decimals == 0)
      str = Math.floor(field.value).toFixed(0)
    if (field.format == 'Currency')
      str = USDollar.format(field.value)

    return str;
  }



  getForms() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;

    self.global.allForms = [];
    self.global.allFormNames = [];
    self.global.allSessionFields = [];

    self.global.allForms = [];
    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("forms")
      .onSnapshot((querySnapshot) => {

        console.log("BEFORE UPDATE: ", self.global.allForms.length)


        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const m = <Form>change.doc.data();
            if (!m.deleted) {
              //  console.log("FORM: ", m)
              self.global.allForms.push(m);
              self.global.allFormNames.push(m.name);
              m.fields.forEach(function (field) {
                self.global.allSessionFields.push({ id: field.id, sourceForm: m.name, sourceField: field.title, type: field.type })

              })
            }

          }
          if (change.type === "modified") {
            const m = <Form>change.doc.data();
            const index = self.global.allForms.findIndex(x => x.id === m.id)
            //HAVE NO IDEA WHY NOT NEEDED   
            if (index != -1) self.global.allForms[index] = { ...m }
            console.log("Modified city: ", change.doc.data());
          }
          if (change.type === "removed") {
            const m = <Form>change.doc.data();

            console.log("Removed form: ", change.doc.data());
          }
        });
        console.log("GOT ALL Forms ", self.global.allForms, self.global.allSessionFields)
      });

  }

  updateField(form: Form, field?: Field) {
    var self = this;
    var id = form.id;

    if (!id) id = form.formId;

    var db = firebase.firestore();

    if (field) {
      console.log("Updating field: ", field.title, form)
      var fieldToUpdate = self.findMe(field.title, form)
      if (fieldToUpdate != -1) {
        console.log("Updating field: ", field, form.fields[fieldToUpdate])
        form.fields[fieldToUpdate] = field;
      }
    }
    form.fields.forEach(function (field) {
      if (field.safeHtml) field.safeHtml = undefined;
    })
    // STRIP all empty fields
    form.fields = removeUndefined(form.fields)

    var fieldRef =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(id)
        .update({ fields: form.fields }).then((querySnapshot) => {
          console.log("FIELD UPDATED ", form.fields)
        })


  }

  watching: boolean = false;
  watchThemes() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;

    if (this.watching) return;
    this.watching = true;

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("themes")
      .where("companyId", "==", "OdCDgRWTZhTMzW8N0y9y")
      .onSnapshot((querySnapshot) => {
        if (!self.global.allThemes) self.global.allThemes = []

        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const m = <Theme>change.doc.data();

            if (self.global.allThemes.findIndex(x => x.id === m.id) == -1) {
              self.global.allThemes.push(m)
            }
          }
          if (change.type === "modified") {
            //   const m = <Form>change.doc.data();
            //   const index = self.global.allThemes.findIndex(x => x.id === m.id)
            //HAVE NO IDEA WHY NOT NEEDED   if (index != -1) self.global.mediaGroups[index] = {...m}
            console.log("Modified city: ", change.doc.data());
          }

        });
        /*
        self.global.allChatBots.sort(function (a, b) {
          if (!a.createdAt || !a.editedAt) {
            a.editedAt = Date.now()
          }
          if (!b.createdAt || !b.editedAt) {
            b.editedAt = Date.now()
          }
          return a.editedAt - b.editedAt;
        });
        */
        console.log("Themes: ", self.global.allThemes);

      });

  }


  getFunctions() {
    var self = this;

    var db = firebase.firestore();

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("functions")
      .get()
      .then((querySnapshot) => {
        self.global.functions = [];
        self.global.functionTags = [];
        querySnapshot.forEach((doc) => {
          var data = <Functions>doc.data();
          if (data.internalOnly &&
            self.global.authuser &&
            self.global.authuser.uid == 'kvT6eKFxFHb3EKbVeCTusW86CrZ2')
            self.global.functions.push(data);
          else if (!data.internalOnly) {
            self.global.functions.push(data);

            if (!data.tags) data.tags = ["Other"]
          //  console.log("Tags ", data.tags)

            data.tags.forEach(function (tag) {
              if (!self.global.functionTags.includes(tag)) {
                self.global.functionTags.push(tag)
              }
            })
          }
        });
        console.log("Functions; ", self.global.functions);

      })
      .catch((error) => {
        console.log("no functions found: ", error);
      });

  }



  addFieldToForm(form: Form, field: Field) {
    var self = this;

    // let's ensure unique title
    var digit = 2;
    var unique = true;
    var title = field.title;

    var newField = { ...field };

    do {
      const exists = form.fields.some(function (post) {
        if (post.title == title)
          return true;
      });
      if (exists) {
        title = field.title + " " + digit.toString();
        unique = false;
        digit++;
      }
      else unique = true;
    }
    while (!unique)

    newField.title = title;

    /********* Get UID */
    newField.id = self.createUID();

    if (newField.type == 'canvas') {
      if (!newField.binding) {
        console.log("INSTANTIATING NEW SCENE")
        //   newField.binding = <string>self.VIPservice.newScene(self.newSceneCallback, self);
      }
    }
    console.log("Form & field add ", form, newField)

    form.fields.push(newField)
    self.updateForm(form);

  }

  newSceneCallback(self: this) {

    console.log("got new scene ");

  }
  getPrettyDate(dateMs: number) {


    var date = new Date(dateMs);
    // console.log(date, dateMs)

    return (dateFormatter(date));

  }
  // ***** Data Import/Export *****


  // Export submissions from date range
  exportData(cols: Column[], rows: { fields: Field[] }[]) {
    var self = this;

    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Products',
      useBom: false,
      noDownload: false,
      useHeader: true,
      headers: [],
      nullToEmptyString: true,
    };
    cols.forEach(function (col) {
      options.headers.push(col.header)
    })

    console.log("DATA FIELDS: ", options.headers)
    // GET FIELD LIST FROM FORM/FIELDS

    var dataRows = [];

    rows.forEach(function (row) {
      var dataRow = [];
      row.fields.forEach(function (field, index) {
        var col = options.headers[index]
        if (field.binding)
          dataRow[col] = field.binding;
        if (field.value)
          dataRow[col] = field.value;
        if (field.selected)
          dataRow[col] = self.dataConcat(field.selected);
      })

      dataRows.push(dataRow)
    })



    console.log("REPORT DATA: ", options.headers, dataRows)


    new AngularCsv(dataRows, 'My Data', options);
  }

  dataConcat(array: string[]): string {
    var str = "";
    if (array && array.length) {
      array.forEach(function (item, index) {
        if (index) str += " - "
        str += item;
      })

    }
    return str;
  }

  queryOpenAI(prompt, model, code, conversation, callBack, mySelf) {
    var self = this;

    if (!model) model = "gpt-3.5-turbo"
    console.log("Model: ", model);

    if (!conversation) conversation = [];

    self.http.post('https://generateanswer-xdnunfyrka-uc.a.run.app',
      { prompt: prompt, model: model, messages: conversation }).subscribe({
        next: response => {
          console.log('Got answer', response)
          if (typeof (callBack) == 'function')
            callBack(mySelf, code, response)

        },
        error: error => console.error('Error sending file', error)
      });

  }
  createImageAI(prompt, modelDetails, code, callBack, mySelf) {
    var self = this;

    if (!modelDetails) modelDetails = "dall-e-2 512x512";
    console.log("MODEL ", modelDetails)

    if (!prompt || prompt?.length < 4) return;

    var quality;
    var model;
    var sizeStr;
    var cost; // need a lookup for this
    var size = { width: 0, height: 0, size: 0 }
    switch (modelDetails) {
      case "dall-e-2 1024x1024":
        quality = 'standard';
        model = 'dall-e-2';
        sizeStr = '1024x1024';
        size.width = 1024;
        size.height = 1024;
        cost = .02;
        break;
      case "dall-e-2 256x256":
        quality = 'standard';
        model = 'dall-e-2';
        sizeStr = '256x256';
        size.width = 256;
        size.height = 256;
        cost = .016;
        break;
      case "dall-e-2 512x512":
        quality = 'standard';
        model = 'dall-e-2';
        sizeStr = '512x512';
        size.width = 512;
        size.height = 512;
        cost = .018;
        break;
      case "dall-e-3 1024x1024 Std":
        quality = 'standard';
        model = 'dall-e-3';
        sizeStr = '1024x1024';
        size.width = 1024;
        size.height = 1024;
        cost = .04;
        break;
      case "dall-e-3 1024x1792 Std":
        quality = 'standard';
        model = 'dall-e-3';
        sizeStr = '1024x1792';
        size.width = 1024;
        size.height = 1792;
        cost = .08;
        break;
      case "dall-e-3 1792x1024 Std":
        quality = 'standard';
        model = 'dall-e-3';
        sizeStr = '1792x1024';
        size.width = 1792;
        size.height = 1024;
        cost = .08;
        break;
      case "dall-e-3 1024x1024 HD":
        quality = 'hd';
        model = 'dall-e-3';
        sizeStr = '1024x1024';
        size.width = 1024;
        size.height = 1024;
        cost = .08;
        break;
      case "dall-e-3 1024x1792 HD":
        quality = 'hd';
        model = 'dall-e-3';
        sizeStr = '1024x1792';
        size.width = 1024;
        size.height = 1792;
        cost = .12;
        break;
      case "dall-e-3 1792x1024 HD":
        quality = 'hd';
        model = 'dall-e-3';
        sizeStr = '1792x1024';
        size.width = 1792;
        size.height = 1024;
        cost = .12;
        break;
    }
    console.log("MODEL ", modelDetails, model, quality, size)


    // Store-only feature for now...
    var bucket = "store-content";
    var imageFile = "image" + Math.floor(Math.random() * 10000).toString();
    var path = self.global.myCompany.id + "/AI-images/" + imageFile

    self.http.post(' https://createimage-xdnunfyrka-uc.a.run.app',
      { prompt: prompt, quality: quality, bucket: bucket, model: model, size: sizeStr, outputImage: path }).subscribe({
        next: response => {
          console.log('Got image', response)
          if (typeof (callBack) == 'function')
            callBack(mySelf, code, prompt, response, imageFile, modelDetails, cost, size)
        },
        error: error => console.error('Error sending file', error)
      });
  }
}

export function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}


function dateFormatter(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  // date.toDateString();

  return date.toLocaleDateString('en-US', timeformat);
}

var is_array = function (value) {
  return value &&
    typeof value === 'object' &&
    typeof value.length === 'number' &&
    typeof value.splice === 'function' &&
    !(value.propertyIsEnumerable('length'));
};