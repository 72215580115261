<div [style]="getMyStyle()">
<!--    
    <div *ngIf="overFlow==true" (click)="toggleExpand(false)">MINIMIZE</div>
    <div *ngIf="!overFlow" (click)="toggleExpand(true)">EXPAND</div>

-->
<div fxFlex fxLayout="column" fxLayoutAlign="center center" class="xxxlayout">
    <!--[style.width.px]="global?.getScreenWidth"-->
    <p-toast key="toast1" position="top-center"></p-toast>
    <p-toast key="toast2" position="top-center"></p-toast>

    <div *ngIf="!editing && !headLess" style="width:100%">
        <app-home></app-home>
    </div>
    <div id="page-root"></div>
    <div class="layout" style="margin: auto;" fxFlex fxLayout="column" fxLayoutAlign="center center">
        <p-accordion *ngIf="global.entryPoint=='front' && global.myCompany" expandIcon="false" collapseIcon="false"
            [multiple]="false">
            <p-accordionTab iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Settings</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-form formName="masterAppSettings" [formInput]="global.appDoc[appId].app" [autoSubmit]="false"
                        (objectEvents)="fieldEvent($event)" (submitEvent)="outputEvent($event)"></app-form>
                    <div class="app-link">
                        App Link: <a target="_blank" href="{{getMyAppLink()}}">{{getMyAppLink()}}</a>
                    </div>


                    <div *ngIf="1 || app?.share">
                        <app-sharing type="app" [share]="app.share" [linkURL]="getMyAppLink()"
                            (updateShare)="outputEventShare($event) "></app-sharing>
                    </div>
                    <!--
                    // Add the Remove Field Button
                    var remove: Field = { type: 'button', class: 'remove-field', title: 'Remove Form', label: 'Remove' }
                    self.editForm.fields.push(remove)
                    -->
                    <!-- DISPLAY APP STATS -->
                    <div
                        *ngIf="global.appDoc[appId] && global.appDoc[appId].app.appStats && global.appDoc[appId].app.appStats.totalOpens">
                        {{global.appDoc[appId].app.appStats.totalOpens+" "}}Opens<br>
                        {{getUniques()}} Unique users<br>

                        Last opened{{" "+getPrettyDate(global.appDoc[appId].app.appStats.lastOpen)}}

                        <!-- NAV TRACKING -->
                        <div *ngIf="global.appDoc[appId].app.appStats.navCounters">
                            <div *ngFor="let nav of global.appDoc[appId].app.appStats.navCounters;">
                                {{nav.title}}:{{nav.count}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>

        <div
            *ngIf="(global?.appDoc?.[appId]?.app?.addToCart && cartService?.newOrderItems?.length) || global.paymentSuccess">
            <p-accordion expandIcon="null" collapseIcon="null">

                <p-accordionTab iconPos="end" [(selected)]="global.viewOrderMode">
                    <ng-template pTemplate="header">
                        <div class="cart-header">
                            Your Order <span *ngIf="global.authuser">&nbsp;{{global.authuser.firstName}}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">

                        <cart-view></cart-view>

                        <!--  Empty CART     -->
                        <div *ngIf="cartService.newOrderItems.length==0">
                            <h2>Your cart is empty</h2>

                        </div>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>

        <div *ngIf="!headLess && currentParent == 'Home'" class="back" [style.width.px]="getWidth(0)">
            <div *ngIf="global.appDoc[appId].app.showLogo">
                <div *ngIf="global.myCompany && global.myCompany.logoMedia">
                    <img [src]="global.myCompany.logoMedia[0].url" class="napkin-logo"
                        style="max-width:100vw; max-height:10vh; object-fit: scale-down;">
                </div>
                <div *ngIf="global?.myCompany && !global?.myCompany?.logoMedia">
                    {{global.myCompany.name}}
                </div>
            </div>
        </div>

        <div style="width: 100%" fxFlex fxLayout="row" fxLayoutAlign="center center">
            <div *ngIf="global?.parents.length>1" (click)="goHome()" class="back" [style.width]="getWidth(99)">
                Home
            </div>
            <div *ngIf="currentParent != 'Home'" (click)="goBack()" class="back" [style.width]="getWidth(99)">
                Back
            </div>
        </div>
        <div *ngIf="global?.appNodes[appId]?.nodes" style="width:100%; margin: auto;" fxFlex fxLayout="column"
            fxLayoutAlign="center center">
            <div *ngFor="let node of global.appNodes[appId].nodes; let i = index;"
                style="height:max-content; margin: auto;" [style.width.px]="getWidth(node)">
                <app-node-object [appLink]="app" [currentParent]="currentParent" (changeParent)="changeParent($event)"
                    [pageRoot]="pageRoot" [appId]="appId" [node]="node" [editing]="editing"
                    (tabOpened)="openTab($event)"></app-node-object>

            </div>

        </div>

    </div>

    <div *ngIf="global.entryPoint != 'front'"
        style="width: 100%; max-width: 100vw; background-color: rgb(236, 249, 236);">
        <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="gold" style="width: 100%;">NAPKIN APPS</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">

                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%; text-align: center;">Privacy Policy</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div style="width:100%; max-width:900px;margin: auto;">
                                    <img src="./assets/PrivacyPolicy.png" class="napkin-logo"
                                        style="width:100%; object-fit: cover; max-height:100vh;">
                                </div>
                            </ng-template>
                        </p-accordionTab>
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%; text-align: center;">Terms of Use</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div style="max-width:900px;margin: auto;">

                                    <div *ngIf="global.getScreenWidth<600">
                                        <img src="./assets/termsmobile.png" class="napkin-logo"
                                            style="object-fit: cover;width:100vw">

                                    </div>
                                    <div *ngIf="global.getScreenWidth>600">
                                        <img src="./assets/terms.png" class="napkin-logo"
                                            style="width:100%; max-width: 900px;">

                                    </div>


                                </div>

                            </ng-template>
                        </p-accordionTab>
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%; text-align: center;">What is Napkin Apps?</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div fxLayoutAlign="center center"
                                    style="width: 100%; max-width: 600px; text-align: center;">

                                    <div
                                        style="width: 100%; max-width: 600px; font-size: 24px; line-height: 120%; text-align: center;">
                                        Napkin Apps is a no-code, run-everywhere, software development platform.

                                        Included are rich dev tools as well as a suite of built-in modules like
                                        e-commerce, point of sale, chats, media manager, visual playground and much
                                        more.<br><br>
                                    </div>
                                </div>

                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>

                </ng-template>
            </p-accordionTab>
        </p-accordion>



    </div>
</div>
</div>