import { Component, Injectable } from '@angular/core';
import { Company, Sheet, Theme,Query, Palette, Functions, SessionField, Report, Form, AppNode, NapkinApp, Field, Review, Coupon, Reviews, ReservationSettings, ReservationDay, reservation, resTime } from './components/company-interface';
import { User, ChatBot, Follows } from './components/user-interface';
import { Product } from './components/product-int';
import { onMenuItem } from './components/menu-item-interface';
import { Content } from './components/content-interface';
import { Observable } from 'rxjs';
import { Order, OrderTicket, OrderItem } from './components/order-interface';
//import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { FieldEdit, FieldDetail } from './components/field-edit-interface';
import { Media, MediaGroup } from './components/user-interface'
import { PhaserComponent } from './components/phaser/phaser.component';
import { Quote } from './components/content-interface';

@Injectable({
  providedIn: 'root',
})
export class Globals {
  appId: string; // This is the collection ID
  appNodes: {name?:string, nodes?: AppNode[]};
  appDoc: {name?:string, app?: NapkinApp} = {};

  //parent: string = "Home";
  parents: AppNode[] = [];
  fieldList: string[] = [];
  fields: Field[];
  functions: Functions[];
  functionTags: string[];
  quote: Quote;
  media: Media[];
  mediaGroups: MediaGroup[];
  mediaTags: string[];
  parentNode: AppNode;
  updatingNodes: boolean = false;
  formIndex: number;
  entryPoint: string = "";
  store: string;    
  myCompany: Company; // = new Company();
  authuser: User;
  customer: User;
  tableOrders?: string[]; // array of tables placing orders
  tableNameQR: string="";
  myParent: string = "";
  menuTitle: string = "";
  viewOrderMode: number = 0;
  companyID: string = "";
  viewMode: number = 0;
  displayMenu: boolean = false;
  userName: string;
  errorMsg: string;
  redeem: Product[] = [];
  public products: Product[];
  public allThemes: Theme[];
  public top5ByUnits: string[];
  public top5ByRev: string[];
  public allProducts: Product[] = [];
  public allProdCategories: string[] = []; // Upgrade to object with positioning etc.
  public allTags: string[] = [];
  allTagsCount: number[];
  obs: Observable<any>;
  menu: onMenuItem[] = [];
  allWeek: ReservationDay[] = [];
  openDays: boolean[] = [];
  showCheckOut: boolean = false;
  paymentSuccess: number = 0;
  public getScreenWidth: any;
  public getScreenHeight: any;
  newResDate: Date;
  newResTime: resTime;
  viewOnly: string;
  formNames: string[];
  appForms: Form [];
  allFields: Field[];
  allForms: Form [];  
  allSubmissions: Form[]
  allFormNames: string[];
  allSessionFields: SessionField [];
  allReports: Report [];  
  allReportNames: string [];
  allQueries: Query [];
  allQueryNames: string [];
  selectedTab: string;
  canvasFrames: string[] = [];
  canvasHandles: number[] = [];

  public publicApps: NapkinApp[];
  public recentApps: NapkinApp[];
  public allApps: NapkinApp[];

  editField: Field;
  
  public fieldEdit: FieldEdit [];
  public allPalettes: Palette[] = 
    [
    {colors:[{hex:"#a0d2eb"},{hex:"#e5eaf5"},{hex:"#d0bdf4"},{hex:"#8458B3"},{hex:"#a28089"}]},
    {colors:[{hex:"#00204a"},{hex:"#005792"},{hex:"#00bbf0"},{hex:"#fdb44b"},{hex:"#ffffff"}]},
    {colors:[{hex:"#780000"},{hex:"#c1121f"},{hex:"#fdf0d5"},{hex:"#003049"},{hex:"#669bbc"}]},

]

  public allContent: Content[] = [];
  content: Content[] = [];
  contentobs: Observable<any>;

  publishedReviews: Review [];
  reviewMedia: Media[];
  pendingReviews: Review[] = [];
  reviews: Reviews;
  coupons: Coupon[];

  myReservations: reservation[];
  public allReservations: reservation[];

  public allVIPs: PhaserComponent[];
  public allImageKeys: string[]; // Global keys
  
  public allStores: Company[];
  public loadingStore: boolean = false;
  public loadingReservations: boolean = false;
  public allChatBots: ChatBot[];
  public allSheets: Sheet[];

  myRewardPoints: number = 0;
  customGameObjectsLoaded: boolean = false;

//-- New Order System
public orderTicket: OrderTicket;
public orderItems: OrderItem[];
public openTickets: {name?:string, ticket?: OrderTicket, items?: OrderItem[]};

//appNodes: {name?:string, nodes?: AppNode[]};



//-- New Order System



  public lastUpdated = Date.now();
  public openOrders: Order[] = [];
  public closedOrders: Order[] = [];
  public openOrdersData: Observable<any> = <any>[];
  public openOrdersList: Order[] = [];
  public groupItems: Product [] = [];
  public messageCount: number = 0;  
  public mapOptions: {
   
  };

  userTabOpen: boolean = false;


}
/*
function User() {
    this.uid = "";
    this.email = "";
    this.displayName = "";
    this.photoURL = "";
    this.emailVerified = false;
    this.companyID = "";
    this.staff = false;
    this.admin = false;
    //this.creds = [];
    this.following = [];
  }
*/

/*
function Company() {
    this.name = "";
    this.palette1 = [];
    this.address1 = "";
    this.address2 = "";
    this.primaryPhone = "";
    this.city = "";
    this.state = "";
    this.postal = "";
    this.logoImg = "bob";
}

*/
