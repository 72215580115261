import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { FormService } from '../form.service';
import { FieldDetail } from '../field-edit-interface';

//import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';

@Component({
  selector: 'app-field-update',
  templateUrl: './field-update.component.html',
  styleUrls: ['./field-update.component.css', '../../common.scss']
})
export class FieldUpdateComponent implements OnInit {

  @Input() editId: string;
  @Input() form: Form;
  @Input() formIndex: number;
  @Input() field: Field;  // Current values
  @Input() formEditing: Form;

  editForm: Form;
  csvRecords: any[] = [];
  fieldEditList: string[] = ["Settings", "Logic"]
  fieldEditSettings = { width: "100%", backgroundColor: "#2e641f" }
  myFields: string[];

  constructor(public global: Globals, private ngxCsvParser: NgxCsvParser, private formService: FormService) {

  }

  ngOnInit(): void {
    var self = this;

    this.editForm = { name: "bob", showSubmit: false, noSubmit: true, fields: [] };


    //    if (this.form) this.editForm = this.form;
    console.log("FIELD UPDATE: ", this.form, this.field, this.editId)

    // Read the doc ID from master
    //  this.getMasterForm();
    //  this.fixMasterForms();

    //  this.setFieldEditForm();
    this.createFieldEditForms();

  }

  tabOpened(event) {

  }
  fieldEvents(field: Field) {
    var self = this;

    console.log("FIELD UPDATE GOT EVENT ", field)
    if (field.title == 'Field options') {
      self.field.options = field.options;
      self.updateField(self.formEditing, self.field);

    }

    if (field.type == 'button') {
      if (field.title.toLowerCase() == 'copy field') {
        console.log("Copying field ", field)
        //   copyingField = true;
      }
      if (field.title.toLowerCase() == 'remove field' &&
        field.outputs[0].value == 'button pressed') {
        console.log("Removing field now...", self.field)
        self.removeField(self.formEditing, self.field)
        self.global.editField = null;
      }
    }

  }

  outputEvent(data) {
    var self = this;
    var removed = false;
    var updateEditor: boolean = false;
    //  var formEditing = self.global.allForms[self.global.formIndex];
    var copyingField = false;

    console.log("++++EDIT FIELD DATA: ", data, self.formEditing)

    data.fields.forEach(function (field) {

      if (field.title == 'Width' && field?.selected) {
        console.log("update width ", field)
        // alert("Yeah")
        self.field.width = field.selected[0];
      }
      if (field.title == 'URL') {
        console.log("update URL ", field.binding)
        self.field.gotoURL = field.binding;
      }
      if (field.title == 'trueLabel') {
        console.log("tlabel ", field.binding)
        self.field.trueLabel = field.binding;
      }
      if (field.title == 'falseLabel') {
        console.log("flabel ", field.binding)
        self.field.falseLabel = field.binding;
      }
      if (field.title == 'trueColor') {
        console.log("flabel ", field.binding)
        self.field.trueColor = field.binding;
      }
      if (field.title == 'display') {
        console.log("update display ", field.trueFalse)
        self.field.display = field.trueFalse;
      }
      if (field.title == 'noSave') {
        console.log("update nosave ", field.trueFalse)
        self.field.noSave = field.trueFalse;
      }
      if (field.title == 'autoOpen') {
        self.field.defaultOpen = field.trueFalse;
      }
      if (field.title == 'buttonStyle') {
        console.log("update button style ", field.binding)
        self.field.buttonStyle = field.binding;
      }
      if (field.title == 'theme') {
        console.log("update theme", field)
        self.field.theme = field.binding;
      }

      // console.log("EDIT FIELD DATA: ", field)
      if (field.title == 'Title' && self.field.title != field.binding) {
        self.field.title = field.binding;
        updateEditor = true;
        // Since title has changed we must update all Logic using this field
        // FIXFIX USE IDs  self.formService.updateTitle(self.field.id, field.binding);
      }

      if (field.title == 'htmlEditor') {
        self.field.html = field.html;
      }

      if (field.title == 'Label') {
        self.field.label = field.binding;
        updateEditor = true;
      }

      if (field.title == 'Field options') {
        self.field.options = field.options;
      }
      if (field.title == 'falseHide') {
        //  if (!self.field.optionSelected) self.field.optionSelected = []
        self.field.falseHide = field.selected;
      }
      if (field.title == 'required') {
        self.field.required = field.trueFalse;
      }
      if (field.title == 'blur') {
        if (field.trueFalse)
          self.field.blurButton = 'Update';
        else
          self.field.blurButton = 'None';
      }
      if (field.title == 'showOnly') {
        //   if (!self.field.optionSelected) self.field.optionSelected = []
        self.field.showOnly = field.selected;
      }
      if (field.title == 'fieldGroup') {
        //   if (!self.field.optionSelected) self.field.optionSelected = []
        self.field.fieldGroup = field.selected[0];
        self.field.groupIndex = 0;
      }

      if (field.title == 'filter') {
        self.field.filter = field.binding;
      }
      if (field.title == 'allowAdds') {
        self.field.allowAdds = field.trueFalse;
      }
      if (field.title == 'dataSource' && self.field.dataSource != field.binding) {
        self.field.dataSource = field.binding;
        updateEditor = true;
      }
      if (field.title == 'form') {
        self.field.sourceForm = field.binding;;

      }
      if (field.title == 'field') {
        self.field.sourceField = field.binding;
      }
      if (field.title == 'Field source' && field.index >= 0 && field.binding) { //maybe old
        console.log("source field: ", field)
        self.field.sourceField = field.binding;
        self.field.dataSource = "Field";
        self.field.sourceForm = self.global.allSessionFields[field.index].sourceForm;

        const index = self.global.allForms.findIndex(function (post) {
          if (post.name == self.field.sourceForm)
            return true;
        });

        const fieldIndex = self.global.allForms[index].fields.findIndex(function (post) {
          if (post.title == self.field.sourceField)
            return true;
        });
        console.log("source field: ", field, self.field, fieldIndex)

        if (fieldIndex != -1) {
          var label = self.global.allForms[index].fields[fieldIndex].label;
          var labelFieldIndex = self.formService.findMe('Label', self.editForm)

          self.field.label = label;
          self.editForm.fields[labelFieldIndex].binding = label;

          //   var fieldToUpdate = self.formService.findMe(field.title, self.formEditing)

        }


      }
      if (field.title == 'Format') {
        console.log("update format ", field.binding)
        self.field.format = field.binding;
      }
      if (field.title == 'Decimals') {
        console.log("update decimals ", field.value)
        self.field.decimals = field.value;
      }
      if (field.title == 'Min value') {
        console.log("update min val ", field.value)
        self.field.minVal = field.value;
      }
      if (field.title == 'Max value') {
        console.log("update max val ", field.value)
        self.field.maxVal = field.value;
      }

      if (field.title == 'Height') {
        console.log("update height ", field.binding)
        self.field.height = field.binding
      }
      

      /*
      if (field.binding == 'button pressed') {
        if (field.title.toLowerCase() == 'copy field') {
          console.log("Copying field ", field)
          copyingField = true;
        }
        if (field.title.toLowerCase() == 'remove field') {
          console.log("Removing field ", self.field)
          removed = true;
        }
      }*/


      if (field.binding && field.binding.length) {
        if (field.title == 'Access control')
          self.field.accessControl = field.binding;
        else if (field.title == 'Style') {
          self.field.style = field.selected[0];
          if (field.selected[0].toLowerCase() == 'default') {
            // Reset the Class to type
            self.field.class = self.getDefaultStyle(self.field.type)
          }
          else {
            if (field.binding == 'none')
              self.field.class = null;
            else
              self.field.class = field.selected[0];
          }
        }


        else if (field.title == 'Field type') {
          self.field.type = field.binding.toLowerCase();
        }
        else if (field.title == 'filterSource') {
          self.field.sourceField = field.binding;
          self.field.sourceForm = self.form.name;

         // self.field.filter = field.binding.toLowerCase();
        }
        else if (field.title == 'Title source')
          self.field.titleSource = field.binding.toLowerCase();
        else if (field.title == 'Class') {
          if (field.binding == 'none')
            self.field.class = null;
          else
            self.field.class = field.binding.toLowerCase();
        }
        else if (field.title == 'Overlay')
          self.field.overlay = field.binding.toLowerCase();
        else if (field.title == 'Mask')
          self.field.mask = field.binding.toLowerCase();
        else if (field.title == 'Option source')
          self.field.optionSource = field.binding.toLowerCase();
        else if (field.title == 'Time start')
          self.field.timeStart = field.binding.toLowerCase();
        else if (field.title == 'Time end')
          self.field.timeEnd = field.binding.toLowerCase();
        else if (field.title == 'Time delta')
          self.field.timeDelta = field.binding.toLowerCase();
        else if (field.title == 'Edit only') {
          if (field.binding.toLowerCase() == 'true') self.field.editOnly = true;
          else self.field.editOnly = false;
        }
        else if (field.title == 'Show') {
          if (field.binding.toLowerCase() == 'true') self.field.show = true;
          else self.field.show = false;
        }
        else if (field.title == 'True or False') {
          if (field.binding.toLowerCase() == 'true') self.field.trueFalse = true;
          else self.field.trueFalse = false;
        }

      }
    })

    if (copyingField) {
      self.formService.addFieldToForm(self.formEditing, self.field)

    }
    else if (!removed) {
      console.log("EDIT Field updated: ", self.formEditing, self.field, data)
      self.updateField(self.formEditing, self.field);
    }
    /* moved else {
       self.removeField(self.formEditing, self.field)
       self.global.editField = null;
 
     } */

    if (updateEditor)
      self.createFieldEditForms();
  }


  copyField(form: Form, field: Field) {


  }

  calOutputEvent (form: Form) {
    var self = this;

    console.log ("Got a calendar Update ", form)
    if (!self.field.calendar) self.field.calendar = {}
    self.formService.napkinFieldsToObject(form, self.field.calendar, true)

    // I need to manually convert bookings as it container complex data
    // seems to be a Hack. I could update nFTO but this is easier
    var bookings = form.fields.find(p => p.title=='bookings')
    if (bookings) {
      self.field.calendar.bookings= 
      {form:bookings.sourceForm,
      field:bookings.selected[0]}
      console.log ("Bookings source: ",bookings, self.field.calendar.bookings )
    }

    console.log ("Got a calendar Update ", self.field)
    // Let's save calendar data to the field.
    self.updateField(self.formEditing, self.field);



  }

  removeField(form: Form, field: Field) {
    var self = this;

    console.log("REMOVE FIELD ", form, field, self.editForm)

    var db = firebase.firestore();

    const index = form.fields.findIndex(function (item) {
      if (item.title == field.title && field.label == item.label)
        return true;
    });

    if (index != -1) {
      console.log("Found ", index)
      form.fields.splice(index, 1); // get rid of field
      /*
            const findex = self.global.allForms.findIndex(function (post) {
              if (post.id == form.id)
                return true;
            });
            if (findex != -1) {
              // console.log('update global ', form)
              //  self.global.allForms[findex].fields.splice(index, 1);
            }
            form = self.global.allForms[findex];
            self.editForm = null;
      */
      form = removeUndefined(form);

      var fieldRef =
        db.collection("company").doc(self.global.myCompany.id)
          .collection("forms").doc(form.id)
          .update({ fields: form.fields }).then((querySnapshot) => {
            console.log("FIELD UPDATE ", form)
            self.editForm = null;

          })



    }

  }

  updateField(form: Form, field: Field) {
    var self = this;
    var id = form.id;

    if (!id) id = form.formId;

    var db = firebase.firestore();

    var fieldToUpdate = self.formService.findMe(field.title, form)

    if (fieldToUpdate != -1) {
      console.log("Updating field: ", field, form.fields[fieldToUpdate])

      form.fields[fieldToUpdate] = field;

      // etc. move to service
      self.formService.clearForm(form)

      form.fields.forEach(function (field) {
        if (field.safeHtml) field.safeHtml = undefined;
      })

      // STRIP all empty fields
      form.fields = removeUndefined(form.fields)

      console.log("FIELD UPDATE-after strip ", form.fields)

      var fieldRef =
        db.collection("company").doc(self.global.myCompany.id)
          .collection("forms").doc(id)
          .update({ fields: form.fields }).then((querySnapshot) => {
            console.log("FIELD UPDATE ", form.fields)
            //  self.createFieldEditForms();
          })
    }

  }

  setFieldEditForm() {  // from CSV (loaded once) check booleans
    var self = this;
    return;
    var parts = [
      new Blob(['you construct a file...'], { type: 'text/csv' }),
      ' Same way as you do with blob',
      new Uint16Array([33])
    ];

    // Construct a file
    var file = new File(parts, './assets/JADE-Fields.csv', {
      type: "overide/mimetype" // optional - default = ''
    });


    //  var f = new File([""], "./assets/JADE-Fields.csv");

    console.log("File: ", file)
    this.ngxCsvParser.parse(file, { header: true, delimiter: ',', encoding: 'utf8' })
      .pipe().subscribe({
        next: (result): void => {
          console.log('Result', result);
          //  this.csvRecords = result;
        },
        error: (error: NgxCSVParserError): void => {
          console.log('Error', error);
        }
      });

    /*
    
    
        self.ngxCsvParser
          .parse(file, {
            header: true,
            delimiter: ',',
            encoding: 'utf8'
          })
          .pipe()
          .subscribe(
            (result: Array<any>) => {
              self.csvRecords = [];
    
              result.forEach(function (item) {
              //  if (item.title.length > 0) {
    
                  self.csvRecords.push(item);
              //  }
              })
              console.log('Result',result, self.csvRecords);
            },
            (error: NgxCSVParserError) => {
              console.log('Error', error);
            }
          );
          */
  }



  getMasterForm() {
    var self = this;
    return;
  }

  getDefaultStyle(type) {

    switch (type) {
      case 'time':
        return 'select-button'

        break;

    }
    return type;

  }
  createFieldEditForms() {  // create internal forms to edit fields
    var self = this;
    self.global.fieldEdit = [];

    self.editForm.fields = [];
    self.myFields = [];
    self.form.fields.forEach(function (field) {
      self.myFields.push(field.title)
    })
    // console.log("KLDSJKLFJDSL:KJFF ", self.myFields)

    if (!self.field.label) self.field.label = "";
    var baseDetail: FieldDetail[] = [
      { type: 'input-text', class: "text", label: 'Title', title: 'Title', blurButton: 'Update', binding: self.field.title, },
      { type: 'input-text', class: "text", label: 'Label', title: 'Label', blurButton: 'Update', binding: self.field.label, },
 // Not used replaced by themes     { type: 'select-button', label: 'Style', title: 'Style', blurButton: 'Update', options: ['none', 'Default', 'text', 'Neon-Betty'], selected: [self.field.style] },
      { type: 'switch', label: 'Required', blurButton: 'Update', title: 'required', trueFalse: self.field.required },
      { type: 'switch', label: 'Blur', blurButton: 'Update', title: 'blur', trueFalse: self.field.blurButton=='Update' },

    ]

    if (self.form?.fieldGroups && self.form?.fieldGroups.length) {
      baseDetail.push({ type: 'select-button', label: 'Group', blurButton: 'Update', title: 'fieldGroup', options: self.form.fieldGroups, selected: [self.field.fieldGroup], class: 'multi-select' })
    }

    baseDetail.forEach(function (field) {
      var f: Field = <Field>field;
      self.editForm.fields.push(f);
    })

    if (this.field.type == 'canvas' || this.field.type == 'button'
      || this.field.type == 'paletteSelect') {
      var f: Field = { type: 'inputSize', label: 'Width', title: 'Width', binding: self.field.width }
      self.editForm.fields.push(f);
      f = { type: 'inputSize', label: 'Height', title: 'Height', binding: self.field.height }
      self.editForm.fields.push(f);
    }
    if (self.field.type == 'button') {
      f = { type: 'select-button', options: [],blurButton: 'Update', dataSource: 'buttonStyles', label: 'Button Style', title: 'buttonStyle', binding: self.field.binding }
      self.editForm.fields.push(f);
    }

    if (self.field.type == 'calendar') {
      f = { type: 'select-button', options: [],blurButton: 'Update', dataSource: 'Themes', label: 'Theme', title: 'theme', binding: self.field.theme }
      self.editForm.fields.push(f);
      var f: Field = {
        type: 'select-button', blurButton: 'Update', label: 'Width', title: 'Width',
        options: ['370px', '100%', '450px', '980px'], selected: [self.field.width]
      }
      self.editForm.fields.push(f);
    }

    if (self.field.type == 'input-text' || this.field.type == 'full-text') {
      f = { type: 'switch', label: 'No Save Button',blurButton: 'Update', title: 'noSave', trueFalse: self.field.noSave }
      self.editForm.fields.push(f);
    }


    if (this.field.type == 'gotoURL') {
      var f: Field = { type: 'input-text', class: "text", label: 'Enter URL', title: 'URL', blurButton: 'Update', binding: self.field.gotoURL, }
        self.editForm.fields.push(f);
    }
    if (this.field.type == 'newLine') { // perhaps enable for ALL
      var f: Field = { type: 'switch', label: 'Display', title: 'display', blurButton: 'Update', trueFalse: self.field.display, }
        self.editForm.fields.push(f);
    }


    if (self.field.type == 'select-button' || self.field.type.toLowerCase() == 'options') {
      var f: Field = { type: 'switch', label: 'Auto Open', title: 'autoOpen', blurButton: 'Update', trueFalse: self.field.defaultOpen}
      self.editForm.fields.push(f);
    }

    if (self.field.type == 'switch') {
      if (!this.field.falseHide) this.field.falseHide = [];
      var f: Field = { type: 'input-text', label: 'True Label', title: 'trueLabel', binding: self.field.trueLabel }
      self.editForm.fields.push(f);
      var f: Field = { type: 'input-text', label: 'False Label', title: 'falseLabel', binding: self.field.falseLabel }
      self.editForm.fields.push(f);
      var f: Field = { type: 'colorChooser', label: 'True Color', title: 'trueColor', binding: self.field.trueColor }
      self.editForm.fields.push(f);
    }

    if (self.field.type == 'switch' /* || self.field.type == 'button' */) {
      if (!this.field.falseHide) this.field.falseHide = [];
      var f: Field = { class: 'multi-select', type: 'multi-select', label: 'When False hide:', title: 'falseHide', options: self.myFields, selected: this.field.falseHide }
      self.editForm.fields.push(f);
    }
    else {
      //   if (!this.field.falseHide) this.field.falseHide = [];

      var showQuery = [];
      if (self.field.showOnly) showQuery = self.field.showOnly;
      var f: Field = { type: 'queryBuilder', label: 'Show only if:', title: 'showOnly', options: [], selected: showQuery }
      // search fields for selections
      console.log("Populate show only ", self.form)
      self.form.fields.forEach(function (field) {
        f.options.push(field.title);
      })
      console.log("Populate show only ", f)


      // ADD THE FIELDS TOO Please

    /*  self.form.fields.forEach(function (field) {
        if (field.options && (field.type == 'select-button' || field.type == 'multi-select')) {
          field.options.forEach(function (option) {
            f.options.push(option);
          })
        }

      })*/

      self.editForm.fields.push(f);
    }
    if (self.field.type == 'input-text' || self.field.type == 'displayText'
      || self.field.type == 'select-button' || self.field.type == 'displayImage') {
      var f: Field = {
        type: 'select-button', blurButton: 'Update', label: 'Width', title: 'Width',
        options: ['10em', '20em', '30em', '66vw', '80vw', '96vw', '60%', '80%', '100%'], selected: [self.field.width]
      }
      self.editForm.fields.push(f);
    }

    if (self.field.type == 'result') {
      var f: Field = { type: 'select-button', blurButton: 'Update', label: 'Format', title: 'Format', options: ['Number', 'Currency', 'Text', 'Percentage', 'Short date', 'Long date', 'Time'], value: self.field.format }
      self.editForm.fields.push(f);
    }
    if (self.field.type == 'displayHTML') {
      var f: Field = { type: 'htmlEditor', blurButton: 'Update', label: 'Editor', title: 'htmlEditor', html: self.field.html }
      self.editForm.fields.push(f);
    }
    if (self.field.type == 'multi-select') {
      var f: Field = { type: 'select-button', blurButton: 'Update', label: 'Max-width', title: 'htmlEditor', html: self.field.html }
    //  self.editForm.fields.push(f);
    }

    if (self.field.type == 'number' || self.field.type == 'currency' || self.field.type == 'result') {
      var f: Field = { type: 'number', label: 'Decimals', title: 'Decimals', value: self.field.decimals }
      self.editForm.fields.push(f);
      var f: Field = { type: 'number', decimals: 2, label: 'Min value', title: 'Min value', value: self.field.minVal }
      self.editForm.fields.push(f);
      var f: Field = { type: 'number', decimals: 2, label: 'Max value', title: 'Max value', value: self.field.maxVal }
      self.editForm.fields.push(f);
      var f: Field = {
        type: 'select-button', blurButton: 'Update', label: 'Width', title: 'Width',
        options: ['4em', '6em', '8em', '10em', '12em'], selected: [self.field.width]
      }
      self.editForm.fields.push(f);


    }
        if (self.field.type == 'multi-select') {
          self.editForm.fields.push(
            { title: 'allowAdds', label:"Allow Adds", type: "switch", trueFalse: self.field.allowAdds }
          )
        }

    if (self.field.type == 'share' || self.field.type == 'options' || self.field.type == 'select-button' || self.field.type == 'multi-select' || self.field.type == 'tags') {
      self.editForm.fields.push(
        { title: 'dataSource', type: "Options", options: ["none", "Field", "Media tags", "Node tags", "Departments", "Forms", "Fields", "Themes", "Product Tags", "POS Tags", "Report tags", "Reports", "Queries", "Products", "Open Orders", "My Stores", "Napkin app"], label: "Data source", binding: self.field.dataSource, class: "select-button" }
      )
      console.log("Datasource: ", self.field.dataSource)

      if (self.field.dataSource == 'Field') {

        var formIndex = self.editForm.fields.push(
          { title: 'form', type: "Options", dataSource: 'someField', options: [], outputs: [{ name: "field", value: null }], label: "Form", binding: self.field.sourceForm, class: "select-button" }
        )
        formIndex--; // push return count, we want the index;
        console.log("formIndex ", formIndex, self.editForm)

        self.global.allFormNames.forEach(function (name) {
          self.editForm.fields[formIndex].options.push(name)
        })

        var fieldIndex = self.editForm.fields.push(
          { title: 'field', type: "Options", dataSource: 'someField', options: [], label: "Field", binding: self.field.sourceField, class: "select-button" }
        )
        fieldIndex--; // push return count, we want the index;

        self.global.allSessionFields.forEach(function (fieldSource) {
          if (fieldSource.sourceForm == self.field.sourceForm)
            self.editForm.fields[fieldIndex].options.push(fieldSource.sourceField);
        })
        var fieldIndex = self.editForm.fields.push(
          { title: 'filter', type: "Options", dataSource: 'someField', options: ['All', 'Today', 'This Month', 'Date', 'Date Range'], label: "Limited to", selected: [self.field.filter], binding: self.field.filter, class: "select-button" }
        )



      }

      if (self.field.dataSource == 'Products') {
        var fieldList = [];
        self.form.fields.forEach(function (item) { // Yikes! does this work??
          fieldList.push(item.title);
  
        })
        var f: Field = { type: 'Options', label: 'Filter source', title: 'filterSource', allowAdds: false, options: fieldList }
        self.editForm.fields.push(f);
      }


      if (self.field.dataSource == 'Reports') {
        console.log ("REPORTS ", f,self.global.allReportNames)
      }


      var f: Field = { type: 'tags', blurButton:'None', label: 'Field options', title: 'Field options', addOnly: true, allowAdds: true, options: self.field.options }
      self.editForm.fields.push(f);
    }

    if (this.field.type == 'displayField') {
      var fieldList = [];
      self.global.allSessionFields.forEach(function (item) {
        fieldList.push(item.sourceField);

      })
      var f: Field = { type: 'Options', label: 'Field source', title: 'Field source', allowAdds: false, options: fieldList }
      self.editForm.fields.push(f);
    }


    // Add the COPY Field Button
    var copyField: Field = { type: 'button', class: '', title: 'Copy Field', label: 'Copy Me' }
    self.editForm.fields.push(copyField)


    // Add the Remove Field Button
    var remove: Field = { type: 'button', buttonStyle: 'Delete', title: 'Remove Field', label: 'Remove' }
    self.editForm.fields.push(remove)

  }

}
function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}

