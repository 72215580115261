<div *ngIf="!global.authuser" class="layout">


    <div fxLayout="row" style="width:100%; height:fit-content; align-items:stretch;" fxLayoutAlign="center center">

        <input class="emailInput" data-lpignore="true"
            style="width:100% !important; min-width: 15rem !important;  padding-left:4px !important; font-size: 1.4rem; margin-right:4px !important;"
            type="email" pInputText placeholder="Email" [(ngModel)]="Email">

        <div *ngIf="!inputCreds" class="command" style=" min-height: 100%;" (click)="checkEmail()">
            Next
        </div>
    </div>


    <div *ngIf="inputCreds && foundUser">

        <div style="text-align: center; margin:12px;" *ngIf="loginError">Please check email &
            password</div>
        <div style="display:flex; padding:12px; flex-direction: row; align-items:stretch; justify-content: center;">


            <input type="password" style="height: 40px; width: 15rem !important;  padding-left:4px !important; font-size: 1.4rem;"
                class="password" [(ngModel)]="pw" placeholder="password" />
            <button pRipple style="height: 40px; padding-left:6px; padding-right:6px" (click)="login()">Sign In</button>
        </div>
        <div *ngIf="!forgotStr"
            style="font-size: 120%; cursor: pointer; text-align: center;margin-top:12px; background-color: rgb(254, 243, 243);"
            (click)="ForgotPassword()">Forgot password? Click to have it emailed to you.
        </div>
        <div *ngIf="forgotStr" style="text-align: center;margin-top:12px">Reminder email
            sent.
        </div>
    </div>

    <div *ngIf="inputCreds && !foundUser">

        <div class="pretty" fxAlign="row" fxLayoutAlign="space-around none">

            <input data-lpignore="true" style="font-size: 1.4rem !important; padding-left:4px !important; cursor:text;"
                type="text" pInputText placeholder="First name" [(ngModel)]="FirstName">
            <input data-lpignore="true" style="font-size: 1.4rem; padding-left:4px !important; cursor:text;" type="text"
                pInputText placeholder="Last name" [(ngModel)]="LastName">

        </div>


        <div (click)="RegisterNewUser()" class="command">
            {{startText}}
        </div>


    </div>

</div>