<div *ngIf="!admin">You might be lost.</div>

<div *ngIf="admin">
    Hello Scott<br>


    <div *ngIf="logs">
        <div fxLayout="row wrap" fxLayoutAlign="none center" *ngFor="let log of logs">
  
          {{log.dateStr+" "+log.timeStr+" " + getUID(log.uid) +" "+log.storeID+" "+ log.type + " " +
          log.info1+" "+log.info2+" "+log.info3 }}
        

        </div>
      </div>  



</div>
