

<!--
    Edit product: 

-->


<div *ngIf="global.allProducts">

    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                <div class="pretty-data" fxLayout="row wrap" fxLayoutAlign="space-between center">
                    Settings
                </div>
            </ng-template>
    
            <ng-template pTemplate="content">
                <button (click)="productService.moveProducts()">
                    MOVE PRODUCTS
                </button>
                
    
                <button *ngIf="global.allProducts" (click)="exportProducts()">
                    Export Products
                </button>
                
                <div class="csv-file-chooser-section">
                    Load Products
                    <input type="file" #fileImportInput name="File Upload" id="csvFileUpload" (change)="fileChangeListener($event)"
                        accept=".csv" />
                
                    <label>
                        Parse Headers?
                    </label>
                    <select aria-placeholder="Parse headers?" [(ngModel)]="header">
                        <option [value]="true">Yes</option>
                        <option [value]="false">No</option>
                    </select>
                </div>
                
                <div *ngIf="csvRecords">
                    <button (click)="importProducts()">
                        Import Products
                    </button>
                
                </div>
    
    
    
            </ng-template>
        </p-accordionTab>


    <p-accordionTab style="width:100%;">
        <ng-template pTemplate="header">
            <div class="pretty-data" fxLayout="row wrap" fxLayoutAlign="space-between center">
                Tags
            </div>
        </ng-template>

        <ng-template pTemplate="content">

            <div *ngIf="global.allTags" fxFlex fxLayout="row wrap"
            fxLayoutAlign="none space-between">
            <div *ngFor="let t of global.allTags; let i = index;">
                <div>
                   
                        <button (click)="tagSearch(t)" class="pretty-postag" pButton pRipple type="button">
                        {{t+"("+global.allTagsCount[i]+")"}}
                        </button>
                </div>
            </div>
            <div *ngIf="tagSearchStr.length>0" (click)="clearSearch()" class="command"> Clear Tag </div>

        </div>



        </ng-template>
    </p-accordionTab>
</p-accordion>

<app-form formName="master-add-product" (submitEvent)="addProduct($event)"></app-form>


    <div fxLayout="row">

        <input style="width:15em; font-size: 150%;" class="search" type="search" [(ngModel)]="searchString" pInputText
            placeholder="Search by Name">
        <div *ngIf="searchString.length>0" (click)="clearSearch()" class="command"> Clear </div>
    </div>

<p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
    *ngFor="let prod of global.allProducts; let i = index;">

    
    <div *ngIf="productFilter(prod)" style="width:100%;" fxLayout="row wrap" fxLayoutAlign="none center">
        <p-accordionTab style="width:100%;" [(selected)]="viewProduct[i]">
            <ng-template pTemplate="header">
                <div class="product-header" fxLayout="row wrap" fxLayoutAlign="space-between center"
                    [ngStyle]="{ 'color': getProdColor(prod) }"
                    [ngStyle]="{ 'font-size': getFontSize(i) }">
                    {{" "+prod.title}} {{prod.retail}}
                   
                </div>
            </ng-template>

            <ng-template pTemplate="content">
                <app-view-item [p]="prod"></app-view-item>

                <app-edit-product [editProduct]="prod"></app-edit-product>



            </ng-template>
        </p-accordionTab>
    </div>

</p-accordion>
</div>