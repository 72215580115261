<p-accordion *ngIf="mode != 'viewonly'" fxLayout="column" fxLayoutAlign="center center" expandIcon="false"
    collapseIcon="false" [multiple]="true">
    <p-accordionTab iconPos="end">
        <ng-template pTemplate="header">
            <div style="width:100%; font-size: 1.2em; text-align: center;">Settings
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div style="width: 100% !important;">

                <div *ngIf="global.authuser.uid=='kvT6eKFxFHb3EKbVeCTusW86CrZ2'" pRipple class="command"
                    (click)="copyMedia()">Copy Media</div>

                    <div *ngIf="editNode" pRipple class="command"
                    (click)="selectNodeMediaGroup(group, editNode)">Use media group</div>
                    
                <app-form formName="masterMediaGroup" [formInput]="group" [autoSubmit]="false"
                    (objectEvents)="groupSettingsOutput($event)"></app-form>

            </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>

<p-accordion *ngIf="userSettings && mode == 'viewonly'" fxLayout="column" fxLayoutAlign="center center" expandIcon="false"
    collapseIcon="false" [multiple]="true">
    <p-accordionTab iconPos="end">
        <ng-template pTemplate="header">
            <div [style]="getFullWidth()" style="font-size: 1.2em; text-align: center;">Settings
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div [style]="getFullWidth()">
                <app-form formName="masterUserMediaSettings" [autoSubmit]="false"
                    (objectEvents)="userSettingsOutput($event)"></app-form>

            </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>


<div *ngIf="global.media" class="video-thumb" fxLayout="row wrap" fxLayoutAlign="center center"
    style="text-align: center;" [style]="getFullWidth()">

    <div *ngFor="let m of global.media; let i = index;">
        <div *ngIf="checkGroup(m)" [ngStyle]="{ 'width': getMediaGroupWidth(group)}"
            [style]="getMediaGroupHeight(group)" (click)="userClickedMedia(m)">
            <div *ngIf="m.fileType=='audio/mpeg'">
                <audio id="audio" controls (click)="setFullScreen(m)">
                    <source src={{m.url}} id="src" />
                </audio>
                <button *ngIf="editNode" (click)="selectNodeVideo(m, editNode)" class="pretty-postag" pButton pRipple
                    type="button">
                    Add Audio to {{editNode.title}}
                </button>
            </div>

            <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
            || m.fileType=='video/quicktime')" class="media-container">
                <div fxLayout="column" fxLayoutAlign="center none">
                    <div *ngIf="!group.autoPlayVideo">
                        <video class="media" [style]="getMediaGroupHeight(group)" (click)="setFullScreen(m)" playsinline
                            webkit-playsinline [muted]="'muted'" loop #videoall>
                            <source src="{{m.url}}" type="video/mp4">
                        </video>
                        <div *ngIf="!isPlaying(m.url)">
                            <button (mousedown)="videoall.play()" (mouseup)="playMe(m.url)" class="play-button" pButton
                                pRipple type="button">
                                Play
                            </button>
                        </div>
                    </div>
                    <div *ngIf="group.autoPlayVideo">
                        <video class="media" [style]="getMediaGroupHeight(group)" (click)="setFullScreen(m)" playsinline
                            webkit-playsinline [muted]="'muted'" autoplay loop #videoall>
                            <source src="{{m.url}}" type="video/mp4">
                        </video>
                        
                    </div>
                </div>
            </div>
            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                m.fileType=='image/png' || m.fileType=='photo'" class="media-container">
                <div fxLayout="column" fxLayoutAlign="center none">
                    <img id="image{{i}}" (click)="setFullScreen(m)" class="media" [style]="getMediaGroupHeight(group)"
                        src="{{m.url}}" />
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="fullScreenMode">
    <p-sidebar [(visible)]="fullScreenMode" [fullScreen]="true">
        <div *ngIf="currentVideo.fileType && (currentVideo.fileType=='video' || currentVideo.fileType=='video/mp4' 
            || currentVideo.fileType=='video/quicktime')" class="media-container-full">
            <div fxLayout="column" fxLayoutAlign="center none">

                <video id="video300" class="media-full" playsinline webkit-playsinline [muted]="'muted'" #myVideo2
                    (click)="setFullScreen(m)">
                    <source src="{{currentVideo.url}}" type="video/mp4">
                </video>
                <div style="width: auto;" fxLayout="row wrap" fxLayoutAlign="center space-between">
                    <button (click)="myVideo2.play()" class="pretty-postag" pButton pRipple type="button">
                        Play Video
                    </button>

                </div>
            </div>
        </div>

        <div *ngIf="!currentVideo.fileType || currentVideo.fileType=='image/jpeg' || 
                currentVideo.fileType=='image/png' || currentVideo.fileType=='photo'">
            <div fxLayout="column" fxLayoutAlign="center none" class="media-container-full">


                <img (click)="setFullScreen(m)" class="media-full" src="{{currentVideo.url}}" />
           
            </div>


        </div>




    </p-sidebar>
</div>


