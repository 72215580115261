import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../environments/environment';
import { interval } from 'rxjs';
import { User } from '../user-interface';
import { MessageService } from 'primeng/api';
import { Howl, Howler } from 'howler';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Question, Answer, Player, TriviaGame } from "../games-interface";



const sound = new Howl({
  src: ['../../../assets/Ping-sound.mp3']
});

@Component({
  selector: 'app-trivia',
  templateUrl: './trivia.component.html',
  styleUrls: ['./trivia.component.css']
})
export class TriviaComponent implements OnInit {
  fakeNames: Array<string> = ["Vindicate", "Ironside", "Torpedo", "Bionic", "Dynamo", "Mr. Miraculous", "Tornado", "Metal Man", "Jawbreaker",
    "Barrage", "Amplify", "Bonfire", "Monsoon", "Urchin", "Firefly", "Rubble", "Blaze", "Hurricane", "Slingshot",
    "Storm Surge", "Impenetrable", "Quicksand", "Night Watch", "Mastermind", "Captain Freedom",
    "Cannonade", "Bulletproof", "Turbine", "Kraken", "Granite", "Glazier", "MechaMan", "Fortitude",
    "Cast Iron", "Fireball", "Polar Bear", "Turbulence", "Mako", "Captain Victory", "Flying Falcon"
  ];

  rightAnswer: Array<string> = ["Perfect", "Wow", "Awesome", "Correctamundo", "Dy-na-mite", "Nice Guess", "You Got It", "Smart", "Really Smart",
    "Incredible. Almost no one gets that one", "Great", "You are the best", "Amazing", "You are a winner"
  ];

  addQuestion: boolean = false;
  playGame: boolean = false;
  gameJoined: string;
  player: Player;
  playerName: string; // Randomly generated for now

  theQuestion: Question;

  resultsMode: boolean = false;
  resultsTimer: number;
  joinGame: boolean = false;
  gameStarted: boolean = false;
  gameOver: boolean = false;
  games: TriviaGame[];
  storeNum: string = "4390"; // Hack
  answered: boolean = false;
  myAnswers: Answer[] = [];

  leaderNames: string[] = [];
  leaderScores: number[] = [];
  leaders: Player[] = [];

  timer: number = 0;
  timeLeft: number = 0;

  gameIndex: number = 0;
  game: string;
  gameID: string;
  showJoinCode: boolean = true;

  gettingQuestion: boolean = false;
  joinURL: string;
  qrcodeURL: string;

  //KeepAwake: WakeLock;
  correctAnswerStr: string = "Right!";
  myGame: TriviaGame;
  admin: boolean = false;
  feedback: boolean = true;
  editingMode: boolean = false;
  allTopics: string[] = [];
  allQuestions: Question[] = [];
  searchString: string = "";
  roundTime: number = 30;
  postRoundTime: number = 8;
  public audio: HTMLAudioElement;
  public audioStart: HTMLAudioElement;
  constructor(private messageService: MessageService, private route: ActivatedRoute, private clipboard: Clipboard) {
    firebase.initializeApp(environment.firebase);
    this.game = route.snapshot.queryParamMap.get('game');
    if (!this.game) {
      this.game = this.route.snapshot.paramMap.get('game');
    }
    this.gameID = this.route.snapshot.paramMap.get('id');

    if (this.game) this.gameID = this.game;
    else if (this.gameID) this.game = this.gameID;
    console.log("game: ", this.game, this.gameID);


  }

  ngOnInit(): void {
    var self = this;

    this.admin = false;
    this.theQuestion = { question: "", category: "", correctAnswer: "", altAnswers: [] };

    this.audio = new Audio();
    this.audioStart = new Audio();
    this.initAudio();

    if (this.game == "tunes") {
      this.GetSongs();
    }


    const id = localStorage.getItem('uid');
    if (id) {
      var self = this;
      var db = firebase.firestore();

      db.collection("users").doc(id).onSnapshot((doc) => {

        const data = <User>doc.data();

        if (data.creds) {
          data.creds.forEach(function (cred) {
            if (cred.storeNum == "4390") {  // HACK
              self.admin = true;
            }
          })

        }

      })
    }
    // GIVE PLAYER A NAME
    var r = Math.floor(Math.random() * (this.fakeNames.length));
    this.player = {
      name: this.fakeNames[r],
      score: 0
    }
    if ('wakeLock' in navigator) {
      // Screen Wake Lock API supported 🎉
      //  this.player.name = "KillJoy";
    }

    // this.KeepAwake = navigator.wakeLock;
    var anyNav: any = navigator;
    const requestWakeLock = async () => {
      try {
        const wakeLock = await anyNav["wakeLock"].request('screen');
        //   this.player.name = "KillJoy";

        //  const wakeLock = await navigator.wakeLock.request('screen');

      } catch (err) {
        // The wake lock request fails - usually system-related, such as low battery.
        //  this.player.name = err;
        console.log(`${err.name}, ${err.message}`);
      }
    }

    requestWakeLock();


    interval(50).subscribe(() => {
      this.RunGame();
    });

    // DIRECT JOIN ANOTHER GAME - Auto-Sub-teaming

    if (this.gameID) {
      this.GetGame();
    }



  }

  item1: any;
  initAudio() {
    this.item1 = new Howl({
      src: ['../../../assets/shipsbell.webm', '../../../assets/shipsbell.mp3'], html5: true, onplayerror: function () {
        this.item1.once('unlock', function () {
          this.item1.play();
        });
      }
    });

  }

  playAudio() {

    this.audio = <HTMLAudioElement>document.getElementById('audio1');
    this.audio.play();

  }
  toggleJoinCode() {
    this.showJoinCode = !this.showJoinCode;
  }


  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  randomCorrectStr() {



    var r = Math.floor(Math.random() * (this.rightAnswer.length));

    this.correctAnswerStr = this.rightAnswer[r];



  }
  SaveQuestion() {
    var self = this;

    var db = firebase.firestore();

    //    console.log("Question: ", quest);
    db.collection("trivia").doc().set(self.theQuestion)
      .then(() => {
        console.log("Question Added");
        self.theQuestion = { question: "", category: "", correctAnswer: "", altAnswers: [] };
      })
      .catch((error) => {
        console.error("Error writing question: ", error);
      });
  }

  UpdateQuestion() {
    var self = this;

    var db = firebase.firestore();

    db.collection("trivia").doc(self.theQuestion.id).update(self.theQuestion)
      .then(() => {
        console.log("Question Updated");
        self.theQuestion = { question: "", category: "", correctAnswer: "", altAnswers: [] };
      })
      .catch((error) => {
        console.error("Error writing question: ", error);
      });
  }

  AddQuestion() {
    this.addQuestion = true;
    this.playGame = false;
  }
  EditQuestions() {
    this.editingMode = true;
    this.GetAllQuestions();

  }
  EditMode(topic: string) {


  }

  onQuestClick(question: Question) {  // Edit this question
    this.theQuestion = Object.assign(this.theQuestion, question);
    this.editingMode = true;
    console.log("Edit: ", this.theQuestion);
  }

  DeleteQuestion() {

    if (!confirm("Please confirm QUESTION DELETION! /n" + this.theQuestion.question)) return;

    var self = this;

    var db = firebase.firestore();

    db.collection("trivia").where("id", "==", self.theQuestion.id)
      .get()
      .then((querySnapshot) => {
        // IF HERE THEN WE CAN REMOVE
        if (!querySnapshot.empty) {
          db.collection("trivia").doc(self.theQuestion.id).delete().then(() => {
            console.log("Question successfully deleted!");
          }).catch((error) => {
            console.error("Error removing question: ", error);
          });
        }
      });


  }

  PlayGame() {
    if (!this.gameStarted) {
      this.FindGame();
    }

    // this.KeepAwake.request('screen');

    this.addQuestion = false;
    this.playGame = true;
    this.audioStart = <HTMLAudioElement>document.getElementById('audioStartGame');
    this.audioStart.play();
  }

  PlayMusicGame() { // Tmp hack
    this.game = "tunes";
    if (!this.gameStarted) {
      this.FindGame();
    }

    // this.KeepAwake.request('screen');

    this.addQuestion = false;
    this.playGame = true;
    this.audioStart = <HTMLAudioElement>document.getElementById('audioStartGame');
    this.audioStart.play();
  }


  PlayMovieGame() { // Tmp hack
    this.game = "Movies";
    if (!this.gameStarted) {
      this.FindGame();
    }

    // this.KeepAwake.request('screen');

    this.addQuestion = false;
    this.playGame = true;
    this.audioStart = <HTMLAudioElement>document.getElementById('audioStartGame');
    this.audioStart.play();
  }

  GoHome() {
    this.addQuestion = false;
    this.playGame = false;
    this.editingMode = false;
    this.theQuestion = { question: "", category: "", correctAnswer: "", altAnswers: [] };
  }

  FindGame() {  /* Check for games at my  Store */
    var self = this;
    var db = firebase.firestore();

    db.collection("triviaGame").where("storeNum", "==", self.storeNum).where("game", "==", "Trivia")
      .get()
      .then((querySnapshot) => {
        self.games = [];
        if (querySnapshot.empty) {  // NO GAMES FOUND- START NEW ONE
          this.NewGame();
        }
        querySnapshot.forEach((doc) => {

          const data = <TriviaGame>doc.data();
          console.log("game", data);
          var gameStart = data.start.toDate();

          const day = gameStart.getDate();
          const month = gameStart.getMonth();
          const year = gameStart.getFullYear();
          var d = new Date();
          if (!data.finish && day == d.getDate() && month == d.getMonth() && year == d.getFullYear()) {
            if (data.index < data.questions.length) {
              if ((Date.now() - data.start.toMillis()) / 1000 < 300) { // 5 min max
                self.games.push(data);
                self.joinGame = true;
              }

            }
          }
          else {  // Auto-delete finished games
            if (!querySnapshot.empty) {
              db.collection("triviaGame").doc(doc.id).delete().then(() => {
                console.log("Game successfully deleted!");
              }).catch((error) => {
                console.error("Error removing game: ", error);
              });
            }
          }
        });
        console.log("Games Available to Join: ", self.games);
        // We found a game so let's auto-join
        // self.JoinGame();

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  NewGame() {

    console.log(this.item1);
    this.item1.play();

    this.myGame = {
      host: this.player.name,
      hostTime: firebase.firestore.Timestamp.now(),
      category: "Wine",
      storeNum: "4390",
      game: "Trivia",
      index: 0,
      start: firebase.firestore.Timestamp.now(),
      answers: []
    }
    this.myGame.players = [];
    this.myGame.players.push(this.player);  //autogen fake names
    this.myGame.questions = [];
    this.GetQuestions();
  }

  JoinGame(game: TriviaGame) {
    var self = this;
    console.log("Joining game: ", game);

    this.myGame = game;
    this.myGame.index = game.index;
    this.gameJoined = game.id;
    this.joinGame = false;
    // ELIMATE DUPLICATES
    if (self.myGame && self.myGame.players) {


      do {
        var found = false;
        self.myGame.players.forEach(function (arrayItem) {
          if (arrayItem.name == self.player.name) {
            found = true;
            const r = Math.floor((Math.random() * 998) + 1);
            self.player.name += "-" + r.toString();
            // Check one more time for a duplicat

          }
        });
      } while (found)
    }


    this.myGame.players.push(this.player);
    this.UpdatePlayers();
    this.gameStarted = true;

    if (this.gameID) this.PlayGame();
    //this.myGame.startQuestion = firebase.firestore.Timestamp.now();

    // Establish realtime connection to get New Index
    // Look for simpler way to get single value

    var db = firebase.firestore();
    const q = query(collection(db, "triviaGame"), where("id", "==", self.myGame.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        const r = <TriviaGame>doc.data();
        console.log("Join Game: ", r);
        self.myGame.answers = r.answers;
        /*        if (r.index > self.gameIndex) {
                  self.myGame.index = r.index;
                  self.gameIndex = r.index;
                  self.answered = false;
                }
         */
      });
    });
  }

  TapGame(game: TriviaGame) {

    // Establish realtime connection to get New Index
    // Look for simpler way to get single value
    var self = this;
    var db = firebase.firestore();
    const q = query(collection(db, "triviaGame"), where("id", "==", self.myGame.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      querySnapshot.forEach((doc) => {
        const r = <TriviaGame>doc.data();
        console.log("Tapped Game: ", r);
        self.myGame.answers = r.answers;
        /*        if (r.index > self.gameIndex) {
                  self.myGame.index = r.index;
                  self.gameIndex = r.index;
                  self.answered = false;
                }
                */
      });
    });

  }


  UpdatePlayers() {
    var self = this;
    var db = firebase.firestore();

    var ref = db.collection("triviaGame").doc(self.myGame.id);
    //console.log (ref, self.myGame.id);
    // Check for Existing first


    ref.update({
      players: self.myGame.players
    })
      .then(() => {
        console.log("Player added");
      })
      .catch((error) => {
        console.error("Error writing players: ", error);
      });

  }


  answerColor(answer, i) {

    var index = this.myAnswers.map(function (e) { return e.index; }).indexOf(i);
    //console.log(i, index, answer);

    if (index != -1 && answer == this.myAnswers[index].answer) {
      return "rgb(250, 245, 255)";
    }
    else {
      return "rgb(250, 245, 178)";
    }
  }

  myAnswer(answer: string, i: number) {
    var self = this;

    if (this.answered) return;  // ONE answer!

    this.answered = true;
    var corr = this.myGame.questions[i].correctAnswer;

    this.messageService.add({ severity: 'success', summary: 'Got it!', detail: '' });


    var a: Answer = {
      player: this.player,
      index: i,
      answer: answer,
      correct: (answer == corr)
    }
    this.myAnswers.push(a);
    console.log("You answered: ", answer, i, a);

    if (answer == corr) {
      console.log("Correct!");
      this.player.score += 100;
    }
    else {
      console.log("Wrong!");
    }

    // Add answer to Game data

    var db = firebase.firestore();
    var answerRef = db.collection("triviaGame").doc(this.myGame.id);

    answerRef.update({
      answers: firebase.firestore.FieldValue.arrayUnion(a),
      index: self.gameIndex
    });
  }

  lastQuestTime = 0;

  RunGame() {
    var self = this;

    if ('wakeLock' in navigator) {
      // Screen Wake Lock API supported 🎉
      //   this.player.name = "KillJoy";
    }

    var now = firebase.firestore.Timestamp.now().toMillis();
    // Game time & Game Indexbased on game.start plus fixed intervals
    // Default: 8 seconds per question, 4 seconds for results

    if (self.gameStarted && !self.gameOver) {
      if (!self.lastQuestTime) this.lastQuestTime = now;

      var secondsFromGameStart = (now - self.myGame.start.toMillis()) / 1000;
      var newIndex = Math.floor(secondsFromGameStart / (self.postRoundTime + self.roundTime)); // 
      var secondsFromQuestStart = (now - self.lastQuestTime) / 1000;

      self.timeLeft = (self.roundTime - secondsFromQuestStart);

      //  console.log(newIndex, secondsFromQuestStart, self.timer);

      if (!self.resultsMode && secondsFromQuestStart > self.roundTime) {
        self.resultsMode = true;
        self.LeaderBoard();
        document.getElementById("top").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }
      if (newIndex > self.myGame.index) {
        document.getElementById("top").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
        self.randomCorrectStr();
        self.myGame.index = newIndex;
        self.gameIndex = newIndex;
        self.resultsMode = false;
        self.lastQuestTime = now;
        self.answered = false;
        self.playClip();

        //   self.item1.play();
      }
      if (self.gameIndex >= self.myGame.sequence.length) {
        //   self.gameStarted = false;


        self.LeaderBoard();
        self.FinishGame();
        console.log("Game Over");
        self.gameOver = true;
      }
    }
  }

  clip: any;

  playClip() {
    var self = this;
    console.log("Play clip: ", self.myGame.questions[self.myGame.sequence[self.gameIndex]].audioLink);

    if (self.myGame.questions[self.myGame.sequence[self.gameIndex]].audioLink) {
      /*      self.clip = new Howl({
              src: [self.myGame.questions[self.myGame.sequence[self.gameIndex]].audioLink], html5: true, onplayerror: function () {
                self.clip.once('unlock', function () {
                  //  self.clip.play();
                  console.log("Play clip2: ", self.clip, self.myGame.questions[self.myGame.sequence[self.gameIndex]].audioLink);
                });
              }
            });
            */

      var sound = new Howl({
        src: [self.myGame.questions[self.myGame.sequence[self.gameIndex]].audioLink],
        html5: true
      });
      sound.play();

      // self.clip.play();
    }
  }
  LeaderBoard() {
    var self = this;
    // create list of players & scores

    self.leaderNames.splice(0, self.leaderNames.length);
    self.leaderScores = [];
    self.leaders = [];
    self.myGame.answers.forEach(function (answer) {
      var i = self.leaderNames.indexOf(answer.player.name);
      if (i == -1) {
        // Add Player
        self.leaderNames.push(answer.player.name);
        var s = 0;
        if (answer.correct) s += 100;

        self.leaderScores.push(s);
        var p: Player = { name: answer.player.name, score: s }
        self.leaders.push(p);
      }
      else {
        var s = 0;
        if (answer.correct) s += 100;
        self.leaderScores[i] += s;
        self.leaders[i].score += s;
      }

    })
    // Sort by Score
    self.leaders.sort(function (a, b) { return b.score - a.score; });
    // Let's check final scores and pick some winners etc.
    // Assign Winners: 1. Top Score(First, second, third place) 1+ 2. Correct > 50%-"Smart", >60-"very smart"

    self.leaders.forEach(function (player, index) {
      player.tags = [];
      if (index == 0) player.tags.push("First place!");
      if (index == 1) player.tags.push("Second place!");
      if (index == 2) player.tags.push("Third place!");


    })
    console.log("Leaders ", self.leaders);

  }

  GetGame() { // Get Game from url parameter
    var self = this;
    var db = firebase.firestore();

    var ref = db.collection("triviaGame");

    var gameActive = false;

    ref.where('shortCode', '==', self.gameID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <TriviaGame>doc.data();
          if (!data.finish) {
            self.myGame = data;
            console.log(self.myGame);
            gameActive = true;
          }
        })
        if (gameActive) {
          self.JoinGame(self.myGame);
          console.log("Auto-joining ", self.myGame);
        }
        else
          self.gameID = null;
      })
      .catch((error) => {
        console.error("Game Expired: ", error);
        self.gameID = null;
      });





  }
  GetQuestions() {
    var self = this;

    var allQuestions: Question[] = [];

    var db = firebase.firestore();
    var topic = "Wine";
    if (this.game) topic = this.game;

    db.collection("trivia").where("category", "==", topic)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Question>doc.data();
          //   if (self.myGame.questions.length <= 10) // 10 Q game rounds
          self.myGame.questions.push(data);
        });
        console.log(self.myGame.questions);
        /* Now create randomized sequence of ? questions */

        self.myGame.sequence = [];
        self.myGame.correct = [];
        while (self.myGame.sequence.length < self.myGame.questions.length) {
          console.log("..");
          var r = Math.floor(Math.random() * (self.myGame.questions.length));
          if (self.myGame.sequence.indexOf(r) == -1) {
            self.myGame.sequence.push(r);
            // SCRAMBLE ANSWERS
            // Put correct answer in random spot
            var arr = [];
            arr.push(self.myGame.questions[r].correctAnswer);
            self.myGame.questions[r].altAnswers.forEach(function (item) {
              arr.push(item);
            })

            self.myGame.questions[r].answers = [];
            var totAnswers = arr.length;

            while (self.myGame.questions[r].answers.length < totAnswers) {
              var a = Math.floor(Math.random() * (totAnswers));
              if (self.myGame.questions[r].answers.indexOf(arr[a]) == -1) {
                self.myGame.questions[r].answers.push(arr[a]);
              }
            }


          }
        }
        console.log(self.myGame.questions);

        // Now Game can start

        // Save New Game for others to join
        const docRef = db.collection('triviaGame').doc();

        self.myGame.id = docRef.id;
        self.myGame.shortCode = docRef.id.substring(0, 5);

        self.qrcodeURL = "http://api.qrserver.com/v1/create-qr-code/?data=" + "napkinhq.com/trivia/" + docRef.id.substring(0, 5) + "&size=200x200",

          self.joinURL = "https://napkinhq.com/trivia/" + docRef.id.substring(0, 5);
        console.log(self.joinURL);
        docRef.set(self.myGame)
          .then(() => {
            console.log("Game Added");

          })
          .catch((error) => {
            console.error("Error writing game: ", error);
          });

        self.gameStarted = true;
        self.joinGame = false;
        self.gameJoined = null;
        self.playClip();
        self.myGame.startQuestion = firebase.firestore.Timestamp.now();
        self.TapGame(self.myGame);


      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }


  GetAllQuestions() {
    var self = this;

    this.allTopics = [];

    var db = firebase.firestore();

    db.collection("trivia")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Question>doc.data();
          if (!data.id) data.id = doc.id; // Hack? Transient doc id
          self.allQuestions.push(data);
          if (self.allTopics.indexOf(data.category) == -1) {
            self.allTopics.push(data.category);
          }
        });
      })
      .catch((error) => {
        console.log("Error getting all questions: ", error);
      });

  }

  UpdateGameIndex() {
    var self = this;

    var db = firebase.firestore();

    var ref = db.collection("triviaGame").doc(self.myGame.id);
    //console.log (ref, self.myGame.id);

    ref.update({
      index: self.gameIndex,
      startQuestion: self.myGame.startQuestion
    })
      .then(() => {
        console.log("New Index ", self.gameIndex);

      })
      .catch((error) => {
        console.error("Error writing index: ", error);
      });

  }

  FinishGame() {
    var self = this;


    var db = firebase.firestore();

    var ref = db.collection("triviaGame").doc(self.myGame.id);
    ref.update({
      finish: firebase.firestore.Timestamp.now(),
      players: self.myGame.players
    })
      .then(() => {

      })
      .catch((error) => {
        console.error("Error writing index: ", error);
      });
  }

  GetSongs() {
    var self = this;


    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'spotify23.p.rapidapi.com'
      }
    };

    fetch('https://spotify23.p.rapidapi.com/search/?q=heavy%20metal&type=tracks&offset=0&limit=5&numberOfTopResults=3', options)
      .then(response => response.json())
      .then(response => {
        console.log("Search: ", response);
        response.tracks.items.forEach(function (item) {


          var a: string[] = [];
          a.push("Some other band")
          var theQ: Question = {
            category: "tunes",
            question: "What is the name of song?",
            correctAnswer: item.data.name,  //item.data.artists.items[0].profile.name,
            altAnswers: a,
          }

          console.log("Song Item: ", theQ);

          //Now get the track
          const options = {
            method: 'GET',
            headers: {
              'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
              'X-RapidAPI-Host': 'spotify23.p.rapidapi.com'
            }
          };

          const fetchStr = 'https://spotify23.p.rapidapi.com/tracks/?ids=' + item.data.id;
          fetch(fetchStr, options)
            .then(response => response.json())
            .then(response => {
              console.log("Track:", self.theQuestion, response);
              if (response.tracks) {
                theQ.audioLink = response.tracks[0].preview_url;
                self.theQuestion = theQ;

                self.SaveQuestion();
              }




            })
            .catch(err => console.error(err));

          setTimeout(() => { console.log("Delayed"); }, 800);

        })


      }
      )
      .catch(err => console.error(err));

  }

}


/*

Storm Surge

Impenetrable

Quicksand

Night Watch

Mastermind

Captain Freedom

Cannonade

Bulletproof

Turbine

Kraken

Granite

Glazier

MechaMan

Fortitude

Cast Iron

Fireball

Polar Bear

Turbulence

Mako

Captain Victory

Flying Falcon

Blackback

Tradewind

Manta Ray

The Rooster

Megalodon

Steamroller

Apex

Leviathan

Onyx

Shadowman

Exodus

Eagle Eye

Laser Sight

Titan

Vigilance

Volcanic Ash

Jackhammer

Bullseye

Tarantula

Shockwave

Barracuda

Night Howler

Chromium


*/
