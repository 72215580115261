import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface Results {
  position?: number;
  url?: string;
  description?: string;
  title?: string;
}

export interface Kpanel {
  name: string;
  label: string;
  description: Desc;
  image: kImage;
  info: Info[];
}

export interface kImage {
  url: string;
  width: number;
  height: number;
  page_url: string;
}

export interface Desc {
  text: string;
  url: string;
  site: string;
}

export interface Info {
title: string;
labels: string[];

}


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Input() getResults: Results[];
  @Input() cancelIcon: boolean = false;
  @Input() query: string;
  @Input() getInfo: Kpanel;
  @Input() showQuery: boolean = true;
  @Output() setResultEvent = new EventEmitter<Results[]>();
  @Output() setInfoEvent = new EventEmitter<Kpanel>();
  @Output() setQueryEvent = new EventEmitter<string>();


  
  results: Results[];
  info: Kpanel;


  ngOnInit(): void {
    console.log ("Data ",this.query, this.getInfo, this.getResults, this.showQuery)
    if (this.getResults) {
      this.results = this.getResults;
      if (this.getInfo) this.info = this.getInfo;
      console.log ("Have results; ",this.results)
    }
    if (this?.query?.length && !this.results) {
      this.Search(this.query)
    }

  }
  LoadPage (r:Results) {

    if (r.url) {
      window.open(r.url);

    }
  }

  cancel () {
    this.setResultEvent.emit(null);
  }

  Search(query: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'web-search24.p.rapidapi.com'
      }
    };

    var fetchStr = 'https://web-search24.p.rapidapi.com/?query=' + query + '&limit=60'

    fetch(fetchStr, options)
      .then(response => response.json())
      .then(response => {
        
        self.results = <Results[]>response.results;
        self.info = <Kpanel>response.knowledge_panel;
        console.log(self.results, self.info);
        self.setInfoEvent.emit(self.info);
        self.setQueryEvent.emit(self.query);
        self.setResultEvent.emit(self.results);

      })
      .catch(err => console.error(err));
  }

}