<audio id="audioStartGame">
    <source
        src="https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/Audio%2FArcadeIntro.aac?alt=media&token=87e91fb3-3547-4556-a594-2224793f6cf8"
        type="audio/aac">
</audio>
<div id="top" class="layout">
    <p-toast position="top-center"></p-toast>

    <div class="player">
        <div fxLayout="column" fxLayoutAlign="center center" style="margin-left:6px;">
            <div style="font-size:.7em">Playing as</div>
            <div style="font-size:1.3em"> {{player.name}}</div>
        </div>

        <div *ngIf="gameStarted" style="font-size:1.3em">
            {{timeLeft | number:'1.1-1'}}&nbsp;Sec
        </div>

        <div style="font-size:1.3em;margin-right:6px;"> {{"Score: " + player.score}}</div>



    </div>

    <div *ngIf="!addQuestion && !playGame">
        <div *ngIf="admin" class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
            (click)="AddQuestion()">Add Question</div>
        <div *ngIf="admin && !playGame && !editingMode" class="action-button2"
            style="cursor: pointer; background-color:rgb(187, 242, 139)" (click)="EditQuestions()">Edit Questions</div>

        <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)" (click)="PlayGame()">
            Play {{game}} Trivia Game</div>

            <div *ngIf="game!='Movies'" class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)" (click)="PlayMovieGame()">
                Play Movie Trivia Game</div>

            <div *ngIf="game!='Music'" class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)" (click)="PlayMusicGame()">
                Play Music Trivia Game</div>

    </div>
    <div *ngIf="admin && editingMode">
        <div class="pretty">
            <!--        <div *ngFor="let topic of allTopics; let i = index;">
                <div class="join-games" style="cursor: pointer; background-color:rgb(67, 109, 226)"
                    (click)="EditMode(topic)">{{topic}} </div>
            </div>
            -->

        </div>

        <p-dataView #dv [value]="allQuestions" [paginator]="true" [rows]="10" [alwaysShowPaginator]="false"
            filterBy="category" layout="list">

            <ng-template pTemplate="header">
                <div class="p-col-12 flex-auto flex-row justify-content-between">
                    {{searchString}}
                    <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                        <i class="pi pi-search"></i>
                        <input type="search" [(ngModel)]="searchString" pInputText placeholder="Search by Name"
                            (input)="dv.filter($event.target.value, 'contains')">
                    </span>

                </div>
                <div (click)="EditMode()" style="width:100%; background-color: rgb(199, 199, 199)" fxLayout="row wrap"
                    fxLayoutAlign="space-around center">

                    <div *ngFor="let cat of allTopics; let i = index">
                        <div class="pretty-category" fxLayoutAlign="center center" (click)="dv.filter(cat, 'contains')">

                            <div style="margin-left:12px; margin-right: 12px;">
                                {{cat}}
                            </div>

                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template let-item pTemplate="listItem">
                <div id="products" class="pretty-products" fxLayout="row wrap" fxLayoutAlign="none center"
                    (click)="onQuestClick(item)">

                    <button pButton pRipple type="button" class="p-button-raised p-button-text p-button-text"
                        style="color:black">
                        {{item.question}}</button>


                </div>

            </ng-template>

        </p-dataView>


    </div>



    <div *ngIf="admin && (addQuestion || editingMode)">
        <div *ngIf="theQuestion">
            <textarea placeholder="Category" [(ngModel)]="theQuestion.category" [rows]="1" [cols]="30" pInputTextarea
                [autoResize]="true"></textarea>
            <textarea placeholder="Question" [(ngModel)]="theQuestion.question" [rows]="1" [cols]="30" pInputTextarea
                [autoResize]="true"></textarea>
            <textarea placeholder="Correct Answer" [(ngModel)]="theQuestion.correctAnswer" [rows]="1" [cols]="30"
                pInputTextarea [autoResize]="true"></textarea>
            <textarea placeholder="Alt Answer 1" [(ngModel)]="theQuestion.altAnswers[0]" [rows]="1" [cols]="30"
                pInputTextarea [autoResize]="true"></textarea>
            <textarea placeholder="Alt Answer 2" [(ngModel)]="theQuestion.altAnswers[1]" [rows]="1" [cols]="30"
                pInputTextarea [autoResize]="true"></textarea>
            <textarea placeholder="Alt Answer 3" [(ngModel)]="theQuestion.altAnswers[2]" [rows]="1" [cols]="30"
                pInputTextarea [autoResize]="true"></textarea>
            <textarea placeholder="Image URL (with question)" [(ngModel)]="theQuestion.beforeImage" [rows]="1"
                [cols]="30" pInputTextarea [autoResize]="true"></textarea>
            <textarea placeholder="Audio URL " [(ngModel)]="theQuestion.audioLink" [rows]="1" [cols]="30" pInputTextarea
                [autoResize]="true"></textarea>

        </div>

        <div *ngIf="addQuestion" class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
            (click)="SaveQuestion()">Save Question</div>
        <div *ngIf="editingMode" class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
            (click)="UpdateQuestion()">Update Question</div>
        <div *ngIf="editingMode" class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
            (click)="DeleteQuestion()">Remove Question</div>
        <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)" (click)="GoHome()">Done
        </div>
    </div>

    <div *ngIf="joinGame">
        <div *ngFor="let game of games; let i = index;">
            <div class="join-games" style="cursor: pointer; background-color:rgb(67, 109, 226)"
                (click)="JoinGame(game)">Join Game: {{game.game+" "+game.category+" "+game.host}} </div>
        </div>

    </div>


    <div *ngIf="playGame">
        <div *ngIf="!gameStarted">
            <div class="action-button2" style="cursor: pointer; background-color:rgb(241, 247, 197)"
                (click)="NewGame()">New Game</div>
        </div>

        <div *ngIf="gameStarted">
            <div *ngIf="myGame && !resultsMode && !gameOver">
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div class="index">{{gameIndex+1}}</div>

                    <div class="question">
                        {{myGame.questions[myGame.sequence[gameIndex]].question}}
                    </div>
                </div>

                <div *ngIf="myGame.questions[myGame.sequence[gameIndex]].beforeImage">
                    <img style="width: 100%" src={{myGame.questions[myGame.sequence[gameIndex]].beforeImage}}
                        alt="Photo of spider" />
                </div>
                <div *ngIf="myGame.questions[myGame.sequence[gameIndex]].audioLink">
                    <audio id="audio1">
                        <source src={{myGame.questions[myGame.sequence[gameIndex]].audioLink}} type="audio/mpeg">
                    </audio>
                    <div class="play-audio" style="cursor: pointer;" (click)="playAudio()">Play Audio Clip Again</div>
                </div>
                <div fxLayout="column" fxLayoutAlign="space-around stretch">
                    <div *ngFor="let answer of myGame.questions[myGame.sequence[gameIndex]].answers; let i = index;">
                        <div *ngIf="!answered" class="answer" style="background-color: rgb(250, 245, 178)"
                            (click)="myAnswer(answer, myGame.sequence[gameIndex])">{{answer}}</div>
                        <div *ngIf="answered" class="answer"
                            [style.background-color]="answerColor(answer, myGame.sequence[gameIndex])"
                            (click)="myAnswer(answer, myGame.sequence[gameIndex])">{{answer}}</div>

                    </div>
                </div>
            </div>
            <div *ngIf="resultsMode || gameOver">
                <div *ngIf="gameOver" class="game-over">GAME OVER</div>

                <div *ngIf="answered" class="correct-answer">
                    Answer:{{" "+myGame.questions[myGame.sequence[gameIndex]].correctAnswer}}
                </div>
                <div *ngIf="answered && myAnswers[myAnswers.length-1].correct" class="correct">
                    {{correctAnswerStr}}
                </div>
                <div class="players" *ngFor="let leader of leaders; let i = index;">
                    {{leader.name + "  "+ leader.score}}
                    <div *ngIf="leader.tags">
                        <div *ngFor="let tag of leader.tags; let i = index;">
                            {{tag+" "}}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div *ngIf="gameStarted && joinURL">
        <br>
        <div (click)="toggleJoinCode()" class="direct-join">
            <div>Direct join to your game</div>
            <div *ngIf="showJoinCode" style="font-size:.8em">Hide Code</div>
            <div *ngIf="!showJoinCode" style="font-size:.8em">Show Code</div>
        </div>

        <div *ngIf="showJoinCode" class="qrcodeImage">
            <div class="action-button2">Click to Copy to Clipboard
                <div (click)="copyText(joinURL)">{{joinURL}}</div>

            </div>

            <img [src]="qrcodeURL" alt="QR code to join game" title="" />
<!--
            <qrcode [qrdata]="" [allowEmptyString]="true"
                [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
                [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [elementType]="'canvas'"
                [errorCorrectionLevel]="'M'" [imageSrc]="'./assets/partygameslogo.png'" [imageHeight]="75"
                [imageWidth]="75" [margin]="2" [scale]="1" [title]="'A custom title attribute'" [width]="300"></qrcode>
        -->
            </div>
    </div>
</div>