/*
This module creates apps automatically.
Phase 1: from a business name (generated from API) or entered get google & Tripadvisor data and create app
with photos reviews and info.

Phase 2: From a form wizard to gather design and feature target

Phase 3: From a conversation as App is built in real-time during conversation

 */


import { Component, SkipSelf, Input, HostListener, ViewChild, OnInit, AfterViewInit, BootstrapOptions } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../../environments/environment';
import { interval, NotFoundError } from 'rxjs';
import { User, Media } from '../../user-interface';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/globals';
import { Company, Form, Field, NapkinApp, Gallery, AppNode, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate } from '../../company-interface';
import { Product, Options, Table } from '../../product-int';
import { onMenuItem } from '../../menu-item-interface';
import { Content, Weather } from '../../content-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../../products.service';
import { ViewProductsComponent } from '../../menu/view-products/view-products.component';
import { DragDropModule } from 'primeng/dragdrop';
import { HttpEvent } from '@angular/common/http';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { NavService } from '../../menu/nav.service';
import { ReviewsComponent } from '../../reviews/reviews.component';
import { Quote } from '../../content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { Order } from '../../order-interface';
import { Howl, Howler } from 'howler';
import { RegisterService } from '../../register.service';
import { Creds, Follows, Notify } from '../../../components/user-interface';
import { TAlocationInfo, TAphotos, TAobject, TAlookAhead, OpenClose, TAreview } from '../../trip-advisor-interface';


@Component({
  selector: 'app-ai-maker',
  templateUrl: './ai-maker.component.html',
  styleUrls: ['./ai-maker.component.css']
})
export class AiMakerComponent implements OnInit {

  place: string;
  newStoreId: string;
  newStoreNum: string;
  storeFields: any;
  locationInfo: TAlocationInfo;
  locationId: "21042803";

  constructor(public cartService: CartService, public sanitizer: DomSanitizer, public global: Globals, private router: Router,
    private route: ActivatedRoute,
    public navService: NavService,
    public registerService: RegisterService,
    public productService: ProductsService) {


  }
  ngOnInit(): void {
    console.log("Started AI-Maker ");


  }
  placeSearch() {
    console.log("Search: ", this.place);
    this.lookAhead();

  }

  lookAhead() {

    var self = this;
    const url = 'https://worldwide-restaurants.p.rapidapi.com/typeahead';


    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'worldwide-restaurants.p.rapidapi.com'
      },
      body: new URLSearchParams({
        q: 'San Diego',
        language: 'en_US'
      })
    };

    fetch(url, options)
      .then(response => response.json())
      .then(response => {

        const r = <TAlookAhead[]>response.results.data;
        console.log("TA-lA results ", response, r);
        self.searchPlace(r);
      })
      .catch(err => console.error(err));


  }

  searchLocation() {

    var self = this;
    const url = 'https://worldwide-restaurants.p.rapidapi.com/search';

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'worldwide-restaurants.p.rapidapi.com'
      },
      body: new URLSearchParams({
        language: 'en_US',
        location_id: self.locationId,
        currency: 'USD',
        offset: '0'
      })
    };

    fetch(url, options)
      .then(response => response.json())
      .then(response => {

        const r = <TAlocationInfo[]>response.results.data;
        console.log("search results ", response, r);
        self.storeSearch(r);
      })
      .catch(err => console.error(err));

  }


  storeSearch(result: TAlocationInfo[]) {
    var self = this;

    // Store all results locally

    result.forEach(function (item) {
      item.hoursOpen = [];

      var o: [{ open_time: number, close_time: number }];
      var openClose: OpenClose = { open_time: 0, close_time: 0 };

      item.hours.week_ranges.forEach(function (day) {

        o = day;
        if (o.length) {
          console.log("O: ", o, openClose)
          openClose.open_time = o[0].open_time;
          openClose.close_time = o[0].close_time;
          item.hoursOpen.push(openClose)
        }
        else {
          openClose = { open_time: 0, close_time: 0 };
          item.hoursOpen.push(openClose)
        }
      })

      item.hours = null;
      self.addSearch(item);

    })

    // var cityId = result[0].result_object.location_id;
    //console.log ("Searching: ", cityId)
  }



  searchPlace(result: TAlookAhead[]) {
    var self = this;

    // Store all results locally

    result.forEach(function (item) {
      self.addLocation(item.result_object);

    })

    // var cityId = result[0].result_object.location_id;
    //console.log ("Searching: ", cityId)
  }


  addLocation(result: TAobject) {
    const db = firebase.firestore()

    const vm = this;
    var docRef =
      db.collection('locations').doc()

    result.id = docRef.id;
    docRef.set(result);
  }

  CreateStore() {
    const vm = this;
    const db = firebase.firestore()

    vm.locationId = "21042803";

    var docRef =
      db.collection('location_info')
        .doc("EdcreuAh9G9r7Ld3UhqN")    // HACK
        .get().then((doc) => {
          const data = <TAlocationInfo>doc.data()
          vm.locationInfo = data;
          console.log("Location: ", data);

          vm.regStore(data);

        })
        .catch((error) => {
          console.error("Error getting app link", error);
        });

  }

  addSearch(result: TAlocationInfo) {
    const db = firebase.firestore()

    const vm = this;
    var docRef =
      db.collection('location_info').doc()
    result.id = docRef.id
    docRef.set(result);
  }

  sanDiego: string = "60750";



  // SO LET'S MAKE SOME APPS
  // read the db to get location info
  // Create the Company
  // Add Creds to my account for each new app
  // Add photos to company
  // Add reviews to company
  // Add location details to company
  // Create an app...

  regStore(storeInfo: TAlocationInfo) {

    var self = this;

    //      if (!validatePhoneNumber(this.reservePhone)) return;
    self.newStoreId = self.registerService.GetCompanyUID();
    var newStore = (+Math.random() * 9000 + 1000); //hack
    var newStoreInt = Math.trunc(newStore);
    self.newStoreNum = newStoreInt.toString();


    const zipcode = storeInfo.address_obj.postalcode;
    const address = storeInfo.address_obj.street1;
    const state = storeInfo.address_obj.state;
    const phone = storeInfo.phone;
    const city = storeInfo.address_obj.city;
    const domain = storeInfo.website;
    const busName = storeInfo.name

    var services = {
      appBuilder: true,
      categories: false,
      groupBookings: false,
      pos: true,
      orders: true,
      products: true,
      reservationRewards: false,
      reservations: true,
      rewards: false,
      chatService: true,
      media: true,
      reviews: true,
      reviewsPro: true,
      reports: false,

    }

    /*
    var chatService;
    var chatServiceEmail;
    var email = self.storeNum + "@napkinhq.com";
    */
    var db = firebase.firestore();

    /*
    // For Chat - add chat store user to connect with
    if (1) { //self.services.chatService) do it here
      var docRef = db.collection("users").doc();
      self.registerService.RegisterChatServiceUser(
        email,
        docRef, "Customer", "Service", null, null);
      chatService = docRef.id;
      chatServiceEmail = email;
    } */

    self.storeFields = {
      name: busName,
      address1: address,
      city: city,
      state: state,
      postal: zipcode,
      store: self.newStoreNum,
      primaryPhone: phone,
      storeURL: domain,
      lat: <number>parseInt(storeInfo.latitude),
      long: <number>parseInt(storeInfo.longitude),
    }

    self.registerService.registerStore(services, self.storeFields, self.afterRegisterStore, self);

  }

  afterRegisterStore(self: this) {
    console.log("after store reg:")
    // Add creds to User

    self.updateCreds();
    self.updateTripAdvisor(self.newStoreId)
    self.getPhotos();

    setTimeout(() => {
      console.log("Delayed for 3 second.");
      self.getTAReviews();
    }, 3000);


    


  }

  updateTripAdvisor(storeId) {

    var self = this;
    console.log("Updating TA info ", storeId);

    var db = firebase.firestore();

    var dbRef = db.collection("company").doc(storeId)

    dbRef.update({ TAinfo: self.locationInfo })
      .then(() => {
        console.log("ta updated!");
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error: ", error);
      });



  }






  updateCreds() {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("users").doc(self.global.authuser.uid);

    var notify: Notify = { enable: true, reservations: true };

    var creds: Creds = {
      id: self.newStoreId,
      storeNum: self.storeFields.store,
      role: "Creator",
      startDate: Date.now(),
      storeName: self.storeFields.name,
      notify: notify,
      enabled: { staff: true, reviews: true }

    }

    console.log("Set Creds: ", creds, notify)

    var myCreds = self.global.authuser.creds;

    myCreds.push(creds);

    ref.update({
      creds: myCreds
    })
      .then(() => {
        console.log("Creds updated: ");
        // Save locally
        //      localStorage.setItem('myCreds', JSON.stringify(creds));
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  getPhotos() {
    this.fetchTripAdvisorPhotos();
  }
  fetchCount = 0;

  fetchTripAdvisorPhotos(next?: string) {
    var self = this;
    var url = 'https://worldwide-restaurants.p.rapidapi.com/photos';

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'worldwide-restaurants.p.rapidapi.com'
      },
      body: new URLSearchParams({
        language: 'en_US',
        location_id: self.locationId,
        currency: 'USD',
        limit: '20',
        offset: (self.fetchCount*20).toString()
      })
    };


    fetch(url, options)
      .then(response => response.json())
      .then(response => {
        const r = <TAphotos[]>response.results.data;
        self.addMediaToStore(r);
        console.log("photo results ", response, r);
        if (response.results?.paging.next && self.fetchCount < 10) {
          setTimeout(() => {
            self.fetchCount++;
            self.fetchTripAdvisorPhotos(response.results.paging.next)
          }, 4000);

        }

      })
      .catch(err => console.error(err));


  }

  addMediaToStore(photos: TAphotos[]) {
    var self = this;


    photos.forEach(function (photo) {
      var media = {
        store: self.newStoreNum,
        tags: ["Trip Advisor"],
        url: photo.images.large.url,
        width: parseInt(photo.images.large.width),
        height: parseInt(photo.images.large.height),
        fileType: "image/jpeg",


      }
      self.addMedia(media);


    })

  }


  addMedia(newMedia: Media) {
    var db = firebase.firestore();
    var self = this;

    // To avoid duplicates add Query for PexelVideo.id before saving - Hack

    newMedia.companyId = self.newStoreId;
    var mediaRef = db.collection("company").doc(self.newStoreId).collection("media").doc();
    newMedia.id = mediaRef.id;

    newMedia.uploadId = this.global.authuser.uid;
    newMedia.createdAt = Date.now();

    mediaRef.set(newMedia)
      .then(() => {
        console.log("TA Media Added!", newMedia);

      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating media: ", error);
      });

  }

  reviewCount = 0;

  getTAReviews(next?: string) {
    var self = this;
    const url = 'https://worldwide-restaurants.p.rapidapi.com/reviews';

    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'worldwide-restaurants.p.rapidapi.com'
      },
      body: new URLSearchParams({
        language: 'en_US',
        location_id: self.locationId,
        currency: 'USD',
        limit: '20',
        offset: (self.reviewCount*20).toString()
      })
    };

    fetch(url, options)
      .then(response => response.json())
      .then(response => {

        const r = <TAreview[]>response.results.data;
        self.addReviewsToStore(r);
        console.log("Review results ", response, r);
        if (response.results?.paging.next && self.reviewCount < 20) {
          setTimeout(() => {
            self.reviewCount++;
            self.getTAReviews(response.results.paging.next)
          }, 2000);

        }


      })
      .catch(err => console.error(err));


  }
  addReviewsToStore(reviews: TAreview[]) {
    var self = this;
    var db = firebase.firestore();
    var newReview: Review;
    //OLD

    reviews.forEach(function (r) {
      newReview = r;

      newReview.author = "";
      if (r?.user?.name) {
        newReview.author = r.user.name;
      }
      newReview.date = r.published_date;

      newReview.review_rating = r.rating;
      newReview.review_text = r.text;
      newReview.status = 'published';
      newReview.source = 'TripAdvisor';

   //   console.log("Saving New Review ", newReview, self.newStoreId);

      var db = firebase.firestore();

      var reviewRef = db.collection("company").doc(self.newStoreId).collection("reviews").doc();
      newReview.id = reviewRef.id;

      // Add Photos  - Move to Media - check if exists first etc.
      if (r.photos) {
        newReview.review_photos = [];
        r.photos.forEach (function(photo){
          if (photo?.large?.url)
            newReview.review_photos.push(photo.large.url)
        })
      }

      reviewRef.set(newReview)
        .then(() => {
          console.log("review Added!", newReview);
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error tareview: ", error);
        });


    })


  }

  createReviewApp () {
    // Simple starter app for reviews with media

    var node: AppNode = {
      active: true,
      title: "Reviews App",
      type: 'object',
      objectType: 'getreview',
    }

    this.createNewApp("Reviews", node);


  }


  createNewApp(title, firstNode: AppNode) {
 

    }


    insertNew(node: AppNode, appId: string) {
      var vm = this;
  /*
      var baseNode: AppNode = {
        active: false,
        title: "New Item",
        parent: node.parent,
        objectType: "content",
        createdAt: Date.now(),
        createdBy: this.global.authuser.uid,
        height: 100 + Math.floor(Math.random() * 400),
        width: 400 + Math.floor(Math.random() * 400)
      }
  */
      //  const i = Math.floor(Math.random() * this.colorOptions.length)
  
      //   baseNode.backgroundColor = this.colorOptions[i];
      vm.addNode(node, vm.global.myCompany.id, appId);
  
  
    }
  
    addNode(node: AppNode, docId, collectionId) {
      const db = firebase.firestore()
  
      console.log("add: ", node, docId, collectionId)
      const vm = this;
      var docRef =
        db.collection('company')
          .doc(docId)
          .collection(collectionId).doc()
  
      node.id = docRef.id;
      docRef.set(node);
   
    }









}
