import { Component, Output, Input, HostListener, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import { Company, Calendar, Theme, Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { MediaService } from "../media//media-functions/media-functions"
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'
import { set, isToday, compareAsc, startOfDay, getTime, getDate, setDate, setMonth, setYear, getYear, addMonths, subMonths, getMonth, getDaysInMonth, startOfMonth, getDay, endOfDay, getMilliseconds, isPast } from "date-fns";


@Component({
  selector: 'app-field-selector',
  templateUrl: './field-selector.component.html',
  styleUrls: ['./field-selector.component.css']
})
export class FieldSelectorComponent implements OnInit {
  @Input() field: Field;
  @Input() maxFields: number = -1;

  fields: Field[];

  constructor(public global: Globals,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {


  }

  ngOnInit(): void {
    var self = this;

    this.fields = [];
    var f: Field = { type: 'select-button', dataSource: 'Forms', optionsVisible: true, display: true, label: "Form", options: [], blurButton: "Update", title: 'form' }
    if (this.field?.fieldSource?.form) {
      f.selected = [this.field.fieldSource.form];
      // f.binding = this.field.fieldSource.form;
    }
    if (f.selected?.length) f.optionsVisible = false;

    console.log("field-selector-field ", this.field, f)
    self.fields.push(f);

    if (f?.selected?.length) {
      var f: Field = { type: 'multi-select', maxSelections: this.maxFields, optionsVisible: true, display: true, label: "Fields", options: [], blurButton: "Update", title: 'fields' }
      f.selected = this.field.fieldSource.includedFields;
      self.global.allSessionFields.forEach(function (fieldSource) {
        if (fieldSource.sourceForm == self.field?.fieldSource?.form)
          f.options.push(fieldSource.sourceField);
      })
      self.fields.push(f);
    }



  }

  fieldEvents(field: Field) {
    var self = this;

    if (field.title == 'form') {
      if (field.selected[0] != self.field?.fieldSource?.form) {
        field.sourceForm = field.selected[0];
        self.field.sourceForm = field.selected[0];
        // NEW
        self.field.fieldSource = { includedFields: [] };

        self.field.fieldSource.form = field.selected[0];
        // Display Fields
        // If only 1 then use select-button
        var f: Field = { type: 'multi-select', maxSelections: self.maxFields, optionsVisible: true, display: true, label: "Fields", options: [], blurButton: "Update", title: 'fields' }
        f.selected = self.field.fieldSource.includedFields;
        self.global.allSessionFields.forEach(function (fieldSource) {
          if (fieldSource.sourceForm == self.field.fieldSource.form)
            f.options.push(fieldSource.sourceField);
        })

        if (self.fields.length == 1)
          self.fields.push(f);
        else
          self.fields.splice(1, 1, f)
      }

    }
    else if (field.title == 'fields') {
      self.field.selected = field.selected;
      if (!self.field.fieldSource.includedFields) self.field.fieldSource.includedFields = [];
      self.field.fieldSource.includedFields = field.selected
    }

    console.log("Field ", field, self.field);

  }

}
