import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomizeMessageService {

    private loaderSubject = new Subject<MessageState>();
    loaderState = this.loaderSubject.asObservable();

    msg: string = "";
    summary: string = "Success";

    constructor() { }

    show() {
        this.loaderSubject.next(<MessageState>{ show: true });
    }

    msg2(message) {
        this.msg = message;

    }

}

export interface MessageState {
    show: boolean;
}