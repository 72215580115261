<div fxFlex fxLayout="column" fxLayoutAlign="space-between center" style="width:100%; min-height: 100px">

    <div *ngIf="showQuery && !results" style="width: 100%">
        <div fxFlex="75%">
            <input style="width:100%" placeholder="Search phrase" type="text" pInputText [(ngModel)]="query" />

        </div>
        <div fxFlex="25%" fxLayout="row">
            <div *ngIf="cancelIcon" class="cancel-button" (click)="cancel()">
                X</div>
            <div class="action-button3"
                style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                (click)="Search(query)">
                Search</div>
        </div>


    </div>


    <div *ngIf="info" class="card" fxLayout="row wrap">

        <div class="card__img-container">
            <img src={{info.image.url}} alt="">
        </div>
        <div class="card__content">

            <div class="card__title">

                {{info.label}}:
                {{info.name}}
            </div>
            <div class="card__desc">
                {{info.description.text}}
            </div>

            <div *ngIf="info.info" fxLayout="row wrap" fxLayoutAlign="space-between space-between"
                style="height: min-content">
                <div *ngFor="let detail of info.info; let i = index" class="details">
                    <b>{{detail.title}}</b>
                    <div *ngFor="let label of detail.labels;">
                        {{label}}

                    </div>


                </div>



            </div>
        </div>

    </div>

    <div *ngIf="results">
        <div *ngFor="let r of results; let i = index" class="card" (click)="LoadPage(r)">
            <div class="card__content">
                <div class="card__title">
                    {{r.position}}

                    {{r.title}}
                </div>
                <div class="card__desc">
                    {{r.description}}
                </div>
            </div>

        </div>

    </div>
</div>