<div *ngIf="global.authuser">
    <div class="layout">
        <p-toast key="toast1" position="top-center"></p-toast>
        <p-toast key="toast2" position="top-center"></p-toast>

        <p-accordion (onOpen)="onTabOpen($event)" expandIcon="false" collapseIcon="false" [multiple]="true">
            <div fxFlex fxLayout="column" fxLayoutAlign="none none">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="l1-feature">
                            POS Settings
                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">

                        <p-accordionTab style="width:100%;">
                            <ng-template pTemplate="header">
                                <div class="sub-feature">
                                    Manage Tag Groups
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">




                                <div fxFlex fxLayout="column" fxLayoutAlign="center none" style="width: 100%">
                                    <!--  View Tag Groups -->
                                    <div fxLayout="row wrap">
                                        <div *ngFor="let tag of global.myCompany.posTagGroups; let i = index">
                                            <div fxLayout="row" fxLayoutAlign="none none" class="pretty-postag">
                                                <button (click)="manageTags(tag)" style="margin-right:0px" pButton
                                                    pRipple type="button">
                                                    {{tag.name}}
                                                </button>
                                                <div *ngIf="!tag.tags.length" style="margin: 4px;"
                                                    (click)="removeTagGroup(tag)">Remove</div>
                                            </div>
                                        </div>
                                        <div *ngIf="!newGroupMode" (click)="addNewTagGroup()" class="command"> Add
                                            New Tag Group</div>

                                        <input *ngIf="newGroupMode" style="width:15em; " class="search" type="text"
                                            [(ngModel)]="groupName" pInputText placeholder="">
                                        <div *ngIf="groupName.length>0" (click)="addTagGroup()" class="command">
                                            Add Tag Group </div>

                                    </div>

                                    <div *ngIf="editTagGroup" fxLayout="row" style="width: 100%">
                                        <input style="width:15em;" class="search" type="search"
                                            [(ngModel)]="searchString" pInputText placeholder="Search">
                                        <div *ngIf="searchString.length>0" (click)="clearTagSearch()" class="command">
                                            Clear </div>


                                    </div>
                                    <div *ngIf="editTagGroup" fxLayout="row" style="width: 100%">

                                        <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:30%">
                                            <div *ngIf="editTagGroup.tags">
                                                Tags assigned:
                                            </div>
                                            <div *ngIf="editTagGroup.tags" fxFlex fxLayout="row wrap"
                                                fxLayoutAlign="start start">
                                                <div *ngFor="let d of editTagGroup.tags; let i = index;">
                                                    <div class="my-tag" (click)="removeTag(d)">
                                                        {{d}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="width:70%" fxLayout="column" fxLayoutAlign="none none">
                                            Tags not assigned:
                                            <!-- TAGS AVAILABLE -->
                                            <div *ngIf="global.allTags" fxFlex fxLayout="row wrap"
                                                fxLayoutAlign="none space-between">
                                                <div *ngFor="let t of global.allTags; let i = index;">
                                                    <div *ngIf="tagFilter(t)">
                                                        <div *ngIf="!editTagGroup.tags || (editTagGroup.tags && !editTagGroup.tags.includes(t))"
                                                            class="pretty-tag" (click)="addTag(t)">
                                                            {{t}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </ng-template>
                        </p-accordionTab>


                    </ng-template>
                </p-accordionTab>

                <p-accordionTab *ngIf="orderService.openOrdersList.length>0" styleClass="dark-panel">
                    <ng-template pTemplate="header">
                        <div class="l1-feature">
                            {{orderService.openOrdersList.length+" "}}Open Orders
                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">

                        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
                            <div *ngFor="let item of orderService.openOrdersList; let i = index">

                                <div fxLayout="row" fxLayoutAlign="none none">
                                    <div class="pretty-time" fxLayoutAlign="center center"
                                        (click)="selectedOpenOrder(item)" [style]="getOpenOrderStyle(item)">
                                        <div style="cursor:pointer">
                                            {{item.tableName}}
                                        </div>
                                    </div>
                                    <div class="pretty-time"
                                        style="margin-left: 0px; color:gray; background-color: rgb(213, 244, 213);"
                                        fxLayoutAlign="center center" (click)="CheckOutOrder(item)">

                                        <div style="margin:0px;">
                                            -$-
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </p-accordionTab>
                <p-accordionTab *ngIf="orderService.closedOrders.length>0" styleClass="dark-panel"
                    [(selected)]="viewTabClosedOrders">
                    <ng-template pTemplate="header" styleClass="dark-panel">
                        <div class="l1-feature">
                            {{orderService.closedOrders.length+" "}}Closed Orders
                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">

                        <div style="width:100%;" fxFlex fxLayout="row wrap" fxLayoutAlign="space-around center">
                            <div *ngFor="let item of orderService.closedOrders; let i = index">

                                <div fxLayout="row wrap" fxLayoutAlign="none none">
                                    <div class="pretty-time" fxLayoutAlign="center center"
                                        (click)="selectedClosedOrder(item)">
                                        <div style="cursor:pointer">
                                            {{item.name}}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </ng-template>
                </p-accordionTab>

                <div *ngIf="!viewTabOpenOrders" style="background-color: lightyellow;width:100%" fxFlex
                    fxLayout="column" fxLayoutAlign="center center">


                    <div fxLayout="row">

                        <input style="width:15em; font-size: 150%;" class="search" type="search"
                            [(ngModel)]="searchString" pInputText placeholder="Search by Name">
                        <div *ngIf="searchString.length>0" (click)="clearSearch()" class="command"> Clear </div>
                    </div>
                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                        <div *ngFor="let tag of global.myCompany.posTagGroups; let i = index" >
                            <button class="pretty-postag" (click)="updateFilter(tag)" pButton pRipple type="button">

                                {{tag.name}}
                            </button>

                        </div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutAlign="center center">

                        <div style="cursor: pointer;" *ngFor="let item of global.allProducts; let i = index">


                            <div *ngIf="productFilter(item)" (click)="onProdClick(item)">

                                <button class="pretty-products" pButton pRipple type="button">
                                    {{" "+item.title}}
                                    {{"$"+item.retail}}

                                </button>
                                <div *ngIf="tagMode">
                                    <img src="./assets/tag.png" style="height: 36px; width: auto">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="pretty-header-data-center">



                    <!--    The ORDER                                          -->

                    <div fxFlex="100%">
                        <div fxLayout="column" class="order-container">
                            <div fxLayout="row wrap" fxLayoutAlign="none center">
                                TABLE:&nbsp;
                                <div>
                                    <input id="tableInput" class="table-input" type="text" pInputText
                                        [(ngModel)]="myTable" (blur)="changeTable()" placeholder="Table?">

                                </div>
                                <div *ngIf="optionsReq" class="new-order">
                                    Options Required

                                </div>
                                <div (click)="discounts()" class="discounts">Discounts</div>
                                <div *ngIf="showDiscounts">
                                    <p-sidebar [(visible)]="showDiscounts" [fullScreen]="true">
                                        <div fxLayout="column" fxLayoutAlign="start none">
                                            <div class="pretty-feature">Discounts</div>

                                            <div style="width:100%;" fxFlex fxLayout="row wrap"
                                                fxLayoutAlign="space-around center">
                                                <div *ngFor="let item of orderService.discounts; let i = index">

                                                    <div fxLayout="row" fxLayoutAlign="none none">
                                                        <div *ngIf="(itemDiscount == item.oneItem) || itemDiscount"
                                                            class="pretty-discounts" fxLayoutAlign="center center"
                                                            (click)="selectedDiscount(item)">
                                                            <div *ngIf="discountActive(item);else inactive">
                                                                {{item.name}} active
                                                            </div>
                                                            <ng-template #inactive style="cursor:pointer">
                                                                {{item.name}}
                                                            </ng-template>


                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="pretty-feature"> Discounts Applied to order</div>

                                            <div style="width:100%;" fxFlex fxLayout="column"
                                                fxLayoutAlign="space-around center">
                                                <div *ngFor="let item of newOrder; let i = index">
                                                
                                                    <div fxLayout="column" fxLayoutAlign="none none">

                                                        <div class="discount-list" fxLayout="row"
                                                            *ngIf="item.discountsApplied && item.discountsApplied.length"
                                                            style="cursor:pointer">
                                                            {{item.discountsApplied[0].name}} {{item.item}}
                                                            <div class="discount-remove" (click)="removeDiscount(item)">
                                                                Remove this discount</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>






                                            <div class="action-button2"
                                                style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                                (click)="discounts()">Save Changes</div>
                                        </div>
                                    </p-sidebar>
                                </div>

                                <!--
                                <div (click)="NewOrder()" class="new-order">New Order</div>
                                -->

                            </div>

                            <div fxLayout="row" class="top-info-bar">
                                <div class="left-side">



                                    <div *ngIf="newOrder.length">
                                        <div class="action-button2" style="cursor: pointer; background-color:DarkSalmon"
                                            (click)="NewOrder()">Clear Order</div>
                                    </div>

                                    <div *ngIf="myTable.length && CartItems() && !optionsReq;then active else disabled">
                                    </div>
                                    <ng-template #disabled>
                                        <div *ngIf="newOrder.length && !CartItems() ">
                                            <div class="action-button2"
                                                style="cursor: pointer; color:white; background-color:rgb(114, 32, 5)"
                                                (click)="CloseOrder()">Checkout</div>
                                        </div>
                                        <div *ngIf="0" class="action-button2"
                                            style="cursor: pointer; color:white; background-color:rgb(114, 32, 5)"
                                            (click)="RemoveOrder()">Close
                                        </div>
                                    </ng-template>
                                    <ng-template #active>
                                        <div>
                                            <div class="action-button2"
                                                style="cursor: pointer; color: black;  background-color:  greenyellow"
                                                (click)="SendOrder()">Kitchen</div>
                                        </div>


                                    </ng-template>



                                    <div *ngIf="readyItems" class="action-button2"
                                        style="cursor: pointer; color:darkblue; background-color:rgb(254, 255, 242)"
                                        (click)="allDelivered()">ALL Delivered</div>
                                </div>

                                <div class="total-right">Total:{{(getOrderTotal() | currency)}} </div>
                                <!--      <div class="status2" (click)="Expanded()" style="background-color: rgb(180, 229, 255)">
                                </div>
                                -->

                            </div>
                            <div class="list-container">
                                <p-accordion #itemAccordion expandIcon="false" collapseIcon="false"
                            
                                *ngFor="let order of newOrder; let i = index">

                                        <p-accordionTab styleClass="dark-panel">
                                            <ng-template pTemplate="header">
                                                <div class="left-side2" fxFlex fxLayout="row wrap"
                                                    fxLayoutAlign="space-between center">

                                                    <span *ngIf="order.qty>1"
                                                        style="font-size:1.2em; line-height:1.3em;  ">{{order.qty}}</span>
                                                        <span style="font-size:120%;">
                                                    {{lineItem(order)}}</span>
                                                    <span
                                                        style="font-size:.8em;">{{lineItemOptions(order)}}
                                                    </span>

                                                    <div fxLayoutAlign="end center">
                                                        <div class="status"
                                                            [style.background-color]="getStatusColor(order)"
                                                            (click)="changeStatus($event, order)">{{getStatus(order)}}
                                                        </div>

                                                        <div class="right-side2" fxLayout="row"
                                                            [style.background-color]="getStatusColor(order)">

                                                            {{" " + getExtPrice(order)}}
                                                            <div *ngIf="order.discountsApplied && order.discountsApplied.length"
                                                                style="font-size:75%">
                                                                {{order.discountsApplied[0].name+" "}} Discount applied
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </ng-template>

                                            <ng-template pTemplate="content">
                                                <!--    ORDER DETAIL                                          -->
                                                <div fxLayout="row wrap" fxLayoutAlign="space-between none"
                                                    class="exp-panel">
                                                    <p-inputNumber [(ngModel)]="order.price" mode="currency"
                                                        currency="USD" locale="en-US"></p-inputNumber>
                                                    <button pButton pRipple type="button"
                                                        (click)="changePrice($event, order)"
                                                        style="font-size:1.2em;">Update Price</button>

                                                    <div *ngIf="CheckforMinus(order)">
                                                        <button pButton pRipple type="button"
                                                            (click)="Minus($event, order, i)" style="font-size:1.4em;"
                                                            class="small-button">-</button>
                                                    </div>
                                                    <div *ngIf="CheckforPlus(order)">
                                                        <button type='button' mat-raised-button (click)="Plus(order, i)"
                                                            style="font-size:1.4em;" class="small-button">+</button>
                                                    </div>
                                                    <div (click)="itemDiscounts(order)" class="item-discounts">Item
                                                        Discounts
                                                    </div>

                                                    <!--  PRESENT OPTIONS  -->
                                                    <div class="exp-panel" *ngIf="order.allOptions">
                                                        <div
                                                            *ngFor="let thisOption of order.allOptions; let n = index;">
                                                            <div *ngIf="thisOption.list.length > 1" fxLayout="row wrap"
                                                                fxLayoutAlign="center center"
                                                                style="margin-top: 10px; margin-bottom: 10px; background-color: rgb(136, 254, 120);">
                                                                {{thisOption.title+": select below "}}
                                                                <p-selectButton (onChange)="optionChanged($event, i)"
                                                                    [options]="thisOption.list"
                                                                    [(ngModel)]="thisOption.chosen" optionLabel=""
                                                                    optionValue="">
                                                                  
                                                                </p-selectButton>

                                                            </div>

                                                        </div>

                                                    </div>

                                                    <textarea #textarea1 [(ngModel)]="order.note" [rows]="3" [cols]="30"
                                                        pInputTextarea autoResize="autoResize" [autofocus]="true"
                                                        (change)="changeNote($event, order, i)"></textarea>



                                                    <!--     NOW PRESENT ITEM DETAILS [Item 1: Ordered: 12:31pm  Ready in 12 minutes Delivered in 15 min -->
                                                    <div class="right-side">
                                                        {{itemDetail(order)}}
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>
                                  
                                </p-accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-accordion>
    </div>

</div>