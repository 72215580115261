<div style="width: 100%;" fxFlex fxLayout="row wrap" fxLayoutAlign="center center">
    <div *ngFor="let app of global.allApps; let i = index;">
        <div [ngStyle]="{ 'width': getAppWidth(group)}"
        [style]="getAppHeight(group)" (click)="gotoApp(app)" class="app">
      
            <div >
            <div *ngIf="app.coverImage" fxLayout="column" fxLayoutAlign="center none">
                <img id="image{{i}}" class="media" [style]="getAppHeight()"
                    src="{{app.coverImage.url}}" />
            </div>
            <div *ngIf="!app.coverImage" fxLayout="column" fxLayoutAlign="center center" [style]="getAppHeight()">
                <div >
                    {{myName(app)}}
                </div>
                     
            </div>
           
        </div>









        </div>
    </div>
</div>