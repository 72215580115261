import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from '../../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../../../components/register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { Creds, Follows, User, Notify } from '../../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';


export interface EnabledOption {
  name: string;
  code: boolean;
}
export interface yesNoOption {
  name: string;
  code: boolean;
}

@Component({
  selector: 'app-managestaff',
  templateUrl: './managestaff.component.html',
  styleUrls: ['./managestaff.component.css']
})
export class ManagestaffComponent {
  staffEmail: string;
  staffFirstName: string;
  staffLastName: string;
  inputCredsnoUser: boolean = false;
  inputCreds: boolean = false;
  foundUser: boolean = false;
  staffUserId: string;

  allStaff: User[];
  staffEnable: boolean = false;
  enabledOptions: EnabledOption[];
  yesNoOptions: yesNoOption[];

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,) {

    this.enabledOptions = [
      { name: 'Staff access', code: true },
      { name: 'No access', code: false }
    ];

    this.yesNoOptions = [
      { name: 'Enabled', code: true },
      { name: 'Off', code: false }
    ];

    this.fetchStaff();

  }



  fetchStaff() {

    var self = this;
    var db = firebase.firestore();

    self.allStaff = [];

    db.collection("users").where("creds", "!=", "null")
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {

          const data = <User>doc.data();

          data.creds.forEach(function (cred, i) {
            if (cred.id == self.global.myCompany.id) {
              if (!cred.enabled) cred.enabled = { staff: false };
              data.credIndex = i; // Kinda a hack to find store.
              self.allStaff.push(data);
            }
          })
        });
        console.log("Staff: ", self.allStaff);

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  updateCreds(staff: User) {

    var self = this;
    const xid = this.global.myCompany.id;

    const index = staff.creds.findIndex(item => item.id === xid);

    var db = firebase.firestore();
    var ref = db.collection("users").doc(staff.uid);

    var notify: Notify = { enable: true };
    if (!staff.creds[index].notify)
      staff.creds[index].notify = notify;
    if (staff.creds[index].notify.chat ||
      staff.creds[index].notify.reservations)
      staff.creds[index].notify.enable = true;


    console.log("Creds Index ", index, staff.creds);
    ref.update({
      creds: staff.creds
    })
      .then(() => {
        console.log("Creds updated: ");
        // Save locally
        //??    localStorage.setItem('myCreds', JSON.stringify(creds));
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });


  }
  Clicked(command: string) {
    var self = this;

   // if (command.toLowerCase() == "add staff") {
   //   console.log("Clicked ", command);
      self.checkEmail();
  //  }

  }
  checkEmail() {
    var self = this;
    console.log("Checkemail ", self.staffEmail);

    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    self.foundUser = false;
    if (!self.staffEmail || self.staffEmail.length < 5) return;

    self.inputCreds = false;
    db.collection("users").where("email", "==", self.staffEmail)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          self.foundUser = true;
          self.inputCredsnoUser = null;
          self.staffUserId = querySnapshot.docs[0].id;
          // ADD ENABLE Chat Notifications now

          var user = <User>querySnapshot.docs[0].data();
          /*
         if (!user.notify) user.notify = { "enable": true };
         user.notify.enable = true;
         user.notify.chat = true;
         querySnapshot.docs[0].ref.update(user);
         */
          self.AddStaff(user.uid);
          self.sendStaffInviteEmail(user.email, user.firstName, user.lastName, user.sid);


        }
        else if (self.staffFirstName && self.staffLastName) {
          console.log("Register Staff ", self.staffEmail, self.staffFirstName, self.staffLastName)

          self.registerService.RegisterStaff(self.staffEmail, self.staffFirstName, self.staffLastName,
            self.global.myCompany.store, self.global.myCompany.id, self.global.myCompany.name, self.afterStaffAdded, self);
        }
        else {
          console.log ("Get name")
          self.inputCredsnoUser = true;
        }
        self.inputCreds = true;
        console.log("Found: ", self.foundUser);



      })

  }
  afterStaffAdded(self: this, user: User) {
    console.log("Inviting New Staff member")
    self.sendStaffInviteEmail(self.staffEmail, self.staffFirstName, self.staffLastName, user.sid);
    self.staffEmail = "";
    self.staffFirstName = "";
    self.staffLastName = "";
    self.inputCredsnoUser = null;
  }

  sendStaffInviteEmail(email, first, last, sid) {
    // NOTIFY STAFF
    var templateParams = {
      from_name: this.global.myCompany.name,  // Company Name
      to_name: first,
      to_email: email,
      direct: "https://openbabble.com/front/?p=" + sid,
      msgDate: Date.now(),
      msgFrom: this.global.authuser.firstName + " " + this.global.authuser.lastName,
      msgText: ""
    };

    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_q6lkf0o", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log("staff notified: ", result.text);
      }, (error) => {
        console.log(error.text);
      });

  }

  AddDepartment(staff: User, dept) {
    const xid = this.global.myCompany.id;
    const index = staff.creds.findIndex(item => item.id === xid);

    if (!staff.creds[index].departments) staff.creds[index].departments = [];
    staff.creds[index].departments.push(dept);
    this.updateCreds(staff);
  }

  RemoveDepartment(staff: User, dept) {
    const xid = this.global.myCompany.id;
    // const index = staff.creds.findIndex(item => item.id === xid);
    console.log("Remove Dept: ", staff);

    if (!staff.creds[staff.credIndex].departments) staff.creds[staff.credIndex].departments = [];

    // Code below works to find element in array
    var deptIndex = staff.creds[staff.credIndex].departments.findIndex(item => item === dept);

    console.log("Remove Dept: ", staff, deptIndex);

    staff.creds[staff.credIndex].departments.splice(deptIndex, 1);
    this.updateCreds(staff);

  }

  AddStaff(userID) {
    var self = this;
    // Lookup email and if found add this company as creds 'staff'
    var db = firebase.firestore();

    var docRef = db.collection("users").doc(userID);
    docRef.get().then((doc) => {

      // ADD TO CREDS
      var data = <User>doc.data();
      var myCreds = <Creds[]>[];
      if (data.creds)
        myCreds = data.creds;

      var notify: Notify = { enable: false, reservations: false };


      var newCreds = {
        enabled: { staff: true },
        notify: notify,
        id: self.global.myCompany.id,
        storeName: self.global.myCompany.name,
        storeNum: self.global.myCompany.store,
        role: "staff",
        startDate: Date.now()
      }
      myCreds.push(newCreds);
      console.log(myCreds);
      //  myCreds = JSON.parse(JSON.stringify(myCreds));
      //   }

      var setWithMerge = doc.ref.set({
        creds: myCreds
      }, { merge: true });

      console.log("Staff Added");

    })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }


}
