import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import { AngularFireModule } from '@angular/fire/compat';
//import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../../../environments/environment';
import firebase from 'firebase/compat/app';
import { User, Follows } from '../user-interface'
import { CartService } from '../menu/cart.service';
import { AccordionModule } from 'primeng/accordion';
import { Logger } from '../../functions';


@Component({
  selector: 'app-unsub',
  templateUrl: './unsub.component.html',
  styleUrls: ['./unsub.component.css']
})
export class UnsubComponent implements OnInit {
  storeNum: string = "";
  email: string = "";
  msg = ";"

  constructor(private route: ActivatedRoute,private router: Router) {
    this.storeNum = route.snapshot.queryParamMap.get('s');
    console.log("s: ", this.storeNum);

    this.email = route.snapshot.queryParamMap.get('n');
    console.log("email: ", this.email);
    if (!this.email || this.email.length == 0) {
      this.msg = "missing email.";
      return;
    }
/*
    this.router.navigate([], {    // REMOVE dcode
      queryParams: {
        's': null,
        'n': null,
      },
      queryParamsHandling: 'merge'
    })
*/

  }
  ngOnInit(): void {
    var self = this;
    firebase.initializeApp(environment.firebase);
    var db = firebase.firestore();

    db.collection("users").where("email", "==", this.email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();


          if (data.unsub && data.unsub.includes(self.storeNum)) {
            self.msg = "Successfully Unsubscribed.";
          }
          else {
            var unsubs: string[] = [];
            if (data.unsub) {
              unsubs = data.unsub;
            }
            unsubs.push(self.storeNum);
            var catRef = db.collection("users").doc(data.uid);
            if (catRef) {
              catRef.update({
                unsub: unsubs,
              }).then(() => {
                console.log("updated: ");
                self.msg = "Successfully Unsubscribed";
                Logger("Unsub", data.uid, this.storeNum, self.email);


              })
              .catch((error) => {
                console.error("Error: ", error);
              });
            }
          }

        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });

  }
  private removeParamFromUrl(paramMap: ParamMap, keysToRemove: string[]) {
    const queryParams = {};
    const keysToKeep = paramMap.keys.filter(k => !keysToRemove.includes(k));
    keysToKeep.forEach(k => (queryParams[k] = paramMap.get(k)));

    this.router.navigate([], { queryParams, replaceUrl: true, relativeTo: this.route });
}
}
