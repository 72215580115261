import { Component, OnInit, Input, Output, EventEmitter, BootstrapOptions } from '@angular/core';
import { Globals } from 'src/app/globals';
import { User, Channel, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../user-interface';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import firebase from 'firebase/compat/app';
import 'firebase/storage';
import 'firebase/compat/storage';
import { doc, onSnapshot, DocumentReference } from "firebase/firestore";
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { Howl, Howler } from 'howler';
import { Time } from '@angular/common';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { TagInputModule } from 'ngx-chips';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Company, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';

export interface jVal {
  display: string;
  value: string;
}



@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.css', '../../common.scss']
})
export class ChannelsComponent implements OnInit {

  email: string;

  groupList: string[];

  newMsg: Message;

  channel: Channel;

  constructor(public global: Globals, private registerService: RegisterService) {

  }

  ngOnInit(): void {
    this.fetchChannels();

  }

  newChannel: boolean = false;
  saveChannel() {
    var self = this;
    var db = firebase.firestore();

    var groupRef = db.collection("msg_groups").doc();


    var channelRef = db.collection("users").doc(self.global.authuser.uid).collection("channels").doc();
    self.channel.createdAt = Date.now();
    self.channel.followers = 0;
    self.channel.id = channelRef.id;
    self.channel.groupId = groupRef.id;
    // Additional Channel data...

    channelRef.set(self.channel)
      .then(() => {
        console.log("Channel added.",);
        self.newChannel = false;

      })
      .catch((error) => {
        console.error("Error in channel;", error);
      });

  }

  addChannel() {
    this.newChannel = true;
    this.channel = {};

  }


  fetchChannels() {

    const db = firebase.firestore()
    const vm = this;

    db.collection('users')
      .doc(vm.global.authuser.uid.trim())
      .collection('channels')
      //    .orderBy('sentAt')
      .onSnapshot((querySnapshot) => {

        vm.global.authuser.channels = [];
        querySnapshot.forEach((doc) => {

          const data = <Channel>doc.data()
          data.id = doc.id
          vm.global.authuser.channels.push(data);

        })


      })

  }

  sendInvite(channel: Channel) {
    const db = firebase.firestore();
    var self = this;
    // check for email not added when button clicked
    const sentAt = Timestamp.now();
    var message: Message = {
      msg: "Thanks for following my channel.",
      sentAt,
      sentBy: self.global.authuser.uid
    }


    var members: string[] = [];
    var emails: string[] = [];
    var names: string[] = [];

    var emailList: string[] = [];
    self.groupList = [];

    self.groupList.push(this.email);
    if (self.groupList.length == 0) return;

    self.groupList.forEach(function (email: string) {
      if (ValidateEmail(email))  // Check on entry
        emailList.push(email);
    })
    self.groupList = [];

    if (emailList.length) {
      console.log(emailList);
      // Add me first
      members.push(self.global.authuser.uid);
      emails.push(self.global.authuser.email);
      names.push(self.global.authuser.firstName + " " + self.global.authuser.lastName);
      var emailGroup: msg_Groups;

      
      var dbRef = db.collection("users").where("email", 'in', emailList)
      dbRef
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("no Users not found here... ");
          }
          if (!querySnapshot.empty) {
            console.log("All found: ", querySnapshot.docs);

            querySnapshot.forEach((doc) => {
              var data = <User>doc.data();
              console.log("user: ", data);
              members.push(data.uid);
              emails.push(data.email);
              names.push(data.firstName + " " + data.lastName);

              emailGroup.members.push (doc.id);
              emailGroup.emails.push (data.email);
              console.log("EMAIL GROUP: ", emailGroup)
            });
          }



          // NOW FOR ANY EMAILS NOT FOUND LET'S INVITE USER TO CHAT
          emailList.forEach(function (email) {
            if (!emails.includes(email)) { // Auto-add
              const userRef = db.collection('users').doc();
              members.push(userRef.id);
              emails.push(email);
              names.push("");
              self.registerChatUser(email, userRef);

              emailGroup.members.push (userRef.id);
              emailGroup.emails.push (email);
              console.log("EMAIL GROUP: ", emailGroup)

              
            }
          })

       

          // Now Let's make a group IF not already Created


          const docRef1 = db.collection('msg_groups').doc(channel.groupId);

          docRef1.get()
          .then((doc) => {
            // let's get current Group and add new names
            if (doc.exists) {
              var data = <msg_Groups>doc.data();
              console.log("ffufiuy usdyf", data,data.members);
              members.forEach (function (item, index){
                if (!data.members.includes(item)) {
                  data.members.push(item);
                  data.names.push(names[index]);
                  data.emails.push(emails[index]);
                }
    
              })
       
              const group: msg_Groups = {
                members: data.members,
                names: data.names,
                emails: data.emails,
                id: docRef1.id,
                createdBy: self.global.authuser.uid,
                status: "Active"
              }
              console.log ("UPDATING GROUP ", group)
              docRef1.update(group)
                .then(() => {
                  if (message) {
                    self.saveMessage(message, channel.groupId)
                    self.sendNewGroupEmails(emailGroup, <Message>message);
                  }
                })
                .catch((error) => {
                  console.error("Error writing document: ", error);
                });

            }
            else {
              const createdAt = Timestamp.now();

              const group: msg_Groups = {
                channel: channel.title,
                members,
                names,
                emails,
                createdAt,
                //  createdBy: self.global.authuser.uid
              }

              console.log("START NEW GROUP CHAT", group)

              docRef1.set(group)
                .then(() => {
                  if (message) {
                    self.saveMessage(message, channel.groupId)
                    self.sendNewGroupEmails(emailGroup, <Message>message);
                  }
                })
                .catch((error) => {
                  console.error("Error writing document: ", error);
                });
            }
          })
        })
    }
  }



  registerChatUser(email, userRef: any) {


    this.registerService.RegisterChatUser(email, userRef);
  }

  saveMessage(msg: Message, GroupId: string) {
    const db = firebase.firestore();
    var self = this;


    const sentAt = Timestamp.now();
    if (msg.msg.trim() || msg.feature) {

      const message: Message = { ...msg };   // DEEP COPY


      console.log("SAVING..:", message);


      return new Promise((resolve, reject) => {
        db.collection('msg_messages')
          .doc(GroupId)
          .collection('allMessages')
          .add(message)
          .then(function (docRef) {
            console.log(" added", message);
            ////   self.updateGroupDateTime(currentGroupId);

            resolve(<Message>message);


            //   if (index != null) {
            //  self.userNotifyEmail(self.groups[index], <Message>message);
            //   }
            //else console.log("index is null ", message);

          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }

  sendNewGroupEmails(group: msg_Groups, newMsg: Message) {
    var self = this;
    // SEND Info EMAILS TO PEOPLE ADDED TO GROUP - NOT Including Creator

    console.log("email group ",group)
    var creator = group.members.findIndex(function (post, index) {
      if (post == newMsg.sentBy)
        return true;
    });


    // check group creator/msg sender

    group.members.forEach(function (email, i) {
      if (group.members[i] != newMsg.sentBy) {  // Don't send to creator
      var dCode = group.members[i].substring(0, 5);
      var templateParams = {
        from_name: group.names[creator],
        to_name: group.names[i],
        to_email: group.emails[i],
        message: newMsg.msg,
        msgDate: self.prettyDateTime(newMsg.sentAt),
        msgFrom: group.names[i],
        msgText: newMsg.msg,
        dCode: dCode,

      };
      if (group.storeID != group.members[i]) { }

      console.log("New group EMAIL: ", templateParams, creator);
      //  e.preventDefault();
      emailjs.send("service_jwders9", "template_dm7az4j", templateParams)
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
      }

    })


  }
  prettyDateTime(date: Timestamp): string {

    let d = new Date(date.toDate());
    return dateFormatter(d);
  }
}

function dateFormatter(date: Date) {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true
  };

  return " at " + TimeFormat(date.getTime()) + " on " + date.toLocaleDateString('en-US', timeformat);
}

function TimeFormat(time: number) {

  var d = new Date(time);
  let hours = d.getHours();
  // Determine AM or PM suffix based on the hour
  const suffix = (hours < 12) ? "am" : "pm";

  // Convert hour from military time
  hours = (hours < 12) ? hours : hours - 12;

  // If hour is 0, set it to 12
  hours = hours || 12;

  var min = d.getUTCMinutes();
  var minText = min.toString();
  if (min < 10) minText = "0" + min.toString();
  if (min == 0) minText = "00 ";


  return hours + ":" + minText + suffix;

}

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}
