<div class="container">

    <!-- USER REGISTERED AND STORE ADDED -->
    <div *ngIf="global.authuser && global.authuser.creds && global.myCompany">

        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Company info</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-form formName="masterCompanyInfo" [formInput]="global.myCompany" [autoSubmit]="false"
                        (submitEvent)="settingsOutput($event)"></app-form>

                        <app-appMaker appId="app-7884" [headLess]="true"></app-appMaker> 
                </ng-template>
            </p-accordionTab>
            <p-accordionTab *ngIf="global.myCompany.enabled.publicApps" iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Google info</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="!global.myCompany.hasGoogleInfo">
                        <button (click)="registerService.FetchGoogleInfo()" class="function" pButton pRipple type="button">
                            Get Google Info
                        </button>
                    </div>

                    <div *ngIf="global.myCompany.hasGoogleInfo">
                        <button (click)="getGoogleDays($event)" class="save-text" pRipple>Copy days & hours open from
                            Google Info</button>
                    </div>

                </ng-template>
            </p-accordionTab>
            <p-accordionTab *ngIf="global.myCompany.enabled.publicApps" iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Days & Hours</div>
                </ng-template>
                <ng-template pTemplate="content">


                    <app-form formName="masterDaysHours" [formInput]="global.myCompany.daysHours" [autoSubmit]="false"
                        (objectEvents)="daysHoursOutput($event)"></app-form>

                </ng-template>
            </p-accordionTab>
            <p-accordionTab iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Departments</div>
                </ng-template>
                <ng-template pTemplate="content">


                    <app-form formName="masterDepartmentSettings" [formInput]="global.myCompany" [autoSubmit]="false"
                        (submitEvent)="departmentsOutput($event)"></app-form>

                </ng-template>
            </p-accordionTab>
            <p-accordionTab *ngIf="global.myCompany.enabled.manageStaff" iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Staff</div>
                </ng-template>
                <ng-template pTemplate="content">

                    <app-managestaff></app-managestaff>
                </ng-template>
            </p-accordionTab>
            <p-accordionTab *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.chatService"
                iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Customer Chat</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div>
                        <app-chat-settings></app-chat-settings>
                    </div>
                </ng-template>
            </p-accordionTab>

            <p-accordionTab
                *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.reservations && global.myCompany.reserveSettings && global.myCompany.reserveSettings.reserveDay"
                iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Reservation Settings</div>
                </ng-template>
                <ng-template pTemplate="content">
                    move to Reservations.
                    <div>
                        <app-reservesettings></app-reservesettings>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>


        <!--

        <app-container [settings]="storeSettings" [list]="storeList" [api]="true" [style]="style"></app-container>

   

        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
            
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="pretty-setting">QR Codes</div>
                </ng-template>

                <ng-template pTemplate="content">
                    <img [src]="getJoinCode()" alt="QR code to join" title="" />


                </ng-template>
            </p-accordionTab>

            

        </p-accordion>
        -->
    </div>

    <!--   Need a better way to verify user creds  -->
    <div *ngIf="!entryFront && global.authuser && global.authuser.creds && global.myCompany">
        <p-accordion (onOpen)="goFront()" expandIcon=" false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="pretty">Front of House</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <li><a routerLink="/front" routerLinkActive="active">go</a>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>