import { Category } from './components/category-interface';
import firebase from 'firebase/compat/app';
import { Activity } from './components/user-interface';
import { Timestamp } from "firebase/firestore";


export function GridSize(item: Category, shape, size) {
var cols, rows;

    console.log (shape, size);
    if (!shape) shape = 'square';
    if (!size) size = 2;

if (!shape || shape == "square") {
    if (size == 1) {
      cols = 1;
      rows = 1;
    }
    if (size == 2) {
      cols = 2;
      rows = 2;
    }

    if (size == 4) {
      cols = 4;
      rows = 4;
    }
  }
  if (shape == "wide") {
    if (size == 1) {
      cols = 2;
      rows = 1;
    }
    if (size > 1) {
      cols = 4;
    rows = 2;
    }
  }
  if (shape == "very wide") {
    if (size == 1) {
      cols = 3;
      rows = 1;
    }
    if (size > 1) {
      cols = 4;
      rows = 1;
    }
  }      
  if (shape == "tall") {
    if (size == 1) {
      cols = 1;
      rows = 2;
    }
    if (size == 2) {
      cols = 2;
      rows = 4;
    }

    if (size > 2) {
      cols = 4;
      rows = 8;
    }
  }
  if (shape == "very tall") {
    if (size == 1) {
      cols = 1;
      rows = 3;
    }
    if (size == 2) {
      cols = 2;
      rows = 6;
    }

    if (size > 2) {
      cols = 4;
      rows = 12;
    }
  }
  item.rows = rows;
  item.cols = cols;
}

export function Logger (type, uid, storeID, info1?, info2?, info3?) {
  var self = this;


// SKIP ME
//  if (uid=="kvT6eKFxFHb3EKbVeCTusW86CrZ2") return;

  var db = firebase.firestore();

  if (!info1) info1="";
  if (!info2) info2="";
  if (!info3) info3="";
  
  const stamp = firebase.firestore.Timestamp.now();

  var time = stamp.toMillis();


  db.collection("activity").doc().set({
    createStamp: stamp,
    timeMs: stamp.toMillis(),
    timeStr: stamp.toDate().toLocaleTimeString(),
    dateStr: stamp.toDate().toLocaleDateString(),
    storeID: storeID,
    uid: uid,
    type: type,
    info1: info1,
    info2: info2,
    info3: info3,
    dateTime: Date.now()

  })
    .then(() => {
      // Here we can safely remove
      console.log("Log  written!");
    })
    .catch((error) => {
      console.error("Error writing log: ", error);
    });

}

export function getNiceDate(date: Timestamp) {

  let d = new Date(date.toDate());

  //    console.log(d);
  return (dateFormatter(d));
  //   return d.getMonth()+" "+d.getDay()+" "+d.getFullYear();

}

export function dateFormatter(date: Date, time?: boolean) {
 // const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  var timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  if (time) {
    timeformat.timeStyle = 'short'
  //  timeformat.hour12 = true;
  //  timeformat.minute = "2-digit";
 
    return date.toLocaleString ('en-US');
  }

  // date.toDateString();


  return date.toLocaleDateString('en-US', timeformat);

}

