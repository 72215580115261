<div *ngIf="p">

    <div *ngIf="!p.style || p.style=='Tall Image'" class="card" [style.height]="getMyProdHeight(p)" fxFlex
        fxLayout="row" fxLayoutAlign="center center">
        <div *ngIf="p.media && p.media.length" class="card__img-container">

            <div
                *ngIf="p.media[0].fileType =='image/png' || p.media[0].fileType=='photo' || p.media[0].fileType=='image/jpeg'">

                <img [style.object-fit]="getImageFit(p)" [style.object-position]="getImagePos(p)"
                    [style]="getMediaHeight(p)" src={{p.media[0].url}} alt="">

            </div>
            <div *ngIf="p.media[0].fileType=='video' || p.media[0].fileType=='non-image' || p.media[0].fileType=='video/mp4' || p.media[0].fileType=='video/quicktime'"
                class="video-container">
                <video playsinline webkit-playsinline autoplay muted loop [style.max-height]="getMyProdHeight(p)"
                    [style.object-fit]="getImageFit(p)" [style.object-position]="getImagePos(p)" id="myVideo">
                    <source src="{{p.media[0].url}}" type="video/mp4">
                </video>

            </div>


        </div>

        <div class="card__content">
            <div fxLayout="column" fxLayoutAlign="center center" style="width:100%">
                <div class="card__title">{{p.title}}</div>

                <div class="card__subtitle">{{p.subTitle}}</div>

            </div>
            <div *ngIf="p.allTags" fxLayout="row wrap" fxLayoutAlign="center center">
                <div *ngFor="let c of p.allTags; let i2 = index">
                    <p-chip class="card__tag">{{c}}</p-chip>
                </div>
            </div>



            <div *ngIf="p.safeHtml">
                <div class="card__desc" [innerHTML]="p.safeHtml"></div>
            </div>

            <article *ngIf="!p.safeHtml">
                <p class="card__desc">{{p.fullDescription}}</p>
            </article>


            <div *ngIf="!p.type || (p.type && p.type=='Product')">
                <p class="card__price">{{p.retail | currency}}</p>

            </div>

            <div *ngIf="addToCart" fxLayoutAlign="center center">

                <button class="add-to-cart" pRipple (click)="cartService.addItemToTicket(p)"><span
                        style="margin:auto">Add
                        item to Cart</span></button>
            </div>
        </div>


    </div>


    <div *ngIf="p.style && p.style=='Wide Image'" class="card" fxLayout="column" fxLayoutAlign="space-between center"
        style="height:max-content">

        <div *ngIf="p.media && p.media.length" class="card-wide__img-container">

            <div
                *ngIf="p.media[0].fileType =='image/png' || p.media[0].fileType=='photo' || p.media[0].fileType=='image/jpeg'">

                <img [style.object-fit]="getImageFit(p)" [style.object-position]="getImagePos(p)"
                    [style]="getMediaHeight(p)" src={{p.media[0].url}} alt="">

            </div>
            <div *ngIf="p.media[0].fileType=='video' || p.media[0].fileType=='non-image' || p.media[0].fileType=='video/mp4' || p.media[0].fileType=='video/quicktime'"
                class="video-container">
                <video playsinline webkit-playsinline autoplay muted loop [style.max-height]="getMyProdHeight(p)"
                    [style.object-fit]="getImageFit(p)" [style.object-position]="getImagePos(p)" id="myVideo">
                    <source src="{{p.media[0].url}}" type="video/mp4">
                </video>

            </div>
        </div>

        <div class="card__content">
            <div>
                <h2 class="card__title">{{p.title}}</h2>
                <p class="card__subtitle">{{p.subTitle}}</p>
                <div *ngIf="p.allTags" fxLayout="row wrap" fxLayoutAlign="center none">
                    <div *ngFor="let c of p.allTags; let i2 = index">
                        <p-chip class="card__tag">{{c}}</p-chip>
                    </div>
                </div>
            </div>

            <div *ngIf="p.safeHtml" class="card__desc">
                <div [innerHTML]="p.safeHtml"></div>
            </div>

            <article *ngIf="!p.safeHtml" class="card__desc">
                <p>{{p.fullDescription}}</p>
            </article>

            <div *ngIf="!p.type || (p.type && p.type=='Product')" style="text-align: center;">
                <p class="card__price">{{p.retail | currency}}</p>

            </div>
            <!-- Options -->
            <div *ngIf="addToCart && p.allOptions && optionFields && p.allOptions.length>0">
                <h3>Select options</h3>
                <div *ngFor="let option of p.allOptions; let x = index">

                    <app-field [field]="optionFields[x]" [binding]="" [showSubmit]="false" [editMode]="false"
                        (outputs)="optionOutput($event)"></app-field>

                </div>
                <p style="margin-bottom: 8px"></p>
            </div>

            <div *ngIf="addToCart" fxLayoutAlign="center center">

                <button class="add-to-cart" pRipple (click)="cartService.addItemToTicket(p)"><span
                        style="margin:auto">Add to cart</span></button>
            </div>
        </div>
    </div>

    <div *ngIf="p.style && p.style=='Minimal'" class="card-minimal" fxLayout="row" fxLayoutAlign="space-between center"
        [style]="getMyMinProdHeight(p)">

        <div *ngIf="p.media && p.media.length" class="card-minimal__img-container">

            <div
                *ngIf="p.media[0].fileType =='image/png' || p.media[0].fileType=='photo' || p.media[0].fileType=='image/jpeg'">

                <img [style.object-fit]="getImageFit(p)" [style.object-position]="getImagePos(p)"
                    [style]="getMediaHeight(p)" src={{p.media[0].url}} alt="">

            </div>
            <div *ngIf="p.media[0].fileType=='video' || p.media[0].fileType=='non-image' || p.media[0].fileType=='video/mp4' || p.media[0].fileType=='video/quicktime'"
                class="video-container">
                <video playsinline webkit-playsinline autoplay muted loop [style.max-height]="getMyProdHeight(p)"
                    [style.object-fit]="getImageFit(p)" [style.object-position]="getImagePos(p)" id="myVideo">
                    <source src="{{p.media[0].url}}" type="video/mp4">
                </video>

            </div>


        </div>





        <h2 class="card-minimal__title">{{p.title}}</h2>
        <p class="card__subtitle">{{p.subTitle}}</p>
        <div *ngIf="!p.type || (p.type && p.type=='Product')" style="justify-items: right;">
            <p class="card-minimal__price">{{p.retail | currency}}</p>
        </div>

        <div *ngIf="addToCart" fxLayoutAlign="center center">

            <button class="add-to-cart" pRipple (click)="cartService.addItemToTicket(p)"><span
                    style="margin:auto">Add</span></button>
        </div>
    </div>





    <div *ngIf="p.reserveLink">
        <button class="action-button3" (click)="makeReservation()"
            style="font-size:1.2rem; color: white; cursor: pointer; background-color:rgb(193, 187, 237)">
            Make Reservation</button>
    </div>

    <div
        *ngIf="allowEdits && global.authuser && global.authuser.creds && global.authuser.creds[0].id == global.myCompany.id">
        <div *ngIf="!editingProduct">
            <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                (click)="EditProduct(p)">Edit This Product</div>
        </div>

        <div *ngIf="editingProduct && editingProduct==p">
            <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                (click)="EditProduct(p)">Finish editing this product</div>
        </div>

        <div *ngIf="editingProduct && editingProduct==p">
            <app-edit-product [editProduct]="p" [editNav]="n"></app-edit-product>
        </div>
    </div>
</div>