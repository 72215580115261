import { Component, Output, Input, HostListener, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import { Company, Sheet, MergeData, CellData, SheetSettings, RowColSettings, Calendar, Theme, Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { MediaService } from "../media//media-functions/media-functions"
import { Logger } from 'src/app/functions';
import { FormService, removeUndefined } from '../form.service'
import { set, isToday, compareAsc, startOfDay, getTime, setDate, setMonth, setYear, getYear, addMonths, subMonths, getMonth, getDaysInMonth, startOfMonth, getDay, endOfDay, getMilliseconds, isPast } from "date-fns";
import * as math from 'mathjs';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.css']
})
export class SheetComponent implements OnInit {
  @Input() sheet: Sheet;

  editing: 'base' | 'sheet' | 'col' | 'row' | 'cell' | 'tool' | 'none';
  editingRow: number;
  editingCol: number;
  editingCell: { row: number, col: number }
  displayGroup: string[] = ["sheet"];
  rowCol: RowColSettings = {}
  insertObject: boolean = false;
  settingsForm: Form;
  rangeMode: boolean = false;
  range: { row: number, col: number }[];
  rangeField: Field;
  moving: boolean = false;
  sheetSettings: SheetSettings;
  sheetForm: Form;

  sheetTheme: Theme;
  //totalWidth;
  width;

  enterCounter = 0;

  @HostListener('document:keydown.enter')
  onDocumentKeydownEnter() {

    this.enterCounter++;
    console.log("Hit Enter! ", this.enterCounter)
    this.enterKey(null, 'down')
  }

  constructor(public global: Globals,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {


  }

  ngOnInit(): void {
    this.sheetTheme;
    this.sheetSettings = { rows: 10, cols: 10, cellHeight: 32, cellWidth: 100 }

    this.editing = 'base';
    this.displayGroup = ['base', 'width', 'height']

    if (!this.sheet) this.sheet = { sheetSettings: this.sheetSettings } //
    else {
      this.sheetSettings = this.sheet.sheetSettings;
    }
    //  if (typeof(this.sheet.sheetSettings.headers)=='undefined') 
    this.sheet.sheetSettings.headers = true;

    // Let's run the evauluator
    // Update all other fields

    this.createSheet();

  }

  initSettings(form: Form) {
    this.settingsForm = form;
    console.log("FORM INITIALIZED ", form)
  }

  createSheet() {
    var self = this;
    var f: Field;

    var tw = 50 + (self.sheetSettings.cellWidth * (self.sheetSettings.cols));
    if (!self.sheet.sheetSettings.headers) tw -= 50;

    self.sheetForm = { name: "Sheet", themeId: "hp24fAiGo6o5VnzKw57N", width: "100%", showSubmit: false, noSubmit: true, fields: [] };
    var cw = self.sheetSettings.cellWidth + 'px';
    var ch = self.sheetSettings.cellHeight + 'px';
    if (self.sheet.sheetSettings.headers) {
      var rowNames = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', "Z", 'AA', 'BB', 'CC', 'DD']

      f = { type: 'button', label: "-----", buttonStyle: 'cell', width: "50px", height: "25px", noSave: true, blurButton: "None", title: 'sheetSelect', }
      self.sheetForm.fields.push(f);
      for (var col = 0; col < self.sheetSettings.cols; col++) {
        f = { type: 'button', label: rowNames[col], cellStyle: {}, buttonStyle: 'cell', width: cw, height: "25px", noSave: true, blurButton: "None", title: 'colSelect', }
        if (self.editing == "col" && self.editingCol == col) {
          f.highlight = true;
        }
        self.getMyStyle(f, -1, col)
        self.sheetForm.fields.push(f);
      }
    }

    // Sort row Col data by timeStamp
    if (self.sheet?.rowColSettings?.length)
      self.sheet.rowColSettings.sort(function (a, b) {
        if (!a?.timeStamp || b?.timeStamp) {
          //   console.log("BAD TimeStamp: ", a, b)
          return 0;
        }
        return a.timeStamp - b.timeStamp;
      });
    console.log("SORTED settings ", self.sheet.rowColSettings)

    /* Create grid of fields */
    for (var row = 0; row < self.sheetSettings.rows; row++) {
      for (var col = 0; col < self.sheetSettings.cols; col++) {
        if (col == 0 && self.sheet.sheetSettings.headers) {
          f = { type: 'button', label: row.toString(), buttonStyle: 'cell', width: "50px", height: ch, noSave: true, blurButton: "None", title: 'rowSelect', }
          self.getMyStyle(f, row, -1);// Maybe need special function here

          self.sheetForm.fields.push(f);
        }
        f = { type: 'cell', width: cw, height: ch, buttonStyle: 'cell', blurButton: "None", title: ((row * 1000) + col).toString() }
        self.getMyData(f, row, col);
        self.getMyStyle(f, row, col)


        self.sheetForm.fields.push(f);
      }
    }
    // Now Add the Merged Fields
    if (self.sheet.mergeData)
      self.sheet.mergeData.forEach(function (range) {
        self.mergeFields(range);
      })

    console.log("Create Sheet ", self.sheet, self.sheetForm.fields, self.editingRow)

    // Now Update all data
    self.sheetForm.fields.forEach(function (field) {
      self.fieldEvaluate(field);
    })

  }

  gridStyle() {
    var self = this;

    var str = "";
    str +=
      "display: grid;"
    if (!self.sheet.sheetSettings.headers)
      str += "grid-template-columns: ";
    else
      str += "grid-template-columns: 50px  ";


    for (var n = 0; n < self.sheet.sheetSettings.cols; n++) {
      if (self.sheet?.rowColSettings) {
        const colIndex = self.sheet.rowColSettings.findIndex(function (post) {
          if (post.col == n && post.cellWidth)
            return true;
        });
        if (colIndex != -1) {
          str += self.sheet.rowColSettings[colIndex].cellWidth + 'px '
        }
        else
          str += self.sheet.sheetSettings.cellWidth + 'px '
      }
      else {
        str += self.sheet.sheetSettings.cellWidth + 'px '
      }
    }
    if (!self.sheet.sheetSettings.headers)
      str += ";grid-template-rows:  "

    else
      str += ";grid-template-rows: 25px "
    for (var n = 0; n < this.sheet.sheetSettings.rows; n++) {
      if (self.sheet?.rowColSettings) {
        // Set Height for row settings if present
        const rowIndex = self.sheet.rowColSettings.findIndex(function (post) {
          if (post.row == n && post.cellHeight)
            return true;
        });
        if (rowIndex != -1) {
          //   if (n==0) str += self.sheet.rowColSettings[rowIndex].cellHeight + 'px '
          str += self.sheet.rowColSettings[rowIndex].cellHeight + 'px '
        }
        else {
          //    if (n==0) str += '25px '; // default height
          str += self.sheet.sheetSettings.cellHeight + 'px '
        }
      }
      else {
        //   if (n==0) str += '25px '; // default height
        str += self.sheet.sheetSettings.cellHeight + 'px '
      }
    }
    str += "; grid-gap: 0px 0px;"

    //console.log ("GRIDSTYLE ", str)
    return str;
  }

  settingsEvents(field: Field) {
    var self = this;
    console.log("Settings field ", field)

    switch (field.title) {
      case 'width':

        break;
      case 'insert':
        self.insertObject = !self.insertObject;
        break;
      case 'move':
        self.moving = !self.moving;
        break;
      case 'format':
        if (self.displayGroup.includes('format')) {

          const index = self.displayGroup.findIndex(p => p == 'format')
          self.displayGroup.splice(index, 1)
        }

        else
          self.displayGroup.push('format')
        break;
        case 'clearFormatting':
          if (self.editing == 'cell') {
            const fieldIndex = self.calcFieldIndex(row, col);
  
            var rowColIndex = self.sheet.rowColSettings.findIndex(function (post) {
              if (post.row == self.editingCell.row && post.col == self.editingCell.col)
                return true;
            });
  
            if (rowColIndex != -1) {
              self.sheet.rowColSettings.splice(rowColIndex,1)
            }  
          }
  
          self.saveSheet();
          self.createSheet();
  
          return;

        break;
      case 'clear':
        console.log("Clear: ", self.editing, self.range, self.editingCell)

        // Sheet, row, col, area
        if (self.editing == 'sheet') {
          self.sheet.sheetData = []

          /*    self.sheet.sheetData.forEach(function (cell) {
                cell.binding = undefined;
                cell.value = undefined;
              }) */
        }
        else if (self.editing == 'col') {
          self.sheet.sheetData.forEach(function (cell, index) {
            if (cell.col == self.editingCol) {
              self.sheet.sheetData.splice(index, 1)
              //  cell.binding = undefined;
              //  cell.value = undefined;
            }
          })
        }
        else if (self.editing == 'row') {
          self.sheet.sheetData.forEach(function (cell, index) {
            if (cell.row == self.editingRow) {
              self.sheet.sheetData.splice(index, 1)

              //    cell.binding = undefined;
              //    cell.value = undefined;
            }
          })
        }
        else if (self.range?.length == 2) {

          for (var row = self.range[0].row; row <= self.range[1].row; row++) {
            for (var col = self.range[0].col; col <= self.range[1].col; col++) {
              const fieldIndex = self.calcFieldIndex(row, col);

              self.sheetForm.fields[fieldIndex].binding = undefined;
              self.sheetForm.fields[fieldIndex].value = undefined;

              //   const celRef = (row * 1000 + col).toString();
              var cellData = self.sheet.sheetData.find(function (post) {
                if (post.row == row && post.col == col)
                  return true;
              });

              if (cellData) {
                cellData.binding = undefined;
                cellData.value = undefined;
              }

            }
          }

        }
        if (self.editing == 'cell') {
          const fieldIndex = self.calcFieldIndex(row, col);

          var cellData = self.sheet.sheetData.find(function (post) {
            if (post.row == self.editingCell.row && post.col == self.editingCell.col)
              return true;
          });

          if (cellData) {

            cellData.type = undefined;
            cellData.binding = undefined;
            cellData.value = undefined;
          }

          //  self.sheetForm.fields[fieldIndex].binding = undefined;
          //  self.sheetForm.fields[fieldIndex].value = undefined;
        }

        self.saveSheet();
        self.createSheet();

        return;

        break;
      case 'merge': // Yeah baby!
        // New Merge Creation
        if (!self.sheet.mergeData) self.sheet.mergeData = []
        var rangeStart = { row: self.range[0].row, col: self.range[0].col }
        var rangeEnd = { row: self.range[1].row, col: self.range[1].col }
        self.sheet.mergeData.push({ rangeStart, rangeEnd })

        // Highlight chosen cell
        self.mergeFields({ rangeStart: self.range[0], rangeEnd: self.range[1] });


        self.saveSheet();

        return;

        break;
      case 'selectRange':
        self.rangeMode = true;
        self.editing = 'cell';
        self.range = [];
        self.rangeField = field;
        console.log("CHOOSE RANGE ")
        return;
        break;
    }
    // At this point all Functions checked so it's okay to save
    if (self.editing == 'sheet' || self.editing == 'base') {
      self.formService.napkinFieldToObject(field, self.sheetSettings)
      console.log("Settings ", field, self.sheetSettings)
      self.saveSheet();
      self.createSheet();
    }
    if (self.editing == 'cell') {
      var rowSet: RowColSettings = self.editingCell;
      self.formService.napkinFieldToObject(field, rowSet)
      rowSet.timeStamp = Date.now();
      if (!self.sheet.rowColSettings) self.sheet.rowColSettings = []

      const cellIndex = self.sheet.rowColSettings.findIndex(function (post) {
        if (post.col == rowSet.col && post.row == rowSet.row)
          return true;
      });

      if (cellIndex == -1)
        self.sheet.rowColSettings.push(rowSet)
      else
        self.sheet.rowColSettings[cellIndex] = Object.assign(self.sheet.rowColSettings[cellIndex], rowSet)

      self.saveSheet();
      console.log("Row Settings ", field, rowSet)
      self.createSheet();
    }

    if (self.editing == 'row') {
      var rowSet: RowColSettings = { row: self.editingRow }
      self.formService.napkinFieldToObject(field, rowSet)
      rowSet.timeStamp = Date.now();
      if (!self.sheet.rowColSettings) self.sheet.rowColSettings = []
      const index = self.sheet.rowColSettings.findIndex(x => x.row === self.editingRow)
      if (index == -1)
        self.sheet.rowColSettings.push(rowSet)
      else
        self.sheet.rowColSettings[index] = Object.assign(self.sheet.rowColSettings[index], rowSet)

      self.saveSheet();
      console.log("Row Settings ", field, rowSet)
      self.createSheet();
    }
    if (self.editing == 'col') {
      var rowSet: RowColSettings = { col: self.editingCol }
      self.formService.napkinFieldToObject(field, rowSet)
      rowSet.timeStamp = Date.now();
      if (!self.sheet.rowColSettings) self.sheet.rowColSettings = []
      const index = self.sheet.rowColSettings.findIndex(x => x.col === self.editingCol)
      if (index == -1)
        self.sheet.rowColSettings.push(rowSet)
      else {
        self.sheet.rowColSettings[index] = Object.assign(self.sheet.rowColSettings[index], rowSet)
      }
      self.saveSheet();
      console.log("Col Settings ", field, rowSet)
      self.createSheet();
    }

  }

  calcFieldIndex(row, col) {
    var self = this;
    var headerOffset = 1;
    if (!self.sheet.sheetSettings.headers) headerOffset = 0;

    return (self.sheetSettings.cols + headerOffset) +
      (row * (self.sheetSettings.cols + headerOffset)) +
      (col + headerOffset)
  }

  mergeFields(range: MergeData) {
    var self = this;

    // calc the cols & rows merging
    const cols = range.rangeEnd.col - range.rangeStart.col + 1;
    const rows = range.rangeEnd.row - range.rangeStart.row + 1;
    const newWidth = self.sheetSettings.cellWidth * cols;
    const newHeight = self.sheetSettings.cellHeight * rows;

    console.log("MERGING... ", cols, rows, newWidth, newHeight)

    var first = true;
    for (var row = range.rangeStart.row; row <= range.rangeEnd.row; row++) {
      for (var col = range.rangeStart.col; col <= range.rangeEnd.col; col++) {
        const fieldIndex = self.calcFieldIndex(row, col);
        //    console.log(self.sheetForm.fields, fieldIndex)

        if (first) {
          var finalWidth = newWidth;
          var finalHeight = newHeight;
          // Now check rowColSettings for custom width/height
          if (self.sheet?.rowColSettings)
            self.sheet.rowColSettings.forEach(function (item) {
              if (item.col >= range.rangeStart.col && item.col <= range.rangeEnd.col) {
                if (item.cellWidth)
                  finalWidth += item.cellWidth - self.sheetSettings.cellWidth;
              }
              if (item.row >= range.rangeStart.row && item.row <= range.rangeEnd.row) {
                if (item.cellHeight)
                  finalHeight += item.cellHeight - self.sheetSettings.cellHeight;
              }
            })
          console.log("Size Adjusted ", finalWidth, finalHeight)

          self.sheetForm.fields[fieldIndex].width = finalWidth + 'px'
          self.sheetForm.fields[fieldIndex].height = finalHeight + 'px'
          first = false;
        }
        else {
          self.sheetForm.fields[fieldIndex].width = '0px';
          self.sheetForm.fields[fieldIndex].height = '0px';
          self.sheetForm.fields[fieldIndex].display = false;
        }
      }
    }
  }

  enterKey(field: Field, move?: string) {
    var self = this;
    var cellData: CellData;

    if (!field) {
      field = self.sheetForm.fields.find(bob => bob.fieldEditing == true)
      console.log("FOUND FIELD EDITING ", field)
    }

    if (field) {
      field.fieldEditing = false;
      field.highlight = false;
      self.fieldEvaluate(field);
      // Now let's create/edit cellData
      var cell = parseInt(field.title)
      var row = Math.trunc(cell / 1000);
      var col = Math.trunc(cell % 1000);
      cellData = {
        row: row,
        col: col,
        binding: field.binding,
        lastEval: Date.now()
      }
      if (field.value) {
        cellData.value = field.value;
      }
      if (!self.sheet.sheetData) self.sheet.sheetData = [];
      var found = self.sheet.sheetData.find(function (post) {
        if (post.row == row && post.col == col)
          return true;
      });
      var dataChanged = false;
      if (found) { // replace
        if (found.binding != cellData.binding) {
          found = Object.assign(found, cellData); //Yikes!
          console.log("Replace cell")
          dataChanged = true;
        }
      }
      else {
        if (cellData?.binding?.length) {
          self.sheet.sheetData.push(cellData);
          dataChanged = true;
        }
      }
      console.log("CELL DATA: ", cellData, self.sheet.sheetData)
      if (dataChanged) {
        // Update all other fields
        self.sheetForm.fields.forEach(function (field) {
          self.fieldEvaluate(field);
        })

        self.saveSheet();
      }
    }

    if (move == 'down' && cellData?.row >= 0 && field.type != 'cellQuery') {
      console.log("Cell data ", cellData)
      // Now move down one row (or over one col?)
      var newRow = cellData.row + 1;

      const fieldIndex = self.calcFieldIndex(newRow, cellData.col)
      self.sheetForm.fields[fieldIndex].highlight = true;
      self.sheetForm.fields[fieldIndex].fieldEditing = true;
      self.displayGroup = ['cell', 'tool']
      self.editingCell = { row: newRow, col: cellData.col }
      self.updateSettings('cell', newRow, cellData.col);
      self.editing = 'cell'
    }
  }

  objectSelect(event) {

    console.log("You Selected an object ", event)

  }

  addSubmitEvent(form) {
    var self = this;

    console.log("Add Object ", form, this.editingCell)

    const index = form.fields.findIndex(p => p.title == 'function')

    const fieldIndex = self.calcFieldIndex(self.editingCell.row, self.editingCell.col)
    //     (self.sheetSettings.cols + 1) +
    //     (self.editingCell.row * (self.sheetSettings.cols + 1)) + (self.editingCell.col + 1)

    var found = self.sheet.sheetData.find(function (post) {
      if (post.row == self.editingCell.row && post.col == self.editingCell.col)
        return true;
    });
    switch (form.fields[index].binding) {
      case 'Math Query':
        if (found) {
          found.type = 'cellQuery';
          found.width = self.sheetForm.fields[index].width;
          found.height = self.sheetForm.fields[index].height;
        }
        else {
          var f: CellData = { type: 'cellQuery', row: self.editingCell.row, col: self.editingCell.col }
          f.width = self.sheetForm.fields[index].width;
          f.height = self.sheetForm.fields[index].height;
          self.sheet.sheetData.push(f)
        }
        self.saveSheet();
        self.createSheet();


        break;
      case 'Media: Create with AI':
        var query = form.fields.find(p => p.title == 'query').binding;
        console.log("MEDIA AI ", query)

        var f1;
        if (found) {
          found.type = 'displayImage';
          found.binding = "Generating Media";
          found.width = self.sheetForm.fields[index].width;
          found.height = self.sheetForm.fields[index].height;
          f1 = found;
        }
        else {
          f1 = { type: 'displayImage', binding: "Generating Media", row: self.editingCell.row, col: self.editingCell.col }
          f1.width = self.sheetForm.fields[index].width;
          f1.height = self.sheetForm.fields[index].height;
          self.sheet.sheetData.push(f1)
        }

        self.formService.createImageAI(query, null, f1, self.gotImage, self)
        break;

      case 'Media: Upload/Capture':
        console.log("MEDIA UPLOAD ")

        if (found) {
          found.type = 'media';
        }
        else {
          self.sheet.sheetData.push({ type: 'media' })

        }
        break;
      case 'NapkinApp':

        var insert = form.fields.find(p => p.title == 'createInsert').binding;
        var pickApp = form.fields.find(p => p.title == 'pickApp').binding;
        console.log("Add Napkin App ", insert, pickApp)

        if (found) {
          found.type = 'napkinApp';
          found.binding = pickApp;
          found.width = self.sheetForm.fields[index].width;
          found.height = self.sheetForm.fields[index].height;
        }
        else {
          var f: CellData = { type: 'napkinApp', binding: pickApp, row: self.editingCell.row, col: self.editingCell.col }
          f.width = self.sheetForm.fields[index].width;
          f.height = self.sheetForm.fields[index].height;
          self.sheet.sheetData.push(f)
        }
        self.saveSheet();
        self.createSheet();

        break;

      case 'Report':
        var report = form.fields.find(p => p.title == 'report').binding;
        console.log("Add Report", report)

        if (found) {
          found.type = 'report';
          found.binding = report;
          found.width = self.sheetForm.fields[index].width;
          found.height = self.sheetForm.fields[index].height;
        }
        else {
          var f: CellData = { type: 'report', binding: report, row: self.editingCell.row, col: self.editingCell.col }
          f.width = self.sheetForm.fields[index].width;
          f.height = self.sheetForm.fields[index].height;
          self.sheet.sheetData.push(f)
        }
        self.saveSheet();
        self.createSheet();

        break;
      case 'Query':
        var query = form.fields.find(p => p.title == 'query').binding;
        console.log("Add Query", query)

        if (found) {
          found.type = 'query';
          found.binding = query;
          found.width = self.sheetForm.fields[index].width;
          found.height = self.sheetForm.fields[index].height;
        }
        else {
          var f: CellData = { type: 'query', binding: query, row: self.editingCell.row, col: self.editingCell.col }
          f.width = self.sheetForm.fields[index].width;
          f.height = self.sheetForm.fields[index].height;
          self.sheet.sheetData.push(f)
        }
        self.saveSheet();
        self.createSheet();

        break;




      case 'Weather':
        var adr = form.fields.find(p => p.title == 'location').binding;

        if (found) {
          found.type = 'weather';
          found.binding = adr;
          found.width = self.sheetForm.fields[fieldIndex].width;
          found.height = self.sheetForm.fields[fieldIndex].height;
        }
        else {
          var f: CellData = { type: 'weather', binding: adr, row: self.editingCell.row, col: self.editingCell.col }
          f.width = self.sheetForm.fields[fieldIndex].width;
          f.height = self.sheetForm.fields[fieldIndex].height;
          self.sheet.sheetData.push(f)
        }
        self.saveSheet();
        self.createSheet();
        break;
      case 'Map':
        // Look for address:
        var adr = form.fields.find(p => p.title == 'location').binding;

        if (found && adr?.length > 4) {
          found.type = 'map';
          found.binding = adr;

          found.width = self.sheetForm.fields[fieldIndex].width;
          found.height = self.sheetForm.fields[fieldIndex].height;

          self.geoCode(adr, self.editingCell.row, self.editingCell.col)

        }
        else {
          var f: CellData = { type: 'map', binding: adr, row: self.editingCell.row, col: self.editingCell.col }
          f.width = self.sheetForm.fields[fieldIndex].width;
          f.height = self.sheetForm.fields[fieldIndex].height;

          self.sheet.sheetData.push(f)
          self.geoCode(adr, self.editingCell.row, self.editingCell.col)

        }

        break;
    }

  }

  fieldEvents(field: Field) {
    var self = this;

    console.log("Cell event ", field, self.rangeMode)

    if (self.rangeMode) {
      if (typeof (parseInt(field.title)) == 'number') {
        var cell = parseInt(field.title)
        var row = Math.trunc(cell / 1000);
        var col = Math.trunc(cell % 1000);
        self.range.push({ row, col });
        console.log("Range: ", self.range)

        if (self.range.length == 1) {
          // Highlight chosen cell
          const fieldIndex = self.calcFieldIndex(row, col);
          //    (self.sheetSettings.cols + 1) +
          //    (row * (self.sheetSettings.cols + 1)) + (col + 1)
          self.sheetForm.fields[fieldIndex].highlight = true;

        }

        if (self.range.length >= 2) {
          self.rangeMode = false;
          self.rangeField.trueFalse = false;
          // FIX DATA
          if (self.range[0].col > self.range[1].col) {
            const a = self.range[0].col;
            self.range[0].col = self.range[1].col;
            self.range[1].col = a;
          }
          if (self.range[0].row > self.range[1].row) {
            const a = self.range[0].row;
            self.range[0].row = self.range[1].row;
            self.range[1].row = a;
          }
          self.displayGroup.push('range')

          self.sheetForm.fields.forEach(function (field) {
            var cell = parseInt(field.title)
            var row = Math.trunc(cell / 1000);
            var col = Math.trunc(cell % 1000);
            if (row >= self.range[0].row && col >= self.range[0].col &&
              row <= self.range[1].row && col <= self.range[1].col) {
              field.highlight = true;
            }
          })
        }
      }
      return;
    }
    else {
      self.range = [];
    }
    // Now disable all other fields
    if (field.fieldEditing) // A Blur has occurred on data entry
      self.enterKey(field, ""); // save the data
    else { // Not a blur but a selection - 
      var n = Number(field.title)
      if (field.title != 'sheetSelect' && field.title != 'rowSelect' && field.title != 'colSelect' && typeof (n) == 'number') {
        self.sheetForm.fields.forEach(function (field) {
          field.highlight = false;
        })


        field.highlight = true;
        field.fieldEditing = true;
        self.displayGroup = ['cell', 'tool']
        var cell = parseInt(field.title)
        var row = Math.trunc(cell / 1000);
        var col = Math.trunc(cell % 1000);
        console.log("Edit: ", field.title, row, col)
        self.editingCell = { row: row, col: col }
        self.editing = 'cell'
        self.updateSettings('cell', row, col)
      }
    }

    switch (field.title) {
      case "sheetSelect":
        if (self.editing == 'sheet') {
          self.editing = 'base';
          self.displayGroup = ['base', 'width', 'height']
          self.sheetForm.fields.forEach(function (field) {
            field.highlight = false;
          })

        }
        else {
          self.editing = 'sheet';
          self.displayGroup = ['sheet', 'base', 'width', 'height', 'tool']
          self.updateSettings('sheet')
          self.sheetForm.fields.forEach(function (field) {
            field.highlight = true;
          })
        }

        break;
      case "rowSelect":
        self.editing = 'row';
        self.editingRow = parseInt(field.label);
        self.editingCol = null;
        self.displayGroup = ['row', 'height', 'tool',]
        self.updateSettings('row', self.editingRow)
        self.sheetForm.fields.forEach(function (field) {
          var cell = parseInt(field.title)
          var row = Math.trunc(cell / 1000);
          var col = Math.trunc(cell % 1000);

          if (row == self.editingRow)
            field.highlight = true;
          else
            field.highlight = false;
        })
        break;
      case "colSelect":
        self.editing = 'col';
        var x = field.label.toLowerCase().charCodeAt(0)
        x -= ('a'.charCodeAt(0));
        self.editingCol = x;
        self.editingRow = null;
        self.displayGroup = ['col', 'width', 'tool']
        self.updateSettings('col', -1, x)
        self.sheetForm.fields.forEach(function (field) {
          var cell = parseInt(field.title)
          var row = Math.trunc(cell / 1000);
          var col = Math.trunc(cell % 1000);

          if (col == self.editingCol)
            field.highlight = true;
          else
            field.highlight = false;
        })
        break;
    }
  }

  updateSettings(type, row?: number, col?: number) {
    var self = this;
    if (type == 'sheet' && self.settingsForm) {
      self.formService.objectToNapkinFields(self.sheet.sheetSettings, self.settingsForm.fields)
    }
    if (self.sheet?.rowColSettings?.length) {
      if (type == 'col') {
        const index = self.sheet.rowColSettings.findIndex(p => p.col == col)
        if (index != -1)
          self.formService.objectToNapkinFields(self.sheet.rowColSettings[index], self.settingsForm.fields)
      }
      if (type == 'row') {
        const index = self.sheet.rowColSettings.findIndex(p => p.row == row)
        if (index != -1)
          self.formService.objectToNapkinFields(self.sheet.rowColSettings[index], self.settingsForm.fields)
      }
      if (type == 'cell') {
        const index = self.sheet.rowColSettings.findIndex(p => (p.row == row && p.col == col))
        if (index != -1)
          self.formService.objectToNapkinFields(self.sheet.rowColSettings[index], self.settingsForm.fields)
      }
    }

  }

  getMyStyle(f, row, col) {
    var self = this;
    var cell, row, col;
    var styles: RowColSettings[] = [];

    if (!self.sheet.sheetData || !self.sheet.rowColSettings) return;

    self.sheet.rowColSettings.forEach(function (style) {
      if (style?.col == col && style?.row == row)
        styles.push(style);
      if (style.row == row && typeof (style.col) == 'undefined')
        styles.push(style);
      if (style.col == col && typeof (style.row) == 'undefined')
        styles.push(style);
    })

    if (styles?.length == 0) return;

    if (styles.length > 1)
    styles.sort(function (a, b) {
      if (!a?.timeStamp || !b?.timeStamp) {
        return 0;
      }
      return b.timeStamp - a.timeStamp;
    })
    console.log ("Styles ", styles)
    // Now first array item is correct
    
    f.cellStyle = styles[0];

    if (styles[0].cellHeight)
      f.height = styles[0].cellHeight + 'px';
    if (styles[0].cellWidth)
      f.width = styles[0].cellWidth + 'px';

    return;

  }

  getMyData(f, row, col) {
    var self = this;

    if (!self.sheet.sheetData) return;

    var found = self.sheet.sheetData.find(function (post) {
      if (post.row == row && post.col == col)
        return true;
    });
    if (found) { // replace
      f.binding = found.binding;
      f.value = found.value;
      if (found.type)
        f.type = found.type;
      if (found.mapOptions)
        f.mapOptions = found.mapOptions;
      if (typeof (found.expanded) == 'boolean')
        f.expanded = found.expanded;
      if (found.media) {
        f.media = [found.media]
        console.log("MEDIA ", f, found)
      }

    }


  }
  // Patterns
  // +45*b5/4-c45
  // =sum(b1:b10)
  // =avg(45,45,45,45,56)
  // MODES: 
  // FUNC_NAME - GET ALL CHARS TO (
  // VAL/CELL
  // : RANGE
  // , ARRAY

  fieldEvaluate(field: Field) {
    var self = this;

// IF IT's a Math Query then just scan for cell references (b1,b5 or c0:d6)
    if (field.type=='cellQuery') {
      field.value = Math.random()*1000
      console.log ("RANDOM MATH ", field)
      return
    }

    if (field?.binding?.trim().startsWith('=')) {
      // It's a function
      var index = 1;
      var funcName = self.getName(field.binding, index)
      console.log("Function ", funcName)
      // Find the (
      var firstPos = field.binding.indexOf('(');
      var str = field.binding.slice(firstPos)
      var cellData = self.getCell(str, firstPos)
      var mathQ = funcName + cellData;
      console.log("Function Q: ", mathQ)
      self.mathEvaluate(field, mathQ)
      return;
    }

    if (field?.binding?.trim().startsWith('+')) {
      // We have some math
      // First search for any cell references
      var array = field.binding.split('')

      const base = 'a'.charCodeAt(0)
      console.log("EVAL: ", field, array)
      var mathQuery = '';
      var skip = 0;
      var lc1;

      array.forEach(function (item, index) {

        if (!skip) {
          var lc = item.toLowerCase();
          if (index < array.length - 1)
            lc1 = array[index + 1].toLowerCase();

          if (lc >= 'a' && lc <= 'z') { // Z99, AA99
            var col = lc.charCodeAt(0) - base;
            var numberOffset = 1;
            if (lc1 >= 'a' && lc1 <= 'z') { // Concatenate Col
              col = 26 + (lc1.charCodeAt(0) - base)
              numberOffset = 2;
            }
            var n1 = parseInt(array[index + numberOffset]);
            var n2 = parseInt(array[index + numberOffset + 1]);
            console.log("ROW: ", n1, n2)
            if (!isNaN(n1) && typeof (n1) == 'number') {
              var row = n1;
              skip = numberOffset + 1;

              if (!isNaN(n2) && typeof (n2) == 'number') { //append number
                row = n1 * 10 + n2;

                skip++;
              }
              console.log("ROW: ", n1, n2, row, col)
              var fieldIndex = self.calcFieldIndex(row, col)

              var data;
              if (!self.sheetForm.fields[fieldIndex].value)
                data = 0;
              else
                data = self.sheetForm.fields[fieldIndex].value;
              mathQuery += data;
              console.log("Cell reference ", col, row, data, mathQuery)


            }
          }
        }

        if (!skip)
          mathQuery += item;
        else skip--;
      })
      self.mathEvaluate(field, mathQuery)

      console.log("MATH QUERY: ", mathQuery)
    }
    else {
      const bob = parseFloat(field.binding)
      if (typeof (bob) == 'number')
        field.value = bob;
    }
  }


  getName(str: string, index) {
    var newStr: string = str.slice(index)
    var array = newStr.split('')
    var name = "";
    var adding = true;
    array.forEach(function (c) {
      if (adding && c.toLowerCase() >= 'a' && c.toLowerCase() <= 'z')
        name += c;
      else adding = false;
    })
    return name;

  }
  // Convert range or list of data or cells into list of values
  // B1:b6,c5,78,
  getCell(str, index) {
    var self = this;
    var array = str.split('')
    var mathQuery = "";

    const base = 'a'.charCodeAt(0)

    var skip = 0;
    var lc1;
    var range1: { row: number, col: number };
    var range2: { row: number, col: number };

    array.forEach(function (item, index) {
      if (!skip) {
        var lc = item.toLowerCase();
        if (index < array.length - 1)
          lc1 = array[index + 1].toLowerCase();

        if (lc >= 'a' && lc <= 'z') { // Z99, AA99
          var col = lc.charCodeAt(0) - base;
          var numberOffset = 1;
          if (lc1 >= 'a' && lc1 <= 'z') { // Concatenate Col
            col = 26 + (lc1.charCodeAt(0) - base)
            numberOffset = 2;
          }
          var n1 = parseInt(array[index + numberOffset]);
          var n2 = parseInt(array[index + numberOffset + 1]);
          console.log("ROW: ", n1, n2)
          if (!isNaN(n1) && typeof (n1) == 'number') {
            var row = n1;
            skip = numberOffset + 1;

            if (!isNaN(n2) && typeof (n2) == 'number') { //append number
              row = n1 * 10 + n2;

              skip++;
            }

            console.log("Cell Data: ", array[index + skip])
            if (array[index + skip] == ':') { // RANGE of Cells
              range1 = { row, col }
            }
            else if (range1) {
              // now we have range 1,2
              range2 = { row, col }
              mathQuery += self.addRange(range1, range2)
            }
            else {
              var fieldIndex = self.calcFieldIndex(row, col)
              var data;
              if (!self.sheetForm.fields[fieldIndex].value)
                data = 0;
              else
                data = self.sheetForm.fields[fieldIndex].value;
              mathQuery += data;
            }
          }
        }
      }
      if (!skip) {
        console.log("Char: ", item)
        if (item != ':')
          mathQuery += item;
      }
      else skip--;
    })
    console.log("Function ", mathQuery)
    return mathQuery;
  }

  addRange(range1, range2) {
    var self = this;
    var str = ""

    for (var row = range1.row; row <= range2.row; row++) {
      for (var col = range1.col; col <= range2.col; col++) {

        var fieldIndex = self.calcFieldIndex(row, col)
        var data;
        if (!self.sheetForm.fields[fieldIndex].value)
          data = 0;
        else
          data = self.sheetForm.fields[fieldIndex].value;
        str += data + ","

      }
    }
    if (str.endsWith(",")) str = str.slice(0, str.length - 1)
    return str;

  }


  mathEvaluate(field: Field, query) {
    console.log("Evalulate event ", query)
    const bob = math.evaluate(query)
    console.log("Evalulate event ", query, bob)
    if (bob?.entries) {
      field.evaluated = bob.entries;
      console.log("IT'S AN ARRAY ", bob)
      field.value = bob[bob.length - 1];
    }
    else
      field.value = bob;
  }

  saveSheet() {
    var self = this;
    //Update the settings or data
    var db = firebase.firestore();

    // Add temp name ...
    if (!self.sheetSettings?.name)
      self.sheetSettings.name = "NapkinSheet-" + Math.floor(Math.random() * 9989)

    console.log("SAVING...", self.sheet)
    if (!self.sheet.id) {
      var docRef = db.collection("company").doc(self.global.myCompany.id).collection("sheets")
        .doc()
      self.sheet.id = docRef.id;
      self.sheet.uid = self.global.authuser.uid;
      if (self.global.myCompany)
        self.sheet.companyId = self.global.myCompany.id;
      self.sheet.name = self.sheetSettings.name;
      self.sheet.sheetSettings = self.sheetSettings;
      self.sheet = removeUndefined(self.sheet)
      if (self.sheet.rowColSettings)
        self.sheet.rowColSettings.forEach(function (item) {
          //nothing
        })


      docRef.set(self.sheet).then(() => {
        console.log("sheet added");
      })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
    else {
      self.sheet.name = self.sheetSettings.name;
      self.sheet.sheetSettings = self.sheetSettings;

      self.sheet = removeUndefined(self.sheet)
      var docRef = db.collection("company").doc(self.global.myCompany.id).collection("sheets")
        .doc(self.sheet.id)

      docRef.update(self.sheet).then(() => {
        console.log("sheet updated");
        //      if (self.displayGroup.includes ('format')){
        //        self.settingsForm.fields.forEach(function(field){
        //          if (field.title=='format') field.display = false;
        //        })
        //      }

      })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }


  }
  updateLatLong(lat: number, long: number, row, col) {
    var self = this;

    if (lat) {
      const cellIndex = self.sheet.sheetData.findIndex(p => (p.row == row && p.col == col))
      self.sheet.sheetData[cellIndex].mapOptions = {
        center: [long, lat],
        zoom: 14,
        attributionControl: false,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
      }
      console.log("Got map ", cellIndex)
      self.saveSheet()
    }
  }
  // MOVE CODE BELOW TO SERVICE
  gotImage(self, code, prompt, response, fileName, modelDetails, cost, size) {
    console.log("We got your answer, Bob ", code, prompt, fileName, response.savedImage)

    // self.setValue(code.values[0], self.form, response.savedImage)

    var newMedia: Media = {
      //   fileId?: string;
      name: fileName,
      altDesc: "image desc",
      url: response.savedImage,
      prompt: prompt,
      modelDetails: modelDetails,
      cost: cost,
      height: size.height,
      width: size.width,
      //  size: file.size,
      tags: ["AI"],
      //  format?: string;
      fileType: "image/png",
      companyId: self.global.myCompany.id,
      store: self.global.myCompany.store,
      userId: self.global.authuser.uid,

      uploadId: self.global.authuser.uid, // who uploaded
      createdAt: Date.now(),
      updatedAt: Date.now(),
      updatedBy: self.global.authuser.uid

    }
    self.mediaService.addImageToCompany(newMedia)

    // Now add it to the Cell

    //const cellIndex = self.sheet.sheetData.findIndex(p => (p.row == row && p.col == col))
    code.media = newMedia;
    code.binding = "AI Generated"

    console.log("Got Image ", code)
    self.saveSheet()




  }

  geoCode(adr, row, col) {
    var self = this;
    console.log("GEOCODE ", adr, row, col)

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com'
      }
    };

    //'&city=' + city + '&state=' + state + '&postalcode=' + zip +

    fetch('https://forward-reverse-geocoding.p.rapidapi.com/v1/search?q=' + adr + '&accept-language=en&polygon_threshold=0.0', options)
      .then(response => response.json())
      .then(response => {
        console.log("GEO: ", response);
        if (response[0] && response[0].lat) {
          var lat: number = Number(response[0].lat);
          var lon: number = Number(response[0].lon);
          self.updateLatLong(lat, lon, row, col);
        }


      })
      .catch(err => console.error(err));


  }
}

