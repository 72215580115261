import { Injectable, NgZone } from '@angular/core';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';


@Injectable({
  providedIn: 'root'
})

export class NgAuthService {

  SignOut() {
      localStorage.removeItem('user');
      localStorage.removeItem('myCreds');
      localStorage.removeItem('follows');
      localStorage.removeItem('companyIndex');
      localStorage.removeItem('table');
      localStorage.removeItem('uid');

  }
}


