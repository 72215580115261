import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import { AngularFireModule } from '@angular/fire/compat';
//import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { environment } from '../../../environments/environment';
import firebase from 'firebase/compat/app';
import { User, Follows } from '../user-interface'
import { CartService } from '../menu/cart.service';
import { AccordionModule } from 'primeng/accordion';
import{ init } from '@emailjs/browser';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';


@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.css']
})
export class VerifyComponent implements OnInit {
  code: string = "";
  user: User;
  sid: string;
  auto: boolean = false;  // Should return to where they were //

  constructor(private route: ActivatedRoute,private router: Router) {

    const codeStr = route.snapshot.queryParamMap.get('v'); // this.name = 'testing'

// 3 digit key + sid

    this.code = codeStr.substring(0,3);
    this.sid = codeStr.substring(3,8);

    console.log("V-code: ", this.code);
    if (!this.code || this.code.length==0) {}
  }

  ngOnInit(): void {
    var self = this;

    firebase.initializeApp (environment.firebase);
    var db = firebase.firestore();
    init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER


  //  const id = localStorage.getItem('uid');
    // Verification can only occur on same device registered??
    // Does email need to have UID??
    // Final flow needs validation
    // IF - No stored User - auto login - just once or for 1 day or ???


    console.log ("ID ", this.sid);
    if (self.sid) { // Check if verification needed
      db.collection("users").where("sid", "==", this.sid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = <User>doc.data();
            self.user = data;

            // Now that we actually have the user let's process email validation
            if (!self.user.emailVerified) {
              if (self.user.keyCode == parseInt(self.code)) {
                var catRef = db.collection("users").doc(self.user.uid);
                if (catRef) {
                  catRef.update({
                    emailVerified: true
                  });
                    self.sendEmailVerified();
                    // Now Let's auto-login the user if needed
                    if (self.auto)
                      self.autoLogin();
                }
              }

            }
            else {
               // Now Let's auto-login the user
               self.autoLogin();
            }
          });
        })
        .catch((error) => {
          console.log("no documents: ", error);
        });


    }
  }

  autoLogin () {
    var self = this;
    self.router.navigate(['home'], { queryParams: { p: self.user.sid } });

  }

// ADD AUTO-PASSWORD to this email.
  // Also allow user to change password once verified
  public sendEmailVerified() {
    var self = this;

    // NEED TO LOAD STORE HACK HACK


    var templateParams = {
      from_name: 'Napkin Apps',  // Company Name
      to_name: self.user.firstName,
      to_email: self.user.email,
      code: self.user.keyCode, 
      sid: self.user.sid,
      pw: self.user.pw,
      message: 'Welcome to Napkin Apps.'
    };
 //   this.cartService.sendMsg("Verified email "+this.email, this.tableNameQR);


    console.log("EMAIL: ", self.user.firstName, self.user.email);
    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_j23qemn", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

}
