import { Component } from '@angular/core';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';


export interface Option {
  name: string;
  code: boolean;
}

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent {

  enabled: Option[];
  
  constructor(public global: Globals) {
    this.enabled = [
      { name: 'Enabled', code: true },
      { name: 'Not Enabled', code: false }
    ];

  }


  updateEnabled() {
    var self = this;

    console.log ("enabled: ",self.global.myCompany.enabled);

    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.global.myCompany.id);

    ref.update({
      enabled: self.global.myCompany.enabled,
    })
      .then(() => {
        console.log("Enabled updated: ");
      })
      .catch((error) => {
        console.error("Error writing enableds: ", error);
      });
  }

}
