import { Component, OnInit } from '@angular/core';
import { Order } from '../../order-interface';
import firebase from 'firebase/compat/app';
import { User } from '../../user-interface';
import { Company } from '../../company-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgAuthService } from "../../../ng-auth.service";
import { Observable } from 'rxjs';


@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {
  orders: Order[] = [];
  ordersObs: Observable<any[]>;
  user: User;
  companyID: string;
  selected: string;

  myCompany: Company = new Company();

  //dataSourceOrders: MatTableDataSource<Order>;

  constructor(private router: Router, public ngAuthService: NgAuthService) { }

  ngOnInit(): void {
    var self = this;


return; // Old code

    this.user.companyID = this.user.following[0].id;
    this.companyID = this.user.companyID; // TEMP
    this.selected = this.user.following[0].storeName;

  //  this.dataSourceOrders = new MatTableDataSource(this.orders);
  //  this.ordersObs = this.dataSourceOrders.connect();

    if (this.user) {
      self.GetCompany(this.companyID);
      self.GetOrders();

    }
  }

  GetOrders() { // GET ALL ORDERS auto-sort to NEW on top
    var db = firebase.firestore();
    var self = this;
    var index = 0; // 

    self.orders = [];

    db.collection("orders").where("companyID", "==", self.companyID).where("userID", "==", self.user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Order>doc.data();

          self.orders.push(data);

        });
        console.log(self.orders);
//        this.ordersObs = this.dataSourceOrders.connect();


      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  GetCompany(companyID: string) {
    var db = firebase.firestore();
    var self = this;
    var docRef = db.collection("company").doc(companyID);

    self.myCompany = new Company();
    docRef.get().then((doc) => {
      if (doc.exists) {
        self.myCompany = <Company>doc.data();
        const data = <Company>doc.data();
        console.log("image ", self.myCompany.logoImg);
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!", companyID);
      }
    }).catch((error) => {
      console.log("Error getting company:", error);
    });

  }


  dateTimeSort() {

    this.orders.sort(function (a, b) { return b.dateTime - a.dateTime; });

  }
  myPoints () {
    this.router.navigate(['my-points']); 

  }

  myOrders () {
    this.router.navigate(['my-orders']); 

  }
  navigateBack() {}
  navigateCrumb() {
    this.router.navigate(['menu']);
  }


  SwitchAdmin (){
    this.router.navigate (['admin']);
  }
  logout() { this.ngAuthService.SignOut(); }


}

function Company() {
  this.name = "";
  this.palette1 = [];
  this.address1 = "";
  this.address2 = "";
  this.primaryPhone = "";
  this.city = "";
  this.state = "";
  this.postal = "";
  this.logoImg = "";
}