<div class="container">
    <div *ngIf="global.myCompany && reviewAdded" style="text-align:center;">
        <div class="review">Thanks for leaving a review for<br></div>
        <div class="review-company">{{" "+global.myCompany.name}}</div>
        <br><br>
        <div *ngIf="quote">
            <div class="card__content">
                <article>
                    <div class="card__desc">
                        <blockquote>{{quote.text}}</blockquote>


                    </div>
                    <p class="author">{{quote.author}}</p>
                </article>
            </div>
        </div>
    </div>

    <div *ngIf="global.myCompany && !reviewAdded" style="text-align:center" fxLayout="column"
        fxLayoutAlign="center center">
        <div class="review">Leave a review for<br></div>
        <div class="review-company">{{" "+global.myCompany.name}}</div>

        <!--        <h1>Your server will earn $5 for your feedback!</h1> -->
        <div *ngIf="!global.authuser">

       <!--     <app-form [localStorage]="false" [formName]="'master-enter-email'" [pwrequired]="false"
                (submitEvent)="isEmailFound($event)"></app-form>
-->

                        <SignIn startText="Continue Review" [pwrequired]="false" (setContactEvent)="setContact($event)"></SignIn>
        </div>


        <div class="stars" fxLayout="row" fxLayoutAlign="space-between center">

            <img fxFlex="20%" [ngStyle]="{ 'background-color': getBackbroundColor(1) }" id="1star" (click)="star(1)"
                src="/assets/R-1.png" alt="image">
            <img fxFlex="20%" [ngStyle]="{ 'background-color': getBackbroundColor(2) }" id="2star" (click)="star(2)"
                src="/assets/R-1.png" alt="image">
            <img fxFlex="20%" [ngStyle]="{ 'background-color': getBackbroundColor(3) }" id="3star" (click)="star(3)"
                src="/assets/R-1.png" alt="image">
            <img fxFlex="20%" [ngStyle]="{ 'background-color': getBackbroundColor(4) }" id="4star" (click)="star(4)"
                src="/assets/R-1.png" alt="image">
            <img fxFlex="20%" [ngStyle]="{ 'background-color': getBackbroundColor(5) }" id="5star" (click)="star(5)"
                src="/assets/R-1.png" alt="image">
        </div>
        <br>
        <div class="review-container" fxLayout="column" fxLayoutAlign="center center">
            Please share your experience with others
            <textarea style="width:94%" pInputTextarea [rows]="6" [cols]="36" [autoResize]="true" id="comment" #inputRef
                type="text" (blur)="checkReview()" class="text-entry" [(ngModel)]="reviewContent">
            </textarea>

        </div>

        <div fxLayout="column" fxLayoutAlign="center center" style="text-align: center;">

            <div *ngIf="newReview.review_photos && newReview.review_photos.length>0" style="width:92%; margin: auto;">
                <swiper-container class="mySwiper" navigation="true" pagination-clickable="true">

                    <div *ngFor="let item of newReview.review_photos">

                        <!-- Slides -->
                        <swiper-slide>
                            <img [src]="item" style="width: 100%; display: block;" />
                        </swiper-slide>
                        <!--
                <div *ngIf="newReview.review_photos && newReview.review_photos[0]">
                    <img style="max-height: 400px; max-width: 100%;" src={{newReview.review_photos[0]}} />
                </div>
                    -->


                    </div>
                </swiper-container>
            </div>


            
            <div *ngIf="!global.myCompany.reviewSetup || !global.myCompany.reviewSetup.videoReviews">
           
                <button class="file-upload-button">
                    <input type="file" name="uploaded_file"
                    #fileInput
                    capture="environment" accept="image/*;"
                    (change)="upload($event)"
                    />
                </button>

            </div>
            <div *ngIf="global.myCompany.reviewSetup && global.myCompany.reviewSetup.videoReviews==true">
           
                <button class="file-upload-button">
                    <input type="file"
                    #fileInput
                    capture="environment" accept="image/*;"
                    (change)="upload($event)"
                    />
                </button>

            </div>

       

            <div *ngIf="newReview && newReview.media">
                <img style="max-height: 400px; max-width: 100%;" src={{newReview.media[0].url}} />
            </div>

        </div>

        <br>


        <div *ngIf="global.authuser" fxLayout="row" fxLayoutAlign="center center">
            <div (click)="submitReview()" pRipple class="submit-button">Submit feedback</div>
        </div>

        <div *ngIf="uploadingMedia==true" fxLayout="row" fxLayoutAlign="center center">
            <h1>Uploading Media</h1>
        </div>
        <br>
        <br>

    </div>

    <img src="./assets/reviewslogo.png" class="napkin-logo">

</div>