import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, Share, Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Chat, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { MediaService } from "../media//media-functions/media-functions"
import { Quote } from '../content-interface';
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css', '../../common.scss']
})
export class ChatbotComponent implements OnInit {
  @Input() chatBot: ChatBot;
  @Input() chatBotId: string;
  @Input() autoSave: boolean = true;
  @Input() userId: string;
  @Input() chat: any;
  @Input() headless: boolean = true;
  @Input() editing: boolean = true;
  @Input() access: string = 'user';
  @Input() accessValid: boolean = false;

  @Output() createCallback = new EventEmitter<any>();

  botId;
  waitingForAnswer: boolean = false;



  constructor(private route: ActivatedRoute, public global: Globals,
    public nav: NavService, private sanitizer: DomSanitizer,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {



  }

  ngOnInit(): void {
    var self = this;

    console.log("CHATBOT: ", this.chatBot, this.chatBotId)
    // THIS MODULE CAN BE REFERENCED VIA URL
    this.botId = this.route.snapshot.paramMap.get('botId')
    if (this.botId) {
      console.log("CHATBOT DIRECT: ", this.chatBot, this.chatBotId)

      this.headless = false; // turn on USER
      // direct entry so initialize environment
      firebase.initializeApp(environment.firebase);
      // Now let's load user so we know if they have access
      this.chatBotId = this.botId;
    }

    if (this.chatBotId) { // Load the bot
      this.chatBot =
      {
        id: "New",
        uid: this.userId,
        conversation: [],
        settings: { model: "gpt-3.5-turbo" }
      }
      this.LoadChat(this.chatBotId)
    }
    else if (!this.chatBot && !this.chatBotId) {
      this.chatBot =
      {
        id: "New",
        uid: this.userId,
        createdAt: Date.now(),
        createdBy: self.global.authuser.uid,
        editedAt: Date.now(),
        editedBy: self.global.authuser.uid,
        conversation: [],
        settings: { model: "gpt-3.5-turbo" }
      }
      this.accessValid = true;
      this.editing = true;
      this.access = "Admin"
    }
    // DEFAULT SETTINGS
    if (!this?.chatBot?.settings)
      this.chatBot.settings = { model: "gpt-3.5-turbo" }
    //    Logger("Chat Bot Started", "", "")

  }

  validateUser() {
    var self = this;

    console.log("Now check user validity ");
    if (self.chatBot?.share?.enableLink && self.chatBot.share.linkMode.toLowerCase() == 'anyone with link')
      self.accessValid = true;
    if (!self.chatBot?.share)
      self.accessValid = true;

    if (self?.chatBot?.share?.linkAccessRights)
      self.access = self.chatBot.share.linkAccessRights;

    if (self?.access.toLowerCase() == 'user')
      self.editing = false;

  }

  getResponseText(text) {
    return text;
  }
  outputEventShare(share: Share) {
    var self = this;

    //  self.formService.napkinFieldsToObject(form, self.chatBot.share, false)
    console.log("New share ", share, self.chatBot.share)
    self.chatBot.share = share;
    if (self.chatBot.id.length > 5) {
      // Update Chat settings.  (model is only for Next Chat, so don't save)
      self.UpdateChat();
    }


  }
  settingsOutputEvent(form: Form) {
    var self = this;
    // UPDATE TO USE napkinfieldtoobject

    form = removeUndefined(form)
    self.formService.napkinFieldsToObject(form, self.chatBot.settings, true)
    console.log("New Settings ", form, self.chatBot.settings)

    if (self.chatBot.id.length > 5) {
      // Update Chat settings.  (model is only for Next Chat, so don't save)

    }
  }

  fieldEvent(field: Field) {

  }

  outputEvent(form: Form) {
    var self = this;
    console.log("Chat got Input ", form)

    if (form.fields[0].title == "query" && form.fields[0]?.binding?.length > 3) {
      Logger("ChatBot Query", self.global.authuser.uid, "Storeid", form.fields[0].binding, self.chatBot.settings.model);

      self.formService.queryOpenAI(form.fields[0].binding, self.chatBot.settings.model, form.fields[0].binding, self.chatBot.conversation, self.gotAnswer, self)
      self.waitingForAnswer = true;
    }

  }
  getMyBotLink() {

    var str = "/bot/" + this.chatBot.id.toString()
    return str;

  }

  gotAnswer(self, query, response) {
    console.log("Got answer ", query, response.message)
    // Save answer locally and to DB
    // Can I set up a Joint/Shared AI Bot experience???? Yes I can...

    if (!self.chatBot?.conversation) self.chatBot.conversation = [];
    if (!self.chatBot?.usage) self.chatBot.usage = [];
    if (!self.chatBot.usageTotal) self.chatBot.usageTotal = 0;

    if (!self.chatBot.name)
      self.chatBot.name = query;
    var responseHtml = response.message.split('\n').join('<br>');

    self.chatBot.conversation.push({ role: "user", content: query })
    self.chatBot.conversation.push({ role: "assistant", content: responseHtml })
    self.chatBot.usage.push(response.usage)
    self.chatBot.usageTotal += Number(response.usage.total_tokens);
    if (self.autoSave)
      self.SaveChat()
    self.waitingForAnswer = false;
  }

  LoadChat(id) {
    var self = this;
    var db = firebase.firestore();
    var docRef = db.collection('chatBots').doc(id)

    docRef.get().then((doc) => {
      self.chatBot = <ChatBot>doc.data();
      self.validateUser();
      console.log("Chat loaded ", self.chatBot);
    })
      .catch((error) => {
        console.error("Error gettin bot", error);
      });

  }



  UpdateChat() {
    var self = this;
    var db = firebase.firestore();
    var docRef = db.collection('chatBots').doc(self.chatBot.id)
    
    self.chatBot.editedAt = Date.now();
    self.chatBot.editedBy = self.global.authuser.uid; 
    console.log("Chat Updated ", self.chatBot);

    docRef.update(self.chatBot).then(() => {
      console.log("Chat Updated ");
    })
      .catch((error) => {
        console.error("Error savin bot", error);
      });

  }


  SaveChat() {
    var self = this;
    var db = firebase.firestore();

    if (self.chatBot?.id?.length < 5) {
      var docRef =
        db.collection('chatBots')
          .doc()

      self.chatBot.id = docRef.id;
      self.chatBot.createdAt = Date.now();
      self.chatBot.createdBy = self.global.authuser.uid;
      self.chatBot.editedAt = Date.now();
      self.chatBot.editedBy = self.global.authuser.uid; 
      self.createCallback.emit({ id: self.chatBot.id, chat: self.chat, name: self.chatBot.name }) //Add msgId if I have it??
      docRef.set(self.chatBot).then(() => {
        self.chatBot.query = ""
        console.log("Chat Added ");
      })
        .catch((error) => {
          console.error("Error savin bot", error);
        });
    }
    else {
      var docRef =
        db.collection('chatBots').doc(self.chatBot.id)

        self.chatBot.editedAt = Date.now();
        self.chatBot.editedBy = self.global.authuser.uid; 
      console.log("Chat Updated ", self.chatBot);


      docRef.update(self.chatBot).then(() => {
        console.log("Chat Updated ");
        self.chatBot.query = "";
      })
        .catch((error) => {
          console.error("Error savin bot", error);
        });
    }
  }

}
function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}