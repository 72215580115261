<div class="pretty-center">
    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
        <div>
            <p-accordionTab [selected]="viewRewards">
                <ng-template pTemplate="header">
                    <div class="pretty-center">

                        <div *ngIf="!selectedReward">
                            Choose a freebie for making a reservation!
                        </div>
                        <div *ngIf="selectedReward">
                            Reservation Freebie:<br>{{selectedReward.title}}
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="pretty-center">
                    Your reservation helps us provide our best level of service. As a thank you, please select an item.
                </div>
                    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false" fxLayout="column"
                        fxLayoutAlign="none none" *ngFor="let item of rewardItems; let i = index">

                        <p-accordionTab [(selected)]="viewRewardTab[i]">
                            <ng-template pTemplate="header">
                                <div class="pretty-center" fxFlex fxLayout="column" fxLayoutAlign="none none">

                                    <div>{{item.title}}</div>
                                    <span *ngIf="item.groupExclusive" style="font-size:.8em">Group Discount</span>

                                </div>

                            </ng-template>

                            <ng-template pTemplate="content">
                                <div style="background: #f0f2f1">

                                    <!--      Freebie OPTIONS -->
                                    <div *ngIf="item.allOptions">

                                        <div *ngFor="let opt of item.allOptions; let i = index">
                                            <div class="pretty-center">{{opt.title}}</div>
                                            <div class="option-buttons" fxLayout="row wrap"
                                                fxLayoutAlign="center center">
                                                <p-selectButton (onChange)="gogogo(item, opt, $event)"
                                                    [options]="opt.list" [ngModel]="opt.chosen" optionLabel="">
                                                </p-selectButton>

                                            </div>


                                        </div>

                                    </div>
                                    <div>
                                        <button class="button-14" role="button" (click)="toggleSelect(item)">Select
                                            Reservation
                                            Freebie</button>
                                    </div>

                                    <div *ngIf="item.image1" fxLayout="column" fxLayoutAlign="center center">
                                        <img alt="salad" class="med-size-image" src={{item.image1}}>
                                    </div>
                                    <div class="pretty-center-blue" [innerHtml]="item.fullDescription"></div>
                                    <br>
                                </div>
                            </ng-template>
                        </p-accordionTab>

                    </p-accordion>
                </ng-template>
            </p-accordionTab>
        </div>
    </p-accordion>
</div>