

import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../cart.service';
import { CustomizeMessageService, MessageState } from '../../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../nav.service';
import { environment } from '../../../../environments/environment';
import { Table } from '../../product-int'
import { User } from '../../user-interface';
import { ViewportScroller } from "@angular/common";
import { HttpClient } from '@angular/common/http';
//import { MapGeocoder } from '@angular/google-maps';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Component({
  selector: 'app-addTable',
  templateUrl: './addTable.component.html',
  styleUrls: ['./reserve.component.css'],
  providers: [MessageService]

})


export class addTableComponent implements OnInit {
    authorizedUser: boolean = false;
    table: Table;
    blockMinMax: number[] = [45,90];
    selectGalleryVisible: boolean = true;
    rangeValues: number[] = [20,80];
    checked: boolean;

  constructor(
    public global: Globals,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public nav: NavService,
    public customizeMessageService: CustomizeMessageService,
    private scroller: ViewportScroller,

  ) {
    /*
    this.table  = {
      companyID: null,
      active: true,
      name: "uiiu",
      seats: 0,
      minPartySize: 0,
      maxPartySize:0,  
      
    }
    if (this.validCreds()) {
      this.table.companyID = this.global.myCompany.id;
    }
    */
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER

  }

  ngOnInit(): void {
    let self = this;



    console.log("Init addtable");

    if (this.global.authuser && this.global.myCompany && this.validCreds()) {
        console.log("Authorized user access");
        this.authorizedUser=true;
    }
    else {
        console.log("Not authorized.");
        return;
    }

    this.table  = {
      companyID: this.global.myCompany.id, 
      active: true,
      name: "Table for 2",
      seats: 2,
      minPartySize: 1,
      maxPartySize:2,  
      
    }

    //   console.log(this.global.myCompany.reserveSettings);
/*    if (this.global.authuser) {
    }
    else {
      // As ENTRY-POINT

      firebase.initializeApp(environment.firebase);


      // CHECK IF USER/DEVICE IS REGISTERED
      const id = localStorage.getItem('uid');
      if (id) {
        this.nav.GetUserwID(id);
      }
      else {
        this.nav.GetStore(this.global.store);
      }
    }
    */
  }

  dothis () {

  }

  validCreds () {
    // VALIDATE USER CREDS
    if (!this.global.myCompany) return false;
    const xid = this.global.myCompany.id;

    if (!this.global.authuser) return false;
    if (!this.global.authuser.creds) return false;
    var found = this.global.authuser.creds.find(function(post, index) {
      if(post.id == xid && (post.enabled.staff || post.role=='Creator') )
        return true;
    });
    return found;
  }

  saveTable(table: Table) {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("tables").doc();
    self.table.docID = ref.id;

    self.table.companyID = self.global.authuser.creds[0].id; //hack
    self.table.reservable = true;
    
    ref.set(self.table)
      .then(() => {
        console.log("Table successfully saved!");

      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

}