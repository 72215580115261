import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { Product, Options } from '../../components/product-int';
import { ButtonModule } from 'primeng/button';
import { Order } from '../order-interface';
import { CurrencyPipe } from '@angular/common'


@Component({
  selector: 'app-places',
  templateUrl: './places.component.html',
  styleUrls: ['./places.component.css', '../../common.scss']
})
export class PlacesComponent implements OnInit {
  @Input() store: Follows;
  @Input() style: string = "List1";   // Name of a style


  usedPerks: Coupon[];
  perks: Coupon[];
  showPerk: boolean = false;
  thisPerk: Coupon;
  thisPerkQR: string;
  thisProduct: Product;
  perkCompany: string;
  company: Company;
  availablePerks: Product[];

  closedOrders: Order[];
  viewTabItem: boolean[] = [];

  viewStore: boolean = false;
  viewRes: boolean = false;
  viewPerks: boolean = false;
  viewReviews: boolean = false;
  viewQRcodes: boolean = false;
  viewMedia: boolean = false;
  viewOrders: boolean = false;

  list: string[];
  list2: string[];
  tabOpen: boolean[];



  qrCodeAPI: string = "http://api.qrserver.com/v1/create-qr-code/?data=";

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller,
    private cp: CurrencyPipe,

  ) {

    this.list = ["Store", "Reservations", "Perks", "Orders", "Reviews"]
    this.list2 = ["Hello", "This", "Bob", "Ten", "Ruby"]

    this.tabOpen = [false,false,false,false,false];



  }
  /*
    ngOnChanges(): void {
      console.log("Front-Changes");
    }
  */

  ngOnInit(): void {
 //   this.GetRedeemable(this.store.id, this.store.points);
//    this.getPerks();
//    this.getOrders();
    if (this.store) this.getLogo(this.store.id);
    console.log("Store ", this.store);

  }

  openTab (index) {
    console.log ("TAB OPENED: ", index)

  }

  getBackColor(index) {
    if (this.tabOpen[index])
       return "#ffffff";

    return "#1a77f9"

  }

  getStyleWidth (index) {
    var style = "100%";

    if (this.style == "List1")
      style = "220px"

      if (this.tabOpen[index])
      return "100%"

   //   console.log ("Style: ", style)

    return style;

  }



  getFontColor(index) {
    if (this.tabOpen[index]) return "#000000";
    return "#ffffff";
  }

  getMyName(i, name) {
  //  console.log (i,name)
    if (name == "gostore") {
      if (this.viewStore) return "Close Store"
      else return "Open Store"

    }
  }

  getLeftMargin(i?: number) {

    if (i) return "0px";
    if (this.global.getScreenWidth > 900)
      return "20%";
    else if (this.global.getScreenWidth > 600)
      return "20%";
    else return "20%";

  }

  getRightMargin(i?: number) {

    if (i) return "0px";
    if (this.global.getScreenWidth > 900)
      return "20%";
    else if (this.global.getScreenWidth > 600)
      return "20%";
    else return "20%";
  }

  usePerk(perk: Product) {

    this.addPerkToUser(perk);

  }

  returnPoints(item: Coupon) {
    // remove Perk and restore points
    this.removePerk(item);

  }

  emailPerk(item: Coupon) {
    console.log("Emailing this perk to me.")


  }
  openPlaceTab (e) {
    console.log(e);
    // auto-scroll to the tab.
  //  this.scroller.scrollToAnchor("");


  }

  showQR(item: Coupon) {
    this.showPerk = true;
    // HACK should use ROOT URL not Home
    //  item.qrcodeURL = "http://api.qrserver.com/v1/create-qr-code/?data=" +"napkinhq.com/home/?e=" + item.couponCode + "&size=200x200";
    this.thisPerk = item;
    this.perkCompany = item.companyId;
    this.getProductFromCode(item.couponCode.substring(5, 12))
    console.log("This Perk ", this.thisPerk)

  }

  getPerks() {
    var self = this;

    this.perks = [];
    this.usedPerks = [];

    if (this.global.authuser.perks) {
      this.global.authuser.perks.forEach(function (item) {
        if (item.used) self.usedPerks.push(item);
        else self.perks.push(item);

      })
    }


  }

  getExtPrice(item: Order) {

    if (item.RewardPaid) {
      return " " + (item.RewardPaid) + " points";
    }
    else {
      item.extPrice = item.extPrice; //this.checkDiscounts(item.qty, item.price, item)

      // item.extPrice = item.qty * item.price;
      var formatedOutputValue = this.cp.transform(item.extPrice, 'USD', 'symbol', '1.2-2');
      return formatedOutputValue;
      //  return " $" + (item.extPrice);
    }

  }

  lineItem(order: Order) {

    if (order.qty == 1) return order.item;
    else if (order.price > 0) return order.item + " @" + order.price;
    else return order.item;

  }

  lineItemOptions(order: Order) {
    var myOptions = "";

    if (order.allOptions) {
      var oSet = 0;
      order.allOptions.forEach(function (arrayItem: Options) {
        if (arrayItem.chosen && arrayItem.type != 3) {
          myOptions += " " + arrayItem.chosen;
          //          console.log("added ",arrayItem.chosen);
          if (arrayItem.chosen.length) oSet++;
        }
        if (arrayItem.chosenList) {
          arrayItem.chosenList.forEach(function (optList) {
            myOptions += " " + optList;
            if (optList.length) oSet++;
          })


        }
      });
      if (oSet < order.allOptions.length) myOptions += " Select options";


    }
    return myOptions;
  }

  getLogo(companyID: string) {

    var db = firebase.firestore();
    var self = this;

    db.collection("company").where("id", "==", companyID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //  const data = <Company>doc.data();
          self.company = <Company>doc.data();
          self.global.myCompany = <Company>doc.data();

        })

      }).catch((error) => {
        console.log("Error getting logo:", error);
      });

  }



  // HACK HACK - Use single source for products
  GetRedeemable(companyID: string, points: number) {
    console.log("redeem Company ID ", companyID);

    var db = firebase.firestore();
    var self = this;
    console.log("get red ", companyID)
    self.global.redeem = self.global.redeem.splice(0, self.global.redeem.length);
    const docRef = db.collection('company').doc(companyID).collection("products")

  //  db.collection("products").where("companyID", "==", companyID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Product>doc.data();

          if (data.active && data.retail > 0 && data.redeemable && (data.retail < points / 10)) {
            if (data.companyID == companyID && data.active) {
              //  console.log (data);
              self.global.redeem.push(data); // PUSH IF ACTIVE
            }
          }

        })
        console.log("Red: ", self.global.redeem);
        self.findAvailablePerks();

      }).catch((error) => {
        console.log("Error getting redeemables:", error);
      });

  }

  findAvailablePerks() {
    var self = this;
    self.availablePerks = []
    self.global.redeem.forEach(function (item) {
      if (item.active && item.redeemable && item.retail * 100 < self.store.points) {
        self.availablePerks.push(item);
      }
    })
  }

  removePerk(item: Coupon) { // Add points back to user
    // Convert Product to Perk
    console.log("Removing perk ", item);

    var self = this;
    var db = firebase.firestore();

    const index = self.global.authuser.following.findIndex(element => element.id == this.store.id);
    const perkIndex = self.global.authuser.perks.findIndex(element => element.couponCode == item.couponCode);

    if (perkIndex != -1) {

      var ref = db.collection("users").doc(this.global.authuser.uid);

      // Add back Points Used
      if (item.points && item.points > 0)
        self.store.points += item.points;
      else if (item.retail)
        self.store.points += item.retail * 100;

      console.log("perk ", item, self.store.points);

      self.global.authuser.following[index].points = self.store.points;
      self.global.authuser.perks.splice(perkIndex, 1); // REMOVE PERK NOW

      ref.update({
        following: self.global.authuser.following,
        perks: self.global.authuser.perks
      })
        .then(() => {
          self.findAvailablePerks();
          self.perks = self.global.authuser.perks;
          console.log("perk moved to points");
        })
        .catch((error) => {
          console.error("Error saving perk", error);
        });
    }
  }

  getJoinCode() {

    return this.qrCodeAPI + 'napkinhq.com/join/' + this.global.myCompany.store + '&size=200x200';
  }


  addPerkToUser(item: Product) {

    // Convert Product to Perk

    var self = this;

    var code = this.global.authuser.uid.substring(0, 5) + item.sid;
    var newPerk: Coupon = {
      name: item.title,
      couponCode: code,
      storeNum: this.store.storeNum,
      companyId: this.store.id,
      storeName: this.store.storeName,
      created: Date(),
      validThru: "does not expire",
      desc: item.fullDescription,
      retail: item.retail,
      points: item.retail * 100,
      qrcodeURL: "http://api.qrserver.com/v1/create-qr-code/?data=" + "napkinhq.com/home/?e=" + code + "&size=200x200",

    }
    console.log("added perk. ", newPerk);

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    var ref = db.collection("users").doc(this.global.authuser.uid);
    if (!self.global.authuser.perks) self.global.authuser.perks = [];
    // var perks = self.global.authuser.perks;
    //  console.log(perks)
    //  perks.push(newPerk);
    self.global.authuser.perks.push(newPerk);

    // Subtract Points Used
    self.store.points -= item.retail * 100;

    const index = self.global.authuser.following.findIndex(element => element.id == this.store.id)

    if (index != -1) {
      self.global.authuser.following[index].points = self.store.points;
    }

    ref.update({
      following: self.global.authuser.following,
      perks: self.global.authuser.perks,
    })
      .then(() => {
        self.perks = self.global.authuser.perks // direct?
        self.findAvailablePerks();
        console.log("added perk. ", newPerk);

      })
      .catch((error) => {
        console.error("Error saving perk", error);
      });
  }

  getProductFromCode(prodSid: string) {

    if (this.global.allProducts.length) {
      const found = this.global.allProducts.find(element => element.sid == prodSid)
      if (found) {
        this.thisProduct = found;
        console.log("Product found: ", found);
      }

    }
    else {
      console.log("Products not loaded.")

      var self = this;
      var db = firebase.firestore();
/* HACK RECODE
      console.log("Searching PERKS ")
      const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").where("groupMenu", "==", true)

   //   db.collection("products").where("companyID", "==", self.perkCompany).where("sid", "==", prodSid)
        .get()
        .then((querySnapshot) => {

          querySnapshot.forEach((doc) => {
            var data = <Product>doc.data();
            console.log("FOUND product: ", prodSid, data);
            self.thisProduct = data;
          })
        })

        */

    }

  }

  getOrders() {
    var db = firebase.firestore();
    var self = this;
    var index = 0; // 

    console.log("getting orders ", self.store);
    db.collection("orders").where("userID", "==", self.global.authuser.uid).where("companyID", "==", self.store.id).where("status", "==", "Closed")
      .onSnapshot((querySnapshot) => {
        // console.log("New Order Data arrived ", querySnapshot.docs);
        self.closedOrders = [];

        querySnapshot.forEach((doc) => {
          const data = <Order>doc.data();
          //      console.log("Order: ", data);

          if (!data.note) data.note = "";

          let dateT = data.dateTime;
          let localOrder = <Order>data;
          localOrder.isExpanded = false;

     //     if (localOrder.tableName == "") localOrder.tableName = "No Table";
     //     localOrder.id = doc.id;
     //     if (localOrder.tableName != "No Table") {
            // if not showing Removed
            self.closedOrders.push(localOrder);
      //    }

        });
        console.log("New Data ", self.closedOrders)
        //        self.MakeOpenOrderList(self);
        self.closedOrders.sort(function (a, b) { return b.closedAt - a.closedAt });


        //      self.simplifyOrder();
        //   console.log("New Data");
        //     self.dataSourceNewOrder = new MatTableDataSource(self.orderView); // new order
        //      self.obsNewOrder = self.dataSourceNewOrder.connect();

      });




  }
  dateFormatter(dateMs: number) {

    var date = new Date(dateMs);


    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const timeformat: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false
    };
    // date.toDateString();


    return date.toLocaleDateString('en-US', timeformat);

  }

}


