<div class="layout">
    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="true" (onOpen)="openPlaceTab($event, i)"
        (onClose)="onCloseTab($event, i)" *ngFor="let tab of list; let i = index;">

        <p-accordionTab iconPos="end" [(selected)]="tabOpen[i]">
            <ng-template pTemplate="header">
                <div fxLayout="row" fxLayoutAlign="center none"
                    style="text-align:center;background-color: #e3d8ee55; color: 'black'; width:100%">
                    <div [ngStyle]="{ 'width': getStyleWidth(i)}" [style.background-color]="getBackColor(i)"
                        [style.color]="getFontColor(i)" class="place-feature2">
                        <span *ngIf="node && node?.objectType=='tabList'"> {{tab.title}}</span>
                        <span *ngIf="!node || node?.objectType!='tabList'"> {{tab}}</span>

                    </div>
                </div>


            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="node && node.containers" style="background-color: antiquewhite; height:100%">


                    <div style="width:100%; margin: auto;" fxFlex fxLayout="column" fxLayoutAlign="center center">
                        <div *ngFor="let n of global.appNodes[appId].nodes;">
                            <div *ngIf="containerNode(n, node.containers[i].parentId)">
                                <app-node-object [appLink]="app" [pageRoot]="pageRoot" [appId]="appId" [view]="true"
                                    [node]="n" [editing]="editing" (tabOpened)="openTab($event)"></app-node-object>
                            </div>
                        </div>

                    </div>



                </div>

                <div *ngIf="api">
                    <app-api [store]="store" [list]="list" [fields]="fields" [forms]="forms" [formName]="formName"
                        [form]="Form" [follows]="follows" [apiFunction]="tab"></app-api>
                </div>
                <div *ngIf="apiFunction=='Forms'">
                    <app-data [open]="false" [api]="tab"></app-data>
                </div>
                <div *ngIf="apiFunction=='VIP'">
                    <app-phaser-edit [sceneId]="sceneId" [open]='false' [api]="tab"
                        [VIPIndex]="VIPIndex"></app-phaser-edit>

                </div>
                <div *ngIf="apiFunction=='EditForm'">
                    <app-form-edit [formName]="tab" [open]="true" [form]="global.allForms[i]"
                        [formIndex]="i"></app-form-edit>
                </div>
                <div *ngIf="apiFunction=='Reports'">
                    <app-reports [reportName]="tab" [reportIndex]="i"></app-reports>
                </div>
                <div *ngIf="apiFunction=='Queries'">
                    <app-queries [editing]='true' [queryIndex]="i"></app-queries>
                </div>

                <div *ngIf="tab=='Fields' && apiFunction=='EditingForm'">
                    <app-field-edit [fieldName]="field" [form]="form" [formIndex]="formIndex"></app-field-edit>
                </div>
                <div *ngIf="tab=='Logic' && apiFunction=='EditingForm'">
                    <app-logic-editor [form]="form" [formLogic]="true" [logic]="form.logic"></app-logic-editor>
                </div>
                <div *ngIf="tab=='Form Settings' && apiFunction=='EditingForm'">
                    <app-form-settings [form]="global.allForms[formIndex]" [formIndex]="formIndex"></app-form-settings>
                </div>
                <div *ngIf="tab=='Database' && apiFunction=='EditingForm'">
                    <app-database [form]="global.allForms[formIndex]" [formIndex]="formIndex"></app-database>
                </div>
                <div *ngIf="tab=='Analytics' && apiFunction=='EditingForm'">
                    <div *ngIf="!submissions" fxLayout="column" fxLayoutAlign="none center" style="text-align: center;">
                        This Form is not saving data or gathering Analytics. Enable "Save Form Data" in Settings.
                    </div>
                </div>






            </ng-template>
        </p-accordionTab>
    </p-accordion>
</div>