export class Palette extends Phaser.Scene {
    self;
    masterSelf;
    draggingMe = false;
    fill: boolean = true;
    palette;
    swatchData;
    fillColor = new Phaser.Display.Color();
    color = new Phaser.Display.Color();

    constructor() {
        console.log("CREATE palette ")
        super({
            key: 'palette', active: true
        });

    }

    preload() {
        this.palette = this.load.image('palette', 'assets/gradient-palette.png');

    }

    create() {
        var self = this;
        const sceneB = <any>self.scene.get('VIP');
        this.masterSelf = sceneB.registry.get('masterSelf');

        const src = this.textures.get('dp').getSourceImage();
        this.swatchData = this.textures.createCanvas('swatch', src.width, src.height);
        this.swatchData.draw(0, 0, src);


        //   this.fill = this.registry.get('fill');
        console.log("Fill: ", this.fill)

        var x, y;
        var toolbarWidth = 210;

        x = 300;
        y = 440;

        const dragMe = self.add.rectangle(x, y, toolbarWidth, 20, 0x34def2, .75).setInteractive({ draggable: true });
        dragMe.setDisplayOrigin(0, 0)
        dragMe.setOrigin(0, 0)
        y += 20;

        const container = this.add.container(x, y);
        container.bringToTop(null);
        container.setAlpha(1)
        const background = self.add.rectangle(0, 0, toolbarWidth, 24, 0x000e12, 1).setInteractive();
        background.setDisplayOrigin(0, 0)
        background.setOrigin(0, 0)
        container.add(background);

        dragMe.on('dragstart', (pointer, dragX, dragY) => {
            this.draggingMe = true;
        });
        dragMe.on('dragend', (pointer, dragX, dragY) => {
            this.draggingMe = false;
        });

        dragMe.on('drag', (pointer, dragX, dragY) => {
            container.setPosition(dragX, dragY + 20);
            dragMe.setPosition(dragX, dragY);
        });

        this.input.on('pointerover', (event, gameObjects) => {
            console.log("Hit me")
            container.setScale(1);
            dragMe.setScale(1);
            container.setY(dragMe.y + dragMe.height)
            this.scene.bringToTop();

        });

        this.input.on('pointerout', (event, gameObjects) => {
            if (!this.draggingMe) {
                container.setScale(.25);
                dragMe.setScale(.25);
                container.setY(dragMe.y + (dragMe.height * .25))
            }

            // container.setDepth(9000);
        });

        x = 0; y = 0;

        const fillRect = self.add.rectangle(x, y, toolbarWidth / 2, 24);
        fillRect.setDisplayOrigin(0, 0)
        fillRect.setAlpha(1)
        fillRect.setOrigin(0, 0)
        container.add(fillRect)
        const outlineRect = self.add.rectangle(x + toolbarWidth / 2, y, toolbarWidth / 2, 24);
        outlineRect.setDisplayOrigin(0, 0)
        outlineRect.setAlpha(1)
        outlineRect.setOrigin(0, 0)
        container.add(outlineRect)


        var str = "fill:"
        if (this?.fill == false) {
            str += " none"
        } else str += 'FFAAB3'


        const str1 = self.add.text(x + 4, y + 4, str);
        str1.setDisplayOrigin(0, 0)
        str1.setAlpha(1)
        str1.setOrigin(0, 0)
        str1.name = 'fill'
        container.add(str1);
        y += 24;


        const swatch = self.add.image(x, y, 'palette').setInteractive();
        swatch.setDisplayOrigin(0, 0)
        swatch.setAlpha(1)
        swatch.setOrigin(0, 0)
        swatch.name = 'palette'
        container.add(swatch);

        swatch.on('pointerdown', (pointer, x, y, event) => {
            event.stopPropagation();
            var color2 = this.textures.getPixel(x,y, 'palette');
            fillRect.setFillStyle(color2.color)

            console.log("Clicked COLOR??",color2)
            self.masterSelf.setObjectFill(self.fill, color2)
            //  self.registry.set('currentImage', bob.name);

        })


    }


}