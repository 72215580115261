import { Component, ViewChild, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Field, Form, AppNode, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { Product, Options } from '../../components/product-int';
import { ButtonModule } from 'primeng/button';
import { Order } from '../order-interface';
import { CurrencyPipe } from '@angular/common'
import { Accordion } from 'primeng/accordion'
import { FormService } from '../form.service';


@Component({
  selector: 'app-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.css', '../../common.scss']
})
export class DatabaseComponent implements OnInit {
  @Input() form: Form;
  @Input() formIndex: number;
  @Input() formId: string;  // SHOULD HAVE THIS
  @Input() app: AppNode[];
  @Output() submitEvent = new EventEmitter<{ node: AppNode, form: Form }>();

  submissions: Form[];

  formNode: AppNode;
  display: string = "flat";  // or container


  ngOnInit(): void {
    var self = this;

    // Check for exiting Report in Form
    if (!this.form && !this.formId) {
      this.form = this.global.allForms[this.formIndex]
    }
    if (!this.form)
      this.form = this.global.allForms.find(p=>p.id==this.formId)
    if (this.form && !this.form?.report) {
      this.createDefaultReport ();
    }
    else console.log ("DATABASE: ", this.formId, this.form)

return;


    self.getSubmissions();
    if (self.app)
      self.app.forEach(function (node) {
        console.log("APP: ", self.app, self.formId, node);

        if (node.objectType == 'form' && node.formId[0] == self.formId) {
          self.formNode = node;
          console.log("Connection: ", node)
        }

      })
  }

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller,
    private cp: CurrencyPipe,
    private formService: FormService,

  ) {
  }

  getSubmissions() {

    var self = this;

    var db = firebase.firestore();
    if (!self.formId && self.form) {
      self.formId = self.form.id;
      if (!self.formId) self.formId = self.form.formId;
    }

    console.log("GETTING DATA: ", self.formId, self.app)
    db.collection("company").doc(this.global.myCompany.id).collection("forms")
      .doc(self.formId)
      .collection("submissions")

      .onSnapshot((querySnapshot) => {
        self.submissions = [];
        querySnapshot.forEach((doc) => {
          const data = <Form>doc.data()
          if (!data.deleted) {
            data.id = doc.id;
            data.fields.forEach(function (field) {  // fixer-upper
              if (!field.reportClass) field.reportClass = "report"

            })

            self.submissions.push(data)
          }
        })


        self.submissions.sort(function (a, b) {

          return b.timeStamp - a.timeStamp;


        });
        console.log("DATABASE Records; ", self.submissions);
     //??   self.scroller.scrollToAnchor("formtop");
      })





  }
  timeStamp(time) {

    return (TimeFormat(time))


  }


  //editForm: Form;

  // Edit This Form...
  loadFormData(e, form: Form) {
    var self = this;

    e.stopPropagation();

    // How to communicate from one node to another.

    let editForm = JSON.parse(JSON.stringify(form))

    self.global.allForms[self.formIndex] = { ...editForm };

    console.log("Form data loaded: ", form);
  }

  findMe(me: string, form: Form) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { alert("oops " + me) }
    return index;

  }

  findValue(me: string, form: Form) {
    me = me.toLowerCase();
    if (!me) return false;

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { return false }

    return form.fields[index].value;

  }

  createDefaultReport () {
    var self = this;

    this.form.report = {
      allowEdits: true,
      title: "Database",
      userId: this.global.authuser.uid, // probably should move to form creation
      fieldSource: {form: this.form.name, formId: this.form.id, includedFields:[]}
    }
    this.form.fields.forEach(function(field){
      self.form.report.fieldSource.includedFields.push(field.title)
    })
    console.log ("Auto Report ", this.form)
    this.formService.updateForm(this.form)

  }



}
function TimeFormat(time: number) {

  var d = new Date(time);
  let hours = d.getHours();
  // Determine AM or PM suffix based on the hour
  const suffix = (hours < 12) ? "am" : "pm";

  // Convert hour from military time
  hours = (hours < 12) ? hours : hours - 12;

  // If hour is 0, set it to 12
  hours = hours || 12;

  var min = d.getUTCMinutes();
  var minText = min.toString();
  if (min < 10) minText = "0" + min.toString();
  if (min == 0) minText = "00 ";


  return hours + ":" + minText + suffix;

}

