<p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">

<p-accordionTab>

    <ng-template pTemplate="header">
        <div class="pretty-setting">Reservation Settings</div>
    </ng-template>

    <ng-template pTemplate="content">
        <div *ngIf="global.myCompany.enabled.reservations">
            Great {{global.authuser.firstName}}.<br>
            Reservations are all set! Edit settings below.<br>
            Preview and make a reservation:
            <div class="URL" (click)="GotoReserve()">Make a Reservation</div>

            <qrcode [qrdata]="reserveQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>

        </div>
        <div style="display:flex; flex-direction: column;">
            <div
                style="display:flex; flex-direction: row; align-items:center; justify-content:space-between ; font-size: 1.3rem; margin-bottom:12px;">

                Time Block

                <p-dropdown [options]="allDurations"
                    [(ngModel)]="global.myCompany.reserveSettings.timeBlock" [autoWidth]="false"
                    [dropdownIcon]="false" placeholder="15 min" optionLabel="name">
                </p-dropdown>
            </div>
            <div style="flex-direction: row; font-size: 1.3rem;">
                Min party
                <p-inputNumber size="4" min="1" max="100" maxlength="3" class="integerInput"
                    [(ngModel)]="global.myCompany.reserveSettings.minParty" [showButtons]="true"
                    buttonLayout="horizontal" spinnerMode="horizontal" incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus">
                </p-inputNumber>
            </div>

            <div style="flex-direction: column; font-size: 1.3rem;">
                Max party
                <p-inputNumber size="4" min="1" max="100" maxlength="3" class="integerInput"
                    [(ngModel)]="global.myCompany.reserveSettings.maxParty" [showButtons]="true"
                    buttonLayout="horizontal" spinnerMode="horizontal" incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus">
                </p-inputNumber>
            </div>
            <div style="flex-direction: column; font-size: 1.3rem;">
                Max People
                <p-inputNumber size="4" min="1" max="100" maxlength="3" class="integerInput"
                    [(ngModel)]="global.myCompany.reserveSettings.maxOccupancy" [showButtons]="true"
                    buttonLayout="horizontal" spinnerMode="horizontal" incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus">
                </p-inputNumber>
            </div>

            <div style="flex-direction: column; font-size: 1.3rem;">
                Max Tables
                <p-inputNumber size="4" min="1" max="100" maxlength="3" class="integerInput"
                    [(ngModel)]="global.myCompany.reserveSettings.maxTables" [showButtons]="true"
                    buttonLayout="horizontal" spinnerMode="horizontal" incrementButtonIcon="pi pi-plus"
                    decrementButtonIcon="pi pi-minus">
                </p-inputNumber>
            </div>

            <div
                style="display:flex; flex-direction: row; align-items:center; justify-content:space-around ; font-size: 1.3rem; margin-bottom:12px;">

                Minimum advance notice

                <p-dropdown [options]="allAdvNotices"
                    [(ngModel)]="global.myCompany.reserveSettings.timeNotice" [autoWidth]="false"
                    [dropdownIcon]="false" placeholder="15 min" optionLabel="name">
                </p-dropdown>
            </div>

            <div
                style="display:flex; flex-direction: row; align-items:center; justify-content:space-between ; font-size: 1.3rem; margin-bottom:12px;">

                Reservation time gap

                <p-dropdown [options]="allTimeGaps" [(ngModel)]="global.myCompany.reserveSettings.timeSkip"
                    [autoWidth]="false" [dropdownIcon]="false" placeholder="15 min" optionLabel="name">
                </p-dropdown>
            </div>
            <div>

                <textarea #textarea1 [(ngModel)]="global.myCompany.reserveSettings.resPolicy" [rows]="5"
                    [cols]="30" pInputTextarea autoResize="autoResize" [autofocus]="true"></textarea>

            </div>

        </div>
        <button class="button-9" role="button" (click)="updateResSettings()">Save Changes</button>

    </ng-template>

</p-accordionTab>
</p-accordion>
