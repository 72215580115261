<div class="container" [style]="getFullWidth()">
    <div *ngIf="!mediaSource">
        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="true">


            <p-accordionTab *ngIf="currentMedia">
                <ng-template pTemplate="header">
                    <div class="user-feature">Current Media</div>
                </ng-template>
                <ng-template pTemplate="content">

                </ng-template>
            </p-accordionTab>

            <p-accordionTab iconPos="end" [(selected)]="tabSelected[0]" headerStyleClass="headers">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(0)">Media Groups</div>
                    </div>

                </ng-template>
                <ng-template pTemplate="content">
                    <button (click)="mediaService.addMediaGroup($event)" class="create-form" pButton pRipple type="button">
                        Create a new Media Group
                    </button>
                    <div fxLayout="column" fxLayoutAlign="center center" class="user-feature" [style]="getFullWidth()">

                        <p-accordion fxLayout="column" fxLayoutAlign="center center" expandIcon="false"
                            collapseIcon="false" [multiple]="true" *ngFor="let g of global.mediaGroups;">
                            <p-accordionTab iconPos="end" headerStyleClass="headers">
                                <ng-template pTemplate="header">
                                    <div class="user-feature">{{g.name}}</div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div fxLayout="column" fxLayoutAlign="center center" class="user-feature"
                                        [style]="getFullWidth()">

                                        <app-media-groups [appId]="appId" [editNode]="editNode"
                                            [group]="g"></app-media-groups>
                                    </div>
                                </ng-template>
                            </p-accordionTab>
                        </p-accordion>
                    </div>
                </ng-template>
            </p-accordionTab>

            <p-accordionTab iconPos="end" [(selected)]="tabSelected[1]">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">

                        <div class="user-feature" [style]="getStyle(1)">My Media</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center" class="user-feature"
                        [style]="getStyle(1)">

                        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="true">

                            <p-accordionTab iconPos="end" [(selected)]="viewSettings">
                                <ng-template pTemplate="header">
                                    <div style=" font-size: 1.2em; text-align: center;">Settings</div>
                                </ng-template>
                                <ng-template pTemplate="content">

                                    <div *ngIf="global?.myCompany?.googleBusinessInfo && global?.myCompany?.googleBusinessInfo.photo_count > 0"
                                        fxLayout="column">
                                        <button (click)="mediaService.FetchGooglePhotos()" class="function" pButton
                                            pRipple type="button">
                                            Get Google Photos [{{global?.myCompany?.googleBusinessInfo.photo_count}}]
                                        </button>
                                    </div>




                                    <div fxLayout="row wrap" fxLayoutAlign="center center">

                                        <div class="command">Show All</div>
                                        <div class="command">Video</div>
                                        <div class="command">Images</div>

                                        <div class="command">Sort by Date</div>
                                        <div class="command">Type & Date</div>
                                    </div>
                                    <div fxLayout="row wrap" fxLayoutAlign="center center">
                                        <div pRipple class="command" (click)="setMediaWidth('5vw')">5%</div>
                                        <div pRipple class="command" (click)="setMediaWidth('10vw')">10%</div>
                                        <div pRipple class="command" (click)="setMediaWidth('16vw')">16%</div>
                                        <div pRipple class="command" (click)="setMediaWidth('25vw')">25%</div>
                                        <div pRipple class="command" (click)="setMediaWidth('33vw')">33%</div>
                                        <div pRipple class="command" (click)="setMediaWidth('50vw')">50%</div>
                                        <div pRipple class="command" (click)="setMediaWidth('100vw')">100%</div>
                                    </div>

                                    <div fxLayout="row" fxLayoutAlign="none none">

                                        Search:
                                        <input id="search" #inputRef type="text" class="text-entry" pInputText
                                            [(ngModel)]="searchStr" />
                                        <div class="command" (click)="clearSearch()">Clear</div>


                                    </div>
                                    <div style="cursor:pointer" fxLayout="row wrap" fxLayoutAlign="center center">
                                        <div *ngFor="let t of global.mediaTags; let i = index;">
                                            <div pRipple class="command" (click)="addSearchTag(t)"> {{t}} </div>
                                        </div>
                                    </div>

                                    <app-form formName="masterAddTags" [autoSubmit]="false"
                                        (objectEvents)="addTagsOutput($event)"></app-form>


                                </ng-template>
                            </p-accordionTab>

                        </p-accordion>


                        <div *ngIf="global.media" class="video-thumb" fxLayout="row wrap" fxLayoutAlign="center center"
                            style="text-align: center;">

                            <div *ngFor="let m of global.media; let i = index;">
                                <div *ngIf="checkSearch(m)" [ngStyle]="{ 'width': getMediaWidth()}"
                                    [style]="getMediaHeight()">
                                    <div *ngIf="m.fileType=='audio/mpeg'">
                                        <audio id="audio" controls (click)="setFullScreen(m)">
                                            <source src={{m.url}} id="src" />
                                        </audio>
                                        <button *ngIf="editNode" (click)="selectNodeVideo(m, editNode)"
                                            class="pretty-postag" pButton pRipple type="button">
                                            Add Audio to {{editNode.title}}
                                        </button>
                                    </div>

                                    <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
                                || m.fileType=='video/quicktime')" class="media-container">
                                        <div fxLayout="column" fxLayoutAlign="center none">
                                            <video class="media" [style]="getMediaHeight()" (click)="setFullScreen(m)"
                                                playsinline webkit-playsinline [muted]="'muted'" #videoall>
                                                <source src="{{m.url}}" type="video/mp4">
                                            </video>
                                            <div *ngIf="!isPlaying(m.url)">
                                                <button (mousedown)="videoall.play()" (mouseup)="playMe(m.url)"
                                                    class="play-button" pButton pRipple type="button">
                                                    Play
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                                    m.fileType=='image/png' || m.fileType=='photo'" class="media-container">
                                        <div fxLayout="column" fxLayoutAlign="center none">
                                            <img id="image{{i}}" (click)="setFullScreen(m)" class="media"
                                                [style]="getMediaHeight()" [src]="getMyUrl(m)" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>

            <p-accordionTab iconPos="end" *ngIf="editNode && editNode.media" [(selected)]="tabSelected[2]"
                headerStyleClass="headers">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(2)">Node Media</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="editNode" class="user-feature" [style]="getStyle(2)">

                        <div *ngFor="let m of editNode.media; let i = index;">

                            <div *ngIf="m.fileType && (m.fileType=='video' || 
                            m.fileType=='video/mp4' || m.fileType=='video/quicktime')">
                                <div fxLayout="column" fxLayoutAlign="none none">
                                    <video style="max-width:100%; max-height:80vh" playsinline webkit-playsinline
                                        [muted]="'muted'" [id]="'prodmedia'+i">
                                        <source src="{{m.url}}" type="video/mp4">
                                    </video>

                                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">
                                        <button (click)="playVideo('prodmedia'+i)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            Play Video
                                        </button>
                                        <button (click)="setFullScreen(m)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            Full Screen & More Features
                                        </button>

                                        <button (click)="removeNodeMedia($event, m, i)" class="pretty-remove" pButton
                                            pRipple type="button">
                                            Remove media from {{editNode.title}}
                                        </button>
                                    </div>

                                </div>
                            </div>

                            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                                m.fileType=='image/png' || m.fileType=='photo'">
                                <div fxLayout="column" fxLayoutAlign="none none">

                                    <img class="media" src="{{m.url}}" alt="{{m.alt}}" />
                                    <button (click)="removeNodeMedia($event, m, i)" class="pretty-remove" pButton
                                        pRipple type="button">
                                        Remove media from {{editNode.title}}
                                    </button>
                                </div>
                            </div>

                            <div fxLayout="row wrap" fxLayoutAlign="space-between" style="min-width: 370px;">

                                <input id="title" placeholder="Title" type="text" class="text-entry" pInputText
                                    [(ngModel)]="m.description" />
                                <button (click)="updateDesc(m, editNode, i)" class="function" pButton pRipple
                                    type="button">
                                    Save Description
                                </button>
                            </div>



                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>


            <p-accordionTab *ngIf="editProduct" iconPos="end" styleClass="accordion">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(3)">Product/Content Media</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="editProduct" class="user-feature" [style]="getStyle(3)">

                        <div *ngFor="let m of editProduct.media; let i = index;">

                            <div *ngIf="m.fileType && (m.fileType=='video' || 
                            m.fileType=='video/mp4' || m.fileType=='video/quicktime')">
                                <div fxLayout="column" fxLayoutAlign="none none">
                                    <video playsinline webkit-playsinline [muted]="'muted'" [id]="'prodmedia'+i">
                                        <source src="{{m.url}}" type="video/mp4">
                                    </video>

                                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">
                                        <button (click)="playVideo('prodmedia'+i)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            Play Video
                                        </button>
                                        <button (click)="setFullScreen(m)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            Full Screen & More Features
                                        </button>

                                        <button (click)="removeProductMedia($event, m, i)" class="pretty-remove" pButton
                                            pRipple type="button">
                                            Remove media from {{editProduct.title}}
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                                m.fileType=='image/png' || m.fileType=='photo'">
                                <div fxLayout="column" fxLayoutAlign="none none">

                                    <img class="media" src="{{m.url}}" alt="{{m.alt}}" />
                                    <button (click)="removeProductMedia($event, m, i)" class="pretty-remove" pButton
                                        pRipple type="button">
                                        Remove media from {{editProduct.title}}
                                    </button>
                                </div>
                            </div>



                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>


            <p-accordionTab *ngIf="!userId" iconPos="end" [(selected)]="tabSelected[3]" headerStyleClass="headers">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(3)">
                            Search new media
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">

                    <div *ngIf="myMedia" style="width:100%">

                        <div *ngFor="let m of myMedia; let i = index;">

                            <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' || m.fileType=='video/quicktime')"
                                class="edit-media">

                                <video class="edit-media" playsinline preload="none" mute="true" webkit-playsinline
                                    [muted]="'muted'">

                                    <source src={{m.url}}>
                                </video>
                            </div>
                        </div>
                    </div>


                    <div fxLayout="row wrap" fxLayoutAlign="space-around center">
                        <div class="command" (click)="SetEditMode('find-images')">Search for Images</div>

                        <div class="command" (click)="SetEditMode('find-internet-images')">Search the
                            internet for
                            Images
                        </div>

                        <!--    <div class="command" (click)="SetEditMode('media')">Enter Image URL</div>
                                        <div class="command" (click)="SetEditMode('add-gallery')">Add Gallery</div>
                    <div class="command" (click)="SetEditMode('video-url')">Enter Video URL</div>-->
                        <div class="command" (click)="SetEditMode('find-video')">Search for Video</div>


                    </div>
                    <div *ngIf="editMode=='media'">
                        <p-sidebar [(visible)]="visible" [fullScreen]="true">

                            <div fxLayout="column" fxLayoutAlign="start none">
                                <div class="action-button" (click)="SetEditMode('find-images')">Find
                                    Media
                                </div>

                                Image URL
                                <input id="image" #inputRef (click)="inputRef.select()" type="text" class="text-entry"
                                    pInputText [(ngModel)]="searchStr" />

                                <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                    (click)="SaveDataStop($event)">Save Changes</div>

                                <div *ngIf="editProduct.image1">
                                    Current Image
                                    <!--          <img class="fit-nicely" [src]="editProduct.image1" /> -->
                                </div>
                            </div>
                        </p-sidebar>
                    </div>

                    <div *ngIf="editMode=='video-url'">
                        <p-sidebar [(visible)]="visible" [fullScreen]="true">

                            <div fxLayout="column" fxLayoutAlign="start none">
                                <!-- 
                            Image URL
                            <input id="image" #inputRef (click)="inputRef.select()" type="text" class="text-entry"
                                pInputText [(ngModel)]="editProduct.video1" />

                            <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                (click)="SaveDataStop($event)">Save Changes</div>

                          <div *ngIf="editProduct.video1">
                                Current Video
                                <video [muted]="'muted'" id="myVideo">
                                 <source src="{{editProduct.video1}}" type="video/mp4"> 
                                </video>
                            </div>
                        -->
                            </div>
                        </p-sidebar>
                    </div>

                    <div *ngIf="editMode=='add-gallery'">
                        <p-sidebar [(visible)]="selectGalleryVisible" [fullScreen]="true">
                            <app-gallery (selectionEvent)="setGallery($event)"></app-gallery>
                        </p-sidebar>
                    </div>

                    <div *ngIf="editMode=='find-images'">
                        <div class="gallery">
                            <div fxLayout="row wrap" fxLayoutAlign="center center"> <input type="text" pInputText
                                    style="width: 350px;" [(ngModel)]="imgSearch" />
                                <div class="command" (click)="FindPexelPhoto(imgSearch)">Search Images</div>
                            </div>
                            <div *ngIf="foundVideos">
                                <div style="width:100%" fxLayout="row wrap" fxLayoutAlign="center center">

                                    <div *ngFor="let v of foundVideos; let i = index;">

                                        <div *ngIf="v.fileType && (v.fileType=='image/jpeg' || v.fileType=='image/png')"
                                            class="img-container">

                                            <img src="{{v.url}}" alt="{{v.alt}}" (click)="ChooseVideo(v)" />
                                            <div *ngIf="v.added"
                                                style="background-color:#f3c07d; padding: 12px; font-size:1.2em;"
                                                class="centered">Added to Media</div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="editMode=='find-internet-images'">
                        <div class="gallery">
                            <div fxLayout="row wrap" fxLayoutAlign="center center"> <input type="text" pInputText
                                    style="width: 350px;" [(ngModel)]="imgSearch" />
                                <div class="command" (click)="imageSearch(imgSearch, cursor)">Search the
                                    Internet
                                    for
                                    Images
                                </div>

                                <button (click)="imageSearch('site:'+global.myCompany.storeURL,cursor)" class="function"
                                    pButton pRipple type="button">
                                    Search {{": "+global.myCompany.storeURL}}
                                </button>

                            </div>
                            <div *ngIf="mediaList">
                                <div style="width:100%" fxLayout="row wrap" fxLayoutAlign="center center">

                                    <div *ngFor="let m of mediaList; let i = index;"
                                        [ngStyle]="{ 'width': getMediaWidth()}">


                                        <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                                    m.fileType=='image/png' || m.fileType=='photo'" class="media-container">
                                            <div fxLayout="column" fxLayoutAlign="center none" class="img-container">
                                                <img id="image{{i}}" (click)="ChooseVideo(m)" class="media"
                                                    src="{{m.url}}" />
                                                <div *ngIf="m.added"
                                                    style="background-color:#f3c07d88; padding: 12px; font-size:1.2em;"
                                                    class="centered">Added to Media</div>

                                            </div>
                                        </div>

                                        <!--

                                    <div *ngIf="v.fileType && (v.fileType=='image/jpeg' || v.fileType=='image/png')">

                                        <img src="{{v.url}}" alt="{{v.alt}}" (click)="ChooseVideo(v)" />
                                    </div>

                                    -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>







                    <div *ngIf="editMode=='find-video'">

                        <div class="gallery" fxFlex fxLayout="column" fxLayoutAlign="center center">
                            <div fxLayout="row wrap" fxLayoutAlign="center center"> <input type="text" pInputText
                                    style="width: 350px;" [(ngModel)]="imgSearch" />
                                <div class="command" (click)="FindVideo(imgSearch)">Search Video</div>
                            </div>
                            <!--    <input type="text" pInputText [(ngModel)]="imgSearch" />
                        <div class="command" (click)="FindVideo(imgSearch)">Search Videos</div>-->

                            <div *ngIf="!fullScreenMode">
                                <div *ngIf="foundVideos" style="width:100%" fxLayout="row wrap"
                                    fxLayoutAlign="center center">
                                    <div *ngFor="let v of foundVideos; let i = index;">

                                        <div *ngIf="v.fileType && v.fileType=='video/mp4'">
                                            <div fxLayout="column" fxLayoutAlign="none none" class="img-container">
                                                <video playsinline webkit-playsinline [muted]="'muted'" #myVideo
                                                    (click)="ChooseVideo(v)">
                                                    <source src="{{v.url}}" type="video/mp4">
                                                </video>
                                                <div *ngIf="v.added"
                                                    style="background-color:#f3c07d; padding: 12px; font-size:1.2em;"
                                                    class="centered">Added to Media</div>

                                                <button (click)="myVideo.play()" class="pretty-postag" pButton pRipple
                                                    type="button">
                                                    Play Video
                                                </button>
                                                <button (click)="setFullScreen(v)" class="pretty-postag" pButton pRipple
                                                    type="button">
                                                    Full Screen & More Features
                                                </button>
                                                <button (click)="ChooseVideo(v)" class="pretty-postag" pButton pRipple
                                                    type="button">
                                                    Add this video to my Media
                                                </button>
                                            </div>


                                            <div
                                                *ngIf="v.fileType && v.fileType=='image' ||
                                            v.fileType=='photo' || v.fileType=='image/jpeg' || v.fileType=='image/png'">

                                                <img src="{{v.url}}" alt="{{v.alt}}" />

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>


                            <div *ngIf="fullScreenMode">
                                <p-sidebar [(visible)]="fullScreenMode" [fullScreen]="true">
                                    <video class="video-full-screen" playsinline webkit-playsinline [muted]="'muted'"
                                        #myVideo2>
                                        <source src="{{currentVideo.url}}" type="video/mp4">
                                    </video>
                                    <button (click)="myVideo2.play()" class="pretty-postag" pButton pRipple
                                        type="button">
                                        Play Video
                                    </button>
                                    <button (click)="setFullScreen(currentVideo)" class="pretty-postag" pButton pRipple
                                        type="button">
                                        Exit Full Screen Mode
                                    </button>

                                    <button (click)="ChooseVideo(currentVideo)" class="pretty-postag" pButton pRipple
                                        type="button">
                                        Add this video to my Media
                                    </button>
                                </p-sidebar>

                            </div>

                        </div>


                    </div>

                </ng-template>
            </p-accordionTab>


            <p-accordionTab *ngIf="1" iconPos="end" [(selected)]="tabSelected[4]" headerStyleClass="headers">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(4)">Upload/Capture Media</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="user-feature" [style]="getStyle(4)">
                        <label class="cameraButton">Add Media
                            <app-capture [destination]="destination"></app-capture>
                            <!--
                            <input type="file" accept="image/jpeg;image/png;capture=camera"
                                (change)="newUpload($event, newMsg)">
                                -->
                        </label>
                    </div>
                </ng-template>
            </p-accordionTab>
            <p-accordionTab *ngIf="!userId && companyId" iconPos="end" [(selected)]="tabSelected[5]" headerStyleClass="headers">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(5)">Generate AI Media</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="user-feature" [style]="getStyle(5)">
                        <label class="cameraButton">Generate Image
                            <app-form formName="masterCreateImageFromText" [autoSubmit]="false"
                                (submitEvent)="outputEventAI($event)"></app-form>
                        </label>
                    </div>
                </ng-template>
            </p-accordionTab>

            <!--
        <p-accordionTab *ngIf="global.myCompany.googlePhotos" iconPos="end" [(selected)]="tabSelected[5]" headerStyleClass="headers">
            <ng-template pTemplate="header">
                <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                    <div class="user-feature" [style]="getStyle(5)">Google My Business Photos</div>
                </div>
            </ng-template>
            <ng-template pTemplate="content">
                <div fxLayout="column" fxLayoutAlign="none none" class="user-feature" [style]="getStyle(6)">


                    <div *ngIf="media" class="video-thumb" fxLayout="row wrap" fxLayoutAlign="center center"
                        style="text-align: center;">

                        <div *ngFor="let m of global.myCompany.googlePhotos; let i = index;">
                            <div *ngIf="checkSearch(m)" [ngStyle]="{ 'width': getMediaWidth()}">
                                <div *ngIf="m.type=='video'" class="media-container">
                                    <div fxLayout="column" fxLayoutAlign="center none">


                                        <video class="media" playsinline webkit-playsinline [muted]="'muted'" #videoall>
                                            <source src="{{m.photo_url}}" type="video/mp4">
                                        </video>

                                        <button (click)="videoall.play()" class="pretty-postag" pButton pRipple
                                            type="button">
                                            Play
                                        </button>
                                    </div>

                                </div>

                                <div *ngIf="m.type == 'photo'" class="media-container">
                                    <div fxLayout="column" fxLayoutAlign="center none">
                                        <img id="image{{i}}" (click)="setFullScreen(m)" class="media" src="{{m.photo_url}}" />
                                    </div>
                                </div>
                                <div *ngIf="m.added"
                                style="background-color:#f3c07d; padding: 12px; font-size:1.2em;"
                                class="centered">Added to Media</div>

                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-accordionTab>

    -->


            <p-accordionTab *ngIf="global.authuser.sharedMediaFrom && global.authuser.sharedMediaFrom.length"
                iconPos="end" [(selected)]="tabSelected[6]" headerStyleClass="headers">
                <ng-template pTemplate="header">
                    <div style="width: 100%" fxLayout="column" fxLayoutAlign="center center">
                        <div class="user-feature" [style]="getStyle(6)">Media shared with me</div>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="user-feature" [style]="getStyle(6)">

                        <p-accordion styleClass="accordion" expandIcon="false" collapseIcon="false" [multiple]="true">

                            <div *ngFor="let share of global.authuser.sharedMediaFrom; let i = index;">
                                <p-accordionTab iconPos="end" styleClass="accordion">
                                    <ng-template pTemplate="header">
                                        <div class="user-feature">{{share.name}}</div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div *ngFor="let m of share.media; let i = index;">

                                            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                                        m.fileType=='image/png' || m.fileType=='photo'" class="media-container">
                                                <div fxLayout="column" fxLayoutAlign="center none">
                                                    <img id="image{{i}}" (click)="setFullScreen(m)" class="media"
                                                        src="{{m.url}}" />
                                                </div>
                                            </div>


                                            <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
                                        || m.fileType=='video/quicktime')" class="media-container-full">
                                                <div fxLayout="column" fxLayoutAlign="center none">
                                                    <video class="media-full" playsinline webkit-playsinline
                                                        [muted]="'muted'" #myVideo2 (click)="setFullScreen(m)">
                                                        <source src="{{m.url}}" type="video/mp4">
                                                    </video>
                                                    <div class="center-me-video">Video</div>
                                                </div>
                                            </div>








                                        </div>

                                    </ng-template>

                                </p-accordionTab>



                            </div>
                        </p-accordion>

                    </div>
                </ng-template>
            </p-accordionTab>


        </p-accordion>

    </div>


    <div *ngIf="mediaEditing">
        <p-sidebar [(visible)]="showMediaEdit" [fullScreen]="true" (onHide)="hideEdit()">
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="none none">
                <div class="media-container">
                    <div *ngIf="mediaEditing.fileType && mediaEditing.fileType=='non-image'" class="edit-media">
                        <div>{{mediaEditing.url}}</div>

                        <video class="edit-media" playsinline preload="none" mute="true" webkit-playsinline
                            [muted]="'muted'">

                            <source src={{mediaEditing.url}}>
                        </video>
                    </div>

                    <div *ngIf="mediaEditing.fileType && (mediaEditing.fileType=='video' || mediaEditing.fileType=='video/mp4' || mediaEditing.fileType=='video/quicktime')"
                        class="edit-media">
                        <div>{{mediaEditing.url}}</div>

                        <video class="edit-media" playsinline preload="none" mute="true" webkit-playsinline
                            [muted]="'muted'">

                            <source src={{mediaEditing.url}}>
                        </video>
                    </div>



                    <div
                        *ngIf="!mediaEditing.fileType || mediaEditing.fileType=='image' ||
                                mediaEditing.fileType=='photo' || mediaEditing.fileType=='image/jpeg' || mediaEditing.fileType=='image/png'">
                        <div>{{getImageURL(mediaEditing)}}</div>
                        <div class="edit-media">
                            <img id="bigImage" src="{{getImageURL(mediaEditing)}}" />
                        </div>
                    </div>
                </div>

                <tag-input style="width: 100%; border: 2px solid green;" [(ngModel)]="mediaEditing.tags"
                    [allowDupes]="false" [placeholder]="'Add tags'" [modelAsStrings]="true"
                    [secondaryPlaceholder]="'Add Tags with Enter'"></tag-input>

                <div *ngFor="let t of everyTag; let i = index;">
                    <div class="command" (click)="addTag(t)"> {{t}} </div>
                </div>
            </div>



            <div class="command" (click)="saveChanges(mediaEditing)">Save Changes</div>

            <div class="command" (click)="deleteMedia(mediaEditing)">Delete this Media</div>

        </p-sidebar>
    </div>

    <div *ngIf="fullScreenMode">
        <p-sidebar [(visible)]="fullScreenMode" [fullScreen]="true">
            <div *ngIf="currentVideo.fileType && (currentVideo.fileType=='video' || currentVideo.fileType=='video/mp4' 
                || currentVideo.fileType=='video/quicktime')" class="media-container-full">
                <div fxLayout="column" fxLayoutAlign="center none">

                    <video id="video300" class="media-full" playsinline webkit-playsinline [muted]="'muted'" #myVideo2
                        (click)="setFullScreen(m)">
                        <source src="{{currentVideo.url}}" type="video/mp4">
                    </video>
                    <div style="width: auto;" fxLayout="row wrap" fxLayoutAlign="center space-between">
                        <button pButton pRipple (click)="changeAudio('video300')">{{audioLabel('video300')}}</button>
                        <button (click)="myVideo2.play()" class="pretty-postag" pButton pRipple type="button">
                            Play Video
                        </button>

                        <button *ngIf="editNode" (click)="selectNodeVideo(currentVideo, editNode)" class="pretty-postag"
                            pButton pRipple type="button">
                            Add Video to {{editNode.title}}
                        </button>

                        <button *ngIf="eventEmitter" (click)="addImageToGame(currentVideo, 'video')"
                            class="pretty-postag" pButton pRipple type="button">
                            Add Video to the Scene
                        </button>

                        <button (click)="removeMedia($event, currentVideo)" class="pretty-remove" pButton pRipple
                            type="button">
                            Remove Media - Permanently!
                        </button>


                        Access control:
                        <p-selectButton (onChange)="accessChanged($event, currentVideo.accessChanged)"
                            [options]="accessModes" [(ngModel)]="currentVideo.access"></p-selectButton>

                        <div *ngIf="currentVideo.access=='shared'">
                            <input data-lpignore="true"
                                style="min-width: 15rem !important;  padding-left:4px !important; font-size: 1.4rem; margin-right:4px !important;"
                                type="email" pInputText placeholder="email" [(ngModel)]="email">

                            <div *ngIf="!inputCreds" pRipple class="command" style=" min-height: 100%;"
                                (click)="shareThisMedia(currentVideo)">
                                Share Media
                            </div>
                        </div>


                    </div>
                </div>

            </div>

            <div *ngIf="!currentVideo.fileType || currentVideo.fileType=='image/jpeg' || 
                    currentVideo.fileType=='image/png' || currentVideo.fileType=='photo'" fxLayout="column"
                fxLayoutAlign="center center">

                <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
                    <p-accordionTab iconPos="end">
                        <ng-template pTemplate="header">
                            <div class="settings">Settings</div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div style="height: 100%">
                                <div *ngIf="currentVideo.prompt">
                                    AI Generated from: {{currentVideo.prompt}}
                                    {{" "+currentVideo.modelDetails}}
                                    {{" "+currentVideo.cost}}
                                </div>
                                <app-form formName="masterImageSettings" [formInput]="currentVideo" [autoSubmit]="false"
                                    (objectEvents)="imageObjectEvent($event)"
                                    (submitEvent)="outputEvent($event)"></app-form>
                            </div>
                            <p-accordion *ngIf="currentVideo?.otherSizes" expandIcon="false" collapseIcon="false" [multiple]="false">
                                <p-accordionTab iconPos="end">
                                    <ng-template pTemplate="header">
                                        <div class="settings">Other Versions</div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <img *ngIf="currentVideo?.otherSizes['large']"  style="max-height: 30vh; max-width: 100%;" src={{currentVideo.largeUrl}} />
                                        <img *ngIf="currentVideo.otherSizes['med']"  style="max-height: 30vh; max-width: 100%;" src={{currentVideo.medUrl}} />
                                        <img *ngIf="currentVideo.otherSizes['small'].width" style="max-height: 30vh; max-width: 100%;" src={{currentVideo.smallUrl}} />
                                        <img *ngIf="currentVideo.otherSizes['thumb'].width" style="max-height: 30vh; max-width: 100%;" src={{currentVideo.thumbnailUrl}} />
                                        
                                    </ng-template>
                                </p-accordionTab>
                            </p-accordion>
              
                        </ng-template>
                    </p-accordionTab>
                    <p-accordionTab iconPos="end">
                        <ng-template pTemplate="header">
                            <div class="settings">Sharing</div>
                        </ng-template>
                        <ng-template pTemplate="content">
                        
                    <app-sharing type="media" [share]="currentVideo.share" [linkURL]="getMyMediaLink()"
                    (updateShare)="outputEventShare($event)"></app-sharing>
           

                        </ng-template>
                    </p-accordionTab>

                </p-accordion>
                <div class="media-container-full">

                    <img (click)="setFullScreen(m)" class="media-full" src="{{currentVideo.url}}" />




                    <!--
                    <app-form formName="masterMediaSettings" [autoSubmit]="true" [formInput]="currentVideo"
                    (objectEvents)="outputEvent($event)"></app-form>
                    -->
                    <div fxLayout="row wrap" fxLayoutAlign="center space-between">
                        <button *ngIf="editNode" (click)="selectNodeVideo(currentVideo, editNode)" class="pretty-postag"
                            pButton pRipple type="button">
                            Add Photo to {{editNode.title}}
                        </button>

                        <button *ngIf="global.mediaGroups" (click)="addPhotoToGroups(currentVideo)"
                            class="pretty-postag" pButton pRipple type="button">
                            Add Photo to Group(s)
                        </button>

                        <button *ngIf="eventEmitter" (click)="addImageToGame(currentVideo, 'image')"
                            class="pretty-postag" pButton pRipple type="button">
                            Add Image to the Scene
                        </button>

                        <button *ngIf="editProduct" (click)="selectVideo(currentVideo)" class="pretty-postag" pButton
                            pRipple type="button">
                            Add Photo to {{editProduct.title}}
                        </button>

                        <button (click)="removeMedia($event, currentVideo)" class="pretty-remove" pButton pRipple
                            type="button">
                            Remove Media - Permanently!
                        </button>




                        <div *ngIf="currentVideo.access=='Shared'">
                            <input data-lpignore="true"
                                style="min-width: 15rem !important;  padding-left:4px !important; font-size: 1.4rem; margin-right:4px !important;"
                                type="email" pInputText placeholder="email" [(ngModel)]="email">

                            <div *ngIf="!inputCreds" class="command" style=" min-height: 100%;"
                                (click)="shareThisMedia(currentVideo)">
                                Share Media
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </p-sidebar>
    </div>