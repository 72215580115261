<div *ngIf="liveChat">
    <div *ngIf="!userChat && global.myCompany && global.myCompany.enabled && global.myCompany.enabled.chatService">
        <div class="pretty-chat" fxLayout="column" fxLayoutAlign="center center">
            <div>Chat with {{global.myCompany.name}}</div>
        </div>
    </div>
    <div *ngIf="!global.authuser">
        <SignIn startText="Start Chat" (setContactEvent)="setContact($event)" (afterLoginEvent)="afterLogin()"></SignIn>
    </div>
    <div style="width: 100%">
        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false" (onOpen)="onTabClick()">
            <p-accordionTab *ngIf="allowNewConnection">
                <ng-template pTemplate="header">
                    <div class="function-header">New Connection</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div class="p-fluid" fxFlex fxLayout="column" style="width:100%">

                        <!--   <input id="email" type="email" placeholder="Enter recipient Email" class="text-entry" pInputText
                [(ngModel)]="newMsg.to" />-->

                        <tag-input style="width: 100%" [(ngModel)]='groupList' [allowDupes]="false"
                            [separatorKeyCodes]="[32]" [placeholder]="'Add Emails, Space or Enter'"
                            [secondaryPlaceholder]="'Add Emails with Space or Enter'"></tag-input>

                        <div style="width:100%">

                            <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                (click)="createGroup()">Start Group Conversation</div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>



        <p-accordion styleClass="tab" [multiple]="false" expandIcon="false" collapseIcon="false"
            *ngFor="let conversation of groups; let i = index;" (onOpen)="onChatOpen($event, i)"
            (onClose)="onChatClose($event, i)">

            <p-accordionTab *ngIf="activeIndex<0 || activeIndex == i" [selected]="activeChat[i]">
                <ng-template pTemplate="header">
                    <div fxLayout="row" fxLayoutAlign="none none">
                        <div class="group-name">{{GetGroupName (i)}}
                            <span *ngIf="conversation.newMessages">
                                ({{conversation.newMessages}})
                            </span>
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="content">
                    <div class="card">
                        <!--
                        <div *ngIf="dataMode=='ChatBot'">
                            <div fxLayout="row" fxLayoutAlign="space-between none">
                                <div fxFlex="75%">
                                    <input style="width:100%; min-height: 40px;" placeholder="Name for the Chat Bot"
                                        type="text" pInputText [(ngModel)]="newMsg.botName" />

                                </div>
                                <div fxFlex="25%" fxLayout="row">
                                    <div class="cancel-button" (click)="cancel()">
                                        X</div>
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="startChatBot(chat, i)">
                                        Start Chat Bot</div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="dataMode=='Map'">
                            <div *ngIf="!newMsg.Lat" fxLayout="row" fxLayoutAlign="space-between none">
                                <div fxFlex="75%">
                                    <input style="width:100%" placeholder="Address, Zipcode or Place name" type="text"
                                        pInputText [(ngModel)]="newMsg.mapAddress" />

                                </div>
                                <div fxFlex="25%" fxLayout="row">
                                    <div class="cancel-button" (click)="cancel()">
                                        X</div>
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="getMap(chat, i)">
                                        GetMap</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="dataMode=='Media'" style="margin-bottom: 12px;">

                            <div fxFlex="75%" fxLayout="row">
                                <input id="imageLoad{{i2}}" type="file"
                                    accept="image/x-png,image/jpeg,image/gif,image/heic,image/mp4"
                                    (change)="upload($event, newMsg)" />

                                <input placeholder="enter URL" type="text" pInputText [(ngModel)]="newMsg.media" />

                            </div>
                            <div fxFlex="25%" fxLayout="row">
                                <div class="cancel-button" (click)="cancel()">
                                    X</div>
                                <div class="action-button3"
                                    style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                    (click)="addImage(chat, i)">
                                    Add Image</div>
                            </div>


                        </div>

                      

                        <div *ngIf="dataMode=='NapkinApp'">
                            <app-form formName="masterChooseApp" [formInput]="chat" [autoSubmit]="false"
                                (submitEvent)="outputEvent($event)"></app-form>
                        </div>

                        <div *ngIf="dataMode=='newNapkinApp'">
                            <div *ngIf="!newMsg.Lat" fxLayout="row" fxLayoutAlign="space-between none">
                                <div fxFlex="75%">
                                    <input style="width:100%" placeholder="App name" type="text" pInputText
                                        [(ngModel)]="newMsg.appName " />

                                </div>
                                <div fxFlex="25%" fxLayout="row">
                                    <div class="cancel-button" (click)="cancel()">
                                        X</div>
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="createApp(chat, i)">
                                        Create App</div>
                                </div>
                            </div>
                        </div>


                        <div *ngIf="dataMode=='Sticky Note'">
                            <div class="sticky">
                                <div class="sticky__title">
                                    <input placeholder="Add Title" type="text"
                                        style="text-align: center; font-size: 1.2rem; background-color:yellow"
                                        pInputText [(ngModel)]="newMsg.stickyTitle" />
                                </div>
                                <tag-input inputClass="sticky__text" style="background-color:yellow"
                                    [(ngModel)]='stickyNotes' [allowDupes]="false"
                                    [placeholder]="'Add notes press Enter when done'"
                                    [secondaryPlaceholder]="'Add notes press Enter when done'"></tag-input>
                                <div fxLayout="row">
                                    <div class="action-button3"
                                        style="width: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                        (click)="sendSticky()">
                                        Send Sticky</div>
                                    <div class="cancel-button" (click)="cancel()">
                                        X</div>
                                </div>
                            </div>
                        </div>
-->
                        <!--
                        <div *ngIf="dataMode=='functions'">
                            <p-sidebar [(visible)]="viewFunctions" [modal]="false" position="left" [autoZIndex]="false"
                                [fullScreen]="false" (onHide)="hideFunctions()">
                                Available Functions
                                <div style="width: 100%; cursor:pointer;" fxFlex fxLayout="row wrap"
                                    fxLayoutAlign="space-between space-between">
                                    <div *ngFor="let f of chatFunctions; let i = index;" class="command"
                                        (click)="setFunction($event.target.innerHTML)">
                                        {{f}}

                                    </div>
                                </div>
                            </p-sidebar>

                        </div>


                        <div *ngIf="dataMode=='video'">
                            <p-sidebar [(visible)]="visible" [modal]="false" position="left" [autoZIndex]="false"
                                [fullScreen]="false" (onHide)="hideFindVideo()">

                                <div class="gallery" fxFlex fxLayout="column">
                                    <input type="text" pInputText [(ngModel)]="videoSearch" />
                                    <div class="command" (click)="FindVideo(videoSearch)">Search Videos</div>
                                    <div fxLayout="row wrap" fxLayoutAlign="none none">
                                        <div style="margin: 12px;" *ngFor="let v of videos; let i = index;">
                                            <video style="max-width:100%; max-height:300px;" playsinline
                                                webkit-playsinline autoplay [muted]="'muted'" controls id="myVideo">
                                                <source src="{{v}}" type="video/mp4">
                                            </video>
                                            <div class="action-button3"
                                                style="width: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                                (click)="ChooseVideo(v)">
                                                Choose Video</div>

                                        </div>
                                    </div>
                                </div>
                            </p-sidebar>
                        </div>
END OF DATAMODES
-->


                        <!-- Show Chat entry if not other mode -->
                        <!--moved down update to form            <div
                            *ngIf="dataMode=='' && 
                            (!conversation.channel || (conversation.channel && conversation.members[0] == global.authuser.uid))">
                            <div *ngIf="newMsg" fxLayout="row" fxLayoutAlign="none none">
                                <div style="position:relative; width:100%;" class="text-entry">
                                    <textarea style="height:100%" pInputTextarea [placeholder]="getChatPlaceHolder()"
                                        [rows]="3" [autoResize]="false" id="fullDescription" type="text"
                                        [(ngModel)]="newMsg.msg">
                                </textarea>
                                    <div *ngIf="!conversation.storeID">
                                        <div *ngIf="newMsg.msg.length==0" class="command-button"
                                            (click)="functionMode()">
                                            Functions
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="newMsg.msg.length>0" fxFlex="15%">
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:#B4E4FF"
                                        (click)="SendMsg(i)">
                                        Send</div>
                                </div>
                            </div>
                        </div>   -->

                        <!-- DISPLAY CHAT MESSAGES -->
                        <div class="chat-messages" fxLayout="column" fxLayoutAlign="none none"
                            *ngFor="let chat of messages; let i2 = index" class="card.text">
                            <div *ngIf="!editChat || editChat!= chat" (click)="msgClicked(chat)">

                                <span *ngIf="chat.msg.length">
                                    <span *ngIf="chat.msg.startsWith('http')">
                                        <div class="urlLink" (click)="goLink(chat.msg)">Go to: {{" "+chat.msg}}
                                        </div>
                                    </span>
                                    <span *ngIf="!chat.msg.startsWith('http')">
                                        <div class="chat-msg" [style.justify-content]="getTextAlign(chat)"
                                            [ngStyle]="{ 'justify-content': getTextAlign(chat)}">
                                            <div class="chat-text" style="width:fit-content"
                                                [style.background-color]="getBackgroundcolor(chat)">{{chat.msg}}
                                            </div>




                                        </div>
                                        <!-----  Wink     ----->
                                        <div *ngIf="dataMode=='' && 
                            (conversation.channel && conversation.members[0] != global.authuser.uid)">
                                            <div fxFlex fxLayout="column" fxLayoutAlign="end end" style="width: 100%;">
                                                <div class="wink" pRipple (click)="wink(conversation, chat)">Wink</div>
                                            </div>

                                        </div>
                                    </span>
                                </span>
                            </div>


                            <div *ngIf="editChat == chat">
                                <p-accordionTab [selected]="editChat">
                                    <ng-template pTemplate="header">
                                        <div class="card__text" style="width:100%;" [style.color]="getTextColor(chat)"
                                            [ngStyle]="{ 'color': getTextColor(chat)}">
                                            {{chat.msg}}
                                        </div>

                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        <div style="width: 100%;" fxLayout="column" fxLayoutAlign="space-between none">
                                            <div style="width: 100%;" fxLayout="row" fxLayoutAlign="space-between none">
                                                <div fxFlex="85%">
                                                    <textarea pInputTextarea [rows]="3" [cols]="50" [autoResize]="true"
                                                        id="fullDescription" type="text" class="text-entry"
                                                        [(ngModel)]="newMsg.msg">
                                                </textarea>
                                                </div>
                                                <div fxFlex="15%">
                                                    <div class="action-button3"
                                                        style="height: 100%; cursor: pointer; background-color:rgb(187, 242, 139)"
                                                        (click)="UpdateMsg(chat, i)">
                                                        Update</div>
                                                </div>
                                            </div>
                                            <div fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                                                <div class="command" (click)="Clicked($event.target.innerHTML)">Hide
                                                </div>
                                                <div class="command" (click)="Clicked($event.target.innerHTML)">
                                                    Remove
                                                </div>
                                                <div class="command" (click)="Clicked($event.target.innerHTML)">Copy
                                                </div>

                                            </div>

                                        </div>


                                    </ng-template>
                                </p-accordionTab>

                            </div>
                            <div *ngIf="chat.feature=='video search'" class="pretty-header-data-center">
                                <div *ngIf="chat.media">
                                    <video style="max-width:100%; max-height: 400px;" playsinline webkit-playsinline
                                        autoplay controls [muted]="'muted'" id="myVideo">
                                        <source src="{{chat.media[0]}}" type="video/mp4">
                                    </video>

                                </div>

                            </div>

                            <div *ngIf="chat.feature=='sticky note'" class="pretty-header-data-center">

                                <div *ngIf="chat.stickyTitle" class="sticky">
                                    <div class="sticky__title">{{chat.stickyTitle}}</div>
                                    <div class="sticky__text" style="background-color:yellow"
                                        *ngFor="let n of chat.stickyNotes; let i = index;">
                                        {{n}}

                                    </div>

                                </div>
                            </div>

                            <div *ngIf="chat.feature=='get image'" class="pretty-header-data-center">
                                <div *ngIf="chat.media">
                                    <img style="max-width:100%; max-height: 400px;" src={{chat.media}} alt="">
                                </div>
                            </div>

                            <div *ngIf="chat.feature=='Search Internet'" class="pretty-header-data-center">
                                <div *ngIf="1 || chat.searchResults">
                                    <p-accordion [multiple]="false">
                                        <p-accordionTab [(selected)]="chat.searchTabOpen">
                                            <ng-template pTemplate="header">
                                                <div class="pretty-header-data-center">Search{{"
                                                    "+chat.searchQuery}}</div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <!--   {{chat.searchResults}} -->
<!--
                                                <div *ngIf="dataMode=='Search Internet'">
                                                    <app-search (setInfoEvent)="setInfo($event)" (setQueryEvent)="setQuery($event)"
                                                        (setResultEvent)="setResult($event)" [cancelIcon]="true"></app-search>
                                                </div>
-->
                                                <app-search [query]="chat.searchQuery" 
                                                (setInfoEvent)="setInfo($event, chat)" 
                                                (setResultEvent)="setResult($event, chat)"
                                                [getResults]="chat.searchResults"
                                                    [getInfo]="chat.searchInfo" [showQuery]="false"
                                                    [cancelIcon]="true"></app-search>

                                            </ng-template>
                                        </p-accordionTab>
                                    </p-accordion>



                                </div>
                            </div>



                            <div *ngIf="chat.feature =='map'" class="pretty-header-data-center">
                                <p-accordion>
                                    <p-accordionTab [(selected)]="chat.searchTabOpen">
                                        <ng-template pTemplate="header">
                                            <div *ngIf="chat.mapLat">
                                                {{" "+chat.mapAddress}}

                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <app-maps [mapOptions]="chat.mapOptions"></app-maps>

                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>

                            </div>

                            <div *ngIf="chat.feature =='weather'" class="pretty-header-data-center">
                                <p-accordion>
                                    <p-accordionTab [(selected)]="chat.searchTabOpen">
                                        <ng-template pTemplate="header">
                                                {{"Weather: "+chat.mapAddress}}
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <app-weather [location]="chat.mapAddress"></app-weather>

                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>

                            </div>

                            <div *ngIf="chat.feature=='napkinApp'" class="pretty-header-data-center">
                                <p-accordion>
                                    <p-accordionTab>
                                        <ng-template pTemplate="header">
                                            <div class="pretty-header-data-center">Napkin App</div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <div *ngIf="chat.appId">
                                                <app-appMaker [editing]="true" [appId]="chat.appId"
                                                    [headLess]="true"></app-appMaker>
                                            </div>
                                            <!-- THIS CODE AUTO/CREATES PATH TO DRAWING 
                                                <div style="height:600px;">
                                                    <app-draw initAccount="{{GetAccount(conversation, chat)}}"
                                                        initTopic="{{GetTopic(conversation, chat)}}"></app-draw>
                                                </div>
                                                -->
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>

                            <div *ngIf="chat.feature=='Form'" class="pretty-header-data-center">
                                <p-accordion>
                                    <p-accordionTab>
                                        <ng-template pTemplate="header">
                                            <div class="pretty-header-data-center">Form</div>
                                        </ng-template>
                                        <ng-template pTemplate="content">
                                            <div *ngIf="chat.formName">

                                            <app-form-edit [formName]="chat.formName" [open]="true"
                                            ></app-form-edit>

                                            </div>
                                            <!-- THIS CODE AUTO/CREATES PATH TO DRAWING 
                                                <div style="height:600px;">
                                                    <app-draw initAccount="{{GetAccount(conversation, chat)}}"
                                                        initTopic="{{GetTopic(conversation, chat)}}"></app-draw>
                                                </div>
                                                -->
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>



                            <div *ngIf="chat.feature=='ChatBot'">
                                <p-accordion>
                                    <p-accordionTab>
                                        <ng-template pTemplate="header">
                                            <div class="pretty" style="width: 100%">Chat Bot: {{chat.chatBotName}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="content">

                                            <div style="width: 100%">
                                                <app-chatbot [userId]="global.authuser.uid" [chat]="chat"
                                                    [chatBotId]="chat.chatBotId"
                                                    (createCallback)="createCallback($event)"></app-chatbot>

                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>


                            <div *ngIf="chat.feature=='Report'">
                                <p-accordion>
                                    <p-accordionTab>
                                        <ng-template pTemplate="header">
                                            <div class="pretty" style="width: 100%">Report: {{chat.reportName}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="content">

                                            <div style="width: 100%">

                                                <app-reports [editReport]="true"
                                                    [reportName]="chat.reportName"></app-reports>


                                            </div>
                                        </ng-template>
                                    </p-accordionTab>
                                </p-accordion>
                            </div>





                            <div *ngIf="chat.feature=='play'">

                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="pretty" style="width: 100%">Game</div>
                                    </ng-template>
                                    <ng-template pTemplate="content">

                                        <div style="width: 100%">
                                            <app-trivia></app-trivia>
                                        </div>
                                    </ng-template>
                                </p-accordionTab>
                            </div>

                        </div>

                        <!-- Show Chat entry if not other mode -->
                        <div
                            *ngIf="dataMode=='' && 
                            (!conversation.channel || (conversation.channel && conversation.members[0] == global.authuser.uid))">
                            <div *ngIf="newMsg" fxLayout="column" fxLayoutAlign="center none">
                                <div *ngIf="!conversation.storeID">
                                    <!--
                                    <div *ngIf="newMsg.msg.length==0" (click)="functionMode()">
                                        Functions
                                    </div> -->

                                    <app-form formName="masterChatChooseFunction" [autoSubmit]="true"
                                        (objectEvents)="fieldEvent($event)"
                                        (submitEvent)="functionSubmitted($event, i)"></app-form>

                                </div>
                                <textarea style="width:100%" pInputTextarea [placeholder]="getChatPlaceHolder()"
                                    [rows]="3" [autoResize]="false" id="fullDescription" type="text"
                                    [(ngModel)]="newMsg.msg">
                                </textarea>

                                <div *ngIf="newMsg.msg.length>0" fxFlex="15%">
                                    <div class="action-button3"
                                        style="height: 100%; cursor: pointer; background-color:#B4E4FF"
                                        (click)="SendMsg(i)">
                                        Send</div>
                                </div>
                            </div>
                        </div>



                    </div>


                </ng-template>
            </p-accordionTab>

        </p-accordion>
    </div>
    <your-component-2></your-component-2>