import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../product-int.js'
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { User, Contact, PexelVideo, PexelVideoFile, PexelUser, PexelPhoto, Media } from '../user-interface';
import {Company, Field} from '../company-interface.js';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { CartService, SharedDataService } from '../menu/cart.service';



@Component({
  selector: 'app-view-item',
  templateUrl: './view-item.component.html',
  styleUrls: ['./view-item.component.scss', '../../common.scss']
})
export class ViewItemComponent implements OnInit {
  @Input() p: Product;
  @Input() appId: string;

  myMedia: Media[];
  optionFields: Field[];
  addToCart: boolean;

  allowEdits: boolean = false;

  constructor(public global: Globals,
    public cartService: CartService,
    private sanitizer: DomSanitizer, 
    ) {


  }

  ngOnInit(): void {
    var self = this;

    //  this.loadMedia();
    self.optionFields = [];
    if (self.p?.allOptions)
    self.p.allOptions.forEach(function(options){
      var field: Field = {title: options.title,class:"product-options", label: options.title, type:"Options", options: options.list }
      if (options.list.length==1) {
        field.binding = options.list[0];
        field.display = true;
      }
      self.optionFields.push(field);
    })

    self.addToCart = self.global.appDoc[self.appId].app.addToCart;

    console.log(self.global.appDoc[self.appId].app)

  }
  /*
  loadMedia() {
    var self = this;
    var db = firebase.firestore();

    var docRef = db.collection("products").doc(self.p.docID).collection("media")

    console.log("Get product Media: ", self.p.docID)
    const vm = this;

    db.collection('products')
      .doc(self.p.docID.trim())
      .collection('media')
      .get()
      .then((querySnapshot) => {
        self.p.media = [];
        querySnapshot.forEach((doc) => {
          if (doc) {
            var data = doc.data().mediaId;
            self.p.media.push(data);
            console.log("GOT product media ", self.p.media)
          }
        })

        // Now let's get the actual media
        self.myMedia = [];
        if (self.p.media)
          self.p.media.forEach(function (media) {
            const mediaRef = db.collection("media").doc(media).get()
              .then((querySnapshot) => {
                const data = <Media>querySnapshot.data();
                if (!data) {
                  console.log("Missing media", media)
                }
                else
                  self.myMedia.push(data);
              })
          })
      })

  }
*/

optionOutput (field: Field) {
  console.log("option selected: ", field)
  this.p.allOptions.forEach (function(item){
    if (item.title==field.title) {
      item.chosen = field.binding;
    }
  })

}

  getImageFit(p: Product) {
    if (p.backgroundSize) { return p.backgroundSize; }
    else
      return "scale-down";

  }



  getImagePos(p: Product) {
    return "50% 50%";

    if (p.imagePosX == null) return "50% 50%";

    //    console.log("bx ", p.imagePosX, p.imagePosY, p.minHeight);
    //    if (!p.imagePosX )p.imagePosX = 0;
    //  if (!p.imagePosY) p.imagePosY = 0;

    //    console.log("bx ", p.imagePosX, p.imagePosY, p.minHeight);
    return (p.imagePosX + '% ' + p.imagePosY + '%');


  }

  getMediaHeight (p:Product) {
    return ("height:100%; max-height: 400px; align-items:center;margin:auto")

  }

  getMyHeight(p: Product) {
    // console.log ("GH ");



    var minH = 500;
    var arr;
    
    if (p.fullDescription)
      arr = p.fullDescription.split(' ');
    // set default height based on lines in desc
    if (arr.length / 5 < 5) minH = 300;


    if (p.minHeight) return p.minHeight.toString();
    else {
      p.minHeight = minH;
      // console.log ("GH ",minH, arr);
      return minH.toString();
    };
  }

  getMyMinProdHeight (p: Product) {
    return "height:max-content; max-height: 300px;"
  }

  getMyProdHeight(p: Product) {
    var v = "";

    return "max-content";

    if (p.autoHeight) v = "max-content";
    else if (p.minHeight) v = p.minHeight.toString() + 'px';


    //console.log ("Tall height: ",p.autoHeight, v);

    return v;

    // default
    return "max-content";
    /*

    var minH = 500;

    const arr = p.fullDescription.split(' ');
    // set default height based on lines in desc
    if (arr.length / 5 < 11) minH = 450;
    if (arr.length / 5 < 9) minH = 400;
    if (arr.length / 5 < 7) minH = 350;
    if (arr.length / 5 < 5) minH = 300;
    if (arr.length / 5 < 3) minH = 250;
    if (arr.length / 5 > 12) minH = 600;
    if (p.style && p.style == "Wide Image") {
      minH += 120;
    }

    //    if (p.minHeight) return p.minHeight.toString();
    //    else {
    p.minHeight = minH;
    console.log("GH ", minH, arr);
    return minH.toString();
    //   };

*/

  }
  getMyWideProdHeight(p: Product) {
    //  console.log ("wide height: ", p.minHeight);

    if (p.minHeight == null) return '200px';
    if (p.minHeight != null) return p.minHeight.toString() + 'px';

    // default
    return "";
  }

}
