import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { FormService } from '../form.service';

@Component({
  selector: 'app-form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.css', '../../common.scss']
})
export class FormSettingsComponent implements OnInit {
  @Input() formIndex: number;
  @Input() form: Form;

  //editForm: Form;


  constructor(public global: Globals, private formService: FormService) {

  }

  ngOnInit(): void {
    var self = this;
    // this.editForm = { name: "bob", showSubmit: false, fields: [] };

    //    if (this.form) this.editForm = this.form;
    console.log("Form Settings UPDATE: ", this.form, this.formIndex)

    // Read the doc ID from master

    /* OLD
      self.getMasterForm("master-form-settings");
      */
    //  this.fixMasterForms();

  }
  outputEvent(data) {
    var self = this;
    var removed = false;
    var copyMe = false;
    var copyStore;

    console.log("Outputevent ", data)
    /* New STYLE */
    self.formService.napkinFieldsToObject(data, self.form)
    console.log("Outputevent ", data, self.form);

    data.fields.forEach(function (field) {
      // Special FIELDS
      if (field.dataSource == 'Themes' && field.binding) {
        self.form.themeId = field.dataSourceId;
        //
      }


      if (field.title == 'copyForm' && field.binding) {
        copyMe = true;
      }
      if (field.title == 'copyStore' && field.binding) {
        copyStore = field.binding;
      }

    })
    if (copyMe && copyStore) {
      self.copyForm(self.form, copyStore)
      console.log("LET'S COPY THIS FORM ", copyMe, copyStore)
      copyMe = null;
    }
    self.updateForm(self.form);

  }

  copyForm(form: Form, destination?) {
    var self = this;

    var db = firebase.firestore();

    console.log("Copying form: ", form)

    // CLEAN THE FORM
    self.formService.clearForm(form);
    form = removeUndefined(form)

    var newForm: Form = {};
    newForm = Object.assign(newForm, form)

    var storeId = self.global.myCompany.id;
    if (destination) {
      // Find store in creds and use that id
      self.global.authuser.creds.forEach(function (cred) {
        if (cred.storeName == destination) {
          storeId = cred.id;
        }

      })
    }

    var formRef =
      db.collection("company").doc(storeId)
        .collection("forms").doc()
    newForm.id = formRef.id;
    newForm.name += ' Copy'

    formRef.set(newForm).then((querySnapshot) => {
      console.log("Form COPIED ", newForm)
      //  self.editForm = null;
      if (!destination) {
        self.global.allForms.push(newForm)
        self.global.allFormNames.push(newForm.name)
      }

    })

  }

  removeForm(form: Form) {
    var self = this;

    var db = firebase.firestore();

    console.log("Removing form: ", form)
    if (!confirm("Confirm REMOVAL of form: " + form.name)) return;

    var formRef =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(form.id)
        .update({ deleted: true }).then((querySnapshot) => {
          console.log("Form Removed ", form)
          //  self.editForm = null;

        })

  }


  updateForm(form: Form) {
    var self = this;
    var id = form.id;

    if (!id) id = form.formId;

    console.log("Settings updating: ", form, id)

    var updateInfo: Form = { name: form.name };
    // if (form.layout) 
    updateInfo.layout = form.layout;
    //  if (form.autoSave) 
    updateInfo.autoSave = form.autoSave;
    // if (form.showSubmit) 
    updateInfo.showSubmit = form.showSubmit;
    if (form.submitMsg)
      updateInfo.submitMsg = form.submitMsg;
    //  if (form.backgroundColor)
    updateInfo.backgroundColor = form.backgroundColor;

    if (typeof (form.loginRequired) == 'boolean') {
      updateInfo.loginRequired = form.loginRequired;
    }
    if (typeof (form.width) == 'number') {
      updateInfo.width = form.width;
    }
    if (typeof (form.height) == 'number') {
      updateInfo.height = form.height;
    }
    if (typeof (form.marginBottom) == 'number') {
      updateInfo.marginBottom = form.marginBottom;
    }
    if (typeof (form.marginTop) == 'number') {
      updateInfo.marginTop = form.marginTop;
    }
    if (typeof (form.marginLeft) == 'number') {
      updateInfo.marginLeft = form.marginLeft;
    }
    if (typeof (form.marginRight) == 'number') {
      updateInfo.marginRight = form.marginRight;
    }
    if (typeof (form.theme) == 'string') {
      updateInfo.theme = form.theme;
    }
    if (typeof (form.themeId) == 'string') {
      updateInfo.themeId = form.themeId;
    }

    updateInfo.fieldGroups = form.fieldGroups;


    // STRIP REMOVE NULLS all empty fields
    updateInfo = removeUndefined(updateInfo)
    var db = firebase.firestore();

    var fieldRef =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(id)
        .update(updateInfo).then((querySnapshot) => {
          self.global.allForms[self.formIndex] = form;
          console.log("Form settings UPDATEs ", form)
        })

  }

}
function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
