<div [style]="getMyStyle()">
    <div *ngIf="overFlow==true" (click)="toggleExpand(false)">MINIMIZE</div>
    <div *ngIf="!overFlow" (click)="toggleExpand(true)">EXPAND</div>


<p-accordion *ngIf="editing" expandIcon="false" collapseIcon="false" [multiple]="false">
    <p-accordionTab *ngIf="query" iconPos="end">
        <ng-template pTemplate="header">
            <div class="settings">Settings</div>
        </ng-template>
        <ng-template pTemplate="content">

            <app-form [form]="queryForm" [formInput]="query" [autoSubmit]="false"
                (submitEvent)="outputEvent($event)"></app-form>


<!--            <div *ngIf="global.authuser.uid=='kvT6eKFxFHb3EKbVeCTusW86CrZ2'">
                <button (click)="moveReport(report)" class="scott-function" pButton pRipple type="button">
                    Save this report to Master
                </button>
         

            </div>
        -->

        </ng-template>
    </p-accordionTab>
</p-accordion>

<div *ngIf="result>=0 && query.style=='Style 1'" style="text-align: center; font-size: 32px;">
    {{query.label+": "+result}}
</div>

<div *ngIf="result>=0 && query.style=='Style 2'" 
    style="text-align: center; font-size: 22px; width: 120px; height: 120px; background-color:rgb(172, 252, 234); border: 1px solid black; margin: 8px;">
    {{query.label}}<br>
    <span style="font-size:35px">{{result}}</span>

</div>
</div>  