import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../../../environments/environment';
import { interval } from 'rxjs';
import { Globals } from 'src/app/globals';
import { Company, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../../../company-interface';
import { Product, Options } from '../../../product-int'
import { SelectItem, PrimeNGConfig, MessageService } from 'primeng/api';
import { Color } from 'exceljs';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';


interface Option {
  name: string,
  code: string
}


@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['../reserve.component.css', './groups.component.css']
})
export class GroupsComponent implements OnInit {
  @Input() selectedSize: number;
  @Input() selectedSizeUnder21: number;
  @Output() setPackageEvent = new EventEmitter<Product>();

  options: Option[];
  viewPackageTab: boolean[];

  selectedOption: Option;
  selectedPackage: Product;

  selectedOpt: number;
  priceUpdated: boolean = false;
  priceUpdatedColor: string;
  priceUpdatedIndex: number = 0;



  constructor(public global: Globals, private sanitizer: DomSanitizer,private messageService: MessageService) { }

  ngOnInit(): void {
    this.options = [
      { name: 'Include', code: 'Huh?' },

    ];

  //  window.setInterval(this.runCountdown(), 1000);

//    let timerId = setInterval(() => this.runCountdown(), 1000);

    console.log("Adult: ", this.selectedSize, this.selectedSizeUnder21);

    this.GetGroupItems();
  }

  outputProduct(value: Product) {
    console.log("Emit package ", this.selectedPackage);
    this.setPackageEvent.emit(value);
  }

  toggleSelect(item: Product) {
    var self = this;


    if (this.selectedPackage == item)
      this.selectedPackage = null;
    else {
      this.selectedPackage = item;
      this.priceUpdated = false;
    }
    this.outputProduct(this.selectedPackage);

    self.viewPackageTab.forEach(function (arrayItem, n) {
      if (arrayItem == true)
        arrayItem = false;
      self.viewPackageTab[n] = false;

    })

    console.log("sel package: ", this.viewPackageTab,);
  }

  onTabOpen(i) {
    var self = this;

    console.log("Open Package Tab: ", self.viewPackageTab, i);
    self.viewPackageTab[i] = true;

    return;
    self.viewPackageTab.forEach(function (item) {
      item = true;
    })
  }

  onTabClose(i) {
    var self = this;

    self.viewPackageTab[i] = true;
    console.log("Close Package Tab: ", self.viewPackageTab, i);

    return;

    self.viewPackageTab.forEach(function (item, n) {
      item = true;
      self.viewPackageTab[i] = true;
    })
  }


  gogogo(item: Product, opt: Options, e, index: number) {
    var self = this;

    var t = e.value;


    console.log("Option changed: ", t, e,this.selectedSize);
    var i = opt.list.indexOf(t);

    if (i != -1) {
      opt.chosen = opt.list[i];
      // Check price adjust xqty!!

      item.adjRetail += opt.adjust[i];
      item.adjRetailUnder21 += opt.adjust[i];
      var total = self.selectedSize * item.adjRetail;

      self.priceUpdated = true;
      self.priceUpdatedColor = "#FF9977FF";
      if (index >= 0)
        self.priceUpdatedIndex = index;

      if (this.selectedSizeUnder21)
        total += self.selectedSizeUnder21 * item.adjRetailUnder21;

        item.adjTotal = total;
        console.log("Adj====: ", opt.adjust[i], total, item.adjRetail, self.selectedSize);
      //      this.priceUpdatedTime = Date.now();
    }

  }
  priceUpdateColor(i:number) {
    var self = this;

    var c = "#FF9977FF";

    if (i != self.priceUpdatedIndex)
      c = "#000000FF";

 //   console.log (self.priceUpdatedIndex);
    return c;

  }


  calculateTotals(item: Product) {

    item.adjRetail = item.retail;
    item.adjRetailUnder21 = item.retailUnder21;

    var total = this.selectedSize * item.adjRetail;

    if (this.selectedSizeUnder21 && item.retailUnder21)
      total += this.selectedSizeUnder21 * item.adjRetailUnder21;

    item.adjTotal = total;

  }
  getPackageTotal(item: Product) {
    var self = this;


    var total = this.selectedSize * item.retail;

    if (this.selectedSizeUnder21 && item.retailUnder21)
      total += this.selectedSizeUnder21 * item.retailUnder21;



    return total;
  }
  optionChanged(opt: Option, option: string) {

    console.log("Option changed: ", opt, option);


  }
  GetGroupItems() {
    var self = this;
    var db = firebase.firestore();

    self.viewPackageTab = [];

    const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").where("groupMenu", "==", true)

  //  db.collection("products").where("companyID", "==", self.global.myCompany.id).where("groupMenu", "==", true)
      .get()
      .then((querySnapshot) => {
        self.global.groupItems = [];
        querySnapshot.forEach((doc) => {

          const data = <Product>doc.data();

          if (data.html) {
            data.safeHtml = self.sanitizer.bypassSecurityTrustHtml(data.html);
           // console.log ("HTML: ", data.safeHtml);
          }

          self.calculateTotals(data);

          data.backColor = "#24A19C";
          self.viewPackageTab.push(false);
          self.global.groupItems.push(data);

        });
        console.log("Group Items: ", self.global.myCompany.id, self.global.groupItems);

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  runCountdown() {
    var self = this;

//    console.log("tick ",self.priceUpdated, self.priceUpdatedIndex);
    if (self.priceUpdated) {
      self.priceUpdatedColor = "#44aabbff";
      var id = 'price-updated' + self.priceUpdatedIndex;
      console.log("tick ",id);
  //    document.getElementById(id).style.color = self.priceUpdatedColor;
      
    }
  }

}


