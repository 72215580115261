import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter, ElementRef } from '@angular/core';
import { Form, Query, When, Report, FieldInfo, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, News, Media } from "../user-interface";
import { set, parseISO, startOfDay, endOfDay, getMilliseconds } from "date-fns";
import { FormService } from '../form.service';
import { HttpClient } from '@angular/common/http';
import { WhereFilterOp } from 'firebase/firestore';
import { MediaService } from '../media/media-functions/media-functions';
import { Logger } from 'src/app/functions';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.scss', '../../common.scss']
})

export class CaptureComponent implements OnInit {
  @Input() editing: boolean = false;
  @Input() videoAllowed: boolean = false;
  @Input() destination: string = 'user';

  @ViewChild('imageDiv', { static: false }) imageDiv: ElementRef;

  media: Media[] = [];

  constructor(public global: Globals,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private formService: FormService,
    private mediaService: MediaService
  ) {

  }

  ngOnInit(): void {
    console.log("Capture/Upload ", this.editing, this.destination,)

  }


  loadImg() {
    var self = this;
    const img: HTMLImageElement = this.imageDiv.nativeElement;
    self.media[0].width = img.naturalWidth;
    self.media[0].height = img.naturalHeight;
    // So now it's loaded let's make some thumbnails!!
    // Add aspect tag
    // decide what sizes for thumbnail
    if (img.naturalWidth > img.naturalHeight) {
      // width but by how much
      var wideness = 1 - ((img.naturalWidth - img.naturalHeight) / img.naturalWidth)
    }
    else {
      var tallness = 1 - ((img.naturalHeight - img.naturalWidth) / img.naturalHeight)
    }

    console.log("Let's make some thumbnails! ", wideness, self.media[0], img.naturalWidth, img.naturalHeight);
    // let's set up temporary thumb urls from original

    var path = "";
    var bucket = "user-media";
    if (self.destination.toLowerCase() == 'user') {
      path = self.global.authuser.uid + "/images/"
    }
    else {
      path = self.global.myCompany.id + "/images/"
      bucket = "store-content"
    }

    if (!self.media[0].otherSizes) self.media[0].otherSizes = {};

    if (img.naturalWidth > 75 || img.naturalHeight > 75) {
      self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "thumb_" + self.media[0].name, 75, 75)
      self.media[0].otherSizes["thumb"] = { width: 75, height: 75 };
    }
    var shape = 'square';
    if (wideness && wideness < .8)
      shape = 'wide';
    if (tallness && tallness < .8)
      shape = 'tall'

    if (!self.media[0].tags) self.media[0].tags = []
    self.media[0].tags.push(shape)

    if (shape == 'wide') {
      if (img.naturalWidth > 266) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "small_" + self.media[0].name, 266, 150)
        self.media[0].otherSizes["small"] = { width: 266, height: 150 };
      }
      if (img.naturalWidth > 533) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "med_" + self.media[0].name, 533, 300)
        self.media[0].otherSizes["med"] = { width: 533, height: 300 };
      }
      if (img.naturalWidth > 1600) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "large_" + self.media[0].name, 1600, 900)
        self.media[0].otherSizes["large"] = { width: 1600, height: 900 };
      }
    }
    if (shape == 'tall') {
      if (img.naturalHeight > 266) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "small_" + self.media[0].name, 150, 266)
        self.media[0].otherSizes["small"] = { width: 150, height: 266 };
      }
      if (img.naturalHeight > 533) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "med_" + self.media[0].name, 300, 533)
        self.media[0].otherSizes["med"] = { width: 300, height: 533 };
      }
      if (img.naturalHeight > 1600) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "large_" + self.media[0].name, 900, 1600)
        self.media[0].otherSizes["large"] = { width: 900, height: 1600 };
      }
    }
    if (shape == 'square') {
      if (img.naturalHeight > 150) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "small_" + self.media[0].name, 150, 266)
        self.media[0].otherSizes["small"] = { width: 150, height: 150 };
      }
      if (img.naturalHeight > 300) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "med_" + self.media[0].name, 300, 533)
        self.media[0].otherSizes["med"] = { width: 300, height: 300 };
      }
      if (img.naturalHeight > 900) {
        self.mediaService.createThumbnail(bucket, path + self.media[0].name, path + "large_" + self.media[0].name, 900, 1600)
        self.media[0].otherSizes["large"] = { width: 900, height: 900 };
      }
    }

    //      self.autoTag(results.data.url)
    if (self.destination.toLowerCase() == 'user')
      self.mediaService.addImageToUser(self.media[0]);
    else
      self.mediaService.addImageToCompany(self.media[0])
  }


  newUpload(event: any) {
    var self = this;

    var thisMedia: Media = {}


    self.mediaService.newUpload(thisMedia, event, self.destination, self.callback, self)
  }

  callback(mySelf, resultsUrl, file, name, media) {
    console.log("Capture got media: ", resultsUrl, file, name, media)

 //   Logger("Media Upload Callback", mySelf.global.authuser.uid, "", resultsUrl, file, name);

    if (!mySelf.destination || mySelf.destination.toLowerCase() == 'user' || mySelf.destination == 'Both') {
      var media = mySelf.mediaService.createMedia("user-media",
        mySelf.global.authuser.uid, file.fileType, resultsUrl, file, name)
    }
    else if (mySelf.global?.myCompany.id && mySelf.destination.toLowerCase() != 'user') {
      var media = mySelf.mediaService.createMedia("store-content",
        mySelf.global.myCompany.id, file.fileType, resultsUrl, file, name)

      media.companyId = mySelf.global.myCompany.id;
      media.store = mySelf.global.myCompany.store;
    }


    mySelf.media.push(media);

  }

}
