<p-accordion expandIcon="false" collapseIcon="false" [multiple]="true">

    <p-accordionTab iconPos="end">
        <ng-template pTemplate="header">
            <div fxLayout="row" fxLayoutAlign="center none"
                style="text-align:center;background-color: #e3d8ee55; width:100%">
                <div class="place-feature2" style="width:100%">
                    {{field.title}}
                    <span>Settings</span>
                  

                </div>
            </div>


        </ng-template>
        <ng-template pTemplate="content">
            type: {{field.type}}
            <div *ngIf="field.type=='calendar'">
                <app-form formName="masterCalendarSettings" [formInput]="field.calendar"
                [autoSubmit]="false" 
                (submitEvent)="calOutputEvent($event)"></app-form>
            </div>
            <app-form [formData]="field" [form]="editForm" [autoSubmit]="true"
            (objectEvents)="fieldEvents($event)"    
            (submitEvent)="outputEvent($event)"></app-form>

        </ng-template>
    </p-accordionTab>


    <p-accordionTab iconPos="end">
        <ng-template pTemplate="header">
            <div fxLayout="row" fxLayoutAlign="center none"
             style="text-align:center;background-color: #e3d8ee55; width:100%">
                <div class="place-feature2" style="width:100%">
                    <span>Logic</span>

                </div>
            </div>


        </ng-template>
        <ng-template pTemplate="content">
        <div style="width:100%">  

            <app-logic-editor [form]="form" [field]="field" [logic]="field.logic"></app-logic-editor>

        </div>
        </ng-template>
    </p-accordionTab>