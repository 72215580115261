<div class="pretty-center">

    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="true" (onOpen)="onTabOpen(i)"
        (onClose)="onTabClose(i)" *ngFor="let item of global.groupItems; let i = index">
        <div *ngIf="!selectedPackage || selectedPackage==item" fxFlex fxLayout="column" fxLayoutAlign="none stretch">

            <p-accordionTab [(selected)]="viewPackageTab[i]">
                <ng-template pTemplate="header">

                    <div fxLayout="row wrap" fxLayoutAlign="space-between center" style="width: 100%;">

                        <span class="pretty-title">{{item.title}}</span>
                        <span *ngIf="item.groupExclusive">Group Discount</span>

                        <div *ngIf="item.adjRetail" class="pricing" fxLayout="column" fxLayoutAlign="center right">
                            <div>{{selectedSize}}&nbsp;Adults(21+):&nbsp;@{{item.adjRetail|currency}}</div>

                            <div *ngIf="item.adjRetailUnder21 && selectedSizeUnder21>0">{{selectedSizeUnder21}}&nbsp;Guests 5-20 (under 5
                                free):&nbsp;@{{"
                                "+item.adjRetailUnder21|currency}}</div>
                            <div>Total:&nbsp;{{item.adjTotal | currency}}</div>
                            <div *ngIf="priceUpdated" id="price-updated{{i}}" [style.color]="priceUpdateColor(i)">Price
                                updated</div>


                            <div style="font-size: .8rem">does not include tax or tips</div>

                            <div *ngIf="item.allOptions" style="text-align: center;">This package has options.</div>
                            <div class="pretty-subtitle-center">Reserve now, pay at venue. Modify/Cancel any time.</div>
                            <br>
                            <div *ngIf="!viewPackageTab[i]" style="font-size:.9rem; text-align: center;">Click for details</div>
                            <div *ngIf="viewPackageTab[i]" style="font-size:.9rem; text-align: center;">Hide details</div>

                        </div>

                    </div>

                </ng-template>

                <ng-template pTemplate="content">
                    <div>

                        <!--      GROUP OPTIONS -->

                      <div *ngIf="item.allOptions">

                        <div *ngFor="let opt of item.allOptions; let i2 = index">
                            <div class="pretty-center">{{opt.title}}<br></div>
                            <div class="option-button" fxLayout="row wrap" fxLayoutAlign="center center">
                                <p-selectButton (onChange)="gogogo(item, opt, $event, i)" [options]="opt.list"
                                    [ngModel]="opt.chosen" optionLabel="">
                                </p-selectButton>

                            </div>

                        </div>

                    </div>

                    <div style="margin: 12px; margin-top:15px;">

                        <button *ngIf="item==selectedPackage" class="button-14" role="button" (click)="toggleSelect(item)">Remove Package</button>

                        <button *ngIf="item!=selectedPackage" class="button-14" role="button" (click)="toggleSelect(item)">Select Package</button>
                    </div>
                  
                        <div *ngIf="item.image1" style="width:100%">
                            <img alt="salad" class="full-size-image" src={{item.image1}}>
                        </div>

                        <div *ngIf="item.safeHtml">
                            <div class="card__desc" [innerHTML]="item.safeHtml"></div>
                        </div>

                        <div *ngIf="!item.safeHtml" class="pretty-center-blue" [innerHtml]="item.fullDescription"></div>
                        <br>
                    </div>
                </ng-template>

            </p-accordionTab>
        </div>
    </p-accordion>
</div>