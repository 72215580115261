import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Share, Query, When, Report, FieldInfo, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { set, startOfDay, endOfDay, getMilliseconds } from "date-fns";
import { FormService } from '../form.service';
import { HttpClient } from '@angular/common/http';
import { WhereFilterOp } from 'firebase/firestore';

@Component({
  selector: 'app-sharing',
  templateUrl: './sharing.component.html',
  styleUrls: ['./sharing.component.css', '../../common.scss']
})
export class SharingComponent {
  @Input() type: string;
  @Input() linkURL: string = "";
  @Input() share: Share;
  @Output() updateShare = new EventEmitter<any>();


  constructor(public global: Globals, public registerService: RegisterService,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private formService: FormService,
    public nav: NavService) {

  }

  ngOnInit(): void {
    if (!this.share) this.share = {}
    console.log("Share: ", this.linkURL)
    this.share.linkURL = this.linkURL;
 //   this.share.shareDept = null;

  }

  fieldEvent (field: Field) {
    var self = this;

    // If user clicked Remove, delete the groupIndex
    console.log("Share field event ", field)
    if (field.title=='remove') {
      self.share.sharedWith.splice(field.groupIndex, 1)

    // Return data to the caller then close this
    self.updateShare.emit(self.share);
    }
    if (field.title=='clipboard') {
      var text = ""
      if (self.type == 'app') text = "https://napkinhq.com" + self.share.linkURL
      else if (self.type == 'chatBot') text = "https://napkinhq.com" + self.share.linkURL

      navigator.clipboard.writeText(text)
        .then(() => console.log('Text copied!'))
        .catch(err => console.error('Error copying text: ', err));


    }

  }
  outputEvent(form: Form) {
    var self = this;
    var getNewData = false;
    var updateFields = false;

    console.log("Sharing form Input ", form)
    
    self.formService.napkinFieldsToObject(form, self.share)
    console.log("Object output ",self.share)

    self.share = removeUndefined(self.share)
    // Return data to the caller then close this
    self.updateShare.emit(self.share);

  }

}
function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
