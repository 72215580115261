export class Minimap extends Phaser.Scene {
    self;
    masterSelf;
    minimap;
    miniDrag;

    constructor() {

        super({
            key: 'minimap', active: true
        });

    }
    preload() {

    }

    create() {
        var self = this;
        const sceneB = <any>self.scene.get('VIP');
        this.masterSelf = sceneB.registry.get('masterSelf');
        console.log("CREATE Minimap ")

        var width = 320 - 32;
        var height = 240 - 24;
        var x = 800 - (width + 40);
        var y = 0;
        var zoom = 1;

        const container = this.add.container(x, y);
        container.bringToTop(null);
        container.setAlpha(1)
        x= 0;y=0;// now relative to container

        const dragMe = this.add.rectangle(x, y, width, 24, 0x34def2, .75)
        dragMe.setDisplayOrigin(0, 0)
        dragMe.setOrigin(0, 0)

        const zoomIn = this.add.rectangle(x + 140, 2, 50, 20).setFillStyle(0)
        .setOrigin(0).setInteractive();
        const t1 = this.add.text (x+144,4, "In")
        zoomIn.on('pointerup', (pointer, x, y, event) => {
            event.stopPropagation();
            zoom *= 1.25;
            //  this.minimap.setZoom(zoom);
            self.masterSelf.myScene.cameras.main.setZoom(zoom)

            //  And this camera is 400px wide, so -200
            //  this.minimap.scrollX = Phaser.Math.Clamp(this.player.x - 200, 800, 2000)
        })
        const zoomOut = this.add.rectangle(x + 200, 2, 50, 20).setFillStyle(0)
        .setOrigin(0).setInteractive();
        const t2 = this.add.text (x+204,4, "Out")
        zoomOut.on('pointerup', (pointer, x, y, event) => {
            event.stopPropagation();
            zoom *= .75;
            self.masterSelf.myScene.cameras.main.setZoom(zoom)
        })
        t1.setDisplayOrigin(0, 0)
        t1.setOrigin(0, 0)
        t2.setDisplayOrigin(0, 0)
        t2.setOrigin(0, 0)

        container.add([dragMe,zoomIn, zoomOut,t1,t2])
        container.setSize(width, 24);
        container.setInteractive({ draggable: true })

        x = 800 - (width + 40);
        y += 24;


        //  The miniCam is 400px wide, so can display the whole world at a zoom of 0.2
        this.minimap = this.masterSelf.myScene.cameras.add(x, y, width, height).setZoom(0.1).setName('mini')
        this.minimap.setBackgroundColor(0x002244)
        this.minimap.scrollX = 1600 - 310;
        this.minimap.scrollY = 1200 - 234;

        console.log("MINI ", this.minimap, self.masterSelf.myScene.cameras.main.worldView)
        var r = self.masterSelf.myScene.cameras.main.worldView

        self.masterSelf.myScene.cameras.main.on('pan_complete', (pointer) => {
            console.log("PANNED CAMERA")
        });
        this.miniDrag = this.add.rectangle(x, y,
            80, 60, 0xdffef2, .75).setInteractive({ draggable: true });
        this.miniDrag.setDisplayOrigin(0, 0)
        this.miniDrag.setOrigin(0, 0)
        this.miniDrag.setDepth(20000)


        container.on('dragstart', (pointer, dragX, dragY) => {
            //   this.draggingMe = true;
        });
        container.on('dragend', (pointer, dragX, dragY) => {
            //    this.draggingMe = false;
        });
        container.on('drag', (pointer, dragX, dragY) => {
          
            container.setPosition(dragX, dragY);
            self.minimap.setPosition(dragX, dragY + 24);
          //  dragMe.setPosition(dragX, dragY);
            this.miniDrag.setPosition(dragX, dragY + 24)

        });

        this.miniDrag.on('drag', (pointer, dragX, dragY) => {
            this.miniDrag.setPosition(dragX, dragY)
            x = (dragX - this.minimap.x) * 10;
            y = (dragY - this.minimap.y) * 10;
            self.masterSelf.myScene.cameras.main.setScroll(x, y);
        });

    }

    setMiniDrag(x, y) {
        var self = this;
        // console.log ("CAMCAM")

        //   var r = self.masterSelf.myScene.cameras.main.worldView
        this.miniDrag.setPosition(this.minimap.x + (x / 10), this.minimap.y + (y / 10))

    }

}