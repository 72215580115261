
import { Component, OnInit, Input, Output, EventEmitter, BootstrapOptions } from '@angular/core';
import { Globals } from 'src/app/globals';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../user-interface';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import firebase from 'firebase/compat/app';
import 'firebase/storage';
import 'firebase/compat/storage';
import { doc, onSnapshot, DocumentReference } from "firebase/firestore";
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { Howl, Howler } from 'howler';
import { Time } from '@angular/common';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { TagInputModule } from 'ngx-chips';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Company, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';
import { Logger } from 'src/app/functions';

export interface jVal {
  display: string;
  value: string;
}

const sound = new Howl({
  src: ['../../../assets/sound-newmsg.mp3']
});


@Component({
  selector: 'app-chatnow',
  templateUrl: './chatnow.component.html',
  styleUrls: ['./chatnow.component.scss']
})
export class ChatnowComponent implements OnInit {

  @Input() store: string = "";
  @Input() user: string = "";
  @Output() resultsEvent = new EventEmitter<Results[]>();
  @Output() infoEvent = new EventEmitter<Kpanel>();

  contact: Contact;
  groups: msg_Groups[];
  messages: Message[];
  currentGroupId: string;
  allowNewConnection: boolean = true;
  editChat: Message;
  videoSearch: string;
  videos: any[];
  info: Kpanel;
  result: Results[];
  stickyNotes: jVal[]; //
  groupList: jVal[] = [];
  groupListInput: string = "";

  //chats: Chat[];
  //conversations: Conversation[];
  newMsg: Chat;
  index: Number;
  activeChat: boolean[];
  visible: boolean = false;
  liveChat: boolean = true;
  display: boolean = false;
  dataMode: string = "";
  routing: boolean = false;
  activeTagging: boolean = false;

  pw: string;
  foundUser: boolean = false;
  inputCreds: boolean = false;
  inputCredsnoUser: boolean = false;
  loginError: boolean = false;
  userDataValid: boolean = false;
  newUserID: string;

  FirstName: string;
  LastName: string;
  Email: string;

  /* List of available functions */
  viewFunctions: boolean = false;

  chatFunctions: string[] = ["Internet search", "map", "Video search",
    "Get Image", "Sticky Note"];

  storeChatFunctions: string[] = ["Get Image"];


  constructor(public global: Globals, public nav: NavService,
    public registerService: RegisterService, private route: ActivatedRoute,
  ) {
    firebase.initializeApp(environment.firebase);
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER

  }

  ngOnInit(): void {

    this.result = [];

    this.allowNewConnection = false;
    this.GetMyGroups(this.user, this.store);
    const members = [this.user, this.store];
    //  this.CheckExistingGroup(members);  dont need on store side
    this.newMsg = {
      from: this.global.myCompany.chatServiceEmail, to: "",
      msg: "",
    }

  }

  /*
    setContact(info: Contact) {
      // After User has logged in or created account
  
      console.log("User contact: ", info);
  
      this.contact = info;
      if (!this.global.authuser) {
        console.log("contact ", this.contact)
  
        if (!this.global.authuser) {
          if (!this.contact.firstName || this.contact.firstName.length == 0
            || !this.contact.lastName || this.contact.lastName.length == 0
          ) { alert("Please enter a name."); return; }
          //  if (!ValidateEmail(this.Email)) return;
          var newUserID = this.registerService.GetUID();
          this.registerService.RegisterUser(this.contact.email, this.contact.firstName, this.contact.lastName);
  
        }
  
      }
  
  
  
  
      if (this.global.authuser && this.store) {
  
        this.user = this.global.authuser.uid;
        this.allowNewConnection = false;
        this.GetMyGroups(this.user, this.store);
        const members = [this.user, this.store];
        this.CheckExistingGroup(members);
        this.newMsg = {
          from: this.global.authuser.email, to: "",
          msg: "",
        }
  
      }
  
    }
  
    afterLogin() {
      var self = this;
      console.log("afterLoginEvent: ");
  
      if (self.global.authuser && self.store) {
        self.user = self.global.authuser.uid;
        self.allowNewConnection = false;
        self.GetMyGroups(self.user, self.store);
        const members = [self.user, self.store];
        self.CheckExistingGroup(members);
        self.newMsg = {
          from: self.global.authuser.email, to: "",
          msg: "",
        }
      } else {
        self.GetMyGroups(self.global.authuser.uid);
        self.newMsg = {
          from: self.global.authuser.email, to: "",
          msg: "",
        }
      }
    }
  
    afterUserInit(self: this) {
      console.log("Init Chat, user loaded ", self.global.authuser);
      console.log("iStore: ", self.store);
      if (!self.global.authuser) return;
  
      if (self.global.authuser && self.store) {
        self.user = self.global.authuser.uid;
        self.allowNewConnection = false;
        self.GetMyGroups(self.user, self.store);
        const members = [self.user, self.store];
        self.CheckExistingGroup(members);
        self.newMsg = {
          from: self.global.authuser.email, to: "",
          msg: "",
        }
      } else {
  
  
  
        self.GetMyGroups(self.global.authuser.uid);
        self.newMsg = {
          from: self.global.authuser.email, to: "",
          msg: "",
        }
      }
  
    }
  */

  Clicked(conversation: msg_Groups, command: string) {



    switch (command) {
      case "Finished":
        conversation.status = "Finished";
        console.log(command, conversation);
        for (var n = 0; n < this.activeChat.length; n++) this.activeChat[n] = false;
        this.updateGroupStatus(conversation);
        break;
      case "Waiting":
        conversation.status = "Waiting";
        console.log(command, conversation);
        for (var n = 0; n < this.activeChat.length; n++) this.activeChat[n] = false;
        this.updateGroupStatus(conversation);
        break;
      case "Route":
        this.routing = !this.routing;
        if (!this.global.myCompany.departments)
          this.global.myCompany.departments = [];
        break;
        case "Active":
          this.activeTagging = !this.activeTagging;
          if (!this.global.myCompany.activeTags)
            this.global.myCompany.activeTags = [];
          break;

    }

  }

  onTagAdded (event:jVal) {
    console.log ("Added tag: ", event);
    this.updateTags (event.value);

  }

  updateTags(tag) {
    var self = this;
    var db = firebase.firestore();

 //   if (!this.global.myCompany.activeTags) this.global.myCompany.activeTags = [];
 //   this.global.myCompany.activeTags.push(tag);

    var docRef = db.collection("company").doc(this.global.myCompany.id);

    var setWithMerge = docRef.set({
      activeTags: self.global.myCompany.activeTags
    }, { merge: true });

    console.log("Department Added");

  }


  RouteDepartment(conversation: msg_Groups, dept) {
    conversation.status = "Active";
    conversation.routed = dept;
    this.routing = false;

    for (var n = 0; n < this.activeChat.length; n++) this.activeChat[n] = false;
    this.updateGroupRouting(conversation);
    this.updateGroupStatus(conversation);

  }
  setActiveTag(conversation: msg_Groups, tag) {
    conversation.status = "Active";
    conversation.tag = tag;
    this.activeTagging = false;

    for (var n = 0; n < this.activeChat.length; n++) this.activeChat[n] = false;
    this.updateGroupTag(conversation);
  //  this.updateGroupStatus(conversation);

  }

  onTabClick() {

  }

  functionMode() {
    this.dataMode = "functions"
    this.viewFunctions = true;
  }
  hideFunctions() {
    this.dataMode = ""
    this.viewFunctions = false;

  }

  hideFindVideo() {
    this.dataMode = ""
    this.visible = false;

  }

  cancel() {
    this.dataMode = "";
  }
  setFunction(selection: string) {

    if (selection.trim().toLowerCase() == 'map' && (!this.newMsg.mapAddress || !this.newMsg.mapAddress.length)) {
      this.dataMode = 'address';
      this.newMsg.mapAddress = "";
      console.log("MAP: ", this.newMsg);
      this.viewFunctions = false;
      this.newMsg.feature = "map";
      return;
    }

    if (selection.trim().toLowerCase() == 'drawing') {
      this.viewFunctions = false;
      this.newMsg.feature = "draw";
      this.SendMsg(this.messages.length);
      return;
    }

    if (selection.trim().toLowerCase() == 'get image' && (!this.newMsg.media || (this.newMsg.media && !this.newMsg.media.length))) {
      this.dataMode = 'image';
      this.viewFunctions = false;
      this.newMsg.feature = "get image";
      return;
    }
    if (selection.trim().toLowerCase() == 'video search' && (!this.newMsg.media || (this.newMsg.media && !this.newMsg.media.length))) {
      // USER WANTS TO INSERT Video
      this.dataMode = 'video';
      this.viewFunctions = false;
      this.visible = true;
      this.newMsg.feature = "video search";
      return;
    }
    if (selection.trim().toLowerCase() == 'internet search' && (!this.newMsg.searchResults || (this.newMsg.searchResults && !this.newMsg.searchResults.length))) {
      // USER WANTS TO INSERT IMAGE
      this.dataMode = 'search';
      this.viewFunctions = false;
      this.newMsg.feature = "internet search";
      console.log("SEARCH...");
      return;
    }
    if (selection.trim().toLowerCase() == 'sticky note' && (!this.newMsg.stickyTitle || (this.newMsg.stickyTitle && !this.newMsg.stickyTitle.length))) {
      // USER WANTS TO INSERT IMAGE
      this.dataMode = 'sticky';
      this.newMsg.stickyTitle = "";
      this.newMsg.stickyNotes = [];
      this.viewFunctions = false;
      this.newMsg.feature = "sticky note";
      this.stickyNotes = [];

      console.log("StickyNote...");
      return;
    }

  }

  setQuery(query: string) {
    // Got results back so let's save them

    this.newMsg.searchQuery = query;
  }
  setInfo(info: Kpanel) {
    // Got results back so let's save them
    console.log("Info: ", info);
    this.newMsg.searchInfo = info;
  }
  setResult(result: Results[]) {
    var self = this;

    if (!result) { //cancelled
      self.dataMode = "";
      return;
    }

    // Got results back so let's save them
    console.log("RESULTS: ", result);
    self.newMsg.searchResults = result;
    self.newMsg.searchTabOpen = true;
    self.SendMsg(self.messages.length);
    self.dataMode = "";


  }
  onTabOpen(e: any, i) {
    e.stopPropagation();
    console.log("o-i ", i)
  }

  onTabClosed(e: any, i) {
    e.stopPropagation();
    console.log("C-i ", i)
  }

  getBackgroundcolor(chat: Message) {
    if (chat.sentBy == this.global.myCompany.chatService) {
      return "rgb(216, 228, 239)";
    }
    else {
      if (chat.notReadBy && chat.notReadBy.includes(this.global.myCompany.chatService))
        return "rgb(240, 221, 16)"

      else
        return "rgb(40, 221, 16)"
    }

  }
  getTextAlign(chat: Message) {
    if (chat.sentBy == this.global.myCompany.chatService)
      return "left";
    else
      return "right";
  }

  getChatPlaceHolder() {
    // var x = "try entering a command; image, map, video, draw, play";

    var x = "Enter message"

    if (this.store) return "";
    else return x;

  }

  goLink(url: string) {
    if (url)
      window.open(url);

  }

  msgClicked(chat: Message) {
    console.log("Clicked ", chat);

    if (chat.msg.startsWith("http")) { console.log("url"); return; }
    if (chat.sentBy == this.global.authuser.uid) { //Me
      this.editChat = chat;
      this.newMsg = <Chat>chat;

    }

  }

  onChatOpen(evo: any, index) {
    var self = this;

    //   e.stopPropagation();

    // DONT WORK?  self.activeChat.forEach (function(tab:boolean){ tab = false;}) // CLOSE ALL TABS

    for (var n = 0; n < self.activeChat.length; n++) {
      self.activeChat[n] = false;
    }


    self.activeChat[index] = true;
    console.log("active: ", self.activeChat, index);

    self.currentGroupId = self.groups[index].id;

    self.fetchMessagesByGroupId(self.currentGroupId, index);


  }

  GetGroupName(i): string {
    var self = this;

    var members = "";

    if (self.store) {
      if (self.global.authuser.uid == self.user)
        members = (self.groups[i].names[1]); // Bit of a HACK
      else members = self.groups[i].names[0];

      return members;
    }

    self.groups[i].members.forEach(function (id, index) {
      if (id != self.global.authuser.uid) {
        if (members) members += ", "
        members += self.groups[i].names[index];
      }
    })
    return members;

  }

  GetGroupDept(i): string {
    var self = this;

    var members = "";

    if (self.store) {

      if (self.groups[i].routed) {
        members += self.groups[i].routed;
        members += " ";
      }
      if (self.groups[i].tag)
        members += self.groups[i].tag;

      return members;
    }
  }

  GetGroupTimer(i): string {
    var self = this;

    var members = "";

    if (self.store) {
      if (self.groups[i].lastTime) {
        const d = <Timestamp>self.groups[i].lastTime;
        members += " " + this.getTimeSince(d);
      }
      return members;
    }
  }

  getTimeSince(d: Timestamp): string {

    var date = <Date>d.toDate();

    var sec = (Date.now() - d.toMillis()) / 1000;

    //    console.log (sec);

    //    if (sec < 90)
    //      return sec +" sec";

    var min = sec / 60;

    if (min < 60)
      return min.toFixed(0) + " min";

    var hours = min / 60;
    if (hours < 24)
      return hours.toFixed(1) + " hours";

    var days = hours / 24;
    return days.toFixed(1) + " days"









  }

  getTextColor(chat: Message): string {
    if (chat.sentBy == this.global.authuser.uid) // ME
      return 'rgb(0,45,0)';
    else return 'rgb(83, 104, 176)';
  }

  GetAccount(con: msg_Groups, chat: Message) {
    return con.id;

    //  const allSpacesRemoved = str.replaceAll(' ', '');


  }
  GetTopic(con: msg_Groups, chat: Message) {
    return chat.sentAt.toMillis().toString();
  }
  SendMsg(i) {
    console.log("SEND");

    this.SendNewMsg(i);

  }

  async SendNewMsg(i) {
    var self = this;

    this.saveMessage(<Message>self.newMsg, self.currentGroupId, i);

  }
  sendSticky() {
    var self = this;

    if (!self.newMsg.stickyTitle) {
      self.dataMode = "";
      self.stickyNotes = [];
      self.newMsg.stickyTitle = "";
      self.newMsg.stickyNotes = [];
      return;
    }

    self.newMsg.stickyNotes = [];
    self.stickyNotes.forEach(function (item) {
      self.newMsg.stickyNotes.push(item.value);
    })
    self.SendNewMsg(self.messages.length);
    self.dataMode = "";
    self.stickyNotes = [];
    self.newMsg.stickyTitle = "";
    self.newMsg.stickyNotes = [];


  }
  UpdateMsg(msg: Message) {
    this.updateMessage(msg, this.currentGroupId);
    this.editChat = null;
    this.newMsg.msg = "";
  }

  getMap(chat: Chat, i) {
    this.geoCode(this.newMsg.mapAddress, i);

  }

  addImage(chat: Message, i) {
    if (this.newMsg.media) { // Add Check for type of file at URL
      this.SendMsg(this.messages.length); // it's new!
      this.dataMode = "";
    }
  }

  ChooseVideo(v) {
    if (!this.newMsg.media) this.newMsg.media = [];
    this.newMsg.media.push(v);
    this.SendMsg(this.messages.length);
    this.visible = false;
    this.dataMode = "";
  }
  FindVideo(search: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        Authorization: '563492ad6f91700001000001654b43a8e2d04946ad43a2468e61c945',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'PexelsdimasV1.p.rapidapi.com'
      }
    };
    var search: string = 'https://pexelsdimasv1.p.rapidapi.com/videos/search?query=' + search + '&per_page=15&page=1';
    console.log("V Search: ", search);
    fetch(search, options)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        var r = <Video[]>response.videos;
        self.videos = [];
        r.forEach(function (item: Video) {

          var smallest: number;
          var smallIndex: number;
          item.video_files.forEach(function (v: VideoFile, i) {
            if (!smallest || (v.height * v.width) < smallest) {
              smallest = v.height * v.width;
              smallIndex = i;
            }
          })

          self.videos.push(item.video_files[smallIndex].link);
        })
        console.log("Videos: ", self.videos, r);

      })
      .catch(err => console.error(err));
  }


  upload(event: any, chat: Message) {
    var self = this;

    var fn = event.target.value.lastIndexOf("\\");
    console.log(fn);
    var imageName = event.target.value;
    imageName = imageName.slice(fn + 1);

    console.log("uploading image ", imageName);

    var index = self.messages.length;

    const filePicker = document.querySelector('imageLoad' + index.toString()); //HACK use id


    console.log("Image: ", event, filePicker);
    // Now get reference to upload

    const ref = firebase.storage().ref();
    const file = event.target.files[0];
    //const name = (+new Date()) + '-' + file.name;
    const metadata = {
      contentType: file.type
    };

    const name = ('chat-images/' + imageName);

    const task = ref.child(name).put(file, metadata);
    task
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then((url) => {
        Logger ("Media Upload - Chat", self.global.authuser.uid, self.global.myCompany.store,url, name )

        console.log(url);
        chat.media = url;
        self.SendMsg(index);
        self.dataMode = "";
        // this.updateMessage(chat, this.currentGroupId);

      })
      .catch(console.error);



  }

  // NEW DB CODE

  firstTime = true;
  GetMyGroups(uid, limituid?: string) {
    const db = firebase.firestore()
    console.log('Gettin groups: ', this.store);
    const vm = this;

    const xid = this.global.myCompany.id;
    const index = vm.global.authuser.creds.findIndex(item => item.id === xid);


    return new Promise((resolve, reject) => {
      const groupRef = db.collection('msg_groups')
      groupRef
        .where('members', 'array-contains', uid)
        .onSnapshot((querySnapshot) => {
          console.log("On Group Snapshot")
          const allGroups = []
          var count = 0;
          if (!vm.activeChat)
            vm.activeChat = []
          querySnapshot.forEach((doc) => {
            const data = <msg_Groups>doc.data()
            data.id = doc.id
            var included = true;
            if (limituid) {
              included = data.members.includes(limituid);

            }
            if (included) {
              console.log("included ", data, index,vm.global.authuser.creds[index]) ;

              count++;
              if (!data.status || (data.status && data.status != "Finished" && data.status != "Waiting" && !data.routed)) {
                allGroups.push(data);
                console.log("pushed")
              }
              else if (data.routed && vm.global.authuser.creds[index].departments && vm.global.authuser.creds[index].departments.includes(data.routed)
                && (!data.status || (data.status == "Active"))
              ) {
                allGroups.push(data);
                console.log("pushed")
              }
              if (vm.activeChat.length < count)
                vm.activeChat.push(false);
            }

          })

          vm.groups = allGroups
          //   if (allGroups.length == 1) vm.onChatOpen(null, 0);
          console.log("Groups: ", allGroups);
          if (vm.firstTime) {
            vm.firstTime = false;
            vm.groups.forEach(function (group) {
              vm.fetchNewByGroupId(group.id);
            })
          }

        })
    })
  }

  CheckExistingGroup(members: string[]) {
    // If no message group then create for store & cusstomer
    const db = firebase.firestore()

    const vm = this
    return new Promise((resolve, reject) => {
      const groupRef = db.collection('msg_groups')
      groupRef
        .where('members', '==', members)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.createServiceGroup(members[0], members[1]);
          }
        })

    })
  }

  // Monitor Messsages for NEW notifications
  fetchNewByGroupId(groupId) {
    const db = firebase.firestore()
    const vm = this;

    db.collection('msg_messages')
      .doc(groupId.trim())
      .collection('allMessages')
      .orderBy('sentAt')
      .onSnapshot((querySnapshot) => {
        //  vm.groups.findIndex
        var f = vm.groups.findIndex(function (post, index) {
          if (post.id == groupId)
            return true;
        });
        vm.groups[f].newMessages = 0;


        //   const allMessages = []
        querySnapshot.forEach((doc) => {
          if (doc) {
            const data = <Message>doc.data()
            data.id = doc.id
            console.log("NEW Message ", data);
            vm.groups[f].status = "Active";
            vm.updateGroupStatus(vm.groups[f]);

            // Track unread messsages
            if (data.notReadBy) {
              if (data.notReadBy.includes(vm.global.myCompany.chatService)) {
                vm.groups[f].newMessages++;
                vm.global.messageCount++;
                sound.play();
                console.log("Not read here", data.notReadBy);



              }
            }
            /*
            if (data.sentBy != vm.global.authuser.uid && (!vm.groups[f].lastNotify || data.sentAt > vm.groups[f].lastNotify)) {
              console.log("M ", data.sentBy, vm.global.authuser.uid, f)
              vm.groups[f].lastNotify = Timestamp.now();
              //   if (!vm.groups[f].newMessages) vm.groups[f].newMessages = 0;

              sound.play();

              //   vm.staffNotifyEmail(data, f);
              vm.UpdateGroup(vm.groups[f], groupId);
            }
            */

            // allMessages.push(data)
          }
        })

        // vm.messages = allMessages
        // console.log("Messages: ", allMessages);


      })
  }
  groupsStarted: string[] = [];

  fetchMessagesByGroupId(groupId, index) {

    //     if (this.groupsStarted.includes(groupId)) return;
    //     else this.groupsStarted.push(groupId); // Only once

    console.log("starting service for group: ", groupId);
    const db = firebase.firestore()

    this.currentGroupId = groupId;  // Temp Hack

    const vm = this
    db.collection('msg_messages')
      .doc(groupId.trim())
      .collection('allMessages')
      .orderBy('sentAt')
      .onSnapshot((querySnapshot) => {
        if (groupId == vm.currentGroupId) {
          const allMessages = []
          var f = vm.groups.findIndex(function (post, index) {
            if (post.id == groupId)
              return true;
          });


          querySnapshot.forEach((doc) => {
            if (doc) {
              const data = <Message>doc.data()
              data.id = doc.id

              // Track unread messsages
              if (vm.activeChat[f] == true && data.notReadBy && data.notReadBy.includes(vm.global.myCompany.chatService)) {
                // if (!vm.groups[f].newMessages) vm.groups[f].newMessages = 0;
                //  vm.groups[index].newMessages++;
                vm.global.messageCount--;
                console.log("Now it's read by me ", index, groupId);
                //Update message as read
                doc.ref.update({
                  notReadBy: firebase.firestore.FieldValue.arrayRemove(vm.global.myCompany.chatService),
                })


              }


              allMessages.push(data)
            }
          })
          allMessages.sort(function (a, b) { return b.sentAt - a.sentAt; });
          vm.messages = allMessages
          console.log("Messages: ", allMessages);
        }

      })
  }



  saveMessage(msg: Message, currentGroupId: string, index) {
    const db = firebase.firestore();
    var self = this;


    const sentAt = Timestamp.now();
    if (msg.msg.trim() || msg.feature) {
      if (index != null) {
        msg.sentAt = sentAt;
        msg.sentBy = self.global.myCompany.chatService;
        if (!msg.notReadBy) msg.notReadBy = [];
        self.groups[index].members.forEach(function (member) {
          if (member != self.global.myCompany.chatService)
            msg.notReadBy.push(member);
        })
      }

      const message: Message = { ...msg };   // DEEP COPY


      console.log("SAVING..:", message, currentGroupId, index);
      if (self.newMsg) {
        self.newMsg.msg = "";
        self.newMsg.mapAddress = "";
        self.newMsg.media = [];
        self.newMsg.searchResults = [];
        self.newMsg.searchQuery = "";
      }
      else {
        self.newMsg = {
          from: self.global.myCompany.chatServiceEmail, to: "",
          msg: "",
        }
      }

      return new Promise((resolve, reject) => {
        db.collection('msg_messages')
          .doc(currentGroupId)
          .collection('allMessages')
          .add(message)
          .then(function (docRef) {
            console.log(" added", message);
            self.updateGroupDateTime(currentGroupId);
            resolve(<Message>message);


            if (index != null) {
              self.userNotifyEmail(self.groups[index], <Message>message);
            }
            else console.log("index is null ", message);

          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }

  updateMessage(message: Message, currentGroupId) {
    const db = firebase.firestore();
    var self = this;

    //  const sentAt = Timestamp.now();

    //    this.newMsg.msg = "";
    return new Promise((resolve, reject) => {
      db.collection('msg_messages')
        .doc(currentGroupId)
        .collection('allMessages').doc(message.id)
        .update(message)
        .then(function (docRef) {
          resolve(message)
          //     self.userNotifyEmail(self.groups[index],<Message>message);
        })
        .catch(function (error) {
          reject(error)
        })
    })

  }

  updateGroupStatus(group: msg_Groups) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(group.id)
        .update({
          status: group.status,
        })
        .then(function (docRef) {
          resolve(group)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  updateGroupTag(group: msg_Groups) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(group.id)
        .update({
          tag: group.tag,
        })
        .then(function (docRef) {
          resolve(group)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  updateGroupDateTime(groupid: string) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(groupid)
        .update({
          lastTime: new Date(),
          status: "Active"
        })
        .then(function (docRef) {
          resolve(groupid)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  updateGroupRouting(group: msg_Groups) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(group.id)
        .update({
          routed: group.routed,
        })
        .then(function (docRef) {
          resolve(group)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  UpdateGroup(group: msg_Groups, GroupId) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(GroupId)
        .update({
          lastNotify: group.lastNotify,
          //      newMessages: group.newMessages
        })
        .then(function (docRef) {
          resolve(group)
        })
        .catch(function (error) {
          reject(error)
        })
    })

  }


  /*
    async createGroup() {
      const db = firebase.firestore();
      var self = this;
      // check for email not added when button clicked
      console.log("Add: ", self.groupListInput)
  
  
      const sentAt = Timestamp.now();
      //    if (self.newMsg.msg.trim()) {
      var message: Message = {
        msg: self.newMsg.msg,
        sentAt,
        sentBy: this.global.authuser.uid
      }
      //    }
  
      var members: string[] = [];
      var emails: string[] = [];
      var names: string[] = [];
  
      var emailList: string[] = [];
      if (this.groupList.length == 0) return;
  
      this.groupList.forEach(function (email: jVal) {
        if (ValidateEmail(email.value))  // Check on entry
          emailList.push(email.value);
      })
      this.groupList = [];
  
      if (emailList.length) {
        console.log(emailList);
        // Add me first
        members.push(self.global.authuser.uid);
        emails.push(self.global.authuser.email);
        names.push(self.global.authuser.firstName + " " + self.global.authuser.lastName);
  
        var dbRef = db.collection("users").where("email", 'in', emailList)
        dbRef
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.empty) {
              console.log("no Users not found here... ");
            }
            if (!querySnapshot.empty) {
              console.log("All found: ", querySnapshot.docs);
  
              querySnapshot.forEach((doc) => {
                var data = <User>doc.data();
                console.log("user: ", data);
                members.push(data.uid);
                emails.push(data.email);
                names.push(data.firstName + " " + data.lastName);
              });
            }
            console.log(emails);
  
            // NOW FOR ANY EMAILS NOT FOUND LET'S INVITE USER TO CHAT
            emailList.forEach(function (email) {
              if (!emails.includes(email)) { // Auto-add
                const userRef = db.collection('users').doc();
                members.push(userRef.id);
                emails.push(email);
                names.push(email);
                self.registerChatUser(email, userRef);
              }
            })
  
  
            // Now Let's make a group
            const docRef1 = db.collection('msg_groups').doc();
            const createdAt = Timestamp.now();
  
            const group: msg_Groups = {
              id: docRef1.id,
              members,
              names,
              emails,
              createdAt,
              createdBy: self.global.authuser.uid
            }
  
            console.log("START GROUP CHAT", group)
  
            docRef1.set(group)
              .then(() => {
                console.log("gg");
                if (self.newMsg.msg)
                  self.saveMessage(message, group.id, null)
                self.sendNewGroupEmails(group, <Message>message);
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
  
  
  
          })
      }
  
    }
    */
  registerChatUser(email, userRef: any) {


    this.registerService.RegisterChatUser(email, userRef);
  }

  createServiceGroup(uid1, uid2) {
    var self = this;
    const db = firebase.firestore();

    const createdAt = Timestamp.now();

    const members = [uid1, uid2];
    const names = [self.global.authuser.firstName + " " + self.global.authuser.lastName, "CHV Customer Service"];
    const emails = [self.global.authuser.email, self.global.myCompany.chatServiceEmail];

    const docRef1 = db.collection('msg_groups').doc();

    const sentAt = Timestamp.now();

    var message: Message = {
      msg: "How may we assist?",
      sentAt,
      sentBy: uid2,
      from: self.global.myCompany.chatServiceEmail
    }

    const group: msg_Groups = {
      id: docRef1.id,
      members,
      names,
      emails,
      createdAt,
      createdBy: uid1,
      storeID: self.global.myCompany.id,
      storeName: self.global.myCompany.name,
    }
    console.log("New Customer Chat: ", group, message)


    return new Promise((resolve, reject) => {

      docRef1
        .set(group)
        .then(function (docRef) {

          resolve(<msg_Groups>group)

          console.log("New Customer Chat: ", group, message);
          // Now send the initial MSG
          self.saveMessage(message, group.id, null)
          self.sendNewGroupEmails(group, <Message>message);

        })
        .catch(function (error) {
          reject(error)
        })
    })
  }


  public staffNotifyEmail(r: Message, name) {
    var self = this;

    if (!this.store) return;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    var notifyUsers: User[];
    notifyUsers = [];

    db.collection("users").where("creds", "!=", "null")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          data.creds.forEach(function (cred) {
            if (r.sentBy != data.uid && cred.id == self.global.myCompany.id
              && cred.notify && cred.notify.enable == true && cred.notify.chat == true) {
              notifyUsers.push(data);

              // NOTIFY STAFF
              var templateParams = {
                from_name: self.global.myCompany.name,  // Company Name
                to_name: data.firstName,
                to_email: data.email,
                msgDate: r.sentAt,
                msgFrom: name,
                msgText: r.msg
              };

              //  e.preventDefault();
              emailjs.send("service_jwders9", "template_yy94vla", templateParams) //"template_1ky3ksd"
                .then((result: EmailJSResponseStatus) => {
                  console.log("staff notified: ", result.text);
                }, (error) => {
                  console.log(error.text);
                });
            }
          })
        });
        console.log("Notify staff of new chat item: ", notifyUsers);

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }


  public userNotifyEmail(group: msg_Groups, newMsg: Message) {
    var self = this;
    var db = firebase.firestore();

    console.log("Notify: ", group, newMsg);

    var creator = group.members.findIndex(function (post, index) {
      if (post == newMsg.sentBy)
        return true;
    });

    if (group.storeID) {

      if (newMsg.sentBy != group.storeID)
        self.staffNotifyEmail(newMsg, group.names[0]);

      return;
    }

    console.log("Notify: ", group, newMsg);
    // check group creator/msg sender

    group.members.forEach(function (uid, i) {

      if (group.members[i] != newMsg.sentBy) {

        db.collection("users").doc(uid).get().then((doc) => {
          const data = <User>doc.data();
          if (data.notify && data.notify.enable == true && data.notify.chat == true) {
            var dCode = group.members[i].substring(0, 5);
            console.log("dc ", dCode)

            var templateParams = {
              from_name: group.names[creator],
              to_name: group.names[i],
              to_email: group.emails[i],
              message: newMsg.msg,
              msgDate: self.prettyDateTime(newMsg.sentAt),
              msgFrom: group.names[creator],
              msgText: newMsg.msg,
              dCode: dCode

            };

            console.log("New group EMAIL: ", templateParams, creator);
            //  e.preventDefault();
            emailjs.send("service_jwders9", "template_fljzpn6", templateParams)
              .then((result: EmailJSResponseStatus) => {
                console.log(result.text);
              }, (error) => {
                console.log(error.text);
              });
          }


        })

      }
    })

  }

  checkNotificationSettings(uid) {

    var self = this;
    var db = firebase.firestore();

    db.collection("users").doc(uid).get().then((doc) => {

    })

    return false;

  }

  sendNewGroupEmails(group: msg_Groups, newMsg: Message) {
    var self = this;
    // SEND Info EMAILS TO PEOPLE ADDED TO GROUP - Including Creator

    var creator = group.members.findIndex(function (post, index) {
      if (post == newMsg.sentBy)
        return true;
    });


    // check group creator/msg sender

    group.members.forEach(function (email, i) {
      var dCode = group.members[i].substring(0, 5);
      var templateParams = {
        from_name: group.names[creator],
        to_name: group.names[i],
        to_email: group.emails[i],
        message: newMsg.msg,
        msgDate: self.prettyDateTime(newMsg.sentAt),
        msgFrom: group.names[i],
        msgText: newMsg.msg,
        dCode: dCode,

      };
      if (group.storeID != group.members[i]) { }

      console.log("New group EMAIL: ", templateParams, creator);
      //  e.preventDefault();
      emailjs.send("service_jwders9", "template_dm7az4j", templateParams)
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });

    })


  }
  prettyDateTime(date: Timestamp): string {

    let d = new Date(date.toDate());
    return dateFormatter(d);
  }


  updateLatLong(lat: number, long: number, i) {
    var self = this;


    if (lat) {
/*
      self.newMsg.mapLat = lat;
      self.newMsg.mapLong = long;
      self.newMsg.mapOptions = {
        center: { lat: lat, lng: long },
        zoom: 14
      }
      */
      this.SendMsg(i);
      this.dataMode = "";
    }
  }






  geoCode(adr, i) {
    var self = this;


    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com'
      }
    };

    //'&city=' + city + '&state=' + state + '&postalcode=' + zip +

    fetch('https://forward-reverse-geocoding.p.rapidapi.com/v1/search?q=' + adr + '&accept-language=en&polygon_threshold=0.0', options)
      .then(response => response.json())
      .then(response => {
        console.log("GEO: ", response);
        if (response[0] && response[0].lat) {
          var lat: number = Number(response[0].lat);
          var lon: number = Number(response[0].lon);
          self.updateLatLong(lat, lon, i);
        }


      })
      .catch(err => console.error(err));


  }


  checkEmail() {
    var self = this;
    console.log("Checkemail ");

    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    self.foundUser = false;
    if (!this.Email || this.Email.length < 5) return;

    self.inputCreds = false;
    db.collection("users").where("email", "==", self.Email)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          self.foundUser = true;
          self.inputCredsnoUser = null;
        }
        self.inputCredsnoUser = true;
        console.log("Found: ", self.foundUser);

        self.inputCreds = true;
      })

  }





}
function dateFormatter(date: Date) {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true
  };

  return " at " + TimeFormat(date.getTime()) + " on " + date.toLocaleDateString('en-US', timeformat);
}

function TimeFormat(time: number) {

  var d = new Date(time);
  let hours = d.getHours();
  // Determine AM or PM suffix based on the hour
  const suffix = (hours < 12) ? "am" : "pm";

  // Convert hour from military time
  hours = (hours < 12) ? hours : hours - 12;

  // If hour is 0, set it to 12
  hours = hours || 12;

  var min = d.getUTCMinutes();
  var minText = min.toString();
  if (min < 10) minText = "0" + min.toString();
  if (min == 0) minText = "00 ";


  return hours + ":" + minText + suffix;

}


function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}


