/* TO DO:
 * - ADD COMPANY REGISTRATIONS
 * - ADD APP LOADS: MENU/RESERVE (ACTIVITY: IP ADDRESS, TIME, UID)
 * 
 * 
 * 
 * 
 * 
 * 
 */



import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { getCountFromServer } from "firebase/firestore"
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../environments/environment';
import { interval } from 'rxjs';
import { User, Activity } from '../user-interface';
import { Question, Answer, Player, TriviaGame } from "../games-interface";
import { Company, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';
import { Timestamp } from "firebase/firestore";


@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.css']
})
export class StatsComponent implements OnInit {

  admin: boolean = false;
  resCount: number = 0;
  gameCount: number = 0;
  playerCount: number = 0;
  resTodayCount: number = 0;
  resTodayHeadCount: number = 0;
  validatedUsers: number = 0;
  notValidusers: number = 0;
  newUsersToday: number = 0;
  totalUsers: number = 0;
  totalLogs: number = 0;
  logs: Activity[];


  constructor() {
    firebase.initializeApp(environment.firebase);
  }

  ngOnInit(): void {

    const id = localStorage.getItem('uid');
    if (id == "kvT6eKFxFHb3EKbVeCTusW86CrZ2") {

      this.admin = true;
    }

    this.logs = [];
//    this.tapReservations();
//    this.tapGames();
//    this.tapUsers();
    this.tapActivity();

    console.log("Nav: ", navigator);

  }

  getUID (uid) : string {

    if (!uid) return "Anon"
    return uid.substring(0,4);
  }

  async CountReservations() {

    var self = this;

    var db = firebase.firestore();
    const coll = collection(db, "reservations");
    const query_ = query(coll, where('status', '==', 'open'));
    const snapshot = await getCountFromServer(query_);
    console.log('count: ', snapshot.data().count);
    self.resCount = snapshot.data().count;

  }

  async CountGames() {

    var self = this;

    var db = firebase.firestore();
    const coll = collection(db, "triviaGame");
    const query_ = query(coll, where('storeNum', '==', '4390'));
    const snapshot = await getCountFromServer(query_);
    console.log('count: ', snapshot.data().count);
    self.gameCount = snapshot.data().count;

  }

  tapGames() {
    var self = this;
    var db = firebase.firestore();
    const q = query(collection(db, "triviaGame"), where('storeNum', '==', '4390'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.gameCount = querySnapshot.size;
      self.playerCount = 0;

      querySnapshot.forEach((doc) => {
        const r = <TriviaGame>doc.data();
        self.playerCount += r.players.length;
      });
    });
  }
  tapReservations() {
    var self = this;
    var db = firebase.firestore();
    const q = query(collection(db, "reservations"), where('status', '==', 'open'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.resCount = querySnapshot.size;
      self.resTodayCount = 0;

      querySnapshot.forEach((doc) => {
        const r = <reservation>doc.data();
        if (r.createStamp && self.today(r.createStamp)) {
          self.resTodayCount++;
          self.resTodayHeadCount += r.partySize;
        }
      });
    });
  }

  tapUsers() {
    var self = this;
    var db = firebase.firestore();
    const q = query(collection(db, "users"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.totalUsers = querySnapshot.size;
      self.validatedUsers = 0;
      self.notValidusers = 0;
      self.newUsersToday = 0;

      querySnapshot.forEach((doc) => {
        const r = <User>doc.data();

        if (r.createStamp && self.today(r.createStamp)) {
          self.newUsersToday++;
        }
      });
    });
  }

  delActivity() {
    var self = this;

    var db = firebase.firestore();

    db.collection("activity")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Activity>doc.data();
          if (!data.createStamp || !self.today(data.createStamp)) {
            db.collection("activity").doc(doc.id).delete().then(() => {
              console.log("Activity successfully deleted!");
            }).catch((error) => {
              console.error("Error removing: ", error);
            });
            
          }


        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });



  }

  tapActivity() {

    var self = this;
    var db = firebase.firestore();

    var dayago = Date.now() - (60000 * 60 * 24);


    console.log ("24hago ", new Date(dayago))
    const q = query(collection(db, "activity"), where("dateTime", ">", dayago));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.totalLogs = querySnapshot.size;
      self.logs.splice(0, self.logs.length);

      querySnapshot.forEach((doc) => {
        var r = <Activity>doc.data();
        if (r?.storeID?.length==0) r.storeID = "0000"; //hackatab

        // Only Save Activity with Time Stamp
        if (r.createStamp  && r.timeMs) {  //&& self.today(r.createStamp)
  //        if (r.uid != "5vjmmQOnSx31XLsMDvZx") {
            self.logs.push(r);
  //        }

        }

      });

      self.logs.sort(function (a, b) { return b.timeMs - a.timeMs });

    });



  }




  today(date: firebase.firestore.Timestamp): boolean {

    var d1 = date.toDate();

    const day = d1.getDate();
    const month = d1.getMonth();
    const year = d1.getFullYear();

    var d = new Date();
    if (day == d.getDate() && month == d.getMonth() && year == d.getFullYear()) {
      return true;
    }
    else return false;
  }

  getNiceDate(date: Timestamp) {

    let d = new Date(date.toDate());

    //    console.log(d);
    return (dateFormatter(d));
    //   return d.getMonth()+" "+d.getDay()+" "+d.getFullYear();

  }



}

function dateFormatter(date: Date) {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'

  };
  // date.toDateString();


  return date.toLocaleString('en-US', timeformat) + " " + date.toLocaleTimeString();

}
