import { AfterViewInit, Component, EventEmitter, Output, OnInit, HostListener } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, NapkinApp, Form, Field, Duration, Coupon, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, ContainerSettings } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Content } from '../content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Product } from '../product-int';
import { Contact, Media } from '../user-interface';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { Logger } from 'src/app/functions';
import { thumbnailsSettings } from 'lightgallery/plugins/thumbnail/lg-thumbnail-settings';
import { NodeService } from '../node.service';
import { FormService } from '../form.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../common.scss']
})
export class HomeComponent implements OnInit, AfterViewInit {
  @Output() validateUser = new EventEmitter<any>();

  store: string;
  baseURL: string;
  activeStore: boolean[];
  contentID: string;
  content: Content;
  public audio: HTMLAudioElement;
  openedTab: boolean = false;
  viewIntro: boolean = false;
  showStore: boolean = false;
  perkIsValid: boolean = false;
  validatingPerk: boolean = false;
  perkUserId: string;
  perkUser: User;
  validPerk: Coupon;
  perkRedeemed: boolean = false;
  perkUsed: boolean = false;
  thisProduct: Product;
  userSid: string;
  perkSid: string;
  perkCompany: string;
  navigating: boolean = false;
  followSettings: ContainerSettings;
  userSettings: ContainerSettings;
  home: boolean = false;
  openFavorite: boolean = true;
  userTabs: boolean[];
  viewTab: boolean[] = [false, false, false, false];
  userList: string[];  // make public
  fullWidth: boolean = true;


  heroes$: Observable<string>;
  selectedId: string;
  //heroes = HEROES;

  contact: Contact;


  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public formService: FormService,
    public nodeService: NodeService,
    private scroller: ViewportScroller,
    private sanitizer: DomSanitizer,
    public reviewsService: ReviewsService
  ) {
    firebase.initializeApp(environment.firebase);

    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER
    this.store = this.route.snapshot.queryParamMap.get('store');

    /*
        this.route.queryParams.subscribe(params => {
          this.contentID = params['cid'];
        });
    */


  }

  ngAfterViewInit(): void {
    // addElement();
    //  document.body.onload =  this.addButton;



    //const installApp = document.getElementById('installApp');





  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.global.getScreenWidth = window.innerWidth;
    this.global.getScreenHeight = window.innerHeight;
  }

  ngOnInit(): void {

    this.global.getScreenWidth = window.innerWidth;
    this.global.getScreenHeight = window.innerHeight;

    this.baseURL = this.router.navigate.toString();

    if (!this.global.allThemes)
      this.formService.watchThemes();

    console.log("base url: ", this.router.routerState.snapshot.url, window.location.hostname); //????
    if (window.location.hostname == "openbabble.com") {
      Logger("Openbabble", "Anon", "", this.router.routerState.snapshot.url, info)


      if (this.router.routerState.snapshot.url == "settings")
        this.global.entryPoint = "openbabble";

      if (this.router.routerState.snapshot.url != "/front")
        this.router.navigate(['/chat']);

    }
    console.log("URL: ", this.router.routerState.snapshot.url)
    if (this.router.routerState.snapshot.url.startsWith("/app")) {
      this.navigating = true;

    }

    this.heroes$ = this.route.paramMap.pipe(
      switchMap(params => {
        this.selectedId = params.get('store');
        console.log("Mapstore: ", this.selectedId)
        return this.selectedId;// this.service.getHeroes();
      })
    );

    //this.fixSID();
    //this.fixProdSID(); //one-time use

    this.nav.GetAllStores();
    this.activeStore = [];
    this.audio = new Audio();

    this.global.tableNameQR = this.route.snapshot.queryParamMap.get('table');


    this.contentID = this.route.snapshot.queryParamMap.get('c'); // direct content
    var direct = this.route.snapshot.queryParamMap.get('p'); // direct Login
    var coupon = this.route.snapshot.queryParamMap.get('e'); // coupon reemption

    if (this.contentID) { this.GetContent(this.contentID); }

    console.log("Direct/Content/table: ", direct, this.contentID, this.global.tableNameQR);


    if (coupon) {
      // SPECIAL ENTRYPOINT TO REDEEM COUPON
      // Redemption is added to User account

      console.log("PERK REDEMPTION: ", coupon);
      this.verifyCoupon(coupon)


    }
    if (this.validatingPerk) return;  // STOP HERE

    if (this.router.routerState.snapshot.url == "/navigate") {
      this.navigating = true;

    }
    if (this.router.routerState.snapshot.url == "/app") {
      this.navigating = true;

    }

    /*
        this.route.queryParams.subscribe(params => {
          direct = params['p'];
        }); */

    // Load user
    const id = localStorage.getItem('uid');

    // LOG Activity
    var info = "";
    if (coupon) info += "Coupon: " + coupon;
    if (direct) info += "Direct Login: " + direct;
    if (this.contentID) info += "Content: " + this.contentID;

    Logger("New Session", id, "", this.router.routerState.snapshot.url, info)


    if (id && !this.global.authuser) {
      console.log("UID: ", id, this.global.allReservations);
      this.nav.GetUserwID(id, false, false, this, this.afterUserLoaded);// true get all res

      this.global.userTabOpen = false;
    }
    else if (!id && direct) {
      console.log("Direct: ", direct)
      // get UID from directdb
      this.global.userTabOpen = true;
      this.getDirectUID(direct);

    }
    else if (this.global.authuser) {   // Not direct but already loaded >navigate
      this.afterUserLoaded(this);

    }
    if (this.router.routerState.snapshot.url == "/home" ||
      this.router.routerState.snapshot.url == "/") {
      this.home = true;

    }

    if (id == null || window.location.hostname == "napkinhq.com") {
      //  this.nav.GetStoreOnly("6259", this.InitStore, null, this);

    }
    //  consoleText(['Welcome back '+ this.global.authuser.firstName, 'Console Text', 'Made with Love.'], 'text',['tomato','rebeccapurple','lightblue']);

    this.router.navigate([], {    // REMOVE dcode
      queryParams: {
        'p': null,
      },
      queryParamsHandling: 'merge'
    })
    /*
        document.getElementById("top").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
        */
  }

  setContact(info: Contact) {
    var self = this;

    // Got results back so let's save them
    console.log("Info: ", info);
    self.contact = info;
    if (!self.global.authuser) {
      console.log("contact ", this.contact)

      if (!self.global.authuser) {
        if (!self.contact.firstName || self.contact.firstName.length == 0
          || !self.contact.lastName || self.contact.lastName.length == 0
        ) { alert("Please enter a name."); return; }
        //  if (!ValidateEmail(this.Email)) return;
        // if (!validatePhoneNumber(this.Phone)) return;
        var newUserID = self.registerService.GetUID();
        self.registerService.RegisterUser(self.contact.email, self.contact.firstName, self.contact.lastName, true, self.afterUserLoaded, self);

      }

    }

  }

  afterLogin(contact: Contact) {
    this.afterUserLoaded(this);


  }


  afterUserLoaded(self: this) {
    console.log("After user login->let's get any open orders ", self.global.authuser)

    self.userSettings = { backgroundColor: "#b800d1", width: "400px" };

    self.userList = [];
    // Build list of User Features Enabled
    self.userList.push("My Settings");
    //   self.userList.push("My Contacts");

    if (self.global.openOrders && self.global?.openOrders?.length > 0)
      self.userList.push("My Orders");

    if (self.global.myReservations && self.global.myReservations.length)
      self.userList.push("Reservations");

    if (self.global.authuser?.userFeature?.myQuotes) self.userList.push("Quotes");
    if (self.global?.authuser.uid == "kvT6eKFxFHb3EKbVeCTusW86CrZ2") {
      if (self.global.authuser.following)
        self.userList.push("Favorite Places");
    }
    if (self.global.authuser.creds || self.global.authuser?.userFeature?.addStore) self.userList.push("My Stores");
    if (self.global.authuser?.userFeature?.myChatBots) self.userList.push("Chat Bots");

    if (self.global.authuser.badges) self.userList.push("Badges");
    //self.userList.push("Channels");

    if (self.global.authuser?.userFeature?.mySearch) self.userList.push("Search");
    if (self.global.authuser?.userFeature?.myChats) self.userList.push("Chats");
    if (self.global.authuser?.userFeature?.myMedia) self.userList.push("Media");
    if (self.global.authuser?.userFeature?.myGames) self.userList.push("Games");

    if (self.global.authuser?.userFeature?.myStores) self.userList.push("Stores");
    if (self.global.authuser?.userFeature?.myWeather) self.userList.push("Weather");
    if (self.global.authuser?.userFeature?.myReviews) self.userList.push("Reviews");
    if (self.global.authuser?.userFeature?.myStoresNearMe) self.userList.push("Stores Near Me");
    //  if(self.global.authuser.userFeature.myReservations) self.userList.push("Reservations");
    if (self.global.authuser?.userFeature?.myNews) self.userList.push("News");
    if (self.global.authuser.uid.includes("vT6eKFxFHb3EKbVeCTusW86CrZ"))
      self.userList.push("All Stores");

    // remove for now  self.getRecentApps();

    // self.cartService.GetMyOrder();
    // IF USER HAS 1 STORE THEN AUTO-LOGIN
    if (self.global.entryPoint=='front' && self.global.authuser?.creds.length == 1) {
      self.nav.GetStore(self.global.authuser.creds[0].storeNum, self.startServices, null, this);
      self.global.userTabOpen = false;

    }
// CALLBACK FOR VALIDATION
  self.validateUser.emit();



  }


  getMaxWidth() {
    if (this.fullWidth) return "100%";
    else return "960px";

  }


  setActiveStore() {
    this.followSettings = { width: "50%", backgroundColor: "#4499bb" }


    // if (this.openFavorite) 
    //   this.activeStore[0] = true;   // Hack Hack
  }

  password: string = ""
  pwSuccess: boolean = false;
  passwordChange: boolean = false;
  changePassword() {
    this.passwordChange = true;
  }
  updatePassword() {
    var self = this;
    var db = firebase.firestore();
    var ref = db.collection("users").doc(self.global.authuser.uid);
    ref.update({
      pw: self.password,
    })
      .then(() => {
        console.log("pw updated: ");
        Logger("Password change", self.global.authuser.uid, "", this.router.routerState.snapshot.url)

        self.passwordChange = false;
        self.pwSuccess = true;
      })
      .catch((error) => {
        console.error("Error writing perk: ", error);
      });

  }

  addButton() {
    let installButton = document.getElementById('install');
    console.log("INSTALL :", installButton);

    let prompt;
    window.addEventListener('beforeinstallprompt', function (e) {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      prompt = e;
    });

    installButton.addEventListener('click', function () {
      console.log("INSTALL");
      prompt.prompt();
    })
  }

  getStoreStyle(i) {
    var style = "width: "

    style += this.global.getScreenWidth + 'px;'

    return style;

  }

  confirmRedeem() { // Button is visible only if perk is valid

    // Final step is to add a Used flag to user perks


    var self = this;
    self.validPerk.used = true;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();
    var ref = db.collection("users").doc(this.perkUserId);
    ref.update({
      perks: self.perkUser.perks,
    })
      .then(() => {
        console.log("perk updated: ");
        self.perkRedeemed = true;
      })
      .catch((error) => {
        console.error("Error writing perk: ", error);
      });

  }
  verifyCoupon(couponCode: string) {

    // break code into parts, check if logged-in user is staff at Company/Store
    // - Authorized to redeem.
    // Then check if this coupon has already been redeemed.
    // Then add to user and display redemption success.

    this.userSid = couponCode.substring(0, 5);
    this.perkSid = couponCode.substring(5, 12);
    console.log("Codes: ", this.userSid, this.perkSid);

    this.validatingPerk = true;

    // Flow: check code from user Sid: verify matching perk; not already used, 
    this.perkIsValid = this.isPerkValid(this.userSid, this.perkSid);
    // Once verified, present redeem button for user/staff cconfirmation


    // Once confirmed - add a used flag with date to the perk

  }

  getProductFromCode(prodSid: string) {

    if (this.global.allProducts.length) {
      const found = this.global.allProducts.find(element => element.sid == prodSid)
      if (found) {
        this.thisProduct = found;
        console.log("Product found: ", found);
      }

    }
    else {
      console.log("Products not loaded.")

      var self = this;
      var db = firebase.firestore();

      //  console.log ("Searching PERKS ", match)

      var ref2 = db.collection("company").doc(self.perkCompany).collection('products').where("sid", "==", prodSid)

        //    db.collection("products").where("companyID", "==", self.perkCompany).where("sid", "==", prodSid)
        .get()
        .then((querySnapshot) => {
          console.log("FOUND product: ", querySnapshot);
          querySnapshot.forEach((doc) => {
            var data = <Product>doc.data();
            self.thisProduct = data;
          })
        })





    }

  }

  isPerkValid(userSid, perkSid): boolean {

    var self = this;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    var match: string = userSid + perkSid;
    self.perkIsValid = false;
    console.log("Searching PERKS ", match)

    db.collection("users").where("sid", "==", userSid)
      .get()
      .then((querySnapshot) => {
        console.log("FOUND PERKS: ", querySnapshot);
        querySnapshot.forEach((doc) => {
          var data = <User>doc.data();
          self.perkUser = data;

          // console.log  ("FOUND PERKS: ",data)
          data.perks.forEach(function (perk: Coupon) {
            if (perk.couponCode == match) {
              console.log("FOUND PERK: ", perk)
              // Now check if used
              if (perk.used)
                self.perkUsed = true;
              else {
                self.perkIsValid = true;
                self.perkUserId = data.uid;
                self.perkCompany = perk.companyId;
                self.validPerk = perk;
                perk.used = true; // set here but only saved if confirmed.
                // Let's lookup the product code and load that for viewing
                self.getProductFromCode(self.perkSid); // product sid
                return true;
              }
            }



          })
        });

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    return false;

  }


  InitStore(res, self: this) {

    console.log("Init after store loaded ", self.global.authuser, self.store, self.global.myCompany);

    if (self.global.authuser && self.store) {
      console.log("initstore-inituser");
      //   this.initUser();
    }

    /* 
       if (self.global.myCompany.store == "6259") {
        //  self.showStore = true;
         // console.log ("Show Napkin store");
        }
    */


  }
  userTab(i?: number) {

    this.activeIndex = i;
    console.log(i)

  }
  activeIndex: number = -1;


  getBackColor(i) {
    if (this.viewTab[i] == true) return "#f3c5ff";
    // if(i % 2 == 0) 
    //   return "#9b4aaf"
    else return "#d542fa";

  }

  getFontColor(i) {
    if (this.viewTab[i]) return "#000000";
    return "#e4f8e4";
  }

  getStoreBack(i) {
    if (this.activeStore[i] == true) return "#DDEAFF";

    return "#ffffff";

  }

  getStoreColor(i) {
    if (this.activeStore[i]) return "#000000";
    return "#000000";
  }

  getLeftMargin(i?: number) {

    if (this.viewTab[i]) return "0px";
    if (this.global.getScreenWidth > 900)
      return "30%";
    else if (this.global.getScreenWidth > 600)
      return "30%";
    else return "0px";

  }

  getRightMargin(i?: number) {

    if (this.viewTab[i]) return "0px";
    if (this.global.getScreenWidth > 900)
      return "30%";
    else if (this.global.getScreenWidth > 600)
      return "30%";
    else return "0px";
  }



  playAudio() {
    this.audio = <HTMLAudioElement>document.getElementById('audio1');
    this.audio.play();
  }

  playTrivia() {
    this.router.navigate(['/trivia']);

  }
  onTabClick() {
    this.openedTab = true;
  }

  gotoMenu() {
    this.router.navigate(['/menu/' + this.global.myCompany.store]);

  }

  GetContent(id: string) {
    var self = this;

    var db = firebase.firestore();

    return; // Hack how to maintain product shortcut // special indirect link? // .com/?c=cid 
    // This allows/requires content to be private by default // Publish creates link // 
    // Do we even need this since any content can be basis for an App. 
    // Just make an app, add content and done!
    db.collection("products").where("sid", "==", id)
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {
          const data = <Product>doc.data();

          if (!data.style) data.style = 'Wide Image'
          var html: SafeHtml;
          html = this.sanitizer.bypassSecurityTrustHtml(data.fullDescription);
          self.thisProduct = data;
          console.log("FOUND product: ", self.thisProduct);

        })
      })


  }

  GetStore(id: string) {
    var self = this;

    var db = firebase.firestore();

    db.collection("company").where("id", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Company>doc.data();
          self.global.myCompany = data;
        });
        console.log("Company: ", self.global.myCompany.name);
      })

  }

  onResOpen(e, i: number) {
    if (!this.activeStore[i]) this.activeStore[i] = true;
    else this.activeStore[i] = false;

    // Now close all other tabs
    for (var n = 0; n < this.activeStore.length; n++) {
      if (n != i) this.activeStore[n] = false;

    }
    // auto-scroll to the tab.
    this.scroller.scrollToAnchor("store" + i);

  }

  noShow() { }
  GotoReserve(store: string) {
    this.router.navigate(['/reserve/' + store]);

  }

  goMenu(store: string) {
    this.nav.GetStore(store);
    this.router.navigate(['/menu/' + store]);
  }

  getDirectUID(direct) {
    var self = this;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    db.collection("users").where("sid", "==", direct)
      .get()
      .then((querySnapshot) => {
        console.log("DIRECT:");
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          self.nav.GetUserwID(data.uid, true, null, self, self.afterUserLoaded);// true get all res
          localStorage.setItem('uid', data.uid);

        });



      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }




  RemoveMyAccount() {
    var self = this;

    if (this.global.myCompany.id == "5vjmmQOnSx31XLsMDvZx") {
      confirm("You are not authorized for this feature.");
      return;
    }
    // Remove Store account and user account from system
    // First validate that user is creator of account
    var creator: boolean = false;
    this.global.authuser.creds.forEach(function (cred, i) {
      if (cred.role == "Creator" && cred.id == self.global.myCompany.id) {
        creator = true;
      }

    })

    if (creator) {
      if (confirm("Warning: this removal cannot be undone"))
        if (confirm("Confirm PERMANENT removal of all data for " + self.global.myCompany.name)) {
          // REMOVE COMPANY ACCOUNT HERE

          self.RemoveCompany(self.global.myCompany);


          // NOW ASK TO REMOVE THE USER ACCOUNT
          if (confirm("Do you want to REMOVE your USER account? " + self.global.authuser.displayName)) {
            if (confirm("DELETE your USER account CONFIRMATION? " + self.global.authuser.lastName)) {
              // OK, Let's remove the user account???
              self.RemoveUser(self.global.authuser);
            }
          }
        }
    }
    else confirm("You are not authorized for this feature.");
  }

  RemoveUser(user: User) {
    var self = this;

    var db = firebase.firestore();

    db.collection("users").doc(user.uid).delete().then(() => {
      console.log("User successfully deleted!");
      self.ngAuthService.SignOut();
    }).catch((error) => {
      console.error("Error removing user: ", error);
    });

  }

  RemoveCompany(store) {
    // Remove company plus all reservations 
    console.log("Removing COMPANY: ", store.name);
    var self = this;

    if (store.id == "5vjmmQOnSx31XLsMDvZx") { //CHV
      return;
    }

    var db = firebase.firestore();

    db.collection("company").where("id", "==", store.id)
      .get()
      .then((querySnapshot) => {
        // IF HERE THEN WE CAN REMOVE
        if (!querySnapshot.empty) {
          db.collection("company").doc(store.id).delete().then(() => {
            console.log("Company successfully deleted!");
          }).catch((error) => {
            console.error("Error removing company: ", error);
          });
        }
      });
    console.log("Removing RESERVATIONS for: ", store.name);

    db.collection("reservations").where("companyID", "==", store.id)
      .get().then((querySnapshot) => {
        if (querySnapshot.empty) console.log(store.name + " had no reservations");

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          db.collection("reservations").doc(doc.id).delete().then(() => {
            console.log("Res successfully deleted!");

          }).catch((error) => {
            console.error("Error removing res: ", error);
          });
        })
      });
  }



  RemoveStore(store: Company) {
    // Super-admin

    if (this.global.authuser.uid.includes("vT6eKFxFHb3EKbVeCTusW86CrZ")) {
      console.log("Hello SuperAdmin");
      if (!confirm("Please confirm DATA DELETION! " + store.name)) return;
    }
    else return;


    // Remove company plus all reservations 
    console.log("Removing COMPANY: ", store.name);
    var self = this;

    var db = firebase.firestore();

    db.collection("company").where("id", "==", store.id)
      .get()
      .then((querySnapshot) => {
        // IF HERE THEN WE CAN REMOVE
        if (!querySnapshot.empty) {
          db.collection("company").doc(store.id).delete().then(() => {
            console.log("Company successfully deleted!");
          }).catch((error) => {
            console.error("Error removing company: ", error);
          });
        }
      });
    console.log("Removing RESERVATIONS for: ", store.name);

    db.collection("reservations").where("companyID", "==", store.id)
      .get().then((querySnapshot) => {
        if (querySnapshot.empty) console.log(store.name + " had no reservations");

        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          db.collection("reservations").doc(doc.id).delete().then(() => {
            console.log("Res successfully deleted!");
          }).catch((error) => {
            console.error("Error removing res: ", error);
          });
        })
      });

  }

  logMeOut() {
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'p': null,
      },
      queryParamsHandling: 'merge'
    })

    this.ngAuthService.SignOut();
    this.global.authuser = null;

    //  window.location.reload();
  }

  follow(store: Company) {

    var self = this;


    var db = firebase.firestore();
    var ref = db.collection("users").doc(self.global.authuser.uid);

    //  var notify: Notify = {enable: true, reservations:true};


    var follow: Follows = {
      id: store.id,     // remove later
      storeNum: store.store,    // fix name
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: store.name

    }
    if (this.global.authuser.following) this.global.authuser.following.push(follow);
    else {
      this.global.authuser.following = [];
      this.global.authuser.following.push(follow);
    }
    console.log("Set follows: ", follow)



    ref.update({
      following: this.global.authuser.following
    })
      .then(() => {
        console.log("follows updated: ");
        // Save locally
        //      localStorage.setItem('myFollows', JSON.stringify(follows));
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  LoginAdmin(store: string) {


  }

  startServices(res, self) {
// ALL THIS SHOULD BE IN FRONT NOT HERE

    console.log("Starting Services")
 // SHOULD BE IN FRONT  
 self.reviewsService.getReviews();

    self.getFields();
    self.getForms();
    self.nodeService.storeAppsListener();
    self.nodeService.getNodeTypes();
    // Check if user has valid email and has provided URL and we haven't gotten Google Bus info yet

    if (self.global.authuser.emailVerified && self.global.myCompany && !self.global.myCompany.hasGoogleInfo) {
      if (self.global.myCompany.storeURL) {
        //  self.FetchGoogleInfo();
        //  console.log("GET GOOGLE INFO")
        //  self.registerService.FetchGoogleInfo();
      }
    }
    self.router.navigate(['/front']);

  }

  Navigate(store) {
    this.router.navigate(['/navigate/' + store]);

  }

  getForms() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;

    self.global.allForms = [];
    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("forms")
      .onSnapshot((querySnapshot) => {
        //   index = 0;
        //    self.global.allForms= [];






        querySnapshot.forEach((doc) => {
          const m = <Form>doc.data();
          const index = self.global.allForms.findIndex(function (post) {
            if (post.id == m.id)
              return true;
          });
          if (index == -1)
            self.global.allForms.push(m);
          else {
            console.log("Overwriting data")
            //  self.global.allForms[index] = m
          }

        })

      })
    console.log("Forms ", self.global.allForms)


  }


  getFields() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;


    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("fields")
      .onSnapshot((querySnapshot) => {
        index = 0;
        self.global.allFields = [];

        querySnapshot.forEach((doc) => {
          const m = <Field>doc.data();
          console.log("Fields ", m)

          self.global.allFields.push(m);

        })

      })
    console.log("Fields ", self.global.allFields)

    db.collection('platform')
      .doc("OdCDgRWTZhTMzW8N0y9y")
      .collection("fields")
      .onSnapshot((querySnapshot) => {
        index = 0;
        querySnapshot.forEach((doc) => {
          const m = <Field>doc.data();
     //     console.log("Fields ", m)
          self.global.allFields.push(m);
        })
      })
  }


  fixSID() { // ADD SID to all users
    return; // One time code

    /*
    var self = this;

    var db = firebase.firestore();
    console.log ("Adding SID ");

    db.collection("users")
      .get()
      .then((querySnapshot) => {
        // IF HERE THEN WE CAN REMOVE

        querySnapshot.forEach(function (item) {
          const tf = <User>item.data();
          if (!tf.sid) {
            var dCode = item.id.substring(0,5);
            console.log ("Adding SID ",dCode);

            db.collection("users").doc(item.id).update({sid:dCode}).then(() => {
              console.log("User updated");
            }).catch((error) => {
              console.error("Error ", error);
            });
            
          }
          else console.log("ignore ", item.id);

        })

      });
*/



  }




  fixProdSID() { // ADD SID to all users
    return;

    var self = this;

    var db = firebase.firestore();
    console.log("Adding SID ");

    db.collection("products")
      .get()
      .then((querySnapshot) => {
        // IF HERE THEN WE CAN REMOVE

        querySnapshot.forEach(function (item) {
          var tf = <Product>item.data();

          if (!tf.docID) {
            console.log("Missing DOCID ", tf.title);
            tf.docID = item.id;
            db.collection("products").doc(item.id).update({ doocID: item.id }).then(() => {
              console.log("id updated");
            }).catch((error) => {
              console.error("Error ", error);
            });
          }

          if (1) {
            if (!tf.docID) {
              //  console.log("Missing DOCID ", tf.title);
              tf.docID = item.id;
            }
            if (!tf.companyID) tf.companyID = "MISSING";

            var dCode = tf.docID.substring(0, 5) + tf.companyID.substring(5, 7);
            console.log("Adding SID ", dCode);

            db.collection("products").doc(item.id).update({ sid: dCode }).then(() => {
              console.log("prod updated");
            }).catch((error) => {
              console.error("Error ", error);
            });

          }
          else console.log("ignore ", item.id);

        })

      });

  }

  onStoreOpen(event, i, storeNum) {
    var db = firebase.firestore();
    var self = this;
    if (this.global?.myCompany?.store == storeNum) return;

    db.collection("company").where("store", "==", storeNum)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Company>doc.data();
          self.global.myCompany = <Company>doc.data();
          console.log("got store ", data);

        });
      })
      .catch((error) => {
        console.log("no store found: ", error);
      });

  }

  gotoApp(event, appLink: NapkinApp) {
    var id = appLink.appId;
    //   if (appLink.appURL)
    //     id = appLink.appURL

    this.router.navigate(['/app/' + id]);

  }

  getRecentApps() {
    const db = firebase.firestore()
    /*
       if (this.global?.authuser.uid != "kvT6eKFxFHb3EKbVeCTusW86CrZ2") return;
    
        if (!this.global.recentApps) this.global.recentApps = [];
    
        const vm = this
        db.collection('app_link')
          .where("createdAt", ">", 0)
          .onSnapshot((querySnapshot) => {
         
            vm.global.recentApps = [];
              querySnapshot.forEach((doc) => {
                if (doc) {
                  const data = <AppLink>doc.data()
                  data.id = doc.id;
                  if (data.accessControl=='Public')
                    vm.global.recentApps.push(data);
                }
    
    
              })
              console.log("App List ", this.global?.recentApps)
    
            })
            */

  }


}


/*
function consoleText(words, id, colors) {
  if (colors === undefined) colors = ['#fff'];
  var visible = true;
  var con = document.getElementById('console');
  var letterCount = 1;
  var x = 1;
  var waiting = false;
  var target = document.getElementById(id)
  target.setAttribute('style', 'color:' + colors[0])
  window.setInterval(function() {

    if (letterCount === 0 && waiting === false) {
      waiting = true;
      target.innerHTML = words[0].substring(0, letterCount)
      window.setTimeout(function() {
        var usedColor = colors.shift();
        colors.push(usedColor);
        var usedWord = words.shift();
        words.push(usedWord);
        x = 1;
        target.setAttribute('style', 'color:' + colors[0])
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (letterCount === words[0].length + 1 && waiting === false) {
      waiting = true;
      window.setTimeout(function() {
        x = -1;
        letterCount += x;
        waiting = false;
      }, 1000)
    } else if (waiting === false) {
      target.innerHTML = words[0].substring(0, letterCount)
      letterCount += x;
    }
  }, 120)
  window.setInterval(function() {
    if (visible === true) {
      con.className = 'console-underscore hidden'
      visible = false;

    } else {
      con.className = 'console-underscore'

      visible = true;
    }
  }, 400)
}
*/

function addElement() {
  // create a new div element
  const newDiv = document.createElement("div");

  // and give it some content
  const newContent = document.createTextNode("Hi there and greetings!");

  // add the text node to the newly created div
  newDiv.appendChild(newContent);

  // add the newly created element and its content into the DOM
  const currentDiv = document.getElementById("div1");
  document.body.insertBefore(newDiv, currentDiv);
  console.log("ADD ELEMENT");
}