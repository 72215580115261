<p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
    <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="none center">
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Basic Info & Settings
            </ng-template>
            <ng-template pTemplate="content">
                <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                    (click)="CloneContent(editProduct)">Clone this Content</div>
                <br>
                Title
                <input id="title" #inputRef (click)="inputRef.select()" type="text" class="text-entry" pInputText
                    [(ngModel)]="editProduct.title" />

                <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="center center">
                    <div class="yes-no">
                        Active&nbsp;
                        <p-selectButton [options]="yesNo" [(ngModel)]="editProduct.active" optionLabel="option"
                            optionValue="value">
                            <ng-template let-item>
                                {{item.option}}
                            </ng-template>
                        </p-selectButton>
                    </div>

                    Direct link: {{getMyLink(editProduct)}}

                    <div class="yes-no">
                        Type&nbsp;

                        <p-selectButton [options]="type" [(ngModel)]="editProduct.type" optionLabel="option"
                            optionValue="option">
                            <ng-template let-item>
                                {{item.option}}
                            </ng-template>
                        </p-selectButton>
                    </div>
                </div>

                <div *ngIf="editProduct.type && editProduct.type.toLowerCase()=='product'" style="width:100%;" fxLayout="row wrap"
                    fxLayoutAlign="center center">

                    <div class="yes-no" fxLayout="column">
                        Retail Price
                        <p-inputNumber [(ngModel)]="editProduct.retail" inputId="currency-us" mode="currency"
                            currency="USD" locale="en-US">
                        </p-inputNumber>

                    </div>

                    <div class="yes-no">
                        On Menu
                        <p-selectButton [options]="yesNo" [(ngModel)]="editProduct.onMenu" optionLabel="option"
                            optionValue="value">
                            <ng-template let-item>
                                {{item.option}}
                            </ng-template>
                        </p-selectButton>
                    </div>
                    <div class="yes-no">
                        Redeemable
                        <p-selectButton [options]="yesNo" [(ngModel)]="editProduct.redeemable" optionLabel="option"
                            optionValue="value">
                            <ng-template let-item>
                                {{item.option}}
                            </ng-template>
                        </p-selectButton>
                    </div>
                    <div class="yes-no">
                        Shippable
                        <p-selectButton [options]="yesNo" [(ngModel)]="editProduct.shippable" optionLabel="option"
                            optionValue="value">
                            <ng-template let-item>
                                {{item.option}}
                            </ng-template>
                        </p-selectButton>
                    </div>
                </div>

                <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                    (click)="SaveProduct(editProduct, $event)">Save Changes</div>
            </ng-template>
        </p-accordionTab>
        <p-accordionTab style="width:100%;" [(selected)]="viewTagTab">
            <ng-template pTemplate="header">
                Tags
            </ng-template>
            <ng-template pTemplate="content">
                <div fxFlex fxLayout="column" fxLayoutAlign="center none" style="width: 100%">
                    <div fxLayout="row" style="width: 100%">
                        <input style="width:15em;" class="search" type="search" [(ngModel)]="searchString" pInputText
                            placeholder="Search or Add New">
                        <div *ngIf="searchString.length>0" (click)="clearSearch()" class="command"> Clear </div>
                        <div *ngIf="searchString.length>1" (click)="addNewTag($event)" class="command"> Add New Tag </div>

                    </div>
                    <div fxLayout="row" style="width: 100%">

                        <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:30%">
                            <div *ngIf="editProduct.allTags">
                                Tags assigned:
                            </div>
                            <div *ngIf="editProduct.allTags" fxFlex fxLayout="row wrap" fxLayoutAlign="start start">
                                <div *ngFor="let d of editProduct.allTags; let i = index;">
                                    <button (click)="removeTag($event, d)" class="pretty-postag" pButton pRipple type="button">
                                        {{d+"("+getTagCount(d)+")"}}
                                    </button>


                                </div>
                            </div>
                        </div>
                        <div style="width:70%" fxLayout="column" fxLayoutAlign="none none">
                            Tags not assigned:
                            <!-- TAGS AVAILABLE -->
                            <div *ngIf="global.allTags" fxFlex fxLayout="row wrap" fxLayoutAlign="none space-between">
                                <div *ngFor="let t of global.allTags; let i = index;">
                                    <div *ngIf="tagFilter(t)">
                                        <div *ngIf="!editProduct.allTags || (editProduct.allTags && !editProduct.allTags.includes(t))" >
                                             <button (click)="addTag($event, t)" class="pretty-postag" pButton pRipple type="button">
                                                {{t+"("+global.allTagsCount[i]+")"}}
                                            </button>
                                     
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ng-template>
        </p-accordionTab>

        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Text content
            </ng-template>
            <ng-template pTemplate="content">
                <div class="p-field p-col-12 p-md-3" style="cursor:text">
                    Body Content

                    <textarea *ngIf="!editProduct.html" pInputTextarea [rows]="10" [cols]="30" [autoResize]="true"
                        id="fullDescription" #inputRef (click)="inputRef.select()" type="text" class="text-entry"
                        [(ngModel)]="editProduct.fullDescription">
                </textarea>

                    <editor apiKey="kyiith4j5do4lxxcds8kr8siijwvuxpnqi2bf4klx9c48rxf"
                        [init]="{ inline: true, plugins: 'lists link table code help wordcount'}"
                        modelEvents="change input nodechange undo redo" [(ngModel)]="editProduct.html"></editor>


                </div>

            </ng-template>
        </p-accordionTab>
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Product Styling
            </ng-template>
            <ng-template pTemplate="content">


                <div fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                    <div class="command" (click)="Clicked($event.target.innerHTML)">+Height</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">-Height</div>
                    <div *ngIf="editProduct.style=='Tall Image'" class="command"
                        (click)="Clicked($event.target.innerHTML)">
                        Max content</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Left</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Right</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Up</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Down</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Cover</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Contain</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Fill</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">None</div>
                    <div class="command" (click)="Clicked($event.target.innerHTML)">Scale down</div>
                </div>

                <br>
                <div class="action-button3" style="cursor: pointer; background-color:rgb(156, 243, 122)"
                    (click)="SaveProduct(p, $event)">Save Changes</div>






                <div class="yes-no">
                    Styles&nbsp;
                    <p-selectButton [options]="styles" [(ngModel)]="editProduct.style" optionLabel="option"
                        optionValue="option" (onChange)="ChangeStyles()">
                        <ng-template let-item>
                            {{item.option}}
                        </ng-template>
                    </p-selectButton>
                </div>

            </ng-template>
        </p-accordionTab>

        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Manage Media
            </ng-template>
            <ng-template pTemplate="content">
<!--
                <div *ngIf="myMedia" style="width:100%">
                 
                    <div *ngFor="let m of myMedia; let i = index;">
                    
                        <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' || m.fileType=='video/quicktime')" class="edit-media">
                 
                            <video class="edit-media" playsinline preload="none" mute="true" webkit-playsinline
                                [muted]="'muted'">
    
                                <source src={{m.url}}>
                            </video>
                        </div>
                    </div>  
                </div>


                <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                    <div class="command" (click)="SetEditMode('find-images')">Search for Images</div>
                    <div class="command" (click)="SetEditMode('media')">Enter Image URL</div>
                    <div class="command" (click)="SetEditMode('video-url')">Enter Video URL</div>
                    <div class="command" (click)="SetEditMode('find-video')">Search for Video</div>
                    <div class="command" (click)="SetEditMode('add-gallery')">Add Gallery</div>

                </div>
                <div *ngIf="editMode=='media'">
                    <p-sidebar [(visible)]="visible" [fullScreen]="true">

                        <div fxLayout="column" fxLayoutAlign="start none">
                            <div class="action-button" (click)="SetEditMode('find-images')">Find
                                Media
                            </div>

                            Image URL
                            <input id="image" #inputRef (click)="inputRef.select()" type="text" class="text-entry"
                                pInputText [(ngModel)]="editProduct.image1" />

                            <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                (click)="SaveDataStop(editProduct, $event)">Save Changes</div>

                            <div *ngIf="editProduct.image1">
                                Current Image
                                <img class="fit-nicely" [src]="editProduct.image1" />
                            </div>
                        </div>
                    </p-sidebar>
                </div>

                <div *ngIf="editMode=='video-url'">
                    <p-sidebar [(visible)]="visible" [fullScreen]="true">

                        <div fxLayout="column" fxLayoutAlign="start none">
                            Image URL
                            <input id="image" #inputRef (click)="inputRef.select()" type="text" class="text-entry"
                                pInputText [(ngModel)]="editProduct.video1" />

                            <div class="action-button2" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                                (click)="SaveDataStop(editProduct, $event)">Save Changes</div>

                            <div *ngIf="editProduct.video1">
                                Current Video
                                <video muted id="myVideo">
                                    <source src="{{editProduct.video1}}" type="video/mp4">
                                </video>
                            </div>
                        </div>
                    </p-sidebar>
                </div>

                <div *ngIf="editMode=='add-gallery'">
                    <p-sidebar [(visible)]="selectGalleryVisible" [fullScreen]="true">
                        <app-gallery (selectionEvent)="setGallery($event)"></app-gallery>
                    </p-sidebar>
                </div>

                <div *ngIf="editMode=='find-images'">
                    <p-sidebar [(visible)]="visible" [fullScreen]="true">

                        <div class="gallery">
                            <input type="text" pInputText [(ngModel)]="imgSearch" />
                            <div class="command" (click)="FindImages(imgSearch)">Search Images</div>

                            <p-galleria [(value)]="images" [responsiveOptions]="responsiveOptions" [numVisible]="3"
                                thumbnailsPosition="top">
                                <ng-template pTemplate="item" let-item>
                                    <img (click)="ChooseImage(editProduct, item)" [src]="item.previewImageSrc"
                                        style="width: 100%; display: block;" />
                                </ng-template>
                                <ng-template pTemplate="thumbnail" let-item>
                                    <div>
                                        <img style="height: 120px; overflow:hidden; object-fit:contain"
                                            [src]="item.thumbnailImageSrc" />
                                    </div>
                                </ng-template>
                            </p-galleria>
                        </div>

                    </p-sidebar>
                </div>

                <div *ngIf="editMode=='find-video'">
                    <p-sidebar [(visible)]="visible" [fullScreen]="true">

                        <div class="gallery">
                            <input type="text" pInputText [(ngModel)]="imgSearch" />
                            <div class="command" (click)="FindVideo(imgSearch)">Search Videos</div>

                            <div fxFlex fxLayout="row wrap" fxLayoutAlign="none none"
                                *ngFor="let v of videos; let i = index;">
                                <video playsinline webkit-playsinline  muted  id="myVideo"
                                    (click)="ChooseVideo(editProduct, v)">
                                    <source src="{{v}}" type="video/mp4">
                                </video>

                            </div>
                        </div>

                    </p-sidebar>
                </div>
-->
        <app-media [editProduct]="editProduct" [companyId]="global.myCompany.id"></app-media>

            </ng-template>
        </p-accordionTab>
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Options & Add-ons
            </ng-template>
            <ng-template pTemplate="content">
                Title:
                <br>Price:
            </ng-template>
        </p-accordionTab>
        <!--
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Group Settings
            </ng-template>
            <ng-template pTemplate="content">
                Title:
                <br>Price:
            </ng-template>
        </p-accordionTab>
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Size Variants
            </ng-template>
            <ng-template pTemplate="content">
                Title:
                <br>Price:
            </ng-template>
        </p-accordionTab>
        <p-accordionTab style="width:100%;">
            <ng-template pTemplate="header">
                Inventory
            </ng-template>
            <ng-template pTemplate="content">
                Title:
                <br>Price:
            </ng-template>
        </p-accordionTab>
        -->
    </div>

</p-accordion>