import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, NapkinApp, Container, Form, Field, ContainerSettings, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.css']
})
export class AppsComponent {

  appWidth: string = '6';  // # columns
  appHeight;

  constructor(public global: Globals,) {

  }

  gotoApp(app) {

    const r = "https://napkinhq.com/app/" + app.id;

    window.open(r)
  }


  getAppWidth() {
    var width = '50vw';

    switch (this.appWidth) {
      case '1 - full width':
        width = '100vw'
        break;
      case '2':
        width = '50vw'
        break;
      case '3':
        width = '33vw'
        break;
      case '4':
        width = '25vw'
        break;
      case '5':
        width = '20vw'
        break;
      case '6':
        width = '14vw'
        break;
      case '8':
        width = '12vw'
        break;
      case '10':
        width = '10vw'
        break;
      case '12':
        width = '8vw'
        break;
      case '16':
        width = '6vw'
        break;
      case '20':
        width = '5vw'
        break;
    }
    if (this.global.getScreenWidth < 600) width = '30vw'

    return width;
  }
  getAppHeight() {

    const ratio = this.global.getScreenWidth / this.global.getScreenWidth
    var width = this.global.getScreenWidth;

    var columns = parseInt(this.appWidth)

    if (this.global.getScreenWidth < 600) columns = 3;

    width /= columns;
    return "height:" + width + 'px'; // SQUARE

  }

  myName(app:NapkinApp) {
    const len = app.name.length;
    var str = app.name;
    if (len > 15)
      str = str.substring(0,20) + "..."

    return str;
  }

}
