<div *ngIf="global.pendingReviews" class="container">
    <div *ngFor="let p of global.pendingReviews; let i = index">

        <div class="review">

            <div class="review__content" fxLayout="column" fxLayoutAlign="none none">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div class="review__rating">Rated&nbsp;{{p.review_rating}} stars
                        {{" "+p.dateStr}}
                    </div>
                    <div class="review__subtitle">{{p.author_name}}</div>
                </div>


                <div *ngIf="p.media" fxLayout="row wrap" fxLayoutAlign="center center">
                    <div *ngFor="let m of p.media; let ind = index">

                        <div *ngIf="m.fileType && (m.fileType=='video' || 
                        m.fileType=='video/mp4' || m.fileType=='video/quicktime')">

                            <video class="media" playsinline webkit-playsinline controls [muted]="'muted'">
                                <source src={{m.url}}>
                            </video>

                        </div>

                        <div
                            *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/png' || m.fileType=='photo'">
                            <img style="max-height: 400px; max-width: 100%;" src={{m.smallUrl}} />
                        </div>
                    </div>
                </div>


                <div *ngIf="!p.media && p.review_photos" fxLayout="row wrap" fxLayoutAlign="center center">
                    <div *ngFor="let m of p.review_photos; let ind = index">
                        <div *ngIf="p.fileType && (p.fileType=='non-image' || p.fileType=='video/quicktime')">
                            <video class="media" playsinline webkit-playsinline autoplay [muted]="'muted'">
                                <source src={{m}} type="video/quicktime,video/mp4,video/mov">
                            </video>
                        </div>

                        <div
                            *ngIf="p.fileType && (p.fileType=='video' || p.fileType=='video/mp4' || p.fileType=='video/quicktime')">
                            <video class="media" playsinline webkit-playsinline autoplay [muted]="'muted'">
                                <source src={{m}} type="video/quicktime,video/mp4,video/mov">
                            </video>
                        </div>

                        <div
                            *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/png' || m.fileType=='photo'">
                            <img style="max-width: 100%;" src={{m}} />
                        </div>
                    </div>
                </div>



                <div>
                    <p class="review__desc">{{p.review_text}}</p>
                </div>
            </div>


        </div>
        <!-- 
        <div *ngIf="p.couponURL">
            Active Coupon:
            {{p.couponName}}
            {{p.couponDesc}}

          <qrcode (qrCodeURL)="onChangeURL($event,p)" [qrdata]="p.couponURL" [width]="64" [errorCorrectionLevel]="'M'"></qrcode>
    
       <img [src]="p.qrcodeURL" alt="" title="" />   
          
    
        </div> -->

        <div fxLayout="row wrap" fxLayoutAlign="center none">
            <div class="command" (click)="publishReview(p,i)">PUBLISH Now</div>
            <div class="command-red" (click)="removeReview(p,i)">Remove Review</div>
        </div>
        <br><br>

    </div>
</div>