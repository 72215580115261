import { Component, SkipSelf, Input, HostListener, ViewChild, OnInit, OnDestroy, AfterViewInit, BootstrapOptions } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../environments/environment';
import { interval, NotFoundError } from 'rxjs';
import { User, Media, MediaGroup } from '../user-interface';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/globals';
import { Company, Share, Palette, Form, Field, NapkinApp, Gallery, AppStats, AppNode, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';
import { Product, Options, Table } from '../product-int';
import { onMenuItem } from '../menu-item-interface';
import { Content, Weather } from '../content-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../products.service';
import { ViewProductsComponent } from '../menu/view-products/view-products.component';
import { DragDropModule } from 'primeng/dragdrop';
import { HttpEvent } from '@angular/common/http';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { NavService } from '../menu/nav.service';
import { ReviewsComponent } from '../reviews/reviews.component';
import { Quote } from '../content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { CartService, SharedDataService } from '../menu/cart.service';
import { Order } from '../order-interface';
import { Howl, Howler } from 'howler';
import { FormService } from '../form.service';
import { NodeService } from '../node.service'
import { Logger } from 'src/app/functions';
import { MediaService } from "../media/media-functions/media-functions"
import { IpService } from 'src/app/GetIP.service'
import { Meta } from "@angular/platform-browser";


export interface AppForm {
  node: AppNode;
  form: Form;
}
export interface Container {
  node: AppNode;
  index: number;
}


@Component({
  selector: 'app-appMaker',
  templateUrl: './app-maker.component.html',
  styleUrls: ['./app-maker.component.scss', '../../common.scss']
})
export class AppMakerComponent implements OnInit {
  @Input() app: NapkinApp;
  @Input() companyId: string = "";
  @Input() editing: boolean = false;
  @Input() appId;
  @Input() headLess: boolean = false;
  @Input() userApp: string;
  @Input() currentParent: string = "Home";
  @Input() overFlow;

  appName: string;

  //allMedia: Media[];

  objectList: any[] = [];
  objectSelected: string;
  appSettings: Form;


  //  appId: string; // Collection Id of current app
  compId: string;
  ipAddress: string;


  //  parent: string[] = ["Home"];
  //  parents: AppNode[];
  parentNode: AppNode;
  typeOptions: any[];
  colorOptions: any[];
  allTotals: any[];
  // quote: Quote;
  muted: boolean = false;
  list: string[];
  pageRoot: any;



  constructor(public cartService: CartService, public mediaService: MediaService,
    public nav: NavService, public sanitizer: DomSanitizer,
    public global: Globals, private router: Router, private ipService: IpService,
    private route: ActivatedRoute,
    public navService: NavService,
    public formService: FormService,
    public nodeService: NodeService,
    private meta: Meta,
    public productService: ProductsService) {
    this.typeOptions = [
      { index: 0, label: 'Content', value: 'content' },
      { index: 1, label: 'Nav Container', value: 'Nav' },
      { index: 2, label: 'object', value: 'object' },
    ];

    this.list = ["This is A", "This is B", "This is C"]

    this.colorOptions = ['#23890790', '#5093ff33', '#0fe3a2e3', '#45672121',
      '#23452323', '#f3651210'];




  }


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.global.getScreenWidth = window.innerWidth;
    this.global.getScreenHeight = window.innerHeight;
    //   console.log("SIZE CHANGED: ", window.screen, window.visualViewport, this.global.getScreenWidth )
  }


  /*
    @HostListener('window:unload', ['$event'])
    async unloadHandler(event) {
      var self = this;
      Logger("Update Stats ", self.global.authuser.uid, "")
  
      console.log("Elvis has left the building...")
  
     // self.nodeService.AddItem(self.appId, self.ipAddress).subscribe();
    }
  */

  @HostListener('window:pagehide', ['$event'])
  pagehide(event) {
    var self = this;
    Logger("Update Stats 2 ", self.global.authuser.uid, "")

    const url = "https://updatestats-xdnunfyrka-uc.a.run.app";

    var appId = self.appId;
    // self.nodeService.AddItem(appId, self.ipAddress)

    var data = { AppId: 'app-0000', companyId: '45612', stats: self.global.appDoc[appId].app.appStats }

    //  console.log("Elvis has left the building... ")

    const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });

    navigator.sendBeacon(url, blob);

  }

  ngOnInit(): void {

    this.global.getScreenWidth = window.outerWidth;
    this.global.getScreenHeight = window.innerHeight;

    // GET User's IP address
    this.setIpAddress()

    // FIX META
    this.meta.updateTag({
      name: 'viewport',
      content: 'width=device-width, initial-scale=1'
    });
    /*
    this.meta.updateTag({ 
      'http-equiv': 'Refresh',
      content: '0; URL=https://napkinreviews.com/'
  });
  */

   // var store = this.route.snapshot.paramMap.get('store')
    var store = this.route.snapshot.queryParamMap.get('store');

    console.log ("Store ", store)
    if (store == '4390')
      appId = "chv"
    else
      var appId = this.route.snapshot.paramMap.get('appId')

    console.log("appId ", appId, this.companyId, this.global.getScreenWidth)
    this.global.parents = [];

    if (this.userApp && this.userApp?.length > 0) {

      this.getUserApp(this.userApp)

      return;
    }

    if (appId) {
      this.appId = appId;

      firebase.initializeApp(environment.firebase);

      const db = firebase.firestore()
      const vm = this;
      var docRef =
        db.collection('apps')
          .doc(appId)
          .get().then((doc) => {
            const data = <NapkinApp>doc.data()
            console.log("Here's what I got: ", data)

            // CHECK FOR ACCESSCONTROL
            const ac = data.accessControl.toLowerCase();
            vm.app = data;
            if (!vm.app.share) vm.app.share = {}
            //     if (!vm.global?.appDoc) vm.global.appDoc = {name:"test"}

            if (!vm.global.appDoc[vm.appId])
              vm.global.appDoc[vm.appId] = { name: vm.appId, app: {} }

            vm.global.appDoc[vm.appId].app = data;

            appId = data.appId;

// NEED TO REWRITE THIS CODE FOR NEW SHARING
vm.navService.GetStoreId(data.companyId, this.gotStore, vm)



            if (ac == 'public') {

              //        vm.global.appId = appId;
              vm.appSettings = {
                showSubmit: true,
                fields: [{ title: "name", binding: data.name }]
              }

              vm.navService.GetStoreId(data.companyId, this.gotStore, vm)

              //  if (vm.appId=='app-9530')
              //    window.location.replace('https://NapkinReviews.com'); 
              //  //   window.location.href = "https://NapkinReviews.com/";

              console.log("Got link ", vm.companyId, vm.appId, vm.appSettings)
              vm.getApp(vm.companyId, appId);

            }
            else if (ac == 'private') {
              // USER NOT LOADED YET. Let's get it done.
              // Load user
              const id = localStorage.getItem('uid');

              // GET USER CHECKED FIRST 
              if (id && !this.global.authuser) {
                console.log("UID: ", id, this.global.allReservations);
                vm.nav.GetUserwID(id, false, false, this, this.afterUserLoaded);// true get all res

                vm.global.userTabOpen = false;
              }
            }
            else {
              // FAILED ACCESS CONTROL


            }

          })
          .catch((error) => {
            console.error("Error getting app link", error);
          });

    }

    //   if (this.appId=='app-9530')
    //   window.location.replace('https://NapkinReviews.com'); 

    console.log("App:", this.companyId, this.app, this.appId)
    if (this.appId) {
      this.getApp(null, this.appId)
    }

    if (this.app) {
      console.log("Napkin APP: ", this.app)

      if (!this.global.myCompany || (this.global?.myCompany.id != this.app.companyId))
        this.navService.GetStoreId(this.app.companyId, this.gotStore, this)
      else {
        //SHOULD ONLY BE DONE AFTER STORE LOADED  this.formService.getForms();
      }

      this.appId = this.app.appId;
      //   this.global.appId = appId;
      if (!this.global?.appDoc)
        this.global.appDoc = {}

      this.global.appDoc[this.appId] = { name: this.appId, app: this.app };
      console.log("APD STATS: ", this.global.appDoc)

      this.getApp(this.app.companyId, this.app.appId)
      //move to promise  this.getProducts();

      console.log("App2:", this.app.companyId, this.app)

    }
    this.pageRoot = document.getElementById("page-root")

    // console.log("GLOBAL APP: ", this.global.appId, this.pageRoot)
  }

  afterUserLoaded(vm: this) {
    if (vm.app.accessControl == 'private'
      && vm.app.createdBy == vm.global.authuser.uid) {

      //   vm.global.appId = vm.app.appId;
      vm.appSettings = {
        showSubmit: true,
        fields: [{ title: "name", binding: vm.app.name }]
      }

      vm.navService.GetStoreId(vm.app.companyId, vm.gotStore, vm)

      console.log("Got link ", vm.companyId, vm.appId, vm.appSettings)
      vm.getApp(vm.companyId, vm.app.appId);
      vm.appSettings = {
        showSubmit: true,
        fields: [{ title: "name", binding: vm.app.name }]
      }

      vm.navService.GetStoreId(vm.app.companyId, vm.gotStore, vm)

      console.log("Got link ", vm.companyId, vm.appId, vm.appSettings)
      vm.getApp(vm.companyId, vm.app.appId);


    }

  }


  outputEventShare (share: Share) {
    var self = this;

    console.log ("AppMaker got new share data ", share)
    
    self.updateAppShare (share)
  }

  updateAppShare(share: Share) {
    const db = firebase.firestore()
    const vm = this;

    const index = vm.global.publicApps.findIndex(x => x.appId === vm.appId)

    if (index != -1) {
      var app: NapkinApp = { ...vm.global.publicApps[index] }

      app.share = share;
      app.editedAt = Date.now();
      app.editedBy = vm.global.authuser.uid;
      app = removeUndefined(app)

      var docRef = db.collection('apps').doc(app.appId)

      docRef.update(app)
        .then((doc) => {
          console.log("App updated ", app)
          vm.global.publicApps[index] = app;

        })
        .catch((error) => {
          console.error("Error updating app share", error);
        });
    }

  }

  private setIpAddress() {
    var self = this;
    this.ipService.getIPAddress().subscribe((result: any) => {
      self.ipAddress = result.ip;
      console.log("IP: ", self.ipAddress);

    })
  }

  openTab(data) {
    var self = this;

    var tab: Container = data;

    console.log("User opened tab appmaker: ", tab)
    // Now that user opened the tab we add a parent
    self.containerOpen(tab.node, tab.index);

  }

  gotStore(self: this) {
    var user = "Anon";

    if (self.global?.authuser?.uid) user = self.global.authuser.uid;

    Logger("App Start-", user, self.global.myCompany.store, self.appId, self.ipAddress)


    self.formService.getForms();
    self.mediaService.getMediaGroups()
    self.mediaService.getMedia()



    if (self.global?.allProducts.length == 0) {
      console.log("GETTING ALL PRODUCTS -------")
      self.productService.GetAllProducts();
    }
    //    self.getProducts();
  self.cartService.GetMyOrder();


    // LOG THE ACCESS
    self.nodeService.AddItem(self.appId, self.ipAddress); //.subscribe();

  }

  updateAppName(name, accessControl, maxWidth, palette: Palette, cover, addToCart, showLogo) {
    const db = firebase.firestore()
    const vm = this;

    // FIX TO USE NapkinfieldstoObject
    console.log("NEW APP NAME: ", name)
    const index = vm.global.publicApps.findIndex(x => x.appId === vm.appId)

    if (index != -1) {
      var app: NapkinApp = { ...vm.global.publicApps[index] }

      app.name = name;
      app.accessControl = accessControl;
      app.maxWidth = maxWidth;
      app.palette = palette;
      app.coverImage = cover;
      app.addToCart = addToCart;
      app.showLogo = showLogo;
      app.editedAt = Date.now();
      app.editedBy = vm.global.authuser.uid;
      app = removeUndefined(app)

      var docRef = db.collection('apps').doc(app.appId)

      docRef.update(app)
        .then((doc) => {
          console.log("App updated ", app)
          vm.global.publicApps[index] = app;

        })
        .catch((error) => {
          console.error("Error updating app name", error);
        });
    }

  }

  getMyAppLink() {

    if (this.app) {
      //   if (this.app.name)
      //     return "/app/" + this.app.name;  //test
      //   else
      return "/app/" + this.app.appId.toString()
    }

  }

  isImage(m: Media) {
    if (m.fileType == "image/png" || m.fileType == "image/jpg" || m.fileType == "image/jpeg") {
      return true;
    }
    else return false;
  }


  lastReview: number;

  updateCard(node: AppNode) {

    var found = this.global.publishedReviews.findIndex((f, index) =>
      f.review_photos?.length > 0 && f.rating == '5'
      && index > this.lastReview)

    if (found != -1) {
      node.review = this.global.publishedReviews[found];
      node.review.style = 'polaroid';
      this.lastReview = found;

    }

  }
  /*
    getForms() {
      var self = this;
      var db = firebase.firestore();
      var index = 0;
  
      self.global.allForms = [];
      db.collection('company')
        .doc(self.global.myCompany.id)
        .collection("forms")
        .onSnapshot((querySnapshot) => {
          //   index = 0;
          self.global.allForms = [];
          self.global.allFormNames = [];
          self.global.allSessionFields = [];
          console.log("BEFORE UPDATE: ", self.global.allForms.length)
  
          querySnapshot.forEach((doc) => {
            const m = <Form>doc.data();
            console.log("FORM: ", m)
            self.global.allForms.push(m);
            self.global.allFormNames.push(m.name);
            m.fields.forEach(function (field) {
              self.global.allSessionFields.push({ id: field.id, sourceForm: m.name, sourceField: field.title, type: field.type })
  
            })
          })
  
          console.log("App-maker ALL Forms ", self.global.allForms, self.global.allSessionFields)
  
        })
  
  
    }
  */






  dataEdit(data) {
    var appForm: AppForm = data;

    console.log("GOT IT BABY: ", appForm.node, appForm.form);

    appForm.node.formInput = appForm.form;

    // Now Copy New field data
    // appForm.node.forms[0].fields = appForm.form.fields;


  }

  formSubmitted(form: Form) {
    var self = this;
    // Actions: go-when-valid, go-on-submit

    if (!self.global.appForms) self.global.appForms = [];
    self.global.appForms.push(form); // hack check for dupes (or keep all?)
    console.log("Form submitted: ", form, self.global.appForms)

    // Goto Hack - remove soon
    //    const found = this.appNodes.find(element => element.id == "QIjof3MXhe5pw1ozR1Dh")
    //    this.navTo(found);
    // Goto Hack - remove soon

    if (typeof form.autoSave === 'undefined' || form?.autoSave == true)
      self.formService.saveForm(form);

    return;

    // Check for Actions in the form
    if (form.actions) {
      if (form.actions[0].action == "Go to form") {
        console.log("Go to form: ", form.actions[0].destination)

        var me = form.actions[0].destination.toLowerCase();

        const index = self.global.allForms.findIndex(function (post) {
          if (post.name.toLowerCase() == me)
            return true;
        });

        form = self.global.allForms[index];




      }
    }





  }
  /*
    changeType(node: AppNode, index) {
      switch (index) {
        case 0: // content
          break;
        case 1: // nav
  
  
          break;
        case 2: // feature
          break;
  
      }
  
      // Auto update record
      this.updateNodeStatus(node, this.global.myCompany.id, this.appId)
    }
    */

  editNode: AppNode;
  editMe(node: AppNode) {
    var self = this;
    console.log("EDIT THIS NODE: ", node)
    self.editNode = node;
    self.editing = true;

    //  this.global.selectedTab = null;

  }
  finishEditing() { this.editNode = null; }




  /*
    updateNodeStatus(node: AppNode, docId, collectionId) {
      const db = firebase.firestore()
  
      const vm = this;
      var docRef =
        db.collection('apps')
          .doc(collectionId)
          .collection('nodes').doc(node.id)
      docRef.update({ type: node.type });
    }
  */
  oneQuote: boolean = false;



  fixApp(docId, collectionId) {
    return;

    this.global.updatingNodes = true;

    const db = firebase.firestore()

    const vm = this;

    db.collection('company')
      .doc(docId)
      .collection(collectionId)

      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <AppNode>doc.data()

          if (data.type.toLowerCase() == 'nav' || data.objectType == 'tabList' || data.type == 'Home') {
            if (!data.parentId) {
              if (data.type == 'Home')
                data.parentId = 'Home'
              else
                data.parentId = vm.createParentId()
              const addParentId = doc.ref.update({ parentId: data.parentId })
            }
            //       vm.updateParents(docId, collectionId, data.id, data.parentId)

          }
        })
        vm.global.updatingNodes = false;
      })

    console.log("Got App, now sort: ", vm.global.appNodes)

  }

  /* Used with fixapp

  updateParents(docId, collectionId, oldId, newId) {
    const db = firebase.firestore()

    const vm = this;

    db.collection('company')
      .doc(docId)
      .collection(collectionId)

      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <AppNode>doc.data()

          if (data.parent == oldId) {
            data.parent = newId;
            const newParentId = doc.ref.update({ parent: newId })
          }
        })
      })

  }
*/


  getApp(docId, collectionId) {
    const db = firebase.firestore()

    //  this.fixApp(docId, collectionId);

    const vm = this;
    if (!vm.global.appNodes) vm.global.appNodes = {}

    var homeNode: Node;

    console.log("Set up App Listener: ", docId, collectionId)

    if (vm.global.myCompany) {

      if (vm.global.publicApps) {
        const index = vm.global.publicApps.findIndex(x => x.appId === vm.appId)

        if (index != -1) {
          vm.appSettings = {
            showSubmit: true,
            fields: [{ title: "name", binding: vm.global.publicApps[index].name },
            { title: "sharing", binding: vm.global.publicApps[index].accessControl },
            { title: "maxWidth", binding: vm.global.publicApps[index].maxWidth },
            { title: "palette", palette: vm.global.publicApps[index].palette },
            { title: "addToCart", trueFalse: vm.global.publicApps[index].addToCart },
            ]
          }
        }
      }
    }

    db.collection('apps')
      .doc(collectionId)
      .collection('nodes')
      .onSnapshot((querySnapshot) => {
        if (vm.global.updatingNodes) return;
        if (!vm.global.appNodes[collectionId])
          vm.global.appNodes[collectionId] = { name: collectionId, nodes: [] }
        querySnapshot.forEach((doc) => {
          if (doc) {
            const data = <AppNode>doc.data()
            // TEMP FIXER
            if (!data.objectType && data.type) data.objectType = data.type;

            var html: SafeHtml;
            html = vm.sanitizer.bypassSecurityTrustHtml(data.html);
            data.safeHtml = html;

            //FIXER
            if (data.objectType == 'dataview') {
              if (!data.reportStyles) data.reportStyles = ["none"];
            }

            if (data.objectType == 'famousquote' && !vm.oneQuote) {
              vm.GetInspiration();
            }
            const index = vm.global.appNodes[collectionId].nodes.findIndex(function (post) {
              if (post.id == data.id)
                return true;
            });
            if (index != -1) {
              if (vm.global.appNodes[collectionId].nodes[index] != data) {
                console.log("Copy only ")
                vm.global.appNodes[collectionId].nodes[index] = { ...data };
              }
            }
            else vm.global.appNodes[collectionId].nodes.push(data)
          }
        })
        //    vm.global.appId = collectionId;
        console.log("Got App, now sort: ", vm.global.appNodes)

        vm.global.appNodes[collectionId].nodes.sort(function (a, b) {
          if (a.myPosition == null) a.myPosition = 100;
          if (b.myPosition == null) b.myPosition = 100;
          return (a.myPosition - b.myPosition)
        });

        console.log("nodes: ", vm.global.appNodes, vm.editNode);
      })


  }


  getUserApp(name) {
    const db = firebase.firestore()
    const vm = this;
    vm.global.appNodes = {};
    var homeNode: Node;


    db.collection('users').doc(vm.global.authuser.uid)
      .collection("apps")
      .doc(name)
      .collection('nodes')
      .get()
      .then((querySnapshot) => {
        console.log("FETCH APP")
        querySnapshot.forEach((doc) => {
          if (doc) {
            const data = <AppNode>doc.data()
            // TEMP FIXER
            if (!data.objectType && data.type) data.objectType = data.type;

            var html: SafeHtml;
            html = vm.sanitizer.bypassSecurityTrustHtml(data.html);
            data.safeHtml = html;

            //FIXER
            if (data.objectType == 'dataview') {
              if (!data.reportStyles) data.reportStyles = ["none"];
            }
            /*         if (data.objectType == 'mediagroup') {
                       vm.mediaService.getMediaGroups()
                       vm.mediaService.getMedia()
                     }
                     */

            if (data.objectType == 'famousquote' && !vm.oneQuote) {
              vm.GetInspiration();
            }
            if (data.objectType == 'product') {
              //     vm.productService.GetAllProducts();
            }
            const index = vm.global.appNodes[name].nodes.findIndex(function (post) {
              if (post.id == data.id)
                return true;
            });
            if (index != -1) {
              if (vm.global.appNodes[index] != data) {
                console.log("Copy only ")
                vm.global.appNodes[index] = { ...data };
              }
            }
            else vm.global.appNodes[name].nodes.push(data)
          }
        })
        //     vm.global.appId = name;
        console.log("Got App, now sort: ", vm.global.appNodes)

        vm.global.appNodes[name].sort(function (a, b) {
          if (a.myPosition == null) a.myPosition = 100;
          if (b.myPosition == null) b.myPosition = 100;
          return (a.myPosition - b.myPosition)
        });
        /*
                vm.global.formNames = [];
                vm.global.appNodes.forEach(function (node) {
                  // Initialize Forms
                  if (node.objectType == 'form')
                    vm.global.formNames.push("register-store") // hack check for dupes (or keep all?
                })
                */

        console.log("nodes: ", vm.global.appNodes);
      })


  }

  containerOpen(node: AppNode, tabIndex) {
    var self = this;
    /* NEED TO ADD APPID TO FUNCTION
        // How many child nodes do I have??
        const nodeCount = self.global.appNodes[].some(function (post) {
          if (post.parent == node.containers[tabIndex].parentId)
            return true;
        });
        if (!nodeCount) {
          node.parent = node.parentId;
          self.nodeService.insertNew(node, self.appId, tabIndex);
        }
        console.log("Container opened -appmaker", node, tabIndex, nodeCount)
    */

    /*
    
        if (!node.tabList || (node.tabList && !node.tabList[tabIndex])) {
          console.log("Insert NEW ", node, tabIndex);
          self.nodeService.insertNew(node, self.appId, tabIndex);
        }
    */

    /*
    
        var self = this;
    
        // First find my parent/Home
        const index = self.global.appNodes.findIndex(function (post) {
          if (post.parentId == node.parent)
            return true;
        });
        // Home should always be found
    
        if (index != -1) {
          self.global.parents.push(self.global.appNodes[index]);
          self.global.parentNode = self.global.appNodes[index];
        }
    
      //  self.global.parent = node.parentId;
      self.currentParent = node.parentId;
        console.log("go: ", index, node, self.global.parentNode);
    
        // How many child nodes do I have??
        const nodeCount = self.global.appNodes.some(function (post) {
          if (post.parent == node.parentId || post.parent == node.id)
            return true;
        });
        if (!nodeCount) {
          node.parent = node.parentId;
          self.nodeService.insertNew(node, self.appId);
        }
        */





  }
  /*
    checkParent(input: string) {
      return this.global.parent.includes(input)
    }
    */

  getWidth(node) {

    if (node == 99) {
      if (self.global?.parents.length > 1) return '50%'
      else return '100%'

    }

    if (node == 0) {
      if (this.global.getScreenWidth > 0)
        return this.global.getScreenWidth;
    }
    else return "100%";

    if (this.app.maxWidth == "100%")
      return this.global.getScreenWidth;
    if (this.global.getScreenWidth > this.app.maxWidth)
      return this.app.maxWidth;


    if (node.width)
      return node.width.toString() + 'px'
    else return "100%";
  }

  goHome() {
    var self = this;

    const home = self.global.appNodes[self.appId].nodes.find(function (post) {
      if (post.id == 'Home')
        return true;
    });

    self.global.parents = [];
    self.global.parentNode = null;

    self.currentParent = home.parentId;
    // self.global.parent = home.parentId;

    console.log("parent: ", self.global.parentNode);
    self.pageRoot.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  }
  changeParent(event) {
    var self = this;
    console.log("We changed parents: ", event)
    self.currentParent = event;
  }

  goBack() {
    var self = this;

    console.log("BACK: parents", self.global.parents, self.appId)
    if (self.global.parents.length) {

      const doc = self.global.parents[self.global.parents.length - 1];
      console.log("Popped: ", doc, this.global.parents)
      self.global.parents.splice(self.global.parents.length - 1, 1);

      //   self.global.parent = doc.parentId;
      self.currentParent = doc.parentId;

      // Get parent container of the node
      const index = self.global.appNodes[self.appId].nodes.findIndex(function (post) {
        if (post.id == doc.parent)
          return true;
      });
      // Home should always be found

      if (index != -1) {

        self.parentNode = self.global.appNodes[index];
      }
      else { console.log("No Back1") }
    }
    else {
      console.log("No Back2")
      self.goHome();

    }
  }

  getProduct(productId): Product {

    const found = this.global.allProducts.find(element => element.docID == productId)

    if (found && found.active == true) {
      console.log("found p")
      return found
    }
    else return null;
  }
  taggedProduct(node, p) {

    if (!node.productTags) return false;

    var found = false;
    node.productTags.forEach(function (item) {
      if (p.allTags && p.allTags.includes(item) && p.active == true && p.onMenu) found = true;

    })


    return found;

  }

  item1: any;

  helpAudio(n) {

    if (!this.item1) {
      this.item1 = new Howl({
        src: ['../../../assets/shipsbell.webm', '../../../assets/help1.m4a'], html5: true, onplayerror: function () {
          this.item1.once('unlock', function () {
            this.item1.play();
          });
        }
      });

    }
    else this.item1.play();

  }

  firstTime: boolean = true;

  getProducts() {
    var self = this;
    var colorIn = 0;
    //  self.global.products = [];
    self.global.allTags = [];
    self.global.allTagsCount = [];

    return;

    console.log("getting ALL Products...", self.global.myCompany.id);
    var db = firebase.firestore();

    const q = query(collection(db, "products"), where("companyID", "==", self.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      // self.allItems = [];
      //    self.global.allTagsCount.splice(0, self.global.allTagsCount.length);
      //    self.global.allTags = [];
      //   self.global.allTagsCount = [];
      querySnapshot.forEach((doc) => {

        var item = <Product>doc.data();
        // Fix Product/Content field
        if (item.type) {
          if (item.type.toLowerCase() == "product") item.type = "Product"
          if (item.type.toLowerCase() == "content") item.type = "Content"
        }
        else {
          if (item.retail > 0)
            item.type = "Product";
          else
            item.type = "Content"
        }




        /*    const obj = {};
           
                      if (item.allTags && item.allTags.length) {
                        item.allTags.forEach((element, index) => {
                          obj[`tag${index}`] = element;
                        });
            
                        item = { ...item, ...obj }
                      }
                    */
        const index = self.global.products.findIndex(prod => prod.docID === item.docID);
        if (index != -1)
          self.global.products[index] = item;
        else {
          //  console.log("Adding")
          self.global.products.push(item);
        }

      })
      if (self.firstTime) {
        console.log("Addinglkjfd")
        self.firstTime = false;
        self.global.products.forEach(function (item, index) {
          if (item.allTags) {
            item.allTags.forEach(function (tag) {

              var ind = self.global.allTags.indexOf(tag);
              if (ind == -1) {
                self.global.allTags.push(tag);
                self.global.allTagsCount.push(1);
              }
              else {
                self.global.allTagsCount[ind]++;
              }

            })
          }
        })



        // Sort - Alpha then active
        self.global.products.sort(function (a, b) {
          if (b.title > a.title) return -1;
          if (b.title < a.title) return 1;
          return 0;

        });

        self.global.products.sort(function (a, b) {
          if (b.active == a.active) return 0;
          if (b.active == false) return -1;
          return 1;

        });
      }
      this.getDataViews();   // as callback after app loaded

      console.log("All prod: ", self.global.products, self.global.allTagsCount);
    })

  }

  closedOrders: Order[];

  getDataViews() {
    // Hack move to service // load only what's in app


    var db = firebase.firestore();
    var self = this;

    var dayago = Date.now() - (60000 * 60 * 24) * 30;

    /// start with last 30 days orders

    db.collection("orders").where("companyID", "==", self.global.myCompany.id).where("status", "==", "Closed").where("closedAt", ">", dayago)
      .onSnapshot((querySnapshot) => {
        //        self.closedOrders.splice(0, self.global.openOrders.length);  // ERASE
        self.closedOrders = [];
        querySnapshot.forEach((doc) => {
          const data = <Order>doc.data();

          if (data.showOrder == null || data.showOrder == true) {
            let dateT = data.dateTime;
            let localOrder = <Order>data;

            if (localOrder.status == 'Removed') { }
            else {
              /*
              const index = self.closedOrders.findIndex(function (post) {
                if (post.resultId == localOrder.resultId)
                  return true;
                  
              });
              */

              //  if (index == -1)
              self.closedOrders.push(localOrder);
            }
          }

        });

        // Now let's do some math...
        self.allTotals = [];

        self.closedOrders.forEach(function (order) {
          if (order.extPrice == 0) console.log("zero: ", order)

          const index = self.allTotals.findIndex(function (post) {
            if (post.name == order.item)
              return true;
          });
          if (index != -1) {
            self.allTotals[index].count += order.qty;
            self.allTotals[index].rev += order.extPrice;
          }
          else {
            var totals = {
              name: order.item, count: order.qty, rev: order.extPrice
            }
            self.allTotals.push(totals);
          }


        })
        self.global.top5ByUnits = [];
        self.global.top5ByRev = [];

        self.allTotals.sort(function (a, b) { return b.count - a.count; });

        self.allTotals.forEach(function (item, index) {
          if (index <= 5) {
            self.global.top5ByUnits.push(item.name);
          }
        })

        self.allTotals.sort(function (a, b) { return b.rev - a.rev; });

        self.allTotals.forEach(function (item, index) {
          if (index <= 5) {
            self.global.top5ByRev.push(item.name);
          }
        })

        console.log("Data views: ", self.allTotals, self.closedOrders);


      });

    // DataGems: singular name/value pairs; Transactions, Sales {date-range}, Open Orders,
    // Reviews, Reservations, Open Chats,

  }

  GetInspiration() {
    var self = this;
    var db = firebase.firestore();

    var r = <number>Math.floor(Math.random() * 1600);

    db.collection("quotes").where("index", "==", r)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Quote>doc.data();
          self.global.quote = data;
          console.log("QUOTE: ", self.global.quote)
          self.oneQuote = true;
        })
      })


  }
  setFullScreen() { }

  changeAudio(id, i) {

    var audio = <HTMLVideoElement>document.getElementById(id + i);
    audio.muted = !audio.muted;
  }

  playEnded(i) {
    console.log("Play ended: ", i);

  }
  showPlay(i) {
    return false;
  }

  paused: boolean = false;

  isPaused(id, i) {

    return this.paused;

    var video = <HTMLVideoElement>document.getElementById(id + i);

    if (video && !video?.paused && !video?.onended) {

      video.onended = (event) => {

        console.log(
          "Video stopped either because it has finished playing or no further data is available.",
        );
        return true;
      }
    }


    return false;


  }

  playVideo(id, i) {
    var video = <HTMLVideoElement>document.getElementById(id + i);
    video.play();
    this.paused = false;

  }

  pauseVideo(id, i) {
    var video = <HTMLVideoElement>document.getElementById(id + i);
    video.pause();
    this.paused = true;


  }

  audioLabel(id) {
    var audio = <HTMLVideoElement>document.getElementById(id);
    if (audio && audio.muted) return "Play Audio"
    else return "Mute Audio"

  }

  fieldEvent(field: Field) {
    var self = this;

    if (field.title == 'editing') {
      self.editing = field.trueFalse;
    }
    console.log("FIELD EVENT: ", self.editing)
  }

  outputEvent(form: Form) {
    var self = this;

    console.log("App Maker settings got an update ", form, self.appId);
    // update name etc.

    var name, addToCart, access, width, showLogo,
    coverImage, palette: Palette;

    form.fields.forEach(function (field) {
      if (field.title == 'showLogoLine') {
        showLogo = field.trueFalse;
      }
      if (field.title == 'addToCart') {
        addToCart = field.trueFalse;
      }
      if (field.title == 'name') {
        name = field.binding;
      }
      if (field.title == 'sharing') {
        //    sharing = field.binding;
      }
      if (field.title == 'maxWidth') {
        width = field.binding;
      }
      if (field.title == 'coverImage' && field?.media?.length) {
        coverImage = field.media[0];
      }
      if (field.title == 'palette') {
        palette = field.palette;
        console.log("Palette Choice: ", palette)
      }

    })

    self.updateAppName(name, access, width, palette, coverImage, addToCart, showLogo)


  }

  getUniques() {
    if (!this.global.appDoc[this.appId].app.appStats?.ipAddress) return 0;

    return (this.global.appDoc[this.appId].app.appStats.ipAddress.length).toString() + " "
  }

  createParentId(): string {
    var uid = "";

    // For now just 3 1000 randoms + time;

    var a = Math.floor(Math.random() * 1000).toString();
    var d = Date.now().toString()
    console.log("D ", d)
    uid = a + d.substring(7, 3)

    return uid;
  }

  getPrettyDate(dateMs: number) {


    var date = new Date(dateMs);
    // console.log(date, dateMs)

    return (dateFormatter(date));

  }

  getMyStyle () {
    if (this.overFlow)
     var style="height: 100%;  overflow: visible; z-index: 200000; position: absolute; left: 0px; top: 0px;"
    else
      var style="max-height: 100%; max-width: 100%; overflow: scroll; position: relative;"


  return style;
  }

  toggleExpand (tf) {
    this.overFlow = tf;
  }


}
function dateFormatter(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true,
    hour: "2-digit",
    minute: "2-digit"
  };
  // date.toDateString();

  return date.toLocaleDateString('en-US', timeformat);
}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}