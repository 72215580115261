<div [style]="getMyStyle()">
    <div *ngIf="overFlow==true" (click)="toggleExpand(false)">MINIMIZE</div>
    <div *ngIf="!overFlow" (click)="toggleExpand(true)">EXPAND</div>

    <input type="text" pInputText [(ngModel)]="location" />
    <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
        (click)="GetWeather(location)">
        Get Weather for {{" "+location}}</div>
    <div *ngIf="forecast">
        <p-accordion styleClass="tab" expandIcon=" false" collapseIcon="false" [multiple]="true"
            (onOpen)="onWeatherOpen($event)">

            <div *ngFor="let day of forecast; let i = index;">

                <p-accordionTab>
                    <ng-template pTemplate="header">

                        <!-- DIDn't work 
                    <div *ngIf="day.day.maxwind_mph>20" class="header-video">
                        <video playsinline webkit-playsinline autoplay muted style="width:100%;"
                         
                         id="myVideo" >
                        <source src="https://player.vimeo.com/external/359803806.sd.mp4?s=80e1f583f301a15fe17f8d2c9fcafc981123fd65&profile_id=139&oauth2_token_id=57447761
                        " type="video/mp4">
                    </video>
                    </div>
                            -->


                        <div>{{day.date}}</div>
                        <img src={{day.day.condition.icon}} alt="sunny">
                        <div class="card__title">{{day.day.condition.text}}</div>


                    </ng-template>
                    <ng-template pTemplate="content">
                        <div>
                            <div class="card" fxFlex fxLayout="column" fxLayoutAlign="center center">
                                <div class="card__text">
                                    Temp range: {{day.day.mintemp_f}}F to&nbsp; {{day.day.maxtemp_f}}F<br>

                                    Max Wind: {{day.day.maxwind_mph}}mph<br>

                                    <div *ngIf="!day.day.daily_will_it_rain && day.day.maxwind_mph>20">
                                        <video id="rain-video{{i}}" playsinline webkit-playsinline autoplay
                                            [muted]="'muted'" loop style="width:100%;">
                                            <source src={{getMyWindyMovie(i)}} type="video/mp4">
                                        </video>
                                    </div>
                                    <div *ngIf="day.day.daily_will_it_rain">

                                        <video id="rain-video{{i}}" playsinline webkit-playsinline autoplay
                                            [muted]="'muted'" loop style="width:100%;">
                                            <source src={{GetMyRainMovie(i)}} type="video/mp4">
                                        </video>

                                        {{day.day.daily_chance_of_rain}}% chance of rain<br>
                                        Projected rainfall: {{day.day.totalprecip_in}}
                                    </div>
                                </div>


                                <div style="width: 100%; margin-left: 20px;" fxFlex fxLayout="row wrap"
                                    fxLayoutAlign="center end">

                                    <div class="rain-container" *ngFor="let h of day.hour; let i = index;">
                                        <div style="font-size:.5rem; word-wrap: break-word;">{{getTime(i)}}</div>
                                    </div>
                                </div>


                                <span *ngIf="day.day.daily_will_it_rain">Rain (likely%):</span>
                                <div *ngIf="day.day.daily_will_it_rain"
                                    style="width: 100%; height: 40px; border: 2px solid rgb(224, 224, 224)" fxFlex
                                    fxLayout="row" fxLayoutAlign="center end">
                                    <div style="width: 20px; height: 40px; padding-bottom: 2px; padding-top: 2px; font-size: .7rem"
                                        fxLayout="column" fxLayoutAlign="space-between end">
                                        <div>100</div>
                                        <div>0</div>
                                    </div>

                                    <div class="rain-container" style="height: 40px;" fxLayout="row"
                                        fxLayoutAlign="center end" *ngFor="let h of day.hour; let i = index;">
                                        <div *ngIf="getmyRain(h)" class="rain" [ngStyle]="{ 'height': getmyRain(h)}">
                                        </div>
                                    </div>
                                </div>

                                <span *ngIf="day.day.daily_will_it_rain">Rain Amount(in)</span>
                                <div *ngIf="day.day.daily_will_it_rain"
                                    style="width: 100%; height: 40px; border: 1px solid rgb(231, 230, 230)" fxFlex
                                    fxLayout="row" fxLayoutAlign="center end">
                                    <div style="width: 20px; height: 40px; padding-bottom: 2px; padding-top: 2px; font-size: .7rem"
                                        fxLayout="column" fxLayoutAlign="space-between end">
                                        <div>2</div>
                                        <div>0</div>
                                    </div>

                                    <div class="rain-container" style="height: 40px;" fxLayout="row"
                                        fxLayoutAlign="center end" *ngFor="let h of day.hour; let i = index;">
                                        <div *ngIf="getmyRainAmount(h)" class="rain"
                                            [ngStyle]="{ 'height': getmyRainAmount(h)}"></div>
                                    </div>
                                </div>


                                Temperature (F):
                                <div style="width: 100%; height: 40px; border: 1px solid rgb(231, 230, 230)" fxFlex
                                    fxLayout="row" fxLayoutAlign="center end">
                                    <div style="width: 20px; height: 40px; padding-bottom: 2px; padding-top: 2px; font-size: .7rem"
                                        fxLayout="column" fxLayoutAlign="space-between end">
                                        <div>{{day.day.maxtemp_f}}</div>
                                        <div>{{day.day.mintemp_f}}</div>
                                    </div>

                                    <div class="rain-container" style="height: 40px;" fxLayout="row"
                                        fxLayoutAlign="center end" *ngFor="let h of day.hour; let i = index;">
                                        <div class="rain"
                                            [ngStyle]="{ 'height': getMyTemp(h, day.day.mintemp_f, day.day.maxtemp_f)}">
                                        </div>
                                    </div>
                                </div>

                                Wind Speed:
                                <div style="width: 100%; height: 40px; border: 1px solid rgb(242, 203, 203)" fxFlex
                                    fxLayout="row" fxLayoutAlign="center end">
                                    <div style="width: 20px; height: 40px; padding-bottom: 2px; padding-top: 2px; font-size: .7rem"
                                        fxLayout="column" fxLayoutAlign="space-between end">
                                        <div>{{day.day.maxwind_mph}}</div>
                                        <div>0</div>
                                    </div>

                                    <div class="rain-container" style="height: 40px;" fxLayout="row"
                                        fxLayoutAlign="center end" *ngFor="let h of day.hour; let i = index;">
                                        <div *ngIf="getmyWind(h, day.day.maxwind_mph)" class="rain"
                                            [ngStyle]="{ 'height': getmyWind(h,day.day.maxwind_mph)}"
                                            [style.background-color]="getWindColor(h)"></div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </div>
        </p-accordion>
    </div>

