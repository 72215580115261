import { AfterViewInit, AfterContentInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Company, Form, Navigate, NapkinApp, AppNode, Reviews, Review, Coupon } from '../../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../menu/nav.service';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../../user-interface';
import { RegisterService } from '../../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { ReviewsService } from '../../reviews.service'
import { FormService } from '../../form.service'
import { MediaService } from '../../media/media-functions/media-functions';
import { NodeService } from '../../node.service';

@Component({
  selector: 'app-app-list',
  templateUrl: './app-list.component.html',
  styleUrls: ['./app-list.component.css', '../../../common.scss']
})
export class AppListComponent implements OnInit {
  @Input() storeNum;
  openApp: boolean[];
  activeStore: [];

  constructor(public global: Globals, private registerService: RegisterService,
    private router: Router,
    private mediaService: MediaService,
    private route: ActivatedRoute, public nav: NavService,
    public formService: FormService,
    public nodeService: NodeService,
    public reviewService: ReviewsService) {

  }

  ngOnInit(): void {

    this.openApp = [];
    this.activeStore = [];
    this.nodeService.getNodeTypes();

  }

  getStoreStyle(i) {
    var style = "width: "

    style += this.global.getScreenWidth + 'px;'

    return style;

  }

  getMyApp(event, index) {


  }

  getAppNameStyle(i) {
    var style;

    if (this.global.getScreenWidth < 400 || this.openApp[i])
      style = "width: " + this.global.getScreenWidth + 'px;'

    else if (this.global.getScreenWidth >= 400)
      style = "width: 400px;"

    if (this.openApp[i]) {  // I'm selected yay!
      style += " background-color: #add8e6; font-size: 130%;";
    }

    return style;
  }

  addStore: boolean = false;
  createStore() {
    this.addStore = true;

  }

  regStore () {
    // Already been done.
  }


  showApp(i) {
    if (!this.openApp) return true;
    if (!this.openApp.includes(true)) return true;
    if (this.openApp[i]) return true;
    return false;

  }
  LoginAdmin(store: string) {

    // Clear Store data
    /* this.global.media = [];
     this.global.mediaGroups = [];
     this.global.allApps = [];
     this.global.allForms = [];
     this.global.publishedReviews = [];
     this.global.allProducts = [];
     this.global.myCompany = undefined;
 */

    console.log("Admin Login", store);
    this.nav.GetStore(store, this.startServices, null, this);
    this.global.userTabOpen = false;
    this.router.navigate(['/front']);
  }


  startServices(res, self: this) {

    // Start listener for all store app
    self.nodeService.getMyStoreApps()
    self.mediaService.getMedia(true);
    self.reviewService.getReviews(true); // Global service

    self.formService.getForms();
    self.mediaService.getMediaGroups();
    self.mediaService.getMedia();

  }



}
