import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Company, Navigate, Coupon, ReviewTotals, Reviews, Review } from './company-interface';
import { Howl, Howler } from 'howler';
import { Order, Discounts } from './order-interface'

export interface TableColor {
  tableName: string;
  color: string;
}

const sound = new Howl({
  src: ['../../../assets/new-review-0.mp3']
});
const sound2 = new Howl({
  src: ['../../../assets/pickupCoin.wav']
});

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  openOrdersList: Order[] = [];
  closedOrders: Order[] = [];
  discounts: Discounts[];
  totalOpenOrders: number = 0;
  totalReadyItems: number = 0;
  readyItems: number = 0;
  totalCompletedOrders: number = 0;
  totalClosedOrders: number = 0;
  departments: string[] = [];

  tableColors: TableColor[];
  colorPalette: Array<string> =
    ["#eeddbb", "#ffeedd", "#eeffdd", "#ddeeff", "#eeeebb",
      "#eeffaa", "#aaffff", "#aaeeee", "#bbffcc", "#AABBCC",
      "#DDDDFF", "#ddffdd", "#ddaaff", "#ddaadd", "#aadddd",
      "#bbccbb", "#bbddff", "#bbeeaa", "#bbffdd", "#bbffbb"];


  constructor(private global: Globals, private sanitizer: DomSanitizer) {
    this.GetOrders();
    this.tableColors = [];
    this.GetDept();
    this.GetClosedOrders();
    this.GetDiscounts();

  }
  GetOrders() { // GET ALL ORDERS auto-sort to NEW on top

    var db = firebase.firestore();
    var self = this;
    var index = 0; // 

    if (!self.global.myCompany) return;

    //   console.log ("getting orders ", self.global.myCompany);
    db.collection("orders").where("companyID", "==", self.global.myCompany.id).where("status", "!=", "Closed")  // Need to include date filter - include closed for today.
      .onSnapshot((querySnapshot) => {
        // console.log("New Order Data arrived ", querySnapshot.docs);
        self.global.openOrders.splice(0, self.global.openOrders.length);  // ERASE
        self.global.openOrders = [];
        if (!self.global.tableOrders) self.global.tableOrders = [];
        querySnapshot.forEach((doc) => {
          const data = <Order>doc.data();

          if (data.showOrder == null || data.showOrder == true) {
            let dateT = data.dateTime;
            let localOrder = <Order>data;
            localOrder.id = doc.id;
            if (!localOrder.tableColor) localOrder.tableColor = "#0";

            let jTime = dateT;
            localOrder.localMs = dateT; // Sort on this
            localOrder.localAge = +(Date.now() - localOrder.localMs);
            localOrder.timeLapse = timeConversion(localOrder.localAge);


            let s1 = +(localOrder.localAge / 60000).toFixed(0);
            const bgColor = "rgb(" + s1 + "," + 0 + "," + 0 + ")";
            localOrder.localColor = bgColor;
            localOrder.tableColor = self.GetTableColor(localOrder.tableName);

            //Let's add only items 'Not-Delivered'
            if (1) {
              if (localOrder.status == 'Removed' /*&& !self.showRemoved*/) { }
              else
                self.global.openOrders.push(localOrder);
            }
          }
          else console.log("Don't show: ", data.showOrder);


          /*
                    
                    if (!data.note) data.note = "";
                    // SKIP COMPLETED
                    //         if (data.status != "Delivered") {
          
                    let dateT = data.dateTime;
                    let localOrder = <Order>data;
                    localOrder.isExpanded = false;
          
                    if (localOrder.tableName == "") localOrder.tableName = "No Table";
                    localOrder.id = doc.id;
                    if (localOrder.tableName != "No Table") {
                      // if not showing Removed
                      if (localOrder.status != "Removed")
                        self.global.openOrders.push(localOrder);
                    }
                    */

        });

        self.MakeOpenOrderList(self);
        self.dateTimeSort();

        console.log("Open Orders: ", self.global.openOrders);

      });

  }

  GetDiscounts() { // GET ALL ORDERS auto-sort to NEW on top
    var db = firebase.firestore();
    var self = this;
    if (!self.global.myCompany) return;

    db.collection("discounts").where("companyId", "==", self.global.myCompany.id).where("active", "==", true)
      .onSnapshot((querySnapshot) => {
        //     self.discounts.splice(0, self.global.openOrders.length);  // ERASE
        self.discounts = [];
        querySnapshot.forEach((doc) => {
          const data = <Discounts>doc.data();
          self.discounts.push(data);
        });

        console.log("Discounts ", self.discounts);

      });

  }





  GetClosedOrders() { // GET ALL ORDERS auto-sort to NEW on top
    var db = firebase.firestore();
    var self = this;

    if (!self.global.myCompany) return;

    var dayago = Date.now() - (60000 * 60 * 24);

    db.collection("orders").where("companyID", "==", self.global.myCompany.id).where("status", "==", "Closed").where("closedAt", ">", dayago)
      .onSnapshot((querySnapshot) => {
        self.closedOrders.splice(0, self.global.openOrders.length);  // ERASE
        self.closedOrders = [];
        querySnapshot.forEach((doc) => {
          const data = <Order>doc.data();

          if (data.showOrder == null || data.showOrder == true) {
            let dateT = data.dateTime;
            let localOrder = <Order>data;

            if (localOrder.status == 'Removed') { }
            else {
              const index = self.closedOrders.findIndex(function (post) {
                if (post.resultId == localOrder.resultId)
                  return true;
              });

              if (index == -1)
                self.closedOrders.push(localOrder);
            }
          }

        });
        //self.MakeOpenOrderList(self);
        // self.dateTimeSort();

        console.log("Closed Orders: ", self.closedOrders);

      });

  }









  // FIX: Need to use grouping here
  MakeOpenOrderList(self) {
    self.openOrdersList.splice(0, self.openOrdersList.length);

    var table = -1;

    self.totalOpenOrders = 0;
    self.totalReadyItems = 0;
    self.totalCompletedOrders = 0;
    //    self.totalClosedOrders = 0;

    self.global.openOrders.forEach(function (arrayItem: Order) {
      table = -1;

      if (arrayItem.status == "Ready") {
        self.totalReadyItems++;
        arrayItem.myColor = "8bc34a";
      }

      self.openOrdersList.forEach(function (item: Order, index) {

        if (item.tableName == arrayItem.tableName) {
          table = 99;
          if (arrayItem.dateTime < item.dateTime)
            item.dateTime = arrayItem.dateTime;

          if (arrayItem.status == 'Cart' || arrayItem.status == 'New') {
            item.status = arrayItem.status;
            if (!self.global.tableOrders.includes(item.tableName)) { self.global.tableOrders.push(item.tableName) }

          }
          //    self.LoadOrder ()
          //   console.log("status", arrayItem.status, item.tableName);
        }
      })

      if (table == -1) {
        self.totalOpenOrders++;
        if (arrayItem.status == 'Cart' || arrayItem.status == 'New') {
          arrayItem.status = arrayItem.status;
        }
        if (arrayItem?.tableName?.length > 0)
          if (!self.global.tableOrders.includes(arrayItem.tableName)) { self.global.tableOrders.push(arrayItem.tableName) }

        self.openOrdersList.push(arrayItem);
      }

    });
    console.log("OPEN ORDERS List: ", self.openOrdersList, self.global.tableOrders);
    /*
    if (self.myTable.length > 0) {
      var tmp: Order = {};
      tmp.tableName = self.myTable;

      self.LoadOrder(tmp);  // HACK??
    }
*/
    self.openOrdersList.sort(function (a, b) { return b.dateTime - a.dateTime; });

  }





  dateTimeSort() {

    this.global.openOrders.sort(function (a, b) { return b.localMs - a.localMs; });

  }
  GetTableColor(table: string): string {
    var self = this;
    // console.log(table);

    var color = "";

    // Find or add
    self.tableColors.forEach(function (arrayItem) {
      if (arrayItem.tableName == table) {
        color = arrayItem.color;
      }

    });

    if (color == "") {
      var a = self.tableColors.length;
      if (a >= self.colorPalette.length) a = a - self.colorPalette.length; //hack to double
      if (a >= self.colorPalette.length) a = 0;

      var tc: TableColor = {
        tableName: table,
        color: this.colorPalette[a]
      }
      console.log("Color: ", tc);
      self.tableColors.push(tc);
      color = tc.color;
    }
    return color;
  }


  GetDept() {

    var self = this;

    var colorIn = 0;

    if (!self.global.myCompany) return;


    return; // I don't think we use this anymore


  }




}

function timeConversion(millis) {
  var minutes = Math.floor(millis / 60000);
  var seconds = ((millis % 60000) / 1000).toFixed(0);
  return minutes + ":" + (+seconds < 10 ? '0' : '') + seconds;
}
