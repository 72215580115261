import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Globals } from 'src/app/globals';
import { Product } from '../components/product-int';
import { onMenuItem } from './menu-item-interface';
import { Content } from './content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Order } from '../components/order-interface';
import { Company, EmailForm, Form, Field, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from './company-interface';
import { RegisterService } from '../components/register.service';
import { Creds, Follows, User, Notify } from './user-interface';
import { HttpClient } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class EmailService {

    constructor(private global: Globals, 
        public registerService: RegisterService, 
        private http: HttpClient,
        private sanitizer: DomSanitizer) {


    }

    ForgotPassword(email, firstName, pw) {
        var self = this;

        var html = "Hi "+firstName;
        html += "<br>The information requested is below:<br>";
        html += pw + "<br>"

        var emailForm: EmailForm = {
            to: email,
            from: "info@crystalhillvineyard.com",
            subject: "Requested info",
            message: html
        }
        self.sendEmail (emailForm);

    }

    sendEmail(emailForm: EmailForm) {

        this.http.post('https://sendemailhtml-xdnunfyrka-uc.a.run.app',
          emailForm).subscribe({
            next: response => console.log('Email sent', response),
            error: error => console.error('Error sending email', error)
          });
    
      }

      sendBatchEmails (sendList, htmlSource, subject) {
        var self = this;
    
        console.log (htmlSource, sendList)
    
        this.http.post('https://batchemail-xdnunfyrka-uc.a.run.app',
         { companyId: "skkk8SCSIDyrdiueY4jU",   
          templates: htmlSource,
          sendList: sendList,
          subject: subject,
          values: 'none',
          tags: 'Test'
         }
        ).subscribe({
            next: response => console.log('Email sent', response),
            error: error => console.error('Error sending email', error)
          });
    
      }


      public sendReserveEmail(r: reservation) {
        var self = this;
    /*
        var addonItems = "";
    
        if (r.addons) {
          addonItems = "Requested options: " + r.addons;
        }
   
        var rewardStr = "";
    
        if (this.global.myCompany.groupSettings && this.global.myCompany.enabled.reservationRewards) {
          if (r.reward) {
            rewardStr = "Reward selection: " + r.reward.title;
          }
          else {
            rewardStr = "Reservaton Freebie available upon arrival."; // Link to change
          }
    
        }
        */
    
        var optionString = "";
        if (r.package && r.package.allOptions) {
          r.package.allOptions.forEach(function (item) {
            optionString += item.title + ": " + item.chosen;
          })
    
    
        }
    
        // IF no Reply EMail default to noreply@rewards.com - this shouldn't happen
        if (!this.global.myCompany.reserveSettings.replyEmail)
          this.global.myCompany.reserveSettings.replyEmail = "noreply@rewards.com"
    
        var tableName = "Assigned at arrival";
        if (r.tableObj) tableName = r.tableObj.name;
        var packageDetail;
    //    if (this.selectedPackage) packageDetail = "Your Package: " + this.selectedPackage.title;
    
        var changeStr = "To make changes: https://napkinhq.com/reserve/" + this.global.myCompany.store;
    
        //  if (!self.global.authuser.emailVerified) {
        //    changeStr += "?v=" + this.global.authuser.keyCode + this.global.authuser.uid.substring(0, 5);
        //  }
    
        var templateParams = {
          from_name: this.global.myCompany.name,  // Company Name
          to_name: r.firstName,
          to_email: r.email,
          reply_email: this.global.myCompany.reserveSettings.replyEmail,
          resDate: r.resDate.dateStr,
          resTime: r.resTime.strTime,
          resSize: r.partySize,
          resSizeMinors: r.partySizeUnder21,
      //    addons: addonItems,
          options: optionString,
          tableName: tableName,
          package: packageDetail,
      //    reward: rewardStr,
          changeURL: changeStr,
          store_num: this.global.myCompany.store,
          storeName: this.global.myCompany.name,
          storeAddress: this.global.myCompany.address1 + " " + this.global.myCompany.city + " " + this.global.myCompany.state
        };

        var html = "<p style=\"font-size:15px;line-height:160%;\">Hi " +r.firstName+",<br>"
        html += "Your reservation is confirmed:<br><br>"
        html += "<strong>Party of "+r.partySize+" on "+ r.resDate.dateStr +" at " + r.resTime.strTime+"</strong>";
        html += "<br><br>Cheers,<br>"
        html += this.global.myCompany.name +"<br><br></p>"
        html += "<p style=\"font-size:11px;\">You are receiving this transactional email based on your recent interaction with "+
        this.global.myCompany.name+". To update notification preferences please visit: "+
        "<a href=\"https://napkinhq.com/reserve/"+this.global.myCompany.store
          
          if (r.userID)
            var sid = r.userID.substring(0,5)
          html += "?p="+sid + "\">NapkinHq</a></p>"

        var emailForm: EmailForm = {
            to: r.email,
            from: "info@crystalhillvineyard.com",
            subject: "Reservation at "+this.global.myCompany.name,
            message: html
        }
        self.sendEmail (emailForm);
    
    /*
    
        if (r.partySize >= 12 || r.package) {
          emailjs.send("service_jwders9", "template_wesadg8", templateParams) //"template_1ky3ksd"
            .then((result: EmailJSResponseStatus) => {
              console.log(result.text);
            }, (error) => {
              console.log(error.text);
            });
        }
        else {
    
          //  e.preventDefault();
          emailjs.send("service_jwders9", "template_nglyu2h", templateParams) //"template_1ky3ksd"
            .then((result: EmailJSResponseStatus) => {
              console.log(result.text);
            }, (error) => {
              console.log(error.text);
            });
        }
        */
      }
    



}
