import { Injectable } from '@angular/core';
import { Order } from '../order-interface';
import { Product, Options, Table } from '../product-int';
import { onMenuItem } from '../menu-item-interface';
import firebase from 'firebase/compat/app';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { MessagePacket } from '../message-int';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Company, Form, Field, Duration, ReservationDay, reservation, resTime, ReservationSettings } from '../company-interface';
import { Globals } from '../../globals';
import { Category } from '../category-interface';
import { Observable } from 'rxjs';
import { Content } from '../content-interface';
import { ProductsService } from '../products.service';
import { User, Follows } from '../user-interface'
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { CartService } from './cart.service';
import { Logger } from 'src/app/functions';
import { thumbnailsSettings } from 'lightgallery/plugins/thumbnail/lg-thumbnail-settings';
//import { FormService } from '../form.service'


let categoryLists = [];
export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}



@Injectable({
  providedIn: 'root',
})
export class NavService {

  allCategories: Category[] = [];
  catOptions: Category[] = [];
  items: Observable<any[]> = <any>[];
  //dataSourceCat: MatTableDataSource<Category>;
  //  menu: onMenuItem[] = [];
  //  obs: Observable<any>;
  //  public allProducts: onMenuItem[] = [];
  //allContent: Content[] = [];
  //content: Content[] = [];
  //contentobs: Observable<any>;

  quantities: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8]; // Some crazy HACK

  resSettings: ReservationSettings;

  //dataSourceMenu: MatTableDataSource<onMenuItem>;
  //dataSourceContent: MatTableDataSource<Content>;

  constructor(public global: Globals, public productsService: ProductsService,
    public cartService: CartService) {
    /*
  this.dataSourceCat = new MatTableDataSource(this.catOptions);
  this.items = this.dataSourceCat.connect();
  this.dataSourceMenu = new MatTableDataSource(this.global.menu);
  this.global.obs = this.dataSourceMenu.connect();

  this.dataSourceContent = new MatTableDataSource(this.global.content);
  this.global.contentobs = this.dataSourceContent.connect();
*/
  }

  firstTime: boolean = true;


  login(form: Form) {
    var self = this;

    self.global.displayMenu = false;

    console.log("forms:", self.global.allForms);
    var email, pw: string;
    /*
        // Scan Forms to first email field....
        self.global.allForms.forEach(function(form){
          var found = form.fields.find((f) => f.type == 'email')
          if (found)
            email = found.binding
    
          var found = form.fields.find((f) => f.type == 'pw')
            if (found)
            pw = found.binding
    
        })
       BAD BAD CODE */
    // email = self.registerService.user.email;
    pw = form.fields[0].binding;

    console.log("LOGIN EMAIL; ", email);

    var db = firebase.firestore();

    db.collection("users").where("email", "==", email).where("pw", "==", pw)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          Logger("Password Error", email, pw);  // Hack here is where I would add bad attempt counter 

        }
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          console.log("user ", data)
          self.global.authuser = data;

          Logger("Login", self.global.authuser.uid, "", data.firstName, data.lastName)


          localStorage.setItem('uid', data.uid);
          //          self.cartService.GetMyOrder();

          // Now enable the listener for User
          self.GetUserwID(self.global.authuser.uid);

        });
      })
      .catch((error) => {

        console.log("no user found: ", error);
      });

  }





  getUserOnlywID(id: string, getAll?: boolean, callback?: any, mySelf?: any, afterUserLoadCallback?: any) {
    var self = this;

    var db = firebase.firestore();
    console.log("get user: ", id);

    db.collection("users").doc(id).get().then((doc) => {
      const data = <User>doc.data();

      self.global.authuser = <User>{ ...data };
      Logger("Login", self.global.authuser.uid, "", data.firstName, data.lastName)

      console.log("Current data: ", data, self.global.authuser);

      if (typeof afterUserLoadCallback == "function")
        afterUserLoadCallback(mySelf);

    })
      .catch((error) => {

        console.log("OOPS NOBODY FOUND ")
        const id = localStorage.removeItem('uid');
        self.global.authuser = null;

        // Logger ("User ID Not Found!", "id","");  // Hack here is where I would add bad attempt counter 

        console.log("no user found: ", error);
      });

  }


  GetUserwID(id: string, getAll?: boolean, callback?: any, mySelf?: any, afterUserLoadCallback?: any) {
    var self = this;

    var db = firebase.firestore();

    const unsub = onSnapshot(doc(db, "users", id), (doc) => {
      const data = <User>doc.data();
      if (!data.firstName) {
        console.log("OOPS NOBODY FOUND ", data)
        const id = localStorage.removeItem('uid');
        return;
      }

      self.global.authuser = <User>{ ...data };
      Logger("User Observer started", id, "", data.firstName, data.lastName)

      console.log("GETUSERwID Current data: ", self.global.authuser, self.global.store);

      //not here    self.getReservations(storeNum, getAll, callback, mySelf);
      if (typeof afterUserLoadCallback == "function")
        afterUserLoadCallback(mySelf);

    })


  }

  getReservations(store: string, getAll?: boolean, callback?: any, mySelf?: any) {
    var self = this;

    if (!getAll) getAll = false;

    if (!self.global.authuser) return;
    this.global.loadingReservations = true;

    if (!store) return;

    console.log("Get res store: ", store, getAll, self.global.authuser.uid);
    var db = firebase.firestore();

    const q = query(collection(db, "reservations"), where("userID", "==", self.global.authuser.uid), where("status", "==", "open"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.global.myReservations = [];

      querySnapshot.forEach((doc) => {
        const r = <reservation>doc.data();
        //  console.log("My Res: ", r);
        if (!r.myID) r.myID = doc.id;  // HACK remove any time
        //        if (r.companyID == self.global.myCompany.id) {
        if (!getAll) {
          if (r.storeNum == store)
            self.global.myReservations.push(r);
        }
        else {
          self.global.myReservations.push(r);
        }

        //        }
      });
      this.global.loadingReservations = false;
      // Let's call any callback function at end here
      if (typeof callback == "function")
        callback(mySelf);
      console.log("Reservations: ", self.global.myReservations);
    });
  }



  getAllReservations(companyID: string) {
    var self = this;

    console.log("compid: ", companyID);

    var db = firebase.firestore();

    const q = query(collection(db, "reservations"), where("companyID", "==", companyID), where("status", "==", "open"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      self.global.allReservations = [];

      querySnapshot.forEach((doc) => {
        const r = <reservation>doc.data();
        if (!r.myID) r.myID = doc.id;  // HACK remove any time
        self.global.allReservations.push(r);
      });
      console.log("All Reservations: ", self.global.allReservations);

      // Now sort by date/time


      self.global.allReservations.sort(function (a, b) {

        var d = a.resDate.date.toDate();
        var amonth = d.getMonth();
        var ayear = d.getFullYear();
        var aday = d.getDay();

        var n = b.resDate.date.toDate();
        var bmonth = n.getMonth();
        var byear = n.getFullYear();
        var bday = n.getDay();

        var sameDay = false;

        if (ayear == byear && amonth == bmonth && aday == bday) {
          sameDay = true;
        }

        if (sameDay) {
          if (a.resTime.msTime > b.resTime.msTime) return 1;
          else if (a.resTime.msTime < b.resTime.msTime) return -1;
          else return 0;
        }

        // Dates noot same
        if (a.resDate.date > b.resDate.date) return 1;
        else if (a.resDate.date < b.resDate.date) return -1;
        return 0;

      });


    });

  }

  GetAllStores() {   // Get em all!!
    var self = this;

    var db = firebase.firestore();

    db.collection("company").where("store", "!=", "null")
      .get()
      .then((querySnapshot) => {
        self.global.allStores = [];
        querySnapshot.forEach((doc) => {
          const data = <Company>doc.data();
          self.global.allStores.push(data);


        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });

  }

  GetStoreId(storeId: string, callback?: any, mySelf?: any) {

    var db = firebase.firestore();
    var self = this;

    db.collection("company").doc(storeId)
      .get()
      .then((doc) => {
        const data = <Company>doc.data();
        self.global.myCompany = <Company>doc.data();
        if (data?.lat) {
          self.global.mapOptions = {
            center: { lat: data.lat, lng: data.long },
            zoom: 14
          }
        }

        console.log("got store ", storeId, data);
        // Let's call any callback function at end here
        if (typeof callback == "function")
          callback(mySelf);
      })
  }

  GetStoreOnly(store: string, callback?: any, mySelf?: any) {

    // if (!store && this.global.store) store = this.global.store;

    var db = firebase.firestore();
    var self = this;


    if (self.global.loadingStore == true) return;
    console.log("Got to 3 ", store);

    self.global.loadingStore = true;


    db.collection("company").where("store", "==", store)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Company>doc.data();
          self.global.myCompany = <Company>doc.data();

          if (data.lat) {
            self.global.mapOptions = {
              center: { lat: data.lat, lng: data.long },
              zoom: 14
            }
          }

          console.log("got store ", data);

          // Let's LOAD the DATABASE
          //    self.formService.getForms();

          console.log(doc.id, "found Store => ", self.global.myCompany, self.global.authuser);
          self.global.loadingStore = false;
        });
        // Let's call any callback function at end here
        if (typeof callback == "function")
          callback(mySelf);
      })
      .catch((error) => {
        console.log("no store found: ", error);
        self.global.loadingStore = false;
      });

  }


  GetStoreoOld(store: string, callback?: any, res?: reservation, mySelf?: any) {

    //* if (!store && this.global.store) store = this.global.store;

    var db = firebase.firestore();
    var self = this;

    if (!this.global.authuser && (!store || store.length == 0)) store = "4390";
    /*
        if ((!store || store.length == 0) && !this.global.authuser) return;
        if (!self.global.tableNameQR) self.global.tableNameQR = "";
        if (self.global.loadingStore == true) return;
        if (store)
          self.global.loadingStore = true;
        else
          return;
    */

    db.collection("company").where("store", "==", store)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Company>doc.data();
          self.global.myCompany = <Company>doc.data();
          //*   self.global.myCompany.id = data.id;
          console.log("found Store => ", self.global.myCompany);

          if (data.lat) {
            self.global.mapOptions = {
              center: { lat: data.lat, lng: data.long },
              zoom: 14
            }
          }


          if (self.global.authuser) {
            //* self.global.authuser.companyID = data.id;
          }

          if (!self.global.myCompany.reserveSettings) {
            //* should be elsewhere         self.InitReservations();
          }
          //* Should be elsewhere       self.cartService.GetMyOrder();

          // MOVE BELOW To CALLBACK

          // INSERT RESERVATION SETTINGS
          self.global.allWeek = [];

          self.global.allWeek = self.global.myCompany.reserveSettings.reserveDay;

          self.global.myCompany.reserveSettings.daysClosed = [];
          self.global.openDays = [];
          if (!self.global.myCompany.reserveSettings.timeNotice) {
            let aTime: Duration = {
              min: 15,
              name: ""
            }
            self.global.myCompany.reserveSettings.timeNotice = aTime;
          }

          self.global.myCompany.reserveSettings.openDays.forEach(function (value: boolean, index) {
            if (!value) {

              self.global.myCompany.reserveSettings.daysClosed.push(index);
            }
            self.global.openDays.push(value);
          });

          // Copy into local ngModel vars
          console.log("Reservation settings:", self.global.allWeek, self.global.myCompany.reserveSettings.daysClosed, self.global.myCompany.reserveSettings.openDays);

          /* SHOULD BE In Load User
          
                    if (self.global.authuser) {
                      if (self.global.authuser.following) // hack on index
                        self.global.myRewardPoints = self.global.authuser.following[0].points; // HACK 0
          
                      if (self.global.myCompany.id)
                        self.productsService.GetRedeemable(self.global.myCompany.id);
                    }
                    if (self.global.authuser && self.global.authuser.creds)
                      self.getAllReservations(self.global.myCompany.id);
              */
          //    self.GetCategories()
          //     self.productsService.GetAllContent();
          self.productsService.GetAllProducts();
          // HACK REMOVE     self.productsService.GetAllOrders();

          // Let's call any callback function at end here
          if (typeof callback == "function") {
            console.log("Callback--->")
            callback(res, mySelf);
          }



          /* 
                        if (self.global.authuser) {
                          self.cartService.initCart2(self.global.authuser.uid, self.global.tableNameQR, self.global.myCompany.id);
              
                          self.cartService.GetMyOrder(this.myOrder);
                          console.log("Fix table num: ", self.cartService.tableNameQR);
              */
          //        if (self.tableNameQR.length == 0) {
          //          self.tableNameQR = self.cartService.tableNameQR;
          //          console.log("Fix table num: ", self.tableNameQR);
          //        }

          //      }
          // doc.data() is never undefined for query doc snapshots
          self.global.loadingStore = false;
        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
        self.global.loadingStore = false;
      });

  }


  GetStore(store: string, callback?: any, res?: reservation, mySelf?: any) {

    if (!store && this.global.store) store = this.global.store;

    var db = firebase.firestore();
    var self = this;

    //console.log("Got to 0", this.global.myCompany,store,this.global.myCompany.store);

    //  if (this.global.myCompany && this.global.myCompany.store == store) return;

    // Cut the cord... if (!this.global.authuser && (!store || store.length == 0)) store = "4390";

    console.log("Got to 1");
    if ((!store || store.length == 0) && !this.global.authuser) return;
    console.log("Got to 2");
    if (!self.global.tableNameQR) self.global.tableNameQR = "";
    console.log("Looking for ", store);

    if (self.global.loadingStore == true) return;
    console.log("Got to 3 ", store);
    if (store)
      self.global.loadingStore = true;
    else
      return;

    console.log("Got to 4");


    const q = query(collection(db, "company"), where("store", "==", store));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {


      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const data = <Company>change.doc.data();
          self.global.myCompany = data;

          if (data.lat) {
            self.global.mapOptions = {
              center: { lat: data.lat, lng: data.long },
              zoom: 14
            }
          }

          console.log("got store ", data);

          if (!self.global.myCompany.reserveSettings) {
            // Hack     self.InitReservations();
          }
          self.cartService.GetMyOrder(); // Hack hack - user-loads

          // INSERT RESERVATION SETTINGS
          if (self.global.myCompany.enabled.reservations) {
            self.global.allWeek = [];
            if (self.global.myCompany?.reserveSettings) {
              self.global.allWeek = self.global.myCompany.reserveSettings.reserveDay;

            self.global.myCompany.reserveSettings.daysClosed = [];
            self.global.openDays = [];
            if (!self.global.myCompany.reserveSettings.timeNotice) {
              let aTime: Duration = {
                min: 15,
                name: ""
              }
              self.global.myCompany.reserveSettings.timeNotice = aTime;
            }

            self.global.myCompany.reserveSettings.openDays.forEach(function (value: boolean, index) {
              if (!value) {

                self.global.myCompany.reserveSettings.daysClosed.push(index);
              }
              self.global.openDays.push(value);
            });
            console.log("Reservation settings:", self.global.allWeek, self.global.myCompany.reserveSettings.daysClosed, self.global.myCompany.reserveSettings.openDays);

          }

          }

          if (self.global.authuser && self.global.authuser.creds)
            self.getAllReservations(self.global.myCompany.id);

          self.productsService.GetAllProducts();

          // Let's call any callback function at end here
          if (typeof callback == "function")
            callback(res, mySelf);

          //  console.log(doc.id, "found Store => ", self.global.myCompany, self.global.authuser);
          self.global.loadingStore = false;
        }

        if (change.type === "modified") {
          const m = <Company>change.doc.data();
          self.global.myCompany = m;
          console.log("Modified city: ", change.doc.data());
        }
        if (change.type === "removed") {
          const m = <Company>change.doc.data();

          console.log("Removed form: ", change.doc.data());
        }
      });
     // console.log("GOT ALL Forms ", self.global.allForms, self.global.allSessionFields)
    });



    /*
    
          querySnapshot.forEach((doc) => {
            const data = <Company>doc.data();
            self.global.myCompany = <Company>doc.data();
            if (data.lat) {
              self.global.mapOptions = {
                center: { lat: data.lat, lng: data.long },
                zoom: 14
              }
            }
    
            console.log("got store ", data);
    
            if (!self.global.myCompany.reserveSettings) {
              // Hack     self.InitReservations();
            }
            self.cartService.GetMyOrder(); // Hack hack - user-loads
    
            // INSERT RESERVATION SETTINGS
            if (self.global.myCompany.enabled.reservations) {
              self.global.allWeek = [];
    
              self.global.allWeek = self.global.myCompany.reserveSettings.reserveDay;
    
              self.global.myCompany.reserveSettings.daysClosed = [];
              self.global.openDays = [];
              if (!self.global.myCompany.reserveSettings.timeNotice) {
                let aTime: Duration = {
                  min: 15,
                  name: ""
                }
                self.global.myCompany.reserveSettings.timeNotice = aTime;
              }
    
              self.global.myCompany.reserveSettings.openDays.forEach(function (value: boolean, index) {
                if (!value) {
    
                  self.global.myCompany.reserveSettings.daysClosed.push(index);
                }
                self.global.openDays.push(value);
              });
    
              console.log("Reservation settings:", self.global.allWeek, self.global.myCompany.reserveSettings.daysClosed, self.global.myCompany.reserveSettings.openDays);
            }
    
            if (self.global.authuser && self.global.authuser.creds)
              self.getAllReservations(self.global.myCompany.id);
    
            self.productsService.GetAllProducts();
    
            // Let's call any callback function at end here
            if (typeof callback == "function")
              callback(res, mySelf);
    
            console.log(doc.id, "found Store => ", self.global.myCompany, self.global.authuser);
            self.global.loadingStore = false;
          });
        })
        */
  }

  InitReservations() {
    var self = this;

    var resDays: ReservationDay[] = [];
    var checked: boolean[] = [true, false, false, false, false, true, true];

    let openTime: resTime = {
      msTime: (12 * 60 * 60) * 1000,
      strTime: "12:00 pm"
    }

    let closeTime: resTime = {
      msTime: (18 * 60 * 60) * 1000,
      strTime: "6:00 pm"
    }

    let dur1: Duration = {
      min: 30,
      name: "30 min"
    }

    let resDay: ReservationDay = {
      timeStart: (12 * 60 * 60) * 1000, // Noon as ms
      timeEnd: (18 * 60 * 60) * 1000,   // 6pm as ms
      lastSeating: (17 * 60 * 60) * 1000,   // 5pm as ms
      resOpenTime: openTime,
      resCloseTime: closeTime,
      skipMinutes: 15,
      minParty: 2,
      maxParty: 12,
    }
    resDays.push(resDay);
    resDays.push(resDay);
    resDays.push(resDay);
    resDays.push(resDay);
    resDays.push(resDay);
    resDays.push(resDay);
    resDays.push(resDay);

    self.global.myCompany.reserveSettings = {
      openDays: checked,
      maxOccupancy: 50,
      maxTables: 10,
      reserveDay: resDays,
      minParty: 2,
      maxParty: 12,
      duration: 45,
      timeBlock: dur1,
      timeSkip: dur1,
      timeNotice: dur1
    }
    self.global.allWeek = self.global.myCompany.reserveSettings.reserveDay;
    self.global.openDays = self.global.myCompany.reserveSettings.openDays;


    console.log("Default Reservations: ", self.global.myCompany.reserveSettings);


    this.updateResSettings();




  }

  updateResSettings() {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.global.myCompany.id);

    ref.update({
      reserveSettings: self.global.myCompany.reserveSettings
    })
      .then(() => {
        console.log("Res settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing res settings: ", error);
      });
  }

  GetCategories() {
    var db = firebase.firestore();
    var self = this;
    var index = 0; // 
    return;

    self.allCategories.splice(0, self.allCategories.length);
    self.allCategories = [];

    console.log("Getting categories for: ", self.global.myCompany.id, self.global.myCompany.name)
    db.collection("categories").where("companyID", "==", self.global.myCompany.id).where("active", "==", true).get().then(function (querySnapshot) {  // add where (active=true)
      querySnapshot.forEach(function (doc) {
        // doc.data() is never undefined for query doc snapshots
        const data = <Category>doc.data();

        self.allCategories.push(data);
      });
      console.log("GOT ALLCATS: ", self.allCategories);
      // NOW LETS BREAK APART CATEGORIES INTO LISTS
      self.CreateCatLists();

    })


  }
  NullCatLists() {
    categoryLists.splice(0, categoryLists.length);
    categoryLists[0] = [];
  }

  CreateCatLists() {
    let self = this;
    var i = 1;
    var found = false;
    categoryLists.splice(0, categoryLists.length);
    categoryLists[0] = [];
    self.catOptions.splice(0, self.catOptions.length);

    console.log("ALL cats ", this.allCategories);
    this.allCategories.forEach(function (arrayItem, index, array) {
      if (arrayItem.type.toLowerCase() == "top") {
        // CHECK IF THIS CATEGORY INCLUDES ANY PRODUCTS
        //        if (arrayItem.hasProducts) {
        categoryLists[0].push(arrayItem);
        //        }
      }

      found = false;
      if (arrayItem.type.toLowerCase() == 'sub') {
        // look for parent in lists[1..length]
        for (var n = 1; n < categoryLists.length; n++) {
          if (categoryLists[n][0].parent == (arrayItem.parent)) {
            //        arrayItem.myColor = categoryLists[n][0].myColor; // Copy color from parent hack
            categoryLists[n].push(arrayItem);
            found = true;
          }

          if (arrayItem.allTags) {
            arrayItem.allTags.forEach(function (tag: string) {
              if (tag.toLowerCase() == categoryLists[n][0].parent.toLowerCase()) {
                categoryLists[n].push(arrayItem);
                found = true;
              }
            })
          }
        }
        if (!found) {
          // NEW PARENT WITH SUBS
          console.log("adding");

          categoryLists[i] = [];
          categoryLists[i++].push(arrayItem);
        }
      }

    });

    // NOW SORT EACH BY POSITION
    categoryLists.forEach(function (arrayItem) {
      arrayItem.sort(function (a, b) { return a.position - b.position; });
    });
    categoryLists[0].forEach(function (arrayItem, index, me) {

      self.catOptions.push(arrayItem);

    });

    console.log("Cat Options", self.catOptions, this.allCategories);
    //self.items = self.dataSourceCat.connect();
  }


  /*
        navigateCrumb(crumb: string) {
          console.log(crumb);
      
          this.global.viewMode = 0;
      
          if (crumb == "All Products") {
            this.global.viewMode = 1;
            console.log("SHOW All ITEMS");
            this.GetMenu(crumb);
            return;
          }
      
          if (crumb == "Home") { this.navigate(null, "Top Category"); }
          else this.navigate(crumb);
      
        }
    */
  navigateBack(parent: string) {
    // Find category that has this title

    var self = this;

    // CHECK FOR ANY SUB-CATEGORIES - SWITCH TO PRODUCT VIEW IF NONE
    // PROBABLY SHOULD ADD hasCHildren to data...
    var gotoCat: Category;


    console.log("BACK ", parent);
    this.global.viewOrderMode = 0;
    var foundMe = -1;
    categoryLists.forEach(function (arrayItem: Category[], index) {
      arrayItem.forEach(function (item: Category) {
        if (item.title == parent) {
          gotoCat = item;
          foundMe = index;
        }

      })

    });


    if (foundMe != -1) {
      console.log("found: ", gotoCat, categoryLists[foundMe][0]);
      //      this.navigate (gotoCat);

      this.global.viewMode = 0;
      self.global.myParent = gotoCat.parent;

      if (self.global.myParent == "Top Category") {
        self.global.myParent = "";
        self.global.menuTitle = "";
      }
      else {  // IF NOT A TOP CATEGORY GO FIND IT TO GET CORRECT menuTitle

        self.allCategories.forEach(function (item: Category) {
          if (item.title == gotoCat.parent) {
            if (item.menuTitle)
              self.global.menuTitle = item.menuTitle;
            else
              self.global.menuTitle = item.title;
          }
        })

      }



      self.catOptions.splice(0, self.catOptions.length);
      categoryLists[foundMe].forEach(function (arrayItem) {
        self.catOptions.push(arrayItem);
        console.log(arrayItem.title);

      });
      //      console.log(self.catOptions);

      //  self.items = self.dataSourceCat.connect();

    }
    // Scroll to top
    window.scroll(0, 0);
  }

  navigate(item: Category, optTitle?: string) {
    var self = this;
    var title = "";

    if (item) {
      console.log("nav: ", item);

      if (item && item.linkURL) {
        window.location.href = item.linkURL;
        return;
      }
    }

    if (item) {
      title = item.title;

      if (item.menuTitle) {
        self.global.menuTitle = item.menuTitle;
      }
      else {
        self.global.menuTitle = item.title;
      }
    }
    if (optTitle) title = optTitle;
    console.log("nav title: ", title);

    // CHECK FOR ANY SUB-CATEGORIES - SWITCH TO PRODUCT VIEW IF NONE
    // PROBABLY SHOULD ADD hasCHildren to data...

    this.global.viewMode = 0
    this.global.viewOrderMode = 0;
    var hasChildren = false;
    self.global.myParent = title;
    console.log("ALL cats ", categoryLists);

    categoryLists.forEach(function (arrayItem, index, me) {
      if (arrayItem[0].parent == title) {
        hasChildren = true;
        self.global.myParent = title;
        if (self.global.myParent == "Top Category") {
          self.global.myParent = "";
          self.global.menuTitle = "";
        }

        self.catOptions.splice(0, self.catOptions.length);

        arrayItem.forEach(function (arrayItem, index, me) {

          self.catOptions.push(arrayItem);
          //   console.log(arrayItem.title);

        });
        console.log(self.catOptions);
        //    self.items = self.dataSourceCat.connect();

      }

    });

    if (!hasChildren) { // SWITCH TO product list 
      console.log("SHOW CONTENT/PRODUCT ITEMS");
      this.global.viewMode = 1;
      this.GetMenu(item.title);
      //    console.log(self.menu);


    }

    // Scroll to top
    //   window.scroll(0, 0);
  }

  GetMenu(category: string) {

    var self = this;
    console.log("Category; ", category);
    var tileCount = 0;
    self.global.menu.splice(0, self.global.menu.length);  // REMEMBER THIS CODE TO ZERO ARRAY
    self.global.content.splice(0, self.global.content.length);

    self.global.allContent.forEach(function (arrayItem: Content) {
      if (arrayItem.category == category || category == "All Content") {
        self.global.content.push(arrayItem);
      }
    })

    tileCount = 0;
    self.global.allProducts.forEach(function (arrayItem: onMenuItem, index) {
      if (arrayItem.category == category || category == "All Products") {
        arrayItem.qty = self.quantities[0];
        self.global.menu.push(arrayItem);
        tileCount++;
      }

    })

    // ADD RELATED by Tag Items
    self.global.allProducts.forEach(function (arrayItem: onMenuItem, index) {
      if (arrayItem.allTags) {
        arrayItem.allTags.forEach(function (tag: string) {
          if (tag == category) {
            const found = self.global.menu.find(element => element == arrayItem);
            if (!found) {
              self.global.menu.push(arrayItem);
              tileCount++;
            }
          }
        })
      }
    })

    self.global.menu.sort(function (a, b) {
      let p1 = a.myPosition || 0, p2 = b.myPosition || 0;

      /* descending
            if(p1<p2)
                return 1; // Put key2 first in sorted order
            return -1; // Else, put key1 first in sorted order
           */
      //   For ascending order, do this
      if (p1 < p2)
        return -1;
      return 1;

    });

    // FINAL STEP: CHECK FOR OPTIONS WITH {listTags} AND AUTO-ADD OPTIONS WITH PRODUCTS HAVING TAG

    self.global.menu.forEach(function (arrayItem, index) {
      if (arrayItem.allOptions) {

        arrayItem.allOptions.forEach(function (option) {
          if (option.listTags) {// HACK SUPPORT MULTIPLE TAGS!!

            self.global.allProducts.forEach(function (product: onMenuItem, index) {
              if (product.allTags) {
                product.allTags.forEach(function (tag: string) {
                  if (tag == option.listTags[0]) {
                    //const found = self.global.menu.find(element => element == product); // NEED??

                    option.list.push(product.title);
                    tileCount++;

                  }
                })
              }
            })


          }

        })
      }

    })



    // self.global.obs = self.dataSourceMenu.connect();
    //  self.global.contentobs = self.dataSourceContent.connect();
    console.log("Products: ", self.global.menu, self.global.content);
  }


  letterWords: Array<string> =
    [
      "Area",
      "Army",
      "Baby",
      "Back",
      "Ball",
      "Band",
      "Bank",
      "Base",
      "Bill",
      "Body",
      "Book",
      "Call",
      "Card",
      "Care",
      "Case",
      "Cash",
      "City",
      "Club",
      "Cost",
      "Date",
      "Deal",
      "Door",
      "Duty",
      "East",
      "Edge",
      "Face",
      "Fact",
      "Farm",
      "Fear",
      "Fig",
      "File",
      "Film",
      "Fire",
      "Firm",
      "Fish",
      "Food",
      "Foot",
      "Form",
      "Fund",
      "Game",
      "Girl",
      "Goal",
      "Gold",
      "Hair",
      "Half",
      "Hall",
      "Hand",
      "Head",
      "Help",
      "Hill",
      "Home",
      "Hope",
      "Hour",
      "Idea",
      "Jack",
      "John",
      "Kind",
      "King",
      "Lack",
      "Lady",
      "Land",
      "Life",
      "Line",
      "List",
      "Look",
      "Lord",
      "Loss",
      "Love",
      "Mark",
      "Mary",
      "Mind",
      "Miss",
      "Move",
      "Name",
      "Need",
      "News",
      "Note",
      "Page",
      "Pain",
      "Pair",
      "Park",
      "Part",
      "Past",
      "Path",
      "Paul",
      "Plan",
      "Play",
      "Post",
      "Race",
      "Rain",
      "Rate",
      "Rest",
      "Rise",
      "Risk",
      "Road",
      "Rock",
      "Role",
      "Room",
      "Rule",
      "Sale",
      "Seat",
      "Shop",
      "Show",
      "Side",
      "Sign",
      "Site",
      "Size",
      "Skin",
      "Sort",
      "Star",
      "Step",
      "Task",
      "Team",
      "Term",
      "Test",
      "Text",
      "Time",
      "Tour",
      "Town",
      "Tree",
      "Turn",
      "Type",
      "Unit",
      "User",
      "View",
      "Wall",
      "Week",
      "West",
      "Wife",
      "Will",
      "Wind",
      "Wine",
      "Wood",
      "Word",
      "Work",
      "Year"
    ];


}
