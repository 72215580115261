<!-- <div id="editContainer"></div> No longer use this -->


<div *ngIf="open">
    <app-container [sceneId]="sceneId" [VIPIndex]="VIPIndex" [eventEmitter]="eventEmitter" [settings]="dataSettings"
        [list]="dataList" (openTab)="tabSelected($event)" [api]="false" [apiFunction]="'VIP'"
        [style]="style"></app-container>
</div>

<div *ngIf="api=='Tool'">
    <div style="width:100%; max-width:100vw; font-size:larger; background-color: rgb(207, 229, 254); border: 2px solid
    rgb(12, 76, 237)">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="center none" style="width:100%;">
            <div *ngFor="let field of editor; let x = index">
                <app-field [field]="field" [binding]="" [showSubmit]="false" [editMode]="true"
                    (outputs)="output($event)"></app-field>

            </div>
        </div>

    </div>
</div>

<div *ngIf="api=='Settings'" style="width:100%; max-width:100vw;">
    <app-form [form]="settingsForm" [autoSubmit]="false" (submitEvent)="settingsOutput($event)"></app-form>
</div>

<div *ngIf="api=='Media'">
    List of included media...
    <app-media [companyId]="global.myCompany.id" (eventEmitter)="mediaEvents($event)"></app-media>
</div>

<div *ngIf="api=='Objects'" fxFlex fxLayout="column" fxLayoutAlign="center start">

    <div pButton pRipple style="cursor:pointer" (click)="selectAll()">Select ALL Objects</div>
    <div pButton pRipple style="cursor:pointer" (click)="selectNone()">Select None</div>
    <div pButton pRipple style="cursor:pointer" (click)="tweenToggle()">Add Tween to selected</div>

    <div pButton pRipple style="cursor:pointer" (click)="enablePhysics()">Enable Physics</div>
    <div pButton pRipple style="cursor:pointer" (click)="disablePhysics()">Disable Physics</div>

    <div pButton pRipple style="cursor:pointer" (click)="groupsToggle()">Groups</div>



    <br><br>
    <div pButton pRipple style="cursor:pointer" (click)="removeSelected()">Remove selected</div>

    <div (click)="selectObject(object.id)" *ngFor="let object of global.allVIPs[VIPIndex].allObjects; let x = index"
        style="width:100%">


        <div style="width:100%; cursor:pointer; margin:12px; text-align: left;"
            (click)="selectionChanged($event, object.id)">
            <div *ngIf="object.selected"
                style="width: 12px; height:12px; background-color:aqua; border: solid, 1px black;" pRipple pButton>
            </div>
            <div *ngIf="!object.selected"
                style="width: 12px; height:12px; background-color:rgb(235, 238, 238); border: solid, 1px black;" pRipple
                pButton>
            </div>

                {{getObjectWhat(object)}}
                {{getObjectWho(object)}}
                {{getObjectWhere(object)}}

                <div pButton pRipple style="cursor:pointer" (click)="tweenToggle()(object)">
                    
                    <span *ngIf="!object.tweens">Add </span>
                    <span *ngIf="object.tweens">Edit </span>Tweens</div>


            <div pButton pRipple style="cursor:pointer" (click)="moveBack(object)">Move Back</div>

            <div pButton pRipple style="cursor:pointer" (click)="moveForward(object)">Move Forward</div>

        </div>
        <div *ngIf="tweens && object.selected">
            <app-form [form]="form" [autoSubmit]="false" (submitEvent)="outputEvent($event)"></app-form>
        </div>
        <div *ngIf="groups && object.selected">
            <app-form [form]="groupForm" [autoSubmit]="false" (submitEvent)="groupsUpdate($event)"></app-form>
        </div>
    </div>

</div>