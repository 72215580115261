import { AfterViewInit, AfterContentInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Company, Field, Navigate, Reviews, Review, Coupon, ReviewSetup } from '../../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../menu/nav.service';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../../user-interface';
import { RegisterService } from '../../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { ReviewsService } from '../../reviews.service'


@Component({
  selector: 'app-review-settings',
  templateUrl: './review-settings.component.html',
  styleUrls: ['./review-settings.component.css', '../../../common.scss']
})

export class ReviewSettingsComponent implements OnInit {

  lastReview: number = -1;

  constructor(public global: Globals, private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute, public nav: NavService,
    public reviewsService: ReviewsService) {

    }

    ngOnInit(): void {
  
    }

    outputEvent(field: Field) {
      var self = this;

      console.log ("Review settings update ", field)
// CHECK THAT OBJECT EXISTS
      if (!self.global.myCompany.reviewSetup)
      self.global.myCompany.reviewSetup = {}


      switch (field.title.toLowerCase()) {
        case "announce":
          self.global.myCompany.reviewSetup.announce = field.trueFalse;
          self.updateSettings (self.global.myCompany.reviewSetup);
          break;
        case "videoreviews":
          self.global.myCompany.reviewSetup.videoReviews = field.trueFalse;
          self.updateSettings (self.global.myCompany.reviewSetup);
          break;
        case "autopublish":
          self.global.myCompany.reviewSetup.autoPublish = field.selected[0];
          self.updateSettings (self.global.myCompany.reviewSetup);
          break;  
      }

    }
    getFullWidth() {
      var style;

      style = this.global.getScreenWidth + 'px;'
      return style;
    }
    getReviewUrl() {

      var url = "http://api.qrserver.com/v1/create-qr-code/?data=NapkinHQ.com/review/";
      url += this.global.myCompany.store
      url += '&size=200x200';
      return url;
    }

    updateSettings(reviewSetup: ReviewSetup) {
      var self = this;
      var db = firebase.firestore();
  
      var docRef = db.collection("company").doc(self.global.myCompany.id)
  
      docRef.update({reviewSetup: reviewSetup}).then(() => {
        console.log("review settings updated");
    
      })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
  
    }

}
