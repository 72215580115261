<div *ngIf="media && !justMedia && !noStyle">
    <div *ngFor="let m of media; let i = index;" class="content" [style.background-color]="getBackgroundColor(m)"
        [style.height]="getHeight(m)" [style.width.px]="getWidth(m)">
        <div [ngStyle]="{ 'width': getMediaWidth()}">
            <div *ngIf="m.fileType=='audio/mpeg'">
                <audio id="audio" controls>
                    <source src={{m.url}} id="src" />
                </audio>
            </div>


            <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
    || m.fileType=='video/quicktime')" class="media-container" [style]="getImageStyle(m)">

                <video (click)="setFullScreen(m)" class="video" [style]="getImageStyle(m)" playsinline
                    webkit-playsinline autoplay loop [muted]="'muted'" [id]="'prodmedia'+i">
                    <source src="{{m.url}}" type="video/mp4">
                </video>

            </div>

            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/gif' || 
    m.fileType=='image/png' || m.fileType=='photo'" class="media-container">
                <div fxLayout="column" fxLayoutAlign="center none">

                    <img (click)="setFullScreen(m)" class="media" [style]="getImageStyle(m)" src="{{m.url}}" />
                </div>
            </div>
        </div>
    </div>
</div>


<div *ngIf="media && justMedia">
    <div *ngFor="let m of media; let i = index;">
        <div>
            <div *ngIf="m.fileType=='audio/mpeg'">
                <audio id="audio" controls>
                    <source src={{m.url}} id="src" />
                </audio>
            </div>


            <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
    || m.fileType=='video/quicktime')">

                <div class="container video-cover">


                    <video [style]="getStyle()" (click)="setFullScreen(m)" playsinline webkit-playsinline autoplay loop [muted]="'muted'"
                        [id]="'prodmedia'+i">
                        <source src="{{m.url}}" type="video/mp4">
                    </video>
                </div>

            </div>

            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/gif' || 
    m.fileType=='image/png' || m.fileType=='photo'" >
            <div>

                    <img (click)="setFullScreen(m)" [style]="getStyle()" src="{{m.url}}" />
                </div>

            </div>

        </div>
    </div>

</div>




<div *ngIf="media && noStyle" class="polaroid">

    <div *ngFor="let m of media; let i = index;">
        <div *ngIf="m.fileType=='audio/mpeg'">
            <audio id="audio" controls>
                <source src={{m.url}} id="src" />
            </audio>
        </div>

        <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
    || m.fileType=='video/quicktime')" class="media-container">
            <div>
                <video id="video{{i}}" (click)="setFullScreen(m)" style="max-width: 100vw; max-height:80vh" playsinline
                    webkit-playsinline autoplay [muted]="'muted'" [id]="'video'+i">
                    <source src="{{m.url}}" type="video/mp4">
                </video>
                <span *ngIf="node?.showControls && node?.showControls==true" class="center-me-video">

                    <div fxFlex fxLayout="row" fxLayoutAlign="center none">
                        <button style="margin-left:12px;margin-right:12px;" pButton pRipple
                            (click)="changeAudio('video',i)">Play Audio</button>
                        <div *ngIf="paused">
                            <button style="margin-left:12px;margin-right:12px;" (click)="playVideo('video', i)"
                                class="pretty-postag" pButton pRipple type="button">Play
                                Video</button>
                        </div>
                        <div *ngIf="!paused">

                            <button style="margin-left:12px;margin-right:12px;" (click)="pauseVideo('video', i)"
                                class="pretty-postag" pButton pRipple type="button">Pause
                                Video</button>
                        </div>
                    </div>

                </span>
            </div>

        </div>

        <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/png' || m.fileType=='photo'">

            <img style="max-width: 100vw; max-height:80vh" src="{{m.url}}" />

        </div>


        <div class="container">
            <div class="review__desc" style='user-select: text' (onselect)="autoTag($event)">

                <div *ngIf="m.description" class="polaroid-text">
                    {{m.description}}
                </div>

            </div>
        </div>


    </div>
</div>