import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Form, Logic, SessionField, Code, CodeObject, Element, Field, ContainerSettings, AppNode, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { Product, Options } from '../../components/product-int';
import { ButtonModule } from 'primeng/button';
import { Order } from '../order-interface';
import { CurrencyPipe } from '@angular/common';
import { FormService } from '../form.service';

@Component({
  selector: 'app-logic-editor',
  templateUrl: './logic-editor.component.html',
  styleUrls: ['./logic-editor.component.css', '../../common.scss']
})
export class LogicEditorComponent implements OnInit {
  @Input() logic: Logic;
  @Input() form: Form;
  @Input() field: Field;
  @Input() formLogic: boolean = false;


  editor: Field[][];
  //editFields: Field[];

  // Modes: New line, Result, Field, Parameters, Operator, Value, Function

  // Modes: New line, Equation, Left side, Right side, Operator, Parameters, Function
  indent: number = 0;
  codeBlocks: string[] = ["#11ffaf77", "#f9d582", "#e1d288", "#f945aa"]

  logicTypes: string[] = ["Equation", "Function", "ForEach", "If"]
//  resultTypes: string[] = ["Value", "Field", "Function"]
//  parameters: string[] = ["Value", "Field"]
 // operatorTypes: string[] = ["Plus", "Minus", "Multiply", "Divide", "Equals"]
 // fieldsAvailable: string[] = [];
  // functionTypes: string[] = [
  //   "Display", "Random", "Payment", "Average", "CreateCanvas"
  // ]
  //  ifElseList: string[] = ["Result == TRUE", "Result == FALSE"]



  currentLine: number = null;
  newLine: Code;
  mode: string = "";
  value: number;
  paramsRequired = 0;
  objectList: string[];
  fieldList: string[];

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller,
    private cp: CurrencyPipe,
    private formService: FormService,

  ) {
    // Form logic?
    //  if (!this.field) {
    //    this.functionTypes = [
    //      "Hide form", "Goto form",
    //    ]

    //   }




  }

  ngOnInit(): void {
    var self = this;

    if (!this.logic) {    // Create basecode
      this.logic = {

        createdBy: this.global.authuser.uid,
        createdAt: Date.now(),
        code: []

      }
    }

    this.initEditor();


    //  this.currentLine = 0; //this.logic.code.length;


    if (this.form) {
      //   this.form.numberFields
    }
    else this.form = this.global.allForms[this.global.formIndex];
    /*
        console.log("Get the number/result fields ", this.form, this.field)
        self.form.fields.forEach(function (field) {
          if (field.type == 'number' || field.type == 'result') {
            self.fieldsAvailable.push(field.title);
          }
          if (field.type == 'result') {
            self.fieldsAvailable.push(field.title);
          }
        })
    */

  }

  initEditor() {
    var self = this;

    console.log("Init the EDITOR ", self.form, self.field)

    self.editor = [];
    var edit: Field[]
      = [
      ]

    self.objectList = [];
    //  this.fieldList = [];
    // SCAN ALL LOGIC?CODE FOR OBJECTS
    self.global.allForms.forEach(function (form) {
      form.fields.forEach(function (field) {
        if (field.logic)
          field.logic.code.forEach(function (code) {
            if (!code.codeObjects.id) code.codeObjects.id = createUID(code.codeObjects);
            //   console.log("Object: ", code.codeObjects, self.form)
            if (code?.codeObjects.type == "Sprite")
              self.objectList.push(code.codeObjects.id);
            if (code?.codeObjects.type == "VIP")
              self.objectList.push(code.codeObjects.id);
          })
      })
    })

    self.logic.code.forEach(function (code) {
 /*     
      if (code?.codeObjects?.type == 'Sprite') {
        // self.objectList.push(code.codeObjects.name)
        self.fieldList = [];

        edit = [
          { title: 'Id', type: "number", label: "Id", value: code.codeObjects.id },

          { title: 'xpos', type: "number", label: "X position", value: code.codeObjects.x },
          { title: 'ypos', type: "number", label: "Y position", value: code.codeObjects.x },
          { title: 'vx', type: "number", label: "X Velocity", decimals: 3, value: code.codeObjects.vx },
          { title: 'vy', type: "number", label: "Y Velocity", decimals: 3, value: code.codeObjects.vy },
          { title: 'scalex', type: "number", label: "X Scale", value: code.codeObjects.scalex },
          { title: 'scaley', type: "number", label: "Y Scale", value: code.codeObjects.scaley },

          { title: 'media', type: "selectMedia", layout: "column", label: "Select media", media: code.codeObjects.media },

          { title: 'Text', type: "text", label: "Text", binding: code.codeObjects.dataStr },
        ]
        edit.forEach(function (item) {
          if (1 || item.type == 'number')
            self.fieldList.push(item.title);
        })


      }
        */
      if (code?.codeObjects?.type == 'ForEach') {
        edit = [];

      }
      if (code?.codeObjects?.type == 'If') {
        edit = [];

        if (!code.codeObjects.fields) code.codeObjects.fields = [];
        edit = [
          { title: 'result', dataSource: 'someField', type: "Options", options: [], label: "Field", binding: code.codeObjects.result },
          { title: 'assign', type: "Options", options: ['==', '>', '<', '!='], label: "Assign", binding: code.codeObjects.assign },
          { title: 'field', dataSource: 'someField', type: "Options", options: ['Value'], label: "Field or Value", binding: code.codeObjects.fields[0] },
        ]
        if (code?.codeObjects?.values)
          edit[2].value = code.codeObjects.values[0]; // Hack - fixed index

        self.global.allSessionFields.forEach(function (fieldSource) {
          if (fieldSource.type == 'number' || fieldSource.type == 'result') {
            var str = fieldSource.sourceForm + ":" + fieldSource.sourceField;
            edit[0].options.push(str);
            edit[2].options.push(str);
          }
        })

      }

      if (code?.codeObjects?.type == 'Function') {
        if (!code.codeObjects.values) code.codeObjects.values = [];
        edit = [{ title: 'result', defaultOpen: true, dataSource: 'nothing', type: "functionBuilder", options: ["result"], label: "Result field: " + code.codeObjects.values[0], selected: code.codeObjects.values, class: "show" },]
        self.form.fields.forEach(function (field) {
          if (field.type == 'select-button' || field.type == 'displayImage' || field.type == 'displayHTML' || field.type == 'htmlEditor' || field.type == 'displayText' || field.type == 'input-text' || field.type == 'full-text' ||field.type == 'currency' || field.type == 'number' || field.type == 'result' || field.type == 'mathQuery') {
            // var str = field.sourceForm + ":" + fieldSource.sourceField;
            edit[0].options.push(field.title);
          }
        })
        // NOW ADD FIELDS FROM OTHER FORMS
        edit[0].options2 = [];
        self.global.allSessionFields.forEach(function (fieldSource) {
          if (fieldSource.type == 'displayHTML' || fieldSource.type == 'displayText' || fieldSource.type == 'input-text' || fieldSource.type == 'currency' || fieldSource.type == 'number' || fieldSource.type == 'result' || fieldSource.type == 'mathQuery') {
            if (fieldSource.sourceForm != self.form.name) {
              var str = fieldSource.sourceForm + ":" + fieldSource.sourceField;
              edit[0].options2.push({ id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            }
          }
        })

      }


      if (code?.codeObjects?.type == 'Equation') {
        if (!code.codeObjects.values) code.codeObjects.values = [];
        edit = [{ title: 'result', defaultOpen: true, dataSource: 'nothing', type: "equationBuilder", sourceForm: self.form.name, options: [], label: "Result field: " + code.codeObjects.values[0], selected: code.codeObjects.values, class: "show" },]
        self.form.fields.forEach(function (field) {
          if (field.type == 'select-button' || field.type == 'displayHTML' || field.type == 'displayText' || field.type == 'input-text' || field.type == 'currency' || field.type == 'number' || field.type == 'result' || field.type == 'mathQuery') {
            // var str = field.sourceForm + ":" + fieldSource.sourceField;
            edit[0].options.push(field.title);
          }
        })

        // NOW ADD FIELDS FROM OTHER FORMS
        edit[0].options2 = [];
        self.global.allSessionFields.forEach(function (fieldSource) {
          if (fieldSource.type == 'displayHTML' || fieldSource.type == 'displayText' || fieldSource.type == 'input-text' || fieldSource.type == 'currency' || fieldSource.type == 'number' || fieldSource.type == 'result' || fieldSource.type == 'mathQuery') {
            if (fieldSource.sourceForm != self.form.name) {
              var str = fieldSource.sourceForm + ":" + fieldSource.sourceField;
              edit[0].options2.push({ id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            }
          }
        })

        /* edit = [
          { title: 'result', dataSource: 'someField', type: "Options", options: [], label: "Result field", binding: code.codeObjects.result, class: "show" },
          { title: 'assign', type: "Options", options: ['=', '+=', '-=', '*=', '/='], label: "Assign", binding: code.codeObjects.assign, class: "show" },
          { title: 'field', dataSource: 'someField', type: "Options", options: ['Value'], label: "Field/Value", binding: self.findField(code, 'field'), class: "show" },
          { title: 'function', dataSource: 'napkinFunctions', type: "Options", options: [], label: "Function", binding: code.codeObjects.function, class: "show" },
        ]


        if (code.codeObjects.function) {
          if (code.codeObjects.function == 'Monthly payment') {
            edit.push(
              { title: 'field1', dataSource: 'someField', type: "Options", options: ['Value'], label: "Principle", binding: self.findField(code, 'field1'), class: "show" },
              { title: 'field2', dataSource: 'someField', type: "Options", options: ['Value'], label: "Term (months)", binding: self.findField(code, 'field2'), class: "show" },
              { title: 'field3', dataSource: 'someField', type: "Options", options: ['Value'], label: "Annual interest rate", binding: self.findField(code, 'field3'), class: "show" },

            )
          }
        }

        if (code?.codeObjects?.values)
          edit[2].value = code.codeObjects.values[0]; // Hack - fixed index
        edit[0].options2 = [];
        edit[2].options2 = [];

        self.global.allSessionFields.forEach(function (fieldSource) {
          if (fieldSource.type == 'currency' || fieldSource.type == 'number' || fieldSource.type == 'result' || fieldSource.type == 'mathQuery') {
            var str = fieldSource.sourceForm + ":" + fieldSource.sourceField;
            edit[0].options2.push({ logicField: edit[0].title, id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            edit[2].options2.push({ logicField: edit[2].title, id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            if (edit.length > 4) {
              if (!edit[4].options2) edit[4].options2 = [];
              edit[4].options2.push({ logicField: edit[4].title, id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            }
            if (edit.length > 5) {
              if (!edit[5].options2) edit[5].options2 = [];
              edit[5].options2.push({ logicField: edit[5].title, id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            }
            if (edit.length > 6) {
              if (!edit[6].options2) edit[6].options2 = [];
              edit[6].options2.push({ logicField: edit[6].title, id: fieldSource.id, title: fieldSource.sourceField, subTitle: fieldSource.sourceForm });
            }

          }
        })
        */
        console.log("Equation: ", edit)
      }

      if (edit && edit.length)
        self.editor.push(edit)

    })
  }

  findField(code: Code, logicField: string) {
    const index = code.codeObjects.fields.findIndex(item => item.logicField === logicField);
    if (index != -1) return code.codeObjects.fields[index];
    else return null;
  }

  spriteOutput(field) {
    var self = this;
    var updateField = true;

    console.log("Update code ", field)


    if (field.title == "object") {
      self.logic.code[self.currentLine].codeObjects.result = field.binding;
    }


    if (field.type == "equationBuilder" || field.type == "functionBuilder") {
      self.logic.code[self.currentLine].codeObjects.values = field.selected;
      updateField = true;
    }


    if (field.title == "field" && field.binding == 'Value') {
      updateField = false;
      if (field.value && (!self.logic.code[self.currentLine]?.codeObjects?.values?.length || field.value != self.logic.code[self.currentLine]?.codeObjects?.values[0])) {
        if (!self.logic.code[self.currentLine].codeObjects.values) self.logic.code[self.currentLine].codeObjects.values = [];
        self.logic.code[self.currentLine].codeObjects.values[0] = field.value;
        updateField = true;
      }
      console.log("HEY GOT A VALUE FOR ", field)

    }
    if (field.title == "objectField") {
      self.logic.code[self.currentLine].codeObjects.resultField = field.binding;


    }
    if (field.title == "Value") {
      self.logic.code[self.currentLine].codeObjects.fields[0] = field.binding;
      self.logic.code[self.currentLine].codeObjects.values = field.selected;
      console.log("VALUE CODE TYPE: ", self.logic.code[self.currentLine].codeObjects)
    }

    /*
        if (field.title == "xpos")
          self.logic.code[self.currentLine].codeObjects.x = field.value;
        if (field.title == "ypos")
          self.logic.code[self.currentLine].codeObjects.y = field.value;
        if (field.title == "vx")
          self.logic.code[self.currentLine].codeObjects.vx = field.value;
        if (field.title == "vy")
          self.logic.code[self.currentLine].codeObjects.vy = field.value;
        if (field.title == "scalex")
          self.logic.code[self.currentLine].codeObjects.scalex = field.value;
        if (field.title == "scaley")
          self.logic.code[self.currentLine].codeObjects.scaley = field.value;
        */
    if (field.title == "Text")
      self.logic.code[self.currentLine].codeObjects.dataStr = field.binding;
    if (field.title == "result")
      self.logic.code[self.currentLine].codeObjects.result = field.binding;
    if (field.title == "resultField")
      self.logic.code[self.currentLine].codeObjects.resultField = field.binding;
    if (field.title == "assign")
      self.logic.code[self.currentLine].codeObjects.assign = field.binding;
    if (field.title.startsWith("field")) {
      if (!self.logic.code[self.currentLine].codeObjects.fields) self.logic.code[self.currentLine].codeObjects.fields = [];


      if (field?.selected && field?.selected?.length)
        self.logic.code[self.currentLine].codeObjects.fields.push(field.selected[0]);
      else
        self.logic.code[self.currentLine].codeObjects.fields.push(field.binding);


      console.log("FIELD FROM BINDING ", field.binding, self.logic.code[self.currentLine].codeObjects.fields)
      if (field.value) {
        if (!self.logic.code[self.currentLine].codeObjects.values)
          self.logic.code[self.currentLine].codeObjects.values = [field.value];
        self.logic.code[self.currentLine].codeObjects.values.push(field.value);
        self.logic.code[self.currentLine].codeObjects.fields.push('Value');

        console.log("UPDATE VALUE ", self.logic.code[self.currentLine].codeObjects.values)
      }
    }
    //  if (field.bounds || 1)
    //    self.logic.code[self.currentLine].codeObjects.bounds = true;

    if (field.title == "minVal") {
      self.logic.code[self.currentLine].codeObjects.fields[0] = field.binding;
      self.logic.code[self.currentLine].codeObjects.values[0] = field.value;
    }
    if (field.title == "maxVal") {
      self.logic.code[self.currentLine].codeObjects.fields[1] = field.binding;
      self.logic.code[self.currentLine].codeObjects.values[1] = field.value;
    }
    /*
    if (field.title == "function") {
      self.logic.code[self.currentLine].codeObjects.fields = [];
      self.logic.code[self.currentLine].codeObjects.values = [];

      self.logic.code[self.currentLine].codeObjects.function = field.binding;
    }
    */
    if (field.title == "media")
      self.logic.code[self.currentLine].codeObjects.media = field.media;

    //old     self.formService.updateLogic(self.form);

    if (updateField)
      self.updateField(self.form, self.field)
  }

  formSettings() {


  }

  updateField(form: Form, field: Field) {
    var self = this;
    var db = firebase.firestore();

    if (form.logic)
      form.logic = removeUndefined(form.logic)

    if (self.formLogic) {
      console.log("EDITING FORM LOGIC ", self.form)

      var fieldRef =
        db.collection("company").doc(self.global.myCompany.id)
          .collection("forms")
          .doc(self.form.id)
          .update({ logic: form.logic }).then((querySnapshot) => {
            console.log("FORM LOGIC UPDATE ", form)
            self.initEditor();
          })


      return;

    }
    if (!form) form = self.form;
    console.log("form ", form)
    var id = form.id;

    if (!id) id = form.formId;

    if (field) {
      var fieldToUpdate = self.formService.findMe(field.title, form)

      if (fieldToUpdate != -1) {

        form.fields[fieldToUpdate] = field;
      }
    }
    // STRIP REMOVE NULLS all empty fields

    form.fields = removeUndefined(form.fields);


    console.log("Updating field: ", form.fields)

    var fieldRef =
      db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(id)
        .update({ fields: form.fields }).then((querySnapshot) => {
          console.log("FIELD UPDATE ", form.fields)
          self.initEditor();
        })


  }




  amIContainer(code: Code) {
    return false;
    /*
    if (code.elements[0].type == 'If') {
      return true;
    }
    else return false;
    */
  }

  getIndentColor(i) {
    var self = this;

    var indent = 0;
    var color = "#ffffff";

    self.logic.code.forEach(function (code, index) {
      if (index == i) color = self.codeBlocks[indent]
      if (code?.codeObjects?.type == 'ForEach')
        indent++;
      if (code?.codeObjects?.type == 'End For' || code?.codeObjects?.type == 'End If')
        indent--;

    })
    return color;
  }


  getBlockColor(i) {
    var self = this;

    var indent = 0;
    var color = "#ffffff";

    self.logic.code.forEach(function (code, index) {
      if (code?.codeObjects?.type == 'ForEach')
        indent++;
      if (index == i) color = self.codeBlocks[indent]
      if (code?.codeObjects?.type == 'End For' || code?.codeObjects?.type == 'End If')
        indent--;
    })
    return color;
  }
  displayRow(code: Code, i) {
    var row = ""

    if (!code?.codeObjects?.type) return "Choose a logic type:"
    switch (code.codeObjects.type) {
      case "Equation":
        row = "";
        code.codeObjects.values.forEach(function (item, index) {
          if (typeof (item) == 'object') {
            row += "<strong>"+item.subTitle + "</strong>: " + item.title
          }
          else
            row += item + " "
        })
        break;
      case "Function":
        row = "";
        code.codeObjects.values.forEach(function (item, index) {
          if (typeof (item) == 'object') {
            row += "<strong>"+item.subTitle + "</strong>: " + item.title
          }
          else
            row += item
          if (index == 2) row += '('
          else if (index >= 3 && index < code.codeObjects.values.length - 1)
            row += ', '
          else if (index < 3)
            row += ' '
        })
        row += ')'
        break;
      /*      
          case "Function":
            if (code.codeObjects?.result)
              row = code.codeObjects.result;
            if (code.codeObjects?.result?.title)
              row = code.codeObjects.result.title + ":" + code.codeObjects.result.subTitle;
            if (code.codeObjects?.assign) row += " " + code.codeObjects.assign + " "
            if (code.codeObjects?.function) row += " " + code.codeObjects.function
            // DISPLAY PARAMETERS
            row += '(';
            if (code?.codeObjects?.fields)
              code.codeObjects.fields.forEach(function (field, index) {
                if (index)
                  row += ", ";
                if (field == 'Value')
                  row += code.codeObjects.values[index];
                else row += field;
              })
            else {
              if (code?.codeObjects?.values)
                code.codeObjects.values.forEach(function (field, index) {
                  if (index)
                    row += ", ";
                  row += field;
                })
            }
            row += ')';
    
    
            break;
      */
      case "If":
        row = "If " + code.codeObjects.result
        if (code.codeObjects.assign) row += " " + code.codeObjects.assign + " "
        if (code.codeObjects.function) row += " " + code.codeObjects.function
        if (code.codeObjects.values) row += " " + code.codeObjects.values[0];

        this.indent++;
        break;
      case "End If":
        row = "End of if true block"
        this.indent--;
        break;
      case "ForEach":
        row = "for each of value times let's execute code below:"
        this.indent++;
        break;
      case "End For":
        row = "End of for Each"
        this.indent--;
        break;

    }

    return row;


  }

  editLine(i) {
    var self = this;

    self.currentLine = i;
    //  self.formService.updateLogic(self.form);
    // self.mode = "New line"
    /*
        self.logic.code[this.currentLine].elements.forEach(function (item) {
          if (item.type == 'If')
            self.mode = "leftSide";
        })
      */

  }

  doneLine(i) {
    this.currentLine = null;
  }

  clearLine(i) {
    this.logic.code.splice(i, 1);

    this.updateField(this.form, null)
    this.initEditor();

    // Do this for form Logic ....
    // this.formService.updateLogic(this.form);
    this.mode = "New line"
  }

  openTab(data) {
    console.log("A tab was opened ", data)
  }

  logicClicked(type: string) {
    var self = this;

    if (!self.logic.code) self.logic.code = [];
    //  if (!self.logic.code[self.currentLine].elements) self.logic.code[self.currentLine].elements = [];

    //  if (!self.logic.code[self.currentLine])
    //  self.startNewLine(self.currentLine);

    //  self.logic.code[self.currentLine].elements.push({ type: type, stringValue: "" })

    console.log("CODE: ", self.logic.code[self.currentLine])
    self.logic.code[self.currentLine].codeObjects = { type: type }

    if (type == 'ForEach') {
      self.startNewLine(self.currentLine);
      self.logic.code[self.currentLine].codeObjects = { type: 'End For' }
    }

    if (type == 'If') {
      self.startNewLine(self.currentLine);
      self.logic.code[self.currentLine].codeObjects = { type: 'End If' }
    }


    self.initEditor();

    //  self.mode = type;

    /*
        if (type == 'If') {
          const x = Math.random() * 10000;
          self.logic.code[self.currentLine].id = x.toString();
    
          // Create TRUE AND FALSE CODE BLOCKS
          self.startNewLine(self.currentLine, "", x.toString());
          self.logic.code[self.currentLine].elements.push({ type: 'True', stringValue: "True result" })
    
          self.startNewLine(self.currentLine, "", x.toString());
          self.logic.code[self.currentLine].elements.push({ type: 'False', stringValue: "False result" })
    
          self.mode = 'leftSide';
    
        }
    */

    //  self.currentLine = self.logic.code.length; // hack ??insert

    console.log("Your first line of code...", self.logic);

  }
  resultClicked(type: string) {
    var self = this;

    // self.logic.code[self.currentLine].elements.push({type: type})

    self.mode = type;

    console.log("Your first line of code...", self.logic);

  }
  leftSideClicked(type: string) {
    var self = this;

    // self.logic.code[self.currentLine].elements.push({type: type})

    self.mode = type;

    console.log("Your first line of code...", self.logic);

  }

  fieldClicked(sessionField: SessionField, index) {
    var self = this;
    return;

    /*
        var lastItem = self.logic.code[self.currentLine].elements.length - 1;
    
        console.log("LAST ITEM ", self.logic.code[self.currentLine].elements[lastItem].type)
    
        if (lastItem >= 0 && self.logic.code[self.currentLine].elements[lastItem].type == 'Equation' &&
          self.logic.code[self.currentLine].elements[lastItem].stringValue.length == 0) {
          self.logic.code[self.currentLine].elements[lastItem].stringValue = sessionField.sourceForm + ":" + sessionField.sourceField;
          self.mode = "leftSide"
        }
        else {
          self.logic.code[self.currentLine].elements.push({ type: "Field", stringValue: sessionField.sourceForm + ":" + sessionField.sourceField })
    
    
          if (self.paramsRequired) {
            self.paramsRequired--;
            if (!self.paramsRequired)
              self.mode = "Operator";
            else self.mode = "Parameters"
          }
          else self.mode = "Operator";
        }
        console.log("Your first line of code...", self.logic);
        */

  }
  operatorClicked(operator: string) {
    var self = this;
    // self.logic.code[self.currentLine].elements.push({ type: "Operator", stringValue: operator })

    self.mode = "rightSide";
  }

  formClicked(event, form: Form, i) {
    var self = this;

    //  self.logic.code[self.currentLine].elements.push({ type: "strValue", stringValue: form.name })
    self.mode = "Parameters"
    self.paramsRequired = 0;
  }

  functionClicked(func: string) {
    var self = this;
    return;

    /*
    self.logic.code[self.currentLine].elements.push({ type: "Function", stringValue: func })

    if (func == 'Goto form')
      self.mode = 'Goto form'
    else {
      self.mode = 'Parameters';
      self.paramsRequired = 3;
    }
    */

  }
  valueEntered(value: number) {
    var self = this;
    console.log("CLINEHKDJH KJH: ", self.currentLine, self.logic.code)

    /*
      if (!self.logic.code[self.currentLine].elements) self.logic.code[self.currentLine].elements = [];
      //   self.logic.code[self.currentLine].elements.push({ type: "Value", numberValue: value })
  
      // Depends on number of expected fields???
      if (self.paramsRequired) {
        self.paramsRequired--;
        if (!self.paramsRequired)
          self.mode = "Operator";
        else self.mode = "Parameters"
      }
      else self.mode = "Operator";
      */

  }

  startNewLine(lineNumber, myId?: string, myParent?: string) {    /// INSERT NEW LINE
    var self = this;

    if (!myId) myId = "code";
    if (!myParent) myParent = "";

    if (self.field)
      self.field.logic = self.logic;
    else if (self.form)
      self.form.logic = self.logic

    self.mode = "New line"

    console.log("lineNumber ", lineNumber, self.logic.code)

    if (!self.logic.code || self.logic.code.length == 0 || lineNumber == self.logic.code.length - 1) {
      self.currentLine = self.logic.code.length;
      self.newLine = {
        line: self.currentLine,
        parent: myParent,
        id: myId,
        //    elements: [{ type: "Line", numberValue: self.currentLine }]
      }
      self.logic.code.push(self.newLine)

    }
    else {
      console.log("INSERT")
      self.currentLine = lineNumber + 1;
      self.newLine = {
        line: self.currentLine,
        parent: "Home",
        id: "mynewline",
        //  elements: [{ type: "Line", numberValue: self.currentLine }]
      }

      // Propogate the line numbers.
      self.logic.code.forEach(function (item) {
        if (item.line >= self.currentLine)
          item.line++;
      })
      self.logic.code.splice(self.currentLine, 0, self.newLine)
      self.logic.code.sort(function (a, b) { return a.line - b.line; });

    }

    //   self.formService.updateLogic(self.form);
  }

}
function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}

function createUID(codeObject: CodeObject): string {
  var uid = codeObject.type + " ";


  // For now just 3 1000 randoms + time;

  var a = Math.floor(Math.random() * 100).toString();
  var d = Date.now().toString()
  console.log("D ", d)
  uid += d.substring(7, 3) + a

  return uid;
}