<div *ngIf="botId && !headless" style="width:100%">
    <app-home (validateUser)="validateUser($event)"></app-home>
</div>

<p-accordion *ngIf="accessValid && access=='Admin' && chatBot.settings" expandIcon="false" collapseIcon="false"
    [multiple]="false">
    <p-accordionTab iconPos="end">
        <ng-template pTemplate="header">
            <div class="settings">Settings</div>
        </ng-template>
        <ng-template pTemplate="content">

            <app-form formName="masterChatbotSettings" [formInput]="chatBot.settings" [autoSubmit]="false"
                (submitEvent)="settingsOutputEvent($event)"></app-form>
            <div *ngIf="1 || app?.share">
                <app-sharing type="chatBot" [share]="chatBot.share" [linkURL]="getMyBotLink()"
                    (updateShare)="outputEventShare($event) "></app-sharing>
            </div>

        </ng-template>
    </p-accordionTab>
</p-accordion>

<div *ngIf="accessValid">
    <div *ngIf="chatBot.conversation.length">
        <div *ngFor="let msg of chatBot.conversation; let i = index;">
            <div *ngIf="msg.role == 'user'" style="font-size: 20px; margin: 10px; text-align: center; color:blue">
                {{msg.content}}<br>
            </div>
            <div *ngIf="msg.role != 'user'" style="font-size: 20px; margin: 10px; text-align: left; color:rgb(0, 0, 0)">
                <div [innerHtml]="getResponseText(msg.content)"></div>

            </div>
        </div>
    </div>
    <div *ngIf="!waitingForAnswer && (access=='Editor' || access=='Admin')">
        <app-form formName="masterChatBot" [autoSubmit]="false" [formInput]="chatBot" (submitEvent)="outputEvent($event)"></app-form>
    </div>
    <div *ngIf="waitingForAnswer" style="text-align: center; font-size: 23px;">We are waiting for the answer...</div>
</div>