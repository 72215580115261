<div class="card__img-container"><img src="https://tse2.mm.bing.net/th?id=OIP.32P28CvncblTGtzBEWhJTgHaH_&pid=Api"
        alt="">
</div>
<div class="card__content">
    <div>
        <h2 class="card__title">{{node.title}}</h2>


        <div style="text-align: center"> Days & Hours of Operation</div>
        <div *ngIf="global.myCompany.daysHours.monday">
            Monday: Open from {{global.myCompany.daysHours.mondayOpen}} -
            {{global.myCompany.daysHours.mondayClose}}
        </div>
        <div *ngIf="global.myCompany.daysHours.tuesday">
            Tuesday: Open from {{global.myCompany.daysHours.tuesdayOpen}} -
            {{global.myCompany.daysHours.tuesdayClose}}
        </div>
        <div *ngIf="global.myCompany.daysHours.wednesday">
            Wednesday: Open from {{global.myCompany.daysHours.wednesdayOpen}} -
            {{global.myCompany.daysHours.wednesdayClose}}
        </div>
        <div *ngIf="global.myCompany.daysHours.thursday">
            Thursday: Open from {{global.myCompany.daysHours.thursdayOpen}} -
            {{global.myCompany.daysHours.thursdayClose}}
        </div>
        <div *ngIf="global.myCompany.daysHours.friday">
            Friday: Open from {{global.myCompany.daysHours.fridayOpen}} -
            {{global.myCompany.daysHours.fridayClose}}
        </div>
        <div *ngIf="global.myCompany.daysHours.saturday">
            Saturday: Open from {{global.myCompany.daysHours.saturdayOpen}} -
            {{global.myCompany.daysHours.saturdayClose}}
        </div>
        <div *ngIf="global.myCompany.daysHours.sunday">
            Sunday: Open from {{global.myCompany.daysHours.sundayOpen}} -
            {{global.myCompany.daysHours.sundayClose}}
        </div>
        
    </div>





</div>