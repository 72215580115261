<div fxLayout="column" fxLayoutAlign="center none">
    <div *ngIf="newChannel">
    <input  class="channel" placeholder="title" type="text" pInputText [(ngModel)]="channel.title">
    <input  class="channel" placeholder="Description" type="text" pInputText [(ngModel)]="channel.desc">
</div>
    <div *ngIf="!newChannel" class="new-channel" pRipple (click)="addChannel()">New Channel</div>
    <div *ngIf="newChannel" class="new-channel" pRipple (click)="saveChannel()">Save Channel</div>


</div>


<div *ngFor="let channel of global.authuser.channels; let i = index;">
    {{channel.title}}<br>
    {{channel.desc}}


    <div >
        Invite a follower
        <input data-lpignore="true"
            style="min-width: 15rem !important;  padding-left:4px !important; font-size: 1.4rem; margin-right:4px !important;"
            type="email" pInputText placeholder="email" [(ngModel)]="email">

        <div *ngIf="!inputCreds" class="command" style=" min-height: 100%;" (click)="sendInvite(channel)">
            Invite
        </div>
</div>

