<div fxLayout="row" fxLayoutAlign="space-between center" class="top-nav-bar">
    <div fxLayout="row" fxLayoutAlign="start center">
        <button type="button" mat-button (click)="drawer.toggle()"><span class="material-icons">
                menu
            </span></button>

        <div class="left-parent" (click)="navigateBack()">
            <span class="material-icons">
                arrow_back
            </span>
        </div>
    </div>
    <div>
        <img class="image-logo" src={{myCompany.logoImg}} alt="Photo of" (click)="navigateCrumb()">

    </div>


    <button type="button" mat-button><span class="material-icons">
            shopping_cart
        </span>{{0}}</button>


</div>


<div class="container">
    <div autosize>
        <div #drawer class="example-sidenav" mode="side">
            <div *ngIf="user.creds">
                <button class="full-width" mat-button (click)="SwitchAdmin()">Back to Store Admin</button>

            </div>
         
            <button class="full-width" mat-button (click)="myPoints()">My Points</button>
            <button class="full-width" mat-button (click)="myOrders()">My Orders</button>


            <button class="full-width" mat-button (click)="logout()">Sign Out </button>

        </div>

        Your orders:

        <div *ngIf="orders">
           

        </div>


    </div>
</div>