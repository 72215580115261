<div class="layout" fxFlex fxLayout="column" fxLayoutAlign="center center">
    <p-accordion iconPos="end" styleClass="nomargins" expandIcon="false" collapseIcon="false" [multiple]="false">
        <p-accordionTab iconPos="end" *ngIf="!global.selectedTab || (global.selectedTab == 'basic')"
            [selected]="isExpanded('basic')" (selectedChange)="tabSelected($event,'basic')" headerStyleClass="headers">
            <ng-template pTemplate="header">

                <div class="user-feature" [style]="getStyle('basic')">Settings</div>


            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('basic')" fxLayout="row wrap" fxLayoutAlign="center center">

                    <!--
                <div class="action-button3" style="cursor: pointer; background-color:rgb(187, 242, 139)"
                    (click)="CloneContent(editProduct)">Clone this Content</div>
                <br>
                -->

                    <app-form formName="masterNodeSettings" [formInput]="editNode" [autoSubmit]="false"
                        (submitEvent)="outputEvent($event)"></app-form>

                </div>
            </ng-template>
        </p-accordionTab>


        <p-accordionTab iconPos="end" *ngIf="!global.selectedTab || (global.selectedTab == 'media')"
            (selectedChange)="tabSelected($event,'media')" [selected]="isExpanded('media')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('media')">Media</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('media')" fxLayout="column" fxLayoutAlign="center center">
                    <app-media [appId]="appId" [editProduct]="editProduct" [editNode]="editNode"
                        [companyId]="global.myCompany.id"></app-media>
                </div>
            </ng-template>
        </p-accordionTab>
        <p-accordionTab iconPos="end" *ngIf="editNode.media &&  !global.selectedTab || (global.selectedTab == 'styling')"
            style="width:100%;" (selectedChange)="tabSelected($event,'styling')" [selected]="isExpanded('styling')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('styling')">Media Styling</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('styling')">

                    <div fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                        <div class="command" (click)="Clicked($event.target.innerHTML)">+Height</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">-Height</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Center</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Left</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Right</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Up</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Down</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Cover</div>
                        <div class="command" (click)="Clicked($event.target.innerHTML)">Contain</div>

                    </div>

                    <br>
                    <div class="action-button3" style="cursor: pointer; background-color:rgb(156, 243, 122)"
                        (click)="updateNodeStyle(editNode, $event)">Save Changes</div>

                    <!--
            <div class="yes-no">
                Styles&nbsp;
                <p-selectButton [options]="styles" [(ngModel)]="editProduct.style" optionLabel="option"
                    optionValue="option" (onChange)="ChangeStyles()">
                    <ng-template let-item>
                        {{item.option}}
                    </ng-template>
                </p-selectButton>
            </div>
            -->
                </div>
            </ng-template>
        </p-accordionTab>

        <p-accordionTab iconPos="end"
            *ngIf="(!global.selectedTab || global.selectedTab == 'text') && editNode.objectType!='Nav'"
            style="width:100%;" (selectedChange)="tabSelected($event,'text')" [selected]="isExpanded('text')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('text')">Text</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('text')">
                    <!--
                Body Content

                <textarea *ngIf="!editProduct.html" pInputTextarea [rows]="10" [cols]="30" [autoResize]="true"
                    id="fullDescription" #inputRef (click)="inputRef.select()" type="text" class="text-entry"
                    [(ngModel)]="editNode.fullDescription">
                </textarea>
-->
                    <div fxFlex fxLayout="column" fxLayoutAlign="space-between center">
                        <editor apiKey="kyiith4j5do4lxxcds8kr8siijwvuxpnqi2bf4klx9c48rxf"
                            [init]="{ height:600, plugins: 'lists link table code help wordcount'}"
                            modelEvents="change input nodechange undo redo" [(ngModel)]="editNode.html"></editor>

                        <button style="width:100%; max-width:300px;" (click)="updateDecription(editNode)"
                            class="function" pButton pRipple type="button">
                            Save Edits
                        </button>

                    </div>
                </div>
            </ng-template>
        </p-accordionTab>
        

        <p-accordionTab iconPos="end"
            *ngIf="editNode.objectType.toLowerCase()=='product' && !global.selectedTab || (global.selectedTab == 'products')"
            (selectedChange)="tabSelected($event,'products')" [selected]="isExpanded('products')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('products')">Products</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('products')" fxFlex fxLayout="column"
                    fxLayoutAlign="center space-around">
                    <div *ngIf="!global.allTags">
                        Select products to include
                        <div *ngIf="global.allProducts">
                            <div *ngFor="let p of global.allProducts; let i = index;">

                                <button *ngIf="p.active" (click)="selectProduct(p)" class="function" pButton pRipple
                                    type="button">
                                    Select {{p.title}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:30%">
                        <div *ngIf="editNode.productTags">
                            Tags assigned:
                        </div>
                        <div *ngIf="editNode.productTags" fxFlex fxLayout="row wrap" fxLayoutAlign="start start">
                            <div *ngFor="let d of editNode.productTags; let i = index;">
                                <button (click)="removeTag($event, d)" class="pretty-postag" pButton pRipple
                                    type="button">
                                    {{d+"("+getTagCount(d)+")"}}
                                </button>


                            </div>
                        </div>
                    </div>
                    <div style="width:70%" fxLayout="column" fxLayoutAlign="none none">
                        Tags not assigned:
                        <!-- TAGS AVAILABLE -->
                        <div *ngIf="global.allTags" fxFlex fxLayout="row wrap" fxLayoutAlign="none space-between">
                            <div *ngFor="let t of global.allTags; let i = index;">
                                <div *ngIf="tagFilter(t)">
                                    <div
                                        *ngIf="!editNode.productTags || (editNode.productTags && !editNode.productTags.includes(t))">
                                        <button (click)="addProductTag($event, t)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            {{t+"("+global.allTagsCount[i]+")"}}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-accordionTab>

        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='form') && !global.selectedTab || (global.selectedTab == 'Form')"
            (selectedChange)="tabSelected($event,'Form')" [selected]="isExpanded('Form')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('Data')">Form</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="global.allForms" class="user-feature" [style]="getStyle('Form')" fxFlex fxLayout="row wrap"
                    fxLayoutAlign="center center">

                    <div *ngFor="let f of global.allForms; let i = index;">

                        <button (click)="selectForm(i)" class="function" style="margin:8px;" pButton pRipple
                            type="button">
                            {{i}}: {{f.name}}
                        </button>


                    </div>


                </div>
            </ng-template>
        </p-accordionTab>


        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='app') && !global.selectedTab || (global.selectedTab == 'App')"
            (selectedChange)="tabSelected($event,'App')" [selected]="isExpanded('App')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('App')">App to embed</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="global.publicApps" class="user-feature" [style]="getStyle('Node')" fxFlex fxLayout="row wrap"
                    fxLayoutAlign="center center">
                    <div *ngFor="let n of global.publicApps; let i = index;">
                        <button (click)="selectApp(n)" class="function" style="margin:8px;" pButton pRipple
                            type="button">
                            {{i}}: {{n.name}}
                        </button>
                    </div>
                </div>
            </ng-template>
        </p-accordionTab>



        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='nodecopy') && !global.selectedTab || (global.selectedTab == 'Node')"
            (selectedChange)="tabSelected($event,'Node')" [selected]="isExpanded('Node')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('Data')">Node to Copy</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="global.appNodes[appId].nodes" class="user-feature" [style]="getStyle('Node')" fxFlex fxLayout="row wrap"
                    fxLayoutAlign="center center">
                    <div *ngFor="let n of global.appNodes[appId].nodes; let i = index;">
                        <button (click)="selectNode(i)" class="function" style="margin:8px;" pButton pRipple
                            type="button">
                            {{i}}: {{n.title}}
                        </button>
                    </div>
                </div>
            </ng-template>
        </p-accordionTab>

        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='mediagroup') && !global.selectedTab || (global.selectedTab == 'Mediagroup')"
            (selectedChange)="tabSelected($event,'Mediagroup')" [selected]="isExpanded('Mediagroup')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('Mediagroup')">Media group</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="global.mediaGroups" class="user-feature" [style]="getStyle('Mediagroup')">

                    <div *ngFor="let f of global.mediaGroups; let i = index;">

                        <button (click)="selectMediaGroup(i)" class="function" style="margin:8px;" pButton pRipple
                            type="button">
                            {{i}}: {{f.name}}
                        </button>

                    </div>

                </div>
            </ng-template>
        </p-accordionTab>

        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='tabList') && !global.selectedTab || (global.selectedTab == 'tabList')"
            (selectedChange)="tabSelected($event,'tabList')" [selected]="isExpanded('tabList')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('tabList')">Containers</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="editNode.containers" class="user-feature" [style]="getStyle('tabList')" fxLayout="column"
                    fxLayoutAlign="none center">

                    <div *ngFor="let f of editNode.containers; let i = index;" fxLayout="column">

                        <button (click)="selectTab(i)" class="function" style="margin:8px;" pButton pRipple
                            type="button">
                            {{i}}: {{f.title}}
                        </button>


                    </div>
                    <button (click)="addContainer($event)" class="create-form" pButton pRipple type="button">
                        Add new container
                    </button>

                </div>
            </ng-template>
        </p-accordionTab>



        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='reviews') && !global.selectedTab || (global.selectedTab == 'reviews')"
            (selectedChange)="tabSelected($event,'reviews')" [selected]="isExpanded('reviews')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('reviews')">Reviews</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('reviews')">

                    <app-form formName="masterDisplayReviewSettings" [formInput]="editNode.reviewSettings"
                        [autoSubmit]="false" (submitEvent)="reviewSettingsOutput($event)"></app-form>

                </div>
            </ng-template>
        </p-accordionTab>





        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='dataview') && !global.selectedTab || (global.selectedTab == 'Data')"
            (selectedChange)="tabSelected($event,'Data')" [selected]="isExpanded('Data')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('Data')">Reports</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div *ngIf="global.allReports" class="user-feature" [style]="getStyle('Data')">

                    <div *ngFor="let f of global.allReports; let i = index;">

                        <button (click)="selectReport(i)" class="function" style="margin:8px;" pButton pRipple
                            type="button">
                            {{i}}: {{f.title}}
                        </button>

                    </div>
                    <button (click)="reportStyle('pricing')" class="function" style="margin:8px;" pButton pRipple
                        type="button">Pricing chart</button>

                </div>
            </ng-template>
        </p-accordionTab>

        <p-accordionTab iconPos="end"
        *ngIf="(editNode.objectType=='query') && !global.selectedTab || (global.selectedTab == 'query')"
        (selectedChange)="tabSelected($event,'query')" [selected]="isExpanded('query')">
        <ng-template pTemplate="header">
            <div class="user-feature" [style]="getStyle('query')">Queries</div>

        </ng-template>
        <ng-template pTemplate="content">
            <app-form formName="masterSelectQueries" [formInput]="editNode" [autoSubmit]="false"
            (submitEvent)="outputEvent($event)"></app-form>

            <!--

            <div *ngIf="global.allQueries" class="user-feature" [style]="getStyle('query')">

                <div *ngFor="let f of global.allQueries; let i = index;">

                    <button (click)="selectQuery(i)" class="function" style="margin:8px;" pButton pRipple
                        type="button">
                        {{i}}: {{f.title}}
                    </button>
                </div>
            </div>
            -->
        </ng-template>
    </p-accordionTab>




<!--
        <p-accordionTab iconPos="end"
            *ngIf="(editNode.objectType=='dashboard' || editNode.objectType=='databit') &&  (!global.selectedTab || global.selectedTab == 'dashboard')"
            (selectedChange)="tabSelected($event,'dashboard')" [selected]="isExpanded('dashboard')">
            <ng-template pTemplate="header">
                <div class="user-feature" [style]="getStyle('dashboard')">Dashboard</div>

            </ng-template>
            <ng-template pTemplate="content">
                <div class="user-feature" [style]="getStyle('dashboard')">
                    <div class="yes-no" fxLayout="row wrap" fxLayoutAlign=" center center">
                        Included Data Gems<br>
                        <div *ngFor="let d of editNode.dataGems; let i = index;">
                            <div class="data-gem-list" (click)="changeDataGem(editNode, d)" pRipple>
                                {{d.name}}
                            </div>
                        </div>
                        Available Data Gems<br>
                        <div *ngFor="let d of dataGemList; let i = index;">
                            <div *ngIf="!gemIncluded(editNode, d)" class="data-gem-list"
                                (click)="changeDataGem(editNode, d)" pRipple>
                                {{d.name}}
                            </div>
                        </div>
                    </div>

                </div>
            </ng-template>
        </p-accordionTab>
    -->

    </p-accordion>
</div>