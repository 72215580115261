import { AfterViewInit, AfterContentInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Company, Navigate, Reviews, Review, Coupon } from '../../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../menu/nav.service';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../../user-interface';
import { RegisterService } from '../../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { ReviewsService } from '../../reviews.service'

@Component({
  selector: 'app-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.css', '../../../common.scss']
})
export class ReviewCardComponent implements OnInit {
  @Input() p: Review;
  @Input() tags: string[];

  lastReview: number = -1;

  constructor(public global: Globals, private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute, public nav: NavService,
    public reviewService: ReviewsService) {

    }

    ngOnInit(): void {
      if (!this.p) this.p = this.global.publishedReviews[0];
    //  console.log("Review card: ", this.p)
    }

    updateReview() {
      console.log ("Next review ",this.p);

      var found = this.global.publishedReviews.findIndex((f, index) =>
        f.review_photos?.length > 0 && f.rating == '5'
        && index > this.lastReview)
  
      if (found != -1) {
        this.p = this.global.publishedReviews[found];
        this.p.style = 'polaroid';
        this.lastReview = found;
        console.log ("Next review ",this.p);
  
      }
  
    }

    getFilteredText(p) {
      var str=p.review_text;
      if (!this.tags || this.tags.length==0) return str;
  
      this.tags.forEach(function(filter){
        str = str.replace(filter,'<strong>'+filter+'</strong>')
      })
  
      return str;
  
    }

    autoTag(e) {}
    useful(t) {}

    getPhotoStyle(r: Review) {
     // if (this.global.getScreenWidth )

     return "max-width: 100vw; max-height:80vh";

    }


}
