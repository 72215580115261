import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { FormService } from '../form.service';
import { VIPService } from '../VIP.service';



@Component({
  selector: 'app-field-edit',
  templateUrl: './field-edit.component.html',
  styleUrls: ['./field-edit.component.css', '../../common.scss']
})
export class FieldEditComponent implements OnInit {


  @Input() fieldName: string;
  @Input() function: string;
  @Input() form: Form;
  @Input() formIndex: number;


  formEditList: string[];

  addingField: boolean = false;
  addingForm: boolean = false;

  fieldSettings: ContainerSettings;

  currentOption: string;

  constructor(public global: Globals, public formService: FormService, public VIPservice: VIPService) {

  }

  ngOnInit(): void {
    var self = this;

    this.fieldSettings = { width: "30%", backgroundColor: "#44949a" }

    if (!self.formIndex) { // Let's get the index
      self.global.allForms.forEach(function (form, index) {
        if (form.name == self.form.name)
          self.formIndex = index;
      })

    }
    console.log("FIELD EDITING: ", self.formIndex, self.global.allForms[self.formIndex])



    // Set up fields
    //self.global.fields = [];
    //self.global.allForms[self.global.formIndex].fields.forEach(function (item) {
    //  self.global.fields.push(item)
    // })


    /*
        this.formEditList = ["Fields", "Logic", "Sunmissions", "Analytics"];
    
        if (this.fieldName) {
          // Find store name in follows and load as store
          const index = self.global.allForms.findIndex(function (item) {
            if (item.name == self.fieldName)
              return true;
          });
          if (index != -1) {
    
            self.form = self.global.allForms[index];
          }
        }
        */


  }

  fieldSelected(event) {
    var self = this;

    self.global.editField = event;
    console.log("EDIT THE FIELD: ", event);
  }

  moveLeft(field, i) {
    moveItem(this.global.allForms[this.formIndex].fields, i, i - 1);
    console.log(this.global.allForms[this.formIndex].fields)
    this.formService.updateField(this.global.allForms[this.formIndex])


  }
  moveRight(field, i) {

    moveItem(this.global.allForms[this.formIndex].fields, i, i + 1);
    console.log(this.global.allForms[this.formIndex].fields)
    this.formService.updateField(this.global.allForms[this.formIndex])
  }

  done() {
    this.global.editField = null;
  }

  tabOpened(event) {
    var data = <Container>event;

    this.currentOption = this.formEditList[data.index]

    console.log("FORM EDITOR ", data.node, data.index)
  }

  addField() {
    this.addingField = true;
  }
  closeAddField() {
    this.addingField = false;
  }

  addFieldToForm(event, form: Form, field: Field) {
    var self = this;

    event.stopImmediatePropagation();

    // let's ensure unique title
    var digit = 2;
    var unique = true;
    var title = field.title;

    field.position = null;

    var newField = { ...field };
    console.log("adding field ", form)
    do {
      const exists = form.fields.some(function (post) {
        if (post?.title == title)
          return true;
      });
      if (exists) {
        title = field.title + " " + digit.toString();
        unique = false;
        digit++;
      }
      else unique = true;
    }
    while (!unique)

    newField.title = title;
    if (!newField.label) newField.label = 'label'

    /********* Get UID */
    newField.id = self.formService.createUID();

    if (newField.type == 'canvas') {
      if (!newField.binding) {
        console.log("INSTANTIATING NEW SCENE")
        newField.binding = <string>self.VIPservice.newScene(self.newSceneCallback, self);
      }
    }

    console.log("Form & field add ", form, newField, self.formIndex)
    //  form.fields.push(field);

    if (self.formIndex>=0) {//Yikes!
      self.global.allForms[self.formIndex].fields.push(newField)
      if (!self.global.allForms[self.formIndex]?.report) {
        self.createReport(self.global.allForms[self.formIndex])
      }

      self.global.allForms[self.formIndex].report.fieldSource.includedFields.push(newField.title)
      self.updateForm(self.global.allForms[self.formIndex]);
    }
    //  else {
    //    self.global.allForms[self.formIndex].fields.push(newField)
    //    self.updateForm(self.global.allForms[self.formIndex]);
    //  }


  }
  createReport(form: Form) {
    var self = this;

    self.form.report = {
      allowEdits: true,
      title: "Database",
      userId: this.global.authuser.uid,
      fieldSource: { form: this.form.name, formId: this.form.id, includedFields: [] }
    }
    this.form.fields.forEach(function (field) {
      self.form.report.fieldSource.includedFields.push(field.title)
    })
  }

  newSceneCallback(self: this) {

    console.log("got new scene ");

  }

  updateForm(event: Form) {
    var self = this;
    var id = event.formId;
    var db = firebase.firestore();

    if (!id) id = event.id;


    event.fields.forEach(function (field) {
      if (field.safeHtml) field.safeHtml = undefined;
    })
    // STRIP REMOVE NULLS all empty fields
    event = removeUndefined(event)
    console.log("After strip undefined ", event)

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(id)

    docRef.update(event).then(() => {
      console.log("form updated");

      // Hack find and update global
      const index = self.global.allForms.findIndex(function (post) {
        if (post.id == event.id)
          return true;
      });
      if (index != -1) {
        self.global.allForms[index] = { ...event }
        console.log("Bull: ", self.global.allForms[index])
      }

      // Set up fields
      //  self.list = [];
      //  self.global.allForms[self.global.formIndex].fields.forEach(function (item) {
      //    self.list.push(item.title)
      // })


    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

}
function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}

function moveItem(data: Field[], from, to) {
  // remove `from` item and store it
  var f = data.splice(from, 1)[0];
  // insert stored item into position `to`
  data.splice(to, 0, f);
  console.log("FIELDS: ", data, from, to)
}
