<div  >
   <!-- We'll have settings later  
    <p-accordion *ngIf="editing" expandIcon="false" collapseIcon="false" [multiple]="false">
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header">
                <div class="settings">Settings</div>
            </ng-template>
            <ng-template pTemplate="content">
    
                <app-form formName="masterNewsSettings" [formInput]="global.authuser.newsSettings" [autoSubmit]="false"
                    (submitEvent)="outputEvent($event)"></app-form>
            </ng-template>
        </p-accordionTab>
    </p-accordion>

    -->

    <div *ngIf="!videoAllowed">
           
        <button class="file-upload-button">
            <input type="file" name="uploaded_file"
            #fileInput
           
            accept="image/png,image/jpeg,image/heic"
            (change)="newUpload($event)"
            />
        </button>

    </div>
    <div *ngIf="videoAllowed">

        <button class="file-upload-button">
            <input type="file"
            #fileInput
            capture="environment" accept="image/*;video/*"
            (change)="upload($event)"
            />
        </button>

    </div>
    

    <div *ngIf="media && media.length && media[0].url">
        <img #imageDiv (load)="loadImg($event)" style="max-height: 80vh; max-width: 100%;" src={{media[0].url}} />
        
        <p-accordion *ngIf="media && media.length && media[0].url" expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab iconPos="end">
                <ng-template pTemplate="header">
                    <div class="settings">Other Versions</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <img *ngIf="media[0]?.otherSizes['large']"  style="max-height: 30vh; max-width: 100%;" src={{media[0].largeUrl}} />
                    <img *ngIf="media[0]?.otherSizes['med']"  style="max-height: 30vh; max-width: 100%;" src={{media[0].medUrl}} />
                    <img *ngIf="media[0]?.otherSizes['small']" style="max-height: 30vh; max-width: 100%;" src={{media[0].smallUrl}} />
                    <img *ngIf="media[0]?.otherSizes['thumb']" style="max-height: 30vh; max-width: 100%;" src={{media[0].thumbnailUrl}} />
                    
                </ng-template>
            </p-accordionTab>
        </p-accordion>




    
        <div class="image-function">Remove Background</div>
        <div class="image-function">Crop Image</div>
        <div class="image-function">Extract Area</div>
    
    </div>


</div>
