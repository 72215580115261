import { Component, OnInit, Input } from '@angular/core';

export interface Results {
  forecast: Forecast;

}

export interface Forecast {
  forecastday: ForecastDay[];

}

export interface ForecastDay {
  date: string; //"2023-01-14"
  date_epoch: string; //1673654400
  day: Day;
  hour: Hour[];
}
export interface Day {
  maxtemp_c: number; //14.2
  maxtemp_f: number; //57.6
  mintemp_c: number; //6.2
  mintemp_f: number; //43.2
  avgtemp_c: number; //10.2
  avgtemp_f: number; //50.4
  maxwind_mph: number; //16.3
  maxwind_kph: number; //26.3
  totalprecip_mm: number; //15.5
  totalprecip_in: number; //0.61
  totalsnow_cm: number; //0
  avgvis_km: number; //9.3
  avgvis_miles: number; //5
  avghumidity: number; //68
  daily_will_it_rain: number; //1
  daily_chance_of_rain: number; //85
  daily_will_it_snow: number; // 0
  daily_chance_of_snow: number; //0
  condition: Condition;
}

export interface Hour {
  chance_of_rain: number; //  0
  chance_of_snow: number; //  0
  cloud: number; //  52 % coverage
  condition: Condition;
  dewpoint_c: number; //6.6
  dewpoint_f: number; // 43.9
  feelslike_c: number;// 9.6
  feelslike_f: number;// 49.3
  gust_kph: number;// 34.2
  gust_mph: number;// 21.3
  heatindex_c: number;// 12.3
  heatindex_f: number;// 54.1
  humidity: number;// 68
  is_day: number;// 1
  precip_in: number;//  0
  precip_mm: number;// 0
  pressure_in: number;// 29.88
  pressure_mb: number;// 1012
  temp_c: number;// 12.3
  temp_f: number;// 54.1
  time: string; // "2023-01-15 15:00"
  time_epoch: number;// 1673823600
  uv: number;// 4
  vis_km: number;// 10
  vis_miles: number;// 6
  will_it_rain: number;// 0
  will_it_snow: number;// 0
  wind_degree: number;// 276
  wind_dir: string;// "W"
  wind_kph: number;// 29.5
  wind_mph: number;// 18.3
  windchill_c: number;// 9.6
  windchill_f: number;//49.3

  windRangeMin?: number;
  windRangeMax?: number;


}

// Condition
//[wind: 24 data points] - plus gusts - and direction

//[rain: 24 data points:precepitation]
//[snow: 24 ]
//[Temp: 24]

// per hour 12 pixels wide (bar is 8 pixels: m:2); height: data mapped to: 36 pixels tall



export interface Condition {
  text?: string;
  icon?: string;
  astro: string;//??
  hour: number; //??
}





@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})

export class WeatherComponent implements OnInit {

  @Input() location: string;
  @Input() overFlow: boolean = false;
  @Input() width: number;
  @Input() height: number;

  forecast: ForecastDay[];
  rainIndex: number = 0;

  rainMovies: string[] = ["https://suncraftcache.sfo2.cdn.digitaloceanspaces.com/Rain-1.MOV",
    "https://suncraftcache.sfo2.cdn.digitaloceanspaces.com/Rain-3.MOV",
    "https://suncraftcache.sfo2.cdn.digitaloceanspaces.com/Rain-2.MOV"
  ]


  ngOnInit(): void {
    this.forecast = [];


    if (this.location)
      this.GetWeather(this.location);


  }

  GetMyRainMovie(i) {


    //    this.rainIndex++;
    //    if (this.rainIndex >= this.rainMovies.length) this.rainIndex = 0;


    var x = Math.floor(Math.random() * 3);

    return this.rainMovies[i];

  }

  getMyWindyMovie (i) {
    return "https://suncraftcache.sfo2.cdn.digitaloceanspaces.com/windy-day.MOV";
  }

  onWeatherOpen(e) {

  }

  getmyWind(h: Hour, max) {
    if (h.wind_mph == 0) return "";
    const scale = (number, [inMin, inMax], [outMin, outMax]) => {
      // if you need an integer value use Math.floor or Math.ceil here
      return (number - inMin) / (inMax - inMin) * (outMax - outMin) + outMin;
    }
    var num = h.wind_mph;

    //    if (h.windRangeMax==null) { // Calculate the wind ranges
    //      this.GetWindRange ();

    //    }


    var v = scale(num, [0, max], [0, 100]);
    return v.toString() + "%";
  }

  getWindColor(h: Hour) {

    if (h.wind_mph < 10) return "000#";
    if (h.wind_mph < 15) return "rgb(255, 254, 186)";
    if (h.wind_mph < 20) return "rgb(255, 215, 186)";
    if (h.wind_mph < 25) return "rgb(255, 192, 186)";
    if (h.wind_mph < 30) return "rgb(255, 164, 108)";

    return "rgb(255, 55, 35)";
  }
  /*
  GetWindRange () {
    this.forecast.forEach (function (day){
      var max = 0;
      day.hour.forEach (function(hour) {
        if (hour.wind_mph > max)
          max = hour.wind_mph;
      })
      day.hour.forEach (function(hour) {
        hour.windRangeMin = 0;
        hour.windRangeMax = max;
      
      })
  

    })


  }
  */
  getmyRain(h: Hour) {
    if (h.chance_of_rain == 0) return "";
    return (h.chance_of_rain).toString() + "%";
  }

  lastHour = 0;

  getRainPercent(h: Hour, i: number): string {

    var p = h.chance_of_rain; // as percentage;
    if (p == 0) return "";

    if (i > 0 && p == this.lastHour) return "";

    this.lastHour = p;
    return p.toString() + '%';

  }

  getTime(i) {
    if (i == 0) return "12";
    if (i == 1) return "1a";
    if (i == 2) return "2a";
    if (i == 3) return "3a";
    if (i == 4) return "4a";
    if (i == 5) return "5a";
    if (i == 6) return "6a";
    if (i == 7) return "7a";
    if (i == 8) return "8a";
    if (i == 9) return "9a";
    if (i == 10) return "10";
    if (i == 11) return "11";
    if (i == 12) return "12";
    if (i == 13) return "1p";
    if (i == 14) return "2p";
    if (i == 15) return "3p";
    if (i == 16) return "4p";
    if (i == 17) return "5p";
    if (i == 18) return "6p";
    if (i == 19) return "7p";
    if (i == 20) return "8p";
    if (i == 21) return "9p";
    if (i == 22) return "10";
    if (i == 23) return "11";



  }

  getmyRainAmount(h: Hour) {

    const scale = (number, [inMin, inMax], [outMin, outMax]) => {
      // if you need an integer value use Math.floor or Math.ceil here
      return (number - inMin) / (inMax - inMin) * (outMax - outMin) + outMin;
    }
    var num = h.precip_in;

    var v = scale(num, [0, 2], [0, 100]);
    return v.toString() + "%";
  }

  getMyTemp(h: Hour, min, max) {

    const scale = (number, [inMin, inMax], [outMin, outMax]) => {
      // if you need an integer value use Math.floor or Math.ceil here
      return (number - inMin) / (inMax - inMin) * (outMax - outMin) + outMin;
    }
    var num = h.temp_f;

    var v = scale(num, [min, max], [0, 100]);
    return v.toString() + "%";
  }

  getMyStyle () {
    if (this.overFlow)
     var style="height: 100%;  overflow: visible; z-index: 200000; position: absolute; left: 0px; top: 0px;"
    else
      var style="max-height: 100%; max-width: 100%; overflow: scroll; position: relative;"


  return style;
  }

  toggleExpand (tf) {
    this.overFlow = tf;
  }
 
  GetWeather(location: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'weatherapi-com.p.rapidapi.com'
      }
    };

    var search = 'https://weatherapi-com.p.rapidapi.com/forecast.json?q=' + location + '&days=3'
    console.log("Getting weather ", location, search);

    fetch(search, options)
      .then(response => response.json())
      .then(response => {

        var data = <Forecast>response.forecast;
        self.forecast = Object.assign(self.forecast, <ForecastDay[]>data.forecastday);
        console.log(self.forecast, response);

      })
      .catch(err => console.error(err));

  }

}
