<div *ngIf="form && !form.hideForm" class="layout" id="formtop" style="height:max-content"
[style.width]="getFormWidth()"  fxFlex fxLayout="column" fxLayoutAlign="center center" [style]="formStyle()">

    <div *ngIf="form.loginRequired && !global.authuser">
        <p style="text-align: center;">Please login or register to access form</p>
        <!--    <app-form [formName]="'master-get-email'"></app-form> -->
    </div>
    <div *ngIf="form.validEmailRequired && (global.authuser && !global.authuser.emailVerified)">
        Please validate email before continuing
    </div>
    <div *ngIf="checkRequirements()" fxFlex [fxLayout]="getLayout()" fxLayoutAlign="center center"
        [style.width]="getFormWidth()" style="max-width: 100vw;">
        <div *ngFor="let field of form.fields; let i = index" [style]="getFieldWidth(field)">
            <div *ngIf="checkGroups(field)">
                <div *ngIf="field.newLine && field.display" style="margin-top: 1px; margin-bottom:1px;">
                    <div style="background-color: rgb(229, 225, 225); height:1px;">
                    </div>
                    <div *ngIf="field.label" style="margin:12px; text-align: center;">{{field.label}}</div>
                </div>
              

                <div *ngIf="!field.newLine && field.type!='groupAdd'">
                    <app-field [appName]="appName" [themeId]="form.themeId" [field]="field"
                        [showSubmit]="form.showSubmit" [editMode]="editData" (outputs)="output($event)"
                        (objectEvents)="outputEvent($event)"></app-field>
                </div>

                <div *ngIf="field.type=='groupAdd'" style="margin: 0px;">
                    <div fxFlex fxLayout="column" fxLayoutAlign="center center">

                        <button [style.width]="field?.width" [style.height]="field?.height" pButton pRipple
                            type="button" style="margin:6px;" (click)="groupAdd(field.fieldGroup)">
                            <span style="text-align: center; padding:4px;">Add Another</span>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div *ngIf="!form.loginRequired || global.authuser" fxLayout="column" fxLayoutAlign="center center">
        <div style="text-align: center; font-size: 17px;">{{feedback}}</div>

        <button *ngIf="checkRequirements() && editData && form.showSubmit && form.showSubmit==true" pRipple
            class="submit" (click)="submitForm()">Submit</button>
    </div>

    <div>

    </div>

    <div *ngIf="media">
        <app-media-view [media]="media"></app-media-view>
    </div>

</div>
<div *ngIf="success && form.hideForm" class="layout" style="margin: 12px;text-align: center;">
    <h2>{{success}}</h2>
</div>
<div *ngIf="form && form.hideForm && form.submitMsg" class="layout" style="height:max-content"
    [style.width]="getFormWidth()" fxFlex fxLayout="column" fxLayoutAlign="center center">

    <div [innerHtml]="form.safeHtml"></div>
</div>