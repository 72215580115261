
<div *ngIf="checkActive () && checkSharing()" style="width:100%!important; margin: 0px;">
    <div *ngIf="checkParent(node?.parent, node)" style=" margin: 0px;">
        <div *ngIf="isNewObject(node)">

            <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-between space-between">
                <div *ngFor="let o of nodeService.nodeList; let i = index;">

                    <button pRipple pButton type="button" class="node-button" [style]="getNodeStyle(o)"
                        (click)="nodeService.changeObject($event, o, node, appId)">
                        {{o.title}}
                    </button>
                </div>

                <!--
                <div *ngFor="let o of nodeService.objectList; let i = index;">
                    <button pRipple pButton type="button" style="padding:12px; margin:12px;"
                        (click)="nodeService.changeObject($event, o, node, appId)">
                        {{o.label}}
                    </button>
                </div>
                -->

            </div>

        </div>
      
        <div *ngIf="isEmpty(node)" [style.background-color]="getBackgroundColor(node)" [style.width.px]="getWidth(node)"
            style="margin:0px; min-height:100px; height: 100%;" fxLayout="column" fxLayoutAlign="center center">
            Add Content
        </div>

        <div *ngIf="checkActive () && node.objectType != 'nav'">
            <div *ngIf="node.format && node.objectType=='content'">
                <app-nodeview [node]="node"></app-nodeview>
            </div>

            <div *ngIf="!node.format || node.objectType!='content' ">
            <!--    <div *ngFor="let g of node.galleryData; let i = index;" class="content"
                    [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                    [style.width.px]="getWidth(node)">
                    <div [ngStyle]="{ 'width': getMediaWidth()}">

                        <div *ngIf="g" style="width:100!important">
                            <app-gallery [gallery]="g"></app-gallery>
                        </div>
                    </div>
                </div>-->

                <div *ngFor="let m of node.media; let i = index;" class="content"
                    [style.background-color]="getBackgroundColor(node)" [style.width.px]="getWidth(node)">
                    <div [ngStyle]="{ 'width': getMediaWidth()}" [style.height]="getMediaHeight(node)">
                        <div *ngIf="m.fileType=='audio/mpeg'">
                            <audio id="audio" controls (click)="setFullScreen(m)">
                                <source src={{m.url}} id="src" />
                            </audio>
                        </div>

                        <div *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' 
                || m.fileType=='video/quicktime')" class="video-wrapper">

                            <video class="video" style="max-width: 100%; max-height:70vh" playsinline webkit-playsinline
                                autoplay loop [muted]="'muted'" id="video{{i}}">
                                <source src="{{m.url}}" type="video/mp4">
                            </video>

                            <span *ngIf="node?.showControls && node?.showControls==true" class="center-me-video2">

                                <div fxFlex fxLayout="row" fxLayoutAlign="center none">
                                    <button style="margin-left:12px;margin-right:12px;" pButton pRipple
                                        (click)="changeAudio('video',i)">{{audioLabel('video'+i)}}</button>

                                    <div *ngIf="paused">
                                        <button style="margin-left:12px;margin-right:12px;"
                                            (click)="playVideo('video', i)" class="pretty-postag" pButton pRipple
                                            type="button">Play
                                            Video</button>
                                    </div>
                                    <div *ngIf="!paused">

                                        <button style="margin-left:12px;margin-right:12px;"
                                            (click)="pauseVideo('video', i)" class="pretty-postag" pButton pRipple
                                            type="button">Pause
                                            Video</button>
                                    </div>
                                </div>

                            </span>

                        </div>

                        <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || 
                m.fileType=='image/png' || m.fileType=='photo'" class="media-container">
                            <div fxLayout="column" fxLayoutAlign="center none">

                                <img (click)="setFullScreen(m)" class="media" [style]="getImageStyle(node)"
                                    src="{{m.url}}" />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ------------------- NAV ----------------------------->
        <div *ngIf="checkActive () && node.objectType=='nav'" (click)="navTo(node)" [style.width.px]="getWidth(node)"
            [style]="getNavHeight(node)">

            <div *ngIf="node.media && node.media.length && (node.media[0].fileType == 'video/mp4'|| node.media[0].fileType=='video/quicktime')"
                class="video-wrapper">
                <video playsinline webkit-playsinline autoplay [muted]="'muted'" loop id="myVideo">
                    <source src="{{node.media[0].url}}" type="video/mp4">
                </video>
            </div>
            <div *ngIf="node.media && node.media.length && (node.media[0].fileType == 'video/mp4'|| node.media[0].fileType=='video/quicktime')"
                class="banner">
                <span class="center-me">
                    <div>{{node.title}}</div>
                </span>

            </div>

            <div *ngIf="node.mediaGroupId">
                <app-media-groups mode="viewonly" [userSettings]="false"
                    [groupId]="node.mediaGroupId"></app-media-groups>
            </div>

            <!-- IMAGE NAVIGATION BACKGROUND -->
            <div *ngIf="!node.media || node.media.length==0 || (node.media[0].fileType != 'video/mp4' && node.media[0].fileType!= 'video/quicktime')"
                class="banner" [style]="getBackImageStyle(node)">
                <span class="center-me">
                    <div>{{node.title}}</div>
                </span>

            </div>
            <!--
        <div *ngIf="node.title && node.objectType.toLowerCase()!='nav'" class="content"
            [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
            [style.width.px]="getWidth(node)">
            {{node.title}}
        </div>
-->
        </div>
        <div *ngIf="node.html && node.objectType!='bignote'" class="html-content"
            [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
            [style.width.px]="getWidth(node)">

            <div fxLayout="column" fxLayoutAlign="center center">
                <div style="max-width: 960px; margin: 4px;" [innerHTML]="node.safeHtml"></div>
            </div>

        </div>



        <div *ngIf="node.objectType=='product'" class="content" [style.background-color]="getBackgroundColor(node)"
            [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">
            <div *ngFor="let p of node.products; let i = index;">
                <app-view-item [appId]="appId" [p]="getProduct(p)"></app-view-item>
            </div>


            <div *ngFor="let p of global.allProducts; let i = index;">
                <app-view-item *ngIf="taggedProduct(node, p)" [appId]="appId" [p]="p"></app-view-item>
            </div>

        </div>


        <div *ngIf="node.objectType=='app' && node.appIds" class="content"
            [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
            [style.width.px]="getWidth(node)">

            <app-appMaker [appId]="node.appIds[0]" [headLess]="true"></app-appMaker>

        </div>




        <div *ngIf="node.objectType=='tabList'" class="content" [style.background-color]="getBackgroundColor(node)"
            [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

            <app-container [appId]="appId" [node]="node" [editing]="editing" [list]="node.containers" [style]="'List1'"
                [store]="store" (openTab)="openTab($event)"></app-container>

        </div>



        <div *ngIf="node.objectType" class="content" [style.background-color]="getBackgroundColor(node)"
            [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">
            <!--  {{node.title}} if showtitle -->

            <div *ngIf="node.objectType=='New Node'" class="content">
                Hey this is a new Node. Choose a type. Learn more...

            </div>


            <div *ngIf="node.objectType=='reserve'">

                <app-reserve-beta [theStore]="global.myCompany.store" [showUser]="false" [showHeader]="true"
                    [openOnly]="false" [getAllRes]="false">
                </app-reserve-beta>
            </div>
            <div *ngIf="global.myCompany && node.objectType=='reviews'" class="content">
                <div *ngIf="node?.reviewSettings?.view=='mediaOnly'">
                    <app-media [companyId]="global.myCompany.id" mediaSource="reviewMedia"></app-media>
                </div>
                <div *ngIf="node?.reviewSettings?.view!='mediaOnly'">
                    <app-reviews [reviewSettings]="node.reviewSettings" [store]="global.myCompany.store"></app-reviews>
                </div>
            </div>
            <div *ngIf="node.objectType=='getreview'" class="content">
                <app-review [store]="global.myCompany.store"></app-review>
            </div>

            <div *ngIf="node.objectType=='form' && node.formId && global.allForms" class="xxxcontent"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <div *ngFor="let form of node.formId; let i = index;">

                    <app-form [appName]="appName" [formId]="form" [node]="node" [formName]="node.formNames[i]"
                        [(formInput)]="node.formInput" (submitEvent)="formSubmitted($event)"
                        (objectEvents)="outputEvent($event)" [autoSubmit]="true"></app-form>
                </div>
            </div>

            <div *ngIf="node.objectType=='dataview' && node.reportIds && node.reportIds.length>0" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <div *ngIf="node.reportStyles && node.reportStyles.length>0 && global?.myCompany">
                    <app-reports [style]="node.reportStyles[0]" [userForm]="node.userForm" [editReport]="false"
                        [reportName]="node.reportNames[0]" [reportId]="node.reportIds[0]"></app-reports>
                </div>
                <div *ngIf="!node.reportStyles">
                    <app-reports [editReport]="false" [userForm]="node.userForm" [reportName]="node.reportNames[0]"
                        [reportId]="node.reportIds[0]"></app-reports>
                </div>
            </div>

            <div *ngIf="node.objectType=='query' && node.queryIds && node.queryIds.length>0" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">
                <div *ngIf="global?.myCompany">
                    <div *ngFor="let query of node.queryIds; let i = index;" style="width:100%" fxFlex
                        fxLayout="row wrap" fxLayoutAlign="center center">
                        <app-queries [queryId]="query"></app-queries>
                    </div>
                </div>
            </div>

            <div *ngIf="node.objectType=='chatBot'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">
                <div *ngIf="global?.myCompany">
                    <app-chatbots [autoSave]="true"></app-chatbots>
                </div>
            </div>

            <div *ngIf="node.objectType=='mediagroup' && global.mediaGroups && global.mediaGroups.length"
                class="content" [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <app-media-groups mode="viewonly" [groupId]="node.mediaGroupId"></app-media-groups>


            </div>

            <div *ngIf="node.objectType=='pos'" class="content">
                <app-pos></app-pos>
            </div>
            <div *ngIf="node.objectType=='orders'" class="content">
                <app-orders></app-orders>
            </div>

            <div *ngIf="node.objectType=='databit'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <div fxFlex style="width:100%" fxLayout="row wrap" fxLayoutAlign="space-between space-between">
                    <div *ngFor="let g of node.dataGems; let i = index;">
                        <div style="margin:10px;">
                            <div *ngIf="g.type=='currency'" class="dataGem">
                                {{g.name}}<br>
                                <span style="font-size: larger"> {{(g.value|currency)}}</span>
                            </div>
                            <div *ngIf="g.type=='number' || g.type=='string'" class="dataGem">
                                {{g.name+": "+(g.value)}}
                            </div>


                        </div>
                    </div>
                </div>
            </div>


            <div *ngIf="node.objectType=='map'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">
                <div *ngIf="global.myCompany && global.myCompany.long">
                    <app-maps></app-maps>
                </div>
            </div>

            <div *ngIf="node.objectType=='weather'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <app-weather [location]="node.location"></app-weather>

            </div>

            <div *ngIf="node.objectType=='chat'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <app-chat [userChat]="true"></app-chat>

            </div>

            <div *ngIf="node.objectType=='chatnow'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <app-chat [store]="global.myCompany.chatService" [user]=""></app-chat>


            </div>

            <div *ngIf="node.objectType=='review-card'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">


                <app-review-card (click)="updateCard(node)" [p]="node.review"></app-review-card>

            </div>



            <div *ngIf="node.objectType=='join'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <app-join></app-join>


            </div>

            <div *ngIf="node.objectType=='bignote'" class="bignote" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <div style="margin:auto; font-size:200%;" [innerHTML]="node.html"></div>



            </div>


            <div *ngIf="node.objectType=='media'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <app-media [companyId]="global.myCompany.id"></app-media>


            </div>

            <div *ngIf="node.objectType=='mediasearch'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                Search

            </div>

            <div *ngIf="node.objectType=='mediacapture'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <button class="file-upload-button">
                    <input type="file" #fileInput capture="environment" accept="image/*;video/*"
                        (change)="mediaService.newUpload($event)" />
                </button>
                <div *ngIf="mediaService.newMedia">
                    <img style="max-height: 400px; max-width: 100%;" src={{mediaService.newMedia.url}} />
                </div>

            </div>

            <div *ngIf="node.objectType=='aboutus'" class="content" [style.background-color]="getBackgroundColor(node)"
                [style.height]="getHeight(node)" [style.width.px]="getWidth(node)">

                <app-about-us [node]="node"></app-about-us>

            </div>

            <div *ngIf="node.objectType=='famousquote'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <div *ngIf="global.quote" class="card" [style.height]="max-content">
                    <div class="card__content">
                        <article style="padding:12px;">
                            <p class="card__desc">{{global.quote.text}}</p>
                            <p class="author">{{global.quote.author}}</p>
                        </article>
                    </div>

                </div>

            </div>



            <div *ngIf="node.objectType=='triviagame'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <app-trivia></app-trivia>

            </div>


            <div *ngIf="node.objectType=='dashboard'" class="content"
                [style.background-color]="getBackgroundColor(node)" [style.height]="getHeight(node)"
                [style.width.px]="getWidth(node)">

                <div fxFlex style="width:100%" fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                    <div *ngFor="let g of node.dataGems; let i = index;">
                        <div style="margin:10px;">
                            <div class="dataGem">
                                {{g.name+": "+(g.value|currency)}}
                            </div>

                        </div>
                    </div>
                </div>


                <div *ngFor="let v of node.dataViews; let i = index;">
                    <div *ngIf="v==0" style="margin:10px;">
                        <strong>Top 5 Selling Products by unit sales:</strong><br>
                        <div *ngFor="let top5 of global.top5ByUnits; let i = index;">
                            {{top5}}
                        </div>
                        <br>
                        <strong>Top 5 Selling Products by revenue:</strong><br>
                        <div *ngFor="let top5 of global.top5ByRev; let i = index;">
                            {{top5}}
                        </div>
                    </div>
                </div>


                <div *ngFor="let p of global.allProducts; let i = index;">
                    <app-view-item *ngIf="taggedProduct(node, p)" [appId]="appId" [p]="p"></app-view-item>
                </div>

            </div>

        </div>
    </div>



</div>

<div *ngIf="checkSharing()" style="width:100%!important; margin: 0px;">
    <div *ngIf="editing && checkParent(node?.parent, node)" style="margin-bottom: 12px;">
        <div *ngIf="!checkActive()">
            <p>This node is hidden &nbsp;</p>
        </div>

        <button (click)="helpAudio(1)" class="function" pButton pRipple type="button">
            ?
        </button>


        <div *ngIf="!editNode || editNode.id!=node.id" fxFlex fxLayout="row wrap" fxLayoutAlign="center center">
            <button (click)="editMe(node)" class="editor-function" pButton pRipple type="button">
                Edit
            </button>
            <button (click)="nodeService.insertNew(node, appId, end)" class="add-function" pButton pRipple
                type="button">
                Add Node
            </button>
            <button *ngIf="canMove(node, 'up')" (click)="nodeService.moveNode(appId, node, 'up')" class="move-function"
                pButton pRipple type="button">
                Move Up
            </button>
            <button *ngIf="canMove(node, 'down')" (click)="nodeService.moveNode(appId, node, 'down')"
                class="move-function" pButton pRipple type="button">
                Move Down
            </button>
        </div>
        <div *ngIf="editNode && editNode.id==node.id">
            <button (click)="finishEditing()" class="editor-function" pButton pRipple type="button">
                Finish Editing
            </button>
        </div>
    </div>
    <div *ngIf="editNode && editNode.id==node?.id" style="margin:0px; padding: 0px;">
        <app-editor [appId]="appId" [editNode]="editNode"></app-editor>
        <button (click)="nodeService.removeNode(node, appId)" class="function" pButton pRipple type="button">
            Remove this Node
        </button>
    </div>

</div>