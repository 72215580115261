<div fxFlex fxLayout="column" fxLayoutAlign="center center">

<div style="width: 100%;max-width: 100vw;">
    <app-form formName="masterSheetSettings" [displayGroup]="displayGroup" [formInput]="sheetSettings"
        [autoSubmit]="true" (objectEvents)="settingsEvents($event)" (initEvent)="initSettings($event)"></app-form>
</div>
<div *ngIf="insertObject">
    <app-form formName="masterChatChooseFunction" [autoSubmit]="true"
    (submitEvent)="addSubmitEvent($event)"></app-form>

</div>
<div *ngIf="moving">
    <app-form formName="masterArrows" [autoSubmit]="true"
    (objectEvents)="settingsEvents($event)">
    </app-form>
</div>



<div style="max-width: 100vw; max-height:85vh; overflow:scroll">
    <div [style]="gridStyle()">

        <div *ngFor="let field of sheetForm.fields; let i = index" style="display:grid;">
           
            <div *ngIf="1||!field.type">
                <app-field [themeId]="'hp24fAiGo6o5VnzKw57N'" [field]="field" [showSubmit]="false"
                    (outputs)="fieldEvents($event)"></app-field>
            </div>
        </div>

    </div>
</div>
</div>