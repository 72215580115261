import { AfterViewInit, ElementRef, ViewChild, AfterContentInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Company, ReviewSettings, Navigate, Reviews, Review, Coupon } from '../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../user-interface';
import { RegisterService } from '../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { ReviewsService } from '../reviews.service'
import { thumbnailsSettings } from 'lightgallery/plugins/thumbnail/lg-thumbnail-settings';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.css','../../common.scss']
})
export class ReviewsComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() store: string;
  @Input() view: string; // Media only etc.
  @Input() reviewSettings: ReviewSettings;

 // @ViewChild('fReview', { static: false }) fReview!: ElementRef;


  allReviews: Review[];
  transformationOne = [{ width: "900" }];
  reviewImages: string[];
  storeNum: string;
  signin: boolean = false;
  contact: Contact;

  totalReviews: number;
  total5Star: number;
  total4Star: number;
  total3Star: number;
  total2Star: number;
  total1Star: number;
  avgRating: number;
  avgRatingTrailing3: number;
  verifyCode: string;
  newFeature: string = "reviews";

  newstoreMode: boolean = false;
  getScreenWidth: number;
  getScreenHeight: number;

  editingReview;

  constructor(public global: Globals, private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute, public nav: NavService,
    public reviewService: ReviewsService) {
    this.allReviews = [];
    console.log("construct reviews")



    firebase.initializeApp(environment.firebase);
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER


  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.storeNum = this.route.snapshot.paramMap.get('store');
    if (!this.store && this.storeNum) this.store = this.storeNum;

    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;

    if (!this.reviewSettings)
      this.reviewSettings = { showHeader: true, defaultView: "Standard", filters: [] }


    console.log("Store: ", this.reviewSettings, this.storeNum, this.store);
    this.allReviews = [];
/*
    if (this?.reviewSettings?.view)
      this.view = this.reviewSettings.view;
*/

  //  if (!this.storeNum && this.store) this.storeNum = this.store;
/*
    if (this.storeNum && this.global.myCompany.store != this.storeNum)
      this.nav.GetStoreOnly(this.storeNum, this.afterStoreLoad, null, this);
    else if (!this.global.myCompany) {
      console.log("New Store Mode")
      this.newstoreMode = true;
    }
    else {
      this.reviewService.getReviews(); // Global service
    }

    */

    if (!this.global.reviews) {
      // if no company loaded find ID
      if (!this.global.myCompany) {
        // NEED ID to load reviews
        this.nav.GetStoreOnly(this.store, this.afterStoreLoad, this);

      }
      else
        this.reviewService.getReviews(); // Global service


    }


    if (!this.global.authuser) {
      // Load user
      const id = localStorage.getItem('uid');
      if (id) {
        console.log("UID: ", id);
        this.nav.getUserOnlywID(id, false, null, this, this.afterUserInit);// true get all res
      }
    }


  }

  ngAfterContentInit(): void {

    //  console.log("After View Init reviews")
  }

  afterUserInit(self: this) {
    console.log("Init, user loaded ", self.global.authuser);
    // IF NEW Store Mode then load my store
    if (self.global.authuser.creds)
      self.nav.GetStoreOnly(self.global.authuser.creds[0].storeNum, self.afterStoreLoad2, this);



  }

  afterStoreLoad2(self: this) {


  }

  afterStoreLoad(self: this) {
    console.log("Got store callback ");

    // self.GetReviews();
    self.reviewService.getReviews(); // Global service

    //  document.onselectionchange = () => {
    //   console.log(document.getSelection());
    // };
    if (self.validCreds() == true) {
      document.addEventListener('mouseup', event => {

        if (window.getSelection().toString().length) {
          window.alert(window.getSelection().toString());
          window.getSelection().empty();
        }
   
      })
    }
  
  }

  getStarted() {
    this.signin = true;
  }
  verifyEmail() {
    console.log("Verified: ", this.verifyCode == this.global.authuser.keyCode.toString())

    if (this.verifyCode == this.global.authuser.keyCode.toString()) {
      this.updateVerified();
    }

  }

  getFilterList (filters: string[]) {
    var str = ''; 
    filters.forEach(function(item){
      str += " "+item;
    })
    return str;
  }
  checkFilter(r:Review):boolean {
    var self = this;

    if(!self?.reviewSettings?.filters || self.reviewSettings.filters.length==0) return true;

    var found = false;

    if (r.review_text) {
    //  console.log("Filters ",self.reviewSettings.filters)

      self.reviewSettings.filters.forEach(function(filter){
 
     //   var exists = r.review_text.toLowerCase().includes(filter.toLowerCase())
        var str = r.review_text.toLowerCase();
        var exists = str.search(filter.toLowerCase())
        if (exists != -1) {found=true;}

      })
    }
    return found;
  }
  /*
  getFilteredText(p) {
    var self = this;

    var str=p.review_text;
    if (!self?.reviewSettings?.filters) return str;

    self?.reviewSettings?.filters.forEach(function(filter){
      str = str.replace(filter,'<strong>'+filter+'</strong>')
    })

    return str;

  }
  */

  editMe (index) {
    if (this.editingReview == index) {
      this.editingReview = -1
      return;
    }

    this.editingReview = index;
    console.log("Editing: ",this.editingReview)
  }

  autoTag(event) {
    console.log("Autoo tag ", event);

  }

  validCreds() {
    var self = this;

    // VALIDATE USER CREDS
    if (!self.global.authuser) return false;
    if (self.global.authuser && self.global.authuser.emailVerified == false) return false;
    if (!self.global.authuser.creds) return false;
   // console.log ("VALID staff1");
    if (!self.global.myCompany) return false;
  //??hack  if (self.global.myCompany.store != self.storeNum) return false;
    
 //   console.log ("VALID staff2");
    const xid = this.store;

    var found = self.global.authuser.creds.find(function (post, index) {
      if (post.storeNum == xid && (post.enabled.staff || post.role == 'Creator')) {
   //     console.log ("VALID staff3 ", xid, post.id);
        return true;
      }
    });
  //  console.log ("VALID: ", found, xid)
    if (found) return true;

    return false;
  }

  checkMedia (p) {
  //  console.log (p.media)
  }

  updateVerified() {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("users").doc(self.global.authuser.uid);

    ref.update({
      emailVerified: true,
    })
      .then(() => {
        console.log("e-verify updated: ");
        self.global.authuser.emailVerified = true;
      })
      .catch((error) => {
        console.error("Error writing verify: ", error);
      });
  }

  gotoSettings() {
    //  this.router.navigate(['/settings']);
  }
  gotoFront() {
    this.router.navigate(['/front']);
  }


  getStarWidth(star) {
    if (star == 5) return (this.global.reviews.totals.total5Star * 100 / this.global.reviews.totals.totalReviews).toFixed(1) + "%";
    if (star == 4) return (this.global.reviews.totals.total4Star * 100 / this.global.reviews.totals.totalReviews).toFixed(1) + "%";
    if (star == 3) return (this.global.reviews.totals.total3Star * 100 / this.global.reviews.totals.totalReviews).toFixed(1) + "%";
    if (star == 2) return (this.global.reviews.totals.total2Star * 100 / this.global.reviews.totals.totalReviews).toFixed(1) + "%";
    if (star == 1) return (this.global.reviews.totals.total1Star * 100 / this.global.reviews.totals.totalReviews).toFixed(1) + "%";

  }


/*
  async GetReviews() {
    var self = this;
    console.log("getin reviews ", self.global.myCompany)

    if (!self.global.myCompany) return;
    var db = firebase.firestore();
    if (!self.reviewImages) self.reviewImages = [];

    const q = query(collection(db, "reviews"), where("companyID", "==", self.global.myCompany.id));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const r = <Review[]>doc.data().reviews;
      self.allReviews = r;
      console.log("Reviews: ", self.allReviews.length, self.allReviews);


      // Fix Date strings
      self.allReviews.forEach(function (review) {
        if (!review.author) review.author = review.author_name;
        if (!review.author_name) review.author_name = review.author;

        if (!review.status) { review.status = "published" }
        if (review.status == 'pending') {

        }
        if (review.rating) {
          review.review_rating = review.rating;
        }
        //  if (review.review_datetime_utc) {
        //    console.log (review.review_datetime_utc);
        //    review.date =  self.timeSince(review.review_datetime_utc);
        // }


        // Collect images from reviews
        if (review.status == "published" && review.review_photos && review.review_photos.length > 0 && review.review_photos[0].length > 10) {
          //       console.log ("Photos ", review.review_photos)
          review.review_photos.forEach(function (imageStr) {

            //  self.reviewImages.push(imageStr);

          })
        }


      })

      self.reviewImages = [];  // Temp off

      console.log("allImages ", self.reviewImages)


      // Gather totals
      self.totalReviews = self.allReviews.length;
      self.total5Star = 0;
      self.total4Star = 0;
      self.total3Star = 0;
      self.total2Star = 0;
      self.total1Star = 0;
      self.avgRatingTrailing3 = 0;
      var daysAgo90 = 0;
      self.allReviews.forEach(function (item) {
        // set up Date string
        var time = new Date().getTime();
        time = +item.date;
        var myDate;
        if (!time) myDate = new Date(item.review_datetime_utc);
        else myDate = new Date(time)

        //    console.log("MY DATE: ", myDate, Date.now() - myDate);

        var daysAgo = (Date.now() - myDate) / (60000 * 60 * 24)
        var hoursAgo = (Date.now() - myDate) / (60000 * 60)
        var minAgo = (Date.now() - myDate) / (60000)


        if (minAgo < 60) {
          item.dateStr = minAgo.toFixed(0) + " minutes ago"

        }
        else if (hoursAgo < 24) {
          item.dateStr = hoursAgo.toFixed(0) + " hours ago"
        }
        else if (daysAgo < 90) {
          item.dateStr = daysAgo.toFixed(0) + " days ago"
        }
        else if (daysAgo < 180)
          item.dateStr = "<6 months ago"
        else if (daysAgo < 360)
          item.dateStr = "this year"
        else
          item.dateStr = "more than 1 year ago"

        if (!item.rating) item.rating = item.review_rating;
        if (!item.review_rating) item.review_rating = item.rating;
        if (+item.rating == 5) self.total5Star++;
        if (+item.rating == 4) self.total4Star++;
        if (+item.rating == 3) self.total3Star++;
        if (+item.rating == 2) self.total2Star++;
        if (+item.rating == 1) self.total1Star++;
        if (daysAgo < 90) {
          self.avgRatingTrailing3 += +item.rating;
          daysAgo90++;
        }
      })

      self.avgRating = (self.total5Star * 5) + (self.total4Star * 4) + (self.total3Star * 3) + (self.total2Star * 2) + self.total1Star
      self.avgRating /= (self.totalReviews);

      self.avgRating = +self.avgRating.toFixed(1);

      self.avgRatingTrailing3 /= daysAgo90;
      self.avgRatingTrailing3 = +self.avgRatingTrailing3.toFixed(1);
      console.log ("avg3 ", self.avgRatingTrailing3, daysAgo90)

      // Sort to put longer reviews on top
      self.allReviews.sort(function (a, b) {
        var alen = 0;
        var blen = 0;
        if (b.comment)
          blen = b.comment.length;
        if (a.comment)
          alen = a.comment.length;

        if (b.review_text)
          blen = b.review_text.length;
        if (a.review_text)
          alen = a.review_text.length;

        return blen - alen;

      });

      // Now Sort to put reviews with media on top
      self.allReviews.sort(function (a, b) {
        var am = 0;
        var bm = 0;
        if (a.review_photos && a.review_photos.length) am = 1;

        if (b.review_photos && b.review_photos.length) bm = 1;

        return bm - am;
      });

      // Sort with recents up front (recent = 6months)
      self.allReviews.sort(function (a, b) {

        var time = new Date().getTime();
        time = +a.date;
        var myDate;
        if (!time) myDate = new Date(a.review_datetime_utc);
        else myDate = new Date(time)
        var daysAgo = (Date.now() - myDate) / (60000 * 60 * 24)

        var time2 = new Date().getTime();
        time2 = +b.date;
        var myDate2;
        if (!time2) myDate2 = new Date(b.review_datetime_utc)
        else myDate2 = new Date(time2)
        var daysAgo2 = (Date.now() - myDate2) / (60000 * 60 * 24)



        if (daysAgo2 && daysAgo)
          return daysAgo - daysAgo2;
        else return 0;

      });
      //hack-add firstTime

      console.log("  Reviews", self.allReviews);
      
    });

    // console.log("Phrase Counts: ", counts);

  }
  */

  // NEW USER REGISTRATION
  setContact(info: Contact) {
    // After User has created account
    const db = firebase.firestore();
    var self = this;

    console.log("User contact: ", info);

    self.contact = info;
    if (!self.global.authuser) {
      console.log("contact ", self.contact)

      if (!this.global.authuser) {
        if (!self.contact.firstName || self.contact.firstName.length == 0
          || !self.contact.lastName || self.contact.lastName.length == 0
        ) { alert("Please enter a name."); return; }
        const userRef = db.collection('users').doc();

        self.registerService.RegisterChatUser(info.email, userRef, info.firstName, info.lastName, self.initUser, self);

      }
    }
  }

  foundUseful: string [];

  useful (review: Review) {
    if (!this.foundUseful) this.foundUseful = [];
// Will add local field to track this user
    const exists = this.foundUseful.some(x => x === review.author)
    if (exists) return;

    console.log ("Useful: ", review);
    if (!review.useful) review.useful = 0;
    review.useful ++;
    this.reviewService.updateReview(review);

    this.foundUseful.push (review.author);

  }

  defStyleWidth(review: Review) {
    review.styleWidth ="";
    this.reviewService.updateReview(review);

  }

  styleWidth(review: Review) {
    review.styleWidth ="full";
    this.reviewService.updateReview(review);

  }

  getFullWidth () {
    return this.global.getScreenWidth+"px"
  }
  getMyWidth (review: Review) {
    var self = this;

    if (!self.checkFilter(review)) return "0%";
    if (review.styleWidth == 'full') return "100%";
    else if (window.innerWidth < 600) return "100%";
    else return '50%';


  }

  defStyle (review: Review){
    review.style ="";
    this.reviewService.updateReview(review);

  }
  quoteStyle (review: Review){

    review.style ="quote";
    this.reviewService.updateReview(review);

  }
  initUser(self?: any) {
    self = self || this;

    // User has been registered - but now we want to code too confirm email.
    // So let's send an email to request
    self.newUserEmail();


    if (self.global.authuser) {
      //  self.user = self.global.authuser.uid;

    }

  }

  polaroid (r:Review) {
    if (r.style=='polaroid') r.style = ''
    else r.style = 'polaroid';
    }

  newUserEmail() {
    var self = this;

    var db = firebase.firestore();
    const sentAt = Timestamp.now();
    // NOTIFY STAFF
    var templateParams = {
      from_name: 'Napkin Reviews',
      to_name: self.contact.firstName,
      to_email: self.contact.email,
      msgDate: sentAt,
      code: self.global.authuser.keyCode,
      msgFrom: 'Napkin Reviews',
      msgText: "Please validate email"
    };

    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_omusiue", templateParams) //"template_1ky3ksd"
      .then((result: EmailJSResponseStatus) => {
        console.log("New Reviews User regisration email sent: ", result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

}


