<div *ngIf="0" fxFlex fxLayout="column" fxLayoutAlign="center center">

    <span style="font-size: 28px; font-weight: 700; color:blue">Thank you for your payment!</span>

    <span style="line-height: 95%; font-size: 28px; font-weight: 700; color:rgb(118, 165, 0); text-align: center;">
        Scan below to leave us feedback.<br>
        Your server will earn an extra $5 on us.<br><br></span>


    <br>
    <hr>

</div>

<p-accordion expandIcon="null" collapseIcon="null" [multiple]="true" fxLayout="column" fxLayoutAlign="center none"
    activeIndex="viewTabItem" *ngFor="let order of cartService.newOrderItems; let i = index">
    <p-accordionTab styleClass="dark-panel">
        <ng-template pTemplate="header" styleClass="dark-panel">
            <div class="left-side2">

                <span *ngIf="order.qty>1" style="font-size:1.2em; line-height:1.1em;  ">{{order.qty}}</span>

                {{lineItem(order)}}<span style="font-size:.8em;">{{lineItemOptions(order)}}
                </span>


                <div class="right-side2" fxLayout="row" [style.background-color]="getStatusColor(order)">
                    <div fxLayout="column">
                        {{" " + getExtPrice(order)}}
                        <div style="font-size:.75em">{{order.status}}</div>
                        <div *ngIf="order.discountsApplied && order.discountsApplied.length" style="font-size:75%">
                            {{order.discountsApplied[0].name+" "}} Discount applied
                        </div>
                    </div>

                </div>
                <div class="status" [style.background-color]="getStatusColor(order)"></div>
            </div>
        </ng-template>

        <ng-template pTemplate="content">



            <div style="width:50%">
                <button class="remove-item" pButton type="button" label="Remove this Item"
                    (click)="cartService.RemoveTicketItem($event, order, i)"></button>
            </div>
            <div *ngIf="mode=='POS'">
                POS options
            </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>
<div fxLayout="column" fxLayoutAlign="center none">
    <div style="width: 100%;" fxFlex fxLayout="column" fxLayoutAlign="center center">
        <div class="left-side2" fxLayoutAlign="center none">
            <!--       <div *ngIf="cartService.unSavedItems()==0" class="OrderButton">
            <button (click)="checkOut($event)" pButton type="button" label="CheckOut"></button>

        </div>
        -->

            <div *ngIf="global.tableNameQR" pRipple>
                <div *ngIf="cartService.cartItemCount()>0" class="OrderButton">
                    <button pButton type="button" label="Send to Server"
                        (click)="cartService.SendMyOrder($event)"></button>
                    <br>
                    <p>Status will update as we prepare your order.</p>
                </div>
            </div>
        

            <div *ngIf="!global.tableNameQR" pRipple>&nbsp;</div>


            <div class="right-side2">Subtotal:{{(getOrderTotal() | currency)}} </div>

        </div>

        <div *ngIf="!global.tableNameQR" pRipple>
            <div class="OrderButton">
                <button (click)="checkOut($event)" pButton type="button" label="CheckOut"></button>

            </div>
        </div>
    </div>
</div>
<!--
<p-accordion #checkoutAccordion fxLayout="column" fxLayoutAlign="center none" expandIcon="null" collapseIcon="null"
    [multiple]="true" (onOpen)="onTabOpen($event)" (onClose)="onTabClose($event)">

   
    <p-accordionTab [selected]="global.showCheckOut">
        <ng-template pTemplate="header">

        </ng-template>

        <ng-template pTemplate="content">
            <div *ngIf="!global.tableNameQR" class="cart-header">
                <app-selfpay></app-selfpay>
            </div>

        </ng-template>
    </p-accordionTab>
</p-accordion>
-->