import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Globals } from 'src/app/globals';
import { Product } from '../components/product-int';
import { onMenuItem } from './menu-item-interface';
import { Content } from './content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Order } from '../components/order-interface';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  allProducts: Product[];


  constructor(private global: Globals, private sanitizer: DomSanitizer) {


   }


  GetRedeemable(companyID: string) {
    // MOVE TO DATA SERVICE MODULE
    console.log("redeem Company ID ", companyID);

    return;

    var db = firebase.firestore();
    var self = this;

    self.global.redeem = self.global.redeem.splice(0, self.global.redeem.length);
    db.collection("products").where("companyID", "==", companyID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Product>doc.data();

          //  if (!data.redeemable) data.redeemable = true;
          //          if (data.retail > 0 && data.active && data.redeemable && (data.retail < (self.myRewardPoints + 100) / 100)) {
          if (data.retail > 0 && data.redeemable && (data.retail < (self.global.myRewardPoints + 100) / 100)) {
            if (data.companyID == companyID && data.active) {
              //  console.log (data);
              self.global.redeem.push(data); // PUSH IF ACTIVE
            }
          }

        })
        //  console.log("Red: ", self.global.redeem);

      }).catch((error) => {
        console.log("Error getting redeemables:", error);
      });

  }

  gettingProds = false;

  GetAllProducts() {
    var self = this;
    var colorIn = 0;

    if (!self.global.myCompany || this.gettingProds) return;

    if (self.global?.allProducts?.length && 
      (self.global.allProducts[0].companyID == self.global.myCompany.id)) {
        console.log ("Attempt to restart Product Services detected")
        return;
      }


    console.log("getting ALL Products...",self.global.myCompany.id, self.global.allProducts);
    var db = firebase.firestore();

    const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products")
    docRef.onSnapshot((querySnapshot) => {
        self.global.allTagsCount = [];
        self.global.allProducts = [];
      //  console.log("Items... ", querySnapshot)
        this.gettingProds =  true;
        querySnapshot.forEach((doc) => {
      
        
          var item =  <Product>doc.data();
          item.docID = doc.id; // Should fix in db

          //DEFAULT QTY to 1
          item.qty = 1;   // HACK??    

          self.global.allProducts.push(item);
      
          // Add all Tags
          if (item.allTags) {
            item.allTags.forEach(function (tag) {

              var ind = self.global.allTags.indexOf(tag);
              if (ind == -1) {
                self.global.allTags.push(tag);
                self.global.allTagsCount.push(0);
              }
              else {
                self.global.allTagsCount[ind]++;
              }

            

            })
          }
         
      });
      this.gettingProds =  false;
    console.log("All prod: ", self.global.allProducts, self.global.allTags);
  })
      
  }

moveProducts () {  // One-time fixer-upper
  this.GetAllProducts2();



}



updateTags(allTags: any, docId) {
  var db = firebase.firestore();
  var self = this;

  var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(docId);
  docRef.update({ allTags: allTags })
    .then(() => {
      console.log("Tags successfully updated!");
    })
    .catch((error) => {
      // The document probably doesn't exist.
      console.error("Error updating tags: ", error);
    })

}

addProduct (product: Product) {
  var db = firebase.firestore();
  var self = this;

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc();

      product.docID = docRef.id;
      product.active = true;
      product.onMenu = true;
      product.type = 'Product'
      

      docRef.set(product)
        .then(() => {
          console.log("Product successfully added!", product);

        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error adding product: ", error);
        });
   

}


// ONE TIME FIXER UPPER
  GetAllProducts2() {
    var self = this;
    var colorIn = 0;

    return;

    if (!self.global.myCompany) return;

    console.log("getting ALL OLD Products...",self.global.myCompany.id);
    var db = firebase.firestore();

    self.allProducts = [];
    

    db.collection("products").where("companyID", "==", self.global.myCompany.id)
    .where("active", "==", true)
     .where("onMenu", "==", true)
      .get()
      .then((querySnapshot) => {

        console.log("Items... ", querySnapshot)
        querySnapshot.forEach((doc) => {
          var item = <Product>doc.data();
          self.addProduct (item);  // ONE TIME FIXER

          item.qty = 1;   // HACK??    

          self.allProducts.push(item);
      
          
      });
    console.log("All prod: ", self.allProducts);
  })
      .catch((error) => {
  console.log("Error getting old products: ", error);
});
  }

/* GET ALL CONTENT FROM THE DATABASE */

/*
GetAllContent() {
  var self = this;
  var colorIn = 0;
  if (!self.global.myCompany) return;

  self.global.allContent.splice(0, self.global.allContent.length);
  console.log("getting content...");
  var db = firebase.firestore();

  db.collection("content").where("companyID", "==", self.global.myCompany.id).where("active", "==", true).get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //   let item = new Content();
        const item = <Content>doc.data();
        var html: SafeHtml;
        html = self.sanitizer.bypassSecurityTrustHtml(item.fullContent);
        item.html = html;

        //    item = Object.assign(item, <Content>doc.data());
        self.global.allContent.push(item);
      });
    })
    .catch((error) => {
      console.log("Error getting content: ", error);
    });
   
}
*/

GetAllOrders() { // GET ALL ORDERS auto-sort to NEW on top

  var db = firebase.firestore();
  var self = this;
  var index = 0; // 
  if (!self.global.myCompany) return;

  this.global.lastUpdated = Date.now();

  console.log("getting orders ", self.global.myCompany);
  db.collection("orders").where("companyID", "==", self.global.myCompany.id).where("status", "!=", "Closed")  // Need to include date filter - include closed for today.
    .onSnapshot((querySnapshot) => {
      // console.log("New Order Data arrived ", querySnapshot.docs);
      self.global.openOrders.splice(0, self.global.openOrders.length);  // ERASE

      querySnapshot.forEach((doc) => {
        const data = <Order>doc.data();
        //      console.log("Order: ", data);

        if (!data.note) data.note = "";
        // SKIP COMPLETED
        //         if (data.status != "Delivered") {

        let dateT = data.dateTime;
        let localOrder = <Order>data;
        localOrder.isExpanded = false;

        if (localOrder.tableName == "") localOrder.tableName = "No Table";
        localOrder.id = doc.id;
        if (localOrder.tableName != "No Table") {
          // if not showing Removed
          if (localOrder.status != "Removed")
            self.global.openOrders.push(localOrder);
        }

      });

      self.MakeOpenOrderList(self);
      self.dateTimeSort();

      //      self.simplifyOrder();
      //   console.log("New Data");
      //     self.dataSourceNewOrder = new MatTableDataSource(self.orderView); // new order
      //      self.obsNewOrder = self.dataSourceNewOrder.connect();

    });

}

dateTimeSort() {

  //  this.newOrder.sort(function (a, b) { return b.localMs - a.localMs; });

}
// FIX: Need to use grouping here
MakeOpenOrderList(self) {
  self.global.openOrdersList.splice(0, self.global.openOrdersList.length);

  var table = -1;

  self.totalOpenOrders = 0;
  self.totalReadyItems = 0;
  self.totalCompletedOrders = 0;
  self.totalClosedOrders = 0;

  self.global.openOrders.forEach(function (arrayItem: Order) {
    table = -1;

    if (arrayItem.status == "Ready") {
      self.totalReadyItems++;
      arrayItem.myColor = "8bc34a";
    }

    self.global.openOrdersList.forEach(function (item: Order, index) {
      if (item.tableName == arrayItem.tableName) {
        table = 99;
        if (arrayItem.dateTime < item.dateTime)
          item.dateTime = arrayItem.dateTime;
        //    self.LoadOrder ()
        //   console.log("status", arrayItem.status, item.tableName);
      }
    })

    if (table == -1) {

      self.totalOpenOrders++;
      self.global.openOrdersList.push(arrayItem);
    }

  });
  console.log("OPEN ORDERS: ", self.global.openOrders, self.global.openOrdersList);
  /* HAck
  
      if (self.myTable.length > 0) {
        let tmp = new Order();
        tmp.tableName = self.myTable;
  
        self.LoadOrder(tmp);  // HACK??
      }
  */
  //  console.log("Open Orders: ", self.openOrdersList.length);
  //  console.log("openorderlist ", self.openOrdersList);
  //    self.openOrdersDataSource = new MatTableDataSource(self.openOrdersList);
  //    self.global.openOrdersData = self.openOrdersDataSource.connect();
  self.global.openOrdersList.sort(function (a, b) { return b.dateTime - a.dateTime; });

}

}

function onMenuItem() {
  this.title = "";
  this.fullDescription = "";
  this.retail = 0;
  this.DealPrice = "";
  this.topCategory = "";
  this.category = "";
  this.image1 = "";
  this.image2 = "";
  this.image3 = "";
  this.status = "";
  this.textAlign = "center";
  this.bodyColor = "#000000";
  this.layout = "wide";
  //  this.allOptions = [];
  this.qty = 1;

}

function Content() {
  this.title = "";
  this.fullDescription = "";

}
