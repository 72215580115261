import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import { Company, Theme, Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { MediaService } from "../media//media-functions/media-functions"
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'


@Component({
  selector: 'app-themes',
  templateUrl: './themes.component.html',
  styleUrls: ['./themes.component.css', '../../common.scss']
})
export class ThemesComponent implements OnInit {
  @Input() chatBot: ChatBot;

  theme: Theme;


  constructor(public global: Globals,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {


  }

  ngOnInit(): void {
    Logger("Themes Started", this.global.authuser.uid, "")
    if (!this.global.allThemes) this.global.allThemes = [];

    this.formService.watchThemes()
  }
  fieldEvents (field: Field) {
    console.log ("GOT THEME FIELD EVENT ", field)
    if (field.title == 'copyTheme') {
      this.copyTheme()

    }
  }

  outputEvent(form: Form) {
    var self = this;

    self.formService.napkinFieldsToObject(form, self.theme, true)
    console.log("Theme data: ", self.theme)
    // update Theme
    self.updateTheme(self.theme)

  }

  copyTheme() {
    var self = this;
    var newTheme = Object.create(self.theme);
    newTheme = {...self.theme};

    newTheme.id = "New";
    newTheme.name = "Copy of "+self.theme.name;
    newTheme.createdAt = Date.now();
    newTheme.createdBy = self.global.authuser.uid;

    // Update buttons - what happens whenI change Theme name...

    console.log ("THEME COPY ", newTheme)
    // New Themes do not get saved until some edits are made...
    self.global.allThemes.splice(0, 0, newTheme) // Insert at top
  }


  addTheme(event) {
    var self = this;
    var newTheme = {
      companyId: "OdCDgRWTZhTMzW8N0y9y", // this.global.myCompany.id,
      id: "New",
      name: "My Theme",
      createdAt: Date.now(),
      createdBy: self.global.authuser.uid,
      editedAt: Date.now(),
      editedBy: self.global.authuser.uid,

    }
    this.global.allThemes.splice(0, 0, newTheme) // Insert at top
  }

  onThemeOpen(e, i) {
    var self = this;
    self.theme = self.global.allThemes[i];

    console.log("MY THEME: ", self.theme)
    

  }

  onThemeClose(e, i) {
    var self = this;
  }

  updateTheme(theme: Theme) {
    var self = this;

    var db = firebase.firestore();

    if (theme.id == 'New') { // create
      var docRef = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("themes")
        .doc()
      theme.id = docRef.id;
      docRef.set(theme).then(() => {
        console.log("Theme ADDED: ");
      })
    }
    else {
      var docRef = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("themes")
        .doc(theme.id)
      docRef.update(theme).then(() => {
        console.log("Theme Updated: ");
      })

    }
  }

}
