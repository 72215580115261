import { AfterViewInit, AfterContentInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Company, Navigate, AppNode, Reviews, Review, Coupon } from '../../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { NavService } from '../../menu/nav.service';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../../user-interface';
import { RegisterService } from '../../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { ReviewsService } from '../../reviews.service'

@Component({
  selector: 'app-nodeview',
  templateUrl: './nodeview.component.html',
  styleUrls: ['./nodeview.component.css', '../../../common.scss']
})
export class NodeviewComponent implements OnInit {
  @Input() node: AppNode;


  constructor(public global: Globals, private registerService: RegisterService,
    private router: Router,
    private route: ActivatedRoute, public nav: NavService,
    public reviewService: ReviewsService) {

    }

  ngOnInit(): void {
    
  }

}
