import { Component, ViewChild, OnChanges, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Form, Field, AppNode, Duration, Reviews, Review, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, NapkinApp } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { NodeService } from '../node.service';
import { Logger } from 'src/app/functions';
import { set, differenceInCalendarDays, startOfDay, endOfDay, getMilliseconds } from "date-fns";



export interface Option {
  name: string;
  code: boolean;
}

@Component({
  selector: 'app-front',
  templateUrl: './front.component.html',
  styleUrls: ['./front.component.css', '../../common.scss']
})
export class FrontComponent implements OnInit, OnChanges {
  @ViewChild('videoFileContainer', { static: false }) videoFileContainer;

  a: string;

  activeRes: boolean[];
  value: Date;
  firstDay: Date;
  reservationList: reservation[];
  allWeekResList: reservation[][];
  availableTimes: resTotals[];
  allWeekTotals: resTotals[][];
  closingTime: number;
  viewResCal: boolean = false;
  storeNum: string;
  allReviews: Review[];
  fullWidth: boolean = true;
  viewPOS: boolean = false;

  openApp: boolean[];

  enabled: Option[];
  haveValidCreds: boolean = false;

  eyeCandyType: string[];
  eyeCandy: string[] = [
    "https://43905vjmm.nyc3.cdn.digitaloceanspaces.com/CHV-view.mp4",
    "https://43905vjmm.nyc3.cdn.digitaloceanspaces.com/SanDiegoBay.mp4",
    "https://43905vjmm.nyc3.cdn.digitaloceanspaces.com/Driving.mp4",
    "https://suncraftcache.sfo2.cdn.digitaloceanspaces.com/LaJolla3.mov",
    "https://player.vimeo.com/external/436413638.hd.mp4?s=16fff2ccbde894b8ce916ad45b9c757dd663974b&profile_id=175&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/341958540.hd.mp4?s=d4908643b655c9f2d487fa415c7f7085a6b0c961&profile_id=170&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/370423208.hd.mp4?s=7dfb8eee5fd0faeb24b3f0153f933ee35bdcc17f&profile_id=175&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/481743558.hd.mp4?s=fd55185d3d239761df220c9f6043523e84a99747&profile_id=175&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/452784721.hd.mp4?s=c321bd010036ad5d7ee1bab3c4df26d10c80bb0e&profile_id=175&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/330412344.hd.mp4?s=39d0c1e909c8337e92866beec5e7b71ddb92cfeb&profile_id=175&oauth2_token_id=57447761",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/users%2FkvT6eKFxFHb3EKbVeCTusW86CrZ2%2Fmedia%2F1703915440929-IMG_3019.mov?alt=media&token=8d7568bd-6626-4834-8b88-f2fc5db11ca3",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F4390%2Fmedia%2F1701559442761-BlueOcean.png?alt=media&token=b4abcb44-7442-40c8-8d02-8d03b83361b3",


    "https://player.vimeo.com/external/424984254.hd.mp4?s=bb1d5aa63705acd983d5b6e264c375d3c4b69133&profile_id=174&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/479606760.hd.mp4?s=884f1bd314005e02a82a8f7684b52d4aa961936e&profile_id=174&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/484726216.hd.mp4?s=46ebfdc7034b0acf2cb534c05461b1b3341338b6&profile_id=170&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/435882538.hd.mp4?s=6292c5280ae0e4dfc81722570d4fa3ff2e706cc8&profile_id=169&oauth2_token_id=57447761",
    "https://player.vimeo.com/external/402571180.hd.mp4?s=ccc349e6cfb4a92d925ef4663701b304e671cdd9&profile_id=172&oauth2_token_id=57447761",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F4390%2Freview-images%2F1702747338441-Thumb6153253.jpg?alt=media&token=adc9bf77-d688-4059-8b61-9219db02faab",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F4390%2Freview-images%2F1702745276322-Thumb619348.jpg?alt=media&token=1cf7cbb6-c40d-4566-8ba3-c98bc512e0cd",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F4390%2Freview-images%2F1702744803317-Thumb784745.jpg?alt=media&token=7deaa389-8e03-45b3-bb3c-7ff807640f8c",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F3513%2Freview-images%2F1702761847025-Thumb1135659.jpg?alt=media&token=c920845e-43f2-4cb9-b2b8-0a9aec6e6ad8",
    "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F3513%2Freview-images%2F1702761659619-Thumb6162549.jpg?alt=media&token=29e38b45-b4db-4954-9227-5438b7366b68",

  ]
  candyIndex = Math.random() * this.eyeCandy.length;


  startDays: number = 10;

  slidercolor: string = "#00e434dd";
  daysofweek: Array<string> = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  r: reservation;
  entry: string;

  allEnabled: Enabled;
  public myAngularxQrCode: string = null;
  public qrCodeDownloadLink: SafeUrl = "";
  publishCode: string = "";

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public nodeService: NodeService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller
  ) {

    this.enabled = [
      { name: 'Enabled', code: true },
      { name: 'Not Enabled', code: false }
    ];

    this.allEnabled = {};

    firebase.initializeApp(environment.firebase);

  }

  ngOnChanges(): void {
  //  console.log("Front-Changes");
  }


  ngOnInit(): void {
    var self = this;
    this.eyeCandyType = [];
    this.eyeCandy.forEach(function (candy) {
      var type = "image/jpg"
      if (candy.includes("mp4") || candy.includes("mov"))
        type = "video/mp4"

      if (candy.includes("png"))
        type = "image/png"
      if (candy.includes("jpg"))
        type = "image/jpg"

      self.eyeCandyType.push(type);



    })

    // Init Eye Candy
    if (window.innerWidth > window.innerHeight)
      this.candyIndex = Math.floor(Math.random() * 7);
    else {
      this.candyIndex = Math.floor(Math.random() * 4 + 7)
    }


    //   this.videoFileContainer.nativeElement.load();

    if (this.videoFileContainer)
      this.videoFileContainer.nativeElement.play()




    this.global.customer = null;
    this.storeNum = this.route.snapshot.paramMap.get('store')
    console.log("Init Front ", this.storeNum, this.global.authuser);

    // NOT SURE FRONT SHOULD HAVE DIRECT LOGIN
    var direct = this.route.snapshot.queryParamMap.get('p'); // direct Login

    this.route.queryParams.subscribe(params => {
      direct = params['p'];


    });

    this.publishCode = this.route.snapshot.queryParamMap.get('x'); // this.name = 'testing'
    console.log("Publish-code: ", this.publishCode);

    if (this.router.routerState.snapshot.url == "front") {
      this.entry = 'front';
      this.global.entryPoint = 'front'
    }
    this.global.entryPoint = 'front'

    if (window.location.hostname == "openbabble.com") {

      //   if (this.router.routerState.snapshot.url == "settings")
      this.global.entryPoint = "openbabble";
      this.router.navigate(['/front']);
    }
    this.activeRes = [false, false, false, false, false, false, false];
    this.value = new Date();
    this.firstDay = getFirstDayOfWeek(this.value);
    this.global.newResDate = new Date();
    let aTime: resTime = {
      msTime: 0,
      strTime: ""
    }
    this.global.newResTime = aTime;


    console.log("Authuser: ", this.global.authuser, this.storeNum);

    if (this.global.authuser) {
      if (this.storeNum) {
        this.global.store = this.storeNum;
        this.nav.GetStore(this.storeNum, this.InitRes, this.r, this);
      }
      else if (this.global.authuser.creds) { //??
        console.log("Init creds: ")
        this.nav.GetStore(this.global.authuser.creds[0].storeNum,
          this.InitCreds, this.r, this);
        this.global.userTabOpen = false;
      }
    }
    else {

      const id = localStorage.getItem('uid');
      if (direct) {
        console.log("Direct: ", direct)
        // Remove query params
        this.router.navigate([], {    // REMOVE dcode
          queryParams: {
            'p': null,
          },
          queryParamsHandling: 'merge'
        })
        // get UID from directdb
        this.getDirectUID(direct);

      }
      else if (id) {
        console.log("UID: ", id, this.global.allReservations);
        this.nav.GetUserwID(id, false, this.InitFront, this);
      }


    }
    this.openApp = [];


  }

  copyApp() { //Hack internal-only to move the app under collection
    return;
    var self = this;
    console.log("Saving Apps ", self.global.myCompany.id);

    var db = firebase.firestore();
    const vm = this;

    var chvapp = {
      companyId: vm.global.myCompany.id,
      accessControl: "public", name: "Wine tasting, flatbreads, views", appId: "chv"
    }

  }

  trialMessage () {
    if (this.global.myCompany.trialMode.status == 'active') {
      var str = "Your "+this.global.myCompany.trialMode.str;
      const days = differenceInCalendarDays(this.global.myCompany.trialMode.end, Date.now())
      str += " has " + days + " days remaining. "
    }
  //  console.log ("trial ", str);
    return str;
  }

  copyReviews() { //Hack internal-only to update reviews from CHV to NapkinReviews

    var self = this;
    console.log("Saving New Review ", self.global.myCompany.id);

    var db = firebase.firestore();

    self.global.publishedReviews.forEach(function (review) {
      var reviewRef = db.collection("company").doc("AOaJSM4Je9OXDo0x8ZcX").collection("reviews").doc();

      review.id = reviewRef.id;
      reviewRef.set(review)
        .then(() => {
          console.log("review Added!", review);
        })
        .catch((error) => {
          console.error("Error adding review: ", error);
        });

    })

  }



  getCandy() {
    return this.eyeCandy[this.candyIndex]
  }

  candyClick() {
    var self = this;

    self.candyIndex++;
    if (self.candyIndex >= self.eyeCandy.length) self.candyIndex = 0;

    self.videoFileContainer.nativeElement.load();
    self.videoFileContainer.nativeElement.play()

    // var video = <HTMLVideoElement>document.getElementById("myCandy");

    //   video.srcObject.currentSrc = self.eyeCandy[self.candyIndex];

  }

  getAppNameStyle(i) {
    var style;

    if (this.global.getScreenWidth < 400 || this.openApp[i])
      style = "width: " + this.global.getScreenWidth + 'px;'

    else if (this.global.getScreenWidth >= 400)
      style = "width: 400px;"

    if (this.openApp[i]) {  // I'm selected yay!
      style += " background-color: #add8e6; font-size: 130%;";
    }

    return style;
  }

  showApp(i) {
    if (!this.openApp) return true;
    if (!this.openApp.includes(true)) return true;
    if (this.openApp[i]) return true;
    return false;

  }

  findMe(me: string, form: Form) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { alert("oops " + me) }
    return index;

  }

  newForm() {
    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc()

    var newForm: Form = {
      id: docRef.id,
      name: "New form",
      showSubmit: true,
      fields: [],
    }
    docRef.set(newForm).then(() => {
      console.log("form added");

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  updateForm(form: Form) {
    var self = this;
    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(form.id)

    docRef.update(form).then(() => {
      console.log("form updated");
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }


  currentForm: Form;

  selectForm(form: Form) {
    this.currentForm = form;
    console.log("Form selected ", form.name)


  }

  addFieldToForm(field: Field) {
    this.currentForm.fields.push(field);
    this.updateForm(this.currentForm);


  }

  formSubmitted(form: Form) {
    console.log("Form data: ", form);

    // Store new Field in Collection

    var self = this;

    const fieldTitle = form.fields[this.findMe("title", form)].binding;
    var fieldClass = form.fields[this.findMe("class", form)].binding;
    const fieldType = form.fields[this.findMe("Field type", form)].binding;


    var db = firebase.firestore();

    fieldClass = fieldTitle.toLowerCase(); //hack


    //  var docRef = db.collection("company").doc(self.global.myCompany.id).collection("fields")
    var docRef = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("fields")
      .doc()
    var newField: Field = {
      id: docRef.id,
      binding: "",
      title: fieldTitle,
      type: fieldType,
      class: fieldClass,

    }
    docRef.set(newField).then(() => {
      console.log("field added");

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  getMaxWidth() {
    if (this.fullWidth) return "100%";
    else return "960px";

  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
    console.log("URL: ", url);
  }
  getDirectUID(direct) {
    var self = this;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    db.collection("users").where("sid", "==", direct)
      .get()
      .then((querySnapshot) => {
        console.log("DIRECT:");
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          localStorage.setItem('uid', data.uid);

          self.nav.GetUserwID(data.uid, true);// true get all res
        });


      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }
  InitCreds(self: this) {
    console.log("init creds")
    self.validCreds();
  }

  InitFront(self: this) {
    // CALLBACK after user loaded
    // Load creds[0] - until we set a Last-Store locally
    console.log("init front callback")

    if (self.global.authuser.creds) {
      self.nav.GetStore(self.global.authuser.creds[0].storeNum,
        self.InitRes, null, self);
      self.global.userTabOpen = false;

    }

  }

/*
getStoreApps () {
  const db = firebase.firestore()
  const vm = this;

  var docRef =
    db.collection('apps').where('companyId', '==', vm.global.myCompany.id)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <NapkinApp>doc.data();
          vm.global.publicApps.push(data);
          vm.openApp.push(false);

        })
        console.log("App List ", vm.storeNum, vm.global?.publicApps)

      })

}
*/

  InitRes(self: this) {

    console.log ("STORE IS NOW LOADED. let's get apps")
  //  self.loadApps();

    return;

    self.reviewsService.getReviews();

    self.availableTimes = [];
    //  self.getReservations(new Date("9/24/2022"));
    self.getWeekReservations(new Date());

    self.allReviews = [];
    console.log("init reviews")
    //  self.GetReviews();

    //self.myAngularxQrCode = "napkinhq.com/review/"+self.global.myCompany.store;




  }

  getReviewUrl() {

    var url = "http://api.qrserver.com/v1/create-qr-code/?data=NapkinHQ.com/review/";
    url += this.global.myCompany.store
    url += '&size=200x200';
    return url;
  }

  allRes() {

    console.log(this.global.allReservations);

  }

  onTimeOpen(e, i) {
    if (i == 0) {

    }


  }



  changeDate() {
    this.viewResCal = false;
    this.getWeekReservations(this.value);
  }

  onResOpen(e, i: number) {
    console.log("index: ", e.index);
    if (!this.activeRes[i]) this.activeRes[i] = true;
    else this.activeRes[i] = false;

    // Now close all other tabs
    for (var n = 0; n < this.activeRes.length; n++) {
      if (n != i) this.activeRes[n] = false;

    }
    console.log(this.activeRes);

  }

  getMyApp(event, i) {


  }
  loadApps() {
    this.nodeService.storeAppsListener();
    this.nodeService.getNodeTypes();
  }

  onTabOpen(e, i: number) {
    console.log("index: ", i, e.index);
    return;
    
    /* fix index
        if (e.index == 4) {
          document.getElementById("app-builder").scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
          });
        } */
/*
    if (e.index == 8) {// Clicked Main tab
      //  this.scroller.scrollToAnchor("pos");

      document.getElementById("pos").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });

    }
    */


  }

  validCreds() {
    var self = this;

    // VALIDATE USER CREDS
    if (!self.global.authuser) return false;
    if (self.global.authuser && self.global.authuser.emailVerified == false) return false;
    if (!self.global.authuser.creds) return false;
    if (!self.global.myCompany) return false;
    // if (self.global.myCompany.store != self.storeNum) return false;

    const xid = this.global.myCompany.id;

    var found = this.global.authuser.creds.find(function (post, index) {
      if (post.id == xid && (post?.enabled?.staff || post.role == 'Creator'))
        return true;
    });

    self.haveValidCreds = true;
  // console.log ("HAVE VALID CREDS")
    return found;


  }


  noShow() { }
  closeRes(id: string) {
    this.cartService.updateRes(id);

  }
  goLogout() {
    this.ngAuthService.SignOut();
    this.router.navigate(['/front']);

  }

  createReview() {
    this.router.navigate(['/review/' + this.global.myCompany.store]);

  }
  getFormattedTime(time: string) {
    // return only HH:MM AM
    let lcTime = time.toLowerCase();

    let length = lcTime.indexOf("m");
    return (time.substring(0, length + 1));

  }

  getReservations(d: Date) {
    var self = this;

    this.createTimeArray(d.getDay(), d);


    var db = firebase.firestore();
    console.log("Getting res totals for ", d, self.global.myCompany.id, self.availableTimes);
    db.collection("reservations").where("companyID", "==", self.global.myCompany.id).where("status", "==", "open").where("resDate.date", "==", d)
      .get()
      .then((querySnapshot) => {
        self.reservationList = [];
        querySnapshot.forEach((doc) => {

          const data = <reservation>doc.data();
          //      console.log("res", self.global.myCompany.id, data);

          /*
                    var rDate = data.resDate.date.toDate();
                    const day = rDate.getDate();
                    const month = rDate.getMonth();
                    const year = rDate.getFullYear();
          */
          //         if (day == d.getDate() && month == d.getMonth() && year == d.getFullYear()) {

          self.reservationList.push(data);
          //          }
        });

        console.log("Checking ", self.reservationList, this.global.allWeek);
        self.checkSectionCapacity();

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  goPOS() { this.router.navigate(['pos']); }

  getWeekReservations(d: Date) {  // From d to d+6 inclusive
    var self = this;

    this.firstDay = getFirstDayOfWeek(this.value);


    //  var dLast = new Date(this.firstDay);

    var dLast = addDays(this.firstDay, this.startDays);
    console.log("Getting res totals for dates ", this.firstDay.toDateString(), dLast);



    // SET UP CALENDAR FOR WEEK
    self.allWeekResList = [];
    for (var n = 0; n++; n <= this.startDays) {
      this.allWeekResList[n] = [];
      this.allWeekTotals[n] = [];
    }
    this.createWeekTimeArray(0, this.firstDay);

    var db = firebase.firestore();

    db.collection("reservations").where("companyID", "==", self.global.myCompany.id).where("status", "==", "open")
      //   .where("resDate.date", ">=", this.firstDay).where("resDate.date", "<=", "dLast")
      .get()
      .then((querySnapshot) => {
        self.allWeekResList = [];
        for (var n = 0; n++; n <= self.startDays) {
          this.allWeekResList[n] = [];

        }
        querySnapshot.forEach((doc) => {

          const data = <reservation>doc.data();
          //    console.log("res", self.global.myCompany.id, data);

          const theDate = data.resDate.date.toDate();
          if (theDate >= this.firstDay && theDate <= dLast) {
            var dayOfWeek = data.resDate.date.toDate().getDay();

            if (!self.allWeekResList[dayOfWeek])
              self.allWeekResList[dayOfWeek] = [];

            self.allWeekResList[dayOfWeek].push(data);
          }

        });

        console.log("Checking ", self.allWeekResList);
        self.checkWeekSectionCapacity();

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  updateEnabled() {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.global.myCompany.id);

    ref.update({
      enabled: self.global.myCompany.enabled,
    })
      .then(() => {
        console.log("Enabled updated: ");
      })
      .catch((error) => {
        console.error("Error writing enableds: ", error);
      });
  }



  // ASSUMES List contains same table, same day 
  // Group by time for sections with multi-tables
  checkWeekSectionCapacity() {
    var self = this;
    var r: reservation;

    var timeReserve: number;
    var minTime: number;
    var sectionTotal: number;
    var maxTables: number;
    timeReserve = self.global.myCompany.reserveSettings.timeBlock.min;

    for (var day = 0; day < 7; day++) {

      // Sort by times 
      //  this.allWeekResList[day].sort(function (a, b) { return a.resTime.msTime - b.resTime.msTime; });

      //    console.log("Gathering totals from ", this.allWeekResList, this.allWeekTotals);

      if (this.allWeekResList[day])
        this.allWeekResList[day].forEach(function (arrayRes, index) {
          // count

          minTime = self.global.myCompany.reserveSettings.timeBlock.min;
          timeReserve = self.global.myCompany.reserveSettings.timeBlock.min;
          sectionTotal = self.global.myCompany.reserveSettings.maxOccupancy;

          if (self.allWeekTotals[day]) {
            self.allWeekTotals[day].forEach(function (arrayItem: resTotals, index) {

              if (arrayItem.resTime.msTime >= arrayRes.resTime.msTime && arrayItem.resTime.msTime < (arrayRes.resTime.msTime + (timeReserve * 60000))) {
                // Within Time of Requested seating - Update occupied records
                arrayItem.parties++;
                arrayItem.seatsOccupied += arrayRes.partySize;

                // console.log ("times ",arrayItem.resTime.msTime,arrayRes.resTime.msTime);
                if (arrayItem.resTime.msTime == arrayRes.resTime.msTime) { // IF RES TIME
                  arrayItem.seatsReserved += arrayRes.partySize;
                }
                if (arrayRes.tableObj) {
                  arrayItem.tablesReserved++;
                  arrayItem.tableSeatsReserved += arrayRes.partySize;
                }
              }
            });
          }
        });
    }
    //   console.log("Occupancy totals: ", self.allWeekTotals);

  }






  // ASSUMES List contains same table, same day 
  // Group by time for sections with multi-tables
  checkSectionCapacity() {
    var self = this;
    var r: reservation;

    var timeReserve: number;
    var minTime: number;
    var sectionTotal: number;
    var maxTables: number;
    timeReserve = self.global.myCompany.reserveSettings.timeBlock.min;


    // Sort by times 
    this.reservationList.sort(function (a, b) { return a.resTime.msTime - b.resTime.msTime; });

    console.log("Gathering totals from ", this.reservationList, this.availableTimes);
    this.reservationList.forEach(function (arrayRes, index) {
      // count

      minTime = self.global.myCompany.reserveSettings.timeBlock.min;
      timeReserve = self.global.myCompany.reserveSettings.timeBlock.min;
      sectionTotal = self.global.myCompany.reserveSettings.maxOccupancy;

      if (self.availableTimes) {
        self.availableTimes.forEach(function (arrayItem: resTotals, index) {

          if (arrayItem.resTime.msTime >= arrayRes.resTime.msTime && arrayItem.resTime.msTime < (arrayRes.resTime.msTime + (timeReserve * 60000))) {
            // Within Time of Requested seating - Update occupied records
            arrayItem.parties++;
            arrayItem.seatsOccupied += arrayRes.partySize;

            // console.log ("times ",arrayItem.resTime.msTime,arrayRes.resTime.msTime);
            if (arrayItem.resTime.msTime == arrayRes.resTime.msTime) { // IF RES TIME
              arrayItem.seatsReserved += arrayRes.partySize;
            }
            if (arrayRes.tableObj) {
              arrayItem.tablesReserved++;
              arrayItem.tableSeatsReserved += arrayRes.partySize;
            }
          }
        });
      }
    });
    console.log("Occupancy totals: ", self.availableTimes);


  }

  createTimeArray(day: number, d: Date) {
    // must be 0-6 (sun, sat)
    var start, end, n;

    console.log("DAY: ", this.global.allWeek, day);
    //HACK HACK to convert to start of week as 0=monday
    //  if (day) day--;
    //  else day = 6;

    if (this.global.allWeek[day].resCloseTime) {


      this.closingTime = this.global.allWeek[day].resCloseTime.msTime;
    }
    start = this.global.allWeek[day].resOpenTime.msTime;
    console.log("Start Time: ", start, day);

    var today = new Date();
    var now = new Date(),
      then = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0, 0, 0),
      diff = now.getTime() - then.getTime(); // difference in milliseconds


    end = this.global.allWeek[day].resCloseTime.msTime - (this.global.myCompany.reserveSettings.timeBlock.min * 60000);
    n = start;
    this.availableTimes = [];

    //??   this.UpdateOptionNames();

    while (n <= end) {
      // add a time slot

      let aTime: resTime = {
        msTime: 0,
        strTime: ""
      }

      aTime.msTime = n;
      var hours = Math.floor(n / 3600000);
      var minutes = Math.floor((n - (hours * 3600000)) / 60000);


      let amPm = "am";
      if (hours >= 12) {
        amPm = "pm";
        if (hours > 12) hours -= 12;
      }

      if (minutes < 10)
        aTime.strTime = hours + ':0' + minutes + " " + amPm;
      else
        aTime.strTime = hours + ':' + minutes + " " + amPm;

      let resTot: resTotals = {
        resDate: null,
        resTime: aTime,
        tablesReserved: 0,
        tableSeatsReserved: 0,
        seatsReserved: 0,
        seatsOccupied: 0,
        parties: 0,
      }
      this.availableTimes.push(resTot);
      n += (this.global.myCompany.reserveSettings.timeSkip.min * 60000);
    }
    console.log("Times Available: ", this.availableTimes, start, end);


  }


  getMyDay(day: number) {

    var dateStr = getMonthYear(this.allWeekTotals[day][0].resDate);
    return dateStr

  }

  getMyDay2(d: Date) {

    var dateStr = getMonthYear(d);
    return dateStr
  }

  getMyShortDate(d: Date) {

    var dateStr = getMonthDay(d);
    return dateStr
  }
  hourClicked(hour: resTotals) {
    console.log("Clicked date/hour: ", hour);
    this.global.newResDate = hour.resDate;
    this.global.newResTime = hour.resTime;
  }
  getMyWeekDay(day: number) {

    if (!this.allWeekTotals && !this.allWeekTotals[day]) return "goobers";
    var weekdayStr = getDayofWeek(this.allWeekTotals[day][0].resDate);
    return weekdayStr;
  }

  getMyWeekDay2(d: Date) {

    if (!this.allWeekTotals && !d) return "goobers";
    var weekdayStr = getDayofWeek(d);
    return weekdayStr;

  }


  createWeekTimeArray(day: number, d: Date) {
    // must be 0-6 (sun, sat)
    var self = this;
    var start, end, n;

    //  console.log(this.global.openDays, day);  // should be 0

    // Find earliest time in day
    var earliestTime = ((23 * 60) + 59) * 60000;
    this.global.allWeek.forEach(function (item, index) {
      if (self.global.openDays[index] && item.resOpenTime.msTime < earliestTime) earliestTime = item.resOpenTime.msTime;
    })


    this.allWeekTotals = [];
    for (var oneDay = 0; oneDay < 7; oneDay++) {
      this.allWeekTotals[oneDay] = [];

      this.closingTime = this.global.allWeek[day].resCloseTime.msTime;

      start = earliestTime;
      console.log("Start Time: ", start, day);

      var today = new Date();
      var now = new Date(),
        then = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          0, 0, 0),
        diff = now.getTime() - then.getTime(); // difference in milliseconds


      end = this.global.allWeek[day].resCloseTime.msTime - (this.global.myCompany.reserveSettings.timeBlock.min * 60000);
      n = start;

      while (n <= end) {
        // add a time slot

        let aTime: resTime = {
          msTime: 0,
          strTime: ""
        }

        aTime.msTime = n;
        var hours = Math.floor(n / 3600000);
        var minutes = Math.floor((n - (hours * 3600000)) / 60000);


        let amPm = "am";
        if (hours >= 12) {
          amPm = "pm";
          if (hours > 12) hours -= 12;
        }

        if (minutes < 10)
          aTime.strTime = hours + ':0' + minutes + " " + amPm;
        else
          aTime.strTime = hours + ':' + minutes + " " + amPm;

        let resTot: resTotals = {
          resDate: addDays(d, oneDay),
          resTime: aTime,
          tablesReserved: 0,
          tableSeatsReserved: 0,
          seatsReserved: 0,
          seatsOccupied: 0,
          parties: 0,
        }
        if (!this.global.allWeek[oneDay].resOpenTime || aTime.msTime < this.global.allWeek[oneDay].resOpenTime.msTime) {
          resTot.notOpen = true;
        }
        else resTot.notOpen = false;
        this.allWeekTotals[oneDay].push(resTot);

        n += (this.global.myCompany.reserveSettings.timeSkip.min * 60000);
      }

    }
    console.log("Times Available: ", this.allWeekTotals, start, end);


  }

  //style="background-image: linear-gradient(to right, rgba(240, 215, 249, 0.9) 50%, rgba(218, 241, 116, 0.9) 50%);"
  //[ngStyle]="getMyStyle(time)"

  getAvailableSeats(time: resTotals) {

    return this.global.myCompany.reserveSettings.maxOccupancy - time.seatsOccupied;
  }

  getPrettyDate(date: Date) {

    return (dateFormatter(date));

  }

  timeStyle(time: resTotals, d) {
    var styles: any;

    styles = {
      'width': '100%',
      'margin': '0px',
      'background': 'white',
      'text-align': 'left',
      'color': 'rgb(18, 12, 23)',
    };

    /*  
      if (time.notOpen || !this.global.myCompany.reserveSettings.openDays[d]) {
        styles = {
          'width': '100%',
          'margin': '0px',
          'background': 'lightgray',
          'text-align': 'left',
           'color': 'gray',
        };
        
      }
      */

    //  console.log ("occ style: ", reserved, styles);

    return styles;

  }

  getBoxStyle(time: resTotals) {
    var styles: any;

    styles = {
      'width': '100%',
      'margin': '0px',
      'background': 'white',
      'text-align': 'left',
      'color': 'rgb(18, 12, 23)',
    };

    var dayNum = time.resDate.getDay();

    if (!this.global.myCompany.reserveSettings.openDays[dayNum]) {
      styles = {
        'height': '70%',
        'margin': '0px',
        'background': 'lightgray',
        'text-align': 'left',
        'color': 'gray',
      };

    }


    //  console.log ("occ style: ", reserved, styles);

    return styles;

  }




  amIOpen(time: resTotals) {
    var dayNum = time.resDate.getDay();
    if (time.notOpen || !this.global.myCompany.reserveSettings.openDays[dayNum]) {
      return false;
    }
    return true;


  }


  seatsAvailableStyle(time: resTotals, d) {

    var occupied = time.seatsOccupied * 100 / this.global.myCompany.reserveSettings.maxOccupancy;
    var reserved = time.seatsReserved * 100 / this.global.myCompany.reserveSettings.maxOccupancy;

    var avail = this.getAvailableSeats(time);

    var availPercent = avail * 100 / this.global.myCompany.reserveSettings.maxOccupancy;


    var styles: any;

    styles = {
      'height': '100%',
      'width': availPercent + '%',
      'margin-top': '0px',
      'margin-bottom': '0px',
      'padding-bottom': '0px',
      'background': 'lightgreen',
      'text-align': 'right'
    };

    var dayNum = time.resDate.getDay();
    if (time.notOpen || !this.global.myCompany.reserveSettings.openDays[dayNum]) {
      styles = {
        'height': '100%',
        'width': '100%',

        'margin': '0px',
        'color': 'white',
        'text-align': 'left',

      };

    }

    //  console.log ("occ style: ", reserved, styles);

    return styles;

  }

  getMyWidth(d) {
    // console.log("goober: ",d );

    var closed = 0;
    var big = 14;
    var little = 7;

    this.global.openDays.forEach(function (arrayItem) {
      if (!arrayItem) closed++;
    })

    if (closed == 1) { big = 15; little = 10; }
    if (closed == 2) { big = 16; little = 10; }
    if (closed == 3) { big = 17; little = 10; }
    if (closed == 4) { big = 20; little = 10; }
    if (closed == 5) { big = 25; little = 10; }


    if (this.global.openDays[d]) return big;
    else return little;


  }

  seatsOccupiedStyle(time: resTotals, d) {

    var occupied = time.seatsOccupied * 100 / this.global.myCompany.reserveSettings.maxOccupancy;
    var reserved = time.seatsReserved * 100 / this.global.myCompany.reserveSettings.maxOccupancy;

    var styles: any;

    styles = {
      'height': '100%',
      'width': occupied - reserved + '%',
      'margin-top': '0px',
      'margin-bottom': '0px',
      'padding-bottom': '0px',
      'background': 'lightblue',
      'text-align': 'center'
    };
    if (time.notOpen /*|| !this.global.myCompany.reserveSettings.openDays[d]*/) {
      styles = {
        'height': '100%',
        'width': '100%',
        'margin': '0px',
        'color': 'white',
        'text-align': 'left'
      };

    }
    //  console.log ("occ style: ", reserved, styles);

    return styles;

  }
  seatsReservedStyle(time: resTotals) {

    var occupied = time.seatsOccupied * 100 / this.global.myCompany.reserveSettings.maxOccupancy;
    var reserved = time.seatsReserved * 100 / this.global.myCompany.reserveSettings.maxOccupancy;

    var styles: any;

    //  if (occupied)
    styles = {
      'height': '100%',
      'width': reserved + '%',
      'margin-top': '0px',
      'margin-bottom': '0px',
      'padding-bottom': '0px',
      'background': 'yellow',
      'text-align': 'left'
    };

    if (time.notOpen) {
      styles = {
        'height': '100%',
        'width': 'auto',
        'margin-top': '0px',
        'margin-bottom': '0px',
        'padding-bottom': '0px',
        'color': 'white',
        'text-align': 'left'
      };

    }
    //console.log ("Reserved count: ", reserved, styles);

    return styles;

  }
  getMyStyle(time: resTotals) {

    var occupied = time.seatsOccupied * 100 / this.global.myCompany.reserveSettings.maxOccupancy;
    var reserved = time.seatsReserved * 100 / this.global.myCompany.reserveSettings.maxOccupancy;

    var styles: any;

    //  if (occupied)
    styles = {
      'height': '100%',
      'margin-top': '0px',
      'margin-bottom': '0px',
      'padding-bottom': '0px',
      'background': 'linear-gradient(to right,yellow 0 ' + reserved.toString() + '%, lightblue ' + reserved.toString() + '% ' + occupied.toString() + '%, lightgreen ' + occupied.toString() + '% 100%);',
      'background-repeat': 'no-repeat'
    };


    //console.log ("Reserved count: ", reserved, styles);

    return styles;
  }

}

function getFirstDayOfWeek(d: Date) {

  const date = new Date(d);
  const day = date.getDay();
  const diff = date.getDate() - day;

  return new Date(date.setDate(diff));
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

function dateFormatter(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  // date.toDateString();

  return date.toLocaleDateString('en-US', timeformat);

}

function getMonthYear(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  return date.toLocaleDateString('en-US', timeformat);
}

function getDayofWeek(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
  };
  return date.toLocaleDateString('en-US', timeformat);
}


function getMonthDay(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  return date.toLocaleDateString('en-US', timeformat);
}
