import { Component, Output, Input, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";

import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, Sheet, CellData, SheetSettings, RowColSettings, Calendar, Theme,Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { MediaService } from "../media//media-functions/media-functions"
import { Quote } from '../content-interface';
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'


@Component({
  selector: 'app-sheets',
  templateUrl: './sheets.component.html',
  styleUrls: ['./sheets.component.css']
})
export class SheetsComponent implements OnInit {
  @Input() sheet: Sheet;
  @Input() userId: string;
  @Input() companyId: string;

  constructor(private route: ActivatedRoute, public global: Globals,
    public nav: NavService, private sanitizer: DomSanitizer,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {
    if (!this.global.allSheets) this.global.allSheets = []

  }

  ngOnInit(): void {
  //  Logger("Sheets Started", this.global.authuser.uid,"")
      this.watchSheets()
      this.sheet = { }
  }

  addSheet(event) {
    var self = this;
    var newSheet = {
      uid: this.global.authuser.uid,
      name: "New Sheet",
   //   createdAt: Date.now(),
   //   createdBy: self.global.authuser.uid,
   //   editedAt: Date.now(),
   //   editedBy: self.global.authuser.uid,
      sheetSettings:  {cellWidth:80, cellHeight:26, name: "New sheet", rows: 10, cols: 12},
      sheetData: [],

    }
    self.global.allSheets.splice(0,0,newSheet) // Insert at top

  }

  getSheetName(sheet: Sheet) {
    if (sheet.name) return sheet.name;
  }

  watchSheets() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;
    console.log("Watch sheets: ",  self.global.authuser.uid)

    db.collection('company').doc(self.global.myCompany.id)
    .collection('sheets').where("uid", "==", self.global.authuser.uid)
      .onSnapshot((querySnapshot) => {
        if (!self.global.allSheets) self.global.allSheets = []
        console.log("Have sheets")
        querySnapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const m = <Sheet>change.doc.data();
           
            if (self.global.allSheets.findIndex(x => x.id === m.id) == -1)
              self.global.allSheets.push(m)
          }
          if (change.type === "modified") {
            //  const m = <Form>change.doc.data();
            //     const index = self.global.mediaGroups.findIndex(x => x.id === m.id)
            //HAVE NO IDEA WHY NOT NEEDED   if (index != -1) self.global.mediaGroups[index] = {...m}
            console.log("Modified city: ", change.doc.data());
          }

        });
        /*
        self.global.allChatBots.sort(function (a, b) {
          if (!a.createdAt || !a.editedAt) {
            a.editedAt = Date.now()
          }
          if (!b.createdAt || !b.editedAt) {
            b.editedAt = Date.now()
          }
          return a.editedAt - b.editedAt;
        });
        */
        console.log("sheets: ", self.global.allSheets);

      });

  }

  onSheetOpen(e, i) {
    var self = this;
    self.sheet = self.global.allSheets[i];

  }

  onSheetClose(e, i) {
    var self = this;
  }
}
