/*
  Handle all cases for Settings including New User
  If no user then present marketing onboarding registration fields
  plus a login button
*/


import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common'
import { Globals } from 'src/app/globals';
import { Company, Form, Field, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings, ContainerSettings } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../../components/register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../../components/customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { Creds, Follows, User, Notify } from '../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { SafePipe } from 'safe-pipe';
import { ChatSettingsComponent } from '../../components/chatnow/settings/settings.component';
import { MediaService } from '../media/media-functions/media-functions';
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service';

export interface EnabledOption {
  name: string;
  code: string;
}

export interface Nub {
  name: string;
  value: any;

}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss', '../../common.scss']
})
export class SettingsComponent implements OnInit {

  @Input() newFeature: string;

  myForm: string = "login";

  emailForm: Form;
  formUser: Form;

  store: string;
  firstName: string;
  lastName: string;

  reserveEmail: string = "";
  reservePhone: string = "";
  value4: string;
  newUserID: string;
  newStore: string;
  newCompanyID: string;
  daysOfWeek: string[] = ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"];

  //  iframeChat: string = "<iframe id='angularFrame2'\n title='My Chat App'\n frameBorder='0' \nsrc='https://openbabble.com/chat/4390' \nwidth='360' height='800'>\n</iframe>"

  //  enabledOptions: EnabledOption[];
  //  whenEnabledSelection: string = "always";

  showMap: boolean = false;
  company: Company;
  busOnlineOnly: boolean = false;
  busName: string;
  busPhone: string = "";
  address: string = "";
  city: string = "";
  state: string = "";
  zipcode: string = "";
  storeURL: string = "";
  daySelected: boolean[];
  checked: boolean[];
  newDept: string = "";

  fields: Field[];
  form: Form;

  chatEntry: boolean = false;

  allTimes: resTime[];
  selectedTimes: resTime[];
  selectedCloseTimes: resTime[];

  allDurations: Duration[];
  selDuration: Duration;

  allAdvNotices: Duration[];
  selAdvNotice: Duration;

  allTimeGaps: Duration[];
  selTimeGap: Duration;

  storeNum: string;

  resSettings: ReservationSettings;
  resDays: ReservationDay[];

  baseURL: string;
  notOpenChatMsg: string;
  entryFront: boolean = false;
  qrCodeAPI: string = "http://api.qrserver.com/v1/create-qr-code/?data=";

  storeList: string[];
  storeSettings: ContainerSettings;

  daysHours: boolean = false;

  constructor(public global: Globals,
    public mediaService: MediaService,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public formService: FormService,
    public nav: NavService) {
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER
    this.store = this.route.snapshot.queryParamMap.get('store'); // this.name = 'testing'

    this.baseURL = this.router.navigate.toString();
    console.log("base url: ", this.route);
    /*    this.enabledOptions = [
          { name: 'Enabled 24/7', code: 'always' },
          { name: 'Enabled only during open hours', code: 'openhours' }
        ];
        this.notOpenChatMsg = "Our business is currently closed so our response may be delayed until we are open."
    */
  }

  ngOnInit(): void {
    let self = this;
    this.primengConfig.ripple = true;
    this.daySelected = [];
    this.checked = [];
    this.storeList = ["Company Info", "Google Business Info", "Days & Hours", "Departments", "Manage Staff"]
    this.storeSettings = { width: "33%", backgroundColor: "#00c4aa" }
    console.log("Init Settings ", this.newFeature);

    this.baseURL = this.router.navigate.toString();
    console.log("base url: ", this.router.routerState.snapshot.url, window.location.hostname); //????
    if (window.location.hostname == "openbabble.com") {
      this.global.entryPoint = "openbabble";
      this.chatEntry = true;
    }
    console.log("ro:", this.router.routerState.snapshot)
    if (this.router.routerState.snapshot.url == "/front") {
      this.entryFront = true;
    }



    console.log("entry point: ", this.global.entryPoint);

    //  if (this.global.entryPoint != "openbabble")
    this.InitReservations();


    //    this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value! HACK HACK

    if (this.global.authuser) {
    }
    else {
      // As ENTRY-POINT

      firebase.initializeApp(environment.firebase);


      // CHECK IF USER/DEVICE IS REGISTERED
      const id = localStorage.getItem('uid');
      if (id) {
        console.log("Found User");
        this.nav.GetUserwID(id);
        this.newUserID = id;
      }
      else {
        console.log("No User");
        this.nav.GetStore(this.global.store);
      }
    }


    this.createTimeArray();

    this.allDurations = [
      { name: '15 min', min: 15 },
      { name: '30 min', min: 30 },
      { name: '45 min', min: 45 },
      { name: '1 hour', min: 60 },
      { name: '1.5 hours', min: 90 },
      { name: '2 hours', min: 120 },
      { name: '4 hours', min: 240 },
      { name: 'Rest of day', min: 800 },
    ];
    this.selDuration = this.allDurations[2];  // get From data

    this.allAdvNotices = [
      { name: '15 min', min: 15 },
      { name: '1 hour', min: 60 },
      { name: '1.5 hours', min: 90 },
      { name: '2 hours', min: 120 },
      { name: '4 hours', min: 240 },
      { name: 'Day before', min: 1440 },
      { name: '2 days', min: 2880 },    // may need better logic
      { name: '3 days', min: 4000 },
    ];
    this.selAdvNotice = this.allAdvNotices[3];  // get From data

    this.allTimeGaps = [
      { name: '15 min', min: 15 },
      { name: '30 min', min: 30 },
      { name: '1 hour', min: 60 },
    ];
    this.selTimeGap = this.allTimeGaps[3];  // get From data

    var uid = "Anon"
    if (this.global.authuser) uid = this.global.authuser.uid
    Logger("Settings", uid, this.store, "")


  }
  onlineOnly() {
    this.busOnlineOnly = !this.busOnlineOnly;

  }
  Clicked(command: string) {

    console.log("Clicked ", command);
    switch (command) {
      case "Add Department":
     //   this.addDept(this.newDept);
        this.newDept = "";
        break;

    }
  }

  convertDay(day: string, dayStr: string) {
    var self = this;

    var open: boolean;
    var openTime: string;
    var closeTime: string;

    if (!dayStr || dayStr.length == 0 || dayStr.includes('losed')) {
      open = false;
    }
    else {
      open = true;
      // Now get open/close times

      const index = 0;

      const regex = /[\u002D\u058A\u05BE\u2010\u2011\u2012\u2013\u2014\u2015\u2E3A\u2E3B\uFE58\uFE63\uFF0D]/g;

      const index2 = dayStr.search(regex);
     // const index2 = dayStr.indexOf("\u8211")

      openTime = dayStr.substring(0, index2)
      closeTime = dayStr.substring(index2+1)

      var hours = parseInt(openTime)
      if (openTime.toLowerCase().includes('p')) hours += 12;

      //year, month, day, hour, minute, second, and millisecond
      var t1 = new Date(2024, 0, 0, hours, 0, 0)
      hours = parseInt(closeTime)
      if (closeTime.toLowerCase().includes('p')) hours += 12;
      var t2 = new Date(2024, 0, 0, hours, 0, 0)

      console.log("1, 2 ", dayStr.charCodeAt(2),  hours, self.prettyTime(t1), self.prettyTime(t2), dayStr, index, index2, openTime, closeTime);
      openTime = self.prettyTime(t1);
      closeTime = self.prettyTime(t2);
    }

    switch (day) {
      case 'monday':
        self.global.myCompany.daysHours.monday = open;
        self.global.myCompany.daysHours.mondayOpen = openTime;
        self.global.myCompany.daysHours.mondayClose = closeTime;
        break;
      case 'tuesday':
        self.global.myCompany.daysHours.tuesday = open;
        self.global.myCompany.daysHours.tuesdayOpen = openTime;
        self.global.myCompany.daysHours.tuesdayClose = closeTime;
        break;
      case 'wednesday':
        self.global.myCompany.daysHours.wednesday = open;
        self.global.myCompany.daysHours.wednesdayOpen = openTime;
        self.global.myCompany.daysHours.wednesdayClose = closeTime;
        break;
      case 'thursday':
        self.global.myCompany.daysHours.thursday = open;
        self.global.myCompany.daysHours.thursdayOpen = openTime;
        self.global.myCompany.daysHours.thursdayClose = closeTime;
        break;
      case 'friday':
        self.global.myCompany.daysHours.friday = open;
        self.global.myCompany.daysHours.fridayOpen = openTime;
        self.global.myCompany.daysHours.fridayClose = closeTime;
        break;
      case 'saturday':
        self.global.myCompany.daysHours.saturday = open;
        self.global.myCompany.daysHours.saturdayOpen = openTime;
        self.global.myCompany.daysHours.saturdayClose = closeTime;
        break;
      case 'sunday':
        self.global.myCompany.daysHours.sunday = open;
        self.global.myCompany.daysHours.sundayOpen = openTime;
        self.global.myCompany.daysHours.sundayClose = closeTime;
        break;
    }

  }

  prettyTime(date: Date) {
    const timeformat: Intl.DateTimeFormatOptions = {
      hour12: true,
      timeStyle: 'short',
    };

    return date.toLocaleTimeString('en-US', timeformat).toLowerCase();
  }

  getGoogleDays(event) {
    var self = this;

    this.convertDay('monday', self.global.myCompany.googleBusinessInfo.working_hours.Monday[0])
    this.convertDay('tuesday', self.global.myCompany.googleBusinessInfo.working_hours.Tuesday[0])
    this.convertDay('wednesday', self.global.myCompany.googleBusinessInfo.working_hours.Wednesday[0])
    this.convertDay('thursday', self.global.myCompany.googleBusinessInfo.working_hours.Thursday[0])
    this.convertDay('friday', self.global.myCompany.googleBusinessInfo.working_hours.Friday[0])
    this.convertDay('saturday', self.global.myCompany.googleBusinessInfo.working_hours.Saturday[0])
    this.convertDay('sunday', self.global.myCompany.googleBusinessInfo.working_hours.Sunday[0])

    self.daysHours = true;
    self.updateStore(false)
  }
  daysHoursOutput(field: Field) {
    var self = this;

    if (!self.global.myCompany.daysHours) {
      //  if (self.global.myCompany.hasGoogleInfo)
      self.global.myCompany.daysHours =
      {
        monday: true, mondayOpen: "9:00 am", mondayClose: "5:00 pm",
        tuesday: true, tuesdayOpen: "9:00 am", tuesdayClose: "5:00 pm",
        wednesday: true, wednesdayOpen: "9:00 am", wednesdayClose: "5:00 pm",
        thursday: true, thursdayOpen: "9:00 am", thursdayClose: "5:00 pm",
        friday: true, fridayOpen: "9:00 am", fridayClose: "5:00 pm",
        saturday: true, saturdayOpen: "9:00 am", saturdayClose: "5:00 pm",
        sunday: true, sundayOpen: "9:00 am", sundayClose: "5:00 pm",
      }
    }


    console.log("Output from DAYSHOURS: ", field)
    self.formService.napkinFieldToObject(field, self.global.myCompany.daysHours)
    self.daysHours = true;


    self.updateStore(false)


  }
  departmentsOutput(form: Form) {
    var self = this;
    self.formService.napkinFieldsToObject(form, self.global.myCompany)

    var db = firebase.firestore();
    var ref = db.collection("company").doc(self.global.myCompany.id);

    ref.update({departments: self.global.myCompany.departments})
      .then(() => {
          console.log("Bus dept settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing  settings: ", error);
      });

  }

  settingsOutput(form: Form) {
    var self = this;

    var address1 = self.global.myCompany.address1;
    var city = self.global.myCompany.city;
    var state = self.global.myCompany.state;
    var zipcode = self.global.myCompany.postal;

    self.formService.napkinFieldsToObject(form, self.global.myCompany)

    var addressChanged = false;
    if (address1 != self.global.myCompany.address1 ||
      city != self.global.myCompany.city ||
      state != self.global.myCompany.state ||
      zipcode != self.global.myCompany.postal
    )
      addressChanged = true;

    console.log("Company info ", form, self.global.myCompany)
    self.updateStore(addressChanged)

  }








  getJoinCode() {

    return this.qrCodeAPI + 'napkinhq.com/join/' + this.global.myCompany.store + '&size=200x200';
  }

  createForm() {
    var self = this;

    return; // one time

    self.fields.forEach(function (field) {
      self.addField(field);
    })

  }

  isEmailFound(form: Form) {
    var self = this;

    console.log("Got it ", form);
    self.emailForm = form;
    form.showSubmit = false;

  }

  addField(field: Field) {

    var db = firebase.firestore();
    var self = this;

    var formRef = db.collection("company").doc("5vjmmQOnSx31XLsMDvZx").collection("forms").doc("wwRCytpCepFCOoKV05xL");
    formRef.update({
      fields: firebase.firestore.FieldValue.arrayUnion(field)
    })


  }
/*

  addDept(dept) {
    var self = this;
    var db = firebase.firestore();

    if (!this.global.myCompany.departments) this.global.myCompany.departments = [];
    this.global.myCompany.departments.push(dept);

    var docRef = db.collection("company").doc(this.global.myCompany.id);

    var setWithMerge = docRef.set({
      departments: self.global.myCompany.departments
    }, { merge: true });

    console.log("Department Added");

  }
  */

  updateHoursDays() {
    this.registerService.updateDaysHours();
  }

  getComponentName() {
    if (this.chatEntry) return "OPENBABBLE"
    else return "NAPKIN"
  }

  getComponentOverview() {
    var str = "The simplest way to accept reservations or appointments"

    if (this.chatEntry)
      str = "The simplest way to connect with prospects & customers"

    return str;
  }

  getComponentDetails() {
    var str = "Includes everything needed to bring more guests to your establishment. Try it for free."
    if (this.chatEntry)
      str = "A complete online chat with advanced functions and super easy to use. Easy to add to any existing site."
    return str;
  }



  output(event: Nub[]) {
    var self = this;

    console.log("OUTPUT", event)

    event.forEach(function (field) {
      if (field.name == 'State') {
        self.state = field.value;
      }
      if (field.name == 'City') {
        self.city = field.value;
      }
      if (field.name == 'zipcode') {
        self.zipcode = field.value;
      }


    })






  }

  zipcodeLookup() {
    console.log("Get zipcode ")

    this.zipcodeToCityState();


  }

  async zipcodeToCityState() {
    var self = this;

    /*
        post code:"90210"
        country:"United States"
        country abbreviation:"US"
        places:0:
        place name:"Beverly Hills"
        longitude:"-118.4065"
        state:"California"
        state abbreviation:"CA"
        latitude:"34.0901"*/


    var ZipCity = {
      "post code": 12345, "country": "here", "country abbreviation": "AB",
      "places": [{ "place name": "there", "state abbreviation": "AX" }]

    }






    var url = 'https://community-zippopotamus.p.rapidapi.com/us/';
    url += this.zipcode;
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'community-zippopotamus.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();

      ZipCity = result;

      console.log(ZipCity['post code']);
      self.city = ZipCity.places[0]['place name'];
      self.state = ZipCity.places[0]['state abbreviation'];


    } catch (error) {
      console.error(error);
    }

  }

  handleChange(e, i) {
    this.global.openDays[i] = e.checked;

    console.log(this.global.openDays, this.global.allWeek);

  }

  handleChangeOpenTime(e, i) {
    console.log(i + " index", e.value, this.global.allWeek[i].resOpenTime);
    this.global.allWeek[i].resOpenTime = e.value;
    this.global.allWeek[i].timeStart = e.value.msTime;

    console.log(this.daySelected);
  }
  handleChangeCloseTime(e, i) {
    this.resDays[i].resCloseTime = e.value;
    this.resDays[i].timeEnd = e.value.msTime;


  }


  login(form: Form) {
    this.global.displayMenu = false;

    const email = this.emailForm.fields[this.findMe("email", this.emailForm)].binding;
    const pw = form.fields[this.findMe("password", form)].binding;

    var self = this;
    console.log("LOGIN EMAIL; ", email);

    var db = firebase.firestore();

    db.collection("users").where("email", "==", email).where("pw", "==", pw)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          console.log("user ", data)
          self.global.authuser = data;
          Logger("Login", self.global.authuser.uid, "", data.firstName, data.lastName)

          localStorage.setItem('uid', data.uid);
          //          self.cartService.GetMyOrder();

          // Now enable the listener for User
          self.nav.GetUserwID(self.global.authuser.uid);
          //Now let's move to home... HACK - Put this in form as Action
          self.router.navigate(['/home'])


        });
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });
  }

  getClosed(index) {
    return "Closed " + this.daysOfWeek[index];

  }
  /*copyToClipboard() {
    console.log("Copied");
    // Copy to clipboard
    const cb = navigator.clipboard;
    if (cb) {
      cb.writeText(this.iframeChat);
    }
  }*/
  services: Enabled;


  findMe(me: string, form: Form) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { alert("oops " + me) }
    return index;

  }


  addChatServiceEmail() {
    var self = this;

    var chatService;
    var chatServiceEmail;
    var email = self.storeNum + "@napkinhq.com";

    var db = firebase.firestore();

    // For Chat - add chat store user to connect with
    if (self.services.chatService) {
      var docRef = db.collection("users").doc();
      self.registerService.RegisterChatServiceUser(
        email,
        docRef, "Customer", "Service", null, null);

      chatService = docRef.id;
      chatServiceEmail = email;

      var db = firebase.firestore();
      var ref = db.collection("company").doc(this.newCompanyID);

      ref.update({
        chatService: chatService,
        chatServiceEmail: chatServiceEmail
      })
        .then(() => {
          console.log("Chat service updated: ");
        })
        .catch((error) => {
          console.error("Error writing latlong settings: ", error);
        });

    }



  }


  updateLatLong(lat: number, long: number) {
    var self = this;

    if (!this.newCompanyID) this.newCompanyID = this.global.myCompany.id
    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.newCompanyID);

    if (lat) {
      self.global.mapOptions = {
        center: { lat: lat, lng: long },
        zoom: 14
      }
    }

    ref.update({
      lat: <number>lat,
      long: <number>long,
    })
      .then(() => {
        console.log("latlong settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing latlong settings: ", error);
      });
  }






  geoCode(adr, city, state, zip) {
    var self = this;


    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com'
      }
    };

    fetch('https://forward-reverse-geocoding.p.rapidapi.com/v1/forward?street=' + adr + '&city=' + city + '&state=' + state + '&postalcode=' + zip + '&accept-language=en&polygon_threshold=0.0', options)
      .then(response => response.json())
      .then(response => {
        console.log("GEO: ", response);
        if (response[0] && response[0].lat) {
          var lat: number = Number(response[0].lat);
          var lon: number = Number(response[0].lon);
          self.updateLatLong(lat, lon);
        }
      })
      .catch(err => console.error(err));

  }

  afterRegisterStore(self: this) {
    self.updateCreds();
    self.sendEmailNewStore(self.services);


    self.nav.InitReservations(); // Default settings - hours & days

    // Now re-route to /Front
    self.router.navigate(['/front'])


  }
  /*
  updateiFrame() {
    this.iframeChat =
      "<iframe id='angularFrame2'\n title='My Chat App'\n frameBorder='0' \nsrc='https://openbabble.com/chat/" +
      this.global.myCompany.store +
      "'\nwidth='360' height='800'>\n</iframe>"

  }*/

  goFront() {
    console.log("Front");
    this.router.navigate(['/front']);
  }
  GotoReserve() {
    this.router.navigate(['/reserve/' + this.global.myCompany.store]);
  }

  GotoChat() {
    window.open("https://openbabble.com/chat/" + this.global.myCompany.store, "_blank");
  }
  /*
    updateFollows() { // Move to common layer
      var self = this;
  
  
      var db = firebase.firestore();
      var ref = db.collection("users").doc(self.global.authuser.uid);
  
      //  var notify: Notify = {enable: true, reservations:true};
  
  
      var follow: Follows = {
        id: self.global.myCompany.id,     // remove later
        storeNum: this.global.myCompany.store,    // fix name
        points: 200,  // Company sets this
        startDate: Date.now(),
        storeName: this.global.myCompany.name
  
      }
      if (this.global.authuser.following) this.global.authuser.following.push(follow);
      else {
        this.global.authuser.following = [];
        this.global.authuser.following.push(follow);
      }
      console.log("Set follows: ", follow)
  
  
  
      ref.update({
        following: this.global.authuser.following
      })
        .then(() => {
          console.log("follows updated: ");
          // Save locally
          //      localStorage.setItem('myFollows', JSON.stringify(follows));
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  
    */

  updateCreds() {
    var self = this;


    var db = firebase.firestore();
    var ref = db.collection("users").doc(this.global.authuser.uid);

    var notify: Notify = { enable: true, reservations: true };
    var enable: Enabled = { staff: true }
    if (self.services.reviews == true) {
      notify.reviews = true;
      enable.reviews = true;
    }

    var creds: Creds[] = [{
      id: self.newCompanyID,
      storeNum: self.storeNum,
      role: "Creator",
      startDate: Date.now(),
      storeName: self.busName,
      notify: notify,
      enabled: enable,
    }]

    var follow: Follows[] = [{
      id: self.newCompanyID,     // remove later
      storeNum: self.storeNum,    // fix name
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: self.busName

    }]



    console.log("Set Creds: ", creds, self.global.authuser.uid, notify)

    ref.update({
      creds: firebase.firestore.FieldValue.arrayUnion(creds),
      following: firebase.firestore.FieldValue.arrayUnion(follow)
    })
      .then(() => {
        console.log("Creds updated: ");
        // Save locally
        //   localStorage.setItem('myCreds', JSON.stringify(creds));
        // It's safe now to reload page - still a hack
        //    window.location.reload();
        this.global.authuser.creds = creds; // add to user record


      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }






  updateHours() {
    var self = this;
    this.updateResSettings();
  }

  updateStore(newAddress: boolean) {
    var self = this;

    if (!self.newCompanyID) this.newCompanyID = self.global.myCompany.id
    var db = firebase.firestore();
    var ref = db.collection("company").doc(self.newCompanyID);

    var newData = <Company>{
      name: self.global.myCompany.name,
      address1: self.global.myCompany.address1,
      city: self.global.myCompany.city,
      state: self.global.myCompany.state,
      postal: self.global.myCompany.postal,
      primaryPhone: self.global.myCompany.primaryPhone,
    }
    if (self.daysHours) {
      newData.daysHours = self.global.myCompany.daysHours;
      console.log("DAYSHOURS: ", newData.daysHours)
    }

    if (self.global.myCompany.storeURL) {
      newData.storeURL = self.global.myCompany.storeURL;
    }
    if (self.global.myCompany.logoMedia) {
      newData.logoMedia = self.global.myCompany.logoMedia;
    }

    newData = removeUndefined(newData)

    ref.update(newData)
      .then(() => {
        self.daysHours = false;
        if (newAddress)
          self.geoCode(self.global.myCompany.address1, self.global.myCompany.city, self.global.myCompany.state, self.global.myCompany.postal);
        console.log("Bus info settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing res settings: ", error);
      });


  }

  


  updateResSettings() {
    var self = this;

    if (!this.newCompanyID) this.newCompanyID = this.global.myCompany.id
    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.newCompanyID);

    this.global.myCompany.reserveSettings.openDays = this.global.openDays;
    this.global.myCompany.reserveSettings.reserveDay = this.global.allWeek;

    this.resSettings.openDays = this.global.openDays;
    this.resSettings.reserveDay = this.global.allWeek;

    this.resSettings.timeBlock = this.global.myCompany.reserveSettings.timeBlock;
    this.resSettings.timeNotice = this.global.myCompany.reserveSettings.timeNotice;
    this.resSettings.timeSkip = this.global.myCompany.reserveSettings.timeSkip;
    this.resSettings.replyEmail = this.global.authuser.email;
    //DO I REALLY NEED THESE??
    this.resSettings.resPolicy = this.global.myCompany.reserveSettings.resPolicy;

    console.log("Saving Reservation Settings: ", this.newCompanyID, self.resSettings);

    ref.update({
      reserveSettings: self.global.myCompany.reserveSettings
    })
      .then(() => {
        console.log("Res settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing res settings: ", error);
      });
  }



  regUser(form: Form) {
    var self = this;

    this.formUser = form;

    const lastName = form.fields[this.findMe("lastname", form)].binding;
    const firstName = form.fields[this.findMe("firstname", form)].binding;
    const fullName = form.fields[this.findMe("full name", form)].binding;
    const email = self.emailForm.fields[self.findMe("email", self.emailForm)].binding;


    // VALIDATE INFO
    if (!this.global.authuser) {
      if (!lastName || firstName.length == 0) { alert("Please enter a name."); return; }
      if (!ValidateEmail(email)) return;
      //   if (!validatePhoneNumber(this.reservePhone)) return;
      this.newUserID = self.registerService.GetUID();
      self.registerService.RegisterNewUser(email, firstName, lastName);
      if (!this.newUserID) return;
    }


  }


  createTimeArray() {
    var start, end, n;


    start = 0;
    end = (24 * 60 * 60 * 1000) - 1;
    n = start;
    this.allTimes = [];


    while (n <= end) {
      // add a time slot

      let aTime: resTime = {
        msTime: 0,
        strTime: ""
      }

      aTime.msTime = n;
      var hours = Math.floor(n / 3600000);
      var minutes = Math.floor((n - (hours * 3600000)) / 60000);


      let amPm = "am";
      if (hours >= 12) {
        amPm = "pm";
        if (hours > 12) hours -= 12;
      }

      if (minutes < 10)
        aTime.strTime = hours + ':0' + minutes + " " + amPm;
      else
        aTime.strTime = hours + ':' + minutes + " " + amPm;

      this.allTimes.push(aTime);
      n += (30 * 60000);
    }
    console.log("Times Available: ", this.allTimes);


  }

  InitReservations() {

    this.daySelected = [true, false, false, false, false, true, true];
    this.resDays = [];
    this.selectedTimes = [];
    this.selectedCloseTimes = [];
    this.checked = [true, false, false, false, false, true, true];

    let openTime: resTime = {
      msTime: (12 * 60 * 60) * 1000,
      strTime: "12:00 pm"
    }

    let closeTime: resTime = {
      msTime: (18 * 60 * 60) * 1000,
      strTime: "6:00 pm"
    }

    let resDay: ReservationDay = {
      timeStart: (12 * 60 * 60) * 1000, // Noon as ms
      timeEnd: (18 * 60 * 60) * 1000,   // 6pm as ms
      lastSeating: (17 * 60 * 60) * 1000,   // 5pm as ms
      resOpenTime: openTime,
      resCloseTime: closeTime,
      skipMinutes: 15,
      minParty: 2,
      maxParty: 12
    }

    this.resDays.push(resDay); // Sunday 0
    this.resDays.push(resDay);
    this.resDays.push(resDay);
    this.resDays.push(resDay);
    this.resDays.push(resDay);
    this.resDays.push(resDay);
    this.resDays.push(resDay);  // Saturday 6

    this.resSettings = {
      openDays: this.checked,
      maxOccupancy: 50,
      maxTables: 10,
      reserveDay: this.resDays,
      minParty: 2,
      maxParty: 12,
      duration: 45
    }

    console.log("Default Res: ", this.resSettings);
  }

  public sendRegisterEmail() {
    var self = this;

    var templateParams = {
      from_name: 'Crystal Hill Vineyard',  // Company Name
      to_name: this.global.authuser.firstName, //this.global.userName,
      to_email: this.reserveEmail,
      message: 'Thanks for registering for Reservation Rewards.'
    };

    //  e.preventDefault();

    emailjs.send("service_jwders9", "template_fsas3w8", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {

        console.log("email err: ", error.text);
      });


  }

  /*
    VerifyEmail() {
      var self = this;
  
      console.log("VERIFY: ", this.validation);
      if (this.validation == this.global.authuser.keyCode) { // MATCH
        console.log("Verified");
        this.global.authuser.emailVerified = true;
        //   localStorage.setItem('uid', JSON.stringify(this.user.uid));
  
        var db = firebase.firestore();
  
        var catRef = db.collection("users").doc(this.global.authuser.uid);
        if (catRef) {
          catRef.update({
            "emailVerified": true
          });
          this.sendEmailVerified();
          // Set localstorage to reflect authenticated device
        }
  
        this.router.navigate(['menu']);
      }
    }
    */

  // ADD AUTO-PASSWORD to this email.
  // Also allow user to change password once verified
  public sendEmailNewStore(services: Enabled) {

    console.log("Sending New Store Email ", services);
    if (services.reservations) {  // HACK Need better code - please move emails to common module
      var templateParams = {
        from_name: 'Reservation Rewards!',  // Company Name
        to_name: this.busName,
        to_email: this.reserveEmail,
        store_num: this.storeNum,

        message: 'Welcome to Reservation Rewards!'
      };

      console.log("to: ", this.reserveEmail)
      //  e.preventDefault();
      emailjs.send("service_jwders9", "template_xm4q9ep", templateParams)
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    }

    if (services.chatService) {  // HACK Need better code - please move emails to common module
      var templateParams = {
        from_name: 'OpenBabble!',  // Company Name
        to_name: this.busName,
        to_email: this.reserveEmail,
        store_num: this.storeNum,

        message: 'Welcome to OpenBabble'
      };

      console.log("to: ", this.reserveEmail)
      //  e.preventDefault();
      emailjs.send("service_jwders9", "template_wka2btt", templateParams)
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });
    }
  }


  getForm() {
    var self = this;

    var db = firebase.firestore();

    db.collection("company").doc("5vjmmQOnSx31XLsMDvZx").collection("forms").doc("wwRCytpCepFCOoKV05xL")
      .get()
      .then((querySnapshot) => {
        const data = <Form>querySnapshot.data();
        self.form = data;
        console.log("form; ", self.form);
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });


  }

  getFields() {
    var self = this;

    var db = firebase.firestore();

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("fields")
      .get()
      .then((querySnapshot) => {
        self.fields = [];
        querySnapshot.forEach((doc) => {
          const data = <Field>doc.data();
          self.fields.push(data);
          console.log("Fields; ", self.fields);
        });
        self.createForm()
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });

  }

}




// MOVE TO COMMON MODULE
function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}

function validatePhoneNumber(input_str) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  if (re.test(input_str)) { return true; }
  else {
    alert("You have entered an invalid phone number!")
    return (false)
  }
}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}

