import { Field } from '../company-interface'


export class settingsTool extends Phaser.Scene {
//    text;
//    mode;
    self;
    masterSelf;
    draggingMe = false;
    settingFields: Field [];
    x1;y1;optionsContainer;
  
    constructor() {
      console.log("CREATE FORM ")
      super({  });
 //     this.text;
    }
    init (data)
    {
       
    }
  
    preload() {
  
  
    }
    create(data) {
        var self = this;
  
        console.log ("DATA ",data)
  
        const sceneB = <any>self.scene.get('VIP');
        this.masterSelf = sceneB.registry.get('masterSelf');
  
        this.masterSelf.VIPservice.getFields(data.formId, this.fieldsCallback, this)
    
      }

    fieldsCallback(fields, self) {
        self.settingFields = fields;
        console.log("GOT FIELDS ", self.settingFields)

        var x, y;
        x = 600;
        y =400;
    
        const dragMe = self.add.rectangle(x, y, 128, 20, 0x34def2, .75).setInteractive({ draggable: true });
        dragMe.setDisplayOrigin(0, 0)
        dragMe.setOrigin(0, 0)
        y += 20;
    
        const container = self.add.container(x, y)
        container.bringToTop(null);
        container.setAlpha(1)
        const background = self.add.rectangle(0, 0, 128, 200, 0x000e12, 1).setInteractive()
        background.setDisplayOrigin(0, 0)
        background.setOrigin(0, 0)
        container.add(background);
    
        dragMe.on('dragstart', (pointer, dragX, dragY) => {
          self.draggingMe = true;
        });
        dragMe.on('dragend', (pointer, dragX, dragY) => {
          self.draggingMe = false;
        });
    
        dragMe.on('drag', (pointer, dragX, dragY) => {
          container.setPosition(dragX, dragY + 20);
          dragMe.setPosition(dragX, dragY);
        });
    
        self.input.on('pointerover', (event, gameObjects) => {
          console.log("Hit me")
          container.setScale(1);
          dragMe.setScale(1);
          container.setY(dragMe.y + dragMe.height)
          self.scene.bringToTop();
    
        });
    
        self.input.on('pointerout', (event, gameObjects) => {
          if (!self.draggingMe) {
            container.setScale(.25);
            dragMe.setScale(.25);
            container.setY(dragMe.y + (dragMe.height * .25))
          }
  
        });
    
        x = 0; y = 0;
        var maxWidth = 0;
        var maxHeight = 0;
        self.settingFields.forEach(function (field, index) {
    
          var str  = field.label
          if (field.type == 'switch') {
            if (field.trueFalse) str += " yes"
            else str += 'no'
          }

          if (field.type == 'button') {
            
          }
            
          const bob = self.add.text(x+4, y+4, str).setInteractive();
          bob.setDisplayOrigin(0, 0)
          bob.setAlpha(1)
          bob.setOrigin(0, 0)
          bob.name = field.title;
          container.add(bob);
          const r = bob.getBounds()
          console.log ("Bounds ", r)
          if (r.width > maxWidth) maxWidth = r.width;
          maxHeight += r.height+7;
    
          bob.on('pointerup', (pointer, x, y, event) => {
            event.stopPropagation();
            console.log("Clicked ME??", bob.name, field)
 
            if (field.type.toLowerCase() == 'button') {
              self.masterSelf.fieldData(field)
            }
            var str  = field.label
            if (field.type == 'switch') {
              field.trueFalse = !field.trueFalse;
              if (field.trueFalse) str += " yes"
              else str += ' no'
              bob.setText(str);
              self.masterSelf.fieldData(field)

            }
            if (field.type == 'options' || field.type == 'select-button') {
              if (!field.optionsVisible) {
                // Show the options

                self.optionsContainer = self.add.container(container.x, container.y)
                self.optionsContainer.bringToTop(null);
                self.optionsContainer.setAlpha(1)

                const optionsBackground = self.add.rectangle(0, 0, 128, 200, 0xffffff, 1)
                optionsBackground.setDisplayOrigin(0, 0)
                optionsBackground.setOrigin(0, 0)
                self.optionsContainer.add(optionsBackground);


                self.x1 = 0; self.y1 = y;
                field.options.forEach(function(element){
                  const item = self.addButton(self.optionsContainer, element, field)
                 
                  
                });
                optionsBackground.setSize(128, self.y1+12)
              }


            }
            
                
          })
    
    
          x = 0
          y += 25;
        })
        // Now let's update sizes
        dragMe.setSize(maxWidth+8, 20)
        container.setSize(maxWidth+8, maxHeight+12);
        background.setSize(maxWidth+8, maxHeight+12)
     
 

      }
  
    addButton (container, label: string, field: Field) {
      var self = this;

      const opt = self.add.text(this.x1+4, self.y1+4, label);
      opt.setColor("black")
      opt.setDepth(10000)
      opt.setFontSize(22)
      opt.setDisplayOrigin(0, 0)
      opt.setAlpha(1)
      opt.setOrigin(0, 0)
      opt.name = label;
      const r = opt.getBounds();
      const rect = self.add.rectangle(self.x1, self.y1, r.width+8, r.height+8, 0xfaebd7).setInteractive();
      rect.setStrokeStyle(1, 0xefc53f);
      rect.setDisplayOrigin(0, 0)
      rect.setAlpha(1)
      rect.setOrigin(0, 0)
      rect.name = label;

      rect.on('pointerup', (pointer, x, y, event) => {
        event.stopPropagation();
        console.log("Clicked Option:",rect.name);
        field.selected=[rect.name]
        self.masterSelf.fieldData(field)
        self.optionsContainer.destroy();

      })




      self.x1 += r.width + 12;
      if (self.x1 >= 128) {
        self.x1 = 0
        self.y1 += r.height+8;
      }

      container.add ([rect,opt]);


    }

  }
  