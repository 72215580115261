<div style="width:100%; margin:20px;font-size: 1.3em; line-height:1.4em;" fxFlex fxLayout="column"
    fxLayoutAlign="center center">
    transactions {{transactions}}<br>

    Gross revenue: {{totalRevenue | currency}}<br>
    Tips: {{tips | currency}}<br>
    Sales Tax: {{salesTax | currency}}<br>
    Net revenue: {{netRevenue | currency}}


    <div fxLayout="column" fxLayoutAlign="end end">
        <div *ngFor="let o of orderData; let i = index">
            <div style="text-align: right">
                <span *ngIf="o.resultId=='Cash Payment'">Cash:</span>
                {{o.localDateTime+" "+ o.name}}[tip: {{o.tipAmount|currency}}] salesTax: {{o.salesTax|currency}} totalCharged:
                {{o.totalCharge|currency}}
            </div>
        </div>



    </div>
</div>