import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import firebase from 'firebase/compat';
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripeTerminal } from '@stripe/terminal-js';

function unexpectedDisconnect() {
  // You might want to display UI to notify the user and start re-discovering readers
}


@Component({
  selector: 'app-pay-stripe',
  templateUrl: './pay-stripe.component.html',
  styleUrls: ['./pay-stripe.component.css']
})

export class PayStripeComponent {

  paymentHandler: any = null;
  stripeAPIKey: any = 'pk_test_51KQwb3Q2rk2SmhdoWXsLXTumzO4H3vOgDUkoFDNloa9PSgyNpGSuhISKzu0jYwOMpDjBpimWiZ6A0nG1gOpNoael00dUQ0Lcyb';



  constructor(private http: HttpClient) { }

  ngOnInit() {

    this.startStripe();
    const discoverButton = document.getElementById('discover-button');
    discoverButton.addEventListener('click', async (event) => {
      this.discoverReaders();
    });

    const connectButton = document.getElementById('connect-button');
    connectButton.addEventListener('click', async (event) => {
      this.connectReaderHandler(discoveredReaders);
    });

    const collectButton = document.getElementById('collect-button');
    collectButton.addEventListener('click', async (event) => {
      this.amount = 3600; //document.getElementById("amount-input").innerHTML;
      this.collectPayment(this.amount);
    });

    const captureButton = document.getElementById('capture-button');
    captureButton.addEventListener('click', async (event) => {
      this.capture(this.paymentIntentId);
    });


  }

  collectPayment(amount) {
    var self = this;

    fetchPaymentIntentClientSecret(amount).then(function (client_secret) {
      self.terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' });
      self.terminal.collectPaymentMethod(client_secret).then(function (result) {
        if (result.error) {
          // Placeholder for handling result.error
        } else {
          console.log('terminal.collectPaymentMethod', result.paymentIntent);
          self.terminal.processPayment(result.paymentIntent).then(function (result) {
            if (result.error) {
              console.log(result.error)
            } else if (result.paymentIntent) {
              self.paymentIntentId = result.paymentIntent.id;
              console.log('terminal.processPayment', result.paymentIntent);
            }
          });
        }
      });
    });
  }

  capture(paymentIntentId) {
    return fetch('https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/capture_payment_intent', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "payment_intent_id": paymentIntentId })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        console.log('server.capture', data);
      });
  }




  terminal: any;
  paymentIntentId: any;
  amount: any;
  location: any;


  async startStripe() {
    const StripeTerminal = await loadStripeTerminal();


    this.terminal = StripeTerminal.create({
      onFetchConnectionToken: await fetchConnectionToken,
      onUnexpectedReaderDisconnect: unexpectedDisconnect,
    });

    console.log("Loadin STRIPE ", this.terminal)

    this.terminal.discoverReaders();
    // this.terminal = terminal;

 
  }
  // Handler for a "Discover readers" button
  discoverReaderHandler() {
    var config = { simulated: true };
    this.terminal.discoverReaders(config).then(function (discoverResult) {
      if (discoverResult.error) {
        console.log('Failed to discover: ', discoverResult.error);
      } else if (discoverResult.discoveredReaders.length === 0) {
        console.log('No available readers.');
      } else {
        discoveredReaders = discoverResult.discoveredReaders;
        console.log('terminal.discoverReaders', discoveredReaders);
      }
    });
  }
  async  discoverReaders() {
    const config = {simulated: false, 
      discoveryMethod: 'bluetoothScan',
      location: this.location
    }
    const discoverResult = await this.terminal.discoverReaders(config);
    if (discoverResult.error) {
      console.log('Failed to discover: ', discoverResult.error);
    } else if (discoverResult.discoveredReaders.length === 0) {
      console.log('No available readers.');
    } else {
      // You should show the list of discoveredReaders to the
      // cashier here and let them select which to connect to (see below).
    //  this.terminal.connectReader(discoverResult);
    }
  }

  // Handler for a "Connect Reader" button
  connectReaderHandler(discoveredReaders) {
    // Just select the first reader here.
    var selectedReader = discoveredReaders[0];
    this.terminal.connectReader(selectedReader).then(function (connectResult) {
      if (connectResult.error) {
        console.log('Failed to connect: ', connectResult.error);
      } else {
        console.log('Connected to reader: ', connectResult.reader.label);
        //log('terminal.connectReader', connectResult)
      }
    });
  }

}
var discoveredReaders;

function fetchPaymentIntentClientSecret(amount) {
  const bodyContent = JSON.stringify({ amount: amount });
  console.log ("amt: ", bodyContent)
  return fetch("https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/create_payment_intent", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: bodyContent
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data.client_secret;
    });
}


async function fetchConnectionToken() {
  console.log("FETCHING STRIPE")
  const res = await fetch("https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/connection_token", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({

    }),
    mode: 'cors',

  });
  const data = await res.json();
  console.log("Yeah: ", data)
//  this.location = createLocation();
  return data.secret;
}

async function createLocation() {
  console.log("Location")
  const res = await fetch("https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/createLocation", {
    method: "POST",


  });
  const data = await res.json();
  console.log("location: ", data)
  return data;
}

