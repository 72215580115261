import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Theme, EmailForm, CodeObject, Field, AppNode } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { LogicService } from '../logic/logic.component';
import { FormService } from '../form.service';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { QuerySnapshot } from 'firebase/firestore';
import { Router } from '@angular/router';
import { EmailService } from '../email.service';
import { CartService, SharedDataService } from '../menu/cart.service';
import { MediaService } from '../media/media-functions/media-functions';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
export interface Nub {
  name: string;
  value: any;

}

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css', '../../common.scss']
})
export class FormComponent implements OnInit, OnChanges {
  @Output() initEvent = new EventEmitter<Form>();
  @Output() objectEvents = new EventEmitter<any>();
  @Output() submitEvent = new EventEmitter<Form>();
  @Output() update = new EventEmitter<Form>();

  @Input() form: Form;
  @Input() theme: string;
  @Input() themeId: string;
  @Input() node: AppNode;
  @Input() formName: string;
  @Input() formId: string;
  @Input() formData: Field;
  @Input() displayAll: boolean = false;
  @Input() formParent: Form;
  @Input() pwrequired: boolean = true;
  @Input() formInput: Form;
  @Input() objectInput: any;
  @Input() appName: string;
  @Input() autoSubmit: boolean = false;
  @Input() localStorage: boolean = true;
  @Input() displayGroup: string[];

  //@Input() dayofweek: string; // generalize to json

  media: Media[];
  showMedia: boolean = false;
  message: string;
  fontSize: string;
  feedback: string;

  editData: boolean = false;

  success: string;
  hideForm: boolean = false;

  constructor(public global: Globals, private sanitizer: DomSanitizer, public logicService: LogicService, public registerService: RegisterService,
    public nav: NavService, private router: Router,
    private emailService: EmailService,
    public mediaService: MediaService,
    public cartService: CartService,
    public formService: FormService, private scroller: ViewportScroller) {

  }
  ngOnChanges(changes: SimpleChanges) {
    var self = this;

    return; // no idea what this is for HACK HACK


    const log: string[] = [];
    if (changes?.formInput?.currentValue) {
      console.log("Changes ----> ", changes, changes.formInput.currentValue)
      self.form = changes.formInput.currentValue;
      self.editData = true;
    }

    for (const propName in changes) {
      const changedProp = changes[propName];
      const to = JSON.stringify(changedProp.currentValue);
      if (changedProp.isFirstChange()) {
        log.push(`Initial value of ${propName} set to ${to}`);
      } else {
        const from = JSON.stringify(changedProp.previousValue);
        log.push(`${propName} changed from ${from} to ${to}`);
      }
    }
  }


  ngOnInit(): void {
    console.log("Input: ", this.pwrequired, this.form, this.formInput, this.formName, this.formId, this.formData, this.formParent)

    if (this.formParent) {
      this.updateNumberFields(this.formParent);
    }

    if (this.formInput) {
      console.log("Have data input ", this.formInput);
    }

    if (!this.form) {
      console.log("No Form, ID or Name? ", this.form, this.formId);

      if (this.formId) {
        if (this.formName.startsWith("master"))
          this.getMasterForm(this.formName);
        else
          this.getForm("", this.formId);
      }
      else if (this.formName) {
        if (this.formName.startsWith("master"))
          this.getMasterForm(this.formName);
        else
          this.getForm(this.formName);
      }
      else {
        console.log("So REALLY no form - auto-create? ")
        // Not here    this.formService.createForm("My new Form", this.formCreated, this);
      }

    }
    else {
      // Get theme
      if (this.form?.themeId) {
        // var myTheme = this.global.allThemes.find(x => x.id === this.form.themeId);
        // if (myTheme) this.theme = myTheme;
      }
      else if (!this.themeId) {
        const index = this.global.allThemes.findIndex(p=>p.name=='Base')
        this.form.themeId = this.global.allThemes[index].id;

        console.log("DEFAULT THEME: ", this.themeId)
      }
      else {
        this.form.themeId = this.themeId;
      }
      console.log("Load Form for field editing...", this.form)
      // Have form check for positions
      if (this?.form?.fields)
        this.form.fields.sort(function (a, b) {
          if (a.position == null) a.position = 99;
          if (b.position == null) b.position = 99;
          if (a.position > b.position) return 1;
          if (a.position < b.position) return -1;
          return 0;
        })
      this.importFieldData();
      this.formInputData();
      this.checkShow();
      // After init Event
      this.initEvent.emit(this.form)
    }



  }

  formCreated(self, form) {
    self.form = form;
    // After init Event
    //  self.initEvent.emit(self.form)

  }

  checkGroups(field: Field) {
    var self = this;

    if (!self.displayGroup || !self.displayGroup?.length) return true;
    if (!field.fieldGroup || !field.fieldGroup.length) return true;
    if (self.displayGroup.includes(field.fieldGroup)) return true;
    else return false;

  }

  formInputData() {
    var self = this;

    if (self.formInput) { // Update FIELDS from object
      var values;
      var keys = Object.keys(self.formInput);
      values = Object.values(self.formInput);

      console.log("FORMINPUT ", self.formInput, keys, values)
      if (keys.length)
        keys.forEach(function (key, dataIndex) {
          var index = self.findMe(key, self.form);
          //    console.log("Input value ", index, key, self.form)
          if (index != -1) { // NOW SET Field value fields (binding, trueFalse etc.)
            self.form.fields[index].binding = values[dataIndex];
            if (typeof (self.formInput[key]) == 'object') {

              const me = self.formInput[key];

              console.log("type: ", me, self.form.fields[index].type)
              if (me?.length && me[0]?.url) {
                self.form.fields[index].media = self.formInput[key];
              }
              // fieldSelector
              if (self.form.fields[index].type == 'fieldSelector') {
                console.log("Setting fields from Input: ", me)
                self.form.fields[index].fieldSource = me;
              }



            }
            if (self.form.fields[index].type == 'number' ||
              self.form.fields[index].type == 'currency'
            ) {
              self.form.fields[index].value = values[dataIndex];
            }
            if (self.form.fields[index].type == 'result'
            ) {
              self.form.fields[index].value = values[dataIndex];
            }

            if (self.form.fields[index].type == 'date') {
              console.log("Setting Date from Input: ", values[dataIndex])
              self.form.fields[index].value = values[dataIndex];
            }
            if (self.form.fields[index].type == 'displayHTML' || self.form.fields[index].type == 'HTMLeditor') {
              self.form.fields[index].html = values[dataIndex];
              console.log("Setting HTML CONTENT ", self.form.fields[index].html)
            }
            if (self.form.fields[index].type == 'switch' || self.form.fields[index].type == 'checkbox')
              self.form.fields[index].trueFalse = values[dataIndex];

            if (self.form.fields[index]?.options ||
              self.form.fields[index].type == 'tags' ||
              self.form.fields[index].type == 'multi-select' ||
              self.form.fields[index].type == 'select-button') {

              console.log("FORM INPUT SELECT: ", values[dataIndex])
              if (is_array(self.formInput[key])) {
                self.form.fields[index].selected = values[dataIndex];
                self.form.fields[index].binding = values[dataIndex];
                if (self.form.fields[index]?.dataSource == 'Report tags')
                  self.form.fields[index].options = values[dataIndex];
              }
              else {
                //     self.form.fields[index].options = [values[dataIndex]];

                self.form.fields[index].selected = [values[dataIndex]];
                self.form.fields[index].binding = values[dataIndex]; //???

              }
              console.log("Options selected: ", self.form.fields[index].selected)

            }
          }
        })
    }
  }


  getFullWidth() {
    var style;
    style = this.global.getScreenWidth + 'px;'
    console.log("FW: ", style)
    return style;
  }




  getMasterForm(formName: string) {
    var self = this;

    console.log("Get master form ", formName, self.themeId)

    if (self.node?.userForm) {
      console.log("This is a user master form ")
      // Get the form locally, if not exist then copy from master
      self.getForm(formName, self.formId, self.node.userForm)
      return;
    }


    var db = firebase.firestore();

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("forms")
      .where("name", "==", formName)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Form>doc.data();
          // Get theme
          if (data.themeId) {
            //  var myTheme = self.global.allThemes.find(x => x.id === data.themeId);
            //  if (myTheme) self.theme = myTheme;
          }
          else if (!self.themeId) {
            const index = self.global.allThemes.findIndex(p=>p.name=='Base')
            data.theme = self.global.allThemes[index].name;
            data.themeId = self.global.allThemes[index].id;
          }
          else {
            data.themeId = self.themeId;
          }

          console.log("Get master form ", formName, self.themeId)


          // Have form check for positions
          data.fields.sort(function (a, b) {
            if (a.position == null) a.position = 99;
            if (b.position == null) b.position = 99;
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            return 0;
          })


          if (typeof data.showSubmit === 'undefined') {
            data.showSubmit = true;  // allow submit.
            console.log("SUBMIT-SHOW ")
          }

          self.form = data;

          if (!self.objectInput && self.formInput && self.formInput?.fields && self.formInput.fields.length > 0) {
            console.log("FORM HAS INPUT DATA: ", self.formInput)
            self.formInput.fields.forEach(function (field) {

              var index = self.findMe(field.title, self.form);
              if (index != -1) {
                if (field.binding)
                  self.form.fields[index].binding = field.binding;
                if (field.selected)
                  self.form.fields[index].selected = field.selected;
              }

            })
          }
          else if (self.objectInput || self.formInput) { // Update FIELDS from object
            var values;
            if (0 && self.objectInput) {
              var keys = Object.keys(self.objectInput);
              values = Object.values(self.objectInput);
            }
            else {
              var keys = Object.keys(self.formInput);
              values = Object.values(self.formInput);
            }
            console.log("FORMINPUT ", self.formInput, keys, values)
            if (keys.length)
              keys.forEach(function (key, dataIndex) {
                var index = self.findMe(key, self.form);
                // IF NOT FOUND CHECK IF THIS IS AN Array of Objects

                if (self.form.fieldGroups && self.form.fieldGroups.includes(key)) {
                  // WE HAVE AN OBJECT ARRAY TO BREAK DOWN IN FIELDS
                  // We assume that index==0 exists to move data to
                  // Any index>0we need to create a new field first
                  // Get and set value after found
                  if (self.formInput[key]?.length) {
                    console.log("Convert ARRAY OBJECT to fields ", self.form.fieldGroups)
                    self.formInput[key].forEach(function (fieldGrpObject, index) {
                      console.log("Object ", fieldGrpObject, index)
                      self.convertObjectFieldtoNapkinField(fieldGrpObject, key, index)
                    })

                    // Now we can add the Insert New button
                    self.form.fields.push({ type: "groupAdd", newLine: true, fieldGroup: self.form.fieldGroups[0] })
                  }
                }

                //    console.log("Input value ", index, key, typeof (self.formInput[key]))
                if (index != -1) { // NOW SET Field value fields (binding, trueFalse etc.)
                  self.form.fields[index].binding = values[dataIndex];
                  if (typeof (self.formInput[key]) == 'object') {

                    const me = self.formInput[key];

                    console.log("type: ", me, self.form.fields[index].type)
                    if (me?.length && me[0]?.url) {
                      self.form.fields[index].media = self.formInput[key];
                    }
                    // fieldSelector
                    if (self.form.fields[index].type == 'fieldSelector') {
                      console.log("Setting fields from Input: ", me)
                      self.form.fields[index].fieldSource = me;
                    }

                  }

                  if (self.form.fields[index].type == 'number' ||
                    self.form.fields[index].type == 'currency'
                  ) {
                    self.form.fields[index].value = values[dataIndex];
                  }
                  if (self.form.fields[index].type == 'result'
                  ) {
                    self.form.fields[index].value = values[dataIndex];
                  }

                  if (self.form.fields[index].type == 'date') {
                    console.log("Setting Date from Input: ", values[dataIndex])
                    self.form.fields[index].value = values[dataIndex];
                  }
                  if (self.form.fields[index].type == 'displayHTML' || self.form.fields[index].type == 'HTMLeditor') {
                    self.form.fields[index].html = values[dataIndex];
                    console.log("Setting HTML CONTENT ", self.form.fields[index].html)
                  }
                  if (self.form.fields[index].type == 'switch' || self.form.fields[index].type == 'checkbox')
                    self.form.fields[index].trueFalse = values[dataIndex];

                  if (self.form.fields[index]?.options ||
                    self.form.fields[index].type == 'tags' ||
                    self.form.fields[index].type == 'multi-select' ||
                    self.form.fields[index].type == 'select-button') {

                    console.log("FORM INPUT SELECT: ", values[dataIndex])
                    if (is_array(self.formInput[key])) {
                      self.form.fields[index].selected = values[dataIndex];
                      self.form.fields[index].binding = values[dataIndex];
                      if (self.form.fields[index]?.dataSource == 'Report tags')
                        self.form.fields[index].options = values[dataIndex];
                    }
                    else {
                      //     self.form.fields[index].options = [values[dataIndex]];

                      self.form.fields[index].selected = [values[dataIndex]];
                      self.form.fields[index].binding = values[dataIndex]; //???

                    }
                    console.log("Options selected: ", self.form.fields[index].selected)

                  }
                  if (self.form.fields[index]?.falseHide) {
                    self.form.fields[index].falseHide.forEach(function (item) {

                      var found = self.form.fields.find((f) => f.title == item)
                      console.log("FIND ", item, found)
                      if (found) {
                        found.display = self.form.fields[index].trueFalse;
                      }
                      else console.log("DID NOT FIND ", item)
                    })
                  }
                }

              })
            //  self.handleOutputs(); //

            if (0 && self.form.fieldGroups) {
              // Let's Sort fields to ensure correct order
              self.form.fields.sort(function (a, b) {
                //  if (a?.fieldGroup?.length==0 || b?.fieldGroup?.length==0) return 0;
                //  if (a.groupIndex == b.groupIndex) return 0;
                //  if (a.fieldGroup != 'buttonStyles') return 0;
                //  if (b.fieldGroup != 'buttonStyles') return 0;
                if (a.groupIndex != b.groupIndex)
                  console.log("Move field ", a, b)
                return a.groupIndex - b.groupIndex;
              });
              console.log("AFTER SORT ", self.form.fields)
            }


          }
          self.checkShow(); // showOnly
          /*
                    data.fields.forEach(function (field) {
                      if (field?.showOnly) {
                        // SHOW THIS FIELD ONLY IF ANOTHER SELECTION FIELD IS SET TO ?
                        var found = false;
                        field.showOnly.forEach(function (item) {
                          data.fields.forEach(function (field) {
                            if (field.selected && field.selected.length) {
                              if (field.selected.includes(item))
                                found = true;
                            }
                          })
                        })
                        console.log("SHOW ONLY CHECK ", found, field)
                        field.display = found;
                      }
                    })
                      */

          console.log("formDATA; ", self.form, self.formData, self.formInput);

          self.importFieldData()
          // After init Event
          self.initEvent.emit(self.form)
        })

      })
      .catch((error) => {
        console.log("no user found: ", error);
      });
  }

  convertObjectFieldtoNapkinField(inputObj: any, groupKey, index: number) {
    var self = this;

    var keys = Object.keys(inputObj);
    var values = Object.values(inputObj);
    //  var keys2 = Object.entries(inputObj)
    console.log("Array Object Keys: ", keys, index)

    if (index == 0) { // First row logic    
      if (keys.length)
        keys.forEach(function (key, dataIndex) {
          var fieldIndex = self.findMe(key, self.form);
          if (fieldIndex != -1) {
            self.form.fields[fieldIndex].binding = <string>values[dataIndex];
          }
          console.log("Found Object Array Field ", fieldIndex, values[dataIndex])
        })
    }
    else { // Additional row logic -> insert new rows
      // First check to ensure that data row does not exist
      // if it does then just iterate keys as above...

      // If it does not then add new fields from object based on first row
      var zeroElement = self.form.fields.findIndex(x => x.fieldGroup == groupKey && x.groupIndex == 0)
      if (zeroElement != -1) {
        // Push now splice later
        self.form.fields.push({ title: "new line", type: 'newLine', newLine: true })
        console.log("zeroElement ", zeroElement, self.form.fields)
        var done = false;
        do {
          var bob: Field = {};
          var endIndex = self.form.fields.push(Object.assign(bob, self.form.fields[zeroElement]))
          endIndex--;
          self.form.fields[endIndex].groupIndex = index;
          zeroElement++;
          //  endIndex++;
          if (self.form.fields[zeroElement].fieldGroup != groupKey
            || self.form.fields[zeroElement].groupIndex != 0)
            done = true;

        } while (!done)
        // Now we can just copy values ...
        if (keys.length)
          keys.forEach(function (key, dataIndex) {
            var fieldIndex = self.findMeInGroup(key, self.form, index);
            if (fieldIndex != -1) {
              self.form.fields[fieldIndex].binding = <string>values[dataIndex];
            }
            console.log("Found Object Array Field ", fieldIndex, values[dataIndex])
          })
        // Now let's move the Add Another button
        const buttonIndex = self.form.fields.findIndex(function (post) {
          if (post.type == 'groupAdd' && post.fieldGroup == groupKey)
            return true;
        });
        if (buttonIndex != -1) {
          console.log("Removing add another button.....", buttonIndex)
          self.form.fields.splice(buttonIndex, 1);
          //moved to calling func      self.form.fields.push({ type: "groupAdd", newLine: true, fieldGroup: groupKey })
        }
      }
    }
  }

  checkRequirements() { // IS THIS FORM ACCESSABLE??
    var ok = true;
    var self = this;

    if (self.form.loginRequired && !self.global?.authuser)
      ok = false;
    if (self.form.validEmailRequired) {
      if (!self.global?.authuser) ok = false;
      else if (!self.global?.authuser.emailVerified)
        ok = false;
    }
    return ok;
  }

  setMargins() {
    var str = ""
    if (this.form.marginLeft) str += 'margin-left: ' + this.form.marginLeft + 'px;'
    if (this.form.marginTop) str += 'margin-Top: ' + this.form.marginTop + 'px;'
    if (this.form.marginRight) str += 'margin-Right: ' + this.form.marginRight + 'px;'
    if (this.form.marginBottom) str += 'margin-Bottom: ' + this.form.marginBottom + 'px;'
    return str;
  }

  checkShow() {
    var self = this;
    // showOnly is a query;

    self.form.fields.forEach(function (field) {
      if (field?.showOnly) {
        var found = self.evalQuery(field.showOnly)
        field.display = found;
        //   console.log("SHOW ONLY CHECK ", found, field, field.showOnly)

      }
    })
  }

  evalQuery(query) {
    var self = this;

    //  console.log("GOT QUERY TO EVAL: ", query)


    // NOW RUN THE FILTER
    var field;
    var fieldValue: string | string[];
    var operator;
    var value: string;
    var adder: string;
    var truthy: boolean;

    query.forEach(function (item, index) {
      var x = index % 4;
      switch (x) {
        case 0: // It's a field
          field = item;
          break;
        case 1: // It's an operator
          operator = item;
          break;
        case 2: // It's a value ... or field
          value = item;
          break;
        case 3: // It's an adder
          adder = item;
          break;
      }
      if (field && operator && value) {
        if (!adder) { // BASE QUERY
          // we have name of field to check/access selections 
          // to check if includes value

          var found = false;

          self.form.fields.forEach(function (myField) {
            if (myField.title == field) {
              if (myField.selected && myField.selected.length) {
                if (myField.selected.includes(value))
                  found = true;
              }
            }
          })
          if (found) truthy = true;
          else truthy = false;
        }
        if (adder && adder.toLowerCase() == 'or') {
          // If already TRUE THEN TRUE
          if (truthy && truthy == true) {
            // We can skip the OR
          }
          else {
            var truthy2: boolean;

            // PUT CODE BELOW IN FUNC
            var found = false;

            self.form.fields.forEach(function (myField) {
              if (myField.title == field) {
                if (myField.selected && myField.selected.length) {
                  if (myField.selected.includes(value))
                    found = true;
                }
              }
            })
            if (found) truthy2 = true;
            if (truthy2) truthy = true;
          }
        }
        if (adder && adder.trim().toLowerCase() == 'and') {
          var truthy2: boolean = false;
          // PUT CODE BELOW IN FUNC
          var found = false;

          self.form.fields.forEach(function (myField) {
            if (myField.title == field) {
              if (myField.selected && myField.selected.length) {
                if (myField.selected.includes(value))
                  found = true;
              }
            }
          })
          //     console.log("The AND clause: ", found, truthy)
          if (found) truthy2 = true;
          if (truthy && truthy2) truthy = true;
          else truthy = false;
        }


        //   console.log("Single Query... ", adder, field, operator, value, truthy);

        field = undefined;
        operator = undefined;
        value = undefined;
        // adder = undefined;
      }


    })

    //  console.log("evalQuery result:", query, truthy)

    return truthy;

  }


  importFieldData() {
    var self = this;

    // OLD SHOULD GO AWAY

    if (self.formData) {
      self.form.fields.forEach(function (field) {
        if (field.title == 'Title') {
          field.binding = self.formData.title;

        }
        if (field.title == 'Field type') {
          field.binding = self.formData.type;
        }
        if (field.title == 'Class') {
          field.binding = self.formData.class;
        }
        if (field.type == 'results') {
          if (self.formParent.numberFields)
            field.available = self.formParent.numberFields;
        }
        if (field.title == 'Mask') {
          field.binding = self.formData.mask;
        }
        if (field.type == 'multi-select') {
          // Let's show and populate the field

          //   field.available = field.available.concat(self.formData.options);
          field.display = true;
        }


      })
      console.log("formDATA; ", self.form, self.formData);
    }

  }

  formStyle() {
    var str;

    if (this.form.backgroundColor)
      str = "background-color: " + this.form.backgroundColor + ";"
    return str;
  }

  getForm(formName: string, formId?: string, userForm?: boolean) {
    var self = this;

    var db = firebase.firestore();

    var q1;
    console.log("formload ", self.formInput, formName, formId, userForm)

    if (formName.length) {
      if (userForm)
        q1 = db.collection("users").doc(self.global.authuser.uid).collection("forms")
      else
        q1 = db.collection("company").doc(self.global.myCompany.id).collection("forms")

      q1.where("name", "==", formName)
        .get()
        .then((querySnapshot: QuerySnapshot) => {
          if (querySnapshot.empty) {
            console.log("DID NOT FIND YOUR FORM")
            // SO Let's create it from Master
            //   self.formService.createFromMaster(formName, formId, userForm)
          }
          querySnapshot.forEach((doc) => {
            const data = <Form>doc.data();
            console.log("form data ", data)
            // Get theme
            if (data.themeId) {
              //  var myTheme = self.global.allThemes.find(x => x.id === data.themeId);
              //  if (myTheme) self.theme = myTheme;
            }
            else if (!self.themeId) {
              const index = self.global.allThemes.findIndex(p=>p.name=='Base')
              data.theme = self.global.allThemes[index].name;
              data.themeId = self.global.allThemes[index].id;
            }
            else {
              data.themeId = this.themeId;
            }

            if (!data.fields) data.fields = [];

            data.formId = doc.id;

            // Have form check for positions
            data.fields.sort(function (a, b) {
              if (a.position == null) a.position = 99;
              if (b.position == null) b.position = 99;
              if (a.position > b.position) return 1;
              if (a.position < b.position) return -1;
              return 0;
            })

            if (typeof data.showSubmit === 'undefined') {
              data.showSubmit = true;  // allow submit.
              console.log("SHOW SUBMIT")
            }
            self.form = data;

            if (self.formInput && self.formInput?.fields?.length) {
              console.log("FORM HAS INPUT DATA: ", self.formInput)
              self.formInput.fields.forEach(function (inputField) {
                const index = self.form.fields.findIndex(item => item.title === inputField.title);
                if (index != -1) {
                  self.form.fields[index].binding = inputField.binding;
                  if (inputField.selected)
                    self.form.fields[index].selected = inputField.selected;
                }
              })

              if (self.formInput && !self.form.fields) { // Update FIELDS from object
                var keys = Object.keys(self.formInput);
                var values = Object.values(self.formInput);
                if (keys.length)
                  keys.forEach(function (value, dataIndex) {
                    var index = self.findMe(value, self.form);
                    console.log("Input value ", index, self.form)
                    if (index != -1) { // NOW SET Field value fields (binding, trueFalse etc.)
                      if (self.form.fields[index].type == 'switch' || self.form.fields[index].type == 'checkbox')
                        self.form.fields[index].trueFalse = values[dataIndex];
                      if (self.form.fields[index]?.options) {
                        self.form.fields[index].selected = [values[dataIndex]];
                        //Options??
                        self.form.fields[index].binding = values[dataIndex];

                      }

                    }
                    if (0 && self.form.fields[index]?.showOnly) {
                      // SHOW THIS FIELD ONLY IF ANOTHER SELECTION FIELD IS SET TO ?
                      var found = false;
                      self.form.fields[index].showOnly.forEach(function (item) {
                        self.form.fields.forEach(function (field) {
                          // Maybe just check selected field directly??
                          //  if (field.type == 'tag' || field.type=='select-button' || field.type=='multi-select') {

                          if (field.selected && field.selected.length) {
                            if (field.selected.includes(item))
                              found = true;
                          }

                        })

                      })
                      console.log("SHOW ONLY CHECK ", found)

                      self.form.fields[index].display = found;

                    }
                    self.checkShow(); // showOnly

                    if (self.form.fields[index]?.falseHide) {
                      self.form.fields[index].falseHide.forEach(function (item) {

                        var found = self.form.fields.find((f) => f.title == item)
                        console.log("FIND ", item, found)
                        if (found) {
                          found.display = self.form.fields[index].trueFalse;
                        }
                        else console.log("DID NOT FIND ", item)
                      })
                    }




                  })
              }

              //  self.form = {...self.formInput};
            }
            console.log("formDATA; ", self.form);
            // After init Event
            self.initEvent.emit(self.form)
            // self.scroller.scrollToAnchor("formtop");

          })
        })
        .catch((error) => {
          console.log("no doc found: ", error);
        });

    }
    else if (formId) {
      q1 = db.collection("company").doc(self.global.myCompany.id).collection("forms").doc(formId);

      q1.get().then((doc) => {
        const data = <Form>doc.data();
        console.log("form data ", data)
        if (data) {
          // Get theme
          if (data.themeId) {
            //  var myTheme = self.global.allThemes.find(x => x.id === data.themeId);
            //  if (myTheme) self.theme = myTheme;
          }
          else if (!self.themeId) {
            const index = self.global.allThemes.findIndex(p=>p.name=='Base')
            data.theme = self.global.allThemes[index].name;
            data.themeId = self.global.allThemes[index].id;
          }
          else {
            data.themeId = this.themeId;
          }

          if (!data.fields) data.fields = [];

          data.formId = doc.id;

          // Have form check for positions
          data.fields.sort(function (a, b) {
            if (a.position == null) a.position = 99;
            if (b.position == null) b.position = 99;
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            return 0;
          })
          if (typeof data.showSubmit === 'undefined') {
            data.showSubmit = true;  // allow submit.
            console.log("SJHOIDHJFJOSUBMIT")
          }
          self.form = data;

          if (self.formData) {
            self.form.fields.forEach(function (field) {
              if (field.title == 'Title') {
                field.binding = self.formData.title;
                console.log("updated")
              }
            })
          }
          if (self.formInput) {
            console.log("FORM HAS INPUT DATA: ", self.formInput)
            self.form = self.formInput;
          }
          console.log("formDATA; ", self.form);
          self.form.tags = [];
          self.form.tags.push(self.appName);


          self.updateNumberFields(self.form);

          //    self.form.showSubmit = true;  // allow submit.

          //??NOtsure   self.scroller.scrollToAnchor("formtop");
        }
      })
        .catch((error) => {
          console.log("no doc found: ", error);
        });

    }

  }

  updateNumberFields(form: Form) {
    var self = this;

    form.numberFields = [];
    form.fields.forEach(function (field) {
      if (field.type == 'number' || field.type == 'currency' || field.type == 'result') { //hack use tags
        form.numberFields.push(field.title)
      }
    })

    console.log("NUMBER FIELD LIST: ", form.numberFields, self.global.allForms)

    form.fields.forEach(function (field) {
      if (field.type == 'results') {
        console.log("NUMBER FIELD LIST: ", form.numberFields)

        field.available = [...form.numberFields];
      }


    })
  }

  showSubmit() {

    return this.form.showSubmit;

  }

  outputEvent(event) {
    var self = this;
    console.log("Form object event", event, self.objectEvents);
    self.editData = true;
    self.objectEvents.emit(event)

  }

  submitForm() {
    var self = this;

    // if (self.form?.noSubmit == true) return; // No form level action please;


    console.log("Submit form ", self.update, self.form, self.submitEvent);


    self.form.fields.forEach(function (field) {
      if (field.type == 'fullName' && field.binding && field.binding.length > 4) {
        const arr = field.binding.split(' ');
        console.log("Name ", arr);
        var lastName = arr[1];

        field.outputs = [
          {
            name: 'firstName',
            value: arr[0]
          },
          { name: "lastName", value: lastName.trimEnd() }
        ]

      }
    })


    // NEXT STEP - CHECK THAT ALL REQUIRED FIELDS ARE VALID

    var allRequired = true;
    self.feedback = "";
    self.form.fields.forEach(function (field) {
      if (field?.required && field.required == true) {
        // NEED A TEST FOR EVERY FIELD TYPE
        if (!field.binding && !field.value && !field.selected
          && !field.media && !field.html && !field.date) {
          allRequired = false;
          console.log("Required field ", field)
        }


      }
    })
    if (!allRequired) {
      // Give the User Some Feedback
      self.feedback = "Some required fields are empty."

      return;
    }



    if (self.form.hideForm == true) self.success = "Thanks for submitting the form."

    if (self.form.autoSave /* && (self.update || !self.form.formId)*/)
      self.saveForm(self.form);

    if (self.update && self.form.formId)
      self.update.emit(self.form)

    if (!self.global.allForms) self.global.allForms = [];


    const index = self.global.allForms.findIndex(function (post) {
      if (post.id == self.form.id)
        return true;
    });
    if (index != -1) {
      self.global.allForms[index] = { ...self.form }
    }

    if (index == -1)  // ONLY ONE COPY OF EACH FORM
      self.global.allForms.push(self.form);  //what the f? should have loaded all forms




    // Autohide and show Message if HTML present
    if (self.form.submitMsg) {
      self.form.hideForm = true;
    }


    // Check for Actions in the form

    if (self.form.actions) {
      self.form.actions.forEach(function (action, index) {
        if (action.action == "Close form") {
          self.form.hideForm = true;

        }

        if (action.action == "user-login") {
          self.registerService.login(self.form)

        }


        if (action.action == "Go to form") {
          console.log("Go to form: ", action.destination)

          var me = action.destination;


          if (me.startsWith("master"))
            self.getMasterForm(me);
          else
            self.getForm(me);

        }
        if (action.action == "success") {
          // Hide form and display success text
          self.success = "Blah"


        }
        if (action.action == "if-found") {
          var found = false;
          self.form.fields.forEach(function (field) {

            if (field.found) {
              found = true;
            }
            found = self.form.fields[0].found;
            console.log("FOUND: ", found, field, self.pwrequired)
            if (!self.pwrequired && self.form.fields[0].type == 'email') {
              if (found == true) {   // Hack Login w/o pw only for reviews
                self.loginNpw(self.form.fields[0].binding);

              }
            }

          })
          if (found && self.pwrequired) {
            var me = action.destination;
            console.log("Go to form: ", me)
            if (me.startsWith("master"))
              self.getMasterForm(me);
            else
              self.getForm(me);
          }
        }

        if (action.action == "if-not-found") {
          var found = false;
          self.form.fields.forEach(function (field) {
            if (field.found) {
              found = true;
            }
          })
          if (!found) {
            var me = action.destination;
            console.log("Not found goto form: ", me)
            if (me.startsWith("master"))
              self.getMasterForm(me);
            else
              self.getForm(me);
          }

        }

        if (action.action == "user-register") {
          self.regUser(self.form);

        }
        if (action.action == "hide-form") {
          self.form.hideForm = true;

        }
        if (action.action == "play-media") {
          console.log("Play Media ")
          self.media = action.media;
        }

        if (action.action == "message") {

          //self.message = self.form.html;
          self.form.hideForm = true;
          console.log("msg ", self.form.safeHtml)
        }


      })


    }

    // Let's scan Fields for Results and check for logic
    self.form.fields.forEach(function (field) {
      if (field.type == 'result' && field?.logic?.code)
        self.logicService.executeLogic(field.logic, null, field, self.codeCallback, self);

    })



    // Execute logic
    if (self.form.logic)
      self.logicService.executeLogic(self.form.logic, self.form, null, self.codeCallback, self);

    self.formService.checkEmbedded(self.form);



    console.log("Emitting: ", self.form)
    self.submitEvent.emit(self.form);



  }

  loginNpw(email) {
    //  this.global.displayMenu = false;

    var self = this;
    console.log("LOGIN NPW ", email)

    var db = firebase.firestore();

    /*
    
        email.global.allForms.forEach(function (form) {
          var found = form.fields.find((f) => f.type == 'email')
          if (found)
            email = found.binding;
        })
    
        */
    if (email)
      db.collection("users").where("email", "==", email)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = <User>doc.data();
            console.log("Loaded tmp user: ", data)
            self.global.authuser = data;
          });
        }).finally(function () {
          console.log("FINALLY!");
        })
        .catch((error) => {
          //    Logger ("Password Error", email, pw);  // Hack here is where I would add bad attempt counter 

          console.log("no user found: ", error);

        });
  }




  regUser(form: Form) {
    var self = this;


    var email, firstName, lastName, fullName: string;

    var found: Field;

    // Scan Forms to first email field....
    self.global.allForms.forEach(function (form) {
      found = form.fields.find((f) => f.type == 'email')
      if (found)
        email = found.binding
    })

    found = form.fields.find((f) => f.title == 'firstName')
    if (found) firstName = found.binding;

    found = form.fields.find((f) => f.title == 'lastName')
    if (found) lastName = found.binding;
    found = form.fields.find((f) => f.type == 'full-name')
    if (found) {
      fullName = found.binding;
    }


    console.log(self.global.allForms, form, email, firstName, lastName, fullName)


    // VALIDATE INFO
    if (!self.global.authuser) {
      if (!lastName || firstName.length == 0) { alert("Please enter a full name."); return; }
      //    if (!ValidateEmail(email)) return;  // Must already be validated
      var newUserID = self.registerService.GetUID();
      self.registerService.RegisterNewUser(email, firstName, lastName, self.localStorage); // add fullname

    }


  }




  saveForm(form: Form) {
    var self = this;
    var db = firebase.firestore();

    if (!self.global.authuser) return; // nobody

    // IF IT's a Master form skip for now

    if (!self.formName || !self.formName.startsWith("master") && form.id) {

      var newData: Form = { ...form };
      newData.fields.forEach(function (field) {
        // Let's remove unwanted fields before saving
        field.safeHtml = undefined;
      })
      newData.safeHtml = undefined;
      newData = removeUndefined(newData)

      // add Form to user as sub-collection
      var ref2 = db.collection("company").doc(self.global.myCompany.id)
        .collection("forms").doc(form.id).collection("submissions").doc();
      newData.id = ref2.id;
      newData.tags = [];   // HACK - FIX
      newData.userId = self.global.authuser.uid;
      newData.userName = self.global.authuser.firstName + " " + self.global.authuser.lastName;
      newData.timeStamp = Date.now();
      newData.formId = form.id;

      console.log("SUBMITME: ", ref2, form.id, newData)


      ref2.set(newData);

    }
    else if (form.userForm) { // A MASTER/LOCAL/USER FORM
      form = removeUndefined(form)
      var ref2 = db.collection("users").doc(self.global.authuser.uid)
        .collection("forms").doc(form.id).collection("submissions").doc();
      var newForm: Form = {};
      console.log("SAVING FORM HERE!! ", form, newForm)

      newForm = Object.assign(newForm, form)
      newForm.formId = form.id;
      newForm.id = ref2.id;
      newForm.userId = self.global.authuser.uid;
      newForm.userName = self.global.authuser.firstName + " " + self.global.authuser.lastName;
      newForm.timeStamp = Date.now();
      //      if (!form.tags) form.tags = [];

      console.log("NEW DATA ", newForm)
      ref2.set(newForm);
    }
    // ADD Store-local app



  }

  /*
  const myUserId = firebase.auth().currentUser.uid;
  const myReviews = firebase.firestore().collectionGroup('reviews');
  .where('author', '==', myUserId);
  myReviews.get().then(function (querySnapshot) {
  // Do something with these reviews! 
  });
  */

  getValue(fieldName) {



  }
  // Handle Field Outputs
  handleOutputs() {
    var self = this;

    self.form.fields.forEach(function (formField) {

      if (formField.outputs) {
        formField.outputs.forEach(function (output) {
          if (output.name == 'Forms') {
            self.form.fields.forEach(function (field) {
              if (field.dataSource == "Fields") {
                field.sourceForm = output.value[0]
                // Now Update the Fields
                console.log("Found Form - Populate the Fields ", field)
                if (field.sourceForm) {
                  field.options = []
                  const index = self.global.allForms.findIndex(function (post) {
                    if (post.name == field.sourceForm)
                      return true;
                  });
                  if (index != -1) {
                    field.selected = [];
                    self.global.allForms[index].fields.forEach(function (f) {
                      field.options.push(f.title);
                    })
                  }
                }
              }

            })
          }
        })
      }
    })
  }






  output(event: Field) {
    var self = this;
    self.editData = true;
    console.log("Form got field update: ", self.editData, self.form.showSubmit, event, self.form)


    if (event.type == 'canvas') {
      console.log("Update with NEW SCENE ")
      self.formService.updateField(self.form, event)
    }


    if (0) { // EX

      self.form.fields.forEach(function (formField) {
        if (formField.logic) {
          console.log("Execute the logic", formField.logic);
          self.logicService.executeLogic(formField.logic, null, formField, self.codeCallback, self);
        }
      })
    }


    //   if (!self.form.showSubmit)
    //ONLY FOR SUBMITMSG  self.formService.checkEmbedded(self.form);



    // set outputs if any
    if (event.outputs) {
      event.outputs.forEach(function (output) {
        console.log("Check outputs: ", output)
        if (output.name == 'Forms') {
          self.form.fields.forEach(function (field) {
            if (field.dataSource == "Fields") {
              field.sourceForm = output.value[0]
              // Now Update the Fields
              console.log("Found Form - Populate the Fields ", field)
              if (field.sourceForm) {
                field.options = []
                const index = self.global.allForms.findIndex(function (post) {
                  if (post.name == field.sourceForm)
                    return true;
                });
                if (index != -1) {
                  field.selected = [];
                  self.global.allForms[index].fields.forEach(function (f) {
                    field.options.push(f.title);
                  })
                }
              }
            }

          })


        }
        /*
                if (output.name == 'results') {
                  var found = self.formParent.fields.find((f) => f.title == output.name)
        
                //  self.formParent.fields
                }
        ???*/

        // NOT SURE ABOUT THIS ONE
        var found = self.form.fields.find((f) => {
          f.title && (f.title.toLowerCase() == output.name.toLowerCase())
        }
        )

        if (found) {
          console.log("OUTPUT", found)
          found.binding = output.value;
        }


      })
    }


    if (event.type == 'groupAdd') {
      console.log("Creating new Array/Group element")
      self.groupAdd(event.fieldGroup); // name of array
    }

    if (event.type == 'button') { // somebody clicked a button - what now?
      console.log("BUTTON EVENT ", event)
      // NEED MORE GENERIC WAY TO SEND CLICK THROUGH


      if (event?.logic?.code?.length) {
        console.log("Execute the logic", event.logic);
        self.logicService.executeLogic(event.logic, null, event, self.codeCallback, self);
      }
      console.log("button emit event ", event, self.objectEvents)

      if (event.falseHide) {
        event.falseHide.forEach(function (item, index) {
          var found = self.form.fields.find((f) => f.title == item)
          console.log("HIDE ME ", found)
          if (found) {
            found.display = event.trueFalse;
          }
        })
      }

      self.objectEvents.emit(event)

      // if (event.blurButton=="Update" || self.autoSubmit) {
      //   self.submitForm();
      // }

      return;// BUTTON ONLY

    }


    /////////////// Important pattern for handing special data sources
    self.form.fields.forEach(function (field) {
      if (field.dataSource == "Products") {
        field.options = [];
        var filterStr, searchStr;
        var filterArray: string[] = [];

        if (field.sourceField && field.sourceForm) {
          // Get filter value from the source . this Field Updates 
          // should be in a service
          var formIndex = self.global.allFormNames.indexOf(field.sourceForm);
          if (formIndex != -1) {
            self.global.allForms[formIndex].fields.forEach(function (myField) {
              if (myField.title == field.sourceField && myField.dataSource == 'Product Tags') {
                // GOT FIELD - GET DATA
                console.log("We got your filter ", myField)
                filterStr = myField.binding;
              }
              else if (myField.title == field.sourceField && myField.dataSource == 'POS Tags') {
                self.global.myCompany.posTagGroups.forEach(function (tagGroup) {
                  if (tagGroup.name == myField.binding)
                    filterArray = tagGroup.tags;
                })
              }

            })
          }
        }
        // NOW FIND THE SEARCH VALUE
        var formIndex = self.global.allFormNames.indexOf(field.sourceForm);
        if (formIndex != -1) {
          self.global.allForms[formIndex].fields.forEach(function (myField) {
            if (myField.title == 'search') {
              // GOT FIELD - GET DATA
              console.log("We got your search ", myField)
              if (myField?.binding && myField.binding.length)
                searchStr = myField.binding.toLowerCase();
            }
          })
        }

        self.global.allProducts.forEach(function (product) {
          if (filterArray && filterArray.length) {
            filterArray.forEach(function (tag) {
              if (product?.allTags?.includes(tag))
                field.options.push(product.title)
            })

          }
          else if (!filterStr || filterStr.length == 0) {
            if (!searchStr || searchStr?.length == 0)
              field.options.push(product.title)
            else {
              if (product.title.toLowerCase().includes(searchStr))
                field.options.push(product.title)
            }
          }
          else if (product?.allTags?.includes(filterStr))
            if (!searchStr || searchStr?.length == 0)
              field.options.push(product.title)
            else {
              if (product.title.toLowerCase().includes(searchStr))
                field.options.push(product.title)
            }
        })
        console.log("Products ", field.options, searchStr, filterStr)
      }
    })
    /////////////// Important pattern for handing special data sources


    if (event.logic) {
      console.log("Execute the logic", event.logic);
      self.logicService.executeLogic(event.logic, null, event, self.codeCallback, self);
    }


    /*
        self.form.fields.forEach(function (field) {
          if (field?.showOnly) {
            // SHOW THIS FIELD ONLY IF ANOTHER SELECTION FIELD IS SET TO ?
            var found = false;
            field.showOnly.forEach(function (item) {
              self.form.fields.forEach(function (field) {
                if (field.selected && field.selected.length) {
                  if (field.selected.includes(item)) {
                    found = true;
                    console.log("Selected ", field)
                  }
                }
              })
            })
            console.log("SHOW ONLY CHECK ", found, field)
            field.display = found;
          }
        })
    */
    self.checkShow();  //showOnly

    if (event.falseHide) {
      event.falseHide.forEach(function (item, index) {
        var found = self.form.fields.find((f) => f.title == item)
        console.log("HIDE ME ", found)
        if (found) {
          found.display = event.trueFalse;
        }
      })
    }

    if (event.optionSelected) {
      if (event.type == "switch") { // 
        event.optionSelected.forEach(function (item, index) {
          if (item.option.toLowerCase() == 'true' && event.trueFalse == true ||
            item.option.toLowerCase() == 'false' && event.trueFalse == false) {
            console.log("Switch: ", item)
            item.hide?.forEach(function (hide) {
              var found = self.form.fields.find((f) => f.title == hide)
              if (found) {
                found.display = false;
              }
            })

            item.show?.forEach(function (show) {
              var found = self.form.fields.find((f) => f.title == show)
              if (found) {
                found.display = true;
              }

            })


          }
        })

      }
      else {
        event.optionSelected.forEach(function (item, index) {
          if (item.option.toLowerCase() == event.binding.toLowerCase()) {
            // This option selected so now we act: Hide/Show/etc.

            item.hide?.forEach(function (hide) {
              var found = self.form.fields.find((f) => f.title == hide)
              if (found) {
                found.display = false;
              }
            })

            item.show?.forEach(function (show) {
              var found = self.form.fields.find((f) => f.title == show)
              if (found) {
                found.display = true;
              }

            })
          }
        })
      }
    }


    if (event.outputs)
      event.outputs.forEach(function (field) {
        // Check Form for values
        var found = self.form.fields.find((f) => f.title == field.name)

        if (found) {
          console.log("OUTPUT", found)
          found.binding = field.value;
        }

      })



    // Check for subtypes such as look-up/search 
    // until all the look-ups have returned a value

    var searchDone = true;
    //   var autoSubmit = false;
    self.form.fields.forEach(function (field) {
      if (field.type == 'email' || field.type == 'full-name') {
        self.autoSubmit = true;
        if (typeof field.found === 'undefined')
          searchDone = false;
      }


    })

    self.objectEvents.emit(event)

    if (searchDone && self.form.showSubmit == true && self.form.autoSave == true) {

      //   console.log("AUTO-SUBMIT ", self.form)
      //   self.submitForm();
    }
    else if (!self.form.showSubmit || event.blurButton == 'Update' || self.autoSubmit) {
      if (event.blurButton != 'None')
        self.submitForm();
    }
    if (!searchDone) return false;


    console.log("New Form data: ", event, self.form)


  }

  groupAdd(fieldGroup: string) {
    var self = this;
    // OK, so we add new item for group which can be any number of fields.
    // scan this form for fields with form.field.fieldGroup == field.binding - value of button

    var newObject: Field[] = [];

    var index = 0;
    var lastIndex = 0;

    self.form.fields.forEach(function (field, fieldIndex) {
      if (!field.groupIndex)
        field.groupIndex = 0;
      if (field.groupIndex > index && field.fieldGroup == fieldGroup) {
        index = field.groupIndex;
        newObject = [];
      }
      if (field.type != 'groupAdd' && field.fieldGroup == fieldGroup && field.groupIndex == index) {
        var bob: Field = {};
        lastIndex = fieldIndex;
        newObject.push(Object.assign(bob, field))
        console.log("Pushed bob ", bob);
      }
    })
    const buttonIndex = self.form.fields.findIndex(function (post) {
      if (post.type == 'groupAdd' && post.fieldGroup == fieldGroup)
        return true;
    });
    if (buttonIndex != -1) {
      console.log("Removing button.....")
      self.form.fields.splice(buttonIndex, 1);
    }


    // Now we have an array of the Group Fields with index as lastItem.
    // Here we can check order, blank fields etc.
    // Let's put the Add Another button here
    newObject.push({ type: "groupAdd", newLine: true, fieldGroup: fieldGroup })
    // Let's remove the 

    console.log("NEW ARRAY ROW: ", newObject)
    index = index + 1;

    lastIndex++;
    // Auto-insert new line
    self.form.fields.splice(lastIndex, 0, { title: "new line", type: 'newLine', newLine: true })
    lastIndex++;

    newObject.forEach(function (field) {
      field.binding = undefined;
      field.value = undefined;
      field.media = undefined;
      field.htmlFinal = undefined;
      field.safeHtml = undefined;
      field.selected = [];
      field.date = undefined;
      field.groupIndex = index;
      //   self.form.fields.push(field);
      console.log("LastIndex ", lastIndex, field);
      self.form.fields.splice(lastIndex, 0, field)
      lastIndex++
    })


    console.log("Adding to ", fieldGroup, self.form.fields)



  }


  round(x) {
    return Math.round(x * 100) / 100;
  }

  codeCallback(code: CodeObject, self: this) {
    console.log("Form/field level logic callback: ", code, self.global.allForms)

    // First get function type at values[2]
    const functionName = code.values[2]; // maybe I need JSON
    // "result": any, noop always =
    // "function": "funcName"
    // "parameters": {title: str, type: str, value:any, field?: str}

    // Now get some parameters
    var parameters: any[] = [];
    if (code.values[3]) {
      const val1 = self.getFieldValue(self.form, code.values[3])
      parameters.push(val1)
    }
    if (code.values[4]) {
      const val2 = self.getFieldValue(self.form, code.values[4])
      parameters.push(val2)
    }
    if (code.values[5]) {
      const val2 = self.getFieldValue(self.form, code.values[5])
      parameters.push(val2)
    }
    if (code.values[6]) {
      const val2 = self.getFieldValue(self.form, code.values[6])
      parameters.push(val2)
    }
    if (code.values[6]) {
      const val2 = self.getFieldValue(self.form, code.values[6])
      parameters.push(val2)
    }

    console.log("FUNCTION Parameters: ", functionName, parameters, code.values[3])

    switch (functionName) {
      case 'loadOrder':
        self.cartService.LoadOrderTicket(parameters[0]);
        break;
      case 'clearCart':
        self.cartService.newOrderTicket = null;
        self.cartService.newOrderItems = [];
        // Need to find ticket field and empty
        self.form.fields.forEach(function (field) {
          if (field.dataSource == 'Open Orders') {
            field.binding = null;
            field.selected = [];
          }

        })
        //tablename???
        break;
      case 'deleteOrder':
        var myTicket;
        Object.entries(self.global.openTickets).forEach(function (ticket: any) {

          if (ticket[1].ticket?.ticketName == parameters[0]) {
            console.log("Ticket ", ticket[1].ticket)
            myTicket = ticket[1].ticket;
          }
        })
        if (myTicket) {
          self.cartService.removeTicket(myTicket);
        }
        // Need to remove ticket from form binding.
        self.form.fields.forEach(function (field) {
          if (field.binding == myTicket.ticketName) {
            field.binding = null;
            field.selected = [];
          }

        })
        break;
      case 'addItemToOrder':
        self.cartService.addItemToTicket(parameters[0]);
        break;
      case 'createImageAI':
        self.formService.createImageAI(parameters[0], parameters[1], code, self.gotImage, self)
        break;
      case 'queryAI':
        self.formService.queryOpenAI(parameters[0], "gpt-3.5-turbo", code, null, self.gotAnswer, self)
        break;
      case 'random':
        var result = (Math.random() * (parameters[1] - parameters[0])) + parameters[0];
        self.setValue(code.values[0], self.form, result)
        break;
      case 'Confirm':
        var confirm = alert(parameters[0]);
        self.setValue(code.values[0], self.form, result)
        break;
      case 'abs':
        var val = Math.abs(parameters[0]);
        self.setValue(code.values[0], self.form, val)
        break;
      case 'acos':
        var val = Math.acos(parameters[0]);
        self.setValue(code.values[0], self.form, val)
        break;
      case 'acosh':
        var val = Math.acosh(parameters[0]);
        self.setValue(code.values[0], self.form, val)
        break;
      case 'asin':
        var val = Math.asin(parameters[0]);
        self.setValue(code.values[0], self.form, val)
        break;
      case 'atan':
        var val = Math.atan(parameters[0]);
        self.setValue(code.values[0], self.form, val)
        break;
      case 'cbrt':
        var val = Math.cbrt(parameters[0]);
        console.log("MATH RESULT: ", val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'ceil':
        var val = Math.ceil(parameters[0]);
        console.log("MATH RESULT: ", val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'cos':
        var val = Math.cos(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'exp':
        var val = Math.exp(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'expm1':
        var val = Math.expm1(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'floor':
        var val = Math.floor(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'hypot':
        // Need a better way to send: as Object??
        if (parameters.length == 1)
          var val = Math.hypot(parameters[0]);
        else if (parameters.length == 2)
          var val = Math.hypot(parameters[0], parameters[1]);
        else if (parameters.length == 3)
          var val = Math.hypot(parameters[0], parameters[1], parameters[2]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'min':
        // Need a better way to send: as Object??
        if (parameters.length == 1)
          var val = Math.min(parameters[0]);
        else if (parameters.length == 2)
          var val = Math.min(parameters[0], parameters[1]);
        else if (parameters.length == 3)
          var val = Math.min(parameters[0], parameters[1], parameters[2]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'max':
        // Need a better way to send: as Object??
        if (parameters.length == 1)
          var val = Math.max(parameters[0]);
        else if (parameters.length == 2)
          var val = Math.max(parameters[0], parameters[1]);
        else if (parameters.length == 3)
          var val = Math.max(parameters[0], parameters[1], parameters[2]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'pow':
        // Should have 2 parameters
        if (parameters.length == 2)
          var val = Math.pow(parameters[0], parameters[1]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'log':
        var val = Math.log(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'round':
        var val = Math.round(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'sign':
        var val = Math.sign(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'sin':
        var val = Math.sin(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'sqrt':
        var val = Math.sqrt(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'tan':
        var val = Math.tan(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'trunc':
        var val = Math.trunc(parameters[0]);
        console.log("MATH RESULT: ", parameters[0], val)
        self.setValue(code.values[0], self.form, val)
        break;
      case 'appendStr':
        var str = self.getFieldValue(self.form, parameters[0]);
        var str2 = self.getFieldValue(self.form, parameters[1]).toString();
        if (str && typeof (str) == 'string' && str?.length > 0)
          var newStr: string = str.concat(str2);
        else
          var newStr: string = str2;
        console.log("append RESULT: ", str, str2, newStr)
        self.setValue(code.values[0], self.form, newStr)
        break;
      case 'emailUser':
        var templateHTML = parameters[0];
        /*
                var template = <string>code.fields[0];
                if (template.startsWith('Field:')) {
                  var field = template.substring(6);
                  var fieldIndex = self.findMe(field, self.form);
                  console.log("Template from field: ", field, fieldIndex);
                  if (self.form.fields[fieldIndex].type == 'displayHTML' ||
                    self.form.fields[fieldIndex].type == 'htmlEditor') {
                    templateHTML = self.form.fields[fieldIndex].htmlFinal;
                  }
                  template = self.form.fields[fieldIndex].binding;
        
                }
                  */
        console.log("emailUser with template: ", templateHTML);
        var emailForm: EmailForm = {
          to: self.global.authuser.email,
          from: "info@crystalhillvineyard.com",
          subject: "Group Event Quote",
          message: templateHTML
        }
        console.log("emailUser with template: ", templateHTML);

        self.emailService.sendEmail(emailForm)
        break;
      case 'emailStaff':
        var emailForm: EmailForm = {
          from: "info@napkinApps.com",
          to: "swarner100@gmail.com",
          subject: "Mother's Day at Crystal Hill",
          message: "You got a message"
        }
        self.emailService.sendEmail(emailForm)
        break;
      case 'emailList':
        var emailForm: EmailForm = {
          from: "info@napkinApps.com",
          to: "swarner100@gmail.com",
          subject: "Mother's Day at Crystal Hill",
          message: "You got a message"
        }
        var emailArray = parameters[0].split(',')
        self.emailService.sendBatchEmails(emailArray, parameters[1], parameters[2])
        break;
      case 'Submit':
        self.editData = false;
        console.log("SUBMITTING THE FORM ")
        self.submitForm();
        break;
      case 'clearForm':
        self.editData = false;

        // For Master forms we need to remove that

        var formName: string;
        if (self.form.name.startsWith("master"))
          formName = self.form.name.slice(6)

        console.log("CLEARING THE FORM ", formName, self.form, code.values[3])

        if (formName == code.values[3]) {
          self.form.fields.forEach(function (field) {
            field.binding = null;
            field.value = null;
            field.media = null;
            field.selected = [];
          })
          console.log("cleared FORM ")
        }
        self.global.allForms.forEach(function (form) {
          if (form.name == code.values[3]) {
            form.fields.forEach(function (field) {
              field.binding = null;
              field.value = null;
              field.media = null;
              field.selected = [];
            })
            console.log("FOUND FORM ", form.id)
          }
        })
        break;



      // OLD
      //  case 'Submit Form':
      //    self.submitForm();
      //    break;
      case 'Email Form': // EMAIL SET OF FORMS TO Depts or emails
        self.global.allForms.forEach(function (form) {
          if (form.name == code.fields[0]) {
            self.registerService.emailForms(code, form, self.global.myCompany.chatServiceEmail)
            console.log("EMAIL FORM ", form)
          }
        })
        break;
      /*
    case 'Clear Form':
      self.editData = false;
      console.log("CLEARING THE FORM ", code.fields[0])
      self.global.allForms.forEach(function (form) {
        if (form.name == code.fields[0]) {
          form.fields.forEach(function (field) {
            field.binding = null;
            field.value = null;
            field.media = null;
            field.selected = null;
  
          })
          console.log("FOUND FORM ", form.id)
        }
      })
      break;
      */
      case 'Close Form':
        self.global.allForms.forEach(function (form) {
          if (form.name == code.fields[0]) {
            self.form.hideForm = true;
            self.success = "Thanks for your submission";// hack as as param
            console.log("CLOSING FORM ", form.id)
          }
        })
        break;
      case 'startFreeTrial':
        var trialType = <string>code.values[3]; // HACKYHACK
        console.log("Let's start free trial ", trialType)
        self.registerService.startFreeTrial(trialType, self.form, self.trialCallback, self)
        break;

      case 'gotoForm':
        self.global.allForms.forEach(function (form) {
          if (form.name == code.values[3]) {
            self.form = form;
            form.hideForm = false;
            console.log("FOUND FORM ", form.id)
            self.getForm(form.name, form.id);

          }
        })
        break;
    }
  }

  trialCallback(self) {
    // After trial is started set entrypoint
    self.router.navigate(['/front']);
    // this.window.reload();

  }

  setValue(resultField, form: Form, result) {

    form.fields.forEach(function (field) {
      if (field.title == resultField) {
        //   field.binding = result;   // HACK - for string values
        console.log("Set field value:", result)

        var val;
        val = Number(result);
        if (result && typeof val === "number" && !isNaN(result)) {
          field.value = result;
        }
        else if (field.type == "displayImage") {
          if (!field.media) field.media = []
          field.media.push({ url: result })
        }
        else if (typeof (result) == 'string') {
          if (field.type == 'displayHTML') {
            field.html = result;
            // replace /n with <br>
            field.htmlFinal = result.split('\n').join('<br>');


            console.log("HTML")
          }
          else
            field.binding = result;

        }

      }

    })

  }

  gotAnswer(self, code, response) {
    console.log("We got your answer, Bob ", response.message)
    self.setValue(code.values[0], self.form, response.message)
  }

  gotImage(self, code, prompt, response, fileName, modelDetails, cost, size) {
    console.log("We got your answer, Bob ", code, prompt, fileName, response.savedImage)

    self.setValue(code.values[0], self.form, response.savedImage)

    var newMedia: Media = {
      //   fileId?: string;
      name: fileName,
      altDesc: "image desc",
      url: response.savedImage,
      prompt: prompt,
      modelDetails: modelDetails,
      cost: cost,
      height: size.height,
      width: size.width,
      //  size: file.size,
      tags: ["AI"],
      //  format?: string;
      fileType: "image/png",
      companyId: self.global.myCompany.id,
      store: self.global.myCompany.store,
      userId: self.global.authuser.uid,

      uploadId: self.global.authuser.uid, // who uploaded
      createdAt: Date.now(),
      updatedAt: Date.now(),
      updatedBy: self.global.authuser.uid

    }
    self.mediaService.addImageToCompany(newMedia)

  }

  findMe(me: string, form: Form) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post?.title && post.title.toLowerCase() == me)
        return true;
    });
    //  if (index == -1) { console.log("oops. didn't find you: " + me, form) }
    return index;

  }
  findMeInGroup(me: string, form: Form, groupIndex) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post?.title && post.title.toLowerCase() == me
        && post.groupIndex == groupIndex)
        return true;
    });
    //  if (index == -1) { console.log("oops. didn't find you: " + me, form) }
    return index;

  }


  getFieldWidth(field) {
    if (field.newLine || field.type == 'groupAdd')
      return "width: " + this.global.getScreenWidth + 'px';
    else
      return "";
  }

  getLayout() {
    if (!this.form.layout) this.form.layout = "row wrap"
    var str = this.form.layout;
    return str;

  }

  getFormWidth() {
    //  console.log("FORM WIDTH ", this.form.width)

    if (!this.form.width) return "100%";

    if (this.form.width == "100%")
      return "100%"

    if (this.form.width) return this.form.width;

    var w = parseInt(this.form.width);
    //console.log("w: ", w,this.global.getScreenWidth)
    if (this.global.getScreenWidth < w)
      return "100vw";
    else {
      if (this.form.width) return w + 'px';
      else return "100%";
    }
  }

  findValue(me: string, form: Form) {
    if (!me || me.length == 0) return;

    me = me.toLowerCase();
    if (!me) return false;

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { return false }
    console.log("FOUND FIELD: ", form.fields[index].value, index)
    return form.fields[index].value;

  }

  getFieldValue(form: Form, me: any) {
    var self = this;
    var value = 0;

    console.log("Looking for ", me)

    if (!me || me.length == 0) return false;

    var formIndex = -1;
    var fieldIndex = -1;
    if (typeof (me) == 'object') {
      self.global.allForms.forEach(function (form, index) {
        const foundIndex = form.fields.findIndex(x => x.id === me.id)
        if (foundIndex != -1) {
          fieldIndex = foundIndex;
          formIndex = index;
        }
      })
      if (fieldIndex != -1) {
        value = self.global.allForms[formIndex].fields[fieldIndex].value
        if (typeof (value) == 'number')
          return value;
        else return 0;
      }
      else
        return 0;
    }



    value = Number(me);
    if (typeof value === "number" && !isNaN(value)) {
      console.log("return value: ", value)
      return value;
    }

    var formField: string;
    formField = me;

    console.log("Search formfield: ", formField);

    // Find field
    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == formField.toLowerCase())
        return true;
    })
    if (index != -1) {
      if (form.fields[index].binding)
        return form.fields[index].binding;
      else if (form.fields[index].value)
        return form.fields[index].value;
      else if (form.fields[index].htmlFinal)
        return form.fields[index].htmlFinal;
      else if (form.fields[index].html)
        return form.fields[index].html;
    }
    else return formField; // Default to a string literal??
  }





}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
var is_array = function (value) {
  return value &&
    typeof value === 'object' &&
    typeof value.length === 'number' &&
    typeof value.splice === 'function' &&
    !(value.propertyIsEnumerable('length'));
};
