

<input id="title" placeholder="App Name" type="text" class="text-entry" pInputText
[(ngModel)]="place" />
<button (click)="CreateStore()" class="function" pButton pRipple type="button">
Create Store
</button>
<br>

<button (click)="createReviewApp()" class="function" pButton pRipple type="button">
    Create Review App
    </button>


