import { Component, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { Product, Options } from '../../components/product-int';
import { ButtonModule } from 'primeng/button';
import { Order } from '../order-interface';
import { CurrencyPipe } from '@angular/common'

@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.css','../../common.scss']
})
export class MyordersComponent implements OnInit {
  @Input() store: string = "";
  @Input() all: boolean = false;

  closedOrders: Order[];


  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller,
    private cp: CurrencyPipe,

  ) {
  }

  ngOnInit(): void {
    console.log("store: ",this.store, this.all)
    this.getOrders(this.store, this.all);

  }
  getExtPrice(item: Order) {

    if (item.RewardPaid) {
      return " " + (item.RewardPaid) + " points";
    }
    else {
      item.extPrice = item.extPrice; //this.checkDiscounts(item.qty, item.price, item)

      // item.extPrice = item.qty * item.price;
      var formatedOutputValue = this.cp.transform(item.extPrice, 'USD', 'symbol', '1.2-2');
      return formatedOutputValue;
      //  return " $" + (item.extPrice);
    }

  }

  lineItem(order: Order) {

    if (order.qty == 1) return order.item;
    else if (order.price > 0) return order.item + " @" + order.price;
    else return order.item;

  }

  lineItemOptions(order: Order) {
    var myOptions = "";

    if (order.allOptions) {
      var oSet = 0;
      order.allOptions.forEach(function (arrayItem: Options) {
        if (arrayItem.chosen && arrayItem.type != 3) {
          myOptions += " " + arrayItem.chosen;
          //          console.log("added ",arrayItem.chosen);
          if (arrayItem.chosen.length) oSet++;
        }
        if (arrayItem.chosenList) {
          arrayItem.chosenList.forEach(function (optList) {
            myOptions += " " + optList;
            if (optList.length) oSet++;
          })


        }
      });
      if (oSet < order.allOptions.length) myOptions += " Select options";


    }
    return myOptions;
  }

  getOrders(store: string, all:boolean) { // All
    var db = firebase.firestore();
    var self = this;
    var index = 0; // 

    console.log("getting orders ", store);

    if (!all) {
      var orderRef = 
      db.collection("orders").where("userID", "==", self.global.authuser.uid).
      where("companyID", "==", store).where("status", "==", "Closed")
    }
    else{
      var orderRef = 
      db.collection("orders").where("userID", "==", self.global.authuser.uid)
      .where("status", "==", "Closed")
    }
    orderRef.onSnapshot((querySnapshot) => {
         console.log("New Order Data arrived ", querySnapshot.docs);
        self.closedOrders = [];

        querySnapshot.forEach((doc) => {
          const data = <Order>doc.data();
          //      console.log("Order: ", data);

          if (!data.note) data.note = "";

          let dateT = data.dateTime;
          let localOrder = <Order>data;
          localOrder.isExpanded = false;

     //     if (localOrder.tableName == "") localOrder.tableName = "No Table";
     //     localOrder.id = doc.id;
     //     if (localOrder.tableName != "No Table") {
            // if not showing Removed
            self.closedOrders.push(localOrder);
      //    }

        });
        console.log("New Data ", self.closedOrders)
        //        self.MakeOpenOrderList(self);
        self.closedOrders.sort(function (a, b) { return b.closedAt - a.closedAt });


        //      self.simplifyOrder();
        //   console.log("New Data");
        //     self.dataSourceNewOrder = new MatTableDataSource(self.orderView); // new order
        //      self.obsNewOrder = self.dataSourceNewOrder.connect();

      });




  }

  dateFormatter(dateMs: number) {

    var date = new Date(dateMs);


    const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const timeformat: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour12: false
    };
    // date.toDateString();


    return date.toLocaleDateString('en-US', timeformat);

  }

}


