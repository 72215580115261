<div *ngIf="open">
    <app-container [settings]="dataSettings" [list]="dataList" (openTab)="tabSelected($event)" [api]="false"
        [apiFunction]="'Forms'" [style]="style"></app-container>
</div>


<div *ngIf="api=='Forms'">
    <div fxLayout="column" fxLayoutAlign="space-between center">
        <app-themes></app-themes>

        <button (click)="addForm($event)" class="create-form" pButton pRipple type="button">
            Create a new form
        </button>
    </div>

    <app-container (openTab)="formSelected($event)" [settings]="formSettings" [list]="global.allFormNames" [api]="false"
        [apiFunction]="'EditForm'"></app-container>
</div>

<div *ngIf="api=='Reports'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="newReport()" class="create-form" pButton pRipple type="button">
            Create a new report
        </button>
    </div>

    <app-container (openTab)="reportSelected($event)" [settings]="formSettings" [list]="global.allReportNames" [api]="false"
        apiFunction="Reports"></app-container>
</div>

<div *ngIf="api=='Analytics'">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="newQuery()" class="create-form" pButton pRipple type="button">
            Create a new Query
        </button>
    </div>

    <app-container (openTab)="querySelected($event)" [settings]="formSettings" [list]="global.allQueryNames" [api]="false"
        apiFunction="Queries"></app-container>

</div>
