import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from '../../../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../../../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../../cart.service';
import { CustomizeMessageService, MessageState } from '../../../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../../nav.service';
import { environment } from '../../../../../environments/environment';
import { Table } from '../../../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../../../ng-auth.service";
import { Creds, Follows, User, Notify } from './../../../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { SafePipe } from 'safe-pipe';
import { ChatSettingsComponent } from '../../../../components/chatnow/settings/settings.component';


@Component({
  selector: 'app-reservesettings',
  templateUrl: './reservesettings.component.html',
  styleUrls: ['./reservesettings.component.scss']
})
export class ReservesettingsComponent {
  daysOfWeek: string[] = ["Sundays", "Mondays", "Tuesdays", "Wednesdays", "Thursdays", "Fridays", "Saturdays"];
  public reserveQrCode: string = null;

  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public nav: NavService) {
      this.reserveQrCode = "napkinhq.com/reserve/"+this.global.myCompany.store;

      console.log ("Reserve Settings");

  }


  updateResSettings() {
    var self = this;

    var db = firebase.firestore();
    var ref = db.collection("company").doc(this.global.myCompany.id);

    this.global.myCompany.reserveSettings.openDays = this.global.openDays;
    this.global.myCompany.reserveSettings.reserveDay = this.global.allWeek;

    /*
    this.resSettings.openDays = this.global.openDays;
    this.resSettings.reserveDay = this.global.allWeek;

    this.resSettings.timeBlock = this.global.myCompany.reserveSettings.timeBlock;
    this.resSettings.timeNotice = this.global.myCompany.reserveSettings.timeNotice;
    this.resSettings.timeSkip = this.global.myCompany.reserveSettings.timeSkip;
    this.resSettings.replyEmail = this.global.authuser.email;
    //DO I REALLY NEED THESE??
    this.resSettings.resPolicy = this.global.myCompany.reserveSettings.resPolicy;
*/
    console.log("Saving Reservation Settings: ");

    ref.update({
      reserveSettings: self.global.myCompany.reserveSettings
    })
      .then(() => {
        console.log("Res settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing res settings: ", error);
      });
  }

  GotoReserve() {
    this.router.navigate(['/reserve/' + this.global.myCompany.store]);
  }

}
