/* CHECK OUT FLOWS:

E-commerce: no TableNumber
  Show only items that can be picked up, take-out, shippable.
  No "Order" Button. Cart to Checkout
  Tip in flow (Store sets)
  Delivery drives data (shipping; cost, address) 
  Pick-up only by default.

Menu Ordering: [has TableNumber] - [On Site Sale - QrWithCustomTable]
  Add to cart - immediate Save - 
  Tips:  store sets: default: true
  Checkout available (Store sets: Anytime(all cart items ordered):default, New+,  Started+, Delivered+)


 */

import { Order, Discounts } from '../../order-interface';
import firebase from 'firebase/compat/app';

import { User, Contact, Follows } from '../../user-interface';
import { Company } from '../../company-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgAuthService } from "../../../ng-auth.service";

import { AfterViewInit, Component, Input, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { UntypedFormGroup, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { RegisterService } from '../../register.service';
import { Logger } from '../../../functions';
import { StripeService, StripePaymentElementComponent, StripePaymentRequestButtonComponent, StripeServiceInterface, StripeElementsService, StripeFactoryService } from 'ngx-stripe';
import {
  StripeElementsOptions,
  PaymentIntent,
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
} from '@stripe/stripe-js';


import { environment as env } from '../../../../environments/environment';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { SelectItem, PrimeNGConfig, MessageService } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Product } from '../../product-int';
import { Globals } from '../../../globals';
import { FormControl } from '@angular/forms';
import { TreeModule } from 'primeng/tree';

export interface StripeCustomer {
  name: string;
  id: string;
  email: string;
}



@Component({
  selector: 'app-selfpay',
  templateUrl: './selfpay.component.html',
  styleUrls: ['./selfpay.component.scss']
})
export class SelfpayComponent implements OnInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  stripeCustomer: StripeCustomer;

  paymentElementForm = this.fb.group({
    name: ['John doe'],
    email: ['support@ngx-stripe.dev'],
    address: [''],
    zipcode: [''],
    city: [''],
    amount: [2500]
  });
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };


  //   amountCtrl = new FormControl(null, { updateOn: 'blur' });


  // Redemption //
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  // Redeption //
  registered: boolean = false;

  myOrderTotal: number = 0;
  myPointsTotal: number = 0;

  shipping: boolean = false;

  salesTax: number = 0;
  tipValue: number = null;
  tipAmount: number = 0;
  theTotal: number = 0;
  xamount: number = 10;
  name: string = "";
  email: string = "";
  customTip: number = 0;
  viewPayButton: boolean = false;
  cashPayment: boolean = false;
  cashPaymentAmount: number = 0;

  viewDetail: boolean = true;
  detailText: string = "see detail";
  user: User;
  tableNameQR: string;
  companyID: string;
  selected: string;
  //stripe: StripeInstance;
  //  paymentSuccess: number = 0;
  myRewardPoints: number = 0;

  selectedMessage: any;
  paying = false;
  myToken: string;

  myCompany: Company;
  red: Product[] = [];
  contact: Contact;

  stripeTest: FormGroup;
  tipOptions: any[];
  errorMessage: string;

  elements: StripeElements;
  card: StripeCardElement;

  cardOptions: StripeCardElementOptions = {

    style: {
      base: {
        iconColor: '#666EE8',
        color: '#0000ff',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '24px',
        '::placeholder': {
          color: '#000000'
        }
      }
    }
  };



  constructor(
    private router: Router, public ngAuthService: NgAuthService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private sharedDataService: SharedDataService,
    private cartService: CartService,
    private stripeService: StripeService,
    private messageService: MessageService,
    private primeNGConfig: PrimeNGConfig,
    public global: Globals,
    public registerService: RegisterService,
  ) {

    this.tipOptions = [
      { index: 0, label: 'none', value: 0, type: 'per' },
      { index: 1, label: '15%', value: 15, type: 'per' },
      { index: 2, label: '18%', value: 18, type: 'per' },
      { index: 3, label: '20%', value: 20, type: 'per' },
      { index: 4, label: '$5', value: 5, type: 'cur' },
      { index: 5, label: '$10', value: 10, type: 'cur' },
      { index: 6, label: '$15', value: 15, type: 'cur' },
      { index: 7, label: '$20', value: 20, type: 'cur' },
      { index: 8, label: 'Custom', value: 0, type: 'cur' },
    ];

  }

  ngOnInit(): void {
    var self = this;

    this.primeNGConfig.ripple = true;

    this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value!
    console.log(this.selectedMessage);

    this.GetOrders();
    this.getTotal();
    if (this.global.authuser) this.startCheckout();

  }

  startCheckout() {
    if (this.global.authuser) {
      var self = this;
      this.paymentElementForm = this.fb.group({
        name: this.global.authuser.firstName + " " + this.global.authuser.lastName,
        email: this.global.authuser.email,
        address: "",
        zipcode: [''],
        city: [''],

      });



      self.createPaymentIntent(Number(self.theTotal) * 100)
        .subscribe(pi => {
          console.log("Got the intent yay")
          self.elementsOptions.clientSecret = pi.client_secret;
        });

      if (0) { // Create customer in flow

        if (self.global.authuser.stripe_id) {
          self.createPaymentIntent(Number(self.theTotal) * 100, self.global.authuser.stripe_id)
            .subscribe(pi => {
              console.log("Got the intent yay")
              self.elementsOptions.clientSecret = pi.client_secret;
            });
        }
        else {

          this.createCustomer()
            .subscribe((customer) => {
              console.log("Created customer!", customer);

              //self.stripeCustomer = Object.assign(self.stripeCustomer, customer)
              console.log("Create Intent ", self.theTotal, customer.id)

              self.createPaymentIntentSave(Number(self.theTotal) * 100, customer.id)
                .subscribe(pi => {
                  console.log("Got the intent yay")
                  self.elementsOptions.clientSecret = pi.client_secret;
                });

            });
        }
      }
    }
  }

  discounts(): boolean {

    var found = false;

    this.sharedDataService.payOrder.forEach(function (item) {
      if (item.discountsApplied && item.discountsApplied.length) {
        found = true;
      }

    })
    return found;
  }


  getDiscounts(): string {
    var total = 0;

    this.sharedDataService.payOrder.forEach(function (item) {
      if (item.discountsApplied && item.discountsApplied.length) {
        total += (item.extPrice - (item.price * item.qty));
      }

    })

    return '$' + total.toFixed(2);

  }
  myColor() {
    if (this.myToken && this.myToken.length > 0) {
      return "none";
    }
    else return "block"
  }
  getReviewUrl() {

    var url = "https://api.qrserver.com/v1/create-qr-code/?data=NapkinHQ.com/review/";
    url += this.global.myCompany.store
    url += '&size=200x200';
    return url;
  }

  lastInput: number = 0;

  lookupUser(event) {
    var fn = event.target.value.lastIndexOf(' ');

    this.foundUsers = [];

    // need first & last name
    if (fn != -1) {

      const arr = event.target.value.split(' ');
      console.log("Have last ", arr);
      if (arr[1].length > 2) {
        console.log("Lookup user ");
        this.findUser(arr[0], arr[1]);

      }

    }

    this.lastInput = Date.now();
  }

  loadUser(user: User) {

    this.sharedDataService.payOrder.forEach(function (item) {
      item.userID = user.uid;

    })
    this.stripeTest.setValue({
      "name": user.firstName + " " + user.lastName,
      "email": user.email
    })
    this.global.customer = user;
    this.foundUsers = [];

  }

  foundUsers: User[];

  findUser(first, last) {
    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("users").where("firstName", "==", first)
      .get()
      .then((querySnapshot) => {
        self.foundUsers = [];

        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          var lastname = data.lastName.toLowerCase();
          if (lastname.startsWith(last.toLowerCase())) {
            console.log("Found ", querySnapshot.size)
            this.foundUsers.push(data);
          }

        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });






  }

  createToken() {
    const name = this.global.authuser.firstName + " " + this.global.authuser.lastName   //this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token

          const captureButton = document.getElementById('hideMe');
          // captureButton.setAttribute('display:', 'none')
          // this.card.unmount();

          this.cardOptions = {
            style: {
              base: {
                iconColor: '#fffffff',
                color: '#ffffff',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '24px',
                '::placeholder': {
                  color: '#ffffff'
                }
              }
            }
          };

          this.myToken = result.token.id;
          // Now remove order detail for tipping  Hack should be based on mode
          this.viewDetail = false;
          if (!this.global.tableNameQR)
            this.viewPayButton = true;

          console.log(result.token.id);
        } else if (result.error) {
          // Error creating the token
          alert("Token Error: " + result.error);
          console.log(result.error.message);
        }
      });
  }

  emailChanged() {

    // Validate FIELD + NAME
    //    if (!self.email || !self.userName) {  // HACK - NEED full email verify function
    //      this.openSnackBar("Email & name required.", "Thanks.");
    //      return;
    //    }
    // Auto-register User if new email
    // If already in system - ask for password to auth - no login
    console.log("Email changed: ", this.email);

    //  this.registerUser();


  }
  getCreditCard() {
    this.cashPayment = false;
    this.getTotal();



  }
  closeSale() {
    var result: any;

    result = {
      paymentIntent: {
        id: "Cash Payment",
        status: "succeeded"
      }
    }

    this.global.paymentSuccess = 1

    this.CloseOrderPaid(result);

  }

  getBalanceorChange() {
    const balance = this.theTotal - this.cashPaymentAmount;

    if (balance > 0)
      return (balance.toFixed(2) + " balance");
    else if (balance < 0)
      return (balance.toFixed(2) + " change owed;")
    else {
      return "Paid in full"
    }


  }

  // NEW USER REGISTRATION
  setContact(info: Contact) {
    // After User has created account
    const db = firebase.firestore();
    var self = this;

    console.log("User contact: ", info);

    self.contact = info;
    if (!self.global.authuser) {

      if (!this.global.authuser) {
        if (!self.contact.firstName || self.contact.firstName.length == 0
          || !self.contact.lastName || self.contact.lastName.length == 0
        ) { alert("Please enter a name."); return; }

        const docRef = db.collection('users').doc();
        self.customerId = docRef.id;
        self.registerService.JustRegisterUser(docRef, self.contact.email, self.contact.firstName, self.contact.lastName,
          true, self.myRewardPoints, self.userRegCallback, self);
        self.AddUserToOrder(docRef.id, self.contact.firstName + " " + self.contact.lastName);

        self.registered = true;
        //  this.GetInspiration();
      }
    }
  }
  afterLogin () {
    var self = this;

    console.log("afterlogin",self.global.authuser)
    this.startCheckout();
    
  }
  userRegCallback(self: this, user: User) {
    // This is the User;
    console.log("usereg")
    self.global.authuser = user;
    //   alert("after user  set")
    localStorage.setItem('uid', user.uid);

    self.cartService.GetMyOrder();
    this.startCheckout();

  }

  customerId: string = "";
  /*
    registerUser() {
      var self = this;
  
  
      self.email = self.email.toLowerCase();
  
  
      /// validate email
  
      var firstName, lastName;
  
  
      var name = this.stripeTest.get('name').value;
      // name = "John House"
  
      const arr = name.split(' ');
  
      firstName = arr[0];
      lastName = arr[1];
      if (arr.length > 2)
        lastName = arr[2];
  

      var db = firebase.firestore();
  
  
      console.log("Joining Perks: ", self.myRewardPoints, firstName, lastName, self.email);
      const docRef = db.collection('users').doc();
      self.customerId = docRef.id;
      self.registerService.JustRegisterUser(docRef, self.email, firstName, lastName, true, self.myRewardPoints);
      self.AddUserToOrder(docRef.id);
  
    }
  */

  getSubTotal() {
    var subTotal = "";

    if (this.myOrderTotal) subTotal = '$' + this.myOrderTotal.toFixed(2);
    //   if (this.myPointsTotal) subTotal += " " + this.myPointsTotal.toFixed(0) + " points";

    return subTotal;
  }

  updateTip(index) {
    console.log("index ", this.tipValue);

    if (index != 8 || (index == 8 && this.customTip > 0)) {
      this.viewPayButton = true;
    }
    //    if (index != 8)
    this.customTip = 0;

    this.getTotal();

  }
  tenderCash() {
    this.cashPayment = true;

  }

  getTotal() {
    var self = this;
    self.salesTax = self.myOrderTotal * .0775;

    if (self.tipValue) {
      if (self.customTip > 0) {
        self.tipAmount = self.customTip
      }
      else {
        var tv = this.tipValue;
        if (!tv) tv = 0;

        if (this.tipOptions[tv].type == 'per')
          self.tipAmount = self.myOrderTotal * (this.tipOptions[tv].value / 100);
        else if (this.tipOptions[tv].type == 'cur')
          self.tipAmount = (this.tipOptions[tv].value);
      }
    }
    self.theTotal = self.myOrderTotal + self.salesTax + self.tipAmount;
    self.theTotal = +self.theTotal.toFixed(2);

    if (!self.cashPayment && self.cashPaymentAmount)
      self.theTotal -= self.cashPaymentAmount;  // hack - show on line

    self.myRewardPoints = (self.myOrderTotal + self.tipAmount) * 10;

    console.log(self.tipAmount, tv, self.theTotal, self.cashPaymentAmount);
    //   this.stripeTest = this.fb.group({
    //     name: ['', [Validators.required]],
    //     amount: [self.theTotal, [Validators.required, Validators.pattern(/\d+/)]]
    //   });

    self.xamount = self.theTotal;


    if (self.elements) {
      //   self.paymentElement.elementsOptions.mode ='payment';// ({ elementsOptions: self.elementsOptions});

      // elementsOp.mode = 'payment';
      // self.elements.update({ amount: self.theTotal * 100 });
    }
  }
  setStripeInstance(account) {
    //  this.stripe = this.stripeFactory.create(env.stripe.published_key, {
    //    stripeAccount: account
    //  });
  }

  collectCard() {
    this.createPaymentIntent(this.theTotal * 100)
      .subscribe(pi => {
        console.log("pi ", pi);
        this.elementsOptions.clientSecret = pi.client_secret;
      });

  }
  /*
  pay(): void {
    var self = this;
    if (1) {
      this.paying = true;
      this.createPaymentIntent(this.theTotal * 100)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.global.authuser.firstName +" "+this.global.authuser.lastName,
                  email: this.global.authuser.email,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          this.paying = false;
          if (result.error) {
            Logger("Payment Error", "", this.global.myCompany.store, result.error.message);
            alert(result.error.message);
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              this.global.paymentSuccess = 1;
              //    this.GetRedeemable()
 
              this.CloseOrderPaid(result);
            }
          }
        });
    } else {
      console.log("Bad Form ", this.stripeTest);
    }
  }
*/
  pay() {
    if (this.paymentElementForm.valid) {
      this.paying = true;
      this.stripeService.confirmPayment({
        elements: this.paymentElement.elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: this.paymentElementForm.get('name').value,
              email: this.paymentElementForm.get('email').value,
              address: {
                line1: this.paymentElementForm.get('address').value || '',
                postal_code: this.paymentElementForm.get('zipcode').value || '',
                city: this.paymentElementForm.get('city').value || '',
              }
            }
          }
        },
        redirect: 'if_required'
      }).subscribe(result => {
        this.paying = false;
        console.log('Result', result);
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          alert({ success: false, error: result.error.message });
        } else {
          // The payment has been processed!
          if (result.paymentIntent.status === 'succeeded') {
            // Show a success message to your customer
            this.global.paymentSuccess = 1;
            //    this.GetRedeemable()

            this.CloseOrderPaid(result);
            alert({ success: true });
          }
        }
      });
    } else {
      console.log(this.paymentElementForm);
    }
  }

  private createPaymentIntentSave(amount: number, customer: string): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/secret`,
      {
        amount: amount,
        customer: customer
      }
    );
  }




  private createPaymentIntent(amount: number, customer?: string): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/create_payment_intent`,
      { amount }
    );
  }

  private createCustomer(): Observable<StripeCustomer> {
    console.log("Create Customer");
    return this.http.post<StripeCustomer>(
      `https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/customer_create`,
      {
        name: this.global.authuser.firstName + " " + this.global.authuser.lastName,
        email: this.global.authuser.email
      }
    );
  }
  /*
 
    const emailInput = document.querySelector('#email');
 
    fetch('https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/create-customers', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: "scott@bobs.com",
      }),
    }).then(r => {
      r.json()
      console.log("Cust: ", r)
    });
 
 
 
  }
*/


  returnToPos() {
    this.card.unmount();
    this.router.navigate(['front']);
  }

  toggleViewDetail() {
    this.viewDetail = !this.viewDetail;
    if (!this.viewDetail) { this.detailText = "see detail"; }
    else { this.detailText = "hide"; }
  }


  CloseOrderPaid(result: any) {
    var self = this;

    var db = firebase.firestore();
    // CUSTOMER BEING CHECKED-OUT


    console.log("customer: ",)
    //  if (self.global.customer) id = self.global.customer.uid;

    var success = false;

    var allClosed = false;

    var testMode = true;

    self.cartService.myOrder.forEach(function (item: Order, index) {

      var id = "";
      if (item.userID) id = item.userID;
      db.collection("orders").doc(item.id).update({
        status: "Closed",
        paid: true,
        tipAmount: self.tipAmount,
        salesTax: self.salesTax,
        totalCharge: self.theTotal,
        cashTendered: self.cashPaymentAmount,
        userID: id,
        resultId: result.paymentIntent.id,
        resultStatus: result.paymentIntent.status,
        name: self.global.authuser.firstName + " " + self.global.authuser.lastName,
        closedAt: Date.now(),
        testMode: testMode
      })
        .then(() => {
          console.log("Order Paid & Closed!");

          if (index == self.sharedDataService.payOrder.length - 1) {
            // this is the last one
            self.AddPointsToUser();
          }

          // success = true;

        })
        .catch((error) => {
          success = false;
          alert("Closing Order falied " + error);
          console.error("Error updating status for paid: ", error);
        });

    })

    //    if (success) {
    // SO NOW ADD POINTS TO ACCOUNT - UNLESS USING POINTS TO PAY
    /* BAD CODE - MUST BE INSIDE PROMISE.
        if (id) {
          // CHECKING OUT USER RECORD SHOULD BE LOADED INTO GLOBAL
          self.global.customer.following.forEach(function (arrayItem) {
            // FIND STORE
            if (arrayItem.id == "4390") { // HACK HACK
              arrayItem.points += self.theTotal * 10; // Magic ONLY PAID ITEMS
             // self.myRewardPoints = arrayItem.points;
    
              // Now Subtract Points USED!
              if (self.myPointsTotal) {
                arrayItem.points -= self.myPointsTotal;
    
              }
    
    
            }
          })
    
    
          db.collection("users").doc(id).update({
    
            following: self.global.customer.following
          }).then(() => {
            console.log("Points ADDED!")
            self.messageService.add({ key: 'tc', severity: 'info', summary: 'Reward!', detail: 'You earned ' + self.theTotal * 10 + ' points!' });
          //  this.GetRedeemable(self.companyID);
    
          })
            .catch((error) => { console.log("ERROR ADDING POINTS!"); })
          //      }
        }
     
        else {    // goto Success HTML
          this.global.paymentSuccess = 1;
        }
        */
  }

  AddPointsToUser() {
    // Only for already registered user, new user points assigned with register
    var self = this;

    if (self.global.authuser) {
      var db = firebase.firestore();

      const storeNum = self.global.myCompany.store;

      const index = self.global.authuser.following.findIndex(item => item.storeNum === storeNum);

      if (index != -1) {
        self.global.authuser.following[index].points += self.myRewardPoints;

        if (this.global.customer) {
          db.collection("users").doc(this.global.authuser.uid).update({
            following: self.global.customer.following
          }).then(() => {
            console.log("Points ADDED! ", self.myRewardPoints, self.global.authuser.following[index].points)
            //  self.messageService.add({ key: 'tc', severity: 'info', summary: 'Reward!', detail: 'You earned ' + self.theTotal * 10 + ' points!' });
            //  this.GetRedeemable(self.companyID);

          })
            .catch((error) => { console.log("ERROR ADDING POINTS!"); })
          //      }


        }
      }
    }

  }

  AddUserToOrder(userId: string, name) {
    var self = this;

    var db = firebase.firestore();
    // CUSTOMER BEING CHECKED-OUT
    //  if (self.global.customer) id = self.global.customer.uid;

    var success = false;

    self.cartService.myOrder.forEach(function (item: Order) {
      //  var id = "";
      //  if (item.userID) id = item.userID;
      db.collection("orders").doc(item.id).update({
        userID: userId,
        name: name,
      })
        .then(() => {
          console.log("User updated!");

        })
        .catch((error) => {
          success = false;
          console.error("Error updating userid ", error);
        });

    })
  }





  GetOrders() { // GET ALL ORDERS auto-sort to NEW on top
    var self = this;
    self.myOrderTotal = 0;
    self.myPointsTotal = 0;

    // This function now adds and verifies order
    var allDelivered = true;
    this.cartService.myOrder.forEach(function (item) {
      console.log(item);
      if (item.status != "Closed") {
        if (!item.RewardPaid) {
          self.myOrderTotal += item.extPrice; //data.price * data.qty;
        }
        else { // Cost of Reward Points

          self.myPointsTotal += item.RewardPaid;
        } if (self.tableNameQR) {
          if (item.price > 0 && item.status != "Delivered") {
            allDelivered = false;
          }
        }

      }

    })
    console.log("Order total: ", self.myOrderTotal);
    /*
    self.salesTax = self.myOrderTotal * .0775;
    self.theTotal = self.myOrderTotal + self.salesTax + self.tipAmount;
  
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      amount: [self.theTotal, [Validators.required, Validators.pattern(/\d+/)]]
    });
    */
    this.getTotal();
    // self.myPointsTotal = (self.myOrderTotal) * 10;  // HACK HACK

    /*
      var db = firebase.firestore();
      console.log("Getting Checkout order: ", self.user.uid, self.companyID);
      
      db.collection("orders").where("companyID", "==", self.companyID).where("userID", "==", self.user.uid)
          .onSnapshot((querySnapshot) => {
              self.orders = [];
              var allDelivered = true;
              self.myOrderTotal = 0;
              querySnapshot.forEach((doc) => {
                  var data = <Order>doc.data();
                  
        //         HACK to REMOVE orders for no one 
        //          if (data.userID == "")
        //          db.collection("orders").doc(data.id).delete().then(() => {
        //            console.log("Document successfully deleted!");
        //        }).catch((error) => {
        //            console.error("Error removing document: ", error);
        //        });
      
                    
      
                  if (data.status != "Closed") {
                      if (!data.id) data.id = doc.id; // AUTO-ID -hack 
                      self.orders.push(data);
                      self.myOrderTotal += data.price * data.qty;
                      if (self.tableNameQR) {
                          if (data.price > 0 && data.status != "Delivered") {
                              allDelivered = false;
                          }
                      }
          
                  }
              }); // Have all orders
      this.fb.group({
                name: ['', [Validators.required]],
                amount: [self.myOrderTotal, [Validators.required, Validators.pattern(/\d+/)]]
              });
      
      
        //      self.CheckOutActive = allDelivered;
      
              console.log("order ", self.orders);
        //      orders = self.myOrder;
              console.log("order updated", );
          })
      
      */
  }

  redeemTotal: number = 0;
  redeemCount(): number {
    var self = this;

    this.redeemTotal = 0;


    this.global.redeem.forEach(function (item) {
      if (item.retail > 0 && item.active && (item.retail < (self.myRewardPoints + 100) / 100)) {
        self.redeemTotal++;
      }
    })
    // console.log("Redeemable items: ", this.redeemTotal, self.myRewardPoints)
    return this.redeemTotal;

  }

  GetRedeemable() {
    var db = firebase.firestore();
    var self = this;

    // Already done not needed
    this.red = [];

    const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").where("active", "==", "true")

  //  db.collection("products").where("companyID", "==", self.global.myCompany.id).where("active", "==", "true")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Product>doc.data();
          if (data.retail > 0 && data.active && data.redeemable && (data.retail < (self.myRewardPoints + 100) / 100)) {
            self.red.push(data);
          }

        })


      }).catch((error) => {
        console.log("Error getting redeemables:", error);
      });
    console.log("Red: ", this.red);
  }

  GetCompany(companyID: string) {
    var db = firebase.firestore();
    var self = this;
    // var docRef = db.collection("company").where("store", "==", companyID); //.doc(companyID);
    console.log("get Company ID ", companyID);
    companyID = "4390";
    db.collection("company").where("store", "==", companyID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //    const data = <Company>doc.data();

          //  docRef.get().then((doc) => {
          //    if (doc.exists) {
          self.myCompany = <Company>doc.data();
          const data = <Company>doc.data();
          self.companyID = data.id;

          console.log("stripeAccount ", self.myCompany.stripeAccountID);
          this.setStripeInstance(self.myCompany.stripeAccountID);
          //        self.getCart();
          //        self.GetOrders();
        })

      }).catch((error) => {
        console.log("Error getting company:", error);
      });

  }


  dateTimeSort() {

    this.sharedDataService.payOrder.sort(function (a, b) { return b.dateTime - a.dateTime; });

  }
  // Redemption //
  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  // Redemption //






}





function createPaymentIntentSave2(amount: number, customer: string) {

  return fetch('https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/secret', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount: amount,
      customer: customer,
    }),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      return data.client_secret;
    });




}