import { Component, ViewChild, OnChanges, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Query, When, SessionField, Container, ContainerSettings, Report, Field, Form, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from '../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../menu/cart.service';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../menu/nav.service';
import { environment } from '../../../environments/environment';
import { Table } from '../product-int'
import { AccordionModule } from 'primeng/accordion';
import { NgAuthService } from "../../ng-auth.service";
import { Creds, Follows, User } from './../../components/user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { Observable, of } from 'rxjs';
import { ViewportScroller } from "@angular/common";
import { ReviewsService } from "../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { Product, Options } from '../../components/product-int';
import { ButtonModule } from 'primeng/button';
import { Order } from '../order-interface';
import { CurrencyPipe } from '@angular/common'
import { Accordion } from 'primeng/accordion'
import { FormService } from '../form.service';


@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.css', '../../common.scss']
})
export class DataComponent implements OnInit {
  @ViewChild('itemAccordion') itemAccordion: Accordion;
  @Input() open: boolean;
  @Input() api: string;

  viewStore: boolean = false;
  viewSubmissions: boolean = false;
  viewStats: boolean = false;
  actions: boolean = false;
  addingField: boolean = false;
  operators: any[];
  activeIndex = 0;
  formName = "";

  currentForm: Form;

  fieldTabs: boolean[];
  formTabs: boolean[];

  dataList: string[];
  dataSettings: ContainerSettings;
  formSettings: ContainerSettings;

  style: string;

  currentTab: string;


  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public ngAuthService: NgAuthService,
    public nav: NavService,
    public reviewsService: ReviewsService,
    private scroller: ViewportScroller,
    private cp: CurrencyPipe,
    public formService: FormService,

  ) {
    this.formService.getFunctions();

    this.operators = ["times", "plus", "minus", "divided by", "equals", "average of"]

  }

  ngOnInit(): void {
    var self = this;
    this.dataList = ["Reports", "Analytics"]

    self.getForms();
    self.getFields();
    self.getReports();
    self.getQueries();

  //  if (this.open) {

      this.dataSettings = { width: "50%", backgroundColor: "#E17D13" }
      //    this.formSettings = { width: "80%", backgroundColor: "#77345a", color: 'black' }
      //    this.fieldTabs = [];
      //    this.formTabs = [];
      //  if (!this.global.allForms) {
      //   self.getForms();
      //   self.getFields();
    //  self.getReports();
    //  self.getQueries();
      // }
  //  }
    if (this.api == "Forms") {
      //   self.getForms();
      //   self.getFields();
    }
    if (this.api == "Reports") {

    }


  }

  tabSelected(event) {
    var self = this;

    var data = <Container>event;

    this.currentTab = self.dataList[data.index]


    console.log("Database Tab is: ", data.index, data.node, self.currentTab)

    return;
    /*
        console.log("event", event, index)
        if (index == -1) return;
        self.currentForm = self.global.allForms[index];
    
        self.updateNumberFields(self.currentForm);
    */
  }

  reportSelected(event) {

  }
  querySelected(event) {

  }

  formSelected(event) {
    var self = this;

    var data = <Container>event;

    self.formName = self.global.allFormNames[data.index]
    self.global.formIndex = data.index;
    // Hack - Re-Load all forms to create a new "session" - 
    /*
        // Set up fields
        self.global.fields = [];
        self.global.allForms[self.global.formIndex].fields.forEach(function (item) {
          self.global.fields.push(item)
        })
    */
    console.log("FORMS ", data.node, data.index)
    //  self.global.allForms[data.index].hideForm = false;

    // Clear field data
    self.global.allForms[data.index].fields.forEach(function (field) {
      field.binding = null;
      field.value = null;
    })

  }

  formsListener = false;
  getForms() {
    var self = this;
    var db = firebase.firestore();
    var index = 0;

    if (this.formsListener) return;

    self.global.allForms = [];
    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("forms")
      .onSnapshot((querySnapshot) => {
        //   index = 0;
        self.global.allForms = [];
        self.global.allFormNames = [];
        self.global.allSessionFields = [];
        console.log("BEFORE UPDATE: ", self.global.allForms.length)
        self.formsListener = true;
        querySnapshot.forEach((doc) => {
          const m = <Form>doc.data();
          if (!m.deleted) {
            //    console.log("FORM: ", m)
            self.global.allForms.push(m);
         //   self.global.allFormNames.push(m.name);
            m.fields.forEach(function (field) {
              self.global.allSessionFields.push({ id: field.id, sourceForm: m.name, sourceField: field.title, type: field.type })

            })
          }
        })

        self.global.allForms.sort(function (a, b) {
          return b.timeStamp - a.timeStamp;
        });
        self.global.allForms.forEach(element => {
          self.global.allFormNames.push(element.name)
        });

        console.log("ALL Forms ", self.global.allForms, self.global.allSessionFields)

      })


  }
  reportsListener = false;

  getReports() {
    var self = this;
    var db = firebase.firestore();
    if (self.reportsListener) return;

    var index = 0;

    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("reports")
      .onSnapshot((querySnapshot) => {
        self.global.allReports = [];
        self.global.allReportNames = [];
        self.reportsListener = true;
        querySnapshot.forEach((doc) => {
          const m = <Report>doc.data();
          m.id = doc.id;
          console.log("Report: ", m)
          self.global.allReports.push(m);
          self.global.allReportNames.push(m.title);
        })
        console.log("ALL reports ", self.global.allReportNames, self.global.allReports)
      })
  }

  queryListener = false;

  getQueries() {
    var self = this;
    var db = firebase.firestore();
    if (self.queryListener) return;

    var index = 0;

    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("Queries")
      .onSnapshot((querySnapshot) => {
        self.global.allQueries = [];
        self.global.allQueryNames = [];
        self.queryListener = true;

        querySnapshot.forEach((doc) => {
          const m = <Query>doc.data();
          m.id = doc.id;
          self.global.allQueries.push(m);
          self.global.allQueryNames.push(m.title);
        })
        console.log("ALL queries ", self.global.allQueries)
      })
  }



  fieldsListener = false;

  getFields() {
    var self = this;
    var db = firebase.firestore();
    if (self.fieldsListener) return;

    var index = 0;

/*
    db.collection('company')
      .doc(self.global.myCompany.id)
      .collection("fields")
      .onSnapshot((querySnapshot) => {
        index = 0;
        self.global.allFields = [];
        self.fieldsListener = true;
        querySnapshot.forEach((doc) => {
          const m = <Field>doc.data();

          self.global.allFields.push(m);

        })

      })
    console.log("Fields ", self.global.allFields)
    */

    db.collection('platform')
      .doc("OdCDgRWTZhTMzW8N0y9y")
      .collection("fields")
      .onSnapshot((querySnapshot) => {
        index = 0;
        if (!self.global.allFields) self.global.allFields = [];
        querySnapshot.forEach((doc) => {
          const m = <Field>doc.data();
          if (!m.position) m.position = 99;

          // check for updates
          const index = self.global.allFields.findIndex(function (post) {
            if (post.id == m.id)
              return true;
          });
          if (index == -1 && !m.internalOnly)
            self.global.allFields.push(m);
          else {
            // REPLACE
            self.global.allFields[index] = m;
          }
        })

        self.global.allFields.sort(function (a, b) { return a.position - b.position; });

        console.log("All abc Fields ", self.global.allFields)

      })
  }


  selectForm(form: Form) {
    this.currentForm = form;
    console.log("Form selected ", form.name)


  }

  updateNumberFields(form: Form) {
    var self = this;

    form.numberFields = [];
    form.fields.forEach(function (field) {
      if (field.type == 'number' || field.type == 'currency' || field.type == 'result') { //hack use tags
        form.numberFields.push(field.title)
      }
    })

    console.log("NUMBER FIELD LIST: ", form.numberFields, self.global.allForms)

    form.fields.forEach(function (field) {
      if (field.type == 'results') {
        console.log("NUMBER FIELD LIST: ", form.numberFields)

        field.available = [...form.numberFields];
      }


    })
  }

  outputEvent(form: Form, field?: Field, editForm?: Form) {
    var self = this;

    console.log("FORM UPDATED: ", editForm, form, field);

    // Field here is the name of the actual field being edited.
    // editForm is the SOURCE TO UPDATE IF exists
    // form is the data from user interaction, 
    // wherein we will find output events such as a button press


    // Let's SCAN & FIRE all events we find in form
    form.fields.forEach(function (item, index) {
      if (item.outputs)
        item.outputs.forEach(function (event, index) {
          console.log("FIRE EVENT: ", event)
          if (event.value == "button pressed" && event.name == 'Remove field') {

            const fieldToRemove = self.findMe(field.title, editForm)

            editForm.fields.splice(fieldToRemove, 1)
            console.log("REMOVING FIELD ", fieldToRemove, editForm)
            self.updateForm(editForm);


          }

        })
    })



    const type = form.fields[self.findMe("Field type", form)].selected[0];
    if (field && type) {
      field.type = type;
      //   field.name =  type;
      self.updateForm(editForm);
    }



  }


  addForm(event) {

    event.stopPropagation();

    // console.log("ADDING FORM ", newForm)

    this.newForm();
  }

  addField() {

    this.addingField = !this.addingField;

  }

  addFieldToForm(form: Form, field: Field) {
    console.log("Form & field add ", form, field)
    form.fields.push(field);
    this.updateForm(form);


  }


  formSubmitted(form: Form) {
    console.log("Form data: ", form);

    // Store new Field in Collection

    var self = this;
    var db = firebase.firestore();

    // Check for Actions in the form
    if (form.actions) {
      if (form.actions[0].action == "Go to form") {
        console.log("Go to form: ", form.actions[0].destination)

        var me = form.actions[0].destination.toLowerCase();

        const index = self.global.allForms.findIndex(function (post) {
          if (post.name.toLowerCase() == me)
            return true;
        });

        console.log("active: ", self.activeIndex)

        self.itemAccordion.activeIndex = index;
      }

    }







    /*

    const fieldTitle = form.fields[this.findMe("title", form)].binding;
    var fieldClass = form.fields[this.findMe("class", form)].binding;
    const fieldType = form.fields[this.findMe("Field type", form)].binding;

    fieldClass = fieldTitle.toLowerCase(); //hack
    //  var docRef = db.collection("company").doc(self.global.myCompany.id).collection("fields")
    var docRef = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("fields")
      .doc()
    var newField: Field = {
      id: docRef.id,
      binding: "",
      title: fieldTitle,
      type: fieldType,
      class: fieldClass,

    }
    docRef.set(newField).then(() => {
      console.log("field added");

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });


      */

  }
  findMe(me: string, form: Form) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { alert("oopsie " + me) }
    return index;

  }

  setBinding(aorb, field: Field, d) {
    /*  if (aorb == 'a')
        field.fielda = d;
      else if (aorb == 'b')
        field.fieldb = d;
      else if (aorb == 'operator')
        field.operator = d;
  
      this.updateForm(this.currentForm)
  */
  }



  updateForm(event: Form) {
    var self = this;
    var db = firebase.firestore();


    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc(event.id)

    docRef.update(event).then(() => {
      console.log("form updated");

      // Hack find and update global
      const index = self.global.allForms.findIndex(function (post) {
        if (post.id == event.id)
          return true;
      });
      if (index != -1) {
        self.global.allForms[index] = { ...event }
        console.log("Bull: ", self.global.allForms[index])
      }


    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  getBackColor(i: boolean) {
    if (i == true) return "#d5e7f9";

    return "#1a77f9"

  }

  newForm() {
    var self = this;

    var newForm: Form = {
      id: "",
      userId: self.global.authuser.uid,
      timeStamp: Date.now(),
      name: "New form",
      showSubmit: true,
      autoSave: true,
      fields: [],
    }

    // let's ensure unique title
    var digit = 2;
    var unique = true;
    var title = newForm.name;

    //  var newField = {...field};

    do {
      const exists = self.global.allForms.some(function (post) {
        if (post.name == title)
          return true;
      });
      if (exists) {
        title = newForm.name + " " + digit.toString();
        unique = false;
        digit++;
      }
      else unique = true;
    }
    while (!unique)

    newForm.name = title;



    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("forms")
      .doc()
    newForm.id = docRef.id;

    docRef.set(newForm).then(() => {
      console.log("form added");

    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }

  newReport() {
    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("reports")
      .doc()

    var report: Report = {
      id: docRef.id,
      userId: self.global.authuser.uid,
      timeStamp: Date.now(),
      title: "New report",
    }
    docRef.set(report).then(() => {
      console.log("Report added");
    })
      .catch((error) => {
        console.error("Error writing report: ", error);
      });

  }

  newQuery() {
    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("Queries")
      .doc()

    var query: Query = {
      id: docRef.id,
      userId: self.global.authuser.uid,
      timeStamp: Date.now(),
      title: "New query",

    }
    docRef.set(query).then(() => {
      console.log("Query added");
    })
      .catch((error) => {
        console.error("Error writing query: ", error);
      });

  }



  getFontColor(i) {
    if (i) return "#000000";
    return "#ffffff";
  }

  getMyName(i, name) {
    //  console.log (i,name)
    if (name == "gostore") {
      if (this.viewStore) return "Close Store"
      else return "Open Store"

    }
  }

  getLeftMargin(i) {
    if (i) return "10px";
    if (this.global.getScreenWidth > 900)
      return "280px";
    else if (this.global.getScreenWidth > 600)
      return "160px";
    else
      return "50px";

  }

  getRightMargin(i) {
    if (i) return "8px";
    if (this.global.getScreenWidth > 900)
      return "280px";
    else if (this.global.getScreenWidth > 600)
      return "180px";
    else
      return "68px";
  }

  // TEMP FUNCTION - move form to another record

}
