import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { MapOptions } from '../user-interface';

var mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.css']
})
export class MapsComponent implements OnInit, AfterViewInit {

  constructor(public global: Globals) {

  }
  @Input() mapOptions: MapOptions;

  map;
  instance;


  ngOnInit(): void {
    this.instance = Math.floor((Math.random() * 1000 + Math.random() * 1000)).toString();


    mapboxgl.accessToken = 'pk.eyJ1Ijoic2NvdHRqd2FybmVyIiwiYSI6ImNseGFnbHc1ZjFmOWgya3B6bzFncnd0aW0ifQ.Xw5_ao-RjmVTvb4sM-4zEw';

    this.mapOptions.attributionControl = false;
    this.mapOptions.style = 'mapbox://styles/mapbox/satellite-streets-v11';
    this.mapOptions.zoom = 16;
    if (!this.mapOptions.center)
      this.mapOptions.center = [this.global.myCompany.long, this.global.myCompany.lat];
    console.log("Map: ", this.mapOptions, this.instance)


  }
  ngAfterViewInit(): void {
    this.map = new mapboxgl.Map(this.mapOptions);
  }

}

