<p-accordion fxLayout="column" fxLayoutAlign="center none" expandIcon="null" collapseIcon="null" [multiple]="true"
    activeIndex="viewTabItem" *ngFor="let order of closedOrders; let i = index">
    <p-accordionTab styleClass="dark-panel" [(selected)]="order.isExpanded">
        <ng-template pTemplate="header" styleClass="dark-panel">
            <div class="left-side2">
                <span style="font-size:80%"> {{dateFormatter(order.closedAt)}}</span>

                <span *ngIf="order.qty>1" style="font-size:1.2em; line-height:1.1em;  ">{{order.qty}}</span>

                {{lineItem(order)}}<span style="font-size:.8em;">{{lineItemOptions(order)}}
                </span>
                <div class="right-side2" fxLayout="row">

                    {{" " + getExtPrice(order)}}
                    <div *ngIf="order.discountsApplied && order.discountsApplied.length" style="font-size:75%">
                        {{order.discountsApplied[0].name+" "}} Discount applied
                    </div>

                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="content">
            <div style="width:50%">

            </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>