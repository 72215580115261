<div class="no-break" style="margin:12px; background-color: white; color:rgb(32, 0, 150)">

  <blockquote>
    <div style="margin:12px; background-color: rgb(255, 255, 255); color:rgb(32, 0, 150)">
      <div [innerHtml]="reviewService.getFilteredText(p, tags)"></div>
    </div>
  </blockquote>
  <div class="review__subtitle">
    {{p.author_name}}
    <span style="text-align: center; font-weight:500; font-size: .75rem;">
      {{" "+p.dateStr}}</span>
  </div>


</div>