import { Component, OnInit } from '@angular/core';
import { User, Follows } from '../../user-interface'
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import firebase from 'firebase/compat/app';
import { NgAuthService } from "../../../ng-auth.service";
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Company, Rewards } from '../../company-interface';

// REGISTER FOR REWARDS PROGRAM
// Once fully registered, show here a list of items can be bought with Lpoints


@Component({
  selector: 'app-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.css']
})
export class RewardsComponent implements OnInit {
  userName: string;
  email: string;
  validation: number;
  store: string;
  valCode: string;
  user: User;
  uid: string;
  logoImg: string;

  constructor(private ngAuthService: NgAuthService, private router: Router) { }

  ngOnInit(): void {
    this.userName = "";
    this.email = "";
    

    // USER STATES: Not Logged In, Logged In - Not Verified, Fully Registered
    // Logging in on new computer requires email and code (can always get new code)

   // this.logoImg = "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FRewardslogoHome-01.png?alt=media&token=1b1a4039-d7e5-4d91-b4e8-685644822a91";
    this.logoImg = "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FCHV-logo-128W.jpg?alt=media&token=2a8cd554-e786-46cc-a994-ac739667be07"
   init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER
    // CHECK IF USER/DEVICE IS REGISTERED
    const id = localStorage.getItem('uid');
    if (id) {
      
      this.GetUserwID (id);
    }
  }

  changeUserName(e: Event) { }

  VerifyEmail() {
    var self = this;

    console.log("VERIFY: ", this.validation);
    if (this.validation == this.user.keyCode) { // MATCH
      console.log("Verified");
      this.user.emailVerified = true;
   //   localStorage.setItem('uid', JSON.stringify(this.user.uid));

      var db = firebase.firestore();

      var catRef = db.collection("users").doc(this.user.uid);
      if (catRef) {
        catRef.update({
          "emailVerified": true
        });

      }
      this.sendEmailVerified();
      this.router.navigate(['menu']);
    }
  }

  public sendEmail() {
    var templateParams = {
      from_name: 'Crystal Hill Vineyard',  // Company Name
      to_name: this.userName,
      to_email: this.email,
      code: this.user.keyCode,  // ADD BAD CODE ATTEMPT COUNTER
      message: 'Thanks for registering for Rewards. Please enter your verification code below to activate rewards.'
    };

    console.log("EMAIL: ", this.userName, this.email);
    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_fsas3w8", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  public sendEmailVerified() {
    var templateParams = {
      from_name: 'Crystal Hill Vineyard',  // Company Name
      to_name: this.userName,
      to_email: this.email,
      code: this.user.keyCode,  // ADD BAD CODE ATTEMPT COUNTER
      sid: this.user.sid,
      message: 'Welcome to CHV',
    };

    console.log("EMAIL: ", this.userName, this.email);
    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_j23qemn", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  Login() {
    var self = this;
    self.email = self.email.toLowerCase();

    var st = localStorage.getItem('store')
    console.log(self.email);
    if (!st) st = "4390";
    else self.store = JSON.parse(st);

    // Sign In
    self.GetUser(self.email);

    //   Log ("New user", user.uid, company, user.email); 

  }
  

  RegisterUser() {
    var self = this;

    self.email = self.email.toLowerCase();

    var db = firebase.firestore();

    var st = localStorage.getItem('store')
    console.log(st);
    if (!st.length) {
      st = "4390";
      self.store = JSON.parse(st);
    }
    else self.store = JSON.parse(st);

    var follow: Follows[] = [{
      id: self.store,//FIX
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: ""

    }]
    const n = (Math.random() * 900).toPrecision(3);

    follow = JSON.parse(JSON.stringify(follow));
    console.log("Follow: ", follow)
    const newUser: User = {
      uid: "",
      email: self.email,
      displayName: self.userName,
      photoURL: "",
      emailVerified: false,    //user.emailVerified,
      companyID: self.store,
      staff: false,
      following: follow,
      keyCode: Number(n),
      uPoints: 0,
    }
    console.log("User: ", newUser)

    // HAVE NEW USER REGISTRATION:
    // CHECK FOR EXISTING
    var docRef = db.collection("users").where("email", "==", self.email);
    docRef.get().then((doc) => {
      //      ((querySnapshot) => {
      if (!doc.empty) {
        //    querySnapshot.forEach((doc) => {
        //const data = <User>doc.data();
        //self.companyID = data.id;
        //
        console.log("Email already associated with account");


        //   });
      }
      else {
        console.log("no account found, so add it");
        var docRef = db.collection("users").doc();
        newUser.uid = docRef.id;


        docRef.set(     //{  // AUTO-ID DOC
          newUser,
        )
          .then(() => {
            console.log("New user added");

            this.user = newUser;

            self.sendEmail();
       //     localStorage.setItem('user', JSON.stringify(newUser));

            localStorage.setItem('uid', newUser.uid);

          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });

      }
    })
    //  }



    //   Log ("New user", user.uid, company, user.email); 

  }


  GetUser(email: string) {
    var self = this;

    var db = firebase.firestore();

    db.collection("users").where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
//          self.myCompany.logoImg = "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FRewardslogoTop-01.png?alt=media&token=cbc5bc8e-66b9-442f-9771-a42a10c1d05f";
          self.user = data;
  //        localStorage.setItem('user', JSON.stringify(self.user));

            localStorage.setItem('uid', self.user.uid);

        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });

  }

  GetUserwID(id: string) {
    var self = this;

    var db = firebase.firestore();
    
    db.collection("users").where("uid", "==", id)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          
          self.user = data;
       
      //    self.userName = self.user.displayName;
          self.email = self.user.email;

        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });

  }



myOrders () {
  this.router.navigate(['my-orders']); 

}
navigateBack() {}
navigateCrumb() {
  this.router.navigate(['menu']);
}

SwitchAdmin (){
  this.router.navigate (['admin']);
}
logout() { this.ngAuthService.SignOut(); }
}
