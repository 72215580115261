import { Injectable } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Product } from './product-int'
import { AppNode, NodeType, AppStats, NapkinApp, DataGem, Navigate } from './company-interface'
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Globals } from '../globals';
import { Media, MediaGroup } from './user-interface';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import firebase from 'firebase/compat/app';
import { ProductsService } from './products.service';
import { RegisterService } from './register.service';
import { of, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NodeService {

    colorOptions: string[];
    objectList: { label: string, id: string }[] = [];

    nodeList: NodeType[] = [];

    constructor(public global: Globals, public registerService: RegisterService, private sanitizer: DomSanitizer) {

        this.colorOptions = ['#23890790', '#5093ff33', '#0fe3a2e3', '#45672121',
            '#23452323', '#f3651210'];


        this.objectList = [
            { label: "Navigation/Container", id: 'nav' },
            { label: "Napkin App", id: 'app' },
            { label: "Accordion Folders", id: 'tabList' },
            { label: "Copy Node", id: 'nodecopy' },
            { label: "Content", id: 'content' },
            { label: "Media Group", id: 'mediagroup' },
            { label: "Capture/Upload Media", id: 'mediacapture' },
            { label: "Search Media", id: 'mediasearch' },
            { label: "Audio", id: 'audio' },
            { label: "Big Note", id: 'bignote' },
            { label: "Map", id: 'map' },
            { label: "About Us", id: 'aboutus' },
            { label: "Display Reviews", id: 'reviews' },
            { label: "Collect Review", id: 'getreview' },
            { label: "Reservation", id: 'reserve' },
            { label: "Events", id: 'events' },
            { label: "Join", id: 'join' },
            { label: "Follow me", id: 'followme' },
            { label: "Follow us", id: 'followus' },
            { label: "Weather", id: 'weather' },
            { label: "Chat", id: 'chat' },
            { label: "Customer Chat", id: 'chatnow' },
            { label: "Famous Quote", id: 'famousquote' },
            { label: "Trivia Game", id: 'triviagame' },
            { label: "Form", id: 'form' },
            { label: "Orders", id: 'orders' },
            { label: "Products", id: 'product' },
            { label: "Point of Sale", id: 'pos' },
            { label: "Report", id: 'dataview' },
            { label: "Query", id: 'query' },
            { label: "Visual Playground", id: 'vip' },
            /*
                        { label: "Data Bit", id: "databit" },
                        { label: "Dashboard", id: "dashboard" },
                        { label: "Channel Feed", id: 'channel' },
                        { label: "Deals", id: 'deals' },
                        { label: "Media Manager", id: 'media' },
                        { label: "Clock In/Out", id: 'clockinout' },
                        */





        ]


    }

    convertObjectLabelToId(label: string): string {
        var self = this;
        console.log("label ", label)

        const index = self.objectList.findIndex(function (post) {
            if (label && post.label.toLowerCase() == label.toLowerCase())
                return true;
        });

        if (index != -1) {
            console.log("poipio", index, self.objectList[index].id)
            return self.objectList[index].id;
        }
        else {
            console.log("NOTfount", label,)
            return label;
        }

    }
    convertObjectIdToLabel(id: string) {
        const index = this.objectList.findIndex(function (post) {
            if (post.id == id)
                return true;
        });
        if (index != -1) {
            return this.objectList[index].label;
        }
        else return id;

    }

    createParentId(): string {
        var uid = "";

        // For now just 3 1000 randoms + time;

        var a = Math.floor(Math.random() * 1000).toString();
        var d = Date.now().toString()
        console.log("D ", d)
        uid = a + d.substring(7, 3)

        return uid;
    }

    insertNew(node: AppNode, appId, tabIndex?) {
        var vm = this;
        console.log("INSERT ", node, appId, tabIndex)

        //   if (!vm.global.appId && appId) vm.global.appId = appId;

        var baseNode: AppNode = {
            active: true,
            title: "New Item",
            parent: node.parent,
            objectType: "New Node",
            createdAt: Date.now(),
            createdBy: vm.global.authuser.uid,
            height: 100 + Math.floor(Math.random() * 400),
            width: 400 + Math.floor(Math.random() * 400)
        }

        const i = Math.floor(Math.random() * this.colorOptions.length)

        /* LATER
        // START Container/tabList with one container
        if (node.objectType.toLowerCase() == 'tablist' && !tabIndex) {
            baseNode.containers = [{
                title: "New container",
                parentId: this.createParentId(),
            }]
        }

        if (node.objectType.toLowerCase() == 'tablist' && tabIndex >= 0) {
            baseNode.parent = node.containers[tabIndex].parentId;
        }
            */
        baseNode.backgroundColor = vm.colorOptions[i];
        vm.addNode(node, baseNode, vm.global.myCompany.id, appId, tabIndex);

    }

    addNode(parentNode: AppNode, node: AppNode, docId, appId, tabIndex?) {
        const db = firebase.firestore()

        const parentId = parentNode.id;
        const vm = this;

        console.log("add a node: ", parentNode, node, parentId, vm.global.myCompany.id, appId)

        if (!node.parent)
            node.parent = parentId;
        vm.global.updatingNodes = false;
        var docRef =
            db.collection('apps')
                .doc(appId)
                .collection('nodes').doc()

        node.id = docRef.id;
        //BAAADnode.parent = parentId;

        docRef.set(node).then(() => {
            console.log("Node was added ", node, parentNode);
            /*  if (parentNode.objectType.toLowerCase() == 'tablist') {
                  console.log("UPDATING TAB LIST")
                  // Find it first
                  const index = vm.global.appNodes.findIndex(function (post) {
                      if (post.id == parentId)
                          return true;
                  });
                  if (index != -1) {
                      var pNode = vm.global.appNodes[index];
  
                      console.log("Updating parent tablist ", pNode, collectionId)
                      if (!pNode.tabList) pNode.tabList = ["", "", ""]
                      pNode.tabList[tabIndex] = node.id;   // HACK - fix by creating array with tab
                      vm.updateTabId(pNode, pNode.id, collectionId);
  
                  }
              }*/

        }).then(() => {
            vm.global.updatingNodes = false;

        })
            .catch((error) => {
                console.error("Error savin html", error);
            });

    }

    AddItem(appId, ip) {
    //    const db = firebase.firestore()

        const vm = this;

        var counters = 1;

        if (vm.global.appDoc[appId].app.appStats.counters)
            counters = vm.global.appDoc[appId].app.appStats.counters + 1;

        if (!vm.global.appDoc[appId].app.appStats) vm.global.appDoc[appId].app.appStats = {
            totalOpens: 0, lastOpen: Date.now(), averageSessionTime: 0
        }

        vm.global.appDoc[appId].app.appStats.lastOpen = Date.now();
        if (!vm.global.appDoc[appId].app.appStats.ipAddress)
            vm.global.appDoc[appId].app.appStats.ipAddress = [];

        var found = false;
        if (ip && vm.global.appDoc[appId]?.apps?.appStats) {
            vm.global.appDoc[appId].apps.appStats.ipAddress.forEach(function (item) {
                if (item.ip == ip) {
                    item.lastVisit = Date.now();
                    item.count++;
                    found = true;
                }
            })

            if (!found) {
                vm.global.appDoc[appId].app.appStats.ipAddress.push({ ip: ip, count: 1, lastVisit: Date.now() })
                console.log("Adding Unique IP ", ip)
            }
        }
        vm.global.appDoc[appId].app.appStats.totalOpens++;
/*
        console.log("APPS UPDATER ", appId, ip,vm.global.appDoc[appId].app.appStats)

        db.collection('apps')
            .doc(appId)
            .update({ appStats: vm.global.appDoc[appId].app.appStats }).then(() => {
            })

        return null;
        */
    }

    getNode(appId, nodeId, nodeCallback, mySelf) {
        const db = firebase.firestore()

        const vm = this;

        db.collection("apps")
            .doc(appId).collection('nodes')
            .doc(nodeId)
            .get()
            .then((doc) => {
                const data = doc.data();
                console.log("NODE---", data);
                if (typeof (nodeCallback) == 'function')
                    nodeCallback(data, mySelf);
            });

    }

    getNodeTypes() {
        var self = this;

        const db = firebase.firestore()

        const vm = this;

        db.collection("platform")
            .doc("OdCDgRWTZhTMzW8N0y9y").collection('nodes')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var data = doc.data();

                    self.nodeList.push(data)

                })
                self.nodeList.sort(function (a, b) {
                    return a.pos - b.pos;
                  });
                console.log("Available Node types: ", self.nodeList)
            });

    }

    updateTabId(node: AppNode, docId, collectionId) {
        const db = firebase.firestore()

        const vm = this;
        vm.global.updatingNodes = true;
        console.log("TAB UPDATER ", node, docId, collectionId, node.id)
        var docRef =
            db.collection('apps')
                .doc(collectionId)
                .collection('nodes').doc(node.id)
        docRef.update({ tabList: node.tabList }).then(() => {
            vm.global.updatingNodes = false;

        })
            .catch((error) => {
                console.error("Error savin html", error);
            });
    }

    removeNode(node: AppNode, appId) {
        //  if (!this.global.appId && appId) this.global.appId = appId;

        this.removeOneNode(node, this.global.myCompany.id, appId)
    }

    removeOneNode(node: AppNode, docId, collectionId) {
        const vm = this;
         console.log("Removing a Node: , node")
        const db = firebase.firestore()
        vm.global.selectedTab = ""
 
        var docRef =
            db.collection('apps')
                .doc(collectionId)
                .collection('nodes').doc(node.id)
        docRef.delete()


        const index = vm.global.appNodes[collectionId].nodes.findIndex(function (post) {
            if (post.id == node.id)
                return true;
        });
        if (index != -1) {
            vm.global.appNodes[collectionId].nodes.splice(index, 1);
        }

    }

    moveNode(appId, node: AppNode, upOrDown: string) {
        var self = this;
        // First lets find the index of this item in app Array (ex;15)
        // And lets create a list of indexes for this container (ex;12,13,19,28)
        // So I am 2-before (base0) - move up get value of 1 and Insert Me in that spot - 
        // if move down get value of 3(19) and insert me at index20


        //  if (!this.global.appId && appId) this.global.appId = appId;

        // splice after - 1 Plus 2 if inserted before. 

        var containerNodes: AppNode[] = [];
        var container: any[] = [];
        var meIndex = -1;
        var myNode = -1;
        var allPositions: number[] = [];

        self.global.appNodes[appId].nodes.forEach(function (n, index) {
            if (n.parent == node.parent) {
                // Hack add checks for visibility

                // Let's add Positioning if none here
                if (n.myPosition == null) n.myPosition = container.length;

                container.push(index);
                n.myPosition = index;
                containerNodes.push(n);  // DocId for this node
                allPositions.push(n.myPosition);
                if (node == n) { ///Me
                    meIndex = container.length - 1;
                    myNode = index;
                }
            }
        })
        // Re-Order Positions for each doc
        // 1,2,3,4 - I am 3 move me up - 3 becomes 2 and 2 becomes 3
        /*
            containerNodes.forEach (function(item, index){
              item.myPosition = 
        
            })
        */

        console.log("Move indexes ", allPositions, this.global.appNodes, container, meIndex);
        if (upOrDown == 'up' && meIndex > 0) {
            // Can be moved up

            var spliceIntoIndex = container[meIndex - 1]

            // Swap Positions
            var pos = containerNodes[meIndex - 1].myPosition;
            containerNodes[meIndex - 1].myPosition = containerNodes[meIndex].myPosition;
            if (pos >= containerNodes[meIndex].myPosition) {
                pos = containerNodes[meIndex].myPosition - 1;
            }
            containerNodes[meIndex].myPosition = pos;
            self.updateAppNodes(appId, containerNodes);

            self.global.appNodes[appId].nodes.splice(spliceIntoIndex, 0, node);
            // now remove original
            self.global.appNodes[appId].nodes.splice(myNode + 1, 1)

        }

        if (upOrDown == 'down' && meIndex < container.length - 1) {
            // Can be moved down

            // Swap Positions
            var pos = containerNodes[meIndex + 1].myPosition;
            containerNodes[meIndex + 1].myPosition = containerNodes[meIndex].myPosition;
            if (pos <= containerNodes[meIndex].myPosition) {
                pos = containerNodes[meIndex].myPosition + 1;
            }
            containerNodes[meIndex].myPosition = pos;

            self.updateAppNodes(appId, containerNodes);

            var spliceIntoIndex = container[meIndex + 1] + 1;
            self.global.appNodes[appId].nodes.splice(spliceIntoIndex, 0, node);
            // now remove original
            self.global.appNodes[appId].nodes.splice(myNode, 1)
        }
    }

    updateMasterNodeTags(newTags: string[]) {
        var self = this;
        const db = firebase.firestore()

        var update = false;

        // Check if any new tags
        if (newTags?.length > 0) {
            if (!self.global.myCompany.masterNodeTags) {
                self.global.myCompany.masterNodeTags = newTags;
                update = true;
            }
            else {
                newTags.forEach(function (tag) {
                    const found = self.global.myCompany.masterNodeTags.find((f) => f == tag)
                    if (!found) {
                        self.global.myCompany.masterNodeTags.push(tag);
                        update = true;
                    }
                })
            }
            if (update) {
                console.log("New Node tag Added ", self.global.myCompany.masterNodeTags)
                db.collection('company')
                    .doc(self.global.myCompany.id)
                    .update({ masterNodeTags: self.global.myCompany.masterNodeTags }).then(() => {
                    })

            }
        }
    }

    updateAppNodes(appId, nodes: AppNode[]) {
        const db = firebase.firestore()

        const vm = this;
        vm.global.updatingNodes = true;
        var nodeCount = 0;

        nodes.forEach(function (node, index) {
            nodeCount++;
            var docRef =
                db.collection('apps')
                    .doc(appId)
                    .collection('nodes').doc(node.id)
            docRef.update({ myPosition: node.myPosition })
                .then(() => {
                    console.log("Updated position: ", node.id, appId, node.myPosition)
                    nodeCount--;
                    if (nodeCount == 0) vm.global.updatingNodes = false;
                })

        })
    }

    changeObject(e, object: any, node: AppNode, appId: string) {

        e.stopPropagation();
        this.global.updatingNodes = true;
        console.log("Updating Node TYPE ", node)

        //   if (!this.global.appId && appId) this.global.appId = appId;

        node.objectType = object.nodeCode;
        node.title = object.title;

        if (node.objectType == 'nav') {
            node.parentId = node.id;
        }
        else node.parentId = null;

        var tempNode: AppNode = {
            objectType: node.objectType,
            title: node.title
        }

        // START Container/tabList with one container
        if (node.objectType.toLowerCase() == 'tablist' && !node.containers) {
            node.containers = [{
                title: "New container",
                parentId: this.createParentId(),
            }]
            tempNode.containers = [{
                title: "New container",
                parentId: this.createParentId(),
            }]
        }

        if (node.objectType == 'weather') {
            node.location = this.global.myCompany.postal;
            tempNode.location = node.location;
        }
        if (node.objectType == 'review-card') {

            var found = this.global.publishedReviews.find((f) => f.review_photos?.length > 0 && f.rating == '5')
            if (found != undefined) {
                node.review = found;
                node.review.style = 'polaroid';
                tempNode.review = node.review;
            }
        }
        if (node.objectType == 'polaroid') {
            node.objectType = 'content';
            node.format = 'polaroid';
            tempNode.objectType = node.objectType;
            tempNode.format = node.format;
        }

        console.log("Object ", object, tempNode);
        this.updateNodeObjectType(node, this.global.myCompany.id, appId, tempNode)
    }
    updateNodeObjectType(node: AppNode, docId, collectionId, tempNode: AppNode) {
        const db = firebase.firestore()

        const vm = this;

        vm.global.updatingNodes = true;
        var docRef =
            db.collection('apps')
                .doc(collectionId)
                .collection('nodes')
                .doc(node.id)

        docRef.update(tempNode).then(() => {
            vm.global.updatingNodes = false;

        })
            .catch((error) => {
                console.error("Error savin html", error);
            });
    }

    copyMasterApp(appId, destination, name) {
        var self = this;

        const db = firebase.firestore()

        console.log("Copy APP to Local: ", appId, destination, name)

        // GET THE APP
        db.collection('apps')
            .doc(appId)
            .get()
            .then((doc) => {
                var app = <NapkinApp>doc.data();
                console.log("GOT THE APP: ", app)
                // Update Fields
                app.accessControl = "private";
                app.appStats = undefined;
                app.companyId = undefined;
                app.createdAt = Date.now();
                app.createdBy = self.global.authuser.uid;
                app.storeNum = undefined;
                if (destination == 'store') {
                    app.companyId = self.global.myCompany.id;
                    app.storeNum = self.global.myCompany.store;
                }
                else {
                    app.userId = self.global.authuser.uid;
                }

                // Cleanup
                app = removeUndefined(app);

                // Now save App to local
                if (destination == 'user') {
                    const target = db.collection('users')
                        .doc(self.global.authuser.uid)
                        .collection("apps")
                        .doc(name)
                    app.id = target.id;

                    target.set(app)
                        .then((doc) => {
                            // Created LOCAL APP
                            // Now add the Nodes
                            self.copyAppNodes(appId, target.id, destination, name);

                        })
                }

            })

        /*     
                 db.collection('apps')
                   .doc(collectionId)
                   .collection('nodes')
                   .onSnapshot((querySnapshot) => {
                     if (vm.global.updatingNodes) return;
                     vm.global.appNodes = [];
                     querySnapshot.forEach((doc) => {
                       if (doc) {
                         const data = <AppNode>doc.data()
                         // TEMP FIXER
                         if (!data.objectType && data.type) data.objectType=data.type;
             
                         var html: SafeHtml;
                         html = vm.sanitizer.bypassSecurityTrustHtml(data.html);
                         data.safeHtml = html;
             
                         //FIXER
                         if (data.objectType == 'dataview' ) {
                           if (!data.reportStyles) data.reportStyles=["none"];
                         }
                         if (data.objectType == 'mediagroup' ) {
                           vm.mediaService.getMediaGroups()
                           vm.mediaService.getMedia()
                         }
             
                         if (data.objectType == 'famousquote' && !vm.oneQuote) {
                           vm.GetInspiration();
                         }
                         if (data.objectType == 'product') {
                           //     vm.productService.GetAllProducts();
                         }
                         const index = vm.global.appNodes.findIndex(function (post) {
                           if (post.id == data.id)
                             return true;
                         });
                         if (index != -1) {
                           if (vm.global.appNodes[index] != data) {
                             console.log("Copy only ")
                             vm.global.appNodes[index] = { ...data };
                           }
                         }
                         else vm.global.appNodes.push(data)
                       }
                     })
                    // vm.global.appId = collectionId;
                     console.log("Got App, now sort: ", vm.global.appNodes)
             
                     vm.global.appNodes.sort(function (a, b) {
                       if (a.myPosition == null) a.myPosition = 100;
                       if (b.myPosition == null) b.myPosition = 100;
                       return (a.myPosition - b.myPosition)
                     });
        

    })




    */




    }

    copyAppNodes(appId, destId, destination, name) {
        var self = this;

        const db = firebase.firestore()

        if (destination == 'user') {
            var target = db.collection('users')
                .doc(self.global.authuser.uid)
                .collection("apps")
                .doc(destId)
                .collection("nodes")
        }
        else {
            var target = db.collection('company')
                .doc(self.global.myCompany.id)
                .collection("apps")
                .doc(destId)
                .collection("nodes")
        }

        db.collection('apps')
            .doc(appId)
            .collection('nodes')
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = <AppNode>doc.data();
                    //COPY DOC TO DESTINATION
                    const newNode = target.doc(data.id)
                    //    data.id = newNode.id;
                    // Check for any nodes that reference other Master data.
                    if (data.objectType == 'form') {
                        // Most likely its a [master] form but check anyway
                        if (data.formNames[0].startsWith('master')) {
                            // Now just get a Doc ID 
                            if (destination == 'user') {
                                var formDoc = db.collection("users").doc(self.global.authuser.uid).collection("forms")
                                    .doc(data.formId[0]);
                            }
                            else {
                                var formDoc = db.collection("company").doc(self.global.myCompany.id).collection("forms")
                                    .doc(data.formId[0]);
                            }
                            // Now we know where Form is Going so we can fix node
                            //  data.formId[0] = formDoc.id
                            // Now both files can save asynchronously, maybe
                            self.createFromMaster(data.formNames[0], formDoc)
                        }

                    }
                    if (data.objectType == 'dataview') {
                        // Most likely its a [master] form but check anyway
                        if (data.reportNames[0].startsWith('master')) {
                            // Now just get a Doc ID 
                            if (destination == 'user') {
                                var reportDoc = db.collection("users").doc(self.global.authuser.uid).collection("reports")
                                    .doc(data.reportIds[0]);
                            }
                            else {
                                var reportDoc = db.collection("company").doc(self.global.myCompany.id).collection("reports")
                                    .doc(data.reportIds[0]);
                            }
                            // Now we know where Form is Going so we can fix node
                            //   data.reportIds[0] = reportDoc.id
                            // Now both files can save asynchronously, maybe
                            self.createReportFromMaster(data.reportNames[0], reportDoc)
                        }

                    }



                    newNode.set(data)
                        .then((doc) => {
                            console.log("Copied 1 Node ", data)
                        })


                })
            })
    }


    createFromMaster(formName, formDoc: firebase.firestore.DocumentReference) {
        var self = this;
        var db = firebase.firestore();

        db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("forms")
            .where("name", "==", formName)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var data = doc.data();
                    data.userId = self.global.authuser.uid;

                    formDoc.set(data)
                        .then(() => {
                            console.log("FORM ADDED: ");
                        })
                })

            })
    }


    appsListener: boolean = false;

    storeAppsListener() {
        var self = this;
        if (self.appsListener) return;
        self.appsListener = true;
        console.log ("Start Apps Listener ")

        const db = firebase.firestore()

        db.collection("apps").where('companyId', '==', self.global.myCompany.id)
          .onSnapshot((querySnapshot) => {
            self.global.publicApps = [];
    
            querySnapshot.forEach((doc) => {
              const data = <NapkinApp>doc.data();
              self.global.publicApps.push(data);
             // self.openApp.push(false);
            })
            console.log("Got some apps", self.global.publicApps)
          })
    
      }
    /// GET ONLY MY APPS
    
      getMyStoreApps() {
        const db = firebase.firestore()
    
        // Create flat array of storeNumbers
        var allStores = [];
        this.global.authuser.creds.forEach(function (store) {
          allStores.push(store.storeNum);
    
        })

        // Not sure why I want all my apps - just 1 will do
      //  allStores = [this.global.myCompany.store]
       // console.log("My Store: ", allStores)
    
        if (!this.global.publicApps) this.global.publicApps = [];
    
        const vm = this;
        var docRef =
          db.collection('apps').where('companyId', '==', vm.global.myCompany.id)
            .get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const data = <NapkinApp>doc.data();
                data.id = doc.id;
                vm.global.publicApps.push(data);
              //  vm.openApp.push(false);
              })
              console.log("App List ", vm.global?.publicApps)
            })
    
        vm.global.allApps = [];
        /* GET ALL PUBLIC APPS */
        var docRef2 =
          db.collection('apps').where('accessControl', '==', 'Public')
            .get().then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                const data = <NapkinApp>doc.data();
                data.id = doc.id;
                //  console.log(data)
                vm.global.allApps.push(data);
              })
              console.log("All Apps ", vm.global?.allApps)
            })
    
      }
    


    createReportFromMaster(reportName, reportDoc: firebase.firestore.DocumentReference) {
        var self = this;
        var db = firebase.firestore();

        db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("reports")
            .where("title", "==", reportName)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var data = doc.data();
                    data.userId = self.global.authuser.uid;

                    reportDoc.set(data)
                        .then(() => {
                            console.log("Report ADDED: ");
                        })
                })

            })
    }

// MOVE TO NODE SERVICES PLEASE
createNewApp(name?: string, callback?, mySelf?) {
    // Create new app
    const db = firebase.firestore()
    const vm = this;

    var appDate = new Date(Date.now());
    var appName = 'New App: '+appDate.toLocaleDateString();

    if (name) appName = name;
    var newApp: NapkinApp = {
      name: appName,
      title: "app",
      appId: "app-",
      accessControl: 'Private',
      companyId: vm.global.myCompany.id,
      storeNum: vm.global.myCompany.store,
      createdAt: Date.now(),
      createdBy: vm.global.authuser.uid,
      appStats: {totalOpens:0, lastOpen: Date.now(), averageSessionTime: 0}
    };

    var appNumber = Math.floor(Math.random() * 10000) + 10
    newApp.appId += appNumber.toString();

    var baseNode: AppNode = {
      id: "Home",
      active: true,
      title: "Start",
      type: "Home",
      objectType: "Home",
      parentId: 'Home',
      createdAt: Date.now(),
      createdBy: vm.global.authuser.uid,

    }
    var newNode: AppNode = {
      parent: 'Home',
      createdAt: Date.now(),
      createdBy: vm.global.authuser.uid,
    }

    var appDocRef = db.collection('apps').doc(newApp.appId)
    appDocRef.set(newApp)
      .then((doc) => {
        console.log("App created ", newApp)
        if (typeof(callback)=='function') 
            callback (mySelf, newApp.appId)
        vm.global.publicApps.push(newApp)
      //  Logger("App Created", vm.global.authuser.uid, vm.global.myCompany.store, newApp.appId)


        // Now we can add a Base Node

        var docRef =
          db.collection('apps')
            .doc(newApp.appId)
            .collection('nodes')
            .doc(baseNode.id).set(baseNode);

        vm.insertNew(newNode, newApp.appId);

      })


  }
/*
  insertNew(node: AppNode, appId: string) {
    var vm = this;

    var baseNode: AppNode = {
      active: true,
      title: "New Item",
      parent: node.parent,
      objectType: "New Node",
      createdAt: Date.now(),
      createdBy: vm.global.authuser.uid,
      height: 100 + Math.floor(Math.random() * 400),
      width: 400 + Math.floor(Math.random() * 400)
    }

    vm.addNode(baseNode, vm.global.myCompany.id, appId);


  }

  addNode(node: AppNode, docId, collectionId) {
    const db = firebase.firestore()

    console.log("add: ", node, docId, collectionId)
    const vm = this;
    var docRef =
      db.collection('apps')
        .doc(collectionId)
        .collection('nodes').doc()

    node.id = docRef.id;
    docRef.set(node);
  }
  */






}
function removeUndefined(o) {
    let stack = [o], i;
    while (stack.length) {
        Object.entries(i = stack.pop()).forEach(([k, v]) => {
            if (v === undefined) delete i[k];
            if (v instanceof Object) stack.push(v);
        })
    }
    return o;
}