/*
* Daily Quotes: One quote per day (earn hourly quotes by getting 1 business to join)
* Show only last 10 quotes - but store all quotes to avoid repeat 
* Option to get weekly quotes.
* 
*
*
*
 */




import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, Navigate, Gallery, Reviews, Review, GooglePhotos } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
//import { ImagekitioAngularModule } from 'imagekitio-angular';
import { HttpClient } from '@angular/common/http';
import { User, Contact, PexelVideo, PexelVideoFile, PexelUser, PexelPhoto, Media, DailyQuote } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import lgZoom from 'lightgallery/plugins/zoom';
import { LightGallery } from 'lightgallery/lightgallery';
import lgVideo from 'lightgallery/plugins/video';
import { Product } from '../product-int'
import { Quote } from '../content-interface';
import { LayoutAlignStyleBuilder } from '@angular/flex-layout';


@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css']
})
export class QuotesComponent implements OnInit {

  @Input() userId: string = "";

  share: boolean = false;
  email: string = "";
  shareQuote: DailyQuote;

  quotes: DailyQuote[];

  constructor(private route: ActivatedRoute, public global: Globals,
    public nav: NavService, private sanitizer: DomSanitizer,
    private http: HttpClient) {


  }


  ngOnInit(): void {

    this.quotes = [];



    var today = Date.now();

    // var todayMs = today.getMilliseconds(); 

    console.log("Init Quotes ", today);

    if (this.global.authuser.dailyQuotes)
      this.global.authuser.dailyQuotes.sort(function (a, b) { return b.date - a.date; });


    if (!this.checkQuote(today)) {
      this.GetInspiration(today, this.getUnique());
    }
    if (this.global.authuser.dailyQuotes)
      this.global.authuser.dailyQuotes.sort(function (a, b) { return b.date - a.date; });


  }

  checkQuote(now: number): boolean {
    var self = this;

    if (this.global.authuser.dailyQuotes) {
      var lastQuote = this.global.authuser.dailyQuotes[0];

      var daysSince = daysBetween(lastQuote.date);
      daysSince = Math.floor(daysSince);
      console.log("lastQuote ", daysSince, lastQuote);
      var today = new Date(new Date(Date.now()).toDateString())
      var lastDay = new Date(new Date(Date.now()).toDateString())


      if (daysSince > 0) {
        if (daysSince >= self.maxLookBack) {
          daysSince = self.maxLookBack;

        }

        var result = lastDay.setDate(lastDay.getDate() - daysSince);
        lastQuote.date = new Date(new Date(lastDay).toDateString()).getTime();
        console.log("lookback ", daysSince, lastDay)

        var start = lastDay;

        while (daysSince) {
          var result2 = start.setDate(start.getDate() + 1);
          var nextDay = new Date(new Date(start).toDateString()).getTime();

          daysSince--;

          console.log("INS: ", start, start.getTime())
          self.GetInspiration(start.getTime(), self.getUnique())

        }
      }
      else return true;


      return true;
    }

    return false;
  }

  shareMe(quote) {

    this.shareQuote = quote;
    // Allow Quote to be shared
    // enter email and if not found add name 
    this.share = true;
  }

  shareThisQuote() {
    var self = this;

    if (!ValidateEmail(this.email)) return;


    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    var foundUser = false;
    if (!this.email || this.email.length < 5) return;

    // self.inputCreds = false;
    db.collection("users").where("email", "==", self.email)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          var tUser = <User>querySnapshot.docs[0].data();
          foundUser = true;
          // Found  user so lets just add Quote to them with today's date.
          var today = Date.now();
          self.shareQuote.prettyDate= this.getPrettyDate(today);
          self.shareQuote.date = today;
          self.shareQuote.sharedBy = self.global.authuser.firstName+" " +self.global.authuser.lastName;
          if (!tUser.dailyQuotes) tUser.dailyQuotes = [];
          tUser.dailyQuotes.push(self.shareQuote);
          tUser.dailyQuotes.sort(function (a, b) { return b.date - a.date; });
          console.log("Share Quote ");
          var userRef = querySnapshot.docs[0].ref
            .update({ dailyQuotes: tUser.dailyQuotes });

        }

      })

  }



  GetQuote(dailyQuote: DailyQuote) {
    var self = this;
    var db = firebase.firestore();

    db.collection("quotes").doc(dailyQuote.quoteId).get()
      .then((doc) => {

        var data = <Quote>doc.data();
        var q: DailyQuote = {
          ...dailyQuote, author: data.author, text: data.text,
        }
        if (!q.author || (q.author && q.author.length==0))
          q.author = "unknown" 
        self.quotes.push(q);
        console.log(q);
      })

  }

  getUnique(): number {
    var self = this;
    var db = firebase.firestore();

    var unique: boolean = false;
    var r = <number>Math.floor(Math.random() * 1644);

    while (!unique) {
      if (!self.global.authuser.dailyQuotes) unique = true;
      else {
        var f = self.global.authuser.dailyQuotes.find((item) => item.index === r)
        if (!f) unique = true;
        else {
          console.log("DUPLICATE - GET ANOTHER QUOTE")
          r = <number>Math.floor(Math.random() * 1644);
        }
      }
    }


    return r;
  }

  maxLookBack: number = 50;
  newQuotes: number = 0;

  GetInspiration(date: number, r: number) {
    var self = this;
    var db = firebase.firestore();

    this.newQuotes++;
    if (this.newQuotes > this.maxLookBack) return;

    db.collection("quotes").where("index", "==", r)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Quote>doc.data();

          // Now update User
          if (!self.global.authuser.dailyQuotes) self.global.authuser.dailyQuotes = [];

          var q: DailyQuote = {
            author: data.author,
            prettyDate: this.getPrettyDate(date),
            text: data.text, date: date,
            index: r, quoteId: doc.id
          }
          if (!q.author || (q.author && q.author.length==0))
          q.author = "unknown" 
          self.quotes.push(q);
          console.log("New Quote: ", q);
          self.global.authuser.dailyQuotes.push(q)
          this.global.authuser.dailyQuotes.sort(function (a, b) { return b.date - a.date; });

          var userRef = db.collection("users").doc(self.global.authuser.uid)
            .update({ dailyQuotes: self.global.authuser.dailyQuotes });


        })
      })


  }

  getPrettyDate(dateMs: number) {


    var date = new Date(dateMs);
    // console.log(date, dateMs)

    return (dateFormatter(date));

  }



}
function daysBetween(date) {
  //var d = new Date(date);
  //var today = new Date(Date.now())
  var today=new Date(new Date(Date.now()).toDateString())
  var d = new Date(new Date(date).toDateString())

  // JavaScript program to illustrate 
  // calculation of no. of days between two date 

  // To set two dates to two variables
  var date1 = d;
  var date2 = today;

  // To calculate the time difference of two dates
  var Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  //To display the final no. of days (result)
  console.log("Total number of days between dates  <br>"
    + date1 + "<br> and <br>"
    + date2 + " is: <br> "
    + Difference_In_Days);


  return Math.floor(Difference_In_Days);

  /*
  
    const d1 = d.getDate();
    const d2 = today.getDate();
    // console.log ("D:", d1, d2);
  
    if (d1 == d2 && d.getMonth() == today.getMonth() && d.getFullYear() == today.getFullYear())
      return true;
    else
      return false;
      */

}

function dateFormatter(date: Date) {
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  // date.toDateString();

  return date.toLocaleDateString('en-US', timeformat);
}

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}