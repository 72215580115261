<div *ngIf="global.publishedReviews" class="reviews-container" style="max-height:60vh; overflow-y: scroll;">

  <div *ngIf="!newstoreMode" id="allReviews">

    <div *ngIf="global.publishedReviews && global.reviews && global.reviews.totals">

      <div class="review-header">Recent reviews</div>
      <div *ngIf="reviewSettings && reviewSettings.filters && reviewSettings.filters.length>0" class="review-filters">
        Keyword filtered
        <!-- {{getFilterList(reviewSettings.filters)}} -->
      </div>
      <div *ngIf="(!reviewSettings||reviewSettings?.showHeader) && global.reviews && global.reviews.totals"
        class="totals">
        <p>{{global.reviews.totals.avgRating}} star average rating based on {{global.reviews.totals.totalReviews}}
          reviews
          from Google, Yelp and Napkin Reviews.</p>
        <p>{{global.reviews.totals.avgRatingTrailing3}} star average rating for the past 3 months</p>
        <hr style="border:3px solid #f1f1f1">

        <div class="row">
          <div class="side">
            <div>5 star</div>
          </div>
          <div class="middle">
            <div class="bar-container">
              <div class="bar-5" [style.width]="getStarWidth(5)"></div>
            </div>
          </div>
          <div class="side right">
            <div>{{getStarWidth(5)}}</div>
          </div>
          <div class="side">
            <div>4 star</div>
          </div>
          <div class="middle">
            <div class="bar-container">
              <div class="bar-4" [style.width]="getStarWidth(4)"></div>
            </div>
          </div>
          <div class="side right">
            <div>{{getStarWidth(4)}}</div>
          </div>
          <div class="side">
            <div>3 star</div>
          </div>
          <div class="middle">
            <div class="bar-container">
              <div class="bar-3" [style.width]="getStarWidth(3)"></div>
            </div>
          </div>
          <div class="side right">
            <div>{{getStarWidth(3)}}</div>
          </div>
          <div *ngIf="global.reviews.totals.total2Star>0">
            <div class="side">
              <div>2 star</div>
            </div>
            <div class="middle">
              <div class="bar-container">
                <div class="bar-2" [style.width]="getStarWidth(2)"></div>
              </div>
            </div>
            <div class="side right">
              <div>{{getStarWidth(2)}}</div>
            </div>
          </div>
          <div *ngIf="global.reviews.totals.total1Star>0">
            <div class="side">
              <div>1 star</div>
            </div>
            <div class="middle">
              <div class="bar-container">
                <div class="bar-1" [style.width]="getStarWidth(1)"></div>
              </div>
            </div>
            <div class="side right">
              <div>{{getStarWidth(1)}}</div>
            </div>
          </div>
        </div>

      </div>

      <!-- END OF HEADER SECTION -->

      <div [style]="getFullWidth()" fxLayout="row wrap" fxLayoutAlign="center center">
        <div *ngFor="let p of global.publishedReviews; let i = index" [ngStyle]="{ 'width': getMyWidth(p)}"
          (click)="editMe(i)">
          <div *ngIf="reviewService.checkFilter(p, reviewSettings.filters)">
            <div *ngIf="reviewSettings && reviewSettings?.defaultView=='Polaroid'" style="width:100%">

              <app-review-card [p]="p" [tags]="reviewSettings.filters"></app-review-card>
            </div>

            <div *ngIf="reviewSettings && reviewSettings?.defaultView=='Quotes only'">
              <app-quote-style [p]="p" [tags]="reviewSettings.filters"></app-quote-style>
            </div>

            <div *ngIf="!reviewSettings || (reviewSettings && reviewSettings?.defaultView=='Standard')" 
            class="review"
              fxLayoutAlign="center center">
              <app-default-style [p]="p" [tags]="reviewSettings.filters"></app-default-style>

            </div>
            <div *ngIf="editingReview==i && validCreds()" fxLayoutAlign="center center">
              <button *ngIf="p.style=='quote'" class="useful" (click)="defStyle(p)">Default Style</button>
              <button *ngIf="p.style!='quote'" class="useful" (click)="quoteStyle(p)">Quote Style</button>
              <button *ngIf="p.style!='polaroid'" class="useful" (click)="polaroid(p)">Polaroid Style</button>


              <button *ngIf="p.styleWidth=='full'" class="useful" (click)="defStyleWidth(p)">Default
                width</button>
              <button *ngIf="!p.styleWidth" class="useful" (click)="styleWidth(p)">Full Width</button>

            </div>
          </div>



        </div>
      </div>
    </div>

  </div>