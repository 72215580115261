import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { Company, Navigate, Reviews, Review, Coupon, YelpOverview, GoogleReviewsID, GoogleBusinessInfo } from '../../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { ReviewsService } from "../../reviews.service"
import { SafeUrl } from "@angular/platform-browser";
import { User } from "../../user-interface";
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'


@Component({
  selector: 'app-manage-reviews',
  templateUrl: './manage-reviews.component.html',
  styleUrls: ['./manage-reviews.component.css']
})
export class ManageReviewsComponent implements OnInit {
  allReviews: Review[];

  public myAngularxQrCode: string = null;
  public qrCodeDownloadLink: SafeUrl = "";


  constructor(public global: Globals, public reviewsService: ReviewsService) {
    console.log("Manage reviews constructor");
    //this.allReviews = [];
    // this.pendingReviews = [];


  }

  ngOnInit(): void {
    var self = this;
    //   this.GetReviews();

    //this.FixReviews();  // one time fix
    //this.moveReviews();
/*

REMOVED UNTIL I CAN REVIEW 
    if (this.global.coupons) {
      // Add coupon to new reviews
      this.global.pendingReviews.forEach(function (item) {
        if (!item.couponCode) {
          item.couponCode = item.authorId.substring(0, 5);
          item.couponCode += self.global.coupons[0].sid;
          item.couponURL = "napkinhq.com/?e=" + item.couponCode;
          item.couponName = self.global.coupons[0].name;
          item.couponDesc = self.global.coupons[0].desc;
          item.qrcodeURL = "http://api.qrserver.com/v1/create-qr-code/?data=" + item.couponURL + "&size=200x200";

        }
      })
    }

    */

  }

  onChangeURL(url: any, p: Review) {
    //    p.qrcodeURL ="http://api.qrserver.com/v1/create-qr-code/?data=HelloWorld!&size=200x200";
    this.qrCodeDownloadLink = url;
    console.log("URL: ", url);
  }

  moveReviews() {   // One-time fixer to move reviews to a collection
    var self = this;
    var db = firebase.firestore();

    return; // done

    console.log("All reviews ", this.global.reviews.reviews)

    //Move Array to sub-collection

    this.global.reviews.reviews.forEach((review) => {
      // Create sub-collection and copy data here:
      var mediaRef = db.collection("company").doc(self.global.myCompany.id).collection("reviews").doc();
      mediaRef.set(review)
        .then(() => {
          console.log("Review successfully updated!", review);

        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating review: ", error);
        });

    })

}


removeReview(review: Review, index) {
  console.log("Removing: ", review);
  review.status = "removed";
  this.reviewsService.updateReview(review);
}
publishReview(review: Review, index) {
  console.log("Publish: ", review);
  review.status = "published";
  this.reviewsService.updateReview(review);
  this.emailThanks(review);

  // ADD COUPON TO USER
  if (review.couponURL && review.authorId) {
    this.addPerkToUser(review)

  }
}
addPerkToUser(review: Review) {

  var self = this;

  var newPerk: Coupon = {
    // id: string;
    //  sid: string;
    name: review.couponName,
    couponCode: review.couponCode,
    storeNum: this.global.myCompany.store,
    companyId: this.global.myCompany.id,
    storeName: this.global.myCompany.name,
    created: "today",
    validThru: "does not expire",
    desc: review.couponDesc,
    //qrcodeURL: string;
  }

  // Look Up all users with creds - should be done on server
  var self = this;
  var db = firebase.firestore();

  var ref = db.collection("users").doc(review.authorId);

  ref.update({
    perks: firebase.firestore.FieldValue.arrayUnion(newPerk) // ...Spread operator
  })
    .then(() => {
      console.log("added perk.");

    })
    .catch((error) => {
      console.error("Error saving perk", error);
    });


}

addBadge(review: Review, badge: string) {

  var self = this;

  var db = firebase.firestore();

  var ref = db.collection("users").doc(review.authorId);

  ref.update({
    badges: firebase.firestore.FieldValue.arrayUnion(badge) // ...Spread operator
  })
    .then(() => {
      console.log("added badge.");

    })
    .catch((error) => {
      console.error("Error saving perk", error);
    });


}


emailThanks(review: Review) {
  
    if (!review.authorId) return;

    var self = this;
    var db = firebase.firestore();
    db.collection("users").where("uid", "==", review.authorId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          console.log("User: ", data)

          var time = new Date().getTime();
          time = +review.date;
          var myDate;
          myDate = new Date(time).toLocaleDateString();
          var templateParams = {
            from_name: "Napkin Reviews",  // Company Name
            to_name: data.firstName,
            to_email: data.email,
            msgDate: myDate,
            fullName: review.author_name,

            storeName: self.global.myCompany.name,
            QRcode: review.qrcodeURL,
            google: "",
            yelp: "",
            googleText: "",
            yelpText: "",
            reviewText:"",
            badgeText: "",
            badge:"",
            quoteText: "",
            quoteAuthor: "",

            storeAddress: self.global.myCompany.address1 + " " + self.global.myCompany.city + " " + self.global.myCompany.state
          };
          templateParams.reviewText = review.review_text;
// Hack add check for reviews - counter in user-rec??
          templateParams.badgeText = "You've earned a Napkin Badge:"
          templateParams.badge = "Author"
          self.addBadge(review, templateParams.badge);

          templateParams.quoteAuthor = "Scott"
          templateParams.quoteText = "Time is only your enemy if you waste it."

          if (self.global.myCompany?.googleBusinessInfo?.reviewLink){
            templateParams.googleText = "If you'd like to share your feedback on Google reviews we've provided a direct link below."
            templateParams.google += self.global.myCompany.googleBusinessInfo.reviewLink;
          }

          if (self.global.myCompany?.yelpOverview?.addReview){
            templateParams.yelpText = "If you'd like to share your feedback on Yelp:"

            templateParams.yelp = self.global.myCompany.yelpOverview.addReview;
          }


          /*
            couponName: review.couponName,
            couponDesc: review.couponDesc,
            perkName: "Insider Perk",
            */

          emailjs.send("service_jwders9", "template_7xwq2tt", templateParams)
            .then((result: EmailJSResponseStatus) => {
              console.log(result.text);
            }, (error) => {
              console.log(error.text);
            });

        })
      })
 
}



emailCoupon(review: Review) {
  if (review.couponURL) {
    var self = this;
    var db = firebase.firestore();
    db.collection("users").where("uid", "==", review.authorId)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          console.log("User: ", data)

          var time = new Date().getTime();
          time = +review.date;
          var myDate;
          myDate = new Date(time).toLocaleDateString();
          var templateParams = {
            from_name: "Napkin Reviews",  // Company Name
            to_name: data.firstName,
            to_email: data.email,
            msgDate: myDate,
            fullName: review.author_name,
            couponName: review.couponName,
            couponDesc: review.couponDesc,
            perkName: "Insider Perk",

            storeName: self.global.myCompany.name,
            QRcode: review.qrcodeURL,
            storeAddress: self.global.myCompany.address1 + " " + self.global.myCompany.city + " " + self.global.myCompany.state
          };

          emailjs.send("service_jwders9", "template_n013ndt", templateParams)
            .then((result: EmailJSResponseStatus) => {
              console.log(result.text);
            }, (error) => {
              console.log(error.text);
            });

        })
      })
  }
}

/*
  FixReviews() {
    return;
    var self = this;
    console.log("Fixing Reviews ", self.global.myCompany.id);
    if (self.global.myCompany.store != "4390") return;
 
    var db = firebase.firestore();
    var ref = db.collection("reviews").where("companyID", "==", self.global.myCompany.id);
 
    var rold = <Reviews>{ reviews: [] };
    ref.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
 
          var rold = <Reviews>doc.data().allReviews;
          var napkinReviews: Review[] = [];
          rold.reviews.forEach(function (item) {
            if (item.source == "Napkin")
              napkinReviews.push(item);
 
          })
 
          console.log("Napkin reviews ", napkinReviews);
          // Now save new reviews
          var ref2 = db.collection("reviews").doc(doc.id);
 
          ref2.update({
            reviews: firebase.firestore.FieldValue.arrayUnion(...napkinReviews) // Spread operator
 
          })
            .then(() => {
              console.log("reviews fixed.", rold);
 
            })
            .catch((error) => {
              console.error("Error savin reviews", error);
            });
 
        })
      })
  }
 
 
*/



/*
 
  UpdateReviews(r: Review) {
    var self = this;
    console.log("Updating Review ", r, self.global.myCompany.id);
 
    var db = firebase.firestore();
    var ref = db.collection("reviews").where("companyID", "==", self.global.myCompany.id);
 
    var rold: Review [] = [];
 
    ref.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
 
          var rold = <Review[]>doc.data().reviews;
 
          console.log("Merging ", rold, r, querySnapshot.size);
 
          // Find the review by id??
          var f = rold.findIndex(function (post, index) {
            if (post.review_text == r.review_text && (post.author == r.author || post.author_name == r.author_name))
              return true;
          });
          if (f != -1) {
            rold[f] = r; // PUT THE UPDATED REVIEW HERE
 
            // Now save new reviews
            var ref2 = db.collection("reviews").doc(doc.id);
 
            ref2.update({
              reviews:  rold
 
            })
              .then(() => {
                console.log("reviews updated.", rold);
 
              })
              .catch((error) => {
                console.error("Error savin reviews", error);
              });
          }
        })
      })
  }
  */
/*
  async GetReviews() {
    var self = this;
 
    return;
 
    if (!self.global.myCompany) return;
    console.log("getin reviews ", self.global.myCompany)
 
    var db = firebase.firestore();
 
    const q = query(collection(db, "reviews"), where("companyID", "==", self.global.myCompany.id));
    self.pendingReviews = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      const r = <Review[]>doc.data().reviews;
      console.log("Review:: ", r);
 
      // Fix Date strings
      r.forEach(function (review) {
 
        if (review.rating) {
          review.review_rating = review.rating;
        }
        if (review.status == 'pending') {
          self.pendingReviews.push(review);
        }
        else self.allReviews.push(review);
        //else if published push here
      })
 
      console.log("Pending Reviews", self.pendingReviews);
    });
 
    // console.log("Phrase Counts: ", counts);
 
  }
  */

gatherReviews() {

  //this.FetchYelpID();
  // Assuming we got data let's ask for verification before fetching all reviews

 // this.FetchGoogleInfo();
}




  /*
    imageSearch(cursor: string) {
      var self = this;
  
      const options = {
        method: 'GET',
        headers: {
          'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
          'X-RapidAPI-Host': 'joj-image-search.p.rapidapi.com'
        }
      };
  
      var str = 'https://joj-image-search.p.rapidapi.com/v2/?q=crystalhillvineyard.com&hl=en';
  
      if (cursor.length) str += '&cursor=' + cursor;
      console.log("Image search ", str);
      fetch(str, options)
        .then(response => response.json())
        .then(response => {
          console.log(response)
          self.imagesFound = <ImageData[]>response.response.images;
          self.imagesFound.forEach(function (image) {
  
            var newImage: Media = {
              //      createdAt?: string;
              //      customCoordinates?: string;
              //      fileId?: string;
              //      name?: string;
              url: image.image.url,
              thumbnailUrl: image.image.url,
              height: image.image.height,
              width: image.image.width,
              //         size?: number
              //         filePath?: string;
              //         tags?: string[];
              //        format?: string;
              fileType: "image",
  
            }
            self.mediaList.push(newImage);
          })
  
          // Call myself if there is a next_Cursor
          console.log("Next: ", response.response.next_cursor);
          if (response.response.next_cursor)
            self.imageSearch(response.response.next_cursor);
  
        })
        .catch(err => console.error(err));
  
    }
  */


}

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}
