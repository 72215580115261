<div class="profile">
    <div *ngIf="global.authuser" fxFlex fxLayout="column" fxLayoutAlign="center center">

        {{global.authuser.firstName+" "+global.authuser.lastName}}

        <div *ngIf="global.authuser.profilePic">
            <img [src]="profile" height="50px" width="50px" style="border-radius:50px"> 
        </div>
        <div *ngIf="!global.authuser?.profilePic">
            <img [src]="profile" height="50px" width="50px" style="border-radius:50px"> 
        </div>

    </div>
</div>