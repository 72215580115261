import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Report, FieldInfo, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Product } from '../product-int'
import { Order } from '../order-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { Table } from 'primeng/table';
import { getNiceDate, dateFormatter } from 'src/app/functions';
import { Timestamp } from 'firebase/firestore';
import { set, startOfDay, endOfDay, getMilliseconds } from "date-fns";
import { FormService } from '../form.service';

interface Column {
  title: string;
  header: string;
  width?: number;
  //  type: string;
}

interface HeaderSettings {
  freeze?: boolean;
  searchTags?: string[];
}


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss', '../../common.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsComponent implements OnInit {
  @Input() reportName: string;
  @Input() reportIndex: number;
  @Input() style: string;
  @Input() editReport: boolean = true;
  @Input() userForm: boolean = false;
  @Input() overFlow;
  @Input() report;

  @Input() reportId: string;

  //@ViewChild('dt', { static: false }) table: Table

  // report: Report;
  editForm: Form;
  expandedRows = {};

  headerOpen: boolean = false; // make a setting for report

  cols!: Column[];
  //allCols!: Column[];
  rows: { fields: Field[] }[];
  allRows: { fields: Field[] }[];
  //reportData: any;

  headerSettings: HeaderSettings;

  reportSettings;

  editingRow: boolean = false;

  internalReport: boolean = false;

  constructor(public global: Globals, public registerService: RegisterService,
    private ref: ChangeDetectorRef, private formService: FormService,
    public nav: NavService, private scroller: ViewportScroller) {

  }

  ngOnInit(): void {
    var self = this;
    console.log("Report: ", this.report, this.reportId, this.reportName, this.reportIndex)

    if (this.reportId) {
      this.getReport(this.reportId)
    }
    else if (self?.reportName) {
      self.global.allReports.forEach(function (report, index) {
        if (report.title == self.reportName) {
          self.reportIndex = index;
          console.log("Got report by name ", self.reportIndex)
        }
      })


    }

    if (this.reportIndex >= 0) {
      this.report = this.global.allReports[this.reportIndex];
      console.log("REPORT from index: ", this.report)
      // create local settings - !add for reportid too please
      this.reportSettings = {
        title: this.report.title,
        // formNames: this.report.
      }

      if (this.report.formIds && this.report.formIds[0]) {
        this.getData(this.report.formIds[0]);
      }
      else if (this.report.dataSource) {
        this.getDataSource(this.report.dataSource)
      }
    }
    // NEW NEW
    if (this.report?.fieldSource?.formId) {
      console.log("getData: ", this.report)

      this.getData(this.report.fieldSource.formId);
    }

  }
  clear(table: Table) {
    table.clear();
  }
  isOverFlow() {
    if (typeof (this.overFlow) != 'boolean') return false;
  }

  getFullWidth() {
    if (this.report.maxWidth == 'Screen width')
      return this.global.getScreenWidth.toString();
    else return ""
  }
  getBackColor() {
    if (!this?.report?.backgroundColor) return "background-color:antiquewhite"
    else {
      return "overflow-x:scroll; background-color:" + this.report.backgroundColor;
    }

  }

  headerClicked(event) {
    this.headerOpen = !this.headerOpen;
  }

  getMaxWidth() {
    var str = "width:100%";
    if (this.report?.maxWidth == 'Max content') {
      str = "width:max-content"
    }
    return str;

  }

  getCellWidth(index) {
    if (this.report?.maxWidth == 'Max content') {
      return "";
    }
    if (this.cols[index]?.width) {
      const x = this.global.getScreenWidth * (this.cols[index].width / 100)
      return x.toString();
    }
    else {
      const x = this.global.getScreenWidth / this.cols.length
      return x.toString()
    }

  }

  exportData() {
    this.formService.exportData(this.cols, this.rows)
  }

  outputEvent(form: Form) {
    var self = this;

    console.log("Report settings got an update ", form, this.report);
    // HEY DUDE - REFACTOR TO USE NAPKINFIELDSTOOBJECT ...


    var getNewData = false;
    var updateFields = false;

    form.fields.forEach(function (field) {
      if (field.title == 'title') {
        self.report.title = field.binding;
      }
      if (field.title == 'allowEdits') {
        self.report.allowEdits = field.trueFalse;
      }
      if (field.title == 'gridLines') {
        self.report.gridLines = field.trueFalse;
      }
      if (field.title == 'backgroundColor') {
        self.report.backgroundColor = field.binding;
      }
      if (field.title == 'maxWidth') {
        self.report.maxWidth = field.selected[0];
      }
      if (field.title == 'filterBy' && self.report.filterBy != field.selected[0]) {
        self.report.filterBy = field.selected[0];
        getNewData = true;
      }
      if (field.title == 'date1' && self.report.date1 != field.value) {
        self.report.date1 = field.value;
        getNewData = true;
      }
      if (field.title == 'date2' && self.report.date2 != field.value) {
        self.report.date2 = field.value;
        getNewData = true;
      }
      if (field.title == 'sortBy') {
        self.report.sortBy = field.selected[0];
      }
      if (field.title == 'userOnly') {
        self.report.userOnly = field.trueFalse;
      }

      // If a form and some fields have been selected ...
      if (field.title == 'fieldSource') {
        self.report.fieldSource = field.fieldSource;
        getNewData = true;
      }


      if (field.title == 'fieldsIncluded') {
        self.report.formIds = [];
        self.report.formNames = [];
        self.report.includedFields = [];

        const index = self.global.allForms.findIndex(function (post) {
          if (post.name == field.sourceForm)
            return true;
        });
        console.log("found form: ", index, field)

        if (index != -1) {
          self.report.formIds.push(self.global.allForms[index].id);
          self.report.formNames.push(field.sourceForm);
        }
        if (JSON.stringify(self.report.includedFields.sort()) === JSON.stringify(field.selected.sort())) {
        }
        else {
          self.report.includedFields = field.selected;
          updateFields = true;
          getNewData = true;
        }

      }
      /*
      if (field.title == 'includedFields') {
        if (JSON.stringify(self.report.includedFields.sort()) === JSON.stringify(field.selected.sort())) {
        }
        else {
          self.report.includedFields = field.selected;
          updateFields = true;
          getNewData = true;
        }



        //   field.selected.forEach(function (field) {
        // Hack add check for access control
        // SKIP some fields - REMOVE BEFORE HERE
        //       if (field.type == 'pw' || field.type == 'button') []
        //       else
        // Fix to use full field info...
        //  self.report.includedFields.push(field)
        //   })

      }
        */
    })
    console.log("AFTER Report settings got an update ", form, this.report);


    self.updateReport(self.report);
    if (updateFields)
      self.getColumns(self.report);

    if (getNewData)
      self.getData(this.report.formIds[0]); // internal data?
    self.sortBy();

    self.ref.markForCheck();


    /*    
        form.fields.forEach(function(field){
          if (field.title == 'Report name' && field?.binding?.length && 
          field.binding != self.report.title) {
            self.report.title = field.binding;
            self.updateReport(self.report)
          }
          if (field.title == 'Form select' && 
          field.selected != self.report.formNames) {
         //   this.report = self.global.allReports[self.reportIndex];
        
            if (!self.report.formIds) self.report.formIds = [];
            if (!self.report.formNames) self.report.formNames = [];
            if (!self.report.includedFields) self.report.includedFields = [];
        
            const index = self.global.allForms.findIndex(function (post) {
              if (post.name == field.selected[0])
                return true;
            });
            console.log ("found form: ", index,field.selected[0])
            self.report.formIds.push(self.global.allForms[index].id);
            self.report.formNames.push(field.selected[0]);
        
            // Add all fields by defualt
    
            self.global.allForms[index].fields.forEach(function (field) {
              // Hack add check for access control
              // SKIP some fields
              if (field.type == 'pw' || field.type == 'button') []
              else
                self.report.includedFields.push({ field: field.title, header: field.title })
            })
        
        
            self.updateReport(self.report);
            self.getColumns(self.report);
    
    
          }
        })
        */


  }

  getPackageTitle(row: Field[]) {
    var title = "";
    row.forEach(function (field) {
      if (field.title == 'Text')
        title = field.binding;
    })
    return title;

  }
  getMonthlyPrice(row: Field[]) {
    var title = "";
    row.forEach(function (field) {
      if (field.label.includes('onthly'))
        title = field.value;
    })
    this.getPackageItems(row)
    return title;
  }

  packageItems: string[];
  packageButton: Field;

  output(event) {
    console.log('output ', event)
  }

  outputEvent2(event) {
    console.log('output Event', event)
  }
  checkGrid() {
    var self = this;

    var style = "background-color:antiquewhite;"

    if (this.report.backgroundColor)
      style = "background-color:" + this.report.backgroundColor + ";"

    if (!self.report.gridLines) return style;
    else style += "border-bottom: 1px solid gray;"

    return style;
  }

  getPackageItems(row: Field[]) {
    var self = this;

    var title = []
    row.forEach(function (field) {
      if (field.type == 'multi-select')
        self.packageItems = field.selected;
      if (field.type == 'button')
        self.packageButton = field;
    })
    return title;

  }

  getLineStyle(index) {
    if (index % 2 == 0) return "background: #f8f8f8;"
    else return ""
  }

  getColumns(report: Report) {
    var self = this;

    self.cols = [];

    var column: Column = { title: "date", header: "When" }
    self.cols.push(column);

    if (report.showUser) {
      self.cols.push({ title: "userName", header: "User" });

    }

    // NEW NEW
    if (report?.fieldSource?.includedFields) {
      report.fieldSource.includedFields.forEach(function (item) {
        var column: Column = { title: item, header: item }
        self.cols.push(column);
      })
    }


    if (report.includedFields) {
      report.includedFields.forEach(function (item) {
        var column: Column = { title: item, header: item }
        self.cols.push(column);
      })
    }

    if (0 && report.allowEdits) {
      self.cols.push({ title: "Actions", header: "Actions" })
    }

    console.log("Report columns: ", self.cols, self.cols.length)
  }

  viewField(rowData: Field[], index) {
    //  console.log (rowData[index], rowData, index)
  }

  getAllFields() {
    var self = this;
    var fields = Object.keys(self.rows[0].fields);
    console.log("FIELDS ", fields)

    self.cols = [];
    fields.forEach(function (item) {
      var width = Math.random() * 300 + 50;
      var column: Column = { title: item, header: item, width: width }
      self.cols.push(column);
    })
    if (0 && self.report.allowEdits)
      self.cols.push({ title: "Actions", header: "Actions" })

  }

  getReport(reportId) {
    var self = this;
    var db = firebase.firestore();

    if (self.userForm) {
      var docRef = db.collection("users").doc(this.global.authuser.uid).collection("reports")
        .doc(reportId)
    }
    else {
      var docRef = db.collection("company").doc(this.global.myCompany.id).collection("reports")
        .doc(reportId)
    }
    docRef.get().then((doc) => {
      var data = <Report>doc.data();
      self.report = data;
      if (data.formIds) {
        self.getData(data.formIds[0]);
      }
      else if (data.dataSource) {
        self.getDataSource(data.dataSource)
      }
    })
  }

  getMasterReport(reportId) {
    var self = this;
    var db = firebase.firestore();
    var docRef = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("reports")
      .doc(reportId)

    docRef.get().then((doc) => {
      var data = <Report>doc.data();
      self.report = data;
      if (data.formIds) {
        self.getData(data.formIds[0]);
      }
      else if (data.dataSource) {
        self.getDataSource(data.dataSource)
      }
    })
  }

  createEditForm(row: { fields: Field[] }, index) {
    var self = this;

    console.log("Edit Row ", row)

    self.editForm = {
      autoSave: false,
      showSubmit: true,
      fields: [],
    }

    /*    
        // Find Current Instance of the Form
        const index = self.global.allForms.findIndex(function (post) {
          if (post.id == report.formIds[0])
            return true;
        });
    */
    //   if (index != -1) {
    // Let's add fields for editing 
    self.allRows[index].fields.forEach(function (field) {
      // Translate field type for editing
      if (field.type == 'input-text') {
        var f: Field = {
          type: 'input-text', title: field.title, binding: field.binding
        }
        self.editForm.fields.push(f)
      }
      else if (field.type == 'select-button' || field.type == 'multi-select') {
        var f: Field = {
          type: 'select-button', title: field.title, options: field.options, selected: field.selected
        }
        self.editForm.fields.push(field)
      }
      else if (field.type) {
        self.editForm.fields.push(field)
      }
    })

  }

  getDataSource(dataSource) {
    var self = this;

    var db = firebase.firestore();

    if (dataSource == 'Products') {
      db.collection("company").doc(this.global.myCompany.id).collection("products")
        .onSnapshot((querySnapshot) => {
          self.rows = [];
          self.internalReport = true;
          querySnapshot.forEach((doc) => {
            const data = <Product>doc.data()
            //OOPS NEED TO FIX  self.rows.push(data);
          })
          self.getAllFields();
          console.log("Data; ", self.rows);
          // self.scroller.scrollToAnchor("formtop");
        })
    }
    if (dataSource == 'Orders') {
      db.collection("orders").where("companyID", "==", this.global.myCompany.id)
        .onSnapshot((querySnapshot) => {
          self.rows = [];
          self.internalReport = true;
          querySnapshot.forEach((doc) => {
            const data = <Order>doc.data()
            //OOPS self.reportData.push(data);
          })
          self.getAllFields();
          console.log("Data; ", self.rows);
        })
    }
  }

  //  getSomeData(form: Form, fieldTitle, callBack, mySelf, filter?) {

  gotData(self, data: Form[]) {
    console.log("REPORT GOT SOME DATA: ", data)
    self.rows = [];
    self.allRows = [];
    var totalFields;
    self.getColumns(self.report);
    if (self.cols?.length)
      totalFields = self.cols.length;

    if (self.report.showUser) totalFields++;

    self.headerSettings = { searchTags: [] }
    data.forEach((doc) => {
      var allFields: Field[] = new Array(totalFields);
      var row: Field[] = new Array(totalFields);
      //     row.push ({title: 'id', label:'id', binding: data.id})
      row[0] = ({ title: "date", label: "date", date: Timestamp.fromMillis(doc.timeStamp), binding: dateFormatter(new Date(doc.timeStamp), true) })
      if (self.report.showUser && doc.userName) {
        row[1] = ({ title: "User", label: "User", binding: doc.userName})

      }
      
      if (doc?.fields && doc.fields?.length)
        doc.fields.forEach(function (field, index) {
          if (field.type == 'calendar') {
            //   console.log ("CALENDAR ", field);
            field.binding = field.selected[0];     //getNiceDate(field.date);
          }
          if (field.type == 'date')
            field.binding = getNiceDate(field.date);
          else if (field.type == 'checkbox') {
            if (field.trueFalse == true)
              field.binding = 'Yes';
            else field.binding = 'No';
          }

          // REMOVE FIELDS
          if (field.type == 'pw' || field.type == 'button') { }//Skip
          else {
            allFields[index] = field;
            const fieldIndex = self.cols.findIndex(x => x.title === field.title)
            if (fieldIndex != -1) {
              row[fieldIndex] = field;
            }
            else { // other data not in field
            

            }
          }
        })

      self.rows.push({ fields: row })
      self.allRows.push({ fields: allFields })
    })
    console.log("Row data: ", self.rows)
    self.sortBy()
    // self.getColumns(self.report);
    self.analytics()
    self.ref.markForCheck();
  }

  dataAnalytics;
  contentSize;

  analytics() {
    var self = this;

    self.dataAnalytics = new Array(self.cols.length)
    self.contentSize = new Array(self.cols.length)

    self.rows.forEach(function (row) {

      row.fields.forEach(function (field, index) {
        if (!self.contentSize[index]) self.contentSize[index] = 0;
        if (!self.dataAnalytics[index]) self.dataAnalytics[index] = [];
        switch (field.type) {
          case 'input-text':
          case 'text':
          case 'fullName':
          case 'full-name':
          case 'city':
          case 'displayText':
          case 'displayField':
          case 'full-text':
            if (field.binding) {
              const length = field.binding.length;
              if (length > self.contentSize[index])
                self.contentSize[index] = length;
            }
            break;


          case 'number':
          case 'currency':
            if (field.value) {
              if (self.dataAnalytics[index]?.length == 0) {
                // Start the data
                self.dataAnalytics[index].push({
                  type: "value",
                  count: 1,
                  total: field.value,
                  average: field.value,
                  min: field.value,
                  max: field.value
                })
              }
              else {  // Update values
                self.dataAnalytics[index][0].count++;
                self.dataAnalytics[index][0].total += field.value;
                self.dataAnalytics[index][0].average = (self.dataAnalytics[index][0].total / self.dataAnalytics[index][0].count).toFixed(2);
                if (field.value < self.dataAnalytics[index][0].min)
                  self.dataAnalytics[index][0].min = field.value;
                if (field.value > self.dataAnalytics[index][0].max)
                  self.dataAnalytics[index][0].max = field.value;

              }
              const length = field.value.toString().length;
              if (length > self.contentSize[index])
                self.contentSize[index] = length;

            }


            break;
          case 'select-button':
            if (field.selected && field.selected.length) {
              // Check if we have it
              var dataIndex = self.dataAnalytics[index].findIndex(function (post) {
                if (post.item == field.selected[0])
                  return true;
              });
              if (dataIndex != -1) {
                self.dataAnalytics[index][dataIndex].count++;
              }
              else {
                self.dataAnalytics[index].push({ type: "selection", item: field.selected[0], count: 1 })
                self.headerSettings.searchTags.push(field.selected[0])
              }
              var length = 0;
              field.selected.forEach(function (item) {
                length += item.length + 1;
              })
              if (length > self.contentSize[index])
                self.contentSize[index] = length;
            }

            break;

          case 'multi-select':
            // Check all selections
            var length = 0;
            field.selected.forEach(function (item) {
              var dataIndex = self.dataAnalytics[index].findIndex(function (post) {
                if (post.item == item)
                  return true;
              });
              if (dataIndex != -1) {
                self.dataAnalytics[index][dataIndex].count++;
              }
              else {
                self.dataAnalytics[index].push({ item: item, count: 1 })
                self.headerSettings.searchTags.push(item)
              }
              length += item.length + 1;
            })

            if (length > self.contentSize[index])
              self.contentSize[index] = length;
            break;


        }
      })

    })

    // SORT ALL
    self.dataAnalytics.forEach(function (col) {
      col.sort(function (a, b) {
        return b.count - a.count;
      });
    })

    // Autofit columns based on content
    // Logic1 max-space for 1 column
    var indexWide = 0;
    var maxLength = 0;
    self.contentSize.forEach(function (item, index) {
      if (item > maxLength) {
        maxLength = item;
        indexWide = index;
      }
    })
    var minWidth = 15; // percent
    const used = minWidth * (self.contentSize.length - 1);
    const balance = 100 - used;
    self.cols.forEach(function (col, index) {
      if (index == indexWide)
        col.width = balance;
      else
        col.width = minWidth;
    })



    console.log("DATA ANALYTICS: ", self.cols, self.dataAnalytics)

  }


  getData(formId) {
    var self = this;

    self.formService.getReportData(formId, self.cols, self.gotData, self, self.report, self.report.userReport, self.report.userOnly)
  }

  sortBy() {
    var self = this;

    if (this.report.sortBy == 'New First') {
      self.rows.sort(function (a, b) {
        return b.fields[0].date.toMillis() - a.fields[0].date.toMillis()
      });

    }
    else if (this.report.sortBy == 'New Last') {
      self.rows.sort(function (a, b) {
        return a.fields[0].date.toMillis() - b.fields[0].date.toMillis()
      });
    }
  }

  selectForm(index) {
    var self = this;
    // Add form and all fields to report
    this.report = self.global.allReports[self.reportIndex];

    if (!this.report.formIds) this.report.formIds = [];
    if (!this.report.formNames) this.report.formNames = [];
    if (!this.report.includedFields) this.report.includedFields = [];

    this.report.formIds.push(self.global.allForms[index].id);
    this.report.formNames.push(self.global.allForms[index].name);

    // Add all fields by defualt
    self.global.allForms[index].fields.forEach(function (field) {
      // Hack add check for access control
      // SKIP some fields
      if (field.type == 'pw' || field.type == 'button') []
      else
        self.report.includedFields.push(field.title)
    })


    self.updateReport(this.report);
    self.getColumns(this.report);

  }

  updateReport(report: Report) {
    var self = this;

    var db = firebase.firestore();

    const reportId = report.id;
    // STRIP NULLS all empty fields
    report = removeUndefined(report);

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("reports")
      .doc(reportId)

    docRef.update(report).then(() => {
      console.log("Report updated ", report);
    })
      .catch((error) => {
        console.error("Error writing report: ", error);
      });

  }

  startsWith(field: any, str) {
    if (typeof (field) != 'string') return;

    if (field && field.length > 0 && field.startsWith(str)) return true;
    else return false;
  }

  amIMedia(field: any) {
    if (field?.media) return true;
    else return false;
  }

  isMedia(fieldStr) {
    console.log("ismedia: ", fieldStr);


    if (fieldStr && typeof (fieldStr) == "string" && fieldStr.startsWith("http"))
      return true;

    else return false;

  }
  editEvent(form) {
    console.log("Form output ", form)
  }

  editRow(index) {
    var self = this;

    console.log("Edit row: ", index, self.allRows[index]);
    self.createEditForm(self.allRows[index], index)
    self.editingRow = true;


  }

  moveReport(report: Report) {
    var self = this;
    var db = firebase.firestore();

    db.collection("company").doc(this.global.myCompany.id).collection("reports")
      .doc(report.id)
      .get().then((doc) => {
        const data = <Report>doc.data();
        console.log("Report copied to Master")

        // Now create record in Master
        var ref1 = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("reports").doc()
        data.id = ref1.id;
        ref1.set(data)
      })
      .catch((error) => {
        console.log("no report found: ", error);
      });
  }
  getMyStyle() {
    if (this.overFlow)
      var style = "height: 100%;  overflow: visible; z-index: 200000; position: absolute; left: 0px; top: 0px;"
    else
      var style = "max-height: 100%; max-width: 100%; overflow: scroll; position: relative;"


    return style;
  }

  toggleExpand(tf) {
    this.overFlow = tf;
  }

}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
