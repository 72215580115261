<div class="layout">
    <div *ngIf="successMsg">
        <div style="width:100%; text-align: center; font-size: larger;">
            Thank You!<br>
            Your reservation has been received by {{" "+global.myCompany.name}}<br><br>
            An email confirmation has been sent to&nbsp; {{reserveEmail}}<br><br>
            

        </div>
    </div>

    <div *ngIf="!successMsg">

    <div *ngIf="global.loadingStore">
        <p-sidebar [(visible)]="global.loadingStore" [dismissible]="false" [showCloseIcon]="false" [fullScreen]="true">
            <img alt="" style="width:100%;"
                src="https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FLoadingStore-01.jpg?alt=media&token=da883e10-1c8f-4645-b1c5-6ac74dfa1f0c">
        </p-sidebar>
    </div>

    <p-accordion *ngIf="!adminRes" expandIcon="false" collapseIcon="false" [multiple]="true">
        <div *ngIf="global.authuser && showUser">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="user-feature">Welcome back {{global.authuser.firstName}}
                    </div>
                </ng-template>

                <ng-template pTemplate="content">

                    <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                        <!--           <button class="button-14" role="button" (click)="logMeOut()">Settings</button> -->

                        <button class="button-14" role="button" (click)="logMeOut()">Log Out</button>
                        <span *ngIf="global.authuser.emailVerified"></span>
                        <div *ngIf="!global.authuser.emailVerified">
                            Please validate email:.
                            <p-inputNumber [(ngModel)]="vCode" (onBlur)="VerifyEmail()"></p-inputNumber>


                        </div>

                    </div>

                </ng-template>
            </p-accordionTab>
        </div>



        <p-accordionTab *ngIf="global.myCompany && showHeader" [(selected)]="viewCompany">
            <ng-template pTemplate="header">
                <div class="feature" fxLayout="column" fxLayoutAlign="center center">
                    <div>New reservation for</div>
                    <div [innerHTML]="newResText" class="pretty-header-company">
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="content">
                <div class="table" (click)="hideMe()">
                    <h3> <span>{{global.myCompany.name}}</span></h3>
                    {{global.myCompany.address1}}
                    {{global.myCompany.city}}<br>
                    {{global.myCompany.primaryPhone}}

                </div>
                <div *ngIf="global.myCompany.reserveSettings.galleryId">
                    <app-gallery [viewOnly]="global.myCompany.reserveSettings.galleryId"></app-gallery>
                </div>



                <div class="pretty-data">{{global.myCompany.reserveSettings.resPolicy}} </div>

            </ng-template>
        </p-accordionTab>

        <div *ngIf="global.myCompany">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="feature">Reviews for {{global.myCompany.name}}</div>
                </ng-template>
                <ng-template pTemplate="content">

                    <app-reviews [store]="global.myCompany.store"></app-reviews>

                </ng-template>
            </p-accordionTab>
        </div>



        <div *ngIf="0 && global.myCompany && global.myCompany.enabled && global.myCompany.enabled.chatService">
            <div *ngIf="global.myCompany.chatSettings && global.myCompany.chatSettings.position=='right'">
                <app-chat [store]="global.myCompany.chatService" [user]=""></app-chat>
            </div>
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="feature" fxLayout="column" fxLayoutAlign="center center">
                        <div>Chat with {{global.myCompany.name}}</div>

                    </div>

                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="global.authuser">
                        <app-chat [store]="global.myCompany.chatService" [user]="global.authuser.uid"></app-chat>
                    </div>
                    <div *ngIf="!global.authuser">
                        <div fxLayout="row">
                            <div style="width:80%;">
                                <input type="email" pInputText placeholder="Email" [(ngModel)]="reserveEmail">
                            </div>
                            <div style="width:20%; min-height: 100%; display: flex; align-items: center; justify-content: center; background-color: lightblue; margin: 0px; padding:0px"
                                (click)="checkEmail()">
                                Next
                            </div>
                        </div>

                        <div *ngIf="inputCreds && foundUser">

                            <p-accordionTab [(selected)]="inputCreds">
                                <ng-template pTemplate="header">
                                    <div class="pretty">
                                        Log in with password
                                    </div>
                                </ng-template>


                                <ng-template pTemplate="content">
                                    <div style="text-align: center; margin:6px;" *ngIf="loginError">Please check email &
                                        password</div>
                                    <div
                                        style="display:flex; flex-direction: row; align-items:stretch; justify-content: center;">


                                        <input type="password" class="password" [(ngModel)]="pw"
                                            placeholder="password" />
                                        <p-button label="Sign In" (click)="login()"></p-button>

                                    </div>
                                    <div *ngIf="!forgotStr"
                                        style="font-size: 120%; text-align: center; cursor:pointer; margin-top:12px; background-color: rgb(244, 233, 233);"
                                        (click)="ForgotPassword()">
                                        <div style="cursor:pointer;">Forgot password? Click me and we'll email it to
                                            you.</div>
                                    </div>
                                    <div *ngIf="forgotStr" style="text-align: center;margin-top:12px">Reminder email
                                        sent.
                                    </div>

                                </ng-template>
                            </p-accordionTab>


                        </div>



                        <div *ngIf="inputCredsnoUser && !foundUser">
                            <p-accordionTab #inputName [(selected)]="inputCredsnoUser">
                                <ng-template pTemplate="header">
                                    <div class="pretty">
                                        Enter contact info below
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="content">

                                    <div class="pretty">

                                        <input type="text" pInputText placeholder="First name"
                                            [(ngModel)]="reserveFirstName">


                                        <div fxLayout="row">
                                            <div style="width:80%">
                                                <input type="text" pInputText placeholder="Last name"
                                                    [(ngModel)]="reserveLastName">
                                            </div>

                                            <div style="width:20%; min-height: 100%; display: flex; align-items: center; justify-content: center; background-color: lightblue; margin: 0px; padding:0px"
                                                (click)="registerMe()">
                                                Start Chat
                                            </div>
                                        </div>
                                    </div>

                                </ng-template>

                            </p-accordionTab>

                        </div>

                    </div>
                </ng-template>
            </p-accordionTab>

        </div>


        <div *ngIf="global.authuser">


            <div style="height: max-content"
                *ngIf="global.authuser && !global.loadingReservations && global.myReservations && global.myReservations.length && !changeRes">
                <p-accordion styleClass="dark-panel" expandIcon=" false" collapseIcon="false" [multiple]="true">
                    <p-accordionTab styleClass="dark-panel" [(selected)]="showOpenRes">
                        <ng-template pTemplate="header" styleClass="dark-panel">
                            <div class="feature">
                                Open Reservations {{global.myReservations.length}}
                            </div>
                        </ng-template>

                        <ng-template pTemplate="content">

                            <div *ngFor="let res of global.myReservations; let i = index">
                                <p-accordionTab class="tab">
                                    <ng-template pTemplate="header">

                                        <div class="pretty-header-data">
                                            {{res.storeName}}<br>
                                            {{res.resDate.dateStr+" "+res.resTime.strTime +" for "+ res.partySize}}
                                            <span *ngIf="res.tableObj"> {{"at " + res.tableObj.name}} </span>

                                        </div>
                                    </ng-template>

                                    <ng-template pTemplate="content">
                                        <div class="pretty-header-data">

                                            <span *ngIf="res.resTime.strDetail"> {{res.resTime.strDetail}} </span>
                                            <span *ngIf="res.tableObj"> {{res.tableObj.name}} </span>
                                            {{addons}}
                                            {{note}}

                                        </div>

                                        <div class="pretty-buttons">
                                            <button role="button" class="button-9"
                                                (click)="changeReservation(res)">Change
                                                Reservation</button>

                                            <div *ngIf="!cancelThisRes">
                                                <button role="button" icon="pi pi-times" class="button-cancel"
                                                    (click)="cancelRes(res)">Cancel Reservation</button>
                                            </div>
                                            <div *ngIf="cancelThisRes==res">
                                                <button role="button" icon="pi pi-times" class="button-9"
                                                    (click)="cancelResUndo(res)">Keep Reservation</button>
                                                <button role="button" label="Confirm Cancel" icon="pi pi-times"
                                                    class="button-9" style="background-color:darkorange"
                                                    (click)="cancelResConfirm(res)">Confirm
                                                    Cancelation</button>

                                            </div>

                                        </div>


                                    </ng-template>
                                </p-accordionTab>
                            </div>
                        </ng-template>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>


    </p-accordion>
    <div *ngIf="reservingYourTable">
        <p-sidebar [(visible)]="reservingYourTable" [dismissible]="false" [showCloseIcon]="false" [fullScreen]="true">
            Reserving Your Table ...
        </p-sidebar>
    </div>
    <div *ngIf="callNowActive">
        <p-sidebar [(visible)]="callNowActive" [dismissible]="false" [showCloseIcon]="false" [fullScreen]="true">
            Before calling here are some Instant Message options
            <button class="button-9" role="button" (click)="getDirections()">We are running up to 10 minutes
                late</button>
            <button class="button-9" role="button" (click)="getDirections()">We are running up to 20 minutes
                late</button>
            <button class="button-9" role="button" (click)="getDirections()">We are on our way.</button>

            <br>
            <button class="button-9" role="button" (click)="getDirections()">Record an instant voice message</button>
            <br>
            <button class="button-9" role="button" (click)="getDirections()">Call Now</button>
            <br><br>
            <button class="button-9" role="button" (click)="callNowInactive()">Cancel Call Now</button>


        </p-sidebar>
    </div>

    <div style="margin:6px;border:2px solid rgb(189, 192, 192); background-color: aliceblue;">


        <!--             NEW RESERVATION            -->

        <div *ngIf="!newRes && !changeRes && showHeader" fxLayout="column" fxLayoutAlign="center center">
            <p-button class="field" label="New Reservation" (click)="newReservation2()"></p-button>
            <p><br></p>
        </div>

        <div *ngIf="!newRes && !changeRes && !showHeader" fxLayout="column" fxLayoutAlign="center center">
            <p-button class="field" label="New Reservation" (click)="newReservation2()"></p-button>
            <p><br></p>
        </div>

        <!-- Get Party Size -->
        <div *ngIf="!openOnly && (newRes || changeRes) && (global.myCompany && global.myCompany.reserveSettings)">
            <div *ngIf="changeRes" class="pretty-headline">Modify Details Below</div>
            <p-accordion class="someStyleClass" expandIcon=" false" collapseIcon="false" [multiple]="true"
                (onOpen)="onTabOpen($event.target, $event.index)">
                <!--    GROUP BOOKINGS -->

                <p-accordionTab *ngIf="global.myCompany.enabled.groupBookings" [(selected)]="viewGroupTab">
                    <ng-template pTemplate="header">
                        <div class="feature">{{groupBookingHeader()}}

                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">
                        <div class="pretty-center" style="padding-top:12px; color:rgb(105, 158, 58)">
                            Enjoy discounts and exclusive options!
                        </div>
                        &nbsp;
                        <div style="margin-bottom: 12px;">
                            <div style="margin-bottom: 12px;" fxLayout="row wrap" fxLayoutAlign="end center">
                                <div class="pretty-text"> How many Adults 21+</div>
                                <div style="width:6em; margin-right:6px;">
                                    <p-inputNumber [(ngModel)]="selectedSize"
                                        [min]="global.myCompany.groupSettings.minParty"
                                        [max]="global.myCompany.groupSettings.maxParty"
                                        (onInput)="groupSizeChanged2(selectedSize)" placeholder=""></p-inputNumber>
                                </div>
                            </div>
                            <div fxLayout="row wrap" fxLayoutAlign="end center">
                                <div class="pretty-text">Aged 5-20 (under 5 free)</div>
                                <div style="width:6em; margin-right:6px;">
                                    <p-inputNumber [(ngModel)]="selectedSizeUnder21" placeholder="">
                                    </p-inputNumber>
                                </div>
                            </div>
                        </div>

                    </ng-template>
                </p-accordionTab>






                <p-accordionTab *ngIf="partySize && !viewGroupTab" [(selected)]="viewPartySize">
                    <ng-template pTemplate="header">
                        <div class="feature">{{partySizeHeader()}}

                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">
                        <!--                    
                    &nbsp;
                    <div style="width:8em;">
                        <p-inputNumber [(ngModel)]="selectedSize" [min]="global.myCompany.reserveSettings.minParty"
                            [max]="global.myCompany.reserveSettings.maxParty" (onInput)="partySizeChanged()"
                            placeholder="How many?"></p-inputNumber>

                    </div>
-->
                        <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="space-around center">
                            <div *ngFor="let item of partySize; let i = index">

                                <div class="pretty-time" fxLayoutAlign="center center"
                                    (click)="partySizeChanged2(item)">
                                    <div style="margin:25px;">
                                        {{item}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </p-accordionTab>

                <p-accordionTab [(selected)]="viewCalendar">
                    <ng-template pTemplate="header">
                        <div class="feature">
                            {{getPrettyDate2(date5)}}
                        </div>
                    </ng-template>

                    <ng-template pTemplate="content">

                        <div class="calendar">
                            <p-calendar [(ngModel)]="date5" [selectOtherMonths]="true" [minDate]="minDate"
                                [inline]="true" [showIcon]="true" placeholder="Choose a date"
                                [disabledDays]="global.myCompany.reserveSettings.daysClosed" [readonlyInput]="true"
                                [disabledDates]="invalidDates" (onSelect)="checkDate(date5)">
                            </p-calendar>
                        </div>

                    </ng-template>
                </p-accordionTab>

                <!-- GET THE TIME -->
                <div *ngIf="date5">
                    <p-accordionTab [(selected)]="viewTimes">
                        <ng-template pTemplate="header">
                            <div class="feature">Your Time:&nbsp;
                                <span *ngIf="selectedTime">{{selectedTime.strTime}}</span>

                            </div>
                        </ng-template>

                        <ng-template pTemplate="content">
                            <div style="width:100%;" fxLayout="column" fxLayoutAlign="space-between space-between">
                                <div *ngFor="let time of availableTimes2; let i = index">

                                    <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                                        <div *ngFor="let min of availableTimes2[i]; let m = index">

                                            <div class="pretty-time" [style.background-color]="myColor(i,m)"
                                                fxLayoutAlign="center center" (click)="chipClicked(i,m)">
                                                <div class="time-string">
                                                    {{getTimeStr(i, m)}}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </ng-template>
                    </p-accordionTab>
                    <!--     CUSTOM GROUP BOOKING -->
                    <div *ngIf="global.myCompany.enabled.groupBookings && groupMode">

                        <p-accordionTab [(selected)]="viewGroupPackages">
                            <ng-template pTemplate="header">
                                <div class="feature" fxLayoutAlign="space-around space-around">

                                    <div class="pretty-data-row">{{getMyPackage()}}</div>
                                    <div *ngIf="groupMode && !selectedPackage"
                                        style="text-align: center; color:orange;">
                                        Please make a selection</div>
                                </div>
                            </ng-template>

                            <ng-template pTemplate="content">
                                <div style="width: 100%">
                                    <app-groups selectedSize={{selectedSize}}
                                        selectedSizeUnder21={{selectedSizeUnder21}}
                                        (setPackageEvent)="setPackage($event)"></app-groups>
                                </div>
                            </ng-template>
                        </p-accordionTab>
                    </div>

                    <!-- Special Tables/services    -->
                    <div *ngIf="0 && availableTables && availableTables.length && date5 && selectedTime">

                        <p-accordionTab [(selected)]="viewTables">
                            <ng-template pTemplate="header">
                                <div class="feature">
                                    {{getSelectedTable()}}
                                </div>
                            </ng-template>

                            <ng-template pTemplate="content">

                                <p-accordion expandIcon=" false" collapseIcon="false" [multiple]="false"
                                    (onOpen)="onResOpen($event, i)">

                                    <!--  PUT THIS IN ANOTHER HTML -->
                                    <div *ngFor="let table of availableTables; let i = index">
                                        <p-accordionTab class="tab" [(selected)]="activeTable[i]">
                                            <ng-template pTemplate="header">
                                                <div class="feature" style="margin-top:10px; margin-bottom:10px;"
                                                    fxLayout="column" fxLayoutAlign="space-around center">
                                                    <div>
                                                        {{table.name}}
                                                    </div>
                                                    <div *ngIf="table.subTitle" class="pretty-subtitle">
                                                        {{table.subTitle}}
                                                    </div>
                                                </div>

                                            </ng-template>

                                            <ng-template pTemplate="content">

                                                <p-accordion>
                                                    <div *ngIf="table.video1">
                                                        <p-accordionTab>
                                                            <ng-template pTemplate="header">
                                                                <div class="feature">Watch the Video
                                                                </div>
                                                            </ng-template>

                                                            <ng-template pTemplate="content">
                                                                <div class="video-container">
                                                                    <video playsinline webkit-playsinline autoplay
                                                                        [muted]="'muted'" id="myVideo">
                                                                        <source src="{{table.video1}}" type="video/mp4">
                                                                    </video>
                                                                </div>
                                                            </ng-template>
                                                        </p-accordionTab>
                                                    </div>

                                                    <div *ngIf="table.image1">
                                                        <p-accordionTab [(selected)]="showPhotos[i]">
                                                            <ng-template pTemplate="header">
                                                                <div class="feature">Photos
                                                                </div>
                                                            </ng-template>

                                                            <ng-template pTemplate="content">
                                                                <div style="width:100%">
                                                                    <img alt="" class="full-size-image"
                                                                        src={{table.image1}}>
                                                                </div>
                                                            </ng-template>
                                                        </p-accordionTab>
                                                    </div>

                                                    <div *ngIf="table.galleryId">
                                                        <p-accordionTab>
                                                            <ng-template pTemplate="header">
                                                                <div class="feature">Gallery
                                                                </div>
                                                            </ng-template>

                                                            <ng-template pTemplate="content">

                                                                <div *ngIf="table.galleryId" style="width:100%">
                                                                    <app-gallery
                                                                        [viewOnly]="table.galleryId"></app-gallery>
                                                                </div>
                                                            </ng-template>
                                                        </p-accordionTab>
                                                    </div>



                                                </p-accordion>

                                                <div class="description">
                                                    {{table.description}}
                                                </div>
                                                <button class="button-9" role="button"
                                                    (click)="toggleTable(table, i)">Select</button>
                                            </ng-template>

                                        </p-accordionTab>
                                    </div>
                                </p-accordion>
                            </ng-template>
                        </p-accordionTab>
                    </div>
                    <!--    RESERVATION REWARDS -->
                    <div *ngIf="global.myCompany.enabled.reservationRewards">
                        <app-res-rewards (setRewardEvent)="setReward($event)"></app-res-rewards>
                    </div>

                    <!-- Check Credentials -->

                    <div *ngIf="!global.authuser && selectedTime">

                        <p-accordionTab [(selected)]="inputCreds">
                            <ng-template pTemplate="header">
                                <div class="pretty">
                                    <div *ngIf="!finalReview" fxLayout="row">
                                        <div style="width:80%;">
                                            <input type="email" pInputText placeholder="Email"
                                                [(ngModel)]="reserveEmail">
                                        </div>
                                        <div style="width:20%; min-height: 100%; display: flex; align-items: center; justify-content: center; background-color: lightblue; margin: 0px; padding:0px"
                                            (click)="checkEmail()">
                                            Next
                                        </div>
                                    </div>
                                    <div *ngIf="finalReview" fxLayout="row" (click)="editNewUser()">
                                        Reservation for: {{" "+reserveFirstName+" "+reserveLastName}}
                                    </div>
                                    <!--
                                <input type="email" pInputText placeholder="Email" [(ngModel)]="reserveEmail"
                                    (blur)="checkEmail()">
                                    -->
                                </div>
                            </ng-template>
                            <ng-template pTemplate="content">
                            </ng-template>
                        </p-accordionTab>


                        <div *ngIf="inputCreds && foundUser">

                            <p-accordionTab [(selected)]="inputCreds">
                                <ng-template pTemplate="header">
                                    <div class="pretty">
                                        Log in with password
                                    </div>
                                </ng-template>


                                <ng-template pTemplate="content">
                                    <div style="text-align: center; margin:6px;" *ngIf="loginError">Please check email &
                                        password</div>
                                    <div
                                        style="display:flex; flex-direction: row; align-items:stretch; justify-content: center;">


                                        <input type="password" class="password" [(ngModel)]="pw"
                                            placeholder="password" />
                                        <p-button label="Sign In" (click)="login()"></p-button>

                                    </div>
                                    <div *ngIf="!forgotStr"
                                        style="font-size: 120%; cursor: pointer; text-align: center;margin-top:12px; background-color: rgb(254, 243, 243);"
                                        (click)="ForgotPassword()">Forgot password? Click me and we'll email it to you.
                                    </div>
                                    <div *ngIf="forgotStr" style="text-align: center;margin-top:12px">Reminder email
                                        sent.
                                    </div>

                                </ng-template>
                            </p-accordionTab>
                        </div>

                        <div *ngIf="inputCredsnoUser && !foundUser">
                            <p-accordionTab [(selected)]="inputCredsnoUser">
                                <ng-template pTemplate="header">
                                    <div class="pretty">
                                        Enter contact info below
                                    </div>
                                </ng-template>

                                <ng-template pTemplate="content">

                                    <div class="pretty">

                                        <input type="text" pInputText placeholder="First name"
                                            [(ngModel)]="reserveFirstName">
                                        <input type="text" pInputText placeholder="Last name"
                                            [(ngModel)]="reserveLastName">




                                        <div fxLayout="row">
                                            <div style="width:70%">
                                                <p-inputMask placeholder="Phone" type="tel" mask="(999) 999-9999"
                                                    [autoClear]="false" [(ngModel)]="reservePhone"
                                                    placeholder="(999) 999-9999"></p-inputMask>
                                            </div>

                                            <div style="width:30%; min-height: 100%; display: flex; align-items: center; justify-content: center; background-color: lightblue; margin: 0px; padding:0px"
                                                (click)="validateUserData()">
                                                Review
                                            </div>
                                        </div>


                                    </div>

                                </ng-template>

                            </p-accordionTab>
                        </div>
                        <div id="inputName2"></div>
                    </div>

                    <div *ngIf="global.authuser || userDataValid && date5 && selectedTime">
                        <p-accordionTab *ngIf="global.authuser && global.authuser.emailVerified">
                            <ng-template pTemplate="header">
                                <div class="feature">
                                    Notes
                                </div>
                            </ng-template>

                            <ng-template pTemplate="content" style="height:100px;">
                                <textarea #textarea1 [(ngModel)]="note" [rows]="5" [cols]="30" pInputTextarea
                                    autoResize="autoResize" [autofocus]="true"></textarea>
                            </ng-template>
                        </p-accordionTab>

                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div class="feature">
                                    Map of {{global.myCompany.name}}
                                </div>
                            </ng-template>

                            <ng-template pTemplate="content">
                                <app-maps></app-maps>
                            </ng-template>
                        </p-accordionTab>



                        <!--          <p-accordionTab>
                        <ng-template pTemplate="header">-->
                        <div class="feature">
                            <div *ngIf="newRes && selectedTime">

                                <div *ngIf="allSet">
                                    <button pRipple pButton style="margin-top:30px;" class="button-14"
                                        (click)="reserveTable()">{{ReserveButtonText()}}</button>
                                    <button style="margin-top:30px;" role="button" class="button-cancel"
                                        (click)="cancelNewReservation()">Start Over</button>
                                </div>

                            </div>
                            <div *ngIf="changeRes">
                                <p-button label="Confirm Changes" (click)="updateReservation(editingRes)">
                                </p-button>
                                <button class="button-14" role="button" (click)="cancelChanges()">Cancel
                                    changes</button>

                            </div>
                            <!-- IMPLEMENT LATER
                                <button class="button-14" role="button" (click)="dialing()">Call Now:
                                    {{global.myCompany.primaryPhone}} </button>
-->
                        </div>

                        <!--
                        </ng-template>

                        <ng-template pTemplate="content">
                        </ng-template>
                    </p-accordionTab>  -->




                    </div>
                </div>

            </p-accordion>


    </div>

    <!--   ADMIN FUNCTIONALITY 
    <div *ngIf="global.authuser">
        <app-addTable></app-addTable>
    </div>

    <div style="width:100%; height:80px;"></div>-->

    <!--
    <div *ngIf="global.authuser && !newRes && !changeRes">
        <button class="button-14" role="button" (click)="gotoMenu()">Go to Menu</button>
    </div>
-->
</div>
</div>
</div>
<div fxLayout="row" fxLayoutAlign="center center">
    <img src="../../../../assets/napkinres.png" class="napkin-logo">
</div>