import { Component, ChangeDetectorRef, ChangeDetectionStrategy, OnInit, ViewChild, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Form, Query, When, Report, FieldInfo, Container, Field, ContainerSettings } from '../company-interface'
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { ViewportScroller } from "@angular/common";
import { User, Media } from "../user-interface";
import { set, startOfDay, endOfDay, getMilliseconds } from "date-fns";
import { FormService } from '../form.service';
import { HttpClient } from '@angular/common/http';
import { WhereFilterOp } from 'firebase/firestore';

@Component({
  selector: 'app-queries',
  templateUrl: './queries.component.html',
  styleUrls: ['./queries.component.css', '../../common.scss']
})
export class QueriesComponent implements OnInit {
  @Input() queryId: string;
  @Input() queryName: string;
  @Input() queryIndex: number;
  @Input() editing: boolean = false;
  @Input() overFlow;

  query: Query;
  result;
  queryForm: Form; //Settings

  constructor(public global: Globals, public registerService: RegisterService,
    private ref: ChangeDetectorRef,
    private http: HttpClient,
    private formService: FormService,
    public nav: NavService, private scroller: ViewportScroller) {

  }

  ngOnInit(): void {
    console.log("Query: ", this, this.queryIndex, this.queryId)

    if (this.queryName) { // Should already be loaded????
      this.queryIndex = this.global.allQueries.findIndex(p => p.title == this.queryName)
    }

    if (this.queryIndex >= 0) {
      this.queryId = this.global.allQueries[this.queryIndex].id;
    }

    if (this.queryId) {
      this.getQuery(this.queryId)
    }


  }
  outputEvent(form: Form) {
    var self = this;
    var getNewData = false;
    var updateFields = false;

    console.log("Query Settings output ", form)

    form.fields.forEach(function (field) {
      if (field.title == 'title') {
        self.query.title = field.binding;
      }
      if (field.title == 'style') {
        self.query.style = field.binding;
      }
      if (field.title == 'label') {
        self.query.label = field.binding;
      }
      // FILTERS - for now just 1
      if (field.title == 'filters' && self.query.filters != field.selected) {
        if (!self.query.filters) self.query.filters = [];
        self.query.filters = field.selected;
        getNewData = true;
      }



      if (field.title == 'formName' && self.query.formName != field.selected[0]) {
        self.query.formName = field.selected[0];
        const myForm = self.global.allForms.find((f) => f.name == self.query.formName)
        self.query.formId = myForm.id;
      }

      if (field.title == 'function') {
        self.query.function = field.selected[0];
      }
      if (field.title == 'when' && self.query.when != field.selected[0]) {
        self.query.when = field.selected[0];
        getNewData = true;
      }
      /*
      if (field.title == 'date1' && self.report.date1 != field.value) {
        self.report.date1 = field.value;
        getNewData = true;
      }
      if (field.title == 'date2' && self.report.date2 != field.value) {
        self.report.date2 = field.value;
        getNewData = true;
      }
      */

      if (field.title == 'displayFields') {
        if (self.query.displayFields && JSON.stringify(self.query.displayFields.sort()) === JSON.stringify(field.selected.sort())) {
        }
        else {
          self.query.displayFields = field.selected;
          updateFields = true;
          getNewData = true;
        }


      }
    })
    console.log("AFTER QUERY settings got an update ", form, this.query);


   self.updateQuery(self.query);

    if (getNewData) {
      self.formService.getSomeData(self.query.formId,
        self.query.displayFields, this.gotData, this, self.query.when, self.query)
    }

    // self.ref.markForCheck();

  }

  updateQuery(query: Query) {
    var self = this;

    var db = firebase.firestore();

    // const reportId = report.id;
    // STRIP NULLS all empty fields
    query = removeUndefined(query);

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("Queries")
      .doc(query.id)

    docRef.update(query).then(() => {
      console.log("Query updated ", query);
    })
      .catch((error) => {
        console.error("Error writing query: ", error);
      });

  }

  getQuery(queryId) {
    var self = this;
    var db = firebase.firestore();

    var docRef = db.collection("company").doc(this.global.myCompany.id).collection("Queries")
      .doc(queryId)

    docRef.get().then((doc) => {
      var data = <Query>doc.data();
      self.query = data;
      self.formService.getSomeData(self.query.formId,
        self.query.displayFields, this.gotData, this, self.query.when, self.query)
      self.getMasterForm("masterQuerySettings");
      console.log("My Query ", self.query)
    })

  }

  getMasterForm(formName: string) {
    var self = this;

    console.log("Get master form ", formName)
    var db = firebase.firestore();

    db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("forms")
      .where("name", "==", formName)
      .get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Form>doc.data();

          // Have form check for positions
          data.fields.sort(function (a, b) {
            if (a.position == null) a.position = 99;
            if (b.position == null) b.position = 99;
            if (a.position > b.position) return 1;
            if (a.position < b.position) return -1;
            return 0;
          })


          self.queryForm = { ...data }
          // update dependent fields
          
          var formSelected = self.formService.findMe("formName", self.queryForm)
          var fieldOptions = self.formService.findMe("displayFields", self.queryForm)
          var fieldQuery = self.formService.findMe("filters", self.queryForm)

          console.log("FORMS FIELDS.... ", formSelected, fieldOptions,fieldQuery)

          self.queryForm.fields[formSelected].selected = [self.query.formName];
          self.queryForm.fields[fieldOptions].sourceForm = self.query.formName;
          self.queryForm.fields[fieldQuery].sourceForm = self.query.formName;
          self.queryForm.fields[fieldQuery].dataSource = "Fields";
          self.queryForm.fields[fieldQuery].selected = self.query.filters;


          /*
          // Add the COPY FORM Button
          var copyForm: Field = { type: 'button', class: '', title: 'Copy Form', label: 'Copy Me' }
          self.editForm.fields.push(copyForm)

          // Add the Remove Field Button
          var remove: Field = { type: 'button', class: 'remove-field', title: 'Remove Form', label: 'Remove' }
          self.editForm.fields.push(remove)
*/
          console.log("formDATA; ", self.queryForm);
        })
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });
  }

  gotData(self: this, submissions: any[]) {
    console.log("GOT QUERY DATA: ", submissions,self.query.filters)

    var filtered = [];

    // Let's run the query filters on the data
    if (self.query.filters && self.query.filters.length) {
      // Now let's run the data.
      submissions.forEach(function (fieldData) {
        // NOW RUN THE FILTER
        var field;
        var fieldValue: string | string[];
        var operator;
        var value: string;
        var adder: string;
        var truthy: boolean;

        self.query.filters.forEach(function (item, index) {
          var x = index % 4;
          switch (x) {
            case 0: // It's a field
              field = item;
              break;
            case 1: // It's an operator
              operator = item;
              break;
            case 2: // It's a value ... or field
              value = item;
              break;
            case 3: // It's an adder
              adder = item;
              break;
          }
          if (field && operator && value) {
            value = value.toLowerCase();

            if (!adder) { // BASE QUERY
              if (is_array(fieldData)) {
                const found = fieldData.some(r => r.toLowerCase() == value);
                if (found)
                  truthy = true;
                else truthy = false;
              }
              else {
                if (fieldData.toString().toLowerCase() == value)
                  truthy = true;
                else truthy = false;
              }
            }
            if (adder && adder.toLowerCase() == 'or') {
              // If already TRUE THEN TRUE
              if (truthy && truthy == true) {
                // We can skip the OR
              }
              else {
                var truthy2;
                if (is_array(fieldData)) {
                  const found = fieldData.some(r => r.toLowerCase() == value);
                  if (found)
                    truthy2 = true;
                  else truthy2 = false;
                }
                else if (fieldData.toLowerCase() == value)
                  truthy2 = true;
                else truthy2 = false;
                if (truthy2) truthy = true;
              }

            }

            console.log("Single Query... ", adder, field, operator, value, truthy);

            field = undefined;
            operator = undefined;
            value = undefined;
          }

        })
        if (truthy && truthy == true) {
          filtered.push(value)
        }
        truthy = undefined;
        adder = undefined;
      })
    }
    else filtered = submissions;



    // NOW CREATE RESULT FROM QUERY TYPE
    switch (self.query.function.toLowerCase()) {
      case 'count':
        self.result = filtered.length;
        break;

    }

    console.log("GOT QUERY RESULT: ", filtered, self.result)



  }

  sendEmail() {

    var form = {
      to: "swarner100@gmail.com",
      subject: "Test",
      message: "<p><strong>This is a test</strong><p>"
    }

    this.http.post('https://sendemailhtml-xdnunfyrka-uc.a.run.app',
      form).subscribe({
        next: response => console.log('Email sent', response),
        error: error => console.error('Error sending email', error)
      });

  }


  getMyStyle () {
    if (this.overFlow)
     var style="height: 100%;  overflow: visible; z-index: 200000; position: absolute; left: 0px; top: 0px;"
    else
      var style="max-height: 100%; max-width: 100%; overflow: scroll; position: relative;"


  return style;
  }

  toggleExpand (tf) {
    this.overFlow = tf;
  }

}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}

var is_array = function (value) {
  return value &&
    typeof value === 'object' &&
    typeof value.length === 'number' &&
    typeof value.splice === 'function' &&
    !(value.propertyIsEnumerable('length'));
};