<div *ngIf="node?.format=='polaroid'" fxFlex fxLayout="column" fxLayoutAlign="none none" >



    <div *ngIf="node?.media" class="polaroid-container">
        <app-media-view [media]="node.media" [noStyle]="true"></app-media-view>
        
    </div>

    



    <div style="text-align: center; font-size: larger; font-weight:600;">{{node.author_name}}</div>
</div>