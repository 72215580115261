<div fxLayout="row" fxLayoutAlign="space-between center">
    <button (click)="addChatBot($event)" class="create-form" pButton pRipple type="button">
        Create New ChatBot
    </button>
</div>
<div *ngIf="global.allChatBots">
    <p-accordion styleClass="tab" [multiple]="false" expandIcon="false" collapseIcon="false"
        *ngFor="let bot of global.allChatBots; let i = index;" (onOpen)="onChatOpen($event, i)"
        (onClose)="onChatClose($event, i)">

        <p-accordionTab>
            <ng-template pTemplate="header">
                <div fxLayout="row" fxLayoutAlign="none none">
                    <div class="chat-row">                
                       {{getBotName(bot)}}
                    </div>
                </div>

            </ng-template>
            <ng-template pTemplate="content">
                <app-chatbot [chatBot]="bot" access="Admin" [accessValid]="true" [userId]="userId"></app-chatbot>

            </ng-template>
        </p-accordionTab>
    </p-accordion>

</div>

<!--
<div *ngIf="!global.allChatBots">
    <app-form formName="masterChatBot" [formInput]="global.authuser.newsSettings" [autoSubmit]="false"
    (submitEvent)="outputEvent($event)"></app-form>
</div>
-->