import { Component } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ProductListComponent } from '../product-list/product-list.component';
import { Product } from '../product-int';
import { InputSwitchModule } from 'primeng/inputswitch';
import firebase from 'firebase/compat/app';
import { Globals } from '../../globals';

@Component({
    templateUrl: './editProduct.html',
    styleUrls: ['./editProduct.css']
})
export class editProduct {
    editingProduct: Product;
    autoResize: boolean = true;
    myPosition: number;

    constructor(public global: Globals, public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {
        //id: this.config.id
        console.log(this.config.data.item);

        this.editingProduct = this.config.data.item;
        if (!this.editingProduct.myPosition) this.editingProduct.myPosition = -1;
        if (!this.editingProduct.redeemable) this.editingProduct.redeemable=false;


    }

    handleClick() {
        // USER UPDATES PRODUCT RECORD
        // Need dataservice to save record
        this.UpdateProduct();
        this.ref.close();

    }
    copyNewProduct() {
        var self = this;

        var db = firebase.firestore();

        var docRef = db.collection("products").doc();
        let newProduct = JSON.parse(JSON.stringify(self.editingProduct)); //DEEP COPY

        newProduct.title = newProduct.title + " -Copy";
        newProduct.docID = docRef.id;
        if (!newProduct.dept) newProduct.dept ="All;"

        docRef.set(
            newProduct
        )
            .then(() => {
                console.log("New Product added");
                //            self.GetProducts();  // SHOULD AUTO UPDATE FROM SERVICE
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
    }



    UpdateProduct() {
        var db = firebase.firestore();
        var self = this;


        var docRef = db.collection("company").doc(self.global.myCompany.id).collection('products').doc(self.editingProduct.docID);

        if (this.editingProduct.myPosition >= 0) { // Single Line Conditional Data Update
            docRef.update({ myPosition: self.editingProduct.myPosition });
        }
        if (this.editingProduct.allTags) {
            docRef.update({
                allTags: self.editingProduct.allTags
            });
        }
        docRef.update(
            {
                fullDescription: this.editingProduct.fullDescription,
                active: this.editingProduct.active,
                redeemable: this.editingProduct.redeemable,
                onMenu: this.editingProduct.onMenu,
                retail: this.editingProduct.retail,
                title: this.editingProduct.title,
                dept: this.editingProduct.dept,
            }
        ).then(() => {
            console.log("Product successfully updated!");
        })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating product: ", error);
            });

        // ADD ERROR HANDLING WHEN MOVE TO V9/Service
    }

    UpdateProduct2() {
        var db = firebase.firestore();
        var self = this;


        var docRef = db.collection("products").doc(self.editingProduct.docID);

        if (this.editingProduct.myPosition >= 0) { // Single Line Conditional Data Update
            docRef.update({ myPosition: self.editingProduct.myPosition });
        }
        if (this.editingProduct.allTags) {
            docRef.update({
                allTags: self.editingProduct.allTags
            });
        }
        docRef.update(
            {
                fullDescription: this.editingProduct.fullDescription,
                active: this.editingProduct.active,
                redeemable: this.editingProduct.redeemable,
                onMenu: this.editingProduct.onMenu,
                retail: this.editingProduct.retail,
                title: this.editingProduct.title,
                dept: this.editingProduct.dept,
            }
        ).then(() => {
            console.log("Product successfully updated!");
        })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating product: ", error);
            });

        // ADD ERROR HANDLING WHEN MOVE TO V9/Service
    }
}