import { Injectable } from '@angular/core';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Product } from './product-int'
import { DataGem, Navigate } from './company-interface'
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Globals } from '../globals';
import { Media } from './user-interface';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import firebase from 'firebase/compat/app';
import { ProductsService } from './products.service';
import { RegisterService } from './register.service';
import { ViewChild, ElementRef } from '@angular/core';
import { Company, Form, Logic, SessionField, Code, CodeObject, Element, Field, ContainerSettings, AppNode, Duration, Reviews, Review, Coupon, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, Enabled } from './company-interface';


class Sprite {
  id: string;   // Unique value
  x: number;
  y: number;
  vx: number;
  vy: number;
  scalex: number;
  scaley: number;
  red: number;
  green: number;
  blue: number;
  opacity: number;
  mathPi2: number;
  dataStr: string;
  media: Media;
  image = new Image();
  bounds: boolean = true;
  name: string;
  type?: string;
  radius: number
  color: string;
  friction = 1;
  angle: number = 0;
  spin: number = 0;
  createdTime: number;
  moveTime: number = 0; // Ms to continue movement

  constructor() {
 //   this.x = Math.random() * 40 + 200;
 //   this.y = Math.random() * 40 + 200;
    this.vx = Math.random() * 200 - 100;
    this.vy = Math.random() * 200 - 100;
    this.red = Math.random() * 255;
    this.green = Math.random() * 255;
    this.blue = Math.random() * 255;
    this.opacity = Math.random() * 255;
    this.color = "rgb(" + this.red + "," + this.green + "," + this.blue + "," + this.opacity + ")"
     this.radius = Math.random() * 12 +2;
     this.mathPi2 = Math.PI * 2;
  }
}

class SpriteEngine {
  sprites: Sprite[];
  width: 500;
  height: 500;
  frame: number;
  //context: any = null;
  canvasEl!: HTMLCanvasElement
  private context!: CanvasRenderingContext2D;
  lastUpdated: number;


  constructor(canvas: HTMLCanvasElement, width, height) {
    this.context = canvas.getContext('2d');
    console.log("XSXSXSXXS context ", this.context, width, height)
    this.sprites = [];
    this.width = width;
    this.height = height;
    this.frame = window.requestAnimationFrame(() => this.draw());

  }

  addSprite(code: CodeObject) {
    var self = this;
/*
I don't think I use this
    console.log("new sprite ", code,this.sprites.length )
    var sprite = new Sprite()
    sprite.x = code.x;
    sprite.y = code.y;
    sprite.type = code.spriteType;
    sprite.dataStr = code.dataStr;
    if (code.media) {
      sprite.media = code.media[0];
      sprite.image = new Image();
      sprite.image.src = code.media[0].url;
      console.log("Added media... ", sprite)
    }

    sprite.vx = code.vx;
    sprite.vy = code.vy;
    sprite.scalex = code.scalex;
    sprite.scaley = code.scaley;
    sprite.id = code.id;
    // code.sprite = sprite;

    sprite.createdTime = Date.now();

    sprite.bounds = code.bounds;

    self.sprites.push(sprite)
    */

  }

  draw() {
    var self = this;
    var now = Date.now();

    const timeDelta = now - this.lastUpdated;
    var secElapsed = timeDelta / 1000;
    if (secElapsed < 0) secElapsed = 0;
    this.lastUpdated = now;

    if (!this?.context) {
      console.log(">o<")
      //  window.requestAnimationFrame(spriteEngine.draw);
      return;
    }

    var ctx = this.context;
    ctx.clearRect(0, 0, this.width, this.height); // clear canvas

    this.sprites.forEach(function (sprite) {

      ctx.save();

      if (sprite.angle) {
        ctx.translate(sprite.x+(sprite.scalex / 2), sprite.y+(sprite.scaley / 2));
        var radians = sprite.angle * Math.PI / 180;
        ctx.rotate(radians);
        ctx.translate(-(sprite.x+(sprite.scalex / 2)), -(sprite.y+(sprite.scaley / 2)));
      }

      if (sprite.dataStr && sprite.dataStr.length > 0) {
        ctx.font = "60px serif";
        ctx.fillStyle = "blue";
        ctx.fillText(sprite.dataStr, sprite.x, sprite.y);
        //    console.log("xy",sprite.dataStr, sprite.x)
      }

      if (sprite.image) {

        ctx.drawImage(sprite.image, sprite.x, sprite.y, sprite.scalex, sprite.scaley);

      }
      if (sprite.type == 'Ball') {
        ctx.beginPath();
        ctx.arc(sprite.x, sprite.y, sprite.radius, 0, sprite.mathPi2, true);
        ctx.closePath();
        ctx.fillStyle = sprite.color;
        ctx.fill();
        self.sprites.forEach(function (spritecheck) {
          if (sprite != spritecheck && sprite.vx!=0 && sprite.vy!=0)
            if (checkCircleCollision(sprite, spritecheck)) {
          /*    sprite.vx = 0;
              sprite.vy = 0;
              spritecheck.vx = 0;
              spritecheck.vy = 0; */
              sprite.vx = -sprite.vx;
              sprite.vy = -sprite.vy;
              spritecheck.vx = -spritecheck.vx;
              spritecheck.vy = -spritecheck.vy;
            }
        })
      }
      ctx.restore();

      if (sprite.spin!=0) { // Apply Spin
        if (secElapsed) {
            sprite.angle += (sprite.spin * secElapsed); 
         //   console.log(".")
        }
        sprite.spin *= sprite.friction;
      }


      // LET'S MOVE THE SPRITE
      // console.log(sprite.vx,secElapsed,sprite.vx*secElapsed)

      if (sprite.moveTime != 0) {  // Stop moving after Ms
        if (now-sprite.createdTime > sprite.moveTime) {
          sprite.vx = 0;
          sprite.vy = 0; // Stop now
        }


      }

      //Apply Friction
      sprite.vx *= sprite.friction;
      sprite.vy *= sprite.friction;
      if (Math.abs(sprite.vx) < 0.1) sprite.vx = 0;
      if (Math.abs(sprite.vy) < 0.1) sprite.vy = 0;

      if (secElapsed) {
        if (sprite.vx != 0)
          sprite.x += (sprite.vx * secElapsed);
        if (sprite.vy != 0)
          sprite.y += (sprite.vy * secElapsed);
      }

      if (sprite.bounds) {

        if (sprite.y > self.height || sprite.y < 0) {
          sprite.vy = -sprite.vy;
        }
        if (sprite.x > self.width || sprite.x < 0) {
          sprite.vx = -sprite.vx;
        }
      }

    })

    this.frame = window.requestAnimationFrame(() => this.draw());


  }

  static displayName = "Sprite";
  static distance(a, b) {
    const dx = a.x - b.x;
    const dy = a.y - b.y;

    return Math.hypot(dx, dy);
  }
}






@Injectable({
  providedIn: 'root'
})
export class SpriteService {

  colorOptions: string[];
  objectList: any[] = [];
  spriteEngines: SpriteEngine[];

  spriteNamer = 1;


  constructor(public global: Globals) {
    this.spriteEngines = [];
  }


  newSpriteEngine(canvas: HTMLCanvasElement, width, height) {
    console.log("Creating new sprite engine: ",this.spriteEngines)
    this.spriteEngines.push(new SpriteEngine(canvas, width, height))
    return this.spriteEngines.length - 1;

  }

  setObjectFieldValue(id, field, result) {
    var self = this;

    self.spriteEngines.forEach(function (se) {
      se.sprites.forEach(function (sprite) {
        if (sprite.id == id) {
          console.log("Update sprite field ", field, result)
          switch (field) {
            case 'xPos': sprite.x = result; break;
            case 'yPos': sprite.y = result; break;
            case 'vx': sprite.vx = result; break;
            case 'vy': sprite.vy = result; break;
            case 'friction': sprite.friction = result; break;
            case 'spin': sprite.spin = result; break;
            case 'moveTime': sprite.moveTime = result; break;
          }
        }
      })
    })
    return null;

  }

  getObjectFieldValue(id, field): any {
    var self = this;

    var value = null;

    console.log("Sprite field lookup ", id, field, self.spriteEngines);

    self.spriteEngines.forEach(function (se) {
      se.sprites.forEach(function (sprite) {
        if (sprite.id == id) {
          console.log("get sprite field ", field, sprite.x);
          switch (field) {
            case 'xPos': value = sprite.x; break;
            case 'yPos': value = sprite.y; break;
            case 'vx': value = sprite.vx; break;
            case 'vy': value = sprite.vy; break;
            case 'friction': value = sprite.friction; break;
            case 'spin': value = sprite.spin; break;
            case 'moveTime': value = sprite.moveTime; break;
          }
        }
      })
    })
    return value;

  }

  getSpriteNames() {
    var names = [];
    this.spriteEngines[0].sprites.forEach(function (sprite) {
      names.push(sprite.id)
    })
    return names;
  }



}
function createUID(): string {
  var uid = "";

  // For now just 3 1000 randoms + time;

  var a = Math.floor(Math.random() * 1000).toString();
  var b = Math.floor(Math.random() * 1000).toString();
  var c = Math.floor(Math.random() * 1000).toString();
  var d = Date.now().toString()
  console.log("D ", d)
  uid = a + b + c + d.substring(7, 3)

  return uid;
}

function checkCircleCollision(c1:Sprite, c2:Sprite)
{
  var a = c1.x - c2.x;
  var b = c1.y - c2.y;
  var c = (a * a) + (b * b);
  var radii = c1.radius + c2.radius;
  return radii * radii >= c; 
}