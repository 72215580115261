<div class="layout" fxFlex fxLayout="column" fxLayoutAlign="center center" *ngIf="global.myCompany.stripeAccountID">
    <div *ngIf="!global.authuser">
        <SignIn startText="Login or Register" (setContactEvent)="setContact($event)" (afterLoginEvent)="afterLogin()">
        </SignIn>
    </div>


    <div *ngIf="global.paymentSuccess" fxFlex fxLayout="column" fxLayoutAlign="center center">

        <span style="font-size: 28px; font-weight: 700; color:blue">Thank you for your payment!</span>

        <span style="line-height: 95%; font-size: 28px; font-weight: 700; color:rgb(118, 165, 0); text-align: center;">
            Scan below to leave us feedback.<br>
            Your server will earn an extra $5 on us.<br><br></span>

        <br>
        <hr>

    </div>




    <hr>

    <div *ngIf="discounts()" fxLayout="row" fxLayoutAlign="flex end center" class="order-detail">

        <div
            class="flex align-items-center justify-content-end white-space-nowrap flex-grow-1 text-overflow-clip w-10 t">
            Order discounts:
        </div>
        <div class="flex align-items-center justify-content-end w-2 t">
            {{getDiscounts()}}
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="flex end center" class="order-detail">


        <div>
            Order subtotal:   {{"   "+getSubTotal()}}
        </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="flex end center" class="order-detail">
        <div>
            Sales tax (CA 7.75%):   {{"   "+(salesTax | currency)}}
        </div>
    </div>


    <div style="margin-top:24px; width: 100%;" fxLayout="row" fxLayoutAlign="center center" class="order-detail">


        <br>
        <div *ngIf="this.global.tableNameQR" fxLayout="column" fxLayoutAlign="center center">
            <span style="margin-bottom: 16px;">Tipping Options</span>
            <p-selectButton [options]="tipOptions" (onChange)="updateTip(index)" [(ngModel)]="tipValue"
                optionLabel="label" optionValue="index">
            </p-selectButton>
            <br>
        </div>
    </div>
    <div *ngIf="this.global.tableNameQR" class="flex justify-content-end flex-wrap mt-3 mb-3 font-bold">
        <div *ngIf="tipValue==8; else val">
            <p-inputNumber styleClass="input-styling" [(ngModel)]="customTip" (onInput)="getTotal()"
                inputId="currency-us" mode="currency" size="5" maxFractionDigits="0" currency="USD" locale="en-US">
            </p-inputNumber>
        </div>
        <ng-template #val>
            {{tipAmount | currency}}
        </ng-template>

    </div>

    <div class="flex justify-content-end flex-wrap mt-3 mb-3 font-bold">

        <div class="flex align-items-center justify-content-end t">

            Total to be charged:
        </div>
        <div class="flex align-items-center justify-content-end t ml-3">
            {{theTotal | currency}}
        </div>

    </div>

    <div [formGroup]="paymentElementForm" style="width:100%">
        <div fxLayout="row wrap" fxLayoutAlign="center space-around">
            <input class="data-input" type="text" pInputText formControlName="name" />
            <input class="data-input" placeholder="Email" type="email" pInputText formControlName="email" />
            <input *ngIf="shipping" class="data-input" placeholder="Address" type="text" pInputText formControlName="address" />
        <!--    <input class="zip-input" type="number" placeholder="zipcode" formControlName="zipcode"/>  -->

        </div>
        <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
            <ngx-stripe-payment [clientSecret]="clientSecret"
            [options]="cardOptions">
            </ngx-stripe-payment>
        </ng-container>
        <button (click)="pay()">PAY</button>
    </div>


    <button *ngIf="viewPayButton &&  myToken && !paying" pRipple class="pay-button" (click)="pay()">Complete
        Purchase
        <!--   {{" " + theTotal|currency}} -->
    </button>
    <div *ngIf="myToken && paying" class="pay-button">
        Stripe Payment Processing...
    </div>

    <div *ngIf="redeemCount()" style="font-size: 1em; line-height: 1.2em;">
        {{redeemTotal+" "}}Perk<span *ngIf="redeemCount()>1">s</span> available<br>
        Redeem perks after payment.</div>


</div>
<div *ngIf="!global.myCompany?.stripeAccountID">
    Please Link Stripe Account In Admin > Profile
</div>

<button *ngIf="0" class="pay-button" (click)="createCustomer()">Create Customer</button>