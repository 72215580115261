<div class="review__content" fxLayout="column" fxLayoutAlign="center center">


    <div style="width:100%; min-width:150px;" fxLayout="row wrap" fxLayoutAlign="space-between space-between">
        <div class="review__rating">{{p.review_rating}} stars

        </div>
        <div class="review__date"> {{p.dateStr}}</div>
        <div class="review__subtitle">{{p.author_name}}</div>
    </div>

    <div *ngIf="p.media" fxLayout="row wrap" fxLayoutAlign="center center">
        <div *ngFor="let m of p.media; let ind = index">
            <div
                *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' || m.fileType=='video/quicktime')">
                <video class="media" playsinline webkit-playsinline autoplay [muted]="'muted'">
                    <source src={{m.url}}>
                </video>
            </div>

            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/png' || m.fileType=='photo'">
                <img style="max-height: 400px; max-width: 100%;" src={{m.smallUrl}} />
            </div>
        </div>
    </div>

    <div *ngIf="!p.media && p.review_photos" fxLayout="row wrap" fxLayoutAlign="center center">
        <div *ngFor="let m of p.review_photos; let ind = index">
            <div *ngIf="m.fileType && (m.fileType=='non-image' || m.fileType=='video/quicktime')">
                <video class="media" playsinline webkit-playsinline autoplay [muted]="'muted'">
                    <source src={{m}} type="video/quicktime,video/mp4,video/mov">
                </video>
            </div>

            <div
                *ngIf="m.fileType && (m.fileType=='video' || m.fileType=='video/mp4' || m.fileType=='video/quicktime')">
                <video class="media" playsinline webkit-playsinline autoplay [muted]="'muted'">
                    <source src={{m}} type="video/quicktime,video/mp4,video/mov">
                </video>
            </div>

            <div *ngIf="!m.fileType || m.fileType=='image/jpeg' || m.fileType=='image/png' || m.fileType=='photo'">
                <img style="max-height:400px; max-width: 100%;" src={{m}} />
            </div>
        </div>
    </div>

    <div>
        <div class="review__desc" style='user-select: text' (onselect)="autoTag($event)">
            <span *ngIf="p.style=='quote';else defaultStyle">
                <blockquote>
                    <div [innerHtml]="reviewService.getFilteredText(p, tags)"></div>
                </blockquote>
            </span>
            <ng-template #defaultStyle>
                <div [innerHtml]="reviewService.getFilteredText(p,tags)"></div>

            </ng-template>

        </div>
    </div>

    <div style="width:100%"  fxFlex fxLayout="column">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div *ngIf="p.source=='Yelp'">Yelp review</div>
            <div *ngIf="p.source=='Google'">Google review</div>
            <div *ngIf="p.source=='Napkin'">Napkin review</div>
            <div *ngIf="p.source=='TripAdvisor'">TripAdvisor review</div>
            <div *ngIf="p.source=='Trip Advisor'">TripAdvisor review</div>
            <div *ngIf="p.source=='Harvest Hosts'">Harvest Hosts review</div>

            <div>
            <button class="useful" (click)="useful(p)">Useful
                <span *ngIf="p.useful && p.useful>4"> {{p.useful}}</span>
                <span *ngIf="p.useful && p.useful<5"><i class="pi pi-check" style="font-size: .8rem"></i></span>
            </button>
        </div>
      
        </div>

    </div>
</div>