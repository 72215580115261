<div *ngIf="apiFunction=='My Settings'">
    <div *ngIf="pwSuccess">Update Successful</div>
    <input *ngIf="passwordChange" class="pw" placeholder="4 character min." type="password" pInputText
        [(ngModel)]="password">
    <div *ngIf="!passwordChange" class="change-pw" pRipple (click)="changePassword()">Change Password</div>
    <div *ngIf="passwordChange" class="change-pw" pRipple (click)="updatePassword()">Update Password</div>

    <button class="button-14" style="margin:12px;" role="button" (click)="logMeOut()">Log Out</button>
    <br>
    <button *ngIf="0" class="button-14" style="margin:12px; font-size:.7rem" role="button"
        (click)="RemoveMyAccount()">Remove Account</button>

    <app-profile></app-profile>

</div>

<div *ngIf="apiFunction=='My Contacts'">
    <app-appMaker userApp="Contacts" [headLess]="true"></app-appMaker> 
    <button *ngIf="global.authuser && global.authuser.uid == 'kvT6eKFxFHb3EKbVeCTusW86CrZ2'" class="button-14"
        style="margin:12px; font-size:.7rem" role="button" (click)="copyMasterApps()">Copy Contacts APP</button>

</div>

<div *ngIf="apiFunction=='Badges'">
    <div *ngFor="let badge of global.authuser.badges">
        <div class="badge-text">{{badge}}</div>

    </div>
    <app-maps></app-maps>
</div>

<div *ngIf="apiFunction=='Channels'">
    <app-channels></app-channels>
</div>

<div *ngIf="apiFunction=='Reservations'">
    <app-reserve-beta [showHeader]="false" [openOnly]="true" [getAllRes]="true">
    </app-reserve-beta>
</div>



<div *ngIf="apiFunction=='Quotes'">
    <app-quotes [userId]="global.authuser.uid"></app-quotes>
</div>


<div *ngIf="apiFunction=='Chat Bots'">
    <app-chatbots [userId]="global.authuser.uid"></app-chatbots>
</div>

<div *ngIf="apiFunction=='Chats'">
    <app-chat [userChat]="true"></app-chat>
</div>
<div *ngIf="apiFunction=='Media'">

    <app-media [userId]="global.authuser.uid"></app-media>
</div>

<div *ngIf="apiFunction=='Favorite Places'">
<!-- Move to another place
    <app-apps></app-apps>
    -->

    {{setActiveStore()}}
    <div *ngIf="global?.authuser?.following && global.authuser.following.length>0">
        <app-container [settings]="followSettings" [follows]="global.authuser.following" [api]="true"
            [style]="style"></app-container>
    </div>
    <!--
    Recommended stores to follow:
    <div *ngFor="let store of global.allStores">
        {{store.name}}
    </div>
-->
</div>
<div *ngIf="apiFunction=='Weather'">
    <app-weather location=""></app-weather>

</div>
<div *ngIf="apiFunction=='News'">
    <app-news [editing]="true"></app-news>
</div>

<div *ngIf="apiFunction=='Search'">

    <app-search></app-search>
</div>
<div *ngIf="apiFunction=='My Orders'">
    <app-myorders [all]="true"></app-myorders>
</div>


<div *ngIf="apiFunction=='Games'">
    <app-trivia></app-trivia>
</div>

<div *ngIf="global.authuser.creds && apiFunction=='My Stores'">
    <app-app-list></app-app-list>
</div>



<div *ngIf="apiFunction=='Places'">
    <app-places [store]="store"></app-places>

</div>


<div *ngIf="apiFunction=='Perks'">
    <app-container [settings]="perkSettings" [store]="store" [list]="perkTabs" [api]="true" [style]="style"
        [store]="store" (openTab)="openTab($event)"></app-container>
</div>

<div *ngIf="apiFunction=='My Perks'">
    <div class="container">
        <div fxLayout="column" fxLayoutAlign="center none" style="width:100%; font-size:130%; text-align: center;">
            <div *ngIf="perks" fxLayout="row wrap" fxLayoutAlign="space-around space-around">

                <div *ngFor="let item of perks; let i = index;" class="card">

                    <div class="main">
                        <div *ngIf="global.myCompany.logoImg" class="co-img" style="height:100px; ">
                            <img class="logo-image" src={{global.myCompany.logoImg}} alt="Photo of" (click)="goHome()">
                        </div>
                        <div class="vertical"></div>
                        <div class="content">

                            <h1>{{item.name}}</h1>
                            <p>Valid while supplies last</p>
                        </div>
                    </div>
                    <div class="copy-button">
                        <!--    <input id="copyvalue" type="text" readonly value="GOFREE" />
                        <button (click)="copyIt()" class="copybtn">COPY</button>
                        -->
                        <button *ngIf="0" pRipple class="perk-button" label="Email Perk" (click)="emailPerk(item)">Email
                            Perk</button>
                        <button pRipple class="perk-button" label="Show Redemption" (click)="showQR(item)">Show
                            Redemption</button>

                        <button class="perk-button" pRipple (click)="returnPoints(item)">Return Perk to Points</button>
                    </div>

                </div>
            </div>
        </div>
    </div>


</div>

<div *ngIf="apiFunction=='Available Perks'">
    Show me all Perks
</div>

<div *ngIf="apiFunction=='Used Perks'">
    Show me all Perk I used already
</div>

<div *ngIf="apiFunction=='All Stores' && global.allStores">
    <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false"
        *ngFor="let store of global.allStores; let i = index;">
        <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="none center">
            <p-accordionTab iconPos="end" style="width:100%;">
                <ng-template pTemplate="header">
                    <div class="pretty-data" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        {{store.name}}

                    </div>
                </ng-template>

                <ng-template pTemplate="content">


                    <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="space-around space-around">
                        <button class="button-14" role="button" (click)="GotoReserve(store.store)">Reserve</button>

                        <button *ngIf="global.authuser" class="button-14" role="button"
                            (click)="follow(store)">Follow</button>
                        <button *ngIf="store.enabled && store.enabled.products" class="button-14" role="button"
                            (click)="goMenu(store.store)">See Menu</button>

                        <div *ngIf="global.authuser && global.authuser.uid == 'kvT6eKFxFHb3EKbVeCTusW86CrZ2'">

                            <button class="button-14" role="button" (click)="RemoveStore(store)">Remove
                                Store
                                from
                                Database</button>

                        </div>

                    </div>

                </ng-template>
            </p-accordionTab>
        </div>

    </p-accordion>
</div>

<div *ngIf="apiFunction=='Company Info'">

</div>

<div *ngIf="apiFunction=='Google Business Info'">

    <div *ngIf="!global.myCompany.hasGoogleInfo">
        <button (click)="registerService.FetchGoogleInfo()" class="function" pButton pRipple type="button">
            Get Google Info
        </button>
    </div>
    <div *ngIf="global.myCompany.hasGoogleInfo">

        <div style="width:100%" fxFlex fxLayout="row wrap" fxLayoutAlign="space-between space-between">


            <div class="info"> name: {{global.myCompany.googleBusinessInfo.name}}</div>
            <div class="info">full_address: {{global.myCompany.googleBusinessInfo.full_address}}</div>
            <div class="info">address: {{global.myCompany.googleBusinessInfo.address}}</div>
            <div class="info">order_link: {{global.myCompany.googleBusinessInfo.order_link}}</div>
            <div class="info">price_level: {{global.myCompany.googleBusinessInfo.price_level}}</div>
            <div class="info">district: {{global.myCompany.googleBusinessInfo.district}}</div>
            <div class="info">street_address: {{global.myCompany.googleBusinessInfo.street_address}}
            </div>
            <div class="info">city: {{global.myCompany.googleBusinessInfo.city}}</div>
            <div class="info">zipcode: {{global.myCompany.googleBusinessInfo.zipcode}}</div>
            <div class="info">state: {{global.myCompany.googleBusinessInfo.state}}</div>
            <div class="info">country: {{global.myCompany.googleBusinessInfo.country}}</div>
            <div class="info">review_count: {{global.myCompany.googleBusinessInfo.review_count}}</div>
            <div class="info">rating: {{global.myCompany.googleBusinessInfo.rating}}</div>
            <div class="info">timezone: {{global.myCompany.googleBusinessInfo.timezone}}</div>
            <div class="info">website: {{global.myCompany.googleBusinessInfo.website}}</div>
            <div class="info">verified: {{global.myCompany.googleBusinessInfo.verified}}</div>


            <div (click)="updateHoursDays()" class="info">Sunday
                {{global.myCompany.googleBusinessInfo.working_hours.Sunday[0]}}</div>
            <div class="info">Monday {{global.myCompany.googleBusinessInfo.working_hours.Monday[0]}}
            </div>
            <div class="info">Tuesday {{global.myCompany.googleBusinessInfo.working_hours.Tuesday[0]}}
            </div>
            <div class="info">Wednesday
                {{global.myCompany.googleBusinessInfo.working_hours.Wednesday[0]}}
            </div>
            <div class="info">Thursday {{global.myCompany.googleBusinessInfo.working_hours.Thursday[0]}}
            </div>
            <div class="info">Friday {{global.myCompany.googleBusinessInfo.working_hours.Friday[0]}}
            </div>
            <div class="info">Saturday {{global.myCompany.googleBusinessInfo.working_hours.Saturday[0]}}
            </div>


            <div class="info">Place link: {{global.myCompany.googleBusinessInfo.place_link}}</div>
            <div class="info">Owner link: {{global.myCompany.googleBusinessInfo.owner_link}}</div>
            <div class="info">Owner name: {{global.myCompany.googleBusinessInfo.owner_name}}</div>
            <div class="info">Reservations link:
                {{global.myCompany.googleBusinessInfo.reservations_link}}
            </div>
            <div class="info">Business status: {{global.myCompany.googleBusinessInfo.business_status}}
            </div>
            <div class="info">Booking link: {{global.myCompany.googleBusinessInfo.booking_link}}</div>
            <div class="info">Phone number: {{global.myCompany.googleBusinessInfo.phone_number}}</div>
            <div class="info">Rating: {{global.myCompany.googleBusinessInfo.rating}}</div>
            <div class="info">Review count: {{global.myCompany.googleBusinessInfo.review_count}}</div>

        </div>
    </div>
</div>

<div *ngIf="apiFunction=='Days & Hours'">


</div>

<div *ngIf="apiFunction=='Departments'">

    <div fxFlex fxLayout="column" fxLayoutAlign="center center">

        <div fxFlex fxLayout="row" fxLayoutAlign="center center">
            <input type="text" pInputText [(ngModel)]="newDept">
            <div class="command" style="width:100%; max-width:120px;" (click)="Clicked($event.target.innerHTML)">Add
                Department</div>
        </div>
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="space-between space-between">
            <div *ngFor="let t of global.myCompany.departments; let i = index;">
                <div class="command">{{t}}</div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="apiFunction=='Manage Staff'">

    <div *ngIf="global.myCompany.enabled.manageStaff">
        <app-managestaff></app-managestaff>
    </div>
</div>

