<div style="width:100%; height: max-content" fxFlex fxLayout="column" fxLayoutAlign="center start">


    <button *ngIf="!global.editField && !addingField" (click)="addField()" class="create-form" pButton pRipple
        style="text-align: left;" type="button">
        Add Fields to this Form
    </button>

    <button *ngIf="!global.editField && addingField" (click)="closeAddField()" class="function" style="padding:8px"
        pButton pRipple type="button">
        Close
    </button>
    <div *ngIf="!global.editField && addingField" fxLayout="row wrap" fxLayoutAlign="space-between space-between">
        <div *ngFor="let newfield of global.allFields; let h = index">


            <div *ngIf="newfield.newLine && newfield?.type!='newLine'">
                <div
                    style="color: red; text-align: center; width:100vw;background-color: rgb(47, 46, 46); height:2px; margin-top:8px; margin-bottom: 16px;">
                    <div *ngIf="newfield.label">{{newfield.label}}</div>

                </div>
            </div>

            <div *ngIf="!newfield.newLine || newfield?.type=='newLine'" class="field-list"
                (click)="addFieldToForm($event, form, newfield)">
                {{newfield.title}}</div>
        </div>
    </div>

    <div style="width: 100%; text-align: center;" fxLayout="column" fxLayoutAlign="center center">
        <div *ngIf="!global.editField" fxFlex fxLayout="row wrap" fxLayoutAlign="space-between space-between">
            <div
                style="width: 100%; background-color: aqua; text-align: center; height: 1.4em; font-size: 1.1em; margin-bottom: 8px;">
                Fields
            </div>
            <div *ngFor="let field of global.allForms[formIndex].fields; let i = index" style="margin:8px;">
                <div fxLayoutAlign="none none">
                    <span *ngIf="i">
                        <button pRipple class="field-button-left" (click)="moveLeft(field,i)">Left</button>
                    </span>
                    <button pRipple class="field-button" (click)="fieldSelected(field)">{{field.title}}</button>

                    <span *ngIf="i < global.allForms[formIndex].fields.length-1">
                        <button pRipple class="field-button-right" (click)="moveRight(field, i)">Right</button>
                    </span>


                </div>
            </div>
        </div>

        <div *ngIf="global.editField">

            <app-field-update [formEditing]="form" [formIndex]="formIndex" [editId]="global.editField.id"
                [field]="global.editField" [form]="form"></app-field-update>
        </div>
        <div *ngIf="global.editField">
            <button pRipple class="show" (click)="done()">Done</button>
        </div>
    </div>

</div>