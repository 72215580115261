<!-- REWARDS PROGRAM 
Must have a User: Validated??: Auto-register from email, name
-->

<div *ngIf="global.viewMode==2">
    <!--
<img src="https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FBadge-ClubMember-01.png?alt=media&token=7c53a7ea-a004-493e-b8fc-601dbae3f34b" alt="Photo of">
-->
    <div class="max-w-full flex flex flex-row justify-content-center">
        <div
            class="surface-50 shadow-3 border-x-3 border-50 md:w-screen sm:w-screen lg:w-6 xl:w-6 line-height-4 pb-3 flex flex-column">
            <h2>Total Rewards Points: {{global.myRewardPoints | number:'1.0-0'}}</h2>
            <div *ngIf="global.redeem.length">
                <div class="card">
                    <p-dataView #dv [value]="global.redeem" [paginator]="true" [rows]="10"
                        filterBy="allTags,title,fullDescription" [sortField]="sortField" [sortOrder]="sortOrder"
                        layout="list">
                        <ng-template pTemplate="header">
                            <div class="p-col-12 flex-auto flex-row justify-content-between">
                                <!--        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>  -->

                                <p-dropdown [options]="sortOptions" [(ngModel)]="sortKey" placeholder="Sort By Price"
                                    (onChange)="onSortChange($event)" styleClass="mb-2 md:mb-0">
                                </p-dropdown>

                                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                                    <i class="pi pi-search"></i>
                                    <input type="search" pInputText placeholder="Search by Name"
                                        (input)="dv.filter($event.target.value, 'contains')">
                                </span>

                            </div>
                        </ng-template>
                        <ng-template let-item pTemplate="listItem">
                            <div class="p-col-12" style="width:100%; margin-bottom:2px;">
                                <div class="product-list-item" [style.background-color]="myProductColor(item)">

                                    <img *ngIf="item.image1" src={{item.image1}} alt="Photo of">

                                    <div style="width:100%" fxLayout="column" fxLayoutAlign="space-between" flexGrow>
                                        <div class="product-list-detail">
                                            <div class="product-name">{{item.title}}</div>
                                            <div class="product-description">{{item.fullDescription}}</div>
                                            <i class="pi pi-tag product-category-icon"></i><span
                                                class="product-category">{{item.category}}</span>
                                        </div>

                                        <div *ngIf="item.allOptions" fxLayout="row wrap"
                                            fxLayoutAlign="space-around center" class="product-options">
                                            <div *ngFor="let thisOption of item.allOptions; let n = index;">

                                                <mat-form-field appearance="fill" class="small-button2">
                                                    <mat-label>{{thisOption.title}}</mat-label>

                                                    <div [ngSwitch]="thisOption.type">
                                                        <div *ngSwitchCase="3">
                        
                                                        </div>

                                                        <!-- . . . -->
                                                        <div *ngSwitchDefault>
                       


                                                        </div>
                                                    </div>

                                                </mat-form-field>
                                            </div>

                                        </div>
                                    </div>



                                    <div class="product-list-action">
                                        <span class="product-price">${{item.retail}}</span>
                                        <p-button icon="pi pi-shopping-cart" label="Add to Cart"
                                            [disabled]="global.redeem.trackInventory === 'OUTOFSTOCK'"
                                            (click)="addRewardItem(item)">
                                        </p-button>
                                        <div *ngIf="global.authuser">
                                            <div *ngIf="global.authuser.creds">
                                                <div *ngIf="global.authuser.creds[0].id==global.myCompany.id">
                                                    <p-button label="Edit" (click)="editMe(item)"></p-button>

                                                </div>

                                            </div>
                                        </div>
                                        <span [class]="'product-badge status-'">{{item.dept}}</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                        <!--

                <ng-template let-item pTemplate="gridItem">
                    <div class="p-col-12 p-md-4">

                        <div class="product-grid-item card">
                            <div class="product-grid-item-top">
                                <div class="product-grid-item">
                                    <img *ngIf="item.image1" src={{item.image1}} alt="Photo of">

                                </div>
                                <div>
                                    <i class="pi pi-tag product-category-icon"></i>
                                    <span class="product-category">{{item.category}}</span>
                                </div>
                                <span [class]="'product-badge status-'">{{item.dept}}</span>
                            </div>
                            <div class="product-grid-item-content">
                                <div class="product-name">{{item.title}}</div>
                                <div class="product-description">{{item.fullDescription}}</div>

                            </div>
                            <div class="product-grid-item-bottom">
                                <span class="product-price">${{item.retail}}</span>
                                <p-button icon="pi pi-shopping-cart" [disabled]="red.dept === 'OUTOFSTOCK'"
                                    (click)="addRewardItem(item)"></p-button>
                            </div>

                        </div>

                    </div>
                </ng-template>
-->

                    </p-dataView>
                </div>
            </div>
        </div>
    </div>