import { NgModule } from '@angular/core' ;
import { BrowserModule } from '@angular/platform-browser';
//import { ColorPickerModule } from 'ngx-color-picker';
//import { ColorPickerModule } from '@iplab/ngx-color-picker';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
//import { NgAuthService } from "./ng-auth.service";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//import { ProductsComponent } from './components/products/products.component';
import { OrdersComponent } from './components/orders/orders.component';
import { POSComponent } from './components/pos/pos.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { StoreSignUpComponent } from './components/store-sign-up/store-sign-up.component';
import { NgxColorsModule } from 'ngx-colors';

//import { GoogleMapsModule } from '@angular/google-maps';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';

//import { MyOrdersComponent } from './components/menu/my-orders/my-orders.component';
import { MyPointsComponent } from './components/menu/my-points/my-points.component';
import { StickyHeaderDirective } from './components/sticky-header.directive';
//import { CheckoutComponent } from './components/menu/checkout/checkout.component';
//import { CheckoutSuccessComponent } from './components/menu/checkout/checkout-success.component';

import { NgxStripeModule } from 'ngx-stripe';
import { CartService, SharedDataService } from './components/menu/cart.service';
import { ButtonModule } from 'primeng/button';
import { SelectButtonModule } from "primeng/selectbutton";
import { ToggleButtonModule } from 'primeng/togglebutton';

import { AccordionModule } from 'primeng/accordion';
import { ToastModule } from 'primeng/toast';
import { PayComponent } from './components/pos/pay/pay.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessageService } from 'primeng/api'
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { RatingModule } from 'primeng/rating';
import { RippleModule } from 'primeng/ripple';
import { VerifyComponent } from './components/verify/verify.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CartViewComponent } from './components/menu/cart-view.component';
import { signInComponent } from './components/reserve/sign-in.component';

import {SidebarModule} from 'primeng/sidebar';
import { CommonModule } from '@angular/common';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import { CustomizeMessageService } from './components/customize-message.service';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { editProduct } from './components/menu/editProduct';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ChipsModule} from 'primeng/chips';
import { ViewProductsComponent } from './components/menu/view-products/view-products.component';
import { ChipModule } from 'primeng/chip';
import { PanelModule } from 'primeng/panel';
import { ReserveComponentBeta } from './components/menu/reserve/reserve-beta.component'
import { CurrencyPipe } from '@angular/common'

import { CalendarModule } from 'primeng/calendar';
import {InputMaskModule} from 'primeng/inputmask';
import { RegisterService } from './components/register.service';
import { MediaService } from './components/media/media-functions/media-functions';
import { FormService } from './components/form.service';
import { VIPService } from './components/VIP.service';
import { EmailService } from './components/email.service';
import { NodeService } from './components/node.service';
import { IpService } from './GetIP.service';
import { CardModule } from 'primeng/card';
import {MultiSelectModule} from 'primeng/multiselect';
import {PasswordModule} from 'primeng/password';
import {InplaceModule} from 'primeng/inplace';
import { SettingsComponent } from './components/settings/settings.component';
import { FrontComponent } from './components/front/front.component';
import { HomeComponent } from './components/home/home.component';
import {addTableComponent} from './components/menu/reserve/addTable.component'
import {SliderModule} from 'primeng/slider'
import {GalleriaModule} from 'primeng/galleria';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TableModule} from 'primeng/table';
import { TriviaComponent } from './components/trivia/trivia.component';
import {OverlayPanelModule} from 'primeng/overlaypanel';
//import { QRCodeModule } from 'angularx-qrcode';
import { StatsComponent } from './components/stats/stats.component';
import { GroupsComponent } from './components/menu/reserve/groups/groups.component';
import { ResRewardsComponent } from './components/menu/reserve/res-rewards/res-rewards.component';
//import { NgWhiteboardModule } from 'ng-whiteboard';
//import { KonvaModule } from "ng2-konva";

import { DrawComponent } from './components/draw/draw.component';
import { EditProductComponent } from './components/edit-product/edit-product.component';
import {DragDropModule} from 'primeng/dragdrop';
import { SearchComponent } from './components/search/search.component';
import { WeatherComponent } from './components/weather/weather.component';
import { ChatComponent } from './components/chat/chat.component';
import {BadgeModule} from 'primeng/badge';
import { TagInputModule } from 'ngx-chips';
import { ReviewsComponent } from './components/reviews/reviews.component';
//import { AgGridModule } from 'ag-grid-angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ReviewComponent } from './components/review/review.component';
//import {ImagekitioAngularModule} from 'imagekitio-angular';
import { MediaComponent } from './components/media/media.component';
import { MediaGroupsComponent } from './components/media/media-groups.component';
import {YourComponent1} from "./components/chat/chat.component"
import {YourComponent2} from "./components/chat/chat.component";
import { ChatnowComponent } from './components/chatnow/chatnow.component'
import { SafePipeModule } from 'safe-pipe'
import { ChatSettingsComponent } from './components/chatnow/settings/settings.component';
import { ReservesettingsComponent } from './components/menu/reserve/reservesettings/reservesettings.component';
import { ManagestaffComponent } from './components/settings/managestaff/managestaff.component';
import { FeaturesComponent } from './components/front/features/features.component';
import { LightgalleryModule } from 'lightgallery/angular';
import {  DisplayGrid,
  GridsterComponent,
  GridsterItemComponent,
} from 'angular-gridster2';
import {NgForOf} from '@angular/common';
import { ManageReviewsComponent } from './components/reviews/manage-reviews/manage-reviews.component';
import { GalleryComponent } from './components/media/gallery/gallery.component';
import { PayStripeComponent } from './components/pay-stripe/pay-stripe.component';
import { CustomersComponent } from './components/customers/customers.component';

import { NgxCsvParserModule } from 'ngx-csv-parser';
import { UnsubComponent } from './components/unsub/unsub.component';
import { SalesComponent } from './components/sales/sales.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ProductsComponent } from './components/products/products.component';
import { PlacesComponent } from './components/places/places.component';
import { ViewItemComponent } from './components/view-item/view-item.component';
import { JoinComponent } from './components/join/join.component';
import { QuotesComponent } from './components/quotes/quotes.component';
import { SelfpayComponent } from './components/navigate/selfpay/selfpay.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ChannelsComponent } from './components/channels/channels.component';
import { MyordersComponent } from './components/myorders/myorders.component';
import { AppMakerComponent } from './components/app-maker/app-maker.component';
import { EditorComponent } from './components/app-maker/editor/editor.component';
import { AboutUsComponent } from './components/app-maker/about-us/about-us.component';
import { FollowComponent } from './components/follow/follow.component';
import { FieldComponent } from './components/field/field.component';
import { FormComponent } from './components/form/form.component';
import { DataComponent } from './components/data/data.component';
import { MediaViewComponent } from './components/media-view/media-view.component';
import { ReviewCardComponent } from './components/reviews/review-card/review-card.component';
import { NodeviewComponent } from './components/app-maker/nodeview/nodeview.component';
import { AppListComponent } from './components/app-maker/app-list/app-list.component';
import { AiMakerComponent } from './components/app-maker/ai-maker/ai-maker.component';
import { DatabaseComponent } from './components/database/database.component';
import { ContainerComponent } from './components/container/container.component';
import { NodeObjectComponent } from './components/app-maker/node-object/node-object.component';
import { ApiComponent } from './components/api/api.component';
import { FormEditComponent } from './components/form-edit/form-edit.component';
import { FieldEditComponent } from './components/field-edit/field-edit.component';
import { FieldUpdateComponent } from './components/field-update/field-update.component';
import { FormSettingsComponent } from './components/form-settings/form-settings.component';
import { LogicEditorComponent } from './components/logic-editor/logic-editor.component';
import { LogicService } from './components/logic/logic.component';
import { CheckboxModule } from 'primeng/checkbox';
import { SpriteService } from './components/sprites.service';
import { PhaserComponent } from './components/phaser/phaser.component';
import { PhaserEditComponent } from './components/phaser-edit/phaser-edit.component';
import { QuoteStyleComponent } from './components/reviews/quote-style/quote-style.component';
import { DefaultStyleComponent } from './components/reviews/default-style/default-style.component';
import { ReviewSettingsComponent } from './components/reviews/review-settings/review-settings.component';
import { AppsComponent } from './components/apps/apps.component';
import { ChatbotComponent } from './components/chatbot/chatbot.component';
import { QueriesComponent } from './components/queries/queries.component';
import { SharingComponent } from './components/sharing/sharing.component';
import { MapsComponent } from './components/maps/maps.component';
import { NewsComponent } from './components/news/news.component';
import { CaptureComponent } from './components/capture/capture.component';
import { ChatbotsComponent } from './components/chatbots/chatbots.component';
import { ThemesComponent } from './components/themes/themes.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { SheetComponent } from './components/sheet/sheet.component';
import { SheetsComponent } from './components/sheets/sheets.component';
import { FieldSelectorComponent } from './components/field-selector/field-selector.component'
//import { settingsTool } from './components/phaser/toolbars.component';


@NgModule({
  declarations: [
    AppComponent,


    OrdersComponent,

    POSComponent,

  //  MyOrdersComponent,
    MyPointsComponent,

    StickyHeaderDirective,

    //CheckoutSuccessComponent,

    PayComponent,
    VerifyComponent,
    CartViewComponent,

    signInComponent,
    editProduct,
    ViewProductsComponent,
    ReserveComponentBeta,
    SettingsComponent,
    FrontComponent,
    HomeComponent,
    addTableComponent,
    TriviaComponent,
    StatsComponent,
    GroupsComponent,
    ResRewardsComponent,
    DrawComponent,
    EditProductComponent,
    SearchComponent,
    WeatherComponent,
    ChatComponent,
    YourComponent1,
    YourComponent2,
    ReviewsComponent,
    ReviewComponent,
    MediaComponent,
    MediaGroupsComponent,
    ChatnowComponent,
    ChatSettingsComponent,
    ReservesettingsComponent,
    ManagestaffComponent,
    FeaturesComponent,
    ManageReviewsComponent,
    GalleryComponent,
    PayStripeComponent,
    CustomersComponent,
    UnsubComponent,
    ReportsComponent,
    SalesComponent,
    ProductsComponent,
    PlacesComponent,
    ViewItemComponent,
    JoinComponent,
    QuotesComponent,
    SelfpayComponent,
    ProfileComponent,
    ChannelsComponent,
    MyordersComponent,
    AppMakerComponent,
    EditorComponent,
    AboutUsComponent,
    FollowComponent,
    FieldComponent,
    FormComponent,
    DataComponent,
    MediaViewComponent,
    ReviewCardComponent,
    NodeviewComponent,
    AppListComponent,
    AiMakerComponent,
    DatabaseComponent,
    ContainerComponent,
    NodeObjectComponent,
    ApiComponent,
    FormEditComponent,
    FieldEditComponent,
    FieldUpdateComponent,
    FormSettingsComponent,
    LogicEditorComponent,
    PhaserComponent,
    PhaserEditComponent,
    QuoteStyleComponent,
    DefaultStyleComponent,
    ReviewSettingsComponent,
    AppsComponent,
    ChatbotComponent,
    QueriesComponent,
    SharingComponent,
    MapsComponent,
    NewsComponent,
    CaptureComponent,
    ChatbotsComponent,
    ThemesComponent,
    CalendarComponent,
    SheetComponent,
    SheetsComponent,
    FieldSelectorComponent,

  ],
  imports: [
    BrowserModule,
    SafePipeModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxColorsModule,
		SliderModule,
    FlexLayoutModule,
  //  GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AccordionModule,
    PanelModule,
    ButtonModule,
    SelectButtonModule,
    ToggleButtonModule,
    ConfirmDialogModule,
    InputTextModule,
    InputNumberModule,
    MessagesModule,
    MessageModule,
    ToastModule,
    DataViewModule,
    CalendarModule,
    DialogModule,
    DropdownModule,
    RippleModule,
    DragDropModule,
    HttpClientModule,
    RatingModule,
    FormsModule,
    BreadcrumbModule,
    SidebarModule,
    CardModule,
    MultiSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    SidebarModule,
    CheckboxModule,
    ButtonModule,
    DynamicDialogModule,
    InputTextareaModule,
    InputSwitchModule,
    ChipsModule,
    ChipModule,
    InputMaskModule,
    PasswordModule,
    InplaceModule,
    GalleriaModule,
    ScrollPanelModule,
    TableModule,
    OverlayPanelModule,
  //  QRCodeModule,
    TagInputModule,
    EditorModule,
// TEST MODE NgxStripeModule.forRoot('pk_test_51KQwb3Q2rk2SmhdoWXsLXTumzO4H3vOgDUkoFDNloa9PSgyNpGSuhISKzu0jYwOMpDjBpimWiZ6A0nG1gOpNoael00dUQ0Lcyb'),

    // LIVE MODE      
     NgxStripeModule.forRoot('pk_live_TLvf44jPdna6QW2e6KwOUuSp00eK5Nrubt'),


//    NgxStripeModule.forRoot("pk_test_r92QM1u6KY5SDssmp9bHODCO00fxsBtiB0"),
//    NgxStripeModule.forChild("pk_test_r92QM1u6KY5SDssmp9bHODCO00fxsBtiB0"),

//    NgWhiteboardModule, 
//    KonvaModule,
    BadgeModule,
    LightgalleryModule,
    NgxCsvParserModule,
    GridsterComponent, GridsterItemComponent,NgForOf,

//    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), BrowserAnimationsModule
  ],
  providers: [CartService, SpriteService, SharedDataService, MessageService, CustomizeMessageService,RegisterService,
  MediaService, CurrencyPipe, FormService, VIPService, EmailService, NodeService, IpService,LogicService,],

  bootstrap: [AppComponent]
})
export class AppModule { }
