import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Field } from '../company-interface';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../user-interface';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { RegisterService } from '../register.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { FormService } from '../form.service';

import firebase from 'firebase/compat/app';
import 'firebase/storage';
import 'firebase/compat/storage';

import { doc, onSnapshot, DocumentReference } from "firebase/firestore";
import { collection, query, where, getDocs, Timestamp } from "firebase/firestore";
import { Howl, Howler } from 'howler';
import { Time } from '@angular/common';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { TagInputModule } from 'ngx-chips';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Company, Form, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';
import { Logger } from 'src/app/functions';
import { NodeService } from '../node.service';
import { FormsModule } from '@angular/forms';

export interface jVal {
  display: string;
  value: string;
}

const sound = new Howl({
  src: ['../../../assets/sound-newmsg.mp3']
});


@Component({
  selector: 'your-component-1',
  template: `

  <div *ngIf="!display" class="fixed-right">
  <p-button type="text" (click)="display = true" icon="pi pi-plus" label="Chat"></p-button>
  </div>

  <p-sidebar position="right" [(visible)]="display">      
  `,
})
export class YourComponent1 {
  display: boolean = true;

}


@Component({
  selector: 'your-component-2',
  template: `


   
  `,
})
export class YourComponent2 {

}

/* Paths: (no user)
 * UserChat:
 * Chat with Store: [ ] - load the store minimally
 * 
 * Sort: newest conversation on top, oldest on bottom.
 * Setting: Hide (other) Conversations on open 
 * 
 * 
 * 
 */






@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @Input() store: string = "";
  @Input() user: string = "";
  @Input() userChat: boolean = false;
  @Output() resultsEvent = new EventEmitter<Results[]>();
  @Output() infoEvent = new EventEmitter<Kpanel>();

  isFull: boolean = false;
  chatPos: string = "right";
  contact: Contact;
  groups: msg_Groups[];
  messages: Message[];
  currentGroupId: string;
  allowNewConnection: boolean = true;
  editChat: Message;
  videoSearch: string;
  videos: any[];
  info: Kpanel;
  result: Results[];
  stickyNotes: jVal[]; //
  groupList: jVal[] = [];
  groupListInput: string = "";
  activeIndex: number = -1;
  searchTabOpen: boolean = false;

  newMsg: Chat;
  index: Number;
  activeChat: boolean[];
  visible: boolean = false;
  liveChat: boolean = true;
  display: boolean = true;
  dataMode: string = "";

  showStore: boolean = false;

  pw: string;
  foundUser: boolean = false;
  inputCreds: boolean = false;
  inputCredsnoUser: boolean = false;
  loginError: boolean = false;
  userDataValid: boolean = false;
  newUserID: string;

  FirstName: string;
  LastName: string;
  Email: string;
  storeChat: boolean = false;

  /* List of available functions */
  viewFunctions: boolean = false;

  //  chatFunctions: string[] = ["Internet search", "map", "Video search", "Chat Bot",
  //    "Get Image", "Sticky Note", "New Napkin App", "Napkin app", "Spreadsheet"];

  storeChatFunctions: string[] = ["Get Image"];


  constructor(public global: Globals, public nav: NavService,
    public registerService: RegisterService,
    private nodeService: NodeService,
    private formService: FormService,
    private route: ActivatedRoute,
  ) {
    firebase.initializeApp(environment.firebase);
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER

  }

  ngOnInit(): void {
    // Check if user to load
    const heroId = this.route.snapshot.paramMap.get('store');
    console.log("xstore: ", heroId);
    if (heroId) {
      this.nav.GetStoreOnly(heroId, this.InitStore, this);
      this.storeChat = true;
      this.display = false;
    }
    else {
      if (!this.store && !this.user) {
        // 
        console.log("No store/No User");
        /*
                //hack    this.nav.GetStoreOnly("FU6259", this.InitStore, null, this);
        */

      }
    }


    /*
        TagInputModule.withDefaults({
          tagInput: {
            placeholder: 'Add a new email, Space or Enter',
            // add here other default values for tag-input
          },
          dropdown: {
            displayBy: 'my-display-value',
            // add here other default values for tag-input-dropdown
          }
        });
    */
    this.result = [];


    if (!this.global.authuser) {
      // Load user
      const id = localStorage.getItem('uid');
      if (id) {
        console.log("UID: ", id);
        this.nav.getUserOnlywID(id, false, null, this, this.afterUserInit);// true get all res
      }
    }


    if (this.storeChat) {
      this.allowNewConnection = false;
      this.GetMyGroups(this.user, this.store);
      const members = [this.user, this.store];
      this.CheckExistingGroup(members);
      this.newMsg = {
        from: this.global.authuser.email, to: "",
        msg: "",
      }

    }
    else {
      //  this.afterUserInit(this);
    }


    // Init User Chat
    if (this.userChat) {
      this.GetMyGroups(this.global.authuser.uid);
      this.newMsg = {
        from: this.global.authuser.email, to: "",
        msg: "",
      }
    }



    console.log("Store/User: ", this.store, this.user);

  }
  gotoFull() {
    this.isFull = true;
    this.chatPos = "";
  }

  gotoSide() {
    this.isFull = false;
    this.chatPos = "right";
  }

  InitStore(self: this) {

    if (self.userChat) return;

    self.allowNewConnection = false;
    if (!self.store)
      self.store = self.global.myCompany.chatService;
    console.log("Init after store loaded ", self.global.authuser, self.store, self.global.myCompany);

    if (self.global.authuser && self.store) {
      console.log("initstore-inituser");
      self.initUser();
    }

    // if (self.global.myCompany.store == "6259") {
    //   self.showStore = true;
    //  console.log("Show Napkin Chatnow store");
    // }



  }

  setContact(info: Contact) {
    // After User has created account
    const db = firebase.firestore();
    var self = this;

    console.log("User contact: ", info);

    self.contact = info;
    if (!self.global.authuser) {
      console.log("contact ", self.contact)

      if (!this.global.authuser) {
        if (!self.contact.firstName || self.contact.firstName.length == 0
          || !self.contact.lastName || self.contact.lastName.length == 0
        ) { alert("Please enter a name."); return; }
        const userRef = db.collection('users').doc();

        self.registerService.RegisterChatUser(info.email, userRef, info.firstName, info.lastName, self.initUser, self);


        //  if (!ValidateEmail(this.Email)) return;
        //      var newUserID = this.registerService.GetUID();
        //      this.registerService.RegisterUser(this.contact.email, this.contact.firstName, this.contact.lastName, false);

      }
    }





  }

  initUser(self?: any) {
    self = self || this;

    if (self.global.authuser && self.store) {
      self.user = self.global.authuser.uid;
      self.allowNewConnection = false;
      self.GetMyGroups(self.user, self.store);
      const members = [self.user, self.store];
      self.CheckExistingGroup(members);
      self.newMsg = {
        from: self.global.authuser.email, to: "",
        msg: "",
      }
      //  self.onChatOpen(null, 0);

    }

  }

  afterLogin() {
    var self = this;
    console.log("afterLoginEvent: ");

    if (self.global.authuser && self.store) {
      console.log("afterlogingetgroups");
      this.initUser();
    } else {
      self.GetMyGroups(self.global.authuser.uid);
      self.newMsg = {
        from: self.global.authuser.email, to: "",
        msg: "",
      }
    }
  }

  afterUserInit(self: this) {
    console.log("Init Chat, user loaded ", self.global.authuser);
    console.log("iStore: ", self.store);
    if (!self.global.authuser) return;

    if (self.global.authuser && self.store) {
      self.initUser();
    } else {

      self.GetMyGroups(self.global.authuser.uid);
      self.newMsg = {
        from: self.global.authuser.email, to: "",
        msg: "",
      }
    }

  }

  Clicked(event) {

  }

  hideSidebar() {
    this.display = false;
    // Close all tabs
    if (this.activeChat)
      for (var n = 0; n < this.activeChat.length; n++) {
        this.activeChat[n] = false;
      }

  }

  onTabClick() {

  }

  functionMode() {
    this.dataMode = "functions"
    this.viewFunctions = true;
  }
  hideFunctions() {
    this.dataMode = ""
    this.viewFunctions = false;

  }

  hideFindVideo() {
    this.dataMode = ""
    this.visible = false;

  }
  createForm(name, i) {
    var self = this;

    self.formService.createForm (self.newMsg.appName)
    self.dataMode = "";

  }

  createApp(map, i) {
    var self = this;

    this.nodeService.createNewApp(this.newMsg.appName, this.callback, self)
    self.dataMode = "";

  }
  callback(self, appId) {
    self.newMsg.appId = appId;
    // self.newMsg.feature = 'napkinApp'
    self.SendMsg(self.activeIndex);

  }

  cancel() {
    this.dataMode = "";
  }
  setFunction(form: Form, i) {
    var self = this;

    var selection = form.fields[0].binding;

    switch (selection) {
      case 'Map':
        var location = self.formService.findMe("location", form);
        if (form.fields[location].binding) {
          self.newMsg.feature = "map";
          self.newMsg.mapAddress = form.fields[location].binding;
          self.geoCode(self.newMsg.mapAddress, i);
        }
        break;
        case 'Weather':
          var location = self.formService.findMe("location", form);
          if (form.fields[location].binding) {
            self.newMsg.feature = "weather";
            self.newMsg.mapAddress = form.fields[location].binding;
            self.SendMsg(i)
          }
          break;
      case 'ChatBot':
        var name = form.fields[self.formService.findMe("name", form)].binding
        if (name?.length) {
          self.newMsg.feature = "ChatBot";
          self.newMsg.botName = name;
          self.startChatBot(i);
        }
        break;
      case 'Report':
        var name = form.fields[self.formService.findMe("report", form)].binding
        if (name?.length) {
          self.newMsg.feature = "Report";
          self.newMsg.reportName = name;
          self.SendMsg(i);
        }
        break;
        case 'Form':
          var createInsert = 
          form.fields[self.formService.findMe("createInsert", form)].binding
          if (createInsert=="Create") {
            var name = form.fields[self.formService.findMe("name", form)].binding
            if (name?.length) {
              self.newMsg.feature = "Form";
              self.newMsg.formName = name;
              self.createForm (name,i)
              self.SendMsg(i);
            }
          }
           console.log ("Cant create form..",createInsert, name)
          
          break;
          case 'Search Internet':
            var button = 
            form.fields[self.formService.findMe("searchButton", form)]
            if (button?.outputs[0]?.value == 'button pressed') {
              
              var search = 
              form.fields[self.formService.findMe("search", form)].binding
              if (search?.length) {
                self.newMsg.feature = "Search Internet";
                self.newMsg.searchQuery = search;
            
                
                self.SendMsg(i);
         
              }
            }

             console.log ("Searching..",search, button)
            
            break;



    }




    /*
        if (selection.toLowerCase() == 'map' && (!this.newMsg.mapAddress || !this.newMsg.mapAddress.length)) {
          this.dataMode = 'address';
          this.newMsg.mapAddress = "";
          console.log("MAP: ", this.newMsg);
          this.viewFunctions = false;
          this.newMsg.feature = "map";
          return;
        }
    
        if (selection.trim().toLowerCase() == 'chat bot') {
          this.viewFunctions = false;
          this.newMsg.feature = "chatBot";
          this.dataMode = 'nameChatBot';
       //   this.SendMsg(this.messages.length);
          return;
        }
    
        if (selection.trim().toLowerCase() == 'napkin app') {
          this.viewFunctions = false;
          this.dataMode = 'napkinApp';
          this.newMsg.feature = "napkinApp";
        //  this.SendMsg(this.messages.length);
          return;
        }
        if (selection.trim().toLowerCase() == 'new napkin app') {
          this.viewFunctions = false;
          this.dataMode = 'newNapkinApp';
          this.newMsg.feature = "napkinApp";
        //  this.SendMsg(this.messages.length);
          return;
        }
    
        if (selection.trim().toLowerCase() == 'get image' && (!this.newMsg.media || (this.newMsg.media && !this.newMsg.media.length))) {
          this.dataMode = 'image';
          this.viewFunctions = false;
          this.newMsg.feature = "get image";
          return;
        }
        if (selection.trim().toLowerCase() == 'video search' && (!this.newMsg.media || (this.newMsg.media && !this.newMsg.media.length))) {
          // USER WANTS TO INSERT Video
          this.dataMode = 'video';
          this.viewFunctions = false;
          this.visible = true;
          this.newMsg.feature = "video search";
          return;
        }
        if (selection.trim().toLowerCase() == 'internet search' && (!this.newMsg.searchResults || (this.newMsg.searchResults && !this.newMsg.searchResults.length))) {
          // USER WANTS TO INSERT IMAGE
          this.dataMode = 'search';
          this.viewFunctions = false;
          this.newMsg.feature = "internet search";
          console.log("SEARCH...");
          return;
        }
        if (selection.trim().toLowerCase() == 'sticky note' && (!this.newMsg.stickyTitle || (this.newMsg.stickyTitle && !this.newMsg.stickyTitle.length))) {
          // USER WANTS TO INSERT IMAGE
          this.dataMode = 'sticky';
          this.newMsg.stickyTitle = "";
          this.newMsg.stickyNotes = [];
          this.viewFunctions = false;
          this.newMsg.feature = "sticky note";
          this.stickyNotes = [];
    
          console.log("StickyNote...");
          return;
        }
    */
  }
/*
  outputEvent(form: Form) {
    var self = this;
    console.log("chooseApp ", form, form.fields[0].selected[0])
    self.newMsg.appId = form.fields[0].selected[0];
    self.SendMsg(self.activeIndex);
    self.dataMode = "";
  }
*/
  setQuery(query: string) {
    // Got results back so let's save them

    this.newMsg.searchQuery = query;
  }
  setInfo(info: Kpanel, msg) {
    // Got results back so let's save them
    console.log("Info: ", msg, info);
    this.newMsg.searchInfo = info;
   // this.updateMessage(msg, this.currentGroupId);

  }
  setResult(result: Results[], chat) {
    var self = this;

console.log("SEARCH RESULTS: ", result, chat)
 
    if (!result) { //cancelled
      self.dataMode = "";
      return;
    }

    // Got results back so let's save them
    chat.searchResults = result;
    //chat.searchTabOpen = true;
    self.updateMessage(chat, self.currentGroupId);


  }
  openChat() {
    this.display = true;
    // sound.play();

  }

  onTabOpen(e: any, i) {
    e.stopPropagation();
    console.log("o-i ", i)
  }

  onTabClosed(e: any, i) {
    e.stopPropagation();
    console.log("C-i ", i)
    this.activeIndex = null;
  }

  getBackgroundcolor(chat: Message) {
    if (chat.sentBy == this.global.authuser.uid) {
      return "rgb(255,255,255)";
    }
    else {
      if (chat.notReadBy && chat.notReadBy.includes(this.global.authuser.uid))
        return "rgb(240, 221, 16)"

      else
        return "#95BDFF"; "rgb(40, 221, 16)"
    }

  }
  getTextAlign(chat: Message) {
    if (chat.sentBy == this.global.authuser.uid)
      return "left";
    else
      return "right";
  }

  fieldEvent(field: Field) {
    console.log("I Got some data ", field)

    switch (field.title) {
      case "searchButton":

      break;
    }


  }

  functionSubmitted(form: Form, i) {
    var self = this;

    console.log("form submitted: ", i, form?.fields[0]?.selected[0], form)

    if (form?.fields[0]?.selected[0]) {
      self.setFunction(form, i)
    }


  }

  getChatPlaceHolder() {
    // var x = "try entering a command; image, map, video, draw, play";

    var x = "Enter message"

    if (this.store) return "";
    else return x;

  }

  goLink(url: string) {
    if (url)
      window.open(url);

  }

  msgClicked(chat: Message) {
    console.log("Clicked ", chat);

    if (chat.msg.startsWith("http")) { console.log("url"); return; }
    if (chat.sentBy == this.global.authuser.uid) { //Me
      this.editChat = chat;
      this.newMsg = <Chat>chat;

    }

  }
  onChatClose(evo: any, index) {


    //   this.activeChat[this.activeIndex] = false;
    this.activeIndex = -1;
  }

  onChatOpen(e: any, index) {
    var self = this;

    //   e.stopPropagation();

    // DONT WORK?  self.activeChat.forEach (function(tab:boolean){ tab = false;}) // CLOSE ALL TABS
    if (!self.activeChat) self.activeChat = [];
    for (var n = 0; n < self.activeChat.length; n++) {
      self.activeChat[n] = false;
    }


    self.activeChat[index] = true;
    self.activeIndex = index;
    console.log("active: ", e, self.activeChat, index);

    self.currentGroupId = self.groups[index].id;

    self.fetchMessagesByGroupId(self.groups[index].id, index);

    //  if (self.groups[index].newMessages) {
    //   self.groups[index].newMessages = 0;
    //   self.UpdateGroup(self.groups[index], self.groups[index].id)
    //   }

  }

  GetGroupName(i): string {
    var self = this;

    var members = "";
    if (self.groups[i].channel) return self.groups[i].channel;

    if (self.store) {
      if (self.global.authuser.uid == self.user)
        return (self.groups[i].names[1]); // Bit of a HACK
      else return self.groups[i].names[0];

    }


    self.groups[i].members.forEach(function (id, index) {
      if (id != self.global.authuser.uid) {
        if (members) members += ", "
        members += self.groups[i].names[index];
      }
    })
    return members;

  }

  getTextColor(chat: Message): string {
    if (chat.sentBy == this.global.authuser.uid) // ME
      return 'rgb(0,45,0)';
    else return 'rgb(83, 104, 176)';
  }

  GetAccount(con: msg_Groups, chat: Message) {
    return con.id;

    //  const allSpacesRemoved = str.replaceAll(' ', '');


  }
  GetTopic(con: msg_Groups, chat: Message) {
    return chat.sentAt.toMillis().toString();
  }
  SendMsg(i) {
    console.log("SEND");

    this.SendNewMsg(i);

  }

  async SendNewMsg(i) {
    var self = this;

    this.saveMessage(<Message>self.newMsg, self.currentGroupId, i);

  }
  sendSticky() {
    var self = this;

    if (!self.newMsg.stickyTitle) {
      self.dataMode = "";
      self.stickyNotes = [];
      self.newMsg.stickyTitle = "";
      self.newMsg.stickyNotes = [];
      return;
    }

    self.newMsg.stickyNotes = [];
    self.stickyNotes.forEach(function (item) {
      self.newMsg.stickyNotes.push(item.value);
    })
    self.SendNewMsg(self.messages.length);
    self.dataMode = "";
    self.stickyNotes = [];
    self.newMsg.stickyTitle = "";
    self.newMsg.stickyNotes = [];


  }
  UpdateMsg(msg: Message) {
    this.updateMessage(msg, this.currentGroupId);
    this.editChat = null;
    this.newMsg.msg = "";
  }
  setConversation(i) {
    // this.activeIndex = i;
  }
  getMap(chat: Chat, i) {
    this.geoCode(this.newMsg.mapAddress, i);

  }
  startChatBot(i) {
    this.SendMsg(i);
    this.dataMode = "";
  }
  createCallback(event) {
    var self = this;

    // needs to include the msgId!!!
    event.chat.chatBotId = event.id;
    event.chat.chatBotName = event.name;
    self.updateMessage(event.chat, self.currentGroupId)
    console.log("callback bot create", event)

  }

  addImage(chat: Message, i) {
    if (this.newMsg.media) { // Add Check for type of file at URL
      this.SendMsg(this.messages.length); // it's new!
      this.dataMode = "";
    }
  }

  ChooseVideo(v) {
    if (!this.newMsg.media) this.newMsg.media = [];
    this.newMsg.media.push(v);
    this.SendMsg(this.messages.length);
    this.visible = false;
    this.dataMode = "";
  }
  FindVideo(search: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        Authorization: '563492ad6f91700001000001654b43a8e2d04946ad43a2468e61c945',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'PexelsdimasV1.p.rapidapi.com'
      }
    };
    var search: string = 'https://pexelsdimasv1.p.rapidapi.com/videos/search?query=' + search + '&per_page=15&page=1';
    console.log("V Search: ", search);
    fetch(search, options)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        var r = <Video[]>response.videos;
        self.videos = [];
        r.forEach(function (item: Video) {

          var smallest: number;
          var smallIndex: number;
          item.video_files.forEach(function (v: VideoFile, i) {
            if (!smallest || (v.height * v.width) < smallest) {
              smallest = v.height * v.width;
              smallIndex = i;
            }
          })

          self.videos.push(item.video_files[smallIndex].link);
        })
        console.log("Videos: ", self.videos, r);

      })
      .catch(err => console.error(err));
  }


  upload(event: any, chat: Message) {
    var self = this;

    var fn = event.target.value.lastIndexOf("\\");
    console.log(fn);
    var imageName = event.target.value;
    imageName = imageName.slice(fn + 1);

    console.log("uploading image ", imageName);

    var index = self.messages.length;

    const filePicker = document.querySelector('imageLoad' + index.toString()); //HACK use id


    console.log("Image: ", event, filePicker);
    // Now get reference to upload

    const ref = firebase.storage().ref();
    const file = event.target.files[0];
    //const name = (+new Date()) + '-' + file.name;
    const metadata = {
      contentType: file.type
    };

    const name = ('chat-images/' + imageName);

    const task = ref.child(name).put(file, metadata);
    task
      .then(snapshot => snapshot.ref.getDownloadURL())
      .then((url) => {
        Logger("Media Upload - Chat", self.global.authuser.uid, self.global.myCompany.store, url, name)

        console.log(url);
        chat.media = url;
        self.SendMsg(index);
        self.dataMode = "";
        // this.updateMessage(chat, this.currentGroupId);

      })
      .catch(console.error);



  }

  // NEW DB CODE

  firstTime = true;
  GetMyGroups(uid, limituid?: string) {
    const db = firebase.firestore()
    console.log('Gettin groups: ', this.store, uid);
    const vm = this
    return new Promise((resolve, reject) => {
      const groupRef = db.collection('msg_groups')
      groupRef
        .where('members', 'array-contains', uid)
        .onSnapshot((querySnapshot) => {
          const allGroups = []
          var count = 0;
          if (!vm.activeChat)
            vm.activeChat = []
          querySnapshot.forEach((doc) => {
            const data = <msg_Groups>doc.data()
            data.id = doc.id
            var included = true;
            if (limituid) {
              included = data.members.includes(limituid);

            }
            if (included) {
              count++;
              //  if (data.recentMessage) 
              allGroups.push(data);
              if (vm.activeChat.length < count)
                vm.activeChat.push(false);
            }

          })

          vm.groups = allGroups
          if (allGroups.length == 1) vm.onChatOpen(null, 0);
          console.log("Groups: ", allGroups);
          if (vm.firstTime && allGroups.length) {
            console.log("Attach a watch to all groups")
            vm.firstTime = false;
            vm.groups.forEach(function (group) {
              vm.fetchNewByGroupId(group.id);
            })
          }

        })
    })
  }

  CheckExistingGroup(members: string[]) {

    const db = firebase.firestore()

    const vm = this
    return new Promise((resolve, reject) => {
      const groupRef = db.collection('msg_groups')
      groupRef
        .where('members', '==', members)
        .get()
        .then((querySnapshot) => {

          if (querySnapshot.empty) {
            this.createServiceGroup(members[0], members[1]);

          }
        })

    })
  }

  // Monitor Messsages for NEW notifications
  fetchNewByGroupId(groupId) {
    const db = firebase.firestore()
    const vm = this
    console.log("Track group: ", groupId)


    db.collection('msg_messages')
      .doc(groupId.trim())
      .collection('allMessages')
      .orderBy('sentAt')
      .onSnapshot((querySnapshot) => {
        vm.groups.findIndex
        var f = vm.groups.findIndex(function (post, index) {
          if (post.id == groupId)
            return true;
        });
        //     vm.groups[f].newMessages = 0;


        //   const allMessages = []
        querySnapshot.forEach((doc) => {
          if (doc) {
            const data = <Message>doc.data()
            data.id = doc.id
// auto-open new stuff
            if (data.feature=="Search Internet") {
              if (!data.searchResults) {
                data.searchTabOpen = true;
              }
              else data.searchTabOpen = false;

            }


          //  console.log("New Message: ", data, data.notReadBy);

            // Track unread messsages
            if (data.notReadBy) {
              if (data.notReadBy.includes(vm.global.authuser.uid)) {
                console.log("Message ", data, vm.global.authuser.uid, f)

                //    vm.groups[f].newMessages++;
                vm.global.messageCount++;
                sound.play();
              }
            }

            /*
                        // Track unread messsages
                        if (data.notReadBy) {
            
                          if ((vm.store == vm.global.myCompany.chatService && data.notReadBy.includes(vm.global.myCompany.chatService)) ||
                            (!vm.store && data.notReadBy.includes(vm.global.authuser.uid))) {
            
                            vm.groups[f].newMessages++;
                            vm.global.messageCount++;
                            //  sound.play();
                            console.log("Not read by me ", data.notReadBy, vm.global.authuser.uid);
                          }
                        }
                        if (data.sentBy != vm.global.authuser.uid && (!vm.groups[f].lastNotify || data.sentAt > vm.groups[f].lastNotify)) {
                          console.log("M ", data.sentBy, vm.global.authuser.uid, f)
                          vm.groups[f].lastNotify = Timestamp.now();
                          //   if (!vm.groups[f].newMessages) vm.groups[f].newMessages = 0;
            
            
                          sound.play();
            
                          //   vm.staffNotifyEmail(data, f);
                          vm.UpdateGroup(vm.groups[f], groupId);
                        }
                        */

            // allMessages.push(data)
          }
        })

        // vm.messages = allMessages
        // console.log("Messages: ", allMessages);


      })
  }

  groupsStarted: string[] = [];

  fetchMessagesByGroupId(groupId, index) {


    if (this.groupsStarted.includes(groupId)) return;
    else this.groupsStarted.push(groupId); // Only once

    console.log("Start Service for Messages:  ", groupId);
    const db = firebase.firestore()

    this.currentGroupId = groupId;  // Temp Hack

    const vm = this
    db.collection('msg_messages')
      .doc(groupId.trim())
      .collection('allMessages')
      .orderBy('sentAt')
      .onSnapshot((querySnapshot) => {
        if (groupId == vm.currentGroupId) {
          const allMessages = []
          querySnapshot.forEach((doc) => {
            if (doc) {
              const data = <Message>doc.data()
              data.id = doc.id


              /*        if (data.notReadBy && data.notReadBy.includes(vm.global.authuser.uid)) {
                        if (!vm.groups[index].newMessages) vm.groups[index].newMessages = 0;
                        vm.groups[index].newMessages++;
                        console.log("Not read by me");
                      }*/

              // Track unread messsages
              if (vm.display && data.notReadBy && data.notReadBy.includes(vm.global.authuser.uid)) {
                if (!vm.groups[index].newMessages) vm.groups[index].newMessages = 0;

                vm.global.messageCount--;
                console.log("Now it's read by me");
                //Update message as read
                doc.ref.update({
                  notReadBy: firebase.firestore.FieldValue.arrayRemove(vm.global.authuser.uid),
                })

              }

              allMessages.push(data)
            }
          })
          allMessages.sort(function (a, b) { return a.sentAt - b.sentAt; });
          vm.messages = allMessages
          //  console.log("Messages: ", allMessages);
        }

      })
  }



  saveMessage(msg: Message, currentGroupId: string, index) {
    const db = firebase.firestore();
    var self = this;

    console.log("SAve message ", msg, currentGroupId, index, self.groups)

    const sentAt = Timestamp.now();
    if (msg.msg.trim() || msg.feature) {
      if (index != null) {
        msg.sentAt = sentAt;
        msg.sentBy = self.global.authuser.uid;
        if (!msg.notReadBy) msg.notReadBy = [];
        self.groups[index].members.forEach(function (member) {
          if (member != self.global.authuser.uid)
            msg.notReadBy.push(member);
        })
      }

      msg.searchTabOpen = false;

      const message: Message = { ...msg };   // DEEP COPY


      console.log("SAVING..:", message, currentGroupId, index);


      return new Promise((resolve, reject) => {
        db.collection('msg_messages')
          .doc(currentGroupId)
          .collection('allMessages')
          .add(message)
          .then(function (docRef) {
            console.log(" added", message);
            self.updateGroupDateTime(currentGroupId);

            resolve(<Message>message);
            if (self.newMsg) {
              self.newMsg.msg = "";
              self.newMsg.mapAddress = "";
              self.newMsg.media = [];
              self.newMsg.searchResults = [];
              self.newMsg.searchQuery = "";

            }
            else {
              self.newMsg = {
                from: self.global.authuser.email, to: "",
                msg: "",
              }
            }

            if (index != null) {
              self.userNotifyEmail(self.groups[index], <Message>message);
            }
            else console.log("index is null ", message);

          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }

  updateGroupDateTime(groupid: string) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(groupid)
        .update({
          lastTime: new Date(),
          status: "Active"
        })
        .then(function (docRef) {
          resolve(groupid)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  updateMessage(message: Message, currentGroupId) {
    const db = firebase.firestore();
    var self = this;

    //  const sentAt = Timestamp.now();

    //    this.newMsg.msg = "";
    return new Promise((resolve, reject) => {
      db.collection('msg_messages')
        .doc(currentGroupId)
        .collection('allMessages').doc(message.id)
        .update(message)
        .then(function (docRef) {
          resolve(message)
          //     self.userNotifyEmail(self.groups[index],<Message>message);
        })
        .catch(function (error) {
          reject(error)
        })
    })

  }

  UpdateGroup(group: msg_Groups, GroupId) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(GroupId)
        .update({
          lastNotify: group.lastNotify,
          //      newMessages: group.newMessages
        })
        .then(function (docRef) {
          resolve(group)
        })
        .catch(function (error) {
          reject(error)
        })
    })

  }



  async createGroup() {
    const db = firebase.firestore();
    var self = this;
    // check for email not added when button clicked
    console.log("Add: ", self.groupListInput)
    const sentAt = Timestamp.now();
    //    if (self.newMsg.msg.trim()) {
    var message: Message = {
      msg: self.newMsg.msg,
      sentAt,
      sentBy: self.global.authuser.uid
    }
    //    }

    var members: string[] = [];
    var emails: string[] = [];
    var names: string[] = [];

    var emailList: string[] = [];
    if (self.groupList.length == 0) return;

    self.groupList.forEach(function (email: jVal) {
      if (ValidateEmail(email.value))  // Check on entry
        emailList.push(email.value);
    })
    self.groupList = [];

    if (emailList.length) {
      console.log(emailList);
      // Add me first
      members.push(self.global.authuser.uid);
      emails.push(self.global.authuser.email);
      names.push(self.global.authuser.firstName + " " + self.global.authuser.lastName);

      var dbRef = db.collection("users").where("email", 'in', emailList)
      dbRef
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            console.log("no Users not found here... ");
          }
          if (!querySnapshot.empty) {
            console.log("All found: ", querySnapshot.docs);

            querySnapshot.forEach((doc) => {
              var data = <User>doc.data();
              console.log("user: ", data);
              members.push(data.uid);
              emails.push(data.email);
              names.push(data.firstName + " " + data.lastName);
            });
          }
          console.log(emails);

          // NOW FOR ANY EMAILS NOT FOUND LET'S INVITE USER TO CHAT
          emailList.forEach(function (email) {
            if (!emails.includes(email)) { // Auto-add
              const userRef = db.collection('users').doc();
              members.push(userRef.id);
              emails.push(email);
              names.push(email);
              self.registerChatUser(email, userRef);
            }
          })


          // Now Let's make a group
          const docRef1 = db.collection('msg_groups').doc();
          const createdAt = Timestamp.now();

          const group: msg_Groups = {
            id: docRef1.id,
            members,
            names,
            emails,
            createdAt,
            createdBy: self.global.authuser.uid
          }

          console.log("START GROUP CHAT", group)

          docRef1.set(group)
            .then(() => {
              console.log("gg");
              if (self.newMsg?.msg?.length) {
                self.saveMessage(message, group.id, null)
                self.sendNewGroupEmails(group, <Message>message);
              }
            })
            .catch((error) => {
              console.error("Error writing document: ", error);
            });



        })
    }

  }

  registerChatUser(email, userRef: any) {


    this.registerService.RegisterChatUser(email, userRef);
  }

  createServiceGroup(uid1, uid2) {
    var self = this;
    const db = firebase.firestore();

    const createdAt = Timestamp.now();

    console.log("Creating Service Group ", self.global.authuser)

    const members = [uid1, uid2];
    const names = [self.global.authuser.firstName + " " + self.global.authuser.lastName, self.global.myCompany.name + " customer service"];
    const emails = [self.global.authuser.email, self.global.myCompany.chatServiceEmail];

    const docRef1 = db.collection('msg_groups').doc();

    const sentAt = Timestamp.now();

    var message: Message = {
      msg: "How may we assist?",
      sentAt,
      sentBy: uid2,
      from: self.global.myCompany.chatServiceEmail
    }

    const group: msg_Groups = {
      id: docRef1.id,
      members,
      names,
      emails,
      createdAt,
      createdBy: uid1,
      storeID: self.global.myCompany.id,
      storeName: self.global.myCompany.name,
    }
    console.log("enter: New Customer Chat: ", group, message)


    return new Promise((resolve, reject) => {

      docRef1
        .set(group)
        .then(function (docRef) {

          resolve(<msg_Groups>group)
          // Now send the initial MSG
          self.saveMessage(message, group.id, null)
          self.sendNewGroupEmails(group, <Message>message);
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }


  public staffNotifyEmail(r: Message, name) {
    var self = this;

    if (!this.store) return;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    var notifyUsers: User[];
    notifyUsers = [];

    db.collection("users").where("creds", "!=", "null")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          data.creds.forEach(function (cred) {
            if (r.sentBy != data.uid && cred.id == self.global.myCompany.id
              && cred.notify && cred.notify.enable && cred.notify.chat) {
              notifyUsers.push(data);

              // NOTIFY STAFF
              var templateParams = {
                from_name: self.global.myCompany.name,  // Company Name
                to_name: data.firstName,
                to_email: data.email,
                msgDate: r.sentAt,
                msgFrom: name,
                msgText: r.msg
              };

              //  e.preventDefault();
              emailjs.send("service_jwders9", "template_yy94vla", templateParams) //"template_1ky3ksd"
                .then((result: EmailJSResponseStatus) => {
                  console.log("staff notified: ", result.text);
                }, (error) => {
                  console.log(error.text);
                });
            }
          })
        });
        console.log("Notify staff of new chat item: ", notifyUsers);

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }


  public userNotifyEmail(group: msg_Groups, newMsg: Message) {
    var self = this;

    if (this.storeChat) return; // different emails

    console.log("Notify: ", group, newMsg);

    var creator = group.members.findIndex(function (post, index) {
      if (post == newMsg.sentBy)
        return true;
    });

    if (group.storeID) {

      if (newMsg.sentBy != group.storeID)
        self.staffNotifyEmail(newMsg, group.names[0]);

      return;
    }

    if (newMsg.msg.length == 0) return;

    console.log("Notify: ", group, newMsg);
    // check group creator/msg sender

    group.members.forEach(function (email, i) {

      if (group.members[i] != newMsg.sentBy) {

        var dCode = group.members[i].substring(0, 5);
        console.log("dc ", dCode)

        var templateParams = {
          from_name: group.names[creator],
          to_name: group.names[i],
          to_email: group.emails[i],
          message: newMsg.msg,
          msgDate: self.prettyDateTime(newMsg.sentAt),
          msgFrom: group.names[creator],
          msgText: newMsg.msg,
          dCode: dCode

        };

        console.log("New group EMAIL: ", templateParams, creator);
        //  e.preventDefault();
        emailjs.send("service_jwders9", "template_fljzpn6", templateParams)
          .then((result: EmailJSResponseStatus) => {
            console.log(result.text);
          }, (error) => {
            console.log(error.text);
          });
      }

    })

  }

  sendNewGroupEmails(group: msg_Groups, newMsg: Message) {
    var self = this;
    // SEND Info EMAILS TO PEOPLE ADDED TO GROUP - Including Creator

    if (this.storeChat) return; // different emails

    var creator = group.members.findIndex(function (post, index) {
      if (post == newMsg.sentBy)
        return true;
    });


    // check group creator/msg sender

    group.members.forEach(function (email, i) {
      var dCode = group.members[i].substring(0, 5);
      var templateParams = {
        from_name: group.names[creator],
        to_name: group.names[i],
        to_email: group.emails[i],
        message: newMsg.msg,
        msgDate: self.prettyDateTime(newMsg.sentAt),
        msgFrom: group.names[i],
        msgText: newMsg.msg,
        dCode: dCode,

      };
      if (group.storeID != group.members[i]) { }

      console.log("New group EMAIL: ", templateParams, creator);
      //  e.preventDefault();
      emailjs.send("service_jwders9", "template_dm7az4j", templateParams)
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });

    })


  }
  prettyDateTime(date: Timestamp): string {

    let d = new Date(date.toDate());
    return dateFormatter(d);
  }


  updateLatLong(lat: number, long: number, i) {
    var self = this;

    if (lat) {
      self.newMsg.mapLat = lat;
      self.newMsg.mapLong = long;
      self.newMsg.mapOptions = {
        center: [long, lat],
        zoom: 14,
        attributionControl: false,
        style: 'mapbox://styles/mapbox/satellite-streets-v11',
      }

      self.SendMsg(i);
      self.dataMode = "";
    }
  }






  geoCode(adr, i) {
    var self = this;


    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com'
      }
    };

    //'&city=' + city + '&state=' + state + '&postalcode=' + zip +

    fetch('https://forward-reverse-geocoding.p.rapidapi.com/v1/search?q=' + adr + '&accept-language=en&polygon_threshold=0.0', options)
      .then(response => response.json())
      .then(response => {
        console.log("GEO: ", response);
        if (response[0] && response[0].lat) {
          var lat: number = Number(response[0].lat);
          var lon: number = Number(response[0].lon);
          self.updateLatLong(lat, lon, i);
        }


      })
      .catch(err => console.error(err));


  }


  checkEmail() {
    var self = this;
    console.log("Checkemail ");

    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    self.foundUser = false;
    if (!this.Email || this.Email.length < 5) return;

    self.inputCreds = false;
    db.collection("users").where("email", "==", self.Email)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          self.foundUser = true;
          self.inputCredsnoUser = null;
        }
        self.inputCredsnoUser = true;
        console.log("Found: ", self.foundUser);

        self.inputCreds = true;
      })

  }

  wink(conversation: msg_Groups, chat: Message) {

    console.log(conversation, chat)


  }



}
function dateFormatter(date: Date) {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: true
  };

  return " at " + TimeFormat(date.getTime()) + " on " + date.toLocaleDateString('en-US', timeformat);
}

function TimeFormat(time: number) {

  var d = new Date(time);
  let hours = d.getHours();
  // Determine AM or PM suffix based on the hour
  const suffix = (hours < 12) ? "am" : "pm";

  // Convert hour from military time
  hours = (hours < 12) ? hours : hours - 12;

  // If hour is 0, set it to 12
  hours = hours || 12;

  var min = d.getUTCMinutes();
  var minText = min.toString();
  if (min < 10) minText = "0" + min.toString();
  if (min == 0) minText = "00 ";


  return hours + ":" + minText + suffix;

}


function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}



