// TO MAKE A NEW MODULE: ng g c components/admin  --module ../app
// ng generate service components/service-name 
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  onActivate(event) {
    window.scroll(0,0);
    console.log("TOP");
    document.body.scrollTop = 0;
    document.querySelector('body').scrollTo(0,0)
    
  }
    constructor(private primengConfig: PrimeNGConfig) { }
  
    ngOnInit() {
        this.primengConfig.ripple = true;
    }

  title = 'NAPKIN';
}
