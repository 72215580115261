import { AfterViewInit, AfterContentInit, Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { ViewChild } from '@angular/core';
import { interval } from 'rxjs';
import { Observable } from 'rxjs';

import { Company, Form, Field, EmailForm, Navigate, Reviews, Review, Coupon } from '../company-interface';
import { Globals } from 'src/app/globals';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collectionGroup, query, where, getDocs } from "firebase/firestore";

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { User, Follows, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../user-interface';
import { RegisterService } from '../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { collection, Timestamp } from "firebase/firestore";
import { ReviewsService } from '../reviews.service'
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';
import { Table } from 'primeng/table';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent {
  getScreenWidth: number;
  getScreenHeight: number;

  customers1: User[];
  customers: User[];
  customerOrders: User[] = [];

  csvRecords: any[] = [];
  header = true;
  @ViewChild('fileImportInput') fileImportInput: any;
  @ViewChild('dt1') table: Table;

  search1: string = "";

  constructor(private ngxCsvParser: NgxCsvParser, public global: Globals, private registerService: RegisterService,
    private router: Router, private route: ActivatedRoute,
    private http: HttpClient,
    public nav: NavService) {


  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;


    // this.fixCustomers();
    this.getCustomers();

  }

  clear(table: Table) {
    this.table.value = this.customers1;
    table.clear();
  }
  searchMe(value: string) {

    console.log("Search ", value);
    if (value.length > 1) {
      this.table.value = this.customers;
      this.table.filterGlobal(value, 'contains');
    }
    else if (value.length == 0) {
      this.table.value = this.customers1;
      this.table.clear();
    }


  }


  fileChangeListener($event: any): void {
    var self = this;

    const files = $event.srcElement.files;
    this.header =
      (this.header as unknown as string) === 'true' ||
      this.header === true;

    this.ngxCsvParser
      .parse(files[0], {
        header: this.header,
        delimiter: ',',
        encoding: 'utf8'
      })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          self.csvRecords = [];
          result.forEach(function (item) {
            if (item.Email) {
              self.csvRecords.push(item);
            }
          })

          console.log('Result', this.csvRecords);
        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
        }
      );
  }

  addToOptIn() {
    // add the CSV Records to Users as Opt-Ins
    // Check if record exists and if so add to follows

    var self = this;

    var db = firebase.firestore();

    var follow: Follows[] = [{
      id: self.global.myCompany.id,     // remove later
      storeNum: self.global.myCompany.store,    // fix name
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: self.global.myCompany.name
    }]
    const n = (Math.random() * 900) + 100;

    var newStoreInt = Math.trunc(n);

    var limiter = 0;

    self.csvRecords.forEach(function (record) {
      limiter++;
      if (1) {
        var newUser: User = {
          createStamp: Timestamp.now(),
          uid: "",
          email: record.Email,
          firstName: record.FirstName,
          lastName: record.LastName,
          emailVerified: false,    //user.emailVerified,
          following: follow,
          follows: [self.global.myCompany.id],
          optIn: true,
        }

        var w1 = getRandomInt(self.nav.letterWords.length);
        var w2 = getRandomInt(self.nav.letterWords.length);
        const n = (Math.random() * 900) + 100;

        newUser.keyCode = Math.trunc(n);
        newUser.pw = self.nav.letterWords[w1] + self.nav.letterWords[w2];
        newUser.pw = newUser.pw.toLowerCase();

        // HAVE NEW USER REGISTRATION:
        // CHECK FOR EXISTING
        var docRef = db.collection("users").where("email", "==", record.Email);
        docRef.get().then((doc) => {
          if (!doc.empty) {
            //  So let's update follows if not already there
            doc.docs.forEach(function (item) {
              var user = <User>item.data();
              console.log("User: ", user);
              if (user.follows && user.follows.includes(self.global.myCompany.id)) {
                console.log("This User already Follows ");
              }
              else {
                // Add a follows
                var f: string[] = [];
                if (user.follows)
                  f = user.follows;
                f.push(self.global.myCompany.id);
                var userRef = db.collection("users").doc(user.uid);

                userRef.update({
                  follows: f,
                })
                  .then(() => {
                    console.log("follow added");
                  })
                  .catch((error) => {
                    console.error("Error writing document: ", error);
                  });


              }
            })

          }
          else {
            console.log("no account found, so add it ", record.Email);
            var docRef = db.collection("users").doc();
            newUser.uid = docRef.id;
            newUser.sid = docRef.id.substring(0, 5);

            docRef.set(     //{  // AUTO-ID DOC
              newUser,
            )
              .then(() => {
                console.log("Opt-In user added ");
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
          }
        })
      }
    })









  }

  validAdmin() {
    //   if (this.global.authuser.uid = "kvT6eKFxFHb3EKbVeCTusW86CrZ2")
    //    return true;

    return false;
  }
  removeUser(user: User) {
    var self = this;
    var db = firebase.firestore();

    // Let's just mark as removed for now.
    var ref = db.collection("users").doc(user.uid);
    ref.update({
      removed: true
    }).then(() => {
      console.log("user updated: ");
    }).catch((error) => {
      console.error("Error writing document: ", error);
    });


  }

  startNumber: number = 0;
  maxNumber: number = 1;

  BatchEmails() {
    var self = this;

    if (self.startNumber >= self.maxNumber) {
      clearInterval(self.batcher)
      return;  // Limiter
    }

    console.log("Batching next 1");
    self.sendEmailBatch(self.startNumber, 1);
    self.startNumber += 1;
  }

  batcher: any;

  stopBatch() {
    clearInterval(this.batcher)
  }
  updateAllCustomers() {

    this.batcher = setInterval(() => {
      this.BatchEmails();
    }, 1000);


    return;
  }

  sendTestEMail() {
    var self = this;

    const sentAt = Timestamp.now();

    var emailForm: EmailForm = {
      to: "swarner100@gmail.com",
      subject: "Mother's Day at Crystal Hill",
      message: this.htmlTemplate
    }

    var sendList = ["swarner100@gmail.com","swarner@suncraftcellars.com"]
   this.sendBatchEmails(sendList);
  }

  // { companyId, templates, values, sendList, tags } = req.body;

  sendBatchEmails (sendList) {
    var self = this;

    console.log (this.htmlTemplate, sendList)

    this.http.post('https://batchemail-xdnunfyrka-uc.a.run.app',
     { companyId: "skkk8SCSIDyrdiueY4jU",   
      templates: self.htmlTemplate,
      sendList: [],
      values: 'none',
      tags: 'subscription'
     }
    ).subscribe({
        next: response => console.log('Email sent', response),
        error: error => console.error('Error sending email', error)
      });

  }

  htmlTemplate;

  
  template(field: Field) {

    this.htmlTemplate = field.html;


    console.log("TEMPLATE ", this.htmlTemplate)
  }

  sendEmail(emailForm: EmailForm) {

    this.http.post('https://sendemailhtml-xdnunfyrka-uc.a.run.app',
      emailForm).subscribe({
        next: response => console.log('Email sent', response),
        error: error => console.error('Error sending email', error)
      });

  }


  sendEmailBatch(start, count) {
    var self = this;
    // Send email message to all users.
    const sentAt = Timestamp.now();

    self.customers.forEach(function (item, index) {
      if (index >= start && index < start + count) {

        if (index >= self.maxNumber) return;  // Limiter

        // CHECK FOR UNSUB
        if (item.unsub && item.unsub.includes(self.global.myCompany.store)) {
          console.log("Unsub: ", item.email);
        }
        else {
          var html = self.htmlTemplate;
          // ADD THE RESERVE NOW
          html += "<p style=\"font-size:22px\">"
          html += "<a href=\"https://napkinhq.com/reserve/4390?p="
          
          if (!item.sid)
            item.sid = item.uid.substring(0,5)
          html += item.sid + "\">Reserve Your Spot</a></p>"


          // Now add the Unsub
          html += "<br><br><p style=\"font-size:15px\">To stop receiving updates from Crystal Hill Vineyard, use the 1-click "
          html += "<a href=\"https://napkinhq.com/unsub?s=4390&n="
          html += item.email + "\">Unsubscribe</a></p>"

          console.log("Added unsub ", html)

          var emailForm: EmailForm = {
            to: item.email,
            subject: "Mother's Day at Crystal Hill",
            message: html
          }

          self.sendEmail(emailForm);

        }
      }
    })

  }


  async getCustomers() {
    var self = this;
    var db = firebase.firestore();


    const q0 = db.collection("users").where("follows", "array-contains", self.global.myCompany.id)
    const unsubscribe = onSnapshot(q0, (querySnapshot) => {
      self.customers = [];

      querySnapshot.forEach((doc) => {
        const c = <User>doc.data();

        if (c.email.includes('napkin')) {
          console.log("Removing Email ", c.email)
        }
        else if (!c.removed)
          self.customers.push(c);
      })
      if (self.global.openOrders && self.global.openOrders.length > 0) {
        // Create list of Customers with open orders
        self.global.openOrders.forEach(function (order) {
          const index = self.customers.findIndex(item => item.uid === order.userID);
          if (index != -1) {
            var exists = false;
            if (self.customerOrders.length)
              exists = self.customerOrders.some(x => x.uid === self.customers[index].uid)

            if (!exists)
              self.customerOrders.push(self.customers[index]);
          }
        })
      }

      console.log("Customers: ", self.customers, self.customerOrders);

    })


  }


  fixCustomers() {
    var self = this;
    var db = firebase.firestore();
    return;  // One time use



    const q0 = db.collection("users")

    q0.get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          // IF Follows does not exist then add it
          if (!data.follows && data.following) {
            var follows: string[] = [];
            data.following.forEach(function (follow) {
              follows.push(follow.id);
            });
            // Save it back
            var ref = db.collection("users").doc(data.uid);
            ref.update({
              follows: follows
            }).then(() => {
              console.log("follows updated: ");
            }).catch((error) => {
              console.error("Error writing document: ", error);
            });
          }

        });
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });



  }
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}
