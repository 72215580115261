<div *ngIf="field.loginRequired && !global.authuser && field.notLoggedInMsg">
    <p>{{field.notLoggedInMsg}}</p>
</div>
<div *ngIf="isVisible() && (field.binding || !field.editOnly || (field.editOnly==true && editMode==true))"
    [style]="themeContainer()" style="position:relative; box-sizing: border-box;">

    <div *ngIf="field.type=='canvas' && sceneId" style="overflow: scroll;">

        <app-phaser [appName]="appName" [sceneId]="sceneId" (eventEmit)="gotEvent($event)"
            (setVIP)="setVIP($event)"></app-phaser>
    </div>
    <div *ngIf="field.type=='canvas' && sceneId" style="width: 100%; max-width: 100vw;">
        <p-accordion *ngIf="global.authuser" iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="gold" style="width: 100%; max-width: 100vw;">EDITOR</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div style="width: 100%; max-width: 100vw;">
                        <app-phaser-edit [sceneId]="sceneId" [open]='true' [VIPIndex]="myVIPIndex"
                            (afterPreload)="afterPreload($event)" (eventEmitter)="VIPEvent($event)"></app-phaser-edit>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div *ngIf="!field.label && field.type!='switch' && field.type!='checkbox' && field.type!='tags'">
        <div *ngIf="field?.display==true" class="field">
            <div fxLayout="row" fxLayoutAlign="start center" [style]="themeLabel()">{{field.label}}<span
                    *ngIf="field.required">**</span></div>
        </div>
        <div *ngIf="!field.display && field.display != false" class="field">
            <div fxLayout="row" fxLayoutAlign="start center" [style]="themeLabel()">{{field.label}}<span
                    *ngIf="field.required">**</span></div>

        </div>
    </div>

    <div *ngIf="field.mask && field.type != 'phone'" fxLayout="column">
        <div fxLayout="row" [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>
        <p-inputMask mask={{field.mask}} slotChar="" [(ngModel)]="field.binding" (onBlur)="outputsEvent($event)"
            placeholder=""></p-inputMask>
    </div>
    <div *ngIf="field.mask && field.type == 'phone'" fxLayout="column">
        <div fxLayout="row" fxLayoutAlign="start center" [style]="themeLabel()">{{field.label}}<span
                *ngIf="field.required">**</span></div>
        <p-inputMask class="phone" mask={{field.mask}} slotChar="" [(ngModel)]="field.binding"
            (onBlur)="outputsEvent($event)" placeholder=""></p-inputMask>
    </div>

    <div *ngIf="field.type=='displayField'" [style]="themeLabel()">
        {{field.label + " "+ field.binding}}
    </div>


    <div *ngIf="!field?.mask && (!field.displayOnly) && field.type=='full-name'" fxLayout="row wrap">
        <div fxLayout="column">
            <div fxLayout="row" [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>

            <input [autocomplete]="off" [style]="themeEntry()" [(ngModel)]="field.binding" />
        </div>
        <div *ngIf="!showSubmit">
            <button (click)="outputsEvent($event)" class="save-text" pRipple>Submit</button>
        </div>
    </div>


    <div *ngIf="(!field.displayOnly) && (field.type=='mathQuery')" fxLayout="column" fxLayoutAlign="center center"
        style="max-width: 100vw;" [style]="themeLabel()">

        {{field.label+"="+field.value}}

        <textarea rows="1" cols="30" pInputTextarea (blur)="evaluate($event)" [autoResize]="true" [style]="themeEntry()"
            [(ngModel)]="field.binding"></textarea>

    </div>



    <div *ngIf="(!field.displayOnly) && (field.type=='chatBot')" fxLayout="column" fxLayoutAlign="center center"
        style="width: max-content;">
        <div fxLayout="column" [style]="themeLabel()">
            <p-accordion *ngIf="global.authuser" iconPos="end" expandIcon="false" collapseIcon="false"
                [multiple]="false">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="gold" style="min-height:30px; padding-top:6px; width: 100%; max-width: 100%;">
                            {{field.label+" "}}<span *ngIf="field.chatBotName">{{field.chatBotName}}</span>

                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <app-chatbot [userId]="global.authuser.uid" [chatBotId]="field.chatBotId" [chat]="field"
                            (createCallback)="createCallback($event)"></app-chatbot>
                    </ng-template>
                </p-accordionTab>
            </p-accordion>
        </div>

    </div>


    <div *ngIf="!field?.mask && (!field.displayOnly) && (field.type=='input-text' || field.type=='fullName' || field?.type?.toLowerCase()=='text')"
        fxLayout="column">

        <div [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>

        <input type="text" [autocomplete]="'off'" style="max-width: 96vw;" [style]="themeEntry()"
            [style.width]="getMyWidth(field.width)" [(ngModel)]="field.binding" (blur)="outputsBlurEvent($event)"
            (input)="textInputEvent($event)" />
        <div *ngIf="!showSubmit && !field.noSave">
            <button (click)="outputsEvent($event)" class="save-text" pRipple>Save</button>
        </div>
    </div>

    <div *ngIf="field.type=='cell' || field.type=='cellQuery'">

        <div *ngIf="!field.fieldEditing">

            <div id="cellButton" [style.width]="getMyWidth(field.width)" [style.height]="getMyHeight(field.height)"
                style="cursor: text;" [style]="myButtonStyle()" (click)="cellSelect($event)" pRipple>

                <span style="z-index:200000"> {{getMyData()}}</span>

            </div>
        </div>

        <div *ngIf="field.fieldEditing && field.type!='cellQuery'">
            <input id="cellInput" #cellInput type="text" [autocomplete]="'off'" [style]="themeEntry()"
                [style.width]="getMyWidth(field.width)" [style.height]="getMyHeight(field.height)"
                [(ngModel)]="field.binding" (input)="cellEvent($event)" (blur)="cellBlurEvent($event)"
                style="margin:0px!important; width:100%; box-sizing: border-box;" />
        </div>

        <div *ngIf="field.fieldEditing && field.type=='cellQuery'" style=" max-width: 100vw; width:100%;
            height:100%" [style]="themeLabel()">
            <textarea style="width:100%; height:100%" rows="4" (blur)="cellBlurEvent($event)" [autoResize]="true"
                [style]="themeEntry()" [(ngModel)]="field.binding"></textarea>

        </div>

    </div>

    <div *ngIf="field.type=='sheet'" [style]="themeEntry()">
        <div *ngIf="field.label" [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>
        <app-sheet></app-sheet>

    </div>


    <div *ngIf="!field.displayOnly && field.type=='calendar'" fxLayout="column" style="cursor: pointer;">

        <div *ngIf="field.label" [style]="themeLabel()" (click)="displayCalendar($event)">{{field.label}}<span
                *ngIf="field.required">**</span></div>
        <div *ngIf="!field.label" [style]="themeLabel()" (click)="displayCalendar($event)">
            Please choose up to {{field.calendar.dateCount}} dates<span *ngIf="field.required">**</span></div>

        <div *ngIf="field.selected && field.selected.length" [style]="themeEntry()" fxLayout="row wrap"
            fxLayoutAlign="center none" (click)="displayCalendar($event)">
            <div *ngFor="let item of field.selected;i as index" style="font-weight:800">
                {{"["+item+"]"}}
            </div>
        </div>


        <div *ngIf="showCalendar">
            <app-calendar [dateMs]="field.dateMs" [width]="field.width" [calendar]="field.calendar"
                (outputDate)="outputDate($event)"></app-calendar>
        </div>
    </div>



    <div *ngIf="!field.displayOnly && (field.type=='city')" fxLayout="column">

        <div [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>

        <!-- 
        Interesting pattern       
            onclick="this.style.backgroundColor='black';this.style.color='white'" 
            onblur="this.style.backgroundColor='green';this.style.color='purple'"
        -->

        <input [autocomplete]="off" [style]="themeEntry()" [(ngModel)]="field.binding" />

    </div>

    <div *ngIf="!field.displayOnly && (field.type=='state')" style="max-width: 100vw;">
        <div fxLayout="column">
            <div [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>

            <input [autocomplete]="off" class="state" style="text-align:left;" [style]="themeEntry()"
                [(ngModel)]="field.binding" />
        </div>
    </div>
    <div *ngIf="!field.displayOnly && (field.type=='country')" style="max-width: 100vw;">
        <div fxLayout="column">
            <div [style]="themeLabel()">{{field.label}}<span *ngIf="field.required">**</span></div>

            <input [autocomplete]="off" class="country" style="text-align:left;" [style]="themeEntry()"
                [(ngModel)]="field.binding" />
        </div>
    </div>

    <div *ngIf="field.type=='gotoURL'" style="max-width: 100vw; cursor:pointer;">
        <div fxLayout="column" [style]="themeLabel()" style="cursor:pointer;">
            {{field.label}}
            <a [style]="themeEntry()" href={{field.gotoURL}}>{{field.gotoURL}}</a>
        </div>
    </div>





    <div *ngIf="field.type=='displayNode'">

        <app-node-object [appId]="field.appId" [nodeId]="field.nodeId" (tabOpened)="openTab($event)"></app-node-object>
    </div>


    <div *ngIf="!field.mask && (field.type=='date')" fxLayout="column" [style]="themeLabel()">
        {{field.label}}
        <div [style]="getItemSelectStyle()">
            <p-calendar [(ngModel)]="myDate" [touchUI]="true" (onSelect)="setDate($event)" />
        </div>
    </div>

    <div *ngIf="!field.mask && (field.type=='number')">
        <span *ngIf="field.decimals && field.decimals>0" fxLayout="column" [style]="themeLabel()">
            {{field.label}}
            <input type="number" [min]="field.minVal" [max]="field.maxVal" mode="decimal"
                [maxFractionDigits]="field.decimals" [style]="themeEntry()" [style.width]="getMyWidth(field.width)"
                (blur)="outputsEvent($event)" [(ngModel)]="field.value" />
        </span>
        <span *ngIf="!field.decimals || field.decimals==0" fxLayout="column" [style]="themeLabel()">
            {{field.label}}

            <input type="number" [min]="field.minVal" [max]="field.maxVal" [style]="themeEntry()"
                [style.width]="getMyWidth(field.width)" (blur)="outputsEvent($event)" [(ngModel)]="field.value" />
        </span>
    </div>
    <div *ngIf="(field.type=='result')" fxLayout="column" [style]="themeLabel()">
        {{field.label}}
        <div [style]="themeEntry()">{{formService.formatData(field)}}</div>
    </div>
    <div *ngIf="(field.type=='displayText')" fxLayout="column">
        <div [style]="themeLabel()">{{field.label}}</div>
        <div *ngIf="field.binding">{{field.binding}}</div>
    </div>

    <div *ngIf="field.type=='displayHTML' && field.htmlFinal" [style.width]="getMyWidth(field.width)">
        <div [innerHTML]="field.htmlFinal"></div>
    </div>

    <div *ngIf="field.type=='displayCart'" [style.width]="getMyWidth(field.width)">
        <cart-view mode="POS"></cart-view>
    </div>


    <div *ngIf="(field.type=='htmlEditor')" fxLayout="column" [style]="themeLabel()">
        {{field.label}}
        <div *ngIf="!editorOpen" style="cursor:pointer; margin: 4px; border: 1px solid lightgreen" pRipple
            (click)="openEditor()">Open Editor</div>

        <div *ngIf="editorOpen" style="width:100%; max-width: 90vw; margin:6px; padding:6px">
            <editor apiKey="kyiith4j5do4lxxcds8kr8siijwvuxpnqi2bf4klx9c48rxf" [init]="{ height:600, plugins: 'lists link table code help image wordcount',
            toolbar: 'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
        }" modelEvents="change input nodechange undo redo" [(ngModel)]="field.html"></editor>

            <button class="btn" style="text-align: center; padding: 9px; margin: 6px;" (click)="outputsEvent($event)"
                pRipple type="button">
                Save Edits
            </button>
            <button class="btn" style="text-align: center; padding: 9px; margin: 6px;" (click)="cancelEdits()" pRipple
                type="button">
                Cancel Edits
            </button>
        </div>

    </div>


    <div *ngIf="!field.mask && (field.type=='currency')" fxLayout="column" [style]="themeLabel()">
        {{field.label}}
        <input type="number" [allowEmpty]="true" [(ngModel)]="field.value" [style]="themeEntry()"
            (blur)="outputsEvent($event)" mode="currency" currency="USD" locale="en-US" />
    </div>

    <div *ngIf="field.type=='checkbox'">

        <input type="checkbox" id="me" ngDefaultControl [(ngModel)]="field.trueFalse" [binary]="true"
            inputId="binary" />
        <label for="me" [style]="themeLabel()">{{field.label}}</label>
    </div>

    <div *ngIf="!field.mask && (field.type=='switch')" fxLayout="column" fxLayoutAlign="center center">
        <div *ngIf="field.trueFalse" (click)="toggleCheckbox($event, field)" [style]="themeLabel()">
            {{field.label}}
            <div [style]="themeEntry()" style="font-weight:700">
                <span *ngIf="field.trueLabel">{{field.trueLabel}}</span>
                <span *ngIf="!field.trueLabel">Yes</span>
            </div>
        </div>
        <div *ngIf="!field.trueFalse" (click)="toggleCheckbox($event, field)" [style]="themeLabel()">
            {{field.label}}
            <div [style]="themeEntry()" style="font-weight:700">
                <span *ngIf="field.falseLabel">{{field.falseLabel}}</span>
                <span *ngIf="!field.falseLabel">No</span>
            </div>
        </div>
    </div>

    <div *ngIf="field.type=='button'" fxFlex fxLayout="row" style="text-align: center;">
        <div [style]="myButtonStyle()" fxLayout="column" [style.width]="getMyWidth(field.width)"
            [style.height]="getMyHeight(field.height)" (click)="outputsEvent($event)" pRipple>
            {{field.label}}
            <div *ngIf="field.event" style="font-size:smaller;">{{field.event}}</div>

        </div>

    </div>

    <div *ngIf="field.type=='map' && field.mapOptions" [id]="field.id" [style.width]="getMyWidth(field.width)"
        [style.height]="getMyHeight(field.height)">
        <app-maps [mapOptions]="field.mapOptions"></app-maps>
    </div>


    <div *ngIf="field.type=='weather' && field.binding" [style.width]="getMyWidth(field.width)"
        [style.height]="getMyHeight(field.height)">

        <span *ngIf="!isExpanded()" (click)="toggleExpand(true)">WEATHER</span>
        <span *ngIf="isExpanded()">

            <app-weather [location]="field.binding" [overFlow]="field.expanded"></app-weather>
        </span>

    </div>
    <div *ngIf="field.type=='napkinApp' && field.binding" [style.width]="getMyWidth(field.width)"
        [style.height]="getMyHeight(field.height)">

        <span *ngIf="!isExpanded()" (click)="toggleExpand(true)">APP</span>
        <span *ngIf="isExpanded()">
            <app-appMaker [appId]="field.binding" [overFlow]="field.expanded" [headLess]="true"></app-appMaker>
        </span>
    </div>
    <div *ngIf="field.type=='report' && field.binding" [style.width]="getMyWidth(field.width)"
        [style.height]="getMyHeight(field.height)">

        <span *ngIf="!isExpanded()" (click)="toggleExpand(true)">Report</span>
        <span *ngIf="isExpanded()">
            <app-reports [editReport]="false" [overFlow]="field.expanded" [reportName]="field.binding"></app-reports>

        </span>
    </div>
    <div *ngIf="field.type=='query' && field.binding" [style.width]="getMyWidth(field.width)"
        [style.height]="getMyHeight(field.height)">

        <span *ngIf="!isExpanded()" (click)="toggleExpand(true)">Report</span>
        <span *ngIf="isExpanded()">
            <app-queries [queryName]="field.binding"></app-queries>

        </span>
    </div>

    <div *ngIf="field.type=='fieldSelector'" [style.width]="getMyWidth(field.width)"
        [style.height]="getMyHeight(field.height)">
        <div *ngIf="field.label" [style]="themeLabel()">
            {{field.label}}
        </div>
        <app-field-selector [field]="field"></app-field-selector>

    </div>




    <div *ngIf="field.type=='box'" [style]="myButtonStyle()"
        style="text-align: center; width:100%; box-sizing: border-box;" (click)="outputsEvent($event)" pRipple>

        {{field.label}}


    </div>


    <div *ngIf="!field.mask && (field.type=='full-text' || field?.type?.toLowerCase()=='full text')" fxFlex
        fxLayout="column" [style]="themeLabel()">
        {{field.label}}
        <textarea rows="5" cols="30" [style.width]="getMyWidth(field.width)" [autoResize]="true"
            (blur)="outputsEvent($event)" [style]="themeEntry()" [(ngModel)]="field.binding"></textarea>
        <div *ngIf="!field.noSave  && !showSubmit">
            <div *ngIf="field.binding && field.binding.length>0">
                <button (click)="outputsEvent($event)" class="save-text" pRipple>Save</button>
            </div>
        </div>
    </div>

    <div *ngIf="!field.mask && (field.type=='star-rating')" fxLayout="column" fxLayoutAlign="center center"
        [style]="themeLabel()">
        {{field.label}}
        <p-rating [style]="themeEntry()" [(ngModel)]="field.value" (onRate)="outputsEvent($event)"
            [cancel]="false"></p-rating>
    </div>

    <!-- TAGS TAGS TAGS -->
    <div *ngIf="field.type=='tags' && field.class=='tagCloud'">
        <div *ngIf="field.options && field.options.length>0" fxFlex fxLayout="row wrap" fxLayoutAlign="center none">
            <div *ngFor="let d of field.options; let i = index;">
                <div class="tag-cloud">
                    <span [style.font-size]="getTagSize(field, i)">{{d}}</span>
                </div>
            </div>
        </div>

    </div>

    <div *ngIf="field.type=='tags' && field.class!='tagCloud'" fxLayout="column" [style]="themeLabel()">
        <div (click)="clickTags($event)">{{field.label}}</div>

        <div *ngIf="!editingTags && (!field.selected || (field.selected && field.selected.length==0))"
            [style]="getItemSelectStyle()" (click)="clickTags($event)">
            Select
        </div>
        <div *ngIf="!editingTags && field.selected && field.selected.length>0" [style]="themeEntry()">
            <div style="max-width: 20vw; max-width:100%;font-weight: 700;" (click)="clickTags($event)">
                {{field.selected}}
            </div>
        </div>

        <div fxFlex fxLayout="column">
            <div *ngIf="editingTags && field?.allowAdds" fxLayout="row" fxLayoutAlign="center center">
                <input style="width:15em; height: 32px;" class="search" type="search" [(ngModel)]="searchString"
                    pInputText placeholder="Add new option">
                <!--        <div *ngIf="searchString?.length>0" (click)="clearSearch()" class="command"> Clear </div>-->
                <div *ngIf="searchString?.length>0" (click)="addNewTag($event)"
                    style="height:100%; margin-left: 8px; border: 1px solid rgb(196, 235, 196)">Add</div>

            </div>
            <!-- ONLY ADDING TAGS NOT SELECTING -->
            <div *ngIf="field.addOnly && editingTags" fxFlex fxLayout="column" fxLayoutAlign="none none">
                <span style="font-size: smaller; text-align: center;">Available Options:</span>

                <div *ngIf="field.options && field.options.length" fxFlex fxLayout="row wrap"
                    fxLayoutAlign="none space-between">
                    <div *ngFor="let t of field.options; let i = index;"
                        style="padding:6px; margin:8px; background-color: rgb(236, 255, 231); border: 1px solid rgb(221, 179, 178);"
                        (click)="removeOption($event, i)">
                        {{t}}

                    </div>
                </div>
                <div style="width:100%; color: blue; font-size: .75rem; text-align: center;">Click Option to Remove
                </div>
            </div>

            <div *ngIf="editingTags" fxLayout="row" fxLayoutAlign="none none">
                <div *ngIf="!addOnly" fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:50%">

                    <span style="font-size: smaller; text-align: center;">Selected:</span>

                    <div *ngIf="1||field.selected && field.selected.length>0" fxFlex fxLayout="row wrap"
                        fxLayoutAlign="none space-between">
                        <div *ngFor="let d of field.selected; let i = index;">
                            <div [style]="getItemSelectStyle()" (click)="removeTag($event, d)" pRipple>
                                {{d}}
                            </div>
                        </div>
                    </div>

                </div>

                <div *ngIf="!field.addOnly" fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:50%">
                    <span style="font-size: smaller; text-align: center;">Available:</span>

                    <!-- AVAILABLE -->
                    <div *ngIf="field.type=='tags' && field.options && field.options.length" fxFlex fxLayout="row wrap"
                        fxLayoutAlign="none space-between">
                        <div *ngFor="let t of field.options; let i = index;">
                            <div *ngIf="tagFilter(t)">
                                <div *ngIf="!field.selected || (field.selected && !field.selected.includes(t))">
                                    <div (click)="addTag($event, t)" [style]="getItemSelectStyle()" pRipple
                                        type="button">
                                        {{t}}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="field.type=='equationBuilder'" fxFlex fxLayout="column" fxLayoutAlign="center none"
        [style]="themeLabel()">
        <span style="padding:0px" (click)="clickMulti($event)">{{field.label}}</span>
        <div style="text-align: center;cursor:pointer;border: 1px solid black;" [class]="field.class">

            <div fxLayout="row" style="width: 100%">
                <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:100%">
                    <div *ngIf="field.selected">
                        Equation:
                        <button (click)="clearQuery()"
                            style="padding: 6px; background-color: rgb(252, 233, 174); color:black" pButton pRipple
                            type="button">
                            Clear
                        </button>
                    </div>
                    <div *ngIf="field.selected && field.selected.length>0" fxFlex fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <div *ngFor="let d of field.selected; let i = index;">
                            <button *ngIf="i < field.selected.length-1" class="pretty-postag"
                                style="padding-left:6px;padding-right:6px; background-color: aquamarine; color:black"
                                pButton pRipple type="button">
                                {{getSelectedValue(d)}}
                            </button>


                            <!-- LAST ITEM CAN BE REMOVED -->
                            <button *ngIf="i == field.selected.length-1" class="pretty-postag"
                                style="padding-left:6px;padding-right:6px; border: 2px solid rgb(248, 216, 143); background-color: aquamarine; color:black"
                                pButton pRipple type="button" (click)="removeLastItem($event)">
                                {{getSelectedValue(d)}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="editingMulti">
                <div fxLayout="row" style="width: 100%">

                    <div style="width:100%" fxLayout="column" fxLayoutAlign="none none">
                        Available options:

                        <!-- AVAILABLE -->
                        <div *ngIf="checkEquationMode() == 0" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div *ngFor="let t of field.options; let i = index;">
                                <button (click)="addEQText($event, t)" class="eq-button" pButton pRipple type="button">
                                    {{t}}
                                </button>
                            </div>
                        </div>
                        <div *ngIf="checkEquationMode() == 1" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div>
                                <button (click)="addEQText($event, '=')" class="eq-button2" pButton pRipple
                                    type="button">
                                    =
                                </button>
                                <!--                   
                                <button (click)="addEQText($event, '*=')" class="eq-button2" pButton pRipple
                                    type="button">
                                    *=
                                </button>
                                <button (click)="addEQText($event, '+=')" class="eq-button2" pButton pRipple
                                    type="button">
                                    +=
                                </button>
                                <button (click)="addEQText($event, '-=')" class="eq-button2" pButton pRipple
                                    type="button">
                                    -=
                                </button>
                                -->
                            </div>
                        </div>

                        <div *ngIf="checkEquationMode() == 2" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">

                            <div fxLayout="row wrap" style="width: 100%">
                                <input class="value-field" type="search" [(ngModel)]="searchString" pInputText
                                    placeholder="Enter value">
                                <div *ngIf="searchString?.length>0" (click)="addEQText($event,searchString)"
                                    style="margin-left: 6px; padding: 6px; background-color: rgb(252, 233, 174); color:black">
                                    Add Value
                                </div>
                            </div>
                            <div fxLayout="column">
                                Available fields from this form
                                <div fxLayout="row wrap" style="width: 100%">

                                    <div *ngFor="let t of field.options; let i = index;">
                                        <button (click)="addEQField($event, t)" class="eq-button" pButton pRipple
                                            type="button">
                                            {{t}}
                                        </button>
                                    </div>
                                </div>

                                Numeric fields from other forms
                                <div fxLayout="row wrap" style="width: 100%">
                                    <div *ngFor="let t of field.options2; let i = index;">
                                        <button (click)="addEQOtherField($event, t)" class="eq-button" pButton pRipple
                                            type="button">
                                            {{t.title}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="checkEquationMode() == 3" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div>
                                <button (click)="addEQText($event, '+')" class="eq-button2" pButton pRipple
                                    type="button">
                                    +
                                </button>
                                <button (click)="addEQText($event, '*')" class="eq-button2" pButton pRipple
                                    type="button">
                                    *
                                </button>
                                <button (click)="addEQText($event, '/')" class="eq-button2" pButton pRipple
                                    type="button">
                                    /
                                </button>
                                <button (click)="addEQText($event, '-')" class="eq-button2" pButton pRipple
                                    type="button">
                                    -
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <button (click)="outputsEvent($event)" class="save-text" pRipple>Cancel</button>

                <button (click)="outputsEvent($event)" class="save-text" pRipple>Save</button>

            </div>
        </div>


    </div>

    <div *ngIf="field.type=='functionBuilder'" fxFlex fxLayout="column" fxLayoutAlign="center none"
        [style]="themeLabel()">
        <span style="padding:0px" (click)="clickMulti($event)">{{field.label}}</span>
        <div style="text-align: center;cursor:pointer;border: 1px solid black;" [class]="field.class">

            <div fxLayout="row" style="width: 100%">
                <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:100%">
                    <div *ngIf="field.selected">
                        Function:
                        <button (click)="clearQuery()"
                            style="padding: 6px; background-color: rgb(252, 233, 174); color:black" pButton pRipple
                            type="button">
                            Clear
                        </button>
                    </div>
                    <div *ngIf="field.selected && field.selected.length>0" fxFlex fxLayout="row wrap"
                        fxLayoutAlign="start start">
                        <div *ngFor="let d of field.selected; let i = index;">
                            <button *ngIf="i < field.selected.length-1" class="pretty-postag"
                                style="padding-left:6px;padding-right:6px; background-color: aquamarine; color:black"
                                pButton pRipple type="button">
                                {{getSelectedValue(d)}}
                            </button>


                            <!-- LAST ITEM CAN BE REMOVED -->
                            <button *ngIf="i == field.selected.length-1" class="pretty-postag"
                                style="padding-left:6px;padding-right:6px; border: 2px solid rgb(248, 216, 143); background-color: aquamarine; color:black"
                                pButton pRipple type="button" (click)="removeLastItem($event)">
                                {{getSelectedValue(d)}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="editingMulti">
                <div fxLayout="row" style="width: 100%">

                    <div style="width:100%" fxLayout="column" fxLayoutAlign="none none">
                        Available options:

                        <!-- AVAILABLE -->
                        <div *ngIf="checkFunctionMode() == 0" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div *ngFor="let t of field.options; let i = index;">
                                <button (click)="addEQText($event, t)" class="eq-button" pButton pRipple type="button">
                                    {{t}}
                                </button>
                            </div>
                        </div>
                        <div *ngIf="checkFunctionMode() == 1" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div>
                                <button (click)="addEQText($event, '=')" class="eq-button2" pButton pRipple
                                    type="button">
                                    =
                                </button>
                            </div>
                        </div>

                        <div *ngIf="checkFunctionMode() == 2" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <!--
                            <div fxLayout="row wrap" style="width: 100%">
                                <input class="value-field" type="search" [(ngModel)]="searchString" pInputText
                                    placeholder="Enter value">
                                <div *ngIf="searchString?.length>0" (click)="addEQText($event,searchString)"
                                    style="margin-left: 6px; padding: 6px; background-color: rgb(252, 233, 174); color:black">
                                    Add Value
                                </div>
                            </div>
                            -->
                            <div fxLayout="column">
                                Function Tags
                                <div fxLayout="row wrap">
                                    <div *ngFor="let t of global.functionTags; let i = index;">
                                        <button (click)="selectFunctionTag($event, t, i)" class="eq-button" pButton
                                            pRipple type="button">
                                            {{t}}
                                        </button>
                                    </div>
                                </div>

                                Functions
                                <div fxLayout="row wrap">
                                    <div *ngFor="let t of global.functions; let i = index;">
                                        <div *ngIf="isFunctionTag(t)">
                                            <button (click)="addFunction($event, t, i)" class="eq-button" pButton
                                                pRipple type="button">
                                                {{t.name}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div *ngIf="checkFunctionMode() >= 3 && functionIndex >= 0" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div *ngIf="global.functions[functionIndex]?.parameters">
                                {{global.functions[functionIndex].parameters[
                                field.selected.length-3].label}}
                                <!-- parameterType: number -->
                                <div *ngIf="1 || global.functions[functionIndex].parameters[
                                field.selected.length-3].type=='number'">

                                    <div fxLayout="row wrap" style="width: 100%">
                                        <input class="value-field" type="search" [(ngModel)]="searchString" pInputText
                                            placeholder="Enter value">
                                        <div *ngIf="searchString?.length>0" (click)="addEQText($event,searchString)"
                                            style="margin-left: 6px; padding: 6px; background-color: rgb(252, 233, 174); color:black">
                                            Add Value
                                        </div>
                                    </div>
                                    <!--              
                                    <div *ngFor="let t of field.options; let i = index;">
                                        <button (click)="addEQText($event, t)" class="eq-button" pButton pRipple
                                            type="button">
                                            {{t}}
                                        </button>
                                    </div>
                                -->

                                    <div *ngIf="global.functions[functionIndex].parameters[
                                    field.selected.length-3].allowFields" fxLayout="column">
                                        Available fields from this form
                                        <div fxLayout="row wrap" style="width: 100%">

                                            <div *ngFor="let t of field.options; let i = index;">
                                                <button (click)="addEQField($event, t)" class="eq-button" pButton
                                                    pRipple type="button">
                                                    {{t}}
                                                </button>
                                            </div>
                                        </div>

                                        Numeric fields from other forms
                                        <div fxLayout="row wrap" style="width: 100%">
                                            <div *ngFor="let t of field.options2; let i = index;">
                                                <button (click)="addEQOtherField($event, t)" class="eq-button" pButton
                                                    pRipple type="button">
                                                    {{t.title}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="global.functions[functionIndex].parameters[
                                field.selected.length-3].type=='form'">
                                    <div *ngFor="let t of global.allFormNames; let i = index;">
                                        <button (click)="addEQText($event, t)" class="eq-button" pButton pRipple
                                            type="button">
                                            {{t}}
                                        </button>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>
                </div>
                <button (click)="outputsEvent($event)" class="save-text" pRipple>Cancel</button>

                <button (click)="outputsEvent($event)" class="save-text" pRipple>Save</button>

            </div>
        </div>


    </div>






    <div *ngIf="field.type=='queryBuilder'" fxFlex fxLayout="column" fxLayoutAlign="center none" [style]="themeLabel()">

        <span style="padding:0px">{{field.label}}</span>
        <div style="text-align: center;cursor:pointer;border: 1px solid black;" [class]="field.class">

            <div *ngIf="field.selected && field.selected.length>0">
                <div (click)="clickMulti($event)">{{field.selected}}</div>
            </div>
            <div *ngIf="!field.selected || (field.selected && field.selected.length==0)">
                <div (click)="clickMulti($event)">Select</div>
            </div>
            <div *ngIf="editingMulti">
                <div fxLayout="row" style="width: 100%">
                    <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:50%">
                        <div *ngIf="field.selected">
                            Query:
                            <button (click)="clearQuery()" style="background-color: rgb(252, 233, 174); color:black"
                                pButton pRipple type="button">
                                Clear
                            </button>
                        </div>
                        <div *ngIf="field.selected && field.selected.length>0" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <div *ngFor="let d of field.selected; let i = index;">
                                <button class="pretty-postag" style="background-color: aquamarine; color:black" pButton
                                    pRipple type="button">
                                    {{d}}
                                </button>
                            </div>
                        </div>

                    </div>
                    <div style="width:50%" fxLayout="column" fxLayoutAlign="none none">
                        Available options:

                        <!-- AVAILABLE -->
                        <div *ngIf="checkQueryMode() == 0" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div *ngFor="let t of field.options; let i = index;">
                                <button (click)="addTag($event, t)" class="pretty-postag" pButton pRipple type="button">
                                    {{t}}
                                </button>
                            </div>
                        </div>
                        <div *ngIf="checkQueryMode() == 1" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div>
                                <button (click)="addTagText($event, '==')" class="pretty-postag" pButton pRipple
                                    type="button">
                                    ==
                                </button>
                                <button (click)="addTagText($event, '!=')" class="pretty-postag" pButton pRipple
                                    type="button">
                                    !=
                                </button>
                            </div>
                        </div>

                        <div *ngIf="checkQueryMode() == 2" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div>
                                <div fxLayout="column" style="width: 100%">
                                    <input class="search" type="search" [(ngModel)]="searchString" pInputText
                                        placeholder="Enter value">
                                    <div *ngIf="searchString?.length>0" (click)="addTagText($event,searchString)"
                                        class="command"> Add Value

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="checkQueryMode() == 3" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div>
                                <button (click)="addTagText($event, 'AND')" class="pretty-postag" pButton pRipple
                                    type="button">
                                    AND
                                </button>
                                <button (click)="addTagText($event, 'OR')" class="pretty-postag" pButton pRipple
                                    type="button">
                                    OR
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="(field.type=='multi-select' || field.type=='formSelect') && (field.options || field.selected)" fxFlex
        fxLayout="column" fxLayoutAlign="center none" [style]="themeLabel()">

        <div (click)="clickMulti($event)">{{field.label}}</div>
        <div style="text-align: center;cursor:pointer;">
            <div *ngIf="!editingMulti && (!field.selected || (field.selected && field.selected.length==0))"
                [style]="getItemSelectStyle()" (click)="clickMulti($event)">
                Select
            </div>
            <div *ngIf="!editingMulti && field.selected && field.selected.length>0" [style]="themeEntry()">
                <div (click)="clickMulti($event)" style="max-width: 20vw; max-width:100%;font-weight: 700;">
                    {{field.selected}}</div>
            </div>

            <div *ngIf="editingMulti">
                <div *ngIf="field?.allowAdds" fxLayout="row" style="width: 100%">
                    <input style="width:15em;" class="search" type="search" [(ngModel)]="searchString" pInputText
                        placeholder="Search or Add New">
                    <!--            <div *ngIf="searchString?.length>0" (click)="clearSearch()" class="command">Clear</div>-->
                    <div *ngIf="searchString?.length>0" (click)="addNewTag($event)" class="command"> Add New
                        Tag </div>

                </div>
                <div fxLayout="row" style="width: 100%">


                    <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width:50%">
                        <div *ngIf="field.selected">
                            Selected options:
                        </div>
                        <div *ngIf="field.selected && field.selected.length>0" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="start start">
                            <div *ngFor="let d of field.selected; let i = index;">
                                <button (click)="removeTag($event, d)" class="pretty-postag" pButton pRipple
                                    type="button">
                                    {{d}}
                                </button>
                            </div>
                        </div>

                    </div>
                    <div style="width:50%" fxLayout="column" fxLayoutAlign="none none">
                        Available options:

                        <!-- AVAILABLE -->
                        <div *ngIf="field.type=='multi-select' && field.options" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div *ngFor="let t of field.options; let i = index;">
                                <div *ngIf="tagFilter(t)">
                                    <div *ngIf="!field.selected || (field.selected && !field.selected.includes(t))">
                                        <button (click)="addTag($event, t)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            {{t}}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="field.type=='formSelect' && global.allFormNames" fxFlex fxLayout="row wrap"
                            fxLayoutAlign="none space-between">
                            <div *ngFor="let t of global.allFormNames; let i = index;">
                                <div *ngIf="tagFilter(t)">
                                    <div *ngIf="!field.selected || (field.selected && !field.selected.includes(t))">
                                        <button (click)="addTag($event, t)" class="pretty-postag" pButton pRipple
                                            type="button">
                                            {{t}}
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="field.type=='displayImage'" fxLayout="column" fxLayoutAlign="center center">

        <div *ngIf="field.media">
            <div *ngIf="field.label" [style]="themeLabel()">
                {{field.label}}
            </div>


            <!--
            <div *ngIf="field.media[0].fileType && (field.media[0].fileType=='video' || field.media[0].fileType=='video/mp4' 
            || field.media[0].fileType=='video/quicktime')">

                <video style="max-height: 400px; max-width: 100%;" autoplay playsinline webkit-playsinline
                    [muted]="'muted'" #videoall>
                    <source src="{{field.media[0].url}}" type="video/mp4">
                </video>
            </div>
            -->

            <div *ngIf="!field.media[0].fileType || field.media[0].fileType=='image/jpeg' || 
            field.media[0].fileType=='image/png' || field.media[0].fileType=='photo'">
                <img [style.height]="getMyHeight(field.height)" [style.width]="getMyWidth(field.width)"
                    style="object-fit: cover;" src={{field.media[0].url}} />
            </div>
        </div>
    </div>


    <div *ngIf="!field.mask && (field.type=='capture-media')" style="max-width:100vw;" fxLayout="column"
        fxLayoutAlign="center center" [style]="themeLabel()">
        {{field.label}}


        <input class="inputfile" name="file" id="file" type="file"
            accept="audio/mp3;image/jpeg;image/png;capture=camera" (change)="upload($event, newMsg)">
        <label for="file" [style]="getItemSelectStyle()">Select</label>


        <div *ngIf="uploadingMedia">
            <span style="text-align: center;">Media is uploading to cloud...</span>
        </div>

        <div *ngIf="field.media">
            <div *ngIf="field.media[0].fileType && (field.media[0].fileType=='video' || field.media[0].fileType=='video/mp4' 
        || field.media[0].fileType=='video/quicktime')">

                <video style="max-height: 400px; max-width: 100%;" autoplay playsinline webkit-playsinline
                    [muted]="'muted'" #videoall>
                    <source src="{{field.media[0].url}}" type="video/mp4">
                </video>
            </div>

            <div *ngIf="!field.media[0].fileType || field.media[0].fileType=='image/jpeg' || 
        field.media[0].fileType=='image/png' || field.media[0].fileType=='photo'">
                <img style="max-height: 400px; max-width: 100%;" src={{field.media[0].url}} />
            </div>

        </div>

    </div>




    <div *ngIf="!field.type=='selectMedia'">
        <div *ngIf="field.media && field.media[0].fileType=='audio/mpeg'">
            <audio id="audio" controls>
                <source src={{field.media[0].url}} id="src" />
            </audio>
        </div>

    </div>

    <div *ngIf="field.type=='domain'" fxLayout="column" [style]="themeLabel()">
        {{field.label}}
        <input pInputText placeholder="" [(ngModel)]="field.binding" />
    </div>

    <div *ngIf="field.type=='inputSize'" [style]="themeLabel()">
        {{field.label}}
        <input pInputText placeholder="ex; 100px, 25%, 100%" (change)="outputsEvent($event)"
            [(ngModel)]="field.binding" />
    </div>

    <div *ngIf="field.type=='colorChooser'" [style]="themeLabel()" style="padding: 4px;">
        {{field.label}}
        <div [style]="themeEntry()" [style]="getColorBinding()">

            <ngx-colors style="z-index:20000!important" ngx-colors-trigger [(ngModel)]="field.binding"
                (input)="outputsEvent($event)"></ngx-colors>
        </div>





    </div>



    <div *ngIf="field.type=='paletteSelect'">
        <div fxFlex fxLayout="column" fxLayoutAlign="center center" *ngFor="let p of global.allPalettes; let i = index;"
            style="border: 2px black solid; margin: 8px;" [style.background-color]="selectedColor(p, field.palette)"
            (click)="paletteSelected($event,p,i)">
            <div fxFlex fxLayout="row wrap" fxLayoutAlign="none none">
                <div *ngFor="let c of p.colors; let x = index;" [style.width.px]="field?.width"
                    [style.height.px]="field?.height" [style.background-color]="c.hex"
                    style="border: 1px black solid; margin: 8px;">
                </div>
            </div>

        </div>

    </div>

    <div *ngIf="field.type=='email'" [style]="themeLabel()" fxLayout="column">
        {{field.label}}

        <input [style]="themeEntry()" [(ngModel)]="field.binding" />
        <!--
            <button *ngIf="field?.binding?.length && field?.emailLookup" (click)="outputsEvent($event)"
                class="show-selected" pRipple>Next</button>
         -->

    </div>

    <div *ngIf="field.type=='selectMedia'" fxFlex fxLayout="column" fxLayoutAlign="center none" style="width: 100%"
        [style]="themeLabel()">

        <div *ngIf="!showAll">
            <span style="padding:0px">{{field.label}}</span>

            <div *ngIf="!field.binding && !optionsEnabled" [style]="getItemSelectStyle()"
                (click)="optionsEnable($event)" pRipple>
                Select</div>

            <div *ngIf="field.media && !optionsEnabled" (click)="optionsEnable($event)" [class]="field.class" pRipple>
                <div *ngIf="field.media[0].fileType && (field.media[0].fileType=='video' || field.media[0].fileType=='video/mp4' 
                || field.media[0].fileType=='video/quicktime')">

                    <video style="max-height: 400px; max-width: 100%;" autoplay loop playsinline webkit-playsinline
                        [muted]="'muted'" #videoall>
                        <source src="{{field.media[0].url}}" type="video/mp4">
                    </video>
                </div>

                <div *ngIf="!field.media[0].fileType || field.media[0].fileType=='image/jpeg' || 
                field.media[0].fileType=='image/png' || field.media[0].fileType=='photo'">
                    <img style="max-height: 400px; max-width: 100%;" src={{field.media[0].url}} />
                </div>

            </div>

            <div *ngIf="optionsEnabled">

                <p-sidebar [(visible)]="sidebarVisible" [fullScreen]="true">
                    <div fxFlex fxLayout="row wrap" fxLayoutAlign="center center" style="width: 100%; max-width:100vw">
                        <div *ngFor="let media of global.media ; let mi = index">
                            <div (click)="mediaSelect(media, mi)" pRipple>

                                <div *ngIf="media.fileType && (media.fileType=='video' || media.fileType=='video/mp4' 
                                || media.fileType=='video/quicktime')">

                                    <video style="width: 100px; width: 100px;" playsinline webkit-playsinline
                                        [muted]="'muted'" #videoall>
                                        <source src="{{media.url}}" type="video/mp4">
                                    </video>
                                </div>

                                <div *ngIf="!media.fileType || media.fileType=='image/jpeg' || 
                                        media.fileType=='image/png' || media.fileType=='photo'">
                                    <img style="width: 100px; width: 100px;" src={{media.url}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </p-sidebar>
                <p-button (click)="sidebarVisible = true" icon="pi pi-th-large"></p-button>
            </div>
        </div>
    </div>

    <div *ngIf="field.type=='Options' || field.type=='time' ||field.type=='select-button'">
        <div fxLayout="column" [style.width]="getMyWidth(field.width)" [style]="themeLabel()"
            (click)="optionsEnable($event)">
            {{field.label}}
            <div *ngIf="!optionsEnabled">
                <div *ngIf="!field.binding" pRipple [style]="getItemSelectStyle()">Select</div>


                <div *ngIf="field.binding && !field?.binding?.title" pRipple [style]="themeEntry()"
                    style="font-weight: 700;">{{getMyLabel(field,
                    field.binding)}}
                </div>



                <button *ngIf="field.binding && field?.binding?.title" pButton class="show" pRipple fxLayout="column">
                    <div>{{field.binding.title}}</div>
                    <div style="font-size:smaller">{{field.binding.subTitle}}</div>
                </button>



                <div *ngIf="field.binding=='Value'">
                    <input type="number" mode="decimal" [allowEmpty]="true" [maxFractionDigits]="3"
                        [(ngModel)]="field.value" [style]="themeEntry()" />
                    <!--
                <p-inputNumber mode="decimal" [allowEmpty]="true" [maxFractionDigits]="3"
                    [(ngModel)]="field.value"></p-inputNumber>
                    -->
                </div>
            </div>
            <div *ngIf="optionsEnabled" fxFlex fxLayout="row wrap" fxLayoutAlign="center center">

                <div *ngFor="let option of field.options; let i = index">
                    <div (click)="optionSelect($event, option, i)" [style.width]="field?.width"
                        [style.height]="field?.height" [style]="getItemSelectStyle()" pRipple>{{option}}</div>

                </div>
                <div *ngFor="let option of field.options2; let i = index">

                    <div pButton class="show" (click)="optionSelect($event, option, i)" pRipple fxLayout="column">
                        <div>{{option.title}}</div>
                        <div style="font-size:smaller">{{option.subTitle}}</div>
                    </div>

                    <!--
                        <button *ngIf="field.binding==option || field.name==option" (click)="optionSelect($event, option, i)"
                            class="show-selected" pRipple>{{option}}</button>
                        <button *ngIf="field.binding!=option && field.name!=option" (click)="optionSelect($event, option, i)"
                            class="show" pRipple>{{option}}</button>

                        -->
                </div>

            </div>

        </div>

        <div *ngIf="showAll" fxFlex fxLayout="row wrap" fxLayoutAlign="center center">
            <div *ngFor="let option of field.options; let i = index">
                <button *ngIf="field.binding==option || field.name==option" (click)="optionSelect($event, option, i)"
                    class="show-selected" pRipple>{{option}}</button>
                <button *ngIf="field.binding!=option && field.name!=option" (click)="optionSelect($event, option, i)"
                    class="show" pRipple>{{option}}</button>
            </div>
        </div>
    </div>
    <!--

    <div *ngIf="field.type=='time'" fxLayout="column" fxLayoutAlign="center center">
        <button *ngIf="!field.binding && !optionsEnabled" (click)="optionsEnable($event)" class="show" pRipple>
            {{field.title}}</button>

        <button *ngIf="field.binding && !optionsEnabled" (click)="optionsEnable($event)" class="show" pRipple>{{field.title+"
            "+field.binding}}</button>

        <div *ngIf="optionsEnabled" fxFlex fxLayout="row wrap" fxLayoutAlign="center center">
            <div *ngFor="let option of field.options; let i = index">
                <button *ngIf="field.binding==option || field.name==option" (click)="optionSelect($event, option, i)"
                    class="show-selected" pRipple>{{option}}</button>
                <button *ngIf="field.binding!=option && field.name!=option" (click)="optionSelect($event, option, i)" class="show"
                    pRipple>{{option}}</button>
            </div>
        </div>
    </div>

-->


    <div *ngIf="field.type=='pw'" fxLayout="column" fxLayoutAlign="center center" style="max-width: 100vw; "
        [style]="themeLabel()">
        Password<span *ngIf="field.required">**</span>

        <div fxLayout="row wrap" [style]="themeEntry()">
            <input (input)="pwChanged($event)" [(ngModel)]="field.value" />

            <button *ngIf="field.show==false" style="margin-left:6px; padding:0px 4px;" (click)="showButton()"
                pRipple>Show</button>
            <button *ngIf="field.show==true" style="margin-left:6px;padding:0px 4px;" (click)="hideButton()"
                pRipple>Hide</button>
        </div>

        <div *ngIf="!forgotStr"
            style="position:absolute;bottom:3px; font-size: 11px; cursor: pointer; text-align: center;background-color: rgb(254, 251, 241);"
            (click)="ForgotPassword()">Click here if you forgot password?
        </div>
        <div *ngIf="forgotStr" style="text-align: center;margin-top:12px">Reminder email
            sent.
        </div>

    </div>

</div>