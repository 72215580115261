<div *ngIf="form?.report">
    <app-reports [editReport]="false" [report]="form.report"></app-reports>
</div>


<!--
<div *ngIf="display=='container'" fxFlex fxLayout="column" fxLayoutAlign="none none">
    <div *ngIf="submissions" fxLayout="column" fxLayoutAlign="none center">

        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
            *ngFor="let form of submissions; let i = index" fxLayout="row wrap" fxLayoutAlign="center center"
            class="row-style">
            <p-accordionTab iconPos="end">
                <ng-template pTemplate="header">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div class="time-stamp">{{timeStamp(form.timeStamp)}}</div>
                        <div class="user-name"> {{form.userName}}</div>
                        <div (click)="loadFormData($event, form)" pRipple>Edit this</div>

                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngFor="let field of form.fields; let fi = index" class="{{field.class}}">
                        <div *ngIf="field.type!='number'">
                            {{field.binding}}
                        </div>
                        <div *ngIf="field.type=='number'">
                            {{field.value}}
                        </div>
                        <div *ngIf="field.type=='multi-select'">
                            <div *ngFor="let item of field.selected">
                                {{item}}
                            </div>
                        </div>

                        <div *ngIf="field.media">
                            <div *ngIf="field.media[0].fileType=='audio/mpeg'">
                                <audio id="audio" controls>
                                    <source src={{field.media[0].url}} id="src" />
                                </audio>
                            </div>
                            <div *ngIf="field.media[0].fileType!='audio/mpeg'">

                                <img src="{{field.media[0].url}}" style="height: 100px;" />

                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>

    </div>


</div>

<div *ngIf="display=='flat'" fxFlex fxLayout="column" fxLayoutAlign="none none">
    <div *ngIf="submissions" fxLayout="column" fxLayoutAlign="none none">
        <div id="formtop" fxFlex fxLayout="column" fxLayoutAlign="none none">
            <div *ngFor="let form of submissions; let i = index" fxLayout="row" fxLayoutAlign="none center"
                (click)="loadFormData($event, form)" class="row-style" pRipple>

                <div fxLayout="column" style="max-width: 10%;">
                    <div class="time-stamp">{{timeStamp(form.timeStamp)}}</div>
                    <div class="user-name"> {{form.userName}}</div>
                </div>
                <div *ngFor="let field of form.fields; let fi = index" class="{{field.reportClass}}">
                    <div *ngIf="field.type!='number'">
                        {{field.binding}}
                    </div>
                    <div *ngIf="field.type=='number'">
                        {{field.value}}
                    </div>
                    <div *ngIf="field.type=='multi-select'">
                        <div *ngFor="let item of field.selected">
                            {{item}}
                        </div>
                    </div>

                    <div *ngIf="field.media">
                        <div *ngIf="field.media[0].fileType=='audio/mpeg'">
                            <audio id="audio" controls>
                                <source src={{field.media[0].url}} id="src" />
                            </audio>
                        </div>
                        <div *ngIf="field.media[0].fileType!='audio/mpeg'">

                            <img src="{{field.media[0].url}}" style="height: 100px;" />
                        </div>

                
</div>
</div>

</div>
</div>
</div>
</div>


<div *ngIf="!submissions || submissions?.length ==0" fxLayout="column" fxLayoutAlign="center center"
    style="width:100%; text-align: center;">
    This Form is not saving data. Enable "Save Form Data" in Settings.
</div>
-->