/*
Get General Settings from global.company
Get Availability from Tables based on party size (tables min/max)
  minus existing reservations:

  Step 1: establish reservation w/o any availability checking - take all
  based on General Settings only.


  Create array of time slots based on General Settings (later add availability)

*/


import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Duration, Review, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate } from '../../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../cart.service';
import { CustomizeMessageService, MessageState } from '../../customize-message.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../nav.service';
import { environment } from '../../../../environments/environment';
import { Table, GalleryImages } from '../../product-int'
import { Follows, User } from '../../user-interface';
import { ViewportScroller } from "@angular/common";
import { HttpClient } from '@angular/common/http';
//import { MapGeocoder } from '@angular/google-maps';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NgAuthService } from 'src/app/ng-auth.service';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { A11yModule } from '@angular/cdk/a11y'
import { Logger } from '../../../functions';
import { Product } from '../../product-int'
import { table } from 'console';
import { ReviewsComponent } from '../../reviews/reviews.component';
import { EmailService } from '../../email.service';
import { set, startOfDay, endOfDay, getMilliseconds } from "date-fns";


export interface Occasion {
  name: string;
  code: string;
}

@Component({
  selector: 'app-reserve-beta',
  templateUrl: './reserve2.component.html',
  styleUrls: ['./reserve.component.css', '../../../common.scss'],
  providers: [MessageService, RegisterService]

})

export class ReserveComponentBeta implements OnInit {
  @Input() theStore: string;
  @Input() showUser: boolean = true;
  @Input() showHeader: boolean = true;
  @Input() getAllRes: boolean = false;
  @Input() adminRes: boolean = false;
  @Input() openOnly: boolean = false;
  @Output() packageEvent = new EventEmitter<Product>();
  @Output() rewardEvent = new EventEmitter<Product>();

  @ViewChild(ReviewsComponent, { static: false }) viewReviews: ReviewsComponent;

  successMsg: boolean = false;
/*
  center: google.maps.LatLngLiteral = { lat: 33.04894, lng: -116.809 };
  zoom = 15;
*/
  invalidDates: Array<Date>
  invalidTimeSpots: Array<Date>;   // Date + Times

  date5: Date;
  rangeDates: Date[];
  note: string;
  thisRes: reservation;
  userDataValid: boolean = false;
  forgotStr: string;
  newResText: string;

  tUser: User;
  loginError: boolean = false;
  minDate: Date;
  notifyUsers: User[];

  rev: Review[];
  show: boolean = false;
  maxDate: Date;
  selectedTime: resTime;
  viewTimes: boolean = false;

  timeStart: number;  // time number as minutes from midnight
  timeLast: number;   // Last seating time

  availableTimes: resTime[];
  availableTimes2: resTime[][];

  availableTables: Table[];
  showPhotos: boolean[];  // Allow auto-show of tables
  showOpenRes: boolean = true;
  allSet: boolean = true;

  partySize: number[] = [];
  selectedSize: number = 0;
  viewPartySize: boolean = true;
  selectedSizeUnder21: number = 0;
  selectedReward: Product;
  viewGroupTab: boolean = false;
  selectedPackage: Product;
  viewGroupPackages: boolean = false;

  groupMode: boolean = false;

  reserveFirstName: string;
  reserveLastName: string;
  reserveEmail: string;
  reservePhone: string;
  pw: string;

  addNote: boolean = false;
  newRes: boolean = false;
  manageRes: boolean = false;
  changeRes: boolean = false;
  foundUser: boolean = false;
  inputCreds: boolean = false;
  inputCredsnoUser: boolean = false;
  callNowActive: boolean = false;
  finalReview: boolean = false;

  reservingYourTable: boolean = false;

  cancelThisRes: reservation;
  editingRes: reservation;
  reservationList: reservation[];

  store: string;
  datePreset: string;
  newUserID: string;
  selectedMessage: any;
  selectedOptions: string[];
  selectedTable: Table;
  closingTime: number = 0;

  vCode: number = 0;
  chatStarted: boolean = false;



  allOccasions: Occasion[];
  selOccasion: string;
  storeNum: string;

  activeTable: boolean[];
  viewTables: boolean = true;
  viewCalendar: boolean = true;
  viewCompany: boolean = false;

  apiLoaded: Observable<boolean>;

  //  map: google.maps.Map;
  showMap: boolean = false;
  /*
  mapOptions: google.maps.MapOptions = {
    center: { lat: 33.048943, lng: -116.809 },
    zoom: 16
  };

*/
  gallery: GalleryImages[];
  vcode = "";

  constructor(httpClient: HttpClient,
   // private geocoder: MapGeocoder,
    public global: Globals,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public nav: NavService,
    public emailService: EmailService,
    private sharedDataService: SharedDataService,
    public customizeMessageService: CustomizeMessageService,
    private scroller: ViewportScroller,
    private router: Router,
    public ngAuthService: NgAuthService,

  ) {

    this.apiLoaded = httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=AIzaSyCruSAzxKo0RTvYiBRsgrNr0d5wWFT7rfU', 'callback')
      .pipe(
        map(() => true),
        catchError(() => of(false)),
      );


    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER
    this.storeNum = this.route.snapshot.paramMap.get('store')


    this.store = this.route.snapshot.queryParamMap.get('store'); // this.name = 'testing'
    //    if (!this.store) this.store = "4390";
    if (this.storeNum) this.store = this.storeNum;
    this.global.store = this.store;

    //  this.datePreset = "05/08/2022"; // route.snapshot.queryParamMap.get('date');
    console.log("Reserve Date ", this.datePreset, this.store, this.storeNum);



    this.allOccasions = [
      { name: 'Fathers Day', code: 'FD' },
      { name: 'Birthday', code: 'NY' },
      { name: 'Anniversary', code: 'RM' },
      { name: 'First date', code: 'LDN' },
      { name: 'Proposal', code: 'IST' }
    ];


  }

  ngOnInit(): void {
    let self = this;
    this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value! HACK HACK
    this.storeNum = this.route.snapshot.paramMap.get('store')
    if (this.theStore) this.storeNum = this.theStore;

    console.log("oninit store: ", this.storeNum);
    if (this.theStore) this.storeNum = this.theStore;

    this.vcode = this.route.snapshot.queryParamMap.get('v'); // this.name = 'testing'
    console.log("V-code: ", this.vcode);

    console.log("Init Reservations ", this.storeNum);

    //this.global.loadingStore = true;
    var direct = this.route.snapshot.queryParamMap.get('p'); // direct Login

    this.route.queryParams.subscribe(params => {
      direct = params['p'];
    });
    console.log("DLog: ", direct);




    this.InitReservations();

    if (this.adminRes) this.newRes = false;

    //   console.log(this.global.myCompany.reserveSettings);
    if (this.global.authuser) {
      // console.log(this.global.myCompany.reserveSettings);
      // this.global.loadingReservations = true;
      if (this.showHeader)
        self.nav.getReservations(this.storeNum, false, this.haveReservations, this);
      else
        self.nav.getReservations(this.storeNum, false, this.haveReservations, this);
      // this.newResText =  this.global.myCompany.name;
      self.reserveEmail = self.global.authuser.email;

    }
    else {
      // As ENTRY-POINT
      console.log("Res as Entry");

      firebase.initializeApp(environment.firebase);
    }



    // CHECK IF USER/DEVICE IS REGISTERED
    var id = localStorage.getItem('uid');
    if (id && !this.global.authuser) {
      // Remove query params
      this.router.navigate([], {    // REMOVE dcode
        queryParams: {
          'p': null,
        },
        queryParamsHandling: 'merge'
      })
      this.nav.GetUserwID(id, false, this.haveReservations, this, this.afterUserLoad);
    }
    else if (direct) {
      console.log("Direct: ", direct)
      Logger("Direct Login Reservation", direct, this.storeNum);

      // Remove query params
      this.router.navigate([], {    // REMOVE dcode
        queryParams: {
          'p': null,
        },
        queryParamsHandling: 'merge'
      })
      // get UID from directdb
      this.getDirectUID(direct);

    }
    else if (this.vcode) { this.validateUser(); }
    if (!id) {
      this.newRes = true;

      this.newResText = "";
      if (this.global.myCompany && this.global.myCompany.name)
        this.newResText += this.global.myCompany.name
      if (!this.global.myCompany)
        this.nav.GetStore(this.global.store, self.newReservation, null, self);
    }



    if (!this.global.myCompany || this.global.myCompany.store != this.storeNum)
      this.nav.GetStore(this.storeNum, self.newReservation, null, self);
    else { this.newResText = this.global.myCompany.name }


    if (this.global.authuser) id = this.global.authuser.uid;
    Logger("Reservation Started", id, this.storeNum);


    this.gallery = [];
    let gImage: GalleryImages = {
      previewImageSrc: "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FMainPatio1.jpg?alt=media&token=cfecdf73-290e-483c-ae40-25b09aea1aa8",
      thumbnailImageSrc: "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FMainPatio1.jpg?alt=media&token=cfecdf73-290e-483c-ae40-25b09aea1aa8",
      alt: "werewrewr",
      title: "erewrewrewr"
    }
    this.gallery.push(gImage);

    let gImage1: GalleryImages = {
      previewImageSrc: "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FYellowFireTable.jpg?alt=media&token=48f8f378-40e2-4363-9405-b2a9115b04ba",
      thumbnailImageSrc: "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FYellowFireTable.jpg?alt=media&token=48f8f378-40e2-4363-9405-b2a9115b04ba",
      alt: "wrwrer",
      title: "dfererre"
    }
    this.gallery.push(gImage1);

    let gImage2: GalleryImages = {
      previewImageSrc: "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FOrange-Section.jpg?alt=media&token=e0eef2fb-76dd-4b0b-9bc8-3b971035928c",
      thumbnailImageSrc: "",
      alt: "wrwrewrwrwwer",
      title: "xcxvcxvcxvcxv"
    }
    this.gallery.push(gImage2);

    //  console.log("Existing Res ",this.global.myReservations);


  }

  getDirectUID(direct) {
    var self = this;

    // Look Up all users with creds - should be done on server
    var self = this;
    var db = firebase.firestore();

    db.collection("users").where("sid", "==", direct)
      .get()
      .then((querySnapshot) => {
        console.log("DIRECT:");
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          localStorage.setItem('uid', data.uid);
          // AUTO-VALIDATE EMAIL
          if (!data.emailVerified) {
            console.log("AUTOVALIDATING")
            doc.ref.update({ emailVerified: true })
          }

          self.nav.GetUserwID(data.uid, true);// true get all res
        });


      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  }



  startChat() {
    this.chatStarted = !this.chatStarted;
  }

  editNewUser() {
    this.finalReview = false;
  }
  InitReservations() {
    var self = this;

    //  let invalidDate = new Date();

    this.invalidTimeSpots = [];

//    this.invalidTimeSpots.push(new Date("05/12/24 2:00 pm"))
//    this.invalidTimeSpots.push(new Date("05/12/24 1:45 pm"))
//    this.invalidTimeSpots.push(new Date("05/12/24 2:15 pm"))


    this.invalidDates = [];
    this.invalidDates.push(new Date("12/25/22")); // Xmas HACK
    this.invalidDates.push(new Date("12/24/22")); // Xmas eve

    this.invalidDates.push(new Date("1/24/23"));
    this.invalidDates.push(new Date("1/31/23"));
    this.invalidDates.push(new Date("2/7/23"));
    this.invalidDates.push(new Date("2/21/23"));
    this.invalidDates.push(new Date("2/28/23"));
    this.invalidDates.push(new Date("2/18/23"));
    this.invalidDates.push(new Date("3/31/24"));  // Easter
    this.invalidDates.push(new Date("9/01/23"));  // Labor day
    this.invalidDates.push(new Date("9/02/23"));  // Labor day
    this.invalidDates.push(new Date("9/03/23"));  // Labor day
    this.invalidDates.push(new Date("11/26/23"));  // Labor day

    this.activeTable = [];
    this.partySize = [];

    if (this.global.myCompany && self.global.myCompany.reserveSettings) {
      for (var n = self.global.myCompany.reserveSettings.minParty; n <= self.global.myCompany.reserveSettings.maxParty; n++) {
        self.partySize.push(n);
      }
      if (self.partySize.length == 1) {
        self.selectedSize = self.partySize[0];
      }
    }

    let today = new Date();
    let month = today.getMonth();
    let year = today.getFullYear();
    let prevMonth = (month === 0) ? 11 : month - 1;
    let prevYear = (prevMonth === 11) ? year - 1 : year;
    let nextMonth = (month === 11) ? 0 : month + 1;
    let nextYear = (nextMonth === 0) ? year + 1 : year;
    this.minDate = new Date();  // today is min

    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);



    this.allOccasions = [
      { name: 'Mothers day', code: 'NY' },
      { name: 'Birthday', code: 'NY' },
      { name: 'Anniversary', code: 'RM' },
      { name: 'First date', code: 'LDN' },
      { name: 'Proposal', code: 'IST' }
    ];

    this.note = "";


  }
  validateUser() {
    var self = this;

    var db = firebase.firestore();

    // Parse the vcode
    var key = this.vcode.substring(0, 3);
    var id5 = this.vcode.substring(3, 8);
    var keyNum: number = parseInt(key);

    console.log("codes ", keyNum, id5, this.global.authuser);
    // HACK
    if (!this.global.authuser) { // Lookup by keycode +
      db.collection("users").where("keyCode", "==", keyNum)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = <User>doc.data();
            if (data.uid.substring(0, 5) == id5) {
              //HACK AUTO LOGIN
              //        self.global.authuser = data;
              self.nav.GetUserwID(data.uid, false, self.haveReservations, self);

              console.log("One click auto login");
              if (!data.emailVerified) {
                var catRef = db.collection("users").doc(data.uid);
                if (catRef) {
                  catRef.update({
                    "emailVerified": true
                  });
                  this.sendEmailVerified();
                  // Set localstorage to reflect authenticated device
                }
              }
            }
          });
        })
        .catch((error) => {
          console.log("no documents: ", error);
        });
    }

    if (this.global.authuser && !this.global.authuser.emailVerified) {
      db.collection("users").where("uid", "==", this.global.authuser.uid)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = <User>doc.data();


            // Now that we actually have the user let's process email validation
            if (!data.emailVerified) {
              if (data.keyCode == parseInt(key)) {
                var catRef = db.collection("users").doc(data.uid);
                if (catRef) {
                  catRef.update({
                    "emailVerified": true
                  });
                  this.sendEmailVerified();
                  // Set localstorage to reflect authenticated device
                }
              }

            }
          });
        })
        .catch((error) => {
          console.log("no documents: ", error);
        });
    }


  }

  setReward(item: Product) {
    var self = this;

    self.selectedReward = item;
    console.log("Reward Set: ", item);
  }

  setPackage(item: Product) {
    var self = this;


    self.selectedPackage = item;
    console.log("Package Set: ", item);
    self.viewGroupPackages = false;

    if (self.selectedPackage)
      this.allSet = true; // if yadayada
    else
      this.allSet = false;

  }
  getMyPackage() {
    if (!this.selectedPackage) return "Select a Package";
    else return "Your Package: " + this.selectedPackage.title;

  }

  checkTimeLineBreak(index: number): boolean {
    var newLine = "";

    if (this.availableTimes.length > index)
      if (this.availableTimes[index].strTime.includes("45")) {
        console.log("newline");
        newLine = '\n';
        return true;
      }
    return false;

  }
  getTimeStr(index: number, min: number): string {
    //  console.log ("Time index: ", index);
    if (this.availableTimes2.length > index)
      return this.availableTimes2[index][min].strTime;
    else return "calm";

  }

  groupSizeChanged2(size) {
    if (size) this.groupMode = true;
    else this.groupMode = false;
    this.partySizeChanged2(size);

  }

  checkReady() {
    if (!this.groupMode || (this.groupMode && this.selectedPackage)) return true;
    else return false;



  }

  ReserveButtonText(): string {
    var reserveStr = "Reserve Now";

    if (this.groupMode) {
      reserveStr = "Reserve Group Booking";
    }
    return reserveStr;
  }

  partySizeChanged2(item) {
    this.selectedSize = item;
    this.viewPartySize = false;

    if (this.selectedSize < this.global.myCompany.groupSettings.minParty) {
      this.selectedPackage = null;
      this.groupMode = false;
    }
    else { this.groupMode = true; }

    this.viewCalendar = true;
    if (this.datePreset) {
      this.date5 = new Date(this.datePreset);
      this.checkDate(this.date5);
    }
    else if (this.date5) {
      this.checkDate(this.date5);
    }
    else console.log("Ignoring time change...");



    this.viewGroupPackages = this.groupMode;

    if (this.groupMode && !this.selectedPackage) this.allSet = false;
    else this.allSet = true;
    console.log("SELECTED SIZE: ", item, this.selectedSize)

  }

  partySizeChanged() {
    this.viewCalendar = true;
    //    this.viewPartySize = false;
    if (this.datePreset) {
      this.date5 = new Date(this.datePreset);
      this.checkDate(this.date5);
    }
    else if (this.date5) {
      this.checkDate(this.date5);
    }
    else console.log("Ignoring time change...");


    if (this.selectedSize < this.global.myCompany.groupSettings.minParty) {
      this.selectedPackage = null;
      this.groupMode = false;
    }
    else {
      this.groupMode = true;
    }


    this.viewGroupPackages = this.groupMode;

    console.log("SELECTED SIZE: ", this.selectedSize)
  }
  checkEmail() {
    var self = this;
    console.log("Checkemail");

    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    self.foundUser = false;
    if (!this.reserveEmail || this.reserveEmail.length < 5) return;


    db.collection("users").where("email", "==", self.reserveEmail)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          self.foundUser = true;
          self.inputCredsnoUser = null;
          self.tUser = <User>querySnapshot.docs[0].data();
          self.forgotStr = null;
        }
        self.inputCredsnoUser = true;
        console.log("Found: ", self.foundUser);

        self.inputCreds = true;

        const element = document.getElementById("inputName2");
        element.scrollIntoView({ behavior: "smooth" });

      })

  }

  // REMOVE WHEN SIGNIN component updated
  login() {
    this.global.displayMenu = false;

    var self = this;
    console.log("LOGIN EMAIL; ", this.reserveEmail);

    var self = this;

    self.loginError = false;

    var db = firebase.firestore();

    db.collection("users").where("email", "==", self.reserveEmail).where("pw", "==", self.pw)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          self.global.authuser = data;
          Logger("Login", self.global.authuser.uid, "", data.firstName, data.lastName)

          localStorage.setItem('uid', data.uid);
          //                    self.cartService.GetMyOrder();
          self.nav.GetUserwID(self.global.authuser.uid, false);
          self.inputCreds = false;
        });
      }).finally(function () { console.log("FINALLY!"); if (self.inputCreds) { self.loginError = true; } })
      .catch((error) => {
        console.log("no user found: ", error);
        self.loginError = true;
      });
  }

  onResOpen(e, i: number) {
    console.log("index: ", e.index);
    if (!this.activeTable[i]) this.activeTable[i] = true;
    else this.activeTable[i] = false;

    // Now close all other tabs
    for (var n = 0; n < this.activeTable.length; n++) {
      if (n != i) this.activeTable[n] = false;

    }
    console.log(this.activeTable);

  }

  VerifyEmail() {
    var self = this;

    console.log("VERIFY: ", this.vCode);
    if (this.vCode == this.global.authuser.keyCode) { // MATCH
      console.log("Verified");
      this.global.authuser.emailVerified = true;
      //   localStorage.setItem('uid', JSON.stringify(this.user.uid));

      var db = firebase.firestore();

      var catRef = db.collection("users").doc(this.global.authuser.uid);
      if (catRef) {
        catRef.update({
          "emailVerified": true
        });
        this.sendEmailVerified();
        // Set localstorage to reflect authenticated device
      }
    }
  }
  public sendEmailVerified() {

    //  if (!this.global.authuser.email || this.global.authuser.email.length < 5) this.email = this.global.authuser.email;
    if (!this.global.userName || this.global.userName.length == 0) {
      this.global.userName = this.global.authuser.displayName;
    }
    var templateParams = {
      from_name: 'Crystal Hill Vineyard',  // Company Name
      to_name: this.global.userName,
      to_email: this.global.authuser.email,
      code: this.global.authuser.keyCode,  // ADD BAD CODE ATTEMPT COUNTER
      pw: this.global.authuser.pw,
      sid: this.global.authuser.sid,
      message: 'Welcome to Crystal Hill Vineyard.'
    };
    this.cartService.sendMsg("Verified email " + this.global.authuser.email, this.global.tableNameQR);


    console.log("EMAIL: ", this.global.userName, this.global.authuser.email);
    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_j23qemn", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }


  checkDate(dateDesired: Date) {
    // Validate that desired date is within advance notices for groups
    if (this.selectedSize >= this.global.myCompany.groupSettings.minParty) {
      // if now()+(.minAdvNotice.min*60000) > date + open-hour
      var dateTime = new Date(Date.now() + (this.global.myCompany.groupSettings.minAdvNotice.min * 60000));
      console.log("DateTime: ", dateTime, dateDesired);
      if (dateTime > dateDesired) {
        this.customizeMessageService.msg = "That date/time is outsize our notice requirement. Please contact us to check availability.";
        this.customizeMessageService.show();
        return;
      }
    }

    console.log("Date & days closed ", dateDesired, this.global.myCompany.reserveSettings.daysClosed);
    this.viewCalendar = false;
    this.viewTables = false;
    this.viewTimes = true;

    this.checkTablesAvailable(dateDesired);
    this.createTimeArray(dateDesired.getDay());
    this.getTableReservations(this.selectedTable, dateDesired)

  }
  selectTable() { }

  hideMe() {

    this.viewCompany = false;
  }

  checkPartySize() {
    var val;

    if (this.selectedSize)
      val = parseInt(this.selectedSize.toString());

    if (!val) { this.selectedSize = 0; console.log("SIZE00000") }
  }


  checkPartySize2(item: number) {
    var val;


    this.selectedSize = item;

    if (this.selectedSize)
      val = parseInt(this.selectedSize.toString());

    if (!val) this.selectedSize = 0;
    console.log("sizeddd ", this.selectedSize);

  }

  focusNotes() {

    console.log("Focus");
    this.show = !this.show;
  }


  onTabOpen(e, i) {

    console.log("Open Tab: ", e, i);
  }

  toggleTable(table: Table, i) {

    // if (!this.selectedTable) {
    this.selectedTable = table;
    // NOW adjust times available based on pre-existing reservations
    //  this.checkDate(this.date5);

    /*  this.checkTablesAvailable(this.date5);
   
      this.createTimeArray(this.date5.getDay());
   
      this.checkTimesforTable(table);
      */
    this.activeTable[i] = false; // collapse content
    this.viewTables = false;



  }

  haveReservations(self: this) {

    console.log("Got reservations callback")
    if (!self.adminRes && (!self.global.myReservations || (self.global.myReservations && self.global.myReservations.length == 0))) {
      self.newRes = true;
    }

  }

  afterUserLoad(self: this) {
    console.log("Got user callback");

    if (self.vcode) { self.validateUser(); }

    self.nav.getReservations(self.storeNum, false, self.haveReservations, self);

    // self.viewReviews.GetReviews();
    self.reserveEmail = self.global.authuser.email;


  }


  newReservation(res?: reservation, self?) {

    if (!self || (!self.global.loadingReservations && !self.global.myReservations)) {
      self.newRes = true;
      self.showOpenRes = false;
      self.editingRes = null;
      self.viewPartySize = true;
    }
    if (self) {
      self.newResText = self.global.myCompany.name;

      self.manageRes = false;
      self.changeRes = false;
      self.partySize = [];

      self.selectedTime = null;
      self.selectedSize = null;

      self.selectedPackage = null;

      self.date5 = null;
      self.note = "";
      self.selectedTable = null;

    }





    for (var n = self.global.myCompany.reserveSettings.minParty; n <= self.global.myCompany.reserveSettings.maxParty; n++) {
      self.partySize.push(n);
    }
    if (self.partySize.length == 1) {
      self.selectedSize = self.partySize[0];
    }


    if (self.newRes) {
      //   self.newResText = self.global.myCompany.name;
    }

    if (self.adminRes) {
      self.date5 = self.global.newResDate;
      self.selectedTime = self.global.newResTime;
    }

  }

  newReservation2() {
    var self = this;

    //   if (!self.global.loadingReservations && !self.global.myReservations) {
    self.newRes = true;
    self.showOpenRes = false;
    self.editingRes = null;
    self.viewPartySize = true;
    //  }

    self.newResText = self.global.myCompany.name;

    self.manageRes = false;
    self.changeRes = false;
    self.partySize = [];

    self.selectedTime = null;
    self.selectedSize = null;

    self.selectedPackage = null;

    self.date5 = null;
    self.note = "";
    self.selectedTable = null;

    for (var n = self.global.myCompany.reserveSettings.minParty; n <= self.global.myCompany.reserveSettings.maxParty; n++) {
      self.partySize.push(n);
    }
    if (self.partySize.length == 1) {
      self.selectedSize = self.partySize[0];
    }


    if (self.newRes) {
      //   self.newResText = self.global.myCompany.name;
    }

    if (self.adminRes) {
      self.date5 = self.global.newResDate;
      self.selectedTime = self.global.newResTime;
    }

  }



  cancelNewReservation() {
    this.newRes = true; this.manageRes = false;
    this.selectedSize = null; this.date5 = null;
    this.selectedSizeUnder21 = null;
    this.selectedTime = null;
    this.selectedPackage = null;
    this.selectedReward = null;
    this.newResText = this.global.myCompany.name;
    this.groupMode = false;
  }

  manage() { this.newRes = false; this.manageRes = true; }
  callNowInactive() {
    this.callNowActive = false;
  }

  AddNote() {
    this.addNote = true;


  }
  dialing() {
    // Play sound like dialing a phone - retro
    this.callNowActive = true;
  }
  getDirections() {
    // turn on MAP in place
/*
    this.geocoder.geocode({
      address: this.global.myCompany.address1 + "," + this.global.myCompany.city + "," + this.global.myCompany.state
    }).subscribe(({ results }) => {
      console.log(results);

      this.center = {
        lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()
      }


    });




    this.showMap = true;
    this.scroller.scrollToAnchor("targetMap");
*/
  }
  /*
  moveMap(event: google.maps.MapMouseEvent) {
    this.center = (event.latLng.toJSON());
  }

  move(event: google.maps.MapMouseEvent) {
    // this.display = event.latLng.toJSON();
  }
  */
  checkTablesAvailable(d: Date) {
    var self = this;
    //  if (!self.global.authuser) return;
    /*
        if (d.toDateString() == "Sun May 14 2023") {
          console.log ("Mother's Day. Turn off tables");
          return;
        }
        else  {console.log ("Mother's Day",d.toDateString());}
    */
    var db = firebase.firestore();

    db.collection("tables").where("companyID", "==", self.global.myCompany.id).where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        //   console.log ("Looking for your table", doc);

        // need to validate existing table choice if partysize changes
        var found = false;
        self.availableTables = [];
        self.showPhotos = [];
        querySnapshot.forEach((doc) => {
          const data = <Table>doc.data();
          if (!data.docID) data.docID = doc.id;
          if (data.reservable) {
            if (self.selectedSize >= data.minPartySize && self.selectedSize <= data.maxPartySize) {
              console.log("Got your table here: ", data);
              self.availableTables.push(data);
              var autoShowPhotos: boolean = true;
              self.showPhotos.push(autoShowPhotos);
              if (self.changeRes && self.editingRes.tableObj && data.docID == self.editingRes.tableObj.docID) {
                found = true; // table is still valid
                console.log("Table still valid ", data);
              }
              if (self.changeRes && self.selectedTable && self.selectedTable.name == data.name) {
                found = true; // table is still valid
                console.log("Table still valid by name ", data);
              }
              if (self.newRes && self.selectedTable && self.selectedTable.name == data.name) {
                console.log("Found table");
                found = true;
              }
            }
          }

        });
        // Check if table is valid
        if ((self.changeRes || self.newRes) && !found) {
          console.log("Nullify table", self.selectedTable);

          self.selectedTable = null;
        }
      })

  }

  cancelChanges() {
    this.changeRes = false;
  }

  confirmChanges(res: reservation) {
    this.updateReservation(res);
  }

  updateChangeSettings(res: reservation, self) {


    console.log("Seriously it's a callback tacked onto distant observable. ", res);

    if (res.tableObj)
      self.selectedTable = res.tableObj;
    self.selectedSize = res.partySize;
    if (res.partySizeUnder21)
      self.selectedSizeUnder21 = res.partySizeUnder21;
    if (res.package)
      self.package = res.package;
    self.date5 = res.resDate.date.toDate();
    self.checkDate(self.date5);
    self.selectedTime = res.resTime;
    self.selectedOptions = res.addons;
    self.note = res.note;
    self.viewTables = false;
    self.editingRes = res;
    self.reserveFirstName = res.firstName;
    self.reserveLastName = res.lastName;
    self.partySize = [];
    for (var n = self.global.myCompany.reserveSettings.minParty; n <= self.global.myCompany.reserveSettings.maxParty; n++) {
      self.partySize.push(n);
    }

    console.log("Change: ", self.partySize, self.thisRes, self.selectedTime);


    self.changeRes = true;
    self.newRes = false;
    self.showOpenRes = false;

  }


  changeReservation(res: reservation) {
    var self = this;


    self.thisRes = res;



    // if no company record then load store with res data

    if (!self.global.myCompany || self.global.myCompany.store != res.storeNum) {
      self.nav.GetStore(res.storeNum, self.updateChangeSettings, res, self);
    }
    else {

      if (res.tableObj)
        self.selectedTable = res.tableObj;
      self.selectedSize = res.partySize;
      if (res.partySizeUnder21)
        self.selectedSizeUnder21 = res.partySizeUnder21;
      if (res.package)
        self.selectedPackage = res.package;

      self.date5 = res.resDate.date.toDate();
      self.selectedTime = res.resTime;
      self.selectedOptions = res.addons;
      self.note = res.note;
      self.viewTables = false;
      self.editingRes = res;
      self.reserveFirstName = res.firstName;
      self.reserveLastName = res.lastName;
      self.partySize = [];
      for (var n = self.global.myCompany.reserveSettings.minParty; n <= self.global.myCompany.reserveSettings.maxParty; n++) {
        self.partySize.push(n);
      }

      self.checkDate(self.date5);

      console.log("Change: ", self.partySize, self.thisRes, self.selectedTable);

      self.changeRes = true;
      self.newRes = false;
      self.showOpenRes = false;
    }


  }
  gotoRewards() {
    //  this.router.navigate(['rewards']);
  }
  cancelRes(res: reservation) {

    this.cancelThisRes = res;
  }

  gotoMenu() {
    //]);

    this.router.navigate(['menu/' + this.global.myCompany.store])
      .then(() => {
        window.location.reload();
      });


  }

  updateReservation(res: reservation) {
    var self = this;

    const dateOnly = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: 'numeric' }).format(self.date5);
    const rDate: resDate = { date: Timestamp.fromDate(this.date5), dateStr: this.getPrettyDate1(this.date5) };

    var t = "";
    var tObj = null;
    if (this.selectedTable) {
      t = this.selectedTable.name;
      tObj = this.selectedTable;
    }
    var u21 = null;
    if (this.selectedSizeUnder21)
      u21 = this.selectedSizeUnder21;
    var myPackage = null;
    if (this.selectedPackage)
      myPackage = res.package;

    console.log("Change res: ", res, self.selectedTime, rDate);

    var db = firebase.firestore();
    var ref = db.collection("reservations").doc(res.myID);

    res.resTime = self.selectedTime;

    res.resDate = rDate;
    res.table = t;
    res.tableObj = tObj;
    res.resTime = this.selectedTime;
    res.partySize = this.selectedSize;
    //    res.firstName = this.reserveFirstName;
    //    res.lastName = this.reserveLastName;
    res.note = this.note;
    res.package = myPackage;
    res.partySizeUnder21 = u21;

    ref.update(res)
      .then(() => {
        console.log("Reservation successfully updated!");
        self.newRes = false;
        self.manageRes = false;
        self.changeRes = false;
        self.sendEmail(res);
        self.sendNotifyEmail(res);
        self.note = "";
        self.cartService.sendMsg("Updated Reservation", res.firstName + " " + res.lastName);

      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });



  }
  cancelResUndo(res: reservation) {
    this.cancelThisRes = null;

  }

  logMeOut() {
    this.ngAuthService.SignOut();
    this.global.authuser = null;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(['/reserve']);
  }

  cancelResConfirm(res: reservation) {

    // Cancel this res - it will dissappear

    this.updateStatus(res, "canceled");

    this.cancelThisRes = null;
    res.status = "canceled";

  }
  registerMe() {
    var self = this;
    // VALIDATE INFO
    if (!this.global.authuser) {
      if (!this.reserveFirstName || this.reserveFirstName.length == 0
        || !this.reserveLastName || this.reserveLastName.length == 0
      ) { alert("Please enter a name."); return; }
      if (!ValidateEmail(this.reserveEmail)) return;
      //    if (!validatePhoneNumber(this.reservePhone)) return;
      this.newUserID = self.registerService.GetUID();
      self.registerService.RegisterUser(this.reserveEmail, this.reserveFirstName, this.reserveLastName);
      if (!this.newUserID) return;
    }
  }

  validateUserData() {
    console.log("Validating info");
    // VALIDATE INFO
    if (!this.global.authuser) {
      if (!this.reserveFirstName || this.reserveFirstName.length == 0
        || !this.reserveLastName || this.reserveLastName.length == 0
      ) { alert("Please enter a name."); return; }
      if (!ValidateEmail(this.reserveEmail)) return;
      if (!validatePhoneNumber(this.reservePhone)) return;
      this.userDataValid = true;
      this.inputCredsnoUser = false;
      this.finalReview = true;

    }
  }

  afterReg(self: this) {


    self.nav.getReservations("4390");


  }


  reserveTable(res: reservation) {
    var self = this;

    console.log("Reserving table ")
    // VALIDATE INFO
    if (!self.global.authuser) {
      if (!self.reserveFirstName || self.reserveFirstName.length == 0
        || !this.reserveLastName || self.reserveLastName.length == 0
      ) { alert("Please enter a name."); return; }
      if (!ValidateEmail(self.reserveEmail)) return;
      //    if (!validatePhoneNumber(self.reservePhone)) return;
      self.newUserID = self.registerService.GetUID();
      self.registerService.RegisterUser(this.reserveEmail, self.reserveFirstName, self.reserveLastName, true, self.afterReg, self);
      if (!self.newUserID) return;
    }

    const dateOnly = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(self.date5);
    const rDate: resDate = { date: Timestamp.fromDate(this.date5), dateStr: this.getPrettyDate1(this.date5) };

    var t = "";
    var tObj = null;
    if (this.selectedTable) {
      t = this.selectedTable.name;
      tObj = this.selectedTable;
    }

    var packageObj = null;
    if (this.selectedPackage) packageObj = this.selectedPackage;

    var rewardObj = null;
    if (this.selectedReward) rewardObj = this.selectedReward;


    let r: reservation = {
      createStamp: Timestamp.now(),
      myID: "?", //hack easy fix
      userID: this.newUserID,    //this.global.authuser.uid,
      //     storeID: this.global.store,  // HACK get rid of this line
      storeNum: this.global.myCompany.store,
      companyID: this.global.myCompany.id,
      storeName: this.global.myCompany.name,
      image1: this.global.myCompany.reserveSettings.mainImage,
      resDate: rDate,
      table: t,
      package: packageObj,
      reward: rewardObj,
      tableObj: tObj,
      resTime: this.selectedTime,
      partySize: this.selectedSize,
      partySizeUnder21: this.selectedSizeUnder21,
      firstName: this.reserveFirstName,
      lastName: this.reserveLastName,
      email: this.reserveEmail,
      phone: this.reservePhone,
      status: "open",
      note: this.note,
    }
    if (!r.image1) r.image1 = "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/images%2FTableGhost-01.png?alt=media&token=7a5beae2-b963-4024-b4b3-b634502d47c3"
    if (this.selectedOptions)
      r.addons = this.selectedOptions;

    var occ: string;

    if (this.selOccasion) {
      occ = <string>this.selOccasion;
      console.log("occ:", occ);
      if (occ)
        r.occasion = occ;
      else { }
    }

    console.log("occ:", r.occasion);

    if (this.global.authuser) {
      r.email = this.global.authuser.email;
      //r.name = this.global.authuser.firstName;
      if (!this.global.authuser.firstName) this.global.authuser.firstName = this.global.authuser.displayName;
      if (!this.global.authuser.lastName) this.global.authuser.lastName = this.global.authuser.displayName;

      r.firstName = this.global.authuser.firstName;
      r.lastName = this.global.authuser.lastName;

      r.phone = "(555) 555-5555"; //this.global.authuser.; ??
      r.userID = this.global.authuser.uid;
      console.log("r ", r);
    }


    var db = firebase.firestore();
    var ref = db.collection("reservations").doc();
    r.myID = ref.id;

    self.reservingYourTable = true;
    if (r.package) r.package.safeHtml = "";
    ref.set(r)
      .then(() => {
        self.reservingYourTable = false;
        var person = r.email + " " + r.firstName + " " + r.lastName

        Logger("Reservation Added", person, self.global.myCompany.store,
          self.global.myCompany.name + " party of " + r.partySize);

        console.log("Reservation successfully saved!");


        self.newRes = false;
        self.newResText = self.global.myCompany.name;
        self.viewPartySize = true;

        self.manageRes = false;
        self.selectedOptions = [];
        self.date5 = null;
        self.selectedTable = null;
        self.selectedSize = 0;
        self.sendEmail(r);
        self.sendNotifyEmail(r);
        self.cartService.sendMsg("New Reservation", r.firstName + " " + r.lastName);
        //      self.customizeMessageService.summary = "You earned 200 points";
        self.customizeMessageService.msg = "Thanks for the reservation.";
        self.customizeMessageService.show();

        // AUTO-FOLLOW if NOT already or NEW User
        if (!this.newUserID) {
          var found = false;
          if (self.global.authuser && self.global.authuser.following) {
            self.global.authuser.following.forEach(function (item: Follows) {
              if (item.storeNum == self.global.myCompany.store) { found = true; }
            })
          }
          if (!found) {
            self.updateFollows();
          }
        }
        // Load reservations
        //      self.nav.getReservations(self.global.myCompany.store, false);
        self.successMsg = true;

      })
      .catch((error) => {
        var person = r.email + " " + r.firstName + " " + r.lastName

        Logger("Reservation Error", person, self.global.myCompany.store,
          self.global.myCompany.name + " party of " + r.partySize);
        console.error("Error writing document: ", error);
      });


  }
  updateFollows() { // Move to common layer
    var self = this;


    var db = firebase.firestore();
    var ref = db.collection("users").doc(self.global.authuser.uid);

    //  var notify: Notify = {enable: true, reservations:true};


    var follow: Follows = {
      id: self.global.myCompany.id,     // remove later
      storeNum: this.global.myCompany.store,    // fix name
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: this.global.myCompany.name

    }
    if (this.global.authuser.following) this.global.authuser.following.push(follow);
    else {
      this.global.authuser.following = [];
      this.global.authuser.following.push(follow);
    }
    console.log("Set follows: ", follow)



    ref.update({
      following: this.global.authuser.following
    })
      .then(() => {
        console.log("follows updated: ");
        // Save locally
        //      localStorage.setItem('myFollows', JSON.stringify(follows));
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }




  updateStatus(res: reservation, newStatus: string) {

    var db = firebase.firestore();
    var ref = db.collection("reservations").doc(res.myID);

    ref.update({
      status: newStatus
    })
      .then(() => {
        console.log("status updated: ", newStatus);
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }

  checkTimesforTable(table: Table) {
    // Find any existing reservations for this table
    // only for the currently selected date
    // Block the amount of time from availableTimes based on timeBlock table setting
    this.getTableReservations(table, this.date5);
  }

  checkAvailableTime(d: Date, time: resTime) {
    // For Date and Time - check if time or near times are available
    var self = this;
    var db = firebase.firestore();

    db.collection("reservations").where("companyID", "==", self.global.myCompany.id).where("status", "==", "open")
      .get()
      .then((querySnapshot) => {
        self.reservationList = [];
        querySnapshot.forEach((doc) => {

          const data = <reservation>doc.data();
          //      console.log("res", self.global.myCompany.id, data);

          var rDate = data.resDate.date.toDate();
          const day = rDate.getDate();
          const month = rDate.getMonth();
          const year = rDate.getFullYear();

          if (day == d.getDate() && month == d.getMonth() && year == d.getFullYear()) {
            self.reservationList.push(data);
          }
        });

        //    console.log("Checking ", self.reservationList.length, " reservations")
        //    self.checkSectionCapacity();

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });


  }

  getTableReservations(table: Table, d: Date) {
    var self = this;
    var db = firebase.firestore();
    var theTable: string = "";
    if (table)
      theTable = table.name;

    db.collection("reservations").where("companyID", "==", self.global.myCompany.id).where("status", "==", "open")
      .get()
      .then((querySnapshot) => {
        self.reservationList = [];
        querySnapshot.forEach((doc) => {

          const data = <reservation>doc.data();
          //      console.log("res", self.global.myCompany.id, data);


          var rDate = data.resDate.date.toDate();
          const day = rDate.getDate();
          const month = rDate.getMonth();
          const year = rDate.getFullYear();

          if (day == d.getDate() && month == d.getMonth() && year == d.getFullYear()) {
            if (data.table == theTable)
              self.reservationList.push(data);
          }
        });

        console.log("Checking ", self.reservationList.length, " reservations")
        self.checkSectionCapacity();

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

  }

  // ASSUMES List contains same table, same day 
  // Group by time for sections with multi-tables
  checkSectionCapacity() {
    var self = this;
    var r: reservation;

    var timeReserve: number;
    var minTime: number;
    var sectionTotal: number;
    var maxTables: number;
    timeReserve = self.global.myCompany.reserveSettings.timeBlock.min;


    // Sort by times 
    this.reservationList.sort(function (a, b) { return a.resTime.msTime - b.resTime.msTime; });
    this.reservationList.forEach(function (arrayRes: reservation, index) {
      if (!index) r = arrayRes;
      else {
        if (arrayRes.resTime.msTime >= r.resTime.msTime && arrayRes.resTime.msTime < r.resTime.msTime + (timeReserve * 60000)) {   // Same TIME
          r.partySize += arrayRes.partySize;
          if (arrayRes.partySizeUnder21) {
            r.partySize += arrayRes.partySizeUnder21;
            arrayRes.partySizeUnder21 = 0;
          }
          arrayRes.partySize = 0;
        }
        else {
          r = arrayRes;
        }

      }

    });
    var now = Date.now();

    console.log("VERIFY AVAILABILITY ", this.reservationList);
    this.reservationList.forEach(function (arrayRes, index) {
      // Now block off some times

      var startIndex = -1;
      var spliceCount = 0;

      self.availableTimes.forEach(function (arrayItem: resTime, index) {
        // Remove any times before now
        console.log("time, now ", arrayItem.msTime, now);

        if (arrayItem.msTime < now) {
          if (startIndex == -1) startIndex = index;
          spliceCount++;
        }
      })


      if (spliceCount) {
        console.log("Blocking times: ", startIndex, spliceCount);
        self.availableTimes.splice(startIndex, 1);
      }

      startIndex = -1;
      spliceCount = 0;

      maxTables = self.global.myCompany.reserveSettings.maxTables;

      var seatsRequested = self.selectedSize;
      if (arrayRes.tableObj) {
        minTime = arrayRes.tableObj.minTimeReserve;
        timeReserve = arrayRes.tableObj.timeReserve;
        sectionTotal = arrayRes.tableObj.sectionTotal;
      }
      else {
        minTime = self.global.myCompany.reserveSettings.timeBlock.min;
        timeReserve = self.global.myCompany.reserveSettings.timeBlock.min;
        sectionTotal = self.global.myCompany.reserveSettings.maxOccupancy;
      }
      console.log("Time-Block: ", arrayRes.tableObj, timeReserve, self.global.myCompany.reserveSettings)

      var block = false;


      if (self.availableTimes) {
        var totalTables = 0;
        self.availableTimes.forEach(function (arrayItem: resTime, index) {
          totalTables++;


          if (arrayItem.msTime > arrayRes.resTime.msTime - (timeReserve * 60000) && arrayItem.msTime < (arrayRes.resTime.msTime + (timeReserve - 15) * 60000)) {
            // Within Time of Requested seating - check for multi-table section and partySize
            block = true;

            if (sectionTotal) {  // presence means multi-table section
              block = false;
              if (totalTables > maxTables) block = true;
              if (arrayRes.partySize + seatsRequested > sectionTotal) {
                block = true;
              }
            }



            // Un-Block table being edited
            if (self.editingRes && arrayRes.myID == self.editingRes.myID) {
              block = false;
              spliceCount = 0;
              console.log("UnBlock ", arrayRes, self.availableTimes);
            }

            if (block) {
              self.selectedTime = null;
              if (arrayItem.msTime <= arrayRes.resTime.msTime - (minTime * 60000)) {
                // SHORT TIME RESERVATION - NOTE TIME
                block = false;
                if (!arrayItem.strDetail.includes("avail")) {
                  if (arrayItem.msTime + (timeReserve * 60000) > self.closingTime) {
                    arrayItem.strDetail = (self.closingTime - arrayItem.msTime) / 60000 + " min available";

                  }
                  else {

                    arrayItem.strDetail = (arrayRes.resTime.msTime - arrayItem.msTime) / 60000 + " min available";

                  }
                }
              } else {
                if (startIndex == -1) startIndex = index;
                spliceCount++;
              }
            }
          }

          if (!block) {
            if (!arrayItem.strTime.includes("avail")) {
              if (arrayItem.msTime + (timeReserve * 60000) > self.closingTime) {
                arrayItem.strDetail = (self.closingTime - arrayItem.msTime) / 60000 + " min available";

              }
              else {
                arrayItem.strDetail = timeReserve + " min available";
              }
            }
          }
          // Block if groupSize > MaxOccupancy
          console.log("Check MaxOcc: ", self.selectedSize, self.global.myCompany.reserveSettings.maxOccupancy);
          if (self.selectedSize > self.global.myCompany.reserveSettings.maxOccupancy) {
            block = true;
            spliceCount++;
          }

        });
      }


      if (spliceCount) {
        console.log("Blocking times: ", startIndex, spliceCount);
        self.availableTimes.splice(startIndex, spliceCount);
      }
      //      }
    });

  }
  createTimeArray(day: number) {
    var self = this;
    // must be 0-6 (sun, sat)
    var start, end, n;
    var hourIndex = 0;
    var minIndex = 0;

    console.log(this.global.allWeek, day);
    //hack to convert to start of week as 0=monday
    //  if (day) day--;
    //  else day = 6;

    if (!self.global.allWeek || self.global.allWeek.length == 0) {
      // INSERT RESERVATION SETTINGS
      self.global.allWeek = [];

      self.global.allWeek = self.global.myCompany.reserveSettings.reserveDay;

      self.global.myCompany.reserveSettings.daysClosed = [];
      self.global.openDays = [];
      if (!self.global.myCompany.reserveSettings.timeNotice) {
        let aTime: Duration = {
          min: 15,
          name: ""
        }
        self.global.myCompany.reserveSettings.timeNotice = aTime;
      }

      self.global.myCompany.reserveSettings.openDays.forEach(function (value: boolean, index) {
        if (!value) {

          self.global.myCompany.reserveSettings.daysClosed.push(index);
        }
        self.global.openDays.push(value);
      });

    }




    this.closingTime = this.global.allWeek[day].resCloseTime.msTime;

    start = this.global.allWeek[day].resOpenTime.msTime;
    console.log("Start Time: ", start, day);

    var today = new Date();
    var now = new Date(),
      then = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        0, 0, 0),
      diff = now.getTime() - then.getTime(); // difference in milliseconds

    if (this.date5.getFullYear() == today.getFullYear() && this.date5.getMonth() == today.getMonth() && this.date5.getDate() == today.getDate()) {
      // It's today!!
      console.log("It's today ", this.date5, today, diff);

      if (diff > start) {
        const delta = this.global.myCompany.reserveSettings.timeBlock.min * 60 * 1000;
        const m = diff % delta;
        console.log("Math: ", diff, delta, m);
        diff += (delta - m);
        start = diff;
      }

      // Now Check that current time is at least timeNotice prior

      while (diff + (this.global.myCompany.reserveSettings.timeNotice.min * 60 * 1000) > start) {
        start += this.global.myCompany.reserveSettings.timeSkip.min * 60 * 1000;
      }


    }
    end = this.global.allWeek[day].resCloseTime.msTime - (this.global.myCompany.reserveSettings.timeBlock.min * 60000);
    n = start;
    this.availableTimes = [];
    this.availableTimes2 = [];


    this.UpdateOptionNames();

    while (n <= end) {
      // add a time slot

      let aTime: resTime = {
        msTime: 0,
        strTime: ""
      }

      aTime.msTime = n;
      var hours = Math.floor(n / 3600000);
      var minutes = Math.floor((n - (hours * 3600000)) / 60000);


      let amPm = "am";
      if (hours >= 12) {
        amPm = "pm";
        if (hours > 12) hours -= 12;
      }

      if (minutes < 10)
        aTime.strTime = hours + ':0' + minutes + " " + amPm;
      else
        aTime.strTime = hours + ':' + minutes;// + amPm;

        var skipTime = false;
        self.invalidTimeSpots.forEach (function(skipMe){
          var invalDate = skipMe.toDateString();
          var dayStart = new Date(startOfDay(invalDate)).getTime()
          var invalTime = skipMe.getTime() - dayStart;
    
          if (invalDate == self.date5.toDateString()) { //Now TIME
            if (invalTime == aTime.msTime) {
              console.log("CHECK TIMES ", dayStart, self.date5.toDateString(), invalDate, invalTime, aTime)
              skipTime = true;
            }
          }
        })

      if (!skipTime) {
        self.availableTimes.push(aTime);
        if (minIndex == 0) this.availableTimes2[hourIndex] = [];
        self.availableTimes2[hourIndex].push(aTime);
        minIndex++;
      }

      if (minutes == 45) {
        hourIndex++;
        minIndex = 0;
      }


      n += (this.global.myCompany.reserveSettings.timeSkip.min * 60000);
    }
    console.log("Times Available: ", this.availableTimes, start, end, this.availableTimes2);


  }

  UpdateOptionNames() {

    if (this.global.myCompany.reserveSettings.addons)
      this.global.myCompany.reserveSettings.addons.forEach(function (item) {
        if (item.retail) item.name = item.name + " $" + item.retail.toFixed(2);
        else item.name = item.name + "(free)";

      })
  }

  // NEED TO CREATE SERVICE MODULE for ALL EMAILS
  public sendEmail(r: reservation) {
    var self = this;
    self.emailService.sendReserveEmail(r);

  }

  ForgotPassword() {
    var self = this;
    if (self.foundUser && !self.forgotStr) {
      self.emailService.ForgotPassword(self.reserveEmail, self.tUser.firstName, self.tUser.pw);
      self.forgotStr = "Email reminder sent.";
    }
    /*

    if (self.foundUser && !self.forgotStr) {
      console.log("Forgot ");

      var templateParams = {
        from_name: this.global.myCompany.name,  // Company Name
        to_name: self.tUser.firstName,
        to_email: self.reserveEmail,
        pw: self.tUser.pw,
        reply_email: this.global.myCompany.reserveSettings.replyEmail
      };

      emailjs.send("service_jwders9", "template_if0oytb", templateParams)
        .then((result: EmailJSResponseStatus) => {
          console.log(result.text);
          self.forgotStr = "Email reminder sent.";
        }, (error) => {
          self.forgotStr = "Error sending Email reminder. " + error.text;
          console.log(error.text);
        });
    }
    */

  }




  public sendNotifyEmail(r: reservation) {
    var self = this;

    var addonItems = "";

    if (r.addons) {
      addonItems = "Requested options: " + r.addons;
    }

    var rewardStr = "";

    if (r.reward) {
      rewardStr = "Reward selection: " + r.reward.title;
    }

    var optionString = "";
    if (r.package && r.package.allOptions) {
      r.package.allOptions.forEach(function (item) {
        optionString += item.title + ": " + item.chosen;
      })


    }

    // Look Up all users with creds - should be done on server

    var self = this;
    var db = firebase.firestore();

    this.notifyUsers = [];

    db.collection("users").where("creds", "!=", "null")
      .get()
      .then((querySnapshot) => {

        querySnapshot.forEach((doc) => {

          const data = <User>doc.data();

          data.creds.forEach(function (cred) {
            if (cred.id == self.global.myCompany.id
              && cred.notify && cred.notify.enable && cred.notify.reservations) {
              self.notifyUsers.push(data);

              var theTable = "Assigned at arrival";
              var thePackage;
              if (r.tableObj) theTable = r.tableObj.name;
              if (r.package) thePackage = r.package.title + " Package Selected";

              if (!r.partySizeUnder21) r.partySizeUnder21 = 0;
              // NOTIFY STAFF
              var templateParams = {
                from_name: "Reservation Rewards!",  // Company Name
                to_name: data.firstName,
                to_email: data.email, // HACK - Need notify list of emails
                resDate: r.resDate.dateStr,
                resTime: r.resTime.strTime,
                resSize: r.partySize,
                resSize20: r.partySizeUnder21,
                resName: r.firstName + ' ' + r.lastName,
                resTable: theTable,
                resPackage: thePackage,
                reward: rewardStr,
                addons: addonItems,
                options: optionString,
                storeName: cred.storeName,
                storeAddress: self.global.myCompany.address1 + " " + self.global.myCompany.city + " " + self.global.myCompany.state
              };

              //  e.preventDefault();
              emailjs.send("service_jwders9", "template_6mcmnsr", templateParams) //"template_1ky3ksd"
                .then((result: EmailJSResponseStatus) => {
                  console.log(result.text);
                }, (error) => {
                  console.log(error.text);
                });


            }
          })
        });
        console.log("Notify users of the reservation: ", self.notifyUsers);

      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });



  }



  getPrettyDate(date: Timestamp) {

    let d = new Date(date.toDate());

    //    console.log(d);
    return (dateFormatter(d));
    //   return d.getMonth()+" "+d.getDay()+" "+d.getFullYear();

  }

  chipClicked(i: number, m: number) {
    console.log("Clicked chip ", i, m);
    this.selectedTime = this.availableTimes2[i][m];

    // ADD AMPM if not Included
    if (this.selectedTime.strTime.includes("am") || this.selectedTime.strTime.includes("pm")) { }
    else {

      let hours = 0;
      hours = this.selectedTime.msTime / 60000 / 60;
      if (hours >= 12) {
        this.selectedTime.strTime += " pm"
      }
      else
        this.selectedTime.strTime += " am"

    }
    this.viewTimes = false;
    this.viewTables = true;

    if (this.selectedSize >= 12) this.viewGroupPackages = true;


  }
  getResText() {
    // && (newRes || changeRes)

    if (this.newRes)
      return "Reservation for: " + '/n' + this.global.myCompany.name;
    else
      return this.global.myCompany.name;



  }

  myColor(i, m) {
    var color = "#898AA6";
    /*
        if (Math.random() * 100 < 30)
        color = "#FFA726";
    
        if (Math.random() * 100 > 90)
        color = "#FF6D00";
    
        */
    return color;

  }

  getSelectedTable() {
    var nameThis = "Table: ";
    if (this.selectedTable) {
      if (this.selectedTable.sectionTotal) {
        nameThis = "Section: ";
      }

    }
    if (this.selectedTable)
      return nameThis + this.selectedTable.name;
    else
      return "Select a table - optional";

  }
  partySizeHeader() {
    var partySizeStr;

    if (!this.selectedSize) partySizeStr = "Please select a party size"; // User's default size

    else partySizeStr = "Party size: " + this.selectedSize;
    return partySizeStr;

  }

  groupBookingHeader() {

    return "Booking for a group of " + this.global.myCompany.groupSettings.minParty + ' to ' + this.global.myCompany.groupSettings.maxParty + "?";
  }

  getPrettyDate2(date: Date) {

    if (this.date5)
      return ("Your date: " + dateFormatter(date));
    else
      return ("Select a date");

  }

  getPrettyDate1(date: Date) {

    if (this.date5)

      return (dateFormatter(date));
    else
      return ("no date");

  }

  /*  
  
  initMap(): void {
    console.log("InitMap");
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: 15,
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
        center: { lat: -34.397, lng: 150.644 },
      }
    );
    const geocoder = new google.maps.Geocoder();
  
    this.geocodeAddress(geocoder, map);
    }
    geocodeAddress(
      geocoder: google.maps.Geocoder,
      resultsMap: google.maps.Map
    ) {
      console.log("address: ", this.global.myCompany.address1);
      geocoder.geocode({ address: this.global.myCompany.address1 + " " + this.global.myCompany.postal }, (results, status) => {//address: address
        if (status === "OK") {
          resultsMap.setCenter(results[0].geometry.location);
          console.log("LAT-LONG: ", results[0]);
          new google.maps.Marker({
            map: resultsMap,
            position: results[0].geometry.location,
          });
        } else {
          alert("Geocode was not successful for the following reason: " + status);
        }
      });
    }
  */
}
/*
let   map: google.maps.Map;

function initMap(): void {
  console.log("Map is Initializing .....");
  map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 14,
  });
}

declare global {
  interface Window {
    initMap: () => void;
  }
}
window.initMap = initMap;
export {};
*/
function dateFormatter(date: Date) {
  const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
  const timeformat: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour12: false
  };
  // date.toDateString();


  return date.toLocaleDateString('en-US', timeformat);

}

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}

function validatePhoneNumber(input_str) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  if (re.test(input_str)) { return true; }
  else {
    alert("You have entered an invalid phone number!")
    return (false)
  }
}