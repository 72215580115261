import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Order } from '../../order-interface';
import firebase from 'firebase/compat/app';
import { User, Follows, Activity } from '../../user-interface';
import { Company, Rewards } from '../../company-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgAuthService } from "../../../ng-auth.service";
import { Observable } from 'rxjs';
import { Logger } from '../../../functions';

import { PrimeNGConfig } from 'primeng/api';
import { SelectItem } from 'primeng/api/selectitem';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessageService } from 'primeng/api';
import { Product, Options } from '../../product-int';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { onMenuItem } from "../../menu-item-interface";
import { Globals } from 'src/app/globals';
import { DialogService } from 'primeng/dynamicdialog';
import { editProduct } from '../editProduct';


@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.scss'],
  providers: [MessageService, DialogService]

})
export class ViewProductsComponent implements OnInit {
  @Input() name: string;

  orders: Order[] = [];
  ordersObs: Observable<any[]>;
  user: User;
  companyID: string = "5vjmmQOnSx31XLsMDvZx";  // TEMP HACK
  selected: string;

  rewards: Rewards[];
  selectedOptions2: string[] = [];

  // Redemption //
  sortOptions: SelectItem[];
  sortOrder: number = 0;
  sortField: string = "";
  //myRewardPoints: number = 0;
  selectedMessage: any;


  constructor(private router: Router, public ngAuthService: NgAuthService,
    private sharedDataService: SharedDataService,
    private cartService: CartService,
    private messageService: MessageService,
    private primeNGConfig: PrimeNGConfig,
    public global: Globals,
    public dialogService: DialogService
  ) { }




  ngOnInit(): void {
    var self = this;

    console.log("onInit-viewprod");
    this.primeNGConfig.ripple = true;

    this.sortField = "";
    this.sortOptions = [
      { label: 'Price High to Low', value: '!retail' },
      { label: 'Price Low to High', value: 'retail' }
    ];

    //    this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value!
    //    console.log(this.selectedMessage);

    thisOption: Option;

  }

  editMe(item: Product) {

    if (screen.width < 900) {
      const ref = this.dialogService.open(editProduct, {
        header: 'Edit: ' + item.title,
        width: '100%',
        data: {
          item
        },
      });
    }
    else {
      const ref = this.dialogService.open(editProduct, {
        header: 'Edit: ' + item.title,
        width: '900px',
        data: {
          item
        },
      });

    }
  }
  GetPoints(item: Rewards) {
    // User Rewards
    console.log("Reward!:", item);

    if (item.validationRequired) {
      //log request as event
      Logger("Rewards Validation", this.user.uid, this.companyID, this.user.email, item.title);
    }
  }

  myProductColor(row: Product) {
    if (row.myColor)
      return "f3f9fb"; //     "#f3fafeaa";// + row.myColor + "11";
    else return "FFAABB";
  }

  addRewardItem(item: onMenuItem) {

    item.RewardPaid = item.retail * 100;
    this.cartService.addItemToOrder(item);

  }

  addItem(item: onMenuItem) {

    this.cartService.addItemToOrder(item);

  }

  dateTimeSort() {

    this.sharedDataService.payOrder.sort(function (a, b) { return b.dateTime - a.dateTime; });

  }
  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  optionSelect(e, option: Options, row: onMenuItem) {

    console.log(option);
    if (option.type == 2) {  // PRICE ADJUST

      row.retail = 0;
      row.allOptions.forEach(function (arrayItem) {
        if (arrayItem.type == 2) {
          const i = arrayItem.list.lastIndexOf(arrayItem.chosen);
          if (i >= 0)
            row.retail += arrayItem.adjust[i];
        }
      })
    }
    if (option.type == 3) {  // PRICE ADJUST
      if (!row.baseRetail) row.baseRetail = row.retail;  //SET THE BASE

      row.retail = row.baseRetail;  // RESET RETAIL
  //    option.chosenList = this.selectedOptions2;
      option.chosenList.forEach(function (arrayItem: string){
        const i = option.list.lastIndexOf(arrayItem);
        console.log("opt adj: ",arrayItem);
        if (i >= 0)
          row.retail += option.adjust[i];
      })
      
    }

  }

}