import { Component, SkipSelf, HostListener, ViewChild, OnInit, AfterViewInit, BootstrapOptions } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TreeNode } from 'primeng/api';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../environments/environment';
import { interval, NotFoundError } from 'rxjs';
import { User, Media } from '../user-interface';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/globals';
import { Company, Form, Field, Gallery, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';
import { Product, Options, Table } from '../product-int';
import { onMenuItem } from '../menu-item-interface';
import { Content, Weather } from '../content-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../products.service';
import { ViewProductsComponent } from '../menu/view-products/view-products.component';
import { DragDropModule } from 'primeng/dragdrop';
import { HttpEvent } from '@angular/common/http';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { NavService } from '../menu/nav.service';
import { ReviewsComponent } from '../reviews/reviews.component';
import { Quote } from '../content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { NgxCsvParser } from 'ngx-csv-parser';
import { NgxCSVParserError } from 'ngx-csv-parser';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  csvRecords: any[] = [];
  header = true;
  @ViewChild('fileImportInput') fileImportInput: any;

  allProducts: Product[];
  searchString: string = "";
  viewProduct: boolean[];
  allItems = {};

  constructor(public global: Globals, private ngxCsvParser: NgxCsvParser, public productService: ProductsService) {
  //  this.getProducts();  // Change to use Product Service realtime updates

  }
  ngOnInit(): void {
    this.viewProduct = [];
    console.log("tags ", this.global.allTags)
  }
  exportProducts() {


    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'Products',
      useBom: false,
      noDownload: false,
      useHeader: true,
      headers: ["active", "onMenu", "title", "retail", "layout", "redeemable", "image1", "tag0", "tag1", "tag2", "tag3", "tag4", "tag5", "fullDescription", "html"],
      nullToEmptyString: true,
    };

    new AngularCsv(this.allProducts, 'My Products', options);
  }

  fileChangeListener($event: any): void {
    var self = this;
    console.log("Co:", self.global.myCompany.id)
    const files = $event.srcElement.files;
    this.header =
      (this.header as unknown as string) === 'true' ||
      this.header === true;
console.log("FILES: ", files);

    this.ngxCsvParser
      .parse(files[0], {
        header: this.header,
        delimiter: ',',
        encoding: 'utf8'
      })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          self.csvRecords = [];

          result.forEach(function (item) {
            if (item.title.length > 0) {
              if (item.tag0) {
                item.allTags = [];
                item.allTags.push(item.tag0);
              }
              if (item.tag1) { item.allTags.push(item.tag1); }
              if (item.tag2) { item.allTags.push(item.tag2); }

              if (item.tag3) { item.allTags.push(item.tag3); }
              if (item.tag4) { item.allTags.push(item.tag4); }
              if (item.tag5) { item.allTags.push(item.tag5); }
              self.csvRecords.push(item);
            }
          })
          console.log('Result', this.csvRecords);
        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
        }
      );
  }

  getFontSize(i) {
    if (this.viewProduct[i]) return "150%";
    else return "120%";

  }

  productFilter(item: Product): boolean {
    var self = this;

    if (this.tagSearchStr.length > 0) { // Search ONLY Tags
      if (item.allTags && item.allTags.includes(this.tagSearchStr)) return true;
      else return false;
    }

    if (this.searchString.length > 1) {
      if (item.title.toLowerCase().includes(this.searchString.toLowerCase())) return true;
      else return false;
    }
    return true;
  }
  clearSearch() {
    this.searchString = "";
    this.tagSearchStr = "";
  }

  tagSearchStr: string = "";
  tagSearch(t: string) {

    this.tagSearchStr = t;
    console.log(t);
  }


  // HACK HACK BAD CODE/  Get products once / check if exists/
  
  importProducts() {
    var self = this;

    var db = firebase.firestore();

    console.log("Importing ", self.global.myCompany.id)
    self.csvRecords.forEach(function (item: Product) {
      var productId = "";

      const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").where("title", "==", item.title)

   //   db.collection("products").where("companyID", "==", self.global.myCompany.id).where("title", "==", item.title)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            var docRef = db.collection("products").doc();
            self.updateProduct(docRef, item);
          }
          querySnapshot.forEach((doc) => {
            // Already have product so we update
            console.log("Updating Product");
            self.updateProduct(doc.ref, item);
          })
        })
    })
  }

  findMe(me: string, form: Form) {
    me = me.toLowerCase();

    const index = form.fields.findIndex(function (post) {
      if (post.title.toLowerCase() == me)
        return true;
    });
    if (index == -1) { alert("oops " + me) }
    return index;

  }

  addProduct (data: Form) {
    var self = this;
    var product: Product = {};

    product.title = data.fields[this.findMe("Product name", data)].binding;
    product.retail = +data.fields[this.findMe("Retail price", data)].value;

    console.log ("Adding product: ", data, product);

    var db = firebase.firestore();

    var docRef = db.collection("company").doc(self.global.myCompany.id).collection("products").doc();
    product.docID = docRef.id;
    product.companyID = self.global.myCompany.id;
    product.active = true;
    product.onMenu = true;
    product.type = 'Product'
    
    docRef.set(product)
      .then(() => {
        console.log("product Added!");
        self.clearForm(data);
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error adding product: ", error);
      });


  }

  clearForm (form: Form) {

    form.fields.forEach(function(field){
      field.binding = "";
      field.value = null;

    })
  }

  updateProduct(docRef, item: Product) {
    var self = this;

    //      headers: ["active", "onMenu", "title", "retail", "layout", "redeemable", "image1", "tag0", "tag1", "tag2", "tag3", "tag4", "tag5", "fullDescription", "html"],

    var newProd: Product = {
      companyID: self.global.myCompany.id,
      active: true,
      title: item.title,

    };
    newProd.docID = docRef.id;
    newProd.sid = docRef.id.substring(0,5)+ self.global.myCompany.id.substring(5,7)
    newProd.retail = parseInt(item.retail.toString());

    if (item.layout)
      newProd.layout = item.layout;
    if (item.image1)
      newProd.image1 = item.image1;
    if (item.fullDescription)
      newProd.fullDescription = item.fullDescription;
    if (item.onMenu)
      newProd.onMenu = true;
    if (item.redeemable)
      newProd.redeemable = false;
   // if (item.tag0)
   //   newProd.allTags.push()

    // Search for title first - use that docId if found

    docRef.set(newProd, { merge: true })
      .then(() => {
        console.log("Product added");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }
  getProdColor(p: Product) {
    if (p.active) {
      if (p.onMenu)
        return "#00aa00";
      else
        return "#000000";


    }
    else return "#aaaaaa";

  }
  firstTime: boolean = true;

  getProducts() {
    var self = this;
    var colorIn = 0;
    self.allProducts = [];
    self.global.allTagsCount.splice(0, self.global.allTagsCount.length);
    self.global.allTags = [];
    self.global.allTagsCount = [];

    console.log("getting ALL Products...");
    var db = firebase.firestore();

    const q = query(collection(db, "products"), where("companyID", "==", self.global.myCompany.id));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {

      //  self.allProducts = [];
      // self.allItems = [];
      //    self.global.allTagsCount.splice(0, self.global.allTagsCount.length);
      //    self.global.allTags = [];
      //   self.global.allTagsCount = [];
      querySnapshot.forEach((doc) => {

        var item = <Product>doc.data();
        // Fix Product/Content field
        if (item.type) {
          if (item.type.toLowerCase() == "product") item.type = "Product"
          if (item.type.toLowerCase() == "content") item.type = "Content"
        }
        else {
          if (item.retail > 0)
            item.type="Product";
          else
            item.type = "Content"
        }




        /*    const obj = {};
           
                      if (item.allTags && item.allTags.length) {
                        item.allTags.forEach((element, index) => {
                          obj[`tag${index}`] = element;
                        });
            
                        item = { ...item, ...obj }
                      }
                    */
        const index = self.allProducts.findIndex(prod => prod.docID === item.docID);
        if (index != -1)
          self.allProducts[index] = item;
        else {
        //  console.log("Adding")

        if (item.active == true && item.onMenu == true)
          self.allProducts.push(item);
        }

      })
      if (self.firstTime) {
        console.log("Addinglkjfd")
        self.firstTime = false;
        self.allProducts.forEach(function (item, index) {
          if (item.allTags) {
            item.allTags.forEach(function (tag) {

              var ind = self.global.allTags.indexOf(tag);
              if (ind == -1) {
                self.global.allTags.push(tag);
                self.global.allTagsCount.push(1);
              }
              else {
                self.global.allTagsCount[ind]++;
              }

            })
          }
        })



        // Sort - Alpha then active
        self.allProducts.sort(function (a, b) {
          if (b.title > a.title) return -1;
          if (b.title < a.title) return 1;
          return 0;

        });

        self.allProducts.sort(function (a, b) {
          if (b.active == a.active) return 0;
          if (b.active == false) return -1;
          return 1;

        });
      }

      console.log("All prod: ", self.allProducts, self.global.allTagsCount);
    })

  }



}