import { Injectable } from '@angular/core';
import { Order, OrderItem, OrderTicket } from '../order-interface';
import { Product, Options } from '../product-int';
import { onMenuItem } from '../menu-item-interface';
import firebase from 'firebase/compat/app';
import { FlexAlignStyleBuilder } from '@angular/flex-layout';
import { MessagePacket } from '../message-int';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { Company } from '../company-interface';
import { Globals } from '../../globals';
import { Observable } from 'rxjs';
import { doc, getDoc } from 'firebase/firestore';
import { User } from '../user-interface';
import { SelectItem, PrimeNGConfig, MessageService } from 'primeng/api';
//import { CustomizeMessageService, MessageState } from '../customize-message.service';


@Injectable({
    providedIn: 'root'
})
export class CartService {


    newOrderTicket: OrderTicket;
    newOrderItems: OrderItem[];

    newOrder: Order[] = [];
    obsNewOrder: Observable<any[]> = <any>[];
    //dataSourceNewOrder: MatTableDataSource<Order>;

    cartItems: number = 0;
    myCompany: Company;
    requestedCheckout: boolean = false;
    myOrder: Order[] = [];
    myOrderTotal: number = 0;
    myPointsTotal: number = 0;


    //tableNameQR: string = "";
    companyID: string = "";
    uid: string = "";
    userEmail: string = "";
    initializedOrders: boolean = false;

    allProducts: onMenuItem[] = [];

    CheckOutActive: boolean = false;

    constructor(public global: Globals,
        private messageService: MessageService,
    ) {



        this.myOrder = [];
    }

    initCart(userID, companyID, email, table) {

        this.global.tableNameQR = table;
        if (!table) this.global.tableNameQR = "";
        this.companyID = companyID;
        this.uid = userID;
        this.userEmail = email;
        console.log("INIT THE CART:", userID, companyID, email, table);

        // Get Product Data
        //   this.GetAllProducts();

    }

    goHome() {


    }

    initCart2(userID, table, companyID) {

        this.global.tableNameQR = table;
        this.companyID = companyID;
        this.uid = userID;
        //    this.userEmail = email;
        console.log("INIT THE CART:", table);

        // Get Product Data
        //     this.GetAllProducts();

    }

    InitCartNew() {
        this.myOrder = [];
        /*
                this.msgs1 = [
                    {severity:'success', summary:'Success', detail:'Message Content'},
                    {severity:'info', summary:'Info', detail:'Message Content'},
                ];
        
        */
        if (!this.global.myCompany || (this.global.store != this.global.myCompany.store))
            this.GetStore(this.global.store);
    }
    async GetCustomerwID(id: string) {
        if (!id) return;

        var db = firebase.firestore(); // Make a Global!!

        console.log("Custid: ", id);
        const docRef = doc(db, "users", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            console.log("Document data:", docSnap.data());
            const data = <User>docSnap.data();
            this.global.customer = data;
        } else {
            // doc.data() will be undefined in this case
            console.log("No such customer!");
        }
        /*
        
        var docRef = db.collection("users").doc("SF");
        
        docRef.get().then((doc) => {
            if (doc.exists) {
                console.log("Document data:", doc.data());
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
        */
    }
    GetStore(store: string) {
        var db = firebase.firestore();
        var self = this;
        if (!store) store = "";
        this.myCompany = new Company();
        console.log("NEW HEADER");
        db.collection("company").where("store", "==", store)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = <Company>doc.data();
                    self.companyID = data.id;

                    //      localStorage.setItem('store', JSON.stringify(self.store));

                    self.myCompany = data;
                    self.global.myCompany = data;
                    console.log(self.myCompany);


                });
            })
            .catch((error) => {
                console.log("no documents: ", error);
            });

    }

    getOrders(): Order[] {
        /*
        
            let x: Order = {
                item: "Crab Cakes",
                id: "",
                type: "order",
                companyID: "",
                dateTime: 0,
                localTime: "0",
                timeLapse: "0",
                category: "",
                myColor: "0",
                userID: "",
                tableName: "",
                name: "",
                status: "",
                price: 0,
                qty: 0,
                extPrice: 0,
                dept: "All",
                //  this.allOptions = [];
              
                startedAt: 0,
                readyAt: 0,
                deliveredAt: 0,
                closedAt: 0
        
            }
        
           this.newOrder.push(x);
         */
        return this.newOrder;
    }

    sendMsg(theMessage: string, table?: string) {

        // Huh??

        return;


        if (!table) var table = "";
        console.log("MSG: ", theMessage, table);
        let msg: MessagePacket = {
            msg: theMessage,
            dateTime: Date.now(),
            readDateTime: 0,
            read: false,
            tableName: table
        }
        var db = firebase.firestore();
        var ref = db.collection("messages").doc();
        var myId = ref.id;

        ref.set({
            id: myId,
            dateTime: msg.dateTime,
            read: msg.read,
            msg: msg.msg,
            readDateTime: msg.readDateTime,
            tableName: msg.tableName
        }, { merge: true })
            .then(() => {
                // Here we can safely remove
                console.log("Document successfully written!");
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });

    }



    updateMsg(id: string) {

        var db = firebase.firestore();
        var ref = db.collection("messages").doc(id);

        ref.update({
            read: true,
            readDateTime: Date.now()
        })
            .then(() => {
                // Here we can 
                console.log("Message successfully updated!");
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
    }

    updateRes(id: string) {

        var db = firebase.firestore();
        var ref = db.collection("reservations").doc(id);

        ref.update({
            status: "closed",
        })
            .then(() => {
                // Here we can
                console.log("Reseervation updated!");
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });
    }


    LoadOrderTicket(ticketName: string) {
        var self = this;

        // Convert ticketId to ticketId as index
        var myTicketId;
        Object.entries(self.global.openTickets).forEach(function (ticket: any) {
            if (ticket[1].ticket.ticketName == ticketName)
                myTicketId = ticket[1].ticket.id;
        })



        // CHECK IF CART NOT EMPTY - AUTO SAVE - if THAT TABLE IS NOT ALREADY SAVED
        if (0 && self.newOrder.length) {
            var found = false;

            console.log("CHECK AUTOSAVE ", myTicketId, self.global.openOrders, self.newOrder[0].tableName)
            self.global.openOrders.forEach(function (item: Order) {
                if (item.tableName == self.newOrder[0].tableName) {
                    found = true;
                }
            })
            if (!found) {
                /*    this.autoSave.splice(0, this.autoSave.length);
                    this.autoSave = [];
                    this.newOrder.forEach(function (arrayItem) {
                      arrayItem.tableName = self.myTable;
                      self.autoSave.push(arrayItem);
                      console.log("autosave :", self.autoSave[0]);
                    })*/
            }

        }

        // EMPTY EXISTING ORDER
        self.newOrderTicket = {};
        self.newOrderItems = [];
        if (!self.global.openTickets[myTicketId].items)
            self.global.openTickets[myTicketId].items = [];


        if (self.global.openTickets[myTicketId].ticket.ticketName == ticketName) {
            self.newOrderTicket = self.global.openTickets[myTicketId].ticket;
            if (self.global.openTickets[myTicketId].items)
                self.newOrderItems = self.global.openTickets[myTicketId].items;

            // Now we create a listener for this order...
            self.watchOpenTicket(myTicketId, self.gotOrderItems, self)
        }

        // this.newOrder.sort(function (a, b) { return b.dateTime - a.dateTime; });
        //  this.simplifyOrder();

        /*
            self.myTable = tableName;
            if (self.newOrder.length > 0)
              self.editMode = 0; // 1; no auto-sending
            else
              self.editMode = 0; // New order
        
            // IF Customer ID present then load Customer
            if (self.newOrder[0])
              self.cartService.GetCustomerwID(self.newOrder[0].userID);
        
            self.viewTabItem.forEach(function (item) {
              item = false;
        
            })
        */
        self.global.customer = null; // UNLOAD ANY CUSTOMER
        console.log("Loaded Order ", self.newOrderItems);
    }

    gotOrderItems(self, ticketId) {
        self.newOrderItems = self.global.openTickets[ticketId].items;
    }





    LoadOrder(tableName: string) {
        var self = this;

        // CHECK IF CART NOT EMPTY - AUTO SAVE - if THAT TABLE IS NOT ALREADY SAVED
        if (0 && self.newOrder.length) {
            var found = false;

            console.log("CHECK AUTOSAVE ", self.global.openOrders, self.newOrder[0].tableName)
            self.global.openOrders.forEach(function (item: Order) {
                if (item.tableName == self.newOrder[0].tableName) {
                    found = true;
                }
            })
            if (!found) {
                /*    this.autoSave.splice(0, this.autoSave.length);
                    this.autoSave = [];
                    this.newOrder.forEach(function (arrayItem) {
                      arrayItem.tableName = self.myTable;
                      self.autoSave.push(arrayItem);
                      console.log("autosave :", self.autoSave[0]);
                    })*/
            }

        }

        self.newOrder.splice(0, self.newOrder.length);

        self.global.openOrders.forEach(function (arrayItem: Order, index) {
            if (arrayItem.tableName == tableName) {
                self.newOrder.push(arrayItem);

                //   self.currentOrder = index;

            }
        })
        this.newOrder.sort(function (a, b) { return b.dateTime - a.dateTime; });
        //  this.simplifyOrder();

        /*
            self.myTable = tableName;
            if (self.newOrder.length > 0)
              self.editMode = 0; // 1; no auto-sending
            else
              self.editMode = 0; // New order
        
            // IF Customer ID present then load Customer
            if (self.newOrder[0])
              self.cartService.GetCustomerwID(self.newOrder[0].userID);
        
            self.viewTabItem.forEach(function (item) {
              item = false;
        
            })
        */
        self.global.customer = null; // UNLOAD ANY CUSTOMER
        console.log("Loaded Order");
    }

    deleteOrder(order: Order) {

        console.log("Deleting order: ", order);
        var db = firebase.firestore();
        var ref = db.collection("orders").doc(order.id);

        ref.update({
            status: "deleted",
        }).then(() => {
            console.log("Order updated!");
        })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });

    }

    RemoveTicketItem(e, item: OrderItem, i) {
        var self = this;

        e.stopPropagation();

        if (item.status == 'Cart' || item.status.toLowerCase() == 'new') {
            if (!item.saved) {
                self.newOrderItems.splice(i, 1);
                return;
            }
            else {
                console.log("Deleting order: ", item);
                var db = firebase.firestore();
                var ref = db.collection("company").doc(item.companyID)
                    .collection("orders").doc(item.ticketId)
                    .collection("items").doc(item.id)

                ref.update({
                    status: "deleted",
                })
                    .then(() => {
                        // Here we can
                        console.log("Item deleted!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }

        }
    }


    removeTicket(item: OrderTicket) {
        var self = this;

        console.log("Deleting order: ", item);
        var db = firebase.firestore();
        var ref = db.collection("company").doc(item.companyID)
            .collection("orders").doc(item.id)
            .collection("items").get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    doc.ref.delete()// remove item

                })
                // Now remove parent record
                var ref = db.collection("company").doc(item.companyID)
                    .collection("orders").doc(item.id).delete()
            })

    }






    RemoveItem(e, item: Order, i) {
        e.stopPropagation();

        if (item.status == 'Cart' || item.status.toLowerCase() == 'new') {

            if (!item.saved) {
                this.newOrder.splice(i, 1);
                return;

            }
            else {

                console.log("Deleting order: ", item);
                var db = firebase.firestore();
                var ref = db.collection("orders").doc(item.id);

                ref.update({
                    status: "deleted",
                })
                    .then(() => {
                        // Here we can
                        console.log("Order updated!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }

        }
    }

    addItemToTicket(prod) {
        var self = this;
        var item;

        // If item is a string then find the product 
        if (typeof (prod) == 'string') {
            self.global.allProducts.forEach(function (product) {
                if (product.title == prod)
                    item = product;
            })
        }
        else item = prod;

        console.log("PRODUCT: ", item)

     //   if (!self.global.tableNameQR || self.global.tableNameQR.length == 0)
     //       self.global.tableNameQR = "New Order: "

        // IF (all options required global setting)
        if (item.allOptions) {
            var missingOptions = false;
            item.allOptions.forEach(function (opt) {
                if (opt.chosen.length == 0) {
                    if (opt.list.length == 1) {
                        // AUTO-CHOOSE SINGLE OPTION ITEM
                        opt.chosen = opt.list[0];
                    }
                }
                if (opt.chosen.length == 0 && opt.type != 3) missingOptions = true;

            });
            if (missingOptions) {
                console.log("Some options are missing");
                this.openSnackBar(item.title + " Missing Selections", "");
                return;
            }
        }

        if (item.qty == 0) item.qty = 1;//hack??

        self.cartItems = self.addToTicket(item, self.global.myCompany.id, self.global.tableNameQR, self.global.userName);

        console.log(this.newOrderItems, self.cartItems);

    }






    addItemToOrder(prod) {

        var self = this;
        var item: Product;


        // If item is a string then find the product 
        if (typeof (prod) == 'string') {
            self.global.allProducts.forEach(function (product) {
                if (product.title == prod)
                    item = product;
            })
        }
        else item = prod;

        console.log("PRODUCT: ", item)

        // IF (all options required global setting)
        if (item.allOptions) {
            var missingOptions = false;
            item.allOptions.forEach(function (opt) {
                if (opt.chosen.length == 0) {
                    if (opt.list.length == 1) {
                        // AUTO-CHOOSE SINGLE OPTION ITEM
                        opt.chosen = opt.list[0];
                    }
                }
                if (opt.chosen.length == 0 && opt.type != 3) missingOptions = true;

            });
            if (missingOptions) {
                console.log("Some options are missing");
                this.openSnackBar(item.title + " Missing Selections", "");
                return;
            }
        }
        //        var displayName = "Guest";
        //        if (self.global.authuser) displayName = self.global.authuser.displayName;

        if (item.qty == 0) item.qty = 1;//hack??

        self.cartItems = self.addToOrder(item, self.global.myCompany.id, self.global.tableNameQR, self.global.userName);
        //        if (item.retail == 0) this.SendOrder();

        self.SendTheOrder();
        self.newOrder = this.getOrders();

        console.log(this.newOrder, self.cartItems);

    }


    unSavedItems() {
        var notSavedCount = 0;

        this.myOrder.forEach(function (item) {
            if (!item.saved) notSavedCount++;
        })
        //    console.log("CART HAS ",notSavedCount)
        return notSavedCount;

    }

    cartItemCount() {
        var cartCount = 0;

        this.newOrderItems.forEach(function (item) {
            if (item.status == "Cart") cartCount++;
            //else console.log("Was ", item.status)
        })
        //   console.log("CART HAS ",cartCount)
        return cartCount;

    }

    addToTicket(item: Product, cid: string, tableName: string, userName: string) {
        var self = this;
        if (!self.newOrderItems) self.newOrderItems = [];

        console.log(item.title, item.companyID, cid, self.global.userName);
        const getRealValue = (val) => +val || val;
        // CHECK IF ITEM IS ALREADY IN CART AND IF SO UPDATE QTY - else QTY = 1

        this.messageService.clear();
        this.messageService.add({ key: 'toast1', severity: 'success', summary: "Item added to cart", detail: item.title + ' $' + item.retail });

        // this.MakeNoise(item);

        var found = false;
        var foundItem;
        self.newOrderItems.forEach(function (arrayItem) {
            if (arrayItem.item == item.title) {
                //update QTY
                arrayItem.qty++;
                arrayItem.extPrice = arrayItem.price * arrayItem.qty;
                found = true;
                foundItem = arrayItem;
                console.log("found");
            }
        })
        if (found) {
            self.updateTicket(foundItem);

            return;
        }

        let theOrder: OrderItem = {

            type: "order",
            paid: false,
            //   companyID: item.companyID, //???
            timeLapse: "0",

            dept: "All",
            startedAt: 0,
            readyAt: 0,
            deliveredAt: 0,
            closedAt: 0,
            showOrder: true

        }


        if (!found) {

            if (!item.allOptions)
                theOrder.allOptions = [];
            else if (item.allOptions) {

                theOrder.allOptions = JSON.parse(JSON.stringify(item.allOptions));

            }

            if (!tableName) {
                tableName = self.global.tableNameQR;
            }
            theOrder = { ...item, ...theOrder };
            var uid = "";
            if (self.global.authuser) uid = self.global.authuser.uid;
            theOrder.userID = uid;
            theOrder.item = item.title;
            theOrder.price = getRealValue(item.retail);
            if (self?.newOrderTicket?.id) {
                theOrder.ticketId = self.newOrderTicket.id;
            }

            theOrder.extPrice = item.retail;
            theOrder.name = userName;
            // theOrder.dateTime = Date.now();
            theOrder.status = "Cart";

            //   if (item.RewardPaid) theOrder.RewardPaid = item.RewardPaid;

            self.newOrderItems.push(theOrder);
            console.log("Added to cart", theOrder, self.newOrderItems);

        }
        self.cartItems = self.newOrderItems.length;

        // If No ticket then auto-create
        console.log("TICKET ", self.newOrderTicket);

        if (!self.newOrderTicket|| !self.newOrderTicket.id || !self.newOrderTicket.id.length) { //
            var ticketName = "Cart-" + (Math.floor(Math.random() * 8999) + 1000).toString()

            self.newOrderItems = [];
            self.newOrderTicket = {
                companyID: self.global.myCompany.id,
                userID: self.global.authuser.uid,
                type: "order",
                //   dept?: string;
                ticketName: ticketName,
                tableName: tableName,
                //  name?: string; // We know name from registration
                status: 'Cart',  // NEW, STARTED, READY, DELIVERED
                startedAt: Date.now(),
                sentAt: Date.now(),

                paid: false,
                saved: false, // Am I in database
                testMode: false,
            }

            var db = firebase.firestore();
            var ticketRef = db.collection("company").doc(self.global.myCompany.id)
                .collection("orders").doc()

            self.newOrderTicket.id = ticketRef.id;
            theOrder.ticketId = ticketRef.id;

            ticketRef.set(self.newOrderTicket)
                .then(() => {
                    console.log("New Ticket successfully created! ", theOrder, self.newOrderTicket.id);
                   // theOrder.ticketId = self.newOrderTicket.id;
                    // Now I can Add Item
                    self.newTicketItem(theOrder, self.global.tableNameQR, "me");
                    self.watchOpenTicket(theOrder.ticketId, self.gotOrderItems, self)
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });

        }
        else {
            // Add this item to the order
            self.newTicketItem(theOrder, self.global.tableNameQR, "me");
        }

        self.global.viewOrderMode = 1
        return this.cartItems;

    }






    addToOrder(item: Product, cid: string, tableName: string, userName: string) {
        var self = this;
        console.log(item.title, item.companyID, cid, self.global.userName);
        const getRealValue = (val) => +val || val;
        // CHECK IF ITEM IS ALREADY IN CART AND IF SO UPDATE QTY - else QTY = 1

        this.messageService.clear();
        this.messageService.add({ key: 'toast1', severity: 'success', summary: "Item added to cart", detail: item.title + ' $' + item.retail });

        // this.MakeNoise(item);
        var found = false;
        this.newOrder.forEach(function (arrayItem: Order) {
            if (arrayItem.item == item.title) {
                //update QTY
                arrayItem.qty++;
                arrayItem.extPrice = arrayItem.price * arrayItem.qty;
                found = true;
                console.log("found");
            }
        })
        if (!found) {

            let theOrder: Order = {
                item: "Crab Cakes",
                id: "",
                type: "order",
                paid: false,
                companyID: item.companyID,
                dateTime: 0,
                localTime: "0",
                timeLapse: "0",
                category: "",
                myColor: "0",
                userID: "",
                tableName: "",
                name: "",
                status: "",
                price: 0,
                qty: 0,
                extPrice: 0,
                dept: "All",
                //  this.allOptions = [];

                startedAt: 0,
                readyAt: 0,
                deliveredAt: 0,
                closedAt: 0,
                showOrder: true

            }
            if (!item.allOptions)
                theOrder.allOptions = [];
            else if (item.allOptions) {

                theOrder.allOptions = JSON.parse(JSON.stringify(item.allOptions));

            }

            if (!tableName) {
                tableName = self.global.tableNameQR;
            }
            theOrder = { ...theOrder, ...item };


            var uid = "";
            if (self.global.authuser) uid = self.global.authuser.uid;
            theOrder.userID = uid;
            theOrder.item = item.title;
            theOrder.price = getRealValue(item.retail);

            theOrder.extPrice = item.retail;
            theOrder.name = userName;
            theOrder.dateTime = Date.now();
            theOrder.status = "Cart";

            //   if (item.RewardPaid) theOrder.RewardPaid = item.RewardPaid;

            this.newOrder.push(theOrder);
            console.log("Added to cart", this.newOrder);

        }
        this.cartItems = this.newOrder.length;
        self.global.viewOrderMode = 1
        return this.cartItems;

    }


    SendMyOrder(event) {
        var self = this;
        var db = firebase.firestore();

        event.stopImmediatePropagation();

        // Update any Cart items to New

        // Let's update the Ticket first
        var ticketRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("orders").doc(self.newOrderTicket.id)

        self.newOrderTicket.ticketName = self.newOrderTicket.ticketName.replace('Cart', 'New');
        self.newOrderTicket.status = 'New'
        console.log("Send Order ", self.newOrderTicket);
        ticketRef.update(self.newOrderTicket) //{ status: 'New', ticketName: self.newOrderTicket.ticketName }
            .then(() => {
                console.log("New Order successfully sent to server!");
            })
            .catch((error) => {
                console.error("Error writing document: ", error);
            });


        self.newOrderItems.forEach(function (item) {
            if (item.status == 'Cart') {
                db.collection("company").doc(self.global.myCompany.id)
                    .collection("orders").doc(item.ticketId)
                    .collection("items").doc(item.id)
                    .update({ status: 'New' })
                    .then(() => {
                        console.log("New Order successfully sent to server!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }

        })


    }


    SendToServer(event) {
        var self = this;
        var db = firebase.firestore();

        // Update any Cart items to New
        console.log("SEND TO SERVER ", this.newOrder)
        this.newOrder.forEach(function (item) {
            if (item.status == 'Cart') {
                db.collection("orders").doc(item.id).update({ status: 'New' })
                    .then(() => {
                        console.log("New Order successfully sent to server!");
                    })
                    .catch((error) => {
                        console.error("Error writing document: ", error);
                    });
            }

        })


    }

    SendTheOrder(event?: any) {  // SEND TO KITCHEN
        var self = this;

        if (event)
            event.stopPropagation();

        var userID;

        if (this.global.authuser)
            userID = this.global.authuser.uid;
        else userID = "Guest-" + this.global.tableNameQR;

        this.newOrder.forEach(function (arrayItem) {
            //  self.Order(arrayItem);
            console.log("CID: ", arrayItem.companyID);
            var name = "Guest";
            if (self.global.authuser) name = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            self.Order(arrayItem, self.global.tableNameQR, name, self.allProducts);

        })
        this.openSnackBar("Order has been sent.", "Thanks.");
        this.requestedCheckout = false; // NEW ITEMS
        this.sendMsg("Has placed a New Order", this.global.tableNameQR)


        //     this.newOrder.splice(0, this.newOrder.length);
        //    this.obsNewOrder = this.dataSourceNewOrder.connect();

        this.cartItems = this.newOrder.length;

        if (!this.initializedOrders) {
            //   self.initCart2(userID, self.global.tableNameQR, self.global.myCompany.id);

            //   this.GetMyOrder();
        }
        //  console.log("RequestCheckout ", this.cartService.requestedCheckout)
    }



    openSnackBar(message: string, action: string) {
        /* this._snackBar.open(message, action, {
             duration: 2000,
             horizontalPosition: "center",
             verticalPosition: "bottom"
         });
     
         */
    }

    updateTicket(item: OrderItem) {
        var self = this;
        var db = firebase.firestore();
        if (item.id) {
            var itemRef = db.collection("company").doc(self.global.myCompany.id)
                .collection("orders").doc(item.ticketId).collection("items").doc(item.id)

            itemRef.update(item)
                .then(() => {
                    console.log("Item successfully updated!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
        }

    }

    newTicketItem(item: OrderItem, tableName, userName) {
        var self = this;
        if (item.saved) { console.log("Already saved "); return; }

        self.requestedCheckout = false;

        if (self.global.authuser)
            item.userID = self.global.authuser.uid;
        else item.userID = "Guest-" + this.global.tableNameQR;
        item.tableName = self.global.tableNameQR;
        item.extPrice = item.price * item.qty;
        //  item.dateTime = Date.now();
        //  item.localDateTime = TimeFormat(Date.now());
        item.name = userName;
        item.status = "Cart";

        if (!item.showOrder) item.showOrder = true;

        //    var newItem = JSON.parse(JSON.stringify(item));

        //   if (!item.RewardPaid) item.RewardPaid = 0;

        var orderItem: OrderItem = {
            ...item,
            type: "order",
            tableName: tableName,

            extPrice: item.price * item.qty,
            name: userName,
            //    dateTime: Date.now(), //item.dateTime, //only here?
            //    localDateTime: TimeFormat(Date.now()),
            status: "Cart",
            saved: true,
        }
        if (!item.ticketId) {
        console.log("Bad ticket ORDER IT: ", item);
        return;
        }
        if (item.allOptions && item.allOptions.length > 0) orderItem.allOptions = item.allOptions;

        var db = firebase.firestore();
        if (!item.id) {
            var itemRef = db.collection("company").doc(self.global.myCompany.id)
                .collection("orders").doc(item.ticketId).collection("items").doc()
            orderItem.id = itemRef.id;
            item.id = itemRef.id;
            orderItem.saved = true;
            itemRef.set(orderItem)
                .then(() => {
                    console.log("New Item successfully written!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });
        }


    }








    Order(item: Order, tableName, userName, allProducts) {

        var self = this;
        if (item.saved) { console.log("Already saved "); return; }

        this.requestedCheckout = false;


        if (this.global.authuser)
            item.userID = this.global.authuser.uid;
        else item.userID = "Guest-" + this.global.tableNameQR;
        item.tableName = this.global.tableNameQR;
        item.extPrice = item.price * item.qty;
        item.dateTime = Date.now();
        item.localDateTime = TimeFormat(Date.now());
        item.name = userName;
        item.status = "Cart";

        if (!item.showOrder) item.showOrder = true;
        console.log("ORDER IT: ", item);
        //    var newItem = JSON.parse(JSON.stringify(item));

        if (!item.RewardPaid) item.RewardPaid = 0;

        if (!item.saved) {

            var orderItem: Order = {
                ...item,

                type: "order",
                tableName: tableName,

                extPrice: item.price * item.qty,
                name: userName,
                dateTime: Date.now(), //item.dateTime, //only here?
                localDateTime: TimeFormat(Date.now()),
                status: "Cart",

                saved: true,
            }
            if (item.allOptions && item.allOptions.length > 0) orderItem.allOptions = item.allOptions;

            var db = firebase.firestore();
            db.collection("orders").doc().set(orderItem)
                .then(() => {
                    console.log("New Order successfully written!");
                })
                .catch((error) => {
                    console.error("Error writing document: ", error);
                });

            /* LET STAFF SEND ORDER WITH OPTIONS TO KITCHEN
                        console.log ("Products: ",self.allProducts);
                // AUTO-ADD Options  
                item.allOptions.forEach(function (arrayItem) {
                    if (arrayItem.type == 1) {
                      var found = -1;
                      self.allProducts.forEach(function (item: onMenuItem, index) {
                        if (item.title == arrayItem.chosen) { // Found item.
                          found = index;
                          console.log("Found option ", found);
                        }
                      });
                      if (found != -1 ) {
                        console.log("Found option ", found);
              
                        db.collection("orders").doc().set({
                          companyID: self.companyID,
                          type: "order",
                          tableName: tableName,
                          item: self.allProducts[found].title,
                      //    dept: self.allProducts[found].dept,
                          price: self.allProducts[found].retail,
                          qty: 1,
                          extPrice: 0,  // How to allocate dollars to auto-adds - perhaps
                          name: userName,
                          dateTime: Date.now(),
                          localDateTime: TimeFormat(Date.now()),
                          startedAt: item.startedAt,
                          readyAt: item.readyAt,
                          deliveredAt: item.deliveredAt,
                          closedAt: item.closedAt,
                          status: "New",
                          category: self.allProducts[found].category,
                          image1: self.allProducts[found].image1,
                          myColor: self.allProducts[found].myColor,
                          note: item.note
                        })
                          .then(() => {
                            // Here we can safely remove
                            console.log("Auto ADDED 1 item!");
                          })
                          .catch((error) => {
                            console.error("Error writing document: ", error);
                          });
              
                      }
                    }
              
                  });
            */


            /*
                    // AUTO-ADD Options  
                    if (item.allOptions) {
                        item.allOptions.forEach(function (arrayItem) {
                            if (arrayItem.type == 1) {
                                var found = -1;
                                self.allProducts.forEach(function (item: onMenuItem, index) {
                                    if (item.title == arrayItem.chosen) { // Found item.
                                        found = index;
                                        console.log("Found option ", found);
                                    }
                                });
                                if (found) {
                                    console.log("Found option ", found);
            
                                    db.collection("orders").doc().set({
                                        companyID: self.companyID,
                                        type: "order",
                                        tableName: tableName,
                                        userID: self.uid,
                                        item: self.allProducts[found].title,
                                        dept: self.allProducts[found].dept,
                                        price: self.allProducts[found].retail,
                                        qty: 1,
                                        extPrice: 0,  // How to allocate dollars to auto-adds - perhaps
                                        name: userName,
                                        dateTime: Date.now(),
                                        localDateTime: TimeFormat(Date.now()),
                                        startedAt: item.startedAt,
                                        readyAt: item.readyAt,
                                        deliveredAt: item.deliveredAt,
                                        closedAt: item.closedAt,
                                        status: "Cart",
                                        category: self.allProducts[found].category,
                                        image1: self.allProducts[found].image1,
                                        myColor: self.allProducts[found].myColor,
                                        note: item.note
                                    })
                                        .then(() => {
                                            // Here we can safely remove
                                            console.log("Auto ADDED 1 item!", TimeFormat(Date.now()));
                                        })
                                        .catch((error) => {
                                            console.error("Error writing document: ", error);
                                        });
            
                                }
                            }
            
            
                        });
                    }
                    */
        }
    }
    updateGuestOrder() { // Specificically for updating GUEST order to NEW userID

        var db = firebase.firestore();
        var self = this;

        // NEED EXTRA VERIFY - ??
        var userID = "Guest-" + self.global.tableNameQR;
        db.collection("orders").where("companyID", "==", self.global.myCompany.id).where("userID", "==", userID)
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.id, " => ", doc.data());
                    doc.ref.update({
                        userID: self.global.authuser.uid
                    });
                });
                self.GetMyOrder();
            })
            .catch((error) => {
                console.log("Error getting documents: ", error);
            });

    }

    GetMyOrder() {
        var self = this;
        var db = firebase.firestore();
        var userID;

        if (this.global.authuser)
            userID = self.global.authuser.uid;
        else
            userID = "Guest-" + self.global.tableNameQR;
        console.log("Getting total order: ", userID, self.global.myCompany.id);

        self.initializedOrders = true;

        db.collection("company").doc(self.global.myCompany.id)
            .collection("orders")
            .where("userID", "==", userID)
            .where("status" ,"!=", 'deleted')
            .onSnapshot((querySnapshot) => {
                self.myOrder = [];
                self.newOrderTicket = {};
                var allDelivered = true;
                self.myOrderTotal = 0;
                self.myPointsTotal = 0;
             //   if (!self.global.openTickets) self.global.openTickets = {};

                querySnapshot.forEach((doc) => {
                    var data = <OrderTicket>doc.data();
                    if (data.status != 'Closed' && data.status != 'deleted') {

                      //  if (!self.global.openTickets[data.id]) self.global.openTickets[data.id] = {};
                        //if (self.global.openTickets[m.id].id == data.id)
                     //   self.global.openTickets[data.id].ticket = data;
                     //   self.global.openTickets[data.id].items = [];



                        self.newOrderTicket = data;

                        if (!data.id) data.id = doc.id;

                        if (data.companyID == self.global.myCompany.id && data.status != "Closed" && data.status.toLowerCase() != "deleted") {
                            self.newOrderItems = [];
                            self.watchMyCart(data.id, self.gotOrderItems, self)


                            //    console.log("item: ", data);
                            //   self.myOrder.push(data);
                            if (!data.RewardPaid) {
                                //   self.myOrderTotal += data.extPrice; //data.price * data.qty;
                            }
                            else { // Cost of Reward Points

                                self.myPointsTotal += data.RewardPaid;
                            }
                            //    if (self.tableNameQR) {
                            //  if (data.price > 0 && data.status != "Delivered") {
                            //     allDelivered = false;
                            //  }

                        }
                    }
                });

                self.newOrder = self.myOrder;
                if (self.myOrder.length)
                    self.global.viewOrderMode = 1;
                self.CheckOutActive = allDelivered;
                if (self.myOrderTotal == 0) self.CheckOutActive = false;
                console.log("Open Cart ", self.newOrderTicket);
                //   orders = self.myOrder;
                // console.log("order ready for checkout: ", self.CheckOutActive, self.newOrderItems.length);
            })

    }

    watchMyCart(ticketId, callBack, mySelf) {
        var self = this;
        var db = firebase.firestore();
        var index = 0;

        // if we have data, must already be watching
      //  if (self.ticketsWatching.includes(ticketId)) return;
     //   if (!self.global.openTickets[ticketId]) {
      //      console.log("BAD TICKET ", self.global.openTickets[ticketId])
      //  }

        db.collection('company').doc(self.global.myCompany.id).collection("orders")
            .doc(ticketId).collection("items")
            .onSnapshot((querySnapshot) => {
                //self.global.openTickets[ticketId].items = [];
              //  self.ticketsWatching.push(ticketId)
                querySnapshot.docChanges().forEach((change) => {
                    console.log("GOT NEW ITEMS ", change.type, self.global.openTickets, ticketId)
                    if (change.type === "added") {
                        const m = <OrderItem>change.doc.data();
                        if (m.status != 'deleted') {
                            if (!m.id) m.id = change.doc.id;

                            const index = self.newOrderItems.findIndex(x => x.id === m.id)
                            if (index == -1) { // Only add if not already there
                                console.log("Adding ", m)
                                self.newOrderItems.push(m)
                            }
                        }
                    }
                    if (change.type === "modified") {
                        const m = <OrderItem>change.doc.data();
                        console.log("Modified item ", m)
                        var index = -1;
                        if (self.global.openTickets[m.ticketId]?.items?.length)
                            index = self.global.openTickets[m.ticketId].items.findIndex(x => x.id === m.id)
                        if (index != -1) {
                            if (m.status == 'deleted')
                                self.global.openTickets[ticketId].items.splice(index, 1);//remove me
                            else
                                self.global.openTickets[ticketId].items[index] = m;
                        }
                        console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed") {
                        const m = <OrderItem>change.doc.data();
                        console.log("Removed items ", m)
                        var index = -1;
                        if (self.global.openTickets[m.ticketId]?.items?.length)
                            index = self.global.openTickets[m.ticketId].items.findIndex(x => x.id === m.id)
                        if (index != -1)
                            self.global.openTickets[ticketId].items.splice(index, 1);//remove me

                    }

                });
                /*
                self.global.allChatBots.sort(function (a, b) {
                  if (!a.createdAt || !a.editedAt) {
                    a.editedAt = Date.now()
                  }
                  if (!b.createdAt || !b.editedAt) {
                    b.editedAt = Date.now()
                  }
                  return a.editedAt - b.editedAt;
                });
                */
                //  if (typeof (callBack) == 'function') callBack(mySelf, ticketId)
              //  if (self.global?.openTickets[ticketId]?.items)
              //      self.newOrderItems = <OrderItem[]>self.global.openTickets[ticketId].items;
                console.log("Open Items: ", self.newOrderTicket, self.newOrderItems);

            });

    }






    watchingTickets: boolean = false;

    watchOpenTickets(callBack, mySelf) {
        var self = this;
        var db = firebase.firestore();
        var index = 0;

        if (self.watchingTickets) return;
        self.newOrderItems = [];
        self.watchingTickets = true;
        if (!self.global.openTickets) self.global.openTickets = {};

        db.collection('company').doc(self.global.myCompany.id).collection("orders")
            .where("status", "!=", 'Closed')
            .onSnapshot((querySnapshot) => {
                if (!self.global.openTickets) self.global.openTickets = {};

                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const m = <OrderTicket>change.doc.data();
                        if (!self.global.openTickets[m.id]) self.global.openTickets[m.id] = {};
                        //if (self.global.openTickets[m.id].id == m.id)
                        self.global.openTickets[m.id].ticket = m;
                    }
                    if (change.type === "modified") {
                        const m = <OrderTicket>change.doc.data();
                        /*  Object.entries(self.global.openTickets).forEach(function (ticket: any) {
                              if (ticket[1].ticket.ticketName == m.ticketName)
                                  myTicketId = ticket[1].ticket.id;
                          })*/

                        self.global.openTickets[m.id].ticket = m;

                        console.log("Modified Ticket: ", m);
                    }
                    if (change.type === "removed") {
                        const m = <OrderTicket>change.doc.data();
                        console.log("Removed Ticket ", m)
                        delete self.global.openTickets[m.id]

                    }

                });
                /*
                self.global.allChatBots.sort(function (a, b) {
                  if (!a.createdAt || !a.editedAt) {
                    a.editedAt = Date.now()
                  }
                  if (!b.createdAt || !b.editedAt) {
                    b.editedAt = Date.now()
                  }
                  return a.editedAt - b.editedAt;
                });
                */
                if (typeof (callBack) == 'function') callBack(mySelf)
                console.log("Open Tickets: ", self.global.openTickets);

            });

    }

    ticketsWatching: string[] = [];

    watchOpenTicket(ticketId, callBack, mySelf) {
        var self = this;
        var db = firebase.firestore();
        var index = 0;

        // if we have data, must already be watching
        if (self.ticketsWatching.includes(ticketId)) return;
        if (!self.global.openTickets[ticketId]) {
            console.log("BAD TICKET ", self.global.openTickets[ticketId])
        }

        db.collection('company').doc(self.global.myCompany.id).collection("orders")
            .doc(ticketId).collection("items")
            .onSnapshot((querySnapshot) => {
                //self.global.openTickets[ticketId].items = [];
                self.ticketsWatching.push(ticketId)
                querySnapshot.docChanges().forEach((change) => {
                    console.log("GOT NEW ITEMS ", change.type, self.global.openTickets, ticketId)
                    if (change.type === "added") {
                        const m = <OrderItem>change.doc.data();
                        if (m.status != 'deleted') {
                            if (!m.id) m.id = change.doc.id;

                            const index = self.global.openTickets[ticketId].items.findIndex(x => x.id === m.id)
                            if (index == -1) { // Only add if not already there
                                console.log("Adding ", m)
                                self.global.openTickets[ticketId].items.push(m)
                            }
                        }
                    }
                    if (change.type === "modified") {
                        const m = <OrderItem>change.doc.data();
                        console.log("Modified item ", m)
                        var index = -1;
                        if (self.global.openTickets[m.ticketId]?.items?.length)
                            index = self.global.openTickets[m.ticketId].items.findIndex(x => x.id === m.id)
                        if (index != -1) {
                            if (m.status == 'deleted')
                                self.global.openTickets[ticketId].items.splice(index, 1);//remove me
                            else
                                self.global.openTickets[ticketId].items[index] = m;
                        }
                        console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed") {
                        const m = <OrderItem>change.doc.data();
                        console.log("Removed items ", m)
                        var index = -1;
                        if (self.global.openTickets[m.ticketId]?.items?.length)
                            index = self.global.openTickets[m.ticketId].items.findIndex(x => x.id === m.id)
                        if (index != -1)
                            self.global.openTickets[ticketId].items.splice(index, 1);//remove me

                    }

                });
                /*
                self.global.allChatBots.sort(function (a, b) {
                  if (!a.createdAt || !a.editedAt) {
                    a.editedAt = Date.now()
                  }
                  if (!b.createdAt || !b.editedAt) {
                    b.editedAt = Date.now()
                  }
                  return a.editedAt - b.editedAt;
                });
                */
                //  if (typeof (callBack) == 'function') callBack(mySelf, ticketId)
                if (self.global?.openTickets[ticketId]?.items)
                    self.newOrderItems = <OrderItem[]>self.global.openTickets[ticketId].items;
                console.log("Open Items: ", self.global.openTickets, self.newOrderItems);

            });

    }







}
function TimeFormat(time: number) {

    var d = new Date(time);
    let hours = d.getHours();
    // Determine AM or PM suffix based on the hour
    const suffix = (hours < 12) ? "am" : "pm";

    // Convert hour from military time
    hours = (hours < 12) ? hours : hours - 12;

    // If hour is 0, set it to 12
    hours = hours || 12;

    return d.toDateString() + " " + hours + ":" + d.getUTCMinutes() + suffix;

}


export class SharedDataService {
    constructor() { }
    //Using any
    public editDataDetails: any = [];
    public subject = new Subject<any>();
    public payOrder: Order[] = [];
    private messageSource = new BehaviorSubject(this.editDataDetails);
    currentMessage = this.messageSource.asObservable();
    changeMessage(message: string) {
        this.messageSource.next(message)
    }
}

function Company() {
    this.name = "";
    this.palette1 = [];
    this.address1 = "";
    this.address2 = "";
    this.primaryPhone = "";
    this.city = "";
    this.state = "";
    this.postal = "";
    this.logoImg = "";
}