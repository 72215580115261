<div class="layout" [ngStyle]="{ 'max-width': getMaxWidth() }">

    <div *ngIf="global.authuser">
        <app-home></app-home>
    </div>

    <!-- NO USER -->
    <div *ngIf="!global.authuser">
        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="pretty">Log In</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <SignIn></SignIn>
                </ng-template>

            </p-accordionTab>
        </p-accordion>
    </div>
    <div *ngIf="global.authuser && !global.authuser.emailVerified" style="text-align: center; font-size:120%;">
        Please validate your email


    </div>
    <div *ngIf="global.authuser && global.myCompany && validCreds()">
        
        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false" (onOpen)="onTabOpen($event, i)">

            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div style="text-align: center; width:100%" fxLayout="column" fxLayoutAlign="center none">

                        <div class="pretty-header-company">{{global.myCompany.name}}</div>
<!-- TRIAL MESSAGE -->
<div *ngIf="global.myCompany?.trialMode?.status == 'active'" style="font-size:14px;">
    {{trialMessage()}}

</div>

                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-settings></app-settings>
                </ng-template>
            </p-accordionTab>



            <p-accordionTab *ngIf="global.myCompany.enabled.chatService">
                <ng-template pTemplate="header">
                    <div class="pretty">Customer Chats
                        <span *ngIf="global.messageCount">{{" "+global.messageCount}}</span>
                    </div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.chatService">
                        <app-chat-settings></app-chat-settings>

                        <app-chatnow [store]="global.myCompany.chatService"
                            [user]="global.myCompany.chatService"></app-chatnow>
                    </div>


                </ng-template>
            </p-accordionTab>


            <p-accordionTab *ngIf="(!global.viewOnly || global.viewOnly=='CM') &&  global.myCompany.enabled.customers">
                <ng-template pTemplate="header">
                    <div class="pretty">Customer Management</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-customers></app-customers>
                </ng-template>
            </p-accordionTab>


            <p-accordionTab *ngIf="(!global.viewOnly || global.viewOnly=='Res') && global.myCompany.enabled.reservations &&
            global.myCompany.enabled.reservations == true">
                <ng-template pTemplate="header">
                    <div class="pretty">Reservations
                        <span
                            *ngIf="global.allReservations && global.allReservations.length">{{global.allReservations.length}}</span>
                    </div>
                </ng-template>

                <ng-template pTemplate="content">
                    <app-reservesettings></app-reservesettings>
                    <div *ngIf="global.myCompany.enabled.reservations">
                        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
                            (onOpen)="onTabOpen($event, i)">

                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="pretty">Reservations by date</div>
                                </ng-template>
                                <ng-template pTemplate="content">

                                    <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
                                        (onOpen)="onTimeOpen($event, i)">
                                        <p-accordionTab [(selected)]="viewResCal">
                                            <ng-template pTemplate="header">
                                                <div class="pretty">{{getPrettyDate(value)}}</div>
                                            </ng-template>
                                            <ng-template pTemplate="content">
                                                <div style="text-align: center;">
                                                    <p-calendar (onSelect)="changeDate()" [(ngModel)]="value"
                                                        [inline]="true">
                                                    </p-calendar>
                                                </div>
                                            </ng-template>
                                        </p-accordionTab>

                                        <div class="week-view" fxLayout="row" fxLayoutAlign="none start">
                                            <p-table [value]="allWeekTotals" [scrollable]="true" scrollHeight="480px">
                                                <ng-template pTemplate="header">
                                                    <tr>
                                                        <th style="width:150px;"
                                                            *ngFor="let time of allWeekTotals[0]; let d = index;">

                                                            <div *ngIf="time.resTime" [ngStyle]="timeStyle(time, d)">
                                                                {{getFormattedTime(time.resTime.strTime)}}
                                                            </div>
                                                        </th>

                                                    </tr>
                                                </ng-template>>

                                                <ng-template pTemplate="body" let-dayhour>
                                                    <tr>
                                                        <td class="cal-hour"
                                                            *ngFor="let time of allWeekTotals[0]; let h = index"
                                                            (click)="hourClicked(dayhour[h])"
                                                            [ngStyle]="getBoxStyle(dayhour[h])">
                                                            <div style="width:100%;">

                                                                <div style="text-align: center; ">
                                                                    {{getMyWeekDay2(dayhour[h].resDate)+" "}}
                                                                    {{getMyShortDate(dayhour[h].resDate)}}
                                                                </div>


                                                                <div *ngIf="amIOpen(dayhour[h])" fxLayout="row">
                                                                    <div *ngIf="dayhour[h].seatsReserved"
                                                                        [ngStyle]="seatsReservedStyle(dayhour[h])">

                                                                        {{" " + dayhour[h].seatsReserved }}
                                                                    </div>

                                                                    <div [ngStyle]="seatsOccupiedStyle(dayhour[h], d)">
                                                                        <span>
                                                                            {{" " + dayhour[h].seatsOccupied }}
                                                                        </span>
                                                                    </div>

                                                                    <div *ngIf="getAvailableSeats(dayhour[h])"
                                                                        [ngStyle]="seatsAvailableStyle(dayhour[h],d)">

                                                                        {{getAvailableSeats(dayhour[h])}}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </ng-template>

                                            </p-table>
                                        </div>
                                    </p-accordion> .
                                </ng-template>
                            </p-accordionTab>


                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="pretty">New Reservation</div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <app-reserve-beta [adminRes]="true"></app-reserve-beta>
                                </ng-template>
                            </p-accordionTab>



                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="pretty">Table View</div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    View tables with side scrolling?.
                                    .
                                </ng-template>
                            </p-accordionTab>

                            <div *ngIf="global.myCompany.enabled.reservations">
                                <p-accordionTab>
                                    <ng-template pTemplate="header">
                                        <div class="pretty">Guest List</div>
                                    </ng-template>
                                    <ng-template pTemplate="content">
                                        View guests .
                                        .
                                    </ng-template>
                                </p-accordionTab>
                            </div>

                            <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
                                (onOpen)="onResOpen($event, i)"
                                *ngFor="let res of global.allReservations; let i = index;">
                                <div style="width:100%;" fxLayout="row wrap" fxLayoutAlign="none center">
                                    <p-accordionTab style="width:100%;" [(selected)]="activeRes[i]">
                                        <ng-template pTemplate="header">
                                            <div *ngIf="res.resTime" class="pretty-data">
                                                {{res.resDate.dateStr}}
                                                {{getFormattedTime(res.resTime.strTime)}}
                                                {{+" "+ res.partySize + " "
                                                }}
                                                {{res.status}}
                                                {{" "+res.firstName+" "+res.lastName}}
                                            </div>
                                        </ng-template>

                                        <ng-template pTemplate="content">
                                            <span *ngIf="res.note">{{" " +res.note}}<br></span>

                                            <span *ngIf="res.table">{{" " +res.table}}</span>
                                            {{" "+res.phone+" "+res.email}}<br>

                                            <span *ngIf="res.addons">{{" " +res.addons}}</span>
                                            <span *ngIf="res.occasion">{{" " +res.occasion}}</span>
                                            {{"Created: "+res.createStamp}}
                                            <span *ngIf="res.changeStamp">{{"Changed: "+res.changeStamp}}</span>
                                            <div style="width:100%;" fxLayout="row wrap"
                                                fxLayoutAlign="space-around space-around">
                                                <button class="button-14" role="button" (click)="noShow()">Mark as No
                                                    Show</button>
                                                <button class="button-14" role="button" (click)="noShow()">Arriving 15M
                                                    late</button>
                                                <button class="button-14" role="button"
                                                    (click)="noShow()">Cancel</button>
                                                <button class="button-14" role="button"
                                                    (click)="noShow()">Change</button>
                                                <button class="button-14" role="button" (click)="noShow()">Call
                                                    guest</button>
                                                <button class="button-14" role="button"
                                                    (click)="closeRes(res.myID)">Close
                                                    this</button>

                                            </div>

                                        </ng-template>
                                    </p-accordionTab>
                                </div>
                            </p-accordion>
                        </p-accordion>
                    </div>
                </ng-template>
            </p-accordionTab>

       
            <div *ngIf="global.myCompany.enabled.reviews">

                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty">

                            <div *ngIf="global.pendingReviews && global.pendingReviews.length>0; else noPending">
                                <span style="font-size:125%;">
                                    <strong>
                                        Reviews
                                        {{" "+global.pendingReviews.length+" pending"}}
                                    </strong>
                                </span>
                            </div>

                            <ng-template #noPending>
                                Reviews
                            </ng-template>



                        </div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <app-review-settings></app-review-settings>
                     
                    </ng-template>
                </p-accordionTab>

            </div>

            <div *ngIf="global.myCompany.enabled && global.myCompany.enabled.pos">
                <div id="pos">
                    <p-accordionTab [(selected)]="viewPOS">
                        <ng-template pTemplate="header">
                            <div class="app-feature">Point of Sale</div>
                        </ng-template>
                        <ng-template pTemplate="content">

                            <app-pos></app-pos>
                            .
                        </ng-template>
                    </p-accordionTab>

                </div>
            </div>
            <div *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.orders">

                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty">Orders</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <app-orders></app-orders>
                    </ng-template>
                </p-accordionTab>
            </div>
            <p-accordionTab *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.orders">
                <ng-template pTemplate="header">
                    <div class="pretty">Sales</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-sales></app-sales>
                </ng-template>
            </p-accordionTab>


            <div
                *ngIf="(!global.viewOnly || global.viewOnly=='Products') && global.myCompany && global.myCompany.enabled && global.myCompany.enabled.products">

                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty">Products</div>
                    </ng-template>
                    <ng-template style="margin:0px; padding:0px;" pTemplate="content">
                        <div class="pretty">
                            <app-products></app-products>
                        </div>
                    </ng-template>
                </p-accordionTab>
            </div>



            <div *ngIf="global.myCompany.enabled.media">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty">Media</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <app-media [companyId]="global.myCompany.id"></app-media>
                    </ng-template>
                </p-accordionTab>
            </div>

            <div *ngIf="global.myCompany.enabled.media">
                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty">Sheets</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                   
                        <app-sheets [companyId]="global.myCompany.id"></app-sheets>
                    </ng-template>
                </p-accordionTab>
            </div>

            <div *ngIf="global.myCompany.enabled.appBuilder">
                <p-accordionTab id="app-builder">
                    <ng-template pTemplate="header">
                        <div class="pretty">App Creator</div>
                    </ng-template>
                    <ng-template pTemplate="content">
                        <button (click)="nodeService.createNewApp()" class="create-form" pButton pRipple type="button">
                            Create a new App
                        </button>

                        <div *ngIf="global.authuser.uid=='kvT6eKFxFHb3EKbVeCTusW86CrZ2'">
                            <!--  
                            <button (click)="copyReviews()" class="create-form" pButton pRipple type="button">
                                Copy Reviews to NapkinReviews
                            </button>
                            <button (click)="copyApp()" class="create-form" pButton pRipple type="button">
                                Move APPS TO NEW HOME
                            </button>
                            -->

                            <!--   <app-ai-maker></app-ai-maker>-->
                        </div>
                        <p-accordion expandIcon="false" collapseIcon="false" [multiple]="false"
                            (onOpen)="getMyApp($event, i)" *ngFor="let app of global.publicApps; let i = index;"
                            fxLayout="column" fxLayoutAlign="center center">


                            <p-accordionTab iconPos="end" *ngIf="showApp(i)" [(selected)]="openApp[i]">
                                <ng-template pTemplate="header">
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <div class="user-feature" [style]="getAppNameStyle(i)">{{app.name}}</div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="content">
                                    <div class="user-feature" [style]="getAppNameStyle(i)">
                                        <app-appMaker [app]="app" [editing]="true"
                                            [companyId]="global.myCompany.id"></app-appMaker>
                                    </div>
                                </ng-template>
                            </p-accordionTab>
                        </p-accordion>
                    </ng-template>
                </p-accordionTab>
            </div>

            
            <p-accordionTab *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.forms">
                <ng-template pTemplate="header">
                    <div class="pretty">UI Designer & Database</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-data [open]="false" [api]="'Forms'"  ></app-data>

                </ng-template>
            </p-accordionTab>

            <p-accordionTab *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.forms">
                <ng-template pTemplate="header">
                    <div class="pretty">Reports</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-data [api]="'Reports'"></app-data>
                </ng-template>
            </p-accordionTab>

            <p-accordionTab *ngIf="global.myCompany && global.myCompany.enabled && global.myCompany.enabled.forms">
                <ng-template pTemplate="header">
                    <div class="pretty">Analytics</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <app-data [api]="'Analytics'"></app-data>

                </ng-template>
            </p-accordionTab>

   
            <p-accordionTab *ngIf="0 && global.myCompany && global.myCompany.enabled && global.myCompany.enabled.visualPlayground">
                <ng-template pTemplate="header">
                    <div class="pretty">Visual Playground</div>
                </ng-template>
                <ng-template pTemplate="content">
                    Start your Imagination...
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <button (click)="newQuery()" class="create-form" pButton pRipple type="button">
                            Create a new Visual Playground
                        </button>
                    </div>

                </ng-template>
            </p-accordionTab>


        </p-accordion>
        <div *ngIf="0 && !global.viewOnly">
            <app-features></app-features>
        </div>

    </div>
    <div fxFlex fxLayout="column" style="width: 100%; background-color: rgb(236, 249, 236);">
        <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">
            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div class="gold" style="width: 100%;">NAPKIN APPS</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <p-accordion iconPos="end" expandIcon="false" collapseIcon="false" [multiple]="false">

                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%;">Privacy Policy</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div style="width:100%; max-width:900px;margin: auto;">
                                    <img src="./assets/PrivacyPolicy.png" class="napkin-logo"
                                        style="width:100%; object-fit: cover; max-height:100vh;">
                                </div>
                            </ng-template>
                        </p-accordionTab>
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%;">Terms of Use</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                                <div style="max-width:900px;margin: auto;">

                                    <div *ngIf="global.getScreenWidth<600">
                                        <img src="./assets/termsmobile.png" class="napkin-logo"
                                            style="object-fit: cover;width:100vw">

                                    </div>
                                    <div *ngIf="global.getScreenWidth>600">
                                        <img src="./assets/terms.png" class="napkin-logo"
                                            style="width:100%; max-width: 900px;">

                                    </div>


                                </div>

                            </ng-template>
                        </p-accordionTab>
                        <p-accordionTab>
                            <ng-template pTemplate="header">
                                <div style="width: 100%;">What is Napkin Apps?</div>
                            </ng-template>
                            <ng-template pTemplate="content">

                            </ng-template>
                        </p-accordionTab>
                    </p-accordion>

                </ng-template>
            </p-accordionTab>
        </p-accordion>

        <!--    <img src="./assets/NapkinLogo4.png" class="napkin-logo" style="width:100vw; max-height:100vh;">  -->


        <div *ngIf="eyeCandyType[candyIndex]=='video/mp4'">
            <video (click)="candyClick()" #videoFileContainer class="media-full" playsinline webkit-playsinline autoplay
                loop [muted]="'muted'">
                <source src="{{eyeCandy[candyIndex]}}" type="video/mp4" />
            </video>
        </div>



        <div *ngIf="eyeCandyType[candyIndex]=='image/png' || eyeCandyType[candyIndex]=='image/jpg'">
            <img (click)="candyClick()" class="media-full" src="{{eyeCandy[candyIndex]}}">

        </div>

    </div>
</div>
<br>
<br>