<div class="container">
    <div class="gallery" fxFlex fxLayout="column"
    fxLayoutAlign="center none">
    <div *ngIf="viewOnly && gallery && gallery.media.length>0" class="pretty"
    fxLayout="row wrap" fxLayoutAlign="center center">
        <gridster [options]="options" style="min-height: 300px; width: 100%">
            <gridster-item [item]="m.gridItem" 
                *ngFor="let m of gallery.media;let ind = index;">
               <!--
                <span *ngIf="m.gridItem.thumbnail">
               
                    <img style="object-fit:cover; width: 100%; height:100%;"
                        src="{{m.thumbnailUrl}}">
                </span>
                <span *ngIf="m.fileType && m.fileType!='image'">
                    <video
                        style="object-fit:cover; width: 100%;"
                        playsinline webkit-playsinline autoplay
                        [muted]="'muted'">
                        <source src={{m.url}} type="video/mp4">
                    </video>
                </span>

                -->

                <span *ngIf="!m.fileType || m.fileType=='image' || m.fileType=='image/png'">
                    <img style="object-fit:cover; width: 100%; height:100%;"
                        src="{{m.url}}">
                </span>

                <span *ngIf="m.fileType && (m.fileType == 'video/quicktime' || m.fileType == 'video/mp4' || m.fileType == 'video/mov')">
                <video class="media" playsinline preload="none" mute="true"
                    webkit-playsinline autoplay loop [muted]="'muted'">
                    <source src={{m.url}}>
                </video>
                </span>



            </gridster-item>
        </gridster>
    </div>

    </div>


<div *ngIf="!viewOnly" class="gallery" fxFlex fxLayout="column">dsdfdsfdsf
    <div fxLayout="row wrap" fxLayoutAlign="center center">

        <div *ngFor="let g of galleries; let i = index;" style="width:100%">
            <p-accordionTab styleClass="accordion">
                <ng-template pTemplate="header">
                    <div class="pretty">{{g.name}}</div>
                </ng-template>
                <ng-template pTemplate="content">
                    <div style="width: 100%; height:800px" fxFlex fxLayout="column"
                        fxLayoutAlign="none none">

                        <p-accordionTab styleClass="accordion">
                            <ng-template pTemplate="header">
                                <div class="pretty">Settings</div>
                            </ng-template>
                            <ng-template pTemplate="content">
                     <!-- Row Height: default: 600px AutoPlay -- Tint: -->           

                            </ng-template>
                        </p-accordionTab>




                        <div *ngIf="g.media">

                            <gridster [options]="options" style="min-height: 300px">
                                <gridster-item [item]="item.gridItem"
                                    *ngFor="let item of g.media;let ind = index;"
                                    (click)="removeItem(item, g, ind)">

                                    <span *ngIf="!item.fileType || item.fileType=='image'">

                                        <img style="object-fit:cover; width: 100%; height:100%;"
                                            src="{{item.gridItem.thumbnail}}">
                                    </span>
                                    <span *ngIf="item.fileType && item.fileType!='image'">
                                        <video
                                            style="object-fit:cover; width: 100%;"
                                            playsinline webkit-playsinline autoplay
                                            [muted]="'muted'">
                                            <source src={{item.url}} type="video/mp4">
                                        </video>
                                    </span>

                                </gridster-item>
                            </gridster>
                            <div class="command" (click)="chooseGallery(g)">Choose Gallery</div>


                        </div>

                    </div>
                </ng-template>
            </p-accordionTab>

        </div>
    </div>
</div>
</div>
