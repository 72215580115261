import Phaser, { GameObjects } from 'phaser'; //import Phaser

export class SpeachBubbleGameObject extends Phaser.GameObjects.Graphics {
    self;
    contentX;
    contentY;
    x;
    y;
    container;

    constructor(scene, x, y, quote, quoteAuthor, fontSize) {
        super(scene);
        this.self = this;
        var width = 300;
        var height = 250;

        // Correct X if off screen;

        if (x+width>window.innerWidth) x=window.innerWidth - width;
        if (x<0)x=0;

        this.container = scene.add.container(x, y)
    
        if (!fontSize) fontSize = 22;

        this.x = x;
        this.y = y;

         x= 0; y = 0;
 

        const bubbleWidth = width;
      //  const bubbleHeight = height;
        const bubblePadding = 10;

        const content = scene.add.text(0, 0, quote, { fontFamily: 'Arial', fontSize: fontSize, color: '#000000', align: 'center', wordWrap: { width: bubbleWidth - (bubblePadding * 2) } });

        const b = content.getBounds();
      //  console.log("BBB ", b);
        const bubbleHeight = b.height+10;
        const arrowHeight = bubbleHeight / 4;

        this.contentX = 0 + (bubbleWidth / 2) - (b.width / 2)
        this.contentY = 0 + (bubbleHeight / 2) - (b.height / 2)

     //   console.log("QUOTE: ", x, y, width, height, quote)

        const bubble = scene.add.graphics({ x: x, y: y });

    //    console.log("QUOTE: ", bubble, x, y, width, height, quote, this)
        //  Bubble shadow
        bubble.fillStyle(0x222222, 0.5);
        bubble.fillRoundedRect(6, 6, bubbleWidth, bubbleHeight, 16);

        //  Bubble color
        bubble.fillStyle(0xffffff, 1);

        //  Bubble outline line style
        bubble.lineStyle(4, 0x565656, 1);

        //  Bubble shape and outline
        bubble.strokeRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);
        bubble.fillRoundedRect(0, 0, bubbleWidth, bubbleHeight, 16);

        //  Calculate arrow coordinates
        const point1X = Math.floor(bubbleWidth / 7);
        const point1Y = bubbleHeight;
        const point2X = Math.floor((bubbleWidth / 7) * 2);
        const point2Y = bubbleHeight;
        const point3X = Math.floor(bubbleWidth / 7);
        const point3Y = Math.floor(bubbleHeight + arrowHeight);

        //  Bubble arrow shadow
        bubble.lineStyle(4, 0x222222, 0.5);
        bubble.lineBetween(point2X - 1, point2Y + 6, point3X + 2, point3Y);

        //  Bubble arrow fill
        bubble.fillTriangle(point1X, point1Y, point2X, point2Y, point3X, point3Y);
        bubble.lineStyle(2, 0x565656, 1);
        bubble.lineBetween(point2X, point2Y, point3X, point3Y);
        bubble.lineBetween(point1X, point1Y, point3X, point3Y);

        const author =  scene.add.text(point3X, point3Y, quoteAuthor, { fontFamily: 'Arial', fontSize: 20, color: '#ffffff', align: 'center', wordWrap: { width: bubbleWidth - (bubblePadding * 2) } });


        content.setPosition(this.contentX, this.contentY);
        this.container.add([bubble, content, author])
   
    }
    destroy () {
        this.container.destroy();
    }

    update (time, delta) {
        var self = this;

        self.container.setPosition(self.x, self.y)

    //  console.log ("MOVED ME ", self.x, self.y)
    }



}

export class SpeachBubblePlugin extends Phaser.Plugins.BasePlugin {
    

    constructor(pluginManager) {
        super(pluginManager);

        //  Register our new Game Object type
        console.log ("PLUGINS ",pluginManager)
      //  var r = pluginManager.get("SpeachBubblePlugin")

      //  if (r)  console.log ("R ",r)

        pluginManager.registerGameObject('speachBubble', this.createSpeachBubble,this.createSpeachBubble);
      
    }

    createSpeachBubble(x, y, str, quoteAuthor, fontSize) {
        var self: any = this;;
        return self.displayList.add(new SpeachBubbleGameObject(self.scene, x, y, str, quoteAuthor, fontSize));
    }


}
