<div fxFlex fxLayout="column" fxLayoutAlign="none none" class="polaroid-container" (click)="updateReview()">
  <div class="polaroid">
    <app-media-view *ngIf="p.media" [media]="p.media" [noStyle]="true"></app-media-view>

    <img *ngIf="p.review_photos && !p.media" src="{{p.review_photos[0]}}" alt="5 Terre" [style]="getPhotoStyle(p)">
    <div class="container">
      <div class="review__desc" style='user-select: text' (onselect)="autoTag($event)">
        <span *ngIf="p.style=='quote';else defaultStyle">
          <blockquote>
            <div [innerHtml]="getFilteredText(p)"></div>
            
          </blockquote>
        </span>
        <ng-template #defaultStyle>
          <div [innerHtml]="getFilteredText(p)"></div>
        </ng-template>

      </div>
    </div>
  </div>
  <div style="text-align: center; font-weight:500; font-size: .6rem;"> {{" "+p.dateStr}}</div>
  <div class="star-container">
    <div class="Stars" style="--rating: {{p.rating}};" aria-label="Rating of this product is 2.3 out of 5.">
    </div>

  </div>

  <div style="text-align: center; font-weight:600;">{{p.author_name}}</div>

  <div fxFlex fxLayout="row" fxLayoutAlign="space-between end">
    <div *ngIf="p.source=='Yelp'">Yelp review</div>
    <div *ngIf="p.source=='Google'">Google review</div>
    <div *ngIf="p.source=='Napkin'">Napkin review</div>
    <div *ngIf="p.source=='Harvest Hosts'">Harvest Hosts review</div>

    <div>
      <button class="useful" (click)="useful(p)">Useful
        <span *ngIf="p.useful && p.useful>4"> {{p.useful}}</span>
        <span *ngIf="p.useful && p.useful<5"><i class="pi pi-check" style="font-size: .8rem"></i></span>
      </button>
    </div>
  </div>
</div>