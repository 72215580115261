<div *ngIf="quotes">

    <div *ngFor="let q of global.authuser.dailyQuotes; let i = index">
        <div *ngIf="shareQuote == q">

            <input data-lpignore="true"
                style="min-width: 15rem !important;  padding-left:4px !important; font-size: 1.4rem; margin-right:4px !important;"
                type="email" pInputText placeholder="email" [(ngModel)]="email">

            <div *ngIf="!inputCreds" class="command" style=" min-height: 100%;" (click)="shareThisQuote()">
                Share Quote
            </div>

        </div>
            <div fxLayout="row wrap">
            {{q.prettyDate}}
            <div *ngIf="q.sharedBy" style="margin-left:15px; color:rgb(173, 2, 246);">Shared by: {{" "+q.sharedBy}}</div>
        </div>
         
   
        <div class="card__content">
            <article>
                <div class="card__desc">
                    <blockquote>{{q.text}}</blockquote>

                </div>
                <button class="author" (click)="shareMe(q)">{{q.author}}</button>
            </article>

        </div>
    </div>
</div>