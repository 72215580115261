import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from '../../company-interface';
import firebase from 'firebase/compat/app';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { RegisterService } from '../../register.service';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { Timestamp } from "firebase/firestore"
import { CartService, SharedDataService } from '../../menu/cart.service';
import { MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NavService } from '../../menu/nav.service';
import { environment } from '../../../../environments/environment';
import { AccordionModule } from 'primeng/accordion';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../../user-interface';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectItem, PrimeNGConfig } from 'primeng/api';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { SafePipe } from 'safe-pipe';
import { interval } from 'rxjs';
import { Quote } from '../../content-interface';


export interface EnabledOption {
  name: string;
  code: string;
}



@Component({
  selector: 'app-chat-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class ChatSettingsComponent {
  iframeChat: string = "<iframe id='angularFrame2'\n title='My Chat App'\n frameBorder='0' \nsrc='https://openbabble.com/chat/4390' \nwidth='360' height='800'>\n</iframe>"
  enabledOptions: EnabledOption[];
  whenEnabledSelection: string = "always";
  notOpenChatMsg: string;
  testUsers: string[];
  firstNames: string[];
  lastNames: string[];
  emails: string[];
  testGroups: string[];
  newMsg: Message;
  testIndex: number = 0;
  quote: Quote;


  constructor(public global: Globals,
    private primengConfig: PrimeNGConfig,
    public registerService: RegisterService,
    public cartService: CartService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private router: Router,
    public nav: NavService) {

    this.testUsers = ["testUser1000", "testUser1001", "testUser1002", "testUser1003", "testUser1004", "testUser1005", "testUser1006", "testUser1007", "testUser1008", "testUser1009"]
    this.firstNames = ["George", "William", "Paul", "Hector", "Cosmo", "Ray", "Casper", "Jack", "Ronny", "Lester"];
    this.lastNames = ["Canterbury", "Maxime", "Jackson", "Nunez", "Jenkins", "Boxer", "Houston", "Remkins", "Bunker", "Pink"];
    this.emails = ["1000@suncraftcellars.com", "1001@suncraftcellars.com", "1002@suncraftcellars.com", "1003@suncraftcellars.com", "1004@suncraftcellars.com", "1005@suncraftcellars.com", "1006@suncraftcellars.com", "1007@suncraftcellars.com", "1008@suncraftcellars.com", "1009@suncraftcellars.com"];
    this.testGroups = [];
    for (var n=0;n<this.emails.length;n++) {
      this.testGroups.push(" ");
    }
    
    this.quote = {author: "me", text:"The best word is the one that inspires."}


    this.enabledOptions = [
      { name: 'Enabled 24/7', code: 'always' },
      { name: 'Enabled only during open hours', code: 'openhours' }
    ];
    this.notOpenChatMsg = "Our business is currently closed so our response may be delayed until we are open."
    console.log("Chat settings");
  }

  Clicked(command: string) {

    console.log("Clicked ", command);
    switch (command) {
      case "Add Staff":
        //  this.checkEmail();

        break;

    }
  }

  startTester() {
    //  this.createTestUsers();

    this.createTestServiceGroups();

    interval(5000).subscribe(() => { // Every 5 seconds
      this.testChats();
    });

  }

  testChats() {
    console.log("Tick tock: ");
    const sentAt = Timestamp.now();

    this.GetInspiration();

    var message: Message = {
      msg: this.quote.text,
      sentAt,
      sentBy: this.testUsers[this.testIndex],
      from: this.emails[this.testIndex]
    }
    if (!message.notReadBy) message.notReadBy = [];
 
    message.notReadBy.push(this.global.myCompany.chatService);
 

    this.updateGroupStatus(this.testGroups[this.testIndex], "Active");
    this.saveMessage(message, this.testGroups[this.testIndex], null);
    this.testIndex++;
    if (this.testIndex > 9) this.testIndex = 0;
  }

  createTestUsers() { // only called once
    // create a bunch of test Users
    var db = firebase.firestore();
    for (var n = 0; n < this.emails.length; n++) {
      const userRef = db.collection('users').doc(this.testUsers[n]);
      this.registerService.RegisterChatUser(this.emails[n], userRef, this.firstNames[n], this.lastNames[n]);
    }
  }

  GetInspiration() {
    var self = this;
    var db = firebase.firestore();

    var r = <number>Math.floor(Math.random() * 1600);

    db.collection("quotes").where("index", "==", r)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Quote>doc.data();
          self.quote = data;
        })
      })


  }

  createTestServiceGroups() {
    var self = this;

    // Now create a Chat Service Group for each test user - one time only
    for (var n = 0; n < this.testUsers.length; n++) {
      self.CheckExistingGroup(n);
    }
  }

  // USED ONLY FOR TESTING
  CheckExistingGroup(index) {
    const vm = this
    // If no message group then create for store & cusstomer
    const db = firebase.firestore()
    var members = [vm.testUsers[index], vm.global.myCompany.chatService];



    return new Promise((resolve, reject) => {
      const groupRef = db.collection('msg_groups')
      groupRef
        .where('members', '==', members)
        .get()
        .then((querySnapshot) => {
          console.log("Qsnap: ",members, querySnapshot)
          if (querySnapshot.empty==true) {
            vm.createServiceGroup(index);
          }
          else {
            var grp = <msg_Groups>querySnapshot.docs[0].data();
            vm.testGroups[index] = grp.id;

          }
        })

    })
  }

  createServiceGroup(index) {
    var self = this;
    const db = firebase.firestore();

    const createdAt = Timestamp.now();

    const members = [self.testUsers[index], self.global.myCompany.chatService];
    const names = [self.firstNames[index] + " " + self.lastNames[index], self.global.myCompany.name + " Customer Service"];
    const emails = [self.emails[index], self.global.myCompany.chatServiceEmail];

    const docRef1 = db.collection('msg_groups').doc();

    const sentAt = Timestamp.now();

    var message: Message = {
      msg: "How may we assist?",
      sentAt,
      sentBy: this.global.myCompany.chatService,
      from: self.global.myCompany.chatServiceEmail
    }

    const group: msg_Groups = {
      id: docRef1.id,
      members,
      names,
      emails,
      createdAt,
      createdBy: this.testUsers[index],
      storeID: self.global.myCompany.id,
      storeName: self.global.myCompany.name,
    }

    console.log("New Customer Chat: ", group, message)


    return new Promise((resolve, reject) => {

      docRef1
        .set(group)
        .then(function (docRef) {

          resolve(<msg_Groups>group)

          console.log("New Customer Chat: ", group, message);
          // Now send the initial MSG
          self.testGroups[index] = group.id;

          self.saveMessage(message, group.id, null)

        })
        .catch(function (error) {
          reject(error)
        })
    })
  }


  updateGroupStatus(groupID: string, status) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(groupID)
        .update({
          status: status,
        })
        .then(function (docRef) {
          resolve(groupID)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }


  saveMessage(msg: Message, currentGroupId: string, index) {
    const db = firebase.firestore();
    var self = this;


    const sentAt = Timestamp.now();
    if (msg.msg.trim() || msg.feature) {
      if (index != null) {
        msg.sentAt = sentAt;
     //   msg.sentBy = self.global.myCompany.chatService;
       
      }

      const message: Message = { ...msg };   // DEEP COPY
    //  console.log("SAVING..:", message, currentGroupId, index);

      return new Promise((resolve, reject) => {
        db.collection('msg_messages')
          .doc(currentGroupId)
          .collection('allMessages')
          .add(message)
          .then(function (docRef) {
            self.updateGroupDateTime(currentGroupId);

            console.log(" added", message);
            resolve(<Message>message);


          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }

  updateGroupDateTime(groupid: string) {
    const db = firebase.firestore();

    return new Promise((resolve, reject) => {
      db.collection('msg_groups')
        .doc(groupid)
        .update({
          lastTime: new Date(),
          status: "Active"
        })
        .then(function (docRef) {
          resolve(groupid)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  //TESTING ONLY ABOVE CODE

  copyToClipboard() {
    console.log("Copied");
    // Copy to clipboard
    const cb = navigator.clipboard;
    if (cb) {
      cb.writeText(this.iframeChat);
    }
  }

  updateiFrame() {
    this.iframeChat =
      "<iframe id='angularFrame2'\n title='My Chat App'\n frameBorder='0' \nsrc='https://openbabble.com/chat/" +
      this.global.myCompany.store +
      "'\nwidth='360' height='800'>\n</iframe>"

  }

}
