/* CHECK OUT FLOWS:

E-commerce
  Show only items that can be picked up, take-out, shippable.
  No "Order" Button. Cart to Checkout
  No tip in flow
  Delivery drives data (shipping; cost, address) 
  Pick-up only by default.

Menu Ordering
  Add to cart, then Order "Send Order to Kitchen"
  More items - same flow
  When all items are marked as "Delivered" price > 0

 */



import { Order, Discounts } from '../../order-interface';
import firebase from 'firebase/compat/app';

import { User, Follows } from '../../user-interface';
import { Company } from '../../company-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgAuthService } from "../../../ng-auth.service";

import { AfterViewInit, Component, Input, OnInit, ViewChild, ɵConsole } from '@angular/core';
import { UntypedFormGroup, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { RegisterService } from '../../register.service';
import { Logger } from '../../../functions';



/*import {
  StripeCardComponent,
  StripeInstance,
  StripeFactoryService
} from 'ngx-stripe';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentIntent
} from '@stripe/stripe-js';
*/
import { StripeService, StripePaymentElementComponent, StripePaymentRequestButtonComponent, StripeServiceInterface, StripeElementsService, StripeFactoryService } from 'ngx-stripe';
import {
  StripeElementsOptions,
  PaymentIntent,
  StripeElements,
  StripeCardElement,
  StripeCardElementOptions,
} from '@stripe/stripe-js';


import { environment as env } from '../../../../environments/environment';
import { Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
import { SelectItem, PrimeNGConfig, MessageService } from 'primeng/api';
import { InputSwitchModule } from 'primeng/inputswitch';
import { Product } from '../../product-int';
import { Globals } from '../../../globals';
import { FormControl } from '@angular/forms';
import { TreeModule } from 'primeng/tree';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
  providers: [MessageService]
})


export class PayComponent implements OnInit, AfterViewInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement: StripePaymentElementComponent;

  paymentElementForm = this.fb.group({
    name: ['John doe'],
    email: ['support@ngx-stripe.dev'],
    address: ['100 Main st.'],
    zipcode: ['90210'],
    city: ['Los Angeles'],
    amount: [1249],
  });

  amountCtrl = new FormControl(null, { updateOn: 'blur' });

  //[Validators.required]

  // Redemption //
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  // Redeption //

  myOrderTotal: number = 0;
  myPointsTotal: number = 0;

  salesTax: number = 0;
  tipValue: number = null;
  tipAmount: number = 0;
  theTotal: number = 0;
  xamount: number = 10;
  name: string = "";
  email: string = "";
  customTip: number = 0;
  viewPayButton: boolean = false;
  cashPayment: boolean  = false;
  cashPaymentAmount: number = 0;

  viewDetail: boolean = true;
  detailText: string = "see detail";
  user: User;
  tableNameQR: string;
  companyID: string;
  selected: string;
  //stripe: StripeInstance;
  paymentSuccess: number = 0;
  myRewardPoints: number = 0;

  selectedMessage: any;
  paying = false;
  myToken: string;

  myCompany: Company;
  red: Product[] = [];

  //dataSourceOrders: MatTableDataSource<Order>;
  /*
    cardOptions: StripeCardElementOptions = {
      style: {
        base: {
          iconColor: '#666EE8',
          color: '#31325F',
          fontWeight: '300',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSize: '24px',
          lineHeight: '2.0em',
          '::placeholder': {
            color: '#CFD7E0',
          },
        },
      },
    };
  */
  elementsOptions: StripeElementsOptions = {
    locale: 'en',

  };

  stripeTest: FormGroup;
  tipOptions: any[];
  errorMessage: string;

  elements: StripeElements;
  card: StripeCardElement;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#0000ff',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '24px',
        '::placeholder': {
          color: '#000000'
        }
      }
    }
  };



  constructor(
    private router: Router, public ngAuthService: NgAuthService,
    private http: HttpClient,
    private fb: UntypedFormBuilder,
    private sharedDataService: SharedDataService,
    private cartService: CartService,
    private stripeService: StripeService,
    private messageService: MessageService,
    private primeNGConfig: PrimeNGConfig,
    public global: Globals,
    public registerService: RegisterService,
  ) {

    this.tipOptions = [
      { index: 0, label: 'none', value: 0, type: 'per' },
      { index: 1, label: '15%', value: 15, type: 'per' },
      { index: 2, label: '18%', value: 18, type: 'per' },
      { index: 3, label: '20%', value: 20, type: 'per' },
      { index: 4, label: '$5', value: 5, type: 'cur' },
      { index: 5, label: '$10', value: 10, type: 'cur' },
      { index: 6, label: '$15', value: 15, type: 'cur' },
      { index: 7, label: '$20', value: 20, type: 'cur' },
      { index: 8, label: 'Custom', value: 0, type: 'cur' },
    ];

  }
  ngAfterViewInit(): void {
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', this.cardOptions);
          this.card.mount('#card-element');
        }
      });

  }

  ngOnInit(): void {
    var self = this;

    console.log("PAYPAY")

    this.primeNGConfig.ripple = true;
    console.log("Redeem", this.global.redeem)

    this.sortOptions = [
      { label: 'Price High to Low', value: '!price' },
      { label: 'Price Low to High', value: 'price' }
    ];


    this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value!
    console.log(this.selectedMessage);

    // this.sharedDataService.currentMessage.subscribe(message => (this.selectedMessage = message)); //<= Always get current value!

    //  this.GetCompany(this.companyID);

    this.GetOrders();

    console.log(this.sharedDataService.payOrder);

    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.nullValidator]]
    });
    /*
    this.stripeService.elements(this.elementsOptions)
      .subscribe(elements => {
        this.elements = elements;
        // Only mount the element the first time
        if (!this.card) {
          this.card = this.elements.create('card', this.cardOptions);
          this.card.mount('#card-element');
        }
      });
      */


  }

  discounts(): boolean {

    var found = false;

    this.sharedDataService.payOrder.forEach(function (item) {
      if (item.discountsApplied && item.discountsApplied.length) {
        found = true;
      }

    })
    return found;
  }


  getDiscounts(): string {
    var total = 0;

    this.sharedDataService.payOrder.forEach(function (item) {
      if (item.discountsApplied && item.discountsApplied.length) {
        total += (item.extPrice - (item.price * item.qty));
      }

    })

    return '$' + total.toFixed(2);

  }
  myColor() {
    if (this.myToken && this.myToken.length > 0) {
      return "none";
    }
    else return "block"
  }
  getReviewUrl() {

    var url = "https://api.qrserver.com/v1/create-qr-code/?data=NapkinHQ.com/review/";
    url += this.global.myCompany.store
    url += '&size=200x200';
    return url;
  }

  lastInput: number = 0;

  lookupUser(event) {
    var fn = event.target.value.lastIndexOf(' ');

    this.foundUsers = [];

    // need first & last name
    if (fn != -1) {

      const arr = event.target.value.split(' ');
      console.log("Have last ", arr);
      if (arr[1].length > 2) {
        console.log("Lookup user ");
        this.findUser(arr[0], arr[1]);

      }

    }

    this.lastInput = Date.now();
  }

  loadUser(user: User) {

    this.sharedDataService.payOrder.forEach(function (item) {
      item.userID = user.uid;

    })
    this.stripeTest.setValue({
      "name": user.firstName + " " + user.lastName,
      "email": user.email
    })
    this.global.customer = user;
    this.foundUsers = [];

  }

  foundUsers: User[];

  findUser(first, last) {
    var self = this;

    var db = firebase.firestore();

    var docRef = db.collection("users").where("firstName", "==", first)
      .get()
      .then((querySnapshot) => {
        self.foundUsers = [];

        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          var lastname = data.lastName.toLowerCase();
          if (lastname.startsWith(last.toLowerCase())) {
            console.log("Found ", querySnapshot.size)
            this.foundUsers.push(data);
          }

        });
      })
      .catch((error) => {
        console.log("no documents: ", error);
      });






  }

  createToken() {
    const name = this.stripeTest.get('name').value;
    this.stripeService
      .createToken(this.card, { name })
      .subscribe((result) => {
        if (result.token) {
          // Use the token

          const captureButton = document.getElementById('hideMe');
          // captureButton.setAttribute('display:', 'none')
          // this.card.unmount();

          this.cardOptions = {
            style: {
              base: {
                iconColor: '#fffffff',
                color: '#ffffff',
                fontWeight: '300',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSize: '24px',
                '::placeholder': {
                  color: '#ffffff'
                }
              }
            }
          };

          this.myToken = result.token.id;
          // Now remove order detail for tipping  Hack should be based on mode
          this.viewDetail = false;
      //    this.viewPayButton = false;

          console.log(result.token.id);
        } else if (result.error) {
          // Error creating the token
          alert("Token Error: "+result.error.message+" code: "+result.error.code+" decline code: "+result.error.decline_code);
          console.log(result.error.message);
        }
      });
  }

  emailChanged() {

    // Validate FIELD + NAME
    //    if (!self.email || !self.userName) {  // HACK - NEED full email verify function
    //      this.openSnackBar("Email & name required.", "Thanks.");
    //      return;
    //    }
    // Auto-register User if new email
    // If already in system - ask for password to auth - no login
    console.log("Email changed: ", this.email);

    this.registerUser();


  }
  getCreditCard () {
    this.cashPayment = false;
    this.getTotal();
    


  }
  closeSale () {
    var result: any;

    result = {
      paymentIntent: {
      id: "Cash Payment",
      status: "succeeded"
      }
    }

    this.paymentSuccess = 1

    this.CloseOrderPaid (result);

  }

  getBalanceorChange() {
    const balance = this.theTotal-this.cashPaymentAmount;

    if (balance > 0)
      return (balance.toFixed(2) +" balance");
    else if (balance < 0)
      return (balance.toFixed(2) + " change owed;")
    else {
      return "Paid in full"
    }


  }
  customerId: string = "";

  registerUser() {
    var self = this;


    self.email = self.email.toLowerCase();


    /// validate email

    var firstName, lastName;


    var name = this.stripeTest.get('name').value;
    // name = "John House"

    const arr = name.split(' ');

    firstName = arr[0];
    lastName = arr[1];
    if (arr.length > 2)
      lastName = arr[2];

    /*
    if (arr[1].length > 2) {
      console.log("Lookup user ");
      this.findUser(arr[0], arr[1]);
 
    }
    */
    var db = firebase.firestore();



    console.log("Joining Perks: ", self.myRewardPoints, firstName, lastName, self.email);
    const docRef = db.collection('users').doc();
    self.customerId = docRef.id;
    self.registerService.JustRegisterUser(docRef, self.email, firstName, lastName, true, self.myRewardPoints);
    self.AddUserToOrder(docRef.id);


    /*
    
        var db = firebase.firestore();
    
        var st = localStorage.getItem('store')
        console.log(st);
        if (!st.length) {
          st = "4390";
          store = JSON.parse(st);
        }
        else store = JSON.parse(st);
    
        var follow: Follows[] = [{
          id: store,//FIX
          points: 200,  // Company sets this
          startDate: Date.now(),
          storeName: ""
    
        }]
        const n = (Math.random() * 900).toPrecision(3);
    
        follow = JSON.parse(JSON.stringify(follow));
        console.log("Follow: ", follow)
        const newUser: User = {
          uid: "",
          email: self.email,
        //  displayName: self.name,
          photoURL: "",
          emailVerified: false,    //user.emailVerified,
          companyID: store,
          staff: false,
          following: follow,
          keyCode: Number(n),
          uPoints: 0,
        }
        console.log("User: ", newUser)
    
        // HAVE NEW USER REGISTRATION:
        // CHECK FOR EXISTING
        var docRef = db.collection("users").where("email", "==", self.email);
        docRef.get().then((doc) => {
          //      ((querySnapshot) => {
          if (!doc.empty) {
            //    querySnapshot.forEach((doc) => {
            //const data = <User>doc.data();
            //self.companyID = data.id;
            //
            console.log("Email already associated with account");
            // Auto-Login User?? - get password - use phone??
    
            //   });
          }
          else {
            console.log("no account found, so add it");
            var docRef = db.collection("users").doc();
            newUser.uid = docRef.id;
            //     self.cartService.uid = docRef.id; // THE ACTUAL ID used for orders
            // UPDATE THIS ORDER TO USE uid when Pay
            self.global.customer = newUser; // STORE AS ACTIVE CUSTOMER
    
            docRef.set(     //{  // AUTO-ID DOC
              newUser,
            )
              .then(() => {
                console.log("New user added");
    
                self.messageService.add({ key: 'tc', severity: 'info', summary: 'Reward!', detail: 'You earned 200 points!' });
                self.user = newUser;
    
                //      self.sendEmail();
    
                //     localStorage.setItem('uid', newUser.uid);
    
              })
              .catch((error) => {
                console.error("Error writing document: ", error);
              });
    
          }
        })
        //  }
    
    
        //   Log ("New user", user.uid, company, user.email); 
    */

  }


  getSubTotal() {
    var subTotal = "";

    if (this.myOrderTotal) subTotal = '$' + this.myOrderTotal.toFixed(2);
    //   if (this.myPointsTotal) subTotal += " " + this.myPointsTotal.toFixed(0) + " points";

    return subTotal;
  }

  updateTip(index) {
    console.log("index ", this.tipValue);

    if (index != 8 || (index == 8 && this.customTip > 0)) {
      this.viewPayButton = true;
    }
//    if (index != 8)
    this.customTip = 0;

    this.getTotal();

  }
  tenderCash () {
    this.cashPayment = true;

  }

  getTotal() {
    var self = this;
    self.salesTax = self.myOrderTotal * .0775;

    if (self.customTip > 0) {
      self.tipAmount = self.customTip
    }
    else {
      var tv = this.tipValue;
      if (!tv) tv = 0;

      if (this.tipOptions[tv].type == 'per')
        self.tipAmount = self.myOrderTotal * (this.tipOptions[tv].value / 100);
      else if (this.tipOptions[tv].type == 'cur')
        self.tipAmount = (this.tipOptions[tv].value);
    }
    self.theTotal = self.myOrderTotal + self.salesTax + self.tipAmount;
    self.theTotal = +self.theTotal.toFixed(2);

    if (!self.cashPayment && self.cashPaymentAmount)
      self.theTotal -= self.cashPaymentAmount;  // hack - show on line

    self.myRewardPoints = (self.myOrderTotal + self.tipAmount) * 10;

    console.log(self.tipAmount, tv,self.theTotal,self.cashPaymentAmount);
    //   this.stripeTest = this.fb.group({
    //     name: ['', [Validators.required]],
    //     amount: [self.theTotal, [Validators.required, Validators.pattern(/\d+/)]]
    //   });

    self.xamount = self.theTotal;


    if (self.elements) {
      //   self.paymentElement.elementsOptions.mode ='payment';// ({ elementsOptions: self.elementsOptions});

      // elementsOp.mode = 'payment';
      // self.elements.update({ amount: self.theTotal * 100 });
    }
  }
  setStripeInstance(account) {
    //  this.stripe = this.stripeFactory.create(env.stripe.published_key, {
    //    stripeAccount: account
    //  });
  }

  collectCard() {
    this.createPaymentIntent(this.theTotal * 100)
      .subscribe(pi => {
        console.log("pi ", pi);
        this.elementsOptions.clientSecret = pi.client_secret;
      });

  }
  pay(): void {
    var self = this;
    if (1) {
      this.paying = true;
      this.createPaymentIntent(this.theTotal * 100)
        .pipe(
          switchMap((pi) =>
            this.stripeService.confirmCardPayment(pi.client_secret, {
              payment_method: {
                card: this.card,
                billing_details: {
                  name: this.stripeTest.get('name').value,
                  email: this.email,
                },
              },
            })
          )
        )
        .subscribe((result) => {
          this.paying = false;
          if (result.error) {
            Logger("Payment Error", "", this.global.myCompany.store, result.error.message);
            alert(result.error.message);
            console.log(result.error.message);
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === 'succeeded') {
              // Show a success message to your customer
              this.paymentSuccess = 1;
              //    this.GetRedeemable()

              this.CloseOrderPaid(result);
            //moved t closeorderpaid  this.AddPointsToUser();
            }
          }
        });
    } else {
      console.log("Bad Form ", this.stripeTest);
    }
  }




  private createPaymentIntent(amount: number): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/create_payment_intent`,
      { amount }
    );
  }

  private createCustomer() {
    /*   console.log ("Create Customer");
       return this.http.post(
         `https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/customer_create`,
         {  }
       );*/

    const emailInput = document.querySelector('#email');

    fetch('https://us-central1-suncraft-ea7be.cloudfunctions.net/expressApi/create-customers', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: "scott@bobs.com",
      }),
    }).then(r => {
      r.json()
      console.log("Cust: ", r)
    });



  }



  returnToPos() {
    this.card.unmount();
    this.router.navigate(['front']);
  }

  toggleViewDetail() {
    this.viewDetail = !this.viewDetail;
    if (!this.viewDetail) { this.detailText = "see detail"; }
    else { this.detailText = "hide"; }
  }


  CloseOrderPaid(result: any) {
    var self = this;

    var db = firebase.firestore();
    // CUSTOMER BEING CHECKED-OUT


    console.log("customer: ", this.stripeTest.get('name').value)
    //  if (self.global.customer) id = self.global.customer.uid;

    var success = false;

    var allClosed = false;
    self.sharedDataService.payOrder.forEach(function (item: Order, index) {

      var id = "";
      if (item.userID) id = item.userID;
      db.collection("orders").doc(item.id).update({
        status: "Closed",
        paid: true,
        tipAmount: self.tipAmount,
        salesTax: self.salesTax,
        totalCharge: self.theTotal,
        cashTendered: self.cashPaymentAmount,
        userID: id,
        resultId: result.paymentIntent.id,
        resultStatus: result.paymentIntent.status,
        name: self.stripeTest.get('name').value,
        closedAt: Date.now()
      })
        .then(() => {
          console.log("Order Paid & Closed!");

          if (index == self.sharedDataService.payOrder.length-1) {
            // this is the last one
            self.AddPointsToUser();
          }

         // success = true;

        })
        .catch((error) => {
          success = false;
          alert("Closing Order falied " + error);
          console.error("Error updating status for paid: ", error);
        });

    })

    //    if (success) {
    // SO NOW ADD POINTS TO ACCOUNT - UNLESS USING POINTS TO PAY
    /* BAD CODE - MUST BE INSIDE PROMISE.
        if (id) {
          // CHECKING OUT USER RECORD SHOULD BE LOADED INTO GLOBAL
          self.global.customer.following.forEach(function (arrayItem) {
            // FIND STORE
            if (arrayItem.id == "4390") { // HACK HACK
              arrayItem.points += self.theTotal * 10; // Magic ONLY PAID ITEMS
             // self.myRewardPoints = arrayItem.points;
    
              // Now Subtract Points USED!
              if (self.myPointsTotal) {
                arrayItem.points -= self.myPointsTotal;
    
              }
    
    
            }
          })
    
    
          db.collection("users").doc(id).update({
    
            following: self.global.customer.following
          }).then(() => {
            console.log("Points ADDED!")
            self.messageService.add({ key: 'tc', severity: 'info', summary: 'Reward!', detail: 'You earned ' + self.theTotal * 10 + ' points!' });
          //  this.GetRedeemable(self.companyID);
    
          })
            .catch((error) => { console.log("ERROR ADDING POINTS!"); })
          //      }
        }
     
        else {    // goto Success HTML
          this.paymentSuccess = 1;
        }
        */
  }

  AddPointsToUser() {
    // Only for already registered user, new user points assigned with register
    var self = this;

    if (self.global.customer) {
      var db = firebase.firestore();

      const storeNum = self.global.myCompany.store;

      const index = self.global.customer.following.findIndex(item => item.storeNum === storeNum);

      if (index != -1) {
        self.global.customer.following[index].points += self.myRewardPoints;

        if (this.global.customer) {
          db.collection("users").doc(this.global.customer.uid).update({
            following: self.global.customer.following
          }).then(() => {
            console.log("Points ADDED! ", self.myRewardPoints, self.global.customer.following[index].points)
            //  self.messageService.add({ key: 'tc', severity: 'info', summary: 'Reward!', detail: 'You earned ' + self.theTotal * 10 + ' points!' });
            //  this.GetRedeemable(self.companyID);

          })
            .catch((error) => { console.log("ERROR ADDING POINTS!"); })
          //      }


        }
      }
    }

  }

  AddUserToOrder(userId: string) {
    var self = this;

    var db = firebase.firestore();
    // CUSTOMER BEING CHECKED-OUT
    //  if (self.global.customer) id = self.global.customer.uid;

    var success = false;

    self.sharedDataService.payOrder.forEach(function (item: Order) {
      //  var id = "";
      //  if (item.userID) id = item.userID;
      db.collection("orders").doc(item.id).update({
        userID: userId,
      })
        .then(() => {
          console.log("User updated!");

        })
        .catch((error) => {
          success = false;
          console.error("Error updating userid ", error);
        });

    })
  }





  GetOrders() { // GET ALL ORDERS auto-sort to NEW on top
    var self = this;
    self.myOrderTotal = 0;
    self.myPointsTotal = 0;

    // This function now adds and verifies order
    var allDelivered = true;
    this.sharedDataService.payOrder.forEach(function (item) {
      console.log(item);
      if (item.status != "Closed") {
        if (!item.RewardPaid) {
          self.myOrderTotal += item.extPrice; //data.price * data.qty;
        }
        else { // Cost of Reward Points

          self.myPointsTotal += item.RewardPaid;
        } if (self.tableNameQR) {
          if (item.price > 0 && item.status != "Delivered") {
            allDelivered = false;
          }
        }

      }

    })
    console.log("Order total: ", self.myOrderTotal);
    /*
    self.salesTax = self.myOrderTotal * .0775;
    self.theTotal = self.myOrderTotal + self.salesTax + self.tipAmount;
  
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
      amount: [self.theTotal, [Validators.required, Validators.pattern(/\d+/)]]
    });
    */
    this.getTotal();
   // self.myPointsTotal = (self.myOrderTotal) * 10;  // HACK HACK

    /*
      var db = firebase.firestore();
      console.log("Getting Checkout order: ", self.user.uid, self.companyID);
      
      db.collection("orders").where("companyID", "==", self.companyID).where("userID", "==", self.user.uid)
          .onSnapshot((querySnapshot) => {
              self.orders = [];
              var allDelivered = true;
              self.myOrderTotal = 0;
              querySnapshot.forEach((doc) => {
                  var data = <Order>doc.data();
                  
        //         HACK to REMOVE orders for no one 
        //          if (data.userID == "")
        //          db.collection("orders").doc(data.id).delete().then(() => {
        //            console.log("Document successfully deleted!");
        //        }).catch((error) => {
        //            console.error("Error removing document: ", error);
        //        });
      
                    
      
                  if (data.status != "Closed") {
                      if (!data.id) data.id = doc.id; // AUTO-ID -hack 
                      self.orders.push(data);
                      self.myOrderTotal += data.price * data.qty;
                      if (self.tableNameQR) {
                          if (data.price > 0 && data.status != "Delivered") {
                              allDelivered = false;
                          }
                      }
          
                  }
              }); // Have all orders
      this.fb.group({
                name: ['', [Validators.required]],
                amount: [self.myOrderTotal, [Validators.required, Validators.pattern(/\d+/)]]
              });
      
      
        //      self.CheckOutActive = allDelivered;
      
              console.log("order ", self.orders);
        //      orders = self.myOrder;
              console.log("order updated", );
          })
      
      */
  }

  redeemTotal: number = 0;
  redeemCount(): number {
    var self = this;

    this.redeemTotal = 0;


    this.global.redeem.forEach(function (item) {
      if (item.retail > 0 && item.active && (item.retail < (self.myRewardPoints + 100) / 100)) {
        self.redeemTotal++;
      }
    })
    // console.log("Redeemable items: ", this.redeemTotal, self.myRewardPoints)
    return this.redeemTotal;

  }

  GetRedeemable() {
    var db = firebase.firestore();
    var self = this;

    // Already done not needed
    this.red = [];
    const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").where("active", "==", true)

   // db.collection("products").where("companyID", "==", self.global.myCompany.id).where("active", "==", "true")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Product>doc.data();
          if (data.retail > 0 && data.active && data.redeemable && (data.retail < (self.myRewardPoints + 100) / 100)) {
            self.red.push(data);
          }

        })


      }).catch((error) => {
        console.log("Error getting redeemables:", error);
      });
    console.log("Red: ", this.red);
  }

  GetCompany(companyID: string) {
    var db = firebase.firestore();
    var self = this;
    // var docRef = db.collection("company").where("store", "==", companyID); //.doc(companyID);
    console.log("get Company ID ", companyID);
    companyID = "4390";
    db.collection("company").where("store", "==", companyID)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //    const data = <Company>doc.data();

          //  docRef.get().then((doc) => {
          //    if (doc.exists) {
          self.myCompany = <Company>doc.data();
          const data = <Company>doc.data();
          self.companyID = data.id;

          console.log("stripeAccount ", self.myCompany.stripeAccountID);
          this.setStripeInstance(self.myCompany.stripeAccountID);
          //        self.getCart();
          //        self.GetOrders();
        })

      }).catch((error) => {
        console.log("Error getting company:", error);
      });

  }


  dateTimeSort() {

    this.sharedDataService.payOrder.sort(function (a, b) { return b.dateTime - a.dateTime; });

  }
  // Redemption //
  onSortChange(event) {
    let value = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }
  // Redemption //

}