    <div class="data-entry" fxLayout="row" fxLayoutAlign="space-between">
        <span fxLayout="column" fxLayoutAlign="center center">
            Active
            <p-inputSwitch [(ngModel)]="editingProduct.active"></p-inputSwitch>
        </span>
        <span fxLayout="column" fxLayoutAlign="center center">
            Redeemable
            <p-inputSwitch [(ngModel)]="editingProduct.redeemable"></p-inputSwitch>
        </span>
        <span fxLayout="column" fxLayoutAlign="center center">

            On Menu
            <p-inputSwitch [(ngModel)]="editingProduct.onMenu"></p-inputSwitch>
        </span>
    </div>

    <div class="data-entry" fxLayout="row wrap" fxLayoutAlign="space-between">
        <div class="p-field p-col-3" fxLayout="column" fxLayoutAlign="none none">
            Price

            <p-inputNumber [(ngModel)]="editingProduct.retail" inputId="currency-us" mode="currency" currency="USD"
                locale="en-US">
            </p-inputNumber>

        </div>

        <div class="p-field p-col-9 p-md-3">
            <label for="integeronly">Set Position</label>
            <p-inputNumber inputId="integeronly" [(ngModel)]="editingProduct.myPosition">
            </p-inputNumber>
        </div>        
        <div class="p-field p-col-9" fxLayout="column" fxLayoutAlign="none none">
            Title

            <input type="text" pInputText [(ngModel)]="editingProduct.title" />

        </div>
        <div class="p-field p-col-9" fxLayout="column" fxLayoutAlign="none none">
            Dept

            <input type="text" pInputText [(ngModel)]="editingProduct.dept" />

        </div>
    </div>

    <div *ngIf="editingProduct.fullDescription" class="text-input">
        <div fxLayout="colummn" fxLayoutAlign="none none">

            <textarea [rows]="5" [cols]="45" pInputTextarea autoResize="autoResize"
                [(ngModel)]="editingProduct.fullDescription"></textarea>
        </div>
    </div>

    <div class="data-entry">
        <p-chips [(ngModel)]="editingProduct.allTags"></p-chips>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around none" class="data-entry">
        <p-button label="Save & Close" (click)="handleClick()"></p-button>

        <p-button label="Copy to New Product" (click)="copyNewProduct()"></p-button>

    </div>

