<div fxLayout="column" fxLayoutAlign="center none" [style]="getFullWidth()">
    <p-accordion *ngIf="editing" expandIcon="false" collapseIcon="false" [multiple]="false">
        <p-accordionTab iconPos="end">
            <ng-template pTemplate="header">
                <div class="settings">Settings</div>
            </ng-template>
            <ng-template pTemplate="content">

                <app-form formName="masterNewsSettings" [formInput]="global.authuser.newsSettings" [autoSubmit]="false"
                    (submitEvent)="outputEvent($event)"></app-form>
            </ng-template>
        </p-accordionTab>
    </p-accordion>

    <app-form [formName]="'masterSearchNews'" [autoSubmit]="false" (objectEvents)="fieldEvent($event)"></app-form>
    <div *ngIf="newsResults" style="width:100%" fxFlex fxLayout="row wrap" fxLayoutAlign="space-between center">
        <div *ngFor="let m of newsResults; let i = index;">

            <figure *ngIf="global.authuser.newsSettings.includePhotos && m.photo_url && m.title.length"
                class="snip1527">
                <div *ngIf="global.authuser.newsSettings.includePhotos" class="image"><img src={{m.photo_url}} /></div>
                <figcaption>
                    <div class="date"><span class="day">{{getDay(m)}}</span><span class="month">{{getMonth(m)}}</span>
                    </div>

                    <h3>
                        {{m.title}}
                    </h3>
                </figcaption>
                <a target="_blank" href="{{m.link}}"></a>
            </figure>
            <div *ngIf="!global.authuser.newsSettings.includePhotos && m.title?.length">

                <figure class="snip1312">
                    <figcaption>
                        <div style="font-size:18px">
                            {{m.title}}
                        </div>

                      
                        <a target="_blank" href="{{m.link}}"></a>
                    </figcaption>
                    <footer>
                        <div class="views"><i class="ion-eye"></i>
                            {{getMonth(m)+" "}}{{getDay(m)}}

                        </div>
                        <div class="views"><i class="ion-eye"></i>2,907</div>
                        <div class="love"><i class="ion-heart"></i>623</div>
                        <div class="comments"><i class="ion-chatboxes"></i>23</div>
                    </footer>
                </figure>








            </div>

        </div>

    </div>
</div>