import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class IpService {
    constructor(private https: HttpClient) { }
    public getIPAddress() {
      return this.https.get("https://api.ipify.org/?format=json");
    }
  }