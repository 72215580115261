/* TO DOs
  Check manual date against valid dates: need a function for this
    - Feedback - Date not available in this calendar
  Fix clicking in edit box issues

Create database for holiday closures to create a [Closure List]
New Year's Day (January 1)
Birthday of Martin Luther King, Jr. (Third Monday in January)
Inauguration Day (January 20, every 4 years following a presidential election)
Washington's Birthday (Also known as Presidents Day; third Monday in February)
Memorial Day (Last Monday in May)
Juneteenth National Independence Day (June 19)
Independence Day (July 4)
Labor Day (First Monday in September)
Columbus Day (Second Monday in October)
Veterans Day (November 11)
Thanksgiving Day (Fourth Thursday in November)
Christmas Day (December 25)



*/


import { Component, Output, Input, HostListener, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { Globals } from 'src/app/globals';
import { Company, Calendar, Theme, Navigate, Form, Field, Reviews, Review } from '../company-interface';
import { HttpClient } from '@angular/common/http';
import { User, Contact, Media, ChatBot } from '../user-interface';
import { RegisterService } from '../register.service';
import { MediaService } from "../media//media-functions/media-functions"
import { Logger } from 'src/app/functions';
import { FormService } from '../form.service'
import { set, isToday, compareAsc, startOfDay, getTime, getDate, setDate, setMonth, setYear, getYear, addMonths, subMonths, getMonth, getDaysInMonth, startOfMonth, getDay, endOfDay, getMilliseconds, isPast, isFuture } from "date-fns";


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarComponent implements OnInit {
  @Input() dateStr: string;
  @Input() dateMs: number[] = [];
  @Input() calendar: Calendar;
  @Input() width: string;
  @Output() outputDate = new EventEmitter<any>();
  @Input() closeOnSelect: boolean = true;
  date: Date;
  calForm: Form;

  monthStart: Date;
  dayStart: number;
  today;
  daysInThisMonth: number;
  month;
  monthName;
  monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  year;
  yearField;
  monthField;
  calendarStyle; // Another way to set style = "border: 1px solid red"
  enterCounter = 0;
  /*
    @HostListener('document:keydown.enter')
    onDocumentKeydownEnter() {
      this.enterCounter++;
      console.log ("Hit Enter! ", this.enterCounter)
    }
  */

  constructor(public global: Globals,
    public registerService: RegisterService, public formService: FormService,
    private http: HttpClient, private mediaService: MediaService

  ) {


  }

  ngOnInit(): void {

    if (!this.dateStr) this.date = new Date(Date.now());
    else this.date = new Date(this.dateStr)

    if (!this.calendar) {
      this.calendar = {
        dateCount: 1,
        themeId: "EFCayOgEI2QY9mtKoamw",
        //  selectPast: true, 
        //  selectToday: true,
        year1: 1900, year2: 2046,
        highlightToday: true,
        highlightSelected: true,
        //   daysOpen: [true, false, false, false, false, true, true],
      }
    }

    console.log("Start Calendar: ", this.calendar, this.dateMs)
    this.today = new Date(Date.now())
    this.monthStart = startOfMonth(this.date)
    this.dayStart = getDay(this.monthStart);
    this.daysInThisMonth = getDaysInMonth(this.monthStart)
    this.month = getMonth(this.monthStart)
    this.monthName = this.monthNames[this.month]
    this.year = getYear(this.monthStart)

    if (!this.calendar) this.calendar = {}
    if (!this.calendar?.year1) this.calendar.year1 = 1970;
    if (!this.calendar?.year2) this.calendar.year2 = this.year + 6; // HARDCODE THAT BABY

    // Check for a bookings source...
    // start watch on that to auto-update this calendar
    // For each date with bookings, update highlight
    if (this.calendar.enableBookings && this.calendar?.bookings) {
      this.getBookings(this.calendar.bookings)
    }


    console.log("Start Calendar: ", this.date, this.calendar, this.dayStart);
    this.createCalendar(this.date)

  }

  createCalendar(date: Date) {
    var self = this;

    var w = self.global.getScreenWidth;
    var myWidth = w + 'px';


    if (self.width && w > 370) {
      if (self.width.includes('%')) {
        const x = parseInt(self.width);
        myWidth = self.width;
        w = self.global.getScreenWidth * (x / 100);
      }
      else {
        myWidth = self.width;
        w = parseInt(myWidth)
      }
      console.log("WIDTH: ", w, myWidth)
    }


    self.calForm = { name: "Calendar", width: myWidth, showSubmit: false, noSubmit: true, fields: [] };
    var boxSize = Math.trunc(w / 7);
    var boxHeader = Math.trunc(w / 4);

    if (self.calendar.themeId)
      self.calForm.themeId = self.calendar.themeId;
    if (self.calendar?.theme?.length) {

      var found = self.global.allThemes.find((f) => f.name == self.calendar.theme)
      if (found) {
        console.log("THEME: ", found, self.calendar.theme)
        self.calForm.themeId = found.id;
      }
    }
    console.log("CALENDER FORM: ", self.calendar, self.global.allThemes, self.calForm)

    var f: Field;

    f = { type: 'button', buttonStyle: 'Item Select', boxWidth: boxHeader + 'px', width: "100%", label: "Prev", blurButton: "Update", title: 'prevMonth' }
    self.calForm.fields.push(f);
    f = { type: 'button', buttonStyle: 'Item Select', boxWidth: boxHeader + 'px', width: "100%", label: self.monthName, blurButton: "Update", title: 'changeMonth' }
    self.calForm.fields.push(f);
    f = { type: 'button', buttonStyle: 'Item Select', boxWidth: boxHeader + 'px', width: "100%", label: self.year, blurButton: "Update", title: 'changeYear' }
    self.calForm.fields.push(f);
    f = { type: 'button', buttonStyle: 'Item Select', boxWidth: boxHeader + 'px', width: "100%", label: "Next", blurButton: "Update", title: 'nextMonth' }
    self.calForm.fields.push(f);
    self.calForm.fields.push({ title: "new line", type: 'newLine', newLine: true })

    f = { type: 'select-button', optionsVisible: true, display: false, label: "Month", options: [], blurButton: "Update", title: 'newMonth' }
    f.options = self.monthNames;

    self.calForm.fields.push(f);
    self.monthField = self.calForm.fields.length - 1;

    f = { type: 'select-button', optionsVisible: true, display: false, label: "Year", options: [], blurButton: "Update", title: 'newYear' }
    for (var n = self.calendar.year1; n <= self.calendar.year2; n++) {
      if (self?.calendar?.selectPast || n >= self.year) {
        f.options.push(n.toString())
      }
    }
    self.calForm.fields.push(f);
    self.yearField = self.calForm.fields.length - 1;

    // Ok Now add days of the week ...
    for (var n = 0; n < 7; n++) {
      f = { type: 'button', boxWidth: boxSize + 'px', width: "100%", buttonStyle: 'Default Style', label: self.dayNames[n], blurButton: "None", title: 'dayOfWeek' }
      self.calForm.fields.push(f);
    }
    self.calForm.fields.push({ title: "new line", type: 'newLine', newLine: true })

    // CALENDAR DAYS

    for (var n = 1; n <= 35; n++) {
      if (n <= self.dayStart || n - self.dayStart > self.daysInThisMonth) {
        f = { type: 'button', buttonStyle: 'Disabled', boxWidth: boxSize + 'px', width: "100%", label: ' ', blurButton: "None", title: 'day1' }
        self.calForm.fields.push(f);
      }
      else {
        f = { type: 'button', boxWidth: boxSize + 'px', width: "100%", buttonStyle: 'Item Select', label: (n - self.dayStart).toString(), blurButton: "Update", title: 'myDate' }
        var thisDate = set(new Date(), { year: self.year, month: self.month, date: n - self.dayStart })
        thisDate = startOfDay(thisDate);

        if (self?.calendar?.selectPast == false) {
          if (isPast(thisDate)) {
            f.disabled = true;
            f.buttonStyle = 'Disabled';
          }
        }
        if (self?.calendar?.selectFuture == false) {
          if (isFuture(thisDate)) {
            f.disabled = true;
            f.buttonStyle = 'Disabled';
          }
        }
        if (self.calendar.showHolidays) {
          //self.checkHoliday
        }

        if (this.calendar.enableBookings && self.myBookings) {
          const howMany = self.bookingsCount(thisDate)
          if (this.calendar.showBookings && howMany > 0) {
            f.highlight = true;
            f.label += " - " + howMany;
          }
          if (howMany >= this.calendar.maxDailyBookings) {
            f.disabled = true;
            f.buttonStyle = 'Disabled';
          }
        }

        /*
        if (getDate(thisDate) == 14)
        f.event = "Hey this is\nextra info"
        if (thisDate.getDay() == 6)
        f.event = "Vineyard Tour\n2:00pm"
*/
        if (isToday(thisDate)) {
          if (self?.calendar?.selectToday) {
            f.disabled = false;
            f.buttonStyle = 'Item Select';
          }
          else {
            f.disabled = true;
            f.buttonStyle = 'Disabled';
          }
        }
        if (self.calendar.highlightToday && isToday(thisDate)) {
          f.highlight = true;
        }
        if (self.calendar.highlightSelected && self.dateMs && self.dateMs.length) {
          self.dateMs.forEach(function (item) {
            if (compareAsc(thisDate, item) == 0)
              f.highlight = true;
          })
        }
       // console.log("open ", self.calendar.daysOpen)
        if (self.calendar.daysOpen) { // Harsh but true
          const index = getDay(thisDate)
          const dayOfWeek = self.dayNames[index];
       
          if (!self.calendar.daysOpen.includes(dayOfWeek)) { // DISABLE THIS DATE
            f.disabled = true;
            f.buttonStyle = 'Disabled';
          }

        }

        self.calForm.fields.push(f);
      }
      if (n % 7 == 0) {
        self.calForm.fields.push({ title: "new line", type: 'newLine', newLine: true })
      }
    }
  }
  theDates: number[] = [];
  fieldEvents(field: Field) {
    var self = this;
    var dateMs;

    switch (field.title) {
      case "myDate":
        if (!field.disabled) {
          var dateSelected = set(self.date, { year: self.year, month: self.month, date: Number(field.label) })

          // Now Let's format based on settings
          var dayStart = startOfDay(dateSelected)
          dateMs = getTime(dayStart);

          console.log("Got date selection: ", dateSelected, dateMs)
          self.outputDate.emit(dateMs);
          if (!self.dateMs || !self.dateMs?.length || self.dateMs.length < 1) {
            self.dateMs = [dateMs];


          }

          // if (self.calendar?.dateCount > 1)
          self.updateCal();
        }

        break;
      case "prevMonth":
        self.date = subMonths(self.date, 1)
        self.updateCal();
        break;
      case "nextMonth":
        self.date = addMonths(self.date, 1)
        self.updateCal();
        break;
      case "changeMonth":
        self.calForm.fields.forEach(function (field) {
          field.display = false;
        })
        self.calForm.fields[self.monthField].display = true;
        break;
      case "changeYear":
        self.calForm.fields.forEach(function (field) {
          field.display = false;
        })
        self.calForm.fields[self.yearField].display = true;
        break;
      case "newMonth":
        const index = self.monthNames.findIndex(x => x === field.binding)

        self.date = setMonth(self.date, index)
        self.calForm.fields.forEach(function (field) {
          field.display = true;
        })
        self.calForm.fields[self.monthField].display = false;
        self.updateCal();
        break;
      case "newYear":
        self.date = setYear(self.date, Number(field.binding))
        self.calForm.fields.forEach(function (field) {
          field.display = true;
        })
        self.calForm.fields[self.yearField].display = false;
        self.updateCal();
        break;
    }


  }
  updateCal() {
    var self = this;

    self.monthStart = startOfMonth(self.date)
    self.dayStart = getDay(self.monthStart);
    self.daysInThisMonth = getDaysInMonth(self.monthStart)
    self.month = getMonth(self.monthStart)
    self.monthName = this.monthNames[self.month]
    self.year = getYear(self.monthStart)
    self.createCalendar(self.monthStart)
  }
  getBookings(bookings: { form: string, field: string }) {
    var self = this;

    if (!bookings?.form || !bookings.field) return;
    // First get the formID (should already have)
    const formId = self.global.allForms.find(p => p.name == bookings.form).id;
    console.log("Let's get some bookings ", formId, self.global.allForms)

    self.formService.getSomeData(formId,
      [bookings.field], self.gotData, this)


  }

  myBookings;

  gotData(self, data) {
    console.log("We got Bookings! ", data)
    // Here we can filter by past etc.
    self.myBookings = data;
    self.createCalendar();

  }
  bookingsCount(thisDate: Date) {
    var count = 0;

    this.myBookings.forEach(function (booked) {
      //    console.log ("Booked ", thisDate, booked[0] )
      if (compareAsc(thisDate, booked[0]) == 0)
        count++;
    })

    return count;
  }

}