import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Phaser, { GameObjects } from 'phaser'; //import Phaser
import { VIPService, VIPScene, VIPObject, ObjectEvent, VIPSettings, Tween } from '../VIP.service';
import { Globals } from 'src/app/globals';
import { Field } from '../company-interface'
import { settingsTool } from './toolbars.component'
import { Palette } from './palette.component'
import { Minimap } from './minimap.component'
import { SpeachBubblePlugin } from './speach-bubble.component'
import { ReviewsService } from '../reviews.service';

class Bullet extends Phaser.Physics.Matter.Sprite {
  lifespan;
  // self;

  constructor(world, x, y, texture, mySelf) {
    super(world, x, y, texture);
    // this.self = mySelf;
    this.name = 'bullet';
    this.setFrictionAir(0);
    this.setFixedRotation();
    this.setActive(false);

    this.scene.add.existing(this);

    this.world.remove(this.body, true);
  }

  fire(x, y, angle, speed) {
    this.world.add(this.body);

    this.setPosition(x, y);
    this.setActive(true);
    this.setVisible(true);

    this.setRotation(angle);
    this.setVelocityX(speed * Math.cos(angle));
    this.setVelocityY(speed * Math.sin(angle));

    this.lifespan = 2000;
  }

  preUpdate(time, delta) {
    super.preUpdate(time, delta);

    this.lifespan -= delta;

    if (this.lifespan <= 0) {
      this.setActive(false);
      this.setVisible(false);
      this.world.remove(this.body, true);
    }
  }
}




@Component({
  selector: 'app-phaser',
  templateUrl: './phaser.component.html',
  styleUrls: ['./phaser.component.css']
})

export class PhaserComponent implements OnInit {
  @Input() appName;
  @Input() sceneId;
  @Output() setVIP = new EventEmitter<any>();
  @Output() afterPreload = new EventEmitter<any>();
  @Output() eventEmit = new EventEmitter<any>()


  //declare phaserGame variable, the ! is needed for it to be valid code in Angular, we just have to make sure we initialize it in ngOnInit
  phaserGame!: Phaser.Game;
  config: Phaser.Types.Core.GameConfig;
  matter: Phaser.Physics.Matter.MatterPhysics;
  blocks;
  tool: string = "Selector";
  vipObject: VIPObject;
  click: boolean;
  currentImage: string = "gems";
  allObjects: VIPObject[];
  myScene: Phaser.Scene;
  img: any;
  sceneWidth = 800;
  speachbubbles: any[] = [];

  sceneScene: VIPScene;
  sceneSettings: VIPSettings;
  source: any;
  target: any;
  lastUpdated = 0;
  emitter1: any;
  flame;
  wisp;
  smokey;
  palette;
  g2;
  timerEvents: any[];
  objectEvents: ObjectEvent[];
  newMedia = false;
  currentFill;
  currentColor;

  settingFields: Field[];

  tool_toolBar;
  tool_imageSelector;
  tool_mainSettings;
  tool_objectSettings;
  tool_palette;
  tool_minimap;
  toolsVisible: boolean = true;
  allowEditing: boolean;


  color = new Phaser.Display.Color();
  cursors;
  index = 0;
  shape = null;
  current = 0;
  newTool;
  isDown = false;
  shapes = [];
  swatchData;
  paused: boolean = false;
  score: number = 0;
  scoreText: any;
  objectPhysicsEnabled: boolean = false;
  text: string = "Hello";
  editLayer;
  nameLayer;
  avatar;
  bullets;
  myAvatar;
  lastAvatarUpdated = 0;
  soundFx;
  pointerIsOverGame: boolean;
  wKey;
  aKey;
  sKey;
  dKey;
  fKey;
  pKey;
  tKey;
  startScale;
  shipCollisionCategory;
  bulletCollisionCategory;
  enemiesCollisionCategory;
  platformCollisionCategory;
  collisionCategories: { name: string, category: number }[];

  constructor(public VIPservice: VIPService, public global: Globals, public reviewsService: ReviewsService) {
    //this.myScene.matter.
    // this.myScene.matter.body.set

    console.log("SCENEID ", this.sceneId)

    this.soundFx = [
      "alien death",
      "boss hit",
      "escape",
      "meow",
      "numkey",
      "ping",
      "death",
      "shot",
      "squit"

    ]




  }
  ngOnInit() {
    const that = this;
    const context = this

    console.log("INIT PHASER: ", this.appName)

    this.config = {
      type: Phaser.CANVAS,
      /*      plugins: {
              global: [
                { key: 'SpeachBubblePlugin', plugin: SpeachBubblePlugin, start: true }
              ]
            },
            */
      scene: [{
        key: "VIP",
        preload: function () {
          that.preloadScene(this);
        },
        create: function () {
          that.createScene(this);
        },
        update: function () {
          that.updateScene(this);
        },

      }],
      scale: {
        height: 1900,
        width: 1600,
        mode: Phaser.AUTO,
        autoCenter: Phaser.Scale.CENTER_BOTH,
        zoom: 1,
      },


      parent: 'gameContainer',
      dom: {
        createContainer: true
      },
      input: {
        mouse: { preventDefaultWheel: false }, touch: { capture: false },


      },


      title: "VIP",
      backgroundColor: "#fefefe",
      clearBeforeRender: true,
      physics: {
        default: 'matter',
        matter: {
          gravity: { x: 0, y: .7 },
          setBounds: true,
          debug: false,
          enableSleeping: false,

        },

      }
    };


    this.VIPservice.getSceneSettings(this.sceneId, this.settingsCallback, this)

    this.setVIP.emit(this)

  }
  /*
    fieldsCallback(fields, self) {
      self.settingFields = fields;
      console.log("GOT FIELDS ", self.settingFields)
    }
  */
  createScene(scene) {
    this.myScene = scene;
    scene.input.keyboard.enableGlobalCapture()

    scene.input.on('gameover', (event) => {
      console.log("Over game"); this.pointerIsOverGame = true;
      //  scene.input.keyboard.enableGlobalCapture()
    })
    scene.input.on('gameout', (event) => {
      console.log("Not Over game");
      //    scene.input.keyboard.disableGlobalCapture()
      this.pointerIsOverGame = false;
    })

    /*
    scene.element = scene.add.dom(400, 300, 'div', 'font-size: 96px', '💩').setOrigin(0);
    const element2 = scene.add.dom(400, 300, 'div', 'background-color: rgba(255, 255, 0, 0.5); width: 300px; height: 200px; font: 48px Arial; font-weight: bold', 'Phaser 3');
    const element = scene.add.dom(200, 200).createFromCache('reviews');
    */

    if (!this.global.customGameObjectsLoaded) {
      this.global.customGameObjectsLoaded = true;
      let plugin2 = scene.plugins.install('SpeachBubblePlugin', SpeachBubblePlugin, true);
    }



    if (this.allowEditing) {
      this.editLayer = scene.add.layer();
      this.editLayer.setDepth(200000);
      this.nameLayer = scene.add.layer();
      this.nameLayer.setDepth(10000)
    }

    // scene.matter.world.setBounds(0, 0, this.sceneSettings.width, this.sceneSettings.height, 50);


    // CREATE COLLISION CATEGORIES FROM GROUPS
    this.collisionCategories = [];
    var self = this;
    if (this.sceneScene?.groups)
      this.sceneScene.groups.forEach(function (group) {
        const next = <number>scene.matter.world.nextGroup();
        self.collisionCategories.push({ name: group, category: next });
      })

    // KILL any existing events
    if (this.timerEvents && this.timerEvents.length) {
      this.timerEvents.forEach(function (event) {
        event.destroy();
      })
    }
    // this.cursors = scene.input.keyboard.createCursorKeys();

    this.wKey = scene.input.keyboard.addKey(false, 'W');  // Get key object
    this.aKey = scene.input.keyboard.addKey(false, 'A');  // Get key object
    this.dKey = scene.input.keyboard.addKey(false, 'D');  // Get key object
    this.sKey = scene.input.keyboard.addKey(false, 'S');  // Get key object
    this.fKey = scene.input.keyboard.addKey(false, 'F');  // Get key object
    this.pKey = scene.input.keyboard.addKey(false, 'P');  // Get key object
    //  this.tKey = scene.input.keyboard.addKey(false, 'T');  // Get key object





    //  console.log(scene)

    this.checkWorld(scene)

    //scene.cameras.main.setZoom(4);
    console.log("Setting up bullets ", this.sceneScene.settings.avatars)
    if (this.sceneScene.settings.avatars) {
      console.log("Setting up bullets ")
      this.bullets = [];
      this.shipCollisionCategory = scene.matter.world.nextCategory();
      this.bulletCollisionCategory = scene.matter.world.nextCategory();
      this.enemiesCollisionCategory = scene.matter.world.nextCategory();

      for (let i = 0; i < 64; i++) {
        const bullet = new Bullet(scene.matter.world, 0, 0, 'bullet-sm', this);
        bullet.setIgnoreGravity(true)
        bullet.setCollisionCategory(this.bulletCollisionCategory);
        bullet.setCollidesWith([this.enemiesCollisionCategory]);
        // bullet.setOnCollide(

        //  console.log("Bullet ", bullet)
        this.bullets.push(bullet);
      }
    }


    this.emitter1 = scene.add.particles(0, 0, 'flares', {
      frame: ['red', 'yellow', 'green'],
      lifespan: 6000,
      speed: { min: 10, max: 200 },
      scale: { start: 0.001, end: .4 },
      gravityY: 70,
      frequency: 10,
      blendMode: 'ADD',
      emitting: false
    });

    this.flame = scene.add.particles(0, 0, 'flares',
      {
        frame: 'white',
        color: [0xfacc22, 0xf89800, 0xf83600, 0x9f0404],
        colorEase: 'quad.out',
        lifespan: 2400,
        angle: { min: -100, max: -80 },
        scale: { start: 0.70, end: 0, ease: 'sine.out' },
        speed: 100,
        advance: 2000,
        blendMode: 'ADD',
        emitting: false
      });

    this.wisp = scene.add.particles(0, 0, 'flares',
      {
        frame: 'white',
        color: [0x96e0da, 0x937ef3],
        colorEase: 'quart.out',
        lifespan: 1500,
        angle: { min: -100, max: -80 },
        scale: { start: 1, end: 0, ease: 'sine.in' },
        speed: { min: 250, max: 350 },
        advance: 2000,
        blendMode: 'ADD',
        emitting: false
      });

    this.smokey = scene.add.particles(0, 0, 'flares',
      {
        frame: 'white',
        color: [0x040d61, 0xfacc22, 0xf89800, 0xf83600, 0x9f0404, 0x4b4a4f, 0x353438, 0x040404],
        lifespan: 1500,
        angle: { min: -100, max: -80 },
        scale: 0.75,
        speed: { min: 10, max: 300 },
        advance: 2000,
        blendMode: 'ADD',
        emitting: false
      });

    scene.registry.set('currentImage', this.currentImage);
    scene.registry.set('masterSelf', this);
    scene.registry.set('newMedia', this.newMedia);

    scene.registry.set('score', 3);
    if (this.sceneSettings.starField) {
      this.phaserGame.scene.add('SceneMain', SceneMain, true);
      //   this.phaserGame.scene.start('SceneMain');
    }

    scene.registry.events.on('changedata', this.updateTool, this);

    if (this.allowEditing && (this.sceneSettings.toolbars || this.toolsVisible)) {
      this.tool_toolBar = this.phaserGame.scene.add('toolBar', toolBar, true);
      this.tool_imageSelector = this.phaserGame.scene.add('imageSelector', imageSelector, true);
      this.tool_mainSettings = this.phaserGame.scene.add('playgroundSettings', settingsTool, true, { formId: "rrlTmT1aARFaCi0S5lod" });
      this.tool_objectSettings = this.phaserGame.scene.add('objectSettings', settingsTool, true, { formId: "9Jw0dYt0Nr9SyKIrUrro" });
      this.tool_palette = this.phaserGame.scene.add('palette', Palette, true);
      this.tool_minimap = this.phaserGame.scene.add('minimap', Minimap, true);
    }

    if (this.sceneSettings.score) {
      this.scoreText = scene.add.text(10, 0, 'Score: ');
      this.scoreText.setColor("black")
      this.scoreText.setBackgroundColor("green")
      this.scoreText.setDepth(10000)
      this.scoreText.setFontSize(30)
    }


    scene.matter.world.on('collisionstart', (event, bodyA, bodyB) => {
      var self = this;
      //  console.log(event)

      event.pairs.forEach(function (pair) {
        self.collisionActions(pair.bodyA, pair.bodyB)
        /*
                if (pair.bodyA.label == "Circle Body" || pair.bodyB.label == "Circle Body") {
                  var soundIndex = Math.floor(Math.random() * self.soundFx.length)
                  if (pair.bodyA?.gameObject?.type == 'Arc') {
                    //  console.log(bodyA, bodyB)
                    const r = Math.random() * 255;
                    const g = Math.random() * 255;
                    const b = Math.random() * 255;
                    const a = Math.random();
                    const hex: number = colorToNumber(r, g, b)
                    pair.bodyA.gameObject.setFillStyle(hex, a)
                    self.myScene.sound.playAudioSprite('sfx', self.soundFx[soundIndex]);
                  }
                  if (pair.bodyB?.gameObject?.type == 'Arc') {
                    // console.log(bodyA, bodyB)
                    const r = Math.random() * 255;
                    const g = Math.random() * 255;
                    const b = Math.random() * 255;
                    const a = Math.random();
                    const hex: number = colorToNumber(r, g, b)
                    self.myScene.sound.playAudioSprite('sfx', self.soundFx[soundIndex]);
                    pair.bodyB.gameObject.setFillStyle(hex, a)
                  }
        
        
                }
        
        
                if (pair.bodyA.label == "Rectangle Body" && pair.bodyA.gameObject) {
                  //   console.log(pair.bodyA, pair.bodyB)
                  if (pair.bodyA.gameObject && pair.bodyA.gameObject.type != 'Image') {
                    //  pair.bodyA.gameObject.setFillStyle(0x24bbdd, .8)
                  }
                  //  if (pair.bodyB.gameObject) {
                  //    console.log("HIT! ", pair.bodyA, pair.bodyB)
                  //    pair.bodyB.gameObject.destroy()
                  //   self.collisionActions(pair.bodyA, pair.bodyB)
                  //  }
                }
                if (pair.bodyB.label == "Rectangle Body") {
                  // console.log(pair.bodyA, pair.bodyB)
                  if (pair?.bodyB?.gameObject.type != 'Image') {
                    //   pair.bodyB.gameObject.setFillStyle(0x2499dd, .8)
                  }
                  if (pair.bodyA?.gameObject) {
                    //      pair.bodyA.gameObject.destroy()
        
                    // console.log("HIT! ", pair.bodyA, pair.bodyB)
                  }
                }
        
                */
      })
    });


    //this.setVIP.emit("afterPreLoad")
    this.VIPservice.getVIP(this.sceneId, "Who am I", scene, this.addObject, this)
    this.VIPservice.watchAvatars(this.sceneId, scene, this.updateAvatar, this)

    if (self.allowEditing) {
      scene.input.keyboard.on('keydown-D', this.setDrag, this);
      scene.input.keyboard.on('keydown-S', this.setResize, this);

      // scene.input.keyboard.on('keydown-C', this.setCircle, this);
      // scene.input.keyboard.on('keydown-Z', this.setSelect, this);
      //  scene.input.keyboard.on('keydown-R', this.setRectangle, this);
      //  scene.input.keyboard.on('keydown-E', this.setEllipse, this);
      //   scene.input.keyboard.on('keydown-S', this.setStar, this);
      // scene.input.keyboard.on('keydown-L', this.setLine, this);
      // scene.input.keyboard.on('keydown-DELETE', this.deleteShape, this);
      // scene.input.keyboard.on('keydown-TAB', this.changeShape, this);


      scene.input.on('pointerdown', this.drawStart, this);
      scene.input.on('pointermove', this.drawUpdate, this);
      scene.input.on('pointerup', this.drawStop, this);



      scene.input.on('pointerover', (event, gameObjects) => {
        var VIPobject = this.findVIP(gameObjects[0].name)
        if (VIPobject.locked) return;

        console.log("Over ", gameObjects)
        if (gameObjects[0].type == 'Image' || gameObjects[0].type == 'Sprite')
          gameObjects[0].setAlpha(.2)
        if (gameObjects[0].type == 'Rectangle') {
          gameObjects[0].setStrokeStyle(4, 0x00ff22, 1)
        }

      });
      scene.input.on('pointerout', (event, gameObjects) => {
        var VIPobject = this.findVIP(gameObjects[0].name)
        if (VIPobject.locked) return;
        if (gameObjects[0].type == 'Image' || gameObjects[0].type == 'Sprite')
          gameObjects[0].setAlpha(1)
        if (gameObjects[0].type == 'Rectangle') {
          gameObjects[0].setStrokeStyle()
        }

      });

      scene.input.on('pointerup', (event, gameObjects) => {
        var self = this;
        if (this.tool == "Selector") {
          if (gameObjects.length == 0) {
            self.selectNone();
          }
          else {

            var VIPobject = this.findVIP(gameObjects[0].name)
            VIPobject.selected = true;
            console.log("Selected ", gameObjects, VIPobject)
            self.addSelected(scene, VIPobject, gameObjects);
          }
        }

      })
    }


    // scene.input.on('touchmove', this.touchMove, this)



    /*
    
        scene.input.on('pointerup', (event, gameObjects) => {
          var self = this;
    
          if (this.tool != 'User' && self?.objectEvents?.length > 0) {
            self.objectEvents.forEach(function (event) {
              if (gameObjects?.length && event.objectId == gameObjects[0]?.name) {
                console.log("CLICKITY CLACK... ")
                self.eventEmit.emit(event)
              }
    
    
            })
    
          }
    
    
        })
    
        scene.input.on('pointerdown', (event, gameObjects) => {
          if (this.tool == 'Selector') {
            if (!gameObjects || (gameObjects && gameObjects.length == 0)) {
              console.log("No Object Selected")
              //   this.selectNone();
              //   this.editLayer.removeAll();
    
    
              //   this.editLayer = scene.add.layer();
    
            }
    
            else if (this.isSelected(gameObjects[0].id) == false) {
              var VIPobject = this.findVIP(gameObjects[0].name)
              VIPobject.selected = true;
              this.addSelected(scene, VIPobject);
    
    
            }
          }
          else if (gameObjects && gameObjects.length > 0) {
            console.log("Rotate? ", gameObjects[0])
            var VIPobject = this.findVIP(gameObjects[0].name)
    
            if (VIPobject && VIPobject.rotate) {
    
              gameObjects[0].setStatic(false);
              gameObjects[0].setIgnoreGravity(true)
              gameObjects[0].setFixedRotation();
              gameObjects[0].setAngularVelocity(VIPobject.rotate)
    
            }
          }
    
        });
    */

    if (self.allowEditing) {
      scene.input.on('dragstart', (pointer, gameObject) => {
        console.log("Drag start ", gameObject)
        var vipObject = this.findVIP(gameObject.name)
        vipObject.selected = true;

        this.myScene.children.bringToTop(gameObject);
        this.startScale = gameObject.scale;


      });

      scene.input.on('drag', (pointer, gameObject, dragX, dragY) => {
        if (this.current == 0) {
          //  console.log("Drag ", dragX)

          var x = dragX;
          var y = dragY;

          // GRID ALIGNMENT
          if (this.sceneSettings.gridSnap) {
            x = Phaser.Math.Snap.To(x, this.sceneSettings.grid);
            y = Phaser.Math.Snap.To(y, this.sceneSettings.grid);
          }
          gameObject.setPosition(x, y);

          var vipObject = this.findVIP(gameObject.name)
          if (vipObject) {
            vipObject.x = x;
            vipObject.y = y;
          }
        }
        else if (this.current == 9) {


          var xd = ((pointer.x - pointer.downX) / 100)
          if (xd != 0) {
            xd = this.startScale * (1 + xd);
            console.log("Drag ", xd, gameObject.scale)
            gameObject.setScale(xd);

          }
        }


      });

      scene.input.on('dragend', (pointer, gameObject) => {
        if (this.current == 0) {

          console.log("DONEDRAGGING: ", pointer)
          var vipObject = this.findVIP(gameObject.name)
          if (vipObject) {
            this.VIPservice.updateVIP(this.sceneId, vipObject)
          }
          console.log("Drag done ", gameObject, vipObject)
        }
        else if (this.current == 9) {

          var vipObject = this.findVIP(gameObject.name)
          if (vipObject) {
            vipObject.scale = gameObject.scale;

            this.VIPservice.updateVIP(this.sceneId, vipObject)
          }
          console.log("Scale done ", gameObject, vipObject)

        }
      });

    }

    /*
        scene.input.on('wheel', pointer =>
        {
          console.log("wheel ")
    
        })
    */

    const collisionHappened = (dog, cat) => {
      // projectile.destroy()
      // console.log("X")
    }
    //  scene.matter.add.collider(this.blocks, platforms, collisionHappened, null, this)
    //  this.scale.updateBounds() 

    scene.input.keyboard.on('keydown-T', event => {
      console.log("Pressed KEY", event.key);

      if (self.toolsVisible)
        self.hideTools();
      else
        self.showAllTools()

    });


  }
  bulletVsEnemy(collisionData) {

    const bullet = collisionData.bodyA.gameObject;
    const enemy = collisionData.bodyB.gameObject;

    if (enemy?.name) {
      console.log("COLLISIONBULLET! ", enemy)
      // collisionActions(collisionData.bodyB, null, "bullet!")
    }

    bullet.setActive(false);
    bullet.setVisible(false);
    bullet.world.remove(bullet.body, true);



    //  enemy.setScale(enemy.scale * 1.05)
    enemy.setActive(false);
    enemy.setVisible(false);
    enemy.world.remove(enemy.body, true);
  }

  lastBullet = 0;
  fireBullet() {
    var self = this;


    console.log("PING")
    if (self.bullets)
      var bullet = self.bullets.find(bullet => !bullet.active);

    if (bullet && self.avatar) {
      const rad = degrees_to_radians(self.avatar.angle)

      bullet.fire(self.avatar.x, self.avatar.y, rad, 7);
    }
  }

  checkWorld(scene: Phaser.Scene) {
    var self = this;

    console.log("WORLD: ", self.sceneSettings)

    if (!self.sceneSettings.worldWidth) {
      if (self.sceneSettings.width == '100%')
        self.sceneSettings.worldWidth = self.global.getScreenWidth;
      else
        self.sceneSettings.worldWidth = self.sceneSettings.width;

      if (self.sceneSettings.height == '100%')
        self.sceneSettings.worldHeight = self.global.getScreenHeight;
      else
        self.sceneSettings.worldHeight = self.sceneSettings.height;
    }



    if (self.sceneSettings.worldWidth) {
      scene.cameras.main.setBounds(0, 0, self.sceneSettings.worldWidth, self.sceneSettings.worldHeight);
      scene.matter.world.setBounds(0, 0, self.sceneSettings.worldWidth, self.sceneSettings.worldHeight);

      if (self.allowEditing && self.sceneSettings.grid) {
        this.g2 = scene.add.grid(0, 0, self.sceneSettings.worldWidth, self.sceneSettings.worldHeight, self.sceneSettings.grid, self.sceneSettings.grid, 0xffffff).setAltFillStyle(0xffffff).setOutlineStyle(0xff0000, 1);

        this.g2.setOutlineStyle(0xff0000, .5);
        console.log("GRID ", this.g2)
        //  g2.setScale(2)
        // g2.setAlpha(.05)
        this.g2.setOrigin(0, 0)
        this.g2.setDisplayOrigin(0, 0);
        this.g2.setClosePath(true)
        this.g2.setDepth(-100000); // Way back there
      }

      if (self.allowEditing) {
        scene.input.on('wheel', (pointer, gameObjects, deltaX, deltaY, deltaZ) => {
          //    console.log("Scroll ", self.sceneSettings.worldWidth, scene.cameras.main.scrollX, scene.cameras.main.scrollY)

          scene.cameras.main.scrollX += deltaX * 0.5;
          scene.cameras.main.scrollY += deltaY * 0.5;

          if (scene.cameras.main.scrollX > self.sceneSettings.worldWidth - (self.sceneSettings.width))
            scene.cameras.main.scrollX = self.sceneSettings.worldWidth - (self.sceneSettings.width);
          if (scene.cameras.main.scrollY > self.sceneSettings.worldHeight - (self.sceneSettings.height))
            scene.cameras.main.scrollY = self.sceneSettings.worldHeight - (self.sceneSettings.height);

          const mini = <any>scene.scene.get('minimap');
          //  console.log ("MYMINIMAP ", mini)
          if (mini)
            mini.setMiniDrag(scene.cameras.main.worldView.x, scene.cameras.main.worldView.y)


        });
      }


    }
  }
  selectionChanged(id) { // evolve to include array
    var self = this;

    const item = self.allObjects.find((f) => f.id == id)

    if (item?.selected) {  // De-select
      var objects = this.editLayer.getChildren();
      item.selected = false;
      objects.forEach(function (obj) {
        if (obj.name == item.id) {
          obj.destroy();
        }
      })
    }
    else {  // Select this
      item.selected = true;
      console.log("Add selected ", item)
      self.addSelected(self.myScene, item)

    }

  }

  addSelected(scene, VIPobject, gameObjects?) {
    var self = this;

    console.log("ADD SEL GRAPHICS ", scene, VIPobject)
    if (VIPobject) {
      //VIPobject.selected = !VIPobject.selected;
      if (VIPobject.selected) {

        const rect: Phaser.Geom.Rectangle = gameObjects[0].getBounds();

        const graphics = scene.add.graphics({ fillStyle: { color: 0x00ffaa } });
        //  graphics.setDepth(2000000)
        graphics.setAlpha(.4)
        //      graphics.fillRectShape(rect);
        graphics.name = VIPobject.id;



        graphics.lineStyle(2, 0xaaffff);

        graphics.lineBetween(rect.x, rect.y - 10, rect.x + rect.width, rect.y - 10);
        graphics.lineBetween(rect.x - 10, rect.y, rect.x - 10, rect.y + rect.height);
        graphics.lineBetween(rect.left, rect.top, rect.right, rect.bottom);
        graphics.lineBetween(rect.centerX - 10, rect.centerY, rect.centerX + 10, rect.centerY);
        graphics.lineBetween(rect.centerX, rect.centerY - 10, rect.centerX, rect.centerY + 10);

        self.editLayer.add([graphics]);

      }
    }
  }



  isSelected(id) {
    var self = this;

    const item = self.allObjects.find((f) => f.id == id)
    if (item) return item.selected;

    return false;
  }
  selectNone() {
    console.log("None ")

    this.allObjects.forEach(function (item) {
      item.selected = false;
    })
    this.editLayer.removeAll();
  }

  findVIP(id) {
    var self = this;

    const index = self.allObjects.findIndex(function (post) {
      if (post.id == id)
        return true;
    });
    if (index == -1) return null;
    else return self.allObjects[index]
  }

  collisionActions(bodyA, bodyB) {
    var self = this;
    var a = bodyA, b = bodyB;
    var aBullet = false;

    if (a?.gameObject?.name == "bullet") {
      console.log("A=Bullet")
      aBullet = true;
      var rnd = Math.random() * 3;
      if (rnd < 1)
        self.emitter1.explode(a.gameObject.x, a.gameObject.y, 4);
      else if (rnd < 2)
        self.flame.emitParticleAt(a.gameObject.x, a.gameObject.y, 40);
      else if (rnd < 3)
        self.smokey.emitParticleAt(a.gameObject.x, a.gameObject.y, 40);
      else
        self.wisp.emitParticleAt(a.gameObject.x, a.gameObject.y, 40);

      a.gameObject.setActive(false);
      a.gameObject.setVisible(false);
      a.gameObject.world.remove(a.gameObject.body, true);


    }
    if (b?.gameObject?.name == "bullet")
      console.log("B=Bullet")


    if (!a.gameObject) return;

    if (bodyA?.gameObject)
      var VIPobjA = self.allObjects.find((f) => f.id == bodyA.gameObject.name)

    if (bodyB?.gameObject)
      var VIPobjB = self.allObjects.find((f) => f.id == bodyB.gameObject.name)

    if (VIPobjA) {
      //  console.log("ACTIONS? ", VIPobjA, a, b)
      if (VIPobjA?.physics?.collisions) {
        VIPobjA.physics.collisions.forEach(function (collision) {
          collision.onCollision.forEach(function (action) {
            switch (action.action) {
              case "emitter":
                var rnd = Math.random() * 5;
                if (rnd < 1)
                  self.emitter1.emitParticleAt(a.gameObject.x, a.gameObject.y, 32);
                else if (rnd < 2)
                  self.flame.emitParticleAt(a.gameObject.x, a.gameObject.y, 32);
                else if (rnd < 3)
                  self.smokey.emitParticleAt(a.gameObject.x, a.gameObject.y, 32);
                else
                  self.wisp.emitParticleAt(a.gameObject.x, a.gameObject.y, 32);

                break;
              case "randomColor":
                const r = Math.random() * 255;
                const g = Math.random() * 255;
                const bb = Math.random() * 255;
                const alpha = Math.random();
                const hex: number = colorToNumber(r, g, bb);
                a.gameObject.setAlpha(alpha)
                console.log("kkk: ", hex)
                break;
              case "score":
                var tot = self.countType(VIPobjA.id)
                self.score += action.value;
                self.scoreText.setText(`Score: ` + self.score + " Asteroids: " + tot);
                break;
              case "destroy":
                if (a.gameObject) {
                  a.setActive(false);
                  a.setVisible(false);
                  a.world.remove(a.body, true);
                  a.gameObject.destroy();
                }
                break;
              case 'shrink':
                if (a.gameObject) {
                  a.gameObject.setScale(a.gameObject.scale * action.value)
                }
                break;

            }

          })
        })
      }
    }

    if (VIPobjB) {
      //    console.log("ACTIONS? ", VIPobjB, a, b)
      if (VIPobjB?.physics?.collisions) {
        VIPobjB.physics.collisions.forEach(function (collision) {
          collision.onCollision.forEach(function (action) {
            if (aBullet == true)
              switch (action.action) {
                case "breakInto":   // break into smaller pieces
                  var scale = b.gameObject.scale;
                  if (scale > .05) {
                    for (var n = 0; n < 2; n++) {

                      const ball = self.myScene.matter.add.image(b.gameObject.x, b.gameObject.y - 50, VIPobjB.imageKey)

                      ball.setScale(scale * .5);

                      //  .setCollidesWith(cat1);
                      ball.name = VIPobjB.id;
                      ball.setMass(.01)
                      ball.setDensity(.01)

                      var xv = Math.random() * 4 - 2
                      var yv = Math.random() * 4 - 2
                      ball.setVelocity(xv, yv)



                      ball.setCollisionCategory(self.enemiesCollisionCategory);
                      ball.setFriction(5).setBounce(.9);
                      ball.setFriction(0).setBounce(.9); ball.setFrictionAir(0); ball.setFrictionStatic(0)
                    }
                  }
                  if (b.gameObject) {
                    //  b.setActive(false);
                    //  b.setVisible(false);
                    // b.world.remove(b.body, true);
                    b.gameObject.destroy();
                  }

                  break;

                case "randomColor":
                  const r = Math.random() * 255;
                  const g = Math.random() * 255;
                  const bb = Math.random() * 255;
                  const alpha = Math.random();
                  const hex: number = colorToNumber(r, g, bb);
                  b.gameObject.setAlpha(alpha)
                  console.log("kkk: ", hex)
                  break;
                case "score":
                  var tot = self.countType(VIPobjB.id)
                  self.score += action.value;
                  self.scoreText.setText(`Score: ` + self.score + " Asteroids: " + tot);
                  break;
                case "destroy":
                  if (b.gameObject) {
                    //  b.setActive(false);
                    //  b.setVisible(false);
                    // b.world.remove(b.body, true);
                    b.gameObject.destroy();
                  }
                  break;
                case 'shrink':
                  if (b.gameObject) {
                    b.gameObject.setScale(b.gameObject.scale * action.value)
                  }
                  break;

              }

          })
        })
      }
    }

  }


  updateScene(scene) {
    var self = this;

    if (this.speachbubbles?.length)
      this.speachbubbles.forEach(function (custom) {
        custom.update(12, 44);
      })

    var now = Date.now();

    const timeDelta = now - this.lastUpdated;
    var secElapsed = timeDelta / 1000;
    if (secElapsed < 0) secElapsed = 0;
    this.lastUpdated = now;

    // Track time since last avatar update (start with 1/sec) scene setting.
    const aDelta = now - this.lastAvatarUpdated;
    const bulletTime = now - this.lastBullet;


    var sec = aDelta / 1000;
    if (sec > 1 && this.myAvatar && this.avatar) {
      var dx = Math.abs(this.myAvatar.x - this.avatar.x);
      var dy = Math.abs(this.myAvatar.x - this.avatar.x);
      var angleDelta = this.myAvatar.angle - this.avatar.angle;
      var scaleDelta = this.myAvatar.scale - this.avatar.scale;

      if (dx > 1 || dy > 1 || angleDelta || scaleDelta) {
        // if (now - this.lastUpdateFromOthers < 10000) {
        this.myAvatar.x = this.avatar.x;
        this.myAvatar.y = this.avatar.y;
        this.myAvatar.scale = this.avatar.scale;
        this.myAvatar.angle = this.avatar.angle;
        this.VIPservice.avatarUpdate(this.sceneId, this.myAvatar)
        this.lastAvatarUpdated = now;
        //   }
      }
    }





    //   if (!this.shape) {
    //     return;
    //   }



    if (this.avatar) {
      if (this.pKey.isDown) {
        if (bulletTime > 100) {  // DEFAULT TO 1 per sec
          this.fireBullet()
          this.lastBullet = now;
        }
      }


      if (this.aKey.isDown) {
        this.avatar.setAngularVelocity(-this.sceneSettings.avatarSettings.turnThrust);

      }
      else if (this.dKey.isDown) {
        this.avatar.setAngularVelocity(this.sceneSettings.avatarSettings.turnThrust);
      }
      else {
        this.avatar.setAngularVelocity(0);
      }

      if (this.wKey.isDown) {
        this.avatar.thrust(this.sceneSettings.avatarSettings.forwardThrust);
      }
      else if (this.sKey.isDown) {
        this.avatar.setVelocity(0, 0)

      }

    }


    /*
        if (scene.input.keyboard.checkDown(this.cursors.left, 100)) {
          this.shape.x -= (this.cursors.left.shiftKey) ? 10 : 1;
        }
        else if (scene.input.keyboard.checkDown(this.cursors.right, 100)) {
          this.shape.x += (this.cursors.right.shiftKey) ? 10 : 1;
        }
    
        if (scene.input.keyboard.checkDown(this.cursors.up, 100)) {
          this.shape.y -= (this.cursors.up.shiftKey) ? 10 : 1;
        }
        else if (scene.input.keyboard.checkDown(this.cursors.down, 100)) {
          this.shape.y += (this.cursors.down.shiftKey) ? 10 : 1;
        }
    */

    //   if (this.lastTool != this.tool) {
    //     this.lastTool = this.tool;
    //     console.log("NEW TOOL: ", this.tool);

    // }
    //   scene.matter.collide(this.blocks);
  }
  lastTool: any;

  preloadScene(scene) {
    this.global.allImageKeys = [];
    scene.load.html('reviews', 'assets/reviews.html');

    console.log('preload method');
    // scene.matter.world.setBounds(0, 0, this.sceneSettings.width, this.sceneSettings.height);

    scene.load.audioSprite('sfx', 'assets/audio/SoundEffects/fx_mixdown.json', [
      'assets/audio/SoundEffects/fx_mixdown.ogg',
      'assets/audio/SoundEffects/fx_mixdown.mp3'
    ]);


    scene.load.image('gems', 'assets/NapkinLogo4.png');
    //   scene.load.image('ground', 'assets/platform.png');

    this.VIPservice.preLoad(this.sceneId, scene, this.loader, this)
    scene.load.video("space", "assets/579928232.mp4", true);
    scene.load.image('ball', 'assets/pangball.png');
    this.global.allImageKeys.push('ball')
    scene.load.image('shinyball', 'assets/shinyball.png');
    this.global.allImageKeys.push('shinyball')
    scene.load.image('bullet-sm', 'assets/bullet-sm.png');
    //  this.global.allImageKeys.push('bullet-sm')  // for now ... Internal/emitter only 
    scene.load.image('snow', 'assets/snow.png');
    this.global.allImageKeys.push('snow')
    scene.load.image('Jade', 'assets/Jade.png');
    this.global.allImageKeys.push('Jade')



    scene.load.image('ship', 'assets/sprites/thrust_ship.png');
    this.global.allImageKeys.push('ship')

    scene.load.spritesheet('asteroid-large', 'assets/sprites/asteroid-large-1.png', { frameWidth: 64, frameHeight: 64 });
    this.global.allImageKeys.push('asteroid-large')
    scene.load.spritesheet('asteroid-med', 'assets/sprites/asteroid-med-1.png', { frameWidth: 32, frameHeight: 32 });
    this.global.allImageKeys.push('asteroid-large')

    scene.load.spritesheet('explosion', 'assets/sprites/explosion.png', { frameWidth: 64, frameHeight: 64 });
    this.global.allImageKeys.push('explosion')

    scene.load.spritesheet('daco', 'assets/sprites/daco.png', { frameWidth: 192, frameHeight: 180 });
    this.global.allImageKeys.push('daco')

    scene.load.atlas('flares', 'assets/particles/flares.png', 'assets/particles/flares.json');


  }

  loader(scene, data: VIPObject, url, self) {
    console.log("PRELOAD THE IMAGE ", data, url)

    if (data.type == 'image') {
      scene.load.crossOrigin = 'anonymous';
      scene.load.imageLoadType = "HTMLImageElement";
      var result = scene.load.image(data.imageKey, url)
      scene.load.start()
      scene.load.on('complete', () => {
        self.global.allImageKeys.push(data.imageKey)
        scene.registry.set('newMedia', true);
      });



      console.log("Image load result: ", result)
    }
    if (data.type == 'video') {
      scene.load.crossOrigin = 'anonymous';
      scene.load.imageLoadType = "HTMLImageElement";
      var result = scene.load.video(data.imageKey, url, false, true);
      scene.load.start()
      self.global.allImageKeys.push(data.imageKey)
      console.log("Video load result: ", result)
    }


  }

  objectSameData(obj1: VIPObject, obj2: VIPObject) {
    if (obj1 != obj2) return false
    if (obj1.x == obj2.x && obj1.y == obj2.y) {
      if (obj1?.scale == obj2?.scale)
        return true;
      else
        return false;
    }

    return false;
  }

  updateNameLayer(scene, data) {
    var self = this;
    if (!self.sceneSettings.showNames) return;

    var objects = self.nameLayer.getChildren();

    var obj = objects.find((f) => f.name == data.id)

    if (obj) { obj.destroy() }


    const name = scene.add.text(data.x, data.y, data.editedBy, { fontFamily: 'Arial Black', fontSize: 10, color: '#f5ff00' });
    name.setBackgroundColor('#002200')
    name.name = data.id;
    self.nameLayer.add([name])
  }

  newNameLayer(scene) {
    var self = this;
    if (!self.sceneSettings.showNames) {
      self.nameLayer.removeAll();
      return;
    }

    self.nameLayer.removeAll();

    self.allObjects.forEach(function (data) {
      const name = scene.add.text(data.x, data.y, data.editedBy, { fontFamily: 'Arial Black', fontSize: 10, color: '#f5ff00' });
      name.setBackgroundColor('#002200')
      name.name = data.id;
      self.nameLayer.add([name])
    })

  }


  getAllImageKeys() {
    var self = this;
    return self.global.allImageKeys;
  }

  updateGroups(groups: string[]) {
    var self = this;

    self.allObjects.forEach(function (item) {
      if (item.selected) {
        item.groups = groups;
        self.VIPservice.updateVIP(self.sceneId, item)
      }
    })
  }

  enablePhysics(newState: boolean) {
    var self = this;
    // self.VIPservice.updatingNow = true;

    self.allObjects.forEach(function (item) {
      if (item.selected) {
        item.physicsEnabled = newState;
        console.log("Enabling physics ", item)

        self.VIPservice.updateVIP(self.sceneId, item)

        /*
                let allSprites = self.myScene.children.list.forEach(function (obj: any) {
        
                  if (obj.name == item.id) {
                    console.log("SET PHYSICS ", obj.body)
        
                    if (newState) {
                      obj.body.gameObject.setStatic(false);
                      obj.body.gameObject.setIgnoreGravity(false)
                    //  obj.setFillStyle(0x994455, .8)
                      obj.body.gameObject.setBounce(true)
                      obj.body.gameObject.setAwake(true)
                    }
                    else {
        
                      obj.body.gameObject.setStatic(true);
                      obj.body.gameObject.setIgnoreGravity(true)
                    
                      obj.body.gameObject.setBounce(false)
                      obj.body.gameObject.setAwake(false)
        
                    }
                  }
                })
                */

      }
    })


  }

  lastUpdateFromOthers;

  updateAvatar(scene, data: VIPObject, self) {
    if (!self.sceneSettings.avatars) return;

    if (data.sid == self.global.authuser.sid) {
      //  var gameObj = scene.children.list.find((f) => f.name == data.id)
      //  if (gameObj) return;
      console.log("Skip me")
      return;
    } // It's ME!

    self.lastUpdateFromOthers = Date.now()

    // CHECK IF EXISTS
    var item = self.allObjects.find((f) => f.name == data.id)

    if (item) {
      item.setPosition(data.x, data.y);
      if (data.angle)
        item.setAngle(data.angle);
      // if (data.scale)
      //   avatar.setScale(data.scale);

    }
    else {
      // AVATAR NOT IN SCENE SO ADD IT
      var avatar = scene.matter.add.image(data.x, data.y, data.imageKey, null);
      if (data.angle)
        avatar.setAngle(data.angle);
      if (data.scale)
        avatar.setScale(data.scale);

      avatar.name = data.id;
      avatar.setFrictionAir(0.02);
      avatar.setFixedRotation();
      avatar.setIgnoreGravity(true)
      avatar.setDepth(100000)
      if (avatar.sid == self.global.authuser.sid) {
        console.log("<NEW ME>", data.sid)
        //  avatar.setCollisionGroup(self.shipCollisionCategory);
      }
      else {
        console.log("<NEW ENEMY>", data.sid)
        //   avatar.setCollisionCategory(self.enemiesCollisionCategory);
      }

      self.allObjects.push(avatar)

    }

  }

  settingsCallback(data: VIPScene, self) {
    self.sceneScene = data;
    self.sceneSettings = data.settings;

    console.log("SETTINGS: ", data, self.config, self.sceneScene.settings.avatars)

    if (data.settings.allowAppEditing) {
      self.allowEditing = true;

    }
    else if (typeof (self.appName) != 'undefined') {
      self.allowEditing = false;
    }
    else self.allowEditing = true;

    if (typeof (data.settings?.width) == 'string' && data.settings?.width.includes('%')) {
      self.config.scale.width = window.innerWidth;
      self.sceneWidth = window.innerWidth;

    }
    else if (data.settings.width) {
      self.config.scale.width = data.settings.width;
      self.sceneWidth = data.settings.width;
    }
    else {
      self.config.scale.width = 900;
      self.sceneWidth = 900;
    }

    if (typeof (data.settings?.height) == 'string' && data.settings?.height.includes('%')) {
      self.config.scale.height = window.innerHeight;
    }
    else if (data.settings.height) {
      self.config.scale.height = data.settings.height;
    }
    else self.config.scale.height = 600;

    if (data.settings.worldWidth && self.allowEditing) {
      self.config.input = {
        mouse: { preventDefaultWheel: true }, touch: { capture: true },
      }
    }

    if (typeof (data.settings.backgroundColor) == 'number') self.config.backgroundColor = data.settings.backgroundColor;

    if (data.settings.gravity) {
      self.config.physics.matter.gravity = data.settings.gravity
    }
    if (typeof (data.settings.clear) == 'boolean')
      self.config.clearBeforeRender = data.settings.clear;

    /*
        showNames?: boolean;
        score?: boolean;
    
    */
    if (self.phaserGame) {
      console.log("Destroy old game")

      self.allObjects = [];

      let allSprites = self.myScene.children.list.filter(x => x instanceof Phaser.GameObjects.GameObject);
      console.log("Destroy all objects ", allSprites.length)
      allSprites.forEach(x => x.destroy());
      self.phaserGame.destroy(true);
    }

    self.phaserGame = new Phaser.Game(self.config);



    // this.setVIP.emit(this)

    /*
    if (data.gravity) {
      scene.matter.world.setGravity(data.gravity.x, data.gravity.y)
    }
    if (data.width) {
      scene.matter.world.setSize (data.widtaddObjecth,data.height)
    }
    */
    // if (data.backgroundColor)
    // self.phaserGame. .scene2D.setBackgroundColor(data.backgroundColor)

  }
  countType(type) {
    var self = this;
    var counter = 0;

    self.myScene.children.list.forEach(function (item) {
      if (item.name == type) counter++;
    })
    return counter;
  }



  addObject(scene, data: VIPObject, self, command?: string) {
    //  console.log("adding.. ", data.type, data, scene)
    /*
        if (command == 'Destroy All') {
          self.allObjects = [];
    
          let allSprites = scene.children.list.filter(x => x instanceof Phaser.GameObjects.GameObject);
          console.log("Destroy all objects ", scene, allSprites.length)
          allSprites.forEach(x => x.destroy());
    
          return;
        }
    */
    if (!data) return;

    if (!self.allObjects) self.allObjects = [];

    if (data?.height == 0) data.height = 4;
    if (data?.width == 0) data.width = 4;

    if (data.xAlign) {
      //Adjust the X to this directive
      if (data.xAlign == 'center') {
        if (self.sceneSettings.worldWidth)
          data.x = self.sceneSettings.worldWidth / 2;
        else
          data.x = self.sceneWidth / 2
      }
      console.log("Adjusted X; ", data.x)
    }

    if (self.sceneSettings?.showNames == true)
      self.updateNameLayer(scene, data);

    // Check if exists
    const index = self.allObjects.findIndex(function (post) {
      if (post.id == data.id)
        return true;
    });
    var updatedItem: boolean;
    updatedItem = false;

    if (index != -1 && !self.objectSameData(self.allObjects[index], data)) {
      console.log("Updating Object: New, Old ", data, self.allObjects[index])
      const id = self.allObjects[index].id

      let allSprites = scene.children.list.forEach(function (item) {
        if (item.name == id) {
          // item.destroy();
          console.log("sprite: ", item)

          if (!data.fill) {
            // item.setFillStyle(0x237856, 1)
            //     rect.setStroke(1, 0x00);
          }
          else if (data.fill && data.fill == true) {
            if (item.type.toLowerCase() == 'text') {
              item.setColor('#' + data.fillColor.toString(16))
            }
            //  else
            //  item.setFillStyle(data.fillColor, 1)
          }
          item.setPosition(data.x, data.y)

          updatedItem = true;

        }
      })
    }
    if (updatedItem) return;

    data.selected = false; // Reset selection for new

    if (index == -1) {
      // console.log("Adding object", data.type, data, command, scene)
      var x = data.x, y = data.y;
      if (data.type == "Line") {
        var shape = scene.add.line(data.x, data.y, data.x1, data.y1, data.x2, data.y2, self.color.color);
        shape.setOrigin(0, 0);
        shape.setDisplayOrigin(0.5, 0.5)
        var b = scene.matter.add.gameObject(shape)
        self.allObjects.push(data);
      }
      if (data.type == 'Text') {
        self.addText(scene, data)
      }

      if (data.type == 'avatar' && !self.avatar && self.sceneSettings.avatars) {  // AVATAR NOT IN SCENE SO ADD IT
        self.myAvatar = data;
        self.avatar = scene.matter.add.image(data.x, data.y, data.imageKey, null);
        self.avatar.name = data.id;
        if (data.scale)
          self.avatar.setScale(data.scale)
        self.avatar.setFrictionAir(0.02);
        self.avatar.setFixedRotation();
        self.avatar.setIgnoreGravity(true)
        scene.cameras.main.startFollow(self.avatar, true);
        console.log("Added avatar")
        self.VIPservice.addAvatar(self.sceneId, data)
        if (!updatedItem)
          self.allObjects.push(data);
      }


      if (data.type == "Arc") {
        self.addArc(scene, data)
      }
      if (data.type == 'ObjectCreator') {
        if (!data.delay) data.delay = 1000; // default 1/sec
        if (!data.repeat) data.repeat = 100; // default 100

        var event = scene.time.addEvent({
          delay: data.delay,
          callback: function () {
            var x, y, x1, y1 = 0;
            var x2, y2;

            if (data.x)
              x = data.x;
            if (data.y)
              y = data.y;

            if (data.randomXrange) {
              x1 = data.randomXrange.x1;
              if (typeof (data.randomXrange.x2) == 'string') {
                if (data.randomXrange.x2 == '100%')
                  x2 = self.sceneSettings.width;
                if (x2 == '100%')
                  x2 = window.innerWidth;
                console.log("Xsw2", x2)
              }
              else if (typeof (data.randomXrange.x2) == 'number')
                x2 = data.randomXrange.x2;
              else x2 = 900;


              x = Phaser.Math.Between(x1, x2);
            }
            if (data.randomYrange) {
              y1 = data.randomYrange.y1;
              y2 = data.randomYrange.y2;
              y = Phaser.Math.Between(y1, y2)
            }
            if (data.objectCreator) {
              var str = ""
              var ball;

              if (data.objectCreator?.source == 'reviews') {
                var qData = self.getNextReview()

                if (!qData.quote?.length) {
                  self.emitter1.emitParticleAt(x, 100, 500);
                  self.emitter1.emitParticleAt(x - 100, 120, 500);
                  self.emitter1.emitParticleAt(x + 100, 80, 500);
                }

                if (data.objectCreator.type == 'speachBubble' && qData?.quote?.length) {
                  if (self.speachbubbles) {
                    var index = self.speachbubbles.findIndex(item => !item.active);
                  }

                  console.log("r ", self.nextReview, index, self.speachbubbles)
                  if (index != -1) {
                    var newBubble = scene.add.speachBubble(x, y, qData.quote, qData.quoteAuthor, data.fontSize);
                    self.speachbubbles[index] = newBubble;
                  }
                  else {
                    var newBubble = scene.add.speachBubble(x, y, qData.quote, qData.quoteAuthor, data.fontSize);
                    self.speachbubbles.push(newBubble);
                  }
                  if (qData.when == 'new') {
                    self.emitter1.emitParticleAt(x + 150, 200, 1500);

                  }


                  if (data.tweens) {
                    console.log("Adding tween: ",data.tweens)
                    self.addTween(scene, data, newBubble)
                  }

                }

                else if (qData?.quote?.length) {
                  ball = scene.add.text(x, y, qData.quote, { font: '12px Courier', fill: '#ffff34' }).setShadow(1, 1).setOrigin(0.5);

                  if (data?.width && data?.height) {
                    ball.setDisplaySize(data.width, data.height)
                    ball.setSize(data.width, data.height)
                  }
                  //  .setCollidesWith(cat1);
                  ball.name = data.id;
                  //     ball.setCircle();
                  //     ball.setMass(.01)
                  //    ball.setDensity(.01)
                  if (data.impulse) {
                    //     ball.setVelocity(data.impulse.xv, data.impulse.yv)

                  }
                  //     ball.setCollisionCategory(self.enemiesCollisionCategory);
                  //      ball.setFriction(5).setBounce(.9);

                  //      ball.setFriction(0).setBounce(.9); ball.setFrictionAir(0); ball.setFrictionStatic(0)

                  if (data.tweens) {
                    self.addTween(scene, data, ball)
                  }
                  if (data.events) {
                    self.addEvent(scene, data, ball)
                  }
                }
              }
            }
            else {

              const ball = scene.matter.add.image(x, y, data.imageKey)
              if (data?.width && data?.height) {
                ball.setDisplaySize(data.width, data.height)
                ball.setSize(data.width, data.height)
              }
              //  .setCollidesWith(cat1);
              ball.name = data.id;
              ball.setCircle();
              ball.setMass(.01)
              //   if (self.sceneSettings?.worldWidth)
              //  ball.setBounds(self.sceneSettings.worldWidth, self.sceneSettings.worldHeight)
              ball.setDensity(.01)
              if (data.impulse) {
                ball.setVelocity(data.impulse.xv, data.impulse.yv)

              }
              ball.setCollisionCategory(self.enemiesCollisionCategory);
              ball.setFriction(5).setBounce(.9);

              ball.setFriction(0).setBounce(.9); ball.setFrictionAir(0); ball.setFrictionStatic(0)
              //  ball.setMass(10)
              // ball.setDensity(10)

            }

          },
          callbackScope: this,
          repeat: data.repeat
        });
        if (!self.timerEvents) self.timerEvents = []
        self.timerEvents.push(event)


        self.allObjects.push(data)
      }

      if (data.type == "Rectangle") {
        var rect = scene.add.rectangle(data.x, data.y, data.width, data.height, self.color.color);
        rect.name = data.id
        if (!data.fill) {
          rect.setFillStyle();
          //  rect.setStroke(1, 0x00);
        }
        else if (data.fill && data.fill == true) {
          rect.setFillStyle(data.fillColor, 1)
          // rect.setStroke(1, 0x00);
        }

        rect.setInteractive({ draggable: true });
        rect.setOrigin(0, 0);
        rect.setDisplayOrigin(0, 0);

        if (data?.width && data?.height) {
          rect.setDisplaySize(data.width, data.height)
          rect.setSize(data.width, data.height)
        }



        // rect.centerX = data.x + data.width / 2;
        // rect.centerY = data.y + data.height / 2;

        // IF PHYSICS!!
        if (data.physicsEnabled) {

          var b = scene.matter.add.gameObject(rect)
          //   rect.setOrigin(0, 0);
          //   rect.setDisplayOrigin(0, 0);

          //   rect.centerX = data.x + data.width / 2;
          //   rect.centerY = data.y + data.height / 2;
          self.myScene.matter.alignBody(b, data.x, data.y, Phaser.Display.Align.TOP_LEFT);
          b.setInteractive({ draggable: true });
          b.setFriction(0.05);
          rect.setBounce(1);
          if (data.static)
            b.setStatic(data.static)
          else
            b.setStatic(false);
          //    b.setIgnoreGravity(true)
          //  b.setMass(data.width * data.height)
          b.setMass(data.width * data.height / 10)
          b.setDensity(data.width * data.height / 10)
          b.setActive(true);
          b.setVisible(true);

          self.setCollisions(b, data, self)

        }

        self.allObjects.push(data);
      }

      if (data.type == 'video') {
        const video = scene.add.video(data.x, data.y, data.imageKey).setOrigin(0);

        video.on('locked', () => {
          let message = scene.add.text(400, 100, 'Click to play video', { font: '32px Courier', fill: '#00ff00' }).setShadow(1, 1).setOrigin(0.5);
          video.on('unlocked', () => {
            message.destroy();
          });

        });

        /*
                if (data?.width && data?.height) {
                  video.setDisplaySize(data.width, data.height)
                  video.setSize(data.width, data.height)
                }
        */

        if (data.scale >= 0)
          video.setScale(data.scale)
        else
          video.setScale(1)

        video.name = data.id
        video.play(true);

        video.setInteractive({ draggable: true });
        self.allObjects.push(data);
        video.setDepth(100)
        if (data.zOrder)
          video.setDepth(data.zOrder)

        if (data.tweens) {
          self.addTween(scene, data, video)
        }
        if (data.events) {
          self.addEvent(scene, data, video)
        }

      }

      if (data.type == 'image' || data.type == 'sprite') {
        if (data.type == 'sprite') {
          var config = {
            key: data.imageKey,
            frames: data.imageKey,
            frameRate: 16,
            repeat: -1
          };
          if (data.framesPerSec) config.frameRate = data.framesPerSec;
          //  This code should be run from within a Scene:
          //  scene.anims.destroy(config);
          scene.anims.create(config);
        }
        if (!data.physicsEnabled) {
          if (data.type == 'sprite') {
            var img1 = scene.add.sprite(data.x, data.y).play(data.imageKey).setInteractive({ draggable: true })
          }
          else
            var img1 = scene.add.image(data.x, data.y, data.imageKey).setInteractive({ draggable: true });

          if (data?.width && data?.height) {
            img1.setDisplaySize(data.width, data.height)
            img1.setSize(data.width, data.height)
          }
          //   img1.setStatic(true)
          img1.setOrigin(0, 0);
          //   img1.setDisplayOrigin(0, 0);

          // IF (!allowStretch) then use scale and ignore height/width
          if (data?.scale)
            img1.setScale(data.scale)
          //  else
          //     img1.setScale(1)

          if (data?.centerX && data?.centerY) {
            img1.centerX = data.centerX;
            img1.centerY = data.centerY;
          }

          img1.name = data.id

          if (data.zOrder) {
            img1.setDepth(data.zOrder)
          }
          if (data.tweens) {
            self.addTween(scene, data, img1)
          }
          if (data.events) {
            self.addEvent(scene, data, img1)
          }

        }
        else {
          var img = scene.matter.add.sprite(data.x, data.y, data.imageKey).setInteractive({ draggable: true });
          if (data.type == 'sprite') {
            img.play(data.imageKey);
          }

          if (data?.centerX && data?.centerY) {
            img.centerX = data.centerX;
            img.centerY = data.centerY;
          }
          else {
            img.centerX = data.x + data.width / 2;
            img.centerY = data.y + data.height / 2;
          }

          //   img.setOrigin(0,0)
          //  self.myScene.matter.alignBody(img, data.x, data.y, Phaser.Display.Align.TOP_LEFT);

          img.name = data.id
          img.setStatic(false);
          img.setFriction(.1, 0, .5);
          img.setDensity(1)
          if (typeof (data.ignoreGravity) == 'boolean')
            img.setIgnoreGravity(data.ignoreGravity)
          else
            img.setIgnoreGravity(false)
          img.setFixedRotation();

          img.setBounce(.8)
          img.setMass(1);
          //img.setDepth(10)
          if (data.zOrder) {
            img.setDepth(data.zOrder)
          }
          if (data?.scale)
            img.setScale(data.scale)

          if (data?.width && data?.height) {
            img.setDisplaySize(data.width, data.height)
            img.setSize(data.width, data.height)
          }



          if (data.rotate) {
            img.setStatic(false);
            img.setIgnoreGravity(true)
            img.setFixedRotation();
            img.setAngularVelocity(data.rotate)
          }

          if (data.tweens) {
            self.addTween(scene, data, img)
          }
          if (data.events) {
            self.addEvent(scene, data, img)
          }
          self.setCollisions(img, data, self)
          img.setBounce(.9);
        }
        self.allObjects.push(data);
      }
    }

  }

  addText(scene, data: VIPObject) {
    var self = this;
    var str;

    if (!data.fill) {
      data.fillColor = 0;
    }
    else if (data.fill) {
      str = data.fillColor.toString(16)
      console.log("text color", data.fillColor, str)
    }
    const text1 = scene.add.text(data.x, data.y, data.text, { fontFamily: 'Arial Black', fontSize: 44, color: '#' + str });
    text1.name = data.id

    if (data?.scale)
      text1.setScale(data.scale)

    const matterText2 = scene.matter.add.gameObject(text1)
    matterText2.setInteractive({ draggable: true });
    if (data.physicsEnabled) {
      matterText2.setFriction(0.1, 0, 0)
      matterText2.setMass(10);
      matterText2.setDensity(10);
      matterText2.setStatic(true)
    }
    if (data.tweens) {
      self.addTween(scene, data, text1)
    }
    self.allObjects.push(data);
  }

  addArc(scene, data: VIPObject) {
    var self = this;

    if (data.type == "Arc") {
      if (data.radius < 4) data.radius = 4

      var arc = scene.add.circle(data.x, data.y, 4, self.color.color);
      arc.name = data.id
      arc.setRadius(data.radius);
      var b = scene.matter.add.gameObject(arc).setInteractive({ draggable: true });
      b.setCircle();
      b.setStatic(true);
      if (!data.fill) {
        b.setFillStyle();
      }
      else if (data.fill && data.fill == true) {
        b.setFillStyle(data.fillColor, 1)
        // b.setStroke(1, 0x00);
      }

      if (data.physicsEnabled) {
        b.setStatic(false);
        b.setBounce(1.0);
        b.setMass((data.radius * data.radius) / 10)
        b.setDensity((data.radius * data.radius) / 10)
        b.setIgnoreGravity(false); // **!*!*!*
        b.setFriction(0.1, 0, 0);
        //     b.setFixedRotation();
      }

      self.allObjects.push(data);

    }
  }

  setCollisions(b, data: VIPObject, self: this) {
    if (data.groups) { // Only objects assigned to a group can have collisions
      if (data.groups[0]) {
        const category = self.collisionCategories.find((f) => f.name == data.groups[0])

        if (category) {
          b.setCollisionGroup(category.category);
          console.log("Category", category)
          if (data?.physics?.collisions) {
            data.physics.collisions.forEach(function (item) {
              const category = self.collisionCategories.find((f) => f.name == item.collidesWith)
              if (category) {
                b.setCollidesWith(category.category)  //array here too??
                //  b.setOnCollide(self.onCollisions)
                console.log("ONCOLLIDE", b, data, category)
              }

            })
          }
        }
        else
          console.log("No category ", b, data, data.groups[0])

      }



    }


  }

  onCollisions(collisionData) {
    var self = this;

    const bullet = collisionData.bodyA.gameObject;
    const enemy = collisionData.bodyB.gameObject;
    if (!bullet || !enemy) return;

    console.log("*COLLISION*", bullet, enemy, self.allObjects)
    if (!self.allObjects) return;
    /*
        var VIPobject1 = self.allObjects.find((f) => f.id == bullet.name)
        var VIPobject2 = self.allObjects.find((f) => f.id == enemy.name)
    
        if (VIPobject1.collisions)
          VIPobject1.collisions.forEach(function(collide){
            collide.onCollision.forEach(function(action){
              switch (action.action) {
                case 'destroy':
                  bullet.setActive(false);
                  bullet.setVisible(false);
                  bullet.world.remove(bullet.body, true);
                  break;
                case 'shrink':
                    bullet.setScale(bullet.scale*.95)
                    break;
              }
            })
          
        })
    */


    return false;
    /*
          bullet.setActive(false);
          bullet.setVisible(false);
          bullet.world.remove(bullet.body, true);
    
          enemy.setActive(false);
          enemy.setVisible(false);
          enemy.world.remove(enemy.body, true);
          */
  }

  touchMove(pointer) {

  }

  nextReview: number = 0;
  totalReviews: number = 0;

  getNextReview() {
    var self = this;

    if (!self.global.publishedReviews || (self.global.publishedReviews && self.global.publishedReviews.length == 0)) {
      // NO DATA? Let's get it.
      console.log("getting reviews now")
      self.reviewsService.getReviews();
      return { quote: "" };
    }
    var when = 'recent'

    if (self.global.publishedReviews.length > self.totalReviews) {
      // YEAH - We got a new REVIEW! so let's put it at the top of the leaderboard
      if (self.totalReviews)
        when = 'new'
      self.totalReviews = self.global.publishedReviews.length;
      console.log('Yeah - new review')
      self.nextReview = 0;

    }
    if (self.nextReview < 10)
      when = 'new';

    //if (Date.now() - self.global.publishedReviews[self.nextReview].date)




    // console.log("REV--- ", self.nextReview, self.global.publishedReviews)
    var str = self.global.publishedReviews[self.nextReview].review_text;
    var quoteAuthor = self.global.publishedReviews[self.nextReview].author;
    self.nextReview++;
    if (self.nextReview >= self.global.publishedReviews.length)
      self.nextReview = 0;
    return { quote: str, quoteAuthor: quoteAuthor, when: when };
  }



  addEvent(scene, data: VIPObject, obj: any) {
    var self = this;

    console.log("Add event to object: ", data, obj)
    data.events.forEach(function (event) {
      if (!self.objectEvents) self.objectEvents = [];
      event.objectId = obj.name;
      self.objectEvents.push(event)


    })
  }
  cropRect: Phaser.Geom.Rectangle;

  addTween(scene, data: VIPObject, obj) {
    var self = this;

    data.tweens.forEach(function (tween) {

      if (!obj) return;
      var tw: any = {
        targets: obj,
        duration: tween.duration,
        repeat: tween.repeat,
        hold: tween.hold,
        yoyo: tween.yoyo,
        repeatDelay: 500,
        onComplete: () => {
          console.log('onComplete ', obj);
          if (tween.destroyOnComplete) {
            obj.active = false;
            obj.destroy();
          }

        },


      }
      if (tween.xTarget) {
        tw.x = { value: tween.xTarget.x, ease: tween.xTarget.ease, flipX: tween.xTarget.flip }
      }

      if (tween.yTarget) {
        tw.y = { value: tween.yTarget.y, ease: tween.yTarget.ease, flipY: tween.yTarget.flip }
      }
      if (tween.scaleTarget) {
        tw.scale = { value: tween.scaleTarget.scale, yoyo: tween.scaleTarget.yoyo }
      }
      if (tween.angleTarget) {
        tw.angle = { from: tween.angleTarget.angleFrom, to: tween.angleTarget.angleTo }
      }
      if (tween.cropTarget) {
        self.cropRect = new Phaser.Geom.Rectangle(110, 0, tween.cropTarget.width, tween.cropTarget.height);
        // tw.target += { x: tween.cropTarget.x, y: tween.cropTarget.y, width: tween.cropTarget.width, height: tween.cropTarget.height  }

        // tw.targets=[obj,self.cropRect];
        //     tw.x = 300;
        //     tw.y = 200;

        const videoObj = obj;
        scene.tweens.addCounter({
          from: 0,
          to: 1,
          duration: 6000,
          yoyo: true,
          repeat: -1,
          onUpdate: (tween) => {
            // console.log("tween ", tween)
            const v = tween.getValue();
            const c = 400 * v;
            videoObj.setCrop(c, 0, videoObj.width, videoObj.height)

          }
        });





      }

      console.log("+", tw)
      scene.tweens.add(tw);
    })

  }

  startX; startY;

  drawStart(pointer) {
    if (this.tool != 'Selector') {
      if (this.tool == 'tool-image' || this.tool == 'tool-sprite' || this.tool == 'Text' || this.tool == 'Object Creator')
        this.current = 2;  // FORCE RECT
    }
    else {
      this.current = 8;
      return;
    }
    if (this.current === 0) {
      return;
    }

    this.isDown = true;




    var x = pointer.worldX;
    var y = pointer.worldY;

    // GRID ALIGNMENT
    if (this.sceneSettings.gridSnap) {
      x = Phaser.Math.Snap.To(x, this.sceneSettings.grid);
      y = Phaser.Math.Snap.To(y, this.sceneSettings.grid);
    }

    this.startX = x;
    this.startY = y;


    switch (this.current) {
      case 1:
        this.shape = this.myScene.add.circle(x, y, 4, this.color.color);
        break;

      case 2:
        this.shape = this.myScene.add.rectangle(x, y, 40, 40, 0xff0000);
        console.log("RECT")
        this.shape.setOrigin(0, 0);
        this.shape.setDisplayOrigin(0, 0)

        break;

      case 3:
        this.shape = this.myScene.add.ellipse(x, y, 8, 8, this.color.color);
        break;

      case 4:
        //  this.shape = this.myScene.add.star(pointer.x, pointer.y, 5, 2, 4, this.color.color);
        break;

      case 5:
        this.shape = this.myScene.add.line(pointer.x, pointer.y, 0, 0, 4, 0, this.color.color);
        break;
    }
  }


  drawUpdate(pointer) {
    if (!this.isDown) {
      return;
    }
    var x = pointer.worldX;
    var y = pointer.worldY;

    // GRID ALIGNMENT
    if (this.sceneSettings.gridSnap) {
      x = Phaser.Math.Snap.To(x, this.sceneSettings.grid);
      y = Phaser.Math.Snap.To(y, this.sceneSettings.grid);
    }

    switch (this.current) {
      case 1:
        this.shape.radius = pointer.getDistance();
        break;

      case 2:
        this.shape.setSize((x - this.startX), (y - this.startY));

        console.log("update size")
        break;

      case 3:
        this.shape.setSize((pointer.x - pointer.downX) * 2, (pointer.y - pointer.downY) * 2);
        break;

      case 4:
        //  this.shape.scaleX = pointer.x - pointer.downX;
        //  this.shape.scaleY = pointer.y - pointer.downY;
        break;

      case 5:
        this.shape.setTo(0, 0, pointer.x - pointer.downX, pointer.y - pointer.downY);
        break;
    }
  }

  shapeNames: string[] = ["None", "Arc", "Rectangle", "Ellipse", "Star", "Line"]

  drawStop(pointer) {
    var self = this;

    this.isDown = false;
    var x, y;

    x = pointer.x;
    y = pointer.y;

    if (this.current == 0) return;

    if (typeof (self.currentFill) == 'undefined') self.currentFill = true;
    if (typeof (self.currentColor) == 'undefined') self.currentColor = '0xeefede';

    console.log("World: ", pointer.worldX, pointer.worldY)

    // GRID ALIGNMENT
    if (this.sceneSettings.gridSnap) {
      x = Phaser.Math.Snap.To(x, this.sceneSettings.grid);
      y = Phaser.Math.Snap.To(y, this.sceneSettings.grid);
    }

    //  this.shapes.push(this.shape);
    if (this.tool == 'Selector' || this.tool == 'drag' || this.tool == 'resize') return;

    console.log("Created NEW object: ", this.shape, this.tool, (pointer.x - pointer.downX), (pointer.y - pointer.downY))

    var newVIPObj: VIPObject;

    // ADD TO DOCUMENT
    if (this.shape) {
      newVIPObj = {
        type: this.shapeNames[this.current],
        //  originX: this.shape.originX,
        //  originY: this.shape.originY,

        x: this.shape.x,
        y: this.shape.y,

      }
    }
    else {
      newVIPObj = {
        type: this.tool,
        originX: 0,
        originY: 0,

        x: x,
        y: y,
      }
    }

    if (self.objectPhysicsEnabled)
      newVIPObj.physicsEnabled = true;



    if (this.tool == 'tool-text') {
      newVIPObj.type = 'Text';
      newVIPObj.text = this.text;
      console.log("Save text")

    }
    if (this.tool == 'Object Creator') {
      console.log("Save OBJCRE ", this.vipObject)
      newVIPObj.type = 'ObjectCreator';
      newVIPObj.delay = 2000;
      newVIPObj.repeat = 10;
      newVIPObj.imageKey = this.currentImage;

      newVIPObj.impulse = { xv: this.vipObject.impulse.xv, yv: this.vipObject.impulse.yv };
      if (this.vipObject?.delay)
        newVIPObj.delay = this.vipObject.delay
      if (this.vipObject?.repeat)
        newVIPObj.repeat = this.vipObject.repeat

      newVIPObj.x = this.shape.x;
      newVIPObj.y = this.shape.y;

    }
    else if (this.current == 2 && this.tool != "Image") {///rect
      newVIPObj.width = this.shape.geom.width;
      newVIPObj.height = this.shape.geom.height;
      newVIPObj.x = newVIPObj.x;
      newVIPObj.y = newVIPObj.y;
      newVIPObj.fill = self.currentFill;
      newVIPObj.fillColor = self.currentColor.color;
      newVIPObj.outlineColor = 0xff00ed19;


    }

    if (this.tool == 'tool-image') {
      newVIPObj.type = 'image';
      newVIPObj.imageKey = this.currentImage;
      if (this.currentImage.includes("Video"))
        newVIPObj.type = 'video';


      newVIPObj.width = this.shape.geom.width;
      newVIPObj.height = this.shape.geom.height;
      //  newVIPObj.centerX = this.shape.geom.centerX;
      //  newVIPObj.centerY = this.shape.geom.centerY;
      newVIPObj.x = this.shape.x;
      newVIPObj.y = this.shape.y;
      console.log("Save image ", newVIPObj)

    }
    if (this.tool == 'tool-sprite') {
      newVIPObj.type = 'sprite';
      newVIPObj.imageKey = this.currentImage;
      console.log("Save sprite")
      newVIPObj.physicsEnabled = false;
      newVIPObj.width = this.shape.geom.width;
      newVIPObj.height = this.shape.geom.height;
      //   newVIPObj.centerX = this.shape.geom.centerX;
      //   newVIPObj.centerY = this.shape.geom.centerY;
      newVIPObj.x = newVIPObj.x + newVIPObj.centerX;
      newVIPObj.y = newVIPObj.y + newVIPObj.centerY;
    }

    if (this.current == 5) {//line
      newVIPObj.x1 = this.shape.geom.x1;
      newVIPObj.y1 = this.shape.geom.y1;
      newVIPObj.x2 = this.shape.geom.x2;
      newVIPObj.y2 = this.shape.geom.y2;
    }

    if (this.current == 1) {//Circle/Arc

      //     newVIPObj.diameter = this.shape.geom.diameter;
      newVIPObj.radius = this.shape.geom.radius;
      newVIPObj.fill = self.currentFill;
      newVIPObj.fillColor = self.currentColor.color;

      // newVIPObj.type = this.shape.geom.type;
    }

    this.VIPservice.addVIP(this.sceneId, newVIPObj)

    if (this.shape)
      this.shape.destroy();
    //  this.index++;
  }

  changeColor(pointer, x, y, event) {
    this.swatchData.getPixel(x, y, this.color);

    if (this.shape) {
      this.shape.setFillStyle(this.color.color);
    }

    event.stopPropagation();
  }

  updateColor(pointer, x, y, event) {
    if (!pointer.isDown) {
      return;
    }

    this.swatchData.getPixel(x, y, this.color);

    if (this.shape) {
      this.shape.setFillStyle(this.color.color);
    }

    event.stopPropagation();
  }

  deleteShape() {
    if (this.shape) {
      this.shape.destroy();
      this.shape = null;
    }
  }

  changeShape() {
    if (this.shapes.length < 2) {
      return;
    }

    this.index++;

    if (this.index >= this.shapes.length) {
      this.index = 0;
    }

    this.shape = this.shapes[this.index];
  }

  setSelect() {
    this.current = 8;
    this.shape = null;
    this.tool = 'Selector'
  }

  setResize() {
    this.current = 9;
    this.shape = null;
    this.tool = 'resize'
  }


  setCircle() {
    if (this.isDown) {
      return;
    }

    this.current = 1;
    this.shape = null;
    this.myScene.registry.set('score', this.current);

  }
  setDrag() {
    if (this.isDown) {
      return;
    }
    //  if (!this.pointerIsOverGame) return;

    this.tool = 'Drag'
    this.current = 0;
    this.shape = null;
    this.myScene.registry.set('score', this.current);

  }

  setRectangle() {
    if (this.isDown) {
      return;
    }
    //  if (!this.pointerIsOverGame) return;

    this.tool = "shape"
    this.current = 2;
    this.shape = null;
    this.myScene.registry.set('score', this.current);

  }

  setEllipse() {
    if (this.isDown) {
      return;
    }
    if (!this.pointerIsOverGame) return;

    this.tool = "shape"
    this.current = 3;
    this.shape = null;
  }

  setStar() {
    if (this.isDown) {
      return;
    }
    if (!this.pointerIsOverGame) return;

    this.tool = "shape"
    this.current = 4;
    this.shape = null;
    this.myScene.registry.set('score', this.current);

  }

  setLine() {
    if (this.isDown) {
      return;
    }
    if (!this.pointerIsOverGame) return;
    this.tool = "shape"
    this.current = 5;
    this.shape = null;
    this.myScene.registry.set('score', this.current);

  }

  fieldData(field: Field) {
    var self = this;

    // GOT SOME DATA FROM SOME FORM
    console.log("GOT FIELD DATA: ", field)

    switch (field.title) {
      case 'Grid size':
        if (self.g2) {
          self.g2.cellWidth = parseInt(field.selected[0]);
          self.g2.cellHeight = parseInt(field.selected[0]);
          self.sceneSettings.grid = parseInt(field.selected[0]);
        }

        break;
      case 'Show grid':
        if (self.g2) {
          self.g2.setVisible(field.trueFalse);
        }
        break;
      case 'Grid snap':
        self.sceneSettings.gridSnap = field.trueFalse;
        break;
      case 'Show names':
        self.sceneSettings.showNames = field.trueFalse;
        self.newNameLayer(self.myScene)
        break;
      case 'Avatars':
        self.sceneSettings.avatars = field.trueFalse;
        self.avatarUpdate(field.trueFalse)



        break;
      case 'Gravity':
        if (field.trueFalse) {
          self.sceneSettings.gravity = { x: 0, y: .9 }
          self.myScene.matter.world.setGravity(0, .9)
        }
        else {
          self.myScene.matter.world.setGravity(0, 0)
        }

        break;

      case 'Physics enabled':
        self.objectPhysicsEnabled = field.trueFalse;
        break;
      case 'Hide tools':
        self.hideTools()


        break;


    }


  }

  hideTools() {
    var self = this;
    self.toolsVisible = false;

    self.tool_toolBar.scene.setVisible(false);
    self.tool_imageSelector.scene.setVisible(false);
    self.tool_mainSettings.scene.setVisible(false);
    self.tool_objectSettings.scene.setVisible(false);
    self.tool_palette.scene.setVisible(false);
    self.tool_minimap.scene.setVisible(false);
    self.myScene.cameras.getCamera('mini').setVisible(false)
    //   self.tKey = self.myScene.input.keyboard.addKey('T');  // Get key object
  }
  avatarUpdate(avatarsOn: boolean) {
    var self = this;

    if (!avatarsOn) { // TURN OFF AVATARS
      if (self.avatar) {
        self.avatar.setVisible(false)
        self.myScene.cameras.main.stopFollow();
      }

    }
    else {
      if (self.avatar) {
        self.avatar.setVisible(true)
        self.myScene.cameras.main.startFollow(self.avatar, true);
      }

    }

    /*
          self.avatar = scene.matter.add.image(data.x, data.y, data.imageKey, null);
          self.avatar.name = data.id;
          if (data.scale)
            self.avatar.setScale(data.scale)
          self.avatar.setFrictionAir(0.02);
          self.avatar.setFixedRotation();
          self.avatar.setIgnoreGravity(true)
          scene.cameras.main.startFollow(self.avatar, true);
          console.log("Added avatar")
          self.VIPservice.addAvatar(self.sceneId, data)
          if (!updatedItem)
            self.allObjects.push(data);
        }
    */


  }

  showAllTools() {
    var self = this;
    // self.myScene.input.keyboard.removeKey('T');  // Get key object
    self.tKey = null;
    console.log("Show tools.")

    self.tool_toolBar.scene.setVisible(true);
    self.tool_imageSelector.scene.setVisible(true);
    self.tool_mainSettings.scene.setVisible(true);
    self.tool_objectSettings.scene.setVisible(true);
    self.tool_palette.scene.setVisible(true);
    self.tool_minimap.scene.setVisible(true);
    self.myScene.cameras.getCamera('mini').setVisible(true)
    self.toolsVisible = true;

  }

  setObjectFill(fill, color) {
    var self = this;
    self.currentFill = fill;
    self.currentColor = color;

    this.allObjects.forEach(element => {
      if (element.selected) {
        console.log("Yay! it works ", fill, color.color)
        element.fill = fill;
        element.fillColor = color.color;
        //  if (element.type == 'text')

        self.VIPservice.updateVIP(self.sceneId, element)

      }
    });
  }

  updateTool(parent, key, data) {
    // this.text = (`Score: ${data}`);

    console.log("UPDATING TOOL ", parent, key, data)
    this.tool = data;

    if (key == 'currentImage') {
      this.currentImage = data;
      this.tool = 'tool-image';
      this.current = 2;
      this.shape = null;
    }

    switch (data) {
      case "tool-ellipse":
        this.tool = "shape"
        this.current = 3;
        this.shape = null;

        break;
      case "tool-circle":
        this.tool = "shape"
        this.current = 1;
        this.shape = null;
        break;
      case "tool-rect":
        this.tool = "shape"
        this.current = 2;
        this.shape = null;
        break;
      case "tool-image":
        this.tool = data;
        this.current = 2;
        this.shape = null;
        break;
      case "tool-sprite":
        this.tool = data;
        this.current = 2;
        this.shape = null;
        break;
      case "tool-text":
        this.tool = data;
        this.current = 12;  // FIX ME PLEEEEASE
        this.shape = null;
        break;
      case "tool-selector":
        this.current = 8;
        this.shape = null;
        this.tool = 'Selector'
        break;
      case "tool-movescale":
        this.tool = 'Drag'
        this.current = 0;
        this.shape = null;
        break;

    }

    console.log("GOT TOOL: ", parent, key, data)
  }

}

function getRelativePositionToCanvas(gameObj, camera) {
  return {
    x: (gameObj.x - camera.worldView.x) * camera.zoom,
    y: (gameObj.y - camera.worldView.y) * camera.zoom
  }
}


class ballDrop extends Phaser.Scene {
  text;
  mode;
  constructor() {
    console.log("CREATE BALLxblahx DROP ")
    super({
      key: 'ballDrop', active: true, physics: {
        matter: {
          gravity: { x: 0, y: .9 },
        }
      }
    });
    this.text;


  }

  preload() {
    console.log("CREATE BALLxx DROP ")
    this.load.image('ball', 'assets/pangball.png');
  }

  updateScore(parent, key, data) {
    this.text = (`Score: ${data}`);
    this.mode = data;
    console.log("MODE: ", this.mode)

  }

  create() {
    console.log("CREATE BALL DROP ")


    this.text = this.add.text(100, 100, 'Monitoring Registry');

    this.registry.events.on('changedata', this.updateScore, this);

    const lineCategory = this.matter.world.nextCategory();
    const ballsCategory = this.matter.world.nextCategory();

    const sides = 4;
    const size = 14;
    const distance = size;
    const stiffness = 0.1;
    const lastPosition = new Phaser.Math.Vector2();
    const options = { friction: 0, frictionAir: 0, restitution: 0, ignoreGravity: true, inertia: Infinity, isStatic: true, angle: 0, collisionFilter: { category: lineCategory } };

    let current = null;
    let previous = null;

    const curves = [];
    let curve = null;

    const graphics = this.add.graphics();

    this.input.on('pointerdown', function (pointer) {
      if (this.mode == 4) {

        lastPosition.x = pointer.x;
        lastPosition.y = pointer.y;

        previous = this.matter.add.polygon(pointer.x, pointer.y, sides, size, options);

        curve = new Phaser.Curves.Spline([pointer.x, pointer.y]);

        curves.push(curve);
      }

    }, this);

    this.input.on('pointerup', function (pointer) {

      console.log("Done draw: ", curves);

    }, this);

    this.input.on('pointermove', function (pointer) {
      if (this.mode == 4) {
        if (pointer.isDown) {
          const x = pointer.x;
          const y = pointer.y;

          if (Phaser.Math.Distance.Between(x, y, lastPosition.x, lastPosition.y) > distance) {
            options.angle = Phaser.Math.Angle.Between(x, y, lastPosition.x, lastPosition.y);

            lastPosition.x = x;
            lastPosition.y = y;

            current = this.matter.add.polygon(pointer.x, pointer.y, sides, size, options);

            this.matter.add.constraint(previous, current, distance, stiffness);

            previous = current;

            curve.addPoint(x, y);

            graphics.clear();
            graphics.lineStyle(size * 1.5, 0xffffff);

            curves.forEach(c => {
              c.draw(graphics, 64);
            });
          }
        }
      }

    }, this);

    this.input.once('pointerup', function (pointer) {
      this.time.addEvent({
        delay: 200,
        callback: function () {
          const ball = this.matter.add.image(Phaser.Math.Between(100, 1500), Phaser.Math.Between(0, 0), 'ball')
          //  .setCollidesWith(cat1);

          ball.setCircle();
          ball.setCollisionCategory(ballsCategory);
          ball.setFriction(0.005).setBounce(.9);

        },
        callbackScope: this,
        repeat: 1500
      });

    }, this);
  }
}

class SceneMain extends Phaser.Scene {
  points = [];
  stars;


  maxDepth = 32;
  constructor() {
    super({ key: "SceneMain" });
  }

  create() {
    //  return; // FIX TO ALLOW ADDING THIS SCENE FROM AN OBJECT
    this.points = [];
    this.stars = this.add.group();
    this.maxDepth = 32;

    for (var i = 0; i < 1512; i++) {
      this.points.push({
        x: Phaser.Math.Between(-25, 25),
        y: Phaser.Math.Between(-25, 25),
        z: Phaser.Math.Between(1, this.maxDepth)
      });
    }
  }

  update() {
    //   return;
    this.stars.clear(true, true);
    for (var i = 0; i < this.points.length; i++) {
      var point = this.points[i];

      point.z -= 0.25;

      if (point.z <= 0) {
        point.x = Phaser.Math.Between(-25, 25);
        point.y = Phaser.Math.Between(-25, 25);
        point.z = this.maxDepth;
      }

      var px = point.x * (128 / point.z) + (1600 * 0.5);
      var py = point.y * (128 / point.z) + (900 * 0.5);

      var circle = new Phaser.Geom.Circle(
        px,
        py,
        (1 - point.z / 32) * 2
      );

      var graphics = this.add.graphics({ fillStyle: { color: 0xffffff } });
      graphics.setAlpha((1 - point.z / 32));
      graphics.fillCircleShape(circle);
      this.stars.add(graphics);
    }
  }
}

class toolBar extends Phaser.Scene {
  text;
  mode;
  self;
  draggingMe = false;

  constructor() {
    console.log("CREATE Tools ")
    super({
      key: 'toolBar', active: true
    });
    this.text;
  }

  preload() {

    this.load.image('tool-selector', 'assets/tool-selector.png');
    this.load.image('tool-movescale', 'assets/tool-movescale.png');
    this.load.image('tool-rect', 'assets/tool-rect.png');
    this.load.image('tool-circle', 'assets/tool-circle.png');
    this.load.image('tool-path', 'assets/tool-path.png');
    this.load.image('tool-freedraw', 'assets/tool-freedraw.png');
    this.load.image('tool-text', 'assets/tool-text.png');
    this.load.image('tool-textbox', 'assets/tool-textbox.png');
    this.load.image('tool-image', 'assets/tool-image.png');
    this.load.image('tool-sprite', 'assets/tool-sprite.png');

  }


  updateMyTool(parent, key, data) {
    // this.text = (`Score: ${data}`);
    //this.tool = data;
    console.log("UPDATETOOL: ", parent, key, data)

    // parent.registry.set('newTool', data);

  }

  myTools = ["tool-selector", "tool-movescale", "tool-rect", "tool-circle",
    "tool-path", "tool-freedraw", "tool-text", "tool-textbox",
    "tool-image", "tool-sprite"]

  lastTool;

  create() {
    var self = this;
    this.lastTool = null;

    //  this.registry.events.on('changedata', this.updateMyTool, this);
    var x, y;
    x = 40;
    y = 200;

    const dragMe = self.add.rectangle(x, y, 128, 20, 0x34def2, .75).setInteractive({ draggable: true });
    dragMe.setDisplayOrigin(0, 0)
    dragMe.setOrigin(0, 0)
    y += 20;

    const container = this.add.container(x, y);

    dragMe.on('dragstart', (pointer, dragX, dragY) => {
      this.draggingMe = true;
    });
    dragMe.on('dragend', (pointer, dragX, dragY) => {
      this.draggingMe = false;
    });

    dragMe.on('drag', (pointer, dragX, dragY) => {
      container.setPosition(dragX, dragY + 20);
      dragMe.setPosition(dragX, dragY);
    });

    x = 0; y = 0;


    this.input.on('pointerover', (event, gameObjects) => {
      console.log("Hit me")
      container.setScale(1);
      dragMe.setScale(1);
      container.setY(dragMe.y + dragMe.height)
      this.scene.bringToTop();
    });

    this.input.on('pointerout', (event, gameObjects) => {
      if (!this.draggingMe) {
        container.setScale(.25);
        dragMe.setScale(.25);
        container.setY(dragMe.y + (dragMe.height * .25))
      }
    });


    this.myTools.forEach(function (tool, index) {
      const bob = self.add.image(x, y, tool).setInteractive();
      bob.setDisplayOrigin(0, 0)
      bob.setAlpha(1)
      bob.setOrigin(0, 0)
      bob.width = 64;
      bob.height = 64;
      bob.scale = .5
      bob.name = tool;
      container.add(bob);

      bob.on('pointerup', (pointer, x, y, event) => {
        event.stopPropagation();
        console.log("EVE ", pointer, x, y, event, bob.name)
        if (self.lastTool) {
          self.lastTool.setScale(.5);
          //      self.lastTool.setFrame('toggle-off');
        }
        if (self.lastTool === bob) {
          self.lastTool = null;
        }
        else {
          self.lastTool = bob;
          //   bob.setFrame('toggle-on');
          bob.setScale(.55);
          self.registry.set('newTool', self.lastTool.name);
        }
      })

      if (index % 2 == 0)
        x += 64;
      else {
        x -= 64;
        y += 64;
      }

    })

  }


}



class imageSelector extends Phaser.Scene {
  text;
  mode;
  self;
  masterSelf;
  keys;
  draggingMe = false;
  container;
  dragMe;

  constructor() {
    super({
      key: 'imageSelector', active: true
    });
    this.text;
  }

  preload() {


  }

  updateCurrentImage(parent, key, data) {
    var self = this;

    const newMedia = parent.registry.get('newMedia')

    if (newMedia) {
      console.log("UPDATECURRENTIMAGE: ", newMedia, self.keys.length,
        self.masterSelf.global.allImageKeys.length, parent, key, data)
      parent.registry.set('newMedia', false);
      self.createMenu(self.masterSelf, self);
    }

  }

  create() {
    var self = this;
    const sceneB = <any>self.scene.get('VIP');

    this.masterSelf = sceneB.registry.get('masterSelf');
    this.masterSelf.myScene.registry.events.on('changedata', this.updateCurrentImage, this);

    console.log("sceneb ", self.scene, sceneB, this.masterSelf)

    this.createMenu(this.masterSelf, self)

  }

  createMenu(masterSelf, self) {
    var x, y;

    // Remove the old.
    self.children.list.forEach(function (obj: any) {
      obj.destroy();
    })
    if (self.dragMe)
      self.dragMe.destroy();
    if (self.container)
      self.container.destroy();

    x = 44 + 128;
    y = 200;

    self.dragMe = self.add.rectangle(x, y, 128, 20, 0x34def2, .75).setInteractive({ draggable: true });
    self.dragMe.setDisplayOrigin(0, 0)
    self.dragMe.setOrigin(0, 0)
    y += 20;

    self.container = self.add.container(x, y);
    self.dragMe.on('dragstart', (pointer, dragX, dragY) => {
      self.draggingMe = true;
    });
    self.dragMe.on('dragend', (pointer, dragX, dragY) => {
      self.draggingMe = false;
    });

    self.dragMe.on('drag', (pointer, dragX, dragY) => {
      self.container.setPosition(dragX, dragY + 20);
      self.dragMe.setPosition(dragX, dragY);
    });

    self.input.on('pointerover', (event, gameObjects) => {
      console.log("Hit me")
      self.container.setScale(1);
      self.dragMe.setScale(1);
      self.container.setY(self.dragMe.y + self.dragMe.height)
      self.scene.bringToTop();
    });

    self.input.on('pointerout', (event, gameObjects) => {
      if (!this.draggingMe) {
        self.container.setScale(.25);
        self.dragMe.setScale(.25);
        self.container.setY(self.dragMe.y + (self.dragMe.height * .25))
      }
    });




    x = 0; y = 0;

    self.keys = masterSelf.getAllImageKeys();
    console.log("KEYS ", self.keys)

    self.keys.forEach(function (tool, index) {
      const bob = self.add.image(x, y, tool).setInteractive();
      bob.setOrigin(0, 0)
      bob.setDisplaySize(32, 32)
      bob.name = tool;
      self.container.add(bob);

      bob.on('pointerup', (pointer, x, y, event) => {
        event.stopPropagation();
        console.log("Image SETTETETETETE ", bob.name)
        self.registry.set('currentImage', bob.name);
      })


      //  console.log("Index ", x, y, index)
      if (index && ((index + 1) % 4 == 0)) {
        x = 0;
        y += 32;
      }
      else
        x += 32;

    })
  }


}



function componentToHex(c) {
  let hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}
function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}
function colorToNumber(r, g, b) {
  return (r << 16) + (g << 8) + (b);
}
function degrees_to_radians(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}