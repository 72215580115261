import { Component, HostListener, AfterViewChecked, ɵɵNgOnChangesFeature, ChangeDetectionStrategy, ViewChild, ElementRef, Input, Output, OnInit, EventEmitter, AfterViewInit, AfterContentChecked, OnChanges } from '@angular/core';
import { formatCurrency, formatNumber } from '@angular/common';
import { Field, Calendar, ButtonStyle, Theme, CodeObject, Palette } from '../company-interface'
import { User, Contact, Media } from '../user-interface';
import firebase from 'firebase/compat/app';
import { Globals } from 'src/app/globals';
import { skip } from 'node:test';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { Logger } from 'src/app/functions';
import { LogicService } from '../logic/logic.component';
import { SpriteService } from '../sprites.service'
import { MediaService } from "../media/media-functions/media-functions"
import { EmailService } from "../email.service"
import { RegisterService } from '../register.service';
import { VIPService } from '../VIP.service';
import { FormService } from '../form.service';
import { CurrencyPipe } from '@angular/common';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Timestamp } from 'firebase/firestore';
import * as math from 'mathjs';
import { CalendarModule } from 'primeng/calendar';
import { CartService } from '../menu/cart.service';
import { OrderTicket } from '../order-interface';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { set, getTime, startOfDay, format, setDate, setYear, getYear, addMonths, subMonths, getMonth, getDaysInMonth, startOfMonth, getDay, endOfDay, getMilliseconds } from "date-fns";


@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./field.component.css', '../../common.scss']
})

export class FieldComponent implements OnInit, AfterViewChecked {
  @Input() appName: string;
  @Input() field: Field;
  @Input() themeId: string;
  @Input() showSubmit: boolean = true;
  @Input() editMode: boolean = false;
  @Output() outputs = new EventEmitter<any>();
  @Input() dayofweek: string;
  @Input() binding: Field;
  @Output() objectEvents = new EventEmitter<any>();

  /*
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    var self = this;

  //  if(event.keyCode == KEY_CODE.DOWN_ARROW){
      // Your row selection code
   //   if (self.field.type == 'cell' && self.field.fieldEditing) {
     //   self.field.binding += event.key;
    //  }

   //   console.log(event);
  //  }
  //  event.stopImmediatePropagation();
  }
*/

  @ViewChild('myCanvas', { static: false }) myCanvas!: ElementRef;

  @ViewChild("cellInput") myInputField: ElementRef;

  private ctx: CanvasRenderingContext2D;

  myVIPIndex: number;
  sceneId: string;
  showMedia: boolean = false;

  calField: Field = { type: 'calendar', width: "370px", }

  buttonStyle: ButtonStyle;
  itemSelectStyle: ButtonStyle;
  fieldEditing: boolean = false;

  //calendar: Calendar = {}; // move to field ...

  theme: Theme;

  colorChoice;
  editorOpen = false;
  showCalendar = false;
  days: string[]
  city: string;
  state: string;
  newMedia: Media;
  uploadingMedia: boolean = false;
  optionsEnabled: boolean = false;
  functionIndex: number;
  editingTags: boolean = false;
  editingMulti: boolean = false;

  myDate = new Date(Date.now());
  minDate = "";    //ADD SETTING FOR DATE FIELDS  new Date(Date.now());

  searchString: string = "";
  forgotStr: string;
  user: User;
  constructor(public global: Globals, private sanitizer: DomSanitizer, public spriteService: SpriteService, public logicService: LogicService,
    public mediaService: MediaService, public cartService: CartService,
    public emailService: EmailService, public VIPservice: VIPService,
    public registerService: RegisterService, public formService: FormService,) {
    this.days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  }

  once: boolean = false;

  ngAfterViewChecked(): void {
    var self = this;
    //console.log ("VIEW CHECKED ....",self.field)
    if (self.field.type == 'cell' && self.field.fieldEditing) {
      self.focusMe()
    }
  }
  /*
    ngOnChanges(): void {
      var self = this;
  console.log ("CHANGES....",self.field)
      if (self.field.type == 'cell' && self.field.fieldEditing) {
        self.focusMe()
      }
    }
  */
  ngAfterViewInit(): void {
    var self = this;
    //  if (self.once) return;

    if (self.field.type == 'cell' && self.field.fieldEditing) {
      //    self.focusMe()
    }


    /*


    if (self.field.type == 'canvas') {
      self.ctx = this.myCanvas.nativeElement.getContext('2d');

      self.myCanvas.nativeElement.width = self.field.width;
      self.myCanvas.nativeElement.height = self.field.height;
      console.log("Starting canvas...", self.ctx)

      const canvas = <HTMLCanvasElement>self.myCanvas.nativeElement;

      var index = self.spriteService.newSpriteEngine(canvas, self.field.width, self.field.height)
      self.field.canvasIndex = index;
      console.log("After view init set up canvas ",index)
      self.once = true;
    }
*/

  }
  codeCallback(code: CodeObject, self: this) {
    console.log("Callback: ", code)



    //   spriteEngine.addSprite(code)

  }

  ngAfterContentChecked(): void {

  }
  ngOnDestroy() {
    // unsubscribe from all on destroy
    //  this.subs.forEach(sub => sub.unsubscribe());
    //if (this.field.type == 'canvas') cancelCanvas = true;
    //  console.log("FIELD COMP DESTROYED ", this.field)

    // remove CANVAS
    /*    if (typeof(this.field.canvasIndex)!='undefined'){
          if (this.spriteService.spriteEngines) {
            this.spriteService.spriteEngines.splice(this.field.canvasIndex, 1);
          }
        }*/

  }


  ngOnInit(): void {
    var self = this;


    if (self.field.type == 'displayImage') {
      console.log("IMAGE DISPLAY ", self.field)
    }



    if (self.field.type == 'map') {
      self.field.mapOptions.container = (Math.floor(Math.random() * 100)).toString()
    }

    if (!this.themeId) {
      this.themeId = this.global.allThemes[0].id;
      self.theme = self.global.allThemes[0];
      console.log("No Themeid  ", this.themeId)
    }
    // GET Theme
    else {
      self.theme = self.global.allThemes.find(x => x.id === this.themeId)
      if (!self.theme) {
        console.log("Did not find your theme...")
      }
    }

    if (self.field.type == 'calendar') {
      if (!self.field.calendar) { // DEFAULT SETTINGS
        self.field.calendar = {
          dateCount: 1,
          autoOpen: true,
          //  theme: self.field.theme,
          themeId: "EFCayOgEI2QY9mtKoamw",
          //  selectPast: true, 
          //  selectToday: true,
          year1: 1900, year2: 2046,
          highlightToday: true,
          highlightSelected: true,
          //   daysOpen: [true, false, false, false, false, true, true],
        }
        self.calField.calendar = self.field.calendar;
      }
      if (self.field.calendar?.autoOpen)
        self.showCalendar = true;
      console.log("CALENDAR FIELD: ", self.field)

    }

    // If no label then use title
    //  if (!this.field.label) this.field.label = this.field.title;

    // default display field to true
    if (typeof (this.field.display) != 'boolean')
      this.field.display = true;

    // Default to Visible for Options ...
    if (this.field.optionsVisible)
      this.optionsEnabled = true;

    if (this.field?.defaultOpen) {
      this.editingMulti = true;
    }

    if (!this.field?.blurButton) {
      this.field.blurButton = "Update";
    }

    if (this.field.type == 'date') {
      // Convert Timestamp to date
      //  self.myDate = new Date(self.field.value)
    }
    if (1/* || self.field.type == 'button' || self.field.type == 'select-button' ||
      self.field.type=='Options' || self.field.type=='time' || self.field.type == 'multi-select'
      || self.field.type == 'tags'*/
    ) {
      if (self.field.buttonStyle == 'theme') {
        self.buttonStyle = self.theme.buttonStyles[self.field.groupIndex]
      }

      else if (self.field?.buttonStyle) {
        self.theme.buttonStyles.forEach(function (style, index) {
          if (style.buttonName == self.field.buttonStyle) {
            //    console.log("BUtton style ", style)
            self.buttonStyle = style;
          }
        })
      }
      if (!self.field?.buttonStyle && self.theme?.buttonStyles?.length) { // DEFAULT BUTTON STYLE
        self.buttonStyle = self.theme.buttonStyles[0];
      }

      //  console.log("Button style ", self?.buttonStyle, self.itemSelectStyle, self.theme, self.field)
      self.itemSelectStyle = self.theme.buttonStyles[1]


    }

    if (self.field.type == 'canvas') {
      console.log("Canvas init ", self.field)
      if (!self.field?.binding) {
        console.log("OOPS - there is no scene here")
        //  self.field.binding = <string> self.VIPservice.newScene(self.newSceneCallback, self);
      }
      else {
        self.sceneId = self.field.binding;
      }
    }

    if (self.field.type == 'colorChooser') {
      if (!self.field.binding) self.field.binding = "0xffaaff"

    }
    if (self.field.type == 'queryBuilder') {
      //  self.field.dataSource = "Fields"
      console.log("query ", self.field);
    }

    //  console.log("Global Palettes ", this.global.allPalettes)
    if (this.field.type == 'checkbox') {
      if (!this.field.label) this.field.label = "checkbox label"
      if (!this.field.trueFalse) this.field.trueFalse = false;
      console.log("Checkbox field")
    }

    if (this.field.dataSource == "napkinFunctions") {
      self.field.options = ["Math", "Financial", "Monthly payment", "Sum", "Average"];
    }
    if (this.field.dataSource == "buttonStyles") {
      self.theme.buttonStyles.forEach(function (style) {
        self.field.options.push(style.buttonName)
      })
    }

    /*if (this.field.dataSource == "myFields") {
      self.field.options = [];

      const index = self.global.allForms.findIndex(function (post) {
          if (post.name == self.field.sourceForm)
            return true;
        });
        if (index != -1) {
        }
      }
    }*/



    if (this.field.dataSource == "Media tags") {
      self.field.options = self.global.mediaTags;
      // self.field.available = self.global.mediaTags;
    }
    if (this.field.dataSource == "My Stores") {
      if (!self.field.options) self.field.options = [];
      self.global.authuser.creds.forEach(function (store) {
        self.field.options.push(store.storeName)
      })
    }
    if (this.field.dataSource == "Node tags") {
      self.field.options = self.global.myCompany.masterNodeTags;
      // self.field.available = self.global.mediaTags;
    }
    if (self.field.dataSource == "Departments") {
      self.formService.getDepartments(self.deptCallback, self);
      // self.field.options = self.global.myCompany.departments
    }
    if (this.field.dataSource == "Napkin app") {
      self.field.options = [];
      console.log("All my apps ", self.global.publicApps)
      if (self.global?.publicApps)
        self.global.publicApps.forEach(function (app) {
          self.field.options.push(app.appId);
        })

    }
    if (this.field.dataSource == "Report tags") {
      // self.field.options = self.global.myCompany.masterNodeTags;
    }

    if (this.field.dataSource == 'Reports') {
      self.field.options = self.global.allReportNames;
    }
    if (this.field.dataSource == 'Queries') {
      self.field.options = self.global.allQueryNames
    }
    if (this.field.dataSource == "Forms") {
      self.field.options = [];
      if (self.global?.allForms?.length)
        self.global.allForms.forEach(function (form) {
          self.field.options.push(form.name)
        })
    }
    if (this.field.dataSource == "Themes") {
      self.field.options = [];
      self.global.allThemes.forEach(function (theme) {
        self.field.options.push(theme.name)
      })
    }
    if (this.field.dataSource == "POS Tags") {
      self.field.options = [];
      self.global.myCompany.posTagGroups.forEach(function (tagGroup) {
        self.field.options.push(tagGroup.name);
      })
      console.log("Pos tags ", self.field.options)
    }
    if (this.field.dataSource == "Product Tags") {
      self.field.options = [];
      self.global.allTags.forEach(function (tag) {
        self.field.options.push(tag)
      })
      console.log("Product tags ", self.field.options)
    }
    if (this.field.dataSource == "Open Orders") {
      self.field.options = [];
      if (self.global.openTickets) {
        console.log("Open Tickets", self.global.openTickets)

        Object.entries(self.global.openTickets).forEach(function (ticket: any) {
          console.log("Ticket---", ticket)

          if (ticket?.ticket?.ticketName)
            self.field.options.push(<any>ticket.ticket.ticketName)
        })
      }
      else { // Check if listener has started
        self.cartService.watchOpenTickets(this.gotOpenOrders, self);
      }
      console.log("Open orders ", self.field.options)
    }
    self.checkProducts();

    if (this.field.dataSource == "Queries") {
      self.field.options = [];
      self.global.allQueries.forEach(function (query) {
        self.field.options.push(query.title)
      })
    }
    // Get list of fields limited to forms selected.
    if (this.field.dataSource == "Fields") {
      // Find another field 
      if (self.field.sourceForm) {
        self.field.options = []
        const index = self.global.allForms.findIndex(function (post) {
          if (post.name == self.field.sourceForm)
            return true;
        });
        if (index != -1) {
          self.global.allForms[index].fields.forEach(function (field) {
            self.field.options.push(field.title);
          })
        }
      }
    }

    if (this.field.dataSource == "Field") {
      if (self.field.sourceForm && self.field.sourceField) {
        const index = self.global.allForms.findIndex(function (post) {
          if (post.name == self.field.sourceForm)
            return true;
        });
        if (index != -1) {
          const fieldIndex = self.global.allForms[index].fields.findIndex(function (post) {
            if (post.title == self.field.sourceField)
              return true;
          });
          if (fieldIndex != -1) {

            self.field.binding = self.global.allForms[index].fields[fieldIndex].binding;
            self.field.value = self.global.allForms[index].fields[fieldIndex].value;
            console.log("FetchField: ", self.field, self.global.allForms)

            if (self.field.type.toLowerCase() == 'select-button' || self.field.type.toLowerCase() == 'options' || self.field.type.toLowerCase() == 'multi-select') {
              // Wow! now we'll fetch the data from the submissions of another form!
              self.field.options = []
              self.formService.getSomeData(self.global.allForms[index].id, [self.field.sourceField], self.callBack, this, self.field.filter)
            }

            //  if (self.field.value && !self.field.binding)
            //    self.field.binding = self.field.value;

            // HANDLE ALL binding types
          }
        }
      }
    }

    // console.log("FIELD: ", this.field, this.editMode)
    /*
        if (this.field.title && this.field.title.startsWith('[')) {
          if (this.field.title == '[dayofweek]')
            this.field.title = this.dayofweek;
        }
        */
    if (this.field.titleSource == 'dayofweek') {
      const day = new Date(Date.now());
      const weekday = day.getDay();

      this.field.title = this.days[weekday]
    }

    //  if (this.field.type == 'currency' && !this.field.value) this.field.value = null;

    if (this.field.dataSource == 'fullName') {
      this.field.binding = this.global.authuser.firstName + " " + this.global.authuser.lastName;
      console.log("User name ", this.field.binding)
    }

    if (self.field.type == 'tags' && self?.field?.options) {
      if (!self.field.optionSizes)
        self.field.optionSizes = [];
      self.field.options.forEach(function (option, index) {
        if (!self.field.optionSizes[index])
          self.field.optionSizes.push(self.getRandom(90, 210))
      })
    }

    if (this.field.type == 'time') {
      // Verify that time options exist, create if not
      if (!this.field.options) {
        // Check for start & end times

        // default times
        this.field.options = [];
        this.createTimeOptions();

      }


    }



    if (this.field.type == 'select-button') {

      if (this.field.defaultOpen) {
        this.optionsEnabled = true;
      }
      // Let's check for default value in selected
      if (this.field.selected && !this.field.binding) { this.field.binding = this.field.selected[0] }

      // Let's check if there is a special source for options
      if (this.field.optionSource == 'fieldTypes') {
        // Let's populate the field.
        self.field.options = [];
        self.field.selected = [];

        self.global.allFields.forEach(function (item) {
          self.field.options.push(item.title);

        })
        //  console.log ("fieldTypes... ", self.field.options)
      }
    }

    if (this.field.type == "displayHTML" && this.field.html) {
      // Scan for embedded fields...
      var pos = -1;
      var allValid = true;

      this.field.htmlFinal = this.field.html
      // replace /n with <br>
      this.field.htmlFinal = this.field.htmlFinal.split('\n').join('<br>');
      //   console.log("After scan for cr ", this.field.htmlFinal)
      do {
        var str = self.field.htmlFinal;
        pos = str.search("{{")
        if (pos != -1) { // find match
          var replaceStr = "";
          var pos2 = str.search("}}")
          if (pos2 != -1) {
            // startPos = pos2 + 2;
            // Now extract the variable
            const variableName = str.substring(pos + 2, pos2);
            pos = -1;
            self.global.allForms.forEach(function (form) {
              form.fields.forEach(function (srcField) {
                if (srcField.title == variableName) {
                  if (srcField.type == 'date') {
                    if (srcField.date)
                      replaceStr = self.formService.getPrettyDate(srcField.date.toMillis())
                    else allValid = false;
                  }
                  else if (srcField.type == 'time') {
                    if (srcField?.selected?.length)
                      replaceStr = srcField.selected[0]
                    else allValid = false;
                  }
                  else {
                    var value: number = srcField.value;
                    if (!srcField.value) {
                      value = 0;
                      allValid = false;
                    }
                    replaceStr = self.formService.formatData(srcField)

                  }
                  console.log("Found a Variable ", replaceStr, variableName, srcField, pos, pos2);
                  pos = 1;
                  self.field.htmlFinal = self.field.htmlFinal.replace("{{" + variableName + "}}", replaceStr)
                }
              })
            })
          }
          else pos = -1;
        }
      } while (pos != -1)
      if (self.field.showIfValid && !allValid) {
        self.field.display = false;
      }
      else {
        self.field.display = true;
        // this.field.safeHtml = self.sanitizer.bypassSecurityTrustHtml(self.field.htmlFinal);
      }
    }
  }
  cellSelect(event) {
    event.stopImmediatePropagation();
    //  this.field.fieldEditing = true;
    this.outputsEvent(event)
    //   this.focusMe()
  }

  isExpanded() {

    //  console.log ("exp: ", this.field.expanded)
    if (typeof (this.field.expanded) != 'boolean' || this.field.expanded == true)
      return true;
    else
      return false;
  }
  toggleExpand(tf) {
    this.field.expanded = tf;
  }

  focusMe() {
    var self = this;

    //  document.getElementById("cellInput").focus()

    if (self.myInputField?.nativeElement)
      self.myInputField.nativeElement.focus();
  }

  cellEvent(event) {
    var self = this;
    //   console.log("Cell event ", self.field.binding.length)

    if (self.field.binding.length > 8) {
      //  self.myInputField.nativeElement.width = 120;
      //   self.field.width = "200px";
    }

  }

  cellBlurEvent(event) {
    event.stopImmediatePropagation();
    this.outputsEvent("cellBlur")

  }

  gotOpenOrders(self) {

    self.field.options = [];

    Object.entries(self.global.openTickets).forEach(function (ticket: any) {
      console.log("Ticket ", ticket[1].ticket)
      if (ticket[1]?.ticket) {
        const ticketName = ticket[1].ticket.ticketName;
        self.field.options.push(ticketName)
      }
    })
    console.log("Field Options : ", self.field.options)
    /*
    self.global.openTickets.forEach(function (order) {
      if (!order.tableName || order?.tableName.length==0)
        order.tableName = "New Order"
      self.field.options.push(order.tableName)
    })*/
  }

  checkProducts() {
    var self = this;

    if (this.field.dataSource == "Products") {
      self.field.options = [];
      var filterStr;
      self.formService.getSomeData
      if (self.field.sourceField && self.field.sourceForm) {
        // Get filter value from the source . this Field Updates 
        // should be in a service
        var formIndex = self.global.allFormNames.indexOf(self.field.sourceForm);
        if (formIndex != -1) {
          self.global.allForms[formIndex].fields.forEach(function (field) {
            if (field.title == self.field.sourceField) {
              // GOT FIELD - GET DATA
              filterStr = field.binding;
            }
          })

        }


      }
      self.global.allProducts.forEach(function (product) {
        if (!filterStr || filterStr.length == 0)
          self.field.options.push(product.title)
        else if (product?.allTags?.includes(filterStr))
          self.field.options.push(product.title)

      })
      console.log("Products ", self.field.options, filterStr)
    }

  }

  createCallback(event) {

    var self = this;

    // needs to include the msgId!!!
    event.chat.chatBotId = event.id;
    event.chat.chatBotName = event.name;
    //  self.updateMessage(event.chat, self.currentGroupId)
    console.log("callback bot create", event)
    self.gotEvent(event)

  }

  deptCallback(self, submissions) {
    console.log("Getting DEPTS ", submissions);
    self.global.myCompany.departments = [];
    self.field.options = [];
    submissions.forEach(function (item) {
      if (is_array(item)) item = item[0]
      self.global.myCompany.departments.push(item)

    })
    self.field.options = self.global.myCompany.departments;
    console.log("Getting DEPTS ", self.field, self.global.myCompany.departments);

  }

  getMyWidth(width) {
    if (width) return width;
    //  else return "min-content;"
  }
  getMyHeight(height) {
    if (height) return height;
  }

  checkQueryMode() {
    var x = this.field?.selected?.length;

    return x % 4;
  }

  outputDate(dateTimeMS: number) {
    var self = this;
    console.log("GOT DATE: ", dateTimeMS, self.field.calendar, self.field)

    if (!self.field.selected) self.field.selected = [];
    if (self.field.selected.length >= self.field.calendar.dateCount) {
      // Got all the dates we allow or need
      self.field.selected[self.field.selected.length - 1] = format(new Date(dateTimeMS), 'MMM dd yyyy');
      self.field.dateMs[self.field.selected.length - 1] = dateTimeMS;
    }
    else {
      if (!self.field.dateMs || !self.field.dateMs?.length) self.field.dateMs = [];
      self.field.selected.push(format(new Date(dateTimeMS), 'MMM dd yyyy'))
      self.field.dateMs.push(dateTimeMS);
    }

    if (!self.field.calendar.dateCount || self.field.dateMs.length >= self.field.calendar.dateCount) {
      self.showCalendar = false;
      this.outputsEvent("calendar")
    }


  }
  displayCalendar(event) {
    event.stopImmediatePropagation();
    this.showCalendar = !this.showCalendar;
  }

  checkFunctionMode() {
    var x = this.field.selected.length;
    if (x >= this.global.functions[this.functionIndex]?.parameters?.length + 3)
      x = -1;
    return x;


  }
  checkEquationMode() {
    var x = this.field?.selected?.length;
    var y = x % 4;
    if (x > 3) {
      if (x % 2 == 0) y = 2;
      else y = 3;
    }
    return y;
  }


  themeContainer() {
    var str: string;
    var self = this;


    if (self.field.type != 'button') {
      if (self.theme.containerBackground)
        str = "background-color: " + self.theme.containerBackground + ";"

      if (self.theme.containerBorder)
        str += "border: " + self.theme.containerBorder + ";"

      if (self.theme.containerBoxShadow)
        str += "box-shadow: " + self.theme.containerBoxShadow + ";"
    }
    else {
      //  str = "width: 100%; height:100%;"
    }

    if (self.field.type == 'cell' || self.field.buttonStyle == 'cell') {
      str += "display: grid;"
      return str;
    }

    if (self.theme.containerMargin)
      str += "margin: " + self.theme.containerMargin + ";"

    if (self.theme.containerPadding)
      str += "padding: " + self.theme.containerPadding + ";"

    if (self.theme.containerAlignment) {
      str += "display: flex; justify-content: " + self.theme.containerAlignment + ";"
      str += "align-items:  " + self.theme.containerAlignment + ";"
    }


    if (self.field.boxWidth)
      str += "width: " + self.field.boxWidth + ';';
    if (self.field.highlight)
      str += "outline: 2px rgb(20,45,75) inset;"

    // console.log("Container ", str)
    return str;
  }
  getColorBinding() {
    return "background-color: " + this.field.binding;
  }

  themeLabel() {
    var str = "";
    var self = this;

    //  if (self.theme.labelBackground)
    //     str += "background-color: "+self.theme.labelBackground+";"
    if (self.theme.labelColor)
      str += "color: " + self.theme.labelColor + ";"
    if (self.theme.labelFont)
      str += "font-family: " + self.theme.labelFont + ";"

    if (self.theme.labelFontSize)
      str += "font-size: " + self.theme.labelFontSize + ";"

    if (self.theme.labelFontWeight)
      str += "font-weight: " + self.theme.labelFontWeight + ";"

    if (self.theme.labelAlignment)
      str += "text-align: " + self.theme.labelAlignment + ";"
    if (self.theme.labelTextShadow)
      str += "text-shadow: " + self.theme.labelTextShadow + ";"
    if (self.theme.labelPadding)
      str += "padding: " + self.theme.labelPadding + ";"
    if (self.theme.labelMargin)
      str += "margin: " + self.theme.labelMargin + ";"



    return str;
  }

  themeEntry() {
    var str;
    var self = this;

    if (self.theme.entryBackground)
      str = "background-color: " + self.theme.entryBackground + ";"

    if (self.field.type == 'switch') {
      str = self.getTrueColor()
    }


    if (self.theme.entryColor)
      str += "color: " + self.theme.entryColor + ";"

    str += "height: 100%; cursor: text;"

    if (self.theme.entryPadding)
      str += "padding: " + self.theme.entryPadding + ";"
    if (self.theme.entryMargin)
      str += "margin: " + self.theme.entryMargin + ";"
    if (self.theme.entryWidth)
      str += "width: " + self.theme.entryWidth + ";"
    if (self.theme.entryHeight)
      str += "height: " + self.theme.entryHeight + ";"
    if (self.theme.entryAlignment)
      str += "text-align: " + self.theme.entryAlignment + ";"
    if (self.theme.entryFontSize)
      str += "font-size: " + self.theme.entryFontSize + ";"
    if (self.theme.entryFontShadow)
      str += "text-shadow: " + self.theme.entryFontShadow + ";"
    if (self.theme.entryBorder)
      str += "border: " + self.theme.entryBorder + ";"
    if (self.theme.entryBorderShadow)
      str += "border-shadow: " + self.theme.entryBorderShadow + ";"


    return str;
  }
  getMyData() {

    if (this.field?.cellStyle?.type == 'Currency') {
      if (this.field.value) {
        var digits;
        var outStr;

        if (this.field?.cellStyle?.decimals == 'any') {
          digits = null;
        }
        else if (!this.field?.cellStyle?.decimals)
          digits = "1.2-2"
        else digits = "0." + this.field.cellStyle.decimals + "-" +
          this.field.cellStyle.decimals

        if (!digits)
        outStr = formatCurrency(this.field.value, "en-US", '$', 'USD',"1.0")
        else
          outStr = formatCurrency(this.field.value, "en-US", '$', 'USD',
            digits)

        return outStr;
      }
    }
    if (this.field?.cellStyle?.type == 'Number') {

      if (this.field.value) {
        var digits;

        if (this.field?.cellStyle?.decimals == 'any') {
          digits = null;

        }
        else if (!this.field?.cellStyle?.decimals)
          digits = "1.2-2"
        else digits = "1." + this.field.cellStyle.decimals + "-" +
          this.field.cellStyle.decimals
        if (!digits) {
          return this.field.value
          outStr = formatNumber(this.field.value, "en-US","0.0")
        }
        else {
          outStr = formatNumber(this.field.value, "en-US", digits)
        }
      //  console.log("DIGITS: ", digits, outStr)

        return outStr;
      }
    }

    if (this.field.value) return this.field.value;

    else if (this.field?.binding?.length) return this.field.binding;
    else return ""
  }

  toggleFieldEditing(event) {
    var self = this;
    //  event.stopImmediatePropagation();

    self.fieldEditing = true;
    self.field.highlight = true;
  }
  fieldEditingOff(event) {
    var self = this;
    self.fieldEditing = false;
    self.field.highlight = false;
    console.log("It's off")
  }


  getTrueColor() {
    var str;
    var self = this;

    if (self.field.trueFalse == false) return;
    if (self?.field?.trueColor)
      str = "background-color: " + self?.field?.trueColor + ";"
    return str;

  }


  myButtonStyle() {
    var str;
    var self = this;

    if (self.field.type != 'cell' && self?.buttonStyle?.buttonBackground)
      str = "background-color: " + self.buttonStyle.buttonBackground + ";"
    if (self.field.type != 'cell' && self?.buttonStyle?.buttonColor) {
      //if (self.field.disabled)
      //  str += "color: " + "#668833" + ";"
      //else
      str += "color: " + self.buttonStyle.buttonColor + ";"
    }
    if (self?.buttonStyle?.buttonPadding)
      str += "padding: " + self.buttonStyle.buttonPadding + ";"
    if (self?.buttonStyle?.buttonMargin)
      str += "margin: " + self.buttonStyle.buttonMargin + ";"
    if (self?.buttonStyle?.buttonBorder)
      str += "border: " + self.buttonStyle.buttonBorder + ";"
    if (self?.buttonStyle?.buttonRadius)
      str += "border-radius: " + self.buttonStyle.buttonRadius + ";"
    if (self?.buttonStyle?.buttonShadow)
      str += "box-shadow: " + self.buttonStyle.buttonShadow + ";"
    if (self?.buttonStyle?.buttonFontSize)
      str += "font-size: " + self.buttonStyle.buttonFontSize + ";"
    if (self?.buttonStyle?.buttonFont)
      str += "font-family: " + self.buttonStyle.buttonFont + ";"
    if (self?.buttonStyle?.buttonFontWeight)
      str += "font-weight: " + self.buttonStyle.buttonFontWeight + ";"

    str += "cursor: pointer; box-sizing: border-box;"

    if (self.field.highlight)
      str += "outline: 2px rgb(20,45,75) inset;"


    if (self.field.type == 'cell') {
      if (self?.field?.cellStyle?.cellColor && self?.field?.cellStyle?.color)
        str = "background-color: " + self.field.cellStyle.cellColor +
          ";color: " + self?.field?.cellStyle?.color + ';'

      else {
        if (self?.field?.cellStyle?.cellColor)
          str = "background-color: " + self.field.cellStyle.cellColor + ";"

        if (self?.field?.cellStyle?.color)
          str = "color: #f4ed45;"// + self.field.cellStyle.color + ";"
      }
      if (self?.field.cellStyle?.font)
        str += "font-family: " + self.field.cellStyle.font + ";"

      if (self?.field.cellStyle?.fontSize)
        str += "font-size: " + self.field.cellStyle.fontSize + ";"
      if (self?.field.cellStyle?.fontWeight)
        str += "font-weight: " + self.field.cellStyle.fontWeight + ";"

      /*     if (self?.field.cellStyle?.alignH)
             str += "text-align: " + self.field.cellStyle.alignH.toLowerCase() + ";"
           if (self?.field.cellStyle?.alignV)
             str += "align-items: center"
     */
      if (self?.field.cellStyle?.align) {
        switch (self?.field.cellStyle?.align) {
          case 'Top-Left':
            str += "display: flex; justify-content: left; align-items:  top; text-align: left;"
            break;
          case 'Top-Center':
            str += "display: flex; justify-content: center; align-items:  top; text-align: center;"
            break;
          case 'Top-Right':
            str += "display: flex; justify-content: right; align-items:  top; text-align: right;"
            break;
          case 'Middle-Left':
            str += "display: flex; justify-content: left; align-items:  center; text-align: left;"
            break;
          case 'Middle-Center':
            str += "display: flex; justify-content: center; align-items:  center; text-align: center;"
            break;
          case 'Middle-Right':
            str += "display: flex; justify-content: right; align-items:  center; text-align: right;"
            break;
          case 'Bottom-Left':
            str += "display: flex; justify-content: left; align-items: flex-end; text-align: left;"
            break;
          case 'Bottom-Center':
            str += "display: flex; justify-content: center; align-items: flex-end; text-align: center;"
            break;
          case 'Bottom-Right':
            str += "display: flex; justify-content: right; align-items: flex-end; text-align: right;"
            break;
        }
      }

      if (self?.field.cellStyle?.wordWrap) {
        var wrap = 'nowrap';
        if (self.field.cellStyle.wordWrap.toLowerCase() == 'wrap')
          wrap = 'wrap';
        if (self.field.cellStyle.wordWrap.toLowerCase() == 'clip')
          str += "overflow: clip;text-wrap: " + wrap + ";"
        else
          str += "overflow:visible; text-wrap: " + wrap + ";"
      }
      else str += "overflow:visible; text-wrap: nowrap;"
    }

    return str;
  }

  onKeydown(event) {
    console.log("Keypress ", event)
  }

  getItemSelectStyle() {
    var str;
    var self = this;
    if (!self?.itemSelectStyle) console.log("Missing Theme Button Style!")

    if (self?.itemSelectStyle?.buttonBackground)
      str = "background-color: " + self.itemSelectStyle.buttonBackground + ";"
    if (self?.itemSelectStyle?.buttonColor)
      str += "color: " + self.itemSelectStyle.buttonColor + ";"
    if (self?.itemSelectStyle?.buttonPadding)
      str += "padding: " + self.buttonStyle.buttonPadding + ";"
    if (self?.itemSelectStyle?.buttonMargin)
      str += "margin: " + self.itemSelectStyle.buttonMargin + ";"
    if (self?.itemSelectStyle?.buttonBorder)
      str += "border: " + self.itemSelectStyle.buttonBorder + ";"
    if (self?.itemSelectStyle?.buttonRadius)
      str += "border-radius: " + self.itemSelectStyle.buttonRadius + ";"
    if (self?.itemSelectStyle?.buttonShadow)
      str += "box-shadow: " + self.itemSelectStyle.buttonShadow + ";"
    if (self?.itemSelectStyle?.buttonFontSize)
      str += "font-size: " + self.itemSelectStyle.buttonFontSize + ";"
    if (self?.itemSelectStyle?.buttonFont)
      str += "font-family: " + self.itemSelectStyle.buttonFont + ";"
    if (self?.itemSelectStyle?.buttonFontWeight)
      str += "font-weight: " + self.itemSelectStyle.buttonFontWeight + ";"

    str += "cursor: pointer;"
    return str;
  }



  themeOptionSelected() {
    var str;
    var self = this;

    str = "min-width: 80px; background-color:blue; color:white; text-align: center!important;margin: 8px!important; padding: 8px;"

    return str;
  }

  themeOption() {
    var str;
    var self = this;

    str = "min-width: 80px; text-align: center !important;margin: 8px!important; padding: 8px;"

    return str;

  }


  evaluate(event) {

    const bob = math.evaluate(this.field.binding)
    console.log("Evalulate event ", event, bob)
    if (bob?.entries) {
      this.field.evaluated = bob.entries;
      console.log("IT'S AN ARRAY ", bob)
      this.field.value = this.field.evaluated[this.field.evaluated.length - 1];
    }
    else
      this.field.value = bob;
    this.outputsEvent(event)
  }
  clearQuery() {
    this.field.selected = []
  }

  callBack(self, submissions) {
    console.log("GOT OPTIONS BABY ", self.field, submissions)

    self.field.options = [];

    submissions.forEach(function (item) {
      if (is_array(item)) item = item[0]; // Flatten array
      self.field.options.push(item)

    })

    // self.field.options = submissions;
    console.log("GOT OPTIONS BABY2 ", self.field, submissions)

  }

  isVisible() {
    var self = this;

    //   if (self.field.type == 'displayImage' && !self.field?.media?.length) {
    //     return false
    //   }

    if (self.field.loginRequired && !self.global?.authuser?.uid) {
      console.log("login required ", self.field)
      return false;
    }

    if (self.field.type == 'pw') return true; // HACK HACK

    if (typeof (self.field.display) != null && self.field.display == false) return false;

    if (typeof (self.field.show) != 'boolean') return true;
    else return (self.field.show);
  }

  newSceneCallback(self: this) {
    // self.sceneId = self.field.binding;
    // Save to DB
    //  self.outputsEvent("new scene")
  }


  setVIP(event) {
    var self = this;
    if (event == "afterPreLoad") {
      console.log("AFTER PRELOAD")
      //  self.getVIP(self.myVIPIndex)
    }
    else {


      // So let's add it to the VIP manager
      if (!self.global.allVIPs) self.global.allVIPs = [];
      self.global.allVIPs.push(event);
      self.myVIPIndex = self.global.allVIPs.length - 1;
      console.log("WE GOT A VIP! ", self.global.allVIPs)
    }
  }

  afterPreload(event) {
    var self = this;
    console.log("AFTER PRELOAD CALL")

    //  self.getVIP(self.myVIPIndex)
  }

  gotEvent(event) {
    console.log("GOT EVENT FROM Object ", event)
    this.objectEvents.emit(event)


  }
  openTab() { }

  VIPEvent(event) {
    var self = this;

    console.log("GOT EVENT FROM EDITOR ", event)
    if (event == 'Image') {
      this.global.allVIPs[self.myVIPIndex].tool = event;

    }
    /*
    if (event=="Pause")
     // this.global.allVIPs[self.myVIPIndex].phaserGame.pause()
    if (event=="Play")
   //   this.global.allVIPs[self.myVIPIndex].resume()    
    if (event=="Restart")
   //   this.global.allVIPs[self.myVIPIndex].scene.scenes[0].scene.start() 
  */
  }

  startCanvas(context) {
    var self = this;

    if (!self.onlyOnce) {
      console.log("CANVAS: ", self.field.width, self.field.height)
      self.initCanvas(context, self.field.width, self.field.height);
    }
  }

  getRandom(min, max) {
    var delta = max - min;
    var rand = (Math.random() * delta) + min;

    rand = Math.floor(rand);
    return rand.toString() + "%";

  }

  getTagSize(field: Field, i) {
    var size = parseInt(field.optionSizes[i]);
    if (this.global.getScreenWidth > 900) return field.optionSizes[i];
    else return (size * .65).toString() + "%"

  }

  getMyClass(field) {
    console.log("Updating class", field)
    if (!field.style || field.style == 'Default') return field.class;
    else if (field.style) return field.style;

  }

  clickMulti(event) {
    event.stopImmediatePropagation();
    this.editingMulti = !this.editingMulti;
    this.field.defaultOpen = this.editingMulti;

    //   console.log("Open tags: ", this.editingMulti)
  }


  clickTags(event) {
    event.stopPropagation();
    this.editingTags = !this.editingTags;
    console.log("Open tags: ", this.editingTags)
  }

  lookUpResult() {  // Called after any asynch function gets a result
    var self = this;

    self.outputs.emit(self.field)


  }
  #drawRect(context: CanvasRenderingContext2D) {

    context.fillRect(10, 10, 30, 30);
    console.log("CANVAS ", context)
  }

  setDate(date: Date) {
    if (this.field.type == 'date') {
      console.log("setDate ")
      // Convert Date to Timestamp
      this.field.date = Timestamp.fromDate(this.myDate) //OLD-BAD
      this.field.value = this.field.date.toMillis();
      this.outputsEvent(null)
    }


  }

  textInputEvent(event) {
    // Bit of a hack here
    event.stopImmediatePropagation();
    if (this.field.title == 'search') { // Let's fire the output
      this.outputsEvent(event)
    }
    if (this.field.type == 'calendar') {
      console.log("Got calendar manual input ")
    }

  }

  outputsBlurEvent(event) {
    var self = this;

    event.stopImmediatePropagation()
    event.stopPropagation();

    if (self.field.type == 'calendar') {
      // Check that dates match
      if (!self.field.binding) return; // NO DATA
      var dateFromStr = getTime(new Date(self.field.binding))
      if (typeof (dateFromStr) == 'number' && dateFromStr != self.field.dateMs[0]) {
        console.log("CALENDAR DIRECT INPUT: ", self.field.binding, dateFromStr, self.field.dateMs)
        self.field.dateMs[0] = dateFromStr;
        self.showCalendar = false;

      }
    }


    self.outputsEvent(event)

  }

  buttonState() {
    //  if (this.field?.trueFalse == true) {
    //    return "background-color: #00ff11;"
    //  }
    //  else
    return "background-color: #22aa21;"
  }

  outputsEvent(event) {
    var self = this;
    console.log("Field level event ", event)

    if (event && typeof (event) != 'string') {
      event.stopImmediatePropagation();
      //   event.stopPropagation();
    }
    if (self.field.type == 'Options') {
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    // self.checkProducts()

    // Toggle button Truthy
    if (0 && self.field.type == 'button') {
      if (typeof (self.field.trueFalse) == 'boolean') {
        self.field.trueFalse = !self.field.trueFalse;
      }
      else
        self.field.trueFalse = true;
    }

    var output: any[];
    this.editorOpen = false

    var skipOutput = false;  // skip if lookup


    // If this field has a datasource of "Forms" then 
    //   add output to a field with "Fields" datasource

    if (self.field.dataSource == 'Forms') {
      self.field.outputs = [
        {
          name: 'Forms',
          value: self.field.selected
        },
      ]
    }




    if (this.field.minVal && this.field.value < this.field.minVal)
      this.field.value = this.field.minVal

    if (this.field.maxVal && this.field.value > this.field.maxVal)
      this.field.value = this.field.maxVal


    if (this.field.type == 'zipcode') {
      this.zipcodeToCityState(this.field.binding);
    }

    if (this.field.type == 'button') {
      self.field.outputs = [];
      output = [
        {
          name: this.field.title,
          value: 'button pressed'
        }
      ]
      this.field.outputs = output;

    }
    /* not needed 
        if (this.field.type == 'groupAdd') {
          self.field.binding = self.field.fieldGroup;
          self.field.outputs = [];
          output = [
            {
              name: this.field.title,
              value: 'button pressed'
            }
          ]
          this.field.outputs = output;
    
        }
        */

    if (self.field.type == 'colorChooser') {
      self.field.binding = event;
      if (!self.field.binding) return;

      console.log("Selected color: ", self.field.binding)

    }
    if (self.field.type == 'paletteSelect') {
      // self.field.palette
      //   this.field.palette = p;
      console.log("Selected palette: ", event)
    }


    if (self.field.type == 'email') {
      if (!ValidateEmail(self.field.binding)) {
        return
      }
      else {  // HAck ADD A check for repeats w/o changes
        self.emailLookup(self.field.binding);
        skipOutput = true;
      }
    }

    if (self.field.outputs) {
      output = [
        {
          name: self.field.outputs[0].name,
          value: self.field.binding
        }
      ]
    }
    else {
      /*
      console.log("DEFAULT OUTPUT???????????")
      output = [
        {
          name: self.field.title,
          value: self.field.binding
        }
      ]*/

    }
    if (self.field.type == 'fullName' || self.field.type == 'full-name' && self.field.binding && self.field.binding.length > 4) {
      const arr = self.field.binding.split(' ');
      console.log("Name ", arr);
      if (arr.length > 1) {
        var lastName = arr[1];
        //   arr.forEach(function (n, index) {
        //     if (index) lastName += n + " ";
        //   })

        output = [
          {
            name: 'firstName',
            value: arr[0]
          },
          { name: "lastName", value: lastName.trimEnd() }
        ]
        self.field.outputs = output;
      }
    }

    if (self.field.logic) {
      console.log("EXECUTING FIELD LOGIC: ", self.field)
      //  self.logicService.executeLogic(self.field.logic, null, self.field, self.codeCallback, self);
    }

    if (!skipOutput) {
      console.log("Outputs ", self.field, output)
      // Execute Logic - 
      //   if (self.field.logic)
      //     self.logicService.executeLogic(self.field.logic, null, self.codeCallback, self);
      self.outputs.emit(self.field)
    }

  }

  getFieldWidth() {

    if (this.field?.layout == 'column') return "100%";

    else return "100%";

  }
  optionsEnableYes(e) {
    e.stopImmediatePropagation();
    e.stopPropagation();
    var self = this;

    self.optionsEnabled = true;
  }

  optionsEnable(event) {
    var self = this;
    event.preventDefault()
    event.stopPropagation();
    event.stopImmediatePropagation();

    self.optionsEnabled = !self.optionsEnabled;
    console.log("OPT ENABL ", self.optionsEnabled)
  }

  clearSearch() {
    this.searchString = "";

  }

  setBinding(event, field: Field, d) {
    //  field.fielda = d;

  }

  addNewTag(e) {
    var self = this;

    e.stopPropagation();
    e.stopImmediatePropagation();
    if (!self.field.options) self.field.options = [];
    self.field.options.push(self.searchString);
    self.searchString = "";

    self.outputsEvent(e)

  }
  tagFilter(item: string[]): boolean {
    var self = this;

    if (self.searchString.length > 1) {
      if (item.includes(self.searchString.toLowerCase())) return true;
      else return false;
    }
    return true;
  }

  htmlOnOpen;
  openEditor() {
    this.htmlOnOpen = this.field.html;
    this.editorOpen = true;
  }
  cancelEdits() {
    this.editorOpen = false;
    this.field.html = this.htmlOnOpen;

  }


  removeTag(e, t: string) {
    e.stopImmediatePropagation();
    const index = this.field.selected.indexOf(t);
    if (index != -1) {
      this.field.selected.splice(index, 1);
      this.outputsEvent(e);
    }
  }

  removeOption(e, index) {
    var self = this;
    e.stopImmediatePropagation();
    e.stopPropagation();

    self.field.options.splice(index, 1);
    self.outputsEvent(e);

  }

  getMyLabel(field: Field, str) {

    return str;


  }
  removeLastItem(e) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();
    if (self.field.selected.length) // REMOVE LAST ITEM
      self.field.selected.splice(self.field.selected.length - 1, 1)


  }

  addFunction(e, t, i) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();
    if (!self.field.selected) self.field.selected = [];
    self.field.selected.push(t.name);
    self.searchString = "";
    // Based on Function create parameter list
    self.functionIndex = i;
    console.log("Selected function: ", self.global.functions[i])


  }

  addEQField(e, t: string) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();


    if (!self.field.selected) self.field.selected = [];
    self.field.selected.push(t);

    self.searchString = "";
    // self.outputsEvent(e);
  }
  getSelectedValue(d) {
    var str;

    if (typeof (d) == 'object') {
      str = d.subTitle + ": " + d.title
    }
    else str = d;
    return str;
  }

  currentFunctionTag;

  isFunctionTag(t) {
    if (this.currentFunctionTag == t.tags[0]) return true
    else return false;
  }

  selectFunctionTag($event, t, i) {
    this.currentFunctionTag = t;

  }

  addEQOtherField(e, t) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();


    if (!self.field.selected) self.field.selected = [];
    self.field.selected.push(t);

    self.searchString = "";
    // self.outputsEvent(e);
  }


  addEQText(e, t: string) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();


    if (!self.field.selected) self.field.selected = [];
    self.field.selected.push(t);
    self.searchString = "";
    // self.outputsEvent(e);
  }

  getSelectedClass(option) {

    if (option == this.binding) return "show-selected";
    else return "show";

  }

  addTagText(e, t: string) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();

    if (!self.field.selected) self.field.selected = [];

    if (!self.field?.maxSelections || self.field?.maxSelections == -1) {
      self.field.selected.push(t);
    }
    else if (self.field?.maxSelections < self.field?.selected?.length) {
      self.field.selected.push(t);
    }

    self.searchString = "";
    //  self.outputsEvent(e);

  }

  addTag(e, t: string) {
    var self = this;

    e.stopImmediatePropagation();
    e.stopPropagation();

    if (!self.field.selected) self.field.selected = [];
    self.field.selected.push(t);
    self.searchString = "";
    // self.outputsEvent(e);
    if (self.field.blurButton)
      self.outputs.emit(self.field)
    console.log("ADDING TAG ", e)
  }


  localPw: string = "";
  pwChanged(event: InputEvent) {
    var self = this;

    console.log(event.data, event);
    if (event.inputType == 'insertText')
      self.localPw += event.data;
    else if (event.inputType == 'deleteContentBackward') {
      if (self.localPw.length)
        self.localPw = self.localPw.slice(0, self.localPw.length - 1)
    }

    if (self.field.show && self.field.show) {
      self.field.value = self.field.binding;
    }
    else {


      self.field.value = "";
      for (var n = 0; n < self.localPw.length; n++) {
        self.field.value += "*"
      }
    }
    console.log(self.localPw)
    self.field.binding = self.localPw;

  }

  mediaSelect(media: Media, index) {
    var self = this;

    self.field.media = [media];
    self.optionsEnabled = false;
    // Now check for hide/show
    self.field.index = index;

    if (self.field.media) {
      console.log('selected media: ', self.field);
      self.outputs.emit(self.field);

    }

  }

  optionSelect(event, option, index) {
    var self = this;

    event.preventDefault()
    event.stopPropagation();
    event.stopImmediatePropagation();
    if (is_array(option)) option = option[0]; //HACK
    console.log('XXXoption: ', option);


    self.field.binding = option;
    self.field.selected = [];
    self.field.selected.push(option);
    self.optionsEnabled = false;
    // What if this is an Object Selection (Form, Theme, etc.)?
    // Let's store the Object Id too
    if (self.field.dataSource == 'Themes') {
      var themeIndex = self.global.allThemes.findIndex(x => x.name === option)
      if (themeIndex != -1) self.field.dataSourceId = self.global.allThemes[themeIndex].id
      console.log("You picked a theme: ", self.field)
    }
    // self.checkProducts();


    // Now check for hide/show
    self.field.index = index;

    if (self.field.selected && self.field.binding != 'Value') {
      console.log('option: ', option);
      // self.outputsEvent(event);
      self.outputs.emit(self.field);
      //  self.optionsEnabled = false;

    }


  }

  toggleCheckbox(event, field: Field) {
    field.trueFalse = !field.trueFalse;
    this.outputsEvent(event);

  }

  showButton() {
    this.field.show = true;
    this.field.value = this.field.binding;
  }

  hideButton() {
    this.field.show = false;
    this.field.value = "";
    for (var n = 0; n < this.localPw.length; n++) {
      this.field.value += "*"
    }
  }

  emailLookup(email: string) {
    var self = this;

    self.field.found = false;

    self.registerService.emailLookup(email, self.lookupResult, self);

    console.log("EMAIL LOOKUP ", email,)

  }

  lookupResult(self: this, data: any) {
    if (data != null) {
      self.user = <User>data;
      self.field.found = true;
      console.log("found email ", self.user)
    }
    else console.log("NOT found email ")

    self.lookUpResult();

  }

  async zipcodeToCityState(zipcode: string) {
    var self = this;
    if (zipcode.length != 5) return;

    /*
        post code:"90210"
        country:"United States"
        country abbreviation:"US"
        places:0:
        place name:"Beverly Hills"
        longitude:"-118.4065"
        state:"California"
        state abbreviation:"CA"
        latitude:"34.0901"*/


    var ZipCity = {
      "post code": 12345, "country": "here", "country abbreviation": "AB",
      "places": [{ "place name": "there", "state abbreviation": "AX" }]

    }






    var url = 'https://community-zippopotamus.p.rapidapi.com/us/';
    url += zipcode;
    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'community-zippopotamus.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log(result, response)
      ZipCity = result;

      if (response.status != 200) {
        alert("Zipcode not found")
        zipcode = "";
      }
      else {
        console.log(ZipCity['post code']);
        self.city = ZipCity.places[0]['place name'];
        self.state = ZipCity.places[0]['state abbreviation'];

        var output: any[];

        if (this.field.outputs) {
          output = [
            {
              name: 'City',
              value: self.city
            },
            { name: "State", value: self.state }
          ]
        }

        self.field.outputs = [];
        console.log("Outputs ", output)
        self.field.outputs = [
          {
            name: 'City',
            value: self.city
          },
          { name: "State", value: self.state }
        ]

        self.outputs.emit(self.field)
      }




    } catch (error) {
      console.error(error);
    }

  }

  upload(event: any) {
    var self = this;
    var destination = 'Store'; // default set in field editor 
    var newMedia: Media = {}

    if (!event?.target?.files[0]) return; // no file selected
    self.mediaService.newUpload(newMedia, event, destination)



  }
  addImageToCompany(media: Media) {
    var self = this;
    var db = firebase.firestore();
    console.log("Store Media add ", media)

    // add Media to Company as sub-collection
    var ref2 = db.collection("company").doc(this.global.myCompany.id)
      .collection("media").doc().set(media);

    // All done!
  }

  /*
    createMedia(photo: string, file: any, name: string) {
      var self = this;
   
      // Create URL for thumbnail
      var fn = photo.lastIndexOf("review-images");
   
   
      var thumb = "thumb%2fthumb_" + photo.slice(fn + 16);
      var thumbStr = photo.slice(0, fn + 16) + thumb;
   
      // swap the extension if .MOV
      var hasExt = thumbStr.indexOf('.MOV');
      if (hasExt != -1) {
        //off now   thumbStr = thumbStr.replace(".MOV", ".JPG");
      }
   
      var small = "small%2fsmall_" + photo.slice(fn + 16);
      var smallStr = photo.slice(0, fn + 16) + small;
      // swap the extension if .MOV
      var hasExt = smallStr.indexOf('.MOV');
      if (hasExt != -1) {
        //   smallStr = smallStr.replace(".MOV", ".jpg");
      }
   
   
      var med = "med%2fmed_" + photo.slice(fn + 16);
      var medStr = photo.slice(0, fn + 16) + med;
   
      console.log("Sizes: ", fn, medStr, smallStr);
   
      self.newMedia = {
        //   fileId?: string;
        name: name,
        store: self.global.myCompany.store,
        updatedAt: Date.now(),
        createdAt: Date.now(),
        altDesc: "image desc",
        url: photo,
        thumbnailUrl: thumbStr,
        smallUrl: smallStr,
        medUrl: medStr,
        // height?: number;
        //  width?: number;
        //  size?: number
        tags: [self.global.myCompany.name],
        //  format?: string;
        fileType: file.type,
      }
      if (self.field.autoTag)
        self.newMedia.tags.push(...self.field.autoTag)
   
      console.log("media ", self.newMedia);
   
   
      console.log("Media added. ", self.newMedia.smallUrl);
    }
    */

  paletteSelected(event, p, i) {
    this.field.palette = p;
    console.log("Selected palette: ", p)
    this.outputsEvent(event)

  }

  selectedColor(p: Palette, palette: Palette) {
    if (p == palette) return "#12ed3488"
    else return "#ffffff"

  }

  ForgotPassword() {
    var self = this;

    console.log("Forgot ", self.registerService.user, self.forgotStr);

    if (self.registerService.user && !self.forgotStr) {
      console.log("Forgot ");
      self.emailService.ForgotPassword(self.registerService.user.email, self.registerService.user.firstName, self.registerService.user.pw)
      self.forgotStr = "Email reminder sent."
    }

  }

  onlyOnce = false;
  initCanvas(context, width, height) {
    var self = this;
    if (self.onlyOnce) return;

    const canvas = <HTMLCanvasElement>self.myCanvas.nativeElement;

    spriteEngine = new SpriteEngine(canvas, width, height)

    if (self.field.type == 'canvas') {
      /*   spriteEngine.sprites = [];
         for (var n = 0; n < 30; n++) {
   
           var x = Math.random() * 200;
           var y = Math.random() * 200;
           var vx = Math.random() * 2 - 1;
           var vy = Math.random() * 2 - 1;
   
           spriteEngine.addSprite({ type: "Ball", x: x, y: y, vx: vx, vy: vy });
         
         }*/


      //    window.requestAnimationFrame(spriteEngine.draw);
      console.log("INIT SPRITE ENGINE ", context)
      //  self.onlyOnce = true;
    }
    /*
   
    sun.src = "https://firebasestorage.googleapis.com/v0/b/suncraft-ea7be.appspot.com/o/store%2F4390%2Fmedia%2F1690379947934-MangoMargarita.jpeg?alt=media&token=187819de-c5af-4e21-89d2-8adf1d39d0b8";
    moon.src = "assets/canvas_moon.png";
    earth.src = "assets/Ureshi.png";
     window.requestAnimationFrame(self.draw);
   
    // Below is the way to call animation
    const canvas = <HTMLCanvasElement>self.myCanvas.nativeElement;
   // self.myCanvasAnimation = new CanvasAnimation(canvas, self.field.id, self.field.width, self.field.height);
   
   
    const canvasIndex = self.global.canvasFrames.findIndex(item => item === self.field.id);
   
    if (canvasIndex != -1) {
      console.log("CANCELLING ANIMATION on FRAME: ", canvasIndex);
      cancelAnimationFrame(self.global.canvasHandles[canvasIndex])
    }
   
   
    if (!self.global.canvasFrames) {
      self.global.canvasFrames = [];
      self.global.canvasHandles = [];
    }
    console.log("INIT ASSETS ", self.field.id, self.global.canvasFrames, self.global.canvasHandles);
   // self.myCanvasAnimation = new CanvasAnimation(canvas, self.field.id, self.field.width, self.field.height);
  //  self.global.canvasFrames.push(self.field.id);
  //  self.global.canvasHandles.push(self.myCanvasAnimation.frame);
  //  self.frame = window.requestAnimationFrame(() => self.myCanvasAnimation.draw());
   
    // self.onlyOnce = true;
   
    */
  }

  // myCanvasAnimation: CanvasAnimation;
  frame: number;













  createTimeOptions() {

    var time = 0;

    var minTime = 0;
    var maxTime = (24 * (60000 * 60)) - 1;

    if (this.field.timeStart.toLowerCase() == 'store open')
      minTime = this.global.myCompany.reserveSettings.reserveDay[0].resOpenTime.msTime;

    if (this.field.timeEnd.toLowerCase() == 'store close')
      maxTime = this.global.myCompany.reserveSettings.reserveDay[0].resCloseTime.msTime;

    console.log("MINMAX TIMES: ", minTime, maxTime)
    this.field.options = [];
    for (time = minTime; time < maxTime; time += (30 * 60000)) {
      this.field.options.push(this.msTimeStr(time))
    }
    console.log("Times: ", this.field.options)
  }

  msTimeStr(time: number): string {

    var strTime;


    var hours = Math.floor(time / 3600000);
    var minutes = Math.floor((time - (hours * 3600000)) / 60000);


    let amPm = "am";
    if (hours >= 12) {
      amPm = "pm";
      if (hours > 12) hours -= 12;
    }

    if (hours == 0) hours = 12;

    if (minutes < 10)
      strTime = hours + ':0' + minutes + " " + amPm;
    else
      strTime = hours + ':' + minutes + amPm;

    return strTime;
  }


}

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }

  if (!email || email.length < 5) return false;
  alert("Invalid email address!")
  return (false)
}

const sun = new Image();
const moon = new Image();
const earth = new Image();


function drawSolar(ctx, width, height): boolean {
  var self = this;

  // const canvas = this.myCanvas.nativeElement;
  //   const ctx = canvas.getContext('2d');

  //   if (!ctx) return;

  //const ctx = document.getElementById("canvas").getContext("2d");

  ctx.globalCompositeOperation = "destination-over";
  ctx.clearRect(0, 0, width, height); // clear canvas

  drawBalls(ctx)
  ctx.fillStyle = "rgba(0, 0, 0, 0.4)";
  ctx.strokeStyle = "rgba(0, 153, 255, 0.4)";
  ctx.save();
  ctx.translate(200, 200);

  // Earth
  const time = new Date();
  ctx.rotate(Math.PI);
  /*
  ctx.rotate(
    ((2 * Math.PI) / 60) * time.getSeconds() +
    ((2 * Math.PI) / 60000) * time.getMilliseconds(),
  );
  */
  // ctx.translate(165, 0);
  ctx.fillRect(0, -12, 40, 24); // Shadow
  ctx.drawImage(earth, -12, -12, 48, 48);

  // Moon
  ctx.save();
  ctx.rotate(
    ((2 * Math.PI) / 6) * time.getSeconds() +
    ((2 * Math.PI) / 6000) * time.getMilliseconds(),
  );
  ctx.translate(0, 28.5);
  ctx.drawImage(moon, -3.5, -3.5);
  ctx.restore();

  ctx.restore();

  ctx.beginPath();
  ctx.arc(200, 200, 105, 0, Math.PI * 2, false); // Earth orbit
  ctx.stroke();

  // ctx.drawImage(sun, 0, 0, width, height);

  return cancelCanvas;
}

class Sprite {
  x: number;
  y: number;
  vx: number;
  vy: number;
  scalex: number;
  scaley: number;
  red: number;
  green: number;
  blue: number;
  opacity: number;
  mathPi2: number;
  dataStr: string;
  media: Media;
  image = new Image();
  bounds: boolean = true;
  name: string;
  type?: string;


  radius: number
  color: string;

  constructor() {
    this.x = Math.random() * 40 + 200;
    this.y = Math.random() * 40 + 200;
    this.vx = Math.random() * 16 - 8;
    this.vy = Math.random() * 16 - 8;
    this.red = Math.random() * 255;
    this.green = Math.random() * 255;
    this.blue = Math.random() * 255;
    this.opacity = Math.random();
    this.color = "rgb(" + this.red + "," + this.green + "," + this.blue + "," + this.opacity + ")"
    this.radius = Math.random() * 20;
    this.mathPi2 = Math.PI * 2;
  }

}



class SpriteEngine {
  sprites: Sprite[];
  width: 500;
  height: 500;
  frame: number;
  //context: any = null;
  canvasEl!: HTMLCanvasElement
  private context!: CanvasRenderingContext2D;


  constructor(canvas: HTMLCanvasElement, width, height) {
    this.context = canvas.getContext('2d');
    console.log("XSXSXSXXS context ", this.context, width, height)
    this.sprites = [];
    this.width = width;
    this.height = height;
    this.frame = window.requestAnimationFrame(() => this.draw());

  }

  addSprite(code: CodeObject) {
    var self = this;

    //  console.log("new sprite ", code)
    var sprite = new Sprite()
    sprite.x = code.x;
    sprite.y = code.y;
    sprite.type = code.type;
    sprite.dataStr = code.dataStr;
    if (code.media) {
      sprite.media = code.media[0];
      sprite.image = new Image();
      sprite.image.src = code.media[0].url;
      console.log("Added media... ", sprite)
    }

    sprite.vx = code.vx;
    sprite.vy = code.vy;
    sprite.scalex = code.scalex;
    sprite.scaley = code.scaley;
    sprite.name = code.name;
    // code.sprite = sprite;

    self.sprites.push(sprite)

  }


  draw() {
    var self = this;

    if (!this?.context) {
      console.log(">o<")
      //  window.requestAnimationFrame(spriteEngine.draw);


      return;
    }

    var ctx = this.context;
    ctx.clearRect(0, 0, this.width, this.height); // clear canvas


    this.sprites.forEach(function (sprite) {
      if (sprite.dataStr && sprite.dataStr.length > 0) {
        ctx.font = "30px Arial";
        ctx.fillStyle = "blue";
        ctx.fillText(sprite.dataStr, sprite.x, sprite.y);

      }
      if (sprite.image) {
        ctx.drawImage(sprite.image, sprite.x, sprite.y, sprite.scalex, sprite.scaley);

      }
      if (sprite.type == 'Ball') {
        ctx.beginPath();
        ctx.arc(sprite.x, sprite.y, sprite.radius, 0, sprite.mathPi2, true);
        ctx.closePath();
        ctx.fillStyle = sprite.color;
        ctx.fill();
      }

      sprite.x += sprite.vx;
      sprite.y += sprite.vy;

      if (sprite.bounds) {

        if (sprite.y + sprite.vy > self.height || sprite.y + sprite.vy < 0) {
          sprite.vy = -sprite.vy;
        }
        if (sprite.x + sprite.vx > self.width || sprite.x + sprite.vx < 0) {
          sprite.vx = -sprite.vx;
        }
      }

    })

    this.frame = window.requestAnimationFrame(() => this.draw());


  }

  static displayName = "Sprite";
  static distance(a, b) {
    const dx = a.x - b.x;
    const dy = a.y - b.y;

    return Math.hypot(dx, dy);
  }
}


//let spriteEngine = new SpriteEngine()

let spriteEngine: SpriteEngine;

function drawBalls(ctx) {

  spriteEngine.draw();


  // raf = window.requestAnimationFrame(draw);
}
/*
canvas.addEventListener("mouseover", (e) => {
  raf = window.requestAnimationFrame(draw);
});

canvas.addEventListener("mouseout", (e) => {
  window.cancelAnimationFrame(raf);
});
*/

let cancelCanvas: boolean = false;

var is_array = function (value) {
  return value &&
    typeof value === 'object' &&
    typeof value.length === 'number' &&
    typeof value.splice === 'function' &&
    !(value.propertyIsEnumerable('length'));
};