<p-accordion expandIcon="false" collapseIcon="false">
    <p-accordionTab>
        <ng-template pTemplate="header">
            <div class="pretty-setting">Manage Staff
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <p-accordion (multiple)="false" expandIcon="false" collapseIcon="false">

                <div id="staff-list" *ngFor="let staff of allStaff; let i = index">

                    <p-accordionTab (selected)="staff.selected">
                        <ng-template pTemplate="header">
                            <div class="pretty-setting">
                                {{staff.firstName + " " + staff.lastName + " " +staff.email}}

                            </div>
                        </ng-template>
                        <ng-template pTemplate="content">
                            <div fxFlex fxLayout="column" fxLayoutAlign="none none" style="width: 100%;">

                                <p-selectButton [options]="enabledOptions"
                                    [(ngModel)]="staff.creds[staff.credIndex].enabled.staff" optionLabel="name"
                                    optionValue="code" (onChange)="updateCreds(staff)"></p-selectButton>

                                <div *ngIf="staff.creds[staff.credIndex].enabled.staff==true">
                                    <div style="width: 100%; margin: 12px; padding: 12px;" fxLayout="row wrap">
                                        <div *ngIf="global.myCompany.enabled.chatService" style="text-align:center"
                                            fxLayout="column" fxLayoutAlign="center none" class="sel-button">Chat
                                            Service
                                            <p-selectButton [options]="yesNoOptions"
                                                [(ngModel)]="staff.creds[staff.credIndex].enabled.chatService"
                                                optionLabel="name" optionValue="code"
                                                (onChange)="updateCreds(staff)"></p-selectButton>

                                        </div>
                                        <div *ngIf="staff.creds[staff.credIndex].enabled.chatService" fxLayout="column"
                                            fxLayoutAlign="center none" class="sel-button">
                                            Chat Notifications
                                            <p-selectButton [options]="yesNoOptions"
                                                [(ngModel)]="staff.creds[staff.credIndex].notify.chat"
                                                optionLabel="name" optionValue="code"
                                                (onChange)="updateCreds(staff)"></p-selectButton>

                                        </div>
                                        <div *ngIf="global.myCompany.enabled.reservations" style="text-align:center"
                                            fxLayout="column" fxLayoutAlign="center none" class="sel-button">
                                            Reservations
                                            <p-selectButton [options]="yesNoOptions"
                                                [(ngModel)]="staff.creds[staff.credIndex].enabled.reservations"
                                                optionLabel="name" optionValue="code"
                                                (onChange)="updateCreds(staff)"></p-selectButton>
                                        </div>
                                        <div *ngIf="global.myCompany.enabled.media" style="text-align:center"
                                            fxLayout="column" fxLayoutAlign="center none" class="sel-button">Media
                                            <p-selectButton [options]="yesNoOptions"
                                                [(ngModel)]="staff.creds[staff.credIndex].enabled.media"
                                                optionLabel="name" optionValue="code"
                                                (onChange)="updateCreds(staff)"></p-selectButton>
                                        </div>

                                        <div *ngIf="global.myCompany.enabled.appBuilder" style="text-align:center"
                                            fxLayout="column" fxLayoutAlign="center none" class="sel-button">App
                                            Builder
                                            <p-selectButton [options]="yesNoOptions"
                                                [(ngModel)]="staff.creds[staff.credIndex].enabled.appBuilder"
                                                optionLabel="name" optionValue="code"
                                                (onChange)="updateCreds(staff)"></p-selectButton>
                                        </div>
                                    </div>



                                </div>
                                <div *ngIf="staff.creds[staff.credIndex].enabled.staff==true" fxLayout="column"
                                    fxLayoutAlign="center none">
                                    <div *ngIf="staff.creds[staff.credIndex].departments">
                                        Departments assigned:
                                    </div>
                                    <div *ngIf="staff.creds[staff.credIndex].departments" fxFlex fxLayout="row wrap"
                                        fxLayoutAlign="none space-between">

                                        <div *ngFor="let d of staff.creds[staff.credIndex].departments; let i = index;">
                                            <div class="command" (click)="RemoveDepartment(staff, d)">{{d}}</div>
                                        </div>
                                    </div>


                                    Departments not asssigned:
                                    <!-- DEPARTMENT ASSIGNS -->
                                    <div *ngIf="global.myCompany.departments" fxFlex fxLayout="row wrap"
                                        fxLayoutAlign="none space-between">

                                        <div *ngFor="let t of global.myCompany.departments; let i = index;">
                                            <div *ngIf="!staff.creds[staff.credIndex].departments || (staff.creds[staff.credIndex].departments && !staff.creds[staff.credIndex].departments.includes(t))"
                                                class="command" (click)="AddDepartment(staff, t)">{{t}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>

                    </p-accordionTab>


                </div>
            </p-accordion>




            Add Staff:
            <div fxLayout="row">
                <div style="width:100%;">
                    <input type="email" style="width:100%; max-width:400px; " pInputText placeholder="Email"
                        [(ngModel)]="staffEmail">
                </div>
                <div *ngIf="!inputCredsnoUser" class="command" style="width:100%; max-width:120px;"
                    (click)="Clicked($event.target.innerHTML)">Add Staff</div>

            </div>
            <div *ngIf="inputCredsnoUser">
                <p>First & Last Name</p>
                <div fxLayout="row" style="width:100%;">
                    <input style="width:30%!important;" type="text" pInputText [(ngModel)]="staffFirstName">
                    <input style="width:40%!important;" type="text" pInputText [(ngModel)]="staffLastName">
                    <div class="command" style="width:30%; max-width:120px;" (click)="Clicked($event.target.innerHTML)">
                        Add Staff</div>
                </div>

            </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>