import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Globals } from 'src/app/globals';
import { GoogleBusinessInfo } from './company-interface';
import { User, Follows, Notify, Creds, UserFeatures } from './user-interface'
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { CustomizeMessageService, MessageState } from './customize-message.service';
import { DocumentReference, Timestamp } from 'firebase/firestore';
import { NavService } from '../components/menu/nav.service';
import { Company, Form, CodeObject, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from './company-interface';
//import { prepareSyntheticPropertyName } from '@angular/compiler/src/render3/util';
import { Logger } from '../functions';
import { FormService } from './form.service';
import { set, startOfDay, addDays, endOfDay, getMilliseconds } from "date-fns";
import { tick } from '@angular/core/testing';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  userRef: firebase.firestore.DocumentReference;
  companyRef: firebase.firestore.DocumentReference;
  resSettings: ReservationSettings;
  googleInfo: GoogleBusinessInfo;
  user: User; // internal tmp user


  constructor(public global: Globals, public nav: NavService,
    private formService: FormService,
    private customizeMessageService: CustomizeMessageService) {
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER
    console.log("Construct Reg Server");
  }


  login(form: Form) {
    var self = this;

    self.global.displayMenu = false;

    console.log("forms:", self.global.allForms);
    var email, pw: string;

    email = self.user.email;
    pw = form.fields[0].binding;

    console.log("LOGIN EMAIL; ", email);

    var db = firebase.firestore();

    db.collection("users").where("email", "==", email).where("pw", "==", pw)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          Logger("Password Error", email, pw);  // Hack here is where I would add bad attempt counter 

        }
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          console.log("user ", data)
          self.global.authuser = <User>{ ...data };

          Logger("Login", self.global.authuser.uid, "", data.firstName, data.lastName)

          localStorage.setItem('uid', data.uid);

          // Now enable the listener for User
          //  self.GetUserwID(self.global.authuser.uid);

        });
      })
      .catch((error) => {

        console.log("no user found: ", error);
      });

  }




  GetUID() {
    var db = firebase.firestore();

    const docRef = db.collection('users').doc();

    this.userRef = docRef;
    return docRef.id;

  }


  GetCompanyUID() {
    var db = firebase.firestore();

    const docRef = db.collection('company').doc();

    this.companyRef = docRef;
    return docRef.id;

  }

  RegisterUser(email, firstName, lastName, sendEmail?: boolean, callBack?: any, mySelf?: any) {
    var self = this;
    // This function assumes email and name are validated
    // Also presumes that email is not already registered
    // Test: all failure routes.
    sendEmail = sendEmail || true;

    // HACK HACK!!
    // this function assumes self.userRef has been set by calling function


    email = email.toLowerCase();

    var db = firebase.firestore();
    var store = "none";
    var storeID = "none";
    var storeNum = "none";
    var storeName = "none";

    console.log("Register ", self.global);
    if (self.global.myCompany) {
      storeID = self.global.myCompany.id;
      storeNum = self.global.myCompany.store;
      storeName = self.global.myCompany.name;
      store = self.global.store;  //??



      var follow: Follows[] = [{
        id: storeID,     // remove later
        storeNum: storeNum,    // fix name
        points: 200,  // Company sets this
        startDate: Date.now(),
        storeName: storeName

      }]
      follow = JSON.parse(JSON.stringify(follow));
      console.log("Follow: ", follow, store, storeNum);
    }
    const n = (Math.random() * 900) + 100;

    var newStoreInt = Math.trunc(n);



    var newUser: User = {
      uid: self.userRef.id,
      sid: self.userRef.id.substring(0, 5),
      createStamp: Timestamp.now(),
      email: email,
      firstName: firstName,
      lastName: lastName,
      photoURL: "",
      emailVerified: false,    //user.emailVerified,
      staff: false,
      keyCode: newStoreInt,
      pw: "",
      uPoints: 0,
      userFeature: { myQuotes: true, myMedia: false, myChats: false, myGames: false },
    }
    if (follow) {
      newUser.following = JSON.parse(JSON.stringify(follow));

    }

    var w1 = getRandomInt(this.nav.letterWords.length);
    var w2 = getRandomInt(this.nav.letterWords.length);

    newUser.pw = (this.nav.letterWords[w1] + this.nav.letterWords[w2]).toLowerCase();

    //self.global.authuser = Object.create(newUser);


    console.log("User: ", newUser);

    // HAVE NEW USER REGISTRATION:
    // CHECK FOR EXISTING
    var docRef = db.collection("users").where("email", "==", email);
    docRef.get().then((doc) => {
      //      ((querySnapshot) => {
      if (!doc.empty) {
        //    querySnapshot.forEach((doc) => {
        //const data = <User>doc.data();
        //self.companyID = data.id;
        //

        self.customizeMessageService.msg = "Email already in use.";
        self.customizeMessageService.show();

        console.log("Email already associated with account");
        // Auto-Login User?? - get password - use phone??

        //   });
      }
      else {
        console.log("no account found, so add it ", self.userRef.id);
        //   var docRef = db.collection("users").doc();
        newUser.uid = self.userRef.id;
        //move code up 1      self.cartService.uid = docRef.id; // THE ACTUAL ID used for orders


        self.userRef.set(     //{  // AUTO-ID DOC
          newUser,
        )
          .then(() => {
            Logger("New User", newUser.uid, "", newUser.firstName, newUser.lastName)


            self.customizeMessageService.summary = "Rewards!!";
            self.customizeMessageService.msg = "You're all set. 200 points added to account!";
            self.customizeMessageService.show();
            // authuser created with register or loaded
            self.global.authuser = Object.create(newUser);
            self.global.authuser = { ...newUser }

            console.log("New user added ", self.global.authuser);
            localStorage.setItem('uid', newUser.uid);
            //HACK HACK HACK
            if (sendEmail == true) {
              self.sendRegisterEmail(newUser);
              console.log("email: ", sendEmail)
            }

            //  self.nav.getReservations("4390");

            if (typeof callBack == "function") {
              callBack(mySelf, newUser);
            }
            //  self.nav.GetUserwID(newUser.uid);  //hack




          })
          .catch((error) => {
            //     self.customizeMessageService.msg = "Error creating user. " + error;
            //     self.customizeMessageService.show();
            console.error("Error writing document: ", error);
            //      self.global.errorMsg = "FB add user: " + error;
          });

      }
    })
    //  }


    //   Log ("New user", user.uid, company, user.email); 

  }


  JustRegisterUser(userRef, email, firstName, lastName, sendEmail?: boolean, rewardPoints?: number, callBack?: any, mySelf?: any) {
    var self = this;
    // This function assumes email and name are validated
    // Also presumes that email is not already registered
    // Test: all failure routes.
    sendEmail = sendEmail || false;
    rewardPoints = rewardPoints || 0;


    email = email.toLowerCase();

    var db = firebase.firestore();
    var store = "none";
    var storeID = "none";
    var storeNum = "none";
    var storeName = "none";

    console.log("Register ", this.global);
    if (this.global.myCompany) {
      storeID = this.global.myCompany.id;
      storeNum = this.global.myCompany.store;
      storeName = this.global.myCompany.name;
      store = this.global.store;  //??
    }

    var follow: Follows[] = [{
      id: storeID,     // remove later
      storeNum: storeNum,    // fix name
      points: rewardPoints,  // Hack Company sets this
      startDate: Date.now(),
      storeName: storeName

    }]
    const n = (Math.random() * 900) + 100;

    var newStoreInt = Math.trunc(n);

    follow = JSON.parse(JSON.stringify(follow));
    console.log("Follow: ", follow, store, storeNum);
    var newUser: User = {
      createStamp: Timestamp.now(),
      uid: "",
      sid: "",
      email: email,
      firstName: firstName,
      lastName: lastName,
      photoURL: "",
      emailVerified: false,    //user.emailVerified,
      companyID: this.global.myCompany.id,       // this needs to be removed
      staff: false,
      following: follow,
      keyCode: newStoreInt,
      pw: "",
      uPoints: 0,
      userFeature: { myQuotes: true },
    }

    var w1 = getRandomInt(this.nav.letterWords.length);
    var w2 = getRandomInt(this.nav.letterWords.length);

    newUser.pw = (this.nav.letterWords[w1] + this.nav.letterWords[w2]).toLowerCase();

    console.log("User: ", newUser);


    //     const docRef = db.collection('users').doc();
    newUser.uid = userRef.id;
    newUser.sid = newUser.uid.substring(0, 5);
    userRef.set(newUser)
      .then(() => {
        Logger("New User", newUser.uid, "", newUser.firstName, newUser.lastName)

        if (typeof callBack == "function") {
          callBack(mySelf, newUser);
        }

        //HACK HACK HACK
        if (sendEmail == true) {
          self.sendRegisterEmail(newUser);
          console.log("email: ", sendEmail)
        }
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });




  }








  RegisterStaff(email, firstName: string, lastName: string, storeNum: string, companyID: string, storeName, callBack?: any, mySelf?: any) {
    var self = this;
    email = email.toLowerCase();

    console.log("Register Staff ", email);
    const n = (Math.random() * 900) + 100;

    var newStoreInt = Math.trunc(n);

    var db = firebase.firestore();

    const docRef = db.collection('users').doc();

    var notify: Notify = { enable: true, chat: true };

    var creds: Creds[] = [{
      id: companyID,
      storeNum: storeNum,
      role: "Staff",
      startDate: Date.now(),
      storeName: storeName,
      notify: notify,
      enabled: { staff: true }
    }]

    var newUser: User = {
      createStamp: Timestamp.now(),
      uid: docRef.id,
      creds: creds,
      sid: docRef.id.substring(0, 5),
      email: email,
      firstName: firstName,
      lastName: lastName,
      emailVerified: true,
      staff: true,
      pw: "",
      userFeature: { myQuotes: true, myMedia: true, myChats: true, myGames: true },
    }

    var w1 = getRandomInt(this.nav.letterWords.length);
    var w2 = getRandomInt(this.nav.letterWords.length);

    newUser.pw = (this.nav.letterWords[w1] + this.nav.letterWords[w2]).toLowerCase();
    console.log("User: ", newUser);

    // HAVE NEW USER REGISTRATION:
    // CHECK FOR EXISTING
    docRef.set(newUser).then(() => {
      Logger("New Staff", newUser.uid, "", newUser.firstName, newUser.lastName)

      console.log("chat Staff added");
      if (typeof callBack == "function") {
        callBack(mySelf, newUser);
      }
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });


  }




  RegisterChatServiceUser(email, docRef: any, firstName?: string, lastName?: string, callBack?: any, mySelf?: any) {
    var self = this;
    email = email.toLowerCase();

    console.log("Register Chat Service user ", email, docRef);
    const n = (Math.random() * 900) + 100;

    var newStoreInt = Math.trunc(n);

    var newUser: User = {
      createStamp: Timestamp.now(),
      uid: docRef.id,
      sid: docRef.id.substring(0, 5),
      email: email,
      firstName: firstName,
      lastName: lastName,
      emailVerified: true,
      staff: true,
      pw: "",
    }

    var w1 = getRandomInt(this.nav.letterWords.length);
    var w2 = getRandomInt(this.nav.letterWords.length);

    newUser.pw = (this.nav.letterWords[w1] + this.nav.letterWords[w2]).toLowerCase();
    console.log("User: ", newUser);

    // HAVE NEW USER REGISTRATION:
    // CHECK FOR EXISTING
    docRef.set(newUser).then(() => {

      console.log("chat Service user added");
      if (typeof callBack == "function") {
        //  localStorage.setItem('uid', newUser.uid);
        //   self.global.authuser = Object.create(newUser);

        callBack(mySelf);
      }
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });


  }




  RegisterChatUser(email, docRef: any, firstName?: string, lastName?: string, callBack?: any, mySelf?: any) {
    var self = this;

    email = email.toLowerCase();

    var db = firebase.firestore();

    console.log("Register Chat user ", firstName, lastName, this.global);
    const n = (Math.random() * 900) + 100;

    var newStoreInt = Math.trunc(n);

    var newUser: User = {
      createStamp: Timestamp.now(),
      uid: docRef.id,
      sid: docRef.id.substring(0, 5),
      email: email,
      firstName: "",
      lastName: "",
      photoURL: "",
      emailVerified: false,    //user.emailVerified,
      staff: false,
      keyCode: newStoreInt,
      pw: "",
      uPoints: 0,
      userFeature: { myQuotes: true, myMedia: true, myChats: true, myGames: true },
    }
    if (firstName) newUser.firstName = firstName;
    if (lastName) newUser.lastName = lastName;




    var w1 = getRandomInt(this.nav.letterWords.length);
    var w2 = getRandomInt(this.nav.letterWords.length);

    newUser.pw = (this.nav.letterWords[w1] + this.nav.letterWords[w2]).toLowerCase();
    console.log("User: ", newUser);

    // HAVE NEW USER REGISTRATION:
    // CHECK FOR EXISTING
    docRef.set(newUser).then(() => {
      Logger("New User", newUser.uid, "", newUser.firstName, newUser.lastName)


      console.log("chat user added");
      if (typeof callBack == "function") {
        localStorage.setItem('uid', newUser.uid);
        self.global.authuser = Object.create(newUser);

        callBack(mySelf);
      }
    })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });


  }



  RegisterNewUser(email, firstName, lastName, storage?: boolean) {
    var self = this;
    // This function assumes email and name are validated
    // Also presumes that email is not already registered
    // Test: all failure routes.


    email = email.toLowerCase();

    var db = firebase.firestore();
    const n = (+Math.random() * 899) + 100;

    var newStoreInt = Math.trunc(n);


    var newUser: User = {
      uid: self.userRef.id,
      createStamp: Timestamp.now(),
      email: email,
      firstName: firstName,
      lastName: lastName,
      photoURL: "",
      emailVerified: false,    //user.emailVerified,
      //      companyID: store,
      staff: false,
      //      following: follow,
      keyCode: newStoreInt,
      pw: "",
      uPoints: 0,
      userFeature: { myQuotes: true, myMedia: false, myChats: false, myGames: false },
    }

    var w1 = getRandomInt(this.nav.letterWords.length);
    var w2 = getRandomInt(this.nav.letterWords.length);

    newUser.pw = (this.nav.letterWords[w1] + this.nav.letterWords[w2]).toLowerCase();

    console.log("User: ", newUser);

    // HAVE NEW USER REGISTRATION:
    // CHECK FOR EXISTING
    var docRef = db.collection("users").where("email", "==", email);
    docRef.get().then((doc) => {
      //      ((querySnapshot) => {
      if (!doc.empty) {
        //    querySnapshot.forEach((doc) => {
        //const data = <User>doc.data();
        //self.companyID = data.id;
        //

        self.customizeMessageService.msg = "Email already in use.";
        self.customizeMessageService.show();

        console.log("Email already associated with account");
        // Auto-Login User?? - get password - use phone??

        //   });
      }
      else {
        console.log("no account found, so add it");
        //   var docRef = db.collection("users").doc();
        newUser.uid = self.userRef.id;
        newUser.sid = self.userRef.id.substring(0, 5);
        //move code up 1      self.cartService.uid = docRef.id; // THE ACTUAL ID used for orders


        self.userRef.set(     //{  // AUTO-ID DOC
          newUser,
        )
          .then(() => {
            Logger("New User", newUser.uid, "", newUser.firstName, newUser.lastName)

            self.customizeMessageService.summary = "Rewards!!";
            self.customizeMessageService.msg = "You're all set. 200 points added to account!";
            self.customizeMessageService.show();
            // authuser created with register or loaded
            self.global.authuser = Object.create(newUser);

            console.log("New user added ", self.global.authuser);
            if (storage)
              localStorage.setItem('uid', newUser.uid);
            self.sendRegisterEmail(newUser);

            // Start the Listener for User
            self.nav.GetUserwID(newUser.uid);




          })
          .catch((error) => {
            //     self.customizeMessageService.msg = "Error creating user. " + error;
            //     self.customizeMessageService.show();
            console.error("Error writing document: ", error);
            //      self.global.errorMsg = "FB add user: " + error;
          });

      }
    })
    //  }


    //   Log ("New user", user.uid, company, user.email); 

  }

  getUniqueStoreNum() {
    var db = firebase.firestore();

    var unique = true;
    do {
      const n = (Math.random() * 9000) + 1000;
      var newStoreInt = Math.trunc(n).toString();
      // See if it's being used

      var docRef = db.collection("company").where("store", "==", newStoreInt);
      docRef.get().then((doc) => {
        if (!doc.empty) { unique = false; console.log("Fixed repeat code") }
      })
    } while (!unique)

    return newStoreInt;
  }



  startFreeTrial(trialType, form: Form, callback?: any, mySelf?: any) {
    var self = this;
    var services: Enabled;
    var newStore: Company = { id: "" };

    self.formService.napkinFieldsToObject(form, newStore)

    const dur: Duration = {
      name: "2 days",
      min: 48 * 60
    }

    // self.geoCode(address, city, state, zipcode);
    services = {
      appBuilder: true,
      groupBookings: false,
      pos: false,
      orders: false,
      products: false,
      reservationRewards: false,
      reservations: false,
      rewards: false,
      chatService: false,
      media: true,
      reviews: false,
      reviewsPro: false,  // Implement limits (includes app creator??)
      manageStaff: true,
      reports: true,
      forms: true,
      queries: true,
      visualPlayground: true,
      publicApps: false,    // Implement (days/hours/Google Info)



    }
    var newStoreInt = self.getUniqueStoreNum();

    var trialEnd = endOfDay(addDays(Date.now(), 30)).getTime();
    console.log ("END OF TRIAL ", trialEnd)
    var newCompany: Company = {
      ...newStore,
      store: newStoreInt,
      createdAt: Date.now(),
      createdBy: self.global.authuser.uid,
      enabled: services,
      trialMode: {status:"active", str: "30 day trial", end: trialEnd}
    }


    // STRIP NULLS all empty fields
    newCompany = removeUndefined(newCompany)

    console.log("Register New Store ", newCompany)

    // NEW Store REGISTRATION:
    // CHECK FOR EXISTING
    var db = firebase.firestore();

    var docRef = db.collection("company").where("name", "==", newCompany.name);
    docRef.get().then((doc) => {
      if (!doc.empty) {
        // That Store name is already used:
        // Need a policy and feedback on this
        // What about same name but different locations????

      }
      else {
        console.log("no account found, so add it");
        var newRef = db.collection("company").doc();
        newCompany.id = newRef.id;
        newRef.set(newCompany)
          .then(() => {
            Logger("New Store", self.global.authuser.uid, newCompany.store, newCompany.name, newCompany.address1 + " " + newCompany.city + " " + newCompany.state)
            self.geoCode(newCompany.id, newCompany.address1, newCompany.city, newCompany.state, newCompany.postal)
            self.updateCreds (newCompany);
            // Let's call any callback function at end here
            if (typeof callback == "function")
              callback(mySelf);
            //    console.log("New user added ", self.global.authuser );
            //    localStorage.setItem('uid', newUser.uid);
            //    self.sendRegisterEmail(newUser);
          })
          .catch((error) => {
            console.error("Error adding store: ", error);
          });

      }
    })


  }






  /* CREATE NEW Company/Store */

  registerStore(services: Enabled, storeFields: any, callback?: any, mySelf?: any, chatService?: string, chatServiceEmail?: string) {
    var self = this;

    const dur: Duration = {
      name: "2 days",
      min: 48 * 60
    }

    const groupBook: GroupSettings = {
      minParty: 12,
      maxParty: 30,
      serviceCharge: 18,
      minAdvNotice: dur,

    }



    var newCompany: Company = {
      ...storeFields,
      createStamp: Timestamp.now(),
      id: this.companyRef.id,
      //      reserveSettings: self.global.myCompany.reserveSettings,
      palette1: [],
      palette1Names: [],
      address2: "",
      stripeAccountID: "",
      enabled: services,
      groupSettings: groupBook,
    }

    if (chatService) newCompany.chatService = chatService;
    if (chatServiceEmail) newCompany.chatServiceEmail = chatServiceEmail;

    // STRIP NULLS all empty fields
    newCompany = removeUndefined(newCompany)


    // NEW Store REGISTRATION:
    // CHECK FOR EXISTING
    var db = firebase.firestore();
    console.log("register: ", storeFields.store, newCompany);
    var docRef = db.collection("company").where("name", "==", storeFields.name);
    docRef.get().then((doc) => {


      if (!doc.empty) {

      }
      else {
        console.log("no account found, so add it");
        self.companyRef.set(
          newCompany
        )
          .then(() => {
            Logger("New Store", self.global.authuser.uid, newCompany.store, newCompany.name, newCompany.address1 + " " + newCompany.city + " " + newCompany.state)

            // authuser created with register or loaded
            // self.global.authuser = Object.create(newUser);
            /* SHOULD BE DONE WITH ADMIN LOGIN INSTEAD OF HERE
                    self.global.myCompany = Object.create(newCompany);
                    if (services.reservations)
                      self.nav.InitReservations();
            */

            // Let's call any callback function at end here
            if (typeof callback == "function")
              callback(mySelf);
            //    console.log("New user added ", self.global.authuser );
            //    localStorage.setItem('uid', newUser.uid);
            //    self.sendRegisterEmail(newUser);


            // self.nav.GetUserwID(newUser.uid);

          })
          .catch((error) => {
            //     self.customizeMessageService.msg = "Error creating user. " + error;
            //     self.customizeMessageService.show();
            console.error("Error writing document: ", error);
            //      self.global.errorMsg = "FB add user: " + error;
          });

      }
    })
  }

  public sendRegisterEmail(user: User) {
    var self = this;



    var sender = "Napkin";
    if (self.global.myCompany)
      sender = self.global.myCompany.name;

    var templateParams = {
      from_name: sender,  // Company Name
      to_name: user.firstName, //this.global.userName,
      to_email: user.email,
      code: user.keyCode.toString() + user.sid,
      message: 'Thanks for registering.'
    };
    console.log("Reg EMAIL: ", user, templateParams);
    //  e.preventDefault();

    emailjs.send("service_jwders9", "template_fsas3w8", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {

        console.log("email err: ", error.text);
      });


  }

  /*
    VerifyEmail() {
      var self = this;
  
      console.log("VERIFY: ", this.validation);
      if (this.validation == this.global.authuser.keyCode) { // MATCH
        console.log("Verified");
        this.global.authuser.emailVerified = true;
        //   localStorage.setItem('uid', JSON.stringify(this.user.uid));
  
        var db = firebase.firestore();
  
        var catRef = db.collection("users").doc(this.global.authuser.uid);
        if (catRef) {
          catRef.update({
            "emailVerified": true
          });
          this.sendEmailVerified();
          // Set localstorage to reflect authenticated device
        }
  
        this.router.navigate(['menu']);
      }
    }
    */

  // ADD AUTO-PASSWORD to this email.
  // Also allow user to change password once verified
  public sendEmailVerified(email) {
    var templateParams = {
      from_name: 'Crystal Hill Vineyard',  // Company Name
      to_name: this.global.userName,
      to_email: email,
      code: this.global.authuser.keyCode,  // ADD BAD CODE ATTEMPT COUNTER
      pw: this.global.authuser.pw,
      sid: this.global.authuser.sid,
      message: 'Welcome to Crystal Hill Vineyard.'
    };
    // add later  this.cartService.sendMsg("Verified email " + this.email, this.global.tableNameQR);


    console.log("EMAIL: ", this.global.userName, email, this.global.authuser.pw);
    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_j23qemn", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  fetchGooglePhotos() {

  }


  FetchGoogleInfo() {
    var self = this;

    var str = "https://local-business-data.p.rapidapi.com/search?query=";
    str += self.global.myCompany.name;
    if (self.global.myCompany.city) {
      str += " " + self.global.myCompany.city;
      str += "," + self.global.myCompany.state;
    }
    str += "&limit=1&language=en&region=us"
    //'https://local-business-data.p.rapidapi.com/search?query=d'carlos%20ramona%2C%20ca&limit=1&language=en&region=us'

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'local-business-data.p.rapidapi.com'
      }
    };


    console.log("Getting google info ", str)
    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        self.googleInfo = <GoogleBusinessInfo>response.data[0];
        console.log("Google: ", self.googleInfo);
        // Update Company record with Google Info
        self.global.myCompany.googleBusinessInfo = self.googleInfo;
        self.UpdateGoogleInfo();


      })
      .catch(err => console.error(err));

  }

  emailLookup(email: string, callBack: any, mySelf: any) {
    var self = this;

    var db = firebase.firestore();

    console.log("EMAIL LOOKUP")

    db.collection("users").where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          if (typeof callBack == "function") {
            callBack(mySelf, null); // nothing found
          }
        }
        querySnapshot.forEach((doc) => {
          self.user = <User>doc.data();
          if (typeof callBack == "function") {
            callBack(mySelf, self.user);
          }

        });
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });

  }

  emailTemplate(html) {
    var self = this;

    var templateParams = {
      from_name: self.global.authuser.firstName + " " + self.global.authuser.lastName,
      to_name: self.global.authuser.firstName,
      to_email: self.global.authuser.email,
      message: html
    };


    // console.log("Sending Email ",templateParams.message);

    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_yy94vla", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }







  emailForms(code: CodeObject, form: Form, email) {
    var self = this;

    var templateParams = {
      from_name: self.global.authuser.firstName + " " + self.global.authuser.lastName,
      to_name: self.global.myCompany.name,
      to_email: self.global.myCompany.chatServiceEmail,
      message: 'New form submission\n'
    };

    // self.global.allForms.forEach(function (form) {
    //   if (form.name == code.fields[0]) {
    console.log("Match: ", form)
    form.fields.forEach(function (field) {
      var fieldName = field.title;
      if (field?.label) fieldName = field.label
      templateParams.message += fieldName + ": " + field.binding + "\n"
    })
    //  }
    // })
    console.log("Sending FORM Email ", templateParams.message);

    //  e.preventDefault();
    emailjs.send("service_jwders9", "template_6t9k8ta", templateParams)
      .then((result: EmailJSResponseStatus) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
  }

  updateCreds(newCompany: Company) {
    var self = this;
    var db = firebase.firestore();
    var ref = db.collection("users").doc(this.global.authuser.uid);

    var notify: Notify = { enable: true, reservations: true };
    var enable: Enabled = { staff: true }
 //   if (self.services.reviews == true) {
 //     notify.reviews = true;
 //     enable.reviews = true;
//    }

    var creds: Creds = {
      id: newCompany.id,
      storeNum: newCompany.store,
      role: "Creator",
      startDate: Date.now(),
      storeName: newCompany.name,
      notify: notify,
      enabled: enable,
    }

    var follow: Follows = {
      id: newCompany.id,     // remove later
      storeNum: newCompany.store,    // fix name
      points: 200,  // Company sets this
      startDate: Date.now(),
      storeName: newCompany.name
    }

    console.log("Set Creds: ", creds, self.global.authuser.uid, notify)

    ref.update({
      creds: firebase.firestore.FieldValue.arrayUnion(creds),
      following: firebase.firestore.FieldValue.arrayUnion(follow)
    })
      .then(() => {
        console.log("Creds updated: ");

      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });
  }



  UpdateGoogleInfo() {
    var self = this;
    var db = firebase.firestore();

    this.updateDaysHours();


    var ref2 = db.collection("company").doc(this.global.myCompany.id);
    ref2.update({
      hasGoogleInfo: true,
      googleBusinessInfo: self.googleInfo
    })
      .then(() => {
        console.log("G-info added.");
      })
      .catch((error) => {
        console.error("Error in media", error);
      });

  }

  updateLatLong(id, lat: number, long: number) {
    var self = this;

    // if (!id) this.newCompanyID = this.global.myCompany.id
    var db = firebase.firestore();
    var ref = db.collection("company").doc(id);

    if (lat) {
      self.global.mapOptions = {
        center: { lat: lat, lng: long },
        zoom: 14
      }
    }

    ref.update({
      lat: <number>lat,
      long: <number>long,
    })
      .then(() => {
        console.log("latlong settings updated: ");
      })
      .catch((error) => {
        console.error("Error writing latlong settings: ", error);
      });
  }






  geoCode(id, adr, city, state, zip) {
    var self = this;


    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'forward-reverse-geocoding.p.rapidapi.com'
      }
    };

    var str = 'https://forward-reverse-geocoding.p.rapidapi.com/v1/forward?street=' + adr + '&city=' + city + '&state=' + state + '&postalcode=' + zip + '&accept-language=en&polygon_threshold=0.0';
    console.log("GEOCODE ",str)
    fetch(str, options)
      .then(response => response.json())
      .then(response => {
        console.log("GEO: ", response);
        if (response[0] && response[0].lat) {
          var lat: number = Number(response[0].lat);
          var lon: number = Number(response[0].lon);
          self.updateLatLong(id, lat, lon);
        }
      })
      .catch(err => console.error(err));

  }

  updateDaysHours() {

    return;

    // If hours and Days not set then copy from Google.
    if (!this.global.myCompany.workingDays) {

      const dash = this.global.myCompany.googleBusinessInfo.working_hours

      console.log("Sunday: ", dash.Sunday, dash.Monday)





    }

    /*
    
      "global.openDays[i]" style="margin-bottom: 8px;">
      global.allWeek[i].resOpenTime"
    */

  }


}
function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
