import { Component } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Globals } from 'src/app/globals';
import { Company, Gallery, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate } from '../company-interface';
import firebase from 'firebase/compat/app';
import { environment } from '../../../environments/environment';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { User, Contact, Results, Kpanel, Message, msg_Groups, Video, VideoFile, Chat } from '../user-interface';
import { RegisterService } from '../register.service';
import { Quote } from '../content-interface';



@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css']
})
export class JoinComponent {

  registered: boolean = false;
  quote: Quote;
  qrCodeAPI: string = "http://api.qrserver.com/v1/create-qr-code/?data=";
  storeNum: string;

  constructor(public global: Globals, private router: Router,
    public registerService: RegisterService,
    private route: ActivatedRoute) {
    this.storeNum = this.route.snapshot.paramMap.get('store')
    console.log("Store: ", this.storeNum);
    // If User already Registered then auto-follow this store
    // if no user, then login/register
    // Load Store to display logo etc.
    firebase.initializeApp(environment.firebase);
    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER

    this.loadStore(this.storeNum);


  }

  getJoinCode () {

    return this.qrCodeAPI + 'napkinhq.com/join/' + this.storeNum + '&size=200x200';
  }

  loadStore (storeNum) {

    var db = firebase.firestore();
    var self = this;

    db.collection("company").where("store", "==", storeNum)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <Company>doc.data();
          self.global.myCompany = <Company>doc.data();

        })
      })


  }
contact: Contact;

    // NEW USER REGISTRATION
    setContact(info: Contact) {
      // After User has created account
      const db = firebase.firestore();
      var self = this;
  
      console.log("User contact: ", info);
  
      self.contact = info;
      if (!self.global.authuser) {
  
        if (!this.global.authuser) {
          if (!self.contact.firstName || self.contact.firstName.length == 0
            || !self.contact.lastName || self.contact.lastName.length == 0
          ) { alert("Please enter a name."); return; }
          self.registerService.userRef = db.collection('users').doc(); //hack
          self.registerService.RegisterUser(info.email, info.firstName, info.lastName),true;
          self.registered = true;
          this.GetInspiration();
        }
      }
    }

    afterLogin() {
      console.log("User has logged in: ", this.global.authuser);

        this.router.navigate(['home']);
    
    
    }

    GetInspiration() {
      var self = this;
      var db = firebase.firestore();
  
      var r = <number>Math.floor(Math.random() * 1644);
  
      db.collection("quotes").where("index", "==", r)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = <Quote>doc.data();
            self.quote = data;
          })
        })
  
  
    }

  

}
