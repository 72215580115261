<div style="max-width: 100vw; padding: 6px; margin:6px">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button (click)="addTheme($event)" class="create-form" pButton pRipple type="button">
            Create New Theme
        </button>
    </div>
    <div *ngIf="global.allThemes">
        <p-accordion styleClass="tab" [multiple]="false" expandIcon="false" collapseIcon="false"
            *ngFor="let theme of global.allThemes; let i = index;" (onOpen)="onThemeOpen($event, i)"
            (onClose)="onThemeClose($event, i)">

            <p-accordionTab>
                <ng-template pTemplate="header">
                    <div fxLayout="row" fxLayoutAlign="none none">
                        <div class="chat-row">
                            {{theme.name}}
                        </div>
                    </div>

                </ng-template>
                <ng-template pTemplate="content">
                    <div *ngIf="!global.allChatBots">
                        <app-form formName="masterThemeDesign" [themeId]="theme.id" [formInput]="theme"
                            [autoSubmit]="false" 
                            (objectEvents)="fieldEvents($event)"
                            (submitEvent)="outputEvent($event)"></app-form>
                    </div>
                </ng-template>
            </p-accordionTab>
        </p-accordion>

    </div>
</div>