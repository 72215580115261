import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, ComponentFactory, AfterViewInit } from '@angular/core';
import { Company } from "../company-interface";
import { Globals } from '../../globals';
import { SidebarModule } from 'primeng/sidebar';
import { SelectItem, PrimeNGConfig, MessageService } from 'primeng/api';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { NgAuthService } from "../../ng-auth.service";
import { PrimeIcons } from 'primeng/api';
import { CustomizeMessageService, MessageState } from '../customize-message.service';
import { NavService } from '../menu/nav.service';
import firebase from 'firebase/compat/app';
import { Creds, Follows, User, Contact } from './../../components/user-interface';
import { RegisterService } from '../register.service';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { Logger } from 'src/app/functions';
import { EmailService } from '../email.service';

@Component({
  selector: 'SignIn',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})




export class signInComponent implements OnInit, AfterViewInit {


  @Output() setContactEvent = new EventEmitter<Contact>();
  @Output() afterLoginEvent = new EventEmitter<Contact>();
  @Input() startText: string = "Register";
  @Input() pwrequired: boolean = true;

  contact: Contact;
  pw: string;
  foundUser: boolean = false;
  inputCreds: boolean = false;
  inputCredsnoUser: boolean = false;
  loginError: boolean = false;
  userDataValid: boolean = false;
  newUserID: string;

  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  forgotStr: string;
  tUser: User;

  constructor(private primeNGConfig: PrimeNGConfig,
    private router: Router, public ngAuthService: NgAuthService,
    public loaderService: CustomizeMessageService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private emailService: EmailService,
    public registerService: RegisterService,
    public nav: NavService,
    public global: Globals
  ) {

    emailjs.init("user_6l1E7RUh8y1kOgRqjQ3cJ");  // INIT EMAIL SERVER


  }



  ngOnInit() {
    var self = this;

    this.primeNGConfig.ripple = true;
    console.log("start text", this.startText)



  }
  ngAfterViewInit(): void {
    var self = this;
    const input = document.querySelector(".emailInput");

    input.addEventListener("input", updateValue);

    function updateValue(e) {


      console.log("change")
      self.inputCreds = false;

    }
  }
  emailChanged() {
    console.log("change")
    this.inputCreds = false;
  }

  checkEmail() {
    var self = this;
    console.log("Checkemail ");
    if (!ValidateEmail(this.Email)) return;


    //  Check email in DB and if not found get rest of info else ask for pw
    var db = firebase.firestore();
    self.foundUser = false;
    if (!this.Email || this.Email.length < 5) return;

    self.inputCreds = false;
    db.collection("users").where("email", "==", self.Email)
      .get()
      .then((querySnapshot) => {

        if (!querySnapshot.empty) {
          self.tUser = <User>querySnapshot.docs[0].data();
          self.forgotStr = null;
          self.foundUser = true;
          self.inputCredsnoUser = null;
        }
        self.inputCredsnoUser = true;
        console.log("Found: ", self.foundUser);

   
        // Pseudo-hack
        // AUTO-LOG IN if entering Review and account created within [1 hour]
        if (self.foundUser == true && self.pwrequired==false) {
          // now check time passsed;
      //    const created = self.tUser.createStamp.toMillis()

     //     const timeSince = Date.now() - created;
     //     console.log ("Auto login - ", timeSince);

      //    if (timeSince < 60 * 60000) {
            self.pw = self.tUser.pw;
            console.log ("Auto login - ");
            self.login();
      //    }

        }
        self.inputCreds = true;

      })

  }

  // REMOVE WHEN SIGNIN component updated
  login() {
    this.global.displayMenu = false;

    var self = this;
    console.log("LOGIN EMAIL; ", this.Email);

    var self = this;

    self.loginError = false;

    var db = firebase.firestore();

    db.collection("users").where("email", "==", self.Email).where("pw", "==", self.pw)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          self.global.authuser = data;
          Logger ("Login", self.global.authuser.uid, "",data.firstName, data.lastName)

          // SKIP for auto-login
          if (self.pwrequired==true)
            localStorage.setItem('uid', data.uid);
      
          self.inputCreds = false;
          self.afterLoginEvent.emit();
        });
      }).finally(function () { 
        console.log("FINALLY!"); 
      //  Logger ("Password Error", this.Email, this.pw);  // Hack here is where I would add bad attempt counter 

       // if (self.inputCreds) { self.loginError = true; } 
      })
      .catch((error) => {
        Logger ("Password Error", self.Email, self.pw);  // Hack here is where I would add bad attempt counter 

        console.log("no user found: ", error);
        self.loginError = true;
      });
  }


  login2() {
    this.global.displayMenu = false;

    var self = this;
    console.log("LOGIN EMAIL; ", this.Email);

    var self = this;

    var db = firebase.firestore();

    db.collection("users").where("email", "==", self.Email).where("pw", "==", self.pw)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = <User>doc.data();
          self.global.authuser = data;
          localStorage.setItem('uid', data.uid);
          //                    self.cartService.GetMyOrder();
          self.nav.GetUserwID(self.global.authuser.uid);

        });
      })
      .catch((error) => {
        console.log("no user found: ", error);
      });
  }

  onResOpen(e, i: number) {
  }

  ForgotPassword() {
    var self = this;

    if (self.foundUser && !self.forgotStr) {
      self.emailService.ForgotPassword(self.Email, self.tUser.firstName,self.tUser.pw)
    }

  }

  RegisterNewUser() {
    var self = this;
    self.contact = {
      email: self.Email,
      firstName: self.FirstName,
      lastName: self.LastName
    }
    console.log("con: ", self.contact);
  //  if (self.setContactEvent)

      self.setContactEvent.emit(self.contact);
      /*
    else {
      if (!this.global.authuser) {
        if (!this.FirstName || this.FirstName.length == 0
          || !this.LastName || this.LastName.length == 0
        ) { alert("Please enter a name."); return; }
        if (!ValidateEmail(this.Email)) return;
        // if (!validatePhoneNumber(this.Phone)) return;
        self.newUserID = self.registerService.GetUID();
        self.registerService.RegisterUser(this.Email, this.FirstName, this.LastName);
        if (!this.newUserID) return;
      }
    }*/
  }

  validateUserData() {
    console.log("Validating info");
    // VALIDATE INFO
    if (!this.global.authuser) {
      if (!this.FirstName || this.FirstName.length == 0
        || !this.LastName || this.LastName.length == 0
      ) { alert("Please enter a name."); return; }
      if (!ValidateEmail(this.Email)) return;
      if (!validatePhoneNumber(this.Phone)) return;
      this.userDataValid = true;
    }
  }

}

function ValidateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  alert("You have entered an invalid email address!")
  return (false)
}

function validatePhoneNumber(input_str) {
  var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;

  if (re.test(input_str)) { return true; }
  else {
    alert("You have entered an invalid phone number!")
    return (false)
  }
}