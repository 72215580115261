import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { Globals } from 'src/app/globals';
import { Product } from '../components/product-int';
import { onMenuItem } from './menu-item-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Order } from '../components/order-interface';
import { Company, Form, Field, Duration, ReservationSettings, ReservationDay, reservation, resTime, AddOns, resDate, Enabled, GroupSettings } from './company-interface';
import { RegisterService } from '../components/register.service';
import { Creds, Media, Follows, User, Notify } from './user-interface';
import { query } from 'firebase/firestore';
import { Color } from 'exceljs';

export interface VIPScene {
    id?: string;
    type?: string;
    name?: string;
    settings?: VIPSettings;
    groups?: string[];
}
export interface VIPSettings {
    name?: string;
    gravity?: { x?: number, y?: number };
    grid?: number;
    gridSnap?: boolean;
    showGrid?: boolean;
    toolbars?: boolean;
    allowAppEditing?: boolean;
    backgroundColor?: number;
    clear?: boolean;
    width?: any;
    height?: any;
    worldWidth?: number;
    worldHeight?: number;
    showNames?: boolean;
    avatars?: boolean;
    avatarSettings?: {turnThrust: number, forwardThrust: number};
    score?: boolean;
    starField?: boolean;
}


export interface VIPContent {
    id?: string;
    type?: string;
    imageKey?: string;
    url?: string; // MEDIA
    media?: Media;
}

export interface Tween {
    targetObjects?: string[]; // me, group, 
    name?: string;
    cropTarget?: {x:number, y:number, width: number, height: number};
    xTarget?: { x?: number, ease?: string, flip?: boolean };
    yTarget?: { y?: number, ease?: string, flip?: boolean };
    scaleTarget?: { scale?: number, ease?: string, yoyo?: boolean };
    angleTarget?: { angleFrom?: number, angleTo?: number, angleYoyo?: boolean };
    yoyo?: boolean;
    duration: number;
    repeat: number;
    repeatDelay?: number;
    hold?: number;
    destroyOnComplete?: boolean;

}

export interface ObjectEvent {
    objectId: string;  // Object I am attached to
    cause: string; // what caused me?
    type: string; // what to do? i.e. Goto Node
    nodeId: string; // that node we were talking about

}

export interface VIPObjectPhysics {
    collisions: Collision[];
}

export interface Collision {
    collidesWith: string;
    onCollision: { action: string; value: any }[]

}

export interface VIPObject {
    id?: string;
    sid?: string;
    locked?: boolean;
    groups?: string[];
    createdUid?: string;
    createdBy?: string;
    createdAt?: number;
    editedUid?: string;
    editedBy?: string;
    editedAt?: number;
    name?: string;
    fontSize?: string;
    angle?: number;
    zOrder?: number;
    physicsEnabled?: boolean;
    selected?: boolean;
    fill?: boolean; 
    fillColor?: number;
    fillOpacity?: number;
    outline?:boolean;
    outlineStyle?: any;
    outlineOpacity?: number;
    outlineColor?: number;
    static?: boolean;
    ignoreGravity?: boolean;
    type: string;
    framesPerSec?: number;
    objectCreator?: ObjectCreator;
    text?: string; // 
    video?: Phaser.GameObjects.Video;
    physics?: VIPObjectPhysics; // make an array
    xAlign?: string // center, left, right etc.
    x?: number;
    y?: number;
    imageKey?: string;
    scale?: number;
    xs?: number;
    ys?: number;
    vx?: number;
    vy?: number;
    rotate?: boolean;
    x1?: number;
    y1?: number;
    x2?: number;
    y2?: number;
    originX?: number;
    originY?: number;
    diameter?: number;
    radius?: number;
    height?: number;
    width?: number;
    centerX?: number;
    centerY?: number;
    delay?: number;
    repeat?: number;
    objectType?: string;
    randomXrange?: { x1: any, x2: any; };
    randomYrange?: { y1: any, y2: any; };
    impulse?: { xv?: number, yv?: number };
    tweens?: Tween[];
    events?: ObjectEvent[];
}
export interface ObjectCreator { // Settings
    type: string; // ball, text, etc.
    source: string; // reviews, orders, etc.


}

@Injectable({
    providedIn: 'root'
})
export class VIPService {

    updatingNow: boolean = false;


    constructor(private global: Globals, private sanitizer: DomSanitizer) {


    }

    addMediaToPreload(sceneId, item: Media, scene) {
        var self = this;

   //     console.log("Adding NEW Media ", item);

        var newContent: VIPContent = {
            imageKey: item.imageKey,
            url: item.url,
            type: "image",
        }
        if (item.fileType.toLowerCase().includes('video')) {
            newContent.type = 'video'
        }

        var db = firebase.firestore();

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("content").doc();


        newContent.id = VIPRef.id;
        VIPRef.set(newContent).then(() => {

        })

    }


    removeID(sceneId, object: VIPObject) {

        var self = this;

        var db = firebase.firestore();

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("objects")
            .doc(object.id)
            .delete()

    }

    newScene(callback?: any, mySelf?: any) {
        var self = this;

        console.log("NEW Scene created")
        var VIPscene: VIPScene = { type: "VIP", settings: { name: "New scene", width: 800, height: 600, gravity: { x: 0, y: 0 }, grid: 32 } }

        var db = firebase.firestore();

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc()
        VIPscene.id = VIPRef.id;

        VIPRef.set(VIPscene).then(() => {
            if (typeof (callback) == 'function')
                callback(mySelf)
        })

        return VIPscene.id;
    }


    removeAll(sceneId, scene) {

        var self = this;

        var db = firebase.firestore();

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("objects")
            .where("type", "!=", "NONE")
            .get()
            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = <Product>doc.data();
                    doc.ref.delete()

                })

            })


    }

    addAvatar(sceneId, item: VIPObject) {
        var self = this;
        /*
        if (!item.createdUid && self.global.authuser) {
            item.createdUid = self.global.authuser.uid;
            item.createdBy = self.global.authuser.firstName+" "+self.global.authuser.lastName;
            item.createdAt = Date.now();
        }*/

        console.log("NEW AVATAR ", item);

        item.createdAt = Date.now();
        var db = firebase.firestore();

        if (self.global.authuser) {
            item.sid = self.global.authuser.sid;

            const VIPRef = db.collection("company").doc(self.global.myCompany.id)
                .collection("VIP").doc(sceneId)
                .collection("avatars").doc(self.global.authuser.sid);

            VIPRef.set(item);
        }
    }

    avatarUpdate(sceneId, item: VIPObject) {
        var self = this;

        if (!self.global?.authuser?.sid) return;

        /*     if (!item.createdUid && self.global.authuser) {
                 item.createdUid = self.global.authuser.uid;
                 item.createdBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
                 item.createdAt = Date.now();
                 item.editedUid = self.global.authuser.uid;
                 item.editedBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
                 item.editedAt = Date.now();
             }*/
        /* if (self.global.authuser) {
            item.editedUid = self.global.authuser.uid;
            item.editedBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            item.editedAt = Date.now();
        }*/

        console.log("Avatar Update", item);

        item.editedAt = Date.now();
        var db = firebase.firestore();

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("avatars").doc(self.global.authuser.sid);

        VIPRef.update(item);
    }



    watchAvatars(sceneId, scene, callback, mySelf) {
        // TRACK AND UPDATE ALL SCENE AVATARS
        var self = this;
        var db = firebase.firestore();

        if (!sceneId) return;
        if (!self.global.authuser) return;

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("avatars")
            .where("sid", "!=", self.global.authuser.sid)
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const data = doc.data();
                    if (!data.id) data.id = doc.id;
                    if (typeof (callback === 'function')) {
                        callback(scene, data, mySelf)
                        console.log("GOT AVATAR: ", data)
                    }
                })
            })
    }



    addVIP(sceneId, item: VIPObject) {
        var self = this;

        if (!item.createdUid && self.global.authuser) {
            item.createdUid = self.global.authuser.uid;
            item.createdBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            item.createdAt = Date.now();
            item.editedUid = self.global.authuser.uid;
            item.editedBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            item.editedAt = Date.now();
        }
        item = removeUndefined(item);

    //    console.log("NEW DATA ", item);

        var db = firebase.firestore();

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("objects").doc();

        VIPRef.set(item);
    }

    updateVIP(sceneId, item: VIPObject) {
        var self = this;

        if (!item.createdUid && self.global.authuser) {
            item.createdUid = self.global.authuser.uid;
            item.createdBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            item.createdAt = Date.now();
            item.editedUid = self.global.authuser.uid;
            item.editedBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            item.editedAt = Date.now();
        }
        else if (self.global.authuser) {
            item.editedUid = self.global.authuser.uid;
            item.editedBy = self.global.authuser.firstName + " " + self.global.authuser.lastName;
            item.editedAt = Date.now();
        }

     //   console.log("NEW DATA ", item);

        var db = firebase.firestore();

        item = removeUndefined(item);

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("objects").doc(item.id);

        VIPRef.update(item);
    }

    preLoad(sceneId, scene, callback, mySelf) {
        var self = this;
        var db = firebase.firestore();
        console.log("preloading...", sceneId)

        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("content")

            .onSnapshot((querySnapshot) => {

                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                    //    console.log("added ", change.doc.data());
                        const data = change.doc.data();
                        if (typeof (callback) == 'function') {
                            callback(scene, data, data.url, mySelf)
                        }
                    }
                    if (change.type === "modified") {
                        console.log("Modified", change.doc.data());
                    }
                    if (change.type === "removed") {
                        console.log("Removed city: ", change.doc.data());
                    }

                });

                /*
                                querySnapshot.forEach((doc) => {
                                    const data = doc.data();
                                    if (!self.global.allImageKeys.includes(data.imageKey)) {
                                        self.global.allImageKeys.push(data.imageKey)
                                        callback(scene, data, data.url, mySelf)
                                    }
                                })
                                */
            })
    }

    getSceneSettings(sceneId, settingsCallback, mySelf) {
        // LOAD SOME DATA
        var self = this;
        var db = firebase.firestore();

        if (!sceneId) return;
        // First get the settings for the Field
        const ref = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .onSnapshot(docs => {
                var settings = docs.data();
                if (typeof (settingsCallback === 'function'))
                    settingsCallback(settings, mySelf)

            })
    }

    setSceneSettings(sceneId, scene: VIPScene) {
        // LOAD SOME DATA
        var self = this;
        var db = firebase.firestore();

        if (!sceneId) return;

        scene = removeUndefined(scene)

        // First get the settings for the Field
        const ref = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .update(scene)
    }


    getVIP(sceneId, name, scene, callback, mySelf) {
        // LOAD SOME DATA
        var self = this;
        var db = firebase.firestore();

        console.log("START Scene Listener ", sceneId, scene)
        if (!sceneId) return;


        const VIPRef = db.collection("company").doc(self.global.myCompany.id)
            .collection("VIP").doc(sceneId)
            .collection("objects")
            .onSnapshot((querySnapshot) => {

                querySnapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                  //      console.log("New city: ", change.doc.data());
                    }
                    if (change.type === "modified") {
                        console.log("Modified city: ", change.doc.data());
                    }
                    if (change.type === "removed") {
                        console.log("Removed city: ", change.doc.data());
                    }
                    const data = change.doc.data();
                    if (!data.id) data.id = change.doc.id;

                    if (typeof (callback === 'function')) {
                        callback(scene, data, mySelf)

                   //     console.log("GOT OBJECT: ", data)
                    }
                });


                /*
                
                                querySnapshot.forEach((doc) => {
                                    const data = doc.data();
                                    if (!data.id) data.id = doc.id;
                
                                    if (typeof (callback === 'function')) {
                                        callback(scene, data, mySelf)
                
                                         console.log("GOT OBJECT: ", data)
                                    }
                                })
                            */
            })
    }
    createUID(): string {
        var uid = "";

        // For now just 3 1000 randoms + time;

        var a = Math.floor(Math.random() * 1000).toString();
        var b = Math.floor(Math.random() * 1000).toString();
        var c = Math.floor(Math.random() * 1000).toString();
        var d = Date.now().toString()
        console.log("D ", d)
        uid = a + b + c + d.substring(7, 3)

        return uid;
    }

    getFields(formId, callback, mySelf) {
        var self = this;
        var db = firebase.firestore();

        var q1 = db.collection("platform").doc("OdCDgRWTZhTMzW8N0y9y").collection("forms").doc(formId);

        if (q1)
            q1.get().then((doc) => {
                const data = <Form>doc.data();
                if (!data.fields) data.fields = [];

                data.formId = doc.id;

                // Have form check for positions
                data.fields.sort(function (a, b) {
                    if (a.position == null) a.position = 99;
                    if (b.position == null) b.position = 99;
                    if (a.position > b.position) return 1;
                    if (a.position < b.position) return -1;
                    return 0;
                })
                if (typeof data.showSubmit === 'undefined')
                    data.showSubmit = true;  // allow submit.

                if (typeof (callback) == 'function') {
                    callback(data.fields, mySelf)
                }

                /*
                     if (self.formInput) {
                       console.log("FORM HAS INPUT DATA: ", self.formInput)
                       self.form = self.formInput;
                     }
                     */

            })
                .catch((error) => {
                    console.log("no doc found: ", error);
                });

    }

}

function removeUndefined(o) {
    let stack = [o], i;
    while (stack.length) {
      Object.entries(i = stack.pop()).forEach(([k, v]) => {
        if (v === undefined) delete i[k];
        if (v instanceof Object) stack.push(v);
      })
    }
    return o;
  }