<div class="container">
    <div *ngIf="global.viewMode==1">
        <div *ngIf="global.menu.length">
            <div class="max-w-full flex flex flex-row justify-content-center">
                <div
                    class="surface-50 shadow-3 border-x-3 border-50 md:w-screen sm:w-screen lg:w-screen xl:w-6 line-height-4 flex flex-column">
                    <!--         <h2>Total Rewards Points: {{global.myRewardPoints | number:'1.0-0'}}</h2>
-->
                    <div class="card">
                        <p-dataView #dv [value]="global.menu" [paginator]="true" [rows]="10"
                            [alwaysShowPaginator]="false" filterBy="allTags,title,fullDescription" layout="list">
                            <ng-template pTemplate="header">
                                <div class="p-col-12 flex-auto flex-row justify-content-between">
                                    <!--        <p-dataViewLayoutOptions></p-dataViewLayoutOptions>  -->
                                    <!--       
                                <p-dropdown [options]="sortOptions"
                                [(ngModel)]="sortKey" placeholder="Sort By Price" (onChange)="onSortChange($event)"
                                styleClass="mb-2 md:mb-0">
                                </p-dropdown>
                        -->
                                    <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                                        <i class="pi pi-search"></i>
                                        <input type="search" pInputText placeholder="Search by Name"
                                            (input)="dv.filter($event.target.value, 'contains')">
                                    </span>

                                </div>
                            </ng-template>
                            <!--        
                    <ng-template let-item pTemplate="listItem">
                        <div class="p-col-12" style="width:100%; margin-bottom:0px; padding-bottom:0px;">
                            <div class="product-list-item" [style.background-color]="myProductColor(item)">
                                
                                <div *ngIf="item.image1">
                                    
                                <img  src={{item.image1}} alt="Photo of">
                            
                            </div>
                                <div style="width:100%" fxLayout="column" fxLayoutAlign="space-between" flexGrow>
                                    <div class="product-list-detail">
                                        <div class="product-name">{{item.title}}</div>

                                        <div fxLayout="row wrap" fxLayoutAlign="space-between">
                                            <div *ngFor="let tag of item.allTags;">
                                                <p-chip [label]="tag" styleClass="p-mr-2 custom-chip"></p-chip>
                                            </div>
                                        </div>

                                        <div class="product-description">{{item.fullDescription}}</div>
                                        <i class="pi pi-tag product-category-icon"></i><span
                                            class="product-category">{{item.category}}</span>
                                    </div>

                                    <div *ngIf="item.allOptions" [style.font-size.sm:.7em] fxLayout="row wrap" fxLayoutAlign="space-around center"
                                        class="product-options">
                                        <div *ngFor="let thisOption of item.allOptions; let n = index;">

                                            <mat-form-field appearance="fill" class="small-button2">
                                                <mat-label>{{thisOption.title}}</mat-label>

                                                <div [ngSwitch]="thisOption.type">
                                                    <div *ngSwitchCase="3">

                                               
                                                       
                                                    </div>

                                                   
                                                    <div *ngSwitchDefault>
                                                        

                                                    </div>
                                                </div>

                                            </mat-form-field>
                                        </div>

                                    </div>
                                </div>



                                <div class="product-list-action">
                                    <span class="product-price">${{item.retail}}</span>
                                 

                                    <p-button *ngIf="global?.appDoc?.addToCart" icon="pi pi-shopping-cart" label="Add item to Cart"
                                        [disabled]="global.redeem.trackInventory === 'OUTOFSTOCK'"
                                        (click)="addRewardItem(item)">
                                    </p-button>
                                   
                                    <div *ngIf="global.authuser">
                                        <div *ngIf="global.authuser.creds">
                                            <div *ngIf="global.authuser.creds[0].id==global.myCompany.id">
                                                <p-button label="Edit" (click)="editMe(item)"></p-button>

                                            </div>

                                        </div>
                                    </div>
                                    <span [class]="'product-badge status-'">{{item.dept}}</span>
                                </div>
                            </div>
                        </div>
                    </ng-template>

                -->

                            <ng-template let-item pTemplate="listItem">
                                <div class="center-me">
                                    <div class="p-col-12" style="width:100%; margin-bottom:6px; padding-bottom:0px;">
                                        <div class="product-list-item2" [style.background-color]="myProductColor(item)">
                                            <div fxLayout="row" fxLayoutAlign="space-between space-between">
                                                <div class="img-container" *ngIf="item.image1">

                                                    <img style="max-height: 300px;" src={{item.image1}} alt="Photo of">

                                                </div>

                                                <div class="product-list-detail2">
                                                    <div class="product-name2">{{item.title}}</div>

                                                    <div class="product-description">{{item.fullDescription}}

                                                    </div>

                                                    <!--
                                        <div fxLayout="row wrap" fxLayoutAlign="space-between">
                                            <div *ngFor="let tag of item.allTags;">
                                                <p-chip [label]="tag" styleClass="p-mr-2 custom-chip"></p-chip>
                                            </div>
                                        </div>
                                   
                                    
                                        <i class="pi pi-tag product-category-icon"></i><span
                                            class="product-category">{{item.category}}</span>
                                                 -->


                                                    <div *ngIf="item.allOptions" fxLayout="column"
                                                        fxLayoutAlign="space-around center" class="product-options">
                                                        <div *ngFor="let thisOption of item.allOptions; let n = index;">
                                                            <div *ngIf="thisOption.list.length > 1">
                                                                <!--     <mat-label>{{thisOption.title}}</mat-label>   -->

                                                                <div [ngSwitch]="thisOption.type">
                                                                    <div *ngSwitchCase="3">
                                                                        <p-multiSelect appendTo="body"
                                                                            [options]="thisOption.list"
                                                                            [showHeader]="false" [overlayVisible]="true"
                                                                            [maxSelectedLabels]="0"
                                                                            [selectedItemsLabel]="'{0} items selected'"
                                                                            [(ngModel)]="thisOption.chosenList"
                                                                            defaultLabel="Addons" placeholder="extras"
                                                                            [optionLabel]=""
                                                                            (onChange)="optionSelect($event, thisOption, item)"></p-multiSelect>



                                                                    </div>
                                                                    <div *ngSwitchCase="1">
                                                                        <p-dropdown appendTo="body"
                                                                            [options]="thisOption.list"
                                                                            [(ngModel)]="thisOption.chosen"
                                                                            [overlayVisible]="true"
                                                                            [styleClass]="drop-list"
                                                                            [placeholder]="thisOption.title"
                                                                            optionLabel=""></p-dropdown>
                                                                    </div>
                                                                    <!-- . . . -->
                                                                    <div *ngSwitchDefault>
                                                                        <p-dropdown appendTo="body"
                                                                            [options]="thisOption.list"
                                                                            [(ngModel)]="thisOption.chosen"
                                                                            [overlayVisible]="false"
                                                                            [placeholder]="thisOption.title"
                                                                            optionLabel=""></p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="product-list-action2" fxLayout="row"
                                                fxLayoutAlign="space-around center">
                                                <span class="product-price2">${{item.retail}}</span>
                                                <p-button icon="pi pi-shopping-cart" label="Add"
                                                    [disabled]="global.redeem.trackInventory === 'OUTOFSTOCK'"
                                                    (click)="addItem(item)">
                                                </p-button>

                                                <div *ngIf="global.authuser">
                                                    <div *ngIf="global.authuser.creds">
                                                        <div *ngIf="global.authuser.creds[0].id==global.myCompany.id">
                                                            <p-button label="Edit" (click)="editMe(item)"></p-button>

                                                        </div>

                                                    </div>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </ng-template>





                            <!--
    
                    <ng-template let-item pTemplate="gridItem">
                        <div class="p-col-12 p-md-4">
    
                            <div class="product-grid-item card">
                                <div class="product-grid-item-top">
                                    <div class="product-grid-item">
                                        <img *ngIf="item.image1" src={{item.image1}} alt="Photo of">
    
                                    </div>
                                    <div>
                                        <i class="pi pi-tag product-category-icon"></i>
                                        <span class="product-category">{{item.category}}</span>
                                    </div>
                                    <span [class]="'product-badge status-'">{{item.dept}}</span>
                                </div>
                                <div class="product-grid-item-content">
                                    <div class="product-name">{{item.title}}</div>
                                    <div class="product-description">{{item.fullDescription}}</div>
    
                                </div>
                                <div class="product-grid-item-bottom">
                                    <span class="product-price">${{item.retail}}</span>
                                    <p-button icon="pi pi-shopping-cart" [disabled]="red.dept === 'OUTOFSTOCK'"
                                        (click)="addRewardItem(item)"></p-button>
                                </div>
    
                            </div>
    
                        </div>
                    </ng-template>
    -->

                        </p-dataView>
                    </div>
                </div>
            </div>
        </div>

    </div>