
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs } from "firebase/firestore";
import { doc, onSnapshot } from "firebase/firestore";
import { MediaService } from "./media-functions/media-functions"
import { Globals } from 'src/app/globals';
import emailjs, { init, EmailJSResponseStatus } from 'emailjs-com';
import { environment } from '../../../environments/environment';
import { NavService } from '../menu/nav.service';
import { Company, AppNode, Form, Field, Navigate, Gallery, Reviews, Review, GooglePhotos } from '../company-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
//import { ImagekitioAngularModule } from 'imagekitio-angular';
import { HttpClient } from '@angular/common/http';
import { User, Contact, MediaGroup, PexelVideo, PexelVideoFile, PexelUser, PexelPhoto, Media, SharedMediaFrom } from '../user-interface';
import { RegisterService } from '../register.service';
import { Observable, throwError } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import { Product } from '../product-int'
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { Logger } from 'src/app/functions';




@Component({
    selector: 'app-media-groups',
    templateUrl: './media-groups.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    styleUrls: ['./media.component.css', '../../common.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class MediaGroupsComponent implements OnInit {
    @Input() groupId: string;
    @Input() group: MediaGroup;
    @Input() mode: string;
    @Input() editNode: AppNode;
    @Input() appId: string;
    @Input() userSettings: boolean = true;

    mediaWidth = '33vw';
    mediaHeight = '33vh';
    settingsForm: Form;
    clickRemove: boolean;
    fullScreenMode: boolean = false;

    currentVideo;


    constructor(private route: ActivatedRoute, public global: Globals,
        public nav: NavService, private sanitizer: DomSanitizer,
        private http: HttpClient, private mediaService: MediaService) {


    }


    ngOnInit(): void {
        var self = this;

        console.log("Media group: ", this.group, this.groupId, this.mode)

        if (this.groupId) {
            const me = self.global.mediaGroups.find(item => item.id === self.groupId);
            if (me) this.group = me;
        }
        else if (this.group) {
            var keys = Object.keys(this.group)
            var values = Object.values(this.group)
            // this.settingsForm.fields = [];
            const f: Field[] = [{
                title: "Group name", binding: this.group.name
            },
            { title: "Media size", selected: [this.group.mediaWidth] },
            { title: "Tags", selected: this.group.autoIncludeTags },


            ];

            this.settingsForm = { fields: f }
            console.log("Input fields: ", this.settingsForm)

            keys.forEach(function (key, i) {

                switch (key) {
                    case 'name':

                        break;
                }

            })

            //  this.settingsForm.fields
        }



    }


    checkGroup(m: Media): boolean {

        var self = this;
        if (self.group?.id && m.mediaGroups && m.mediaGroups.length > 0 && m.mediaGroups.includes(self.group.id)) return true;

        var found = false;
        //  console.log(self.group.autoIncludeTags)
        if (self.group?.autoIncludeTags)
            self.group.autoIncludeTags.forEach(function (tag) {
                if (m.tags.includes(tag)) {
                    found = true;
                }
            })


        return found;
    }

   

    userClickedMedia(m: Media) {
        var self = this;

        if (self.clickRemove) {
            const index = m.mediaGroups.findIndex(item => item === self.group.id);
            console.log("User clicked ", m, index)
            if (index! - -1) {
                m.mediaGroups.splice(index, 1);
                self.mediaService.updateMediaGroups(m)
            }
        }
    }

    updateMediaGroup(group) {
        var self = this;
        var db = firebase.firestore();

        const ref = db.collection('company')
            .doc(self.global.myCompany.id.trim())
            .collection('mediaGroups').doc(group.id);
        ref.update(group)
    }

    getFullWidth() {
        var style;

        style = "width: " + this.global.getScreenWidth + 'px;'

        if (1) {  // I'm selected yay!
            style += " background-color:#d5ffb5; color: #000000; font-size: 130%;";
        }
        else
            style += " background-color: #023de0; color: #f4f8f4; font-size: 130%;";

        //console.log(style)
        return style;
    }
    userSettingsOutput(field: Field) {
        var self = this;

        console.log("SETTINGS Output ", field)
        switch (field.title) {
            case "Size":
                //  const x = field.selected[0].indexOf('%')
                // var w = field.selected[0].substring(0, x) + 'vw'
                self.group.mediaWidth = field.selected[0];
                //  self.group.mediaHeight = w.substring(0, x) + 'vh'
                break;
                case "Media rows":
                    // const x = field.selected[0].indexOf('%')
                    // var w = field.selected[0].substring(0, x) + 'vw'
    
                    self.group.mediaWidth = field.selected[0];
                    //   self.group.mediaHeight = w.substring(0, x) + 'vh'
                    //   console.log("MWH: ", self.mediaWidth, self.mediaHeight)
    
                    break;
                case "Media shape":
                    self.group.shape = field.selected[0];
                    break;
        }
    }

    groupSettingsOutput(field: Field) {
        var self = this;

        console.log("SETTINGS Output ", field)
        switch (field.title) {
            case "name":
                self.group.name = field.binding;
                self.updateMediaGroup(self.group)
                break;
            case "Click add":

                break;
            case "Click remove":
                self.clickRemove = field.trueFalse;

                break;
            case "mediaWidth":
                // const x = field.selected[0].indexOf('%')
                // var w = field.selected[0].substring(0, x) + 'vw'

                self.group.mediaWidth = field.selected[0];
                //   self.group.mediaHeight = w.substring(0, x) + 'vh'
                //   console.log("MWH: ", self.mediaWidth, self.mediaHeight)


                self.updateMediaGroup(self.group)

                break;
            case "shape":
                self.group.shape = field.selected[0];
                self.updateMediaGroup(self.group)
                break;
            case "autoIncludeTags":
                self.group.autoIncludeTags = field.selected;
                self.updateMediaGroup(self.group)

                break;
           
            case "allowSharing":
                self.group.allowSharing = field.trueFalse;
                self.updateMediaGroup(self.group)

                break;

            case "autoPlayVideo":
                self.group.autoPlayVideo = field.trueFalse;
                self.updateMediaGroup(self.group)

                break;
        }

    }
//        style="margin-left: 3px; margin-right: 3px; margin-top: 6px; margin-bottom: 6px; text-align: center; width:180px; height:180px; background-color: rgb(241, 196, 245); border: 2px solid green">


    getMediaGroupWidth(g: MediaGroup) {
        if (!g.mediaWidth) {
            return this.mediaWidth;
        }
        else {
            var width = '25vw';
            switch (g.mediaWidth) {
                case '1 - full width':
                    width = '100vw'
                    break;
                case '2':
                    width = '50vw'
                    break;
                case '3':
                    width = '33vw'
                    break;
                case '4':
                    width = '25vw'
                    break;
                case '5':
                    width = '20vw'
                    break;
                case '6':
                    width = '16vw'
                    break;
                case '8':
                    width = '12vw'
                    break;
                case '10':
                    width = '10vw'
                    break;
                case '12':
                    width = '8vw'
                    break;
                case '16':
                    width = '6vw'
                    break;
                case '20':
                    width = '5vw'
                    break;
            }
        }
        return width;
    }
    getMediaGroupHeight(g: MediaGroup) {

        if (g.shape == 'square') {
            const ratio = this.global.getScreenWidth / this.global.getScreenWidth
            var width = this.global.getScreenWidth;
            if (!g.mediaWidth.startsWith('1')) {
                const columns = parseInt(g.mediaWidth)

                width /= columns;
                return "height:" + width + 'px'; // SQUARE
            }
        }
        else if (g.shape == 'wide') {
            //  const ratio = this.global.getScreenWidth / this.global.getScreenWidth
            var width = this.global.getScreenWidth;
            if (!g.mediaWidth.startsWith('1')) {
                const columns = parseInt(g.mediaWidth)

                width /= columns;
                width *= .60; // Make it wide
                return "height:" + width + 'px'; // 
            }
        }
        else if (g.shape == 'very wide') {
            //  const ratio = this.global.getScreenWidth / this.global.getScreenWidth
            var width = this.global.getScreenWidth;
            if (!g.mediaWidth.startsWith('1')) {
                const columns = parseInt(g.mediaWidth)

                width /= columns;
                width *= .25; // Make it wide
                return "height:" + width + 'px'; // 
            }
        }
        else if (g.shape == 'tall') {
            //  const ratio = this.global.getScreenWidth / this.global.getScreenWidth
            var width = this.global.getScreenWidth;
            if (!g.mediaWidth.startsWith('1')) {
                const columns = parseInt(g.mediaWidth)

                width /= columns;
                width *= 1.4; // Make it wide
                return "height:" + width + 'px'; // 
            }
        }
        else if (g.shape == 'very tall') {
            //  const ratio = this.global.getScreenWidth / this.global.getScreenWidth
            var width = this.global.getScreenWidth;
            if (!g.mediaWidth.startsWith('1')) {
                const columns = parseInt(g.mediaWidth)

                width /= columns;
                width *= 2; // Make it wide
                return "height:" + width + 'px'; // 
            }
        }

        if (!g.mediaWidth) {
            return "height:" + this.mediaHeight;
        }
        else {
            var height = 'height: 25vh';
            switch (g.mediaWidth) {
                case '1 - full width':
                    height = 'height:100vh'
                    break;
                case '2':
                    height = 'height:50vh'
                    break;
                case '3':
                    height = 'height:33vh'
                    break;
                case '4':
                    height = 'height:25vh'
                    break;
                case '5':
                    height = 'height:20vh'
                    break;
                case '6':
                    height = 'height:16vh'
                    break;
                case '8':
                    height = 'height:12vh'
                    break;
                case '10':
                    height = 'height:10vh'
                    break;
                case '12':
                    height = 'height:8vh'
                    break;
                case '16':
                    height = 'height:6vh'
                    break;
                case '20':
                    height = 'height:5vh'
                    break;
            }
        }
        return height;
    }

    copyMedia() {
        var db = firebase.firestore();
        var self = this;

        console.log("COPY MEDIA FROM CHV REVIEWS")

        var docRef = db.collection("company").doc("5vjmmQOnSx31XLsMDvZx").collection("media")
        docRef.get()

            .then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var m = <Media>doc.data();
                    if (m?.tags && m.tags.length > 0 && m?.tags.includes("Review")) {
                        // SAVE TO Napkin REviews
                        console.log("M ", m)

                        var docRef2 = db.collection("company").doc(self.global.myCompany.id).collection("media")
                        docRef2.doc().set(m)
                    }
                });

            })
    }

    whatsPlaying: string[];

    isPlaying(url) {

        if (!this.whatsPlaying) return false;

        return this.whatsPlaying.includes(url)
    }

    playMe(url) {
        var self = this;

        if (!self.whatsPlaying) self.whatsPlaying = [];
        // Let's use this is a toggler
        const index = self.whatsPlaying.indexOf(url)
        if (index != -1) {
            self.whatsPlaying.splice(index, 1)
        }
        else self.whatsPlaying.push(url);


        console.log("REMOVE THE PLAY BUTTON")

    }

    setFullScreen(v: Media) {
        var self = this;


        this.fullScreenMode = !this.fullScreenMode;
        this.currentVideo = v;
        console.log("Full screen ", v)



        /*
            if (self.selectionMode == 'clickPick') {
              self.addPhotoToGroups(v);
              return;
            }
        

        */

    }

    selectNodeMediaGroup(m: MediaGroup, node: AppNode) {
        var self = this;
   
        console.log("Adding to node: ", self.appId, m, node);
    
        if (!self.editNode) return;
    
        var db = firebase.firestore();
    
        var ref2 = db.collection("apps").doc(self.appId)
          .collection('nodes').doc(node.id)
    
        node.mediaGroupId = self.group.id;

        ref2.update({
            mediaGroupId: node.mediaGroupId
        })
          .then(() => {
            console.log("New Media group added.",);
    
          })
          .catch((error) => {
            console.error("Error in media", error);
          });
    
      }
    



}