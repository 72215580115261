import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Product } from '../../product-int'
import { AppNode, DataGem, Form, Field, Navigate, NapkinApp } from '../../company-interface'
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { Globals } from '../../../globals';
import { Media } from '../../user-interface';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import firebase from 'firebase/compat/app';
import { ProductsService } from '../../products.service';
import { NodeService } from '../../node.service'
import { FormService } from '../../form.service';

export interface Images {
  value?: ImageValues[];
}

export interface ImageValues {
  contentUrl: string;
  thumbnailUrl: string;
}

export interface GalleryImage {
  previewImageSrc: string,
  thumbnailImageSrc: string,
  alt: string,
  title: string
}

export interface Videos {
  videos: Video[];

}
export interface Video {
  video_files: VideoFile[];
}

export interface VideoFile {
  link?: string;
  height?: number;
  width?: number;
}



@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss', '../../../common.scss']
})

export class EditorComponent {
  @Input() editProduct: Product;
  @Input() editNode: AppNode;
  @Input() appId: string;

  //  basicEdits: boolean = false;
  basicTabOpen: boolean = false;
  openAccordion: number = -1;

  showBasic: boolean = true;
  justifyOptions: any[];
  yesNo: any[];
  value: any;
  styles: any[];
  type: any[];
  editMode: string = "";
  visible: boolean = true;
  ImageSearch: boolean = false;
  images: any[];
  videos: any[];
  html: SafeHtml;
  searchString: string = "";
  viewTagTab: boolean = false;
  myMedia: Media[] = [];


  objectSelected: string;
  changeMyType: boolean = true;

  dataViewList: any[];
  dataGemList: any[];
  colorOptions: string[];

  selectGalleryVisible: boolean = true;

  constructor(public nodeService: NodeService, public formService: FormService,
    private sanitizer: DomSanitizer, public global: Globals,
    public productService: ProductsService) {

    this.yesNo = [
      { option: 'No', value: false },
      { option: 'Yes', value: true },
    ];

    this.styles = [
      { option: 'Tall Image' },
      { option: 'Wide Image' },
      { option: 'Minimal' },
    ];

    this.type = [
      { option: 'Content' },
      { option: 'Product' },
      { option: 'Nav' },
      { option: 'object' },
      { option: 'Form' }

    ];

    this.dataViewList = [
      { name: "Top 5 selling products", value: 0 },
      { name: "New reviews", value: 1 },
      { name: "Total sales for today", value: 2 },
      { name: "New followers", value: 3 },
      { name: "Reservations", value: 4 },
      { name: "App Usage", value: 5 },
      { name: "Average time from order to delivered", value: 6 },
      { name: "Customer chats", value: 7 },
    ];

    this.dataGemList = [
      { name: "Sales", value: 1456.45, type: 'currency' },
      { name: "Open orders", value: 27, type: 'number' },
      { name: "Reviews", value: 92, type: 'number' },
      { name: "Pending reviews", value: 3, type: 'number' },
      { name: "Open chats", value: 40, type: 'number' },
      { name: "Reservations today", value: 5, type: 'number' },
      { name: "Future reservations", value: 60, type: 'number' },
      { name: "Customer happiness", value: 96, type: 'number' },
      { name: "Traffic today", value: 443, type: 'number' },
      { name: "Most popular product", value: "Ramona Special", type: 'string' },
      { name: "New users today", value: 23, type: 'number' },
      { name: "Peak usage", value: "9:47am-[187]", type: 'string' },
    ];

    this.justifyOptions = [
      { icon: 'pi pi-align-left', justify: 'Left' },
      { icon: 'pi pi-align-right', justify: 'Right' },
      { icon: 'pi pi-align-center', justify: 'Center' },
      { icon: 'pi pi-align-justify', justify: 'Justify' }
    ];
  }

  ngOnInit(): void {
    console.log("Edit: ", this.editNode, this.appId);
    this.changeMyType = false;
    this.colorOptions = ['#238907', '#5093ff', '#0fe3a2', '#456721',
      '#234523', '#f36512',
      '#2F3C7E', '#FBEAEB',
      '#F96167', '#F9E795',
      '#FC766A', '#783937', '#F1AC88',
      '#AA96DA', '#C5FAD5', '#FFFFD2'

    ];
    //  this.loadMedia();

  }

  outputEvent(form: Form) {
    var self = this;

    var objType = self.editNode;
    console.log("OUTPUT ", form)
    self.formService.napkinFieldsToObject(form, self.editNode)
    if (objType != self.editNode.objectType) {

      if (self.editNode.tags) {
        console.log("Got tags ", self.editNode.tags)
        self.nodeService.updateMasterNodeTags(self.editNode.tags)
      }

      // VALIDATE THE NEW NODE TYPE

      if (self.editNode?.objectType.toLowerCase().startsWith('nav')) {
        self.editNode.parentId = self.editNode.id;
        console.log("KSDHJFSKLDFHSDKLFHJS ", self.editNode.parentId)
      }
      else self.editNode.parentId = null;

      // START Container/tabList with one container
      if (self.editNode.objectType.toLowerCase() == 'tablist' && !self.editNode.containers) {
        self.editNode.containers = [{
          title: "New container",
          parentId: self.createParentId(),
        }]
      }


      if (self.editNode.objectType == 'query') {
        if (form.fields[0].selected) {
          if (!self.editNode.queryIds) self.editNode.queryIds = [];
          if (!self.editNode.queryNames) self.editNode.queryNames = [];

          form.fields[0].selected.forEach(function (queryName) {
            self.editNode.queryNames.push(queryName);
            var found = self.global.allQueries.find((f) => f.title == queryName)
            self.editNode.queryIds.push(found.id)

          })
        }

      }

      if (self.editNode.objectType == 'weather') {
        self.editNode.location = self.global.myCompany.postal;
      }
      if (self.editNode.objectType == 'review-card') {

        var found = self.global.publishedReviews.find((f) => f.review_photos?.length > 0 && f.rating == '5')
        if (found != undefined) {
          self.editNode.review = found;
          self.editNode.review.style = 'polaroid';
        }
      }
      if (self.editNode.objectType == 'polaroid') {
        self.editNode.objectType = 'content';
        self.editNode.format = 'polaroid';
      }


      var objNew = self.editNode.objectType;
      console.log("new: ", self.editNode)
      self.editNode.objectType = self.nodeService.convertObjectLabelToId(objNew)
      console.log("VALIDATING NODE TYPE ", self.editNode)
    }
    console.log("EDITOR OUTPUT: ", self.editNode, form)

    self.global.selectedTab = ""
    self.updateNode(self.editNode)
  }

  updateNode(node: AppNode) {
    const db = firebase.firestore()
    const vm = this;
    vm.global.updatingNodes = true;
    if (node.safeHtml) node.safeHtml = undefined;
    node = removeUndefined(node)

    var docRef =
      db.collection('apps')
        .doc(this.appId)
        .collection('nodes').doc(node.id)
    console.log("ref ", docRef)
    docRef.update(node).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin node", error);
      });

  }



  SetEditMode(mode: string) {
    this.editMode = mode;
    this.visible = true;
    console.log(this.editMode);
  }

  setGallery(galleryId: string) {
    this.editProduct.galleryId = galleryId;
    this.editMode = "";


  }

  clearSearch() {
    this.searchString = "";

  }

  tagFilter(item: string): boolean {
    var self = this;

    if (this.searchString.length > 1) {
      if (item.toLowerCase().includes(this.searchString.toLowerCase())) return true;
      else return false;
    }
    return true;
  }

  isExpanded(tab: string) {
    //  console.log("TABBB",this.global.selectedTab)
    if (tab == this.global.selectedTab)
      return true;
    else return false;

  }

  changeType() {
    this.changeMyType = true;

  }

  selectApp(app: NapkinApp) {
    var self = this;

    console.log ("I can't  beleive it. I will now embed an app inside an app. ", app)
    self.editNode.appIds = [app.appId];
    self.editNode.appNames = [app.name];
    self.updateNode(self.editNode);

  }



  selectNode(i) {
    // MAKE A COPY OF THIS NODE 

    const db = firebase.firestore()
    const vm = this;

    const myId = vm.editNode.id;
    const myParent = vm.editNode.parent;


    vm.editNode = { ...vm.global.appNodes[vm.appId].nodes[i] }
    vm.editNode.id = myId;
    vm.editNode.parent = myParent;


    if (vm.editNode.html && vm.editNode?.html?.length > 1) {
      var html: SafeHtml;
      html = vm.sanitizer.bypassSecurityTrustHtml(vm.editNode.html);
      vm.editNode.safeHtml = null;

      /*
            const html = vm.editNode.safeHtml['changingThisBreaksApplicationSecurity'];
            const safeHtml = vm.sanitizer.bypassSecurityTrustHtml(html);
            vm.editNode.safeHtml = <SafeHtml>safeHtml;
            */
    }



    vm.editNode = removeUndefined(vm.editNode)

    console.log("COPYING: ", vm.editNode)
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    // vm.global.updatingNodes = true;
    docRef.update(vm.editNode).then(() => {
      vm.global.updatingNodes = false;
      console.log("Copied that node fer ya.", vm.editNode)

    })
      .catch((error) => {
        console.error("Error savin form", error);
      });




  }

  selectForm(i) {
    const db = firebase.firestore()
    const vm = this;

    if (!vm.editNode.formId) vm.editNode.formId = [];
    vm.editNode.formId.push(vm.global.allForms[i].id); //??id or formid??


    if (!vm.editNode.formNames) vm.editNode.formNames = [];
    vm.editNode.formNames.push(vm.global.allForms[i].name)

    console.log("Selected form: ", i, vm.editNode, vm.appId)
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({ formNames: vm.editNode.formNames, formId: vm.editNode.formId }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin form", error);
      });



  }

  updateReviewSettings() {

    const db = firebase.firestore()
    const vm = this;
    console.log("Update Review Settings: ", vm.editNode.reviewSettings)

    // if (!vm.editNode.reviewSettings) vm.editNode.reviewSettings = [];
    // vm.editNode.reportStyles.push(type);

    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({ reviewSettings: vm.editNode.reviewSettings }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error updatin reviewsettigns", error);
      });



  }



  reportStyle(type) {

    const db = firebase.firestore()
    const vm = this;

    if (!vm.editNode.reportStyles) vm.editNode.reportStyles = [];
    vm.editNode.reportStyles.push(type);

    console.log("Selected style: ", type, vm.editNode, vm.appId)
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({ reportStyles: vm.editNode.reportStyles }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error updatin report", error);
      });



  }

  selectMediaGroup(i) {
    const db = firebase.firestore()
    const vm = this;

    vm.editNode.mediaGroupId = (vm.global.mediaGroups[i].id);
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({ mediaGroupId: vm.editNode.mediaGroupId }).then(() => {
    })
      .catch((error) => {
        console.error("Error savin report", error);
      });

  }

  selectQuery(i) {
    const db = firebase.firestore()
    const vm = this;

    if (!vm.editNode.queryIds) vm.editNode.queryIds = [];
    vm.editNode.queryIds.push(vm.global.allQueries[i].id);


    if (!vm.editNode.queryNames) vm.editNode.queryNames = [];
    vm.editNode.queryNames.push(vm.global.allQueries[i].title)

    console.log("Selected Query: ", i, vm.global.allQueries)
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({ queryNames: vm.editNode.queryNames, queryIds: vm.editNode.queryIds }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin query", error);
      });



  }



  selectReport(i) {
    const db = firebase.firestore()
    const vm = this;

    if (!vm.editNode.reportIds) vm.editNode.reportIds = [];
    vm.editNode.reportIds.push(vm.global.allReports[i].id);


    if (!vm.editNode.reportNames) vm.editNode.reportNames = [];
    vm.editNode.reportNames.push(vm.global.allReports[i].title)

    console.log("Selected report: ", i, vm.global.allReports)
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({ reportNames: vm.editNode.reportNames, reportIds: vm.editNode.reportIds }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin report", error);
      });



  }

/*
  changeObjectType(event, o: string, editNode: AppNode) {
    const db = firebase.firestore()
    const vm = this;

    editNode.objectType = o;
    if (o == 'nav') {
      editNode.parentId = editNode.id;
    }

    vm.changeMyType = false;
    vm.global.updatingNodes = true;
    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)

    docRef.update({ objectType: editNode.objectType }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin html", error);
      });

  }

*/

  selectProduct(p: Product) {


    var self = this;
    console.log("Adding to node: ", self.appId, p, this.editNode);

    // Hack - check for repeats //

    if (!this.editNode) return;

    // if (!p.createdAt) m.createdAt = Date.now();
    //m.userId = self.global.authuser.uid;

    var db = firebase.firestore();
    self.global.updatingNodes = true;
    var ref2 = db.collection("apps").doc(self.appId)
      .collection('nodes').doc(this.editNode.id)

    if (!this.editNode.products) this.editNode.products = [];
    this.editNode.products.push(p.docID);
    ref2.update({

      products: firebase.firestore.FieldValue.arrayUnion(p.docID) // Spread operator

    })
      .then(() => {
        console.log("New product added.",);
        self.global.updatingNodes = false;

      })
      .catch((error) => {
        console.error("Error in products", error);
      });

  }
  selectDataView(v: any) {
    console.log("dataview:", v.value)

    if (!this.editNode.dataViews) this.editNode.dataViews = [];
    this.editNode.dataViews.push(v.value);

    this.editNode.dataGems = this.dataGemList;

    var self = this;
    self.global.updatingNodes = true;

    var db = firebase.firestore();

    var ref2 = db.collection("apps").doc(self.appId)
      .collection('nodes').doc(this.editNode.id)

    ref2.update({
      dataViews: firebase.firestore.FieldValue.arrayUnion(v.value), // Spread operator
      dataGems: this.editNode.dataGems,
    })
      .then(() => {
        console.log("New dataview.",);
        self.global.updatingNodes = false;

      })
      .catch((error) => {
        console.error("Error in dv", error);
      });

  }

  tabSelected(e: any, me: string) {
    console.log("tab selected: ", me, e);

    if (e)
      this.global.selectedTab = me;
    else
      this.global.selectedTab = null;

  }

  getDefaultWidth() {

    //console.log(i, this.selectedTab,this.global.getScreenWidth);
    var style;

    if (this.global.getScreenWidth > 900) { style = "width: 900px;" }
    else if (this.global.getScreenWidth > 600) { style = "width: 600px;" }
    else { style = "width: " + this.global.getScreenWidth + "px;" }

    return style;

  }

  reviewSettingsOutput(form: Form) {
    var self = this;

    //   if (!self.editNode.reviewSettings)
    self.editNode.reviewSettings = { showHeader: true, defaultView: "Standard", filters: [] }

    console.log("reviews settings form: ", form)
    form.fields.forEach(function (field) {

      if (field.title == 'showHeader') {
        if (typeof (field.trueFalse) != 'undefined')
          self.editNode.reviewSettings.showHeader = field.trueFalse;
      }
      if (field.title == 'defaultView') {
        if (field.selected[0])
          self.editNode.reviewSettings.defaultView = field.selected[0];
      }
      if (field.title == 'filters') {
        self.editNode.reviewSettings.filters = [];
        if (field.selected.length == 0) {
          console.log("SPLICING LAST TAG")
          self.editNode.reviewSettings.filters.splice(0, self.editNode.reviewSettings.filters.length);
        }
        else {
          field.selected.forEach(function (item) {
            self.editNode.reviewSettings.filters.push(item);
          })
        }
      }
    })

    self.updateReviewSettings()

    console.log("Review Settings: ", self.editNode.reviewSettings)

  }

  getStyle(i) {
    var style;

    if (this.global.getScreenWidth < 400 || i == this.global.selectedTab)
      style = "width: " + this.global.getScreenWidth + 'px;'

    else if (this.global.getScreenWidth >= 400)
      style = "width: 400px;"

    if (i == this.global.selectedTab) {  // I'm selected yay!
      style += " background-color:#d5ffb5; color: #000000; font-size: 130%;";
    }
    else {
      if (i == 'basic' || i == 'media' || i == 'text' || i == 'styling')
        style += " background-color: #023de0; color: #f4f8f4; font-size: 130%;";
      else
        style += " background-color: #12ed20; color: #f4f8f4; font-size: 130%;";

    }


    return style;
  }

  /*
    getStyle(i) {
  
      console.log(i, this.global.getScreenWidth);
  
      if (i == this.global.selectedTab) {
        var style = "font-size: 120%; background-color: #9ab2f5; color: #000000; margin-left:0px!important; padding-left:0px!important;margin-right:0px;"
        if (this.global.getScreenWidth > 900) { style += " width: 900px;" }
        else if (this.global.getScreenWidth > 600) { style += " width: 600px;" }
        else { style += " width: "+ this.global.getScreenWidth+"px;" }
  
        return style;
      }
      else {
        var style = "font-size: 100%; background-color: #023de0; color: #f4f8f4;"
  
        if (this.global.getScreenWidth > 900) {
          style += "margin-left:20% margin-right:20%; width:900px";
        }
        else if (this.global.getScreenWidth > 600) {
          style += "margin-left:20%; margin-right:20%; width: 100%;"
        }
        else style += "margin-left:0px; margin-right:0px; width: "+ this.global.getScreenWidth+"px;"
  
        return style;
      }
  
    }*/

  CloneContent(p: Product) {

    var self = this;
    function replacer(key, value) {
      if (key === "parent") return undefined;
      if (key === "selectable") return undefined;
      if (key === "expanded") return undefined;
      if (value === undefined) return null;
      return value;
    }

    var db = firebase.firestore();

    //  var docRef = db.collection("products").doc();
    var docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").doc()

    let newProduct = JSON.parse(JSON.stringify(p, replacer)); //DEEP COPY

    newProduct.title = newProduct.title + " -Copy";
    newProduct.docID = docRef.id;
    newProduct.sid = docRef.id.substring(0, 5) + self.global.myCompany.id.substring(5, 7)

    if (!newProduct.dept) newProduct.dept = "All;"

    docRef.set(
      newProduct
    )
      .then(() => {
        console.log("New Product Cloned");
        //            self.GetProducts();  // SHOULD AUTO UPDATE FROM SERVICE
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
      });

  }
  getFullWidth() {
    var style;

    style = "width: " + this.global.getScreenWidth + 'px;'

    return style;
  }

  addProductTag(e, t: string) {
    e.stopImmediatePropagation();

    if (!this.editNode.productTags) this.editNode.productTags = [];
    this.editNode.productTags.push(t);
    this.updateProductTags(this.editNode, this.appId);


    this.searchString = "";
  }
  /*
    addNewTag(e) {
      e.stopImmediatePropagation();
      this.editProduct.allTags.push(this.searchString);
      this.global.allTags.push(this.searchString);
      this.updateTags();
      this.searchString = "";
    }
  */
  removeProductTag(e, t: string) {
    e.stopImmediatePropagation();
    return;
    const index = this.editProduct.allTags.indexOf(t);
    if (index != -1) {
      this.editProduct.allTags.splice(index, 1);
      //    this.updateTags();
      this.viewTagTab = true;
    }
  }

  removeTag(e, t: string) {
    e.stopImmediatePropagation();
    const index = this.editNode.productTags.indexOf(t);
    if (index != -1) {
      this.editNode.productTags.splice(index, 1);
      this.updateProductTags(this.editNode, this.appId)
      this.viewTagTab = true;
    }
  }

  gemIncluded(node: AppNode, gem: DataGem) {
    if (!node.dataGems) { node.dataGems = []; }
    const includes = node.dataGems.some(function (post) {
      if (post.name == gem.name)
        return true;
    });
    //  console.log (node, gem, includes)
    return includes;
  }

  ChangeStyles() {
    var db = firebase.firestore();
    var self = this;

    /*
    
        var docRef = db.collection("products").doc(self.editNode.docID);
    
        console.log("Product:! ", self.editProduct, docRef);
        if (!self.editProduct.style) self.editProduct.style = "Minimal";
        if (self.editProduct.html) {
          self.editProduct.safeHtml = self.editProduct.html;
          //  self.html = self.sanitizer.bypassSecurityTrustHtml(self.editProduct.html);
          console.log("HTML: ", self.editProduct.safeHtml);
          //  self.editProduct.safeHtml = self.html;
        }
    
    
        if (!self.editProduct.docID) {
          self.editProduct.docID = docRef.id;
          //    self.editProduct.categoryTags = [];
          //    self.editProduct.categoryTags.push (self.editNav.key);
          docRef.set(self.editProduct)
            .then(() => {
              console.log("Product successfully added!");
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating product: ", error);
            });
    
        }
        else {
          docRef.update(self.editProduct)
            .then(() => {
              console.log("Product successfully updated!", self.editProduct);
    
            })
            .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating product: ", error);
            });
        }
    */
  }

  SaveDataStop(node, $event) {

    this.ChangeStyles();
    this.visible = false;
  }

  SaveProduct(p, e) {
    this.ChangeStyles();

  }


  updateProductTags(node: AppNode, collectionId) {
    const db = firebase.firestore()

    const vm = this;
    vm.global.updatingNodes = true;
    var docRef =
      db.collection('apps')
        .doc(collectionId)
        .collection('nodes').doc(node.id)
    docRef.update({ productTags: node.productTags }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin html", error);
      });

  }

  getTagCount(tag: string) {

    var index = this.global.allTags.indexOf(tag);
    if (index != -1) return (this.global.allTagsCount[index])
    return 0;

  }


  FindMedia(node) {
    this.ImageSearch = true;
    this.visible = true;

  }

  ChooseImage(p: Product, img: GalleryImage) {
    p.image1 = img.thumbnailImageSrc;
    console.log("Excellent Choice! ", img);
    // this.SaveData(node);
    this.ImageSearch = false;

  }

  getMyLink(item: Product) {

    // return the direct link
    //  return "napkinhq.com/home/?c="+item.sid;

    return ""
  }
  /*
    loadMedia () {
      var self = this;
      console.log ("Loading Media")
      var db = firebase.firestore();
      self.myMedia = [];
      if (this.editProduct.media)
      this.editProduct.media.forEach (function(media){
        const mediaRef =  db.collection("media").doc(media).get()
        .then((querySnapshot) => {
          const data = <Media>querySnapshot.data();
          self.myMedia.push(data);
        })
      })   
  
    }
  */

  ChooseVideo(p: Product, videoURL: string) {
    var self = this;
    var db = firebase.firestore();

    var newMedia: Media = {};

    newMedia.fileType = "video";
    newMedia.url = videoURL;

    self.updateProductMedia(newMedia);

    return;
    /*
 
    var mediaRef = db.collection("media").doc();
 
    newMedia.id = mediaRef.id;
 
 
    mediaRef.set(newMedia)
      .then(() => {
        console.log("Media Added!", newMedia);
 
        if (!self.editProduct.media) self.editProduct.media = [];
        self.editProduct.media.push(newMedia.id);
        if (!self.myMedia) self.myMedia = [];
        self.myMedia.push(newMedia);
        console.log("Excellent Choice! ", self.myMedia);
        self.visible = false;
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating product: ", error);
      });
 
*/




  }

  updateProductMedia(media: Media) {
    var db = firebase.firestore();
    var self = this;

    const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").doc(self.editProduct.docID)

    //var docRef = db.collection("products").doc(self.editProduct.docID)

    docRef.update({ media })
      .then(() => {
        console.log("Media successfully updated!", media);

      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating media: ", error);
      });


  }



  updateProduct() {
    var db = firebase.firestore();
    var self = this;

    const docRef = db.collection('company').doc(self.global.myCompany.id).collection("products").doc(self.editProduct.docID)

    // var docRef = db.collection("products").doc(self.editProduct.docID);

    docRef.update(self.editProduct)
      .then(() => {
        console.log("Product successfully updated!", self.editProduct);

      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating product: ", error);
      });


  }



  FindImages(search: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'bing-image-search1.p.rapidapi.com'
      }
    };

    var fetchStr = 'https://bing-image-search1.p.rapidapi.com/images/search?q=' + search + '&count=16'

    fetch(fetchStr, options)
      .then(response => response.json())
      .then(response => {
        console.log("Convert to carousel", response);
        var r = <ImageValues[]>response.value;
        self.images = [];
        r.forEach(function (item: ImageValues) {

          var newNode: GalleryImage = {
            previewImageSrc: item.contentUrl,
            thumbnailImageSrc: item.thumbnailUrl,
            alt: "string",
            title: "Title"
          }
          self.images.push(newNode);
        })
        console.log("Gallery: ", self.images);

      })
      .catch(err => console.error(err));

  }

  FindVideo(search: string) {
    var self = this;

    const options = {
      method: 'GET',
      headers: {
        Authorization: '563492ad6f91700001000001654b43a8e2d04946ad43a2468e61c945',
        'X-RapidAPI-Key': '3db8ec5e8cmshd02fcacee808d07p11c61ajsn2e99cdfb6199',
        'X-RapidAPI-Host': 'PexelsdimasV1.p.rapidapi.com'
      }
    };
    var search: string = 'https://pexelsdimasv1.p.rapidapi.com/videos/search?query=' + search + '&per_page=15&page=1';
    console.log("V Search: ", search);
    fetch(search, options)
      .then(response => response.json())
      .then(response => {
        console.log(response);
        var r = <Video[]>response.videos;
        self.videos = [];
        r.forEach(function (item: Video) {

          var smallest: number;
          var smallIndex: number;
          item.video_files.forEach(function (v: VideoFile, i) {
            if (!smallest || (v.height * v.width) < smallest) {
              smallest = v.height * v.width;
              smallIndex = i;
            }
          })

          self.videos.push(item.video_files[smallIndex].link);
        })
        console.log("Videos: ", self.videos, r);

      })
      .catch(err => console.error(err));
  }

  changeDataGem(node: AppNode, d) {
    if (!this.gemIncluded(node, d))
      node.dataGems.push(d);
    else {// remove the gem
      const index = node.dataGems.findIndex(function (post) {
        if (post.name == d.name)
          return true;
      });
      if (index != -1) {
        node.dataGems.splice(index, 1);
      }

    }
    this.updateDataGem(node)

  }
  selectTab(i) {

  }

  createParentId(): string {
    var uid = "";

    // For now just 3 1000 randoms + time;

    var a = Math.floor(Math.random() * 1000).toString();
    var d = Date.now().toString()
    console.log("D ", d)
    uid = a + d.substring(7, 3)

    return uid;
  }


  addContainer(i) {
    if (!this.editNode.containers)
      this.editNode.containers = [];

    this.editNode.containers.push({
      title: "New container",
      parentId: this.createParentId(),

    })


    const db = firebase.firestore()
    const vm = this;

    var docRef = db.collection("apps").doc(vm.appId)
      .collection('nodes').doc(vm.editNode.id)
    vm.global.updatingNodes = true;
    docRef.update({
      containers: vm.editNode.containers

    }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin containers", error);
      });




  }

  updateDataGem(node: AppNode) {
    const db = firebase.firestore()
    const vm = this;
    vm.global.updatingNodes = true;
    var docRef =
      db.collection('apps')
        .doc(this.appId)
        .collection('nodes').doc(node.id)
    console.log("ref ", docRef)
    docRef.update({ dataGems: node.dataGems }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin html", error);
      });

  }



  Clicked(command: string) {

    console.log("Clicked ", command);
    switch (command) {
      case "+Height":
        if (!this.editNode.height)
          this.editNode.height = 200;

        if (this.global.getScreenWidth < 600) { // NARROW/MOBILE
          if (!this.editNode.narrowHeight) {
            this.editNode.narrowHeight = this.editNode.height;
          }
          this.editNode.narrowHeight += 25;

        }
        else { // WIDE SCREENS
          this.editNode.height += 25;
          this.editNode.autoSize = 'auto';
        }

        break;

      case "-Height":
        if (!this.editNode.height)
          this.editNode.height = 200;

        if (this.global.getScreenWidth < 600) { // NARROW/MOBILE
          if (!this.editNode.narrowHeight) {
            this.editNode.narrowHeight = this.editNode.height;
          }
          this.editNode.narrowHeight -= 25;

        }
        else { // WIDE SCREENS
          this.editNode.height -= 25;
          this.editNode.autoSize = 'auto';
        }


        break;
      case "Max content":
        if (!this.editNode.autoSize) this.editNode.autoSize = "max-content";
        else this.editProduct.autoHeight = null;
        break;

      case "Left":
        if (this.editNode.posX == null) {
          this.editNode.posY = 50;
          this.editNode.posX = 50;
        }
        else
          this.editNode.posX -= 2;
        break;
      case "Center":
        this.editNode.posX = 50;
        this.editNode.posY = 50;
        break;
      case "Right":
        if (this.editNode.posX == null) {
          this.editNode.posX = 50;
          this.editNode.posY = 50;
        }
        else
          this.editNode.posX += 2;
        console.log("right ", this.editNode.posX);
        break;

      case "Up":
        if (this.editNode.posY == null) {
          this.editNode.posY = 50;
          this.editNode.posX = 50;
        }
        else
          this.editNode.posY -= 2;

        break;
      case "Down":
        if (this.editNode.posY == null) {
          this.editNode.posY = 50;
          this.editNode.posX = 50;
        } else
          this.editNode.posY += 2;
        console.log("down ", this.editNode.posY);
        break;
      case "Cover":
        this.editNode.size = 'cover';
        break;
      case "Contain":
        this.editNode.size = "contain";
        break;
      case "Fill":
        this.editNode.size = "fill";
        break;
      case "None":
        this.editNode.size = "none";
        break;
      case "Scale down":
        this.editNode.size = "scale-down";
        break;


    }

  }


  changeBackColor(node: AppNode, p) {

    this.editNode.backgroundColor = p;
    this.updateBackColor(node);

  }

  updateBackColor(node: AppNode) {
    const db = firebase.firestore()

    const vm = this;
    console.log("BACKCOLORUPD ", node, vm.appId)
    vm.global.updatingNodes = true;

    var docRef =
      db.collection('apps')
        .doc(this.appId)
        .collection('nodes').doc(node.id)
    docRef.update({ backgroundColor: node.backgroundColor })
      .then(() => {
        console.log("SAVED")
        vm.global.updatingNodes = false;

      })
      .catch((error) => {
        console.error("Error savin bc", error);
      });

  }

  getBackColor(p) {
    return "background-color: " + p;

  }

  changeActive(node: AppNode) {
    const db = firebase.firestore()

    const vm = this;
    vm.global.updatingNodes = true;

    var docRef =
      db.collection('apps')
        .doc(this.appId)
        .collection('nodes').doc(node.id)
    docRef.update({ active: node.active })
      .then(() => {
        //   vm.basicEdits = true;
        vm.global.updatingNodes = false;

      })
      .catch((error) => {
        console.error("Error savin reviews", error);
      });

  }
  updateDecription(node: AppNode) {
    const db = firebase.firestore()

    const vm = this;
    var html: SafeHtml;
    html = vm.sanitizer.bypassSecurityTrustHtml(<string>node.html);
    node.safeHtml = html;
    vm.global.updatingNodes = true;

    var docRef =
      db.collection('apps')
        .doc(this.appId)
        .collection('nodes').doc(node.id)
    docRef.update({ html: node.html })
      .then(() => {
        vm.global.updatingNodes = false;

      })
      .catch((error) => {
        console.error("Error savin html", error);
      });

  }
  /*
  updateNodeStatus(node: AppNode, docId, collectionId) {
    const db = firebase.firestore()

    const vm = this;
    vm.global.updatingNodes = true;

    var docRef =
      db.collection('apps')
        .doc(collectionId)
        .collection('nodes').doc(node.id)
    docRef.update({ objectType: node.objectType }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin html", error);
      });
  }
  */

  updateTitle(node: AppNode) {
    const db = firebase.firestore()
    const vm = this;
    vm.global.updatingNodes = true;
    var docRef =
      db.collection('apps')
        .doc(vm.appId)
        .collection('nodes').doc(node.id)
    console.log("ref ", docRef)
    docRef.update({ title: node.title }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin html", error);
      });

  }

  updateNodeStyle(node: AppNode, event) {
    const db = firebase.firestore()
    const vm = this;

    if (!node.narrowHeight) node.narrowHeight = node.height;
    console.log("Updating Style ", node)

    if (!node.posX) node.posX = 50;
    if (!node.posY) node.posY = 50;

    vm.global.updatingNodes = true;
    var docRef =
      db.collection('apps')
        .doc(vm.appId)
        .collection('nodes').doc(node.id)

    docRef.update({
      height: node.height,
      narrowHeight: node.narrowHeight,
      size: node.size,
      posX: node.posX,
      posY: node.posY,

    }).then(() => {
      vm.global.updatingNodes = false;

    })
      .catch((error) => {
        console.error("Error savin html", error);
      });

  }

}

function removeUndefined(o) {
  let stack = [o], i;
  while (stack.length) {
    Object.entries(i = stack.pop()).forEach(([k, v]) => {
      if (v === undefined) delete i[k];
      if (v instanceof Object) stack.push(v);
    })
  }
  return o;
}
