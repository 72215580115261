import { Component, SkipSelf, Input, HostListener, ViewChild, Output, EventEmitter, OnInit, AfterViewInit, BootstrapOptions } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import firebase from 'firebase/compat/app';
import { doc, onSnapshot } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import { environment } from '../../../../environments/environment';
import { interval, NotFoundError } from 'rxjs';
import { User, Media, MediaGroup } from '../../user-interface';
import { MessageService } from 'primeng/api';
import { Globals } from 'src/app/globals';
import { Company, Form, Field, NapkinApp, Gallery, AppNode, Navigate, Reviews, Review, Duration, ReservationSettings, resTotals, ReservationDay, reservation, resTime, AddOns, resDate, NodeType } from '../../company-interface';
import { Product, Options, Table } from '../../product-int';
import { onMenuItem } from '../../menu-item-interface';
import { Content, Weather } from '../../content-interface';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProductsService } from '../../products.service';
import { ViewProductsComponent } from '../../menu/view-products/view-products.component';
import { DragDropModule } from 'primeng/dragdrop';
import { HttpEvent } from '@angular/common/http';
import { FixedSizeVirtualScrollStrategy } from '@angular/cdk/scrolling';
import { NavService } from '../../menu/nav.service';
import { ReviewsComponent } from '../../reviews/reviews.component';
import { Quote } from '../../content-interface';
import { DomSanitizer, SafeHtml, SafeScript } from '@angular/platform-browser';
import { CartService, SharedDataService } from '../../menu/cart.service';
import { Order } from '../../order-interface';
import { Howl, Howler } from 'howler';
import { FormService } from '../../form.service'
import { NodeService } from '../../node.service';
import { MediaService } from "../../media//media-functions/media-functions"

export interface AppForm {
  node: AppNode;
  form: Form;
}
export interface Container {
  node: AppNode;
  index: number;
}

@Component({
  selector: 'app-node-object',
  templateUrl: './node-object.component.html',
  styleUrls: ['./node-object.component.scss', '../../../common.scss']
})
export class NodeObjectComponent implements OnInit {
  @Input() appId: string;
  @Input() nodeId: string;
  @Input() pageRoot: any;
  @Input() appLink: NapkinApp;
  @Input() node: AppNode;
  @Input() view: boolean = false;
  @Input() editing: boolean = false;
  @Input() currentParent: string;
  @Output() changeParent = new EventEmitter<any>();
  @Output() tabOpened = new EventEmitter<any>();

  appName = "Napkin App";
  list: string[];

  constructor(public nodeService: NodeService, public cartService: CartService, public sanitizer: DomSanitizer, public global: Globals, private router: Router,
    private route: ActivatedRoute,
    public navService: NavService,
    public formService: FormService,
    public mediaService: MediaService,
    public productService: ProductsService) {
    this.list = ["This is A", "This is B", "This is C"]


  }

  ngOnInit(): void {
    var self = this;

    //   console.log("Node Object ", this.appLink, this.node, this.appId)
    if (this.nodeId) {   // fetch the node.
      this.getTheNode();
    }
    if (self.node && self.node.html) {
      self.node.safeHtml = self.sanitizer.bypassSecurityTrustHtml(self.node.html);

    }
  }

  getTheNode() {
    this.nodeService.getNode(this.appId, this.nodeId, this.gotNode, this);
  };
  gotNode(node: AppNode, self) {
    console.log('Got node: ', node)
    self.node = node;
    if (self.node && self.node.html) {
      self.node.safeHtml = self.sanitizer.bypassSecurityTrustHtml(self.node.html);

    }
  }




  gotoReservations() {
    this.router.navigate(['/reserve/' + this.global.myCompany.store]);

  }

  checkParent(myParent: string, node) {
    var self = this;

    if (this.view) return true;
    if (this.currentParent == myParent) return true;
    else {
/* still working on this
      // Test for EMPTY APP
      if (node.objectType == "Home" &&
        self.global.appNodes[self.appId].nodes.length == 1
      ) return true;
*/
      return false;
    }

  }
  getProduct(productId): Product {

    const found = this.global.allProducts.find(element => element.docID == productId)

    if (found && found.active == true) {
      console.log("found p")
      return found
    }
    else return null;
  }

  checkActive() {
    if (!this?.node?.active) return false;
    return true;
  }

  checkSharing() {
    //  if (!this.node.active) return false;
    if (!this.node?.shareMode) return true;
    if (this.node?.shareMode == 'public') return true;
    if (this.node.shareMode == 'private' &&
      this.node.createdBy == this.global.authuser.uid) return true;



    return false;
  }

  editNode: AppNode;
  editMe(node: AppNode) {
    var self = this;
    console.log("EDIT THIS NODE: ", node)
    self.editNode = node;
    self.editing = true;

    //  this.global.selectedTab = null;

  }
  finishEditing() { this.editNode = null; }

  canMove(node, upOrDown) {
    var self = this;
    var meIndex = -1;
    var container: any[] = [];

    // console.log("Can I move? ", self.appId, node)

    if (self.global?.appNodes[self.appId]?.nodes)
      self.global.appNodes[self.appId].nodes.forEach(function (n, index) {
        if (n.parent == node.parent) {
          // Hack add checks for visibility
          container.push(index);
          if (node == n) { ///Me
            meIndex = container.length - 1;
          }
        }
      })

    if (upOrDown == 'up' && meIndex > 0) {
      // Can be moved up
      return true;
    }

    if (upOrDown == 'down' && meIndex < container.length - 1) {
      // Can be moved down
      return true;
    }
    return false;
  }


  openTab(data) {
    var self = this;

    var tab: Container = <Container>data;
    console.log("User opened tab: ", tab, self.tabOpened)
    // Now that user opened the tab we add a parent
    self.containerOpen(tab.node, tab.index);
    //  if (typeof (self.tabOpened) == 'function')
    //    self.tabOpened.emit(data);

  }

  containerOpen(node: AppNode, tabIndex) {
    var self = this;

    // How many child nodes do I have??
    const nodeCount = self.global.appNodes[self.appId].nodes.some(function (post) {
      if (post.parent == node.containers[tabIndex].parentId)
        return true;
    });
    if (!nodeCount) {
      node.parent = node.parentId;
      self.nodeService.insertNew(node, self.appId, tabIndex);
    }

    /*
    
    
        const index = self.global.appNodes.findIndex(function (post) {
          if (post.id == node.parent)
            return true;
        });
    
        if (index == -1) { // CHECK FOR ACCORDION TAB AS PARENT
          var found = -1;
          var myParent;
    
          self.global.appNodes.findIndex(function (post) {
            if (post.objectType == 'tabList') {
              post.containers.forEach(function (tab, i) {
                if (node.parent == tab.parentId) {
                  found = i;
                  myParent = tab.parentId;
                  console.log("Found parent container ", tab)
                }
              })
            }
          })
        }
    
            // Home should always be found
    
            console.log("Open TAB: ", node, self.editing, index, found)
    
        if (found != -1) {
    
    
        // How many child nodes do I have??
        const nodeCount = self.global.appNodes.some(function (post) {
          if (post.parent == myParent)
            return true;
        });
        if (!nodeCount) {
          node.parent = node.parentId;
          self.nodeService.insertNew(node, self.appId, found); //??Found
        }
        console.log("Container opened here", nodeCount, node, found)
      }
    
            //   self.parent = node.id;
    
            if (!self.editing) {
              // Ok let's Nav to the content
            }
    */

  }
  taggedProduct(node, p) {

    if (!node.productTags) return false;

    var found = false;
    node.productTags.forEach(function (item) {
      if (p.allTags && p.allTags.includes(item) && p.active == true && p.onMenu) found = true;

    })

    return found;

  }

  isNewObject(node: AppNode) {
    var self = this;
 //   console.log('NODE: ', node)
    if (!node) return false;
    if (!node?.objectType) {
      console.log("BAD NODE ", node);
      return false;
    }
    // Test for EMPTY APP
    if (node.objectType == "Home" &&
      self.global.appNodes[self.appId].nodes.length == 1
    ) return true;


    if (node.objectType == "New Node") return true;
    /*
         
       //   if (node.title == "New Item") return true;
          if (node.objectType) return false;
       //   if (node.objectType && node.objectType.toLowerCase() != 'content') return false;
    
          if (node.objectType.toLowerCase() == 'nav') return false;
          if (node.objectType.toLowerCase() == 'product' && (node.products || node.productTags)) return false;
          if (node.objectType.toLowerCase() == 'content' && node.safeHtml) return false;
    
          if (node.objectType.toLowerCase() == 'dataview' && node.dataViews) return false;
        //  if (!node.mediaGroupId && (!node.media || (node.media && node.media.length == 0))) return true;
          else return false;
    
          // else if (!node.html) return true;
    */
    return false;

  }

  formSubmitted(event: Form) {

    console.log("App form submitted ", event)
  }

  outputEvent(event) {
    var self = this;
    //  event.stopPropagation();
    //  event.stopImmediatePropagation();

    //  console.log("Object Event triggered - node ", event)
    //  if (event.nodeId)
    //    self.navToID(event.nodeId);//make sure to use Parent ID in Form/phaser/event code



  }

  getBackgroundColor(node: AppNode) {

    if (node.backgroundColor)
      return node.backgroundColor
    else return "#000000";

  }

  isEmpty(node: AppNode) {
    if (!node) return false;

    if (node?.media && node.media.length) return false;
    if (node.objectType.toLowerCase() == 'nav' && !node?.media?.length && !node?.mediaGroupId) return true;

    if ((!node.html || node.html.length == 0) && (node.objectType == 'Nav' || node.objectType.toLowerCase() == 'content'))
      return true;

    else return false;
  }

  navToID(nodeId) {
    var self = this;

    const index = self.global.appNodes[self.appId].nodes.findIndex(function (post) {
      if (post.id == nodeId)
        return true;
    });
    // Let's record the Click in stats
    //   if (!self.global.appDoc[appId].appStats.ipAddress)
    //     vm.global.appDoc[appId].appStats.ipAddress = [];

    if (index != -1) {
      const parentIndex = this.global.appNodes[self.appId].nodes.findIndex(function (post) {
        if (post.id == self.global.appNodes[index].parent)
          return true;
      });
      console.log("II ", nodeId, index, parentIndex, self.global.appNodes)

      self.global.parents.push(self.global.appNodes[parentIndex]);
      self.global.parentNode = self.global.appNodes[parentIndex];
    }
    else { console.log("NAV TO NOWHERE") }

    self.currentParent = nodeId; //??self.global.appNodes[index].parent;
    self.changeParent.emit(self.currentParent);
    console.log("parent: ", self.global.parents);
    self.pageRoot.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  goHome() {
    var self = this;

    const home = self.global.appNodes[self.appId].nodes.find(function (post) {
      if (post.id == 'Home')
        return true;
    });

    self.global.parents = [];
    self.global.parentNode = null;

    self.currentParent = home.parentId;

    console.log("parent: ", self.global.parentNode);
    self.pageRoot.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });

  }
  getNodeStyle(nodeType: NodeType) {
    return "background-color: " + nodeType.backgroundColor
  }

  navTo(node: AppNode) {
    var self = this;
    console.log("NAV TO: ", node, self.appId)

    //record the stat
    /*
          if (self.global?.appDoc[self.appId]?.app?.appStats  && 
            !self.global.appDoc[self.appId]?.app?.appStats?.navCounters)
            self.global.appDoc[self.appId].app.appStats.navCounters = [];
    
          var found = self.global.appDoc[self.appId].app.appStats.navCounters.find((f) => f.nodeId == node.id)
          if (found) found.count++;
          else self.global.appDoc[self.appId].app.appStats.navCounters.push(
            {nodeId: node.id, count: 1, title:node.title}
          )
          console.log ("Stat: ",self.global.appDoc[self.appId].app)
          self.nodeService.AddItem(self.appId, 0); //.subscribe();
    
    */
    // First find my parent/Home
    const index = self.global.appNodes[self.appId].nodes.findIndex(function (post) {
      if (post.parentId == node.parent)
        return true;
    });
    // Home should always be found

    if (index != -1) {
      self.global.parents.push(self.global.appNodes[self.appId].nodes[index]);
      self.global.parentNode = self.global.appNodes[self.appId].nodes[index];
    }
    else { console.log("WE FOUND AN ORPHAN...", node) }

    self.currentParent = node.parentId;
    self.changeParent.emit(node.parentId)
    console.log("go: ", self.appId, index, node, node.parentId, self.global.parentNode);

    // How many child nodes do I have??
    const nodeCount = self.global.appNodes[self.appId].nodes.some(function (post) {
      if (post.parent == node.parentId || post.parent == node.id)
        return true;
    });
    if (!nodeCount) {
      node.parent = node.parentId;
      self.nodeService.insertNew(node, self.appId);
    }

    console.log("parent: ", self.global.parentNode);
    self.pageRoot.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  getHeight(node) {


    //    if (node.height)
    //      return node.height.toString() + 'px'
    //   else
    return "100%";
  }
  getWidth(node) {
    //   console.log (this.global.getScreenWidth,this.appLink.maxWidth)
    if (!this.appLink || !this.appLink?.maxWidth) return this.global.getScreenWidth

    if (this.appLink?.maxWidth == "100%")
      return this.global.getScreenWidth;
    if (this.global.getScreenWidth > this.appLink.maxWidth)
      return this.appLink.maxWidth;

    //  if (this.global.getScreenWidth > 1200) return 1200;
    //  else return this.global.getScreenWidth;


    if (node.width)
      return node.width.toString() + 'px'
    else return "100%";
  }
  getMediaWidth(m: Media) {
    if (this.global.getScreenWidth > 900) return 900;
    else if (this.global.getScreenWidth > 600) return 600;
    else return this.global.getScreenWidth;

    return "800px";
  }

  getMediaHeight(node) {
    var style = "";

    if (!node.size) node.size = "cover";
    var style = "";

    if (node.media && node.media.length) {
      style = "object-fit: " + node.size + ";";
      if (node.posX) style += "object-position: " + node.posX + "% " + node.posY + "%;"
    }

    var height = 300;
    if (node.height) height = node.height;
    if (this.global.getScreenWidth < 900 && node.narrowHeight)
      height = node.narrowHeight;
    /*
    
        var height = 200;
    
    
        if (node.height) height = node.height;
    
        if (this.global.getScreenWidth < 900) {
          style = height + "px;";
          //style = "35vh"
        }
        else {
          if (height) style = height + "px;";
        }
        */

    if (height) style += height + "px;";
    return style;


    //    if (this.global.getScreenHeight > 900) return 900;
    //    else return this.global.getScreenHeight;

  }


  getBackgroundImageUrl(node: AppNode) {
    return node.media[0].url;

  }

  getBackImageStyle(node: AppNode) {

    if (!node.size) node.size = "cover";
    var style = "";

    if (node.media && node.media.length) {
      style = "background-image: url(" + node.media[0].url + "); width: 100%; background-repeat: no-repeat; background-position: center; background-size: " + node.size + ';'
      if (node.posX) style += "background-position: " + node.posX + "% " + node.posY + "%;"
    }
    else {
      style = "background: " + node.backgroundColor;

    }

    var height = 300;
    if (node.height) height = node.height;

    if (this.global.getScreenWidth < 900) {
      if (node.narrowHeight) height = node.narrowHeight;

      // VIEW MEDIA MOBILE
      //  style += "max-height: 25vh"; // SETTINGS
      if (height) style += "height: " + height + "px;";
    }
    else {
      if (height) style += "height: " + height + "px;";
    }

    return style;
  }

  getImageStyle(node: AppNode) {

    if (!node.size) node.size = "cover";

    var style = "width: 100%;  object-fit: " + node.size + ';'
    if (node.posX) style += "object-position: " + node.posX + "% " + node.posY + "%;"
    if (node.height) style += "height: " + node.height + "px;";
    else style += " height: 100%;"
    //    console.log("backstyle ", style);
    return style;
  }

  getNavHeight(node: AppNode) {
    if (!node.size) node.size = "cover";
    var style = "";

    if (node.media && node.media.length) {
      style = "object-fit: " + node.size + ";";
      if (node.posX) style += "object-position: " + node.posX + "% " + node.posY + "%;"
    }
    else {
      style = "background: " + node.backgroundColor;

    }

    var height = 300;
    if (node.height) height = node.height;
    if (this.global.getScreenWidth < 900 && node.narrowHeight)
      height = node.narrowHeight;

    style += "height: " + height + "px;";
    //  console.log ("video style ", style)

    return style;


  }

  getNavStyle(node: AppNode) {
    var self = this;

    var style = "width:100%; object-fit:cover;"

    var height = 300;
    if (node.height) height = node.height;

    if (this.global.getScreenWidth < 900)
      if (node.narrowHeight) height = node.narrowHeight;

    //  style += "height: " + height + "px;";

    console.log("sty;e;", style)
    return style;
  }

  /*
[style.height]="getMyNavHeight(n)"
            [style.object-fit]="getNavFit(n)" [style.object-position]="getNavPos(n)"
  
 
            image
[ngStyle]="{ 'height': getMyNavHeight(n), 'background-repeat': no-repeat, 'background-position': getNavPos(n), 'background-size': getBackgroundSize(n)}"
  
            */


  changeAudio(id, i) {

    var audio = <HTMLVideoElement>document.getElementById(id + i);
    audio.muted = !audio.muted;
  }

  playEnded(i) {
    console.log("Play ended: ", i);

  }
  showPlay(i) {
    return false;
  }

  paused: boolean = false;

  isPaused(id, i) {

    return this.paused;

    var video = <HTMLVideoElement>document.getElementById(id + i);

    if (video && !video?.paused && !video?.onended) {

      video.onended = (event) => {

        console.log(
          "Video stopped either because it has finished playing or no further data is available.",
        );
        return true;
      }
    }


    return false;


  }

  playVideo(id, i) {
    var video = <HTMLVideoElement>document.getElementById(id + i);
    video.play();
    this.paused = false;

  }

  pauseVideo(id, i) {
    var video = <HTMLVideoElement>document.getElementById(id + i);
    video.pause();
    this.paused = true;


  }

  audioLabel(id) {
    var audio = <HTMLVideoElement>document.getElementById(id);
    if (audio && audio.muted) return "Play Audio"
    else return "Mute Audio"

  }
}
