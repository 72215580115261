<p-accordion fxLayout="column" fxLayoutAlign="center center" expandIcon="false" collapseIcon="false" [multiple]="true">

    <div *ngIf="global.pendingReviews && global.pendingReviews.length>0">
        <app-manage-reviews></app-manage-reviews>
    </div>

    <p-accordionTab>
        <ng-template pTemplate="header">
            <div class="pretty-feature">Settings</div>
        </ng-template>
        <ng-template pTemplate="content">

            <div fxFlex fxLayout="column" fxLayoutAlign="center center"
                style="font-size:1.2em; line-height: 1.5em;  text-align: center;">
                <app-form formName="masterReviewSettings" [autoSubmit]="true" [formInput]="global.myCompany.reviewSetup"
                    (objectEvents)="outputEvent($event)"></app-form>

                <div *ngIf="global.reviews.totals">
                Review Stats<br>
                New reviews last 24 hours: {{global.reviews.totals.reviewsLastDay}}<br>
                New reviews last 7 days: {{global.reviews.totals.reviewsLastWeek}}<br>
                New reviews last 30 days: {{global.reviews.totals.reviewsLastMonth}}<br>
                </div>
                Reviews are LIVE!<br>

                Your review URL: <a target="_blank"
                    href="/review/{{global.myCompany.store}}">NapkinHQ.com/review/{{global.myCompany.store}}</a><br>



                <img src={{getReviewUrl()}}>

                <!--

                <div style="font-size:1.2rem;" *ngIf="global.myCompany.reviewSettings && global.myCompany.reviewSettings.couponsActive &&  
                    global.myCompany.reviewSettings.activeCoupons">
                    <br>

                    You have active Perks for reviewers.
                    <strong>{{global.myCompany.reviewSettings.activeCouponNames[0]}}</strong>

                </div>
            -->

                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty-feature" [ngStyle]="{ 'width': getFullWidth()}">Google Reviews</div>
                    </ng-template>
                    <ng-template pTemplate="content">

                        <div *ngIf="!global.myCompany.hasGoogleInfo">
                            <button (click)="registerService.FetchGoogleInfo()" class="function" pButton pRipple
                                type="button">
                                Get Google Info
                            </button>
                        </div>    

                        <div *ngIf="global.myCompany.googleBusinessInfo">
                            <!--  Ask user to verify data before collecting reviews -->
                            We found {{global.myCompany.googleBusinessInfo.review_count}} Google
                            Reviews
                            for
                            {{global.myCompany.googleBusinessInfo.full_address}}<br>
                            Here is your direct Google review link:<br>
                            <a target="_blank" href="{{global.myCompany.googleBusinessInfo.reviewLink}}">{{"
                                "+global.myCompany.googleBusinessInfo.reviewLink}}</a><br>

                            <div class="command" (click)="reviewsService.getGoogleReviews()">Collect
                                All
                                Google Reviews</div>

                        </div>
                    </ng-template>
                </p-accordionTab>

                <p-accordionTab>
                    <ng-template pTemplate="header">
                        <div class="pretty-feature" [ngStyle]="{ 'width': getFullWidth()}">Yelp Reviews</div>
                    </ng-template>
                    <ng-template pTemplate="content">

                        <div *ngIf="global.myCompany.enabled.reviewsPro">


                            <div *ngIf="!global.myCompany.yelpOverview">
                                <div class="command" (click)="reviewsService.FetchYelpID()">Get Your
                                    YelpId
                                </div>

                            </div>
                            <div *ngIf="global.myCompany.yelpOverview">
                                <div>YelpId: {{" "+global.myCompany.yelpOverview.id}}<br>
                                    Name: {{" "+global.myCompany.yelpOverview.name}}<br>
                                    Address: {{" "+global.myCompany.yelpOverview.address}} <br>
                                    Phone: {{" "+global.myCompany.yelpOverview.phone}} <br>
                                    Business page Link: {{"
                                    "+global.myCompany.yelpOverview.business_page_link}}<br>
                                    Yelp Review Link:<br><a href="{{global.myCompany.yelpOverview.addReview}}">{{"
                                        "+global.myCompany.yelpOverview.addReview}}</a><br>
                                    Price range: {{" "+global.myCompany.yelpOverview.price_range}}<br>
                                    Review count: {{" "+global.myCompany.yelpOverview.review_count}}<br>
                                    Rating: {{" "+global.myCompany.yelpOverview.rating}}<br>


                                </div>
                                <div *ngIf="!global.myCompany.yelpConfirmed && !global.authuser.emailVerified">

                                    Please validate email to unlock next steps.
                                </div>

                                <div *ngIf="!global.myCompany.yelpConfirmed && global.authuser.emailVerified">

                                    <div class="command" (click)="reviewsService.confirmYelp()">Click to
                                        CONFIRM that this is your business</div>
                                </div>

                                <div *ngIf="global.myCompany.yelpConfirmed && global.authuser.emailVerified">
                                    Yelp Id Claimed
                                    <div class="command" (click)="reviewsService.getYelpReviews()">Get
                                        your
                                        latest Yelp reviews</div>
                                </div>



                            </div>



                        </div>
                    </ng-template>
                </p-accordionTab>

            </div>


        </ng-template>
    </p-accordionTab>


    <p-accordionTab *ngIf="true">
        <ng-template pTemplate="header">
            <div class="pretty-feature">All Reviews</div>
        </ng-template>
        <ng-template pTemplate="content">



            <app-reviews [store]="global.myCompany.store"></app-reviews>
        </ng-template>
    </p-accordionTab>

</p-accordion>