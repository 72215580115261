import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CartService, SharedDataService } from '../menu/cart.service';
import { Order } from '../order-interface';
import { Product, Options } from '../product-int';
import { CurrencyPipe } from '@angular/common'
import { Globals } from '../../globals';
import { Accordion} from 'primeng/accordion'
import { onMenuItem } from '../menu-item-interface';


@Component({
  selector: 'cart-view',
  templateUrl: './cart-view.component.html',
  styleUrls: ['../pos/pos.component.scss',
  './cart-view.component.css']
})
export class CartViewComponent implements OnInit  {
  @Input() name: string;
  @Input() mode: string;

  @ViewChild('checkoutAccordion') checkoutAccordion: Accordion;

  viewTabItem: boolean[];
  showCheckOut: boolean = false;
  showCheckOutTab: boolean = false;

  constructor (public global: Globals, public cartService: CartService, private cp: CurrencyPipe,) {


  }
  ngOnInit(): void {
    var self  = this;

    self.viewTabItem = [];

    if (self.cartService.newOrderItems)
    self.cartService.newOrderItems.forEach(function(item){
      self.viewTabItem.push(false);

    })
    self.viewTabItem.push(false);
    self.showCheckOutTab = false;

// If we have a ticket but not watching items, let's do that now
   //nononon self.cartService.checkItemsWatching();


    console.log("Cart View: ", this.mode)
  }

  lineItem(order: Order) {

    if (order.qty == 1) return order.item;
    else if (order.price > 0) return order.item + " @" + order.price;
    else return order.item;

  }

  lineItemOptions(order: Order) {
    var myOptions = "";

    if (order.allOptions) {
      var oSet = 0;
      order.allOptions.forEach(function (arrayItem: Options) {
        if (arrayItem.chosen && arrayItem.type != 3) {
          myOptions += " " + arrayItem.chosen;
          //          console.log("added ",arrayItem.chosen);
          if (arrayItem.chosen.length) oSet++;
        }
        if (arrayItem.chosenList) {
          arrayItem.chosenList.forEach(function (optList) {
            myOptions += " " + optList;
            if (optList.length) oSet++;
          })


        }
      });
      if (oSet < order.allOptions.length) myOptions += " Select options";


    }
    return myOptions;
  }

  onTabClose (event) {
    var self = this;


  var value = event.index;
  console.log ("Close: ",  value);

    if (value == 0) {
      self.showCheckOutTab = true;
      self.showCheckOut = true;
      self.global.showCheckOut = true;
      self.checkoutAccordion.tabList[value].selected = true;
    }


  }

  public isExpanded() {
    return true;
  }

  checkOutPOS(event) {

  }

  onTabOpen (e: any) {
   // this.showCheckOutTab = true;
   // this.showCheckOut = true;
   // this.global.showCheckOut = true;

    console.log ("Open: ",  e);
  }

  getShowCheckout () {
    return this.global.showCheckOut;
  }

  getStatusColor(row: Order) {
    // RETURN COLOR CODE FOR THE CURRENT STATUS
    if (row.status == "Cart") { return "#adff2f"; }

    if (row.status == "New") { return "#ffe500"; }
    if (row.status == "Started") { return "#00FFFF"; }
    if (row.status == "Ready") { return "#8bc34a"; }
    if (row.status == "Delivered") { return "#ffffff"; }
    if (row.status == "Removed") { return "#222222"; }
    if (row.status == "Closed") { return "#f0f0f0"; }
  }
  getStatus(row: Order) {
    if (row.status == "Cart") { return "Order "; }

    if (row.status == "New") { return "New "; }
    if (row.status == "Started") { return "Started "; }
    if (row.status == "Ready") { return "Ready "; }
    if (row.status == "Delivered") { return "Delivered "; }
    if (row.status == "Removed") { return "Removed "; }
    if (row.status == "Closed") { return "Closed "; }

  }

  getExtPrice(item: Order) {

    if (item.RewardPaid) {
      return " " + (item.RewardPaid) + " points";
    }
    else {
      item.extPrice = this.checkDiscounts(item.qty, item.price, item)

     // item.extPrice = item.qty * item.price;
      var formatedOutputValue = this.cp.transform(item.extPrice, 'USD', 'symbol', '1.2-2');
      return formatedOutputValue;
      //  return " $" + (item.extPrice);
    }

  }

  checkOut(e) {
    var self = this;

    e.stopPropagation();
    e.stopImmediatePropagation();

    console.log("Checking out")
    self.showCheckOut = true;
    self.showCheckOutTab = true;
    self.global.showCheckOut = true;
  //  self.checkoutAccordion.tabList[0].selected = true;

    

  }

  checkDiscounts(qty, price, order: Order): number {
    var newPrice = price;
    if (order.discountsApplied && order.discountsApplied.length) {
      order.discountsApplied.forEach(function (item) {
        if (item.active) {
        //  console.log("Applying discount ", item)
          if (item.type == 'percent') {
            newPrice = newPrice * ((100 - item.value) / 100);
          }
          if (item.type == 'currency') {
            newPrice = newPrice - item.value;
          }
        }
      })
    }

    return newPrice * qty;
  }

  getOrderTotal() {
    var total = 0;
    var self = this;
    if (self.cartService?.newOrderItems?.length)
      self.cartService.newOrderItems.forEach(function (arrayItem) {
        total += +arrayItem.extPrice;
      })
    return total;
  }
  SendOrder() {  // Using cartservice
    var self = this;


  }

}